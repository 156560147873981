import { connectionConstants } from "../../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  formatNumber,
  convertClienteUtcToLavanderiaUtc,
} from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var selCompaRuta;

  //#region Datasource

  //#region COMPAÑÍAS
  var datasource_entidadesConPedidos_compañias = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infRutasExpedicion_spSelectEntidadConPedido_compañias",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var filtro_dxCalendar_fecha = $("#filtro_dxCalendar_fecha").dxCalendar(
          "instance",
        );
        var filtro_dxCalendar_fecha_value =
          typeof filtro_dxCalendar_fecha !== undefined &&
          filtro_dxCalendar_fecha.option("value")
            ? formatDate_parameter(
                $("#filtro_dxCalendar_fecha")
                  .dxCalendar("instance")
                  .option("value"),
              )
            : null;
        request.params.idLavanderia = filtro_dxCalendar_fecha_value
          ? LAVANDERIA.idLavanderia
          : -1;
        request.params.fecha = filtro_dxCalendar_fecha_value
          ? filtro_dxCalendar_fecha_value
          : formatDate_parameter(
              convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date()),
            );
      },
      version: 4,
    }),
  });

  var datasource_PedidosNEntidadNCompañia = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infRutasExpedicion_spSelectPedidosNCompañia",
      key: "idPedido",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var filtro_dxCalendar_fecha = $("#filtro_dxCalendar_fecha").dxCalendar(
          "instance",
        );
        var filtro_dxCalendar_fecha_value =
          typeof filtro_dxCalendar_fecha !== undefined &&
          filtro_dxCalendar_fecha.option("value")
            ? formatDate_parameter(
                $("#filtro_dxCalendar_fecha")
                  .dxCalendar("instance")
                  .option("value"),
              )
            : null;
        var dxDataGrid_selPedido = $("#dxDataGrid_selPedido").dxDataGrid(
          "instance",
        );

        request.params.idCompañia =
          dxDataGrid_selPedido.option("selectedRowKeys")[0];
        request.params.fecha = filtro_dxCalendar_fecha_value;
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    postProcess: function (data) {
      if (data.length > 0) {
        data.splice(0, 0, {
          idPedido: 0,
          denoEntidad: getTrad("todos").toUpperCase(),
          codigoPedido: null,
          observaciones: null,
        });
      }
      return data;
    },
  });

  var datasource_spSelectPrendaNPedido_compañias = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infRutasExpedicion_spSelectPrendaNPedido_compañias",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var filtro_dxCalendar_fecha = $("#filtro_dxCalendar_fecha").dxCalendar(
          "instance",
        );
        var filtro_dxCalendar_fecha_value =
          typeof filtro_dxCalendar_fecha !== undefined &&
          filtro_dxCalendar_fecha.option("value")
            ? formatDate_parameter(
                $("#filtro_dxCalendar_fecha")
                  .dxCalendar("instance")
                  .option("value"),
              )
            : null;
        var dxDataGrid_selPedido = $("#dxDataGrid_selPedido").dxDataGrid(
          "instance",
        );
        var dxDataGrid_PedidosNEntidad = $(
          "#dxDataGrid_PedidosNEntidad",
        ).dxDataGrid("instance");

        var idPedido = null;
        var idCompañia = null;
        var fecha = null;

        if (dxDataGrid_PedidosNEntidad.option("selectedRowKeys").length > 0) {
          if (dxDataGrid_PedidosNEntidad.option("selectedRowKeys")[0] === 0) {
            idCompañia = dxDataGrid_selPedido.option("selectedRowKeys")[0];
            fecha = filtro_dxCalendar_fecha_value;
          } else {
            idPedido = dxDataGrid_PedidosNEntidad.option("selectedRowKeys")[0];
          }
        }

        request.params.idPedido = idPedido;
        request.params.idCompañia = idCompañia;
        request.params.fecha = fecha;
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      onLoaded: function (data) {
        COMPONENT.loadPanel_hide();
      },
      version: 4,
    }),
  });
  //#endregion

  //#region RUTAS EXPEDICIÓN
  var datasource_entidadesConPedidos_rutasExpedicion = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infRutasExpedicion_spSelectEntidadConPedido_rutasExpedicion",
      key: "idRutaExpedicion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var filtro_dxCalendar_fecha = $("#filtro_dxCalendar_fecha").dxCalendar(
          "instance",
        );
        var filtro_dxCalendar_fecha_value =
          typeof filtro_dxCalendar_fecha !== undefined &&
          filtro_dxCalendar_fecha.option("value")
            ? formatDate_parameter(
                $("#filtro_dxCalendar_fecha")
                  .dxCalendar("instance")
                  .option("value"),
              )
            : null;
        request.params.idLavanderia = filtro_dxCalendar_fecha_value
          ? LAVANDERIA.idLavanderia
          : -1;
        request.params.fecha = filtro_dxCalendar_fecha_value
          ? filtro_dxCalendar_fecha_value
          : formatDate_parameter(
              convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date()),
            );
      },
      version: 4,
    }),
  });

  var datasource_PedidosNEntidadNRutasExpedicion = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infRutasExpedicion_spSelectPedidosNRutasExpedicion",
      key: "idPedido",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var filtro_dxCalendar_fecha = $("#filtro_dxCalendar_fecha").dxCalendar(
          "instance",
        );
        var filtro_dxCalendar_fecha_value =
          typeof filtro_dxCalendar_fecha !== undefined &&
          filtro_dxCalendar_fecha.option("value")
            ? formatDate_parameter(
                $("#filtro_dxCalendar_fecha")
                  .dxCalendar("instance")
                  .option("value"),
              )
            : null;
        var dxDataGrid_selPedido = $("#dxDataGrid_selPedido").dxDataGrid(
          "instance",
        );

        request.params.idRutaExp =
          dxDataGrid_selPedido.option("selectedRowKeys")[0];
        request.params.fecha = filtro_dxCalendar_fecha_value;
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      onLoaded: function (data) {
        COMPONENT.loadPanel_hide();
      },
      version: 4,
    }),
    postProcess: function (data) {
      if (data.length > 0) {
        data.splice(0, 0, {
          idPedido: 0,
          denoEntidad: getTrad("todos").toUpperCase(),
          codigoPedido: null,
          observaciones: null,
        });
      }
      return data;
    },
  });

  var datasource_spSelectPrendaNPedido_rutasExpedicion = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infRutasExpedicion_spSelectPrendaNPedido_rutasExpedicion",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var filtro_dxCalendar_fecha = $("#filtro_dxCalendar_fecha").dxCalendar(
          "instance",
        );
        var filtro_dxCalendar_fecha_value =
          typeof filtro_dxCalendar_fecha !== undefined &&
          filtro_dxCalendar_fecha.option("value")
            ? formatDate_parameter(
                $("#filtro_dxCalendar_fecha")
                  .dxCalendar("instance")
                  .option("value"),
              )
            : null;
        var dxDataGrid_selPedido = $("#dxDataGrid_selPedido").dxDataGrid(
          "instance",
        );
        var dxDataGrid_PedidosNEntidad = $(
          "#dxDataGrid_PedidosNEntidad",
        ).dxDataGrid("instance");

        var idPedido = null;
        var idRutaExp = null;
        var fecha = null;

        if (dxDataGrid_PedidosNEntidad.option("selectedRowKeys").length > 0) {
          if (dxDataGrid_PedidosNEntidad.option("selectedRowKeys")[0] === 0) {
            idRutaExp = dxDataGrid_selPedido.option("selectedRowKeys")[0];
            fecha = filtro_dxCalendar_fecha_value;
          } else {
            idPedido = dxDataGrid_PedidosNEntidad.option("selectedRowKeys")[0];
          }
        }

        request.params.idPedido = idPedido;
        request.params.idRutaExp = idRutaExp;
        request.params.fecha = fecha;
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });
  //#endregion

  var enum_informes = [
    {
      text: getTrad("porCompañia"),
      value: 1,
    },
    {
      text: getTrad("porRuta"),
      value: 2,
    },
  ];

  //#endregion

  COMPONENT.setReport({
    denominacion: "infRutasExpedicion",
    parameterEvent: CustomizeParameterEditors,
  });

  $("#InfRutasExpedicion #dxContainer").dxBox({
    direction: "col",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
    items: [
      {
        ratio: 1,
        template: function (e, index, item) {
          var tabpanel = $("<div id='tabpanel-container'/>")
            .addClass("no-header")
            .dxTabPanel({
              height: "100%",
              width: "100%",
              scrollByContent: true,
              scrollingEnabled: true,
              animationEnabled: true,
              items: [
                {
                  title: getTrad("seleccioneTipoInforme"),
                  template: function () {
                    return $("<div />").dxBox({
                      direction: "col",
                      align: "space-around",
                      crossAlign: "stretch",
                      height: "100%",
                      items: [
                        {
                          baseSize: 50,
                          shrink: 0,
                          ratio: 0,
                          template: function () {
                            return $("<div />").dxToolbar({
                              items: [
                                {
                                  location: "after",
                                  widget: "dxButton",
                                  options: {
                                    elementAttr: {
                                      id: "dxButton_cargar",
                                      class: "dx-button-iconRight",
                                    },
                                    text: getTrad("cargar"),
                                    icon: "chevronright",
                                    type: "success",
                                    disabled: true,
                                    onClick: function () {
                                      var tabpanelContainer = $(
                                        "#tabpanel-container",
                                      ).dxTabPanel("instance");
                                      var dxDataGrid_PedidosNEntidad = $(
                                        "#dxDataGrid_PedidosNEntidad",
                                      ).dxDataGrid("instance");
                                      var dxDataGrid_spSelectPrendaNPedido = $(
                                        "#dxDataGrid_spSelectPrendaNPedido",
                                      ).dxDataGrid("instance");
                                      var filtro_dxList_informe = $(
                                        "#filtro_dxList_informe",
                                      ).dxList("instance");

                                      COMPONENT.loadPanel_show(true);
                                      if (
                                        typeof dxDataGrid_PedidosNEntidad !==
                                        "undefined"
                                      ) {
                                        switch (
                                          filtro_dxList_informe.option(
                                            "selectedItemKeys",
                                          )[0]
                                        ) {
                                          case 1: // POR COMPAÑÍA
                                            dxDataGrid_PedidosNEntidad.option(
                                              "dataSource",
                                              datasource_PedidosNEntidadNCompañia,
                                            );
                                            dxDataGrid_spSelectPrendaNPedido.option(
                                              "dataSource",
                                              datasource_spSelectPrendaNPedido_compañias,
                                            );
                                            break;
                                          case 2: // POR RUTA
                                            dxDataGrid_PedidosNEntidad.option(
                                              "dataSource",
                                              datasource_PedidosNEntidadNRutasExpedicion,
                                            );
                                            dxDataGrid_spSelectPrendaNPedido.option(
                                              "dataSource",
                                              datasource_spSelectPrendaNPedido_rutasExpedicion,
                                            );
                                            break;
                                          default:
                                            break;
                                        }
                                      }
                                      tabpanelContainer.option(
                                        "selectedIndex",
                                        tabpanelContainer.option(
                                          "selectedIndex",
                                        ) + 1,
                                      );
                                    },
                                  },
                                },
                              ],
                            });
                          },
                        },
                        {
                          ratio: 1,
                          template: function () {
                            return $("<div id='dxBox_Filtros' />").dxBox({
                              direction: "row",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              width: "100%",
                              items: [
                                {
                                  //INFORMES
                                  ratio: 1,
                                  template: function () {
                                    return $(
                                      "<div id='dxBox_Filtros_fecha'/>",
                                    ).dxBox({
                                      direction: "col",
                                      align: "space-around",
                                      crossAlign: "stretch",
                                      height: "100%",
                                      items: [
                                        {
                                          baseSize: 45,
                                          shrink: 0,
                                          ratio: 0,
                                          template: function () {
                                            return $(
                                              "<div class='font-size' style='margin-left:9px;'>1. " +
                                                getTrad("seleccioneInforme") +
                                                "</div>",
                                            );
                                          },
                                        },
                                        {
                                          ratio: 1,
                                          template: function () {
                                            return $(
                                              "<div id='filtro_dxList_informe'>",
                                            ).dxList({
                                              dataSource: enum_informes,
                                              focusStateEnabled: false,
                                              activeStateEnabled: false,
                                              selectionMode: "single",
                                              keyExpr: "value",
                                              scrolling: { mode: "infinite" },
                                              pageLoadMode: "scrollBottom",
                                              onSelectionChanged: function (
                                                selection,
                                              ) {
                                                var dxBox_Filtros =
                                                  $("#dxBox_Filtros").dxBox(
                                                    "instance",
                                                  );
                                                dxBox_Filtros.option(
                                                  "items[1].visible",
                                                  true,
                                                );
                                                dxBox_Filtros.repaint();
                                                var dxDataGrid_selPedido = $(
                                                  "#dxDataGrid_selPedido",
                                                ).dxDataGrid("instance");
                                                switch (
                                                  selection.addedItems[0].value
                                                ) {
                                                  case 1: // POR COMPAÑÍA
                                                    dxDataGrid_selPedido.option(
                                                      "dataSource",
                                                      datasource_entidadesConPedidos_compañias,
                                                    );
                                                    break;
                                                  case 2: // POR RUTA
                                                    dxDataGrid_selPedido.option(
                                                      "dataSource",
                                                      datasource_entidadesConPedidos_rutasExpedicion,
                                                    );
                                                    break;
                                                  default:
                                                    break;
                                                }
                                              },
                                              itemTemplate: function (
                                                data,
                                                index,
                                              ) {
                                                var result = $(
                                                  "<div class='container_spanCentrado' style='height:250px;'>",
                                                );
                                                $("<div class='font-size'>")
                                                  .text(data.text.toUpperCase())
                                                  .appendTo(result);
                                                return result;
                                              },
                                            });
                                          },
                                        },
                                      ],
                                    });
                                  },
                                },
                                {
                                  // SELECTOR FECHA
                                  ratio: 1,
                                  visible: false,
                                  template: function () {
                                    return $(
                                      "<div id='dxBox_Filtros_fecha'  style='margin-right:15px;'/>",
                                    ).dxBox({
                                      direction: "col",
                                      height: "100%",
                                      width: "100%",
                                      align: "center",
                                      crossAlign: "center",
                                      items: [
                                        {
                                          baseSize: 45,
                                          shrink: 0,
                                          ratio: 0,
                                          template: function () {
                                            return $(
                                              "<div class='font-size'>",
                                            ).text(
                                              "2. " +
                                                getTrad("seleccioneFecha"),
                                            );
                                          },
                                        },
                                        {
                                          ratio: 1,
                                          template: function () {
                                            return $(
                                              "<div id='filtro_dxCalendar_fecha'>",
                                            ).dxCalendar({
                                              max: convertClienteUtcToLavanderiaUtc(
                                                LAVANDERIA.GMT,
                                                new Date(),
                                              ),
                                              firstDayOfWeek: 1,
                                              //EVENTOS
                                              onValueChanged: function (e) {
                                                let dxDataGrid_selPedido = $(
                                                  "#dxDataGrid_selPedido",
                                                ).dxDataGrid("instance");
                                                dxDataGrid_selPedido
                                                  .refresh(true)
                                                  .done(function () {
                                                    var dxBox_Filtros =
                                                      $("#dxBox_Filtros").dxBox(
                                                        "instance",
                                                      );
                                                    if (
                                                      dxBox_Filtros.option(
                                                        "items[2].visible",
                                                      ) === false
                                                    ) {
                                                      dxBox_Filtros.option(
                                                        "items[2].visible",
                                                        true,
                                                      );
                                                      dxBox_Filtros.repaint();
                                                    }
                                                    dxDataGrid_selPedido.repaint();
                                                    dxDataGrid_selPedido.updateDimensions();
                                                  });
                                              },
                                            });
                                          },
                                        },
                                      ],
                                    });
                                  },
                                },
                                {
                                  // GRID PEDIDOS
                                  ratio: 2,
                                  visible: false,
                                  template: function () {
                                    return $(
                                      "<div id='dxBox_Filtros_pedidos'  style='margin-right:15px;'/>",
                                    ).dxBox({
                                      direction: "col",
                                      height: "100%",
                                      width: "100%",
                                      align: "space-around",
                                      crossAlign: "stretch",
                                      items: [
                                        {
                                          baseSize: 45,
                                          shrink: 0,
                                          ratio: 0,
                                          template: function () {
                                            return $(
                                              "<div class='font-size'>",
                                            ).text(
                                              "3. " +
                                                getTrad("seleccionePedido"),
                                            );
                                          },
                                        },
                                        {
                                          ratio: 1,
                                          template: function () {
                                            return $(
                                              "<div id='dxDataGrid_selPedido' />",
                                            ).dxDataGrid({
                                              //Datos
                                              remoteOperations: false,
                                              //Propiedades
                                              height: "100%",
                                              columnsAutoWidth: true,
                                              headerFilter: {
                                                visible: true,
                                              },
                                              filterRow: {
                                                visible: true,
                                                applyFilter: "auto",
                                              },
                                              paging: {
                                                enabled: false,
                                              },
                                              selection: {
                                                mode: "single",
                                              },
                                              columns: [
                                                {
                                                  dataField: "denominacion",
                                                  caption: getTrad("compañia"),
                                                  allowHeaderFiltering: false,
                                                  alignment: "left",
                                                },
                                                {
                                                  dataField:
                                                    "entidadesConPedido",
                                                  name: "entidadesConPedido",
                                                  caption: getTrad("pedidos"),
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  width: 65,
                                                  alignment: "center",
                                                },
                                                {
                                                  dataField: "totalBacs",
                                                  caption: "Bacs",
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  width: "14%",
                                                  alignment: "center",
                                                  calculateDisplayValue:
                                                    function (e) {
                                                      if (e.totalBacs <= 0)
                                                        return "-";
                                                      return formatNumber(
                                                        e.totalBacs,
                                                        0,
                                                        "decimal",
                                                      );
                                                    },
                                                },
                                                {
                                                  dataField: "carros",
                                                  caption: getTrad("carros"),
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  width: "12%",
                                                  alignment: "center",
                                                  calculateDisplayValue:
                                                    function (e) {
                                                      if (e.carros <= 0)
                                                        return "-";
                                                      return formatNumber(
                                                        e.carros,
                                                        2,
                                                        "decimal",
                                                      );
                                                    },
                                                },
                                                {
                                                  dataField: "peso",
                                                  caption: getTrad("peso"),
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  width: "17%",
                                                  alignment: "center",
                                                  calculateDisplayValue:
                                                    function (e) {
                                                      if (e.peso <= 0)
                                                        return "-";
                                                      return formatNumber(
                                                        e.peso,
                                                        2,
                                                        "decimal",
                                                      );
                                                    },
                                                },
                                              ],
                                              summary: {
                                                totalItems: [
                                                  {
                                                    column: "denominacion",
                                                    displayFormat:
                                                      getTrad(
                                                        "total",
                                                      ).toUpperCase(),
                                                  },
                                                  {
                                                    column:
                                                      "entidadesConPedido",
                                                    name: "entidadesConPedido",
                                                    showInColumn:
                                                      "entidadesConPedido",
                                                    summaryType: "custom",
                                                    displayFormat: "{0}",
                                                  },
                                                  {
                                                    column: "totalBacs",
                                                    summaryType: "sum",
                                                    customizeText: function (
                                                      data,
                                                    ) {
                                                      return formatNumber(
                                                        data.value,
                                                        0,
                                                        "decimal",
                                                      );
                                                    },
                                                    displayFormat: "{0}",
                                                  },
                                                  {
                                                    column: "carros",
                                                    summaryType: "sum",
                                                    customizeText: function (
                                                      data,
                                                    ) {
                                                      return formatNumber(
                                                        data.value,
                                                        2,
                                                        "decimal",
                                                      );
                                                    },
                                                    displayFormat: "{0}",
                                                  },
                                                  {
                                                    column: "peso",
                                                    summaryType: "sum",
                                                    customizeText: function (
                                                      data,
                                                    ) {
                                                      return (
                                                        formatNumber(
                                                          data.value,
                                                          2,
                                                          "decimal",
                                                        ) + " Kg."
                                                      );
                                                    },
                                                  },
                                                ],
                                                calculateCustomSummary:
                                                  function (options) {
                                                    if (
                                                      options.name ===
                                                      "entidadesConPedido"
                                                    ) {
                                                      if (
                                                        options.summaryProcess ===
                                                        "start"
                                                      ) {
                                                        options.totalValue = 0;
                                                      }
                                                      if (
                                                        options.summaryProcess ===
                                                        "calculate"
                                                      ) {
                                                        let valueArray =
                                                          options.value.split(
                                                            "/",
                                                          );
                                                        options.totalValue +=
                                                          parseInt(
                                                            valueArray[0],
                                                          );
                                                      }
                                                    }
                                                  },
                                              },
                                              // EVENTOS
                                              onSelectionChanged: function (e) {
                                                selCompaRuta =
                                                  e.selectedRowsData[0];
                                                var dxButton_cargar =
                                                  $(
                                                    "#dxButton_cargar",
                                                  ).dxButton("instance");

                                                if (
                                                  e.component.option(
                                                    "selectedRowKeys",
                                                  ).length > 0
                                                ) {
                                                  dxButton_cargar.option(
                                                    "disabled",
                                                    false,
                                                  );
                                                } else {
                                                  dxButton_cargar.option(
                                                    "disabled",
                                                    true,
                                                  );
                                                }
                                              },
                                              showColumnLines: false,
                                              showRowLines: true,
                                              rowAlternationEnabled: true,
                                            });
                                          },
                                        },
                                      ],
                                    });
                                  },
                                },
                              ],
                            });
                          },
                        },
                      ],
                    });
                  },
                },
                {
                  title: getTrad("listadoPedidos"),
                  visible: false,
                  template: function () {
                    return $("<div id='dxBox_Pedidos'/>").dxBox({
                      direction: "col",
                      align: "space-around",
                      crossAlign: "stretch",
                      height: "100%",
                      items: [
                        {
                          baseSize: 50,
                          shrink: 0,
                          ratio: 0,
                          template: function () {
                            return $("<div />").dxToolbar({
                              items: [
                                {
                                  location: "before",
                                  widget: "dxButton",
                                  options: {
                                    text: getTrad("volverFiltrar"),
                                    icon: "chevronleft",
                                    onClick: function () {
                                      var tabpanel = $(
                                        "#tabpanel-container",
                                      ).dxTabPanel("instance");
                                      tabpanel.option("selectedIndex", 0);

                                      $("#dxDataGrid_PedidosNEntidad")
                                        .dxDataGrid("instance")
                                        .clearSelection();
                                      $("#dxDataGrid_PedidosNEntidad")
                                        .dxDataGrid("instance")
                                        .refresh(true);
                                      $("#dxDataGrid_spSelectPrendaNPedido")
                                        .dxDataGrid("instance")
                                        .refresh(true);
                                    },
                                  },
                                },
                                {
                                  location: "after",
                                  widget: "dxButton",
                                  locateInMenu: "auto",
                                  options: {
                                    text: getTrad("imprimir"),
                                    icon: " icon_Impresora",
                                    onClick: function () {
                                      var dxDataGrid_PedidosNEntidad = $(
                                        "#dxDataGrid_PedidosNEntidad",
                                      ).dxDataGrid("instance");
                                      var dxDataGrid_PedidosNEntidad_SelRow =
                                        dxDataGrid_PedidosNEntidad.option(
                                          "selectedRowKeys",
                                        );
                                      var dxDataGrid_selPedido_SelRowKey = $(
                                        "#dxDataGrid_selPedido",
                                      )
                                        .dxDataGrid("instance")
                                        .option("selectedRowKeys")[0];

                                      var idLavanderia =
                                        LAVANDERIA.idLavanderia;
                                      var idPedido = null;
                                      var idCompañia = null;
                                      var idRutaExp = null;
                                      var fecha = $("#filtro_dxCalendar_fecha")
                                        .dxCalendar("instance")
                                        .option("value");
                                      var denoEntidad = [];

                                      if (
                                        dxDataGrid_PedidosNEntidad_SelRow.length >
                                        0
                                      ) {
                                        switch (
                                          $("#filtro_dxList_informe")
                                            .dxList("instance")
                                            .option("selectedItemKeys")[0]
                                        ) {
                                          case 1: // POR COMPAÑÍA
                                            idCompañia =
                                              dxDataGrid_selPedido_SelRowKey;
                                            idPedido =
                                              dxDataGrid_PedidosNEntidad_SelRow[0];
                                            break;
                                          case 2: // POR RUTA
                                            idRutaExp =
                                              dxDataGrid_selPedido_SelRowKey;
                                            idPedido =
                                              dxDataGrid_PedidosNEntidad_SelRow[0];
                                            break;
                                          default:
                                            break;
                                        }
                                        if (idPedido === 0) {
                                          $.each(
                                            dxDataGrid_PedidosNEntidad.getVisibleRows(),
                                            function (index, item) {
                                              if (
                                                denoEntidad.indexOf(
                                                  item.data.denoEntidad,
                                                ) === -1 &&
                                                item.data.idPedido !== 0
                                              ) {
                                                denoEntidad.push(
                                                  item.data.denoEntidad,
                                                );
                                              }
                                            },
                                          );
                                        } else {
                                          denoEntidad.push(
                                            dxDataGrid_PedidosNEntidad.getSelectedRowsData()[0]
                                              .denoEntidad,
                                          );
                                        }
                                        var params = {
                                          idPedido: idPedido,
                                          idCompañia: idCompañia,
                                          idRutaExp: idRutaExp,
                                          fecha: formatDate_parameter(fecha),
                                          idLavanderia: idLavanderia,
                                          denoCompaRuta:
                                            selCompaRuta.denominacion,
                                          entidades: denoEntidad
                                            .sort()
                                            .join(","),
                                        };
                                        COMPONENT.setParams(params);
                                      } else {
                                        notify({
                                          message: getTrad("seleccionePedido"),
                                          type: "error",
                                          displayTime: "1500",
                                          closeOnClick: true,
                                        });
                                      }
                                    },
                                  },
                                },
                              ],
                            });
                          },
                        },
                        {
                          ratio: 1,
                          template: function () {
                            return $("<div  />").dxBox({
                              direction: "row",
                              align: "space-around",
                              crossAlign: "stretch",
                              width: "100%",
                              height: "100%",
                              items: [
                                {
                                  //INFORMES
                                  ratio: 4,
                                  template: function () {
                                    return $("<div />").dxBox({
                                      direction: "col",
                                      align: "space-around",
                                      crossAlign: "stretch",
                                      height: "100%",
                                      items: [
                                        {
                                          baseSize: 32,
                                          shrink: 0,
                                          ratio: 0,
                                          template: function () {
                                            return $(
                                              "<div class='font-size' style='margin-left:9px;'>" +
                                                getTrad("listadoPedidos") +
                                                "</div>",
                                            ); //getTrad('listadoPedidos')
                                          },
                                        },
                                        {
                                          ratio: 1,
                                          template: function () {
                                            return $(
                                              "<div id='dxDataGrid_PedidosNEntidad' style='margin-right:15px;' />",
                                            ).dxDataGrid({
                                              //Datos
                                              dataSource:
                                                $("#filtro_dxList_informe")
                                                  .dxList("instance")
                                                  .option(
                                                    "selectedItemKeys",
                                                  )[0] === 1
                                                  ? datasource_PedidosNEntidadNCompañia
                                                  : datasource_PedidosNEntidadNRutasExpedicion,
                                              remoteOperations: true,
                                              //Propiedades
                                              columnsAutoWidth: true,
                                              height: "100%",
                                              headerFilter: {
                                                visible: true,
                                              },
                                              filterRow: {
                                                visible: true,
                                                applyFilter: "auto",
                                              },
                                              paging: {
                                                enabled: false,
                                              },
                                              selection: {
                                                mode: "single",
                                              },
                                              columns: [
                                                {
                                                  dataField: "observaciones",
                                                  visible: false,
                                                },
                                                {
                                                  dataField: "denoEntidad",
                                                  caption: getTrad("entidad"),
                                                  allowHeaderFiltering: false,
                                                  alignment: "left",
                                                  allowSorting: false,
                                                },
                                                {
                                                  dataField: "codigoPedido",
                                                  caption: getTrad("nPedido"),
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  width: "25%",
                                                  allowSorting: false,
                                                  sortOrder: "desc",
                                                  alignment: "center",
                                                },
                                              ],
                                              // EVENTOS
                                              onContentReady: function (e) {
                                                if (
                                                  e.component.option(
                                                    "selectedRowKeys",
                                                  ).length === 0
                                                ) {
                                                  e.component.selectRowsByIndexes(
                                                    [0],
                                                  );
                                                }
                                              },
                                              onSelectionChanged: function (e) {
                                                var observacionesPedido =
                                                  typeof e
                                                    .selectedRowsData[0] !==
                                                  "undefined"
                                                    ? e.selectedRowsData[0]
                                                        .observaciones
                                                    : "";
                                                $(
                                                  "#dxDataGrid_spSelectPrendaNPedido",
                                                )
                                                  .dxDataGrid("instance")
                                                  .refresh(true);
                                                $("#taObservaciones")
                                                  .dxTextArea("instance")
                                                  .option(
                                                    "value",
                                                    observacionesPedido,
                                                  );
                                              },
                                              showColumnLines: false,
                                              showRowLines: true,
                                              rowAlternationEnabled: true,
                                            });
                                          },
                                        },
                                      ],
                                    });
                                  },
                                },
                                {
                                  //INFORMES
                                  ratio: 6,
                                  template: function () {
                                    return $("<div />").dxBox({
                                      direction: "col",
                                      align: "space-around",
                                      crossAlign: "stretch",
                                      height: "100%",
                                      items: [
                                        {
                                          baseSize: 32,
                                          shrink: 0,
                                          ratio: 0,
                                          template: function () {
                                            return $(
                                              "<div class='font-size' style='margin-left:9px;'>" +
                                                getTrad("articulosExpedir") +
                                                "</div>",
                                            );
                                          },
                                        },
                                        {
                                          ratio: 1,
                                          template: function () {
                                            return $(
                                              "<div id='dxDataGrid_spSelectPrendaNPedido' />",
                                            ).dxDataGrid({
                                              //Datos
                                              dataSource:
                                                $("#filtro_dxList_informe")
                                                  .dxList("instance")
                                                  .option(
                                                    "selectedItemKeys",
                                                  )[0] === 1
                                                  ? datasource_spSelectPrendaNPedido_compañias
                                                  : datasource_spSelectPrendaNPedido_rutasExpedicion,
                                              //Propiedades
                                              height: "100%",
                                              columnsAutoWidth: true,
                                              headerFilter: {
                                                visible: true,
                                              },
                                              filterRow: {
                                                visible: true,
                                                applyFilter: "auto",
                                              },
                                              paging: {
                                                enabled: false,
                                              },
                                              columns: [
                                                {
                                                  dataField: "codigoPrenda",
                                                  caption: getTrad("codigo"),
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  width: "10%",
                                                  sortOrder: "asc",
                                                },
                                                {
                                                  dataField: "denoPrenda",
                                                  caption:
                                                    getTrad("denominacion"),
                                                  allowHeaderFiltering: false,
                                                  alignment: "left",
                                                  width: "50%",
                                                },
                                                {
                                                  dataField: "totalBacs",
                                                  caption: getTrad("totalBacs"),
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  width: "13%",
                                                  alignment: "center",
                                                  format: {
                                                    style: "decimal",
                                                    maximumFractionDigits: 0,
                                                  },
                                                },
                                                {
                                                  dataField: "carros",
                                                  caption: getTrad("carros"),
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  width: "14%",
                                                  alignment: "center",
                                                  format: {
                                                    style: "decimal",
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  },
                                                },
                                                {
                                                  dataField: "peso",
                                                  caption: getTrad("peso"),
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  width: "16%",
                                                  alignment: "center",
                                                  format: {
                                                    style: "decimal",
                                                    minimumFractionDigits: 2,
                                                  },
                                                },
                                              ],
                                              summary: {
                                                totalItems: [
                                                  {
                                                    column: "codigoPrenda",
                                                    displayFormat:
                                                      getTrad(
                                                        "total",
                                                      ).toUpperCase(),
                                                  },
                                                  {
                                                    column: "totalBacs",
                                                    summaryType: "sum",
                                                    displayFormat: "{0}",
                                                    valueFormat: {
                                                      style: "decimal",
                                                      maximumFractionDigits: 0,
                                                    },
                                                  },
                                                  {
                                                    column: "carros",
                                                    summaryType: "sum",
                                                    displayFormat: "{0}",
                                                    valueFormat: {
                                                      style: "decimal",
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    },
                                                  },
                                                  {
                                                    column: "peso",
                                                    name: "peso",
                                                    summaryType: "custom",
                                                    customizeText: function (
                                                      data,
                                                    ) {
                                                      return (
                                                        formatNumber(
                                                          data.value,
                                                          2,
                                                          "decimal",
                                                        ) + " Kg"
                                                      );
                                                    },
                                                  },
                                                ],
                                                calculateCustomSummary:
                                                  function (options) {
                                                    if (
                                                      options.name === "peso"
                                                    ) {
                                                      if (
                                                        options.summaryProcess ===
                                                        "start"
                                                      ) {
                                                        options.totalValue = 0;
                                                      }
                                                      if (
                                                        options.summaryProcess ===
                                                        "calculate"
                                                      ) {
                                                        options.totalValue +=
                                                          options.value;
                                                      }
                                                    }
                                                  },
                                              },
                                              showColumnLines: false,
                                              showRowLines: true,
                                              rowAlternationEnabled: true,
                                            });
                                          },
                                        },
                                        {
                                          baseSize: 140,
                                          shrink: 0,
                                          ratio: 0,
                                          template: function () {
                                            var container = $("<div />");
                                            $(
                                              "<div class='font-size' style='margin-left:9px;padding-top:8px !important;margin-bottom:10px;'>" +
                                                getTrad("observaciones") +
                                                "</div>",
                                            ).appendTo(container); // TRAD
                                            $("<div id='taObservaciones' />")
                                              .dxTextArea({
                                                readOnly: true,
                                                height: "75px",
                                                width: "100%",
                                              })
                                              .appendTo(container);
                                            return container;
                                          },
                                        },
                                      ],
                                    });
                                  },
                                },
                              ],
                            });
                          },
                        },
                      ],
                    });
                  },
                },
              ],
              onSelectionChanged: function (e) {
                var tabpanel = e.component;
                if (tabpanel.option("selectedIndex") === 0) {
                  tabpanel.option("items[0].visible", true);
                  tabpanel.option("items[1].visible", false);
                } else {
                  tabpanel.option("items[0].visible", false);
                  tabpanel.option("items[1].visible", true);
                }
              },
            })
            .dxTabPanel("instance");

          item.append(tabpanel.element());
        },
      },
    ],
  });

  //IMPRESIÓN
  function CustomizeParameterEditors(report, s, e) {
    if (report === "infRutasExpedicion") {
      if (
        e.parameter.name === "idPedido" ||
        e.parameter.name === "idCompañia" ||
        e.parameter.name === "fecha" ||
        e.parameter.name === "idLavanderia" ||
        e.parameter.name === "idRutaExp" ||
        e.parameter.name === "entidades" ||
        e.parameter.name === "denoCompaRuta"
      ) {
        //INVISIBLES
        e.info.editor = $.extend({}, e.info.editor);
        e.info.visible = false;
      }
    }
  }

  deferred.resolve();
  return deferred.promise();
}
