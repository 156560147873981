import React, { createRef, useEffect } from "react";
import { DataGrid, Popup } from "devextreme-react";
import { connectionConstants } from "../../../../../constants";
import {
  authHeader,
  formatDateTime_parameter,
  formatDate_parameter,
  getTrad,
} from "helpers";
import ODataContext from "devextreme/data/odata/context";
import { Column, LoadPanel, Paging } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import $ from "jquery";

const PopupHistorico = ({
  visible,
  tipoHistorico,
  onHiding,
  idsRecambioHistorico = [],
  idAlmacenHistorico,
  fecha,
  fechaCierre,
  recambioSel = "",
  referenciaInternaSel = "",
  moneda,
}) => {
  const dataGrid = createRef();

  useEffect(() => {
    datasource_hitoricoRecambio.load();
  }, [tipoHistorico, idsRecambioHistorico, idAlmacenHistorico, fecha]);

  useEffect(() => {
    if (visible) {
      dataGrid.current.instance.option("dataSource", null);
    }
  }, [visible]);

  const postProcess_historicoRecambio = (data) => {
    let balances = {};

    data = data.filter((m) => {
      if (tipoHistorico === 1 && m.idTipoMovimientoRecambioOriginal === 1)
        return m;
      if (tipoHistorico === 2) return m;
      if (
        tipoHistorico === 3 &&
        [3, 6].includes(m.idTipoMovimientoRecambioOriginal)
      )
        return m;
    });

    if (tipoHistorico === 2) {
      data = data.map((m) => {
        let clave = `${m.idAlmacen}-${m.idRecambio}`;
        let cambio =
          m.idTipoMovimientoRecambio === 3 ? -m.cantidad : m.cantidad;

        if (m.idTipoMovimientoRecambioOriginal === 4) {
          balances[clave] = cambio;
        } else if (balances[clave]) {
          balances[clave] += cambio;
        } else {
          balances[clave] = cambio;
        }

        return { ...m, balance: balances[clave] };
      });
    }

    return data;
  };

  const context_hitoricoRecambio = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/Assistant",
    entities: {
      HistoricoRecambio: {
        key: ["idAlmacen", "idRecambio"],
        keyType: {
          idAlmacen: "Int32",
          idRecambio: "Int32",
        },
      },
    },
    beforeSend: (request) => {
      const fechaMod = new Date(fecha.setHours(23, 59, 59));

      if (fechaCierre)
        request.params.fechaCierre = formatDate_parameter(fechaCierre);
      request.headers = { ...authHeader() };
      request.params.idAlmacen = idAlmacenHistorico;
      request.params.fecha = formatDateTime_parameter(
        new Date(new Date(fechaMod).setHours(fechaMod.getUTCHours())),
      );
      request.params.todos = false;
      request.payload = idsRecambioHistorico;
    },
  });

  const datasource_hitoricoRecambio_load = () => {
    var _deferred = $.Deferred();

    context_hitoricoRecambio
      .invoke("HistoricoRecambio", {}, "POST")
      .done((data) => {
        _deferred.resolve(postProcess_historicoRecambio(data));
      });

    return _deferred.promise();
  };

  const datasource_hitoricoRecambio = new DataSource({
    paginate: false,
    store: new CustomStore({
      load: datasource_hitoricoRecambio_load,
    }),
  });

  const onRowPrepared = (e) => {
    if (e.rowType === "data") {
      if ([4, 7].includes(e.data.idTipoMovimientoRecambioOriginal)) {
        e.rowElement.css("background", "#FFEB9C");
      }
      if (e.data.balance < 0) {
        e.rowElement.css("background", "var(--danger)");
        e.rowElement.css("color", "white");
      }
    }
  };

  const groupCellRender_denoAlmacen = (data) => <div>{data.value}</div>;

  const calculateCellValue = (e, field) =>
    e[field] && e[field].length > 0 ? e[field] : "-";

  const tipoHistorico_denominacion = {
    1: "Entradas",
    2: "Histórico",
    3: "Salidas",
  };

  const currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: moneda,
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      title={`${tipoHistorico_denominacion[tipoHistorico] || ""}${referenciaInternaSel?.length > 0 ? " - " + referenciaInternaSel : ""} ${recambioSel?.length > 0 ? recambioSel : ""}`}
    >
      <DataGrid
        ref={dataGrid}
        dataSource={datasource_hitoricoRecambio}
        columnsAutoWidth={true}
        height={"100%"}
        width={"100%"}
        hoverStateEnabled={true}
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        onRowPrepared={onRowPrepared}
      >
        <Paging enabled={false} />
        <Column
          dataField={"denoAlmacen"}
          caption={getTrad("almacen")}
          groupIndex={0}
          groupCellRender={groupCellRender_denoAlmacen}
        />
        <Column
          dataField={"ID"}
          alignment={"center"}
          width={100}
          calculateCellValue={(e) => calculateCellValue(e, "ID")}
        />
        <Column
          dataField={"fecha"}
          caption={getTrad("fecha")}
          alignment={"center"}
          dataType={"datetime"}
          format={"dd/MM/yyyy HH:mm"}
          sortOrder={"asc"}
          width={150}
        />
        <Column
          dataField={"tipoMovimiento"}
          caption={getTrad("tipoMovimiento")}
          alignment={"center"}
        />
        <Column
          dataField={"origen"}
          caption={getTrad("origen")}
          calculateCellValue={(e) => calculateCellValue(e, "origen")}
        />
        <Column
          dataField={"destino"}
          caption={getTrad("destino")}
          calculateCellValue={(e) => calculateCellValue(e, "destino")}
        />
        <Column
          dataField={"denoLavanderia"}
          caption={getTrad("lavanderia")}
          alignment={"center"}
          width={150}
          visible={tipoHistorico !== 1}
        />
        <Column
          dataField={"cantidad"}
          caption={getTrad("cantidad")}
          dataType={"number"}
          alignment={"center"}
          width={100}
        />
        <Column
          dataField={"balance"}
          caption={getTrad("balance")}
          dataType={"number"}
          alignment={"center"}
          width={100}
          visible={tipoHistorico === 2}
        />
        <Column
          dataField={"precio"}
          caption={getTrad("precio")}
          dataType={"number"}
          format={currency_format}
          alignment={"center"}
          width={100}
        />
        <Column
          dataField={"importe"}
          caption={getTrad("importe")}
          dataType={"number"}
          format={currency_format}
          alignment={"center"}
          width={100}
        />
        <LoadPanel enabled={true} />
      </DataGrid>
    </Popup>
  );
};

export default PopupHistorico;
