import { globalConstants } from "../constants";
import { get_resolucion } from "../constants";
import config from "devextreme/core/config";

export const globalActions = {
    setLavanderia,
    setEmpresa,
    setIdioma,
    setResolucion,
    setFormularioActivo,
    setAplicacionActiva,
    setIdUsuarioOriginal,
};

function setLavanderia(lavanderia) {
    config({ defaultCurrency: lavanderia ? lavanderia.moneda : "EUR" });
    return (dispatch) => {
        dispatch(success(lavanderia));
    };
    function success(lavanderia) {
        return { type: globalConstants.SET_LAVANDERIA_SUCCESS, lavanderia };
    }
}

function setEmpresa(empresa) {
    return (dispatch) => {
        dispatch(success(empresa));
    };
    function success(empresa) {
        return { type: globalConstants.SET_EMPRESA_SUCCESS, empresa };
    }
}

function setIdioma(idioma) {
    return (dispatch) => {
        dispatch(success(idioma));
    };
    function success(idioma) {
        return { type: globalConstants.SET_IDIOMA_SUCCESS, idioma };
    }
}

function setResolucion(screen) {
    return (dispatch) => {
        dispatch(success(screen));
    };
    function success(screen) {
        let resolucion = get_resolucion(screen);

        return { type: globalConstants.SET_RESOLUCION_SUCCESS, resolucion };
    }
}

function setFormularioActivo(formularioActivo) {
    return (dispatch) => {
        dispatch(success(formularioActivo));
    };
    function success(formularioActivo) {
        return { type: globalConstants.SET_FORMULARIO_SUCCESS, formularioActivo };
    }
}

function setIdUsuarioOriginal(idUsuarioOriginal) {
    return (dispatch) => {
        dispatch(success(idUsuarioOriginal));
    };
    function success(idUsuarioOriginal) {
        return {
            type: globalConstants.SET_IDUSUARIO_ORIGINAL_SUCCESS,
            idUsuarioOriginal,
        };
    }
}

function setAplicacionActiva(aplicacionActiva, lavInicio) {
    return (dispatch) => {
        dispatch(success(aplicacionActiva));
        if (aplicacionActiva.idAplicacion !== 1) dispatch(globalActions.setLavanderia(null));
        else if (lavInicio != null) dispatch(globalActions.setLavanderia(lavInicio));
    };
    function success(aplicacionActiva) {
        return { type: globalConstants.SET_APLICACION_SUCCESS, aplicacionActiva };
    }
}
