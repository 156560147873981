import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import Box, { Item } from "devextreme-react/box";
import Button from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import { Row, Col } from "reactstrap";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { errorHandler, authHeader } from "helpers";
import DataGridRecambios from "./components/DataGridRecambios";
import ListAlmacenes from "./components/ListAlmacenes";
import { connectionConstants } from "constants";
import { getTrad } from "helpers";

const marginTitle = { marginLeft: "9px" };

const PopupAñadirRecambio = ({
  visible = false,
  parteTrabajoSel = null,
  onHiding,
  onSaveRecambiosNParteTrabajo,
  user,
}) => {
  const dataGrid = useRef(null);
  const [almacenSel, setAlmacenSel] = useState(null);
  const [almacenes_agrupados, setAlmacenes_agrupados] = useState([]);
  const [isVisible_PopupAñadirRecambio, setIsVisible_PopupAñadirRecambio] =
    useState(false);

  useEffect(() => {
    datasource_almacenes_agrupados.load();
  }, []);

  useEffect(() => {
    if (visible) {
      setIsVisible_PopupAñadirRecambio(true);
      setAlmacenSel(null);
    }
  }, [visible]);

  const datasource_almacenes_padre = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
    select: ["idAlmacen", "denominacion"],
    filter: [
      ["idAlmacenPadre", "=", null],
      "and",
      [["activo eq true"], "and", ["eliminado eq false"]],
    ],
  });

  const datasource_almacenes_agrupados = new DataSource({
    paginate: false,
    group: "idAlmacenPadre",
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
    sort: "denominacion asc",
    expand: "idMonedaNavigation($select=codigo)",
    filter: `tblAlmacenRecambiosNPersona/any(arnp: arnp/idPersona eq ${user.idPersona})`,
    postProcess: (data) => datasource_almacenes_agrupados_postProcess(data),
  });

  const datasource_almacenes_agrupados_postProcess = (data) => {
    const fomatData = (almP_final) => {
      data = data
        .filter((arP) => arP.key !== null)
        .map((arP) => {
          return {
            ...arP,
            denominacion: almP_final.find((ap) => ap.idAlmacen === arP.key)
              ?.denominacion,
          };
        });
      setAlmacenes_agrupados(data);
    };

    datasource_almacenes_padre.load().done((dataResult) => {
      fomatData(dataResult);
    });

    return data;
  };

  const onHiding_PopupAñadirRecambio = () => {
    dataGrid?.current?.instance?.cancelEditData();
    onHiding();
  };

  const onHidden_PopupAñadirRecambio = () =>
    setIsVisible_PopupAñadirRecambio(false);

  const onAddRecambiosNParteTrabajo_PopupAñadirRecambio = () => {
    const dataGridInstance = dataGrid.current.instance;

    if (dataGridInstance.hasEditData()) {
      dataGridInstance
        .getController("validating")
        .validate(true)
        .done((isValid) => {
          if (isValid) {
            let parteTrabajoSel_ = { ...parteTrabajoSel };
            const recambios = dataGridInstance.getVisibleRows();

            recambios.forEach(({ data }) => {
              if (data.cantidad > 0 && !data.is_añadido) {
                parteTrabajoSel_.tblRecambioNParteTrabajo.push({
                  idAlmacen: almacenSel.idAlmacen,
                  idAlmacenNavigation: {
                    idMonedaNavigation: almacenSel.idMonedaNavigation,
                    denominacion: almacenSel.denominacion,
                  },
                  idRecambio: data.idRecambio,
                  idParteTrabajo: parteTrabajoSel_.idParteTrabajo,
                  idRecambioNavigation: {
                    referencia: data.referencia,
                    denominacion: data.denominacion,
                  },
                  cantidad: data.cantidad,
                  precio: data.precioMedioPonderado,
                  importe: 0,
                });
              }
            });

            onSaveRecambiosNParteTrabajo(
              parteTrabajoSel_.tblRecambioNParteTrabajo,
            );
            onHiding();
          } else {
            notify({
              message: "Hay datos inválidos en los recambios",
              type: "error",
              displayTime: 1500,
              closeOnClick: true,
            });
          }
        });
    }
  };

  return isVisible_PopupAñadirRecambio ? (
    <Popup
      title={getTrad("añadirRecambios")}
      showTitle={true}
      width={1200}
      height={700}
      visible={visible}
      closeOnOutsideClick={false}
      onHiding={onHiding_PopupAñadirRecambio}
      onHidden={onHidden_PopupAñadirRecambio}
    >
      <div className={"d-flex flex-column he-100"}>
        <div className={"flex-grow-1"}>
          <Row className={"he-100"}>
            <Col xs={"12"} md={"4"}>
              <div className={"font-size"} style={marginTitle}>
                {getTrad("almacenes")}
              </div>
              <ListAlmacenes
                visible={visible}
                parteTrabajoSel={parteTrabajoSel}
                almacenes_agrupados={almacenes_agrupados}
                setAlmacenSel={setAlmacenSel}
              />
            </Col>
            <Col xs={"12"} md={"8"}>
              {almacenSel ? (
                <Box
                  direction={"col"}
                  width={"100%"}
                  height={"100%"}
                  align={"space-around"}
                  crossAlign={"stretch"}
                >
                  <Item baseSize={23}>
                    <div className={"font-size"} style={marginTitle}>
                      {getTrad("recambios")}
                    </div>
                  </Item>
                  <Item ratio={1}>
                    <DataGridRecambios
                      ref={dataGrid}
                      parteTrabajoSel={parteTrabajoSel}
                      almacenSel={almacenSel}
                    />
                  </Item>
                </Box>
              ) : (
                <div
                  className={
                    "he-100 w-100 d-flex align-items-center justify-content-center"
                  }
                >
                  <div className={"dx-datagrid-nodata"}>
                    {getTrad("seleccionaAlmacen")}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className={"text-right pt-3"}>
          <Button
            text={getTrad("añadir")}
            type={"success"}
            onClick={onAddRecambiosNParteTrabajo_PopupAñadirRecambio}
          />
        </div>
      </div>
    </Popup>
  ) : null;
};

const mapStateToProps = (state) => ({ user: state.Authentication.user });

export default connect(mapStateToProps)(PopupAñadirRecambio);
