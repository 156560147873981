import React from "react";

import Button from "devextreme-react/button";
import ScrollView from "devextreme-react/scroll-view";

import { Card, CardBody } from "reactstrap";

import { formatDate, formatNumber, getTrad } from "helpers";

const DisplayNominas = ({ conflictoNominaContrato, dataSource = [], setNominaSel, onClick_aceptarNominasGestoria }) => {
const sortedDataSource =dataSource.sort((a, b) => (b.totalDevengado - b.totalDeducido) - (a.totalDevengado - a.totalDeducido));

  const cardRender = (n, index) => {
    const hayConflictos = n.conceptosNominaNNomina.some(cng => cng.importe !== cng.importeA3);

    return <div
      key={index}
      className={"p-3 w-100"}
    >
      <Card
        className={`rounded-3 hover zoom pointer card-btm-border ${!conflictoNominaContrato || n.isContratoAsociado ? "border-primary" : "border-info"}`}
        onClick={() => setNominaSel(n)}
      >
        <CardBody className={"px-5"}>
          <div className={"d-flex justify-content-between align-items-center"}>
            <div>
              <p className={`mb-1 p-0 font-size-xl font-weight-bold ${!conflictoNominaContrato || n.isContratoAsociado ? "text-primary" : "text-info"}`}>{n.tipoPaga}</p>
              {conflictoNominaContrato
                ? <>
                  <p className={`font-size mr-4 mb-1 p-0`}>{formatDate(new Date(n.fechaDesde))}</p>
                  <p className={`font-size mr-4 mb-1`}>{formatDate(new Date(n.fechaHasta))}</p>
                </>
                : <p className={`font-size mr-4 mb-1 p-0`}>{formatDate(new Date(n.fechaDesde))} - {formatDate(new Date(n.fechaHasta))}</p>
              }
              <p className={"mb-0"}>{getTrad("devengado")}: {formatNumber(n.totalDevengado, 2, "currency", "EUR")}</p>
              <p className={"mb-0"}>{getTrad("deducido")}: {formatNumber(n.totalDeducido, 2, "currency", "EUR")}</p>
            </div>
            <p className={`m-0 p-0 font-size-xxl ${hayConflictos ? "text-danger" : ""}`}>{formatNumber(n.totalDevengado - n.totalDeducido, 2, "currency", "EUR")}</p>
          </div>
        </CardBody>
      </Card>
    </div>
  }

  return (
    <ScrollView height={640}>
      <div>
        {conflictoNominaContrato
          ? <div className={"w-100 d-flex justify-content-end px-3"}>
            <div className={"px-3"}>
              <Button
                icon={"check"}
                text={getTrad("aceptarDatosGestoria")}
                onClick={onClick_aceptarNominasGestoria}
              />
            </div>
          </div>
          : null
        }
      </div>
      <div className={"d-flex w-100"}>
        {conflictoNominaContrato
          ? <>
            <div className={"d-flex flex-row flex-wrap justify-content-between he-100 w-50 px-3"}>
              <p className={"font-size-xxl m-o pl-3 pb-0"}>{getTrad("polarier")}</p>
              {sortedDataSource.filter(n => n.isContratoAsociado).map(cardRender)}
            </div>
            <div className={"d-flex flex-row flex-wrap justify-content-between he-100 w-50 px-3"}>
              <p className={"font-size-xxl m-o pl-3 pb-0"}>{getTrad("gestoria")}</p>
              {sortedDataSource.filter(n => !n.isContratoAsociado).map(cardRender)}
            </div>
          </>
          : <div className={"d-flex flex-row flex-wrap justify-content-between he-100 w-100 px-3"}>
            {sortedDataSource.map(cardRender)}
          </div>
        }
      </div>
    </ScrollView>
  );
};

export default DisplayNominas;
