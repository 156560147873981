import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import ArrayStore from "devextreme/data/array_store";
import DataGrid, {
  Column,
  CustomRule,
  Editing,
  KeyboardNavigation,
  Paging,
  Sorting,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import LoadPanel from "devextreme-react/load-panel";
import ODataStore from "devextreme/data/odata/store";

import { addAuthHeader } from "../../utils";
import { connectionConstants } from "constants";
import { errorHandler, getTrad } from "helpers";
import { removeCellComponent } from "components/DataGrid/Cells";
import { sharedFormatCurrency } from "../../constants";
import notify from "devextreme/ui/notify";

const elementAttr = { id: "dxDataGrid_tblRecambioNMovimientoRecambio" };

const position = { my: "center", at: "center", of: `#${elementAttr.id}` };

const SharedDataGridRecambios = forwardRef(
  (
    {
      movimientoRecambio,
      codigoMoneda,
      idProveedorFilter,
      allowCantidad0 = false,
      isSending,
      allowEditingPrecio = false,
      disableCantidadRule = false,
      setHasChangesForm,
      onChange,
      children,
    },
    ref
  ) => {
    const dataGridRecambiosRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [tblRecambioNMovimientoRecambio, setTblRecambioNMovimientoRecambio] =
      useState([]);

    const { idMovimientoRecambio, isEditable } = movimientoRecambio;

    const this_onChange = (newTblRecambioNMovimientoRecambio) => {
      setTblRecambioNMovimientoRecambio(newTblRecambioNMovimientoRecambio);
      onChange(newTblRecambioNMovimientoRecambio);
    };

    useEffect(() => {
      if (!isSending) {
        this_onChange([]);
        if (idMovimientoRecambio) {
          setIsLoading(true);
          dataSource_movimientoRecambio.reload().done((data) => {
            this_onChange(data);
            setIsLoading(false);
          });
        }
      }
    }, [idMovimientoRecambio]);

    useImperativeHandle(
      ref,
      () => ({
        setIsLoading,
        clear: () => this_onChange([]),
        getDataSource: () =>
          dataGridRecambiosRef?.current?.instance?.getDataSource()?.items() ??
          [],
        onAddRecambio: (newRecambio) =>
          setTblRecambioNMovimientoRecambio((prev) => [newRecambio, ...prev]),
      }),
      []
    );

    const dataSource_onLoading = (loadOptions) => {
      if (idProveedorFilter) {
        loadOptions.filter = ["idProveedor", "=", idProveedorFilter];
      }
    };

    const dataSource = new DataSource({
      store: new ArrayStore({
        key: "idRecambio",
        data: tblRecambioNMovimientoRecambio,
        onLoading: dataSource_onLoading,
      }),
    });

    const editorOptions = {
      cantidad: {
        step: 0,
        format: { style: "decimal", maximumFractionDigits: 0 },
        min: allowCantidad0 ? 0 : 1,
        max: 999999,
      },
      moneda: {
        format: sharedFormatCurrency(codigoMoneda),
        step: 0,
        min: 0,
        max: 999999.99,
      },
    };

    const onRowRemoving = (e) => {
      const { data } = e;

      if (data.min) {
        e.cancel = true;

        notify({
          message: `${getTrad("errorEliminar_recambioConCantidadMinima")} ${
            data.min
          } ${getTrad("unidades").toLowerCase()}.`,
          type: "error",
          displayTime: 2000,
          closeOnClick: true,
        });

        return;
      }

      const newTblRecambioNMovimientoRecambio =
        tblRecambioNMovimientoRecambio.filter(
          (r) => r.idRecambio !== data.idRecambio
        );

      this_onChange(newTblRecambioNMovimientoRecambio);
    };

    const onToolbarPreparing = ({ toolbarOptions }) => {
      toolbarOptions.items.map((item) => {
        if (item.name == "saveButton" || item.name == "revertButton") {
          item.visible = false;
        }
      });
    };

    const onEditingStart = () => setHasChangesForm(true);

    const validationCallback_CustomRule_MaxVal = ({ data, rule }) => {
      rule.message = `${getTrad("alerta_cantidadExcedida")} ${
        data.max
      } ${getTrad("unidades").toLowerCase()}.`;

      if (data.max !== null) {
        return data.cantidad <= data.max;
      }

      return true;
    };

    const validationCallback_CustomRule_MinVal = ({ data, rule }) => {
      if (disableCantidadRule) {
        return true;
      }

      if (data.min !== null) {
        rule.message = `${getTrad("alerta_cantidadMinima")} ${
          data.min
        } ${getTrad("unidades").toLowerCase()}.`;
        return data.cantidad >= data.min;
      }

      rule.message = getTrad("aviso_insertarUnRecambio");

      return data.cantidad !== 0;
    };

    const dataSource_movimientoRecambio_beforeSend = (request) => {
      request.headers = addAuthHeader(request.headers);

      request.params.idMovimientoRecambio =
        movimientoRecambio.idMovimientoRecambio;
    };

    const dataSource_movimientoRecambio = new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.WEB_API_CORE_ODATA_URL +
          "MyPolarier/Assistant/MovimientoRecambio/GetRecambios_MovimientoRecambioCreado",
        key: "idRecambio",
        errorHandler,
        beforeSend: dataSource_movimientoRecambio_beforeSend,
        version: 4,
      }),
    });

    return (
      <>
        <LoadPanel visible={isLoading} position={position} />
        <DataGrid
          ref={dataGridRecambiosRef}
          elementAttr={elementAttr}
          dataSource={dataSource}
          keyExpr={"idRecambio"}
          height={"100%"}
          width={"100%"}
          showRowLines
          columnsAutoWidth
          rowAlternationEnabled
          showColumnLines={false}
          onRowRemoving={onRowRemoving}
          onToolbarPreparing={onToolbarPreparing}
          onEditingStart={onEditingStart}
        >
          <Paging enabled={false} />
          <KeyboardNavigation
            enterKeyAction={"moveFocus"}
            enterKeyDirection={"row"}
            editOnKeyPress
          />
          <Sorting mode={"multiple"} />
          <Editing
            mode={"cell"}
            allowUpdating
            selectTextOnEditStart
            changes={[]}
          />
          <Column
            dataField={"referenciaInterna"}
            caption={getTrad("refInterna")}
            alignment={"left"}
            sortOrder={"asc"}
            width={110}
            allowEditing={false}
          />
          <Column
            dataField={"referencia"}
            caption={getTrad("refFabricante")}
            alignment={"left"}
            width={110}
            allowEditing={false}
          />
          <Column
            dataField={"denominacion"}
            caption={getTrad("denominacion")}
            alignment={"left"}
            allowEditing={false}
          />
          {children}
          <Column
            dataField={"cantidad"}
            name={"cantidad"}
            caption={getTrad("cantidad")}
            width={80}
            format={editorOptions.cantidad.format}
            dataType={"number"}
            editorOptions={editorOptions.cantidad}
            alignment={"center"}
            allowFiltering={false}
            cssClass={isEditable ? "dx-Cell_Editable" : ""}
            allowEditing={isEditable}
          >
            <CustomRule
              reevaluate
              validationCallback={validationCallback_CustomRule_MaxVal}
            />
            <CustomRule
              reevaluate
              validationCallback={validationCallback_CustomRule_MinVal}
            />
          </Column>
          <Column
            dataField={"precio"}
            caption={getTrad("precio")}
            alignment={"center"}
            dataType={"number"}
            width={110}
            format={editorOptions.moneda.format}
            editorOptions={editorOptions.moneda}
            cssClass={
              isEditable && allowEditingPrecio ? "dx-Cell_Editable" : ""
            }
            allowEditing={isEditable && allowEditingPrecio}
          />
          <Column
            caption={getTrad("total")}
            name={"total"}
            alignment={"center"}
            dataType={"number"}
            width={120}
            calculateCellValue={(data) => data.cantidad * data.precio}
            format={editorOptions.moneda.format}
            editorOptions={editorOptions.moneda}
            allowEditing={false}
          />
          <Column
            caption={" "}
            width={30}
            alignment={"center"}
            cssClass={"p-0"}
            visible={isEditable}
            cellComponent={removeCellComponent}
          />
        </DataGrid>
      </>
    );
  }
);

export default SharedDataGridRecambios;
