export const fingerDraw = {
  v: "5.4.2",
  fr: 50,
  ip: 0,
  op: 90,
  w: 1920,
  h: 1920,
  nm: "signature",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "streak",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: 126,
              s: [100],
              e: [0],
            },
            { t: 201 },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 960, 0], ix: 2 },
        a: { a: 0, k: [219, 209.5, 0], ix: 1 },
        s: { a: 0, k: [415.95300000000003, 415.95300000000003, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-14.567, -1.064],
                    [-13.816, -3.669],
                    [-4.368, -12.883],
                    [9.546, -17.442],
                    [9.075, -13.1],
                    [6.911, -12.453],
                    [-5.28, -13.67],
                    [-23.259, -6.381],
                    [-17.864, -1.549],
                    [-19.406, 0],
                  ],
                  o: [
                    [18.622, 0],
                    [21.751, 1.59],
                    [26.627, 7.072],
                    [4.672, 13.778],
                    [-6.882, 12.574],
                    [-9.061, 13.08],
                    [-9.882, 17.807],
                    [4.426, 11.458],
                    [11.69, 3.207],
                    [14.664, 1.272],
                    [0, 0],
                  ],
                  v: [
                    [-105.6, -124.8],
                    [-55.917, -123.177],
                    [-2.899, -115.2],
                    [41.22, -84.638],
                    [31.004, -37.039],
                    [5.98, 1.762],
                    [-19.061, 40.348],
                    [-29.132, 88.406],
                    [10.531, 115.66],
                    [54.625, 122.858],
                    [105.6, 124.8],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Caminho 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 9.6, ix: 5 },
              lc: 2,
              lj: 2,
              nm: "Traçado 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [110.8, 129.8], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transformar",
            },
          ],
          nm: "Grupo 1",
          np: 2,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.425], y: [1] },
                o: { x: [0.57], y: [0] },
                n: ["0p425_1_0p57_0"],
                t: 0,
                s: [0],
                e: [100],
              },
              { t: 70 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Aparar caminhos 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "finger",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: "0p833_0p833_0p167_0p167",
              t: 7,
              s: [70.692, 109.376, 0],
              e: [704.342, 370.633, 0],
              to: [229.70166015625, 2.17830204963684, 0],
              ti: [71.7830505371094, -267.446807861328, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: "0p833_0p833_0p167_0p167",
              t: 31,
              s: [704.342, 370.633, 0],
              e: [437.45, 832.467, 0],
              to: [-31.2289867401123, 116.351867675781, 0],
              ti: [32.400562286377, -119.152496337891, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: "0p833_0p833_0p167_0p167",
              t: 39,
              s: [437.45000000000005, 832.467, 0],
              e: [746.045, 1158.416, 0],
              to: [-153.046875, 336.315856933594, 0],
              ti: [-25.251033782959, -4.66215658187866, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: "0p833_0p833_0p167_0p167",
              t: 51,
              s: [746.045, 1158.416, 0],
              e: [1083.516, 1226.899, 0],
              to: [193.828399658203, 35.7869873046875, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.609, y: 0.609 },
              o: { x: 0.167, y: 0.167 },
              n: "0p609_0p609_0p167_0p167",
              t: 67,
              s: [1083.516, 1226.899, 0],
              e: [1083.516, 1226.899, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.405, y: 1 },
              o: { x: 0.52, y: 0 },
              n: "0p405_1_0p52_0",
              t: 215,
              s: [1083.516, 1226.899, 0],
              e: [70.692, 109.376, 0],
              to: [0, 0, 0],
              ti: [-114.227462768555, -1.08323955535889, 0],
            },
            { t: 249 },
          ],
          ix: 2,
          x: "var $bm_rt;\n$bm_rt = transform.position;",
        },
        a: { a: 0, k: [247.521, 288.227, 0], ix: 1 },
        s: { a: 0, k: [415.95300000000003, 415.95300000000003, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.363, -2.377],
                    [2.377, 2.363],
                    [0, 0],
                    [4.725, -4.754],
                    [-4.745, -4.717],
                    [0, 0],
                    [2.362, -2.377],
                    [2.377, 2.363],
                    [0, 0],
                    [4.725, -4.754],
                    [-4.745, -4.717],
                    [0, 0],
                    [0.475, -2.045],
                    [1.99, -0.675],
                    [0, 0],
                    [2.645, -2.661],
                    [-4.323, -4.297],
                    [-2.945, 0.017],
                    [0, 0],
                    [-15.417, 15.509],
                    [26.118, 25.963],
                    [0, 0],
                    [4.725, -4.753],
                    [-4.745, -4.717],
                    [0, 0],
                    [2.363, -2.377],
                    [2.377, 2.363],
                    [0, 0],
                    [4.725, -4.753],
                    [-4.745, -4.717],
                    [0, 0],
                  ],
                  o: [
                    [-2.363, 2.377],
                    [0, 0],
                    [-4.745, -4.716],
                    [-4.725, 4.753],
                    [0, 0],
                    [2.377, 2.363],
                    [-2.363, 2.377],
                    [0, 0],
                    [-4.745, -4.717],
                    [-4.725, 4.753],
                    [0, 0],
                    [1.49, 1.481],
                    [-0.466, 2.036],
                    [0, 0],
                    [-3.559, 1.204],
                    [-4.297, 4.323],
                    [2.144, 2.131],
                    [0, 0],
                    [21.86, -0.048],
                    [25.963, -26.119],
                    [0, 0],
                    [-4.745, -4.717],
                    [-4.725, 4.754],
                    [0, 0],
                    [2.377, 2.363],
                    [-2.363, 2.377],
                    [0, 0],
                    [-4.745, -4.717],
                    [-4.725, 4.754],
                    [0, 0],
                    [2.377, 2.363],
                  ],
                  v: [
                    [5.366, -24.983],
                    [-2.15, -26.034],
                    [-21.525, -45.294],
                    [-38.697, -45.242],
                    [-38.646, -28.071],
                    [-19.271, -8.811],
                    [-17.105, -2.378],
                    [-23.551, -4.505],
                    [-85.21, -61.517],
                    [-102.381, -61.466],
                    [-102.33, -44.295],
                    [-26.665, 30.921],
                    [-25.034, 36.6],
                    [-29.005, 40.973],
                    [-39.378, 43.039],
                    [-48.848, 48.931],
                    [-48.801, 64.557],
                    [-40.927, 67.813],
                    [33.328, 77.991],
                    [81.143, 54.858],
                    [79.936, -29.607],
                    [40.531, -73.275],
                    [23.359, -73.224],
                    [23.41, -56.052],
                    [25.563, -53.912],
                    [28.907, -48.664],
                    [21.391, -49.715],
                    [10.627, -60.415],
                    [-6.544, -60.364],
                    [-6.493, -43.192],
                    [4.27, -32.493],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Caminho 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: "Preenchimento 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [337.12, 341.001], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transformar",
            },
          ],
          nm: "Grupo 2",
          np: 2,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
