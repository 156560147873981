import { connectionConstants } from "../../../constants";
import {
    authHeader,
    errorHandler,
    getTrad,
    dxMensajePregunta,
    element_absolutePosition,
    phone_Pattern,
} from "../../../helpers";
import { svg_remove } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import CustomStore from "devextreme/data/custom_store";
import ArrayStore from "devextreme/data/array_store";

export default function cargaJs(COMPONENT, LAVANDERIA, USER, IDIOMA, onClick_useMap) {
    var deferred = $.Deferred();

    var isDesarrollador = USER.idCargo === 1;

    var itemsLavanderiaSel;

    var prevValue;

    //#region DATASOURCE
    var datasource_lavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblLavanderia",
            key: "idLavanderia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: [
            "idLavanderia",
            "denominacion",
            "direccion",
            "codigoPostal",
            "coordenadas",
            "poblacion",
            "telefono",
            "email",
            "idIdioma",
            "idMoneda",
            "idMonedaLocal",
            "idZonaHoraria",
            "horarioVerano",
            "gestionaRechazo",
            "idUnidadesPeso",
            "idPais",
            "bdMyUniform",
            "tblCorreosNLav",
            "isMyUniformCantidad",
            "precioUnidad_MyUniform",
            "idAdmElementoPEP",
        ],
        expand: [
            "tblAlmacen($filter=idTipoAlmacen eq 7; $select=idAlmacen,denominacion,idLavanderia,activo,idTipoAlmacen),tblTipoRechazo($select=idTipoRechazo,denominacion) ,tblCorreosNLav($expand=tblTipoIncidencia,tblReports($select=idReport,denominacion,idFormulario)), tblBacsCarro($select=idFamilia,cantidad)",
        ],
        filter: ["idLavanderia eq " + LAVANDERIA.idLavanderia],
    });

    var datasource_tblLavanderia_custom = new DataSource({
        store: new CustomStore({
            load: function (loadOptions) {
                var deferred = $.Deferred();

                $.when(datasource_lavanderia.reload(), datasource_tblFamilia.reload()).done(function () {
                    var lavanderia = datasource_lavanderia.items()[0];

                    var tblBacsCarro = [];

                    tblBacsCarro = $.map(datasource_tblFamilia.items(), function (item) {
                        return {
                            idFamilia: item.idFamilia,
                            denominacion: item.denominacion,
                            cantidad: 0,
                        };
                    });

                    $.each(lavanderia.tblBacsCarro, function (index, item) {
                        $.each(tblBacsCarro, function (subIndex, subItem) {
                            if (subItem.idFamilia === item.idFamilia) subItem.cantidad = item.cantidad;
                        });
                    });

                    lavanderia.tblBacsCarro = tblBacsCarro;

                    deferred.resolve(lavanderia);
                });

                return deferred.promise();
            },
        }),
    });

    var datasource_tblPais = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPais",
            key: "idPais",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    var datasource_idioma = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblIdioma",
            key: "idIdioma",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    var datasource_moneda = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblMoneda",
            key: "idMoneda",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    var datasource_unidadesPeso = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblUnidadesPeso",
            key: "idUnidadesPeso",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    var datasource_zonaHoraria = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblZonaHoraria",
            key: "idZonaHoraria",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    var datasource_tipoRechazo = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoRechazo",
            key: "idTipoRechazo",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    var dataSource_tblAlmacen = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblAlmacen",
            key: "idAlmacen",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.isDelta = true;
            },
            version: 4,
        }),
    });

    var dataSource_tblTipoRechazoNLavanderia = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblRechazoNLavanderia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    var datasource_tipoIncidencia = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoIncidencia",
            key: "idTipoIncidencia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        expand: ["tblTraduccion($select=" + IDIOMA.codigo + "), tblTraduccion_abr($select=" + IDIOMA.codigo + ")"],
    });
    datasource_tipoIncidencia.load();

    var datasource_apartados = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblApartado",
            key: "idApartado",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idApartado,denominacion"],
        filter: ["idApartado eq 9"], // TODO de momento solo mostrar Valet
        expand: ["tblFormulario($select=idFormulario,denominacion;$expand=tblReports($select=idReport,denominacion))"],
        postProcess: function (data) {
            let filterItems = [];
            $.each(data, function (iApartado, apartado) {
                $.each(apartado.tblFormulario, function (iForm, form) {
                    if (form.tblReports.length > 0) {
                        // TODO COGERÁ EL PRIMER FORMULARIO DEL APARTADO VISIBLE - SI SE GUARDAR FORMULARIOS EN VEZ DE APARTADOS REVISAR, HAY OTRO SOME EN LA PARTE DE TBLREPORTS
                        if (!filterItems.some((apart) => apart.idApartado === apartado.idApartado))
                            filterItems.push(apartado);
                    }
                });
            });
            return filterItems;
        },
    });
    datasource_apartados.load();

    var datasource_tipoReport = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblReports",
            key: "idReport",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idReport,tblFormulario"],
        expand: ["tblFormulario($expand=tblApartado($select=idApartado,denominacion))"],
    });
    datasource_tipoReport.load();

    var context_tblCorreosNLav = new ODataContext({
        url: connectionConstants.ODATA_URL + "tblCorreosNLav",
        entities: {
            OverrideMasivo: {},
        },
        beforeSend: function (request) {
            request.headers = { ...authHeader() };
            request.params.idLavanderia = LAVANDERIA.idLavanderia;
            request.payload = itemsLavanderiaSel.tblCorreosNLav;
        },
    });

    var datasource_tblFamilia = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblFamilia",
            key: "idFamilia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idFamilia, denominacion"],
    });

    var context_tblBacsCarro = new ODataContext({
        url: connectionConstants.ODATA_URL + "tblBacsCarro",
        entities: {
            OverrideMasivo: {},
        },
        beforeSend: function (request) {
            request.headers = { ...authHeader() };
            request.params.idLavanderia = LAVANDERIA.idLavanderia;
            request.payload = itemsLavanderiaSel.tblBacsCarro;
        },
    });

    var datasource_tblAdmElementoPEP = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmElementoPEP",
            key: "idAdmElementoPEP",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });
    //#endregion

    //#region LAYOUT
    $("#Lavanderia #dxContainer").dxBox({
        height: "100%",
        direction: "col",
    });

    //#endregion

    $.when(
        datasource_tblLavanderia_custom.load(),
        datasource_idioma.load(),
        datasource_tblPais.load(),
        datasource_moneda.load(),
        datasource_unidadesPeso.load(),
        datasource_zonaHoraria.load(),
        datasource_tipoRechazo.load()
    )
        .then(function (
            itemsLavanderia,
            itemsIdioma,
            itemsPais,
            itemsMoneda,
            itemsUnidadesPeso,
            itemsZonaHoraria,
            itemsTipoRechazo
        ) {
            itemsLavanderiaSel = itemsLavanderia[0][0];

            if (typeof $("#Lavanderia #dxForm_lavanderia").data("dxForm") !== "undefined") {
                $("#Lavanderia #dxForm_lavanderia").dxForm("instance").dispose();
            }

            $("#Lavanderia #dxForm_lavanderia").dxForm({
                formData: itemsLavanderiaSel,
                labelLocation: "top",
                height: "100%",
                scrollingEnabled: true,
                colCount: 1,
                items: [
                    {
                        itemType: "tabbed",
                        tabPanelOptions: {
                            elementAttr: { class: "h3" },
                            deferRendering: true,
                            height: "100%",
                            animationEnabled: true,
                            onSelectionChanged: function (e) {
                                if (e.addedItems.length > 0) {
                                    switch (e.addedItems[0].name) {
                                        case "inventario":
                                            var windowHeight = window.innerHeight;
                                            $("#dxBox_almacenesLavanderia")
                                                .dxBox("instance")
                                                .option(
                                                    "height",
                                                    windowHeight -
                                                        element_absolutePosition($("#dxBox_almacenesLavanderia")) -
                                                        80
                                                );
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            },
                        },
                        tabs: [
                            {
                                title: getTrad("lavanderia"),
                                name: "lavanderia",
                                items: [
                                    {
                                        itemType: "group",
                                        name: "tab1",
                                        height: "100%",
                                        colCount: 2,
                                        items: [
                                            {
                                                itemType: "group",
                                                name: "configuracion",
                                                caption: getTrad("configuracion"),
                                                height: "100%",
                                                colCountByScreen: {
                                                    xl: 3,
                                                    lg: 3,
                                                    md: 3,
                                                    sm: 2,
                                                    xs: 1,
                                                },
                                                screenByWidth: function (width) {
                                                    return width >= 1200
                                                        ? "xl"
                                                        : width >= 992
                                                        ? "lg"
                                                        : width >= 768
                                                        ? "md"
                                                        : width >= 576
                                                        ? "sm"
                                                        : "xs";
                                                },
                                                items: [
                                                    {
                                                        dataField: "denominacion",
                                                        name: "denominacion",
                                                        label: {
                                                            text: getTrad("denominacion"),
                                                        },
                                                        editorOptions: {
                                                            maxLength: 50,
                                                        },
                                                        validationRules: [{ type: "required" }],
                                                    },
                                                    {
                                                        dataField: "telefono",
                                                        label: {
                                                            text: getTrad("telefono"),
                                                        },
                                                        editorOptions: {
                                                            maskInvalidMessage: getTrad("validation_Formato"),
                                                            useMaskedValue: true,
                                                        },
                                                        validationRules: [
                                                            {
                                                                type: "pattern",
                                                                pattern: phone_Pattern,
                                                                message: getTrad("validation_Formato"),
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        dataField: "email",
                                                        name: "email",
                                                        label: {
                                                            text: getTrad("email"),
                                                        },
                                                        editorOptions: {
                                                            maxLength: 50,
                                                        },
                                                        validationRules: [
                                                            {
                                                                type: "pattern",
                                                                pattern:
                                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                message: getTrad("validation_Formato"),
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        dataField: "isMyUniformCantidad",
                                                        label: {
                                                            text: getTrad("tipoConteoMyUniform"),
                                                        },
                                                        editorType: "dxSwitch",
                                                        editorOptions: {
                                                            disabled: !USER.isDepartamentoControl,
                                                            elementAttr: { class: "no-disabled" },
                                                            switchedOnText: getTrad("selectorCantidad"),
                                                            switchedOffText: getTrad("porPrenda"),
                                                            onValueChanged: function (e) {
                                                                $("#Lavanderia #dxForm_lavanderia")
                                                                    .dxForm("instance")
                                                                    .itemOption(
                                                                        "lavanderia.tab1.configuracion.precioUnidad_MyUniform",
                                                                        "visible",
                                                                        e.value
                                                                    );
                                                            },
                                                        },
                                                    },
                                                    {
                                                        dataField: "precioUnidad_MyUniform",
                                                        label: {
                                                            text: getTrad("precioUnidad_MyUniform"),
                                                        },
                                                        visible: itemsLavanderiaSel.isMyUniformCantidad,
                                                        editorType: "dxNumberBox",
                                                        editorOptions: {
                                                            disabled: !USER.isDepartamentoControl,
                                                            step: 0,
                                                            max: 9999.999,
                                                            format: {
                                                                style: "decimal",
                                                                maximumFractionDigits: 3,
                                                            },
                                                        },
                                                    },
                                                    {
                                                        dataField: "idAdmElementoPEP",
                                                        colSpan: 2,
                                                        label: {
                                                            text: getTrad("elementoPep"),
                                                        },
                                                        editorType: "dxSelectBox",
                                                        editorOptions: {
                                                            dataSource: datasource_tblAdmElementoPEP,
                                                            valueExpr: "idAdmElementoPEP",
                                                            searchEnabled: true,
                                                            disabled: !USER.isDepartamentoControl && !isDesarrollador,
                                                            displayExpr: (e) =>
                                                                e ? e.codigo + " - " + e.denominacion : null,
                                                            dropDownOptions: { minWidth: 450 },
                                                            onValueChanged: function (e) {
                                                                prevValue = e.previousValue;
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                itemType: "group",
                                                name: "ubicacion",
                                                caption: getTrad("ubicacion"),
                                                height: "100%",
                                                colCountByScreen: {
                                                    xl: 3,
                                                    lg: 3,
                                                    md: 3,
                                                    sm: 2,
                                                    xs: 1,
                                                },
                                                screenByWidth: function (width) {
                                                    return width >= 1200
                                                        ? "xl"
                                                        : width >= 992
                                                        ? "lg"
                                                        : width >= 768
                                                        ? "md"
                                                        : width >= 576
                                                        ? "sm"
                                                        : "xs";
                                                },
                                                items: [
                                                    {
                                                        visible: LAVANDERIA.idCorporacion === 4,
                                                        itemType: "button",
                                                        cssClass: "buttonSeleccionarUbicacion",
                                                        buttonOptions: {
                                                            icon: "map",
                                                            type: "default",
                                                            height: 36,
                                                            width: "100%",
                                                            disabled: !USER.isDepartamentoControl,
                                                            text: getTrad("seleccionarUbicacion"),
                                                            onClick: () => onClick_useMap(itemsLavanderiaSel),
                                                        },
                                                    },
                                                    {
                                                        dataField: "direccion",
                                                        colSpan: 2,
                                                        label: {
                                                            text: getTrad("direccion"),
                                                        },
                                                        editorOptions: {
                                                            disabled: true,
                                                            maxLength: 50,
                                                        },
                                                    },
                                                    {
                                                        dataField: "codigoPostal",
                                                        label: {
                                                            text: getTrad("codigoPostal"),
                                                        },
                                                        editorOptions: {
                                                            disabled: true,
                                                            maxLength: 50,
                                                        },
                                                    },
                                                    {
                                                        dataField: "poblacion",
                                                        label: {
                                                            text: getTrad("poblacion"),
                                                        },
                                                        editorOptions: {
                                                            disabled: true,
                                                            maxLength: 50,
                                                        },
                                                    },
                                                    {
                                                        dataField: "idPais",
                                                        name: getTrad("pais"),
                                                        label: {
                                                            text: getTrad("pais"),
                                                        },
                                                        editorType: "dxSelectBox",
                                                        editorOptions: {
                                                            items: itemsPais[0],
                                                            valueExpr: "idPais",
                                                            displayExpr: "denominacion",
                                                            showClearButton: false,
                                                        },
                                                        validationRules: [{ type: "required" }],
                                                    },
                                                ],
                                            },
                                            {
                                                itemType: "group",
                                                caption: getTrad("datosGenerales"),
                                                height: "100%",
                                                colCountByScreen: {
                                                    xl: 3,
                                                    lg: 3,
                                                    md: 3,
                                                    sm: 2,
                                                    xs: 1,
                                                },
                                                screenByWidth: function (width) {
                                                    return width >= 1200
                                                        ? "xl"
                                                        : width >= 992
                                                        ? "lg"
                                                        : width >= 768
                                                        ? "md"
                                                        : width >= 576
                                                        ? "sm"
                                                        : "xs";
                                                },
                                                items: [
                                                    {
                                                        dataField: "idIdioma",
                                                        name: getTrad("idioma"),
                                                        label: {
                                                            text: getTrad("idioma"),
                                                        },
                                                        editorType: "dxSelectBox",
                                                        editorOptions: {
                                                            items: itemsIdioma[0],
                                                            valueExpr: "idIdioma",
                                                            displayExpr: "denominacion",
                                                            showClearButton: false,
                                                            disabled: !isDesarrollador,
                                                        },
                                                        validationRules: [{ type: "required" }],
                                                    },
                                                    {
                                                        dataField: "idMoneda",
                                                        name: getTrad("moneda"),
                                                        label: {
                                                            text: getTrad("moneda"),
                                                        },
                                                        editorType: "dxSelectBox",
                                                        editorOptions: {
                                                            items: itemsMoneda[0],
                                                            valueExpr: "idMoneda",
                                                            displayExpr: "denominacion",
                                                            showClearButton: false,
                                                        },
                                                        validationRules: [{ type: "required" }],
                                                    },
                                                    {
                                                        dataField: "idMonedaLocal",
                                                        name: getTrad("monedaLocal"),
                                                        label: {
                                                            text: getTrad("monedaLocal"),
                                                        },
                                                        editorType: "dxSelectBox",
                                                        editorOptions: {
                                                            items: itemsMoneda[0],
                                                            valueExpr: "idMoneda",
                                                            displayExpr: "denominacion",
                                                            showClearButton: false,
                                                        },
                                                        validationRules: [{ type: "required" }],
                                                    },
                                                    {
                                                        dataField: "idUnidadesPeso",
                                                        name: getTrad("unidadPeso"),
                                                        label: {
                                                            text: getTrad("unidadPeso"),
                                                        },
                                                        editorType: "dxSelectBox",
                                                        editorOptions: {
                                                            items: itemsUnidadesPeso[0],
                                                            valueExpr: "idUnidadesPeso",
                                                            displayExpr: "denominacion",
                                                            showClearButton: false,
                                                        },
                                                        validationRules: [{ type: "required" }],
                                                    },
                                                    {
                                                        dataField: "idZonaHoraria",
                                                        name: getTrad("zonaHoraria"),
                                                        label: {
                                                            text: getTrad("zonaHoraria"),
                                                        },
                                                        editorType: "dxSelectBox",
                                                        editorOptions: {
                                                            items: itemsZonaHoraria[0],
                                                            valueExpr: "idZonaHoraria",
                                                            displayExpr: "denominacion",
                                                            showClearButton: false,
                                                        },
                                                        validationRules: [{ type: "required" }],
                                                    },
                                                    {
                                                        dataField: "horarioVerano",
                                                        label: {
                                                            text: getTrad("horarioVerano"),
                                                        },
                                                        editorType: "dxSwitch",
                                                        editorOptions: {
                                                            switchedOnText: getTrad("activo"),
                                                            switchedOffText: getTrad("noActivo"),
                                                            elementAttr: {
                                                                class: "dxSwitch_toolbar",
                                                            },
                                                        },
                                                    },
                                                    {
                                                        itemType: "empty",
                                                        colSpan: 1,
                                                    },
                                                    {
                                                        dataField: "tblBacsCarro",
                                                        label: { visible: false },
                                                        colSpan: 2,
                                                        template: function (data, element) {
                                                            return $("<div id='dxDataGrid_tblBacsCarro'>").dxDataGrid({
                                                                //Datos
                                                                dataSource: new DataSource({
                                                                    store: new ArrayStore({
                                                                        data: itemsLavanderiaSel.tblBacsCarro,
                                                                        onModified: function () {
                                                                            $("#dxForm_lavanderia")
                                                                                .dxForm("instance")
                                                                                .updateData(
                                                                                    "tblBacsCarro",
                                                                                    itemsLavanderiaSel.tblBacsCarro
                                                                                );
                                                                        },
                                                                    }),
                                                                }),
                                                                remoteOperations: false,
                                                                //Propiedades
                                                                columnsAutoWidth: true,
                                                                width: "100%",
                                                                width: "100%",
                                                                keyboardNavigation: {
                                                                    enterKeyAction: "moveFocus",
                                                                    enterKeyDirection: "row",
                                                                    editOnKeyPress: true,
                                                                },
                                                                headerFilter: {
                                                                    visible: false,
                                                                },
                                                                filterRow: {
                                                                    visible: false,
                                                                    applyFilter: "auto",
                                                                },
                                                                paging: {
                                                                    pageSize: 50,
                                                                },
                                                                hoverStateEnabled: false,
                                                                pager: {
                                                                    showPageSizeSelector: true,
                                                                    allowedPageSizes: [20, 50, 100],
                                                                    showInfo: true,
                                                                },
                                                                editing: {
                                                                    refreshMode: "repaint",
                                                                    mode: "cell",
                                                                    allowUpdating: true,
                                                                    allowAdding: false,
                                                                    allowDeleting: false,
                                                                    useIcons: true,
                                                                    selectTextOnEditStart: true,
                                                                },
                                                                scrolling: {
                                                                    showScrollbar: "always",
                                                                },
                                                                columns: [
                                                                    {
                                                                        dataField: "denominacion",
                                                                        caption: getTrad("familia"),
                                                                        editorOptions: {
                                                                            maxLength: 50,
                                                                        },
                                                                        allowEditing: false,
                                                                        validationRules: [{ type: "required" }],
                                                                    },
                                                                    {
                                                                        dataField: "cantidad",
                                                                        caption: getTrad("bacsPorCarro"),
                                                                        width: 105,
                                                                        dataType: "number",
                                                                        format: {
                                                                            style: "decimal",
                                                                            minimumFractionDigits: 0,
                                                                        },
                                                                        editorOptions: {
                                                                            max: 999999,
                                                                            format: {
                                                                                style: "decimal",
                                                                                minimumFractionDigits: 0,
                                                                            },
                                                                            step: 0,
                                                                        },
                                                                        alignment: "center",
                                                                        cssClass: "dx-Cell_Editable",
                                                                        editorOptions: {
                                                                            maxLength: 50,
                                                                        },
                                                                        validationRules: [{ type: "required" }],
                                                                    },
                                                                ],
                                                                //EVENTOS
                                                                onContentReady: function (e) {
                                                                    e.element
                                                                        .find(".dx-scrollable-content")
                                                                        .css("padding-right", 0);
                                                                    e.element
                                                                        .find(".dx-freespace-row")
                                                                        .css("height", 0);
                                                                },
                                                                onRowUpdating: function (e) {
                                                                    if (typeof e.newData.tblBacsCarro !== "undefined")
                                                                        e.newData.tblBacsCarro = $.map(
                                                                            e.newData.tblBacsCarro,
                                                                            function (id, index) {
                                                                                return { idFamilia: id };
                                                                            }
                                                                        );
                                                                },
                                                                onToolbarPreparing: function (e) {
                                                                    $.each(
                                                                        e.toolbarOptions.items,
                                                                        function (index, item) {
                                                                            if (
                                                                                item.name === "saveButton" &&
                                                                                item.name === "addRowButton"
                                                                            ) {
                                                                                item.visible = false;
                                                                            }
                                                                        }
                                                                    );
                                                                },
                                                                //Estilos
                                                                showColumnLines: false,
                                                                showRowLines: true,
                                                                rowAlternationEnabled: true,
                                                            });
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                dataField: "tblCorreosNLav",
                                                visible: false,
                                            },
                                            {
                                                itemType: "tabbed",
                                                cssClass: "dxTabPanel_correos_item",
                                                name: "dxForm_group_gestionCorreos",
                                                height: "100%",
                                                width: "100%",
                                                tabPanelOptions: {
                                                    elementAttr: {
                                                        id: "dxTabPanel_correos",
                                                        class: "fill dx-form-group-caption",
                                                    },
                                                    deferRendering: false,
                                                    repaintChangesOnly: true,
                                                    onContentReady: function (e) {
                                                        e.element.find(".dx-tabs").addClass("fill");
                                                        e.element
                                                            .find(".dx-item-content.dx-multiview-item-content")
                                                            .css("padding-top", 15);
                                                    },
                                                },
                                                tabs: [
                                                    {
                                                        title: getTrad("gestionCorreosInc"),
                                                        height: "100%",
                                                        template: function (itemData, itemIndex, itemElement) {
                                                            itemElement.css("padding-top", 10);
                                                            itemElement.append(
                                                                $("<div id='dxDataGrid_gestionCorreosInc'>").dxDataGrid(
                                                                    {
                                                                        //Datos
                                                                        dataSource: new DataSource({
                                                                            store: new ArrayStore({
                                                                                data: itemsLavanderiaSel.tblCorreosNLav,
                                                                                onModified: function () {
                                                                                    setTimeout(() => {
                                                                                        if (
                                                                                            $(
                                                                                                "#dxForm_lavanderia"
                                                                                            ).data("dxForm") != null
                                                                                        )
                                                                                            $("#dxForm_lavanderia")
                                                                                                .dxForm("instance")
                                                                                                .updateData(
                                                                                                    "tblCorreosNLav",
                                                                                                    itemsLavanderiaSel.tblCorreosNLav
                                                                                                );
                                                                                    }, 0);
                                                                                },
                                                                            }),
                                                                            postProcess: function (data) {
                                                                                let filterItems = [];
                                                                                $.each(data, function (index, item) {
                                                                                    if (
                                                                                        item.tblTipoIncidencia.length >
                                                                                        0
                                                                                    )
                                                                                        filterItems.push(item);
                                                                                });
                                                                                return filterItems;
                                                                            },
                                                                        }),
                                                                        remoteOperations: false,
                                                                        //Propiedades
                                                                        columnsAutoWidth: true,
                                                                        width: "100%",
                                                                        height: 320,
                                                                        keyboardNavigation: {
                                                                            enterKeyAction: "moveFocus",
                                                                            enterKeyDirection: "row",
                                                                            editOnKeyPress: true,
                                                                        },
                                                                        headerFilter: {
                                                                            visible: false,
                                                                        },
                                                                        filterRow: {
                                                                            visible: false,
                                                                            applyFilter: "auto",
                                                                        },
                                                                        paging: {
                                                                            pageSize: 50,
                                                                        },
                                                                        hoverStateEnabled: false,
                                                                        pager: {
                                                                            showPageSizeSelector: true,
                                                                            allowedPageSizes: [20, 50, 100],
                                                                            showInfo: true,
                                                                        },
                                                                        editing: {
                                                                            refreshMode: "repaint",
                                                                            mode: "cell",
                                                                            allowUpdating: true,
                                                                            allowAdding: false,
                                                                            allowDeleting: false,
                                                                            useIcons: true,
                                                                        },
                                                                        scrolling: {
                                                                            showScrollbar: "always",
                                                                        },
                                                                        columns: [
                                                                            {
                                                                                dataField: "denominacion",
                                                                                name: getTrad("gestionCorreosInc"),
                                                                                caption: getTrad("email"),
                                                                                cssClass: "dx-Cell_Editable",
                                                                                editorOptions: {
                                                                                    maxLength: 50,
                                                                                },
                                                                                validationRules: [
                                                                                    {
                                                                                        type: "pattern",
                                                                                        pattern:
                                                                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                                        message:
                                                                                            getTrad(
                                                                                                "validation_Formato"
                                                                                            ),
                                                                                    },
                                                                                    { type: "required" },
                                                                                    {
                                                                                        type: "custom",
                                                                                        validationCallback:
                                                                                            function validationCallback(
                                                                                                options
                                                                                            ) {
                                                                                                var correo =
                                                                                                    options.value;

                                                                                                var rows = $(
                                                                                                    "#dxDataGrid_gestionCorreosInc"
                                                                                                )
                                                                                                    .dxDataGrid(
                                                                                                        "instance"
                                                                                                    )
                                                                                                    .getVisibleRows();

                                                                                                var existe =
                                                                                                    $.grep(
                                                                                                        rows,
                                                                                                        function (row) {
                                                                                                            return (
                                                                                                                row.data.denominacion.toLowerCase() ===
                                                                                                                correo.toLowerCase()
                                                                                                            );
                                                                                                        }
                                                                                                    ).length > 1;

                                                                                                return !existe;
                                                                                            },
                                                                                    },
                                                                                ],
                                                                            },
                                                                            {
                                                                                dataField: "tblTipoIncidencia",
                                                                                caption: getTrad("tipoIncidencia"),
                                                                                cssClass: "dx-Cell_Editable",
                                                                                cellTemplate: function (
                                                                                    container,
                                                                                    options
                                                                                ) {
                                                                                    var denoTipoInc = $.map(
                                                                                        options.data.tblTipoIncidencia,
                                                                                        function (val, key) {
                                                                                            return datasource_tipoIncidencia
                                                                                                .items()
                                                                                                .find(function (
                                                                                                    item,
                                                                                                    index
                                                                                                ) {
                                                                                                    return (
                                                                                                        item.idTipoIncidencia ===
                                                                                                        val.idTipoIncidencia
                                                                                                    );
                                                                                                }).tblTraduccion_abr[
                                                                                                IDIOMA.codigo
                                                                                            ];
                                                                                        }
                                                                                    );

                                                                                    var texto = denoTipoInc.join(", "),
                                                                                        color = "#333",
                                                                                        padding = 0;

                                                                                    if (denoTipoInc.length === 0) {
                                                                                        texto = getTrad("selTipoInc");
                                                                                        color = "#999999";
                                                                                        padding = 2;
                                                                                    }
                                                                                    container.append(
                                                                                        $("<div/>")
                                                                                            .css({
                                                                                                color: color,
                                                                                                "padding-left": padding,
                                                                                            })
                                                                                            .text(texto)
                                                                                    );
                                                                                },
                                                                                editCellTemplate: function (
                                                                                    container,
                                                                                    options
                                                                                ) {
                                                                                    var items_tipoIncidenciaNcorreo =
                                                                                        $.map(
                                                                                            options.data
                                                                                                .tblTipoIncidencia,
                                                                                            function (val, key) {
                                                                                                return val.idTipoIncidencia;
                                                                                            }
                                                                                        );
                                                                                    container.append(
                                                                                        $("<div/>").dxTagBox({
                                                                                            elementAttr: {
                                                                                                id:
                                                                                                    "dxTagBox_tipoInc_" +
                                                                                                    options.rowIndex,
                                                                                            },
                                                                                            dataSource:
                                                                                                datasource_tipoIncidencia.items(),
                                                                                            showSelectionControls: true,
                                                                                            displayExpr: "denominacion",
                                                                                            valueExpr:
                                                                                                "idTipoIncidencia",
                                                                                            value: items_tipoIncidenciaNcorreo,
                                                                                            buttons: [
                                                                                                {
                                                                                                    name: "addTipoInc",
                                                                                                    location: "after",
                                                                                                    options: {
                                                                                                        height: "100%",
                                                                                                        width: "100%",
                                                                                                        icon: "spindown",
                                                                                                        type: "default",
                                                                                                        onClick:
                                                                                                            function (
                                                                                                                e
                                                                                                            ) {
                                                                                                                $(
                                                                                                                    "#dxDataGrid_gestionCorreosInc"
                                                                                                                )
                                                                                                                    .dxDataGrid(
                                                                                                                        "instance"
                                                                                                                    )
                                                                                                                    .getController(
                                                                                                                        "validating"
                                                                                                                    )
                                                                                                                    .validate(
                                                                                                                        true
                                                                                                                    )
                                                                                                                    .done(
                                                                                                                        function (
                                                                                                                            is_gridValid
                                                                                                                        ) {
                                                                                                                            var dxTagBox_tipoInc =
                                                                                                                                $(
                                                                                                                                    "#dxTagBox_tipoInc_" +
                                                                                                                                        options.rowIndex
                                                                                                                                ).dxTagBox(
                                                                                                                                    "instance"
                                                                                                                                );

                                                                                                                            if (
                                                                                                                                is_gridValid
                                                                                                                            ) {
                                                                                                                                dxTagBox_tipoInc.option(
                                                                                                                                    "opened"
                                                                                                                                )
                                                                                                                                    ? dxTagBox_tipoInc.close()
                                                                                                                                    : dxTagBox_tipoInc.open();
                                                                                                                            }
                                                                                                                        }
                                                                                                                    );
                                                                                                            },
                                                                                                    },
                                                                                                },
                                                                                            ],
                                                                                            tagTemplate: function (
                                                                                                tagData
                                                                                            ) {
                                                                                                return $("<div />")
                                                                                                    .addClass(
                                                                                                        "dx-tag-content"
                                                                                                    )
                                                                                                    .append(
                                                                                                        $(
                                                                                                            "<span />"
                                                                                                        ).text(
                                                                                                            tagData
                                                                                                                .tblTraduccion_abr[
                                                                                                                IDIOMA
                                                                                                                    .codigo
                                                                                                            ]
                                                                                                        ),
                                                                                                        $(
                                                                                                            "<div />"
                                                                                                        ).addClass(
                                                                                                            "dx-tag-remove-button"
                                                                                                        )
                                                                                                    );
                                                                                            },
                                                                                            placeholder:
                                                                                                getTrad("selTipoInc"),
                                                                                            onValueChanged: function (
                                                                                                args
                                                                                            ) {
                                                                                                // Se guardará el 1er report del apartado seleccionado
                                                                                                let idsTipoInc =
                                                                                                    args.value;
                                                                                                let valueTipoInc =
                                                                                                    $.map(
                                                                                                        idsTipoInc,
                                                                                                        function (
                                                                                                            idTipoIncidencia
                                                                                                        ) {
                                                                                                            let tblTipoIncidencia =
                                                                                                                datasource_tipoIncidencia
                                                                                                                    .items()
                                                                                                                    .find(
                                                                                                                        function (
                                                                                                                            val,
                                                                                                                            index
                                                                                                                        ) {
                                                                                                                            return (
                                                                                                                                idTipoIncidencia ===
                                                                                                                                val.idTipoIncidencia
                                                                                                                            );
                                                                                                                        }
                                                                                                                    );

                                                                                                            return {
                                                                                                                denominacion:
                                                                                                                    tblTipoIncidencia.denominacion,
                                                                                                                icon: tblTipoIncidencia.icon,
                                                                                                                idTipoIncidencia:
                                                                                                                    tblTipoIncidencia.idTipoIncidencia,
                                                                                                                idTraduccion:
                                                                                                                    tblTipoIncidencia.idTraduccion,
                                                                                                                idTraduccion_abr:
                                                                                                                    tblTipoIncidencia.idTraduccion_abr,
                                                                                                                idxTipoIncidencia:
                                                                                                                    tblTipoIncidencia.idxTipoIncidencia,
                                                                                                            };
                                                                                                        }
                                                                                                    );
                                                                                                options.setValue(
                                                                                                    valueTipoInc
                                                                                                );
                                                                                            },
                                                                                            onFocusIn: function (e) {
                                                                                                var items =
                                                                                                    e.component.option(
                                                                                                        "value"
                                                                                                    );

                                                                                                $(
                                                                                                    "#dxDataGrid_gestionCorreosInc"
                                                                                                )
                                                                                                    .dxDataGrid(
                                                                                                        "instance"
                                                                                                    )
                                                                                                    .getController(
                                                                                                        "validating"
                                                                                                    )
                                                                                                    .validate(true)
                                                                                                    .done(function (
                                                                                                        is_gridValid
                                                                                                    ) {
                                                                                                        if (
                                                                                                            items.length ===
                                                                                                                0 &&
                                                                                                            is_gridValid
                                                                                                        ) {
                                                                                                            e.component.open();
                                                                                                        }
                                                                                                    });
                                                                                            },
                                                                                        })
                                                                                    );
                                                                                },
                                                                            },
                                                                            {
                                                                                caption: " ",
                                                                                width: 50,
                                                                                alignment: "center",
                                                                                cssClass: "p-0",
                                                                                cellTemplate: function (
                                                                                    cellElement,
                                                                                    cellInfo
                                                                                ) {
                                                                                    var contenedor =
                                                                                        $("<div/>").addClass(
                                                                                            "container_spanCentrado"
                                                                                        );
                                                                                    svg_remove
                                                                                        .clone()
                                                                                        .css("height", "25px")
                                                                                        .css("width", "25px")
                                                                                        .css("cursor", "pointer")
                                                                                        .appendTo(contenedor);

                                                                                    cellElement.on(
                                                                                        "click",
                                                                                        function () {
                                                                                            var dxDataGrid_gestionCorreosInc =
                                                                                                $(
                                                                                                    "#dxDataGrid_gestionCorreosInc"
                                                                                                ).dxDataGrid(
                                                                                                    "instance"
                                                                                                );
                                                                                            dxDataGrid_gestionCorreosInc.cancelEditData();

                                                                                            //para validar una nueva row
                                                                                            dxDataGrid_gestionCorreosInc
                                                                                                .getController(
                                                                                                    "validating"
                                                                                                )
                                                                                                .validate(true)
                                                                                                .done(function (
                                                                                                    isValid
                                                                                                ) {
                                                                                                    if (isValid) {
                                                                                                        dxMensajePregunta(
                                                                                                            getTrad(
                                                                                                                "preg_RegistroEliminarCorto"
                                                                                                            ),
                                                                                                            [
                                                                                                                // ACEPTAR
                                                                                                                [
                                                                                                                    getTrad(
                                                                                                                        "aceptar"
                                                                                                                    ),
                                                                                                                    function () {
                                                                                                                        $.each(
                                                                                                                            itemsLavanderiaSel.tblCorreosNLav,
                                                                                                                            function (
                                                                                                                                index,
                                                                                                                                item
                                                                                                                            ) {
                                                                                                                                if (
                                                                                                                                    item.denominacion ===
                                                                                                                                    cellInfo
                                                                                                                                        .data
                                                                                                                                        .denominacion
                                                                                                                                ) {
                                                                                                                                    item.tblTipoIncidencia =
                                                                                                                                        [];
                                                                                                                                }
                                                                                                                            }
                                                                                                                        );
                                                                                                                        $(
                                                                                                                            "#dxForm_lavanderia"
                                                                                                                        )
                                                                                                                            .dxForm(
                                                                                                                                "instance"
                                                                                                                            )
                                                                                                                            .updateData(
                                                                                                                                "tblCorreosNLav",
                                                                                                                                itemsLavanderiaSel.tblCorreosNLav
                                                                                                                            );
                                                                                                                        $(
                                                                                                                            "#dxDataGrid_gestionCorreosInc"
                                                                                                                        )
                                                                                                                            .dxDataGrid(
                                                                                                                                "instance"
                                                                                                                            )
                                                                                                                            .refresh();
                                                                                                                    },
                                                                                                                    "normal",
                                                                                                                    "btnAceptar",
                                                                                                                ],
                                                                                                                // CANCELAR
                                                                                                                [
                                                                                                                    getTrad(
                                                                                                                        "cancelar"
                                                                                                                    ),
                                                                                                                    function () {},
                                                                                                                    "normal",
                                                                                                                    "btnCancelar",
                                                                                                                ],
                                                                                                            ]
                                                                                                        );
                                                                                                    }
                                                                                                });
                                                                                        }
                                                                                    );
                                                                                    contenedor.appendTo(cellElement);
                                                                                },
                                                                            },
                                                                        ],
                                                                        //EVENTOS
                                                                        onContentReady: function (e) {
                                                                            e.element
                                                                                .find(".dx-freespace-row")
                                                                                .css("height", 0);
                                                                        },
                                                                        onRowPrepared: function (e) {
                                                                            if (e.rowType === "data")
                                                                                e.rowElement.addClass("tagBox-row");
                                                                        },
                                                                        onRowUpdating: function (e) {
                                                                            if (
                                                                                typeof e.newData.tblTipoIncidencia !==
                                                                                "undefined"
                                                                            )
                                                                                e.newData.tblTipoIncidencia = $.map(
                                                                                    e.newData.tblTipoIncidencia,
                                                                                    function (id, index) {
                                                                                        return id;
                                                                                    }
                                                                                );
                                                                        },
                                                                        onRowInserting: function (e) {
                                                                            e.data.tblTipoIncidencia = $.map(
                                                                                e.data.tblTipoIncidencia,
                                                                                function (id, index) {
                                                                                    return id;
                                                                                }
                                                                            );
                                                                            delete e.data.__KEY__;
                                                                        },
                                                                        onEditingStart: function (e) {
                                                                            if (
                                                                                e.column.dataField ===
                                                                                "tblTipoIncidencia"
                                                                            ) {
                                                                                $("#dxDataGrid_gestionCorreosInc")
                                                                                    .dxDataGrid("instance")
                                                                                    .getController("validating")
                                                                                    .validate(true)
                                                                                    .done(function (is_gridValid) {
                                                                                        if (!is_gridValid) {
                                                                                            e.cancel = true;
                                                                                        }
                                                                                    });
                                                                            }
                                                                        },
                                                                        onToolbarPreparing: function (e) {
                                                                            $.each(
                                                                                e.toolbarOptions.items,
                                                                                function (index, item) {
                                                                                    if (
                                                                                        item.name === "saveButton" &&
                                                                                        item.name === "addRowButton"
                                                                                    ) {
                                                                                        item.visible = false;
                                                                                    }
                                                                                }
                                                                            );

                                                                            e.toolbarOptions.items.unshift({
                                                                                location: "before",
                                                                                widget: "dxButton",
                                                                                toolbar: "bottom",
                                                                                options: {
                                                                                    width: "100%",
                                                                                    icon: "plus",
                                                                                    text: getTrad("añadirCorreo"),
                                                                                    type: "normal",
                                                                                    onClick: function () {
                                                                                        e.component.addRow();
                                                                                    },
                                                                                },
                                                                            });
                                                                        },
                                                                        //Estilos
                                                                        showColumnLines: false,
                                                                        showRowLines: true,
                                                                        rowAlternationEnabled: true,
                                                                    }
                                                                )
                                                            );
                                                        },
                                                    },
                                                    {
                                                        title: getTrad("gestionCorreosAlbaranes"),
                                                        height: "100%",
                                                        template: function (itemData, itemIndex, itemElement) {
                                                            itemElement.css("padding-top", 10);
                                                            itemElement.append(
                                                                $(
                                                                    "<div id='dxDataGrid_gestionCorreosAlbaranes'>"
                                                                ).dxDataGrid({
                                                                    //Datos
                                                                    dataSource: new DataSource({
                                                                        store: new ArrayStore({
                                                                            data: itemsLavanderiaSel.tblCorreosNLav,
                                                                            onModified: function () {
                                                                                setTimeout(() => {
                                                                                    if (
                                                                                        $("#dxForm_lavanderia").data(
                                                                                            "dxForm"
                                                                                        ) != null
                                                                                    )
                                                                                        $("#dxForm_lavanderia")
                                                                                            .dxForm("instance")
                                                                                            .updateData(
                                                                                                "tblCorreosNLav",
                                                                                                itemsLavanderiaSel.tblCorreosNLav
                                                                                            );
                                                                                }, 0);
                                                                            },
                                                                        }),
                                                                        postProcess: function (data) {
                                                                            let filterItems = [];
                                                                            $.each(data, function (index, item) {
                                                                                if (item.tblReports.length > 0)
                                                                                    filterItems.push(item);
                                                                            });
                                                                            return filterItems;
                                                                        },
                                                                    }),
                                                                    remoteOperations: false,
                                                                    //Propiedades
                                                                    columnsAutoWidth: true,
                                                                    width: "100%",
                                                                    height: 320,
                                                                    keyboardNavigation: {
                                                                        enterKeyAction: "moveFocus",
                                                                        enterKeyDirection: "row",
                                                                        editOnKeyPress: true,
                                                                    },
                                                                    headerFilter: {
                                                                        visible: false,
                                                                    },
                                                                    filterRow: {
                                                                        visible: false,
                                                                        applyFilter: "auto",
                                                                    },
                                                                    paging: {
                                                                        pageSize: 50,
                                                                    },
                                                                    hoverStateEnabled: false,
                                                                    pager: {
                                                                        showPageSizeSelector: true,
                                                                        allowedPageSizes: [20, 50, 100],
                                                                        showInfo: true,
                                                                    },
                                                                    editing: {
                                                                        refreshMode: "repaint",
                                                                        mode: "cell",
                                                                        allowUpdating: true,
                                                                        allowAdding: false,
                                                                        allowDeleting: false,
                                                                        useIcons: true,
                                                                    },
                                                                    scrolling: {
                                                                        showScrollbar: "always",
                                                                    },
                                                                    columns: [
                                                                        {
                                                                            dataField: "denominacion",
                                                                            name: getTrad("gestionCorreosAlb"),
                                                                            caption: getTrad("email"),
                                                                            cssClass: "dx-Cell_Editable",
                                                                            editorOptions: {
                                                                                maxLength: 50,
                                                                            },
                                                                            validationRules: [
                                                                                {
                                                                                    type: "pattern",
                                                                                    pattern:
                                                                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                                    message:
                                                                                        getTrad("validation_Formato"),
                                                                                },
                                                                                { type: "required" },
                                                                                {
                                                                                    type: "custom",
                                                                                    validationCallback:
                                                                                        function validationCallback(
                                                                                            options
                                                                                        ) {
                                                                                            var correo = options.value;

                                                                                            var rows = $(
                                                                                                "#dxDataGrid_gestionCorreosAlbaranes"
                                                                                            )
                                                                                                .dxDataGrid("instance")
                                                                                                .getVisibleRows();

                                                                                            var existe =
                                                                                                $.grep(
                                                                                                    rows,
                                                                                                    function (row) {
                                                                                                        return (
                                                                                                            row.data.denominacion.toLowerCase() ===
                                                                                                            correo.toLowerCase()
                                                                                                        );
                                                                                                    }
                                                                                                ).length > 1;

                                                                                            return !existe;
                                                                                        },
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            dataField: "tblReports",
                                                                            caption: getTrad("tipoInforme"),
                                                                            cssClass: "dx-Cell_Editable",
                                                                            cellTemplate: function (
                                                                                container,
                                                                                options
                                                                            ) {
                                                                                var denoReport = $.map(
                                                                                    options.data.tblReports,
                                                                                    function (val, key) {
                                                                                        return datasource_tipoReport
                                                                                            .items()
                                                                                            .find(function (
                                                                                                item,
                                                                                                index
                                                                                            ) {
                                                                                                return (
                                                                                                    item.idReport ===
                                                                                                    val.idReport
                                                                                                );
                                                                                            }).tblFormulario.tblApartado
                                                                                            .denominacion;
                                                                                    }
                                                                                );

                                                                                let filto_apartadosRepetidos = [];

                                                                                $.each(
                                                                                    denoReport,
                                                                                    function (index, item) {
                                                                                        if (
                                                                                            $.inArray(
                                                                                                item,
                                                                                                filto_apartadosRepetidos
                                                                                            ) === -1
                                                                                        )
                                                                                            filto_apartadosRepetidos.push(
                                                                                                item
                                                                                            );
                                                                                    }
                                                                                );

                                                                                var texto =
                                                                                        filto_apartadosRepetidos.join(
                                                                                            ", "
                                                                                        ),
                                                                                    color = "#333",
                                                                                    padding = 0;

                                                                                if (
                                                                                    filto_apartadosRepetidos.length ===
                                                                                    0
                                                                                ) {
                                                                                    texto = getTrad("selTipoRep");
                                                                                    color = "#999999";
                                                                                    padding = 2;
                                                                                }
                                                                                container.append(
                                                                                    $("<div/>")
                                                                                        .css({
                                                                                            color: color,
                                                                                            "padding-left": padding,
                                                                                        })
                                                                                        .text(texto)
                                                                                );
                                                                            },
                                                                            editCellTemplate: function (
                                                                                container,
                                                                                options
                                                                            ) {
                                                                                var denoReport = $.map(
                                                                                    options.data.tblReports,
                                                                                    function (val, key) {
                                                                                        let tblFormulario =
                                                                                            datasource_tipoReport
                                                                                                .items()
                                                                                                .find(function (
                                                                                                    item,
                                                                                                    index
                                                                                                ) {
                                                                                                    return (
                                                                                                        item.idReport ===
                                                                                                        val.idReport
                                                                                                    );
                                                                                                }).tblFormulario;

                                                                                        return {
                                                                                            idFormulario:
                                                                                                tblFormulario.idFormulario,
                                                                                            idReport: val.idReport,
                                                                                            idApartado:
                                                                                                tblFormulario
                                                                                                    .tblApartado
                                                                                                    .idApartado,
                                                                                            denominacion:
                                                                                                tblFormulario
                                                                                                    .tblApartado
                                                                                                    .denominacion,
                                                                                        };
                                                                                    }
                                                                                );
                                                                                let filto_apartadosRepetidos = [];

                                                                                $.each(
                                                                                    denoReport,
                                                                                    function (index, item) {
                                                                                        if (
                                                                                            !filto_apartadosRepetidos.some(
                                                                                                (apartado) =>
                                                                                                    apartado.idApartado ===
                                                                                                    item.idApartado
                                                                                            )
                                                                                        )
                                                                                            filto_apartadosRepetidos.push(
                                                                                                item
                                                                                            );
                                                                                    }
                                                                                );

                                                                                let idsApartado = $.map(
                                                                                    filto_apartadosRepetidos,
                                                                                    function (item) {
                                                                                        return item.idApartado;
                                                                                    }
                                                                                );

                                                                                container.append(
                                                                                    $("<div/>").dxTagBox({
                                                                                        elementAttr: {
                                                                                            id:
                                                                                                "dxTagBox_tipoRep_" +
                                                                                                options.rowIndex,
                                                                                        },
                                                                                        dataSource:
                                                                                            datasource_apartados.items(),
                                                                                        showSelectionControls: true,
                                                                                        displayExpr: "denominacion",
                                                                                        valueExpr: "idApartado",
                                                                                        value: idsApartado,
                                                                                        buttons: [
                                                                                            {
                                                                                                name: "addTipoInc",
                                                                                                location: "after",
                                                                                                options: {
                                                                                                    height: "100%",
                                                                                                    width: "100%",
                                                                                                    icon: "spindown",
                                                                                                    type: "default",
                                                                                                    onClick: function (
                                                                                                        e
                                                                                                    ) {
                                                                                                        $(
                                                                                                            "#dxDataGrid_gestionCorreosAlbaranes"
                                                                                                        )
                                                                                                            .dxDataGrid(
                                                                                                                "instance"
                                                                                                            )
                                                                                                            .getController(
                                                                                                                "validating"
                                                                                                            )
                                                                                                            .validate(
                                                                                                                true
                                                                                                            )
                                                                                                            .done(
                                                                                                                function (
                                                                                                                    is_gridValid
                                                                                                                ) {
                                                                                                                    var dxTagBox_tipoRep =
                                                                                                                        $(
                                                                                                                            "#dxTagBox_tipoRep_" +
                                                                                                                                options.rowIndex
                                                                                                                        ).dxTagBox(
                                                                                                                            "instance"
                                                                                                                        );

                                                                                                                    if (
                                                                                                                        is_gridValid
                                                                                                                    ) {
                                                                                                                        dxTagBox_tipoRep.option(
                                                                                                                            "opened"
                                                                                                                        )
                                                                                                                            ? dxTagBox_tipoRep.close()
                                                                                                                            : dxTagBox_tipoRep.open();
                                                                                                                    }
                                                                                                                }
                                                                                                            );
                                                                                                    },
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                        tagTemplate: function (
                                                                                            tagData
                                                                                        ) {
                                                                                            return $("<div />")
                                                                                                .addClass(
                                                                                                    "dx-tag-content"
                                                                                                )
                                                                                                .append(
                                                                                                    $("<span />").text(
                                                                                                        tagData.denominacion
                                                                                                    ),
                                                                                                    $(
                                                                                                        "<div />"
                                                                                                    ).addClass(
                                                                                                        "dx-tag-remove-button"
                                                                                                    )
                                                                                                );
                                                                                        },
                                                                                        placeholder:
                                                                                            getTrad("selTipoRep"),
                                                                                        onValueChanged: function (
                                                                                            args
                                                                                        ) {
                                                                                            // Se guardará el 1er report del apartado seleccionado
                                                                                            let idsApartado =
                                                                                                args.value;
                                                                                            let valueApartados = $.map(
                                                                                                idsApartado,
                                                                                                function (idApartado) {
                                                                                                    let tblFormulario =
                                                                                                        datasource_apartados
                                                                                                            .items()
                                                                                                            .find(
                                                                                                                function (
                                                                                                                    val,
                                                                                                                    index
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        idApartado ===
                                                                                                                        val.idApartado
                                                                                                                    );
                                                                                                                }
                                                                                                            )
                                                                                                            .tblFormulario.find(
                                                                                                                function (
                                                                                                                    form,
                                                                                                                    Iform
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        form
                                                                                                                            .tblReports
                                                                                                                            .length >
                                                                                                                        0
                                                                                                                    );
                                                                                                                }
                                                                                                            );

                                                                                                    return {
                                                                                                        idFormulario:
                                                                                                            tblFormulario.idFormulario,
                                                                                                        idReport:
                                                                                                            tblFormulario
                                                                                                                .tblReports[0]
                                                                                                                .idReport,
                                                                                                        denominacion:
                                                                                                            tblFormulario
                                                                                                                .tblReports[0]
                                                                                                                .denominacion,
                                                                                                    };
                                                                                                }
                                                                                            );

                                                                                            options.setValue(
                                                                                                valueApartados
                                                                                            );
                                                                                        },
                                                                                        onFocusIn: function (e) {
                                                                                            var items =
                                                                                                e.component.option(
                                                                                                    "value"
                                                                                                );

                                                                                            $(
                                                                                                "#dxDataGrid_gestionCorreosAlbaranes"
                                                                                            )
                                                                                                .dxDataGrid("instance")
                                                                                                .getController(
                                                                                                    "validating"
                                                                                                )
                                                                                                .validate(true)
                                                                                                .done(function (
                                                                                                    is_gridValid
                                                                                                ) {
                                                                                                    if (
                                                                                                        items.length ===
                                                                                                            0 &&
                                                                                                        is_gridValid
                                                                                                    ) {
                                                                                                        e.component.open();
                                                                                                    }
                                                                                                });
                                                                                        },
                                                                                    })
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            caption: " ",
                                                                            width: 50,
                                                                            alignment: "center",
                                                                            cssClass: "p-0",
                                                                            cellTemplate: function (
                                                                                cellElement,
                                                                                cellInfo
                                                                            ) {
                                                                                var contenedor =
                                                                                    $("<div/>").addClass(
                                                                                        "container_spanCentrado"
                                                                                    );
                                                                                svg_remove
                                                                                    .clone()
                                                                                    .css("height", "25px")
                                                                                    .css("width", "25px")
                                                                                    .css("cursor", "pointer")
                                                                                    .appendTo(contenedor);

                                                                                cellElement.on("click", function () {
                                                                                    var dxDataGrid_gestionCorreosAlbaranes =
                                                                                        $(
                                                                                            "#dxDataGrid_gestionCorreosAlbaranes"
                                                                                        ).dxDataGrid("instance");
                                                                                    dxDataGrid_gestionCorreosAlbaranes.cancelEditData();

                                                                                    //para validar una nueva row
                                                                                    dxDataGrid_gestionCorreosAlbaranes
                                                                                        .getController("validating")
                                                                                        .validate(true)
                                                                                        .done(function (isValid) {
                                                                                            if (isValid) {
                                                                                                dxMensajePregunta(
                                                                                                    getTrad(
                                                                                                        "preg_RegistroEliminarCorto"
                                                                                                    ),
                                                                                                    [
                                                                                                        // ACEPTAR
                                                                                                        [
                                                                                                            getTrad(
                                                                                                                "aceptar"
                                                                                                            ),
                                                                                                            function () {
                                                                                                                $.each(
                                                                                                                    itemsLavanderiaSel.tblCorreosNLav,
                                                                                                                    function (
                                                                                                                        index,
                                                                                                                        item
                                                                                                                    ) {
                                                                                                                        if (
                                                                                                                            item.idCorreo ===
                                                                                                                            cellInfo
                                                                                                                                .data
                                                                                                                                .idCorreo
                                                                                                                        ) {
                                                                                                                            item.tblReports =
                                                                                                                                [];
                                                                                                                        }
                                                                                                                    }
                                                                                                                );
                                                                                                                $(
                                                                                                                    "#dxForm_lavanderia"
                                                                                                                )
                                                                                                                    .dxForm(
                                                                                                                        "instance"
                                                                                                                    )
                                                                                                                    .updateData(
                                                                                                                        "tblCorreosNLav",
                                                                                                                        itemsLavanderiaSel.tblCorreosNLav
                                                                                                                    );
                                                                                                                $(
                                                                                                                    "#dxDataGrid_gestionCorreosAlbaranes"
                                                                                                                )
                                                                                                                    .dxDataGrid(
                                                                                                                        "instance"
                                                                                                                    )
                                                                                                                    .refresh();
                                                                                                            },
                                                                                                            "normal",
                                                                                                            "btnAceptar",
                                                                                                        ],
                                                                                                        // CANCELAR
                                                                                                        [
                                                                                                            getTrad(
                                                                                                                "cancelar"
                                                                                                            ),
                                                                                                            function () {},
                                                                                                            "normal",
                                                                                                            "btnCancelar",
                                                                                                        ],
                                                                                                    ]
                                                                                                );
                                                                                            }
                                                                                        });
                                                                                });
                                                                                contenedor.appendTo(cellElement);
                                                                            },
                                                                        },
                                                                    ],
                                                                    //EVENTOS
                                                                    onContentReady: function (e) {
                                                                        e.element
                                                                            .find(".dx-freespace-row")
                                                                            .css("height", 0);
                                                                    },
                                                                    onRowPrepared: function (e) {
                                                                        if (e.rowType === "data")
                                                                            e.rowElement.addClass("tagBox-row");
                                                                    },
                                                                    onRowUpdating: function (e) {
                                                                        if (
                                                                            typeof e.newData.tblTipoIncidencia !==
                                                                            "undefined"
                                                                        )
                                                                            e.newData.tblReports = $.map(
                                                                                e.newData.tblReports,
                                                                                function (id, index) {
                                                                                    return id;
                                                                                }
                                                                            );
                                                                    },
                                                                    onRowInserting: function (e) {
                                                                        e.data.tblReports = $.map(
                                                                            e.data.tblReports,
                                                                            function (id, index) {
                                                                                return id;
                                                                            }
                                                                        );
                                                                        delete e.data.__KEY__;
                                                                    },
                                                                    onEditingStart: function (e) {
                                                                        if (e.column.dataField === "tblReports") {
                                                                            $("#dxDataGrid_gestionCorreosAlbaranes")
                                                                                .dxDataGrid("instance")
                                                                                .getController("validating")
                                                                                .validate(true)
                                                                                .done(function (is_gridValid) {
                                                                                    if (!is_gridValid) {
                                                                                        e.cancel = true;
                                                                                    }
                                                                                });
                                                                        }
                                                                    },
                                                                    onToolbarPreparing: function (e) {
                                                                        $.each(
                                                                            e.toolbarOptions.items,
                                                                            function (index, item) {
                                                                                if (
                                                                                    item.name === "saveButton" &&
                                                                                    item.name === "addRowButton"
                                                                                ) {
                                                                                    item.visible = false;
                                                                                }
                                                                            }
                                                                        );

                                                                        e.toolbarOptions.items.unshift({
                                                                            location: "before",
                                                                            widget: "dxButton",
                                                                            toolbar: "bottom",
                                                                            options: {
                                                                                width: "100%",
                                                                                icon: "plus",
                                                                                text: getTrad("añadirCorreo"),
                                                                                type: "normal",
                                                                                onClick: function () {
                                                                                    e.component.addRow();
                                                                                },
                                                                            },
                                                                        });
                                                                    },
                                                                    //Estilos
                                                                    showColumnLines: false,
                                                                    showRowLines: true,
                                                                    rowAlternationEnabled: true,
                                                                })
                                                            );
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                itemType: "group",
                                                caption: getTrad("rechazo"),
                                                name: "rechazo",
                                                height: "100%",
                                                items: [
                                                    {
                                                        dataField: "gestionaRechazo",
                                                        label: {
                                                            text: getTrad("gestionaRechazo"),
                                                            visible: true,
                                                        },
                                                        editorType: "dxSwitch",
                                                        colSpan: 1,
                                                        editorOptions: {
                                                            switchedOnText: getTrad("activo"),
                                                            switchedOffText: getTrad("noActivo"),
                                                            elementAttr: {
                                                                class: "dxSwitch_toolbar",
                                                                id: "dxSwitch_gestionaRechazo",
                                                            },
                                                            onValueChanged: function (e) {
                                                                var dxTagBox_rechazo =
                                                                    $("#dxTagBox_rechazo").dxTagBox("instance");
                                                                if (
                                                                    !e.value &&
                                                                    dxTagBox_rechazo.option("value").length > 0
                                                                ) {
                                                                    dxMensajePregunta(
                                                                        getTrad("alert_gestionRechazo_desactivar"),
                                                                        [
                                                                            // ACEPTAR
                                                                            [
                                                                                getTrad("aceptar"),
                                                                                function () {
                                                                                    dxTagBox_rechazo.option(
                                                                                        "disabled",
                                                                                        !e.value
                                                                                    );
                                                                                    if (!e.value)
                                                                                        dxTagBox_rechazo.option(
                                                                                            "value",
                                                                                            []
                                                                                        );
                                                                                },
                                                                                "normal",
                                                                                "btnAceptar",
                                                                            ],
                                                                            // CANCELAR
                                                                            [
                                                                                getTrad("cancelar"),
                                                                                function () {
                                                                                    e.component.option("value", true);
                                                                                },
                                                                                "normal",
                                                                                "btnCancelar",
                                                                            ],
                                                                        ]
                                                                    );
                                                                } else {
                                                                    dxTagBox_rechazo.option("disabled", !e.value);
                                                                    if (!e.value) dxTagBox_rechazo.option("value", []);
                                                                }
                                                            },
                                                        },
                                                    },
                                                    {
                                                        dataField: "tblTipoRechazo",
                                                        label: {
                                                            text: getTrad("tipo"),
                                                        },
                                                        colSpan: 1,
                                                        template: function (data, itemElement) {
                                                            $(itemElement[0].previousSibling).css("padding-top", 5); // PARA QUE SE ALINEE CON LOS OTROS EDITORES

                                                            return $("<div id='dxTagBox_rechazo'/>").dxTagBox({
                                                                dataSource: itemsTipoRechazo[0],
                                                                valueExpr: "idTipoRechazo",
                                                                displayExpr: "denominacion",
                                                                applyValueMode: "useButtons",
                                                                showSelectionControls: true,
                                                                disabled:
                                                                    $("#dxSwitch_gestionaRechazo")
                                                                        .dxSwitch("instance")
                                                                        .option("value") === true
                                                                        ? false
                                                                        : true,
                                                                buttons: [
                                                                    {
                                                                        name: "addRechazo",
                                                                        location: "after",
                                                                        options: {
                                                                            height: "100%",
                                                                            icon: "spindown",
                                                                            type: "default",
                                                                            onClick: function (e) {
                                                                                var dxTagBox_rechazo =
                                                                                    $("#dxTagBox_rechazo").dxTagBox(
                                                                                        "instance"
                                                                                    );
                                                                                dxTagBox_rechazo.option("opened")
                                                                                    ? dxTagBox_rechazo.close()
                                                                                    : dxTagBox_rechazo.open();
                                                                            },
                                                                        },
                                                                    },
                                                                ],
                                                                value: $.map(
                                                                    itemsLavanderiaSel.tblTipoRechazo,
                                                                    function (val, key) {
                                                                        return val.idTipoRechazo;
                                                                    }
                                                                ),
                                                                onValueChanged: function (e) {
                                                                    var idsTipoRechazo = [];
                                                                    $.map(e.value, function (value, key) {
                                                                        idsTipoRechazo.push(value);
                                                                    });
                                                                    var objTipoRechazo = {
                                                                        idLavanderia: LAVANDERIA.idLavanderia,
                                                                        idsTipoRechazo: idsTipoRechazo,
                                                                    };
                                                                    dataSource_tblTipoRechazoNLavanderia
                                                                        .store()
                                                                        .update(
                                                                            LAVANDERIA.idLavanderia,
                                                                            objTipoRechazo
                                                                        );
                                                                },
                                                            });
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                title: getTrad("inventario"),
                                name: "inventario",
                                items: [
                                    {
                                        itemType: "group",
                                        caption: getTrad("almacenesLavanderia"),
                                        colCount: 1,
                                        items: [
                                            {
                                                template: function (data, itemElement) {
                                                    return $("<div id='dxBox_almacenesLavanderia' />").dxBox({
                                                        direction: "col",
                                                        align: "space-around",
                                                        crossAlign: "stretch",
                                                        heigh: "100%",
                                                        width: 300,
                                                        items: [
                                                            {
                                                                baseSize: 30,
                                                                shrink: 0,
                                                                ratio: 0,
                                                                template: function () {
                                                                    return $(
                                                                        "<div id='dxTextBox_addAlmacen'/>"
                                                                    ).dxTextBox({
                                                                        placeholder: getTrad("añadirAlmacen"),
                                                                        maxLength: 50,
                                                                        buttons: [
                                                                            {
                                                                                name: "revertButton",
                                                                                location: "after",
                                                                                options: {
                                                                                    icon: "revert",
                                                                                    type: "danger",
                                                                                    visible: false,
                                                                                    elementAttr: {
                                                                                        id: "dxButton_revertButton",
                                                                                    },
                                                                                    onClick: function (e) {
                                                                                        $("#dxList_almacenesLavanderia")
                                                                                            .dxList("instance")
                                                                                            .unselectAll();
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                name: "addAlmacen",
                                                                                location: "after",
                                                                                options: {
                                                                                    icon: "plus",
                                                                                    type: "default",
                                                                                    elementAttr: {
                                                                                        id: "dxButton_addAlmacen",
                                                                                    },
                                                                                    onClick: function (e) {
                                                                                        var dxList_almacenesLavanderia =
                                                                                                $(
                                                                                                    "#dxList_almacenesLavanderia"
                                                                                                ).dxList("instance"),
                                                                                            dxTextBox_addAlmacen =
                                                                                                $(
                                                                                                    "#dxTextBox_addAlmacen"
                                                                                                ).dxTextBox("instance");

                                                                                        if (
                                                                                            dxTextBox_addAlmacen.option(
                                                                                                "value"
                                                                                            ) !== ""
                                                                                        ) {
                                                                                            if (
                                                                                                dxList_almacenesLavanderia.option(
                                                                                                    "selectedItems"
                                                                                                ).length > 0
                                                                                            ) {
                                                                                                // UPDATE
                                                                                                var idAlmacen =
                                                                                                        dxList_almacenesLavanderia.option(
                                                                                                            "selectedItemKeys"
                                                                                                        )[0],
                                                                                                    denominacion =
                                                                                                        dxTextBox_addAlmacen
                                                                                                            .option(
                                                                                                                "text"
                                                                                                            )
                                                                                                            .replace(
                                                                                                                /\s\s+/g,
                                                                                                                " "
                                                                                                            )
                                                                                                            .toUpperCase();

                                                                                                dataSource_tblAlmacen
                                                                                                    .store()
                                                                                                    .update(idAlmacen, {
                                                                                                        denominacion:
                                                                                                            denominacion,
                                                                                                    })
                                                                                                    .done(function (
                                                                                                        result
                                                                                                    ) {
                                                                                                        $.each(
                                                                                                            itemsLavanderiaSel.tblAlmacen,
                                                                                                            function (
                                                                                                                key,
                                                                                                                almacen
                                                                                                            ) {
                                                                                                                if (
                                                                                                                    almacen.idAlmacen ===
                                                                                                                    idAlmacen
                                                                                                                ) {
                                                                                                                    almacen.denominacion =
                                                                                                                        denominacion;
                                                                                                                }
                                                                                                            }
                                                                                                        );
                                                                                                        dxList_almacenesLavanderia.reload();
                                                                                                        dxTextBox_addAlmacen.reset();
                                                                                                    });
                                                                                            } else {
                                                                                                //INSERT
                                                                                                var nuevoAlmacen = {
                                                                                                    denominacion:
                                                                                                        dxTextBox_addAlmacen
                                                                                                            .option(
                                                                                                                "text"
                                                                                                            )
                                                                                                            .replace(
                                                                                                                /\s\s+/g,
                                                                                                                " "
                                                                                                            )
                                                                                                            .toUpperCase(),
                                                                                                    activo: true,
                                                                                                    idTipoAlmacen: 7,
                                                                                                    idLavanderia:
                                                                                                        LAVANDERIA.idLavanderia,
                                                                                                };
                                                                                                dataSource_tblAlmacen
                                                                                                    .store()
                                                                                                    .insert(
                                                                                                        nuevoAlmacen
                                                                                                    )
                                                                                                    .done(function (
                                                                                                        result
                                                                                                    ) {
                                                                                                        itemsLavanderiaSel.tblAlmacen.push(
                                                                                                            result
                                                                                                        );
                                                                                                        dxList_almacenesLavanderia.reload();
                                                                                                        dxTextBox_addAlmacen.reset();
                                                                                                    });
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                        ],
                                                                        onEnterKey: function (e) {
                                                                            $("#dxButton_addAlmacen")[0].click();
                                                                        },
                                                                    });
                                                                },
                                                            },
                                                            {
                                                                ratio: 1,
                                                                template: function () {
                                                                    return $("<div id='dxList_almacenesLavanderia'>")
                                                                        .css("padding-top", "15px")
                                                                        .dxList({
                                                                            dataSource: new DataSource({
                                                                                store: new ArrayStore({
                                                                                    data: itemsLavanderiaSel.tblAlmacen,
                                                                                }),
                                                                                filter: ["activo", "=", true],
                                                                            }),
                                                                            selectionMode: "single",
                                                                            height: "100%",
                                                                            width: "100%",
                                                                            keyExpr: "idAlmacen",
                                                                            pageLoadMode: "scrollBottom",
                                                                            allowItemDeleting: true,
                                                                            itemDeleteMode: "static",
                                                                            focusStateEnabled: false,
                                                                            showScrollbar: "always",
                                                                            // Eventos
                                                                            onContentReady: function (e) {
                                                                                $(
                                                                                    "#dxList_almacenesLavanderia .dx-list-static-delete-button"
                                                                                )
                                                                                    .empty()
                                                                                    .append(
                                                                                        svg_remove
                                                                                            .clone()
                                                                                            .attr(
                                                                                                "style",
                                                                                                "cursor: pointer; vertical-align:middle;"
                                                                                            )
                                                                                            .appendTo($("<img />"))
                                                                                    );
                                                                            },
                                                                            itemTemplate: function (
                                                                                data,
                                                                                index,
                                                                                element
                                                                            ) {
                                                                                return $("<div>").text(
                                                                                    data.denominacion
                                                                                );
                                                                            },
                                                                            onSelectionChanged: function (e) {
                                                                                var dxTextBox_addAlmacen =
                                                                                        $(
                                                                                            "#dxTextBox_addAlmacen"
                                                                                        ).dxTextBox("instance"),
                                                                                    dxButton_revertButton =
                                                                                        $(
                                                                                            "#dxButton_revertButton"
                                                                                        ).dxButton("instance"),
                                                                                    dxButton_addAlmacen =
                                                                                        $(
                                                                                            "#dxButton_addAlmacen"
                                                                                        ).dxButton("instance");

                                                                                if (e.addedItems.length > 0) {
                                                                                    dxTextBox_addAlmacen.option(
                                                                                        "value",
                                                                                        e.addedItems[0].denominacion
                                                                                    );
                                                                                    dxButton_revertButton.option(
                                                                                        "visible",
                                                                                        true
                                                                                    );
                                                                                    dxButton_addAlmacen.option(
                                                                                        "icon",
                                                                                        "save"
                                                                                    );
                                                                                } else {
                                                                                    dxTextBox_addAlmacen.option(
                                                                                        "value",
                                                                                        ""
                                                                                    );
                                                                                    dxButton_revertButton.option(
                                                                                        "visible",
                                                                                        false
                                                                                    );
                                                                                    dxButton_addAlmacen.option(
                                                                                        "icon",
                                                                                        "plus"
                                                                                    );
                                                                                }
                                                                            },
                                                                            onItemDeleting: function (e) {
                                                                                dxMensajePregunta(
                                                                                    getTrad(
                                                                                        "preg_RegistroEliminarCorto"
                                                                                    ),
                                                                                    [
                                                                                        // ACEPTAR
                                                                                        [
                                                                                            getTrad("aceptar"),
                                                                                            function () {
                                                                                                dataSource_tblAlmacen
                                                                                                    .store()
                                                                                                    .remove(
                                                                                                        e.itemData
                                                                                                            .idAlmacen
                                                                                                    )
                                                                                                    .done(function () {
                                                                                                        e.itemData.activo = false;
                                                                                                        e.component.reload();
                                                                                                    });
                                                                                            },
                                                                                            "normal",
                                                                                            "btnAceptar",
                                                                                        ],
                                                                                        // CANCELAR
                                                                                        [
                                                                                            getTrad("cancelar"),
                                                                                            function () {},
                                                                                            "normal",
                                                                                            "btnCancelar",
                                                                                        ],
                                                                                    ]
                                                                                );
                                                                                e.cancel = true;
                                                                            },
                                                                        });
                                                                },
                                                            },
                                                        ],
                                                    });
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
                onFieldDataChanged: function (e, b, c, d) {
                    var updatedField = e.dataField;

                    var lavanderiaGuardado = {};
                    lavanderiaGuardado[updatedField] = e.dataField === "telefono" ? e.value.toString() : e.value;
                    if ($("#dxForm_lavanderia").dxForm("instance").validate().isValid) {
                        if (e.dataField === "tblCorreosNLav") {
                            context_tblCorreosNLav.invoke("OverrideMasivo", {}, "POST").done(function (result) {
                                datasource_tblLavanderia_custom.load();
                            });
                        } else if (e.dataField === "tblBacsCarro") {
                            context_tblBacsCarro.invoke("OverrideMasivo", {}, "POST").done(function (result) {
                                datasource_tblLavanderia_custom.load();
                            });
                        } else if (e.dataField === "idAdmElementoPEP") {
                            dxMensajePregunta(getTrad("alert_elementoPEP_modificar"), [
                                // ACEPTAR
                                [getTrad("aceptar"), updateLavanderia, "normal", "btnAceptar"],
                                // CANCELAR
                                [getTrad("cancelar"), cancelarUpdate, "normal", "btnCancelar"],
                            ]);
                        } else {
                            updateLavanderia();
                        }

                        function updateLavanderia() {
                            datasource_lavanderia
                                .store()
                                .update(itemsLavanderiaSel.idLavanderia, lavanderiaGuardado)
                                .done(function (result) {
                                    datasource_tblLavanderia_custom.load().done(function (lavanderiaData) {
                                        $("#dxForm_lavanderia").dxForm("instance").updateData(lavanderiaData);

                                        const LAVANDERIAS = JSON.parse(localStorage.getItem("lavanderias"));

                                        var lavanderia = null;

                                        $.each(LAVANDERIAS, function (index, lav) {
                                            if (lav.idLavanderia === LAVANDERIA.idLavanderia) {
                                                var l = lavanderiaData[0];
                                                lav.denominacion = l.denominacion;
                                                lav.horarioVerano = l.horarioVerano;
                                                lav.gestionaRechazo = l.gestionaRechazo;
                                                lav.direccion = l.direccion;
                                                lav.telefono = l.telefono;

                                                lav.moneda = $.grep(itemsMoneda[0], function (m) {
                                                    return m.idMoneda === l.idMoneda;
                                                })[0].codigo;

                                                lav.monedaLocal = $.grep(itemsMoneda[0], function (m) {
                                                    return m.idMoneda === l.idMoneda;
                                                })[0].codigo;

                                                lav.GMT =
                                                    parseInt(
                                                        $.grep(itemsZonaHoraria[0], function (z) {
                                                            return z.idZonaHoraria === l.idZonaHoraria;
                                                        })[0].GMT
                                                    ) + (l.horarioVerano ? 1 : 0);

                                                lavanderia = lav;
                                                return false;
                                            }
                                        });

                                        localStorage.setItem("lavanderias", JSON.stringify(LAVANDERIAS));
                                        COMPONENT.setLavanderia(lavanderia);
                                    });
                                });
                        }

                        function cancelarUpdate() {
                            itemsLavanderiaSel[updatedField] = prevValue;
                            e.component.repaint();
                        }
                    }
                },
            });
        })
        .always(function () {
            deferred.resolve();
        });

    function monthToName(numeroMes) {
        var idioma = IDIOMA.codigo;
        numeroMes = parseInt(numeroMes);

        var mesNombre;
        if (idioma === "es") {
            mesNombre = [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre",
            ];
        } else {
            mesNombre = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
        }
        return mesNombre[numeroMes - 1];
    }

    return deferred.promise();
}

export function onSaveDireccion(update) {
    delete update.pais;

    const form = $("#Lavanderia #dxForm_lavanderia").dxForm("instance");

    form.updateData(update);
}
