import React from "react";
import { getTrad } from "helpers";
import { SelectBox } from "devextreme-react";

class SelectorEstadoSolicitudGestoria extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  setFiltroEstado = (e) => {
    const { onValueChange } = this.props;
    onValueChange({ estadoSel_aceptado: e.value });
  };

  render() {
    const { value } = this.props;

    return (
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="d-flex  justify-content-center align-items-center px-3 ">{getTrad("estadoDeSolicitud")}:</div>
        <div className="d-flex  justify-content-center align-items-center px-3 ">
          <SelectBox
            value={value}
            items={[
              { name: getTrad("validado"), value: true },
              { name: getTrad("pendiente"), value: false },
            ]}
            displayExpr="name"
            valueExpr="value"
            onValueChanged={this.setFiltroEstado}
          />
        </div>
      </div>
    );
  }
}

export default SelectorEstadoSolicitudGestoria;
