import React from "react";
import Tooltip from "devextreme-react/tooltip";
import { getTrad } from "helpers";

const TooltipDayEvents = ({ tooltipTarget, targetEvents }) => {
  const onContentReady = ({ component }) => {
    component
      .content()
      .css("padding", "0")
      .parent()
      .css("border-radius", "15px");
  };

  const content_render = () => {
    if (targetEvents !== null) {
      return targetEvents.map((e) => {
        const targetEventStyle = { backgroundColor: e.colorHexa };
        return (
          <div
            key={`targetEvent-${e.fecha}-${e.idEstado}`}
            className={"tooltipEvento px-5 py-3"}
            style={targetEventStyle}
          >
            <span className={"underline_bottom_dotted pb-2 mb-2"}>
              {getTrad(e.traduccion)}
            </span>
            {e.tblLavanderia
              .sort((a, b) => a.denominacion.localeCompare(b.denominacion))
              .map((l) => (
                <div key={`li-estado_${e.idEstado}-${l.idLavanderia}`}>
                  <span>- {l.denominacion}</span>
                </div>
              ))}
          </div>
        );
      });
    }

    return (
      <div className={"tooltipEvento px-5 py-3"}>
        <span>{getTrad("sinPrevisionesDia")}</span>
      </div>
    );
  };

  return (
    <Tooltip
      target={tooltipTarget}
      visible={tooltipTarget !== null}
      onContentReady={onContentReady}
    >
      <div>{content_render()}</div>
    </Tooltip>
  );
};

export default TooltipDayEvents;
