import { connectionConstants } from "../../../constants";
import {
  history,
  authHeader,
  errorHandler,
  getTrad,
  formatNumber,
  tooltipControl_creacion,
  dxMensajePregunta,
  formatDate,
  get_template_colorTapa,
} from "../../../helpers";
import {
  svg_edit,
  svg_remove,
  svg_remove_circle,
  svg_tick_circle,
  svg_elemTrans_ud,
  svg_elemTrans_bac,
  svg_calendar_remove,
} from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";
import ODataContext from "devextreme/data/odata/context";
import ArrayStore from "devextreme/data/array_store";
import notify from "devextreme/ui/notify";
import query from "devextreme/data/query";
import { locale } from "devextreme/localization";

var inventarioSel = null,
  inventarioPadreSel = null,
  array_subInventarios = [];

const get_datasource_spSelectInventarios = (
  COMPONENT,
  LAVANDERIA,
  onChange_inventarioSel,
) => {
  return new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "EF_inventarios_spSelectInventarios",
      key: "idInventario",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method !== "get")
          request.url = request.url.replace(
            "EF_inventarios_spSelectInventarios",
            "tblInventario",
          );
        else
          request.params.idLavanderia = LAVANDERIA
            ? LAVANDERIA.idLavanderia
            : null;
      },
      version: 4,
    }),
    postProcess: function (data) {
      if (inventarioSel) {
        //Actualizamos inventario sel
        inventarioSel = $.grep(data, function (inventario) {
          return inventarioSel.idInventario === inventario.idInventario;
        })[0];

        if (inventarioSel) {
          onChange_inventarioSel && onChange_inventarioSel(inventarioSel);

          //Actualizamos inventario padre sel en caso de estar en un subinventario
          if (inventarioSel.idInventarioPadre) {
            inventarioPadreSel = $.grep(data, function (inventario) {
              return (
                inventario.idInventario === inventarioSel.idInventarioPadre
              );
            })[0];
          }
        }
      }

      array_subInventarios = [];
      var filteredData = query(data)
        .filter(function (dataItem) {
          if (!dataItem.idInventarioPadre) return true;
          else array_subInventarios.push(dataItem);
          return false;
        })
        .toArray();
      return filteredData;
    },
  });
};

export default function cargaJs(
  COMPONENT,
  LAVANDERIA,
  IDIOMA,
  onChange_inventarioSel,
  onShow_PopupEdit_Inventario,
  onShow_PopupDocumentacion,
) {
  var deferred = $.Deferred();

  locale("es");
  var idEntidadSel_inventarioPorEntidad = null,
    almacenSel = {},
    params_datasource_informePrendas = {},
    entidadSel = null;

  //#region DATASOURCES
  //#region SELECTOR COMPAÑÍA - ENTIDAD
  var datasource_tblCompañia_inv = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.ODATA_URL + "tblCompañia",
        key: "idCompañia",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };

          request.params.idLavanderia = LAVANDERIA
            ? LAVANDERIA.idLavanderia
            : null;
        },
        version: 4,
      }),
      select: ["idCompañia", "denominacion", "activo"],
      sort: ["denominacion"],
    }),
    datasource_tblEntidad_inv = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.ODATA_URL + "tblEntidad",
        key: "idEntidad",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };

          request.params.idLavanderia = LAVANDERIA
            ? LAVANDERIA.idLavanderia
            : null;

          if (request.method === "get") {
            request.params.todas = true;
          }
        },
        version: 4,
      }),
      select: [
        "idEntidad",
        "denominacion",
        "idCompañia",
        "inventarioPorEntidad",
      ],
      expand: [
        "tblAlmacen($select=idAlmacen;$filter=activo eq true and eliminado eq false and idSeccionNivel2 ne null;$expand=tblPrendaNAlmacen($count=true;$top=0)), tblTipoHabitacion($select=idTipoHabitacion;$expand=tblPrendaNTipoHabitacion($count=true;$top=0)), tblLavanderia($select=idLavanderia,denominacion;$expand=tblAlmacen($select=idAlmacen;$filter = activo eq true and eliminado eq false))",
      ],
    }),
    datasource_compaEnti = new DataSource({
      paginate: false,
      store: new CustomStore({
        load: function (loadOptions) {
          var _deferred = $.Deferred();

          $.when(
            datasource_tblCompañia_inv.load(),
            datasource_tblEntidad_inv.load(),
          ).done(function () {
            var compañias = query(datasource_tblCompañia_inv.items())
              .filter(["activo", "=", true])
              .toArray();

            var entidades = datasource_tblEntidad_inv.items();
            var compaEnti = [];

            $.each(compañias, function (index, compañia) {
              var compa = $.extend(true, {}, compañia);

              compa.tblEntidad = $.grep(entidades, function (entidad) {
                return entidad.idCompañia === compañia.idCompañia;
              });
              compaEnti.push(compa);
            });
            _deferred.resolve(compaEnti);
          });
          return _deferred.promise();
        },
      }),
    });
  //#endregion

  var datasource_spSelectInventarios = get_datasource_spSelectInventarios(
    COMPONENT,
    LAVANDERIA,
    onChange_inventarioSel,
  );

  var datasource_spSelectPrendas_nuevoInventario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "inventarios_spSelectPrendas",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        var dxList_nuevoInventario_compañia = $(
            "#dxList_nuevoInventario_compañia",
          ).dxList("instance"),
          dxList_nuevoInventario_entidad = $(
            "#dxList_nuevoInventario_entidad",
          ).dxList("instance"),
          is_entiSel =
            dxList_nuevoInventario_entidad.option("selectedItems").length > 0;

        if (request.method === "get") {
          request.params.idCompañia =
            !idEntidadSel_inventarioPorEntidad && is_entiSel
              ? dxList_nuevoInventario_compañia.option("selectedItemKeys")[0]
              : null;
          request.params.idEntidad = is_entiSel
            ? idEntidadSel_inventarioPorEntidad
            : null;
        }
      },
      version: 4,
    }),
    sort: "codigoPrenda",
  });

  var context_tblInventario = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblInventario",
    entities: {
      inventarios_fnUpdateAplicarInventario: {
        key: "idInventario",
        keyType: "Int32",
      },
      inventarios_fnInsertInventario: {
        key: "idInventario",
        keyType: "Int32",
      },
      inventarios_fnInsertSubInventario: {
        key: "idInventario",
        keyType: "Int32",
      },
      inventarios_spInsertAddPrendas: {
        key: "idInventario",
        keyType: "Int32",
      },
      inventarios_spActualizarPrendas: {
        key: "idInventario",
        keyType: "Int32",
      },
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
      var entityName = request.url.substring(request.url.lastIndexOf("/") + 1);

      switch (entityName) {
        case "inventarios_fnInsertInventario":
          // Inventario
          if (!inventarioSel) {
            var inventario = formatDataInventario();

            request.params.incluirLavanderia = inventario
              ? inventario.incluirLavanderia
              : false;
            request.params.idsEntidad = inventario.idsEntidad;
            request.params.idsPrenda = inventario.idsPrenda;
            request.params.idsLavanderia = inventario.idsLavanderia;
            request.params.GMT = parseInt(LAVANDERIA.GMT);
            request.params.is_SubInv = false;

            request.payload = inventario.objInventario;
          }
          // SubInventario
          else {
            var subInventario = formatDataSubInventario();

            request.params.incluirLavanderia = true;
            request.params.idsEntidad = subInventario.idsEntidad;
            request.params.idsPrenda = subInventario.idsPrenda;
            request.params.idsLavanderia = subInventario.idsLavanderia;
            request.params.GMT = parseInt(LAVANDERIA.GMT);
            request.params.is_SubInv = true;

            request.payload = subInventario.objSubInventario;
          }
          break;
        case "inventarios_spInsertAddPrendas":
          let idsPrenda = $.map(
            $("#dxDataGrid_addPrendasInventario")
              .dxDataGrid("instance")
              .getSelectedRowKeys(),
            function (item, index) {
              return item;
            },
          ).join();

          request.params.idInventario = inventarioSel.idInventario;
          request.params.idsPrenda = "'" + idsPrenda + "'";
          break;
        case "inventarios_spActualizarPrendas":
          request.params.idInventario = inventarioSel.idInventario;
          break;
        default:
          break;
      }
    },
  });

  var enum_estadoInventario = [
      {
        value: 0,
        text: getTrad("cerrado"),
      },
      {
        value: 1,
        text: getTrad("abierto"),
      },
      {
        value: 2,
        text: getTrad("cerradoSubInvAbiertos"),
      },
    ],
    enum_estadoAplicado = [
      {
        value: 0,
        text: getTrad("noAplicado"),
      },
      {
        value: 1,
        text: getTrad("aplicado"),
      },
      {
        value: 2,
        text: getTrad("parcialmenteAplicado"),
      },
    ],
    enum_estadoAlmacen = [
      {
        value: 0,
        text: getTrad("noModificado"),
      },
      {
        value: 1,
        text: getTrad("modificado"),
      },
      {
        value: 2,
        text: getTrad("noMolestar"),
      },
    ],
    enum_colores = {
      rojo: "#BC2929",
      ambar: "#F4BC46",
      verde: "#5FBA46",
      violeta: "#A004FA",
      gris: "#737373",
      rojo_pastel: "#FFA4A4",
      ambar_pastel: "#F7D9C4",
      verde_pastel: "#B5F4C8",
      violeta_pastel: "#DBCDF0",
      gris_pastel: "#EDECEB",
    };

  //#region LISTAS SEL INVENTARIO
  var datasource_spSelectEntidadesNInventario = new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL +
          "EF_inventarios_spSelectEntidadesNInventario",
        key: "id",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          request.params.idInventario = inventarioSel.idInventario;
        },
        version: 4,
      }),
      sort: [{ field: "isLav", desc: true }, "denominacion"],
    }),
    datasource_datos_seccionNivel1 = new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL +
          "EF_inventarios_spSelectSeccionNivel1",
        key: "idSeccionNivel1",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          var dxList_entidadesNInventario = $(
              "#dxList_entidadesNInventario",
            ).dxList("instance"),
            entiNInvSel =
              typeof dxList_entidadesNInventario !== "undefined"
                ? dxList_entidadesNInventario.option("selectedItems")
                : [],
            idEntidad =
              entiNInvSel.length > 0 && !entiNInvSel[0].isLav
                ? dxList_entidadesNInventario.option("selectedItems")[0].id
                : -1;

          request.params.idInventario = inventarioSel.idInventario;
          request.params.idEntidad = idEntidad;
        },
        version: 4,
      }),
      select: ["idSeccionNivel1", "denominacion", "porcentaje"],
      sort: ["denominacion"],
    }),
    datasource_datos_seccionNivel2 = new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL +
          "EF_inventarios_spSelectSeccionNivel2",
        key: "idSeccionNivel2",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          var dxList_tblSeccionNivel1 = $("#dxList_tblSeccionNivel1").dxList(
              "instance",
            ),
            edificiosSel =
              typeof dxList_tblSeccionNivel1 !== "undefined"
                ? dxList_tblSeccionNivel1.option("selectedItems")
                : [],
            idSeccionNivel1 =
              edificiosSel.length > 0
                ? dxList_tblSeccionNivel1.option("selectedItems")[0]
                    .idSeccionNivel1
                : -1;

          request.params.idInventario = inventarioSel.idInventario;
          request.params.idSeccionNivel1 = idSeccionNivel1;
        },
        version: 4,
      }),
      select: ["idSeccionNivel2", "denominacion", "porcentaje"],
      sort: ["denominacion"],
    }),
    datasource_datos_tblAlmacenNInventario = new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL +
          "EF_inventarios_spSelectAlmacenNInventario",
        key: "idAlmacen",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };

          if (request.method === "get") {
            // Coger idSeccionNivel2
            var dxList_tblSeccionNivel2 = $("#dxList_tblSeccionNivel2").dxList(
                "instance",
              ),
              seccionSel =
                typeof dxList_tblSeccionNivel2 !== "undefined"
                  ? dxList_tblSeccionNivel2.option("selectedItems")
                  : [],
              idSeccionNivel2 =
                seccionSel.length > 0
                  ? dxList_tblSeccionNivel2.option("selectedItems")[0]
                      .idSeccionNivel2
                  : null;

            //Coger idLavanderia
            var dxList_entidadesNInventario = $(
                "#dxList_entidadesNInventario",
              ).dxList("instance"),
              entiNInvSel =
                typeof dxList_entidadesNInventario !== "undefined"
                  ? dxList_entidadesNInventario.option("selectedItems")
                  : [],
              idLavanderia =
                entiNInvSel.length > 0 && entiNInvSel[0].isLav
                  ? dxList_entidadesNInventario.option("selectedItems")[0].id
                  : null;

            request.params.idInventario = inventarioSel.idInventario;
            request.params.idLavanderia = idLavanderia;
            request.params.idSeccionNivel2 = idSeccionNivel2;
          } else {
            request.url = request.url.replace(
              "EF_inventarios_spSelectAlmacenNInventario",
              "tblAlmacenNInventario",
            );
          }
        },
        version: 4,
      }),
    });

  //#endregion

  var datasource_datos_tblPrendaNAlmacenNInventario = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.ODATA_URL + "tblPrendaNAlmacenNInventario",
        key: "idPrenda",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          request.params.idInventario = inventarioSel.idInventario;
          request.params.idAlmacen = almacenSel.idAlmacen;
        },
        version: 4,
        onLoaded: function (data) {
          array_prendasNAlmacenNInventario = [];
          $.map(data, function (prenda, index) {
            array_prendasNAlmacenNInventario.push(prenda);
          });
        },
      }),
      postProcess: function (data) {
        $.each(data, function (index, item) {
          item.parStock = !item.parStock ? 0 : item.parStock;
        });
        return data;
      },
      select: ["parStock", "cantidad", "idPrenda"],
      expand: [
        "tblPrenda($select=codigoPrenda,denominacion,udsXBacPedido,idEntidad;$expand=tblElemTransPedido($select=codigo,denominacion),tblColorTapa($select=codigoHexadecimal),tblMarcaTapa($select=denominacion))",
      ],
    }),
    array_prendasNAlmacenNInventario = [];

  var datasource_tblPrendasNInventario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrendaNInventario",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idInventario = inventarioSel.idInventario;
      },
      version: 4,
    }),
    select: [
      "idPrenda",
      "codigoPrenda",
      "denominacion",
      "udsXBacPedido",
      "idEntidad",
    ],
    filter: ["eliminado eq false and activo eq true"],
    sort: "codigoPrenda",
    expand: [
      "tblElemTransPedido($select=codigo,denominacion),tblColorTapa($select=codigoHexadecimal),tblMarcaTapa($select=denominacion)",
    ],
  });

  var datasource_informePrendas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "EF_inventarios_spSelectInformePrendas",
      key: ["idPrenda", "idInventario"],
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idInventario = inventarioSel
          ? inventarioSel.idInventario
          : -1;
        request.params.idInventarioPadre = inventarioSel
          ? inventarioSel.idInventarioPadre
          : -1;
        $.each(params_datasource_informePrendas, function (index, item) {
          request.params[index] = item;
        });
      },
      version: 4,
    }),
  });

  var datasource_prendasInfPrendas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrenda",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idCompañia = inventarioSel.idCompañia;
        request.params.idEntidad = inventarioSel.idEntidad;
        request.params.todas = true;
      },
      version: 4,
    }),
    select: ["idPrenda", "codigoPrenda", "denominacion"],
  });

  //#region SUBINVENTARIOS
  var datasource_prendaNSubInventario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "EF_inventarios_spSelectPrendaNSubInventario",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idInventario = inventarioSel.idInventario;
      },
      version: 4,
    }),
    sort: "codigoPrenda",
  });
  //#endregion
  var datasource_inventarios_spSelectAddPrendas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "EF_inventarios_spSelectAddPrendas",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idInventario = inventarioSel.idInventario;
      },
      version: 4,
    }),
    sort: "codigoPrenda",
  });

  //FIRMA
  var datasource_tblEntidadNInventario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidadNInventario",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idInventario = inventarioSel.idInventario;
        request.params.idEntidad = entidadSel.id;
      },
      version: 4,
    }),
    select: [
      "firmantePolarier",
      "firmanteCliente",
      "correoCliente",
      "fechaRegistro",
    ],
    expand: ["tblArchivo_firmaPolarier", "tblArchivo_firmaCliente"],
    postProcess: function (data) {
      $.each(data, function (index, item) {
        item.firmaPolarier = item.tblArchivo_firmaPolarier.archivo;
        item.firmaCliente = item.tblArchivo_firmaCliente.archivo;

        delete item.tblArchivo_firmaPolarier;
        delete item.tblArchivo_firmaCliente;
      });
      return data;
    },
  });

  //#endregion

  COMPONENT.setReport({
    denominacion: "inventarios_secciones",
    parameterEvent: CustomizeParameterEditors,
  });

  //#region LAYOUT
  $("#Inventarios #dxContainer").dxBox({
    direction: "col",
    height: "100%",
  });

  // POPUP ENTREGA
  $("#Inventarios #dxPopup_fechaCierre").dxPopup({
    showTitle: true,
    title: getTrad("fechaCierre"),
    height: 470,
    width: 425,
    contentTemplate: function () {
      var dxPopup = $("#dxPopup_fechaCierre").dxPopup("instance");
      var fechaCierre = dxPopup.option("fechaCierre");

      var container = $("<div>");
      var title = $(
        "<div class='font-size-xs pb-3'>" +
          getTrad("preg_FechaCierre") +
          "</div>",
      );
      var calendar = $(
        "<div id='dxCalendar_fechaCierre' style='margin:auto;'>",
      ).dxCalendar({
        disabled: false,
        value: fechaCierre,
        firstDayOfWeek: 1,
        zoomLevel: "month",
        minZoomLevel: "month",
      });

      title.appendTo(container);
      calendar.appendTo(container);

      return container;
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("actualizar"),
          icon: "check",
          type: "success",
          onClick: function (e, a) {
            var dxPopup = $("#dxPopup_fechaCierre").dxPopup("instance");
            var idInventario = dxPopup.option("idInventario");
            var fechaEntrega = $("#dxCalendar_fechaCierre")
              .dxCalendar("instance")
              .option("value");

            datasource_spSelectInventarios
              .store()
              .update(idInventario, { fechaCierre: fechaEntrega })
              .done(function () {
                dxPopup.hide();
                datasource_spSelectInventarios.reload();
                notify({
                  message: getTrad("aviso_C_RegistroActualizado"),
                  type: "success",
                  displayTime: "1500",
                  closeOnClick: true,
                });
              });
          },
        },
      },
    ],
    onShowing: function (e) {
      var dxPopup = e.component;
      var fechaCierre = dxPopup.option("fechaCierre");
      if ($("#dxCalendar_fechaCierre").length > 0) {
        $("#dxCalendar_fechaCierre")
          .dxCalendar("instance")
          .option("value", fechaCierre);
      }
    },
  });
  //#endregion
  $.when(datasource_tblCompañia_inv.load(), datasource_compaEnti.load())
    .then(function (itemsCompañias) {
      if (
        typeof $("#Inventarios #dxTabPanel_container").data("dxTabPanel") !==
        "undefined"
      ) {
        $("#Inventarios #dxTabPanel_container")
          .dxTabPanel("instance")
          .dispose();
      }

      var tabpanel = $("#Inventarios #dxTabPanel_container")
        .addClass("no-header")
        .dxTabPanel({
          height: "100%",
          width: "100%",
          animationEnabled: true,
          swipeEnabled: false,
          focusStateEnabled: false,
          items: [
            {
              template: function () {
                return $("<div />").dxBox({
                  direction: "col",
                  height: "100%",
                  items: [
                    {
                      ratio: 1,
                      template: function (itemData, itemIndex, itemElement) {
                        itemElement.append(
                          $("<div id='dxDataGrid_inventarios' />").dxDataGrid({
                            dataSource: datasource_spSelectInventarios,
                            //Propiedades
                            columnsAutoWidth: true,
                            remoteOperations: false,
                            height: "100%",
                            paging: {
                              pageSize: 50,
                            },
                            pager: {
                              showPageSizeSelector: true,
                              allowedPageSizes: [20, 50, 100],
                              showInfo: true,
                            },
                            headerFilter: {
                              visible: true,
                            },
                            searchPanel: {
                              visible: true,
                              width: 240,
                              placeholder: getTrad("busqueda"),
                            },
                            filterRow: {
                              visible: true,
                              applyFilter: "auto",
                            },
                            columns: [
                              {
                                caption: " ",
                                width: 60,
                                alignment: "center",
                                cellTemplate: function (container, options) {
                                  svg_edit
                                    .clone()
                                    .css({
                                      height: 23,
                                      width: 23,
                                      cursor: "pointer",
                                      "vertical-align": "middle",
                                    })
                                    .on("click", function () {
                                      inventarioSel = options.data;
                                      if (
                                        typeof $("#dxToolbar_btnAtras").data(
                                          "dxToolbar",
                                        ) !== "undefined"
                                      ) {
                                        $("#dxToolbar_btnAtras")
                                          .dxToolbar("instance")
                                          .repaint();

                                        //limpiar searchBox listas
                                        var dxList_tblSeccionNivel1 = $(
                                            "#dxList_tblSeccionNivel1",
                                          ).dxList("instance"),
                                          dxList_tblSeccionNivel2 = $(
                                            "#dxList_tblSeccionNivel2",
                                          ).dxList("instance");

                                        $("#dxList_entidadesNInventario")
                                          .dxList("instance")
                                          .option("searchValue", null);
                                        dxList_tblSeccionNivel1.option(
                                          "searchValue",
                                          null,
                                        );
                                        dxList_tblSeccionNivel2.option(
                                          "searchValue",
                                          null,
                                        );

                                        dxList_tblSeccionNivel1.option(
                                          "dataSource",
                                          [],
                                        );
                                        dxList_tblSeccionNivel2.option(
                                          "dataSource",
                                          [],
                                        );
                                      }

                                      actualizarPercentageInventario(
                                        inventarioSel,
                                      );
                                      tabpanel.option("selectedIndex", 1);
                                      onChange_inventarioSel(inventarioSel);
                                    })
                                    .appendTo(container);
                                },
                              },
                              {
                                dataField: "fecha",
                                dataType: "date",
                                sortOrder: "desc",
                                alignment: "center",
                                format: "dd/MM/yyyy",
                                caption: getTrad("fecha"),
                                width: 160,
                                allowHeaderFiltering: false,
                              },
                              {
                                dataField: "idCompañiaFiltro",
                                caption: getTrad("compañia"),
                                width: 200,
                                alignment: "left",
                                lookup: {
                                  dataSource: itemsCompañias[0],
                                  displayExpr: "denominacion",
                                  valueExpr: "idCompañia",
                                },
                                allowHeaderFiltering: false,
                              },
                              {
                                dataField: "idCompañia",
                                visible: false,
                              },
                              {
                                dataField: "denominacion",
                                caption: getTrad("denominacion"),
                                minWidth: 250,
                                allowHeaderFiltering: false,
                              },
                              {
                                caption: getTrad("invCierre"),
                                dataField: "fechaCierre",
                                width: 120,
                                alignment: "center",
                                cssClass: "p-0",
                                cellTemplate: function (cellElement, cellInfo) {
                                  cellElement.append(
                                    svg_calendar_remove
                                      .clone()
                                      .css({
                                        height: 23,
                                        width: 23,
                                        cursor: "pointer",
                                        "vertical-align": "middle",
                                        fill: cellInfo.value
                                          ? "var(--secondary)"
                                          : "rgb(230,230,230)",
                                      })
                                      .on("click", function () {
                                        var dxPopup_fechaCierre = $(
                                          "#dxPopup_fechaCierre",
                                        ).dxPopup("instance");
                                        dxPopup_fechaCierre.option(
                                          "idInventario",
                                          cellInfo.row.data.idInventario,
                                        );
                                        dxPopup_fechaCierre.option(
                                          "fechaCierre",
                                          cellInfo.value
                                            ? cellInfo.value
                                            : new Date(),
                                        );
                                        dxPopup_fechaCierre.show();
                                      }),
                                  );
                                },
                              },
                              {
                                dataField: "porcentaje",
                                caption: getTrad("progreso"),
                                alignment: "center",
                                width: 120,
                                hidingPriority: 0,
                                allowHeaderFiltering: false,
                                allowFiltering: false,
                                cssClass: "p-0",
                                cellTemplate: function (container, options) {
                                  if (options.data.estadoTotal === 1) {
                                    $("<div />")
                                      .attr("style", "padding: 0 10px 0 10px")
                                      .dxProgressBar({
                                        min: 0,
                                        max: 1,
                                        value: options.data.porcentaje,
                                        showStatus: true,
                                        statusFormat: function (ratio, value) {
                                          return formatNumber(
                                            ratio,
                                            (ratio * 100) % 1 !== 0 ? 2 : 0,
                                            "percent",
                                          );
                                        },
                                        onContentReady: function (e) {
                                          e.element
                                            .find(".dx-progressbar-range")
                                            .css({
                                              "border-radius": 2,
                                              "padding-rigth": 1,
                                            });
                                        },
                                      })
                                      .appendTo(container);
                                  }
                                },
                              },
                              {
                                dataField: "estadoTotal",
                                caption: getTrad("estado"),
                                width: 100,
                                alignment: "center",
                                cssClass: "p-0",
                                cellTemplate: function (container, options) {
                                  var estadoImg = {};
                                  switch (options.data.estadoTotal) {
                                    case 0:
                                      estadoImg = {
                                        color: enum_colores["rojo_pastel"],
                                      };
                                      break;
                                    case 1:
                                      estadoImg = {
                                        color: enum_colores["verde_pastel"],
                                      };
                                      break;
                                    case 2:
                                      estadoImg = {
                                        color: enum_colores["ambar_pastel"],
                                      };
                                      break;
                                    default:
                                      estadoImg = {
                                        color: enum_colores["gris_pastel"],
                                      };
                                      break;
                                  }

                                  $("<div />")
                                    .text(
                                      (options.data.estadoTotal === 1
                                        ? getTrad("abierto")
                                        : getTrad("finalizado")
                                      ).toUpperCase(),
                                    )
                                    // estadoImg.svg
                                    // .clone()
                                    .css({
                                      "background-color": estadoImg.color,
                                      "vertical-align": "middle",
                                      cursor: "pointer",
                                      "border-radius": "25px",
                                      padding: "2px 8px",
                                      display: "inline-block",
                                      width: "100%",
                                    })

                                    .on("click", function () {
                                      var dataSource = $.grep(
                                        array_subInventarios,
                                        function (inventario) {
                                          return (
                                            inventario.idInventarioPadre ===
                                            options.data.idInventario
                                          );
                                        },
                                      );
                                      dataSource.push(options.data);

                                      $("#dxDataGrid_estadosInventario")
                                        .dxDataGrid("instance")
                                        .option("dataSource", dataSource);
                                      $("#dxPopup_estadoInventarios")
                                        .dxPopup("instance")
                                        .show();
                                    })
                                    .appendTo(container);
                                },
                                headerFilter: {
                                  dataSource: enum_estadoInventario,
                                },
                                allowHeaderFiltering: true,
                                allowFiltering: false,
                              },
                              {
                                caption: " ",
                                width: 60,
                                alignment: "center",
                                cellTemplate: function (container, options) {
                                  svg_remove
                                    .clone()
                                    .css({
                                      height: 23,
                                      width: 23,
                                      cursor: "pointer",
                                      "vertical-align": "middle",
                                    })
                                    .on("click", function () {
                                      options.component.deleteRow(
                                        options.rowIndex,
                                      );
                                    })
                                    .appendTo(container);
                                },
                              },
                            ],
                            //Eventos
                            onRowRemoving: function (e) {
                              datasource_spSelectInventarios
                                .store()
                                .remove(e.data.idInventario);
                            },
                            onToolbarPreparing: function (e) {
                              e.toolbarOptions.items.unshift(
                                {
                                  location: "before",
                                  widget: "dxButton",
                                  toolbar: "bottom",
                                  options: {
                                    width: "100%",
                                    icon: "plus",
                                    text: getTrad("nuevoInventario"),
                                    type: "normal",
                                    onClick: function () {
                                      $("#dxPopup_nuevoInventario")
                                        .dxPopup("instance")
                                        .show();
                                    },
                                  },
                                },
                                {
                                  location: "after",
                                  widget: "dxButton",
                                  showText: "inMenu",
                                  locateInMenu: "auto",
                                  options: {
                                    icon: "refresh",
                                    text: getTrad("actualizar"),
                                    onInitialized: function (e) {
                                      tooltipControl_creacion(
                                        e.element,
                                        e.component.option("text"),
                                      );
                                    },
                                    onClick: function () {
                                      datasource_spSelectInventarios.reload();
                                    },
                                  },
                                },
                                {
                                  location: "after",
                                  widget: "dxButton",
                                  locateInMenu: "auto",
                                  options: {
                                    text: getTrad("limpiarFiltro"),
                                    icon: " icon_EliminarFiltros",
                                    onClick: function () {
                                      e.component.clearFilter();
                                      notify({
                                        message: getTrad(
                                          "aviso_C_FiltroRestaurado",
                                        ),
                                        type: "success",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                      });
                                    },
                                  },
                                },
                              );
                            },
                            //Estilos
                            showColumnLines: false,
                            showRowLines: true,
                            rowAlternationEnabled: true,
                          }),
                        );
                      },
                    },
                  ],
                });
              },
            },
            {
              template: function () {
                return $("<div />").dxBox({
                  direction: "col",
                  height: "100%",
                  items: [
                    {
                      baseSize: 60,
                      shrink: 0,
                      ratio: 0,
                      template: function (itemData, itemIndex, itemElement) {
                        if (inventarioSel) {
                          var widthBarraPorcentaje = formatNumber(
                            inventarioSel.porcentaje,
                            (inventarioSel.porcentaje * 100) % 1 !== 0 ? 2 : 0,
                            "percent",
                          )
                            .replace(/\s/g, "")
                            .replace(",", "."); // QUITA EL ESPACIO EN BLANCO 0 %Y REEMPLAZA LAS COMAS PARA QUE DECIMALES NO PETEN

                          // CONTENEDOR PRINCIPAL
                          $("<div class='contenedorTituloInv' />")
                            .append(
                              // NOMBRE INVENTARIO + FECHA
                              $("<div id='nombreInventarioContainer' />")
                                .css({ height: 48 })
                                .addClass("d-flex align-items-center")
                                .append(
                                  $(
                                    "<span id='tit_nombre-fecha_Inventario' />",
                                  ),
                                  $("<span id='btnEdit_nombreInventario' />"),
                                ),
                              //CONTENEDOR PORCENTAJE
                              $("<div id='porcentajeContainer' />")
                                .css(
                                  "border-left",
                                  "3px solid " +
                                    colorProgressBar(inventarioSel.porcentaje),
                                )
                                .append(
                                  $("<div id='porcentajeValue' />")
                                    .css(
                                      // BARRA INDICADORA DEL PORCENTAJE
                                      {
                                        "background-color": colorProgressBar(
                                          inventarioSel.porcentaje,
                                        ),
                                        width: widthBarraPorcentaje,
                                      },
                                    )
                                    .append(
                                      // TEXTO %
                                      $(
                                        "<span id='porcentajeInventarioText' />",
                                      ).text(
                                        formatNumber(
                                          inventarioSel.porcentaje,
                                          (inventarioSel.porcentaje * 100) %
                                            1 !==
                                            0
                                            ? 2
                                            : 0,
                                          "percent",
                                        ),
                                      ),
                                    ),
                                ),
                            )
                            .appendTo(itemElement);
                        }
                      },
                    },
                    {
                      baseSize: 50,
                      shrink: 0,
                      ratio: 0,
                      template: function (itemData, itemIndex, itemElement) {
                        itemElement.css("padding-top", "15px");
                        $(itemElement[0]).css("align-self", "center");
                        return $("<div id='dxToolbar_btnAtras' />").dxToolbar({
                          items: [
                            {
                              location: "before",
                              widget: "dxButton",
                              options: {
                                elementAttr: { id: "dxButton_atras" },
                                text: getTrad("atras"),
                                icon: "chevronleft",
                                onClick: function () {
                                  if (!inventarioSel.idInventarioPadre) {
                                    inventarioSel = null;
                                    tabpanel.option("selectedIndex", 0);
                                  } else {
                                    inventarioSel = $.extend(
                                      true,
                                      {},
                                      inventarioPadreSel,
                                    );
                                    inventarioPadreSel = null;
                                    pantallaInvPorDefecto();
                                  }
                                  onChange_inventarioSel(inventarioSel);
                                },
                              },
                            },
                            {
                              location: "before",
                              widget: "dxButton",
                              options: {
                                elementAttr: {
                                  id: "dxButton_visualizarPrendas",
                                },
                                text: getTrad("visualizarPrendas"),
                                //icon: "plus",
                                onClick: function () {
                                  $("#dxPopup_visualizarPrendas")
                                    .dxPopup("instance")
                                    .show();
                                },
                              },
                            },
                            {
                              location: "before",
                              widget: "dxButton",
                              options: {
                                elementAttr: {
                                  id: "dxButton_actualizarPrendas",
                                },
                                text: getTrad("actualizarPrendas"),
                                onClick: function () {
                                  context_tblInventario
                                    .invoke(
                                      "inventarios_spActualizarPrendas",
                                      {},
                                      "GET",
                                    )
                                    .done(function () {
                                      datasource_datos_tblAlmacenNInventario.reload();
                                      notify({
                                        message: getTrad(
                                          "aviso_C_RegistroActualizado",
                                        ),
                                        type: "success",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                      });
                                    });
                                },
                              },
                            },
                            {
                              location: "center",
                              template: function () {
                                return $("<h2 id='subInvTitulo' />");
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              showText: "inMenu",
                              locateInMenu: "auto",
                              options: {
                                icon: "refresh",
                                text: getTrad("actualizar"),
                                onInitialized: function (e) {
                                  tooltipControl_creacion(
                                    e.element,
                                    e.component.option("text"),
                                  );
                                },
                                onClick: function () {
                                  $.when(
                                    datasource_spSelectInventarios.reload(),
                                    datasource_spSelectEntidadesNInventario.load(),
                                    datasource_datos_seccionNivel1.load(),
                                    datasource_datos_seccionNivel2.load(),
                                    datasource_datos_tblAlmacenNInventario.reload(),
                                  ).then(function () {
                                    actualizarPercentageInventario(
                                      inventarioSel,
                                    );

                                    if (
                                      typeof $(
                                        "#dxDataGrid_tblAlmacenNInv",
                                      ).data("dxDataGrid") !== "undefined"
                                    )
                                      $("#dxDataGrid_tblAlmacenNInv")
                                        .dxDataGrid("instance")
                                        .repaint();
                                  });
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              options: {
                                elementAttr: { id: "dxButton_nuevoSubInv" },
                                text: getTrad("subinventarios"),
                                onClick: function () {
                                  var dxList_entidadesNInventario = $(
                                      "#dxList_entidadesNInventario",
                                    ).dxList("instance"),
                                    dxList_tblSeccionNivel1 = $(
                                      "#dxList_tblSeccionNivel1",
                                    ).dxList("instance"),
                                    dxList_tblSeccionNivel2 = $(
                                      "#dxList_tblSeccionNivel2",
                                    ).dxList("instance");

                                  if (!inventarioSel.idInventarioPadre) {
                                    $("#dxPopup_nuevoSubInventario")
                                      .dxPopup("instance")
                                      .show();
                                    dxList_entidadesNInventario.option(
                                      "searchValue",
                                      null,
                                    );
                                    dxList_tblSeccionNivel1.option(
                                      "searchValue",
                                      null,
                                    );
                                    dxList_tblSeccionNivel2.option(
                                      "searchValue",
                                      null,
                                    );
                                  } else {
                                    dxMensajePregunta(
                                      getTrad("preg_AplicarSubInventario"),
                                      [
                                        // ACEPTAR
                                        [
                                          getTrad("aceptar"),
                                          function () {
                                            datasource_prendaNSubInventario
                                              .load()
                                              .done(function () {
                                                $(
                                                  "#dxPopup_tblPrendasSubInv_validar",
                                                )
                                                  .dxPopup("instance")
                                                  .show();
                                              });
                                            dxList_entidadesNInventario.option(
                                              "searchValue",
                                              null,
                                            );
                                            dxList_tblSeccionNivel1.option(
                                              "searchValue",
                                              null,
                                            );
                                            dxList_tblSeccionNivel2.option(
                                              "searchValue",
                                              null,
                                            );
                                          },
                                          "normal",
                                          "btnAceptar",
                                        ],
                                        // CANCELAR
                                        [
                                          getTrad("cancelar"),
                                          function () {},
                                          "normal",
                                          "btnCancelar",
                                        ],
                                      ],
                                    );
                                  }
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              options: {
                                elementAttr: { id: "dxButton_documentacion" },
                                text: getTrad("documentacion"),
                                onClick: onShow_PopupDocumentacion,
                              },
                            },
                          ],
                          onContentReady: function () {
                            if (
                              typeof $("#dxButton_atras").data("dxButton") !==
                              "undefined"
                            ) {
                              $("#dxButton_atras")
                                .dxButton("instance")
                                .option(
                                  "text",
                                  inventarioSel.idInventarioPadre
                                    ? getTrad("volverInventarioPrincipal")
                                    : getTrad("atras"),
                                );
                              $("#dxButton_nuevoSubInv")
                                .dxButton("instance")
                                .option(
                                  "text",
                                  inventarioSel.idInventarioPadre
                                    ? getTrad("validar")
                                    : getTrad("subinventarios"),
                                );
                            }
                            if (inventarioSel) {
                              var subInvTitulo = inventarioSel.idInventarioPadre
                                ? getTrad("subInventario")
                                : "";
                              $("#subInvTitulo").text(subInvTitulo);
                            }
                          },
                        });
                      },
                    },
                    {
                      ratio: 1,
                      template: function (itemData, itemIndex, itemElement) {
                        itemElement.css("padding-top", "15px");
                        itemElement.append(
                          $("<div />").dxBox({
                            direction: "row",
                            height: "100%",
                            items: [
                              {
                                ratio: 4,
                                //ENTIDADES
                                template: function (
                                  itemData,
                                  itemIndex,
                                  itemElement,
                                ) {
                                  itemElement.css("padding-right", "15px");
                                  itemElement.append(
                                    $("<div/>").dxBox({
                                      direction: "col",
                                      align: "space-around",
                                      crossAlign: "stretch",
                                      height: "100%",
                                      width: "100%",
                                      items: [
                                        {
                                          baseSize: 40,
                                          shrink: 0,
                                          ratio: 0,
                                          template: function (
                                            itemData,
                                            itemIndex,
                                            itemElement,
                                          ) {
                                            itemElement.append(
                                              $("<div />").dxToolbar({
                                                items: [
                                                  {
                                                    location: "before",
                                                    template: function () {
                                                      return $(
                                                        "<div class='font-size-xl pb-3' />",
                                                      ).text(
                                                        getTrad("entidades"),
                                                      );
                                                    },
                                                  },
                                                  {
                                                    location: "after",
                                                    template: function () {
                                                      return $(
                                                        "<div />",
                                                      ).dxButton({
                                                        text: getTrad(
                                                          "informePrendas",
                                                        ),
                                                        width: "100%",
                                                        onClick: function (e) {
                                                          $(
                                                            "#dxList_entidadesNInventario",
                                                          )
                                                            .dxList("instance")
                                                            .option(
                                                              "searchValue",
                                                              null,
                                                            );
                                                          params_datasource_informePrendas =
                                                            {
                                                              idEntidad: null,
                                                              idSeccionNivel1:
                                                                null,
                                                              idSeccionNivel2:
                                                                null,
                                                              idLavanderia:
                                                                null,
                                                            };

                                                          var dxPivotGrid_informePrendas =
                                                            $(
                                                              "#dxPivotGrid_informePrendas",
                                                            ).dxPivotGrid(
                                                              "instance",
                                                            );
                                                          dxPivotGrid_informePrendas.option(
                                                            "dataSource.store",
                                                            datasource_informePrendas.store(),
                                                          );
                                                          dxPivotGrid_informePrendas.repaint();

                                                          $(
                                                            "#dxPopup_informePrendas_title",
                                                          ).text(
                                                            getTrad(
                                                              "lavanderiaCompleta",
                                                            ),
                                                          );
                                                          $(
                                                            "#dxPopup_informePrendas",
                                                          )
                                                            .dxPopup("instance")
                                                            .show();
                                                        },
                                                      });
                                                    },
                                                  },
                                                  {
                                                    location: "after",
                                                    template: function () {
                                                      return $(
                                                        "<div />",
                                                      ).dxButton({
                                                        icon: " icon_Impresora",
                                                        width: "100%",
                                                        onInitialized:
                                                          function (e) {
                                                            tooltipControl_creacion(
                                                              e.element,
                                                              getTrad(
                                                                "imprimir",
                                                              ),
                                                            );
                                                          },
                                                        onClick: function (e) {
                                                          var dxList_entidadesNInventario =
                                                            $(
                                                              "#dxList_entidadesNInventario",
                                                            ).dxList(
                                                              "instance",
                                                            );
                                                          dxList_entidadesNInventario.option(
                                                            "searchValue",
                                                            null,
                                                          );

                                                          var entidadesNInv_extendData =
                                                            $.extend(
                                                              true,
                                                              {},
                                                              dxList_entidadesNInventario
                                                                .getDataSource()
                                                                .items(),
                                                            );
                                                          var filteredData = [];
                                                          $.each(
                                                            entidadesNInv_extendData,
                                                            function (
                                                              index,
                                                              item,
                                                            ) {
                                                              if (!item.isLav)
                                                                filteredData.push(
                                                                  item,
                                                                );
                                                            },
                                                          );
                                                          $("#dxList_impresion")
                                                            .dxList("instance")
                                                            .option({
                                                              dataSource:
                                                                filteredData,
                                                              selectedItems: [],
                                                            });

                                                          var dxPopup_impresion =
                                                            $(
                                                              "#dxPopup_impresion",
                                                            ).dxPopup(
                                                              "instance",
                                                            );
                                                          dxPopup_impresion.option(
                                                            "title",
                                                            getTrad(
                                                              "impresionEntidades",
                                                            ),
                                                          );
                                                          dxPopup_impresion.show();
                                                        },
                                                      });
                                                    },
                                                  },
                                                ],
                                              }),
                                            );
                                          },
                                        },
                                        {
                                          ratio: 2,
                                          template: function () {
                                            return $(
                                              "<div id='dxList_entidadesNInventario'>",
                                            ).dxList({
                                              height: "100%",
                                              dataSource:
                                                datasource_spSelectEntidadesNInventario,
                                              selectionMode: "single",
                                              scrolling: {
                                                mode: "infinite",
                                              },
                                              searchEnabled: true,
                                              searchExpr: ["denominacion"],
                                              displayExpr: "denominacion",
                                              keyExpr: ["id", "isLav"],
                                              pageLoadMode: "scrollBottom",
                                              itemTemplate: function (
                                                itemData,
                                                index,
                                                itemElement,
                                              ) {
                                                var listHeight =
                                                  ($(
                                                    "#dxList_entidadesNInventario",
                                                  )[0].clientHeight -
                                                    36) /
                                                  7; // Tienen que caber 7 por defecto , los 36px son del buscador
                                                itemElement.addClass(
                                                  "container_spanCentrado",
                                                );
                                                itemElement.css({
                                                  display: "flex",
                                                  "max-height": 90,
                                                  height: listHeight,
                                                });

                                                var color = colorProgressBar(
                                                  itemData.porcentaje,
                                                );

                                                function abrirFirmas(e) {
                                                  entidadSel = itemData;

                                                  datasource_tblEntidadNInventario
                                                    .reload()
                                                    .done(function () {
                                                      let popup =
                                                        $(
                                                          "#dxPopup_firmas",
                                                        ).dxPopup("instance");
                                                      popup.option({
                                                        contentTemplate: (e) =>
                                                          dxPopup_firmas_ContentTemplate(
                                                            e,
                                                          ),
                                                      });
                                                      popup.show();
                                                    });
                                                }

                                                if (itemData.isCerrado) {
                                                  itemElement.append(
                                                    $("<div />")
                                                      .css({
                                                        "font-size": "20px",
                                                        color: "green",
                                                      })
                                                      .addClass("dx-icon-check")
                                                      .on("click", abrirFirmas),
                                                  );
                                                }
                                                itemElement.append(
                                                  $("<div />")
                                                    .css({
                                                      flex: "1",
                                                      "font-size": "16px",
                                                      padding: "12px 5px",
                                                      "border-bottom":
                                                        "2px solid " + color,
                                                    })
                                                    .text(
                                                      itemData.denominacion,
                                                    ),
                                                );
                                                itemElement.append(
                                                  $("<div />")
                                                    .css({
                                                      width: 86,
                                                      "text-align": "center",
                                                      padding: "8px",
                                                      "background-color": color,
                                                      color: "white",
                                                      "border-radius":
                                                        "3px 3px 3px 0",
                                                      "font-size": "24px",
                                                    })
                                                    .text(
                                                      formatNumber(
                                                        itemData.porcentaje,
                                                        (itemData.porcentaje *
                                                          100) %
                                                          1 !==
                                                          0
                                                          ? 1
                                                          : 0,
                                                        "percent",
                                                      ),
                                                    ),
                                                );
                                              },
                                              onSelectionChanged: function (e) {
                                                var dxButton_infPrendas_seccionNivel1 =
                                                    $(
                                                      "#dxButton_infPrendas_seccionNivel1",
                                                    ).dxButton("instance"),
                                                  dxButton_impresion_seccionNivel1 =
                                                    $(
                                                      "#dxButton_impresion_seccionNivel1",
                                                    ).dxButton("instance"),
                                                  dxButton_infPrendas_seccionNivel2 =
                                                    $(
                                                      "#dxButton_infPrendas_seccionNivel2",
                                                    ).dxButton("instance"),
                                                  dxButton_impresion_seccionNivel2 =
                                                    $(
                                                      "#dxButton_impresion_seccionNivel2",
                                                    ).dxButton("instance"),
                                                  dxTabPanel_seccionAlmacen = $(
                                                    "#dxTabPanel_seccionAlmacen",
                                                  ).dxTabPanel("instance");

                                                if (e.addedItems.length > 0) {
                                                  if (e.addedItems[0].isLav) {
                                                    entidadSel = null;

                                                    $(
                                                      "#dxList_tblSeccionNivel1",
                                                    )
                                                      .dxList("instance")
                                                      .unselectAll();
                                                    $(
                                                      "#dxList_tblSeccionNivel2",
                                                    )
                                                      .dxList("instance")
                                                      .unselectAll();

                                                    if (
                                                      typeof $(
                                                        "#dxDataGrid_tblAlmacenNInv",
                                                      ).data("dxDataGrid") !==
                                                      "undefined"
                                                    )
                                                      datasource_datos_tblAlmacenNInventario.reload();

                                                    $(
                                                      "#dxList_tblSeccionNivel1",
                                                    )
                                                      .dxList("instance")
                                                      .option("dataSource", []);

                                                    dxTabPanel_seccionAlmacen.option(
                                                      "selectedIndex",
                                                      1,
                                                    );
                                                    dxButton_infPrendas_seccionNivel1.option(
                                                      "disabled",
                                                      true,
                                                    );
                                                    dxButton_impresion_seccionNivel1.option(
                                                      "disabled",
                                                      true,
                                                    );
                                                  } else {
                                                    entidadSel =
                                                      e.addedItems[0];

                                                    dxButton_infPrendas_seccionNivel1.option(
                                                      "disabled",
                                                      false,
                                                    );
                                                    dxButton_impresion_seccionNivel1.option(
                                                      "disabled",
                                                      false,
                                                    );
                                                    dxTabPanel_seccionAlmacen.option(
                                                      "selectedIndex",
                                                      0,
                                                    );
                                                    datasource_datos_seccionNivel1
                                                      .load()
                                                      .done(function () {
                                                        $(
                                                          "#dxList_tblSeccionNivel1",
                                                        )
                                                          .dxList("instance")
                                                          .option(
                                                            "dataSource",
                                                            datasource_datos_seccionNivel1,
                                                          );
                                                      });
                                                  }
                                                } else {
                                                  dxButton_infPrendas_seccionNivel1.option(
                                                    "disabled",
                                                    true,
                                                  );
                                                  dxButton_impresion_seccionNivel1.option(
                                                    "disabled",
                                                    true,
                                                  );
                                                  $("#dxList_tblSeccionNivel1")
                                                    .dxList("instance")
                                                    .option("dataSource", []);
                                                }
                                                dxButton_infPrendas_seccionNivel2.option(
                                                  "disabled",
                                                  true,
                                                );
                                                dxButton_impresion_seccionNivel2.option(
                                                  "disabled",
                                                  true,
                                                );
                                              },
                                            });
                                          },
                                        },
                                      ],
                                    }),
                                  );
                                },
                              },
                              {
                                ratio: 10,
                                template: function (
                                  itemData,
                                  itemIndex,
                                  itemElement,
                                ) {
                                  return $(
                                    "<div id='dxTabPanel_seccionAlmacen'/>",
                                  ).dxTabPanel({
                                    height: "100%",
                                    width: "100%",
                                    animationEnabled: true,
                                    swipeEnabled: false,
                                    items: [
                                      {
                                        title: getTrad("edificiosSecciones"),
                                        template: function (
                                          itemData,
                                          itemIndex,
                                          itemElement,
                                        ) {
                                          itemElement.css("padding-right", 0);
                                          itemElement.append(
                                            $("<div/>").dxBox({
                                              direction: "row",
                                              align: "space-around",
                                              crossAlign: "stretch",
                                              height: "100%",
                                              items: [
                                                {
                                                  ratio: 1,
                                                  template: function (
                                                    e,
                                                    index,
                                                    item,
                                                  ) {
                                                    //EDIFICIOS
                                                    item.append(
                                                      $("<div/>").dxBox({
                                                        direction: "col",
                                                        align: "space-around",
                                                        crossAlign: "stretch",
                                                        height: "100%",
                                                        width: "100%",
                                                        items: [
                                                          {
                                                            baseSize: 40,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function (
                                                              e,
                                                              index,
                                                              item,
                                                            ) {
                                                              item.append(
                                                                $(
                                                                  "<div />",
                                                                ).dxToolbar({
                                                                  items: [
                                                                    {
                                                                      location:
                                                                        "before",
                                                                      template:
                                                                        function () {
                                                                          return $(
                                                                            "<div class='font-size-xl pb-3' />",
                                                                          ).text(
                                                                            getTrad(
                                                                              "edificios",
                                                                            ),
                                                                          );
                                                                        },
                                                                    },
                                                                    {
                                                                      location:
                                                                        "after",
                                                                      template:
                                                                        function () {
                                                                          return $(
                                                                            "<div />",
                                                                          ).dxButton(
                                                                            {
                                                                              text: getTrad(
                                                                                "informePrendas",
                                                                              ),
                                                                              width:
                                                                                "100%",
                                                                              disabled: true,
                                                                              elementAttr:
                                                                                {
                                                                                  id: "dxButton_infPrendas_seccionNivel1",
                                                                                },
                                                                              onClick:
                                                                                function (
                                                                                  e,
                                                                                ) {
                                                                                  $(
                                                                                    "#dxList_tblSeccionNivel1",
                                                                                  )
                                                                                    .dxList(
                                                                                      "instance",
                                                                                    )
                                                                                    .option(
                                                                                      "searchValue",
                                                                                      null,
                                                                                    );
                                                                                  var dxPivotGrid_informePrendas =
                                                                                      $(
                                                                                        "#dxPivotGrid_informePrendas",
                                                                                      ).dxPivotGrid(
                                                                                        "instance",
                                                                                      ),
                                                                                    dxList_entidadesNInventarioSel =
                                                                                      $(
                                                                                        "#dxList_entidadesNInventario",
                                                                                      )
                                                                                        .dxList(
                                                                                          "instance",
                                                                                        )
                                                                                        .option(
                                                                                          "selectedItems",
                                                                                        )[0];

                                                                                  params_datasource_informePrendas =
                                                                                    {
                                                                                      idEntidad:
                                                                                        dxList_entidadesNInventarioSel.id,
                                                                                      idSeccionNivel1:
                                                                                        null,
                                                                                      idSeccionNivel2:
                                                                                        null,
                                                                                      idLavanderia:
                                                                                        null,
                                                                                    };

                                                                                  dxPivotGrid_informePrendas.option(
                                                                                    "dataSource.store",
                                                                                    datasource_informePrendas.store(),
                                                                                  );
                                                                                  dxPivotGrid_informePrendas.repaint();

                                                                                  $(
                                                                                    "#dxPopup_informePrendas_title",
                                                                                  ).text(
                                                                                    dxList_entidadesNInventarioSel.denominacion,
                                                                                  );
                                                                                  $(
                                                                                    "#dxPopup_informePrendas",
                                                                                  )
                                                                                    .dxPopup(
                                                                                      "instance",
                                                                                    )
                                                                                    .show();
                                                                                },
                                                                            },
                                                                          );
                                                                        },
                                                                    },
                                                                    {
                                                                      location:
                                                                        "after",
                                                                      template:
                                                                        function () {
                                                                          return $(
                                                                            "<div />",
                                                                          ).dxButton(
                                                                            {
                                                                              icon: " icon_Impresora",
                                                                              width:
                                                                                "100%",
                                                                              disabled: true,
                                                                              elementAttr:
                                                                                {
                                                                                  id: "dxButton_impresion_seccionNivel1",
                                                                                },
                                                                              onInitialized:
                                                                                function (
                                                                                  e,
                                                                                ) {
                                                                                  tooltipControl_creacion(
                                                                                    e.element,
                                                                                    getTrad(
                                                                                      "imprimir",
                                                                                    ),
                                                                                  );
                                                                                },
                                                                              onClick:
                                                                                function (
                                                                                  e,
                                                                                ) {
                                                                                  var dxList_tblSeccionNivel1 =
                                                                                    $(
                                                                                      "#dxList_tblSeccionNivel1",
                                                                                    ).dxList(
                                                                                      "instance",
                                                                                    );
                                                                                  dxList_tblSeccionNivel1.option(
                                                                                    "searchValue",
                                                                                    null,
                                                                                  );

                                                                                  var items =
                                                                                    dxList_tblSeccionNivel1
                                                                                      .getDataSource()
                                                                                      .items();
                                                                                  $(
                                                                                    "#dxList_impresion",
                                                                                  )
                                                                                    .dxList(
                                                                                      "instance",
                                                                                    )
                                                                                    .option(
                                                                                      {
                                                                                        dataSource:
                                                                                          items,
                                                                                        selectedItems:
                                                                                          [],
                                                                                      },
                                                                                    );

                                                                                  var dxPopup_impresion =
                                                                                    $(
                                                                                      "#dxPopup_impresion",
                                                                                    ).dxPopup(
                                                                                      "instance",
                                                                                    );
                                                                                  dxPopup_impresion.option(
                                                                                    "title",
                                                                                    getTrad(
                                                                                      "impresionEdificios",
                                                                                    ),
                                                                                  );
                                                                                  dxPopup_impresion.show();
                                                                                },
                                                                            },
                                                                          );
                                                                        },
                                                                    },
                                                                  ],
                                                                }),
                                                              );
                                                            },
                                                          },
                                                          {
                                                            ratio: 1,
                                                            template:
                                                              function () {
                                                                return $(
                                                                  "<div id='dxList_tblSeccionNivel1'>",
                                                                ).dxList({
                                                                  height:
                                                                    "100%",
                                                                  selectionMode:
                                                                    "single",
                                                                  scrolling: {
                                                                    mode: "infinite",
                                                                  },
                                                                  searchEnabled: true,
                                                                  searchExpr: [
                                                                    "denominacion",
                                                                  ],
                                                                  displayExpr:
                                                                    "denominacion",
                                                                  valueExpr:
                                                                    "idSeccionNivel1",
                                                                  pageLoadMode:
                                                                    "scrollBottom",
                                                                  itemTemplate:
                                                                    function (
                                                                      itemData,
                                                                      index,
                                                                      element,
                                                                    ) {
                                                                      rowTemplate_denoProcentaje_dxList(
                                                                        itemData.denominacion,
                                                                        itemData.porcentaje,
                                                                        element,
                                                                      );
                                                                    },
                                                                  onSelectionChanged:
                                                                    function (
                                                                      e,
                                                                    ) {
                                                                      if (
                                                                        e
                                                                          .addedItems
                                                                          .length >
                                                                        0
                                                                      ) {
                                                                        datasource_datos_seccionNivel2
                                                                          .load()
                                                                          .done(
                                                                            function () {
                                                                              $(
                                                                                "#dxList_tblSeccionNivel2",
                                                                              )
                                                                                .dxList(
                                                                                  "instance",
                                                                                )
                                                                                .option(
                                                                                  "dataSource",
                                                                                  datasource_datos_seccionNivel2,
                                                                                );
                                                                            },
                                                                          );

                                                                        $(
                                                                          "#dxButton_infPrendas_seccionNivel2",
                                                                        )
                                                                          .dxButton(
                                                                            "instance",
                                                                          )
                                                                          .option(
                                                                            "disabled",
                                                                            false,
                                                                          );
                                                                        $(
                                                                          "#dxButton_impresion_seccionNivel2",
                                                                        )
                                                                          .dxButton(
                                                                            "instance",
                                                                          )
                                                                          .option(
                                                                            "disabled",
                                                                            false,
                                                                          );
                                                                      } else
                                                                        $(
                                                                          "#dxList_tblSeccionNivel2",
                                                                        )
                                                                          .dxList(
                                                                            "instance",
                                                                          )
                                                                          .option(
                                                                            "dataSource",
                                                                            [],
                                                                          );
                                                                    },
                                                                });
                                                              },
                                                          },
                                                        ],
                                                      }),
                                                    );
                                                  },
                                                },
                                                {
                                                  ratio: 1,
                                                  template: function (
                                                    e,
                                                    index,
                                                    item,
                                                  ) {
                                                    //SECCIONES
                                                    item.css(
                                                      "padding-left",
                                                      "15px",
                                                    );
                                                    item.append(
                                                      $("<div/>").dxBox({
                                                        direction: "col",
                                                        align: "space-around",
                                                        crossAlign: "stretch",
                                                        height: "100%",
                                                        width: "100%",
                                                        items: [
                                                          {
                                                            baseSize: 40,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function (
                                                              e,
                                                              index,
                                                              item,
                                                            ) {
                                                              item.append(
                                                                $(
                                                                  "<div />",
                                                                ).dxToolbar({
                                                                  items: [
                                                                    {
                                                                      location:
                                                                        "before",
                                                                      template:
                                                                        function () {
                                                                          return $(
                                                                            "<div class='font-size pb-3' />",
                                                                          ).text(
                                                                            getTrad(
                                                                              "secciones",
                                                                            ),
                                                                          );
                                                                        },
                                                                    },
                                                                    {
                                                                      location:
                                                                        "after",
                                                                      template:
                                                                        function () {
                                                                          return $(
                                                                            "<div />",
                                                                          ).dxButton(
                                                                            {
                                                                              text: getTrad(
                                                                                "informePrendas",
                                                                              ),
                                                                              width:
                                                                                "100%",
                                                                              disabled: true,
                                                                              elementAttr:
                                                                                {
                                                                                  id: "dxButton_infPrendas_seccionNivel2",
                                                                                },
                                                                              onClick:
                                                                                function (
                                                                                  e,
                                                                                ) {
                                                                                  $(
                                                                                    "#dxList_tblSeccionNivel2",
                                                                                  )
                                                                                    .dxList(
                                                                                      "instance",
                                                                                    )
                                                                                    .option(
                                                                                      "searchValue",
                                                                                      null,
                                                                                    );
                                                                                  var dxPivotGrid_informePrendas =
                                                                                      $(
                                                                                        "#dxPivotGrid_informePrendas",
                                                                                      ).dxPivotGrid(
                                                                                        "instance",
                                                                                      ),
                                                                                    dxList_tblSeccionNivel1Sel =
                                                                                      $(
                                                                                        "#dxList_tblSeccionNivel1",
                                                                                      )
                                                                                        .dxList(
                                                                                          "instance",
                                                                                        )
                                                                                        .option(
                                                                                          "selectedItems",
                                                                                        )[0],
                                                                                    dxList_entidadesNInventarioSel =
                                                                                      $(
                                                                                        "#dxList_entidadesNInventario",
                                                                                      )
                                                                                        .dxList(
                                                                                          "instance",
                                                                                        )
                                                                                        .option(
                                                                                          "selectedItems",
                                                                                        )[0];

                                                                                  params_datasource_informePrendas =
                                                                                    {
                                                                                      idEntidad:
                                                                                        dxList_entidadesNInventarioSel.id,
                                                                                      idSeccionNivel1:
                                                                                        dxList_tblSeccionNivel1Sel.idSeccionNivel1,
                                                                                      idSeccionNivel2:
                                                                                        null,
                                                                                      idLavanderia:
                                                                                        null,
                                                                                    };

                                                                                  dxPivotGrid_informePrendas.option(
                                                                                    "dataSource.store",
                                                                                    datasource_informePrendas.store(),
                                                                                  );
                                                                                  dxPivotGrid_informePrendas.repaint();

                                                                                  $(
                                                                                    "#dxPopup_informePrendas_title",
                                                                                  ).text(
                                                                                    dxList_tblSeccionNivel1Sel.denominacion,
                                                                                  );
                                                                                  $(
                                                                                    "#dxPopup_informePrendas",
                                                                                  )
                                                                                    .dxPopup(
                                                                                      "instance",
                                                                                    )
                                                                                    .show();
                                                                                },
                                                                            },
                                                                          );
                                                                        },
                                                                    },
                                                                    {
                                                                      location:
                                                                        "after",
                                                                      template:
                                                                        function () {
                                                                          return $(
                                                                            "<div />",
                                                                          ).dxButton(
                                                                            {
                                                                              icon: " icon_Impresora",
                                                                              width:
                                                                                "100%",
                                                                              disabled: true,
                                                                              elementAttr:
                                                                                {
                                                                                  id: "dxButton_impresion_seccionNivel2",
                                                                                },
                                                                              onInitialized:
                                                                                function (
                                                                                  e,
                                                                                ) {
                                                                                  tooltipControl_creacion(
                                                                                    e.element,
                                                                                    getTrad(
                                                                                      "imprimir",
                                                                                    ),
                                                                                  );
                                                                                },
                                                                              onClick:
                                                                                function (
                                                                                  e,
                                                                                ) {
                                                                                  var dxList_tblSeccionNivel2 =
                                                                                    $(
                                                                                      "#dxList_tblSeccionNivel2",
                                                                                    ).dxList(
                                                                                      "instance",
                                                                                    );
                                                                                  dxList_tblSeccionNivel2.option(
                                                                                    "searchValue",
                                                                                    null,
                                                                                  );

                                                                                  var items =
                                                                                    dxList_tblSeccionNivel2
                                                                                      .getDataSource()
                                                                                      .items();
                                                                                  $(
                                                                                    "#dxList_impresion",
                                                                                  )
                                                                                    .dxList(
                                                                                      "instance",
                                                                                    )
                                                                                    .option(
                                                                                      {
                                                                                        dataSource:
                                                                                          items,
                                                                                        selectedItems:
                                                                                          [],
                                                                                      },
                                                                                    );

                                                                                  var dxPopup_impresion =
                                                                                    $(
                                                                                      "#dxPopup_impresion",
                                                                                    ).dxPopup(
                                                                                      "instance",
                                                                                    );
                                                                                  dxPopup_impresion.option(
                                                                                    "title",
                                                                                    getTrad(
                                                                                      "impresionSecciones",
                                                                                    ),
                                                                                  );
                                                                                  dxPopup_impresion.show();
                                                                                },
                                                                            },
                                                                          );
                                                                        },
                                                                    },
                                                                  ],
                                                                }),
                                                              );
                                                            },
                                                          },
                                                          {
                                                            ratio: 1,
                                                            template: function (
                                                              e,
                                                              index,
                                                              item,
                                                            ) {
                                                              item.append(
                                                                $(
                                                                  "<div id='dxList_tblSeccionNivel2'>",
                                                                ).dxList({
                                                                  height:
                                                                    "100%",
                                                                  selectionMode:
                                                                    "single",
                                                                  scrolling: {
                                                                    mode: "infinite",
                                                                  },
                                                                  searchEnabled: true,
                                                                  searchExpr: [
                                                                    "denominacion",
                                                                  ],
                                                                  displayExpr:
                                                                    "denominacion",
                                                                  valueExpr:
                                                                    "idSeccionNivel2",
                                                                  pageLoadMode:
                                                                    "scrollBottom",
                                                                  itemTemplate:
                                                                    function (
                                                                      itemData,
                                                                      index,
                                                                      element,
                                                                    ) {
                                                                      rowTemplate_denoProcentaje_dxList(
                                                                        itemData.denominacion,
                                                                        itemData.porcentaje,
                                                                        element,
                                                                      );
                                                                    },
                                                                  onSelectionChanged:
                                                                    function (
                                                                      e,
                                                                    ) {
                                                                      if (
                                                                        e
                                                                          .addedItems
                                                                          .length >
                                                                        0
                                                                      ) {
                                                                        datasource_datos_tblAlmacenNInventario.reload();
                                                                        $(
                                                                          "#dxTabPanel_seccionAlmacen",
                                                                        )
                                                                          .dxTabPanel(
                                                                            "instance",
                                                                          )
                                                                          .option(
                                                                            "selectedIndex",
                                                                            1,
                                                                          );
                                                                      }
                                                                    },
                                                                }),
                                                              );
                                                            },
                                                          },
                                                        ],
                                                      }),
                                                    );
                                                  },
                                                },
                                              ],
                                            }),
                                          );
                                        },
                                      },
                                      {
                                        title: getTrad("almacenes"),
                                        template: function (
                                          itemData,
                                          itemIndex,
                                          itemElement,
                                        ) {
                                          itemElement.css("padding-right", 0);
                                          itemElement.append(
                                            $(
                                              "<div id='dxDataGrid_tblAlmacenNInv'/>",
                                            ).dxDataGrid({
                                              //Datos
                                              dataSource:
                                                datasource_datos_tblAlmacenNInventario,
                                              remoteOperations: true,
                                              //Propiedades
                                              columnsAutoWidth: true,
                                              repaintChangesOnly: true,
                                              headerFilter: {
                                                visible: true,
                                              },
                                              height: "100%",
                                              filterRow: {
                                                visible: true,
                                                applyFilter: "auto",
                                              },
                                              searchPanel: {
                                                visible: true,
                                                width: 200,
                                                placeholder:
                                                  getTrad("busqueda"),
                                                searchVisibleColumnsOnly: true,
                                              },
                                              paging: {
                                                pageSize: 20,
                                              },
                                              pager: {
                                                showPageSizeSelector: true,
                                                allowedPageSizes: [20, 50, 100],
                                                showInfo: true,
                                              },
                                              loadPanel: {
                                                enabled: false,
                                              },
                                              hoverStateEnabled: false,
                                              columns: [
                                                {
                                                  caption: " ",
                                                  width: "40px",
                                                  alignment: "center",
                                                  cssClass: "p-0",
                                                  cellTemplate: function (
                                                    cellElement,
                                                    cellInfo,
                                                  ) {
                                                    var contenedor = $("<div/>")
                                                      .addClass(
                                                        "container_spanCentrado",
                                                      )
                                                      .append(
                                                        svg_edit.clone().css({
                                                          height: "25px",
                                                          width: "25px",
                                                          cursor: "pointer",
                                                        }),
                                                      );

                                                    cellElement.on(
                                                      "click",
                                                      function () {
                                                        almacenSel =
                                                          cellInfo.data;
                                                        var dxDataGrid_tblPrendaNAlmacen =
                                                            $(
                                                              "#dxDataGrid_tblPrendaNAlmacen",
                                                            ).dxDataGrid(
                                                              "instance",
                                                            ),
                                                          dxList_addPrendasAlmacen =
                                                            $(
                                                              "#dxList_addPrendasAlmacen",
                                                            ).dxList(
                                                              "instance",
                                                            );

                                                        datasource_datos_tblPrendaNAlmacenNInventario
                                                          .load()
                                                          .done(function () {
                                                            if (
                                                              typeof dxDataGrid_tblPrendaNAlmacen !==
                                                              "undefined"
                                                            ) {
                                                              //#region UPDATEAR DATASOURCE PRENDA
                                                              var arrayData =
                                                                new ArrayStore({
                                                                  key: "idPrenda",
                                                                  data: array_prendasNAlmacenNInventario,
                                                                });
                                                              dxDataGrid_tblPrendaNAlmacen.option(
                                                                "dataSource",
                                                                arrayData,
                                                              ); // porque al insertar nueva prenda, se sustituye el datasource por uno local, entonces refresh no funciona.
                                                              //#endregion

                                                              //#region SELECCIÓN DE PRENDAS EN POPUP ADD PRENDAS
                                                              if (
                                                                typeof dxList_addPrendasAlmacen !==
                                                                "undefined"
                                                              ) {
                                                                var idsPrendasSel =
                                                                  [];
                                                                if (
                                                                  array_prendasNAlmacenNInventario.length >
                                                                  0
                                                                ) {
                                                                  $.each(
                                                                    array_prendasNAlmacenNInventario,
                                                                    function (
                                                                      index,
                                                                      prenda,
                                                                    ) {
                                                                      idsPrendasSel.push(
                                                                        prenda.idPrenda,
                                                                      );
                                                                    },
                                                                  );
                                                                }
                                                                dxList_addPrendasAlmacen.option(
                                                                  "selectedItemKeys",
                                                                  idsPrendasSel,
                                                                );
                                                              }
                                                              //#endregion
                                                            }
                                                            $(
                                                              "#dxPopup_prendasNAlmacen",
                                                            )
                                                              .dxPopup(
                                                                "instance",
                                                              )
                                                              .show();
                                                          });
                                                      },
                                                    );

                                                    contenedor.appendTo(
                                                      cellElement,
                                                    );
                                                  },
                                                },
                                                {
                                                  dataField: "denominacion",
                                                  caption:
                                                    getTrad("denominacion"),
                                                  allowHeaderFiltering: false,
                                                  minWidth: 300,
                                                  sortOrder: "asc",
                                                },
                                                {
                                                  dataField: "denoTipoAlm",
                                                  caption:
                                                    getTrad("tipoAlmacen"),
                                                },
                                                {
                                                  dataField: "denoTipoHab",
                                                  caption:
                                                    getTrad("tipoHabitacion"),
                                                },
                                                {
                                                  dataField: "estado",
                                                  caption: getTrad("estado"),
                                                  width: 88,
                                                  alignment: "center",
                                                  cssClass: "p-0",
                                                  cellTemplate: function (
                                                    container,
                                                    options,
                                                  ) {
                                                    var estadoImg = {};
                                                    switch (
                                                      options.data.estado
                                                    ) {
                                                      case 0:
                                                        estadoImg = {
                                                          svg: svg_remove_circle,
                                                          color:
                                                            enum_colores[
                                                              "rojo"
                                                            ],
                                                        };
                                                        break;
                                                      case 1:
                                                        estadoImg = {
                                                          svg: svg_tick_circle,
                                                          color:
                                                            enum_colores[
                                                              "verde"
                                                            ],
                                                        };
                                                        break;
                                                      case 2:
                                                        estadoImg = {
                                                          svg: svg_tick_circle,
                                                          color:
                                                            enum_colores[
                                                              "violeta"
                                                            ],
                                                        };
                                                        break;
                                                      default:
                                                        estadoImg = {
                                                          svg: svg_remove_circle,
                                                          color:
                                                            enum_colores[
                                                              "gris"
                                                            ],
                                                        };
                                                        break;
                                                    }

                                                    estadoImg.svg
                                                      .clone()
                                                      .css({
                                                        fill: estadoImg.color,
                                                        "vertical-align":
                                                          "middle",
                                                        height: "20px",
                                                        width: "20px",
                                                      })
                                                      .appendTo(container);
                                                  },
                                                  allowHeaderFiltering: true,
                                                  allowFiltering: false,
                                                  headerFilter: {
                                                    dataSource:
                                                      enum_estadoAlmacen,
                                                  },
                                                },
                                              ],
                                              onToolbarPreparing: function (e) {
                                                e.toolbarOptions.items.unshift(
                                                  {
                                                    location: "before",
                                                    widget: "dxButton",
                                                    options: {
                                                      text: getTrad("atras"),
                                                      icon: "back",
                                                      onClick: function () {
                                                        var dxList_entidadesNInventario =
                                                            $(
                                                              "#dxList_entidadesNInventario",
                                                            ).dxList(
                                                              "instance",
                                                            ),
                                                          entiNInvSel =
                                                            typeof dxList_entidadesNInventario !==
                                                            "undefined"
                                                              ? dxList_entidadesNInventario.option(
                                                                  "selectedItems",
                                                                )
                                                              : [],
                                                          isLav =
                                                            entiNInvSel.length >
                                                              0 &&
                                                            entiNInvSel[0].isLav
                                                              ? true
                                                              : false;

                                                        if (isLav)
                                                          dxList_entidadesNInventario.unselectAll();

                                                        $(
                                                          "#dxTabPanel_seccionAlmacen",
                                                        )
                                                          .dxTabPanel(
                                                            "instance",
                                                          )
                                                          .option(
                                                            "selectedIndex",
                                                            0,
                                                          );
                                                      },
                                                    },
                                                  },
                                                  {
                                                    location: "before",
                                                    template: function (
                                                      itemData,
                                                      itemIndex,
                                                      itemElement,
                                                    ) {
                                                      itemElement.css(
                                                        "padding-left",
                                                        10,
                                                      );
                                                      var dxList_entidadesNInventario_selItem =
                                                          $(
                                                            "#dxList_entidadesNInventario",
                                                          )
                                                            .dxList("instance")
                                                            .option(
                                                              "selectedItems",
                                                            ),
                                                        dxList_tblSeccionNivel2_selItem =
                                                          $(
                                                            "#dxList_tblSeccionNivel2",
                                                          )
                                                            .dxList("instance")
                                                            .option(
                                                              "selectedItems",
                                                            );

                                                      if (
                                                        dxList_entidadesNInventario_selItem.length >
                                                        0
                                                      ) {
                                                        if (
                                                          dxList_entidadesNInventario_selItem[0]
                                                            .isLav
                                                        ) {
                                                          return $(
                                                            "<span class='font-size' />",
                                                          )
                                                            .text(
                                                              getTrad(
                                                                "lavanderia",
                                                              ),
                                                            )
                                                            .append(
                                                              $(
                                                                "<span style='font-weight:normal' />",
                                                              ).text(
                                                                ": " +
                                                                  dxList_entidadesNInventario_selItem[0]
                                                                    .denominacion,
                                                              ),
                                                            );
                                                        } else if (
                                                          dxList_tblSeccionNivel2_selItem.length >
                                                          0
                                                        ) {
                                                          return $(
                                                            "<span class='font-size' />",
                                                          )
                                                            .text(
                                                              getTrad(
                                                                "seccion",
                                                              ),
                                                            )
                                                            .append(
                                                              $(
                                                                "<span style='font-weight:normal' />",
                                                              ).text(
                                                                ": " +
                                                                  dxList_tblSeccionNivel2_selItem[0]
                                                                    .denominacion,
                                                              ),
                                                            );
                                                        }
                                                      }
                                                    },
                                                  },
                                                  {
                                                    location: "after",
                                                    widget: "dxButton",
                                                    options: {
                                                      text: getTrad(
                                                        "informePrendas",
                                                      ),
                                                      width: "100%",
                                                      onClick: function (e) {
                                                        var dxList_entidadesNInventarioSel =
                                                            $(
                                                              "#dxList_entidadesNInventario",
                                                            )
                                                              .dxList(
                                                                "instance",
                                                              )
                                                              .option(
                                                                "selectedItems",
                                                              )[0],
                                                          isLav =
                                                            dxList_entidadesNInventarioSel.isLav
                                                              ? true
                                                              : false,
                                                          dxList_tblSeccionNivel2Sel =
                                                            $(
                                                              "#dxList_tblSeccionNivel2",
                                                            )
                                                              .dxList(
                                                                "instance",
                                                              )
                                                              .option(
                                                                "selectedItems",
                                                              )[0],
                                                          dxList_tblSeccionNivel1Sel =
                                                            $(
                                                              "#dxList_tblSeccionNivel1",
                                                            )
                                                              .dxList(
                                                                "instance",
                                                              )
                                                              .option(
                                                                "selectedItems",
                                                              )[0];

                                                        params_datasource_informePrendas =
                                                          {
                                                            idEntidad: isLav
                                                              ? null
                                                              : dxList_entidadesNInventarioSel.id,
                                                            idSeccionNivel1:
                                                              isLav
                                                                ? null
                                                                : dxList_tblSeccionNivel1Sel.idSeccionNivel1,
                                                            idSeccionNivel2:
                                                              isLav
                                                                ? null
                                                                : dxList_tblSeccionNivel2Sel.idSeccionNivel2,
                                                            idLavanderia: isLav
                                                              ? dxList_entidadesNInventarioSel.id
                                                              : null,
                                                          };

                                                        var dxPivotGrid_informePrendas =
                                                            $(
                                                              "#dxPivotGrid_informePrendas",
                                                            ).dxPivotGrid(
                                                              "instance",
                                                            ),
                                                          title = $(
                                                            "#dxPopup_informePrendas_title",
                                                          );
                                                        dxPivotGrid_informePrendas.option(
                                                          "dataSource.store",
                                                          datasource_informePrendas.store(),
                                                        );
                                                        dxPivotGrid_informePrendas.repaint();

                                                        if (!isLav)
                                                          title.text(
                                                            dxList_tblSeccionNivel2Sel.denominacion,
                                                          );
                                                        else
                                                          title.text(
                                                            getTrad(
                                                              "almacenesLavanderia",
                                                            ),
                                                          );

                                                        $(
                                                          "#dxPopup_informePrendas",
                                                        )
                                                          .dxPopup("instance")
                                                          .show();
                                                      },
                                                    },
                                                  },
                                                  {
                                                    location: "after",
                                                    widget: "dxButton",
                                                    showText: "inMenu",
                                                    locateInMenu: "auto",
                                                    options: {
                                                      text: getTrad(
                                                        "limpiarFiltro",
                                                      ),
                                                      icon: " icon_EliminarFiltros",
                                                      onInitialized: function (
                                                        e,
                                                      ) {
                                                        tooltipControl_creacion(
                                                          e.element,
                                                          e.component.option(
                                                            "text",
                                                          ),
                                                        );
                                                      },
                                                      onClick: function () {
                                                        e.component.clearFilter();
                                                        notify({
                                                          message: getTrad(
                                                            "aviso_C_FiltroRestaurado",
                                                          ),
                                                          type: "success",
                                                          displayTime: "1500",
                                                          closeOnClick: true,
                                                        });
                                                      },
                                                    },
                                                  },
                                                );
                                              },
                                              //Estilos
                                              showColumnLines: false,
                                              showRowLines: true,
                                              rowAlternationEnabled: true,
                                            }),
                                          );
                                        },
                                      },
                                    ],
                                    onSelectionChanged: function (e) {
                                      var index =
                                        e.component.option("selectedIndex");
                                      if (index === 0)
                                        //Edificios y secciones
                                        $("#dxList_tblSeccionNivel2")
                                          .dxList("instance")
                                          .option("selectedItemKeys", []);
                                      else if (index === 1) {
                                        $("#dxDataGrid_tblAlmacenNInv")
                                          .dxDataGrid("instance")
                                          .repaint();
                                      }
                                    },
                                  });
                                },
                              },
                            ],
                          }),
                        );
                      },
                    },
                  ],
                });
              },
            },
          ],
          onSelectionChanged: function (e) {
            var tabpanel = e.component;
            var tabpanel_index = tabpanel.option("selectedIndex");
            if (tabpanel_index === 0) {
              datasource_spSelectInventarios.reload();

              tabpanel.option("items[0].visible", true);
              $("#dxList_entidadesNInventario")
                .dxList("instance")
                .unselectAll();
              $("#dxTabPanel_seccionAlmacen")
                .dxTabPanel("instance")
                .option("selectedIndex", 0);
            } else if (tabpanel_index === 1) {
              datasource_prendasInfPrendas.load();
              datasource_tblPrendasNInventario.reload();
              datasource_inventarios_spSelectAddPrendas.reload();

              pantallaInvPorDefecto();
            }
          },
        })
        .dxTabPanel("instance");

      //#region POPUPS
      $("#Inventarios #dxPopup_nuevoInventario").dxPopup({
        height: "70%",
        width: "70%",
        title: getTrad("crearNuevoInventario"),
        contentTemplate: function () {
          return $("<div id='dxForm_nuevoInventario'/>")
            .dxForm({
              labelLocation: "top",
              height: "100%",
              scrollingEnabled: false,
              colCount: 1,
              items: [
                {
                  itemType: "group",
                  colCountByScreen: {
                    xl: 10,
                    lg: 10,
                    md: 8,
                    sm: 4,
                    xs: 1,
                  },
                  items: [
                    {
                      colSpan: 6,
                      dataField: "denominacion",
                      label: { text: getTrad("denominacion") },
                      validationRules: [
                        {
                          type: "required",
                          message: getTrad("campoNecesario"),
                        },
                      ],
                    },
                    {
                      colSpan: 2,
                      dataField: "fecha",
                      label: { text: getTrad("fecha") },
                      editorType: "dxDateBox",
                      editorOptions: {
                        displayFormat: "dd/MM/yyyy",
                        min: "01/01/1900",
                      },
                      validationRules: [
                        {
                          type: "required",
                          message: getTrad("campoNecesario"),
                        },
                      ],
                    },
                    {
                      colSpan: 2,
                      dataField: "incluirLavanderia",
                      label: { text: getTrad("incluirLavanderia") },
                      editorType: "dxSwitch",
                      editorOptions: {
                        switchedOnText: getTrad("incluir"),
                        switchedOffText: getTrad("noIncluir"),
                        value: false,
                        disabled: !LAVANDERIA,
                        elementAttr: {
                          class: "dxSwitch_toolbar",
                        },
                        onValueChanged: function (e) {
                          var dxList_prendas_nuevoInv = $(
                            "#dxList_prendas_nuevoInv",
                          ).dxList("instance");
                          var dxList_nuevoInventario_entidad = $(
                            "#dxList_nuevoInventario_entidad",
                          ).dxList("instance");

                          dxList_prendas_nuevoInv.repaint();

                          dxList_nuevoInventario_entidad.repaint();

                          if (!e.value) {
                            //NO INLCUIR
                            dxList_prendas_nuevoInv.unselectAll();
                            dxList_nuevoInventario_entidad.unselectAll();
                          }
                        },
                      },
                    },
                  ],
                },
                {
                  itemType: "group",
                  colCountByScreen: {
                    xl: 10,
                    lg: 10,
                    md: 8,
                    sm: 4,
                    xs: 1,
                  },
                  items: [
                    {
                      itemType: "group",
                      caption: getTrad("compañias"),
                      cssClass: "innerGrop_compaEnti",
                      colSpan: 3,
                      items: [
                        {
                          ratio: 1,
                          template: function () {
                            return $(
                              "<div id='dxList_nuevoInventario_compañia'>",
                            ).dxList({
                              dataSource: datasource_compaEnti.items(),
                              focusStateEnabled: false,
                              selectionMode: "single",
                              showScrollbar: "always",
                              height: 335,
                              keyExpr: "idCompañia",
                              pageLoadMode: "scrollBottom",
                              searchEnabled: true,
                              displayExpr: "denominacion",
                              searchExpr: ["denominacion"],
                              selectedItems: [datasource_compaEnti.items()[0]],
                              // EVENTOS
                              onSelectionChanged: function (e) {
                                var dataSource = [];
                                if (e.addedItems.length > 0) {
                                  var compañia = e.addedItems[0];
                                  dataSource = compañia.tblEntidad;
                                }
                                var dxList_nuevoInventario_entidad = $(
                                    "#dxList_nuevoInventario_entidad",
                                  ).dxList("instance"),
                                  dxList_prendas_nuevoInv = $(
                                    "#dxList_prendas_nuevoInv",
                                  ).dxList("instance");

                                dxList_nuevoInventario_entidad.option(
                                  "selectedItemKeys",
                                  [],
                                );
                                dxList_nuevoInventario_entidad.option(
                                  "dataSource",
                                  new DataSource({
                                    store: dataSource,
                                    paginate: false,
                                  }),
                                );

                                //#region AÑADE ITEM SELECCIÓN TODAS SI TODAS LAS ENTIDADES SON DE INVENTARIO POR COMPAÑÍA
                                if (dataSource.length > 0) {
                                  var itemsEnti = [];
                                  var itemsCompa = $.grep(
                                    dataSource,
                                    function (item) {
                                      if (
                                        item.inventarioPorEntidad &&
                                        itemsEnti.length === 0
                                      )
                                        itemsEnti.push(item);
                                      return !item.inventarioPorEntidad;
                                    },
                                  );

                                  var is_compa =
                                    itemsCompa.length > 0 &&
                                    itemsEnti.length === 0;
                                  dxList_nuevoInventario_entidad.option(
                                    "selectionMode",
                                    is_compa ? "all" : "multiple",
                                  );
                                }
                                //#endregion

                                if (
                                  typeof dxList_prendas_nuevoInv !== "undefined"
                                )
                                  dxList_prendas_nuevoInv.unselectAll();
                              },
                            });
                          },
                        },
                      ],
                    },
                    {
                      itemType: "group",
                      caption: getTrad("entidades"),
                      colSpan: 3,
                      cssClass: "innerGrop_compaEnti",
                      items: [
                        {
                          ratio: 1,
                          template: function (data, itemIndex, itemElement) {
                            function isValid_entidad(itemEntidad) {
                              //#region itera sobre almacenes y tipos de habitacion para validar si entidad tiene estructura
                              var isValid_item = false;
                              $.each(
                                itemEntidad.tblAlmacen,
                                function (index, item) {
                                  if (item["tblPrendaNAlmacen@odata.count"] > 0)
                                    isValid_item = true;
                                },
                              );

                              $.each(
                                itemEntidad.tblTipoHabitacion,
                                function (index, item) {
                                  if (
                                    item[
                                      "tblPrendaNTipoHabitacion@odata.count"
                                    ] > 0
                                  )
                                    isValid_item = true;
                                },
                              );
                              //#endregion
                              return isValid_item;
                            }

                            return $(
                              "<div id='dxList_nuevoInventario_entidad'>",
                            ).dxList({
                              dataSource:
                                datasource_compaEnti.items()[0].tblEntidad,
                              focusStateEnabled: false,
                              height: 335,
                              showScrollbar: "always",
                              keyExpr: "idEntidad",
                              pageLoadMode: "scrollBottom",
                              searchEnabled: true,
                              searchExpr: "denominacion",
                              selectionMode: "all",
                              selectAllMode: "allPages",
                              showSelectionControls: true,
                              displayExpr: "denominacion",
                              scrollPosition: 0,
                              //EVENTOS
                              onItemRendered: function (e) {
                                var item = e.itemData,
                                  nuevoInventario_formData = $(
                                    "#dxForm_nuevoInventario",
                                  ).dxForm("instance"),
                                  isLavIncluida =
                                    typeof nuevoInventario_formData !==
                                    "undefined"
                                      ? nuevoInventario_formData.option(
                                          "formData",
                                        ).incluirLavanderia
                                      : false;

                                var almacenesLav = false;
                                $.each(
                                  item.tblLavanderia,
                                  function (index, item) {
                                    if (item.tblAlmacen.length > 0)
                                      almacenesLav = true;
                                  },
                                );

                                if (
                                  (isLavIncluida && !almacenesLav) ||
                                  (!isValid_entidad(item) && !isLavIncluida) ||
                                  item.disabled
                                ) {
                                  e.itemElement.removeClass(
                                    "dx-state-disabled",
                                  );
                                  e.itemElement.addClass("custom-disabled");
                                  e.itemElement.parent().append(
                                    $("<div />").dxTooltip({
                                      height: "42px",
                                      showEvent: "dxhoverstart",
                                      hideEvent: "dxhoverend",
                                      position: "right",
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        if (item.disabled)
                                          contentElement.html(
                                            getTrad("alert_entiInvEnti"),
                                          );
                                        else
                                          contentElement.html(
                                            getTrad("alert_entiNoAlm"),
                                          );
                                      },
                                      target: e.itemElement,
                                      animation: {
                                        show: {
                                          type: "pop",
                                          from: {
                                            scale: 0.1,
                                            opacity: 0,
                                          },
                                          to: {
                                            opacity: 1,
                                            scale: 1,
                                          },
                                        },
                                        hide: {
                                          type: "pop",
                                          from: {
                                            scale: 1,
                                            opacity: 1,
                                          },
                                          to: {
                                            opacity: 0,
                                            scale: 0.1,
                                          },
                                        },
                                      },
                                    }),
                                  );
                                }
                              },
                              onSelectionChanged: function (e) {
                                var is_addedItem = e.addedItems.length > 0;
                                var item = is_addedItem ? e.addedItems[0] : [];

                                var isSelValid = false, // nos indica si algun item seleccionado es valido, si ninguno lo es, deseleccionar todos, para opción select all.
                                  nuevoInventario_formData = $(
                                    "#dxForm_nuevoInventario",
                                  ).dxForm("instance"),
                                  isLavIncluida =
                                    typeof nuevoInventario_formData !==
                                    "undefined"
                                      ? nuevoInventario_formData.option(
                                          "formData",
                                        ).incluirLavanderia
                                      : false;

                                var almacenesLav = false;
                                $.each(
                                  item.tblLavanderia,
                                  function (index, item) {
                                    if (item.tblAlmacen.length > 0)
                                      almacenesLav = true;
                                  },
                                );

                                if (is_addedItem) {
                                  $.each(e.addedItems, function (index, item) {
                                    if (
                                      (isLavIncluida && !almacenesLav) ||
                                      (!isValid_entidad(item) &&
                                        !isLavIncluida) ||
                                      item.disabled
                                    ) {
                                      e.component.unselectItem(item);
                                    } else isSelValid = true;
                                  });

                                  // if (!isSelValid)
                                  //     e.component.unselectAll();
                                }

                                if (!is_addedItem || !item.disabled) {
                                  idEntidadSel_inventarioPorEntidad = null;
                                  //#region SI ENTRE LOS ITEMS SELECCIONADOS HAY ALGÚN INVENTARIO POR ENTIDAD SE COGE ESE idEntidad
                                  $.each(
                                    e.component.option("selectedItems"),
                                    function (index, item) {
                                      if (item.inventarioPorEntidad)
                                        idEntidadSel_inventarioPorEntidad =
                                          item.idEntidad;
                                    },
                                  );
                                  //#endregion

                                  //#region DESHABILITAR ENTIDADES SI INV. ENTIDAD
                                  $.each(
                                    e.component.option("items"),
                                    function (index, item) {
                                      // ITEM SELECCIONADO === INVENTARIO POR ENTIDAD
                                      if (idEntidadSel_inventarioPorEntidad) {
                                        //ITEM ACTUAL !== SELECCIONADO
                                        if (
                                          item.idEntidad !==
                                          idEntidadSel_inventarioPorEntidad
                                        )
                                          item.disabled = true;
                                      } else if (item.inventarioPorEntidad) {
                                        // ITEM SELECCIONADO INV. COMPA ,ITEM ACTUAL INV. ENTIDAD
                                        item.disabled = true;
                                      }

                                      // NO HAY ITEMS SELECCIONADOS
                                      if (
                                        e.component.option("selectedItems")
                                          .length === 0
                                      )
                                        item.disabled = false;
                                    },
                                  );
                                  //#endregion

                                  //#region RECARGAR LISTA CON PRENDAS AL CAMBIARDE ENTIDAD
                                  var dxList_prendas_nuevoInv = $(
                                      "#dxList_prendas_nuevoInv",
                                    ).dxList("instance"),
                                    entidadesSel_length =
                                      e.component.option(
                                        "selectedItems",
                                      ).length;

                                  if (
                                    is_addedItem &&
                                    isSelValid &&
                                    (entidadesSel_length === 1 ||
                                      e.addedItems.length > 1)
                                  ) {
                                    // carga datos si se añade item, si es el primero de la entidad y si es valido.
                                    datasource_spSelectPrendas_nuevoInventario
                                      .load()
                                      .done(function () {
                                        dxList_prendas_nuevoInv.option(
                                          "dataSource",
                                          datasource_spSelectPrendas_nuevoInventario,
                                        );
                                      });
                                  } else if (entidadesSel_length === 0)
                                    dxList_prendas_nuevoInv.option(
                                      "dataSource",
                                      [],
                                    );
                                  //#endregion

                                  data.component.updateData({
                                    tblEntidad:
                                      e.component.option("selectedItems"),
                                  });
                                }
                                e.component.option(
                                  "scrollPosition",
                                  e.component.scrollTop(),
                                );
                                e.component.repaint();
                                e.component.scrollTo(
                                  e.component.option("scrollPosition"),
                                );
                              },
                            });
                          },
                        },
                      ],
                    },

                    {
                      itemType: "group",
                      caption: getTrad("seleccionPrenda"),
                      colSpan: 4,
                      cssClass: "innerGroup_prendas",
                      items: [
                        {
                          dataField: "tblPrenda",
                          label: { visible: false },
                          template: function (data, itemIndex, itemElement) {
                            var almacenesLav = false;
                            return $("<div id='dxList_prendas_nuevoInv' />")
                              .css({ "padding-top": "20px;" })
                              .dxList({
                                height: 335,
                                showScrollbar: "always",
                                selectionMode: "all",
                                scrolling: { mode: "infinite" },
                                searchEnabled: true,
                                searchExpr: ["denominacion"],
                                displayExpr: "denominacion",
                                pageLoadMode: "scrollBottom",
                                showSelectionControls: true,
                                itemTemplate: function (data, index, item) {
                                  item.append(
                                    $("<div />")
                                      .text(data.codigoPrenda)
                                      .css({
                                        "margin-right": 20,
                                        float: "left",
                                      }),
                                    $("<div />")
                                      .text(data.denominacion)
                                      .css("float", "left"),
                                  );
                                },
                                //EVENTOS
                                onOptionChanged: function (e) {
                                  if (e.name === "dataSource") {
                                    var entiSel = $(
                                      "#dxList_nuevoInventario_entidad",
                                    )
                                      .dxList("instance")
                                      .option("selectedItems");
                                    $.each(entiSel, function (index, entidad) {
                                      $.each(
                                        entidad.tblLavanderia,
                                        function (index, item) {
                                          if (item.tblAlmacen.length > 0)
                                            almacenesLav = true;
                                        },
                                      );
                                    });
                                  }
                                },
                                onItemRendered: function (e) {
                                  var nuevoInventario_formData = $(
                                      "#dxForm_nuevoInventario",
                                    ).dxForm("instance"),
                                    isLavIncluida =
                                      typeof nuevoInventario_formData !==
                                      "undefined"
                                        ? nuevoInventario_formData.option(
                                            "formData",
                                          ).incluirLavanderia
                                        : false;

                                  if (
                                    (!isLavIncluida ||
                                      (isLavIncluida && !almacenesLav)) &&
                                    !e.itemData.isActiva
                                  ) {
                                    e.itemElement.removeClass(
                                      "dx-state-disabled",
                                    );
                                    e.itemElement.addClass("custom-disabled");
                                    e.itemElement.parent().append(
                                      $("<div />").dxTooltip({
                                        height: "42px",
                                        showEvent: "dxhoverstart",
                                        hideEvent: "dxhoverend",
                                        position: "right",
                                        contentTemplate: function (
                                          contentElement,
                                        ) {
                                          contentElement.html(
                                            getTrad("alert_prendaLav"),
                                          );
                                        },
                                        target: e.itemElement,
                                        animation: {
                                          show: {
                                            type: "pop",
                                            from: {
                                              scale: 0.1,
                                              opacity: 0,
                                            },
                                            to: {
                                              opacity: 1,
                                              scale: 1,
                                            },
                                          },
                                          hide: {
                                            type: "pop",
                                            from: {
                                              scale: 1,
                                              opacity: 1,
                                            },
                                            to: {
                                              opacity: 0,
                                              scale: 0.1,
                                            },
                                          },
                                        },
                                      }),
                                    );
                                  }
                                },
                                onSelectionChanged: function (e) {
                                  var is_addedItem = e.addedItems.length > 0;
                                  var nuevoInventario_formData = $(
                                      "#dxForm_nuevoInventario",
                                    ).dxForm("instance"),
                                    isLavIncluida =
                                      typeof nuevoInventario_formData !==
                                      "undefined"
                                        ? nuevoInventario_formData.option(
                                            "formData",
                                          ).incluirLavanderia
                                        : false,
                                    isSelValid = false; // nos indica si algun item seleccionado es valido, si ninguno lo es, deseleccionar todos, para opción select all.

                                  if (is_addedItem) {
                                    $.each(
                                      e.addedItems,
                                      function (index, item) {
                                        if (
                                          (!isLavIncluida ||
                                            (isLavIncluida && !almacenesLav)) &&
                                          is_addedItem &&
                                          item.isActiva === 0
                                        )
                                          e.component.unselectItem(item);
                                        else isSelValid = true;
                                      },
                                    );
                                  }

                                  data.component.updateData({
                                    tblPrenda:
                                      e.component.option("selectedItemKeys"),
                                  });
                                },
                              });
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            })
            .append(
              $("<div id='dxPopup_selectorLavanderias' />").dxPopup({
                width: 325,
                height: 350,
                title: getTrad("lavanderías"),
                deferRendering: false,
                contentTemplate: function () {
                  return $("<div id='dxList_lavanderias' />").dxList({
                    selectionMode: "multiple",
                    showSelectionControls: true,
                    pageLoadMode: "scrollBottom",
                    keyExpr: "idLavanderia",
                    displayExpr: "denominacion",
                    //EVENTOS
                    onItemRendered: function (e) {
                      var item_isValid = e.itemData.tblAlmacen.length > 0;
                      if (!item_isValid) {
                        e.itemElement.removeClass("dx-state-disabled");
                        e.itemElement.addClass("custom-disabled");
                        e.itemElement.parent().append(
                          $("<div />").dxTooltip({
                            height: "42px",
                            showEvent: "dxhoverstart",
                            hideEvent: "dxhoverend",
                            position: "right",
                            contentTemplate: function (contentElement) {
                              contentElement.html(getTrad("alert_noAlmLav"));
                            },
                            target: e.itemElement,
                            animation: {
                              show: {
                                type: "pop",
                                from: {
                                  scale: 0.1,
                                  opacity: 0,
                                },
                                to: {
                                  opacity: 1,
                                  scale: 1,
                                },
                              },
                              hide: {
                                type: "pop",
                                from: {
                                  scale: 1,
                                  opacity: 1,
                                },
                                to: {
                                  opacity: 0,
                                  scale: 0.1,
                                },
                              },
                            },
                          }),
                        );
                      }
                    },
                    onSelectionChanged: function (e) {
                      if (e.addedItems.length > 0) {
                        var item = e.addedItems[0],
                          isValid_item = item.tblAlmacen.length > 0;

                        if (!isValid_item) e.component.unselectItem(item);
                      }
                    },
                  });
                },
                //EVENTOS
                onHiding: function (e) {
                  $("#dxList_lavanderias").dxList("instance").unselectAll();
                },
                toolbarItems: [
                  {
                    toolbar: "bottom",
                    location: "after",
                    widget: "dxButton",
                    options: {
                      text: getTrad("añadir"),
                      type: "success",
                      onClick: function (e) {
                        if (
                          $("#dxList_lavanderias")
                            .dxList("instance")
                            .option("selectedItemKeys").length > 0
                        ) {
                          crearInventario();
                          $("#dxPopup_selectorLavanderias")
                            .dxPopup("instance")
                            .hide();
                        } else
                          dxMensajePregunta(
                            getTrad("alert_sinDatos_lavanderia"),
                            [
                              [
                                getTrad("aceptar"),
                                function (e) {
                                  history.push("/");
                                },
                                "danger",
                                "dxButton_sinLavanderia_error",
                              ],
                            ],
                          );
                      },
                    },
                  },
                ],
              }),
            );
        },
        toolbarItems: [
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              type: "success",
              text: getTrad("crear"),
              onClick: function () {
                var lavanderiasNEnti = lavanderiasNEntidad(),
                  nuevoInventario_formData = $("#dxForm_nuevoInventario")
                    .dxForm("instance")
                    .option("formData");

                if (
                  lavanderiasNEnti.length > 1 &&
                  nuevoInventario_formData.incluirLavanderia
                ) {
                  if (validarNuevoInventario()) {
                    var arrayStore = new ArrayStore({
                      key: "idLavanderia",
                      data: lavanderiasNEnti,
                    });
                    $("#dxList_lavanderias")
                      .dxList("instance")
                      .option("dataSource", arrayStore);
                    $("#dxPopup_selectorLavanderias")
                      .dxPopup("instance")
                      .show();
                  }
                } else {
                  crearInventario();
                }
              },
            },
          },
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              text: getTrad("cancelar"),
              onClick: function () {
                $("#dxPopup_nuevoInventario").dxPopup("instance").hide();
              },
            },
          },
        ],
        //EVENTOS
        onShowing: function (e) {
          var dataSource = datasource_compaEnti.items()[0].tblEntidad;
          $("#dxList_nuevoInventario_entidad")
            .dxList("instance")
            .option("dataSource", dataSource);
          $("#dxList_nuevoInventario_compañia")
            .dxList("instance")
            .option("selectedItems", [datasource_compaEnti.items()[0]]);
        },
        onHiding: function (e) {
          var dxList_nuevoInventario_compañia = $(
              "#dxList_nuevoInventario_compañia",
            ).dxList("instance"),
            dxList_nuevoInventario_entidad = $(
              "#dxList_nuevoInventario_compañia",
            ).dxList("instance");

          $("#dxForm_nuevoInventario").dxForm("instance").resetValues();
          $("#dxList_prendas_nuevoInv")
            .dxList("instance")
            .option("searchValue", null);

          dxList_nuevoInventario_entidad.option("searchValue", null);
          dxList_nuevoInventario_entidad.unselectAll();

          dxList_nuevoInventario_compañia.option("searchValue", null);
          dxList_nuevoInventario_compañia.unselectAll();
          dxList_nuevoInventario_compañia.scrollTo(0);
          dxList_nuevoInventario_compañia.repaint();
        },
      });

      $("#Inventarios #dxPopup_estadoInventarios").dxPopup({
        showTitle: true,
        showCloseButton: false,
        title: getTrad("estadoInventario"),
        height: 500,
        width: 600,
        deferRendering: false,
        contentTemplate: function (contentElement) {
          contentElement.append(
            $("<div id='dxDataGrid_estadosInventario'>").dxDataGrid({
              //Datos
              remoteOperations: true,
              //Propiedades
              columnsAutoWidth: true,
              repaintChangesOnly: true,
              editing: {
                mode: "cell",
                allowUpdating: true,
                refreshMode: "repaint",
              },
              grouping: {
                autoExpandAll: true,
                allowCollapsing: false,
              },
              groupPanel: {
                visible: false,
              },
              loadPanel: {
                enabled: false,
              },
              scrolling: { mode: "virtual" },
              columns: [
                {
                  dataField: "idInventario",
                  visible: false,
                },
                {
                  dataField: "denominacion",
                  caption: getTrad("denominacion"),
                  allowEditing: false,
                  allowSorting: false,
                },
                {
                  dataField: "estado",
                  caption: getTrad("estado"),
                  width: 120,
                  allowSorting: false,
                  showEditorAlways: true,
                  editCellTemplate: function (container, options) {
                    return $("<div/>").dxSwitch({
                      switchedOnText: getTrad("abierto"),
                      switchedOffText: getTrad("finalizado"),
                      value:
                        typeof options.data.estado !== "undefined"
                          ? options.data.estado
                          : true,
                      elementAttr: {
                        class: "dxSwitch_toolbar",
                      },
                      onValueChanged: function (e) {
                        datasource_spSelectInventarios
                          .store()
                          .update(options.row.data.idInventario, {
                            estado: e.value,
                          })
                          .done(function () {
                            datasource_spSelectInventarios.reload();
                          });
                      },
                    });
                  },
                },
                {
                  dataField: "idInventarioPadre",
                  caption: getTrad("inventario"),
                  allowEditing: false,
                  showColumnHeaders: false,
                  groupCellTemplate: function (cellElement, cellInfo) {
                    if (!cellInfo.data.items[0].idInventarioPadre)
                      cellElement.text(getTrad("inventario"));
                    else cellElement.text(getTrad("subinventarios"));
                  },
                  groupIndex: 0,
                },
              ],
              //EVENTOS
              onContentReady: function (e) {
                e.component.option("groupPanel.visible", false);
              },
              onRowPrepared: function (info) {
                info.rowElement
                  .find(".dx-datagrid-group-closed")
                  .hide()
                  .parent()
                  .on("dxclick", function (e) {
                    e.stopPropagation();
                  });
              },
              //Estilos
              showColumnLines: false,
              showRowLines: true,
              rowAlternationEnabled: true,
            }),
          );
        },
        //EVENTOS
        toolbarItems: [
          {
            location: "after",
            widget: "dxButton",
            toolbar: "bottom",
            options: {
              text: getTrad("cerrar"),
              type: "success",
              onClick: function () {
                $("#dxPopup_estadoInventarios").dxPopup("instance").hide();
              },
            },
          },
        ],
      });

      $("#Inventarios #dxPopup_prendasNAlmacen").dxPopup({
        width: 1000,
        height: "80%",
        title: getTrad("configuracion_Almacen"),
        contentTemplate: function (contentElement) {
          return $("<div id='dxDataGrid_tblPrendaNAlmacen' />")
            .dxDataGrid({
              //Datos
              dataSource: new ArrayStore({
                key: "idPrenda",
                data: array_prendasNAlmacenNInventario,
              }),
              //Propiedades
              columnsAutoWidth: true,
              repaintChangesOnly: true,
              height: "100%",
              editing: {
                refreshMode: "repaint",
                mode: "batch",
                allowUpdating: true,
                allowDeleting: false,
                useIcons: true,
                selectTextOnEditStart: true,
              },
              keyboardNavigation: {
                enterKeyAction: "moveFocus",
                enterKeyDirection: "row",
                editOnKeyPress: true,
              },
              grouping: {
                autoExpandAll: true,
                allowCollapsing: false,
              },
              groupPanel: {
                visible: false,
              },
              paging: { enabled: false },
              remoteOperations: false,
              columns: [
                {
                  dataField: "tblPrenda.idEntidad",
                  groupCellTemplate: function (cellElement, cellInfo) {
                    var isEntidad = cellInfo.data.items[0].idEntidad;
                    cellElement.text(
                      isEntidad
                        ? getTrad("prendasEnti")
                        : getTrad("prendasCompañia"),
                    );
                  },
                  visible: false,
                  groupIndex: 0,
                },
                {
                  dataField: "tblPrenda.codigoPrenda",
                  caption: getTrad("codigo"),
                  width: 100,
                  alignment: "center",
                  sortIndex: 0,
                  sortOrder: "asc",
                  allowEditing: false,
                },
                {
                  dataField: "tblPrenda.denominacion",
                  caption: getTrad("denominacion"),
                  minWidth: 250,
                  allowEditing: false,
                },
                {
                  dataField: "parStock",
                  caption: getTrad("stock"),
                  alignment: "center",
                  width: 80,
                  editorOptions: {
                    step: 0,
                    min: 0,
                    max: 99999999,
                  },
                  allowEditing: false,
                },
                {
                  dataField: "cantidad",
                  caption: getTrad("actual"),
                  alignment: "center",
                  width: 80,
                  cssClass:
                    !entidadSel || (entidadSel && !entidadSel.isCerrado)
                      ? "dx-Cell_Editable"
                      : "",
                  editorOptions: {
                    step: 0,
                    min: 0,
                    max: 99999999,
                  },
                  allowEditing:
                    !entidadSel || (entidadSel && !entidadSel.isCerrado),
                },
                {
                  dataField: "tblPrenda.tblColorTapa.codigoHexadecimal",
                  caption: getTrad("colorTapa"),
                  width: 100,
                  alignment: "center",
                  allowEditing: false,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  hidingPriority: 1,
                  cellTemplate: function (element, options) {
                    let data_ = $.extend(true, {}, options.data);
                    let data = {
                      codigoHexadecimal:
                        data_.tblPrenda.tblColorTapa != null
                          ? data_.tblPrenda.tblColorTapa.codigoHexadecimal
                          : null,
                      denoMarcaTapa:
                        data_.tblPrenda.tblMarcaTapa != null
                          ? data_.tblPrenda.tblMarcaTapa.denominacion
                          : null,
                    };
                    return get_template_colorTapa(element, data);
                  },
                },

                {
                  dataField: "tblPrenda.tblElemTransPedido.codigo",
                  caption: getTrad("elementoTransporte_abr"),
                  width: 80,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  alignment: "center",
                  cssClass: "p-0",
                  allowEditing: false,
                  hidingPriority: 0,
                  cellTemplate: function (container, options) {
                    var contenedor = $("<div />")
                        .addClass("container_spanCentrado")
                        .css("padding-left", "10px"),
                      htmlTooltip,
                      svg = null;

                    if (parseInt(options.value) === 1) {
                      // POR UDS
                      svg = svg_elemTrans_ud.clone().css({
                        "vertical-align": "middle",
                        height: "20px",
                        width: "20px",
                      });
                      htmlTooltip = "<p>" + getTrad("porUds") + "</p>";
                    } else if (parseInt(options.value) === 2) {
                      // BAC/SACA
                      svg = svg_elemTrans_bac.clone().css({
                        "vertical-align": "middle",
                        height: "20px",
                        width: "20px",
                      });
                      htmlTooltip =
                        "<p>" +
                        getTrad("bacSaca") +
                        ": " +
                        options.data.tblPrenda.udsXBacPedido +
                        "</p>";
                    }
                    if (svg) {
                      svg.appendTo(contenedor);

                      tooltipControl_creacion(container, htmlTooltip);
                    }
                    return contenedor;
                  },
                },
              ],
              onToolbarPreparing: function (e) {
                $.each(e.toolbarOptions.items, function (index, item) {
                  if (item.name === "saveButton") {
                    item.visible = false;
                  } else {
                    item.options.tooltipText = item.options.hint;
                    item.options.hint = null;

                    tooltipControl_creacion(
                      item,
                      item.options.tooltipText,
                      true,
                    );
                  }
                });
                e.toolbarOptions.items.unshift(
                  {
                    location: "before",
                    widget: "dxButton",
                    options: {
                      text: getTrad("prenda"),
                      icon: "plus",
                      visible: !$("#dxList_entidadesNInventario")
                        .dxList("instance")
                        .option("selectedItem.isLav"),
                      disabled: !(
                        !entidadSel ||
                        (entidadSel && !entidadSel.isCerrado)
                      ),
                      onClick: function () {
                        $("#dxPopup_tblPrendaNAlmacen")
                          .dxPopup("instance")
                          .show();
                      },
                    },
                  },
                  {
                    location: "before",
                    widget: "dxButton",
                    options: {
                      text: getTrad("noMolestar"),
                      visible: !$("#dxList_entidadesNInventario")
                        .dxList("instance")
                        .option("selectedItem.isLav"),
                      disabled: !(
                        !entidadSel ||
                        (entidadSel && !entidadSel.isCerrado)
                      ),
                      onClick: function () {
                        dxMensajePregunta(getTrad("alerta_NoMolestar"), [
                          // ACEPTAR
                          [
                            getTrad("aceptar"),
                            function () {
                              guardarPrendasNAlmacenNInventario(true);
                            },
                            "normal",
                            "btnAceptar",
                          ],
                          // CANCELAR
                          [
                            getTrad("cancelar"),
                            function () {},
                            "normal",
                            "btnCancelar",
                          ],
                        ]);
                      },
                    },
                  },
                );
              },
              //Estilos
              showColumnLines: false,
              showRowLines: true,
              rowAlternationEnabled: true,
            })
            .append(
              $("<div id='dxPopup_tblPrendaNAlmacen' />").dxPopup({
                width: 500,
                height: 500,
                deferRendering: false,
                title: getTrad("prendasAlmacen"),
                contentTemplate: function () {
                  return $("<div/>").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    width: "100%",
                    items: [
                      {
                        baseSize: 50,
                        shrink: 0,
                        ratio: 0,
                        template: function () {
                          return $(
                            "<div class='font-size'>" +
                              getTrad("seleccionPrenda") +
                              "</div>",
                          );
                        },
                      },
                      {
                        ratio: 1,
                        template: function () {
                          return $(
                            "<div id='dxList_addPrendasAlmacen' />",
                          ).dxList({
                            dataSource: datasource_tblPrendasNInventario,
                            pageLoadMode: "scrollBottom",
                            searchEnabled: true,
                            searchExpr: "denominacion",
                            focusStateEnabled: false,
                            selectionMode: "all",
                            selectAllMode: "allPages",
                            showSelectionControls: true,
                            height: "100%",
                            keyExpr: "idPrenda",
                            itemTemplate: function (e) {
                              return e.codigoPrenda + "  -  " + e.denominacion;
                            },
                          });
                        },
                      },
                    ],
                  });
                },
                toolbarItems: [
                  {
                    toolbar: "bottom",
                    location: "after",
                    widget: "dxButton",
                    options: {
                      text: getTrad("guardar"),
                      onClick: function () {
                        var dxDataGrid_tblPrendaNAlmacen = $(
                          "#dxDataGrid_tblPrendaNAlmacen",
                        ).dxDataGrid("instance");
                        var tblPrendaNAlmacen =
                          dxDataGrid_tblPrendaNAlmacen.getDataSource().items()
                            .length > 0
                            ? dxDataGrid_tblPrendaNAlmacen
                                .getDataSource()
                                .items()[0].items
                            : [];
                        var prendasSel = $("#dxList_addPrendasAlmacen")
                          .dxList("instance")
                          .option("selectedItems");

                        //Añadir aquellas que se han seleccionado pero no están en el formData
                        $.each(prendasSel, function (index, prendaSel) {
                          var objPrenda = {};
                          var isDuplicada =
                            $.grep(tblPrendaNAlmacen, function (prenda) {
                              return prendaSel.idPrenda === prenda.idPrenda;
                            }).length > 0;

                          objPrenda.idPrenda = prendaSel.idPrenda;
                          objPrenda.parStock = 0;
                          objPrenda.cantidad = 0;
                          objPrenda.tblPrenda = prendaSel;
                          if (!isDuplicada) tblPrendaNAlmacen.push(objPrenda);
                        });
                        var arrayData = new ArrayStore({
                          key: "idPrenda",
                          data: tblPrendaNAlmacen,
                        });
                        dxDataGrid_tblPrendaNAlmacen.option(
                          "dataSource",
                          arrayData,
                        ); // porque al insertar nueva prenda, se sustituye el datasource por uno local, entonces refresh no funciona.

                        $("#dxPopup_tblPrendaNAlmacen")
                          .dxPopup("instance")
                          .hide();
                      },
                    },
                  },
                  {
                    toolbar: "bottom",
                    location: "after",
                    widget: "dxButton",
                    options: {
                      text: getTrad("cancelar"),
                      onClick: function () {
                        $("#dxPopup_tblPrendaNAlmacen")
                          .dxPopup("instance")
                          .hide();
                      },
                    },
                  },
                ],
                onShowing: function () {
                  $("#dxList_addPrendasAlmacen")
                    .dxList("instance")
                    .unselectAll();
                },
                onHiding: function () {
                  var dxList_addPrendasAlmacen = $(
                    "#dxList_addPrendasAlmacen",
                  ).dxList("instance");
                  dxList_addPrendasAlmacen.scrollTo(0);
                  dxList_addPrendasAlmacen.option("searchValue", null);
                },
              }),
            );
        },
        toolbarItems: [
          {
            toolbar: "top",
            location: "center",
            html: "<span id='dxPopup_prendasNAlmacen_title'></span>",
          },
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              elementAttr: { id: "dxPopup_prendasNAlmacen_btnValidar" },
              text: getTrad("validar"),
              onClick: function () {
                guardarPrendasNAlmacenNInventario();
              },
            },
          },
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              text: getTrad("cancelar"),
              onClick: function () {
                $("#dxPopup_prendasNAlmacen").dxPopup("instance").hide();
              },
            },
          },
        ],
        onShowing: function () {
          $("#dxPopup_prendasNAlmacen_title").text(
            typeof almacenSel.denominacion !== "undefined"
              ? almacenSel.denominacion
              : getTrad("nuevoAlmacen"),
          );
          var dxDataGrid_tblPrendaNAlmacen = $(
            "#dxDataGrid_tblPrendaNAlmacen",
          ).dxDataGrid("instance");

          const isEntidadAbierta =
            !entidadSel || (entidadSel && !entidadSel.isCerrado);

          dxDataGrid_tblPrendaNAlmacen.columnOption(
            4,
            "cssClass",
            isEntidadAbierta ? "dx-Cell_Editable" : "",
          );
          dxDataGrid_tblPrendaNAlmacen.columnOption(
            4,
            "allowEditing",
            isEntidadAbierta,
          );

          dxDataGrid_tblPrendaNAlmacen.repaint();
          dxDataGrid_tblPrendaNAlmacen.cancelEditData();

          $("#dxPopup_prendasNAlmacen_btnValidar")
            .dxButton("instance")
            .option(
              "disabled",
              !(!entidadSel || (entidadSel && !entidadSel.isCerrado)),
            );
        },
        onShown: function () {
          var grid = $("#dxDataGrid_tblPrendaNAlmacen").dxDataGrid("instance");
          if (typeof grid !== "undefined") {
            var data = $.grep(
              datasource_tblPrendasNInventario.items(),
              function (v) {
                return grid.getRowIndexByKey(v.idPrenda) === -1;
              },
            );
            $("#dxList_addPrendasAlmacen")
              .dxList("instance")
              .option("dataSource", data);
          }
        },
        onHiding: function () {
          var dxDataGrid_tblPrendaNAlmacen = $(
            "#dxDataGrid_tblPrendaNAlmacen",
          ).dxDataGrid("instance");
          dxDataGrid_tblPrendaNAlmacen.getScrollable().scrollTo(0);
          dxDataGrid_tblPrendaNAlmacen.clearFilter();
        },
        onHidden: function () {
          $("#dxDataGrid_tblPrendaNAlmacen")
            .dxDataGrid("instance")
            .option("dataSource", null);
        },
      });

      $("#Inventarios #dxPopup_informePrendas").dxPopup({
        width: 1000,
        height: 700,
        title: getTrad("informePrendas"),
        deferRendering: false,
        contentTemplate: function (contentElement) {
          contentElement.append(
            $("<div id='dxPivotGrid_informePrendas'>").dxPivotGrid({
              height: "100%",
              showColumnGrandTotals: false,
              showRowGrandTotals: false,
              showRowTotals: false,
              showBorders: true,
              fieldChooser: {
                enabled: false,
              },
              loadPanel: {
                enabled: false,
              },
              dataSource: {
                retrieveFields: false,
                store: [],
                fields: [
                  {
                    caption: getTrad("codigo"),
                    width: 40,
                    dataField: "idPrenda",
                    expanded: true,
                    selector: function (data) {
                      return $.grep(
                        datasource_prendasInfPrendas.items(),
                        function (prenda) {
                          return data.idPrenda === prenda.idPrenda;
                        },
                      )[0].codigoPrenda;
                    },
                    area: "row",
                  },
                  {
                    caption: getTrad("denominacion"),
                    width: 400,
                    dataField: "denominacion",
                    selector: function (data) {
                      return $.grep(
                        datasource_prendasInfPrendas.items(),
                        function (prenda) {
                          return data.idPrenda === prenda.idPrenda;
                        },
                      )[0].denominacion;
                    },
                    expanded: true,
                    area: "row",
                  },
                  {
                    dataField: "idInventario",
                    area: "column",
                    sortingMethod: function (a, b) {
                      // SubInventario
                      if (inventarioPadreSel) {
                        if (a.value > b.value) return -1;
                        else return 2;
                      } else {
                        //Inventario
                        var splitDate_1 = a.text.split("/"),
                          splitDate_2 = b.text.split("/");

                        var primerValor = isValidDate(a.text)
                            ? new Date(
                                splitDate_1[2],
                                splitDate_1[1],
                                splitDate_1[0],
                              )
                            : null,
                          segundoValor = isValidDate(b.text)
                            ? new Date(
                                splitDate_2[2],
                                splitDate_2[1],
                                splitDate_2[0],
                              )
                            : null;

                        if (splitDate_1.length === 1) return 2;
                        if (primerValor && segundoValor) {
                          if (primerValor > segundoValor) {
                            return 2;
                          } else {
                            return -1;
                          }
                        }
                        if (!primerValor) return 2;
                        if (!segundoValor) return -1;
                      }
                    },
                    width: 90,
                    customizeText: function (cellInfo) {
                      var drillDownDataSource = $("#dxPivotGrid_informePrendas")
                        .dxPivotGrid("instance")
                        .getDataSource()
                        .createDrillDownDataSource();
                      drillDownDataSource.paginate(false);
                      drillDownDataSource.load().done();

                      var items = drillDownDataSource
                          .items()
                          .filter(function (item) {
                            return item.idInventario === cellInfo.value;
                          }),
                        filteredItems = items.filter(function (item) {
                          return item.idInventario === cellInfo.value;
                        }),
                        fecha = filteredItems[0].fecha;

                      var is_subInv = inventarioSel.idInventarioPadre
                        ? true
                        : false;
                      return cellInfo.value === inventarioSel.idInventario
                        ? getTrad("actual")
                        : is_subInv
                          ? getTrad("inventario")
                          : formatDate(fecha);
                    },
                  },
                  {
                    dataField: "cantidad",
                    area: "data",
                    summaryType: "custom",
                    calculateCustomSummary: function (options) {
                      if (options.summaryProcess === "start") {
                        options.totalValue = null;
                      }
                      if (options.summaryProcess === "calculate") {
                        if (options.value) {
                          options.totalValue += options.value;
                        }
                      }
                    },
                  },
                ],
              },
              onContentReady: function (e) {
                // AÑADIR NUEVA COLUMNA
                $(".dx-area-description-cell")
                  .empty()
                  .append(
                    $("<div id='infPrendas_titPrendaContainer' />")
                      .css({
                        display: "flex",
                        "flex-direction": "row",
                        "text-align": "center",
                        "vertical-align": "middle",
                      })
                      .append(
                        $("<div />")
                          .css({
                            flex: "67px 0 0",
                            "border-right": "#DDD solid 1px",
                            padding: "7px 10px",
                          })
                          .text(getTrad("codigo")),
                      )
                      .append(
                        $("<div />")
                          .css({ flex: "1 0 0", padding: "7px 10px" })
                          .text(getTrad("denominacion")),
                      ),
                  );
              },
              onCellPrepared: function (e) {
                e.cellElement.css("cursor", "auto");
                if (e.area === "data") {
                  e.cellElement.css("text-align", "center");
                  if (!e.cell.value) e.cellElement.text("-");
                } else if (e.area === "column") {
                  e.cellElement.css("color", "#333");
                  e.cellElement.css("background-color", "#F8F8F8");
                }
              },
              onCellClick: function (e) {
                e.cancel = true;
              },
            }),
          );
        },
        toolbarItems: [
          {
            toolbar: "top",
            location: "center",
            html: "<span id='dxPopup_informePrendas_title'></span>",
          },
        ],
        onHidden: function (e) {
          $("#dxPivotGrid_informePrendas")
            .dxPivotGrid("instance")
            .option("dataSource.store", []); //Reiniciamos el pivot grid
        },
        onShown: function (e) {
          $("#dxPivotGrid_informePrendas").dxPivotGrid("instance").repaint(); // sin esto no funciona si solo hay una columna.
        },
      });

      $("#Inventarios #dxPopup_impresion").dxPopup({
        width: 500,
        height: 600,
        title: getTrad("impresion"),
        deferRendering: false,
        contentTemplate: function (contentElement) {
          contentElement.append(
            $("<div />").dxBox({
              direction: "col",
              align: "space-around",
              crossAlign: "stretch",
              height: "100%",
              items: [
                {
                  ratio: 1,
                  template: function () {
                    return $("<div id='dxList_impresion'>").dxList({
                      dataSource: [],
                      height: "100%",
                      selectionMode: "all",
                      selectAllMode: "allPages",
                      scrolling: { mode: "infinite" },
                      searchEnabled: true,
                      searchExpr: ["denominacion"],
                      displayExpr: "denominacion",
                      pageLoadMode: "scrollBottom",
                      showSelectionControls: true,
                      itemTemplate: function (itemData, index, itemElement) {
                        rowTemplate_denoProcentaje_dxList(
                          itemData.denominacion,
                          itemData.porcentaje,
                          itemElement,
                        );
                      },
                      onContentReady: function (e) {
                        e.component.selectAll();
                      },
                    });
                  },
                },
              ],
            }),
          );
        },
        onShowing: function (e) {
          var dxList_impresion = $("#dxList_impresion").dxList("instance");
          dxList_impresion.option("searchValue", null);
          dxList_impresion.repaint();
        },
        toolbarItems: [
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              text: getTrad("imprimir"),
              onClick: function () {
                var selectedItems = $("#dxList_impresion")
                  .dxList("instance")
                  .option("selectedItems");
                if (selectedItems.length > 0) {
                  var idInventario = inventarioSel.idInventario,
                    idsEntidad = [],
                    idsSeccionNivel1 = [],
                    idsSeccionNivel2 = [];

                  $.each(selectedItems, function (index, item) {
                    if (item.id) idsEntidad.push(item.id);
                    if (item.idSeccionNivel1)
                      idsSeccionNivel1.push(item.idSeccionNivel1);
                    if (item.idSeccionNivel2)
                      idsSeccionNivel2.push(item.idSeccionNivel2);
                  });

                  var params = {
                    idInventario: idInventario,
                    seccionesNivel1: idsSeccionNivel1
                      .toString()
                      .replace(/,/g, "|"),
                    seccionesNivel2: idsSeccionNivel2
                      .toString()
                      .replace(/,/g, "|"),
                    idsEntidad: idsEntidad.toString().replace(/,/g, "|"),
                  };

                  COMPONENT.setParams(params);
                  $("#dxPopup_impresion").dxPopup("instance").hide();
                } else {
                  notify({
                    message: getTrad("alerta_SeleccionarParaImprimir"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                }
              },
            },
          },
        ],
      });

      $("#Inventarios #dxPopup_nuevoSubInventario")
        .dxPopup({
          showTitle: false,
          title: getTrad("nuevoSubInventario"),
          height: 625,
          width: "70%",
          contentTemplate: function (contentElement) {
            contentElement.append(
              $("<div id='dxForm_nuevoSubInventario'/>").dxForm({
                labelLocation: "top",
                height: "100%",
                scrollingEnabled: false,
                colCount: 5,
                items: [
                  {
                    itemType: "group",
                    colSpan: 1,
                    caption: getTrad("nuevoSubInventario"),
                    colCountByScreen: {
                      xl: 1,
                      lg: 1,
                      md: 1,
                      sm: 1,
                      xs: 1,
                    },
                    items: [
                      {
                        dataField: "denominacion",
                        label: { text: getTrad("denominacion") },
                        validationRules: [
                          {
                            type: "required",
                            message: getTrad("campoNecesario"),
                          },
                        ],
                      },
                      {
                        dataField: "fecha",
                        label: { text: getTrad("fecha") },
                        displayFormat: "dd/MM/yyyy",
                        editorType: "dxCalendar",
                        validationRules: [
                          {
                            type: "required",
                            message: getTrad("campoNecesario"),
                          },
                        ],
                      },
                      {
                        itemType: "button",
                        alignment: "right",
                        buttonOptions: {
                          type: "success",
                          text: getTrad("crear"),
                          onClick: function () {
                            if (validarNuevoSubInventario()) {
                              datasource_prendaNSubInventario
                                .reload()
                                .done(function (prendasInventario) {
                                  var dataSource = new DataSource({
                                    store: prendasInventario,
                                    paginate: false,
                                  });

                                  $("#dxList_prendasSubInventario")
                                    .dxList("instance")
                                    .option("dataSource", dataSource);
                                  $("#dxPopup_tblPrendasSubInventario")
                                    .dxPopup("instance")
                                    .show();
                                });
                            }
                          },
                        },
                      },
                    ],
                  },
                  {
                    itemType: "group",
                    colSpan: 4,
                    caption: getTrad("seleccioneSubInv"),
                    items: [
                      {
                        dataField: "tblSubInventarios",
                        label: { visible: false },
                        template: function (data, itemElement) {
                          return $(
                            "<div id='dxDataGrid_subInventarios' />",
                          ).dxDataGrid({
                            dataSource: array_subInventarios.filter(
                              function (inventario) {
                                return (
                                  inventario.idInventarioPadre ===
                                  inventarioSel.idInventario
                                );
                              },
                            ),
                            remoteOperations: true,
                            //Propiedades
                            columnsAutoWidth: true,
                            height: 470,
                            width: "100%",
                            paging: {
                              pageSize: 50,
                            },
                            pager: {
                              showPageSizeSelector: true,
                              allowedPageSizes: [20, 50, 100],
                              showInfo: true,
                            },
                            headerFilter: {
                              visible: true,
                            },
                            filterRow: {
                              visible: true,
                              applyFilter: "auto",
                            },
                            columns: [
                              {
                                caption: " ",
                                width: 60,
                                alignment: "center",
                                cellTemplate: function (container, options) {
                                  svg_edit
                                    .clone()
                                    .css({
                                      height: 23,
                                      width: 23,
                                      cursor: "pointer",
                                      "vertical-align": "middle",
                                    })
                                    .on("click", function () {
                                      inventarioPadreSel = $.extend(
                                        true,
                                        {},
                                        inventarioSel,
                                      );
                                      inventarioSel = options.data;

                                      datasource_tblPrendasNInventario.load();
                                      $("#dxPopup_nuevoSubInventario")
                                        .dxPopup("instance")
                                        .hide();

                                      pantallaInvPorDefecto();
                                      onChange_inventarioSel(inventarioSel);
                                    })
                                    .appendTo(container);
                                },
                              },
                              {
                                dataField: "fecha",
                                dataType: "date",
                                sortOrder: "desc",
                                alignment: "center",
                                format: "dd/MM/yyyy",
                                caption: getTrad("fecha"),
                                width: 160,
                                hidingPriority: 2,
                                allowHeaderFiltering: false,
                              },
                              {
                                dataField: "denominacion",
                                caption: getTrad("denominacion"),
                                allowHeaderFiltering: false,
                              },
                              {
                                dataField: "porcentaje",
                                caption: getTrad("progreso"),
                                alignment: "center",
                                width: 100,
                                hidingPriority: 0,
                                allowHeaderFiltering: false,
                                allowFiltering: false,
                                cssClass: "p-0",
                                cellTemplate: function (container, options) {
                                  $("<div />")
                                    .attr("style", "padding: 0 1px 0 1px")
                                    .dxProgressBar({
                                      min: 0,
                                      max: 1,
                                      value: options.data.porcentaje,
                                      showStatus: true,
                                      statusFormat: function (ratio, value) {
                                        return formatNumber(
                                          ratio,
                                          (ratio * 100) % 1 !== 0 ? 2 : 0,
                                          "percent",
                                        );
                                      },
                                      onContentReady: function (e) {
                                        e.element
                                          .find(".dx-progressbar-range")
                                          .css({
                                            "border-radius": 2,
                                            "padding-rigth": 1,
                                          });
                                      },
                                    })
                                    .appendTo(container);
                                },
                              },
                              {
                                dataField: "estadoAplicado",
                                caption: getTrad("aplicado"),
                                width: 95,
                                hidingPriority: 1,
                                alignment: "center",
                                cssClass: "p-0",
                                cellTemplate: function (container, options) {
                                  var estadoImg = {};

                                  switch (options.data.estadoAplicado) {
                                    case 0:
                                      estadoImg = {
                                        svg: svg_remove_circle,
                                        color: enum_colores["rojo"],
                                      };
                                      break;
                                    case 1:
                                      estadoImg = {
                                        svg: svg_tick_circle,
                                        color: enum_colores["verde"],
                                      };
                                      break;
                                    case 2:
                                      estadoImg = {
                                        svg: svg_tick_circle,
                                        color: enum_colores["ambar"],
                                      };
                                      break;
                                    default:
                                      break;
                                  }

                                  estadoImg.svg
                                    .clone()
                                    .css({
                                      fill: estadoImg.color,
                                      "vertical-align": "middle",
                                      height: "20px",
                                      width: "20px",
                                    })
                                    .appendTo(container);
                                },
                                allowHeaderFiltering: true,
                                allowFiltering: false,
                                headerFilter: {
                                  dataSource: enum_estadoAplicado,
                                },
                              },
                              {
                                caption: " ",
                                width: 60,
                                alignment: "center",
                                cellTemplate: function (container, options) {
                                  svg_remove
                                    .clone()
                                    .css({
                                      height: 23,
                                      width: 23,
                                      cursor: "pointer",
                                      "vertical-align": "middle",
                                    })
                                    .on("click", function () {
                                      options.component.deleteRow(
                                        options.rowIndex,
                                      );
                                    })
                                    .appendTo(container);
                                },
                              },
                            ],
                            onRowRemoved: function (e) {
                              datasource_spSelectInventarios
                                .store()
                                .remove(e.key.idInventario)
                                .done(function () {
                                  array_subInventarios = $.grep(
                                    array_subInventarios,
                                    function (inventario) {
                                      return (
                                        e.key.idInventario !==
                                        inventario.idInventario
                                      );
                                    },
                                  );
                                });
                            },
                            //Estilos
                            showColumnLines: false,
                            showRowLines: true,
                            rowAlternationEnabled: true,
                          });
                        },
                      },
                    ],
                  },
                ],
              }),
            );
          },
          toolbarItems: [
            {
              toolbar: "bottom",
              location: "after",
              widget: "dxButton",
              options: {
                text: getTrad("cancelar"),
                onClick: function () {
                  $("#dxPopup_nuevoSubInventario").dxPopup("instance").hide();
                },
              },
            },
          ],
          onShowing: function () {
            var dataSource = array_subInventarios.filter(function (inventario) {
              return (
                inventario.idInventarioPadre === inventarioSel.idInventario
              );
            });
            $("#dxDataGrid_subInventarios")
              .dxDataGrid("instance")
              .option("dataSource", dataSource);
          },
          //EVENTOS
          onHiding: function (e) {
            $("#dxForm_nuevoSubInventario").dxForm("instance").resetValues();
          },
        })
        .append(
          $("<div id='dxPopup_tblPrendasSubInventario' />").dxPopup({
            width: 500,
            height: 500,
            showTitle: false,
            deferRendering: false,
            contentTemplate: function (contentElement) {
              contentElement.append(
                $("<div/>").dxBox({
                  direction: "col",
                  align: "space-around",
                  crossAlign: "stretch",
                  height: "100%",
                  width: "100%",
                  items: [
                    {
                      baseSize: 40,
                      shrink: 0,
                      ratio: 0,
                      template: function () {
                        return $(
                          "<div class='font-size'>" +
                            getTrad("prendas") +
                            "</div>",
                        );
                      },
                    },
                    {
                      ratio: 1,
                      template: function () {
                        return $("<div id='dxList_prendasSubInventario' />")
                          .css({ "padding-top": "20px;" })
                          .dxList({
                            height: 300,
                            selectionMode: "all",
                            selectAllMode: "allPages",
                            scrolling: { mode: "infinite" },
                            searchEnabled: true,
                            searchExpr: ["denominacion"],
                            displayExpr: "denominacion",
                            pageLoadMode: "scrollBottom",
                            showSelectionControls: true,
                            itemTemplate: function (data, index, item) {
                              item.append(
                                $("<div />")
                                  .text(data.codigoPrenda)
                                  .css("margin-right", 20)
                                  .css("float", "left"),
                                $("<div />")
                                  .text(data.denominacion)
                                  .css("float", "left"),
                              );
                            },
                          });
                      },
                    },
                  ],
                }),
              );
            },
            toolbarItems: [
              {
                toolbar: "bottom",
                location: "after",
                widget: "dxButton",
                options: {
                  text: getTrad("crear"),
                  onClick: function () {
                    crearSubInventario();
                  },
                },
              },
              {
                toolbar: "bottom",
                location: "after",
                widget: "dxButton",
                options: {
                  text: getTrad("cancelar"),
                  onClick: function () {
                    $("#dxPopup_tblPrendasSubInventario")
                      .dxPopup("instance")
                      .hide();
                  },
                },
              },
            ],
            onShowing: function (e) {
              $("#dxList_prendasSubInventario").dxList("instance").repaint();
            },
            onHiding: function (e) {
              var dxList_prendasSubInventario = $(
                "#dxList_prendasSubInventario",
              ).dxList("instance");
              dxList_prendasSubInventario.unselectAll();
              dxList_prendasSubInventario.scrollTo(0);
            },
          }),
        );

      $("#Inventarios #dxPopup_tblPrendasSubInv_validar").dxPopup({
        width: 500,
        height: 500,
        title: getTrad("prendasInventario"),
        contentTemplate: function (contentElement) {
          contentElement.append(
            $("<div id='dxDataGrid_prendasSubInv_validar' />").dxDataGrid({
              dataSource: datasource_prendaNSubInventario,
              keyExpr: "idPrenda",
              //Propiedades
              columnsAutoWidth: true,
              height: "100%",
              paging: {
                enabled: false,
              },
              pager: {
                visible: false,
              },
              selection: {
                mode: "multiple",
                showCheckBoxesMode: "always",
              },
              headerFilter: {
                visible: true,
              },
              filterRow: {
                visible: true,
                applyFilter: "auto",
              },
              hoverStateEnabled: true,
              columns: [
                {
                  dataField: "codigoPrenda",
                  caption: getTrad("codigo"),
                  width: 80,
                  allowHeaderFiltering: false,
                },
                {
                  dataField: "denominacion",
                  caption: getTrad("denominacion"),
                  minWidth: 230,
                  allowHeaderFiltering: false,
                },
                {
                  dataField: "aplicado",
                  caption: getTrad("aplicado"),
                  width: 95,
                  alignment: "center",
                  cssClass: "p-0",
                  cellTemplate: function (container, options) {
                    var estadoImg = {};

                    switch (options.data.aplicado) {
                      case 0:
                        estadoImg = {
                          svg: svg_remove_circle,
                          color: enum_colores["rojo"], // No aplicada
                        };
                        break;
                      case 1:
                        estadoImg = {
                          svg: svg_tick_circle,
                          color: enum_colores["verde"], // Aplicada
                        };
                        break;
                      case 2:
                        estadoImg = {
                          svg: svg_tick_circle,
                          color: enum_colores["ambar"], // Hay aplicadas y no aplicadas
                        };
                        break;
                      default:
                        break;
                    }

                    estadoImg.svg
                      .clone()
                      .css({
                        fill: estadoImg.color,
                        "vertical-align": "middle",
                        height: "20px",
                        width: "20px",
                      })
                      .appendTo(container);
                  },
                  headerFilter: {
                    dataSource: enum_estadoAplicado,
                  },
                  allowHeaderFiltering: true,
                  allowFiltering: false,
                },
              ],
              onRowClick: function (e) {
                if (!e.isSelected) e.component.selectRows(e.key, true);
                else e.component.deselectRows(e.key);
              },
              onRowPrepared: function (info) {
                if (info.rowType === "data") {
                  info.rowElement.css("cursor", "pointer");
                }
              },
              //Estilos
              showColumnLines: false,
              showRowLines: true,
              rowAlternationEnabled: true,
            }),
          );
        },
        toolbarItems: [
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              text: getTrad("validar"),
              onClick: function () {
                var dxDataGrid_prendasSubInv_validar = $(
                    "#dxDataGrid_prendasSubInv_validar",
                  ).dxDataGrid("instance"),
                  prendasSel =
                    dxDataGrid_prendasSubInv_validar.getSelectedRowKeys();
                if (prendasSel.length > 0) {
                  var idsPrenda = $.map(prendasSel, function (item, index) {
                    return item + "";
                  }).join();

                  context_tblInventario
                    .invoke(
                      "inventarios_fnUpdateAplicarInventario",
                      {
                        idInventario: inventarioSel.idInventario,
                        idsPrenda: idsPrenda,
                      },
                      "GET",
                    )
                    .done(function () {
                      inventarioSel = $.extend(true, {}, inventarioPadreSel);
                      inventarioPadreSel = null;

                      datasource_spSelectInventarios.reload().done(function () {
                        dxDataGrid_prendasSubInv_validar.refresh();
                        dxDataGrid_prendasSubInv_validar.deselectAll();
                        $("#dxPopup_tblPrendasSubInv_validar")
                          .dxPopup("instance")
                          .hide();

                        pantallaInvPorDefecto();
                        dxMensajePregunta(
                          getTrad("aviso_C_SubinventarioAplicado"),
                          [
                            [
                              getTrad("aceptar"),
                              function (e) {},
                              "normal",
                              "btnError_subInvAplicado",
                            ],
                          ],
                        );
                      });
                      onChange_inventarioSel(inventarioSel);
                    });
                } else
                  dxMensajePregunta(getTrad("alerta_SeleccioneRegistro"), [
                    [
                      getTrad("aceptar"),
                      function (e) {},
                      "danger",
                      "btnError_sinRegistro",
                    ],
                  ]);
              },
            },
          },
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              text: getTrad("cancelar"),
              onClick: function () {
                $("#dxPopup_tblPrendasSubInv_validar")
                  .dxPopup("instance")
                  .hide();
              },
            },
          },
        ],
        onShowing: function () {
          if (
            typeof $("#dxDataGrid_prendasSubInv_validar").data("dxDataGrid") !==
            "undefined"
          )
            $("#dxDataGrid_prendasSubInv_validar")
              .dxDataGrid("instance")
              .refresh();
        },
        onHiding: function () {
          var dxDataGrid_prendasSubInv_validar = $(
            "#dxDataGrid_prendasSubInv_validar",
          ).dxDataGrid("instance");
          dxDataGrid_prendasSubInv_validar.getScrollable().scrollTo(0);
          dxDataGrid_prendasSubInv_validar.clearSelection();
        },
      });

      $("#Inventarios #dxPopup_visualizarPrendas").dxPopup({
        width: 550,
        height: "80%",
        title: getTrad("prendasInventario"),
        contentTemplate: function (contentElement) {
          contentElement.append(
            $("<div />").dxBox({
              direction: "col",
              height: "100%",
              items: [
                {
                  ratio: 1,
                  template: function (itemData, itemIndex, itemElement) {
                    itemElement.append(
                      $(
                        "<div id='dxDataGrid_tblPrendasNInventario'/>",
                      ).dxDataGrid({
                        //Datos
                        dataSource: datasource_tblPrendasNInventario,
                        remoteOperations: true,
                        //Propiedades
                        columnsAutoWidth: true,
                        repaintChangesOnly: true,
                        headerFilter: {
                          visible: true,
                        },
                        height: "100%",
                        filterRow: {
                          visible: true,
                          applyFilter: "auto",
                        },
                        paging: {
                          pageSize: 20,
                        },
                        loadPanel: {
                          enabled: false,
                        },
                        scrolling: {
                          mode: "infinite",
                        },
                        hoverStateEnabled: false,
                        columns: [
                          {
                            dataField: "codigoPrenda",
                            caption: getTrad("codigo"),
                            allowHeaderFiltering: false,
                            width: 90,
                            sortOrder: "asc",
                          },
                          {
                            dataField: "denominacion",
                            caption: getTrad("denominacion"),
                            allowHeaderFiltering: false,
                          },
                        ],
                        onToolbarPreparing: function (e) {
                          e.toolbarOptions.items.unshift({
                            location: "after",
                            widget: "dxButton",
                            options: {
                              text: getTrad("añadir"),
                              icon: "plus",
                              onClick: function () {
                                $("#dxPopup_addPrenda")
                                  .dxPopup("instance")
                                  .show();
                              },
                            },
                          });
                        },
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                        rowAlternationEnabled: true,
                      }),
                    );
                  },
                },
                {
                  baseSize: 20,
                },
                {
                  baseSize: 40,
                  template: function (itemData, itemIndex, itemElement) {
                    itemElement.append(
                      $("<div />").dxToolbar({
                        items: [
                          {
                            toolbar: "bottom",
                            location: "after",
                            widget: "dxButton",
                            options: {
                              type: "success",
                              text: getTrad("aceptar"),
                              onClick: function () {
                                $("#dxPopup_visualizarPrendas")
                                  .dxPopup("instance")
                                  .hide();
                              },
                            },
                          },
                        ],
                      }),
                    );
                  },
                },
              ],
            }),
          );
        },
        onShowing: function () {
          $("#dxDataGrid_tblPrendasNInventario")
            .dxDataGrid("instance")
            .clearFilter();
        },
      });

      $("#Inventarios #dxPopup_addPrenda").dxPopup({
        width: 550,
        height: "80%",
        title: getTrad("añadirPrendas"),
        contentTemplate: function (contentElement) {
          contentElement.append(
            $("<div />").dxBox({
              direction: "col",
              height: "100%",
              items: [
                {
                  ratio: 1,
                  template: function (itemData, itemIndex, itemElement) {
                    itemElement.append(
                      $(
                        "<div id='dxDataGrid_addPrendasInventario' />",
                      ).dxDataGrid({
                        //Datos
                        dataSource: datasource_inventarios_spSelectAddPrendas,
                        remoteOperations: true,
                        //Propiedades
                        columnsAutoWidth: true,
                        repaintChangesOnly: true,
                        headerFilter: {
                          visible: true,
                        },
                        height: "100%",
                        filterRow: {
                          visible: true,
                          applyFilter: "auto",
                        },
                        paging: {
                          pageSize: 20,
                        },
                        loadPanel: {
                          enabled: false,
                        },
                        scrolling: {
                          mode: "infinite",
                        },
                        selection: {
                          mode: "multiple",
                          selectAllMode: "allPages",
                          showCheckBoxesMode: "always",
                        },
                        hoverStateEnabled: false,
                        columns: [
                          {
                            dataField: "codigoPrenda",
                            caption: getTrad("codigo"),
                            allowHeaderFiltering: false,
                            width: 80,
                            sortOrder: "asc",
                          },
                          {
                            dataField: "denominacion",
                            caption: getTrad("denominacion"),
                            allowHeaderFiltering: false,
                          },
                        ],
                        onSelectionChanged: function (e) {
                          $("#dxButton_guardarPrendas")
                            .dxButton("instance")
                            .option(
                              "disabled",
                              e.selectedRowsData.length === 0,
                            );
                        },
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                        rowAlternationEnabled: true,
                      }),
                    );
                  },
                },
                {
                  baseSize: 20,
                },
                {
                  baseSize: 40,
                  template: function (itemData, itemIndex, itemElement) {
                    itemElement.append(
                      $("<div />").dxToolbar({
                        items: [
                          {
                            toolbar: "bottom",
                            location: "after",
                            widget: "dxButton",
                            options: {
                              elementAttr: { id: "dxButton_guardarPrendas" },
                              type: "success",
                              text: getTrad("guardar"),
                              onContentReady: function (e) {
                                e.component.option("disabled", true);
                              },
                              onClick: function () {
                                context_tblInventario
                                  .invoke(
                                    "inventarios_spInsertAddPrendas",
                                    {},
                                    "POST",
                                  )
                                  .done(function () {
                                    notify({
                                      message: getTrad(
                                        "aviso_C_RegistroInsertado",
                                      ),
                                      type: "success",
                                      displayTime: "1500",
                                      closeOnClick: true,
                                    });

                                    datasource_inventarios_spSelectAddPrendas.reload();
                                    datasource_tblPrendasNInventario.reload();
                                    $("#dxPopup_addPrenda")
                                      .dxPopup("instance")
                                      .hide();
                                  });
                              },
                            },
                          },
                        ],
                      }),
                    );
                  },
                },
              ],
            }),
          );
        },
        onShowing: function () {
          $("#dxDataGrid_addPrendasInventario")
            .dxDataGrid("instance")
            .clearFilter();
          $("#dxDataGrid_tblPrendasNInventario")
            .dxDataGrid("instance")
            .clearFilter();
        },
        onHidden: function () {
          $("#dxDataGrid_addPrendasInventario")
            .dxDataGrid("instance")
            .deselectAll();
        },
      });

      function dxPopup_firmas_ContentTemplate(contentElement) {
        let entiNLav = datasource_tblEntidadNInventario.items()[0];

        contentElement.append(
          $("<div />").dxBox({
            direction: "col",
            align: "space-around",
            crossAlign: "stretch",
            height: "100%",
            width: "100%",
            items: [
              {
                baseSize: 40,
                shrink: 0,
                ratio: 0,
                template: function () {
                  return $("<div />").dxForm({
                    labelLocation: "left",
                    height: "100%",
                    scrollingEnabled: false,
                    colCount: 1,
                    formData: entiNLav,
                    width: 250,
                    items: [
                      {
                        dataField: "fechaRegistro",
                        label: { text: getTrad("fecha") },
                        editorType: "dxDateBox",
                        editorOptions: {
                          displayFormat: "dd/MM/yyyy HH:mm",
                          disabled: true,
                        },
                      },
                    ],
                  });
                },
              },
              {
                ratio: 1,
                template: function () {
                  return $("<div/>").dxBox({
                    direction: "row",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        ratio: 1,
                        template: function (e, index, item) {
                          //POLARIER
                          item.append(
                            $("<div />").dxBox({
                              direction: "col",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              width: "100%",
                              items: [
                                {
                                  baseSize: 40,
                                  shrink: 0,
                                  ratio: 0,
                                  template: function () {
                                    return $(
                                      "<div class='font-size'>" +
                                        "Polarier" +
                                        "</div>",
                                    );
                                  },
                                },
                                {
                                  ratio: 1,
                                  template: function () {
                                    return $(
                                      "<img src='data:image/png;base64," +
                                        entiNLav.firmaPolarier +
                                        "'/>",
                                    ).css("border", "2px solid");
                                  },
                                },
                                {
                                  baseSize: 150,
                                  template: function () {
                                    return $("<div />").dxForm({
                                      labelLocation: "top",
                                      height: "100%",
                                      scrollingEnabled: false,
                                      colCount: 1,
                                      formData: entiNLav,
                                      items: [
                                        {
                                          dataField: "firmantePolarier",
                                          label: {
                                            text: getTrad("firmante"),
                                          },
                                          editorOptions: {
                                            disabled: true,
                                          },
                                        },
                                      ],
                                    });
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                      {
                        baseSize: 40,
                        shrink: 0,
                        ratio: 0,
                      },
                      {
                        ratio: 1,
                        template: function (e, index, item) {
                          //CLIENTE
                          item.append(
                            $("<div/>").dxBox({
                              direction: "col",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              width: "100%",
                              items: [
                                {
                                  baseSize: 40,
                                  shrink: 0,
                                  ratio: 0,
                                  template: function () {
                                    return $(
                                      "<div class='font-size'>" +
                                        getTrad("cliente") +
                                        "</div>",
                                    );
                                  },
                                },
                                {
                                  ratio: 1,
                                  template: function () {
                                    return $(
                                      "<img src='data:image/png;base64," +
                                        entiNLav.firmaCliente +
                                        "' />",
                                    ).css("border", "2px solid");
                                  },
                                },
                                {
                                  baseSize: 150,
                                  template: function () {
                                    return $("<div />").dxForm({
                                      labelLocation: "top",
                                      height: "100%",
                                      scrollingEnabled: false,
                                      colCount: 1,
                                      formData: entiNLav,
                                      items: [
                                        {
                                          dataField: "firmanteCliente",
                                          label: {
                                            text: getTrad("firmante"),
                                          },
                                          editorOptions: {
                                            disabled: true,
                                          },
                                        },
                                        {
                                          dataField: "correoCliente",
                                          label: { text: getTrad("correo") },
                                          editorOptions: {
                                            disabled: true,
                                          },
                                        },
                                      ],
                                    });
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                    ],
                  });
                },
              },
            ],
          }),
        );
      }

      $("#Inventarios #dxPopup_firmas").dxPopup({
        showTitle: true,
        showCloseButton: true,
        title: getTrad("validacionInventario"),
        height: 600,
        width: 900,
        deferRendering: true,
        contentTemplate: dxPopup_firmas_ContentTemplate,
      });
      //#endregion
    })
    .always(function () {
      deferred.resolve();
    });

  //#region FUNCIONES
  function lavanderiasNEntidad() {
    var lavanderias = [],
      nuevoInventario_formData = $("#dxForm_nuevoInventario")
        .dxForm("instance")
        .option("formData");

    $.each(nuevoInventario_formData.tblEntidad, function (index, item) {
      $.each(item.tblLavanderia, function (index, itemLavanderia) {
        var encontrada = false;

        encontrada =
          $.grep(lavanderias, function (lavanderia) {
            return lavanderia.idLavanderia === itemLavanderia.idLavanderia;
          }).length > 0;

        if (!encontrada) lavanderias.push(itemLavanderia);
      });
    });
    return lavanderias;
  }

  function rowTemplate_denoProcentaje_dxList(
    denominacion,
    porcentaje,
    element,
  ) {
    $("<div />")
      .css({
        display: "grid",
        "grid-auto-flow": "column",
        "grid-template-columns": "70% 30%",
      })
      .append(
        $("<div />").css("font-size", "16px").text(denominacion),
        $("<div />").dxProgressBar({
          min: 0,
          max: 1,
          width: "100%",
          value: porcentaje,
          showStatus: true,
          statusFormat: function (ratio, value) {
            return formatNumber(
              ratio,
              (ratio * 100) % 1 !== 0 ? 2 : 0,
              "percent",
            );
          },
          onContentReady: function (e) {
            e.element.find(".dx-progressbar-range").css({
              "background-color": colorProgressBar(e.component.option("value")),
              "border-color": colorProgressBar(e.component.option("value")),
              "border-radius": 2,
            });
          },
        }),
      )
      .appendTo(element);
  }

  function pantallaInvPorDefecto() {
    actualizarPercentageInventario(inventarioSel);
    if ($("#tit_nombre-fecha_Inventario").length > 0) {
      $("#tit_nombre-fecha_Inventario").text(
        `${inventarioSel.denominacion} - ${formatDate(inventarioSel.fecha)} `,
      );
      $("#btnEdit_nombreInventario")
        .addClass("icon_Edit ml-3 pointer")
        .on("click", onShow_PopupEdit_Inventario);
    }

    //#region LIMPIAR FILTROS
    if (
      typeof $("#dxDataGrid_tblAlmacenNInv").dxDataGrid("instance") !==
      "undefined"
    )
      $("#dxDataGrid_tblAlmacenNInv").dxDataGrid("instance").clearFilter();
    //#endregion

    if (
      typeof $("#dxList_entidadesNInventario").dxList("instance") !==
      "undefined"
    )
      $("#dxList_entidadesNInventario").dxList("instance").reload();

    if (typeof $("#dxToolbar_btnAtras").dxToolbar("instance") !== "undefined")
      $("#dxToolbar_btnAtras").dxToolbar("instance").repaint();

    $("#dxButton_documentacion")
      .dxButton("instance")
      .option({ visible: !inventarioSel?.idInventarioPadre });

    if (
      typeof $("#dxTabPanel_seccionAlmacen").dxTabPanel("instance") !==
      "undefined"
    )
      $("#dxTabPanel_seccionAlmacen")
        .dxTabPanel("instance")
        .option("selectedIndex", 0);

    $("#dxButton_visualizarPrendas")
      .dxButton("instance")
      .option("visible", inventarioPadreSel == null);
    $("#dxButton_actualizarPrendas")
      .dxButton("instance")
      .option("visible", inventarioPadreSel == null);
  }

  function crearInventario() {
    if (validarNuevoInventario()) {
      context_tblInventario
        .invoke("inventarios_fnInsertInventario", {}, "POST")
        .done(function (idInventario) {
          if (idInventario[0] === -1)
            dxMensajePregunta(getTrad("aviso_RegistroNoInsertado"), [
              [
                getTrad("aceptar"),
                function (e) {},
                "danger",
                "dxButton_insertInv_error",
              ],
            ]);
          else {
            inventarioSel = { idInventario: idInventario[0] };
            datasource_spSelectInventarios.reload().done(function () {
              $("#dxForm_nuevoInventario")
                .dxForm("instance")
                .option("formData", null);

              $("#dxPopup_nuevoInventario").dxPopup("instance").hide();
              $("#dxTabPanel_container")
                .dxTabPanel("instance")
                .option("selectedIndex", 1);

              notify({
                message: getTrad("aviso_C_RegistroInsertado"),
                type: "success",
                displayTime: "1500",
                closeOnClick: true,
              });
            });
          }
        });
    }
  }

  function formatDataInventario() {
    //#region Parámetros
    //#region FUNCION INTERNA
    function is_LavanderiaSelected(item) {
      var dxList_lavanderias = $("#dxList_lavanderias").dxList("instance"),
        lavanderias_selectedItemKeys =
          typeof dxList_lavanderias !== "undefined"
            ? dxList_lavanderias.option("selectedItemKeys")
            : [];

      if (lavanderiasNEntidad().length > 1) {
        return (
          (lavanderias_selectedItemKeys.length > 0 &&
            $.inArray(item.idLavanderia, lavanderias_selectedItemKeys) !==
              -1) ||
          lavanderias_selectedItemKeys.length === 0
        );
      } else return true;
    }
    //#endregion

    var inventario = $.extend(
      true,
      {},
      $("#dxForm_nuevoInventario").dxForm("instance").option("formData"),
    );

    var idsEntidad = $.map(inventario.tblEntidad, function (item, index) {
      return item.idEntidad;
    }).join();

    var idsPrenda = $.map(inventario.tblPrenda, function (item, index) {
      return item;
    }).join();

    var idsLavanderia = $.map(lavanderiasNEntidad(), function (item, index) {
      if (is_LavanderiaSelected(item)) return item.idLavanderia;
    }).join();
    //#endregion

    //#region objInventario
    var is_inventarioPorEntidad =
        $.grep(inventario.tblEntidad, function (entidad) {
          return entidad.inventarioPorEntidad === true;
        }).length > 0
          ? true
          : false,
      idCompañia = !is_inventarioPorEntidad
        ? $("#dxList_nuevoInventario_compañia")
            .dxList("instance")
            .option("selectedItemKeys")[0]
        : null,
      idEntidad = is_inventarioPorEntidad
        ? $("#dxList_nuevoInventario_entidad")
            .dxList("instance")
            .option("selectedItemKeys")[0]
        : null,
      incluirLavanderia =
        typeof inventario.incluirLavanderia !== "undefined"
          ? inventario.incluirLavanderia
          : false;

    $.extend(inventario, {
      idCompañia: idCompañia,
      idEntidad: idEntidad,
      idInventarioPadre: null,
    });
    delete inventario.tblEntidad;
    delete inventario.tblPrenda;
    //#endregion

    return {
      objInventario: inventario,
      incluirLavanderia: incluirLavanderia,
      idsEntidad: "'" + idsEntidad + "'",
      idsPrenda: "'" + idsPrenda + "'",
      idsLavanderia: "'" + idsLavanderia + "'",
    };
  }

  function formatDataSubInventario() {
    var subInventario = $.extend(
        true,
        {},
        $("#dxForm_nuevoSubInventario").dxForm("instance").option("formData"),
      ),
      prendasSel = $("#dxList_prendasSubInventario")
        .dxList("instance")
        .option("selectedItems");
    subInventario.idInventarioPadre = inventarioSel.idInventario;

    subInventario.incluirLavanderia =
      typeof subInventario.incluirLavanderia !== "undefined"
        ? subInventario.incluirLavanderia
        : false;

    var idsPrenda = $.map(prendasSel, function (item, index) {
      return item.idPrenda;
    }).join();

    return {
      objSubInventario: subInventario,
      incluirLavanderia: subInventario.incluirLavanderia,
      idsEntidad: "",
      idsPrenda: "'" + idsPrenda + "'",
      idsLavanderia: "",
    };
  }

  function actualizarPercentageInventario(inventarioSel) {
    if (inventarioSel) {
      $("#porcentajeContainer").css(
        "border-color",
        colorProgressBar(inventarioSel.porcentaje),
      );

      $("#porcentajeInventarioText").text(
        formatNumber(
          inventarioSel.porcentaje,
          (inventarioSel.porcentaje * 100) % 1 !== 0 ? 2 : 0,
          "percent",
        ),
      );

      var porcentajeValue = formatNumber(
        inventarioSel.porcentaje,
        (inventarioSel.porcentaje * 100) % 1 !== 0 ? 2 : 0,
        "percent",
      )
        .replace(/\s/g, "")
        .replace(",", "."); // QUITA EL ESPACIO EN BLANCO 0 %Y REEMPLAZA LAS COMAS PARA QUE DECIMALES NO PETEN
      $("#porcentajeValue").width(porcentajeValue);
      $("#porcentajeValue").css(
        "background-color",
        colorProgressBar(inventarioSel.porcentaje),
      );
    }
  }

  function validarNuevoInventario() {
    var isPrendaSel =
        $("#dxList_prendas_nuevoInv")
          .dxList("instance")
          .option("selectedItemKeys").length > 0
          ? true
          : false,
      isEntiSel =
        $("#dxList_nuevoInventario_entidad")
          .dxList("instance")
          .option("selectedItemKeys").length > 0
          ? true
          : false,
      dxForm_nuevoInventario = $("#dxForm_nuevoInventario").dxForm("instance"),
      formData = dxForm_nuevoInventario.option("formData");

    if (
      !dxForm_nuevoInventario.validate().isValid ||
      !isPrendaSel ||
      !isEntiSel
    ) {
      var errorText = "<div id = 'textoMensajeError'><div/>";
      if (!formData.denominacion || formData.denominacion === "")
        errorText += "- " + getTrad("denominacion") + "<br />";
      if (!formData.fecha) errorText += "- " + getTrad("fecha") + "<br />";

      if (!isPrendaSel) errorText += "- " + getTrad("prendas") + "<br />";
      if (!isEntiSel) errorText += "- " + getTrad("entidades") + "<br />";

      dxMensajePregunta(
        getTrad("alerta_rellenarCamposObligatorios") + " : <br />" + errorText,
        [
          [
            getTrad("aceptar"),
            function (e) {},
            "danger",
            "btnError_formUsuario",
          ],
        ],
      );
      return false;
    }
    return true;
  }

  function colorProgressBar(valorPorcentaje) {
    var color = "";
    if (valorPorcentaje < 0.3) {
      color = enum_colores["rojo"];
    } else if (valorPorcentaje >= 0.3 && valorPorcentaje < 0.7) {
      color = enum_colores["ambar"];
    } else if (valorPorcentaje >= 0.7) {
      color = enum_colores["verde"];
    } else {
      color = enum_colores["gris"];
    }
    return color;
  }

  function guardarPrendasNAlmacenNInventario(noMolestar) {
    noMolestar = typeof noMolestar !== "undefined" ? true : false;

    var dxDataGrid_tblPrendaNAlmacen = $(
        "#dxDataGrid_tblPrendaNAlmacen",
      ).dxDataGrid("instance"),
      dxDataGrid_tblPrendaNAlmacenRowsTotales = [],
      dxDataGrid_tblPrendaNAlmacenRowsActualizadas =
        dxDataGrid_tblPrendaNAlmacen.getVisibleRows(),
      tblAlmacenNInv = {
        estado: noMolestar ? 2 : 1, // DESPUÉS DE HACER NO MOLESTAR, SI SE MARCA, ENVIAR ESTADO = 2
        tblPrendaNAlmacenNInventario: [],
      };

    if (typeof dxList_addPrendasAlmacen !== "undefined") {
      var prendasSel = $("#dxList_addPrendasAlmacen")
        .dxList("instance")
        .option("selectedItems");
      if (prendasSel.length > 0) {
        $.each(array_prendasNAlmacenNInventario, function (index, prenda) {
          dxDataGrid_tblPrendaNAlmacenRowsTotales.push(prenda);
        });

        $.each(prendasSel, function (index, prendaSel) {
          var encontrada = false;

          encontrada =
            $.grep(array_prendasNAlmacenNInventario, function (prenda) {
              return prenda.idPrenda === prendaSel.idPrenda;
            }).length > 0;

          //si por prenda seleccionada no está en prenda original -> añadir a fuente de datos
          if (!encontrada)
            dxDataGrid_tblPrendaNAlmacenRowsTotales.push(prendaSel);
        });
      } else
        dxDataGrid_tblPrendaNAlmacenRowsTotales = $.extend(
          true,
          {},
          array_prendasNAlmacenNInventario,
        );
    } else
      dxDataGrid_tblPrendaNAlmacenRowsTotales = $.extend(
        true,
        {},
        array_prendasNAlmacenNInventario,
      );

    $.each(dxDataGrid_tblPrendaNAlmacenRowsTotales, function (index, prenda) {
      $.each(
        dxDataGrid_tblPrendaNAlmacenRowsActualizadas,
        function (index, prendaAct) {
          if (
            !noMolestar &&
            prendaAct.rowType === "data" &&
            prendaAct.data.idPrenda === prenda.idPrenda
          ) {
            prenda.cantidad = prendaAct.data.cantidad;
          }
        },
      );
      if (noMolestar) prenda.cantidad = prenda.parStock;
    });

    $.each(dxDataGrid_tblPrendaNAlmacenRowsTotales, function (index, item) {
      var prendaNAlmNInv = {
        idPrenda: item.idPrenda,
        cantidad: item.cantidad,
        parStock: item.parStock,
        aplicado: true,
      };
      tblAlmacenNInv.tblPrendaNAlmacenNInventario.push(prendaNAlmNInv);
    });

    datasource_datos_tblAlmacenNInventario
      .store()
      .update(
        {
          idAlmacen: almacenSel.idAlmacen,
          idInventario: inventarioSel.idInventario,
        },
        tblAlmacenNInv,
      )
      .done(function () {
        //#region Actualizar porcentajes de listas
        datasource_spSelectEntidadesNInventario.load();
        datasource_datos_seccionNivel1.load();
        datasource_datos_seccionNivel2.load();
        $("#dxDataGrid_tblAlmacenNInv").dxDataGrid("instance").refresh();
        //#endregion

        //#region CAMBIAR BARRA PORCENATJE INVENTARIO
        datasource_spSelectInventarios.reload().done(function () {
          actualizarPercentageInventario(inventarioSel);

          notify({
            message: getTrad("aviso_C_RegistroActualizado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });

          $("#dxPopup_prendasNAlmacen").dxPopup("instance").hide();
        });
        //#endregion
      });
  }

  function crearSubInventario() {
    var nuevoSubInventario_formData = $("#dxForm_nuevoSubInventario")
      .dxForm("instance")
      .option("formData");

    if (validarNuevoSubInventario()) {
      var inventario = $.extend(true, {}, nuevoSubInventario_formData);
      $.extend(inventario, {
        idCompañia: inventarioSel.idCompañia,
        idEntidad: inventarioSel.idEntidad,
        idInventarioPadre: inventarioSel.idInventario,
      });
      delete inventario.incluirLavanderia;

      context_tblInventario
        .invoke("inventarios_fnInsertInventario", {}, "POST")
        .done(function (idInventario) {
          if (idInventario[0] === -1)
            dxMensajePregunta(getTrad("aviso_RegistroNoInsertado"), [
              [
                getTrad("aceptar"),
                function (e) {},
                "danger",
                "dxButton_insertSubInv_error",
              ],
            ]);
          else {
            inventarioSel = { idInventario: idInventario[0] };
            datasource_spSelectInventarios.reload().done(function () {
              nuevoSubInventario_formData = null;

              notify({
                message: getTrad("aviso_C_RegistroInsertado"),
                type: "success",
                displayTime: "1500",
                closeOnClick: true,
              });
              pantallaInvPorDefecto();

              $("#dxPopup_tblPrendasSubInventario").dxPopup("instance").hide();
              $("#dxPopup_nuevoSubInventario").dxPopup("instance").hide();

              datasource_tblPrendasNInventario.load();
            });
          }
        });
    }
  }

  function validarNuevoSubInventario() {
    var dxForm_nuevoSubInventario = $("#dxForm_nuevoSubInventario").dxForm(
        "instance",
      ),
      formData = dxForm_nuevoSubInventario.option("formData");

    if (!dxForm_nuevoSubInventario.validate().isValid) {
      var errorText = "<div id = 'textoMensajeError'><div/>";

      if (formData.denominacion === "" || !formData.denominacion)
        errorText += "- " + getTrad("denominacion") + "<br />";
      if (!formData.fecha) errorText += "- " + getTrad("fecha") + "<br />";
      dxMensajePregunta(
        getTrad("alerta_rellenarCamposObligatorios") + " : <br />" + errorText,
        [
          [
            getTrad("aceptar"),
            function (e) {},
            "danger",
            "btnError_formUsuario",
          ],
        ],
      );
      return false;
    }
    return true;
  }

  //#endregion

  function isValidDate(fecha) {
    var bits = fecha.split("/");
    var d = new Date(bits[2], bits[1] - 1, bits[0]);
    return d && d.getMonth() + 1 === parseInt(bits[1]);
  }

  //IMPRESIÓN
  function CustomizeParameterEditors(report, s, e) {
    if (report === "inventarios_secciones") {
      if (
        e.parameter.name === "idInventario" ||
        e.parameter.name === "seccionesNivel1" ||
        e.parameter.name === "seccionesNivel2" ||
        e.parameter.name === "idsEntidad"
      ) {
        //INVISIBLES
        e.info.editor = $.extend({}, e.info.editor);
        e.info.visible = false;
      }
    }
  }

  return deferred.promise();
}

export const onSave_InventarioMod = (objUpdate, COMPONENT, LAVANDERIA) => {
  $("#tit_nombre-fecha_Inventario").text(
    `${objUpdate?.denominacion ?? inventarioSel.denominacion} - ${formatDate(
      objUpdate?.fecha ?? inventarioSel.fecha,
    )} `,
  );
  const datasource_spSelectInventarios = get_datasource_spSelectInventarios(
    COMPONENT,
    LAVANDERIA,
  );
  datasource_spSelectInventarios.load(); //* Actualiza inventarioSel
};
