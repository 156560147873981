import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  formatNumber,
  durationToDatetime,
  leadingZero,
  convertClienteUtcToLavanderiaUtc,
  formatDate_parameter,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ScrollView from "devextreme-react/scroll-view";

import {
  CircularGauge,
  Geometry,
  Scale,
  Tick,
  RangeContainer,
  Range,
  ValueIndicator,
  Margin,
} from "devextreme-react/circular-gauge";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";

import { Row, Col, Card, CardBody, CardHeader, CardFooter } from "reactstrap";

import { get_isMovil } from "../../../helpers";
import LastUpdateClock from "../../../components/LastUpdateClock";
//Css
import "./Css.scss";

//SignalR
import { signalRService, Group } from "../../../services";

const tiempoRefresco_Actual = 1000;
const tiempoCambioPagina = 25000;

const estadoLavadora_enum = {
  EN_USO: "EN_USO",
  PENDIENTE: "PENDIENTE",
  SIN_USO: "SIN_USO",
};

class DashboardLecturaLavadoras extends React.Component {
  constructor(props) {
    super(props);

    let { lavanderia } = this.props;
    this.state = {
      datos_Actual: {},
      dxMultiView_selectedIndex: 0,
      tiempoActual: convertClienteUtcToLavanderiaUtc(
        lavanderia.GMT,
        new Date(),
      ),
      ultimaFecha_load: convertClienteUtcToLavanderiaUtc(
        lavanderia.GMT,
        new Date(),
      ),
      is_movil: get_isMovil(this.props.resolucion),
    };

    // REFERENCIAS
    this.dxMultiView_paginas_REF = React.createRef();

    //DATASOURCE
    this.dataSource_lecturaLavadoras_beforeSend =
      this.dataSource_lecturaLavadoras_beforeSend.bind(this);
    this.dataSource_lecturaLavadoras_onLoaded =
      this.dataSource_lecturaLavadoras_onLoaded.bind(this);
    this.dataSource_lecturaLavadoras_onLoading =
      this.dataSource_lecturaLavadoras_onLoading.bind(this);

    //FUNCIONES PROPIAS
    this.cardHeader_create = this.cardHeader_create.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  get dxMultiView_paginas() {
    return this.dxMultiView_paginas_REF.current.instance;
  }

  //#region DATSOURCES
  dataSource_lecturaLavadoras = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_lecturaLavadoras_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.dataSource_lecturaLavadoras_onLoading(loadOptions);
      },
      onLoaded: (data) => {
        this.dataSource_lecturaLavadoras_onLoaded(data);
      },
      version: 4,
    }),
    select: ["denominacion,peso,capacidad,codigoLav"],
  });
  dataSource_lecturaLavadoras_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }
  dataSource_lecturaLavadoras_onLoading(loadOptions) {
    let idTipoMaquina = 4, // Tipo máquina lavadora
      { lavanderia } = this.props,
      fecha = formatDate_parameter(
        convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date()),
      ).split("T")[0];

    loadOptions.filter = [
      "tblTipoMaquinaNCategoriaMaquina/idTipoMaquina eq " +
        idTipoMaquina +
        " and activo eq true and eliminado eq false ",
    ];
    loadOptions.expand = [
      "tblLecturaLavadoras($orderby=fecha desc;$top=1;$filter=date(fecha) eq " +
        fecha +
        " ;$select=fecha,peso,caudal,consumo,lecturaValida,fechaFin;$expand=tblProgramasLavadora($select=denominacion,tiempoEstimado),tblEntidad($select=denominacion))",
    ];
  }
  dataSource_lecturaLavadoras_onLoaded(data) {
    //#region Añadir campo estado a datasource
    let { lavanderia } = this.props,
      tiempoActual = convertClienteUtcToLavanderiaUtc(
        lavanderia.GMT,
        new Date(),
      );

    if (data.length > 0) {
      $.each(data, function (index, item) {
        if (item.tblLecturaLavadoras.length === 0) {
          item.estado = estadoLavadora_enum.SIN_USO;
        } else if (item.tblLecturaLavadoras[0].fechaFin === null) {
          item.estado = estadoLavadora_enum.EN_USO;
        } else {
          let tiempoDesde_finLavado =
              tiempoActual - item.tblLecturaLavadoras[0].fechaFin,
            diffSeconds = tiempoDesde_finLavado / 1000,
            HH = Math.floor(diffSeconds / 3600);

          if (HH <= 0) item.estado = estadoLavadora_enum.PENDIENTE;
          //Después de 1 hora
          else item.estado = estadoLavadora_enum.SIN_USO;
        }
      });
    }
    //#endregion

    //#region Ordenación cartas
    data = $(data).sort(function (item_A, item_B) {
      if (item_A.estado === estadoLavadora_enum.PENDIENTE) {
        if (item_B.estado === estadoLavadora_enum.PENDIENTE)
          return item_A.tblLecturaLavadoras[0].fechaFin >
            item_B.tblLecturaLavadoras[0].fechaFin
            ? 1
            : -1;
        else return -1;
      } else if (item_A.estado === estadoLavadora_enum.EN_USO) {
        if (item_B.estado === estadoLavadora_enum.EN_USO) {
          //#region SE SACA LA FECHA DE INICIO DEL PROGRAMA + LA DURACIÓN PARA COMPRARALOS
          var tiempoTotal = [0, 0];
          $.each([item_A, item_B], function (index, item) {
            let fechaInicio = item.tblLecturaLavadoras[0].fecha,
              tiempoPrograma = new Date(
                durationToDatetime(
                  item.tblLecturaLavadoras[0].tblProgramasLavadora
                    .tiempoEstimado,
                ),
              ),
              secondsPrograma =
                tiempoPrograma.getHours() * 60 * 60 * 1000 +
                tiempoPrograma.getMinutes() * 60 * 1000 +
                tiempoPrograma.getSeconds() * 1000;

            tiempoTotal[index] = new Date(fechaInicio);
            tiempoTotal[index].setTime(
              tiempoTotal[index].getTime() + secondsPrograma,
            );
          });
          //#endregion

          return new Date(tiempoTotal[0]) < new Date(tiempoTotal[1]) ? -1 : 1;
        } else if (item_B.estado === estadoLavadora_enum.SIN_USO) return -1;
        else return 1;
      } else if (item_A.estado === estadoLavadora_enum.SIN_USO) {
        return 1;
      }
      return 1;
    });
    //#endregion

    this.setState({
      datos_Actual: data,
      ultimaFecha_load: convertClienteUtcToLavanderiaUtc(
        lavanderia.GMT,
        new Date(),
      ),
    });
    this.loadPanel_hide();
  }
  //#endregion

  cardHeader_create(datosLavadora) {
    let titulo,
      is_enUso = false;

    if (datosLavadora.estado === estadoLavadora_enum.EN_USO) {
      titulo = (
        <div>
          <div className="flex-fill font-size-lg font-weight-normal centerText">
            {datosLavadora.tblLecturaLavadoras[0].tblEntidad !== null
              ? datosLavadora.tblLecturaLavadoras[0].tblEntidad.denominacion
              : ""}
          </div>
          <div className="flex-fill font-size-md text-dark font-weight-bold centerText">
            {
              datosLavadora.tblLecturaLavadoras[0].tblProgramasLavadora
                .denominacion
            }
          </div>
        </div>
      );
      is_enUso = true;
    } else {
      titulo = (
        <div className="flex-fill align-middle text-secondary lineHeight-normal pb-1 opacity-7 centerText">
          <span className="text-font-capacidadLavadora">
            {datosLavadora.capacidad}
          </span>
          <span className="font-size-xl font-weight-normal">{" KG."}</span>
        </div>
      );
    }

    return (
      <CardHeader
        className={"card-header-tab pl-0" + (!is_enUso ? " no_enUso" : "")}
      >
        <div
          className="d-flex justify-content-center align-items-center he-100 header-border-right"
          style={{ flexBasis: 90 }}
        >
          <div className="centerText codigoLav-font pb-1">
            {datosLavadora.codigoLav}
          </div>
        </div>
        <div className="card-header-title d-flex flex-fill flex-column">
          {titulo}
        </div>
      </CardHeader>
    );
  }

  cardBody_create(datosLavadora) {
    let lecturaLavadoras =
        datosLavadora.tblLecturaLavadoras.length > 0
          ? datosLavadora.tblLecturaLavadoras[0]
          : {},
      contenidoCarta,
      isPendiente = datosLavadora.estado === estadoLavadora_enum.PENDIENTE;

    if (datosLavadora.estado === estadoLavadora_enum.EN_USO) {
      contenidoCarta = (
        <Row className="flex-fill he-100">
          <div className="widget-content-wrapper w-100 he-100">
            <CircularGauge
              className="dxCircularGauge"
              value={lecturaLavadoras.peso}
              size={{ width: "100%" }}
            >
              <Margin bottom={15} />

              <Scale
                startValue={0}
                endValue={formatNumber(datosLavadora.capacidad * 1.2, 0)}
                tickInterval={formatNumber(
                  (((datosLavadora.capacidad * 1.2) / 8) * 100) / 100,
                  0,
                )}
              >
                <Tick length={6} />
              </Scale>
              <Geometry startAngle={180} endAngle={0}></Geometry>
              <RangeContainer
                width={25}
                offset={24}
                backgroundColor="var(--danger)"
              >
                <Range
                  color="#d8ea4b"
                  startValue={formatNumber(datosLavadora.capacidad * 0.9, 0)}
                  endValue={formatNumber(datosLavadora.capacidad, 0)}
                />
                <Range
                  color="var(--green)"
                  startValue={formatNumber(datosLavadora.capacidad, 0)}
                  endValue={formatNumber(datosLavadora.capacidad * 1.2, 0)}
                />
              </RangeContainer>
              <ValueIndicator
                type="triangleNeedle"
                color="#575757"
                width={8}
                offset={15}
              ></ValueIndicator>
            </CircularGauge>
            <div className="container_spanCentrado position-absolute w-100 gauge_labelKilos he-fitContent">
              <div className="font-weight-bold">
                {formatNumber(lecturaLavadoras.peso, 0)}
                <span className="pl-1 font-weight-normal"> {"KG."}</span>
              </div>
            </div>
          </div>
        </Row>
      );
    } else {
      contenidoCarta = (
        <Row className="flex-fill">
          <div
            className={
              "widget-content-wrapper justify-content-center font-size-iconLavadora opacity-7 " +
              (isPendiente ? "icon_Lavadora" : "icon_Lavadora_off")
            }
          />
          <div className="container_spanCentrado position-absolute w-100 text_pendiente he-fitContent">
            {isPendiente ? (
              <div
                className="font-weight-bold centerText"
                style={{ color: "var(--danger)" }}
              >
                {getTrad("pendienteCarga").toUpperCase()}
              </div>
            ) : (
              ""
            )}
          </div>
        </Row>
      );
    }

    return (
      <CardBody className="pt-0 pb-0">
        <Row>
          <Col>
            <div className="widget-content pl-0 pr-0  pt-2">
              <div className="widget-content-outer row-1">{contenidoCarta}</div>
            </div>
          </Col>
        </Row>
      </CardBody>
    );
  }

  cardFooter_create(datosLavadora) {
    let tiempo = "",
      lecturaLavadoras =
        datosLavadora.tblLecturaLavadoras.length > 0
          ? datosLavadora.tblLecturaLavadoras[0]
          : [];

    let spanMin = (
        <span className="pl-2 he-100 d-flex font-weight-normal">
          {getTrad("minutos_abr")}
        </span>
      ),
      isMinVisible = false,
      isTextoRojo = false;

    if (datosLavadora.estado === estadoLavadora_enum.EN_USO) {
      let fechaInicio = new Date(lecturaLavadoras.fecha),
        tiempoPrograma = new Date(
          durationToDatetime(
            lecturaLavadoras.tblProgramasLavadora.tiempoEstimado,
          ),
        ),
        secondsPrograma =
          tiempoPrograma.getHours() * 60 * 60 * 1000 +
          tiempoPrograma.getMinutes() * 60 * 1000 +
          tiempoPrograma.getSeconds() * 1000;

      fechaInicio.setTime(fechaInicio.getTime() + secondsPrograma);

      let diffSeconds = (fechaInicio - this.state.tiempoActual) / 1000,
        HH = diffSeconds < 0 ? 0 : diffSeconds / 3600,
        MM = diffSeconds < 0 ? 0 : (diffSeconds % 3600) / 60,
        ss = diffSeconds < 0 ? 0 : diffSeconds % 60;

      if (Math.floor(HH) > 0) {
        tiempo += "+60";
        isMinVisible = true;
      } else {
        tiempo += leadingZero(Math.trunc(Math.abs(MM)));

        if (MM <= 5) {
          tiempo += ":" + leadingZero(Math.trunc(Math.abs(ss)));
          isTextoRojo = true;
        } else isMinVisible = true;
      }
    } else if (datosLavadora.estado === estadoLavadora_enum.PENDIENTE) {
      let fechaFin = new Date(lecturaLavadoras.fechaFin),
        diffSeconds = (this.state.tiempoActual - fechaFin) / 1000,
        HH = diffSeconds < 0 ? 0 : diffSeconds / 3600,
        MM = (diffSeconds % 3600) / 60;

      // Si es menor a 0 es que la fecha de fin es mayor a la actual
      if (diffSeconds >= 0) {
        if (MM > 15) isTextoRojo = true;

        if (Math.floor(HH) > 0) {
          isTextoRojo = true;
          tiempo += "+60";
          isMinVisible = true;
        } else {
          tiempo += leadingZero(Math.trunc(Math.abs(MM)));
          isMinVisible = true;
        }
      } else {
        tiempo += "-";
        isMinVisible = true;
      }
    } else if (datosLavadora.estado === estadoLavadora_enum.SIN_USO) {
      return (
        <CardFooter>
          <Row className="flex-fill">
            <Col>
              <div className="d-flex align-items-stretch justify-content-center text-secondary opacity-7 font-size-xxxl timeText">
                {getTrad("sinUso").toUpperCase()}
              </div>
            </Col>
          </Row>
        </CardFooter>
      );
    }

    return (
      <CardFooter
        className={
          datosLavadora.estado === estadoLavadora_enum.PENDIENTE
            ? "cardFooter-border"
            : ""
        }
      >
        <Row className="flex-fill">
          <Col>
            <div className="widget-title opacity-5 text-uppercase"></div>
            <div className="fsize-4 mb-0 w-100">
              <div className="d-flex align-items-stretch justify-content-center">
                <div
                  className={
                    "font-size-max icon_timer_outline d-flex align-items-center pt-1 pr-2"
                  }
                />
                <div
                  className={
                    "font-size-max d-flex align-items-center text-center text-cut font-weight-bold pl-2 timeText " +
                    (isTextoRojo ? "text-danger" : "")
                  }
                >
                  <span>{tiempo}</span>
                  {isMinVisible ? spanMin : ""}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </CardFooter>
    );
  }

  render() {
    let { datos_Actual, is_movil } = this.state,
      numMax_cardsPorPagina = is_movil ? null : 10,
      items = { paginas: [] },
      pagina = [],
      _component = this;

    $.each(datos_Actual, function (index, item) {
      let numItem = index + 1;
      pagina.push(item);

      if (
        numItem === datos_Actual.length ||
        numItem === numMax_cardsPorPagina
      ) {
        items.paginas.push(pagina);
        index = 0;
        pagina = [];
      }
    });

    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          postHeading={<LastUpdateClock fecha={this.state.ultimaFecha_load} />}
        />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div
            id="DashboardLecturaLavadoras"
            className="formContainer scrollbar-container"
          >
            <ScrollView
              id="dxScrollView_multiView"
              className="cardContent"
              style={{ overflowY: "hidden" }}
            >
              <MultiView
                ref={this.dxMultiView_paginas_REF}
                height="100%"
                width="100%"
                loop={true}
                swipeEnabled={false}
                animationEnabled={false}
              >
                {items.paginas.map((datosLavadora, index) => {
                  return (
                    <MultiViewItem key={index}>
                      <Row>
                        {datosLavadora.map((item, index) => {
                          let isPendiente =
                              item.estado === estadoLavadora_enum.PENDIENTE,
                            isSinUso =
                              item.estado === estadoLavadora_enum.SIN_USO;

                          return (
                            <Col
                              key={index}
                              xs="12"
                              md="6"
                              lg="4"
                              xl=""
                              className="col-xl-5ths"
                            >
                              <Card
                                className={
                                  "mb-3 " +
                                  (isPendiente
                                    ? "border border-3 border-danger"
                                    : isSinUso
                                      ? "border border-3 border-dark"
                                      : "")
                                }
                              >
                                {_component.cardHeader_create(item)}
                                {_component.cardBody_create(item)}
                                {_component.cardFooter_create(item)}
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </MultiViewItem>
                  );
                })}
              </MultiView>
            </ScrollView>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  signalR_onRefresh() {
    this.dataSource_lecturaLavadoras.load();
  }

  componentDidMount() {
    this.dataSource_lecturaLavadoras.load();

    let { lavanderia } = this.props;
    this.interval_tiempoRefresco_Actual = setInterval(() => {
      let dxScrollView_multiView = $("#dxScrollView_multiView").dxScrollView(
          "instance",
        ),
        scrollPosition = dxScrollView_multiView.scrollTop();
      this.setState({
        tiempoActual: convertClienteUtcToLavanderiaUtc(
          lavanderia.GMT,
          new Date(),
        ),
      });

      dxScrollView_multiView.scrollTo(scrollPosition);
    }, tiempoRefresco_Actual);

    this.interval_CambioPagina = setInterval(() => {
      let paginas = this.dxMultiView_paginas.option("items");

      if (paginas.length === this.state.dxMultiView_selectedIndex + 1)
        this.setState({ dxMultiView_selectedIndex: 0 });
      else
        this.setState({
          dxMultiView_selectedIndex: this.state.dxMultiView_selectedIndex + 1,
        });
      this.dxMultiView_paginas.option(
        "selectedIndex",
        this.state.dxMultiView_selectedIndex,
      );
    }, tiempoCambioPagina);

    //#region SignalR
    signalRService.openConnection(
      "LecturaLavadorasHub",
      $.hubConnection(connectionConstants.WEB_API_URL),
    );
    let grupos = [new Group("idLavanderia", null, lavanderia.idLavanderia)];
    signalRService.set_grupos(grupos);
    signalRService.signalR_refresh(this);
    //#endregion
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, resolucion } = this.props;
    if (
      lavanderia &&
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
    ) {
      let grupos = [
        new Group(
          "idLavanderia",
          prevProps.lavanderia.idLavanderia,
          lavanderia.idLavanderia,
        ),
      ];
      signalRService.set_grupos(grupos);

      this.setState({
        tiempoActual: convertClienteUtcToLavanderiaUtc(
          lavanderia.GMT,
          new Date(),
        ),
      });

      this.dataSource_lecturaLavadoras.load();
    } else if (resolucion !== prevProps.resolucion) {
      this.setState({ is_movil: get_isMovil(this.props.resolucion) });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval_tiempoRefresco_Actual);
    clearInterval(this.interval_CambioPagina);

    signalRService.closeAllConnections();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardLecturaLavadoras);
