import React, { useEffect, useState } from "react";

import { errorHandler, authHeader } from "helpers";
import { connectionConstants } from "constants";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { ButtonGroup } from "devextreme-react";
import ItemRender from "./ItemRender";

const datasource_tipoTrabajo = new DataSource({
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoTrabajo",
    key: "idTipoTrabajo",
    errorHandler: function (error) {
      errorHandler(error, COMPONENT);
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
    },
    version: 4,
  }),
  select: ["idTipoTrabajo", "icon"],
});

const Resumen = ({ resumenData }) => {
  const [tblTipoTrabajo, setTblTipoTrabajo] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    datasource_tipoTrabajo.load().done((data) => {
      data.push({
        idTipoTrabajo: null,
        icon: "icon_city_variant_outline",      
      });
      
      setTblTipoTrabajo(data);
    });
  }, []);

  useEffect(() => {
    const resumen = resumenData.map((item) => {
      const tipoTrabajo = tblTipoTrabajo.filter(
        (x) => x.idTipoTrabajo == item.idTipoTrabajo,
      )[0];
      item.icon = tipoTrabajo?.icon ?? null;
      item.estado = item?.isValido ?? false ? "success" : "danger";
      return item;
    });
    setDataSource(resumen);
  }, [tblTipoTrabajo, resumenData]);

  return (
    <ButtonGroup
      items={dataSource}
      keyExpr="idTipoTrabajo"
      stylingMode="text"
      selectionMode="none"
      width={dataSource.length * 50}
      height={"100%"}
      buttonComponent={ItemRender}
      //   onItemClick={itemClick}
    />
  );
};

export default Resumen;
