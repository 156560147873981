import React from "react";
import { Tooltip } from "devextreme-react";
import { formatNumber } from "helpers";
import { ControlPresupuestarioCxt } from "pages/Finanzas/ControlPresupuestarioV2/contexts/context";
import CurrencyFormatter from "../../CurrencyFormatter";

export const DataSourceSAPContext = React.createContext({
    isLoading: false,
    dataSource: [],
});

class CurrencyCell extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { data } = this.props;
        const { column, data: rowData } = data;

        let sapData = null;
        let sapDiff = false;
        if (column.dataField === "valorReal" || column.dataField === "valorPresupuestado") {
            sapData = this.context.dataSource.find(
                (x) => x.idCentroElem === rowData.idCentroElem && x.idAdmCuentaContable === rowData.idAdmCuentaContable
            );
            sapDiff =
                sapData && sapData[column.dataField]?.toFixed(2) * 1 !== rowData[column.dataField]?.toFixed(2) * 1;
        }

        let idCentro = "";
        if (rowData.idCentroElem != null) {
            let ids = JSON.parse(rowData.idCentroElem);
            if (ids[0] > 0) idCentro += ids[0];
            else if (ids[1] > 0) idCentro += ids[1];
            idCentro += "-";
        }

        const editorEnabled =
            column.dataField === "valorAjuste" &&
            (!rowData.idCentroElem || (rowData.idCentroElem != null && rowData.isLocalItem));

        return (
            <ControlPresupuestarioCxt.Consumer>
                {({ moneda }) => (
                    <span
                        id={`${idCentro}${column.dataField}-${rowData.idAdmCuentaContable}`}
                        className={`position-absolute-0 d-flex align-items-center align-content-center justify-content-center he-100 ${
                            sapDiff
                                ? "text-danger font-weight-semiBold"
                                : rowData.valorAjuste !== 0 && column.dataField === "valorAjuste"
                                ? " text-info font-weight-semiBold"
                                : ""
                        } ${editorEnabled ? "editorAjuste" : ""}`}
                    >
                        {sapDiff && <i className="icon_Warning font-size-xl position-absolute-left" />}
                        <CurrencyFormatter value={data.value} idMoneda={rowData.idMoneda} />
                        {sapDiff && (
                            <Tooltip
                                target={`#${idCentro}${column.dataField}-${rowData.idAdmCuentaContable}`}
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                position={"top"}
                            >
                                <span className="text-danger font-weight-bold font-size-md">
                                    SAP:{" "}
                                    <CurrencyFormatter value={sapData[column.dataField]} idMoneda={moneda.idMoneda} />
                                </span>
                            </Tooltip>
                        )}
                    </span>
                )}
            </ControlPresupuestarioCxt.Consumer>
        );
    }
}

CurrencyCell.contextType = DataSourceSAPContext;

export default CurrencyCell;
