import React from "react";
import { connect } from "react-redux";

import { connectionConstants } from "constants";
import {
    endOfMonth,
    errorHandler,
    authHeader,
    getTrad,
    startOfMonth,
    formatDate_noTime_parameter,
    formatDate,
    dxMensajePregunta,
} from "helpers";

import PageTitle from "layout/AppMain/PageTitle";
import DateRangePicker from "components/DateRangePicker/DateRangePicker";

import DataGrid_ContabilidadNominas from "./components/DataGrid_ContabilidadNominas";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { Button, SelectBox, Switch } from "devextreme-react";
import SelectorEmpresa from "pages/Administracion/shared/components/SelectorEmpresa";
import DataGrid_ContabilidadNominas_RD from "./components/DataGrid_ContabilidadNominas_RD";
import ODataContext from "devextreme/data/odata/context";
import { loadPanelActions } from "actions";
import notify from "devextreme/ui/notify";

class AsientosNominas_RD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fechaSel: {
                startDate: startOfMonth(new Date()),
                endDate: endOfMonth(new Date()),
                idTipoCalendario: 0,
            },
            vista: false,
            // idEmpresaPolarier: {
            //     idEmpresaPolarier: null,
            //     denominacion: "",
            // },
            quincena: new Date().getDate() <= 15 ? "1Q" : "2Q",

            tblHistoricoAsientoNomina: [],
        };

        this.DataGrid_REF = React.createRef();
    }

    get DataGrid() {
        return this.DataGrid_REF.current;
    }
    currency_format = {
        style: "currency",
        maximumFractionDigits: 2,
        currency: this.props.getSymbol(this.props.empresa.idEmpresaPolarier),
    };
    headerGap = { gap: 10 };

    // #region Lifecycle

    componentDidMount() {
        this.load_tblHistoricoAsientoNomina();
        this.datasource_asientosNomina.load();
    }

    componentDidUpdate(prevProps, prevState) {
        const { fechaSel, quincena } = this.state;
        if (
            (fechaSel !== prevState.fechaSel || quincena !== prevState.quincena) &&
            quincena !== null &&
            fechaSel !== null
        ) {
            this.load_tblHistoricoAsientoNomina().then(() => {
                this.datasource_asientosNomina.reload();
            });
        }
    }

    // #endregion

    // #region DataSource

    datasource_asientosNomina = new DataSource({
        paginate: false,
        store: new ODataStore({
            url:
                connectionConstants.WEB_API_CORE_ODATA_URL +
                "MyPolarier/Contabilidad/AsientosNominas/GetHistorialNominas_RD",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_asientosNomina_beforeSend(request),
            onLoaded: (data) => this.datasource_asientosNomina_onLoaded(data),
            version: 4,
        }),
    });

    datasource_asientosNomina_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { fechaSel } = this.state;

        request.params = {
            fechaDesde: formatDate_noTime_parameter(fechaSel.startDate),
            fechaHasta: formatDate_noTime_parameter(fechaSel.endDate),
            fortnight: this.state.quincena === "1Q" ? 1 : 2,
        };
    };

    datasource_asientosNomina_onLoaded = (data) => {
        if (this.props.loadPanelVisible) {
            this.props.loadPanel_hide();
        }
    };

    context_importDataVIPS = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "VIPS",
        beforeSend: (request) => this.context_importDataVIPS_beforeSend(request),
    });

    context_importDataVIPS_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    context_importDataVIPS_getFecha = () => {
        const { fechaSel, quincena } = this.state;
        if (quincena == "1Q") {
            return formatDate_noTime_parameter(fechaSel.startDate);
        } else if (quincena == "2Q") {
            return formatDate_noTime_parameter(fechaSel.endDate);
        }
    };

    importDataRD = (e) => {
        const { fechaSel, quincena } = this.state;
        const _this = this;
        if (fechaSel == null || quincena == null) {
            notify({
                message: "Debes seleccionar una fecha y quincena para importar las nóminas de VIPS.",
                type: "error",
                displayTime: "2000",
                closeOnClick: false,
            });
            return;
        }
        dxMensajePregunta(
            "Este proceso puede tardar alrededor de un minuto. ¿Deseas importar nóminas de VIPS comprendidas en " +
                quincena +
                " " +
                fechaSel.startDate.toLocaleString("default", { month: "long", year: "numeric" }).toUpperCase(),
            [
                [
                    getTrad("aceptar"),
                    function () {
                        e.component.option("disabled", true);
                        if (fechaSel == null || quincena == null) {
                            return;
                        }
                        _this.props.loadPanel_show();
                        _this.context_importDataVIPS
                            .invoke("ImportData", { fecha: _this.context_importDataVIPS_getFecha() }, "GET")
                            .then((response) => {
                                if (response) {
                                    _this.datasource_asientosNomina.reload();
                                } else {
                                    notify({
                                        message: getTrad("errorSinNominasVIPS"),
                                        type: "error",
                                        displayTime: "2000",
                                        closeOnClick: false,
                                    });
                                }
                                e.component.option("disabled", false);
                                _this.props.loadPanel_hide();
                            })
                            .catch(() => {
                                notify({
                                    message: getTrad("errorImportar"),
                                    type: "error",
                                    displayTime: "2000",
                                    closeOnClick: false,
                                });
                                e.component.option("disabled", false);
                                _this.props.loadPanel_hide();
                            });
                    },
                    "danger",
                ],
                [getTrad("cancelar"), function () {}],
            ]
        );
    };

    context_historicoAsientoNomina = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL,
        entities: {
            tblHistoricoAsientoNomina_RD: {
                key: ["idAdmElementoPEP", "idAdmCentroCoste"],
                keyType: {
                    idAdmElementoPEP: "Int32",
                    idAdmCentroCoste: "Int32",
                },
            },
        },
        errorHandler: (error) => errorHandler(error),
        beforeSend: (request) => this.context_historicoAsientoNomina_beforeSend(request),
    });

    context_historicoAsientoNomina_beforeSend = (request) => {
        const { fechaSel } = this.state;

        request.headers = { ...authHeader() };

        request.params.fechaDesde = formatDate_noTime_parameter(fechaSel.startDate);
        request.params.fechaHasta = formatDate_noTime_parameter(fechaSel.endDate);
        request.params.fortnight = this.state.quincena === "1Q" ? 1 : 2;
    };

    load_tblHistoricoAsientoNomina = () =>
        new Promise((resolve) => {
            this.context_historicoAsientoNomina
                .invoke("tblHistoricoAsientoNomina_RD", {}, "GET")
                .done((data) => this.setState({ tblHistoricoAsientoNomina: data }, resolve));
        });

    // #endregion

    render() {
        const { fechaSel, vista, tblHistoricoAsientoNomina } = this.state;
        return (
            <>
                <PageTitle
                    heading={getTrad("asientosNominas")}
                    postHeadingEnd={
                        <div className="d-flex flex-row he-100 align-items-center" style={this.headerGap}>
                            <Button
                                icon="exportxlsx"
                                text={getTrad("importar")}
                                className="bg-white"
                                onClick={this.importDataRD}
                            />
                            <Button
                                icon="exportxlsx"
                                text={getTrad("exportar")}
                                className="bg-white"
                                onClick={this.dxButton_onClick_exportar}
                            />
                            <div className="font-weight-bold">Fecha:</div>
                            <DateRangePicker
                                month
                                fechaSel={{ ...fechaSel }}
                                align={"right"}
                                onDateSelected={this.dateRangePicker_onDateSelected}
                            />
                            <div className="font-weight-bold">Quincena:</div>
                            <SelectBox
                                dataSource={["1Q", "2Q"]}
                                value={this.state.quincena}
                                onValueChanged={(e) => this.setState({ quincena: e.value })}
                            />
                        </div>
                    }
                />
                <div className="mt-3 he-100 position-relative">
                    <DataGrid_ContabilidadNominas_RD
                        ref={this.DataGrid_REF}
                        dataSource={this.datasource_asientosNomina}
                        tblHistoricoAsientoNomina={tblHistoricoAsientoNomina}
                        load_tblHistoricoAsientoNomina={this.load_tblHistoricoAsientoNomina}
                        fortnight={this.state.quincena}
                        currency_format={this.currency_format}
                        fechaSel={fechaSel}
                    />
                </div>
            </>
        );
    }

    dxButton_onClick_exportar = () => {
        this.DataGrid.dxDataGrid.exportToExcel();
    };

    dxSwitch_vista_onValueChanged = (e) => {
        this.setState({ vista: e.value });
    };

    dateRangePicker_onDateSelected = (fecha) => {
        if (fecha) {
            this.setState({ fechaSel: fecha });
        }
    };

    // selectorEmpresa_onSelectionChanged = (e) => {
    //     this.setState({ idEmpresaPolarier: e.selectedItem });
    // };
}

const mapStateToProps = (state) => ({
    empresa: state.Global.empresa,
    loadPanelVisible: state.LoadPanel.show,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsientosNominas_RD);
