import React, { Fragment } from "react";
import { connect } from "react-redux";

import { useLocation } from "react-router-dom";
import { globalActions } from "../../actions";

// Layout
import AppHeader from "../../layout/AppHeader/";
import AppSidebar from "../../layout/AppSidebar/";
import Impresion from "layout/AppImpresion";

//Component
import LoadingPanel from "../../components/LoadingPanel";
import Encuestas from "../../components/Encuestas";

let AppMaster = ({ children, ...rest }) => {
  const location = useLocation();

  React.useEffect(() => {
    rest.setFormularioActivo(location.pathname);
  }, [location]);

  let { enableFullScreen, enableMobileMenuSmall, resolucion } = rest;
  let isMobileVersion = resolucion == "xs" || resolucion == "sm";

  if (!enableFullScreen) {
    return (
      <Fragment>
        <AppHeader />
        <div
          className={
            "he-100 app-main" +
            (enableMobileMenuSmall && isMobileVersion ? " filter-blur-5" : "")
          }
        >
          <AppSidebar />
          <div className="he-100 app-main__outer">
            <div className="he-100 app-main__inner">
              <Impresion />
              <LoadingPanel />
              <Encuestas />
              {children}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={"he-100 app-main p-0"}>
        <div className="he-100 app-main__inner">
          <LoadingPanel />
          <div className="he-100" style={{ paddingBottom: "50px" }}>
            {children}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  enableFullScreen: state.ThemeOptions.enableFullScreen,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  resolucion: state.Global.resolucion,
});
const mapDispatchToProps = (dispatch) => ({
  setFormularioActivo: (formulario) =>
    dispatch(globalActions.setFormularioActivo(formulario)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMaster);
