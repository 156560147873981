import { getTrad } from "helpers";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { calcularValorNeto, calculateDisplayValue, getFormatCurrency, getFormatDescuento } from "../functions";
import DxForm, { GroupItem, Item } from "devextreme-react/form";
import { datasource_tblIvaNPais_Venta, tblTipoDescuento, formats, empresaPolarierSel } from "pages/Administracion/shared/model";

const TablaResumen = forwardRef(({ formData, idMoneda, showIva = false, readOnly = false, userCantEdit = true }, ref) => {
    const summaryFormRef = useRef();
    const multiplier = 3; // 3 columnas

    const getText = (trad) => ({ text: getTrad(trad) });
    const shouldBeLocked = () => {
        return (
            formData.idAdmAlbaranVenta?.length > 0 ||
            formData.idAdmAlbaranCompra?.length > 0 ||
            formData.numPedidoCliente_id != null ||
            formData.numPedidoProveedor_id != null
        );
    };

    const getLabel_impuesto = () => {
        if (empresaPolarierSel.idEmpresaPolarier == 4) {
            return { text: "ITBIS" }
        }
        return getText("iva");
    }

    const labels = {
        descuento: getText("descuento"),
        bruto: getText("totalBruto"),
        neto: getText("neto"),
        idIvaNPais: getLabel_impuesto(),
    };

    const editorOptions = {
        idAdmTipoDescuento: {
            dataSource: tblTipoDescuento(idMoneda),
            valueExpr: "idAdmTipoDescuento",
            displayExpr: "simbolo",
            // value: summaryData.idAdmTipoDescuento
        },
        idIvaNPais: {
            dataSource: datasource_tblIvaNPais_Venta,
            // format: formats.percent,
            readOnly: shouldBeLocked(),
            valueExpr: "idIvaNPais",
            displayExpr: "denominacion",
            itemRender: (data) => {
                const displayExpr = data.valor * 100 + " %";
                return { displayExpr };
            },
        },
    };

    useImperativeHandle(ref, () => ({
        updateSummary,
    }));

    const updateSummary = (value, apartado) => {
        const { descuento, idAdmTipoDescuento, idIvaNPais } = summaryFormRef.current.instance.option("formData");
        const bruto = value * 1;
        const neto = calcularValorNeto(value, descuento, idAdmTipoDescuento, idIvaNPais, apartado);
        summaryFormRef.current.instance.option("formData").bruto = bruto; //calculateDisplayValue(bruto, idMoneda);
        summaryFormRef.current.instance.option("formData").neto = neto; //calculateDisplayValue(neto, idMoneda);
        summaryFormRef.current.instance.repaint();
    };

    const summaryForm_OnFieldDataChanged = (e) => {
        if (e.dataField === "idAdmTipoDescuento") {
            if (e.value === 1) {
                //cambiamos a tipo porcentual, por lo que debe cambiar el formato y el valor contenido por el campo en caliente
                summaryFormRef.current.instance
                    .option("items")
                    .find((item) => item.itemType === "group")
                    .items.find((item) => item.dataField === "descuento").editorOptions.format = {
                    ...formats.percent,
                };
                summaryFormRef.current.instance.option("formData").descuento =
                    summaryFormRef.current.instance.option("formData").descuento / 100;
            } else if (e.value === 2) {
                //cambiamos a tipo nominal, por lo que debe cambiar el formato y el valor contenido por el campo en caliente
                summaryFormRef.current.instance
                    .option("items")
                    .find((item) => item.itemType === "group")
                    .items.find((item) => item.dataField === "descuento").editorOptions.format = {
                    ...formats.decimal_2.format,
                };
                summaryFormRef.current.instance.option("formData").descuento =
                    summaryFormRef.current.instance.option("formData").descuento * 100;
            }
        }
        // const { bruto = 0 } = summaryData;
        const {
            descuento,
            idAdmTipoDescuento,
            idIvaNPais,
            bruto = 0,
        } = summaryFormRef.current.instance.option("formData");
        let apartado = "venta";
        if (showIva == false) {
            apartado = "compra";
        }
        summaryFormRef.current.instance.option("formData").neto = calcularValorNeto(
            bruto,
            descuento,
            idAdmTipoDescuento,
            idIvaNPais,
            apartado
        );
        summaryFormRef.current.instance.repaint();
    };

    return (
        <DxForm
            ref={summaryFormRef}
            formData={formData}
            colCount={1 * multiplier}
            onFieldDataChanged={summaryForm_OnFieldDataChanged}
            height={"100%"}
            width={"100%"}
            labelLocation={"top"}
            readOnly={(readOnly || formData.isCerrado) && userCantEdit}
        >
            <Item
                dataField={"bruto"}
                label={labels.bruto}
                colSpan={1 * multiplier}
                editorOptions={{
                    readOnly: true,
                    format: getFormatCurrency(idMoneda),
                }}
            />
            <GroupItem colSpan={1 * multiplier} colCount={1 * multiplier} alignItemLabels={true}>
                <Item
                    dataField={"descuento"}
                    label={labels.descuento}
                    editorType={"dxNumberBox"}
                    editorOptions={{
                        format: getFormatDescuento(formData.idAdmTipoDescuento ?? 1),
                    }}
                    colSpan={1 * multiplier - 1}
                />
                <Item
                    dataField={"idAdmTipoDescuento"}
                    label={{
                        text: "",
                        visible: false,
                    }}
                    editorType={"dxSelectBox"}
                    editorOptions={editorOptions.idAdmTipoDescuento}
                    cssClass={"alignTextBox"}
                    colSpan={1 * multiplier - 2}
                />
            </GroupItem>
            {showIva && (
                <Item
                    dataField={"idIvaNPais"}
                    label={labels.idIvaNPais}
                    colSpan={1 * multiplier}
                    editorType={"dxSelectBox"}
                    editorOptions={editorOptions.idIvaNPais}
                />
            )}
            <Item
                dataField={"neto"}
                label={labels.neto}
                colSpan={1 * multiplier}
                editorOptions={{
                    readOnly: true,
                    format: getFormatCurrency(idMoneda),
                }}
            />
        </DxForm>
    );
});

export default TablaResumen;
