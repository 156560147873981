import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  dxMensajePregunta,
} from "../../../helpers";
import { svg_edit, svg_remove } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var datasource_entidades;
  var datasource_compañias;
  var datasource_rutas;
  var datasource_almacenesNRuta;
  var datasource_añadirAlmacenes;

  var rutaSel = null;

  //#region Datasource
  datasource_compañias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
    //Poner todas en el dropdown y para seleccionar todas las entidades
    postProcess: function (data) {
      data.splice(0, 0, { idCompañia: 0, denominacion: "TODAS" });
      return data;
    },
  });

  //Datasource entidades
  datasource_entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion"],
  });

  datasource_rutas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRuta",
      key: "idRuta",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var entidadSel = $("#dxDropDownBox_selCompaEnti")
          .dxDropDownBox("instance")
          .option("value");

        request.params.idEntidad = entidadSel ? entidadSel : null;
      },
      version: 4,
    }),
    select: ["idRuta", "denominacion", "idEntidad"],
  });

  datasource_almacenesNRuta = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectAlmacenNRuta",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idRuta = rutaSel ? rutaSel.idRuta : -1;
        if (request.method !== "get") {
          request.url = request.url.replace(
            "spSelectAlmacenNRuta",
            "tblAlmacenNRuta",
          );
        }
      },
      version: 4,
    }),
    sort: "orden",
  });

  datasource_añadirAlmacenes = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAlmacen",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var entidadSel = $("#dxDropDownBox_selCompaEnti")
          .dxDropDownBox("instance")
          .option("value");

        request.params.idEntidad = entidadSel ? entidadSel : null;
        request.params.idTipoAlmacen = 2;
      },
      version: 4,
    }),
    select: ["idAlmacen", "denominacion"],
    sort: ["denominacion"],
    postProcess: function (data) {
      var listaAlmacenes = $("#dxListAlmacenesNRuta")
        .dxList("instance")
        .getDataSource()
        .items();
      //Eliminamos los almacenes en la lista selectora que ya existen en la lista donde podemos visualizar los almacenes ya existentes
      return $.grep(data, function (v) {
        var almacenEncontrado = $.grep(listaAlmacenes, function (i) {
          return i.idAlmacen === v.idAlmacen;
        });
        return almacenEncontrado.length === 0;
      });
    },
  });

  //#endregion

  //#region Controles

  $("#RutasRevision #dxContainer").dxBox({
    direction: "row",
    height: "100%",
  });

  $("#RutasRevision #dxBox_RutasRevisar").dxBox({
    direction: "col",
    height: "100%",
  });

  $("#RutasRevision #dxBox_dgvRutasRevisar").dxBox({
    direction: "col",
    height: "100%",
  });

  $("#RutasRevision #dxBox_AlmacenNRuta").dxBox({
    direction: "row",
    height: "100%",
  });

  $("#RutasRevision #dxBox_dgvAlmacenNRuta").dxBox({
    direction: "col",
    height: "100%",
  });

  $("#RutasRevision #dgvRutasRevision").dxDataGrid({
    //Datos
    dataSource: datasource_rutas,
    remoteOperations: true,
    //Configuración
    columnAutoWidth: true,
    height: "100%",
    selection: { mode: "single" },
    paging: {
      pageSize: 50,
    },
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [20, 50, 100],
      showInfo: true,
    },
    editing: {
      mode: "popup",
      allowUpdating: false,
      allowAdding: true,
      popup: {
        title: getTrad("añadirAlmacenRevisar"),
        showTitle: true,
        position: { at: "center", of: window },
        height: "235px",
        width: "350px",
      },
      form: {
        labelLocation: "top",
        height: "100%",
        colCount: 1,
        items: [
          {
            dataField: "denominacion",
            allowFiltering: true,
          },
        ],
      },
    },
    columns: [
      {
        caption: " ",
        width: "30px",
        alignment: "center",
        cssClass: "p-0",
        cellTemplate: function (cellElement, cellInfo) {
          var contenedor = $("<div/>").addClass("container_spanCentrado");
          svg_edit
            .clone()
            .css("height", "25px")
            .css("width", "25px")
            .css("cursor", "pointer")
            .appendTo(contenedor);

          cellElement.on("click", function () {
            cellInfo.component.editRow(cellInfo.rowIndex);
          });

          contenedor.appendTo(cellElement);
        },
        allowSearch: false,
      },
      {
        dataField: "denominacion",
        caption: getTrad("denominacion"),
        minWidth: "100px",
        validationRules: [
          {
            type: "required",
          },
          {
            type: "pattern",
            pattern: "^[_A-zÀ-ú0-9 ]*((-|s)*[_A-zÀ-ú0-9 ])*$",
            message: getTrad("validation_NoCaracteresEspeciales"),
          },
        ],
      },
      {
        caption: " ",
        width: "60px",
        alignment: "left",
        cssClass: "p-0",
        cellTemplate: function (cellElement, cellInfo) {
          var contenedor = $("<div/>").addClass("container_spanCentrado");
          svg_remove
            .clone()

            .css("height", "25px")
            .css("width", "25px")
            .css("cursor", "pointer")
            .appendTo(contenedor);

          cellElement.on("click", function () {
            cellInfo.component.deleteRow(cellInfo.rowIndex);
          });

          contenedor.appendTo(cellElement);
        },
      },
    ],
    //Eventos
    onToolbarPreparing: function (e) {
      e.toolbarOptions.items.unshift(
        {
          location: "after",
          template: function () {
            return $("<div id='dxDropDownBox_selCompaEnti'>").dxDropDownBox({
              dataSource: datasource_entidades,
              valueExpr: "idEntidad",
              displayExpr: "denominacion",
              width: 350,
              deferRendering: false,
              remoteOperations: true,
              placeholder: getTrad("entidades"),
              dropDownOptions: {
                width: 800,
                height: 500,
                position: { my: "center", at: "center", of: window },
                closeOnOutsideClick: true,
              },
              contentTemplate: function (dxDropDownBox) {
                return $("<div>").dxBox({
                  direction: "row",
                  align: "space-around",
                  crossAlign: "stretch",
                  items: [
                    {
                      ratio: 1,
                      template: function () {
                        // COMPAÑÍAS
                        var container = $("<div>");
                        var title = $(
                          "<div class='font-size' style='margin-left:9px;'>" +
                            getTrad("compañia") +
                            "</div>",
                        );
                        var lista_compañias = $(
                          "<div id='dxDropDownBox_lista_compañias'>",
                        ).dxList({
                          dataSource: datasource_compañias,
                          selectionMode: "single",
                          keyExpr: "idCompañia",
                          height: "375px",
                          width: "95%",
                          scrolling: { mode: "infinite" },
                          pageLoadMode: "scrollBottom",
                          // EVENTOS
                          onSelectionChanged: function (e) {
                            var lista_entidades = $(
                              "#dxDropDownBox_lista_entidades",
                            ).dxList("instance");
                            if (e.addedItems[0].idCompañia !== 0) {
                              lista_entidades
                                .getDataSource()
                                .filter([
                                  [
                                    "idCompañia",
                                    "=",
                                    e.addedItems[0].idCompañia,
                                  ],
                                ]);
                            } else if (lista_entidades !== undefined) {
                              lista_entidades.getDataSource().filter("");
                            }
                            datasource_entidades.load();
                          },
                          itemTemplate: function (data, index) {
                            var result = $("<div>");
                            $("<div>").text(data.denominacion).appendTo(result);

                            return result;
                          },
                        });
                        title.appendTo(container);
                        lista_compañias.appendTo(container);

                        return container;
                      },
                    },
                    {
                      // ENTIDADES
                      ratio: 1,
                      template: function () {
                        var container = $("<div>");
                        var title = $(
                          "<div class='font-size' style='margin-left:9px;'>" +
                            getTrad("entidad") +
                            "</div>",
                        );
                        var lista_entidades = $(
                          "<div id='dxDropDownBox_lista_entidades'>",
                        ).dxList({
                          dataSource: datasource_entidades,
                          selectionMode: "single",
                          keyExpr: "idEntidad",
                          height: "375px",
                          scrolling: { mode: "infinite" },
                          pageLoadMode: "scrollBottom",
                          //EVENTOS
                          onSelectionChanged: function (e) {
                            if (typeof e.addedItems[0] !== "undefined") {
                              if (
                                dxDropDownBox.component.option("value") !==
                                e.addedItems[0].idEntidad
                              ) {
                                dxDropDownBox.component.option(
                                  "value",
                                  e.addedItems[0].idEntidad,
                                );
                              }
                            }
                          },
                          itemTemplate: function (data, index) {
                            var result = $("<div>");
                            $("<div>").text(data.denominacion).appendTo(result);
                            return result;
                          },
                        });

                        dxDropDownBox.component.on(
                          "valueChanged",
                          function (args) {
                            var dxDropDownBox_listaEntidades = $(
                              "#dxDropDownBox_lista_entidades",
                            ).dxList("instance");
                            if (
                              dxDropDownBox_listaEntidades.option(
                                "selectedItemKeys",
                              )[0] !== args.value
                            ) {
                              dxDropDownBox_listaEntidades.option(
                                "selectedItemKeys",
                                [args.value],
                              );
                            }
                            var gridRutasRevision =
                              $("#dgvRutasRevision").dxDataGrid("instance");
                            gridRutasRevision.refresh(true);
                            rutaSel = null;
                            gridRutasRevision.option("focusedRowKey", null);
                            gridRutasRevision.option("focusedRowIndex", -1);
                            datasource_almacenesNRuta.load();
                            args.component.close();
                          },
                        );
                        title.appendTo(container);
                        lista_entidades.appendTo(container);

                        return container;
                      },
                    },
                  ],
                });
              },
            });
          },
        },
        {
          location: "after",
          widget: "dxButton",
          showText: "inMenu",
          options: {
            icon: "refresh",
            text: getTrad("actualizar"),
            onClick: function () {
              e.component.refresh();
            },
          },
        },
      );
      $.each(e.toolbarOptions.items, function (index, item) {
        switch (item.name) {
          case "addRowButton":
            item.location = "before";
            item.showText = "always";
            item.options.text = getTrad("añadir");
            item.options.icon = "plus";
            item.options.onClick = function () {
              if (
                !$("#dxDropDownBox_selCompaEnti")
                  .dxDropDownBox("instance")
                  .option("value")
              ) {
                notify({
                  message: getTrad("alerta_SeleccioneEntidad"),
                  type: "error",
                  displayTime: "2500",
                  closeOnClick: true,
                });
              } else {
                e.component.addRow();
              }
            };
            break;
          default:
            break;
        }
      });
    },

    onSelectionChanged: function (e) {
      rutaSel = e.selectedRowsData[0];
      datasource_almacenesNRuta.load();
    },

    onRowInserting: function (e) {
      var dxDropDownBox_selCompaEnti = $(
        "#dxDropDownBox_selCompaEnti",
      ).dxDropDownBox("instance");
      e.data.idEntidad = dxDropDownBox_selCompaEnti.option("value");
    },

    onRowInserted: function (e) {
      e.component.selectRows(e.key, false);
    },
    //Estilos
    showColumnLines: false,
    showRowLines: true,
    rowAlternationEnabled: true,
  });

  //Toolbar para añadir Almacenes a revisar
  $("#RutasRevision #dxToolbar_dgvAlmacenNRuta").dxToolbar({
    items: [
      {
        location: "before",
        widget: "dxButton",
        options: {
          icon: "plus",
          text: getTrad("añadir"),
          onClick: function () {
            if (!rutaSel) {
              notify({
                message: getTrad("alerta_SeleccioneRuta"),
                type: "error",
                displayTime: "2500",
                closeOnClick: true,
              });
            } else {
              $("#popup_añadirAlmacen").dxPopup("instance").show();
            }
          },
        },
      },
    ],
  });

  $("#RutasRevision #dxListAlmacenesNRuta").dxList({
    dataSource: datasource_almacenesNRuta,
    allowItemReordering: true,
    selectionMode: "single",
    activeStateEnabled: false,
    focusStateEnabled: false,
    itemTemplate: function (itemData, itemIndex, itemElement) {
      itemElement.css("padding", "0px");
      itemElement.append(
        $("<div />").dxToolbar({
          items: [
            {
              location: "before",
              template: function () {
                return $("<div style = 'padding-left:10px'>").text(
                  itemData.orden + ".",
                );
              },
            },
            {
              location: "before",
              template: function () {
                return $("<div style = 'padding-left:4px'>").text(
                  itemData.denominacion,
                );
              },
            },
            {
              location: "after",

              template: function () {
                var contenedor = $("<div/>").addClass("container_spanCentrado");
                svg_remove
                  .clone()

                  .css("height", "25px")
                  .css("width", "25px")
                  .attr("style", "margin-right: 20px")
                  .css("cursor", "pointer")
                  .appendTo(contenedor);

                contenedor.on("click", function () {
                  //PopUp generico para confirmar borrado
                  dxMensajePregunta(getTrad("preg_RegistroEliminarCorto"), [
                    [
                      getTrad("aceptar"),
                      function (e) {
                        $("#dxListAlmacenesNRuta")
                          .dxList("instance")
                          .deleteItem(itemIndex);
                      },
                      "normal",
                      "btnAceptar_alert",
                    ],
                    [
                      getTrad("cancelar"),
                      function (e) {},
                      "normal",
                      "btnCancelar_alert",
                    ],
                  ]);
                });

                return contenedor;
              },
            },
          ],
        }),
      );
    },

    onItemDeleted: function (e) {
      e.component.option("disabled", true);
      var cont = e.itemData.orden;
      var dxListAlmacenesNRuta = $("#dxListAlmacenesNRuta").dxList("instance");
      var items = dxListAlmacenesNRuta.getDataSource().items();
      for (var i = cont; i < items.length; i++) {
        var updateAlmacen = {};
        var isUltimoItem = i + 1 === items.length;
        updateAlmacen.orden = items[i].orden - 1;
        if (isUltimoItem) {
          datasource_almacenesNRuta
            .store()
            .update(items[i].idAlmacen, updateAlmacen)
            .done(function () {
              datasource_almacenesNRuta.load().done(function () {
                e.component.option("disabled", false);
              });
            });
        } else {
          datasource_almacenesNRuta
            .store()
            .update(items[i].idAlmacen, updateAlmacen);
        }
      }
      if (cont === items.length) {
        datasource_almacenesNRuta.load();
        e.component.option("disabled", false);
      }
    },

    onItemReordered: function (e) {
      e.component.option("disabled", true);
      var items = e.component.getDataSource().items();
      var isAscendente = e.fromIndex < e.toIndex;
      var desde = isAscendente ? e.fromIndex : e.toIndex;
      var hasta = isAscendente ? e.toIndex : e.fromIndex;
      var almaNRuta = {};
      for (var i = desde; i <= hasta; i++) {
        var cont = i + 1;
        var itemAlmacenNRuta = items[i];
        almaNRuta.orden = isAscendente ? cont - 1 : cont + 1;

        if (e.itemData.idAlmacen === itemAlmacenNRuta.idAlmacen) {
          //Almacen que se mueve
          almaNRuta.orden = e.toIndex + 1;
        }
        if (i === hasta) {
          datasource_almacenesNRuta
            .store()
            .update(itemAlmacenNRuta.idAlmacen, almaNRuta)
            .done(function () {
              datasource_almacenesNRuta.load().done(function () {
                e.component.selectItem(e.toIndex);
                e.component.scrollToItem(e.toIndex);
                e.component.option("disabled", false);
              });
            });
        } else {
          datasource_almacenesNRuta
            .store()
            .update(itemAlmacenNRuta.idAlmacen, almaNRuta);
        }
      }
    },
    onItemHold: function (e) {
      e.component.selectItem(e.itemIndex);
    },
  });

  $("#RutasRevision #popup_añadirAlmacen").dxPopup({
    title: getTrad("añadirAlmacenRevisar"),
    showTitle: true,
    width: "250px",
    height: "550px",
    onShowing: function (e) {
      var dxList_añadirAlmacenes = $("#dxList_añadirAlmacenes").dxList(
        "instance",
      );
      if (typeof dxList_añadirAlmacenes.reload() !== "undefined") {
        dxList_añadirAlmacenes.reload().done(function () {
          dxList_añadirAlmacenes.unselectAll();
        });
      }
    },
    contentTemplate: function () {
      return $("<div id='dxList_añadirAlmacenes' />").dxList({
        //Datos
        dataSource: datasource_añadirAlmacenes,
        selectionMode: "multiple",
        scrolling: { mode: "infinite" },
        pageLoadMode: "scrollBottom",
        itemTemplate: function (data, index) {
          return $("<div>").text(data.denominacion);
        },
        //Estilos
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });
    },
    toolbarItems: [
      {
        location: "after",
        widget: "dxButton",
        toolbar: "bottom",
        options: {
          text: getTrad("guardar"),
          type: "success",
          onClick: function (e) {
            var dxList_añadirAlmacenes_keys = $("#dxList_añadirAlmacenes")
              .dxList("instance")
              .option("selectedItemKeys");
            var dxListAlmacenesNRuta_CountItem = $("#dxListAlmacenesNRuta")
              .dxList("instance")
              .option("items").length;

            $.each(dxList_añadirAlmacenes_keys, function (rowIndex, item) {
              dxListAlmacenesNRuta_CountItem++;
              var isLastElement =
                rowIndex === dxList_añadirAlmacenes_keys.length - 1;
              var almacenNRuta = {};
              almacenNRuta.idAlmacen = item;
              almacenNRuta.idRuta = rutaSel.idRuta;
              almacenNRuta.orden = dxListAlmacenesNRuta_CountItem;

              datasource_almacenesNRuta
                .store()
                .insert(almacenNRuta)
                .done(function () {
                  if (isLastElement) {
                    datasource_almacenesNRuta.load().done(function () {
                      var dxListAlmacenesNRuta = $(
                        "#dxListAlmacenesNRuta",
                      ).dxList("instance");
                      var itemsAlmacenesNRuta = dxListAlmacenesNRuta
                        .getDataSource()
                        .items().length;
                      dxListAlmacenesNRuta.selectItem(itemsAlmacenesNRuta - 1);
                      dxListAlmacenesNRuta.scrollToItem(
                        itemsAlmacenesNRuta - 1,
                      );
                    });
                    notify({
                      message: getTrad("aviso_C_RegistroInsertado"),
                      type: "success",
                      displayTime: "2500",
                      closeOnClick: true,
                    });
                  }
                  $("#popup_añadirAlmacen").dxPopup("instance").hide();
                });
            });
          },
        },
      },
    ],
  });
  //#endregion

  deferred.resolve();
  return deferred.promise();
}
