import React, { Fragment } from "react";
import { connect } from "react-redux";
import { connectionConstants } from "../../../constants";

//Actions
import { loadPanelActions } from "../../../actions";

//Component
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Paging,
    Editing,
    KeyboardNavigation,
    Summary,
    TotalItem,
    LoadPanel,
    Pager,
    Scrolling,
} from "devextreme-react/data-grid";

import ODataContext from "devextreme/data/odata/context";
import ScrollView from "devextreme-react/scroll-view";
import { LoadPanel as LoadPanelGeneral } from "devextreme-react/load-panel";
import { Tooltip } from "devextreme-react/tooltip";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { ButtonGroup } from "devextreme-react/button-group";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import { Popup, ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";
import List from "devextreme-react/list";
import { Popover } from "devextreme-react/popover";
import { SelectBox } from "devextreme-react/select-box";
import {
    getNombreFormulario,
    errorHandler,
    authHeader,
    formatTime_parameter,
    dxMensajePregunta,
    formatDate_noTime_parameter,
    formatDate,
    convertClienteUtcToLavanderiaUtc,
    patchRequestHandler,
    getTrad,
    formatDate_parameter,
    durationToDatetime,
    leadingZero,
    subtractHours,
    timeDiff,
    addHours,
    datetimeToDuration,
} from "../../../helpers";

import $ from "jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";
import query from "devextreme/data/query";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

//Css
import "./Css.scss";
import { Button, DateBox } from "devextreme-react";
import Form, { Item } from "devextreme-react/form";
import ArrayStore from "devextreme/data/array_store";

//SignalRCore
import DropDownBox_filtroCategoriasInternas from "components/DropDownBox_filtroCategoriasInternas";
import FiltrosPersona from "./components/FiltrosPersona";
import { signalRCoreService } from "services";

const dxScrollView_JornadaPersonaGrids = {
    of: "#JornadaPersonaReact  #dxScrollView_JornadaPersonaGrids",
};

class JornadaPersonaReact extends React.Component {
    constructor(props) {
        super(props);

        let { lavanderia } = this.props;
        this.dxDateBox_fechaSel_isInPopup = false;
        this.dxDateBox_fechaSel_isInWidget = false;

        this.state = {
            fechaSel: convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date()),
            idTurnoSel: null,
            tblTurnos_items: [],
            tblTurnos_subTurnos_items: [],
            tblCategoria_items: null,
            isVisible_dxPopup_añadirPersona: false,
            isVisible_dxPopup_datosJornada: false,
            isVisible_dxPopup_importarPersona: false,
            idCategoriaSel: null,
            dxList_tblPersonas_selectedItems: [],
            formData_addPersona: null,
            idCategoriaSel_importarPersona: null,
            idCategoriasInternasSel_importarPersona: null,
            dxList_tblLavanderia_selectedItem: [],
            dxList_importarPersonas_personaNLavanderia_selectedItem: [],
            idPersonaSel: null,
            isVisible_dxPopup_horasExtra: false,
            horasExtra: 0,
            dataFieldSel_horasExtras: null,
            selectedCell_gridJornada: null,
            isVisible_iconWarning: false,
            isVisible_iconWarning_nombre: false,
            popoverText_iconWarning: null,
            iconWarning_id: null,
            dxDataGrid_jornadaPersona_rowData: null,
            isLoading_dxDataGrid_tblJornadaPersona: false,
            isLoading_white_dxDataGrid_tblJornadaPersona: false,
            isVisible_dxPopover_CambioHorasTrabajo: false,
            isLoading_dxScrollView_JornadaPersonaGrids: true,
            items_gridJornada: null,
            items_gridAusencias: [],
            is_loadingLavanderia: false, //Para gestionar loadPanel en ComponentDidUpdate
            is_firstLoad_gridJornada: true,
            is_savingData: false, // Para gestionar signalR, que no se ejecute con las llamadas del propio usuario
            isVisible_dxPopup_editarEstadoPersona: false,
            // isVisible_dxPopup_eventosPersona:false,
            isNoJornada_dxPopup_eventosPersona: false,
            tblCalendarioEstado_items: [],
            dxList_estadosPersona_dataSource: [],
            idEstadoSel_ausencia: null,
            dxList_estadosPersona_selectedItem: null,
            idLavanderiaSel: null,
            idCategoriaInternaSel: null,
            isOpened_dropDownBox_filtroCategoriasInternas: null,
        };

        //REF
        this.dxDataGrid_tblJornadaPersona_REF = React.createRef();
        this.dxForm_addPersona_REF = React.createRef();
        this.dxList_personas_REF = React.createRef();
        this.dxList_lavanderias_REF = React.createRef();
        this.dxList_personaNLavanderia_REF = React.createRef();
        this.dxPopover_REF = React.createRef();
        this.dxPopover_datosErroneos_REF = React.createRef();
        this.dxButtonGroup_horasExtra_REF = React.createRef();

        //DATASOURCE
        this.datasource_tblTurno_onLoading = this.datasource_tblTurno_onLoading.bind(this);
        this.datasource_tblTurno_beforeSend = this.datasource_tblTurno_beforeSend.bind(this);
        this.datasource_tblTurno_postProcess = this.datasource_tblTurno_postProcess.bind(this);
        this.datasource_tblCategoria_beforeSend = this.datasource_tblCategoria_beforeSend.bind(this);
        this.datasource_tblLavanderia_beforeSend = this.datasource_tblLavanderia_beforeSend.bind(this);
        this.datasource_tblLavanderia_postProcess = this.datasource_tblLavanderia_postProcess.bind(this);
        this.datasource_datasource_personaNLavanderia_onLoading =
            this.datasource_datasource_personaNLavanderia_onLoading.bind(this);
        this.datasource_tblPersonas_gridJornada_beforeSend =
            this.datasource_tblPersonas_gridJornada_beforeSend.bind(this);
        this.datasource_tblPersonas_gridJornada_map = this.datasource_tblPersonas_gridJornada_map.bind(this);
        this.datasource_datos_tblCalendarioPersonal_Estado_beforeSend =
            this.datasource_datos_tblCalendarioPersonal_Estado_beforeSend.bind(this);
        this.datasource_datos_tblCalendarioPersonal_Estado_postProcess =
            this.datasource_datos_tblCalendarioPersonal_Estado_postProcess.bind(this);
        this.datasource_tblJornadaPersona_beforeSend = this.datasource_tblJornadaPersona_beforeSend.bind(this);

        //EVENTOS
        this.dxDateBox_onOptionChanged = this.dxDateBox_onOptionChanged.bind(this);
        this.dxDateBox_onValueChanged = this.dxDateBox_onValueChanged.bind(this);
        this.dxDateBox_onOpened = this.dxDateBox_onOpened.bind(this);
        this.dxButton_añadirPersona_onClick = this.dxButton_añadirPersona_onClick.bind(this);
        this.dxButton_importarPersona_onClick = this.dxButton_importarPersona_onClick.bind(this);
        this.dxDataGrid_tblJornadaPersona_onCellHoverChanged =
            this.dxDataGrid_tblJornadaPersona_onCellHoverChanged.bind(this);
        this.dxDataGrid_tblJornadaPersona_calculateCustomSummary =
            this.dxDataGrid_tblJornadaPersona_calculateCustomSummary.bind(this);
        this.dxDataGrid_tblJornadaPersona_setCellValue = this.dxDataGrid_tblJornadaPersona_setCellValue.bind(this);
        this.dxDataGrid_tblJornadaPersona_onRowRemoving = this.dxDataGrid_tblJornadaPersona_onRowRemoving.bind(this);
        this.dxDataGrid_tblJornadaPersona_onRowUpdating = this.dxDataGrid_tblJornadaPersona_onRowUpdating.bind(this);
        this.dxDataGrid_tblJornadaPersona_onRowUpdated = this.dxDataGrid_tblJornadaPersona_onRowUpdated.bind(this);
        this.dxDataGrid_tblJornadaPersona_onRowValidating =
            this.dxDataGrid_tblJornadaPersona_onRowValidating.bind(this);
        this.dxDataGrid_tblJornadaPersona_onRowPrepared = this.dxDataGrid_tblJornadaPersona_onRowPrepared.bind(this);
        this.editCellRender_isRegValido = this.editCellRender_isRegValido.bind(this);
        this.dxDataGrid_jornadaPersona_horaEntradaSalidaDescanso_cellRender =
            this.dxDataGrid_jornadaPersona_horaEntradaSalidaDescanso_cellRender.bind(this);
        this.dxDataGrid_jornadaPersona_horaEntrada_calculateDisplayValue =
            this.dxDataGrid_jornadaPersona_horaEntrada_calculateDisplayValue.bind(this);
        this.dxDataGrid_jornadaPersona_horaSalida_calculateDisplayValue =
            this.dxDataGrid_jornadaPersona_horaSalida_calculateDisplayValue.bind(this);
        this.dxDataGrid_jornadaPersona_tiempoDescanso_calculateDisplayValue =
            this.dxDataGrid_jornadaPersona_tiempoDescanso_calculateDisplayValue.bind(this);
        this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo1_calculateDisplayValue =
            this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo1_calculateDisplayValue.bind(this);
        this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo2_calculateDisplayValue =
            this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo2_calculateDisplayValue.bind(this);
        this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo3_calculateDisplayValue =
            this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo3_calculateDisplayValue.bind(this);
        this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo4_calculateDisplayValue =
            this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo4_calculateDisplayValue.bind(this);
        this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo5_calculateDisplayValue =
            this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo5_calculateDisplayValue.bind(this);
        this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo6_calculateDisplayValue =
            this.dxDataGrid_jornadaPersona_tiempoTipoTrabajo6_calculateDisplayValue.bind(this);
        this.cellRender_nombreCompuesto = this.cellRender_nombreCompuesto.bind(this);

        this.dxDataGrid_tblJornadaPersona_onEditorPreparing =
            this.dxDataGrid_tblJornadaPersona_onEditorPreparing.bind(this);
        this.dxDataGrid_tblJornadaPersona_onEditingStart = this.dxDataGrid_tblJornadaPersona_onEditingStart.bind(this);
        this.calculateDisplayValue_traduccion = this.calculateDisplayValue_traduccion.bind(this);
        this.cellRender_justificante = this.cellRender_justificante.bind(this);
        this.dxPopup_añadirPersona_onContentReady = this.dxPopup_añadirPersona_onContentReady.bind(this);
        this.dxPopup_añadirPersona_onHiding = this.dxPopup_añadirPersona_onHiding.bind(this);
        this.dxSelectBox_idCategoria_onValueChanged = this.dxSelectBox_idCategoria_onValueChanged.bind(this);
        this.dxList_tblPersonas_onItemRendered = this.dxList_tblPersonas_onItemRendered.bind(this);
        this.dxList_tblPersonas_onOptionChanged = this.dxList_tblPersonas_onOptionChanged.bind(this);
        this.dxButton_popupAñadirPersona_cancelar_onClick =
            this.dxButton_popupAñadirPersona_cancelar_onClick.bind(this);
        this.dxPopup_datosJornada_onHiding = this.dxPopup_datosJornada_onHiding.bind(this);
        this.dxButton_popupDatosJornada_guardar_onClick = this.dxButton_popupDatosJornada_guardar_onClick.bind(this);
        this.dxSelectBox_formAddPersona_onValueChanged = this.dxSelectBox_formAddPersona_onValueChanged.bind(this);
        this.dxDateBox_formAddPersona_horaEntrada_onValueChanged =
            this.dxDateBox_formAddPersona_horaEntrada_onValueChanged.bind(this);
        this.dxDateBox_formAddPersona_horaSalida_onValueChanged =
            this.dxDateBox_formAddPersona_horaSalida_onValueChanged.bind(this);
        this.dxDateBox_formAddPersona_tiempoDescanso_onValueChanged =
            this.dxDateBox_formAddPersona_tiempoDescanso_onValueChanged.bind(this);
        this.dxPopup_importarPersona_onHiding = this.dxPopup_importarPersona_onHiding.bind(this);
        this.dxButton_popupImportarPersona_importar_onClick =
            this.dxButton_popupImportarPersona_importar_onClick.bind(this);
        this.dxList_tblLavanderia_onOptionChanged = this.dxList_tblLavanderia_onOptionChanged.bind(this);
        this.dxSelectBox_importarPersonas_idCategoria_onValueChanged =
            this.dxSelectBox_importarPersonas_idCategoria_onValueChanged.bind(this);
        this.DropDownBox_filtroCategoriasInternas_onValueChanged =
            this.DropDownBox_filtroCategoriasInternas_onValueChanged.bind(this);
        this.dxList_importarPersonas_personaNLavanderia_onContentReady =
            this.dxList_importarPersonas_personaNLavanderia_onContentReady.bind(this);
        this.dxList_importarPersonas_personaNLavanderia_onOptionChanged =
            this.dxList_importarPersonas_personaNLavanderia_onOptionChanged.bind(this);
        this.dxPopover_onContentReady = this.dxPopover_onContentReady.bind(this);
        this.calcularTiempoTrabajo = this.calcularTiempoTrabajo.bind(this);
        this.horaEntrada_setCellValue = this.horaEntrada_setCellValue.bind(this);
        this.horaSalida_setCellValue = this.horaSalida_setCellValue.bind(this);
        this.tiempoDescanso_setCellValue = this.tiempoDescanso_setCellValue.bind(this);
        this.tiempoTipoTrabajo1_setCellValue = this.tiempoTipoTrabajo1_setCellValue.bind(this);
        this.tiempoTipoTrabajo2_setCellValue = this.tiempoTipoTrabajo2_setCellValue.bind(this);
        this.tiempoTipoTrabajo3_setCellValue = this.tiempoTipoTrabajo3_setCellValue.bind(this);
        this.tiempoTipoTrabajo4_setCellValue = this.tiempoTipoTrabajo4_setCellValue.bind(this);
        this.tiempoTipoTrabajo5_setCellValue = this.tiempoTipoTrabajo5_setCellValue.bind(this);
        this.tiempoTipoTrabajo6_setCellValue = this.tiempoTipoTrabajo6_setCellValue.bind(this);
        this.cellRender_tblJornadaPersona_remove = this.cellRender_tblJornadaPersona_remove.bind(this);
        this.tratarDatos_dxForm_tblJornadaPersona = this.tratarDatos_dxForm_tblJornadaPersona.bind(this);
        // this.dxButtonGroup_buttonRender = this.dxButtonGroup_buttonRender.bind(this);
        this.dxTooltip_onContentReady = this.dxTooltip_onContentReady.bind(this);
        this.dxButtonGroup_horasExtra_buttonRender = this.dxButtonGroup_horasExtra_buttonRender.bind(this);
        this.dxButtonGroup_horasExtra_onItemClick = this.dxButtonGroup_horasExtra_onItemClick.bind(this);
        this.dxPopup_horasExtra_onHiding = this.dxPopup_horasExtra_onHiding.bind(this);
        this.dxPopup_horasExtra_onHidden = this.dxPopup_horasExtra_onHidden.bind(this);
        this.dxPopover_cambioHorasTrabajo_onHiding = this.dxPopover_cambioHorasTrabajo_onHiding.bind(this);
        this.dxButton_dxPopover_cambioHorasTrabajo_cancelar_onClick =
            this.dxButton_dxPopover_cambioHorasTrabajo_cancelar_onClick.bind(this);
        this.dxButton_dxPopover_cambioHorasTrabajo_aceptar_onClick =
            this.dxButton_dxPopover_cambioHorasTrabajo_aceptar_onClick.bind(this);
        /**
         * MÉTODOS UTILIZADOS POR EL POPUP DE EVENTOS DE PERSONA DESHABILITADO
         * AL IMPLEMENTAR CUADRANTES DE PERSONAL
         */
        // this.onHiding_dxPopup_eventosPersona = this.onHiding_dxPopup_eventosPersona.bind(this);
        // this.onHidden_dxPopup_eventosPersona = this.onHidden_dxPopup_eventosPersona.bind(this);
        // this.dxListEstados_itemRender = this.dxListEstados_itemRender.bind(this);
        // this.dxListEstados_onItemDeleting = this.dxListEstados_onItemDeleting.bind(this);
        // this.dxListEstados_onOptionChanged = this.dxListEstados_onOptionChanged.bind(this);
        this.dxButton_popupHorasExtra_añadir_onClick = this.dxButton_popupHorasExtra_añadir_onClick.bind(this);
        this.dxButton_popupHorasExtra_noAñadir_onClick = this.dxButton_popupHorasExtra_noAñadir_onClick.bind(this);

        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);

        this.calculateSortValue_nombreCompuesto = this.calculateSortValue_nombreCompuesto.bind(this);

        this.signalR_reconnect = this.signalR_reconnect.bind(this);
        this.cargaDatos_lavanderia = this.cargaDatos_lavanderia.bind(this);
        this.cargaDatos_fecha = this.cargaDatos_fecha.bind(this);
        this.dxDataGrids_reload = this.dxDataGrids_reload.bind(this);
        this.set_time_fechaSel = this.set_time_fechaSel.bind(this);
        this.getTurnosFiltrados = this.getTurnosFiltrados.bind(this);
        this.getTrad = this.getTrad.bind(this);
        this.getTurnoPersona = this.getTurnoPersona.bind(this);

        this.loadPanel_show();
    }

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    get dxDataGrid_tblJornadaPersona() {
        return this.dxDataGrid_tblJornadaPersona_REF.current.instance;
    }

    get dxForm_addPersona() {
        return this.dxForm_addPersona_REF.current.instance;
    }

    get dxList_personas() {
        return this.dxList_personas_REF.current.instance;
    }

    get dxList_lavanderias() {
        return this.dxList_lavanderias_REF.current.instance;
    }

    get dxList_personaNLavanderia() {
        return this.dxList_personaNLavanderia_REF.current.instance;
    }

    get dxPopover() {
        return this.dxPopover_REF.current.instance;
    }

    get dxPopover_datosErroneos() {
        return this.dxPopover_datosErroneos_REF.current.instance;
    }

    get dxButtonGroup_horasExtra() {
        return this.dxButtonGroup_horasExtra_REF.current.instance;
    }

    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }

    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    //#region
    datasource_tblTurno = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTurno",
            key: "idTurno",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTurno_beforeSend(request);
            },
            onLoading: (loadOptions) => {
                this.datasource_tblTurno_onLoading(loadOptions);
            },
            version: 4,
        }),
        sort: "horaEntrada",
        expand: ["idTurnoPadreNavigation($select=denominacion;$filter=activo eq true and eliminado eq false)"],
        postProcess: (data) => {
            return this.datasource_tblTurno_postProcess(data);
        },
    });

    datasource_tblTurno_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblTurno_onLoading(loadOptions) {
        let { idLavanderiaSel, idCategoriaInternaSel } = this.state;

        let lavanderiaFilter = idLavanderiaSel != null ? ["idLavanderia eq " + idLavanderiaSel] : ["1 eq 1"];
        let categoriaInternaFilter =
            idCategoriaInternaSel != null
                ? ["tblCategoriaInternaNTurno/any(x:x/idCategoriaInterna eq " + idCategoriaInternaSel + ")"]
                : ["1 eq 1"];

        //tblCategoriaInternaNTurno
        let { fechaSel } = this.state;
        loadOptions.filter = [
            ["activo eq true and eliminado eq false"],
            "and",
            ["idLavanderiaNavigation/idPais eq 1 and idLavanderiaNavigation/enableControlHorario eq true"],
            "and",
            lavanderiaFilter,
            "and",
            categoriaInternaFilter,
        ];
        loadOptions.expand = [
            "idLavanderiaNavigation($select=denominacion,idPais),tblJornadaPersona($filter=fecha eq " +
                formatDate_noTime_parameter(fechaSel) +
                ";$top=1)",
        ];
    }

    datasource_tblTurno_postProcess(data) {
        let _this = this;
        $.each(data, function (index, item) {
            if (item.idTurnoPadreNavigation != null) {
                item.denominacion = item.idTurnoPadreNavigation.denominacion + " - " + item.denominacion;
            }
            item.horaEntrada = _this.set_time_fechaSel(durationToDatetime(item.horaEntrada));
            item.horaSalida = _this.set_time_fechaSel(durationToDatetime(item.horaSalida));
            item.tiempoDescanso = _this.set_time_fechaSel(durationToDatetime(item.descanso));
            item.text = item.denominacion;
        });
        return data;
    }

    datasource_tblCategoria = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoria",
            key: "idCategoria",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCategoria_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
        select: ["idCategoria", "denominacion"],
    });

    datasource_tblCategoria_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
            key: "idLavanderia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblLavanderia_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
        filter: ["idPais eq 1"],
        postProcess: (data) => {
            return this.datasource_tblLavanderia_postProcess(data);
        },
    });

    datasource_tblLavanderia_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
    }

    datasource_tblLavanderia_postProcess(data) {
        let { idLavanderiaSel } = this.state;
        return $.grep(data, function (lav) {
            return (
                ((idLavanderiaSel != null && lav.idLavanderia != idLavanderiaSel) || idLavanderiaSel == null) &&
                lav.idPais == 1 &&
                lav.enableControlHorario
            );
        });
    }
    //#region IMPORTAR PERSONA
    datasource_personaNLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datasource_personaNLavanderia_beforeSend(request);
            },
            onLoading: (loadOptions) => {
                this.datasource_datasource_personaNLavanderia_onLoading(loadOptions);
            },
            version: 4,
        }),
        select: ["idPersona", "nombre", "apellidos", "idTipoTrabajo", "idTurno", "idLavanderia"],
        sort: "nombre",
        map: function (itemData) {
            return {
                idPersona: itemData.idPersona,
                nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
                idTipoTrabajo: itemData.idTipoTrabajo,
                tblJornadaPersona: itemData.tblJornadaPersona,
                tblCalendarioPersonal: itemData.tblCalendarioPersonal,
                idLavanderia: itemData.idLavanderia,
            };
        },
        postProcess: (data) => {
            return this.datasource_personaNLavanderia_postProcess(data);
        },
    });

    datasource_datasource_personaNLavanderia_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_datasource_personaNLavanderia_onLoading(loadOptions) {
        let {
            idCategoriaSel_importarPersona,
            idCategoriasInternasSel_importarPersona,
            fechaSel,
            dxList_tblLavanderia_selectedItem,
            idLavanderiaSel,
        } = this.state;
        var filtroCategoria,
            filtroCategoriaInterna,
            filtroLavanderia =
                "idLavanderia eq " +
                (dxList_tblLavanderia_selectedItem.length > 0 ? dxList_tblLavanderia_selectedItem[0].idLavanderia : -1),
            filtroContrato = [
                "tblPersonaNTipoContrato/any(x: date(x/fechaAltaContrato) le " +
                    formatDate_parameter(fechaSel) +
                    " and (x/fechaBajaContrato eq null or date(x/fechaBajaContrato) ge " +
                    formatDate_parameter(fechaSel) +
                    "))",
            ];

        if (idCategoriaSel_importarPersona) {
            filtroCategoria = ["idCategoria", "=", idCategoriaSel_importarPersona];
        } else filtroCategoria = "idCategoria ne -1";

        if (idCategoriasInternasSel_importarPersona != null && idCategoriasInternasSel_importarPersona.length > 0) {
            filtroCategoriaInterna = [
                "idCategoriaInterna in (" +
                    $.map(idCategoriasInternasSel_importarPersona, function (item) {
                        return item;
                    }) +
                    ")",
            ];
        } else filtroCategoriaInterna = "idCategoriaInterna ne -1";

        var filtroTipoTrabajo = "idTipoTrabajo ne 1";

        if (!loadOptions.filter || loadOptions.filter.length === 0) {
            loadOptions.filter = [
                [filtroCategoria],
                "and",
                [filtroLavanderia],
                "and",
                [filtroCategoriaInterna],
                "and",
                [filtroTipoTrabajo],
                "and",
                ["activo eq true and eliminado eq false"],
                "and",
                filtroContrato,
            ];
        } else if (loadOptions.filter[0][0] !== "idPersona") {
            loadOptions.filter = [
                ["concat(concat(nombre, ' '), apellidos)", "contains", loadOptions.filter[0][2]],
                "and",
                [filtroCategoria],
                "and",
                [filtroLavanderia],
                "and",
                [filtroCategoriaInterna],
                "and",
                [filtroTipoTrabajo],
                "and",
                ["activo eq true and eliminado eq false"],
                "and",
                filtroContrato,
            ];
        }
        loadOptions.expand =
            "tblJornadaPersona($filter = date(fecha) eq " +
            formatDate_parameter(fechaSel) +
            "; $select = idPersona), tblCalendarioPersonal($filter = date(fecha) eq " +
            formatDate_noTime_parameter(fechaSel) +
            "; $select = idPersona)";
    }

    datasource_personaNLavanderia_postProcess(data) {
        let idsPersona_jornadaPersona = this.datasource_tblPersonas_gridJornada.items().map(function (item) {
            return item.idPersona;
        });

        return $.grep(data, function (jornadaPersona) {
            return (
                jornadaPersona.tblJornadaPersona.length === 0 &&
                jornadaPersona.tblCalendarioPersonal.length === 0 &&
                $.inArray(jornadaPersona.idPersona, idsPersona_jornadaPersona) === -1
            );
        });
    }
    //#endregion

    enum_buttonsHorasExtras = [
        {
            value: "menos",
            texto: "-",
        },
        {
            value: "horas",
            texto: 1,
            disabled: "true",
        },
        {
            value: "más",
            texto: "+",
        },
    ];

    datasource_tblPersonas_gridJornada = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: ["idPersona", "fecha"],
            keyType: {
                idPersona: "Int32",
                fecha: "String",
            },
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblPersonas_gridJornada_beforeSend(request);
            },
            version: 4,
        }),
        filter: ["idTipoTrabajo ne 1"],
        map: (itemData) => {
            return this.datasource_tblPersonas_gridJornada_map(itemData);
        },
    });

    datasource_tblPersonas_gridJornada_beforeSend(request) {
        request.headers = { ...authHeader() };

        let { fechaSel, idPersonaSel, idLavanderiaSel, idCategoriaInternaSel, idTurnoSel } = this.state;
        let fecha = formatDate_noTime_parameter(fechaSel);

        if (request.method === "get") {
            request.params.fecha = formatDate_parameter(fechaSel);
            request.params.idLavanderia = idLavanderiaSel;
            request.params.idCategoriaInterna = idCategoriaInternaSel;
            request.params.idTurno = idTurnoSel;

            let newUrl = request.url.split("tblPersona");
            request.url = newUrl[0] + "tblJornadaPersona/GetPersonas";
        } else if (request.method === "POST") {
            let newUrl = request.url.split("tblPersona");
            request.url = newUrl[0] + "tblJornadaPersona/PostMasivo";
        } else if (request.method === "PATCH") {
            let newUrl = request.url.split("tblPersona");
            request.url = newUrl[0] + "tblJornadaPersona" + "/" + idPersonaSel + "/" + fecha;
            this.setState({ idPersonaSel: null });
        } else if (request.method === "DELETE") {
            let newUrl = request.url.split("tblPersona");
            request.url = newUrl[0] + "tblJornadaPersona" + "/" + idPersonaSel + "/" + fecha;

            this.setState({ idPersonaSel: null });
        }
    }

    datasource_tblPersonas_gridJornada_map(itemData) {
        let { fechaSel, idLavanderiaSel } = this.state;
        let jornadaPersona = itemData.tblJornadaPersona != null ? itemData.tblJornadaPersona : {};

        return {
            fecha: formatDate_noTime_parameter(fechaSel),
            horaEntrada:
                itemData.tblJornadaPersona != null && itemData.tblJornadaPersona.horaEntrada
                    ? this.set_time_fechaSel(new Date(itemData.tblJornadaPersona.horaEntrada))
                    : null,
            horaSalida:
                itemData.tblJornadaPersona != null && itemData.tblJornadaPersona.horaSalida
                    ? this.set_time_fechaSel(new Date(jornadaPersona.horaSalida))
                    : null,
            idLavanderia:
                idLavanderiaSel != null
                    ? idLavanderiaSel
                    : itemData.tblJornadaPersona != null
                    ? itemData.tblJornadaPersona.idLavanderia
                    : itemData.idLavanderiaOrigen,
            idLavanderiaOrigen: itemData.idLavanderiaOrigen,
            hasJornadaPersona: itemData.tblJornadaPersona != null,
            idPersona: itemData.idPersona,
            nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
            idTipoTrabajo: itemData.idTipoTrabajo,
            idTipoTrabajo: itemData.idTipoTrabajo,
            isNewRow:
                itemData.tblJornadaPersona == null ||
                (itemData.tblJornadaPersona != null && itemData.tblJornadaPersona.length == 0),
            idTurno: itemData.idTurno,
            isRegManual: itemData.tblJornadaPersona == null ? null : jornadaPersona.isRegManual,
            isRegValido: itemData.tblJornadaPersona == null ? false : jornadaPersona.isRegValido,
            isRevisado_horaEntrada: itemData.tblJornadaPersona == null ? null : jornadaPersona.isRevisado_horaEntrada,
            isRevisado_horaSalida: itemData.tblJornadaPersona == null ? null : jornadaPersona.isRevisado_horaSalida,
            isRevisado_tiempoDescanso:
                itemData.tblJornadaPersona == null ? null : jornadaPersona.isRevisado_tiempoDescanso,
            reg_horaEntrada: itemData.tblJornadaPersona == null ? null : jornadaPersona.reg_horaEntrada,
            reg_horaSalida: itemData.tblJornadaPersona == null ? null : jornadaPersona.reg_horaSalida,
            reg_horaInicioDescanso: itemData.tblJornadaPersona == null ? null : jornadaPersona.reg_horaInicioDescanso,
            reg_horaFinDescanso: itemData.tblJornadaPersona == null ? null : jornadaPersona.reg_horaFinDescanso,
            tiempoDescanso:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.tiempoDescanso
                    ? this.set_time_fechaSel(new Date(jornadaPersona.tiempoDescanso))
                    : null,
            tiempoTipoTrabajo1:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.tiempoTipoTrabajo1
                    ? this.set_time_fechaSel(new Date(jornadaPersona.tiempoTipoTrabajo1))
                    : durationToDatetime("PT0S"),
            tiempoTipoTrabajo2:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.tiempoTipoTrabajo2
                    ? this.set_time_fechaSel(new Date(jornadaPersona.tiempoTipoTrabajo2))
                    : durationToDatetime("PT0S"),
            tiempoTipoTrabajo3:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.tiempoTipoTrabajo3
                    ? this.set_time_fechaSel(new Date(jornadaPersona.tiempoTipoTrabajo3))
                    : durationToDatetime("PT0S"),
            tiempoTipoTrabajo4:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.tiempoTipoTrabajo4
                    ? this.set_time_fechaSel(new Date(jornadaPersona.tiempoTipoTrabajo4))
                    : durationToDatetime("PT0S"),
            tiempoTipoTrabajo5:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.tiempoTipoTrabajo5
                    ? this.set_time_fechaSel(new Date(jornadaPersona.tiempoTipoTrabajo5))
                    : durationToDatetime("PT0S"),
            tiempoTipoTrabajo6:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.tiempoTipoTrabajo6
                    ? this.set_time_fechaSel(new Date(jornadaPersona.tiempoTipoTrabajo6))
                    : durationToDatetime("PT0S"),
            tblCalendarioPersonal: itemData.tblCalendarioPersonal,
            horaEntradaParam: itemData.horaEntradaParam
                ? this.set_time_fechaSel(new Date("01/01/1970 " + itemData.horaEntradaParam))
                : null,
            horaSalidaParam: itemData.horaSalidaParam
                ? this.set_time_fechaSel(new Date("01/01/1970 " + itemData.horaSalidaParam))
                : null,
            tiempoDescansoParam: itemData.tiempoDescansoParam
                ? this.set_time_fechaSel(new Date("01/01/1970 " + itemData.tiempoDescansoParam))
                : null,
            horaEntrada_turno:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.horaEntrada_turno
                    ? this.set_time_fechaSel(new Date("01/01/1970 " + itemData.tblJornadaPersona.horaEntrada_turno))
                    : null,
            horaSalida_turno:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.horaSalida_turno
                    ? this.set_time_fechaSel(new Date("01/01/1970 " + itemData.tblJornadaPersona.horaSalida_turno))
                    : null,
            descanso_turno:
                itemData.tblJornadaPersona && itemData.tblJornadaPersona.descanso_turno
                    ? this.set_time_fechaSel(new Date("01/01/1970 " + itemData.tblJornadaPersona.descanso_turno))
                    : null,
            horasDiarias: itemData.horasDiarias,
        };
    }

    datasource_datos_tblCalendarioPersonal_Estado = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioPersonal_Estado",
            key: "idEstado",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tblCalendarioPersonal_Estado_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
        postProcess: (data) => {
            return this.datasource_datos_tblCalendarioPersonal_Estado_postProcess(data);
        },
    });

    datasource_datos_tblCalendarioPersonal_Estado_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_datos_tblCalendarioPersonal_Estado_postProcess(data) {
        if (this.datasource_datos_tblCalendarioPersonal_Estado.items().length === 0) {
            data.splice(0, 0, {
                idEstado: 0,
                denominacion: "Sin estado",
                traduccion: "sinEstado",
                colorHexa: "#FFFFFF",
                isLavanderia: false,
            });
        }
        return data;
    }

    datasource_tblJornadaPersona = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblJornadaPersona",
            key: ["idPersona", "fecha"],
            keyType: {
                idPersona: "Int32",
                fecha: "String",
            },
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblJornadaPersona_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblJornadaPersona_beforeSend(request) {
        request.headers = { ...authHeader() };

        let { fechaSel, idPersonaSel } = this.state;
        let fecha = formatDate_noTime_parameter(fechaSel);

        if (request.method === "DELETE") {
            let newUrl = request.url.split("tblJornadaPersona");
            request.url = newUrl[0] + "tblJornadaPersona" + "/" + idPersonaSel + "/" + fecha;

            this.setState({ idPersonaSel: null });
        }
    }

    datasource_tblCalendarioPersonal = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioPersonal",
            key: ["idPersona", "fecha"],
            keyType: {
                idPersona: "Int32",
                fecha: "String",
            },
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCalendarioPersonal_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblCalendarioPersonal_beforeSend(request) {
        request.headers = { ...authHeader() };

        let { fechaSel, idPersonaSel } = this.state;
        let fecha = formatDate_noTime_parameter(fechaSel);

        if (request.method === "DELETE") {
            let newUrl = request.url.split("tblCalendarioPersonal");
            request.url = newUrl[0] + "tblCalendarioPersonal" + "/" + idPersonaSel + "/" + fecha;
        }
    }

    context_calendarioPersonal = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioPersonal",
        entities: {
            fn_IU_tblCalendarioPersonal: {},
        },
        beforeSend: (request) => {
            this.context_calendarioPersonal_beforeSend(request);
        },
    });

    context_calendarioPersonal_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.timeout = 300000;

        let { idEstadoSel_ausencia, fechaSel, idPersonaSel } = this.state;
        request.params.idPersona = idPersonaSel;

        request.payload = {
            fechasCalendario: [
                {
                    fechaDesde: formatDate_parameter(fechaSel),
                    fechaHasta: formatDate_parameter(fechaSel),
                    idPersona: idPersonaSel,
                    idEstado: idEstadoSel_ausencia,
                },
            ],
        };
    }
    //#endregion

    render() {
        let { lavanderia, idioma } = this.props;
        let {
            fechaSel,
            idLavanderiaSel,
            isOpened_dropDownBox_filtroCategoriasInternas,
            isVisible_dxPopup_datosJornada,
            formData_addPersona,
            isVisible_dxPopup_importarPersona,
            dxList_tblLavanderia_selectedItem,
            dxList_importarPersonas_personaNLavanderia_selectedItem,
            isVisible_dxPopup_horasExtra,
            selectedCell_gridJornada,
            horasExtra,
            isLoading_dxDataGrid_tblJornadaPersona,
            isLoading_white_dxDataGrid_tblJornadaPersona,
            tblTurnos_subTurnos_items,
            isVisible_dxPopover_CambioHorasTrabajo,
            isLoading_dxScrollView_JornadaPersonaGrids,
            items_gridAusencias,
            items_gridJornada,
            is_firstLoad_gridJornada,
            isVisible_dxPopup_editarEstadoPersona,
            isVisible_dxPopup_eventosPersona,
            isNoJornada_dxPopup_eventosPersona,
            tblCalendarioEstado_items,
            dxList_estadosPersona_dataSource,
            dxList_estadosPersona_selectedItem,
            idCategoriasInternasSel_importarPersona,
        } = this.state;

        if (items_gridJornada == null) return null;

        return (
            <Fragment>
                <LoadPanelGeneral
                    shadingColor="transparent"
                    deferRendering={false}
                    animation={null}
                    position={dxScrollView_JornadaPersonaGrids}
                    visible={isLoading_dxDataGrid_tblJornadaPersona}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <LoadPanelGeneral
                    shadingColor="white"
                    deferRendering={false}
                    animation={null}
                    position={dxScrollView_JornadaPersonaGrids}
                    visible={isLoading_white_dxDataGrid_tblJornadaPersona}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />

                <PageTitle
                    heading={getNombreFormulario(this)}
                    postHeadingBegin={
                        <DateBox
                            displayFormat="dd/MM/yyyy"
                            value={fechaSel}
                            max={convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date())}
                            width="100%"
                            openOnFieldClick={true}
                            onOpened={this.dxDateBox_onOpened}
                            onKeyDown={this.dxDateBox_onKeyDown}
                            onOptionChanged={this.dxDateBox_onOptionChanged}
                            onValueChanged={this.dxDateBox_onValueChanged}
                        />
                    }
                    // postHeadingEnd={
                    //     <div className='d-flex w-100 he-100'>
                    //         <div className='flex-1'>
                    //             <Button
                    //                 disabled={idLavanderiaSel == null}
                    //                 elementAttr={this.dxButton_backgroundWhite}
                    //                 text={this.getTrad('importarPersona')}
                    //                 onClick={this.dxButton_importarPersona_onClick}
                    //             />
                    //         </div>
                    //     </div>
                    // }
                />
                <div className={"media-body"}>
                    <div id="JornadaPersonaReact" className={"formContainer"}>
                        <Box direction="col" width="100%" height="100%">
                            <ItemBox baseSize={75}>
                                <FiltrosPersona
                                    idioma={idioma}
                                    fechaSel={fechaSel}
                                    dxDataGrids_reload={this.dxDataGrids_reload}
                                />
                            </ItemBox>
                            <ItemBox baseSize={35} />
                            <ItemBox ratio={1}>
                                <ScrollView height={"100%"} id="dxScrollView_JornadaPersonaGrids">
                                    <div className="he-100 pr-4">
                                        <DataGrid
                                            ref={this.dxDataGrid_tblJornadaPersona_REF}
                                            //Datos
                                            dataSource={items_gridJornada}
                                            remoteOperations={false}
                                            repaintChangesOnly={true}
                                            //Propiedades
                                            columnsAutoWidth={true}
                                            height={"100%"}
                                            width={"100%"}
                                            //Estilos
                                            showColumnLines={true}
                                            showRowLines={true}
                                            rowAlternationEnabled={true}
                                            onEditingStart={this.dxDataGrid_tblJornadaPersona_onEditingStart}
                                            onEditorPreparing={this.dxDataGrid_tblJornadaPersona_onEditorPreparing}
                                            onEditorPrepared={this.dxDataGrid_tblJornadaPersona_onEditorPrepared}
                                            onCellHoverChanged={this.dxDataGrid_tblJornadaPersona_onCellHoverChanged}
                                            onCellPrepared={this.dxDataGrid_tblJornadaPersona_onCellPrepared}
                                            onKeyDown={this.dxDataGrid_tblJornadaPersona_onKeyDown}
                                            onRowRemoving={this.dxDataGrid_tblJornadaPersona_onRowRemoving}
                                            onRowUpdating={this.dxDataGrid_tblJornadaPersona_onRowUpdating}
                                            onRowValidating={this.dxDataGrid_tblJornadaPersona_onRowValidating}
                                            onRowPrepared={this.dxDataGrid_tblJornadaPersona_onRowPrepared}
                                        >
                                            <KeyboardNavigation
                                                enterKeyAction="moveFocus"
                                                enterKeyDirection="row"
                                                editOnKeyPress={true}
                                            />
                                            <Editing
                                                mode="cell"
                                                refreshMode="repaint"
                                                allowUpdating={true}
                                                selectTextOnEditStart={true}
                                                useIcons={true}
                                            />
                                            <SearchPanel visible={false} />
                                            <HeaderFilter visible={false} />
                                            <FilterRow visible={false} />
                                            <Paging enabled={false} />
                                            <Pager visible={false} />
                                            <LoadPanel enabled={false} />
                                            <Column
                                                name={"error"}
                                                visible={false}
                                                allowEditing={false}
                                                searchEnabled={false}
                                                sortOrder="asc"
                                                sortIndex={0}
                                                calculateSortValue={this.calculateSortValue_isRegValido}
                                            />
                                            <Column
                                                dataField="nombreCompuesto"
                                                caption={this.getTrad("nombre")}
                                                minWidth={200}
                                                alignment="left"
                                                sortOrder="asc"
                                                allowEditing={false}
                                                allowSorting={false}
                                                allowHeaderFiltering={false}
                                                allowUpdating={false}
                                                sortIndex={3}
                                                cellRender={this.cellRender_nombreCompuesto}
                                            />
                                            <Column
                                                dataField="isRegManual"
                                                caption={""}
                                                width={65}
                                                alignment="center"
                                                cssClass="p-0"
                                                allowFiltering={false}
                                                allowSorting={false}
                                                allowEditing={false}
                                                fixed={true}
                                                fixedPosition="left"
                                                cellRender={this.cellRender_isRegManual}
                                            />
                                            <Column
                                                dataField="isRegValido"
                                                caption={this.getTrad("validar")}
                                                width={65}
                                                alignment="center"
                                                cssClass="p-0"
                                                allowFiltering={false}
                                                allowSorting={false}
                                                showEditorAlways={true}
                                                fixedPosition="left"
                                                value={false}
                                                editCellRender={this.editCellRender_isRegValido}
                                            />
                                            <Column
                                                dataField="horaEntrada"
                                                caption={this.getTrad("entrada")}
                                                width="7.5%"
                                                alignment="center"
                                                dataType="date"
                                                format="HH:mm"
                                                allowHeaderFiltering={false}
                                                allowSorting={false}
                                                editorOptions={this.editorOptions_horas}
                                                cellRender={
                                                    this.dxDataGrid_jornadaPersona_horaEntradaSalidaDescanso_cellRender
                                                }
                                                calculateDisplayValue={
                                                    this.dxDataGrid_jornadaPersona_horaEntrada_calculateDisplayValue
                                                }
                                                setCellValue={this.horaEntrada_setCellValue}
                                            />
                                            <Column
                                                dataField="horaSalida"
                                                caption={this.getTrad("salida")}
                                                width="7.5%"
                                                alignment="center"
                                                dataType="date"
                                                format="HH:mm"
                                                allowHeaderFiltering={false}
                                                allowSorting={false}
                                                editorOptions={this.editorOptions_horas}
                                                cellRender={
                                                    this.dxDataGrid_jornadaPersona_horaEntradaSalidaDescanso_cellRender
                                                }
                                                calculateDisplayValue={
                                                    this.dxDataGrid_jornadaPersona_horaSalida_calculateDisplayValue
                                                }
                                                setCellValue={this.horaSalida_setCellValue}
                                            />
                                            <Column
                                                dataField="tiempoDescanso"
                                                caption={this.getTrad("descanso")}
                                                width="7.5%"
                                                alignment="center"
                                                dataType="date"
                                                format="HH:mm"
                                                allowHeaderFiltering={false}
                                                allowSorting={false}
                                                editorOptions={this.editorOptions_horas}
                                                cellRender={
                                                    this.dxDataGrid_jornadaPersona_horaEntradaSalidaDescanso_cellRender
                                                }
                                                calculateDisplayValue={
                                                    this.dxDataGrid_jornadaPersona_tiempoDescanso_calculateDisplayValue
                                                }
                                                setCellValue={this.tiempoDescanso_setCellValue}
                                            />
                                            <Column
                                                dataField="tiempoTipoTrabajo1"
                                                caption={this.getTrad("produccion_abr")}
                                                width="7.5%"
                                                alignment="center"
                                                dataType="date"
                                                format="HH:mm"
                                                allowHeaderFiltering={false}
                                                allowSorting={false}
                                                editorOptions={this.editorOptions_horas}
                                                calculateDisplayValue={
                                                    this
                                                        .dxDataGrid_jornadaPersona_tiempoTipoTrabajo1_calculateDisplayValue
                                                }
                                                setCellValue={this.tiempoTipoTrabajo1_setCellValue}
                                            />
                                            <Column
                                                dataField="tiempoTipoTrabajo2"
                                                caption={this.getTrad("logistica_abr")}
                                                width="7.5%"
                                                alignment="center"
                                                dataType="date"
                                                format="HH:mm"
                                                allowHeaderFiltering={false}
                                                allowSorting={false}
                                                editorOptions={this.editorOptions_horas}
                                                calculateDisplayValue={
                                                    this
                                                        .dxDataGrid_jornadaPersona_tiempoTipoTrabajo2_calculateDisplayValue
                                                }
                                                setCellValue={this.tiempoTipoTrabajo2_setCellValue}
                                            />
                                            <Column
                                                dataField="tiempoTipoTrabajo3"
                                                caption={this.getTrad("administracion_abr")}
                                                width="7.5%"
                                                alignment="center"
                                                dataType="date"
                                                format="HH:mm"
                                                allowHeaderFiltering={false}
                                                allowSorting={false}
                                                editorOptions={this.editorOptions_horas}
                                                calculateDisplayValue={
                                                    this
                                                        .dxDataGrid_jornadaPersona_tiempoTipoTrabajo3_calculateDisplayValue
                                                }
                                                setCellValue={this.tiempoTipoTrabajo3_setCellValue}
                                            />
                                            <Column
                                                dataField="tiempoTipoTrabajo4"
                                                caption={this.getTrad("mantenimiento_abr")}
                                                width="7.5%"
                                                alignment="center"
                                                dataType="date"
                                                format="HH:mm"
                                                allowHeaderFiltering={false}
                                                allowSorting={false}
                                                editorOptions={this.editorOptions_horas}
                                                calculateDisplayValue={
                                                    this
                                                        .dxDataGrid_jornadaPersona_tiempoTipoTrabajo4_calculateDisplayValue
                                                }
                                                setCellValue={this.tiempoTipoTrabajo4_setCellValue}
                                            />
                                            <Column
                                                dataField="tiempoTipoTrabajo5"
                                                caption={this.getTrad("valet")}
                                                width="7.5%"
                                                alignment="center"
                                                dataType="date"
                                                format="HH:mm"
                                                allowHeaderFiltering={false}
                                                allowSorting={false}
                                                editorOptions={this.editorOptions_horas}
                                                calculateDisplayValue={
                                                    this
                                                        .dxDataGrid_jornadaPersona_tiempoTipoTrabajo5_calculateDisplayValue
                                                }
                                                setCellValue={this.tiempoTipoTrabajo5_setCellValue}
                                            />
                                            <Column
                                                dataField="tiempoTipoTrabajo6"
                                                caption={this.getTrad("transporte")}
                                                width="7.5%"
                                                alignment="center"
                                                dataType="date"
                                                format="HH:mm"
                                                allowHeaderFiltering={false}
                                                allowSorting={false}
                                                editorOptions={this.editorOptions_horas}
                                                calculateDisplayValue={
                                                    this
                                                        .dxDataGrid_jornadaPersona_tiempoTipoTrabajo6_calculateDisplayValue
                                                }
                                                setCellValue={this.tiempoTipoTrabajo6_setCellValue}
                                            />
                                            <Column
                                                caption=" "
                                                width={50}
                                                alignment="center"
                                                cssClass="p-0"
                                                allowSorting={false}
                                                cellRender={this.cellRender_tblJornadaPersona_remove}
                                            />
                                            <Summary
                                                recalculateWhileEditing={true}
                                                calculateCustomSummary={
                                                    this.dxDataGrid_tblJornadaPersona_calculateCustomSummary
                                                }
                                            >
                                                <TotalItem
                                                    column="isRegManual"
                                                    displayFormat={this.getTrad("total").toUpperCase()}
                                                />
                                                <TotalItem
                                                    column="horaEntrada"
                                                    showInColumn="nombreCompuesto"
                                                    name="horaEntrada"
                                                    cssClass="pl-2 totalCompuesto"
                                                    summaryType="custom"
                                                />
                                                <TotalItem
                                                    column="horaEntrada"
                                                    showInColumn="nombreCompuesto"
                                                    name="horaEntrada2"
                                                    cssClass="pl-2 totalCompuesto warningRow_onlyText"
                                                    summaryType="custom"
                                                />
                                                <TotalItem
                                                    column="tiempoTipoTrabajo1"
                                                    name="tiempoTipoTrabajo1"
                                                    summaryType="custom"
                                                    displayFormat="{0}"
                                                />
                                                <TotalItem
                                                    column="tiempoTipoTrabajo2"
                                                    name="tiempoTipoTrabajo2"
                                                    summaryType="custom"
                                                    displayFormat="{0}"
                                                />
                                                <TotalItem
                                                    column="tiempoTipoTrabajo3"
                                                    name="tiempoTipoTrabajo3"
                                                    summaryType="custom"
                                                    displayFormat="{0}"
                                                />
                                                <TotalItem
                                                    column="tiempoTipoTrabajo4"
                                                    name="tiempoTipoTrabajo4"
                                                    summaryType="custom"
                                                    displayFormat="{0}"
                                                />
                                                <TotalItem
                                                    column="tiempoTipoTrabajo5"
                                                    name="tiempoTipoTrabajo5"
                                                    summaryType="custom"
                                                    displayFormat="{0}"
                                                />
                                                <TotalItem
                                                    column="tiempoTipoTrabajo6"
                                                    name="tiempoTipoTrabajo6"
                                                    summaryType="custom"
                                                    displayFormat="{0}"
                                                />
                                            </Summary>
                                        </DataGrid>
                                        {items_gridAusencias["_array"].length > 0 && (
                                            <Fragment>
                                                <div
                                                    style={{ height: 60 }}
                                                    className="d-flex flex-column justify-content-end"
                                                >
                                                    <div
                                                        style={{
                                                            height: 30,
                                                            fontWeight: "500",
                                                            lineHeight: "15px",
                                                        }}
                                                        className="text-white bg-secondary p-2 pl-3 font-size-xxs"
                                                    >
                                                        {getTrad("personalAusenciaJustificada").toUpperCase()}
                                                    </div>
                                                </div>

                                                <DataGrid
                                                    dataSource={items_gridAusencias}
                                                    columnsAutoWidth={true}
                                                    width={"100%"}
                                                    //Estilos
                                                    showColumnLines={true}
                                                    showRowLines={true}
                                                    rowAlternationEnabled={true}
                                                    onRowPrepared={this.dxDataGrid_ausencias_onRowPrepared}
                                                >
                                                    <LoadPanel enabled={false} />
                                                    <Paging enabled={false} />
                                                    <Pager visible={false} />

                                                    <Editing
                                                        mode="cell"
                                                        refreshMode="repaint"
                                                        allowUpdating={true}
                                                        selectTextOnEditStart={true}
                                                        useIcons={true}
                                                    />

                                                    <Column
                                                        caption={""}
                                                        width={65}
                                                        allowFiltering={false}
                                                        allowSorting={false}
                                                        allowEditing={false}
                                                        fixed={true}
                                                        fixedPosition="left"
                                                    />
                                                    <Column
                                                        dataField="nombreCompuesto"
                                                        caption={this.getTrad("nombre")}
                                                        minWidth={200}
                                                        alignment="left"
                                                        allowEditing={false}
                                                        allowHeaderFiltering={false}
                                                        allowUpdating={false}
                                                        sortIndex={1}
                                                        sortOrder="asc"
                                                        cellRender={this.cellRender_nombreCompuesto}
                                                    />
                                                    <Column
                                                        dataField="tblCalendarioPersonal.colorHexa"
                                                        caption={this.getTrad("validar")}
                                                        width={65}
                                                        alignment="center"
                                                        cssClass="p-0"
                                                        allowFiltering={false}
                                                        allowSorting={false}
                                                        allowEditing={false}
                                                        cellRender={this.cellRender_colorHexa}
                                                    />
                                                    <Column
                                                        dataField="tblCalendarioPersonal.traduccion"
                                                        caption={this.getTrad("nombre")}
                                                        minWidth={200}
                                                        width="60%"
                                                        alignment="left"
                                                        cssClass={"pl-3"}
                                                        allowEditing={false}
                                                        allowHeaderFiltering={false}
                                                        allowUpdating={false}
                                                        calculateDisplayValue={this.calculateDisplayValue_traduccion}
                                                        sortIndex={0}
                                                        sortOrder="asc"
                                                    />
                                                    <Column
                                                        caption=" "
                                                        width={50}
                                                        alignment="center"
                                                        cssClass="p-0"
                                                        cellRender={this.cellRender_justificante}
                                                    />
                                                </DataGrid>
                                            </Fragment>
                                        )}

                                        <div style={{ height: 48 }} />
                                    </div>
                                </ScrollView>
                            </ItemBox>
                        </Box>
                        <Popup
                            title={this.getTrad("datosJornada")}
                            showTitle={true}
                            visible={isVisible_dxPopup_datosJornada}
                            width={500}
                            height={320}
                            showCloseButton={true}
                            deferRendering={false}
                            position={this.dxPopup_center_position}
                            onContentReady={this.dxPopup_datosJornada_onContentReady}
                            onShown={this.dxPopup_datosJornada_onShown}
                            onHiding={this.dxPopup_datosJornada_onHiding}
                        >
                            <Form
                                height="100%"
                                formData={formData_addPersona}
                                ref={this.dxForm_addPersona_REF}
                                colCount={3}
                                labelLocation="top"
                            >
                                <Item dataField="idTurno" label={this.formLabel_turno} colSpan={3}>
                                    <SelectBox
                                        dataSource={this.getTurnosFiltrados(tblTurnos_subTurnos_items)}
                                        valueExpr="idTurno"
                                        displayExpr="denominacion"
                                        value={formData_addPersona ? formData_addPersona.idTurno : null}
                                        onValueChanged={this.dxSelectBox_formAddPersona_onValueChanged}
                                    />
                                </Item>
                                <Item dataField="horaEntrada" label={this.formLabel_horaEntrada} dataType="datetime">
                                    <DateBox
                                        width="100%"
                                        type="time"
                                        format="HH:mm"
                                        showDropDownButton={false}
                                        useMaskBehavior={true}
                                        min="01/01/1900"
                                        onContentReady={this.dxDateBox_formAddPersona_onContentReady}
                                        onValueChanged={this.dxDateBox_formAddPersona_horaEntrada_onValueChanged}
                                        value={!formData_addPersona ? null : formData_addPersona.horaEntrada}
                                    />
                                </Item>
                                <Item dataField="horaSalida" label={this.formLabel_horaSalida} dataType="datetime">
                                    <DateBox
                                        width="100%"
                                        type="time"
                                        format="HH:mm"
                                        showDropDownButton={false}
                                        useMaskBehavior={true}
                                        min="01/01/1900"
                                        onContentReady={this.dxDateBox_formAddPersona_onContentReady}
                                        onValueChanged={this.dxDateBox_formAddPersona_horaSalida_onValueChanged}
                                        value={!formData_addPersona ? null : formData_addPersona.horaSalida}
                                    />
                                </Item>
                                <Item
                                    dataField="tiempoDescanso"
                                    label={this.formLabel_tiempoDescanso}
                                    dataType="datetime"
                                >
                                    <DateBox
                                        width="100%"
                                        type="time"
                                        format="HH:mm"
                                        showDropDownButton={false}
                                        useMaskBehavior={true}
                                        min="01/01/1900 00:00"
                                        onContentReady={this.dxDateBox_formAddPersona_onContentReady}
                                        onValueChanged={this.dxDateBox_formAddPersona_tiempoDescanso_onValueChanged}
                                        value={!formData_addPersona ? null : formData_addPersona.tiempoDescanso}
                                    />
                                </Item>
                            </Form>

                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("guardar")}
                                    type="success"
                                    disabled={
                                        !formData_addPersona ||
                                        (formData_addPersona && formData_addPersona.idTurno == null)
                                    }
                                    onClick={this.dxButton_popupDatosJornada_guardar_onClick}
                                />
                            </ToolbarItem_popup>
                        </Popup>
                        <Popup
                            title={this.getTrad("importarPersona")}
                            showTitle={true}
                            visible={isVisible_dxPopup_importarPersona}
                            width={700}
                            height={750}
                            deferRendering={false}
                            position={this.dxPopup_center_position}
                            onHiding={this.dxPopup_importarPersona_onHiding}
                        >
                            <Box direction="row" align="space-around" crossAlign="stretch" width="100%" height="100%">
                                <ItemBox ratio={1}>
                                    <Box direction="col" width="100%" height="100%">
                                        <ItemBox baseSize={40}>
                                            <div className="font-size ml-2"> {this.getTrad("lavanderia")} </div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_lavanderias_REF}
                                                dataSource={this.datasource_tblLavanderia}
                                                displayExpr="denominacion"
                                                keyExpr="idLavanderia"
                                                selectionMode="single"
                                                height="100%"
                                                width="100%"
                                                focusStateEnabled={false}
                                                activeStateEnabled={false}
                                                searchEnabled={true}
                                                searchExpr="denominacion"
                                                scrolling={this.dxList_scrolling_infinite}
                                                pageLoadMode="scrollBottom"
                                                selectedItems={dxList_tblLavanderia_selectedItem}
                                                onOptionChanged={this.dxList_tblLavanderia_onOptionChanged}
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox baseSize={20}></ItemBox>
                                <ItemBox ratio={1}>
                                    <Box direction="col" width="100%" height="100%">
                                        <ItemBox baseSize={40}>
                                            <div className="font-size ml-2">{this.getTrad("personas")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <DropDownBox_filtroCategoriasInternas
                                                idPaisSel={
                                                    dxList_tblLavanderia_selectedItem.length > 0
                                                        ? dxList_tblLavanderia_selectedItem[0].idPais
                                                        : -1
                                                }
                                                isOficina_checked={false}
                                                hasFiltroConvenio={true}
                                                idioma={idioma}
                                                noDataText_sinPais="Seleccione una lavandería"
                                                multiSeleccion={true}
                                                opened={isOpened_dropDownBox_filtroCategoriasInternas}
                                                onValueChanged={
                                                    this.DropDownBox_filtroCategoriasInternas_onValueChanged
                                                }
                                            />
                                            <div style={{ height: 10 }} />
                                            <List
                                                ref={this.dxList_personaNLavanderia_REF}
                                                dataSource={this.datasource_personaNLavanderia}
                                                scrolling={this.dxList_scrolling_infinite}
                                                pageLoadMode="scrollBottom"
                                                displayExpr="nombreCompuesto"
                                                searchEnabled={true}
                                                selectionMode="all"
                                                showSelectionControls={true}
                                                onContentReady={
                                                    this.dxList_importarPersonas_personaNLavanderia_onContentReady
                                                }
                                                selectedItems={dxList_importarPersonas_personaNLavanderia_selectedItem}
                                                onOptionChanged={
                                                    this.dxList_importarPersonas_personaNLavanderia_onOptionChanged
                                                }
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                            </Box>
                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("importar")}
                                    disabled={dxList_importarPersonas_personaNLavanderia_selectedItem.length === 0}
                                    type="success"
                                    onClick={this.dxButton_popupImportarPersona_importar_onClick}
                                />
                            </ToolbarItem_popup>
                        </Popup>

                        {/**
                         * POPUP DE EVENTOS DE PERSONA DESHABILITADO
                         * AL IMPLEMENTAR CUADRANTES DE PERSONAL
                         */}
                        {/* <Popup
                        width={350}
                        height={500}
                        title={"Eventos de la persona"}
                        visible={isVisible_dxPopup_eventosPersona}
                        closeOnOutsideClick={false}
                        onContentReady={this.onContentReady_dxPopup_eventosPersona}
                        onHiding={this.onHiding_dxPopup_eventosPersona}
                        onHidden={this.onHidden_dxPopup_eventosPersona}
                    >
                        <Box
                            direction="col"
                            align="space-around"
                            crossAlign="stretch"
                            height="100%"
                            width="100%"
                        >
                            <ItemBox baseSize={40}>
                                <Box
                                    direction="row"
                                    align="space-around"
                                    crossAlign="stretch"
                                    height={"100%"}
                                    width="100%"
                                    elementAttr={this.dxPopup_titleBar_elementAttr}
                                >
                                    <ItemBox baseSize={30}>
                                        <div className='dx-icon-event he-100 d-flex align-items-center justify-content-start font-size-xl pt-1'></div>
                                    </ItemBox>
                                    <ItemBox ratio={1}>
                                        <div className='he-100 d-flex align-items-center font-size-lg p-2 title-dxpopup-fecha'>
                                            {formatDate(fechaSel)}
                                        </div>
                                    </ItemBox>
                                </Box>
                            </ItemBox>
                            <ItemBox baseSize={15} />
                            <ItemBox ratio={1}>
                                <List
                                    dataSource={new DataSource({
                                        store: new ArrayStore({
                                            key: "idEstado",
                                            data: dxList_estadosPersona_dataSource
                                        })
                                    })}
                                    selectionMode={isNoJornada_dxPopup_eventosPersona ? "single" : "none"}
                                    selectedItemKeys={
                                        isNoJornada_dxPopup_eventosPersona ?
                                            dxList_estadosPersona_selectedItem != null ? [dxList_estadosPersona_selectedItem] : []
                                            : []
                                    }

                                    activeStateEnabled={isNoJornada_dxPopup_eventosPersona}
                                    focusStateEnabled={isNoJornada_dxPopup_eventosPersona}
                                    hoverStateEnabled={isNoJornada_dxPopup_eventosPersona}
                                    height="100%"
                                    noDataText="Sin eventos"
                                    allowItemDeleting={!isNoJornada_dxPopup_eventosPersona}
                                    itemDeleteMode="static"
                                    repaintChangesOnly={true}
                                    itemRender={this.dxListEstados_itemRender}
                                    onOptionChanged={this.dxListEstados_onOptionChanged}
                                    onItemDeleting={this.dxListEstados_onItemDeleting}
                                />
                            </ItemBox>
                        </Box>
                    </Popup> */}

                        <Popover
                            title={"Horas extra"}
                            showTitle={true}
                            visible={isVisible_dxPopup_horasExtra}
                            width={255}
                            height={200}
                            deferRendering={false}
                            target={selectedCell_gridJornada ? selectedCell_gridJornada : "#JornadaPersonaReact"}
                            position={"bottom"}
                            onContentReady={this.dxPopup_horasExtra_onContentReady}
                            onHiding={this.dxPopup_horasExtra_onHiding}
                            onHidden={this.dxPopup_horasExtra_onHidden}
                        >
                            <ButtonGroup
                                ref={this.dxButtonGroup_horasExtra_REF}
                                elementAttr={this.dxButtonGroup_horasExtra_elementAttr}
                                items={this.enum_buttonsHorasExtras}
                                buttonRender={this.dxButtonGroup_horasExtra_buttonRender}
                                keyExpr="value"
                                onItemClick={this.dxButtonGroup_horasExtra_onItemClick}
                            />

                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={"No añadir"}
                                    type="danger"
                                    onClick={this.dxButton_popupHorasExtra_noAñadir_onClick}
                                />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("añadir")}
                                    type="success"
                                    onClick={this.dxButton_popupHorasExtra_añadir_onClick}
                                />
                            </ToolbarItem_popup>
                        </Popover>

                        <Popover
                            showTitle={false}
                            visible={isVisible_dxPopover_CambioHorasTrabajo}
                            width={255}
                            height={"auto"}
                            deferRendering={false}
                            target={selectedCell_gridJornada ? selectedCell_gridJornada : "#JornadaPersonaReact"}
                            position={"bottom"}
                            onHiding={this.dxPopover_cambioHorasTrabajo_onHiding}
                        >
                            <div>{this.getTrad("alert_alRealizarCambioHorasInsertaranTipoTrabajoDefecto")}</div>
                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("cancelar")}
                                    type="normal"
                                    onClick={this.dxButton_dxPopover_cambioHorasTrabajo_cancelar_onClick}
                                />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("aceptar")}
                                    type="success"
                                    onClick={this.dxButton_dxPopover_cambioHorasTrabajo_aceptar_onClick}
                                />
                            </ToolbarItem_popup>
                        </Popover>

                        <Popover
                            ref={this.dxPopover_REF}
                            width={"auto"}
                            height={"auto"}
                            onContentReady={this.dxPopover_onContentReady}
                        ></Popover>
                        <Popover
                            ref={this.dxPopover_datosErroneos_REF}
                            width={"auto"}
                            height={"auto"}
                            onContentReady={this.dxPopover_onContentReady}
                        ></Popover>
                    </div>
                </div>
            </Fragment>
        );
    }

    getTurnosFiltrados(turnos) {
        let { idLavanderiaSel } = this.state;
        let turnosFiltrados = $.grep($.extend(true, [], turnos), function (item) {
            return item.idLavanderia == idLavanderiaSel;
        });

        return turnosFiltrados;
    }

    dxButtonGroup_horasExtra_elementAttr = {
        id: "dxButtonGroup_horasExtra",
    };

    formLabel_turno = {
        text: getTrad("turno"),
    };

    formLabel_horaEntrada = {
        text: getTrad("horaEntrada"),
    };

    formLabel_horaSalida = {
        text: getTrad("horaSalida"),
    };

    formLabel_tiempoDescanso = {
        text: getTrad("tiempoDescanso"),
    };

    dxList_scrolling_infinite = { mode: "infinite" };

    dxPopup_center_position = { at: "center", of: window };

    dxButton_backgroundWhite = {
        class: "backgroundWhite",
    };

    editorOptions_horas = {
        placeholder: "00:00",
        step: 0,
        type: "time",
        showDropDownButton: false,
        displayFormat: "HH:mm",
        useMaskBehavior: true,
    };

    elementAttr_toolbarClass = { class: "dxToolbar_opciones" };

    dateTimeToSeconds(date) {
        return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
    }

    calculateSortValue_nombreCompuesto(rowData) {
        return rowData.nombre + " " + rowData.apellidos;
    }

    calculateSortValue_isRegValido(rowData) {
        return rowData.isRegValido ? 2 : 1;
    }

    cellRender_isRegManual(itemData) {
        let icon = itemData.value == true ? "icon_smartCardOff" : null;
        return (
            <div
                title="Ticado manual"
                className={"container_spanCentrado center-svg iconos_svg pe-none- " + icon}
                style={{ fontSize: "23px" }}
            />
        );
    }

    cellRender_colorHexa(itemData) {
        return (
            <div
                style={{
                    height: 20,
                    width: "33%",
                    margin: "0 auto",
                    borderRadius: 15,
                    backgroundColor: itemData.data.tblCalendarioPersonal.colorHexa,
                }}
            />
        );
    }

    onClick_tblJornadaPersona_remove(arg, e) {
        e.component.deleteRow(e.rowIndex);
    }

    cellRender_tblJornadaPersona_remove(e) {
        let { idLavanderia, idLavanderiaOrigen } = e.data;
        if (idLavanderia == idLavanderiaOrigen) return null;
        return (
            <div
                className="container_spanCentrado pointer"
                onMouseDown={(arg) => {
                    this.onClick_tblJornadaPersona_remove(arg, e);
                }}
            >
                <div className="icon_Cerrar font-size-lg" />
            </div>
        );
    }

    cellRender_justificante() {
        // DE MOMENTO SE DEJA PORQUE EN EL FUTURO SE INCLUIRÁ
        return <div />;
        // return <div className="container_spanCentrado pointer" onMouseDown={this.onClick_justificante}>
        //     <div className="icon_Cuaderno font-size-lg" />
        // </ div>
    }

    onClick_justificante() {
        // this.props.data.component.deleteRow(this.props.data.rowIndex)
    }

    compareTime(time, isDuration) {
        // isDuration -> TRUE si es tiempo transcurrido || FALSE si es hora del día
        return (
            (isDuration &&
                ((typeof time === "string" && time === "00:00:00") ||
                    (typeof time !== "string" && formatTime_parameter(time) === "00:00:00"))) ||
            (!isDuration && time == null)
        );
    }

    dxDataGrid_jornadaPersona_horaEntradaSalidaDescanso_cellRender(itemData) {
        let { dataField } = itemData.column;
        let value = itemData.data[dataField];
        let hasInfoFaltante = this.get_hasInfoFaltante(itemData.data);
        let id = "warningIcon_" + dataField + "_" + itemData.data.idPersona;
        let { isVisible_iconWarning, iconWarning_id } = this.state;
        let hasInfoErronea = this.get_accionesRequeridas(itemData.data, dataField);
        let avisosInformativos = this.get_avisosInformativos(itemData.data, dataField);
        let turnoSel = this.getTurnoPersona(itemData.data);

        if (
            !itemData.data.isRegValido &&
            (hasInfoErronea.length > 0 ||
                (hasInfoFaltante && this.compareTime(value, false)) ||
                (avisosInformativos.length > 0 && itemData.data["isRevisado_" + dataField] == null))
        ) {
            itemData.errorIcon = true;

            let warningFaltante = value == null;

            let popoverText = warningFaltante
                ? "Campo obligatorio"
                : hasInfoErronea.length > 0
                ? hasInfoErronea[0].text
                : avisosInformativos.length > 0
                ? avisosInformativos[0].text
                : "";

            return (
                <div className="position-relative">
                    <div>{itemData.text}</div>
                    <i
                        className="dx-icon-warning position-absolute"
                        id={id}
                        style={{
                            top: 0,
                            right: 0,
                            height: "13px",
                            width: "13px",
                            color: warningFaltante || hasInfoErronea.length > 0 ? "var(--danger)" : "var(--warning)",
                        }}
                        onMouseEnter={(e) => this.iconWarning_onMouseEnter(id, popoverText, itemData)}
                        onMouseLeave={() => this.iconWarning_onMouseLeave()}
                    />
                    <Tooltip
                        deferRendering={false}
                        elementAttr={{ id: "tooltip_" + id }}
                        visible={isVisible_iconWarning && iconWarning_id == id}
                        target={"#" + id}
                        position="bottom"
                        closeOnOutsideClick={false}
                        onContentReady={this.dxTooltip_onContentReady}
                    >
                        <ul className="mb-0" style={{ paddingLeft: "20px" }}>
                            {turnoSel != null && !turnoSel.isNull && (
                                <li style={{ textAlign: "justify" }}>
                                    {this.getTrad("horaDelTurno") + ": "}
                                    <b>
                                        {turnoSel[dataField]?.toLocaleTimeString([], {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })}
                                    </b>
                                </li>
                            )}
                            <li style={{ textAlign: "justify" }}>{popoverText}</li>
                        </ul>
                    </Tooltip>
                </div>
            );
        } else {
            itemData.errorIcon = false;
            return <div>{itemData.text}</div>;
        }
    }

    iconWarning_onMouseEnter(id, popoverText) {
        this.setState({
            isVisible_iconWarning: true,
            popoverText_iconWarning: popoverText,
            iconWarning_id: id,
        });
    }

    iconWarning_onMouseLeave() {
        this.setState({ isVisible_iconWarning: false, iconWarning_id: null });
    }

    cellRender_nombreCompuesto(itemData) {
        let _this = this;
        let { isVisible_iconWarning_nombre, iconWarning_nombre_id, iconWarning_id } = this.state;
        let {
            nombreCompuesto,
            tblCalendarioPersonal,
            hasJornadaPersona,
            idPersona,
            horaEntrada,
            horaSalida,
            tiempoDescanso,
        } = itemData.data;

        let hasAusenciaYJornada = tblCalendarioPersonal != null && hasJornadaPersona;
        let hasAusenciaNoJustificada = horaEntrada == null && horaSalida == null && tiempoDescanso == null;
        let noJornada = !hasJornadaPersona;

        let icono = noJornada ? "icon_CalendarioMes dangerColor" : "icon_Warning";

        /**
         * ENSEÑAR ICONO CALENDARIO, OCULTADO AL IMPLEMENTAR CUADRANTES DE PERSONAL
         */
        // if(
        //     (tblCalendarioPersonal != null && (hasAusenciaYJornada || hasAusenciaNoJustificada))
        //     || noJornada)
        // {
        //     return <div className='d-flex flex-row'>
        //         <div className='flex-1'>{itemData.data.nombreCompuesto}</div>
        //         <div
        //             style={{fontSize:"19px"}}
        //             id={"nombreCompuestoWarning_"+idPersona}
        //             className={'pointer container_spanCentrado center-svg iconos_svg justify-content-end ' + icono}
        //             onClick = {(e)=>this.iconWarning_nombre_onClick(itemData)}
        //             onMouseEnter = {(e)=>this.iconWarning_nombre_onMouseEnter(idPersona, itemData)}
        //             onMouseLeave = {()=>this.iconWarning_nombre_onMouseLeave()}
        //         />
        //         <Tooltip
        //             deferRendering={false}
        //             elementAttr={{id:'tooltip_warning_'+ idPersona}}
        //             visible={isVisible_iconWarning_nombre && iconWarning_nombre_id == idPersona}
        //             target={"#nombreCompuestoWarning_"+idPersona}
        //             position="bottom"
        //             height={"auto"}
        //             closeOnOutsideClick={false}
        //         >
        //             {
        //                 noJornada && tblCalendarioPersonal == null ? "Añadir ausencia justificada" :
        //                 noJornada && tblCalendarioPersonal != null ? "Modificar ausencia justificada" :
        //                 <Fragment>
        //                     <div style={{fontSize:"13px",fontWeight:600, color:"#5C6476" }}>{"Esta persona tiene asignada un evento: "}</div>
        //                     <ul className='mb-1 pt-1'>{
        //                             <li
        //                                 style={{
        //                                     fontSize:"13px",
        //                                     color:"#5C6476",
        //                                     fontWeight:"400",
        //                                     textAlign:"left"
        //                                 }}
        //                             >{_this.getTrad(tblCalendarioPersonal.traduccion)}</li>}
        //                 </ul>
        //                 </Fragment>
        //             }
        //         </Tooltip>
        //     </div>
        // }
        return nombreCompuesto;
    }

    iconWarning_nombre_onClick(itemData) {
        let { tblCalendarioPersonal, hasJornadaPersona } = itemData.data;
        let noJornada = !hasJornadaPersona;

        let tblCalendarioEstado_items = $.extend(true, [], this.state.tblCalendarioEstado_items);
        let jornadaData = { ...itemData.data };
        let ausenciaData = { ...itemData.data.tblCalendarioPersonal };

        let turno = $.grep(this.datasource_tblTurno.items(), function (item) {
            return item.idTurno == jornadaData.idTurno;
        })[0];

        let estadosSeleccionados = [];

        $.each(tblCalendarioEstado_items, function (index, item) {
            if (noJornada) {
                if (
                    item.idEstado != 3 &&
                    item.idEstado != 4 &&
                    ((noJornada && tblCalendarioPersonal != null) || item.idEstado != 0) &&
                    item.idEstado != 8 &&
                    item.idEstado != 10
                ) {
                    estadosSeleccionados.push(item);
                }
            } else {
                if (
                    item.idEstado == 3 &&
                    (jornadaData.horaEntrada != null ||
                        jornadaData.horaSalida != null ||
                        jornadaData.tiempoDescanso != null)
                ) {
                    let horaEntrada =
                        jornadaData.horaEntrada != null
                            ? formatTime_parameter(jornadaData.horaEntrada).split(":")[0] +
                              ":" +
                              formatTime_parameter(jornadaData.horaEntrada).split(":")[1]
                            : "(Sin hora entrada)";
                    let horaSalida =
                        jornadaData.horaSalida != null
                            ? formatTime_parameter(jornadaData.horaSalida).split(":")[0] +
                              ":" +
                              formatTime_parameter(jornadaData.horaSalida).split(":")[1]
                            : "(Sin hora salida)";

                    item.subText =
                        "Horario: " + horaEntrada + " - " + horaSalida + " (" + turno.denominacion.toUpperCase() + ")";
                    estadosSeleccionados.push(item);
                }

                if (item.idEstado == ausenciaData.idEstado) estadosSeleccionados.push(item);
            }
        });

        let sortedArray = estadosSeleccionados
            .sort(function (a, b) {
                var textA = a.denominacion.toUpperCase();
                var textB = b.denominacion.toUpperCase();
                return a.idEstado == 3 || a.idEstado == 4 ? -1 : textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .sort(function (a, b) {
                return a.idEstado == 0 ? -1 : 0;
            });

        this.state.isNoJornada_dxPopup_eventosPersona = noJornada;
        this.setState({
            // isVisible_dxPopup_eventosPersona:true,
            idPersonaSel: itemData.data.idPersona,
            dxList_estadosPersona_dataSource: sortedArray,
            dxList_estadosPersona_selectedItem: !tblCalendarioPersonal ? null : tblCalendarioPersonal.idEstado,
        });
    }

    iconWarning_nombre_onMouseEnter(id) {
        this.setState({
            isVisible_iconWarning_nombre: true,
            iconWarning_nombre_id: id,
        });
    }

    iconWarning_nombre_onMouseLeave() {
        this.setState({
            isVisible_iconWarning_nombre: false,
            iconWarning_nombre_id: null,
        });
    }

    editCellRender_isRegValido(itemData) {
        let _this = this;
        let { fechaSel } = this.state;
        let rowData = itemData.data;
        let isRegValido = rowData.isRegValido;
        let icono = isRegValido ? "icon_Circle_check" : "icon_Circle_uncheck";

        let faltaInfo = this.get_hasInfoFaltante(rowData);
        let accionesRequeridas = this.get_accionesRequeridas(rowData).length > 0;

        return (
            <div
                onClick={() => {
                    if (!faltaInfo && !accionesRequeridas && !itemData.value) {
                        _this.state.idPersonaSel = itemData.data.idPersona;
                        let isRegValido_value = itemData.value != null ? !itemData.value : true;

                        itemData.component
                            .getDataSource()
                            .store()
                            .update(itemData.key, { isRegValido: isRegValido_value });

                        let rowIndex = itemData.component.getRowIndexByKey(itemData.key);
                        itemData.component.repaintRows(rowIndex);

                        _this.datasource_tblPersonas_gridJornada
                            .store()
                            .update(itemData.key, patchRequestHandler({ isRegValido: isRegValido_value }));
                    }
                }}
                className={
                    "container_spanCentrado center-svg iconos_svg " +
                    icono +
                    (!itemData.value && (faltaInfo || accionesRequeridas)
                        ? " opacityDisabled dangerColor"
                        : !itemData.value
                        ? " dangerColor"
                        : itemData.value
                        ? " opacityDisabled "
                        : "")
                }
                style={{
                    fontSize: "23px",
                    cursor: itemData.value || (!itemData.value && (faltaInfo || accionesRequeridas)) ? "" : "pointer",
                }}
            />
        );
    }

    dxButton_añadirPersona_onClick(e) {
        this.setState({
            isVisible_dxPopup_añadirPersona: true,
        });
    }

    dxButton_importarPersona_onClick(e) {
        let _this = this;
        this.datasource_tblLavanderia.reload().done(function () {
            let tblLavanderia_items = _this.datasource_tblLavanderia.items();
            let tblLavanderia_firstItem = tblLavanderia_items.length > 0 ? [tblLavanderia_items[0]] : [];
            _this.setState(
                {
                    isVisible_dxPopup_importarPersona: true,
                    dxList_tblLavanderia_selectedItem: tblLavanderia_firstItem,
                    isOpened_dropDownBox_filtroCategoriasInternas: null,
                },
                () => {
                    _this.datasource_personaNLavanderia.reload();
                }
            );
        });
    }

    dxDataGrid_tblJornadaPersona_onEditingStart(itemData) {
        let { dataField } = itemData.column;
        let value = itemData.data[dataField];
        let hasInfoFaltante = this.get_hasInfoFaltante(itemData.data);
        let turnoSel = this.getTurnoPersona(itemData.data);

        if (!turnoSel) {
            return;
        }

        let rowIndex = itemData.component.getRowIndexByKey(itemData.key);

        if (dataField != "isRegValido") {
            this.state.idPersonaSel = itemData.key.idPersona;
            this.state.selectedCell_gridJornada = "#" + dataField + "_" + rowIndex; // No se hace setState porque no quiero renderizar la pantalla
        }
        if (itemData.column.dataType == "date" && hasInfoFaltante && dataField == "tiempoDescanso" && value == null) {
            //Si no hay tiempo seteado
        } else if (
            (dataField == "horaEntrada" && value && value < turnoSel[dataField]) ||
            (dataField == "horaSalida" && value && value > turnoSel[dataField])
        ) {
            let tiempoDiff =
                dataField == "horaEntrada"
                    ? timeDiff(value, turnoSel[dataField])
                    : timeDiff(turnoSel[dataField], value);

            let horasTotales = tiempoDiff.getHours();

            if (tiempoDiff.getMinutes() > 45) horasTotales++;

            if (horasTotales > 0 && itemData.data.isRegValido != true) {
                this.setState({
                    selectedCell_gridJornada: "#" + dataField + "_" + rowIndex,
                });
                setTimeout(() => {
                    this.setState({
                        isVisible_dxPopup_horasExtra: true,
                        horasExtra: horasTotales,
                        dxDataGrid_jornadaPersona_rowData: itemData.data,
                        dataFieldSel_horasExtras: dataField,
                        idPersonaSel: itemData.key.idPersona,
                    });
                    this.dxPopover.hide();
                    this.dxButtonGroup_horasExtra.repaint();
                }, 0);
            }
        }
    }

    dxDataGrid_tblJornadaPersona_onEditorPreparing(e) {
        var field = e.dataField;
        var tipoTrabajo = e.row.data.idTipoTrabajo;
        if (
            field === "tiempoTipoTrabajo" + tipoTrabajo ||
            (field.includes("tiempoTipoTrabajo") && this.get_hasInfoFaltante(e.row.data))
        ) {
            e.editorOptions.disabled = true;
        } else if (e.parentType == "dataRow" && (e.dataField == "horaEntrada" || e.dataField == "horaSalida")) {
            e.editorOptions.placeholder = "00:00";
        }
    }

    dxDataGrid_tblJornadaPersona_onEditorPrepared(e) {
        e.editorElement.find(".dx-texteditor-input").off("dxmousewheel");
    }

    dxDataGrid_tblJornadaPersona_onCellHoverChanged(e) {
        if (e.column !== undefined && e.row !== undefined) {
            let faltaInfo = this.get_hasInfoFaltante(e.data);
            let accionesRequeridas = this.get_accionesRequeridas(e.data).length > 0;

            let _this = this;
            this.dxPopover.hide();

            const horaEntrada = e.data.reg_horaEntrada ?? null,
                horaSalida = e.data.reg_horaSalida ?? null,
                tiempoDescanso = (e.data.reg_horaInicioDescanso || e.data.reg_horaFinDescanso) ?? null;

            if (
                (e.column.dataField == "horaEntrada" && horaEntrada) ||
                (e.column.dataField == "horaSalida" && horaSalida) ||
                (e.column.dataField == "tiempoDescanso" && tiempoDescanso)
            ) {
                if (e.eventType === "mouseover") {
                    let { popoverText_iconWarning, isVisible_iconWarning } = this.state;
                    let turnoSel = this.getTurnoPersona(e.data);

                    if (!turnoSel) {
                        return;
                    }

                    this.dxPopover.option("contentTemplate", function (contentElement) {
                        let data = e.row.data;
                        let text;
                        let text2;

                        if (e.column.dataField == "horaEntrada") {
                            text = new Date(horaEntrada);
                        } else if (e.column.dataField == "horaSalida") {
                            text = new Date(horaSalida);
                        } else if (e.column.dataField == "tiempoDescanso") {
                            text = data.reg_horaInicioDescanso != null ? new Date(data.reg_horaInicioDescanso) : null;
                            text2 = data.reg_horaFinDescanso != null ? new Date(data.reg_horaFinDescanso) : null;

                            if (text == null && text2 == null) text = new Date(tiempoDescanso);
                        }

                        let content;
                        if (popoverText_iconWarning && isVisible_iconWarning) {
                            content = $("<ul class='mb-0' style='padding-left:20px;' />")
                                .append(
                                    "<li>" +
                                        _this.getTrad("horaDelTurno") +
                                        ": " +
                                        "<b>" +
                                        (turnoSel[e.column.dataField]
                                            ? turnoSel[e.column.dataField].toLocaleTimeString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                              })
                                            : "") +
                                        "</b>" +
                                        "</li>"
                                )
                                .append(
                                    "<li>" +
                                        _this.getTrad("horaTicada") +
                                        ": " +
                                        "<b>" +
                                        (text ? text : text2).toLocaleTimeString([], {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }) +
                                        "</b>" +
                                        (text && text2
                                            ? " - " +
                                              "<b>" +
                                              text2.toLocaleTimeString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                              }) +
                                              "</b>" +
                                              "</li>"
                                            : e.column.dataField != "tiempoDescanso" ||
                                              (e.column.dataField == "tiempoDescanso" && tiempoDescanso != null)
                                            ? "</li>" // Si no es descanso, cerrar li
                                            : " (" +
                                              _this.getTrad(text ? "sinHoraFin" : "sinHoraInicio") +
                                              ")" +
                                              "</li>")
                                ) // si es descanso + no tiene text2, sin hora fin
                                .append(popoverText_iconWarning ? "<li>" + popoverText_iconWarning + "</li>" : "");
                        } else {
                            content = $("<ul class='mb-0' style='padding-left:20px;' />")
                                .append(
                                    "<li>" +
                                        _this.getTrad("horaDelTurno") +
                                        ": " +
                                        "<b>" +
                                        (turnoSel[e.column.dataField]
                                            ? turnoSel[e.column.dataField].toLocaleTimeString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                              })
                                            : "") +
                                        "</b>" +
                                        "</li>"
                                )
                                .append(
                                    "<li>" +
                                        _this.getTrad("horaTicada") +
                                        ": " +
                                        "<b>" +
                                        (text ? text : text2).toLocaleTimeString([], {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        }) +
                                        "</b>" +
                                        (text && text2
                                            ? " - " +
                                              "<b>" +
                                              text2.toLocaleTimeString([], {
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                              }) +
                                              "</b>"
                                            : e.column.dataField != "tiempoDescanso" ||
                                              (e.column.dataField == "tiempoDescanso" && tiempoDescanso != null)
                                            ? ""
                                            : " (" +
                                              _this.getTrad(text ? "sinHoraFin" : "sinHoraInicio") +
                                              ")" +
                                              "</li>")
                                );
                        }
                        content.appendTo(contentElement);
                    });
                    this.dxPopover.option("target", e.cellElement);
                    if (!this.state.isVisible_dxPopup_horasExtra) this.dxPopover.show();
                    this.setState({
                        popoverText_iconWarning: null,
                        isVisible_iconWarning: false,
                    });
                } else {
                    this.dxPopover.hide();
                }
            } else if (
                e.column.dataField == "isRegValido" &&
                !e.row.data.isRegValido &&
                (faltaInfo || accionesRequeridas)
            ) {
                if (e.eventType === "mouseover") {
                    let _this = this;
                    this.dxPopover_datosErroneos.option("contentTemplate", function (contentElement) {
                        $("<div/>")
                            .append(_this.getTrad("error_validarPersonasDatosErróneos"))
                            .appendTo(contentElement);
                    });
                    this.dxPopover_datosErroneos.option("target", e.cellElement);
                    this.dxPopover_datosErroneos.show();
                } else this.dxPopover_datosErroneos.hide();
            }
        }
    }

    dxDataGrid_tblJornadaPersona_onCellPrepared(e) {
        if (e.rowType === "data") {
            var field = e.column.dataField;
            var tipoTrabajo = e.row.data.idTipoTrabajo;

            if (field === "tiempoTipoTrabajo" + tipoTrabajo) {
                e.cellElement.css("opacity", 0.5);
            }

            e.cellElement.attr("id", field + "_" + e.rowIndex);
        }
    }

    dxDataGrid_tblJornadaPersona_onKeyDown(e) {
        if (e.jQueryEvent.keyCode === 9) {
            // Tab
            e.event.preventDefault();
        }
    }

    dxDataGrid_tblJornadaPersona_onRowRemoving(e) {
        this.state.idPersonaSel = e.data.idPersona;
        this.datasource_tblPersonas_gridJornada.store().remove(e.key);
        e.component.refresh();
    }

    dxDataGrid_tblJornadaPersona_onRowUpdating(e) {
        let _this = this;
        this.dxDataGrid_tblJornadaPersona.option("loadPanel.enabled", false);
        let rowIndex = e.component.getRowIndexByKey(e.key);

        if (e.oldData.isNewRow) {
            // INSERT
            let { idTurnoSel, idLavanderiaSel } = this.state;
            const turnoJornada = this.getTurnoPersona(e.oldData);
            let row = {
                idPersona: e.key.idPersona,
                fecha: e.key.fecha,
                idLavanderia:
                    idLavanderiaSel != null
                        ? idLavanderiaSel
                        : e.oldData.idLavanderia != null
                        ? e.oldData.idLavanderia
                        : e.oldData.idLavanderiaOrigen,
                idTurno: idTurnoSel != null ? idTurnoSel : e.oldData.idTurno,
                isRegValido: false,
                hasJornadaPersona: true,
                horaEntrada: e.newData.horaEntrada ? formatTime_parameter(e.newData.horaEntrada) : null,
                horaSalida: e.newData.horaSalida ? formatTime_parameter(e.newData.horaSalida) : null,
                tiempoDescanso: e.newData.tiempoDescanso ? formatTime_parameter(e.newData.tiempoDescanso) : null,
                horaEntrada_turno: turnoJornada ? formatTime_parameter(turnoJornada.horaEntrada) : null,
                horaSalida_turno: turnoJornada ? formatTime_parameter(turnoJornada.horaSalida) : null,
                descanso_turno: turnoJornada ? formatTime_parameter(turnoJornada.tiempoDescanso) : null,
                horasDiarias: e.oldData.horasDiarias,
            };

            let isRevisado_field;
            if (row.horaEntrada == null) delete row.horaEntrada;
            else isRevisado_field = "isRevisado_horaEntrada";
            if (row.horaSalida == null) delete row.horaSalida;
            else isRevisado_field = "isRevisado_horaSalida";
            if (row.tiempoDescanso == null) delete row.tiempoDescanso;
            else isRevisado_field = "isRevisado_tiempoDescanso";

            row[isRevisado_field] = true;
            e.oldData.isNewRow = false;

            if (row["idTurno"] == -1) row["idTurno"] = null;

            let updateObj = {};
            updateObj[isRevisado_field] = true;
            updateObj.hasJornadaPersona = true;
            updateObj.horaEntrada_turno = _this.set_time_fechaSel(new Date("01/01/1970 " + row.horaEntrada_turno));
            updateObj.horaSalida_turno = _this.set_time_fechaSel(new Date("01/01/1970 " + row.horaSalida_turno));
            updateObj.descanso_turno = _this.set_time_fechaSel(new Date("01/01/1970 " + row.descanso_turno));

            e.component.getDataSource().store().update(e.key, updateObj);

            this.state.is_savingData = true;
            this.datasource_tblPersonas_gridJornada.store().insert([row]);
        } else {
            // DELETE
            let oldData_ = $.extend(true, {}, e.oldData);
            $.each(e.newData, function (field, value) {
                oldData_[field] = value;
            });

            let { horaEntrada, horaSalida, tiempoDescanso } = oldData_;
            if (horaEntrada == null && horaSalida == null && tiempoDescanso == null) {
                let isRevisado_field;

                if (e.newData.hasOwnProperty("horaEntrada")) isRevisado_field = "isRevisado_horaEntrada";
                if (e.newData.hasOwnProperty("horaSalida")) isRevisado_field = "isRevisado_horaSalida";
                if (e.newData.hasOwnProperty("tiempoDescanso")) isRevisado_field = "isRevisado_tiempoDescanso";

                let updateObj = {};
                updateObj[isRevisado_field] = false;
                updateObj["hasJornadaPersona"] = false;
                updateObj["tiempoTipoTrabajo1"] = this.set_time_fechaSel(new Date("0"));
                updateObj["tiempoTipoTrabajo2"] = this.set_time_fechaSel(new Date("0"));
                updateObj["tiempoTipoTrabajo3"] = this.set_time_fechaSel(new Date("0"));
                updateObj["tiempoTipoTrabajo4"] = this.set_time_fechaSel(new Date("0"));
                updateObj["tiempoTipoTrabajo5"] = this.set_time_fechaSel(new Date("0"));
                updateObj["tiempoTipoTrabajo6"] = this.set_time_fechaSel(new Date("0"));

                e.oldData.isNewRow = true;

                e.component.getDataSource().store().update(e.key, updateObj);
                e.component.repaintRows(rowIndex);
                e.component.refresh(true);

                this.setState({ idPersonaSel: e.key.idPersona, is_savingData: true });
                this.datasource_tblPersonas_gridJornada.store().remove(e.key);
            } else {
                // UPDATE
                let data = {};
                let isRevisado_field;

                let updateObj = {};
                $.each(e.newData, function (field, value) {
                    if (field !== "fecha" && (value instanceof Date || value == null)) {
                        if (value == null) {
                            data[field] = null;
                            if (field == "horaEntrada" || field == "horaSalida" || field == "tiempoDescanso") {
                                data["isRevisado_" + field] = null;
                                isRevisado_field = "isRevisado_" + field;
                                updateObj[isRevisado_field] = null;
                                updateObj.isRegValido = false;
                                updateObj["tiempoTipoTrabajo1"] = _this.set_time_fechaSel(new Date("0"));
                                updateObj["tiempoTipoTrabajo2"] = _this.set_time_fechaSel(new Date("0"));
                                updateObj["tiempoTipoTrabajo3"] = _this.set_time_fechaSel(new Date("0"));
                                updateObj["tiempoTipoTrabajo4"] = _this.set_time_fechaSel(new Date("0"));
                                updateObj["tiempoTipoTrabajo5"] = _this.set_time_fechaSel(new Date("0"));
                                updateObj["tiempoTipoTrabajo6"] = _this.set_time_fechaSel(new Date("0"));
                            }
                        } else {
                            data[field] = formatTime_parameter(value);
                            if (field == "horaEntrada" || field == "horaSalida" || field == "tiempoDescanso") {
                                isRevisado_field = "isRevisado_" + field;
                                updateObj[isRevisado_field] = true;
                            }
                        }
                    }
                });
                e.component.getDataSource().store().update(e.key, updateObj);
                e.component.repaintRows(rowIndex);
                e.component.refresh(true);

                this.state.is_savingData = true;
                this.setState({ idPersonaSel: e.key.idPersona });
                this.datasource_tblPersonas_gridJornada.store().update(e.key, patchRequestHandler(data));
            }
        }
    }

    dxDataGrid_tblJornadaPersona_onRowUpdated(e) {
        this.dxDataGrid_tblJornadaPersona.option("loadPanel.enabled", true);
    }

    dxDataGrid_tblJornadaPersona_onRowValidating(e) {
        e.errorText = getTrad("aviso_I_TiempoSeleccionadoSuperior");
        let data = $.extend(true, {}, e.oldData);
        $.each(e.newData, function (dataField, val) {
            data[dataField] = val;
        });

        if (data.horaEntrada && data.horaSalida && data.tiempoDescanso) {
            e.isValid = this.calcularTiempoTrabajo(data);
        }
    }

    dxDataGrid_tblJornadaPersona_onRowPrepared(e) {
        if (e.rowType === "data") {
            if (!e.data.isRegValido) {
                e.rowElement.addClass("warningRow_onlyText");
            }
        }
    }

    dxDataGrid_tblJornadaPersona_setCellValue(field, newData, value, currentRowData) {
        var isValid = true;
        var grid = this.dxDataGrid_tblJornadaPersona;

        if (!$.isEmptyObject(currentRowData)) {
            var idTipoTrabajo = currentRowData.idTipoTrabajo;

            var rowIndex = grid.getRowIndexByKey({
                idPersona: currentRowData.idPersona,
                fecha: currentRowData.fecha,
            });
            var row = {
                idTipoTrabajo: idTipoTrabajo,
                horaEntrada: grid.cellValue(rowIndex, "horaEntrada"),
                horaSalida: grid.cellValue(rowIndex, "horaSalida"),
                tiempoDescanso: grid.cellValue(rowIndex, "tiempoDescanso"),
                tiempoTipoTrabajo1: grid.cellValue(rowIndex, "tiempoTipoTrabajo1"),
                tiempoTipoTrabajo2: grid.cellValue(rowIndex, "tiempoTipoTrabajo2"),
                tiempoTipoTrabajo3: grid.cellValue(rowIndex, "tiempoTipoTrabajo3"),
                tiempoTipoTrabajo4: grid.cellValue(rowIndex, "tiempoTipoTrabajo4"),
                tiempoTipoTrabajo5: grid.cellValue(rowIndex, "tiempoTipoTrabajo5"),
                tiempoTipoTrabajo6: grid.cellValue(rowIndex, "tiempoTipoTrabajo6"),
            };

            row[field] = value;

            var tiempoTrabajo = this.calcularTiempoTrabajo(row);
            if (tiempoTrabajo) {
                //Se ha superado el tiempo aún poniendo el tiempo del tipoTrabajo a 0
                newData["tiempoTipoTrabajo" + idTipoTrabajo] = tiempoTrabajo;
            } else if (row.tiempoDescanso != null) {
                isValid = false;
            }
        } else {
            isValid = false;
        }

        if (value) {
            value = this.set_time_fechaSel(value);
        }
        newData[field] = value;

        return isValid;
    }

    set_time_fechaSel(time) {
        let { fechaSel } = this.state;
        let newValue = new Date(fechaSel);
        newValue.setHours(time.getHours());
        newValue.setMinutes(time.getMinutes());
        newValue.setSeconds(0);

        return newValue;
    }

    calcularTiempoTrabajo(row) {
        var rowData = $.extend(true, {}, row);
        //Convertimos los valores de duration a datetime, en caso del validation
        var array_field = [
            "horaEntrada",
            "horaSalida",
            "tiempoDescanso",
            "tiempoTipoTrabajo1",
            "tiempoTipoTrabajo2",
            "tiempoTipoTrabajo3",
            "tiempoTipoTrabajo4",
            "tiempoTipoTrabajo5",
            "tiempoTipoTrabajo6",
        ];
        $.each(rowData, function (field, value) {
            if ($.inArray(field, array_field) !== -1 && typeof value === "string") {
                rowData[field] = durationToDatetime(value);
            }
        });

        if (rowData.horaEntrada == null || rowData.horaSalida == null || rowData.tiempoDescanso == null) {
            return null;
        }

        var hEntrada = this.set_time_fechaSel(new Date("0"));
        hEntrada.setHours(
            rowData.horaEntrada ? rowData.horaEntrada.getHours() : 0,
            rowData.horaEntrada ? rowData.horaEntrada.getMinutes() : 0
        );
        var hSalida = this.set_time_fechaSel(new Date("0"));
        hSalida.setHours(
            rowData.horaSalida ? rowData.horaSalida.getHours() : 0,
            rowData.horaSalida ? rowData.horaSalida.getMinutes() : 0
        );
        var tDescanso = rowData.tiempoDescanso;

        var tt1 = rowData.tiempoTipoTrabajo1;
        var tt2 = rowData.tiempoTipoTrabajo2;
        var tt3 = rowData.tiempoTipoTrabajo3;
        var tt4 = rowData.tiempoTipoTrabajo4;
        var tt5 = rowData.tiempoTipoTrabajo5;
        var tt6 = rowData.tiempoTipoTrabajo6;

        if (hEntrada > hSalida) {
            hSalida.setDate(hSalida.getDate() + 1);
        }
        var secDescanso = tDescanso != null ? this.dateTimeToSeconds(tDescanso) : 0;
        var secTt1 = rowData.idTipoTrabajo !== 1 && tt1 ? this.dateTimeToSeconds(tt1) : 0;
        var secTt2 = rowData.idTipoTrabajo !== 2 && tt2 ? this.dateTimeToSeconds(tt2) : 0;
        var secTt3 = rowData.idTipoTrabajo !== 3 && tt3 ? this.dateTimeToSeconds(tt3) : 0;
        var secTt4 = rowData.idTipoTrabajo !== 4 && tt4 ? this.dateTimeToSeconds(tt4) : 0;
        var secTt5 = rowData.idTipoTrabajo !== 5 && tt5 ? this.dateTimeToSeconds(tt5) : 0;
        var secTt6 = rowData.idTipoTrabajo !== 6 && tt6 ? this.dateTimeToSeconds(tt6) : 0;

        //tiempo total disponible - el resto de tiempo trabajo (no incluido defecto)
        var totalSec =
            (hSalida.getTime() - hEntrada.getTime()) / 1000 -
            secDescanso -
            secTt1 -
            secTt2 -
            secTt3 -
            secTt4 -
            secTt5 -
            secTt6;

        var totalSecLibres = secTt1 + secTt2 + secTt3 + secTt4 + secTt5 + secTt6;

        if (totalSec <= 24 * 60 * 60 && totalSec >= 0) {
            var hours = Math.floor(totalSec / 3600);
            var minutes = Math.floor((totalSec - hours * 3600) / 60);

            var horas = this.set_time_fechaSel(new Date("0"));
            horas.setHours(hours);
            horas.setMinutes(minutes);
            return horas;
        } else if (totalSecLibres - Math.abs(totalSec) > 0) {
            this.setState({ isVisible_dxPopover_CambioHorasTrabajo: true });
            return null;
        } else {
            return null;
        }
    }

    tratarDatos_dxForm_tblJornadaPersona() {
        let {
            formData_addPersona,
            dxList_tblPersonas_selectedItems,
            dxList_importarPersonas_personaNLavanderia_selectedItem,
            idLavanderiaSel,
        } = this.state;

        var jornadasInsertar = [];
        var invalido = false;
        let _this = this;
        let { fechaSel } = this.state;

        let personas = $.merge(
            $.extend(true, [], dxList_tblPersonas_selectedItems),
            $.extend(true, [], dxList_importarPersonas_personaNLavanderia_selectedItem)
        );

        $.each(personas, function (index, persona) {
            var jornada = $.extend(true, {}, formData_addPersona);

            const turnoJornada = this.getTurnoPersona(jornada);
            var row = {
                idTipoTrabajo: persona.idTipoTrabajo,
                horaEntrada: formData_addPersona.horaEntrada,
                horaSalida: formData_addPersona.horaSalida,
                tiempoDescanso: formData_addPersona.tiempoDescanso,
                tiempoTipoTrabajo1: jornada.tiempoTipoTrabajo1,
                tiempoTipoTrabajo2: jornada.tiempoTipoTrabajo2,
                tiempoTipoTrabajo3: jornada.tiempoTipoTrabajo3,
                tiempoTipoTrabajo4: jornada.tiempoTipoTrabajo4,
                tiempoTipoTrabajo5: jornada.tiempoTipoTrabajo5,
                tiempoTipoTrabajo6: jornada.tiempoTipoTrabajo6,
                isRegManual: null,
                horaEntrada_turno: turnoJornada?.horaEntrada,
                horaSalida_turno: turnoJornada?.horaSalida,
                descanso_turno: turnoJornada?.tiempoDescanso,
            };
            delete jornada.personasSel;
            if (row["idTurno"] == -1) row["idTurno"] = null;

            var tiempoTrabajo = _this.calcularTiempoTrabajo(row);

            if (!tiempoTrabajo) {
                invalido = true;
                return false;
            }

            jornada.idPersona = persona.idPersona;
            jornada.idLavanderia = idLavanderiaSel != null ? idLavanderiaSel : persona.idLavanderia;
            jornada.fecha = fechaSel;
            jornada["tiempoTipoTrabajo" + persona.idTipoTrabajo] = tiempoTrabajo;

            $.each(jornada, function (field, value) {
                if (field !== "fecha" && value instanceof Date) {
                    jornada[field] = formatTime_parameter(value);
                }
            });

            jornadasInsertar.push(jornada);
        });

        return invalido ? null : jornadasInsertar;
    }

    getTurnoPersona(data) {
        const { hasJornadaPersona } = data;

        if (hasJornadaPersona) {
            let turnoPersonalizadoJornada = {
                horaEntrada: data.horaEntrada_turno,
                horaSalida: data.horaSalida_turno,
                tiempoDescanso: data.descanso_turno,
            };
            let isEmptyTurnoPersonalizado = Object.values(turnoPersonalizadoJornada).every(
                (o) => o === null || o === undefined
            );
            turnoPersonalizadoJornada.isNull = isEmptyTurnoPersonalizado;

            return turnoPersonalizadoJornada;
        } else {
            let turnoPersonalizadoPersona = {
                horaEntrada: data.horaEntradaParam,
                horaSalida: data.horaSalidaParam,
                tiempoDescanso: data.tiempoDescansoParam,
            };

            const sinDatosHoras =
                turnoPersonalizadoPersona.horaEntrada == null || turnoPersonalizadoPersona.horaSalida == null;

            if (!sinDatosHoras) {
                return turnoPersonalizadoPersona;
            } else {
                let { tblTurnos_items, idTurnoSel } = this.state;
                let turnoActual = $.grep(tblTurnos_items, function (item) {
                    return item.idTurno == (data.idTurno != null ? data.idTurno : idTurnoSel);
                })[0];
                return turnoActual;
            }
        }
    }

    get_hasInfoFaltante(row) {
        let horasTrabajadasVacias =
            this.compareTime(row.tiempoTipoTrabajo1, true) &&
            this.compareTime(row.tiempoTipoTrabajo2, true) &&
            this.compareTime(row.tiempoTipoTrabajo3, true) &&
            this.compareTime(row.tiempoTipoTrabajo4, true) &&
            this.compareTime(row.tiempoTipoTrabajo5, true) &&
            this.compareTime(row.tiempoTipoTrabajo6, true);

        let hasInfoFaltante =
            horasTrabajadasVacias ||
            this.compareTime(row.horaEntrada, false) ||
            this.compareTime(row.horaSalida, false) ||
            (!row.isRevisado_tiempoDescanso && row.tiempoDescanso == null);
        return hasInfoFaltante;
    }

    get_accionesRequeridas(row, dataField) {
        let turnoSel = this.getTurnoPersona(row);

        let columnas = dataField != null ? [dataField] : ["horaEntrada", "horaSalida"];
        let errores = [];
        if (turnoSel == null || turnoSel.isNull) return errores;
        $.each(columnas, function (index, dataField_) {
            if (row[dataField_] && typeof row[dataField_] == "object") row[dataField_].setSeconds(0);
            if (row[dataField_] > turnoSel[dataField_] && row["isRevisado_" + dataField_] == null) {
                if (dataField_ == "horaSalida") errores.push({ text: "Ha salido tarde" });
            } else if (row[dataField_] < turnoSel[dataField_] && row["isRevisado_" + dataField_] == null) {
                if (dataField_ == "horaEntrada") errores.push({ text: "Ha llegado antes" });
            }
        });
        return errores;
    }

    get_avisosInformativos(row, dataField) {
        let turnoSel = this.getTurnoPersona(row);
        let columnas = dataField != null ? [dataField] : ["horaEntrada", "horaSalida", "tiempoDescanso"];

        let errores = [];
        if (turnoSel == null || turnoSel.isNull) return errores;

        let _this = this;
        $.each(columnas, function (index, dataField_) {
            if (row[dataField_] && typeof row[dataField_] == "object") row[dataField_].setSeconds(0);
            if (row[dataField_] > turnoSel[dataField_] && row["isRevisado_" + dataField_] == null) {
                if (dataField_ == "horaEntrada") errores.push({ text: _this.getTrad("haLlegadoTarde") });
                else if (dataField_ == "tiempoDescanso") errores.push({ text: _this.getTrad("tiempoSuperior") });
            } else if (row[dataField_] < turnoSel[dataField_] && row["isRevisado_" + dataField_] == null) {
                if (dataField_ == "horaSalida") errores.push({ text: _this.getTrad("haSalidoAntes") });
                else if (dataField_ == "tiempoDescanso") errores.push({ text: _this.getTrad("tiempoInferior") });
            }
        });
        return errores;
    }

    calculateDisplayValue_traduccion(e) {
        return this.getTrad(e.tblCalendarioPersonal.traduccion).toUpperCase();
    }

    dxDataGrid_jornadaPersona_horaEntrada_calculateDisplayValue(e) {
        let field = e.horaEntrada;
        if (this.get_hasInfoFaltante(e) && field == null) {
            return "-";
        } else {
            if (typeof field === "string") {
                let timeSplit = field.split(":");
                return timeSplit[0] + ":" + timeSplit[1];
            }
            if (field == null) {
                var d = this.set_time_fechaSel(new Date());
                d.setHours(0, 0, 0, 0);
                return d;
            }
            return field;
        }
    }

    dxDataGrid_jornadaPersona_horaSalida_calculateDisplayValue(e) {
        let field = e.horaSalida;
        if (this.get_hasInfoFaltante(e) && field == null) {
            return "-";
        } else {
            if (typeof field === "string") {
                let timeSplit = field.split(":");
                return timeSplit[0] + ":" + timeSplit[1];
            }
            if (field == null) {
                var d = this.set_time_fechaSel(new Date());
                d.setHours(0, 0, 0, 0);
                return d;
            }
            return field;
        }
    }

    dxDataGrid_jornadaPersona_tiempoDescanso_calculateDisplayValue(e) {
        let field = e.tiempoDescanso;
        if (this.get_hasInfoFaltante(e) && field == null && !e.isRevisado_tiempoDescanso) {
            return "-";
        } else {
            if (typeof field === "string") {
                let timeSplit = field.split(":");
                return timeSplit[0] + ":" + timeSplit[1];
            }
            if (field == null) {
                var d = this.set_time_fechaSel(new Date());
                d.setHours(0, 0, 0, 0);
                return d;
            }
            return field;
        }
    }

    dxDataGrid_jornadaPersona_tiempoTipoTrabajo1_calculateDisplayValue(e) {
        if (this.get_hasInfoFaltante(e)) {
            return "-";
        } else {
            let field = e.tiempoTipoTrabajo1;
            if (typeof field === "string") {
                let timeSplit = field.split(":");
                return timeSplit[0] + ":" + timeSplit[1];
            }
            if (field == null) {
                var d = this.set_time_fechaSel(new Date());
                d.setHours(0, 0, 0, 0);
                return d;
            }
            return field;
        }
    }

    dxDataGrid_jornadaPersona_tiempoTipoTrabajo2_calculateDisplayValue(e) {
        if (this.get_hasInfoFaltante(e)) {
            return "-";
        } else {
            let field = e.tiempoTipoTrabajo2;
            if (typeof field === "string") {
                let timeSplit = field.split(":");
                return timeSplit[0] + ":" + timeSplit[1];
            }
            if (field == null) {
                var d = this.set_time_fechaSel(new Date());
                d.setHours(0, 0, 0, 0);
                return d;
            }
            return field;
        }
    }

    dxDataGrid_jornadaPersona_tiempoTipoTrabajo3_calculateDisplayValue(e) {
        if (this.get_hasInfoFaltante(e)) {
            return "-";
        } else {
            let field = e.tiempoTipoTrabajo3;
            if (typeof field === "string") {
                let timeSplit = field.split(":");
                return timeSplit[0] + ":" + timeSplit[1];
            }
            if (field == null) {
                var d = this.set_time_fechaSel(new Date());
                d.setHours(0, 0, 0, 0);
                return d;
            }
            return field;
        }
    }

    dxDataGrid_jornadaPersona_tiempoTipoTrabajo4_calculateDisplayValue(e) {
        if (this.get_hasInfoFaltante(e)) {
            return "-";
        } else {
            let field = e.tiempoTipoTrabajo4;
            if (typeof field === "string") {
                let timeSplit = field.split(":");
                return timeSplit[0] + ":" + timeSplit[1];
            }
            if (field == null) {
                var d = this.set_time_fechaSel(new Date());
                d.setHours(0, 0, 0, 0);
                return d;
            }
            return field;
        }
    }

    dxDataGrid_jornadaPersona_tiempoTipoTrabajo5_calculateDisplayValue(e) {
        if (this.get_hasInfoFaltante(e)) {
            return "-";
        } else {
            let field = e.tiempoTipoTrabajo5;
            if (typeof field === "string") {
                let timeSplit = field.split(":");
                return timeSplit[0] + ":" + timeSplit[1];
            }
            if (field == null) {
                var d = this.set_time_fechaSel(new Date());
                d.setHours(0, 0, 0, 0);
                return d;
            }
            return field;
        }
    }

    dxDataGrid_jornadaPersona_tiempoTipoTrabajo6_calculateDisplayValue(e) {
        if (this.get_hasInfoFaltante(e)) {
            return "-";
        } else {
            let field = e.tiempoTipoTrabajo6;
            if (typeof field === "string") {
                let timeSplit = field.split(":");
                return timeSplit[0] + ":" + timeSplit[1];
            }
            if (field == null) {
                var d = this.set_time_fechaSel(new Date());
                d.setHours(0, 0, 0, 0);
                return d;
            }
            return field;
        }
    }

    horaEntrada_setCellValue(newData, value, currentRowData) {
        this.dxDataGrid_tblJornadaPersona_setCellValue("horaEntrada", newData, value, currentRowData);
    }

    horaSalida_setCellValue(newData, value, currentRowData) {
        this.dxDataGrid_tblJornadaPersona_setCellValue("horaSalida", newData, value, currentRowData);
    }

    tiempoDescanso_setCellValue(newData, value, currentRowData) {
        this.dxDataGrid_tblJornadaPersona_setCellValue("tiempoDescanso", newData, value, currentRowData);
    }

    tiempoTipoTrabajo1_setCellValue(newData, value, currentRowData) {
        this.dxDataGrid_tblJornadaPersona_setCellValue("tiempoTipoTrabajo1", newData, value, currentRowData);
    }

    tiempoTipoTrabajo2_setCellValue(newData, value, currentRowData) {
        this.dxDataGrid_tblJornadaPersona_setCellValue("tiempoTipoTrabajo2", newData, value, currentRowData);
    }

    tiempoTipoTrabajo3_setCellValue(newData, value, currentRowData) {
        this.dxDataGrid_tblJornadaPersona_setCellValue("tiempoTipoTrabajo3", newData, value, currentRowData);
    }

    tiempoTipoTrabajo4_setCellValue(newData, value, currentRowData) {
        this.dxDataGrid_tblJornadaPersona_setCellValue("tiempoTipoTrabajo4", newData, value, currentRowData);
    }

    tiempoTipoTrabajo5_setCellValue(newData, value, currentRowData) {
        this.dxDataGrid_tblJornadaPersona_setCellValue("tiempoTipoTrabajo5", newData, value, currentRowData);
    }

    tiempoTipoTrabajo6_setCellValue(newData, value, currentRowData) {
        this.dxDataGrid_tblJornadaPersona_setCellValue("tiempoTipoTrabajo6", newData, value, currentRowData);
    }

    dxDataGrid_tblJornadaPersona_calculateCustomSummary(options) {
        if (options.name === "horaEntrada") {
            let { items_gridJornada } = this.state;
            options.totalValue = items_gridJornada["_array"].length + " PERSONAS ACTIVAS" + " - ";
        } else if (options.name === "horaEntrada2") {
            let { items_gridJornada } = this.state;
            let ausenciasNoJustificadas = $.grep(items_gridJornada["_array"], function (item) {
                return (
                    item.horaEntrada == null &&
                    item.horaSalida == null &&
                    item.tiempoDescanso == null &&
                    item.tblCalendarioPersonal == null
                );
            });
            options.totalValue = ausenciasNoJustificadas.length + " AUSENCIAS NO JUSTIFICADAS";
        } else {
            var array_field = [
                "tiempoTipoTrabajo1",
                "tiempoTipoTrabajo2",
                "tiempoTipoTrabajo3",
                "tiempoTipoTrabajo4",
                "tiempoTipoTrabajo5",
                "tiempoTipoTrabajo6",
            ];

            if ($.inArray(options.name, array_field) !== -1) {
                switch (options.summaryProcess) {
                    case "start":
                        options.totalValue = 0;
                        break;
                    case "calculate":
                        var val = options.value;
                        if (typeof val === "string") {
                            val = durationToDatetime(options.value);
                        }
                        options.totalValue += this.dateTimeToSeconds(val);
                        break;
                    case "finalize":
                        var hours = leadingZero(Math.floor(options.totalValue / 3600));
                        var minutes = leadingZero(Math.floor((options.totalValue - hours * 3600) / 60));

                        options.totalValue = hours + ":" + minutes + " ";
                        break;
                    default:
                        break;
                }
            }
        }
    }

    dxDataGrid_ausencias_onRowPrepared(e) {
        if (e.rowType == "header") e.rowElement.css("display", "none");
    }

    dxDateBox_onKeyDown(e) {
        e.event.preventDefault();
        e.event.stopPropagation();
    }

    dxDateBox_onOpened(e) {
        e.component
            .content()
            .parent()
            .mouseleave(function () {
                setTimeout(() => {
                    e.component.close();
                }, 0);
            });
    }

    dxDateBox_onValueChanged(e) {
        this.setState(
            {
                fechaSel: e.value,
            },
            () => {
                this.signalR_reconnect();
            }
        );
    }
    dxDateBox_onOptionChanged(e) {
        if (e.name === "hoveredElement") {
            if (e.value != null) {
                // IN
                e.component.open();
            }
        }
    }

    dxDataGrids_reload(idsPersonaActualizados, filtrosPersona) {
        let _this = this;
        function reloadData() {
            $.when(_this.datasource_tblPersonas_gridJornada.reload()).then(function (items) {
                let jornada = $.grep(items, function (item) {
                    return item.hasJornadaPersona || (!item.hasJornadaPersona && item.tblCalendarioPersonal == null);
                });
                let ausencias = $.grep(items, function (item) {
                    return item.tblCalendarioPersonal && !item.hasJornadaPersona;
                });

                //#region PARA ACTUALIZAR LA FECHA DE LOS TURNOS
                let tblTurnos_items = $.extend(true, [], _this.state.tblTurnos_items);
                let tblTurnos_subTurnos_items = $.extend(true, [], _this.state.tblTurnos_subTurnos_items);

                $.each(tblTurnos_items, function (index, item) {
                    item.horaEntrada = _this.set_time_fechaSel(item.horaEntrada);
                    item.horaSalida = _this.set_time_fechaSel(item.horaSalida);
                    item.tiempoDescanso = _this.set_time_fechaSel(item.tiempoDescanso);
                });
                $.each(tblTurnos_subTurnos_items, function (index, item) {
                    item.horaEntrada = _this.set_time_fechaSel(item.horaEntrada);
                    item.horaSalida = _this.set_time_fechaSel(item.horaSalida);
                    item.tiempoDescanso = _this.set_time_fechaSel(item.tiempoDescanso);
                });
                //#endregion

                _this.setState(
                    {
                        items_gridJornada: new ArrayStore({
                            key: ["idPersona", "fecha"],
                            data: jornada,
                        }),
                        isVisible_dxPopup_horasExtra: false,
                        tblTurnos_items: tblTurnos_items,
                        tblTurnos_subTurnos_items: tblTurnos_subTurnos_items,
                    },
                    () => {
                        if (idsPersonaActualizados) {
                            $.each(idsPersonaActualizados, function (index, item) {
                                let key = {
                                    idPersona: item,
                                    fecha: formatDate_noTime_parameter(_this.state.fechaSel),
                                };
                                let rowIndex = _this.dxDataGrid_tblJornadaPersona.getRowIndexByKey(key);

                                _this.dxDataGrid_tblJornadaPersona
                                    .getDataSource()
                                    .store()
                                    .update(key, { isNewRow: false });
                                _this.dxDataGrid_tblJornadaPersona.repaintRows(rowIndex);
                            });
                        }
                        setTimeout(() => {
                            _this.setState({
                                isLoading_dxDataGrid_tblJornadaPersona: false,
                                isLoading_white_dxDataGrid_tblJornadaPersona: false,
                                items_gridAusencias: new ArrayStore({
                                    key: ["idPersona", "fecha"],
                                    data: ausencias,
                                }),
                                is_loadingLavanderia: false,
                            });

                            _this.loadPanel_hide();
                        }, 150); // Lo que tarda en renderizar las rows
                    }
                );
            });
        }

        if (filtrosPersona != null) {
            this.setState(
                {
                    isLoading_dxDataGrid_tblJornadaPersona: true,
                    idLavanderiaSel: filtrosPersona.idLavanderia,
                    idCategoriaInternaSel: filtrosPersona.idCategoriaInterna,
                    idTurnoSel: filtrosPersona.idTurno,
                },
                () => {
                    reloadData();
                }
            );
        } else {
            reloadData();
        }
    }

    dxButtonGroup_horasExtra_buttonRender(data) {
        let { horasExtra } = this.state;
        return (
            <div
                id={"dxButtonGroup_horasExtra_" + data.value}
                className={
                    "d-flex justify-content-center align-items-center " +
                    (data.value == "horas" ? "font-size-xl" : "font-size-xxl")
                }
                style={{
                    width: data.value == "horas" ? 76 : 40,
                    height: 30,
                }}
            >
                {data.value == "horas" ? horasExtra : data.texto}
            </div>
        );
    }

    dxButtonGroup_horasExtra_onItemClick(e) {
        let { value } = e.itemData;
        let { dataFieldSel_horasExtras, horasExtra, dxDataGrid_jornadaPersona_rowData } = this.state;
        let turnoSel = this.getTurnoPersona(dxDataGrid_jornadaPersona_rowData);

        let isDiaActual =
            turnoSel[dataFieldSel_horasExtras].getHours() - horasExtra > 0 &&
            turnoSel[dataFieldSel_horasExtras].getHours() + horasExtra < 24;
        if ((value == "más" && isDiaActual) || (value == "menos" && horasExtra > 0)) {
            if (value == "más") horasExtra += 1;
            else if (value == "menos") horasExtra -= 1;

            this.setState({ horasExtra: horasExtra });

            if (value != "horas") e.component.repaint();
        } else if (value == "más") {
            notify({
                message: "No se pueden añadir más horas.",
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }

        setTimeout(() => {
            e.component.option("selectedItemKeys", null);
        }, 120);
    }

    dxPopup_horasExtra_onContentReady(e) {
        e.component.content().attr("id", "dxPopup_horasExtra_jornadaPersona");
    }

    dxPopup_horasExtra_onHiding(e) {
        this.setState({
            isVisible_dxPopup_horasExtra: false,
            selectedCell_gridJornada: null,
        });
    }

    dxPopup_horasExtra_onHidden(e) {
        this.setState(
            {
                horasExtra: 0,
                dataFieldSel_horasExtras: null,
                dxDataGrid_jornadaPersona_rowData: null,
            },
            () => {
                this.dxButtonGroup_horasExtra.repaint();
            }
        );
    }

    dxPopover_cambioHorasTrabajo_onHiding(e) {
        this.setState({ isVisible_dxPopover_CambioHorasTrabajo: false });
    }

    dxButton_dxPopover_cambioHorasTrabajo_cancelar_onClick(e) {
        let _this = this;
        this.setState({ isVisible_dxPopover_CambioHorasTrabajo: false }, () => {
            _this.dxDataGrid_tblJornadaPersona.cancelEditData();
        });
    }

    dxButton_dxPopover_cambioHorasTrabajo_aceptar_onClick(e) {
        let editData = this.dxDataGrid_tblJornadaPersona.getController("editing")._editData[0];
        let rowData = editData.oldData;
        $.each(editData.data, function (index, item) {
            rowData[index] = item;
        });

        let rowIndex = this.dxDataGrid_tblJornadaPersona.getRowIndexByKey(editData.key);
        this.dxDataGrid_tblJornadaPersona.cellValue(
            rowIndex,
            "tiempoTipoTrabajo1",
            this.set_time_fechaSel(new Date("0"))
        );
        this.dxDataGrid_tblJornadaPersona.cellValue(
            rowIndex,
            "tiempoTipoTrabajo2",
            this.set_time_fechaSel(new Date("0"))
        );
        this.dxDataGrid_tblJornadaPersona.cellValue(
            rowIndex,
            "tiempoTipoTrabajo3",
            this.set_time_fechaSel(new Date("0"))
        );
        this.dxDataGrid_tblJornadaPersona.cellValue(
            rowIndex,
            "tiempoTipoTrabajo4",
            this.set_time_fechaSel(new Date("0"))
        );
        this.dxDataGrid_tblJornadaPersona.cellValue(
            rowIndex,
            "tiempoTipoTrabajo5",
            this.set_time_fechaSel(new Date("0"))
        );
        this.dxDataGrid_tblJornadaPersona.cellValue(
            rowIndex,
            "tiempoTipoTrabajo6",
            this.set_time_fechaSel(new Date("0"))
        );

        this.setState({ isVisible_dxPopover_CambioHorasTrabajo: false });
    }

    dxButton_popupHorasExtra_añadir_onClick(e) {
        let { idPersonaSel, fechaSel, dataFieldSel_horasExtras, dxDataGrid_jornadaPersona_rowData } = this.state;
        let { horasExtra } = this.state;

        let turnoSel = this.getTurnoPersona(dxDataGrid_jornadaPersona_rowData);
        let newValue =
            dataFieldSel_horasExtras == "horaEntrada"
                ? subtractHours(horasExtra, this.set_time_fechaSel(new Date(turnoSel[dataFieldSel_horasExtras])))
                : addHours(horasExtra, this.set_time_fechaSel(new Date(turnoSel[dataFieldSel_horasExtras])));

        if (turnoSel[dataFieldSel_horasExtras].getHours() - horasExtra >= 0) {
            let objPatch = {};

            if (dataFieldSel_horasExtras == "horaSalida" && newValue.getHours() == 0) {
                newValue.setHours(23);
                newValue.setMinutes(59);
            }

            let rowIndex = this.dxDataGrid_tblJornadaPersona.getRowIndexByKey({
                idPersona: idPersonaSel,
                fecha: formatDate_noTime_parameter(fechaSel),
            });
            this.dxDataGrid_tblJornadaPersona.cellValue(rowIndex, dataFieldSel_horasExtras, newValue);
            let isValido =
                !this.get_hasInfoFaltante(dxDataGrid_jornadaPersona_rowData) &&
                this.get_accionesRequeridas(dxDataGrid_jornadaPersona_rowData).length == 0;

            objPatch[dataFieldSel_horasExtras] = formatTime_parameter(newValue);
            objPatch.isRegValido = isValido;
            let _this = this;

            _this.setState({ isVisible_dxPopup_horasExtra: false });
        } else {
            notify({
                message: this.getTrad("horasExtraExcedenLimiteDia"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }

    dxButton_popupHorasExtra_noAñadir_onClick(e) {
        let { idPersonaSel, fechaSel, dataFieldSel_horasExtras, dxDataGrid_jornadaPersona_rowData } = this.state;

        let objPatch = {};
        let isValido =
            !this.get_hasInfoFaltante(dxDataGrid_jornadaPersona_rowData) &&
            this.get_accionesRequeridas(dxDataGrid_jornadaPersona_rowData).length == 0;

        let _this = this;
        let turnoSel = this.getTurnoPersona(dxDataGrid_jornadaPersona_rowData);

        let rowIndex = this.dxDataGrid_tblJornadaPersona.getRowIndexByKey({
            idPersona: idPersonaSel,
            fecha: formatDate_noTime_parameter(fechaSel),
        });
        this.dxDataGrid_tblJornadaPersona.cellValue(
            rowIndex,
            dataFieldSel_horasExtras,
            turnoSel[dataFieldSel_horasExtras]
        );

        objPatch[dataFieldSel_horasExtras] = formatTime_parameter(turnoSel[dataFieldSel_horasExtras]);
        objPatch.isRegValido = isValido;

        _this.setState({ isVisible_dxPopup_horasExtra: false });
    }

    dxPopup_añadirPersona_onContentReady(e) {
        e.component.content().attr("id", "dxPopup_añadirPersona");
    }

    dxPopup_añadirPersona_onHiding(e) {
        this.dxList_personas.option("searchValue", "");
        this.setState({
            isVisible_dxPopup_añadirPersona: false,
            idCategoriaSel: null,
            dxList_tblPersonas_selectedItems: [],
        });
    }

    dxSelectBox_idCategoria_onValueChanged(e) {
        this.setState({
            idCategoriaSel: e.value,
        });
    }

    dxList_tblPersonas_onOptionChanged(e) {
        if (e.name === "selectedItems") {
            let { dxList_tblPersonas_selectedItems } = this.state;
            if (!dxList_tblPersonas_selectedItems.includes(e.value)) {
                this.setState({ dxList_tblPersonas_selectedItems: e.value });
            }
        }
    }

    dxList_tblPersonas_onItemRendered(e) {
        if (e.itemData.is_diasCalLaboral) {
            let _this = this;
            e.itemElement.removeClass("dx-state-disabled");
            e.itemElement.addClass("custom-disabled");
            e.itemElement.parent().append(
                $("<div />").dxTooltip({
                    height: "42px",
                    showEvent: "dxhoverstart",
                    hideEvent: "dxhoverend",
                    position: "right",
                    contentTemplate: function (contentElement) {
                        contentElement.html(_this.getTrad("tooltip_conflictoCal"));
                    },
                    target: e.itemElement,
                    animation: {
                        show: {
                            type: "pop",
                            from: {
                                scale: 0.1,
                                opacity: 0,
                            },
                            to: {
                                opacity: 1,
                                scale: 1,
                            },
                        },
                        hide: {
                            type: "pop",
                            from: {
                                scale: 1,
                                opacity: 1,
                            },
                            to: {
                                opacity: 0,
                                scale: 0.1,
                            },
                        },
                    },
                })
            );
        }
    }

    dxButton_popupAñadirPersona_cancelar_onClick(e) {
        this.dxList_personas.option("searchValue", "");
        this.setState({
            isVisible_dxPopup_añadirPersona: false,
            idCategoriaSel: null,
            dxList_tblPersonas_selectedItems: [],
        });
    }

    dxPopup_titleBar_elementAttr = {
        id: "dxPopup_titleBar",
    };

    /**
     * MÉTODOS UTILIZADOS POR EL POPUP DE EVENTOS DE PERSONA DESHABILITADO
     * AL IMPLEMENTAR CUADRANTES DE PERSONAL
     */
    // onContentReady_dxPopup_eventosPersona(e)
    // {
    //     var html = e.component.content();
    //     $(html).css("padding-top", "12px");
    // }

    // onHiding_dxPopup_eventosPersona(e)
    // {
    //     this.setState({isVisible_dxPopup_eventosPersona: false,isNoJornada_dxPopup_eventosPersona:false, dxList_estadosPersona_dataSource:[], idPersonaSel:null, dxList_estadosPersona_selectedItem:null});
    // }

    // onHidden_dxPopup_eventosPersona(e)
    // {
    //     this.setState({dxList_estadosPersona_dataSource:[], idPersonaSel:null, idEstadoSel_ausencia:null, dxList_estadosPersona_selectedItem:null});
    // }

    // dxListEstados_itemRender(e)
    // {
    //     if(e.subText != null)
    //     {
    //         return <Fragment>
    //         <div>
    //             {this.getTrad(e.traduccion)}
    //         </div>
    //         <div style={{fontSize:"12px"}}>
    //             {e.subText}
    //         </div>
    //         </Fragment>
    //     }
    //     else
    //         return this.getTrad(e.traduccion);
    // }

    // dxListEstados_onItemDeleting(e)
    // {
    //     let _this = this;
    //     dxMensajePregunta
    //     (
    //         getTrad('preg_RegistroEliminarCorto')
    //         , [
    //             // ACEPTAR
    //             [
    //                 getTrad('aceptar'),
    //                 function () {
    //                     let dataSource = e.itemData.idEstado == 3 ? _this.datasource_tblJornadaPersona : _this.datasource_tblCalendarioPersonal;
    //                     let {dxList_estadosPersona_dataSource, idPersonaSel} = _this.state;
    //                     let filterDataSource = $.grep(dxList_estadosPersona_dataSource,function(item){ return item.idEstado != e.itemData.idEstado;});

    //                     dataSource.store().remove({}).done(function(){
    //                        _this.setState({dxList_estadosPersona_dataSource: filterDataSource, idPersonaSel:idPersonaSel});
    //                     });
    //                 },
    //                 "normal",
    //                 "btnAceptar"
    //             ],
    //             // CANCELAR
    //             [getTrad('cancelar'),
    //             function () {
    //             },
    //                 "normal",
    //                 "btnCancelar"
    //             ]
    //         ]
    //     );
    //     e.cancel = true;
    // }

    // dxListEstados_onOptionChanged(e)
    // {
    //     let {dxList_estadosPersona_selectedItem, idPersonaSel } = this.state;
    //     if(e.name == "selectedItemKeys" && e.value.length > 0 && e.value[0] != dxList_estadosPersona_selectedItem && idPersonaSel != null)
    //     {
    //         let _this = this;
    //         this.state.idEstadoSel_ausencia = e.value[0];

    //          _this.setState({
    //                 // isVisible_dxPopup_eventosPersona:false,
    //                 isLoading_dxDataGrid_tblJornadaPersona:true
    //         })
    //         this.context_calendarioPersonal.invoke("fn_IU_tblCalendarioPersonal",{}, "POST").done(function (result) {
    //             _this.setState({
    //                 isNoJornada_dxPopup_eventosPersona:false,
    //                 dxList_estadosPersona_dataSource:[],
    //                 idPersonaSel:null
    //             });
    //             _this.dxDataGrids_reload();
    //         });
    //     }
    // }

    dxPopup_datosJornada_onContentReady(e) {
        e.component.content().attr("id", "dxPopup_datosJornada");
    }

    dxPopup_datosJornada_onShown(e) {
        $.each($("#dxPopup_datosJornada").find(".dx-texteditor-input"), function (index, item) {
            $(item).off("dxmousewheel");
        });
    }

    dxPopup_datosJornada_onHiding(e) {
        this.setState({
            isVisible_dxPopup_datosJornada: false,
            dxList_tblPersonas_selectedItems: [],
            formData_addPersona: null,
            idCategoriaSel: null,
            idCategoriaSel_importarPersona: null,
            idCategoriasInternasSel_importarPersona: null,
            isVisible_dxPopup_añadirPersona: false,
        });
    }

    dxButton_popupDatosJornada_guardar_onClick(e) {
        function postMassivo(_this) {
            _this.loadPanel_show(true);

            _this.dxList_lavanderias.option("searchValue", "");
            _this.dxList_personaNLavanderia.option("searchValue", "");
            _this.setState({
                isVisible_dxPopup_datosJornada: false,
                isVisible_dxPopup_importarPersona: false,
                isVisible_dxPopup_añadirPersona: false,
                idCategoriaSel_importarPersona: null,
                idCategoriasInternasSel_importarPersona: null,
                idCategoriaSel: null,
                is_savingData: true,
            });
            _this.datasource_tblPersonas_gridJornada
                .store()
                .insert(_this.tratarDatos_dxForm_tblJornadaPersona())
                .done(function () {
                    _this.state.dxList_tblPersonas_selectedItems = [];
                    _this.state.dxList_importarPersonas_personaNLavanderia_selectedItem = [];
                    _this.dxDataGrids_reload();
                });
        }

        let _this = this;
        let { dxList_tblPersonas_selectedItems } = this.state;
        if (this.tratarDatos_dxForm_tblJornadaPersona()) {
            var idsPersona_diasCalLaboral = [];

            var errorText = "<div id='mensajeError'>" + _this.getTrad("alert_conflictoCal1") + "<br /><br />";
            $.each(dxList_tblPersonas_selectedItems, function (index, item) {
                if (item.is_diasCalLaboral) {
                    errorText += "- " + item.nombreCompuesto + "<br />";
                    idsPersona_diasCalLaboral.push(item.idPersona);
                }
            });
            errorText += "<br />" + _this.getTrad("alert_conflictoCal2");

            if (idsPersona_diasCalLaboral.length > 0) {
                dxMensajePregunta(errorText, [
                    // ACEPTAR
                    [
                        _this.getTrad("aceptar"),
                        function () {
                            postMassivo(_this);
                        },
                        "normal",
                        "btnAceptar",
                    ],
                    // CANCELAR
                    [_this.getTrad("cancelar"), function () {}, "normal", "btnCancelar"],
                ]);
            } else postMassivo(_this);
        } else {
            notify({
                message: _this.getTrad("aviso_I_TiempoSeleccionadoSuperior"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }

    dxSelectBox_formAddPersona_onValueChanged(e) {
        let turno = e.component.option("selectedItem");
        this.setState({
            formData_addPersona: {
                idTurno: e.value,
                horaEntrada: turno.horaEntrada,
                horaSalida: turno.horaSalida,
                tiempoDescanso: turno.tiempoDescanso,
            },
        });
    }

    dxDateBox_formAddPersona_horaEntrada_onValueChanged(e) {
        let formData_addPersona = { ...this.state.formData_addPersona };
        formData_addPersona.horaEntrada = e.value;
        this.setState({
            formData_addPersona: formData_addPersona,
        });
    }

    dxDateBox_formAddPersona_horaSalida_onValueChanged(e) {
        let formData_addPersona = { ...this.state.formData_addPersona };
        formData_addPersona.horaSalida = e.value;
        this.setState({
            formData_addPersona: formData_addPersona,
        });
    }

    dxDateBox_formAddPersona_tiempoDescanso_onValueChanged(e) {
        let formData_addPersona = { ...this.state.formData_addPersona };
        formData_addPersona.tiempoDescanso = e.value ? e.value : this.set_time_fechaSel(new Date(1900, 1, 1, 0));
        this.setState({
            formData_addPersona: formData_addPersona,
        });
    }

    dxPopup_importarPersona_onHiding(e) {
        this.dxList_lavanderias.option("searchValue", "");
        this.dxList_personaNLavanderia.option("searchValue", "");
        this.setState({
            isVisible_dxPopup_importarPersona: false,
            dxList_tblLavanderia_selectedItem: [],
            dxList_importarPersonas_personaNLavanderia_selectedItem: [],
            idCategoriaSel_importarPersona: null,
            idCategoriasInternasSel_importarPersona: null,
            isOpened_dropDownBox_filtroCategoriasInternas: false,
        });
    }

    dxButton_popupImportarPersona_importar_onClick(e) {
        let { tblTurnos_items, idTurnoSel, idLavanderiaSel } = this.state;

        let turnoActual = $.grep(tblTurnos_items, function (item) {
            return item.idTurno == idTurnoSel;
        });
        let hasTurnos = turnoActual.length > 0;

        let turnosLavActual = $.grep($.extend(true, [], tblTurnos_items), function (item) {
            return item.idLavanderia == idLavanderiaSel;
        });

        let formData_addPersona = {
            idTurno: hasTurnos && idTurnoSel != null ? turnoActual[0].idTurno : turnosLavActual[0].idTurno,
            horaEntrada: hasTurnos && idTurnoSel != null ? turnoActual[0].idTurno : turnosLavActual[0].horaEntrada,
            horaSalida: hasTurnos && idTurnoSel != null ? turnoActual[0].idTurno : turnosLavActual[0].horaSalida,
            tiempoDescanso:
                hasTurnos && idTurnoSel != null ? turnoActual[0].idTurno : turnosLavActual[0].tiempoDescanso,
            isRegValido: true,
        };
        this.setState({
            isVisible_dxPopup_datosJornada: true,
            dxList_tblLavanderia_selectedItem: [],
            formData_addPersona: formData_addPersona,
        });
    }

    dxList_tblLavanderia_onOptionChanged(e) {
        if (e.name === "selectedItems") {
            let { dxList_tblLavanderia_selectedItem } = this.state;
            if (!dxList_tblLavanderia_selectedItem.includes(e.value)) {
                this.setState({ dxList_tblLavanderia_selectedItem: e.value }, () => {
                    this.datasource_personaNLavanderia.reload();
                });
            }
        }
    }

    dxSelectBox_importarPersonas_idCategoria_onValueChanged(e) {
        this.setState(
            {
                idCategoriaSel_importarPersona: e.value,
            },
            () => {
                this.datasource_personaNLavanderia.reload();
            }
        );
    }

    DropDownBox_filtroCategoriasInternas_onValueChanged(values) {
        this.setState(
            {
                idCategoriasInternasSel_importarPersona: values,
            },
            () => {
                this.datasource_personaNLavanderia.reload();
            }
        );
    }

    dxList_importarPersonas_personaNLavanderia_onContentReady(e) {
        e.component.unselectAll();
    }

    dxList_importarPersonas_personaNLavanderia_onOptionChanged(e) {
        if (e.name === "selectedItems") {
            let { dxList_importarPersonas_personaNLavanderia_selectedItem } = this.state;
            if (!dxList_importarPersonas_personaNLavanderia_selectedItem.includes(e.value)) {
                this.setState({
                    dxList_importarPersonas_personaNLavanderia_selectedItem: e.value,
                });
            }
        }
    }

    dxPopover_onContentReady(args) {
        args.component.content().attr("id", "dxPopover");
        var html = args.component.content();
        $(html).css("padding", "12px");
    }

    dxTooltip_onContentReady(args) {
        args.component.content().attr("id", "warningTooltip");
    }

    filterTurnos(turnos) {
        turnos = $.each(turnos, function (index, item) {
            item.visible = true;
            if (
                item.idTurnoPadre != null ||
                ((!item.activo || item.eliminado) && item.tblJornadaPersona && item.tblJornadaPersona.length == 0)
            ) {
                item.visible = false;
            }
        });
        return turnos;
    }

    signalR_reconnect() {
        let _this = this;
        let { fechaSel } = this.state;

        signalRCoreService.leaveAllGroups();
        _this.datasource_tblLavanderia.reload().done(function (items) {
            $.each(items, function (index, item) {
                signalRCoreService.joinGroup(
                    "JornadaPersona_" + item.idLavanderia + "_" + formatDate_noTime_parameter(fechaSel)
                );
            });
        });

        signalRCoreService.setEvents([
            {
                type: "JornadaPersona/signalR_refresh",
                listener: function (registrosActualizados) {
                    let { is_savingData } = _this.state;
                    if (!is_savingData) {
                        $.when().then(function () {
                            //Sino no entra en el segundo $.when
                            let idsPersonaActualizados = $.map(registrosActualizados, function (item) {
                                return item.idPersona;
                            });
                            _this.dxDataGrids_reload(idsPersonaActualizados);
                        });
                    } else _this.state.is_savingData = false;
                },
            },
        ]);
    }

    cargaDatos_lavanderia() {
        let { lavanderia } = this.props;
        let _this = this;

        this.loadPanel_show();
        $("#dxScrollView_JornadaPersonaGrids").dxScrollView("instance").scrollTo(0);

        $.when(this.datasource_tblTurno.reload(), this.datasource_tblLavanderia.reload()).then(function (
            tblTurno_items
        ) {
            let turnosSubturnos = $.extend(true, [], tblTurno_items[0]);
            let turnos = _this.filterTurnos(tblTurno_items[0]);

            _this.setState({
                is_loadingLavanderia: true,
                fechaSel: convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date()),
                tblTurnos_items: turnos,
                tblTurnos_subTurnos_items: turnosSubturnos,
            });
        });
    }

    cargaDatos_fecha() {
        if (!this.state.is_loadingLavanderia) this.setState({ isLoading_white_dxDataGrid_tblJornadaPersona: true });

        $("#dxScrollView_JornadaPersonaGrids").dxScrollView("instance").scrollTo(0);

        this.dxDataGrids_reload();
    }

    componentDidUpdate(prevProps, prevState) {
        let { fechaSel } = this.state;

        if (fechaSel != prevState.fechaSel) {
            this.cargaDatos_fecha(fechaSel);
        }
    }

    componentDidMount() {
        let _this = this;
        this.signalR_reconnect();

        let { user } = this.props;
        this.datasource_tblTurno.reload().done(function (tblTurno_items) {
            let turnosSubturnos = $.extend(true, [], tblTurno_items);
            let turnos = _this.filterTurnos(tblTurno_items);

            $.when(
                _this.datasource_tblLavanderia.reload(),
                _this.datasource_tblCategoria.reload(),
                _this.datasource_tblPersonas_gridJornada.reload(),
                _this.datasource_datos_tblCalendarioPersonal_Estado.reload()
            ).then(function (lavanderias, tblCategoria_items, gridJornada, tblCalendarioEstado) {
                let jornada = $.grep(gridJornada[0], function (item) {
                    return item.hasJornadaPersona || (!item.hasJornadaPersona && item.tblCalendarioPersonal == null);
                });
                let ausencias = $.grep(gridJornada[0], function (item) {
                    return item.tblCalendarioPersonal && !item.hasJornadaPersona;
                });

                _this.setState(
                    {
                        tblTurnos_items: turnos,
                        tblTurnos_subTurnos_items: turnosSubturnos,
                        tblCategoria_items: tblCategoria_items[0],
                        tblCalendarioEstado_items: tblCalendarioEstado[0],
                        items_gridJornada: new ArrayStore({
                            key: ["idPersona", "fecha"],
                            data: jornada,
                        }),
                        items_gridAusencias: new ArrayStore({
                            key: ["idPersona", "fecha"],
                            data: ausencias,
                        }),
                    },
                    () => {
                        setTimeout(() => {
                            _this.loadPanel_hide();
                        }, 100);
                    }
                );
            });
        });
    }

    componentWillUnmount() {
        //#region SignalR
        signalRCoreService.leaveAllGroups();
        //#endregion
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JornadaPersonaReact);
