import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  durationToDatetime,
  leadingZero,
  convertClienteUtcToLavanderiaUtc,
  datetimeToDuration,
  formatNumber,
  dxMensajePregunta,
  formatTime_parameter,
} from "../../../helpers";
import { svg_remove } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import notify from "devextreme/ui/notify";
import { keyConverters, EdmLiteral } from "devextreme/data/odata/utils";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var turnoSel,
    fechaSel = convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date()),
    categoriaSel = null,
    jornadaPersonaSel = { personasSel: [] },
    totalDiario = {},
    idLavSel = -1,
    categoriaSel_importar = null,
    personasImportadas = [];

  keyConverters.DateTime = function (value) {
    return new EdmLiteral(formatDate_parameter(value));
  };

  //#region DATASOURCES
  var datasource_tblTurno = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTurno",
      key: "idTurno",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "horaEntrada",
    filter: ["activo eq true and eliminado eq false"],
    postProcess: function (data) {
      $.each(data, function (index, item) {
        item.horaEntrada = durationToDatetime(item.horaEntrada);
        item.horaSalida = durationToDatetime(item.horaSalida);
        item.tiempoDescanso = durationToDatetime(item.descanso);
        item.text = item.denominacion;
      });
      return data;
    },
  });

  var datasource_tblPersonas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      onLoading: function (loadOptions) {
        var filtroTurno = [
          ["idTurno", "=", turnoSel.idTurno],
          "or",
          ["tblTurno/idTurnoPadre", "=", turnoSel.idTurno],
        ];

        var filtroCategoria,
          filtroLavanderia = "idLavanderia eq " + LAVANDERIA.idLavanderia;

        var filtroFecha;
        if (fechaSel)
          filtroFecha =
            "tblPersonaNTipoContrato/any(x: x/fechaAltaContrato le " +
            formatDate_parameter(fechaSel) +
            " and x/fechaBajaContrato ge " +
            formatDate_parameter(fechaSel) +
            ")";

        //Filtro por fecha
        if (categoriaSel) {
          filtroCategoria = ["idCategoria", "=", categoriaSel.idCategoria];
        } else {
          filtroCategoria = [
            [
              "tblCategoria/tblCategoria_Grupo/any(j :  j/idCategoria_Grupo eq 1)",
            ],
            "or",
            ["tblCategoriaInterna/tblCategoriaConvenio/isOficina eq false"],
          ];
        }
        if (!loadOptions.filter || loadOptions.filter.length === 0) {
          loadOptions.filter = [
            [filtroTurno],
            "and",
            [filtroCategoria],
            "and",
            [filtroLavanderia],
            "and",
            [[filtroFecha], "or", ["activo eq true"]],
          ];
        } else if (loadOptions.filter[0][0] !== "idPersona") {
          loadOptions.filter = [
            [
              "concat(concat(nombre, ' '), apellidos)",
              "contains",
              loadOptions.filter[0][2],
            ],
            "and",
            [filtroTurno],
            "and",
            [filtroCategoria],
            "and",
            [filtroLavanderia],
            "and",
            [[filtroFecha], "or", ["activo eq true"]],
          ];
        }
        loadOptions.expand =
          "tblJornadaPersona($filter = date(fecha) eq " +
          formatDate_parameter(fechaSel) +
          "; $select = idPersona),tblCalendarioPersonal($filter= fecha eq " +
          formatDate_parameter(fechaSel) +
          ";$select=fecha)";
      },
      version: 4,
    }),
    select: [
      "idPersona",
      "nombre",
      "apellidos",
      "idTipoTrabajo",
      "idTurno",
      "horasDiarias",
    ],
    sort: "nombre",
    map: function (itemData) {
      return {
        idPersona: itemData.idPersona,
        nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
        idTipoTrabajo: itemData.idTipoTrabajo,
        idTurno: itemData.idTurno,
        tblJornadaPersona: itemData.tblJornadaPersona,
        is_diasCalLaboral:
          itemData.tblCalendarioPersonal.length > 0 ? true : false,
        horasDiarias: durationToDatetime(itemData.horasDiarias),
      };
    },
    postProcess: function (data) {
      $.merge(data, personasImportadas);
      let idsPersona_jornadaPersona = datasource_tblJornadaPersona
        .items()
        .map(function (item) {
          return item.idPersona;
        });
      return $.grep(data, function (jornadaPersona) {
        return (
          jornadaPersona.tblJornadaPersona.length === 0 &&
          $.inArray(jornadaPersona.idPersona, idsPersona_jornadaPersona) === -1
        );
      });
    },
  });

  var datasource_tblCategoria = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCategoria",
      key: "idCategoria",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    filter: "tblCategoria_Grupo/any(j : j/idCategoria_Grupo eq 1)", //Grupo de Jornada Persona
  });

  var context_tblJornadaPersona = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblJornadaPersona",
    entities: {
      GetTotalDiario: {},
      PostMasivo: {},
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };

      if (request.method === "get") {
        //GetTotalDiario
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.fecha = formatDate_parameter(fechaSel);
      } else {
        //PostMasivo
        request.payload = tratarDatos_dxForm_tblJornadaPersona();
      }
    },
  });

  function GetTotalDiario() {
    var _deferred = $.Deferred();

    context_tblJornadaPersona
      .invoke("GetTotalDiario", {}, "GET")
      .done(function (data) {
        totalDiario = data[0];
        if ($("#dxForm_totales").length > 0) {
          var dxForm_totales = $("#dxForm_totales").dxForm("instance");

          dxForm_totales.beginUpdate();
          dxForm_totales.updateData(totalDiario);
          dxForm_totales.repaint();
          dxForm_totales.endUpdate();
        }

        _deferred.resolve();
      });

    return _deferred.promise();
  }

  var datasource_tblJornadaPersona = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblJornadaPersona",
      key: ["idPersona", "fecha"],
      keyType: {
        idPersona: "Int32",
        fecha: "DateTime",
      },
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (request.method === "get") {
          request.params.idLavanderia = LAVANDERIA.idLavanderia;
        }
      },
      onLoading: function (loadOptions) {
        loadOptions.filter = [
          [
            ["idTurno", "=", turnoSel.idTurno],
            "or",
            ["tblTurno/idTurnoPadre", "=", turnoSel.idTurno],
          ],
          "and",
          ["date(fecha) eq " + formatDate_parameter(fechaSel)],
        ];
      },
      onLoaded: function (data) {
        $.each(data, function (index, item) {
          item.horaEntrada = durationToDatetime(item.horaEntrada);
          item.horaSalida = durationToDatetime(item.horaSalida);
          item.tiempoDescanso = durationToDatetime(item.tiempoDescanso);
          item.tiempoTipoTrabajo1 = durationToDatetime(item.tiempoTipoTrabajo1);
          item.tiempoTipoTrabajo2 = durationToDatetime(item.tiempoTipoTrabajo2);
          item.tiempoTipoTrabajo3 = durationToDatetime(item.tiempoTipoTrabajo3);
          item.tiempoTipoTrabajo4 = durationToDatetime(item.tiempoTipoTrabajo4);
          item.tiempoTipoTrabajo5 = durationToDatetime(item.tiempoTipoTrabajo5);
          item.tiempoTipoTrabajo6 = durationToDatetime(item.tiempoTipoTrabajo6);

          item.tblPersona.nombreCompuesto =
            item.tblPersona.nombre + " " + item.tblPersona.apellidos;
        });

        return data;
      },
      onUpdating: function (key, values) {
        $("#dxDataGrid_tblJornadaPersona").dxDataGrid("instance").beginUpdate();
        $.each(values, function (field, value) {
          if (field !== "fecha" && value instanceof Date) {
            values[field] = datetimeToDuration(value);
          }
        });
      },
      onUpdated: function (key, values) {
        var array_field = [
          "horaEntrada",
          "horaSalida",
          "tiempoDescanso",
          "tiempoTipoTrabajo1",
          "tiempoTipoTrabajo2",
          "tiempoTipoTrabajo3",
          "tiempoTipoTrabajo4",
          "tiempoTipoTrabajo5",
          "tiempoTipoTrabajo6",
        ];

        $.each(values, function (field, value) {
          if ($.inArray(field, array_field) !== -1) {
            values[field] = durationToDatetime(value);
          }
        });
        $("#dxDataGrid_tblJornadaPersona").dxDataGrid("instance").endUpdate();
      },
      version: 4,
    }),
    expand: ["tblPersona($select=idTipoTrabajo,nombre,apellidos)"],
  });

  var datasource_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      onLoading: function (loadOptions) {
        let { idPais } = LAVANDERIA;
        loadOptions.filter = ["idPais eq " + idPais];
      },
      version: 4,
    }),
    sort: ["denominacion"],
    postProcess: function (data) {
      return $.grep(data, function (lavanderia) {
        return (
          lavanderia.idLavanderia !== LAVANDERIA.idLavanderia &&
          lavanderia.idCorporacion === LAVANDERIA.idCorporacion
        );
      });
    },
  });

  var datasource_personaNLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = false;
      },
      onLoading: function (loadOptions) {
        var filtroCategoria,
          filtroLavanderia = "idLavanderia eq " + idLavSel;

        if (categoriaSel_importar) {
          filtroCategoria = [
            "idCategoria",
            "=",
            categoriaSel_importar.idCategoria,
          ];
        } else filtroCategoria = "idCategoria ne -1";

        if (!loadOptions.filter || loadOptions.filter.length === 0) {
          loadOptions.filter = [[filtroCategoria], "and", [filtroLavanderia]];
        } else if (loadOptions.filter[0][0] !== "idPersona") {
          loadOptions.filter = [
            [
              "concat(concat(nombre, ' '), apellidos)",
              "contains",
              loadOptions.filter[0][2],
            ],
            "and",
            [filtroCategoria],
            "and",
            [filtroLavanderia],
          ];
        }
        loadOptions.expand =
          "tblJornadaPersona($filter = date(fecha) eq " +
          formatDate_parameter(fechaSel) +
          " and idLavanderia eq " +
          LAVANDERIA.idLavanderia +
          "; $select = idPersona)";
      },
      version: 4,
    }),
    sort: "nombre",
    map: function (itemData) {
      return {
        idPersona: itemData.idPersona,
        nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
        idTipoTrabajo: itemData.idTipoTrabajo,
        tblJornadaPersona: itemData.tblJornadaPersona,
      };
    },
    postProcess: function (data) {
      let dxList_personas_idsPersona = $("#dxList_personas")
        .dxList("instance")
        .option("items")
        .map(function (item) {
          return item.idPersona;
        });
      return $.grep(data, function (jornadaPersona) {
        return (
          jornadaPersona.tblJornadaPersona.length === 0 &&
          $.inArray(jornadaPersona.idPersona, dxList_personas_idsPersona) === -1
        );
      });
    },
  });

  //#endregion

  //#region Controles

  $.when(
    GetTotalDiario(),
    datasource_tblTurno.load(),
    datasource_tblCategoria.load(),
    datasource_tblLavanderia.load(),
  )
    .then(function () {
      let turnosSubturnos = $.extend(true, [], datasource_tblTurno.items());
      let turnos = filterTurnos(datasource_tblTurno.items());
      let turnosVisible = $.grep(turnos, function (item) {
        return item.visible;
      });
      turnoSel = turnosVisible.length > 0 ? turnosVisible[0] : [];

      datasource_tblPersonas.load().done(function () {
        $("#JornadaPersona #dxContainer").dxBox({
          direction: "row",
          align: "space-around",
          crossAlign: "stretch",
          height: "100%",
          items: [
            {
              baseSize: 280,
              shrink: 0,
              ratio: 0,
              template: function (e, index, item) {
                item.append(
                  $("<div />").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        //Calendar
                        baseSize: 280,
                        shrink: 0,
                        ratio: 0,
                        template: function (e, index, item) {
                          item.append(
                            $("<div/>").dxCalendar({
                              max: convertClienteUtcToLavanderiaUtc(
                                LAVANDERIA.GMT,
                                new Date(),
                              ),
                              value: fechaSel,
                              onValueChanged: function (e) {
                                fechaSel = e.value;
                                $("#dxDataGrid_tblJornadaPersona")
                                  .dxDataGrid("instance")
                                  .cancelEditData();
                                GetTotalDiario();
                                datasource_tblJornadaPersona
                                  .reload()
                                  .done(function () {
                                    datasource_tblPersonas.load();
                                  });
                              },
                            }),
                          );
                        },
                      },
                      {
                        //Calendar
                        baseSize: 35,
                        shrink: 0,
                        ratio: 0,
                        template: function (e, index, item) {
                          item.append(
                            $("<div/>").dxSelectBox({
                              items: datasource_tblCategoria.items(),
                              value: categoriaSel,
                              valueExpr: "idCategoria",
                              displayExpr: "denominacion",
                              placeholder: getTrad("seleccioneCategoria"),
                              showClearButton: true,
                              width: "100%",
                              onValueChanged: function (e) {
                                categoriaSel =
                                  e.component.option("selectedItem");
                                datasource_tblPersonas.load();
                              },
                            }),
                          );
                        },
                      },
                      {
                        //Lista personas
                        ratio: 1,
                        template: function (e, index, item) {
                          item.css("padding-top", "10px");
                          item.append(
                            $("<div />").dxBox({
                              direction: "col",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              items: [
                                {
                                  ratio: 1,
                                  template: function (e, index, item) {
                                    item.append(
                                      $("<div id='dxList_personas' />").dxList({
                                        //Datos
                                        dataSource: datasource_tblPersonas,
                                        height: "100%",
                                        scrolling: { mode: "infinite" },
                                        pageLoadMode: "scrollBottom",
                                        displayExpr: "nombreCompuesto",
                                        searchEnabled: true,
                                        selectionMode: "all",
                                        showSelectionControls: true,
                                        onContentReady: function (e) {
                                          e.component.unselectAll();
                                        },
                                        onItemRendered: function (e) {
                                          if (e.itemData.is_diasCalLaboral) {
                                            e.itemElement.removeClass(
                                              "dx-state-disabled",
                                            );
                                            e.itemElement.addClass(
                                              "custom-disabled",
                                            );
                                            e.itemElement.parent().append(
                                              $("<div />").dxTooltip({
                                                height: "42px",
                                                showEvent: "dxhoverstart",
                                                hideEvent: "dxhoverend",
                                                position: "right",
                                                contentTemplate: function (
                                                  contentElement,
                                                ) {
                                                  contentElement.html(
                                                    getTrad(
                                                      "tooltip_conflictoCal",
                                                    ),
                                                  );
                                                },
                                                target: e.itemElement,
                                                animation: {
                                                  show: {
                                                    type: "pop",
                                                    from: {
                                                      scale: 0.1,
                                                      opacity: 0,
                                                    },
                                                    to: {
                                                      opacity: 1,
                                                      scale: 1,
                                                    },
                                                  },
                                                  hide: {
                                                    type: "pop",
                                                    from: {
                                                      scale: 1,
                                                      opacity: 1,
                                                    },
                                                    to: {
                                                      opacity: 0,
                                                      scale: 0.1,
                                                    },
                                                  },
                                                },
                                              }),
                                            );
                                          }
                                        },
                                        onSelectionChanged: function (e) {
                                          var haySeleccion =
                                            e.component.option(
                                              "selectedItemKeys",
                                            ).length > 0;
                                          $("#dxButton_addJornada")
                                            .dxButton("instance")
                                            .option("disabled", !haySeleccion);
                                        },
                                      }),
                                    );
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                      {
                        baseSize: 50,
                        shrink: 0,
                        ratio: 0,
                        template: function (e, index, item) {
                          item.css("padding-top", "15px");
                          item.append(
                            $("<div />").dxToolbar({
                              height: "100%",
                              items: [
                                {
                                  location: "before",
                                  widget: "dxButton",
                                  options: {
                                    text: getTrad("importarPersona"),
                                    onClick: function (e) {
                                      $("#dxList_personas")
                                        .dxList("instance")
                                        .unselectAll();
                                      $("#dxPopup_importarPersona")
                                        .dxPopup("instance")
                                        .show();
                                    },
                                  },
                                },
                                {
                                  location: "after",
                                  widget: "dxButton",
                                  options: {
                                    elementAttr: { id: "dxButton_addJornada" },
                                    text: getTrad("añadir"),
                                    disabled: true,
                                    onClick: function (e) {
                                      var personasSel = $("#dxList_personas")
                                        .dxList("instance")
                                        .option("selectedItems");
                                      crearJornadaPersona(personasSel);

                                      $("#dxPopup_tblJornadaPersona")
                                        .dxPopup("instance")
                                        .show();
                                    },
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                    ],
                  }),
                );
              },
            },
            {
              ratio: 1,
              template: function (e, index, item) {
                item.css("padding-left", "30px");
                item.append(
                  $("<div />").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        baseSize: 70,
                        shrink: 0,
                        ratio: 0,
                        template: function (e, index, item) {
                          item.append(
                            $("<div/>")
                              .addClass("h5")
                              .dxNavBar({
                                dataSource: turnos,
                                keyExpr: "idTurno",
                                selectedItem: turnoSel,
                                onSelectionChanged: function (e) {
                                  if (e.addedItems.length > 0) {
                                    turnoSel = e.addedItems[0];

                                    datasource_tblJornadaPersona.reload();
                                    datasource_tblPersonas.load();
                                  }
                                },
                              }),
                          );
                        },
                      },
                      {
                        ratio: 1,
                        template: function (e, index, item) {
                          item.append(
                            $(
                              "<div id='dxDataGrid_tblJornadaPersona'/>",
                            ).dxDataGrid({
                              //Datos
                              dataSource: datasource_tblJornadaPersona,
                              remoteOperations: false,
                              //Propiedades
                              columnsAutoWidth: true,
                              width: "100%",
                              height: "100%",
                              paging: false,
                              editing: {
                                mode: "cell",
                                refreshMode: "reshape",
                                allowUpdating: true,
                                selectTextOnEditStart: true,
                              },
                              keyboardNavigation: {
                                enterKeyAction: "moveFocus",
                                enterKeyDirection: "row",
                                editOnKeyPress: true,
                              },
                              columns: [
                                {
                                  dataField: "tblPersona",
                                  visible: false,
                                  allowSearch: false,
                                },
                                {
                                  dataField: "idPersona",
                                  visible: false,
                                  allowSearch: false,
                                },
                                {
                                  dataField: "fecha",
                                  visible: false,
                                  allowSearch: false,
                                },
                                {
                                  dataField: "idTurno",
                                  visible: false,
                                  allowSearch: false,
                                },
                                {
                                  dataField: "horasDiarias",
                                  visible: false,
                                  allowSearch: false,
                                },

                                {
                                  dataField: "tblPersona.nombreCompuesto",
                                  caption: getTrad("nombre"),
                                  minWidth: "200px",
                                  alignment: "left",
                                  sortOrder: "asc",
                                  allowEditing: false,
                                  allowHeaderFiltering: false,
                                  allowUpdating: false,
                                },
                                {
                                  dataField: "horaEntrada",
                                  caption: getTrad("entrada"),
                                  width: "7.5%",
                                  alignment: "center",
                                  dataType: "date",
                                  format: "HH:mm",
                                  allowHeaderFiltering: false,
                                  editorOptions: {
                                    type: "time",
                                    showDropDownButton: false,
                                    displayFormat: "HH:mm",
                                    useMaskBehavior: true,
                                  },
                                  setCellValue: function (
                                    newData,
                                    value,
                                    currentRowData,
                                  ) {
                                    dxDataGrid_tblJornadaPersona_setCellValue(
                                      "horaEntrada",
                                      newData,
                                      value,
                                      currentRowData,
                                    );
                                  },
                                },
                                {
                                  dataField: "horaSalida",
                                  caption: getTrad("salida"),
                                  width: "7.5%",
                                  alignment: "center",
                                  allowHeaderFiltering: false,
                                  dataType: "date",
                                  format: "HH:mm",
                                  editorOptions: {
                                    step: 0,
                                    type: "time",
                                    showDropDownButton: false,
                                    displayFormat: "HH:mm",
                                    useMaskBehavior: true,
                                  },
                                  setCellValue: function (
                                    newData,
                                    value,
                                    currentRowData,
                                  ) {
                                    dxDataGrid_tblJornadaPersona_setCellValue(
                                      "horaSalida",
                                      newData,
                                      value,
                                      currentRowData,
                                    );
                                  },
                                },
                                {
                                  dataField: "tiempoDescanso",
                                  caption: getTrad("descanso"),
                                  width: "9%",
                                  alignment: "center",
                                  dataType: "date",
                                  format: "HH:mm",
                                  allowHeaderFiltering: false,
                                  editorOptions: {
                                    step: 0,
                                    type: "time",
                                    showDropDownButton: false,
                                    displayFormat: "HH:mm",
                                    useMaskBehavior: true,
                                  },
                                  setCellValue: function (
                                    newData,
                                    value,
                                    currentRowData,
                                  ) {
                                    dxDataGrid_tblJornadaPersona_setCellValue(
                                      "tiempoDescanso",
                                      newData,
                                      value,
                                      currentRowData,
                                    );
                                  },
                                },
                                {
                                  dataField: "tiempoTipoTrabajo1",
                                  caption: getTrad("produccion_abr"),
                                  width: "7.5%",
                                  alignment: "center",
                                  dataType: "date",
                                  format: "HH:mm",
                                  allowHeaderFiltering: false,
                                  editorOptions: {
                                    step: 0,
                                    type: "time",
                                    showDropDownButton: false,
                                    displayFormat: "HH:mm",
                                    useMaskBehavior: true,
                                  },
                                  setCellValue: function (
                                    newData,
                                    value,
                                    currentRowData,
                                  ) {
                                    dxDataGrid_tblJornadaPersona_setCellValue(
                                      "tiempoTipoTrabajo1",
                                      newData,
                                      value,
                                      currentRowData,
                                    );
                                  },
                                },
                                {
                                  dataField: "tiempoTipoTrabajo2",
                                  caption: getTrad("logistica_abr"),
                                  width: "7.5%",
                                  alignment: "center",
                                  dataType: "date",
                                  format: "HH:mm",
                                  allowHeaderFiltering: false,
                                  editorOptions: {
                                    step: 0,
                                    type: "time",
                                    showDropDownButton: false,
                                    displayFormat: "HH:mm",
                                    useMaskBehavior: true,
                                  },
                                  setCellValue: function (
                                    newData,
                                    value,
                                    currentRowData,
                                  ) {
                                    dxDataGrid_tblJornadaPersona_setCellValue(
                                      "tiempoTipoTrabajo2",
                                      newData,
                                      value,
                                      currentRowData,
                                    );
                                  },
                                },
                                {
                                  dataField: "tiempoTipoTrabajo3",
                                  caption: getTrad("administracion_abr"),
                                  width: "7.5%",
                                  alignment: "center",
                                  dataType: "date",
                                  format: "HH:mm",
                                  allowHeaderFiltering: false,
                                  editorOptions: {
                                    step: 0,
                                    type: "time",
                                    showDropDownButton: false,
                                    displayFormat: "HH:mm",
                                    useMaskBehavior: true,
                                  },
                                  setCellValue: function (
                                    newData,
                                    value,
                                    currentRowData,
                                  ) {
                                    dxDataGrid_tblJornadaPersona_setCellValue(
                                      "tiempoTipoTrabajo3",
                                      newData,
                                      value,
                                      currentRowData,
                                    );
                                  },
                                },
                                {
                                  dataField: "tiempoTipoTrabajo4",
                                  caption: getTrad("mantenimiento_abr"),
                                  width: "7.5%",
                                  alignment: "center",
                                  dataType: "date",
                                  format: "HH:mm",
                                  allowHeaderFiltering: false,
                                  editorOptions: {
                                    step: 0,
                                    type: "time",
                                    showDropDownButton: false,
                                    displayFormat: "HH:mm",
                                    useMaskBehavior: true,
                                  },
                                  setCellValue: function (
                                    newData,
                                    value,
                                    currentRowData,
                                  ) {
                                    dxDataGrid_tblJornadaPersona_setCellValue(
                                      "tiempoTipoTrabajo4",
                                      newData,
                                      value,
                                      currentRowData,
                                    );
                                  },
                                },
                                {
                                  dataField: "tiempoTipoTrabajo5",
                                  caption: getTrad("valet"),
                                  width: "7.5%",
                                  alignment: "center",
                                  dataType: "date",
                                  format: "HH:mm",
                                  allowHeaderFiltering: false,
                                  editorOptions: {
                                    step: 0,
                                    type: "time",
                                    showDropDownButton: false,
                                    displayFormat: "HH:mm",
                                    useMaskBehavior: true,
                                  },
                                  setCellValue: function (
                                    newData,
                                    value,
                                    currentRowData,
                                  ) {
                                    dxDataGrid_tblJornadaPersona_setCellValue(
                                      "tiempoTipoTrabajo5",
                                      newData,
                                      value,
                                      currentRowData,
                                    );
                                  },
                                },
                                {
                                  dataField: "tiempoTipoTrabajo6",
                                  caption: getTrad("transporte"),
                                  width: "7.5%",
                                  alignment: "center",
                                  dataType: "date",
                                  format: "HH:mm",
                                  allowHeaderFiltering: false,
                                  editorOptions: {
                                    step: 0,
                                    type: "time",
                                    showDropDownButton: false,
                                    displayFormat: "HH:mm",
                                    useMaskBehavior: true,
                                  },
                                  setCellValue: function (
                                    newData,
                                    value,
                                    currentRowData,
                                  ) {
                                    dxDataGrid_tblJornadaPersona_setCellValue(
                                      "tiempoTipoTrabajo6",
                                      newData,
                                      value,
                                      currentRowData,
                                    );
                                  },
                                },
                                {
                                  caption: " ",
                                  width: "50px",
                                  alignment: "center",
                                  cssClass: "p-0",
                                  allowEditing: false,
                                  allowHeaderFiltering: false,
                                  allowUpdating: false,
                                  cellTemplate: function (
                                    cellElement,
                                    cellInfo,
                                  ) {
                                    cellElement.on("click", function () {
                                      cellInfo.component.deleteRow(
                                        cellInfo.rowIndex,
                                      );
                                    });

                                    cellElement.append(
                                      $("<div/>")
                                        .addClass("container_spanCentrado")
                                        .append(
                                          svg_remove
                                            .clone()

                                            .css("height", "25px")
                                            .css("width", "25px")
                                            .css("cursor", "pointer"),
                                        ),
                                    );
                                  },
                                },
                              ],
                              summary: {
                                recalculateWhileEditing: true,
                                totalItems: [
                                  {
                                    column: "tblPersona.nombreCompuesto",
                                    displayFormat:
                                      getTrad("total").toUpperCase(),
                                  },
                                  {
                                    name: "tiempoTipoTrabajo1",
                                    column: "tiempoTipoTrabajo1",
                                    summaryType: "custom",
                                  },
                                  {
                                    name: "tiempoTipoTrabajo2",
                                    column: "tiempoTipoTrabajo2",
                                    summaryType: "custom",
                                  },
                                  {
                                    name: "tiempoTipoTrabajo3",
                                    column: "tiempoTipoTrabajo3",
                                    summaryType: "custom",
                                  },
                                  {
                                    name: "tiempoTipoTrabajo4",
                                    column: "tiempoTipoTrabajo4",
                                    summaryType: "custom",
                                  },
                                  {
                                    name: "tiempoTipoTrabajo5",
                                    column: "tiempoTipoTrabajo5",
                                    summaryType: "custom",
                                  },
                                  {
                                    name: "tiempoTipoTrabajo6",
                                    column: "tiempoTipoTrabajo6",
                                    summaryType: "custom",
                                  },
                                ],
                                calculateCustomSummary: function (options) {
                                  var array_field = [
                                    "tiempoTipoTrabajo1",
                                    "tiempoTipoTrabajo2",
                                    "tiempoTipoTrabajo3",
                                    "tiempoTipoTrabajo4",
                                    "tiempoTipoTrabajo5",
                                    "tiempoTipoTrabajo6",
                                  ];

                                  if (
                                    $.inArray(options.name, array_field) !== -1
                                  ) {
                                    switch (options.summaryProcess) {
                                      case "start":
                                        options.totalValue = 0;
                                        break;
                                      case "calculate":
                                        var val = options.value;
                                        if (typeof val === "string") {
                                          val = durationToDatetime(
                                            options.value,
                                          );
                                        }
                                        options.totalValue +=
                                          dateTimeToSeconds(val);
                                        break;
                                      case "finalize":
                                        var hours = leadingZero(
                                          Math.floor(options.totalValue / 3600),
                                        );
                                        var minutes = leadingZero(
                                          Math.floor(
                                            (options.totalValue -
                                              hours * 3600) /
                                              60,
                                          ),
                                        );

                                        options.totalValue =
                                          hours + ":" + minutes + " ";
                                        break;
                                      default:
                                        break;
                                    }
                                  }
                                },
                              },
                              //Eventos
                              onCellPrepared: function (e) {
                                if (e.rowType === "data") {
                                  var field = e.column.dataField;
                                  var tipoTrabajo =
                                    e.row.data.tblPersona.idTipoTrabajo;

                                  if (
                                    field ===
                                    "tiempoTipoTrabajo" + tipoTrabajo
                                  ) {
                                    e.cellElement.css("opacity", 0.5);
                                  }
                                }
                              },
                              onKeyDown: function (e) {
                                if (e.jQueryEvent.keyCode === 9) {
                                  // Tab
                                  e.event.preventDefault();
                                }
                              },
                              onEditorPreparing: function (e) {
                                var field = e.dataField;
                                var tipoTrabajo =
                                  e.row.data.tblPersona.idTipoTrabajo;

                                if (
                                  field ===
                                  "tiempoTipoTrabajo" + tipoTrabajo
                                ) {
                                  e.editorOptions.disabled = true;
                                }
                              },
                              onEditorPrepared: function (e) {
                                e.editorElement
                                  .find(".dx-texteditor-input")
                                  .off("dxmousewheel");
                              },
                              onRowValidating: function (e) {
                                e.errorText = getTrad(
                                  "aviso_I_TiempoSeleccionadoSuperior",
                                );

                                var data = $.extend(true, {}, e.oldData);
                                $.each(e.newData, function (dataField, val) {
                                  data[dataField] = val;
                                });

                                e.isValid = calcularTiempoTrabajo(data);
                              },
                              onRowRemoved: function (e) {
                                GetTotalDiario();
                                datasource_tblPersonas.load();
                                datasource_personaNLavanderia.load();
                              },
                              onRowUpdated: function (e) {
                                GetTotalDiario();
                              },
                              //Estilos
                              showColumnLines: true,
                              showRowLines: true,
                              rowAlternationEnabled: true,
                            }),
                          );
                        },
                      },
                      {
                        baseSize: "auto",
                        shrink: 0,
                        ratio: 0,
                        template: function (e, index, item) {
                          item.css("padding-top", "10px");
                          item.css("border-top", "1px solid gainsboro");

                          item.append(
                            $("<div id='dxForm_totales'/>").dxForm({
                              formData: totalDiario,
                              labelLocation: "top",
                              colCount: 7,
                              items: [
                                {
                                  dataField: "tt",
                                  label: {
                                    text: getTrad(
                                      "totalHorasDiarias",
                                    ).toUpperCase(),
                                  },
                                  editorType: "dxTextBox",
                                  disabled: true,
                                  editorOptions: {
                                    stylingMode: "underlined",
                                  },
                                },
                                {
                                  dataField: "tt1",
                                  label: {
                                    text: getTrad("totalHorasProduccion"),
                                  },
                                  template: function (data, itemElement) {
                                    itemElement.css("display", "flex");

                                    var formData =
                                      data.component.option("formData");
                                    var tt1 = formData.tt1;
                                    var tt1_perc = formData.tt1_perc;

                                    itemElement.append(
                                      $("<div />").css("flex", "1").dxTextBox({
                                        text: tt1,
                                        stylingMode: "underlined",
                                      }),
                                    );

                                    itemElement.append(
                                      $("<div />")
                                        .addClass("porcentaje")
                                        .text(
                                          formatNumber(tt1_perc, 0, "percent"),
                                        ),
                                    );
                                  },
                                  disabled: true,
                                },
                                {
                                  dataField: "tt2",
                                  label: {
                                    text: getTrad("totalHorasLogistica"),
                                  },
                                  template: function (data, itemElement) {
                                    itemElement.css("display", "flex");

                                    var formData =
                                      data.component.option("formData");
                                    var tt2 = formData.tt2;
                                    var tt2_perc = formData.tt2_perc;

                                    itemElement.append(
                                      $("<div />").css("flex", "1").dxTextBox({
                                        text: tt2,
                                        stylingMode: "underlined",
                                      }),
                                    );

                                    itemElement.append(
                                      $("<div />")
                                        .addClass("porcentaje")
                                        .text(
                                          formatNumber(tt2_perc, 0, "percent"),
                                        ),
                                    );
                                  },
                                  disabled: true,
                                },
                                {
                                  dataField: "tt3",
                                  label: {
                                    text: getTrad("totalHorasAdministracion"),
                                  },
                                  template: function (data, itemElement) {
                                    itemElement.css("display", "flex");

                                    var formData =
                                      data.component.option("formData");
                                    var tt3 = formData.tt3;
                                    var tt3_perc = formData.tt3_perc;

                                    itemElement.append(
                                      $("<div />").css("flex", "1").dxTextBox({
                                        text: tt3,
                                        stylingMode: "underlined",
                                      }),
                                    );

                                    itemElement.append(
                                      $("<div />")
                                        .addClass("porcentaje")
                                        .text(
                                          formatNumber(tt3_perc, 0, "percent"),
                                        ),
                                    );
                                  },
                                  disabled: true,
                                },
                                {
                                  dataField: "tt4",
                                  label: {
                                    text: getTrad("totalHorasMantenimiento"),
                                  },
                                  template: function (data, itemElement) {
                                    itemElement.css("display", "flex");

                                    var formData =
                                      data.component.option("formData");
                                    var tt4 = formData.tt4;
                                    var tt4_perc = formData.tt4_perc;

                                    itemElement.append(
                                      $("<div />").css("flex", "1").dxTextBox({
                                        text: tt4,
                                        stylingMode: "underlined",
                                      }),
                                    );

                                    itemElement.append(
                                      $("<div />")
                                        .addClass("porcentaje")
                                        .text(
                                          formatNumber(tt4_perc, 0, "percent"),
                                        ),
                                    );
                                  },
                                  disabled: true,
                                },
                                {
                                  dataField: "tt5",
                                  label: { text: getTrad("totalHorasValet") },
                                  template: function (data, itemElement) {
                                    itemElement.css("display", "flex");

                                    var formData =
                                      data.component.option("formData");
                                    var tt5 = formData.tt5;
                                    var tt5_perc = formData.tt5_perc;

                                    itemElement.append(
                                      $("<div />").css("flex", "1").dxTextBox({
                                        text: tt5,
                                        stylingMode: "underlined",
                                      }),
                                    );

                                    itemElement.append(
                                      $("<div />")
                                        .addClass("porcentaje")
                                        .text(
                                          formatNumber(tt5_perc, 0, "percent"),
                                        ),
                                    );
                                  },
                                  disabled: true,
                                },
                                {
                                  dataField: "tt6",
                                  label: {
                                    text: getTrad("totalHorasTransporte"),
                                  },
                                  template: function (data, itemElement) {
                                    itemElement.css("display", "flex");

                                    var formData =
                                      data.component.option("formData");
                                    var tt6 = formData.tt6;
                                    var tt6_perc = formData.tt6_perc;

                                    itemElement.append(
                                      $("<div />").css("flex", "1").dxTextBox({
                                        text: tt6,
                                        stylingMode: "underlined",
                                      }),
                                    );

                                    itemElement.append(
                                      $("<div />")
                                        .addClass("porcentaje")
                                        .text(
                                          formatNumber(tt6_perc, 0, "percent"),
                                        ),
                                    );
                                  },
                                  disabled: true,
                                },
                              ],
                            }),
                          );
                        },
                      },
                    ],
                  }),
                );
              },
            },
          ],
        });

        $("#JornadaPersona #dxPopup_importarPersona").dxPopup({
          title: getTrad("importarPersona"),
          deferRendering: false,
          showTitle: true,
          width: "700px",
          height: "550px",
          contentTemplate: function (contentElement) {
            contentElement.append(
              $("<div>").dxBox({
                direction: "row",
                align: "space-around",
                crossAlign: "stretch",
                items: [
                  {
                    ratio: 1,
                    template: function () {
                      // LAVANDERÍAS
                      var container = $("<div>");
                      var title = $(
                        "<div class='font-size' style='margin-left:9px;'>" +
                          getTrad("lavanderia") +
                          "</div>",
                      );
                      var lista_compañias = $(
                        "<div id='dxList_lavanderias'>",
                      ).dxList({
                        dataSource: datasource_tblLavanderia.items(),
                        focusStateEnabled: false,
                        activeStateEnabled: false,
                        searchEnabled: true,
                        searchExpr: "denominacion",
                        selectionMode: "single",
                        height: "375px",
                        width: "95%",
                        keyExpr: "idLavanderia",
                        scrolling: { mode: "infinite" },
                        pageLoadMode: "scrollBottom",
                        // EVENTOS
                        onContentReady: function (e) {
                          if (e.component.option("selectedItem") === null)
                            e.component.selectItem(0);
                        },
                        onSelectionChanged: function (e) {
                          let dxList_personasNLav = $(
                            "#dxList_personasNLav",
                          ).dxList("instance");

                          if (e.addedItems.length > 0)
                            idLavSel = e.addedItems[0].idLavanderia;
                          else idLavSel = -1;

                          if (dxList_personasNLav !== undefined)
                            dxList_personasNLav.reload();
                        },
                        itemTemplate: function (data, index) {
                          var result = $("<div>");
                          $("<div>").text(data.denominacion).appendTo(result);

                          return result;
                        },
                      });
                      title.appendTo(container);
                      lista_compañias.appendTo(container);

                      return container;
                    },
                  },
                  {
                    ratio: 1,
                    template: function () {
                      return $("<div>").dxBox({
                        direction: "col",
                        align: "space-around",
                        crossAlign: "stretch",
                        items: [
                          {
                            baseSize: 38,
                            shrink: 0,
                            ratio: 0,
                            template: function (e, index, item) {
                              item.append(
                                $(
                                  "<div class='font-size' style='margin-left:9px;'>" +
                                    getTrad("personas") +
                                    "</div>",
                                ),
                              );
                            },
                          },
                          {
                            baseSize: 45,
                            shrink: 0,
                            ratio: 0,
                            template: function (e, index, item) {
                              item.append(
                                $("<div/>").dxSelectBox({
                                  items: datasource_tblCategoria.items(),
                                  value: categoriaSel_importar,
                                  valueExpr: "idCategoria",
                                  displayExpr: "denominacion",
                                  elementAttr: {
                                    id: "dxSelectBox_importar_categoria",
                                  },
                                  placeholder: getTrad("seleccioneCategoria"),
                                  showClearButton: true,
                                  width: "100%",
                                  onValueChanged: function (e) {
                                    categoriaSel_importar =
                                      e.component.option("selectedItem");
                                    datasource_personaNLavanderia.load();
                                  },
                                }),
                              );
                            },
                          },
                          {
                            ratio: 1,
                            template: function () {
                              // PERSONAS
                              var container = $("<div>");
                              var lista_compañias = $(
                                "<div id='dxList_personasNLav'>",
                              ).dxList({
                                dataSource: datasource_personaNLavanderia,
                                height: "330px",
                                scrolling: { mode: "infinite" },
                                pageLoadMode: "scrollBottom",
                                displayExpr: "nombreCompuesto",
                                searchEnabled: true,
                                selectionMode: "all",
                                showSelectionControls: true,
                                // EVENTOS
                                onContentReady: function (e) {
                                  e.component.unselectAll();
                                },
                              });
                              lista_compañias.appendTo(container);

                              return container;
                            },
                          },
                        ],
                      });
                    },
                  },
                ],
              }),
            );
          },
          toolbarItems: [
            {
              location: "after",
              widget: "dxButton",
              toolbar: "bottom",
              options: {
                text: getTrad("importar"),
                type: "success",
                onClick: function (e) {
                  let dxList_personasNLav = $("#dxList_personasNLav").dxList(
                    "instance",
                  );
                  let nuevasPers_importadas =
                      dxList_personasNLav.option("selectedItems"),
                    selectedKeys = nuevasPers_importadas.map(function (item) {
                      return item.idPersona;
                    });

                  $.merge(personasImportadas, nuevasPers_importadas);
                  datasource_tblPersonas.load().done(function () {
                    $("#dxList_personas")
                      .dxList("instance")
                      .option("selectedItemKeys", selectedKeys);
                  });
                  crearJornadaPersona(nuevasPers_importadas);

                  $("#dxPopup_importarPersona").dxPopup("instance").hide();
                  $("#dxPopup_tblJornadaPersona").dxPopup("instance").show();
                },
              },
            },
          ],
          onShowing: function (e) {
            $("#dxList_personasNLav").dxList("instance").reload();
          },
          onHiding: function (e) {
            let dxList_lavanderias = $("#dxList_lavanderias").dxList(
                "instance",
              ),
              dxList_personasNLav = $("#dxList_personasNLav").dxList(
                "instance",
              );
            dxList_lavanderias.selectItem(0);
            dxList_lavanderias.scrollTo(0);
            dxList_lavanderias.option("searchValue", "");

            dxList_personasNLav.unselectAll();
            dxList_personasNLav.option("searchValue", "");

            $("#dxSelectBox_importar_categoria")
              .dxSelectBox("instance")
              .reset();
          },
        });

        $("#JornadaPersona #dxPopup_tblJornadaPersona").dxPopup({
          title: getTrad("datosJornada"),
          deferRendering: false,
          showTitle: true,
          width: "550px",
          height: "300px",
          contentTemplate: function (contentElement) {
            contentElement.append(
              $("<div id='dxForm_tblJornadaPersona' />").dxForm({
                formData: jornadaPersonaSel,
                labelLocation: "top",
                colCount: 3,
                items: [
                  {
                    dataField: "idTurno",
                    colSpan: 3,
                    editorType: "dxSelectBox",
                    label: {
                      text: getTrad("turno"),
                    },
                    editorOptions: {
                      dataSource: turnosSubturnos,
                      valueExpr: "idTurno",
                      displayExpr: "denominacion",
                      onValueChanged: function (e) {
                        var turno = e.component.option("selectedItem");

                        $("#dxForm_tblJornadaPersona")
                          .dxForm("instance")
                          .updateData({
                            horaEntrada: turno.horaEntrada,
                            horaSalida: turno.horaSalida,
                            tiempoDescanso: turno.tiempoDescanso,
                          });
                      },
                    },
                  },
                  {
                    dataField: "horaEntrada",
                    label: {
                      text: getTrad("horaEntrada"),
                    },
                    editorType: "dxDateBox",
                    dataType: "datetime",
                    editorOptions: {
                      width: "100%",
                      type: "time",
                      format: "HH:mm",
                      showDropDownButton: false,
                      useMaskBehavior: true,
                      min: "01/01/1900",
                    },
                  },
                  {
                    dataField: "horaSalida",
                    label: {
                      text: getTrad("horaSalida"),
                    },
                    editorType: "dxDateBox",
                    dataType: "datetime",
                    editorOptions: {
                      width: "100%",
                      type: "time",
                      format: "HH:mm",
                      showDropDownButton: false,
                      useMaskBehavior: true,
                      min: "01/01/1900",
                    },
                  },
                  {
                    dataField: "tiempoDescanso",
                    label: {
                      text: getTrad("tiempoDescanso"),
                    },
                    editorType: "dxDateBox",
                    dataType: "datetime",
                    editorOptions: {
                      width: "100%",
                      type: "time",
                      format: "HH:mm",
                      showDropDownButton: false,
                      useMaskBehavior: true,
                      min: "01/01/1900",
                    },
                  },
                ],
                onContentReady: function (e) {
                  var form = e.component;
                  form
                    .getEditor("horaEntrada")
                    .element()
                    .find(".dx-texteditor-input")
                    .off("dxmousewheel");
                  form
                    .getEditor("horaSalida")
                    .element()
                    .find(".dx-texteditor-input")
                    .off("dxmousewheel");
                  form
                    .getEditor("tiempoDescanso")
                    .element()
                    .find(".dx-texteditor-input")
                    .off("dxmousewheel");
                },
              }),
            );
          },
          toolbarItems: [
            {
              location: "after",
              widget: "dxButton",
              toolbar: "bottom",
              options: {
                text: getTrad("guardar"),
                type: "success",
                onClick: function (e) {
                  function postMassivo(idsPersona_diasCalLaboral) {
                    context_tblJornadaPersona
                      .invoke("PostMasivo", {}, "POST")
                      .done(function () {
                        //El body lo genera el método tratarDatos_dxForm_tblJornadaPersona en el beforeSend
                        GetTotalDiario();
                        datasource_tblJornadaPersona.reload().done(function () {
                          datasource_tblPersonas.reload();
                          $("#dxList_personas")
                            .dxList("instance")
                            .option("selectedItems", []);
                          personasImportadas = [];
                          $("#dxPopup_tblJornadaPersona")
                            .dxPopup("instance")
                            .hide();
                        });
                      });
                  }

                  var form = $("#dxForm_tblJornadaPersona").dxForm("instance");
                  var formData = form.option("formData");

                  if (
                    formData &&
                    form.validate().isValid &&
                    tratarDatos_dxForm_tblJornadaPersona()
                  ) {
                    var personasSel = $("#dxList_personas")
                        .dxList("instance")
                        .option("selectedItems"),
                      idsPersona_diasCalLaboral = [];

                    var errorText =
                      "<div id='mensajeError'>" +
                      getTrad("alert_conflictoCal1") +
                      "<br /><br />";
                    $.each(personasSel, function (index, item) {
                      if (item.is_diasCalLaboral) {
                        errorText += "- " + item.nombreCompuesto + "<br />";
                        idsPersona_diasCalLaboral.push(item.idPersona);
                      }
                    });
                    errorText += "<br />" + getTrad("alert_conflictoCal2");

                    if (idsPersona_diasCalLaboral.length > 0) {
                      dxMensajePregunta(errorText, [
                        // ACEPTAR
                        [
                          getTrad("aceptar"),
                          function () {
                            postMassivo(idsPersona_diasCalLaboral);
                          },
                          "normal",
                          "btnAceptar",
                        ],
                        // CANCELAR
                        [
                          getTrad("cancelar"),
                          function () {},
                          "normal",
                          "btnCancelar",
                        ],
                      ]);
                    } else postMassivo();
                  } else {
                    notify({
                      message: getTrad("aviso_I_TiempoSeleccionadoSuperior"),
                      type: "error",
                      displayTime: "1500",
                      closeOnClick: true,
                    });
                  }
                },
              },
            },
          ],
          onShowing: function (e) {
            $("#dxForm_tblJornadaPersona").dxForm("instance").repaint();
          },
          onHiding: function (e) {
            personasImportadas = [];
            $("#dxList_personas").dxList("instance").reload();
          },
        });
      });
    })
    .always(function () {
      deferred.resolve();
    });

  function filterTurnos(turnos) {
    turnos = $.each(turnos, function (index, item) {
      item.visible = true;
      if (
        item.idTurnoPadre != null ||
        ((!item.activo || item.eliminado) &&
          item.tblJornadaPersona &&
          item.tblJornadaPersona.length == 0)
      ) {
        item.visible = false;
      }
    });
    return turnos;
  }

  function tratarDatos_dxForm_tblJornadaPersona() {
    var data = $.extend(true, {}, jornadaPersonaSel);

    var jornadasInsertar = [];
    var invalido = false;

    $.each(data.personasSel, function (index, persona) {
      var jornada = $.extend(true, {}, data);
      delete jornada.personasSel;

      var row = {
        idTipoTrabajo: persona.idTipoTrabajo,
        horaEntrada: data.horaEntrada,
        horaSalida: data.horaSalida,
        tiempoDescanso: data.tiempoDescanso,
        tiempoTipoTrabajo1: jornada.tiempoTipoTrabajo1,
        tiempoTipoTrabajo2: jornada.tiempoTipoTrabajo2,
        tiempoTipoTrabajo3: jornada.tiempoTipoTrabajo3,
        tiempoTipoTrabajo4: jornada.tiempoTipoTrabajo4,
        tiempoTipoTrabajo5: jornada.tiempoTipoTrabajo5,
        tiempoTipoTrabajo6: jornada.tiempoTipoTrabajo6,
      };

      var tiempoTrabajo = calcularTiempoTrabajo(row);

      if (!tiempoTrabajo) {
        invalido = true;
        return false;
      }

      jornada.idPersona = persona.idPersona;
      jornada["tiempoTipoTrabajo" + persona.idTipoTrabajo] = tiempoTrabajo;
      jornada.horasDiarias = persona.horasDiarias;

      $.each(jornada, function (field, value) {
        if (field !== "fecha" && value instanceof Date) {
          jornada[field] = formatTime_parameter(value);
        }
      });

      jornadasInsertar.push(jornada);
    });

    return invalido ? null : jornadasInsertar;
  }

  function crearJornadaPersona(personasSel) {
    jornadaPersonaSel.personasSel.length = 0;
    $.merge(jornadaPersonaSel.personasSel, personasSel);

    jornadaPersonaSel.fecha = fechaSel;
    jornadaPersonaSel.horaEntrada = turnoSel.horaEntrada;
    jornadaPersonaSel.horaSalida = turnoSel.horaSalida;
    jornadaPersonaSel.tiempoDescanso = turnoSel.tiempoDescanso;
    jornadaPersonaSel.tiempoTipoTrabajo1 = new Date(2000, 1, 1, 0, 0, 0);
    jornadaPersonaSel.tiempoTipoTrabajo2 = new Date(2000, 1, 1, 0, 0, 0);
    jornadaPersonaSel.tiempoTipoTrabajo3 = new Date(2000, 1, 1, 0, 0, 0);
    jornadaPersonaSel.tiempoTipoTrabajo4 = new Date(2000, 1, 1, 0, 0, 0);
    jornadaPersonaSel.tiempoTipoTrabajo5 = new Date(2000, 1, 1, 0, 0, 0);
    jornadaPersonaSel.tiempoTipoTrabajo6 = new Date(2000, 1, 1, 0, 0, 0);
    jornadaPersonaSel.idTurno = turnoSel.idTurno;
    jornadaPersonaSel.idLavanderia = LAVANDERIA.idLavanderia;
  }

  function calcularTiempoTrabajo(row) {
    var rowData = $.extend(true, {}, row);

    //Convertimos los valores de duration a datetime, en caso del validation
    var array_field = [
      "horaEntrada",
      "horaSalida",
      "tiempoDescanso",
      "tiempoTipoTrabajo1",
      "tiempoTipoTrabajo2",
      "tiempoTipoTrabajo3",
      "tiempoTipoTrabajo4",
      "tiempoTipoTrabajo5",
      "tiempoTipoTrabajo6",
    ];
    $.each(rowData, function (field, value) {
      if ($.inArray(field, array_field) !== -1 && typeof value === "string") {
        rowData[field] = durationToDatetime(value);
      }
    });

    var hEntrada = rowData.horaEntrada;
    var hSalida = new Date(rowData.horaSalida.getTime());
    var tDescanso = rowData.tiempoDescanso;

    var tt1 = rowData.tiempoTipoTrabajo1;
    var tt2 = rowData.tiempoTipoTrabajo2;
    var tt3 = rowData.tiempoTipoTrabajo3;
    var tt4 = rowData.tiempoTipoTrabajo4;
    var tt5 = rowData.tiempoTipoTrabajo5;
    var tt6 = rowData.tiempoTipoTrabajo6;

    if (hEntrada > hSalida) {
      hSalida.setDate(hSalida.getDate() + 1);
    }

    var secDescanso = dateTimeToSeconds(tDescanso);
    var secTt1 = rowData.idTipoTrabajo !== 1 ? dateTimeToSeconds(tt1) : 0;
    var secTt2 = rowData.idTipoTrabajo !== 2 ? dateTimeToSeconds(tt2) : 0;
    var secTt3 = rowData.idTipoTrabajo !== 3 ? dateTimeToSeconds(tt3) : 0;
    var secTt4 = rowData.idTipoTrabajo !== 4 ? dateTimeToSeconds(tt4) : 0;
    var secTt5 = rowData.idTipoTrabajo !== 5 ? dateTimeToSeconds(tt5) : 0;
    var secTt6 = rowData.idTipoTrabajo !== 6 ? dateTimeToSeconds(tt6) : 0;

    var totalSec =
      (hSalida.getTime() - hEntrada.getTime()) / 1000 -
      secDescanso -
      secTt1 -
      secTt2 -
      secTt3 -
      secTt4 -
      secTt5 -
      secTt6;

    if (totalSec <= 24 * 60 * 60 && totalSec >= 0) {
      var hours = Math.floor(totalSec / 3600);
      var minutes = Math.floor((totalSec - hours * 3600) / 60);
      var seconds = totalSec - hours * 3600 - minutes * 60;

      return new Date(2000, 1, 1, hours, minutes, seconds);
    } else {
      return null;
    }
  }

  function dxDataGrid_tblJornadaPersona_setCellValue(
    field,
    newData,
    value,
    currentRowData,
  ) {
    var isValid = true;
    if (!$.isEmptyObject(currentRowData)) {
      var idTipoTrabajo = currentRowData.tblPersona.idTipoTrabajo;

      var grid = $("#dxDataGrid_tblJornadaPersona").dxDataGrid("instance");
      var rowIndex = grid.getRowIndexByKey({
        idPersona: currentRowData.idPersona,
        fecha: currentRowData.fecha,
      });

      var row = {
        idTipoTrabajo: idTipoTrabajo,
        horaEntrada: grid.cellValue(rowIndex, "horaEntrada"),
        horaSalida: grid.cellValue(rowIndex, "horaSalida"),
        tiempoDescanso: grid.cellValue(rowIndex, "tiempoDescanso"),
        tiempoTipoTrabajo1: grid.cellValue(rowIndex, "tiempoTipoTrabajo1"),
        tiempoTipoTrabajo2: grid.cellValue(rowIndex, "tiempoTipoTrabajo2"),
        tiempoTipoTrabajo3: grid.cellValue(rowIndex, "tiempoTipoTrabajo3"),
        tiempoTipoTrabajo4: grid.cellValue(rowIndex, "tiempoTipoTrabajo4"),
        tiempoTipoTrabajo5: grid.cellValue(rowIndex, "tiempoTipoTrabajo5"),
        tiempoTipoTrabajo6: grid.cellValue(rowIndex, "tiempoTipoTrabajo6"),
      };

      row[field] = value;

      var tiempoTrabajo = calcularTiempoTrabajo(row);
      if (tiempoTrabajo) {
        //Se ha superado el tiempo aún poniendo el tiempo del tipoTrabajo a 0
        newData["tiempoTipoTrabajo" + idTipoTrabajo] = tiempoTrabajo;
      } else {
        isValid = false;
      }
    } else {
      isValid = false;
    }

    newData[field] = value;
    return isValid;
  }

  function dateTimeToSeconds(date) {
    return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
  }

  return deferred.promise();
}
