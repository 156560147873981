import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { loadPanelActions } from "../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { getNombreFormulario } from "../../../helpers";

//Js
import cargaJs from "./Js.js";

//Selector
import Selector from "layout/AppSelector";
import * as cardContent from "./cardContent";

class Incidencias extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSelector: true };
    this.hideSelector = this.hideSelector.bind(this);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  hideSelector(params) {
    this.loadPanel_show();
    this.setState({ showSelector: false, ...params });
  }

  render() {
    const { showSelector } = this.state;

    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          preHeading={
            showSelector ? (
              ""
            ) : (
              <div className="backButton dx-icon-chevronleft container_spanCentrado"></div>
            )
          }
        />

        {showSelector ? (
          <Selector
            contenido={getNombreFormulario(this)}
            selector="lavanderia"
            expandir={false}
            rangeSelector={false}
            hideSelector={this.hideSelector}
            {...cardContent}
          />
        ) : (
          <Fragment>
            <div className={"media-body"}>
              {/* <ReactCSSTransitionGroup
                        component="div"
                        className="media-body"
                        transitionName="PageAnimation"
                        transitionAppear={true}
                        transitionAppearTimeout={1200}
                        transitionEnter={false}
                        transitionLeave={false}> */}
              <div id="Incidencias" className={"formContainer"}>
                <div id="dxContainer"></div>

                <div id="dxPopup_incidencia"></div>
                <div id="dxPopup_notificar"></div>
              </div>
              {/* </ReactCSSTransitionGroup> */}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.showSelector) {
      let { lavanderia, user, idioma } = this.props;
      if (
        (lavanderia &&
          lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
        this.state.showSelector !== prevState.showSelector ||
        idioma.idIdioma !== prevProps.idioma.idIdioma
      ) {
        cargaJs(this, lavanderia, user, idioma).then(() =>
          this.loadPanel_hide(),
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Incidencias);
