import React, { createContext, useEffect, useMemo, useRef, useState } from "react";

import { connect } from "react-redux";
import { impresionActions, loadPanelActions, tasaCambioActions } from "../../../actions";

import DataGridFacturasVenta from "./components/DataGridFacturasVenta";
import FormFacturaVenta from "./components/FormFacturaVenta";
import PageTitle from "layout/AppMain/PageTitle";
import { dxMensajePregunta, getNombreFormulario, getTrad } from "helpers";
import { Button, Popup, ScrollView } from "devextreme-react";
import { ToolbarItem as ToolbarItem_Popup } from "devextreme-react/popup";
import { getClearFactura } from "./model";
import { refreshStaticDatasources, datasource_tblAdmFacturaVenta, empresaPolarierSel } from "../shared/model";
import "../shared/styles/css.scss";
import SelectorEmpresa from "../shared/components/SelectorEmpresa";

import idsCargo from "constants/enums/GestionInterna/tblCargo";
import idsUsuario from "constants/enums/GestionInterna/tblUsuario";

let validations = new Map();

const FacturaVenta = ({ idioma, user, loadPanel_show, loadPanel_hide, setParams, loadTasaCambio }) => {
    const heading = getNombreFormulario({ props: { idioma, user } });
    const [facturaVentaSel, setFacturaVentaSel] = useState({});
    const [adminItemPopup_visibility, setAdminItemPopup_visibility] = useState(false);
    const [empresaSel, setEmpresaSel] = useState(empresaPolarierSel);
    const formFacturaVenta_REF = useRef();
    const savedData = useRef();

    useEffect(() => {
        loadTasaCambio();

        if (empresaSel.idEmpresaPolarier == null) {
            const empresaAux = {
                idEmpresaPolarier: user.tblEmpresaPolarierNUsuario[0]?.idEmpresaPolarier,
                idPais: user.tblEmpresaPolarierNUsuario[0]?.idPais,
            };
            setEmpresaSel(empresaAux);
        }
        return () => {
            validations = new Map();
        };
    }, []);

    useEffect(() => {
        savedData.current = { ...facturaVentaSel };
    }, [facturaVentaSel]);

    const openPopup = () => {
        setAdminItemPopup_visibility(true);
    };

    const popup_onHiding = (e) => {
        if (formFacturaVenta_REF.current.hasEditData(savedData.current)) {
            e.cancel = true;
            dxMensajePregunta("Hay cambios sin guardar que se van a perder. ¿Desea salir sin guardar? ", [
                [
                    getTrad("aceptar"),
                    () => {
                        closePopup();
                    },
                    "danger",
                ],
                [getTrad("cancelar"), () => {}],
            ]);
        } else {
            closePopup();
        }
    };

    const closePopup = () => {
        datasource_tblAdmFacturaVenta.reload();
        loadPanel_hide();
        setAdminItemPopup_visibility(false);
        setFacturaVentaSel(getClearFactura());
    };

    const shutdownPopup = () => {
        setAdminItemPopup_visibility(false);
    };

    const onContentReady = (e) => {
        e.component.content().addClass("admScrollPopup");
    };

    const getTitle = () => {
        if (facturaVentaSel.idAdmFacturaVenta) {
            const string = getTrad("modificandoFacturaVenta") + " - " + facturaVentaSel.codigo;
            return string;
        } else {
            return getTrad("nuevaFacturaVenta");
        }
    };

    const getButtonText = () => {
        if (facturaVentaSel.idAdmFacturaVenta) {
            return getTrad("actualizar");
        } else {
            return getTrad("guardar");
        }
    };

    const selectorEmpresa_onSelectionChanged = (e) => {
        // loadPanel_show();
        refreshStaticDatasources(e.selectedItem);
        setEmpresaSel(e.selectedItem);
    };

    const onClick_guardarImprimir = () => {
        formFacturaVenta_REF.current?.onSubmit_FormFacturaVenta().then((response) => {
            response.body
                .getReader()
                .read()
                .then(({ value }) => {
                    const decoder = new TextDecoder("utf-8");
                    const decodedValue = decoder.decode(value);
                    let obj = JSON.parse(decodedValue);

                    setParams({ idsAdmFacturaVenta: [obj.idAdmFacturaVenta] });
                });
        });
    };

    // Si el usuario no es Silverio, Jorge o Maribel y no es desarrollador, no puede editar
    const userCantEdit =
        ![idsUsuario.silverioLaraudogoitia, idsUsuario.jorgeGarcia, idsUsuario.maribelMoranta].includes(
            user.idUsuario
        ) && ![idsCargo.desarrollador].includes(user.idCargo);

    return (
        //     {/* <ConstantData.Provider value={datasources}> */}
        <>
            <PageTitle
                heading={heading}
                postHeading={
                    <SelectorEmpresa value={empresaSel} onSelectionChanged={selectorEmpresa_onSelectionChanged} />
                }
            />
            <div className="media-body">
                <Popup
                    showTitle={true}
                    title={getTitle()}
                    visible={adminItemPopup_visibility}
                    showCloseButton={true}
                    closeOnOutsideClick={false}
                    height="90%"
                    onHiding={popup_onHiding}
                    onContentReady={onContentReady}
                >
                    <ScrollView>
                        <FormFacturaVenta
                            ref={formFacturaVenta_REF}
                            facturaVentaSel={facturaVentaSel}
                            validations={validations}
                            closePopup={shutdownPopup}
                            loadPanel_show={loadPanel_show}
                            loadPanel_hide={loadPanel_hide}
                            userCantEdit={userCantEdit}
                        />
                    </ScrollView>
                    <ToolbarItem_Popup toolbar="bottom" location="after">
                        <Button
                            text={
                                facturaVentaSel.idAdmFacturaVenta
                                    ? getTrad("actualizarImprimir")
                                    : getTrad("guardarImprimir")
                            }
                            icon="print"
                            type="success"
                            onClick={onClick_guardarImprimir}
                            disabled={facturaVentaSel.isCerrado && userCantEdit}
                        />
                    </ToolbarItem_Popup>
                    <ToolbarItem_Popup toolbar="bottom" location="after">
                        <Button
                            text={getButtonText()}
                            type="success"
                            onClick={formFacturaVenta_REF.current?.onSubmit_FormFacturaVenta}
                            disabled={facturaVentaSel.isCerrado && userCantEdit}
                        />
                    </ToolbarItem_Popup>
                </Popup>
                <div className={"formContainer scrollbar-container"}>
                    <DataGridFacturasVenta
                        datasource={datasource_tblAdmFacturaVenta}
                        facturaVentaSel={facturaVentaSel}
                        setFacturaVentaSel={setFacturaVentaSel}
                        openPopup={openPopup}
                        idEmpresaPolarier={empresaSel.idEmpresaPolarier}
                        loadPanel_hide={loadPanel_hide}
                    />
                </div>
                {/* </ConstantData.Provider> */}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
    setParams: (params) => dispatch(impresionActions.setParams(params)),
    loadTasaCambio: (fechaDesde, fechaHasta) => dispatch(tasaCambioActions.loadTasaCambio(fechaDesde, fechaHasta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacturaVenta);
