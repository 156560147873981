export const defaultUserPhoto = {
  v: "5.7.1",
  fr: 60,
  ip: 0,
  op: 300,
  w: 32,
  h: 32,
  nm: "Comp 1",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "faceScan Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [16, 16, 0], ix: 2 },
        a: { a: 0, k: [16, 16, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 105,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [2.134, 0.276],
                          [0.386, 0.144],
                        ],
                        o: [
                          [-1.258, 0.469],
                          [-0.655, -0.085],
                          [0, 0],
                        ],
                        v: [
                          [3.864, -0.41],
                          [-2.277, -0.061],
                          [-3.864, -0.41],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 135,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [2.134, 1.258],
                          [0.386, 0.654],
                        ],
                        o: [
                          [-1.258, 2.134],
                          [-0.655, -0.386],
                          [0, 0],
                        ],
                        v: [
                          [3.864, -1.423],
                          [-2.277, 0.165],
                          [-3.864, -1.423],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 195,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [2.134, 1.258],
                          [0.386, 0.654],
                        ],
                        o: [
                          [-1.258, 2.134],
                          [-0.655, -0.386],
                          [0, 0],
                        ],
                        v: [
                          [3.864, -1.423],
                          [-2.277, 0.165],
                          [-3.864, -1.423],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 225,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [2.134, 0.276],
                          [0.386, 0.144],
                        ],
                        o: [
                          [-1.258, 0.469],
                          [-0.655, -0.085],
                          [0, 0],
                        ],
                        v: [
                          [3.864, -0.41],
                          [-2.277, -0.061],
                          [-3.864, -0.41],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [16, 19.41], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 105,
                    s: [74, 100],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 135,
                    s: [100, 100],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 195,
                    s: [100, 100],
                  },
                  { t: 225, s: [74, 100] },
                ],
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.736, 0],
                        [0, -0.736],
                        [0.736, 0],
                        [0, 0.736],
                      ],
                      o: [
                        [0.736, 0],
                        [0, 0.736],
                        [-0.736, 0],
                        [0, -0.736],
                      ],
                      v: [
                        [0, -1.333],
                        [1.333, 0],
                        [0, 1.333],
                        [-1.333, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [13.064, 12.631], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.735, 0],
                        [0, -0.736],
                        [0.736, 0],
                        [0, 0.736],
                      ],
                      o: [
                        [0.736, 0],
                        [0, 0.736],
                        [-0.735, 0],
                        [0, -0.736],
                      ],
                      v: [
                        [0, -1.333],
                        [1.333, 0],
                        [0, 1.333],
                        [-1.333, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [18.935, 12.631], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [16, 16], ix: 2 },
              a: { a: 0, k: [16, 16], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 10",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, -2.623],
                      ],
                      o: [
                        [0, 0],
                        [2.622, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.374, -2.374],
                        [-2.374, -2.374],
                        [2.374, 2.374],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [23.958, 8.042], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-2.622, 0.001],
                      ],
                      o: [
                        [0, 0],
                        [0, -2.622],
                        [0, 0],
                      ],
                      v: [
                        [-2.374, 2.374],
                        [-2.374, 2.374],
                        [2.374, -2.374],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [8.042, 8.042], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 2.622],
                      ],
                      o: [
                        [0, 0],
                        [2.622, -0.001],
                        [0, 0],
                      ],
                      v: [
                        [-2.374, 2.374],
                        [-2.374, 2.374],
                        [2.374, -2.374],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [23.958, 23.958], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-2.622, -0.001],
                      ],
                      o: [
                        [0, 0],
                        [0, 2.622],
                        [0, 0],
                      ],
                      v: [
                        [-2.374, -2.374],
                        [-2.374, -2.374],
                        [2.374, 2.374],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [8.042, 23.958], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 7",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [16, 16], ix: 2 },
              a: { a: 0, k: [16, 16], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 150,
                    s: [100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 165,
                    s: [85, 85],
                  },
                  { t: 180, s: [100, 100] },
                ],
                ix: 3,
              },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.579], y: [1] },
                    o: { x: [0.479], y: [-0.001] },
                    t: 30,
                    s: [0],
                  },
                  { t: 75, s: [180] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 9",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 303,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
