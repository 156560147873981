import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import Box, { Item } from "devextreme-react/box";
import notify from "devextreme/ui/notify";

import PageTitle from "layout/AppMain/PageTitle";
import {
  dxMensajePregunta,
  formatDateTime_parameter,
  getNombreFormulario,
  getTrad,
} from "helpers";
import { getInitialState } from "../../utils";
import {
  datasource_tblAlmacenRecambios,
  datasource_tblCierreRecambioNAlmacen,
  datasource_tblMovimientoRecambio,
  setIdTipoMovimientoRecambio,
} from "../../api";
import FormMovimientoRecambioWrapper from "../FormMovimientoRecambioWrapper";

const defaultNotify = (isError, trad) => {
  notify({
    message: getTrad(trad),
    type: isError ? "error" : "success",
    displayTime: 1500,
    closeOnClick: true,
  });
};

const isInvalidForm = (movimientoRecambio) => {
  if (
    movimientoRecambio?.tblRecambioNMovimientoRecambio &&
    movimientoRecambio.tblRecambioNMovimientoRecambio.length === 0
  ) {
    defaultNotify(true, "ingreseAlMenosUnRecambio");
    return true;
  }

  const isInvalidDataGridRecambios =
    movimientoRecambio.tblRecambioNMovimientoRecambio.some(
      (rnmr) =>
        rnmr.cantidad <= 0 ||
        (rnmr.min !== null && rnmr.cantidad < rnmr.min) ||
        (rnmr.max !== null && rnmr.cantidad > rnmr.max)
    );

  if (isInvalidDataGridRecambios) {
    defaultNotify(true, "cantidadRecambiosInvalida");
    return true;
  }

  return false;
};

const formatMovimientoRecambio = (movimientoRecambio) => {
  let movimientoRecambio_ = { ...movimientoRecambio };

  delete movimientoRecambio_.isEditable;
  delete movimientoRecambio_.idAlmacenOrigenNavigation;
  delete movimientoRecambio_.idAlmacenDestinoNavigation;
  delete movimientoRecambio_.idProveedorNavigation;

  if (movimientoRecambio_.idMovimientoRecambio === null) {
    delete movimientoRecambio_.idMovimientoRecambio;
  }

  movimientoRecambio_.fecha = new Date(
    new Date(movimientoRecambio_.fecha).setHours(
      movimientoRecambio_.fecha.getUTCHours()
    )
  );

  movimientoRecambio_.tblRecambioNMovimientoRecambio =
    movimientoRecambio_.tblRecambioNMovimientoRecambio.map(
      ({
        idMovimientoRecambio,
        idRecambio,
        cantidad,
        ubicacion,
        referenciaProveedor,
        precio,
        cantidadTeorico,
      }) => {
        const objResult = {
          idRecambio,
          cantidad,
          ubicacion,
          referenciaProveedor,
          precio,
          cantidadTeorico,
        };

        if (idMovimientoRecambio) {
          objResult.idMovimientoRecambio = idMovimientoRecambio;
        }

        return objResult;
      }
    );

  return movimientoRecambio_;
};

const MovimientoRecambioWrapper = ({
  FormMovimientoRecambio,
  DataGridRecambios,
  DataGridMovimientos,
  idTipoMovimientoRecambio,
  idioma,
  user,
}) => {
  const dataGridRecambiosRef = useRef(null);
  const dataGridMovimientosRef = useRef(null);

  const [isSending, setIsSending] = useState(false);
  const [hasChangesForm, setHasChangesForm] = useState(false);
  const [movimientoRecambioSel, setMovimientoRecambioSel] = useState(
    getInitialState(idTipoMovimientoRecambio)
  );

  useEffect(() => {
    setIdTipoMovimientoRecambio(idTipoMovimientoRecambio);
  }, []);

  useEffect(() => {
    datasource_tblAlmacenRecambios.load();
    datasource_tblCierreRecambioNAlmacen.load();
    if (isSending) {
      setIsSending(false);
    }
  }, [movimientoRecambioSel.idMovimientoRecambio]);

  const onNewMovimientoRecambio = () => {
    dataGridMovimientosRef?.current?.instance?.deselectAll();
    dataGridRecambiosRef?.current?.clear();
    setHasChangesForm(false);
    setMovimientoRecambioSel(getInitialState(idTipoMovimientoRecambio));
  };

  const onSelectionChanged = (e) => {
    const dataGridMovimientosInstance =
      dataGridMovimientosRef?.current?.instance;

    if (
      e !== null &&
      (e.currentSelectedRowKeys.length === 0 ||
        e.currentSelectedRowKeys?.[0] ===
          movimientoRecambioSel.idMovimientoRecambio)
    ) {
      return;
    }

    if (hasChangesForm) {
      if (e !== null) {
        e.cancel = true;
      }

      dxMensajePregunta(getTrad("preg_PerderCambios"), [
        [
          getTrad("aceptar"),
          () => {
            if (e === null) {
              onNewMovimientoRecambio();
            } else {
              e.cancel = false;
              setMovimientoRecambioSel({ ...e.selectedRowsData[0] });
              setHasChangesForm(false);
            }
          },
          "danger",
        ],
        [
          getTrad("cancelar"),
          () => {
            if (movimientoRecambioSel.idMovimientoRecambio) {
              dataGridMovimientosInstance?.selectRows(
                [movimientoRecambioSel.idMovimientoRecambio],
                false
              );
            } else {
              dataGridMovimientosInstance?.deselectAll();
            }
          },
        ],
      ]);
      return;
    }

    if (e === null) {
      onNewMovimientoRecambio();
      return;
    }

    setMovimientoRecambioSel({ ...e.selectedRowsData[0] });
  };

  const onSubmit = (movimientoRecambio) => {
    movimientoRecambio.tblRecambioNMovimientoRecambio =
      dataGridRecambiosRef?.current?.getDataSource();

    if (isInvalidForm(movimientoRecambio)) return;

    movimientoRecambio = formatMovimientoRecambio(movimientoRecambio);

    setIsSending(true);

    if (movimientoRecambio?.idMovimientoRecambio) {
      datasource_tblMovimientoRecambio
        .store()
        .update(movimientoRecambio.idMovimientoRecambio, movimientoRecambio)
        .done(() => {
          datasource_tblMovimientoRecambio.reload().done(() => {
            setHasChangesForm(false);
          });
          defaultNotify(false, "aviso_C_RegistroActualizado");
        })
        .catch(() => defaultNotify(true, "aviso_RegistroNoActualizado"))
        .always(() => setIsSending(false));
    } else {
      datasource_tblMovimientoRecambio
        .store()
        .insert(movimientoRecambio)
        .done((idMovimientoRecambio) => {
          datasource_tblMovimientoRecambio.reload().done(() => {
            setHasChangesForm(false);
            setMovimientoRecambioSel({
              ...movimientoRecambioSel,
              idMovimientoRecambio,
            });
            dataGridMovimientosRef?.current?.instance?.selectRows(
              [idMovimientoRecambio],
              false
            );
          });
          defaultNotify(false, "aviso_C_RegistroInsertado");
        })
        .catch(() => defaultNotify(true, "aviso_I_RegistroNoInsertado"))
        .always(() => setIsSending(false));
    }
  };

  const onRemove = (idMovimientoRecambio) => {
    dxMensajePregunta(getTrad("alert_eliminarRegistro"), [
      [
        getTrad("aceptar"),
        () => {
          datasource_tblMovimientoRecambio
            .store()
            .remove(idMovimientoRecambio)
            .done(() => {
              datasource_tblMovimientoRecambio.reload().done(() => {
                if (
                  movimientoRecambioSel.idMovimientoRecambio ===
                  idMovimientoRecambio
                ) {
                  onNewMovimientoRecambio();
                }
              });
              defaultNotify(false, "aviso_C_RegistroEliminado");
            })
            .catch(() => defaultNotify(true, "aviso_I_RegistroNoEliminado"));
        },
        "danger",
      ],
      [getTrad("cancelar"), () => {}],
    ]);
  };

  return (
    <>
      <PageTitle heading={getNombreFormulario({ props: { idioma, user } })} />
      <div className={"media-body"}>
        <div className="formContainer scrollbar-container">
          <Box height={"100%"} width={"100%"}>
            <Item ratio={2}>
              <FormMovimientoRecambioWrapper
                ref={dataGridRecambiosRef}
                Form={FormMovimientoRecambio}
                DataGrid={DataGridRecambios}
                movimientoRecambio={movimientoRecambioSel}
                isSending={isSending}
                setHasChangesForm={setHasChangesForm}
                onSubmit={onSubmit}
              />
            </Item>
            <Item baseSize={20} />
            <Item ratio={1}>
              <DataGridMovimientos
                ref={dataGridMovimientosRef}
                dataSource={datasource_tblMovimientoRecambio}
                onSelectionChanged={onSelectionChanged}
                onRemove={onRemove}
              />
            </Item>
          </Box>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ Global, Authentication }) => ({
  idioma: Global.idioma,
  user: Authentication.user,
});

export default connect(mapStateToProps)(MovimientoRecambioWrapper);
