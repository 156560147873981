import { store } from "../config";
import $ from "jquery";
export function getEstructura(lavanderia) {
  // DEVUELVE LA ESTRUCTURA FE FORMULARIOS FILTRADA POR LAVANDERIA Y APLICACIÓN SELECCIONADA.
  const estructuraMenu = JSON.parse(localStorage.getItem("estructuraMenu"));

  //#region Setear Traducciones
  let idioma =
    store.getState().Global.idioma != null
      ? store.getState().Global.idioma.codigo
      : "es";
  $.each(estructuraMenu, function (i, apart) {
    let apartLabel =
      apart.tblTraduccion !== null
        ? apart.tblTraduccion[idioma]
        : apart.denominacion;
    apart.label = apartLabel;

    $.each(apart.content, function (i, frm) {
      let formLabel =
        frm.tblTraduccion !== null ? frm.tblTraduccion[idioma] : frm.label;
      frm.label = formLabel;
    });
  });
  //#endregion

  let aplicacionActiva =
    store.getState().Global.aplicacionActiva != null
      ? store.getState().Global.aplicacionActiva
      : null;

  if (estructuraMenu && Object.keys(estructuraMenu).length > 0) {
    //Filtro lavanderia
    for (let apartado in estructuraMenu) {
      estructuraMenu[apartado].content = estructuraMenu[
        apartado
      ].content.filter(function f(item) {
        //FILTRAR POR idAplicacion
        if (
          ((lavanderia &&
            lavanderia.visiblePages.includes(item.idFormulario)) ||
            !lavanderia) &&
          ((aplicacionActiva &&
            item.idAplicacion == aplicacionActiva.idAplicacion) ||
            !aplicacionActiva)
        )
          return true;

        if (item.content) {
          return (item.content = item.content.filter(f)).length;
        }

        return false;
      });

      if (estructuraMenu[apartado].content.length === 0) {
        delete estructuraMenu[apartado];
      }
    }
  }
  return estructuraMenu;
}
