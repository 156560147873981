import React from "react";

import "./style.scss";

import { connectionConstants } from "constants";
import { errorHandler, authHeader, compressBase64Img_scaleWidth, getTrad, rotateBase64Image } from "helpers";

import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { Button, FileUploader } from "devextreme-react";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Box, { Item as ItemBox } from "devextreme-react/box";
import Popup, { ToolbarItem as PopupToolbarItem } from "devextreme-react/popup";
import Imagen from "./Imagen";

class ImagenEditor extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isPopupVisible: false,
            loading: false,
            base64: null,
        };
    }

    // #region Lifecycle

    componentDidMount() {
        const formComponent = this.props.data.component;
        const data = formComponent?.option("formData");
        if (data.idAdmCliente != null && !data.tblImagenNCliente) {
            this.setState({ loading: true });
            this.datasource_tblImagenNCliente.reload().done((data) => {
                formComponent.option("formData").tblImagenNCliente = data;
                this.setState({ base64: data?.at(0)?.imagen, loading: false });
            });
        }
    }

    // #endregion

    // #region DataSources

    datasource_tblImagenNCliente = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblImagenNCliente",
            key: "idAdmCliente",
            errorHandler,
            beforeSend: (request) => (request.headers = { ...authHeader() }),
            onLoading: (loadOptions) => this.datasource_tblImagenNCliente_onLoading(loadOptions),
            version: 4,
        }),
        select: ["idImagenNCliente", "imagen"],
    });

    datasource_tblImagenNCliente_onLoading = (loadOptions) => {
        loadOptions.filter = ["idAdmCliente", "=", this.props.data.component.option("formData").idAdmCliente];
    };

    // #endregion

    render() {
        const { isPopupVisible, loading, base64 } = this.state;
        const formComponent = this.props.data.component;
        const value = formComponent?.option("formData").tblImagenNCliente?.at(0);

        return (
            <div className="ImagenEditor position-relative">
                <div className="position-absolute-0" onClick={this.onClick_imagen}>
                    <Imagen value={value?.imagen} loading={loading} />
                </div>
                {value != null && (
                    <Toolbar className="position-absolute-bottom-right">
                        <ToolbarItem location="after">
                            <Button
                                icon=" icon_Arrow_Down font-weight-bold"
                                stylingMode="contained"
                                className="border-0 bg-white border-radius"
                            />
                        </ToolbarItem>
                        <ToolbarItem location="after">
                            <Button
                                icon=" icon_Cerrar font-weight-bold"
                                stylingMode="contained"
                                className="border-0 bg-white border-radius"
                            />
                        </ToolbarItem>
                    </Toolbar>
                )}
                <Popup
                    title={getTrad("subirArchivo")}
                    visible={isPopupVisible}
                    onHiding={this.dxPopup_onHiding}
                    height={500}
                    width={600}
                    dragEnabled={false}
                >
                    <Box direction="col" className="he-100">
                        <ItemBox baseSize={"auto"}>
                            <div className="ImagenEditor position-relative">
                                <div className="position-absolute-0">
                                    <Imagen value={base64} />
                                </div>
                            </div>
                        </ItemBox>
                        <ItemBox baseSize={20}></ItemBox>
                        <ItemBox baseSize={"auto"}>
                            <FileUploader
                                width="100%"
                                height="100%"
                                labelText={getTrad("msg_arrastraArchivo5mb")}
                                accept={"image/*"}
                                minFileSize={1}
                                uploadMode="instantly"
                                uploadFailedMessage={getTrad("subidaFallida")}
                                selectButtonText={getTrad("subirArchivo")}
                                onUploadStarted={this.fotos_onUploadStarted}
                            ></FileUploader>
                        </ItemBox>
                    </Box>
                    <PopupToolbarItem location={"after"} toolbar={"bottom"}>
                        <Button
                            disabled={!base64}
                            type="success"
                            text={getTrad("guardar")}
                            onClick={this.dxButton_onClick_Guardar}
                        />
                    </PopupToolbarItem>
                    <PopupToolbarItem location={"before"} toolbar={"bottom"}>
                        <Button disabled={!base64} icon="redo" onClick={this.dxButton_rotarImagen_onClick} />
                    </PopupToolbarItem>
                </Popup>
            </div>
        );
    }

    onClick_imagen = () => {
        this.setState({ isPopupVisible: true });
    };

    // #region dxFileUploader

    fotos_onUploadStarted = (e) => {
        let reader = new FileReader();
        const _this = this;
        const component = _this.props.data.component;

        if (e.file.size > 5242880) {
            notify({
                message: getTrad("subirArchivo"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        } else {
            reader.onloadend = function () {
                if (reader.result !== "") {
                    let fileSplit = reader.result.split(",");
                    let extension = e.file.type;
                    let base64 = fileSplit[1];

                    function insertarDocumento() {
                        _this.setState({ base64 });
                    }

                    //Compress
                    if (extension.split("/")[0] === "image") {
                        compressBase64Img_scaleWidth(reader.result, 800).then(function (compressedBase64) {
                            base64 = compressedBase64;
                            insertarDocumento();
                        });
                    } else {
                        insertarDocumento();
                    }
                }
            };
            reader.readAsDataURL(e.file);
        }
        e.request.abort();
    };

    dxButton_rotarImagen_onClick = () => {
        let { base64 } = this.state;

        rotateBase64Image("data:image/jpeg;base64," + base64, 90, (resultBase64) => {
            base64 = resultBase64.replace("data:image/png;base64,", "");
            this.setState({ base64 });
        });
    };

    dxButton_onClick_Guardar = () => {
        const { base64 } = this.state;
        const component = this.props.data.component;

        let imagen = { imagen: base64 };
        component.option("formData").tblImagenNCliente[0] = imagen;
        this.dxPopup_onHiding();
    };

    // #endregion

    // #region dxPopup

    dxPopup_onHiding = () => {
        this.setState({ isPopupVisible: false });
    };

    // #endregion
}

export default ImagenEditor;
