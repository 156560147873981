import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { impresionActions, loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { Button } from "devextreme-react/button";
import $ from "jquery";

import { getNombreFormulario } from "../../../helpers";
//Js
import cargaJs from "./Js.js";

class Pedidos extends React.Component {
  constructor(props) {
    super(props);
    this.setParams = this.setParams.bind(this);
    this.setReport = this.setReport.bind(this);
    this.closeReport = this.closeReport.bind(this);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    this.btn_responsiveOptions = this.btn_responsiveOptions.bind(this);
    this.btn_responsiveOptions_onClick =
      this.btn_responsiveOptions_onClick.bind(this);
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  setParams(params) {
    this.props.setParams(params);
  }

  setReport(report) {
    this.props.setReport(report);
  }

  closeReport() {
    this.props.close();
  }

  //BOTÓN PARA RESOLUCIONES BAJAS
  btn_responsiveOptions() {
    let { resolucion } = this.props;

    if (resolucion !== "xs" && resolucion !== "sm" && resolucion !== "md")
      return null;

    return (
      <Button
        width={185}
        elementAttr={{ class: "m-auto" }}
        text="Pedidos anteriores"
        type="normal"
        onClick={this.btn_responsiveOptions_onClick}
      />
    );
  }

  btn_responsiveOptions_onClick() {
    $("#popup_pedidosAnteriores").dxPopup("instance").show();
  }

  render() {
    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          postHeading={[this.btn_responsiveOptions()]}
        />
        <div className="media-body">
          <div id="Pedidos" className={"formContainer"}>
            <div id="dxLoadPanel_GridsNuevoPedido"></div>
            <div id="dxScrollView_pedidos">
              <div id="dxContainer"></div>
            </div>
            <div id="popup_selCompaEnti"></div>
            <div id="popup_alert_AceptarCancelar"></div>
            <div id="popup_añadirPrendas"></div>
            <div id="popup_pedidosAnteriores"></div>
            <div id="dxTooltip_Pedidos"></div>
          </div>
        </div>
      </Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, user, idioma, resolucion } = this.props;

    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();

      cargaJs(this, lavanderia, user, idioma).then(() => this.loadPanel_hide());

      this.props.close();
    } else if (resolucion !== prevProps.resolucion) {
      setTimeout(() => {
        if (
          typeof $("#Pedidos #dxContainer").data("dxResponsiveBox") !==
          "undefined"
        )
          $("#Pedidos #dxContainer").dxResponsiveBox("instance").repaint();
        if (
          typeof $("#dxDatagrid_pedidosAnteriores").data("dxDatagrid") !==
          "undefined"
        )
          $("#dxDatagrid_pedidosAnteriores").dxDatagrid("instance").repaint();

        $("#dxResponsiveBox_filtros").dxResponsiveBox("instance").repaint();
      }, 0);
    }
  }

  componentDidMount() {
    let { lavanderia, user, idioma } = this.props;
    cargaJs(this, lavanderia, user, idioma).then(() => this.loadPanel_hide());
  }

  componentWillUnmount() {
    this.closeReport();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  setReport: (report) => dispatch(impresionActions.setReport(report)),
  setParams: (params) => dispatch(impresionActions.setParams(params)),
  close: () => dispatch(impresionActions.close()),
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pedidos);
