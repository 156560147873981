import React, { Fragment } from "react";
import { connect } from "react-redux";
import Box, { Item as ItemBox } from "devextreme-react/box";
import DateRangePicker from "components/DateRangePicker/DateRangePicker";
import PageTitle from "../../../layout/AppMain/PageTitle";
import { connectionConstants } from "constants";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { errorHandler, authHeader, getTrad } from "../../../helpers";
import { SelectBox } from "devextreme-react";

import GridPrendasControlAlmacen from "./components/GridPrendasControlAlmacen";

class InfControlAlmacenCliente extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idCompañiaSel: null,
      prendaSel: null,
      group: null,
      fechaIni: new Date(),
      fechaFin: new Date(),
      refresh: false,
    };
  }

  render() {
    let { fechaIni, fechaFin, idCompañiaSel, prendaSel, refresh } = this.state;

    return (
      <Fragment>
        <Fragment>
          <PageTitle
            heading={getTrad("infControlAlmCliente")}
            postHeadingEnd={
              <Box direction="row" width={800} height="100%">
                <ItemBox ratio={1} />
                <ItemBox baseSize={"auto"}>
                  <div className="d-flex flex-row he-100 align-items-center">
                    <div className="font-weight-bold pr-3">
                      {getTrad("compañia")}:
                    </div>
                    <SelectBox
                      dataSource={this.datasource_tblCompañia}
                      displayExpr="denominacion"
                      placeholder="Selecciona compañía"
                      value={idCompañiaSel}
                      searchEnabled={true}
                      searchExpr="denominacion"
                      showClearButton={true}
                      width="250px"
                      onSelectionChanged={
                        this.dxSelectBox_onSelectionChanged_compañia
                      }
                    />
                  </div>
                </ItemBox>
                <ItemBox baseSize={30} />
                <ItemBox baseSize={"auto"}>
                  <div className="d-flex flex-row he-100 align-items-center">
                    <div className="font-weight-bold pr-3">Fecha:</div>
                    {this.get_dateRangePicker()}
                  </div>
                </ItemBox>
              </Box>
            }
          />
          <div className={"media-body"}>
            <div className="formContainer scrollbar-container">
              <Box direction="row" width="auto" height="100%">
                <ItemBox ratio={1}>
                  <GridPrendasControlAlmacen
                    title={getTrad("prendas")}
                    fechaIni={fechaIni}
                    fechaFin={fechaFin}
                    idCompañiaSel={idCompañiaSel}
                    onValueChange={this.onValueChange}
                    group={"prenda"}
                    onDataRefresh={this.onDataRefresh}
                    refresh={refresh}
                  />
                </ItemBox>
                <ItemBox baseSize={20} />
                <ItemBox ratio={1}>
                  <GridPrendasControlAlmacen
                    title={
                      getTrad("entidades") +
                      (prendaSel != null ? " - " + prendaSel.denominacion : "")
                    }
                    fechaIni={fechaIni}
                    fechaFin={fechaFin}
                    idCompañiaSel={idCompañiaSel}
                    idPrendaSel={prendaSel?.idPrenda ?? null}
                    onValueChange={this.onValueChange}
                    group={"entidad"}
                    onDataRefresh={this.onDataRefresh}
                    refresh={refresh}
                  />
                </ItemBox>
              </Box>
            </div>
          </div>
        </Fragment>
      </Fragment>
    );
  }

  dateRangePicker_onDateSelected = (fecha) => {
    if (fecha) {
      this.setState({ fechaIni: fecha.startDate, fechaFin: fecha.endDate });
    }
  };

  get_dateRangePicker = () => {
    let { fechaIni, fechaFin } = this.state;
    let fechaSel = {
      startDate: fechaIni,
      endDate: fechaFin,
      idTipoCalendario: 2,
    };

    return (
      <DateRangePicker
        month
        year
        day
        defaultType="personalizado"
        fechaSel={fechaSel}
        align={"right"}
        maxDate={new Date()}
        onDateSelected={this.dateRangePicker_onDateSelected}
      />
    );
  };

  dxSelectBox_onSelectionChanged_compañia = (e) => {
    const { selectedItem } = e;
    const idCompañiaSel = selectedItem ? selectedItem.idCompañia : null;
    this.setState({ idCompañiaSel, prendaSel: null });
  };

  onValueChange = (item) => {
    this.setState(item);
  };

  onDataRefresh = (value) => {
    this.setState({ refresh: value });
  };

  //#region DATASOURCES

  datasource_tblCompañia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCompañia_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idCompañia", "denominacion"],
    filter: ["eliminado eq false"],
    sort: ["denominacion asc"],
  });

  datasource_tblCompañia_beforeSend(request) {
    const { lavanderia } = this.props;
    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  //#endregion

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia } = this.props;
    if (
      lavanderia &&
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
    ) {
      this.setState({ idCompañiaSel: null, prendaSel: null });
      this.datasource_tblCompañia.reload();
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(InfControlAlmacenCliente);
