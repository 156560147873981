import $ from "jquery";
import React, { Fragment, createRef } from "react";

import {
    endOfYear,
    startOfYear,
    authHeader,
    errorHandler,
    formatDate_noTime_parameter,
    getTrad,
    capitalize,
    dxMensajePregunta,
    dateTime_hourMinute,
    durationToDatetime,
    addDays,
    getBrightnessColor,
} from "helpers";
const { Popup, SelectBox, Button, Tooltip, MultiView, List, Form, DateBox } = require("devextreme-react");
import ODataContext from "devextreme/data/odata/context";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { connectionConstants } from "constants";
import Loader from "react-loaders";
import "rc-year-calendar/locales/rc-year-calendar.es";
import "rc-year-calendar/locales/rc-year-calendar.pt";
import { ToolbarItem } from "devextreme-react/popup";
import NewYearCalendar from "components/NewYearCalendar";
import { Col, Row } from "reactstrap";
import { patchCuadrantePersonalRecalcular, postChangesIntoCuadrantePersonal } from "../../services/apiCalls";
import "./CalendarioCuadrante.scss";
import { Item as MultiViewItem } from "devextreme-react/multi-view";
import { Item as FormItem, RequiredRule } from "devextreme-react/form";
import notify from "devextreme/ui/notify";

class CalendarioCuadrante extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
            isExportacion: null,
            isImportacion: null,
            lavanderiaImportar: null,

            isEditTurnoValid: true,
            isEditHoraEntradaValid: true,
            isEditHoraSalidaValid: true,
            isEditEstadoValid: true,
            isLavanderiaSelValid: true,
            isTurnoSelValid: true,

            // MultiView
            selectedView: 0,
            isExportarEditar: false,
            tblLavanderia: [],
            tblTurno: [],
            lavanderiaSel: null,

            // Lista Exportar o Editar
            updateData: {},
            modoEditor: null,

            // Calendario
            idPersona: null,
            popupTitle: null,
            isCalendarVisible: false,
            tooltipTarget: null,
            targetEvent: null,
            cuadrante: [],
            year: new Date().getFullYear(),
            seleccion: null,
            loading: true,
            turnoSel: null,
        };

        this.dxPopupREF = createRef(null);
        this.dxList_tblPersonaREF = createRef(null);
        this.dxListREF_Edicion = createRef(null);
    }

    get dxPopup() {
        return this.dxPopupREF.current.instance;
    }

    get dxList_tblPersona() {
        return this.dxList_tblPersonaREF.current.instance;
    }

    get dxList_Edicion() {
        return this.dxListREF_Edicion.current.instance;
    }

    componentDidMount() {
        const promises = [];

        promises.push(this.dataSource_tblLavanderia.load());
        promises.push(this.dataSource_tblTurno.load());

        Promise.all(promises).then(([tblLavanderia, tblTurno]) => {
            tblTurno = tblTurno.map((x) => {
                x.horaEntrada = dateTime_hourMinute(durationToDatetime(x.horaEntrada));
                x.horaSalida = dateTime_hourMinute(durationToDatetime(x.horaSalida));
                return x;
            });

            this.setState({
                tblLavanderia,
                tblTurno,
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.enableMigracion !== this.props.enableMigracion) {
            this.enum_modoCalendario[0].disabled = !this.props.enableMigracion;
        }
    }

    // #region TRADUCCIONES

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    // #endregion

    // #region DATASOURCES Y CONTEXTS

    dataSource_errorHandler(error) {
        errorHandler(error, null);
    }

    dataSource_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    context_Cuadrantes = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/CuadrantePersonal",
        entities: {
            PrevisionCuadrantePersonal_Persona: {},
        },
        errorHandler: this.dataSource_errorHandler,
        beforeSend: (request) => {
            this.context_Cuadrantes_beforeSend(request);
        },
    });

    context_Cuadrantes_beforeSend(request) {
        request.headers = { ...authHeader() };

        const { isImportacion, lavanderiaSel, turnoSel, personaSel, idPersona, year } = this.state;
        const { lavanderia, turno } = this.props;

        const idLavanderia = isImportacion
            ? lavanderia.idLavanderia
            : lavanderiaSel != null
            ? lavanderiaSel?.idLavanderia
            : personaSel?.idLavanderia;

        let date = new Date(`${year}-01-01`);
        request.params = {
            idLavanderia: idLavanderia,
            idTurno: isImportacion ? turno.idTurno : turnoSel?.idTurno,
            idPersona: idPersona,
            fechaDesde: formatDate_noTime_parameter(startOfYear(date)),
            fechaHasta: formatDate_noTime_parameter(endOfYear(date)),
        };
    }

    dataSource_tblCalendarioLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioLavanderia",
            errorHandler: this.dataSource_errorHandler,
            beforeSend: (request) => this.dataSource_tblCalendarioLavanderia_beforeSend(request),
            version: 4,
        }),
        select: ["fecha", "idLavanderia", "idEstado"],
        sort: ["fecha desc"],
        filter: "(idEstado eq 8 or idEstado eq 10)",
    });

    dataSource_tblCalendarioLavanderia_beforeSend(request) {
        request.headers = { ...authHeader() };

        const { lavanderiaSel, isExportacion, year } = this.state;
        const { lavanderia } = this.props;
        let date = new Date(`${year}-01-01`);

        request.params.fechaDesde = formatDate_noTime_parameter(startOfYear(date));
        request.params.fechaHasta = formatDate_noTime_parameter(endOfYear(date));
        request.params.idLavanderia = isExportacion ? lavanderiaSel?.idLavanderia : lavanderia.idLavanderia;
    }

    dataSource_tblLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
            errorHandler: this.dataSource_errorHandler,
            beforeSend: (request) => this.dataSource_beforeSend(request),
            version: 4,
        }),
        select: ["idLavanderia", "denominacion"],
        sort: ["denominacion"],
    });

    dataSource_tblTurno = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTurno",
            errorHandler: this.dataSource_errorHandler,
            beforeSend: (request) => this.dataSource_beforeSend(request),
            version: 4,
        }),
        filter: [["activo", "=", true], "and", ["eliminado", "=", false]],
        select: ["idTurno", "denominacion", "idLavanderia", "horaEntrada", "horaSalida"],
        sort: ["denominacion"],
    });

    dataSource_tblPersona = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            errorHandler: this.dataSource_errorHandler,
            beforeSend: (request) => this.dataSource_beforeSend(request),
            onLoading: (loadOptions) => this.dataSource_tblPersona_onLoading(loadOptions),
            onLoaded: (result) => this.dataSource_tblPersona_onLoaded(result),
            version: 4,
        }),
        select: ["idPersona", "nombre", "apellidos", "idLavanderia, idTurno"],
        expand: [
            "tblPersonaNTipoContrato($select=fechaAltaContrato,fechaBajaContrato;$orderby=fechaAltaContrato desc)",
        ],
        sort: ["nombre", "apellidos"],
    });

    dataSource_tblPersona_onLoading = (loadOptions) => {
        const { lavanderiaImportar, dxListPersona_seachValue, idPersona } = this.state;
        const { lavanderia } = this.props;

        loadOptions.filter = [
            ["activo", "=", true],
            ["eliminado", "=", false],
        ];

        if (dxListPersona_seachValue && dxListPersona_seachValue.length > 0) {
            loadOptions.filter.push(["concat(concat(nombre, ' '), apellidos)", "contains", dxListPersona_seachValue]);
        }
        if (lavanderiaImportar != null) {
            loadOptions.filter.push(["idLavanderia", "=", lavanderiaImportar.idLavanderia]);
        } else if (idPersona == null) {
            loadOptions.filter.push(["!", ["idLavanderia", "=", lavanderia.idLavanderia]]);
        }
        if (idPersona != null) {
            loadOptions.filter.push(["idPersona", "=", idPersona]);
            if (loadOptions.skip) delete loadOptions.skip;
        }
    };

    dataSource_tblPersona_onLoaded = (result) => {
        return result.map((x) => {
            x.nombreCompuesto = x.nombre + " " + x.apellidos;

            if (x.tblPersonaNTipoContrato) {
                var ultimoContrato = x.tblPersonaNTipoContrato[0];
                var primerContrato = x.tblPersonaNTipoContrato[x.tblPersonaNTipoContrato.length - 1];

                x.fechaBaja =
                    ultimoContrato?.fechaBajaContrato != null
                        ? new Date(ultimoContrato?.fechaBajaContrato + " 00:00:00:00")
                        : null;
                x.fechaAlta =
                    primerContrato?.fechaAltaContrato != null
                        ? new Date(primerContrato?.fechaAltaContrato + " 00:00:00:00")
                        : null;
                delete x.tblPersonaNTipoContrato;
            }
            return x;
        });
    };

    enum_modoCalendario = [
        {
            text: "Cambiar temporalmente de lavandería",
            disabled: !this.props.enableMigracion,
            value: true,
        },
        {
            text: "Modificar calendario de cuadrante",
            value: false,
        },
    ];

    enum_modoEdicion = [
        {
            text: "Recalcular cuadrante",
            value: "recalcular",
        },
        {
            text: "Modificar turno y horario",
            value: "turno",
        },
        {
            text: "Modificar estado",
            value: "estado",
        },
    ];

    // #endregion

    abrirExportarEditar(idPersona, nombre) {
        this.setState({
            isVisible: true,
            idPersona: idPersona,
            popupTitle: nombre,
            lavanderiaSel: null,
            selectedView: 0,
            isExportarEditar: true,
        });
        this.dataSource_tblPersona.reload().then((tblPersona) => {
            this.setState({
                personaSel: tblPersona[0],
            });
        });
    }

    abrirImportar() {
        this.setState({
            isVisible: true,
            popupTitle: "Importar personal",
            isImportacion: true,
            isExportarEditar: false,
        });
        this.dataSource_tblPersona.pageIndex(0);
        this.dataSource_tblPersona.reload();
    }

    cargarCalendario() {
        const promises = [];

        promises.push(this.context_Cuadrantes.invoke("PrevisionCuadrantePersonal_Persona", {}, "GET"));
        this.state.personaSel == null && promises.push(this.dataSource_tblPersona.reload());

        Promise.all(promises).then(([previsiones, tblPersona]) => {
            previsiones.map((x) => {
                x.fecha = new Date(x.fecha.split("T")[0]);
                x.fecha.setHours(0, 0, 0, 0);
                return x;
            });

            this.setState({
                cuadrante: previsiones,
                loading: false,
                personaSel: this.state.personaSel == null ? tblPersona[0] : this.state.personaSel,
            });
        });
    }

    cargarCalendarioConLavanderia() {
        const promises = [];

        promises.push(this.context_Cuadrantes.invoke("PrevisionCuadrantePersonal_Persona", {}, "GET"));
        promises.push(this.dataSource_tblCalendarioLavanderia.reload());
        this.state.personaSel == null && promises.push(this.dataSource_tblPersona.reload());

        Promise.all(promises).then(([previsiones, tblCalendarioLavanderia, tblPersona]) => {
            previsiones.map((x) => {
                x.fecha = new Date(x.fecha.split("T")[0]);
                x.fecha.setHours(0, 0, 0, 0);
                return x;
            });

            let eventos = tblCalendarioLavanderia.reduce((previsiones, x) => {
                if (
                    previsiones.filter(
                        (y) => x.fecha === formatDate_noTime_parameter(y.fecha) && x.idEstado === y.idEstado
                    ).length === 0
                ) {
                    x.fecha = new Date(x.fecha);
                    x.fecha.setHours(0, 0, 0, 0);
                    previsiones.push(x);
                }
                return previsiones;
            }, previsiones);

            this.setState({
                cuadrante: eventos,
                loading: false,
                personaSel: this.state.personaSel == null ? tblPersona[0] : this.state.personaSel,
            });
        });
    }

    formateadorHora = (hora) => {
        const partes = hora ? hora.split(":") : ["HH", "MM"];
        return `${partes[0]}:${partes[1]}`;
    };

    isDiffLavanderia = (item) => {
        return (
            item.idLavanderia !== this.props.lavanderia.idLavanderia &&
            item.idLavanderia !== this.state.lavanderiaSel?.idLavanderia
        );
    };

    checkMigracionValidation = () => {
        const { personaSel, lavanderiaSel, turnoSel } = this.state;
        const { lavanderia } = this.props;
        const isPersonalMigrado = personaSel?.idLavanderia !== lavanderia.idLavanderia;

        let isLavanderiaSelValid = isPersonalMigrado || lavanderiaSel != null;
        let isTurnoSelValid = isPersonalMigrado || turnoSel != null;

        this.setState({
            isLavanderiaSelValid,
            isTurnoSelValid,
        });
    };

    checkEditValidation = () => {
        const { updateData } = this.state;

        let isEditTurnoValid = updateData?.idTurno != null;
        let isEditHoraEntradaValid = updateData?.horaEntrada != null;
        let isEditHoraSalidaValid = updateData?.horaSalida != null;
        let isEditEstadoValid = updateData?.idEstado != null;

        this.setState({
            isEditTurnoValid,
            isEditHoraEntradaValid,
            isEditHoraSalidaValid,
            isEditEstadoValid,
        });
    };

    dateFormat = { day: "2-digit", month: "2-digit", year: "numeric" };

    render() {
        const {
            isVisible,
            selectedView,
            modoEditor,
            updateData,
            lavanderiaImportar,
            tblLavanderia,
            tblTurno,
            isExportarEditar,
        } = this.state;

        const {
            isCalendarVisible,
            year,
            loading,
            popupTitle,
            tooltipTarget,
            targetEvents,
            cuadrante,
            seleccion,
            lavanderiaSel,
            turnoSel,
            isExportacion,
            isImportacion,
            personaSel,
        } = this.state;

        const {
            isEditTurnoValid,
            isEditHoraEntradaValid,
            isEditHoraSalidaValid,
            isEditEstadoValid,
            isLavanderiaSelValid,
            isTurnoSelValid,
        } = this.state;

        const { lavanderia, tblCalendarioPersonal_estado } = this.props;

        const calendarMinDate = addDays(new Date(), 1);
        calendarMinDate.setHours(0, 0, 0, 0);
        const isPersonalMigrado = personaSel?.idLavanderia !== lavanderia.idLavanderia;

        /**
         * Cuando es un calendario de importacion bloquear días que sean:
         *  - Que tengan una previsión manual o fijada
         *  - Sean días de cierre
         *
         * Si es edición o exportación bloquear cuando:
         *  - La lavandería sea diferente a la lavandería seleccionada Y diferente a la lavandería actual
         *  - Sea igual a la seleccionada con prevision ya vigente
         *  - Días de cierre (idEstado 10)
         */
        const disabledDays =
            isExportacion && isPersonalMigrado
                ? cuadrante.filter(this.isDiffLavanderia).map((x) => {
                      return x.fecha;
                  })
                : isImportacion
                ? cuadrante
                      .filter((x) => x.idCuadrantePersonal != null || x.idEstado === 10)
                      .map((x) => {
                          return x.fecha;
                      })
                : cuadrante
                      .filter(
                          (x) =>
                              this.isDiffLavanderia(x) ||
                              (x.idLavanderia === lavanderiaSel?.idLavanderia && x.idCuadrantePersonal != null) ||
                              x.idEstado === 10
                      )
                      .map((x) => {
                          return x.fecha;
                      });

        return (
            <>
                <Popup
                    ref={this.dxPopupREF}
                    showTitle={true}
                    title={popupTitle ? popupTitle : "Importar personal"}
                    visible={isVisible}
                    dragEnabled={false}
                    onHiding={this.dxPopup_onHiding_Opciones}
                    onHidden={this.dxPopup_onHidden_Opciones}
                    onContentReady={this.dxPopup_onContentReady_Opciones}
                    width={500}
                    height={isExportarEditar ? 400 : 700}
                >
                    <div className="contenedorCentrado">
                        {isExportarEditar ? (
                            <MultiView
                                selectedIndex={selectedView}
                                height={"100%"}
                                animationEnabled={true}
                                swipeEnabled={false}
                                deferRendering={false}
                            >
                                {/* 0 - SELECTOR MODO - EXPORTAR O EDITAR */}
                                <MultiViewItem>
                                    <div className="contenedorCentrado">
                                        <List
                                            width={500}
                                            height={"fit-content"}
                                            selectionMode={"single"}
                                            dataSource={this.enum_modoCalendario}
                                            itemRender={this.dxList_itemRender_ExportarEditar}
                                            onItemRendered={this.dxList_onItemRendered_ExportarEditar}
                                            onItemClick={this.dxList_onItemClick_Exportar}
                                        ></List>
                                    </div>
                                </MultiViewItem>
                                {/* 1 - SELECTOR EDICIÓN */}
                                <MultiViewItem>
                                    <div className="contenedorCentrado">
                                        <div className="w-100 font-size-sm text-center my-1 p-2 underline_bottom_dotted">
                                            Periodo: {seleccion?.startDate.toLocaleString(undefined, this.dateFormat)} -{" "}
                                            {seleccion?.endDate.toLocaleString(undefined, this.dateFormat)}
                                        </div>
                                        <List
                                            ref={this.dxListREF_Edicion}
                                            width={500}
                                            height={"fit-content"}
                                            selectionMode={"single"}
                                            dataSource={this.enum_modoEdicion}
                                            itemRender={this.dxList_itemRender_ElegirAccion}
                                            onItemRendered={this.dxList_onItemRendered_ExportarEditar}
                                            onItemClick={this.dxList_onItemClick_Editar}
                                        ></List>
                                    </div>
                                </MultiViewItem>
                                {/* 2 - EDICIÓN */}
                                <MultiViewItem>
                                    <div className="contenedorCentrado" style={{ width: 500 }}>
                                        {modoEditor === "turno" ? (
                                            <Fragment>
                                                <Form width={350}>
                                                    <FormItem
                                                        dataField="turno"
                                                        cssClass={"text-right"}
                                                        label={this.getTrad("turno")}
                                                    >
                                                        <SelectBox
                                                            className="ml-4"
                                                            stylingMode="underlined"
                                                            dataSource={tblTurno.filter(
                                                                (x) => x.idLavanderia === lavanderia.idLavanderia
                                                            )}
                                                            valueExpr={"idTurno"}
                                                            displayExpr={"denominacion"}
                                                            value={updateData?.idTurno}
                                                            isValid={isEditTurnoValid}
                                                            onSelectionChanged={
                                                                this.dxForm_dxSelectBox_onSelectionChanged_Turno
                                                            }
                                                        />
                                                        <RequiredRule
                                                            message={this.getTrad("alerta_rellenarCamposObligatorios")}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        dataField="horaEntrada"
                                                        cssClass={"text-right"}
                                                        label={this.getTrad("horaEntrada")}
                                                        dataType="datetime"
                                                    >
                                                        <DateBox
                                                            className="ml-4"
                                                            stylingMode="underlined"
                                                            type="time"
                                                            format="HH:mm"
                                                            showDropDownButton={false}
                                                            useMaskBehavior={true}
                                                            value={updateData?.horaEntrada}
                                                            isValid={isEditHoraEntradaValid}
                                                            onValueChanged={this.dxDateBox_onValueChanged_horaEntrada}
                                                        />
                                                        <RequiredRule
                                                            message={this.getTrad("alerta_rellenarCamposObligatorios")}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        dataField="horaSalida"
                                                        cssClass={"text-right"}
                                                        label={this.getTrad("horaSalida")}
                                                        dataType="datetime"
                                                    >
                                                        <DateBox
                                                            className="ml-4"
                                                            stylingMode="underlined"
                                                            type="time"
                                                            format="HH:mm"
                                                            showDropDownButton={false}
                                                            useMaskBehavior={true}
                                                            value={updateData?.horaSalida}
                                                            isValid={isEditHoraSalidaValid}
                                                            onValueChanged={this.dxDateBox_onValueChanged_horaSalida}
                                                        />
                                                        <RequiredRule
                                                            message={this.getTrad("alerta_rellenarCamposObligatorios")}
                                                        />
                                                    </FormItem>
                                                </Form>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <Form width={350}>
                                                    <FormItem
                                                        dataField="estado"
                                                        cssClass={"text-right"}
                                                        label={this.getTrad("estado")}
                                                    >
                                                        <SelectBox
                                                            className="ml-4"
                                                            stylingMode="underlined"
                                                            dataSource={tblCalendarioPersonal_estado
                                                                .filter((x) => x.idEstado !== 10 && x.idEstado !== 4)
                                                                .map((x) => {
                                                                    x.disabled =
                                                                        x.idEstado === 3 ||
                                                                        x.idEstado === 8 ||
                                                                        x.idEstado === 9;
                                                                    return x;
                                                                })}
                                                            valueExpr={"idEstado"}
                                                            displayExpr={"denominacion"}
                                                            value={updateData?.idEstado}
                                                            isValid={isEditEstadoValid}
                                                            onSelectionChanged={
                                                                this.dxForm_dxSelectBox_onSelectionChanged_Estado
                                                            }
                                                        />
                                                        <RequiredRule
                                                            message={this.getTrad("alerta_rellenarCamposObligatorios")}
                                                        />
                                                    </FormItem>
                                                </Form>
                                            </Fragment>
                                        )}
                                    </div>
                                </MultiViewItem>
                            </MultiView>
                        ) : (
                            <div className="contenedorCentrado">
                                <SelectBox
                                    className="my-3"
                                    dataSource={tblLavanderia.filter((x) => x.idLavanderia !== lavanderia.idLavanderia)}
                                    value={lavanderiaImportar?.idLavanderia}
                                    valueExpr={"idLavanderia"}
                                    displayExpr={"denominacion"}
                                    placeholder={this.getTrad("lavanderia")}
                                    stylingMode="underlined"
                                    onSelectionChanged={this.dxSelectBox_onSelectionChanged_Importar}
                                    width={400}
                                />
                                <List
                                    ref={this.dxList_tblPersonaREF}
                                    dataSource={this.dataSource_tblPersona}
                                    displayExpr={"nombreCompuesto"}
                                    valueExpr={"idPersona"}
                                    keyExpr={"idPersona"}
                                    searchEnabled={true}
                                    pageLoadMode="scrollBottom"
                                    searchMode="contains"
                                    onOptionChanged={this.dxList_onOptionChanged_Persona}
                                    onItemClick={this.dxList_onItemClick_Persona}
                                    width={400}
                                />
                            </div>
                        )}
                    </div>
                    <ToolbarItem
                        visible={selectedView === 2} // Editor
                        toolbar="bottom"
                        location="before"
                        widget="dxButton"
                    >
                        <Button
                            icon={"chevronleft"}
                            text={this.getTrad("atras")}
                            onClick={this.dxButton_onClick_Atras}
                        />
                    </ToolbarItem>
                    <ToolbarItem
                        visible={selectedView === 2} // Editor
                        toolbar="bottom"
                        location="after"
                        widget="dxButton"
                    >
                        <Button text={this.getTrad("guardar")} type="success" onClick={this.dxButton_onClick_Guardar} />
                    </ToolbarItem>
                    <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                        <Button text={this.getTrad("cerrar")} onClick={this.dxPopup_onHiding_Opciones} />
                    </ToolbarItem>
                </Popup>
                <Popup
                    className="CuadranteCalendario"
                    showTitle={true}
                    title={popupTitle}
                    visible={isCalendarVisible}
                    dragEnabled={false}
                    onHiding={this.dxPopup_onHiding_Calendario}
                    onHidden={this.dxPopup_onHidden_Calendario}
                    width={"89%"}
                    height={"85%"}
                >
                    <div className="contenedorCentrado">
                        {!loading ? (
                            <Fragment>
                                {isExportacion && (
                                    <Row className="align-items-center w-100">
                                        <Col className="font-size-xs" xs={"auto"}>
                                            <i className="icon_city_variant_outline mr-2" />
                                            <span>{this.getTrad("lavanderia")}</span>
                                        </Col>
                                        <Col xs={2} className="mr-3">
                                            <SelectBox
                                                dataSource={tblLavanderia.filter(
                                                    (x) => x.idLavanderia !== lavanderia.idLavanderia
                                                )}
                                                valueExpr={"idLavanderia"}
                                                displayExpr={"denominacion"}
                                                value={
                                                    isPersonalMigrado
                                                        ? personaSel.idLavanderia
                                                        : lavanderiaSel?.idLavanderia
                                                }
                                                readOnly={isPersonalMigrado}
                                                isValid={isLavanderiaSelValid}
                                                mode="single"
                                                searchEnabled={true}
                                                searchExpr={"denominacion"}
                                                searchMode="contains"
                                                stylingMode="underlined"
                                                onItemClick={this.dxSelectBox_onItemClick_Lavanderia}
                                            />
                                        </Col>
                                        <Col className="font-size-xs" xs={"auto"}>
                                            <i className="icon_ManoObra mr-2" />
                                            <span>{this.getTrad("turno")}</span>
                                        </Col>
                                        <Col xs={2}>
                                            <SelectBox
                                                ref={this.dxSelectBoxRef_tblTurno}
                                                dataSource={tblTurno.filter(
                                                    (x) =>
                                                        x.idLavanderia ===
                                                        (isPersonalMigrado
                                                            ? personaSel.idLavanderia
                                                            : lavanderiaSel?.idLavanderia)
                                                )}
                                                value={isPersonalMigrado ? personaSel.idTurno : turnoSel?.idTurno}
                                                readOnly={isPersonalMigrado}
                                                isValid={isTurnoSelValid}
                                                mode="single"
                                                valueExpr={"idTurno"}
                                                displayExpr={"denominacion"}
                                                stylingMode="underlined"
                                                onItemClick={this.dxSelectBox_onItemClick_Turno}
                                            />
                                        </Col>
                                    </Row>
                                )}

                                <NewYearCalendar
                                    height={"95%"}
                                    eventos={cuadrante}
                                    eventoRangeExpr={"idEstado"}
                                    dateExpr={"fecha"}
                                    colorExpr={"colorHexa"}
                                    year={year}
                                    displayDisabledDataSource={true}
                                    enableRangeSelection={true}
                                    disabledDays={disabledDays}
                                    minDate={calendarMinDate}
                                    minDateRestrictive={personaSel?.fechaAlta}
                                    maxDateRestrictive={personaSel?.fechaBaja}
                                    hideToday={false}
                                    dayRender={this.Calendar_dayRender}
                                    onDayEnter={this.Calendar_onDayEnter}
                                    onDayLeave={this.Calendar_onDayLeave}
                                    onDayClick={this.Calendar_onDayClick}
                                    onRangeSelected={this.Calendar_onRangeSelected}
                                    onYearChanged={this.Calendar_onYearChanged}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className={"loader-container absolute shading"}>
                                    <div className="loader-container-inner">
                                        <div className="text-center">
                                            <Loader type="line-scale" />
                                        </div>
                                        <h6 className="mt-3">{this.getTrad("cargando")}...</h6>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                        {tooltipTarget && (
                            <Tooltip
                                target={tooltipTarget}
                                visible={tooltipTarget !== null}
                                onContentReady={this.dxTooltip_onContentReady}
                            >
                                <div>
                                    {targetEvents != null ? (
                                        targetEvents?.reverse().map((targetEvent, index) => {
                                            return (
                                                <div
                                                    key={`targetEvent-${index}`}
                                                    className="tooltipEvento px-5 py-3"
                                                    style={{
                                                        backgroundColor: targetEvent?.colorHexa,
                                                        color:
                                                            getBrightnessColor(targetEvent?.colorHexa) >= 200 ||
                                                            targetEvent?.colorHexa === "transparent"
                                                                ? "#5C6476"
                                                                : "#FFF",
                                                    }}
                                                >
                                                    {targetEvent?.idEstado === 3 || targetEvent?.idEstado === 4 ? ( // DÍAS TRABAJADOS
                                                        <>
                                                            <span className="underline_bottom_dotted pb-2 mb-2">
                                                                {
                                                                    tblLavanderia.find(
                                                                        (x) =>
                                                                            x.idLavanderia === targetEvent?.idLavanderia
                                                                    )?.denominacion
                                                                }
                                                            </span>
                                                            <span>
                                                                {this.getTrad(
                                                                    tblCalendarioPersonal_estado.find(
                                                                        (x) => x.idEstado === targetEvent?.idEstado
                                                                    )?.traduccion
                                                                )}
                                                            </span>
                                                            <span>
                                                                {capitalize(
                                                                    tblTurno.find(
                                                                        (x) => x.idTurno === targetEvent?.idTurno
                                                                    )?.denominacion,
                                                                    true
                                                                )}
                                                            </span>
                                                            <span>
                                                                {this.formateadorHora(targetEvent?.horaEntrada)} -{" "}
                                                                {this.formateadorHora(targetEvent?.horaSalida)}
                                                            </span>
                                                        </>
                                                    ) : targetEvent?.idEstado === 8 || targetEvent?.idEstado === 10 ? ( // FESTIVOS Y CIERRES
                                                        <>
                                                            <span className="underline_bottom_dotted pb-2 mb-2">
                                                                {
                                                                    tblLavanderia.find(
                                                                        (x) =>
                                                                            x.idLavanderia === targetEvent?.idLavanderia
                                                                    )?.denominacion
                                                                }
                                                            </span>
                                                            <span>
                                                                {this.getTrad(
                                                                    tblCalendarioPersonal_estado.find(
                                                                        (x) => x.idEstado === targetEvent?.idEstado
                                                                    )?.traduccion
                                                                )}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        // OTROS ESTADOS
                                                        <span>
                                                            {this.getTrad(
                                                                tblCalendarioPersonal_estado.find(
                                                                    (x) => x.idEstado === targetEvent?.idEstado
                                                                )?.traduccion
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        })
                                    ) : (
                                        // SIN EVENTOS
                                        <div className="tooltipEvento px-5 py-3">
                                            <span>{"No hay previsiones para este día"}</span>
                                        </div>
                                    )}
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                        <Button
                            disabled={seleccion == null || seleccion.eventos.length === 0}
                            icon={isImportacion || isExportacion ? "export" : "edit"}
                            text={isImportacion || isExportacion ? "Guardar" : "Editar selección"}
                            type="success"
                            onClick={this.dxButton_onClick_Accion}
                        />
                    </ToolbarItem>
                    <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                        <Button text={this.getTrad("cerrar")} onClick={this.dxPopup_onHiding_Calendario} />
                    </ToolbarItem>
                </Popup>
            </>
        );
    }

    // #region PROPS GENERALES

    dxPopup_onHiding_Opciones = () => {
        this.setState({
            isVisible: false,
        });
    };

    dxPopup_onHidden_Opciones = () => {
        this.setState({
            modoEditor: null,
            updateData: {},
            isExportarEditar: false,
            seleccion: null,
            isImportacion: null,
            isExportacion: null,
            idPersona: null,
            lavanderiaImportar: null,

            isEditTurnoValid: true,
            isEditHoraEntradaValid: true,
            isEditHoraSalidaValid: true,
            isEditEstadoValid: true,
            isLavanderiaSelValid: true,
            isTurnoSelValid: true,
        });

        this.dxPopup.option("disabled", false);
        this.dxList_tblPersona.option("searchValue", null);
    };

    dxPopup_onContentReady_Opciones = ({ component, element }) => {
        component.content().css("padding", "0");
    };

    dxButton_onClick_Atras = () => {
        this.setState({
            selectedView: this.state.selectedView - 1,
        });
    };

    dxButton_onClick_Accion = () => {
        this.checkMigracionValidation();

        const { isLavanderiaSelValid, isTurnoSelValid, isImportacion, isExportacion } = this.state;

        if ((isLavanderiaSelValid && isTurnoSelValid) || isImportacion || !isExportacion) {
            isImportacion || isExportacion
                ? this.dxButton_onClick_MigrarLavanderia()
                : this.dxButton_onClick_EditarPrevisiones();
        }
    };

    // #endregion

    // #region PROPS IMPORTAR

    dxSelectBox_onSelectionChanged_Importar = ({ selectedItem }) => {
        this.setState({ lavanderiaImportar: selectedItem });
        this.dataSource_tblPersona.pageIndex(0);
        this.dataSource_tblPersona.reload();
    };

    dxList_onOptionChanged_Persona = (e) => {
        if (e.name === "searchValue") {
            this.setState({ dxListPersona_seachValue: e.value });
        }
    };

    dxList_onItemClick_Persona = ({ itemData, component }) => {
        this.setState({
            idPersona: itemData.idPersona,
            isCalendarVisible: true, // Calendario
            loading: true,
            seleccion: null,
        });

        this.cargarCalendarioConLavanderia();
        component.unselectAll();
    };

    // #endregion

    // #region PROPS LISTA EXPORTAR O EDITAR

    dxList_itemRender_ExportarEditar = (data, index, element) => {
        return (
            <div className="container_spanCentrado" style={{ height: 70 }}>
                <div className="font-size p-0">{data.text.toUpperCase()}</div>
            </div>
        );
    };

    dxList_itemRender_ElegirAccion = (data, index, element) => {
        return (
            <div className="container_spanCentrado" style={{ height: 55 }}>
                <div className="font-size p-0">{data.text.toUpperCase()}</div>
            </div>
        );
    };

    dxList_onItemRendered_ExportarEditar = ({ itemData, itemElement }) => {
        const _this = this;
        if (itemData.disabled) {
            itemElement.removeClass("dx-state-disabled");
            itemElement.addClass("custom-disabled");
            itemElement.parent().append(
                $("<div />").dxTooltip({
                    height: "42px",
                    showEvent: "dxhoverstart",
                    hideEvent: "dxhoverend",
                    position: "right",
                    contentTemplate: function (contentElement) {
                        itemData.text === _this.enum_modoCalendario[0].text
                            ? contentElement.html("No tienes acceso a más lavanderías.")
                            : contentElement.html("No se puede recalcular una previsión sin fijar.");
                    },
                    target: itemElement,
                })
            );
        }
    };

    dxList_onItemClick_Exportar = ({ itemData, component }) => {
        if (!itemData.disabled) {
            this.setState({
                isCalendarVisible: true, // Calendario
                isExportacion: itemData.value,
                loading: true,
                seleccion: null,
            });

            itemData.value ? this.cargarCalendarioConLavanderia() : this.cargarCalendario();
            component.unselectAll();
        }
    };

    dxList_onItemClick_Editar = ({ itemData, component }) => {
        if (!itemData.disabled) {
            if (itemData.value === "recalcular") {
                // Recalcular

                this.dxPopup.option("disabled", true);

                const { seleccion } = this.state;

                patchCuadrantePersonalRecalcular(seleccion.eventos.filter((x) => x.isFijado)).then((serverResponse) => {
                    this.props.CuadranteContext.loadAll();
                    this.setState({
                        isVisible: false,
                    });
                });
            } else {
                this.setState({
                    selectedView: 2, // Editor
                    modoEditor: itemData.value,
                    updateData: {},
                });
            }
        }
        component.unselectAll();
    };

    dxForm_dxSelectBox_onSelectionChanged_Turno = ({ selectedItem }) => {
        this.setState({
            updateData: {
                idTurno: selectedItem.idTurno,
                horaEntrada: selectedItem.horaEntrada,
                horaSalida: selectedItem.horaSalida,
            },
        });
    };

    dxDateBox_onValueChanged_horaEntrada = ({ value }) => {
        if (value != null)
            this.setState({
                updateData: {
                    ...this.state.updateData,
                    horaEntrada: typeof value === "string" ? value : dateTime_hourMinute(value),
                },
            });
    };

    dxDateBox_onValueChanged_horaSalida = ({ value }) => {
        if (value != null)
            this.setState({
                updateData: {
                    ...this.state.updateData,
                    horaSalida: typeof value === "string" ? value : dateTime_hourMinute(value),
                },
            });
    };

    dxForm_dxSelectBox_onSelectionChanged_Estado = ({ selectedItem }) => {
        this.setState({
            updateData: {
                idEstado: selectedItem.idEstado,
            },
        });
    };

    dxButton_onClick_Guardar = (e) => {
        this.dxPopup.option("disabled", true);
        this.checkEditValidation();

        const {
            isEditTurnoValid,
            isEditHoraEntradaValid,
            isEditHoraSalidaValid,
            isEditEstadoValid,
            seleccion,
            updateData,
        } = this.state;
        if ((isEditTurnoValid && isEditHoraEntradaValid && isEditHoraSalidaValid) || isEditEstadoValid) {
            const payload = seleccion.eventos.map((x) => {
                return {
                    ...x,
                    idEstado: !x.isFijado ? null : x.idEstado,
                    ...updateData,
                };
            });

            postChangesIntoCuadrantePersonal(payload).then((serverResponse) => {
                this.props.CuadranteContext.loadAll();
                this.dxPopup_onHiding_Opciones();
            });
        }
    };

    // #endregion

    // #region PROPS CALENDARIO

    dxPopup_onHiding_Calendario = () => {
        this.setState({
            isCalendarVisible: false,
            isVisible: false,
            tooltipTarget: null,
        });
    };

    dxPopup_onHidden_Calendario = () => {
        this.setState({
            isCalendarVisible: false,
            lavanderiaSel: null,
            year: new Date().getFullYear(),
            turnoSel: null,
        });
    };

    dxSelectBox_onItemClick_Lavanderia = ({ itemData }) => {
        if (itemData?.idLavanderia !== this.state.lavanderiaSel?.idLavanderia) {
            this.setState({ lavanderiaSel: itemData, turnoSel: null });
            // if (selectedItem.idLavanderia === this.props.lavanderia.idLavanderia) {
            this.cargarCalendarioConLavanderia();
            // }
        }
    };

    dxSelectBox_onItemClick_Turno = ({ itemData }) => {
        if (itemData?.idTurno !== this.state.turnoSel?.idTurno) {
            this.setState({ turnoSel: itemData });
            this.cargarCalendarioConLavanderia();
        }
    };

    Calendar_dayRender = (element, date, eventos) => {
        const prevision = eventos[0];
        let parentElement = $(element).parent();

        let hasCierre = eventos.filter((x) => x.idEstado === 10).length > 0;

        parentElement.css("background-color", prevision.color);

        parentElement.css(
            "color",
            getBrightnessColor(prevision.color) >= 200 || prevision.color === "transparent" ? "#5C6476" : "#FFF"
        );

        if (hasCierre) parentElement.addClass("diaCierre");
        if (!prevision.isFijado && prevision.idEstado !== 10 && prevision.idEstado !== 8)
            parentElement.addClass("stripe");
        if (prevision.tipoCelda.length > 0) parentElement.addClass(prevision.tipoCelda);
    };

    Calendar_onDayEnter = (e) => {
        this.tooltipDelay = setTimeout(() => {
            const evento = e.eventos.length > 0 ? e.eventos : null;
            this.setState({
                tooltipTarget: `#${$(e.element).attr("id")}`,
                targetEvents: evento,
            });
        }, 100);
    };

    Calendar_onDayLeave = (e) => {
        clearTimeout(this.tooltipDelay);
        this.setState({ tooltipTarget: null, targetEvents: null });
    };

    Calendar_onDayClick = (e) => {
        e.startDate = e.date;
        e.endDate = e.date;
        this.Calendar_onRangeSelected(e);
    };

    Calendar_onRangeSelected = (e) => {
        let { startDate, endDate, eventos } = e;

        this.setState({
            seleccion: {
                startDate: startDate,
                endDate: endDate,
                eventos: eventos,
            },
        });
    };

    Calendar_onYearChanged = (event) => {
        const { year, isImportacion, isExportacion } = this.state;

        if (year !== event.currentYear) {
            this.setState({
                year: event.currentYear,
                loading: true,
                seleccion: null,
            });
            isImportacion || isExportacion ? this.cargarCalendarioConLavanderia() : this.cargarCalendario();
        }
    };

    dxButton_onClick_MigrarLavanderia = () => {
        const { seleccion, lavanderiaSel, turnoSel, isImportacion, isExportacion, personaSel } = this.state;
        const { lavanderia, turno } = this.props;

        const payload = [];

        seleccion.eventos.forEach((x) => {
            if (!payload.find((y) => x.fecha.getTime() == y.fecha.getTime())) {
                payload.push({
                    ...x,
                    idLavanderia: isImportacion
                        ? lavanderia.idLavanderia
                        : personaSel?.idLavanderia !== lavanderia.idLavanderia
                        ? personaSel.idLavanderia
                        : lavanderiaSel.idLavanderia,
                    idTurno: isImportacion ? turno.idTurno : turnoSel?.idTurno,
                    idEstado: null,
                    horaSalida: null,
                    horaEntrada: null,
                    idPosicionNAreaLavanderiaNLavanderia: null,
                });
            }
        });

        postChangesIntoCuadrantePersonal(payload).then((serverResponse) => {
            this.props.CuadranteContext.loadAll();
            this.dxPopup_onHiding_Opciones();
            this.dxPopup_onHiding_Calendario();

            if (isExportacion) {
                dxMensajePregunta(
                    `<div style="display: flex; align-items: center;">
                    <i class="dx-icon-warning m-2" style="font-size: 5rem; color: var(--yellow);"></i>
                    <span style="font-size: 1.5rem;">Las previsiones deben ser fijadas por la lavandería destinataria</span>
                </div>`,
                    [[this.getTrad("aceptar"), () => {}, "default"]]
                );
            } else {
                notify({
                    message: this.getTrad("aviso_C_RegistroActualizado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }
        });
    };

    dxButton_onClick_EditarPrevisiones = () => {
        const { seleccion } = this.state;

        const isRecalcularDisabled = seleccion.eventos.filter((x) => x.isFijado).length === 0;
        this.enum_modoEdicion[0].disabled = isRecalcularDisabled;

        this.dxList_Edicion.reload();

        this.setState({
            isCalendarVisible: false,
            selectedView: 1, // SELECTOR OPCIONES EDICIÓN
        });
    };

    dxTooltip_onContentReady = ({ component, element }) => {
        component.content().css("padding", "0");
        component.content().parent().css("border-radius", "15px");
    };

    // #endregion
}

export default CalendarioCuadrante;
