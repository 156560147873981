import { formatDate_noTime_parameter } from "helpers";
import { getDefaultMonedaId } from "pages/Administracion/shared/functions";
import { empresaPolarierSel } from "pages/Administracion/shared/model";

export const getClearFactura = () => {
    return {
        codigo: null,
        fecha: null,
        idAdmFactura_Estado: 1,
        idAdmProveedor: null,
        idMoneda: getDefaultMonedaId(),
        idAdmTipoCambio: 1,
        idTipoFactura: null,
        tasaCambio: 1,
        descuento: 0,
        idAdmTipoDescuento: 1,
        idAdmFormaPago: null,
        idAdmCondicionPagoCobro: null,
        idIncoterm: null,
        observaciones: null,
        numFacturaProveedor: null,
        idTipoAlbaran: null,
        idAdmCentroCoste: null,
        idAdmElementoPEP: null,
        idAdmAlbaranCompra: [],
        tblAdmConceptoCompra: [],
        idEmpresaPolarier: empresaPolarierSel.idEmpresaPolarier,
    };
};

export const payloadBuilder = (formData) => {
    if (typeof formData.fecha == "object") {
        formData.fecha = formatDate_noTime_parameter(formData.fecha);
    }
    formData.tblAdmConceptoCompra = formData.tblAdmConceptoCompra.map((concepto) => {
        delete concepto.tblAdmConceptoCompra;
        return concepto;
    });
    formData.idAdmAlbaranCompra = formData.idAdmAlbaranCompra.map((x) => x.idAdmAlbaranCompra);
    formData.idEmpresaPolarier = empresaPolarierSel.idEmpresaPolarier;
    return formData;
};
