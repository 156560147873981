import React from "react";

//Css
import "../css.scss";
import { TextArea } from "devextreme-react";
import { deepClone, getTrad } from "helpers";

class PreguntaDescripcion extends React.Component {
  setTexto = (e) => {
    const { onValueChange, respuestas, dataSource } = this.props;
    const idPregunta = dataSource.idPregunta;

    const texto = e.value;
    const resp = { idPregunta, texto };

    const hayRespuesta = respuestas.find((x) => x.idPregunta === idPregunta);
    let respuestas_ = deepClone(respuestas);

    if (hayRespuesta) {
      respuestas_ = respuestas_.filter((x) => x.idPregunta !== idPregunta);
    }
    if (texto.length > 0) {
      respuestas_.push(resp);
    }

    onValueChange({ respuestasUsuario: respuestas_ });
  };

  render() {
    const { dataSource, respuestas } = this.props;
    const descripcionPregunta =
      respuestas?.find((x) => x.idPregunta === dataSource.idPregunta)?.texto ??
      null;

    return (
      <div className="flex-1">
        <TextArea
          height="100%"
          width="100%"
          value={descripcionPregunta}
          onValueChanged={this.setTexto}
          stylingMode="filled"
          className="bg-white"
          placeholder={getTrad("placeHolderIntroduceText")}
        />
      </div>
    );
  }
}

export default PreguntaDescripcion;
