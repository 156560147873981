import React, { Fragment } from "react";
import { connect } from "react-redux";
import { errorHandler, getTrad, authHeader, formatDate_noTime_parameter } from "../../../helpers";
// CSS
import "./Css.scss";

// Components
import Operativo from "../Operativo";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import SmartViewContext, { SVContext } from "../SmartView/components/SmartViewContext";
import SmartViewLayout from "../SmartView/Layout";
import PageTitle from "layout/AppMain/PageTitle";
import LastUpdateClock from "components/LastUpdateClock";
import UserBox from "../../../layout/AppHeader/Components/UserBox";
import ODataContext from "devextreme/data/odata/context";
import ResumenOperarios from "../SmartView/components/ResumenOperarios";
import { connectionConstants } from "../../../constants";
import { signalRCoreService } from "services";

const tiempoCambioPagina = 25000;

class LaundryDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = { dxGallery_selectedIndex: 0 };

        this.SmartView_REF = React.createRef();
    }

    // #region Lifecycle

    componentDidMount() {
        this.context_spSelectPersonasKgHora_StartTimer();

        this.startSignalR();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lavanderia.idLavanderia !== this.props.lavanderia.idLavanderia) {
            this.stopSignalR();
            this.startSignalR();
        }
    }

    componentWillUnmount() {
        this.stopSignalR();
        this.context_spSelectPersonasKgHora_StopTimer();
        clearInterval(this.interval_customized_slideshowDelay);
    }

    // #endregion

    // #region SignalR

    startSignalR = () => {
        const { lavanderia } = this.props;
        signalRCoreService.joinGroup("AppLaundryDashboard_" + lavanderia.idLavanderia, [
            {
                type: "AppLaundryDashboard/signalR_refresh",
                listener: () => {
                    window.location.reload();
                },
            },
        ]);
    };

    stopSignalR = () => {
        const { lavanderia } = this.props;
        signalRCoreService.leaveGroup("AppLaundryDashboard_" + lavanderia.idLavanderia);
    };

    // #endregion

    elementAttr_tabPanelConfig = { class: "no-header  overflow_visible" };

    // #region Datasources y context

    context_spSelectPersonasKgHora = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "Dashboard/SmartView/spSelectPersonasKgHora",
        entities: {
            spSelectPersonasKgHora: {},
        },
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            this.context_spSelectPersonasKgHora_beforeSend(request);
        },
    });

    context_spSelectPersonasKgHora_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    context_spSelectPersonasKgHora_load = (tblPersona) => {
        let { lavanderia } = this.props;
        const params = { idLavanderia: lavanderia.idLavanderia };
        this.context_spSelectPersonasKgHora.invoke("", params, "GET").then((data) => {
            const { operariosActivosProduccion, kgProduccion, hora } = data[0];
            const produccionKgHoraActual = kgProduccion / operariosActivosProduccion;
            if (tblPersona) {
                this.setState({
                    operariosTotal: tblPersona.length,
                    operariosActuales: tblPersona.filter((x) => x.idPosicionNAreaLavanderiaNLavanderia).length,
                    operariosInactivos: tblPersona.filter((x) => !x.idPosicionNAreaLavanderiaNLavanderia),
                    operariosActivosProduccion: operariosActivosProduccion,
                    kgProduccion: kgProduccion,
                    produccionKgHoraActual: Number.isNaN(produccionKgHoraActual)
                        ? 0
                        : Math.round(produccionKgHoraActual),
                    hora: hora,
                });
            } else {
                this.setState({
                    operariosActivosProduccion: operariosActivosProduccion,
                    kgProduccion: kgProduccion,
                    produccionKgHoraActual: Number.isNaN(produccionKgHoraActual)
                        ? 0
                        : Math.round(produccionKgHoraActual),
                    hora: hora,
                });
            }
        });
    };

    context_spSelectPersonasKgHora_StartTimer = () => {
        let now = new Date();
        let next = new Date();
        next.setHours(now.getHours() + 1, 1, 0, 0);

        this.spSelectPersonasKgHora_Timer = setTimeout(() => {
            this.context_spSelectPersonasKgHora_load();
            this.context_spSelectPersonasKgHora_StartTimer();
        }, next.getTime() - now.getTime());
    };

    context_spSelectPersonasKgHora_StopTimer = () => {
        if (this.spSelectPersonasKgHora_Timer) {
            clearInterval(this.spSelectPersonasKgHora_Timer);
        }
    };

    // #endregion

    render() {
        const { enableFullScreen } = this.props;
        const {
            operariosTotal,
            operariosActuales,
            operariosInactivos,
            operariosActivosProduccion,
            kgProduccion,
            produccionKgHoraActual,
            hora,
        } = this.state;

        return (
            <Fragment>
                <SmartViewContext onRefreshPersonalActivo={this.context_spSelectPersonasKgHora_load} paginado={true}>
                    <SVContext.Consumer>
                        {({ layoutPages }) => {
                            return (
                                <TabPanel
                                    height={"100%"}
                                    width={"100%"}
                                    selectedIndex={this.state.dxGallery_selectedIndex}
                                    onContentReady={this.customized_slideshowDelay}
                                    animationEnabled={true}
                                    deferRendering={false}
                                    elementAttr={this.elementAttr_tabPanelConfig}
                                >
                                    <TabPanelItem>
                                        <Operativo title={getTrad("dashboardOperativo")} />
                                    </TabPanelItem>
                                    {layoutPages.map((x, pagina) => {
                                        return (
                                            <TabPanelItem>
                                                <PageTitle
                                                    heading={getTrad("SmartView")}
                                                    postHeading={[
                                                        <ResumenOperarios
                                                            enableFullScreen={enableFullScreen}
                                                            operariosTotal={operariosTotal}
                                                            operariosActuales={operariosActuales}
                                                            operariosInactivos={operariosInactivos}
                                                            operariosActivosProduccion={operariosActivosProduccion}
                                                            kgProduccion={kgProduccion}
                                                            produccionKgHoraActual={produccionKgHoraActual}
                                                            hora={hora}
                                                        />,
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="header-btn-lg">
                                                                <LastUpdateClock fecha={new Date()} />
                                                            </div>
                                                            {enableFullScreen && <UserBox />}
                                                        </div>,
                                                    ]}
                                                />
                                                <SmartViewLayout pagina={pagina} />
                                            </TabPanelItem>
                                        );
                                    })}
                                </TabPanel>
                            );
                        }}
                    </SVContext.Consumer>
                </SmartViewContext>
            </Fragment>
        );
    }

    customized_slideshowDelay = (e) => {
        const numItems = e.component._userOptions.items.length;

        this.interval_customized_slideshowDelay = setInterval(() => {
            let newIndex = 0;
            if (this.state.dxGallery_selectedIndex === 0) {
                newIndex = this.state.dxGallery_selectedIndex + 1;
            } else if (this.state.dxGallery_selectedIndex + 1 < numItems) {
                newIndex = this.state.dxGallery_selectedIndex + 1;
            } else {
                newIndex = 0;
            }
            this.setState({ dxGallery_selectedIndex: newIndex });
        }, tiempoCambioPagina);
    };
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    user: state.Authentication.user,
    enableFullScreen: state.ThemeOptions.enableFullScreen,
    resolucion: state.Global.resolucion,
});

export default connect(mapStateToProps)(LaundryDashboard);
