import React from "react";

import Popup, { ToolbarItem } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";

import { getTrad } from "helpers";

const SharedPopupHistorico = ({
  visible,
  title,
  centerTitle,
  onHiding,
  onSave,
  children,
}) => {
  return (
    <Popup
      width={700}
      height={500}
      title={title}
      visible={visible}
      onHiding={onHiding}
    >
      {children}
      {centerTitle ? (
        <ToolbarItem
          location={"center"}
          toolbar={"top"}
          text={centerTitle}
        />
      ) : null}
      <ToolbarItem widget={"dxButton"} location={"after"} toolbar={"bottom"}>
        <Button text={getTrad("aceptar")} onClick={onSave} />
      </ToolbarItem>
    </Popup>
  );
};

export default SharedPopupHistorico;
