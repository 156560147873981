import React from "react";

import Loader from "react-loaders";

import { getTrad } from "helpers";

const OverlayCalendarioPersonal = ({ isLoading, children }) => {
  return (
    <>
      {isLoading
        ? <div className={"loader-container absolute shading"}>
          <div className={"loader-container-inner"}>
            <div className={"text-center"}>
              <Loader type={"line-scale"} />
            </div>
            <h6 className={"mt-3"}>{getTrad("cargando")}...</h6>
          </div>
        </div>
        : null
      }
      {children}
    </>
  );
}

export default OverlayCalendarioPersonal;