import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "devextreme-react";
import ComponentDrawer from "./componentDrawer";
import Map from "./map";
import { VisorRutasProvider } from "./visorRutasContext";

export default function ReactMap() {
  const { lavanderia } = useSelector((state) => state.Global);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  return (
    <VisorRutasProvider lavanderia={lavanderia}>
      <Drawer
        style={{ borderRadius: 5 }}
        opened={isDrawerOpened}
        openedStateMode={"overlap"}
        position={"left"}
        revealMode={"slide"}
        component={() => {
          return (
            <ComponentDrawer
              isDrawerOpened={isDrawerOpened}
              onSetIsDrawerOpened={setIsDrawerOpened}
            />
          );
        }}
      >
        <Map onSetIsDrawerOpened={setIsDrawerOpened} />
      </Drawer>
    </VisorRutasProvider>
  );
}
