export const check = {
  v: "5.5.7",
  meta: { g: "LottieFiles AE 0.1.20", a: "", k: "", d: "", tc: "" },
  fr: 50,
  ip: 0,
  op: 99,
  w: 1000,
  h: 1000,
  nm: "main comp",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "checkmark",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        a: { a: 0, k: [-323, 834, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-546, 850],
                    [-408, 988],
                    [-100, 680],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 60, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.167], y: [1] },
                o: { x: [0.923], y: [0] },
                t: 43,
                s: [0],
              },
              { t: 56, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "ball",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.192], y: [1] },
              o: { x: [0.772], y: [0] },
              t: 0,
              s: [0],
            },
            { t: 6, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.299, y: 1 },
              o: { x: 0.65, y: 0 },
              t: 0,
              s: [500, 176, 0],
              to: [0, 95.167, 0],
              ti: [0, -54, 0],
            },
            {
              i: { x: 0.378, y: 1 },
              o: { x: 0.627, y: 0 },
              t: 21,
              s: [500, 747, 0],
              to: [0, 54, 0],
              ti: [0, 41.167, 0],
            },
            { t: 33, s: [500, 500, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [53, 57, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.178, 0.178, 0.667], y: [1, 1, 1] },
              o: { x: [0.776, 0.776, 0.333], y: [0, 0, 0] },
              t: 33,
              s: [20, 20, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 50,
              s: [115, 115, 100],
            },
            { t: 56, s: [110, 110, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 18,
                    s: [758, 1393],
                  },
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    t: 21,
                    s: [758, 1114],
                  },
                  { t: 24, s: [758, 1393] },
                ],
                ix: 2,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.285, y: 1 },
                    o: { x: 0.788, y: 0 },
                    t: 18,
                    s: [0, 0],
                    to: [0, 36.5],
                    ti: [0, 0],
                  },
                  {
                    i: { x: 0.272, y: 1 },
                    o: { x: 0.801, y: 0 },
                    t: 21,
                    s: [0, 219],
                    to: [0, 0],
                    ti: [0, 36.5],
                  },
                  { t: 24, s: [0, 0] },
                ],
                ix: 3,
              },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.22745098039215686, 0.7686274509803922, 0.49019607843137253,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [53, 57], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 55.128], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "end shadow",
      sr: 1,
      ks: {
        o: { a: 0, k: 20, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 500, 0], ix: 2 },
        a: { a: 0, k: [53, 57, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.007, 0.007, 0.667], y: [1, 1, 1] },
              o: { x: [0.776, 0.776, 0.333], y: [0, 0, 0] },
              t: 33,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.35, 0.35, 0.833], y: [1, 1, 1] },
              o: { x: [0.732, 0.732, 0.167], y: [0, 0, 0] },
              t: 50,
              s: [130, 130, 100],
            },
            { t: 56, s: [110, 110, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [758, 1393], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [53, 57], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 55.128], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "ball shadow",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.213], y: [1] },
              o: { x: [0.871], y: [0] },
              t: 16,
              s: [0],
            },
            {
              i: { x: [0.264], y: [1] },
              o: { x: [0.891], y: [0] },
              t: 21,
              s: [20],
            },
            { t: 26, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 826, 0], ix: 2 },
        a: { a: 0, k: [6, 356, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0.716, 0.716, 0.333], y: [0, 0, 0] },
              t: 16,
              s: [45, 45, 100],
            },
            {
              i: { x: [0.326, 0.326, 0.667], y: [1, 1, 1] },
              o: { x: [0.813, 0.813, 0.333], y: [0, 0, 0] },
              t: 21,
              s: [100, 100, 100],
            },
            { t: 26, s: [70, 70, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [152, 60], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [6, 356], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 250,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
