import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { impresionActions, loadPanelActions, globalActions } from "../../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import OpcionesTitle from "../../../../layout/AppOpciones/Title";
import OpcionesContainer from "../../../../layout/AppOpciones/Container";

//Layout
import PageTitle from "../../../../layout/AppMain/PageTitle";

import { connectionConstants } from "../../../../constants";
import {
    getNombreFormulario,
    errorHandler,
    authHeader,
    history,
    getTrad,
    formatDate_parameter,
    formatDate,
    formatNumber,
    dayDiff,
} from "../../../../helpers";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import $ from "jquery";
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import AppSelector from "../../../../components/AppSelector/AppSelector";
import { Popover } from "devextreme-react/popover";
import Chart, {
    CommonSeriesSettings,
    Legend,
    Series,
    ValueAxis,
    ArgumentAxis,
    Tooltip as TooltipChart,
    Point,
    Aggregation,
    LoadingIndicator,
} from "devextreme-react/chart";
import {
    CircularGauge,
    Geometry,
    Scale,
    Tick,
    RangeContainer,
    Label,
    ValueIndicator,
    Margin,
    Size,
} from "devextreme-react/circular-gauge";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Paging,
    Summary,
    TotalItem,
    Selection,
    LoadPanel,
    Export,
    Pager,
} from "devextreme-react/data-grid";
import { Popup, ToolbarItem as ToolbarItemPopup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { Button, RadioGroup, DropDownButton } from "devextreme-react";
import { Cookies } from "react-cookie-banner";

import { saveAs } from "file-saver";
import ExcelJS, { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";

//Selector
import { locale } from "devextreme/localization";

class InfReposicionesGeneral extends React.Component {
    constructor(props) {
        super(props);
        let { param_paginaSecundaria } = props;

        this.idsRepartos = [];
        this.idsAbonos = [];
        this.familiaSel = null;

        this.dxDataGrid_prendas_rendered = false;
        this.dxDataGrid_repartos_rendered = false;

        let fechaSel_redireccion;
        if (param_paginaSecundaria != null) {
            fechaSel_redireccion = {
                key: "selection",
                idTipoCalendario: 0,
                startDate: param_paginaSecundaria.fechaDesde,
                endDate: param_paginaSecundaria.fechaHasta,
            };
        }

        this.state = {
            showSelector: true,
            tipoExport: null,
            fechaDesde_redireccion: param_paginaSecundaria != null ? param_paginaSecundaria.fechaDesde : null,
            fechaHasta_redireccion: param_paginaSecundaria != null ? param_paginaSecundaria.fechaHasta : null,
            fechaSel_redireccion: param_paginaSecundaria != null ? fechaSel_redireccion : null,
            fechaSel: null,
            minDate: null,
            maxDate: null,
            tipoTablaSel: 1,
            prendaSel: null,
            is_abono: false,
            datasource_familias: [],
            dxPopup_noDatos_isVisible: false,
        };

        // REFERENCIAS
        this.dxCircularGauge_compa_REF = React.createRef();
        this.dxChart_compañiaSel_REF = React.createRef();
        this.dxDataGrid_compañiaSel_REF = React.createRef();
        this.dxPopover_REF = React.createRef();
        this.dxDataGrid_prendas_REF = React.createRef();
        this.dxDataGrid_repartos_REF = React.createRef();

        this.hideSelector = this.hideSelector.bind(this);
        this.setParams = this.setParams.bind(this);
        this.setReport = this.setReport.bind(this);
        this.closeReport = this.closeReport.bind(this);
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);
        this.loadPanel_show();

        //DATASOURCES
        this.datasource_infReposicionesGeneral_Agrupado_Compañias_beforeSend =
            this.datasource_infReposicionesGeneral_Agrupado_Compañias_beforeSend.bind(this);
        this.datasource_infReposicionesGeneral_Agrupado_Entidades_beforeSend =
            this.datasource_infReposicionesGeneral_Agrupado_Entidades_beforeSend.bind(this);
        this.datasource_infReposicionesGeneral_Agrupado_Fechas_beforeSend =
            this.datasource_infReposicionesGeneral_Agrupado_Fechas_beforeSend.bind(this);
        this.datasource_infReposicionesGeneral_filtroTiempo_beforeSend =
            this.datasource_infReposicionesGeneral_filtroTiempo_beforeSend.bind(this);
        this.datasource_familias_postProcess = this.datasource_familias_postProcess.bind(this);
        this.datasource_familias_map = this.datasource_familias_map.bind(this);
        this.datasource_spSelectRepartos_beforeSend = this.datasource_spSelectRepartos_beforeSend.bind(this);
        this.datasource_spExportDetallado_beforeSend = this.datasource_spExportDetallado_beforeSend.bind(this);
        this.datasource_spExportDetallado_onLoaded = this.datasource_spExportDetallado_onLoaded.bind(this);

        //EVENTOS
        this.onItemClick_Exportar = this.onItemClick_Exportar.bind(this);
        this.dxChart_compañiaSel_onOptionChanged = this.dxChart_compañiaSel_onOptionChanged.bind(this);
        this.dxChart_customizeTooltip = this.dxChart_customizeTooltip.bind(this);
        this.dxDataGrid_onRowPrepared = this.dxDataGrid_onRowPrepared.bind(this);
        this.dxDataGrid_compañiaSel_onCellHoverChanged = this.dxDataGrid_compañiaSel_onCellHoverChanged.bind(this);
        this.dxDataGrid_onCellClick = this.dxDataGrid_onCellClick.bind(this);
        this.dxDataGrid_circularGauge_calculateCellValue = this.dxDataGrid_circularGauge_calculateCellValue.bind(this);
        this.dxDataGrid_circularGauge_cellRender = this.dxDataGrid_circularGauge_cellRender.bind(this);
        this.dxDataGrid_tipoFactCliente_cellRender = this.dxDataGrid_tipoFactCliente_cellRender.bind(this);
        this.dxDataGrid_importeTotal_cellRender = this.dxDataGrid_importeTotal_cellRender.bind(this);
        this.dxDataGrid_print_cellRender = this.dxDataGrid_print_cellRender.bind(this);
        this.dxDataGrid_kgEntregados_cellRender = this.dxDataGrid_kgEntregados_cellRender.bind(this);
        this.dxDataGrid_compañiaSel_btnPrint_onClick = this.dxDataGrid_compañiaSel_btnPrint_onClick.bind(this);
        this.dxButton_imprimirTodo_onClick = this.dxButton_imprimirTodo_onClick.bind(this);
        this.dateRangePicker_onDateSelected = this.dateRangePicker_onDateSelected.bind(this);
        this.dxButton_limpiarFiltro_onClick = this.dxButton_limpiarFiltro_onClick.bind(this);
        this.dxButton_actualizar_onClick = this.dxButton_actualizar_onClick.bind(this);
        this.datasource_spSelectPrendas_beforeSend = this.datasource_spSelectPrendas_beforeSend.bind(this);
        this.datasource_spSelectPrendas_onLoading = this.datasource_spSelectPrendas_onLoading.bind(this);
        this.datasource_spSelectPrendas_onLoaded = this.datasource_spSelectPrendas_onLoaded.bind(this);
        this.dxDataGrid_selectPrendas_onContentReady = this.dxDataGrid_selectPrendas_onContentReady.bind(this);
        this.dxDataGrid_selectPrendas_onCellHoverChanged = this.dxDataGrid_selectPrendas_onCellHoverChanged.bind(this);
        this.dxDataGrid_selectPrendas_onSelectionChanged = this.dxDataGrid_selectPrendas_onSelectionChanged.bind(this);
        this.dxDataGrid_selectPrendas_onRowPrepared = this.dxDataGrid_selectPrendas_onRowPrepared.bind(this);
        this.dxDataGrid_selectPrendas_onExporting = this.dxDataGrid_selectPrendas_onExporting.bind(this);
        this.dxDataGrid_repartos_onContentReady = this.dxDataGrid_repartos_onContentReady.bind(this);
        this.dxDataGrid_selectRepartos_onCellHoverChanged =
            this.dxDataGrid_selectRepartos_onCellHoverChanged.bind(this);
        this.dxDataGrid_selectRepartos_onSelectionChanged =
            this.dxDataGrid_selectRepartos_onSelectionChanged.bind(this);
        this.dxToolbar_prendas_render = this.dxToolbar_prendas_render.bind(this);
        this.onValueChanged_dxRadioGroup_prendas = this.onValueChanged_dxRadioGroup_prendas.bind(this);
        this.dxRadioGroup_toolbarPrendas_render = this.dxRadioGroup_toolbarPrendas_render.bind(this);
        this.imprimir_prendas_onClick = this.imprimir_prendas_onClick.bind(this);
        this.exportar_prendas_onClick = this.exportar_prendas_onClick.bind(this);
        this.btnAtrasTarjetas_onClick = this.btnAtrasTarjetas_onClick.bind(this);
        this.dxRadioGroup_familias_onValueChanged = this.dxRadioGroup_familias_onValueChanged.bind(this);

        //FUNCIONES
        this.getContent_header = this.getContent_header.bind(this);
        this.getContent_front = this.getContent_front.bind(this);
        this.getContent_back = this.getContent_back.bind(this);
        this.getMinSize = this.getMinSize.bind(this);
        this.refreshContent_back = this.refreshContent_back.bind(this);
        this.CustomizeParameterEditors = this.CustomizeParameterEditors.bind(this);
        this.peso_calculateCellValue = this.peso_calculateCellValue.bind(this);
        this.peso_calculateSortValue = this.peso_calculateSortValue.bind(this);
        this.dxDataGrid_prendas_calculateCustomSummary = this.dxDataGrid_prendas_calculateCustomSummary.bind(this);
        this.cantidad_calculateCellValue = this.cantidad_calculateCellValue.bind(this);
        this.kgEntregados_calculateCellValue = this.kgEntregados_calculateCellValue.bind(this);
        this.dxDataGrid_repartos_calculateCustomSummary = this.dxDataGrid_repartos_calculateCustomSummary.bind(this);
        this.dxDataGrid_selectRepartos_onExporting = this.dxDataGrid_selectRepartos_onExporting.bind(this);
        this.dxDataGrid_selectRepartos_onRowPrepared = this.dxDataGrid_selectRepartos_onRowPrepared.bind(this);
        this.dxButton_toolbarSecundario_exportar = this.dxButton_toolbarSecundario_exportar.bind(this);
        this.dxDataGrid_prendas_render = this.dxDataGrid_prendas_render.bind(this);
        this.dxDataGrid_repartos_render = this.dxDataGrid_repartos_render.bind(this);
        this.carga_opcionesInforme = this.carga_opcionesInforme.bind(this);

        this.aceptar_dxButton = {
            text: getTrad("aceptar"),
            type: "danger",
            onClick: this.dxPopup_noDatos_buttonOnClick,
        };
    }

    get dxCircularGauge_compa() {
        return this.dxCircularGauge_compa_REF.current.instance;
    }

    get dxChart_compañiaSel() {
        return this.dxChart_compañiaSel_REF.current.instance;
    }

    get dxDataGrid_compañiaSel() {
        return this.dxDataGrid_compañiaSel_REF.current.instance;
    }

    get dxPopover() {
        return this.dxPopover_REF.current.instance;
    }

    get dxDataGrid_repartos() {
        return this.dxDataGrid_repartos_REF.current.instance;
    }

    get dxDataGrid_prendas() {
        return this.dxDataGrid_prendas_REF.current.instance;
    }

    //#region DATASOURCES
    datasource_infReposicionesGeneral_Agrupado_Compañias = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infReposicionesGeneral_Agrupado_Compañias",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infReposicionesGeneral_Agrupado_Compañias_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_infReposicionesGeneral_Agrupado_Compañias_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;
        let { fechaSel } = this;

        request.params.idLavanderia = lavanderia ? lavanderia.idLavanderia : null;
        request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
        request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
    }

    datasource_infReposicionesGeneral_Agrupado_Entidades = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infReposicionesGeneral_Agrupado_Entidades",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infReposicionesGeneral_Agrupado_Entidades_beforeSend(request);
            },
            version: 4,
        }),
        sort: [{ selector: "kgEntregados", desc: true }],
    });

    datasource_infReposicionesGeneral_Agrupado_Entidades_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;
        let { entidadSel } = this.state;
        let { fechaSel } = this;

        request.params.idLavanderia = lavanderia ? lavanderia.idLavanderia : null;
        request.params.idEntidad = entidadSel ? entidadSel.idEntidad : null;
        request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
        request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
    }

    datasource_infReposicionesGeneral_Agrupado_Fechas = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infReposicionesGeneral_Agrupado_Fechas",
            key: "arg",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infReposicionesGeneral_Agrupado_Fechas_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_infReposicionesGeneral_Agrupado_Fechas_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;
        let { fechaSel } = this;

        request.params.idLavanderia = lavanderia ? lavanderia.idLavanderia : null;
        request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
        request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
    }

    datasource_infReposicionesGeneral_filtroTiempo = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infReposicionesGeneral_filtroTiempo",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infReposicionesGeneral_filtroTiempo_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: function (data) {
            var item = data[0];
            var datasource = [];
            datasource.push({ arg: item.fechaDesde, value: item.fechaDesde });
            datasource.push({ arg: item.fechaHasta, value: item.fechaHasta });
            return datasource;
        },
    });

    datasource_infReposicionesGeneral_filtroTiempo_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;

        request.params.idLavanderia = lavanderia ? lavanderia.idLavanderia : null;
    }

    datasource_infReposicionesGeneral_Parametro_Familia = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblFamilia",
            key: "idFamilia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infReposicionesGeneral_Parametro_Familia_beforeSend(request);
            },
            version: 4,
        }),
        expand: "tblTraduccion($select=" + locale() + ")",
        map: function (dataItem) {
            return {
                text: dataItem.tblTraduccion[locale()].toUpperCase(),
                value: dataItem.idFamilia,
            };
        },
    });

    datasource_infReposicionesGeneral_Parametro_Familia_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_infReposicionesGeneral_Parametro_TipoFacturacion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoFacturacion",
            key: "idTipoFacturacion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infReposicionesGeneral_Parametro_TipoFacturacion_beforeSend(request);
            },
            version: 4,
        }),
        map: function (dataItem) {
            return {
                text: dataItem.denominacion,
                value: dataItem.idTipoFacturacion,
            };
        },
    });

    datasource_infReposicionesGeneral_Parametro_TipoFacturacion_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    enum_infReposicionesGeneral_Parametro_TipoPrenda = [
        { text: getTrad("compañia").toUpperCase(), value: 0 },
        { text: getTrad("entidad").toUpperCase(), value: 1 },
    ];

    enum_infReposicionesGeneral_Parametro_TipoInforme = [
        { text: getTrad("prenda").toUpperCase(), value: "prenda" },
        {
            text: getTrad("familia").toUpperCase(),
            value: this.props.lavanderia && this.props.lavanderia.idLavanderia === 20 ? "familiaEcowhite" : "familia",
        },
        { text: getTrad("albaran").toUpperCase(), value: "tipoConsumoLenceria" },
    ];

    datasource_spExportDetallado = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infReposicionesGeneral_spExportDetallado",
            key: "idPrenda",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_spExportDetallado_beforeSend(request);
            },
            onLoaded: (request) => {
                this.datasource_spExportDetallado_onLoaded(request);
            },
            version: 4,
        }),
    });

    datasource_spExportDetallado_onLoaded(data) {
        $.each(data, function (index, item) {
            item.udsEntregadas = item.cantidad + item.rechazo + item.retiro - item.udsAbonadas;
        });

        this.loadPanel_hide();
        return data;
    }

    datasource_spExportDetallado_beforeSend(request) {
        let { tipoExport } = this.state;
        let fechaDesde = this.fechaSel.startDate;
        let fechaHasta = this.fechaSel.endDate;

        request.headers = { ...authHeader() };
        request.params.idLavanderia = this.props.lavanderia.idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.tipoExport = tipoExport;
    }

    enum_tipoExportacion = [
        {
            id: 1,
            text: getTrad("datosEntidad"),
        },
        {
            id: 2,
            text: getTrad("datosLavanderia"),
        },
    ];

    datasource_spSelectPrendas = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infReposicionesGeneral_spSelectPrendas",
            key: ["idPrenda", "peso"],
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_spSelectPrendas_beforeSend(request);
            },
            onLoading: (request) => {
                this.datasource_spSelectPrendas_onLoading(request);
            },
            onLoaded: (request) => {
                this.datasource_spSelectPrendas_onLoaded(request);
            },
            version: 4,
        }),
    });

    datasource_spSelectPrendas_beforeSend(request) {
        let { entidadSel } = this.state;

        request.headers = { ...authHeader() };
        request.params.idEntidad = entidadSel ? entidadSel.idEntidad : -1;
        request.params.idsRepartos = this.idsRepartos.toString().replace(/,/g, "|");
        request.params.idsAbonos = this.idsAbonos.toString().replace(/,/g, "|");
        request.params.fechaDesde = formatDate_parameter(this.fechaSel.startDate);
        request.params.fechaHasta = formatDate_parameter(this.fechaSel.endDate);
    }

    datasource_spSelectPrendas_onLoading(loadOptions) {
        if (loadOptions.filter && loadOptions.filter.length > 0 && loadOptions.filter[0] == "idPrenda") {
            loadOptions.filter[1] = "<>";
            loadOptions.filter[2] = null;
        }

        if (this.familiaSel) {
            if (loadOptions.filter) {
                loadOptions.filter = [loadOptions.filter, "and", ["idFamilia", "=", this.familiaSel]];
            } else {
                loadOptions.filter = ["idFamilia", "=", this.familiaSel];
            }
        }
    }

    datasource_spSelectPrendas_onLoaded(data) {
        let { is_abono } = this.state;
        var abonos = 0;
        $.each(data, function (index, item) {
            abonos += item.udsAbonadas;
            item.udsEntregadas = item.cantidad + item.rechazo + item.retiro;
        });
        is_abono = abonos > 0 ? true : false;

        this.setState({ is_abono: is_abono });

        return data;
    }

    datasource_spSelectPrendasReparto = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infReposicionesGeneral_spSelectPrendas",
            key: ["idPrenda", "peso"],
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_spSelectPrendas_beforeSend(request);
            },
            onLoading: (request) => {
                this.datasource_spSelectPrendas_onLoading(request);
            },
            onLoaded: (request) => {
                this.datasource_spSelectPrendas_onLoaded(request);
            },
            version: 4,
        }),
    });

    datasource_spSelectRepartos = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infReposicionesGeneral_spSelectRepartos",
            key: ["idReparto", "idAbono"],
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_spSelectRepartos_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_spSelectRepartos_beforeSend(request) {
        let { entidadSel, prendaSel } = this.state;

        request.headers = { ...authHeader() };
        request.params.idEntidad = entidadSel ? entidadSel.idEntidad : -1;
        request.params.idPrenda = prendaSel ? prendaSel.idPrenda : null;
        request.params.fechaDesde = formatDate_parameter(this.fechaSel.startDate);
        request.params.fechaHasta = formatDate_parameter(this.fechaSel.endDate);
        request.params.peso = prendaSel?.peso;
    }

    datasource_familias = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblFamilia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idFamilia", "denominacion", "abreviatura"],
        expand: "tblTraduccion($select=" + this.props.idioma.codigo + ")",

        postProcess: (data) => {
            return this.datasource_familias_postProcess(data);
        },
        map: (data) => {
            return this.datasource_familias_map(data);
        },
        sort: "codigo",
    });

    datasource_familias_postProcess(data) {
        if (this.datasource_familias.items().length === 0) {
            data.splice(0, 0, { value: null, text: getTrad("todas").toUpperCase() });
        }
        return data;
    }

    datasource_familias_map(dataItem) {
        let { idioma } = this.props;
        return {
            text: dataItem.tblTraduccion[idioma.codigo].toUpperCase(),
            value: dataItem.idFamilia,
        };
    }

    enum_tipoTabla = [
        {
            idTipoTabla: 1,
            denominacion: getTrad("prendas").toUpperCase(),
        },
        {
            idTipoTabla: 2,
            denominacion: getTrad("repartos").toUpperCase(),
        },
    ];

    //#endregion

    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }

    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    setParams(params) {
        this.props.setParams(params);
    }

    setReport(report) {
        this.props.setReport(report);
    }

    closeReport() {
        this.props.close();
    }

    setIsPrimeraRedireccion(red) {
        this.props.setIsPrimeraRedireccion(red);
    }

    dxDataGrid_prendas_render() {
        let { tipoTablaSel, is_abono } = this.state;
        return (
            <DataGrid
                ref={this.dxDataGrid_prendas_REF}
                dataSource={
                    tipoTablaSel === 1 ? this.datasource_spSelectPrendas : this.datasource_spSelectPrendasReparto
                }
                height="100%"
                columnAutoWidth={true}
                hoverStateEnabled={tipoTablaSel === 1}
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={true}
                onContentReady={this.dxDataGrid_selectPrendas_onContentReady}
                onCellHoverChanged={this.dxDataGrid_selectPrendas_onCellHoverChanged}
                onSelectionChanged={this.dxDataGrid_selectPrendas_onSelectionChanged}
                onRowPrepared={this.dxDataGrid_selectPrendas_onRowPrepared}
                onExporting={this.dxDataGrid_selectPrendas_onExporting}
            >
                <Selection mode={tipoTablaSel === 1 ? "single" : null} />
                <LoadPanel enabled={false} />
                <Paging pageSize={50} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} showInfo={true} />
                <Export enabled={false} fileName={"RepGeneral_" + getTrad("prendas")} allowExportSelectedData={true} />
                <FilterRow visible={true} applyFilter="auto" showAllText={getTrad("todos")} />
                <HeaderFilter visible={true} />
                <Column
                    dataField="codigoPrenda"
                    caption={getTrad("codigo")}
                    width={80}
                    sortIndex={1}
                    sortOrder="asc"
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField="denominacion"
                    caption={getTrad("denominacion")}
                    alignment="left"
                    allowHeaderFiltering={false}
                    width="50%"
                />
                <Column
                    dataField={"peso"}
                    caption={getTrad("peso") + " (g)"}
                    alignment="center"
                    allowFiltering={false}
                    width={70}
                />
                <Column
                    dataField="udsEntregadas"
                    caption={getTrad("entregadas")}
                    alignment="center"
                    allowFiltering={false}
                    width={110}
                    calculateSortValue={this.udsEntregadas_calculateSortValue}
                />
                <Column
                    dataField="udsAbonadas"
                    caption={getTrad("abonadas")}
                    visible={is_abono}
                    width={110}
                    allowFiltering={false}
                    alignment="center"
                />
                <Column
                    caption={getTrad("totalEntregadas")}
                    width={130}
                    visible={is_abono}
                    allowFiltering={false}
                    allowSorting={true}
                    alignment="center"
                    calculateCellValue={this.totalEntregadas_calculateCellValue}
                    calculateSortValue={this.totalEntregadas_calculateSortValue}
                />
                <Column
                    caption={getTrad("udsDiarias")}
                    width={130}
                    visible={tipoTablaSel === 1}
                    allowFiltering={false}
                    allowSorting={true}
                    alignment="center"
                    calculateCellValue={this.udsDiarias_calculateCellValue}
                    calculateSortValue={this.totalEntregadas_calculateSortValue}
                    calculateDisplayValue={this.udsDiarias_calculateDisplayValue}
                />
                <Column
                    name="kgEntregados"
                    caption={getTrad("kgEntregados")}
                    allowSorting={true}
                    allowFiltering={false}
                    width={130}
                    alignment="center"
                    calculateCellValue={this.peso_calculateCellValue}
                    calculateSortValue={this.peso_calculateSortValue}
                />
                <Summary
                    recalculateWhileEditing={true}
                    calculateCustomSummary={this.dxDataGrid_prendas_calculateCustomSummary}
                >
                    <TotalItem column="codigoPrenda" displayFormat={getTrad("total").toUpperCase()} />
                    <TotalItem
                        name="kgEntregados"
                        showInColumn="kgEntregados"
                        summaryType="custom"
                        displayFormat="{0} Kg."
                    />
                </Summary>
            </DataGrid>
        );
    }

    dxDataGrid_repartos_render() {
        let { tipoTablaSel, prendaSel } = this.state;
        return (
            <DataGrid
                ref={this.dxDataGrid_repartos_REF}
                dataSource={
                    tipoTablaSel === 2 || (tipoTablaSel === 1 && prendaSel) ? this.datasource_spSelectRepartos : []
                }
                height="100%"
                columnAutoWidth={true}
                hoverStateEnabled={tipoTablaSel === 2}
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={true}
                onContentReady={this.dxDataGrid_repartos_onContentReady}
                onCellHoverChanged={this.dxDataGrid_selectRepartos_onCellHoverChanged}
                onSelectionChanged={this.dxDataGrid_selectRepartos_onSelectionChanged}
                onRowPrepared={this.dxDataGrid_selectRepartos_onRowPrepared}
                onExporting={this.dxDataGrid_selectRepartos_onExporting}
            >
                <Selection
                    mode={tipoTablaSel === 2 ? "multiple" : null}
                    showCheckBoxesMode={tipoTablaSel === 2 ? "always" : null}
                />
                <LoadPanel enabled={false} />
                <Paging pageSize={50} />
                <Pager showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} showInfo={true} />
                <Export enabled={false} fileName={"RepGeneral_" + getTrad("repartos")} allowExportSelectedData={true} />
                <FilterRow visible={true} applyFilter="auto" showAllText={getTrad("todos")} />
                <HeaderFilter visible={true} />
                <Column
                    dataField="fecha"
                    caption={getTrad("fecha")}
                    dataType="datetime"
                    sortIndex={1}
                    sortOrder="asc"
                    format="dd/MM/yyyy"
                    alignment="center"
                    allowHeaderFiltering={false}
                    editorOptions={{ showClearButton: true }}
                    minWidth={100}
                />
                <Column
                    dataField="isAbono"
                    caption={getTrad("tipo")}
                    allowFiltering={false}
                    minWidth={130}
                    alignment="center"
                    customizeText={this.isAbono_customizeText}
                />
                <Column
                    dataField="cantidad"
                    caption={getTrad("cantidad")}
                    visible={tipoTablaSel === 1}
                    width={110}
                    allowFiltering={false}
                    alignment="center"
                    calculateCellValue={this.cantidad_calculateCellValue}
                />
                <Column dataField="rechazo" visible={false} />
                <Column dataField="retiro" visible={false} />
                <Column
                    dataField="kgEntregados"
                    caption={getTrad("kgEntregados")}
                    width={130}
                    allowFiltering={false}
                    alignment="center"
                    calculateCellValue={this.kgEntregados_calculateCellValue}
                />
                <Summary
                    recalculateWhileEditing={true}
                    calculateCustomSummary={this.dxDataGrid_repartos_calculateCustomSummary}
                >
                    <TotalItem column="fecha" displayFormat={getTrad("total").toUpperCase()} />
                    <TotalItem name="cantidad" showInColumn="cantidad" summaryType="custom" displayFormat="{0}" />
                    <TotalItem
                        name="kgEntregados"
                        showInColumn="kgEntregados"
                        summaryType="custom"
                        displayFormat="{0} Kg."
                    />
                </Summary>
            </DataGrid>
        );
    }

    render() {
        let {
            showSelector,
            datos_Agrupado_Compañias,
            fechaSel,
            minDate,
            maxDate,
            entidadSel,
            tipoTablaSel,
            dxPopup_noDatos_isVisible,
        } = this.state;
        let { getBackButton_paginaSecundaria, param_paginaSecundaria } = this.props;

        return (
            <Fragment>
                <PageTitle
                    heading={
                        param_paginaSecundaria != null
                            ? param_paginaSecundaria.nombre_paginaSecundaria
                            : getNombreFormulario(this)
                    }
                    preHeading={
                        <Fragment>
                            <div>
                                {showSelector
                                    ? getBackButton_paginaSecundaria
                                        ? getBackButton_paginaSecundaria()
                                        : ""
                                    : ""}
                            </div>
                            <div>
                                {!showSelector ? (
                                    <div
                                        text={getTrad("volverLavanderia")}
                                        className="backButton container_spanCentrado dx-icon-chevronleft"
                                        onClick={this.btnAtrasTarjetas_onClick}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </Fragment>
                    }
                    postHeadingBegin={
                        <DateRangePicker
                            month
                            year
                            day
                            defaultType="month"
                            fechaSel={fechaSel}
                            minDate={minDate}
                            maxDate={maxDate}
                            onDateSelected={this.dateRangePicker_onDateSelected}
                        />
                    }
                    postHeadingEnd={
                        showSelector ? (
                            <DropDownButton
                                text={getTrad("exportar")}
                                icon="exportxlsx"
                                onItemClick={this.onItemClick_Exportar}
                                items={this.enum_tipoExportacion}
                                displayExpr={"text"}
                                keyExpr={"id"}
                                width={190}
                            />
                        ) : (
                            <OpcionesTitle />
                        )
                    }
                />

                <div className={"media-body"}>
                    {/* <ReactCSSTransitionGroup
            component="div"
            className={"media-body"}
            transitionName="PageAnimation"
            transitionAppear={true}
            transitionAppearTimeout={1200}
            transitionEnter={false}
            transitionLeave={false}> */}
                    <OpcionesContainer />
                    <div id="InfReposicionesGeneral" className="formContainer scrollbar-container">
                        <MultiView
                            height="100%"
                            width="100%"
                            selectedIndex={showSelector ? 0 : 1}
                            focusStateEnabled={false}
                            loop={false}
                            swipeEnabled={false}
                            animationEnabled={true}
                        >
                            <MultiViewItem>
                                <AppSelector
                                    rangeSelector={true}
                                    selector="compañia"
                                    getContent_header={this.getContent_header}
                                    getContent_front={this.getContent_front}
                                    getContent_back={this.getContent_back}
                                    getMinSize={this.getMinSize}
                                    dataSource_load={this.dataSource_load}
                                    hideSelector={this.hideSelector}
                                    fechaSel={fechaSel}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    dxTileView_data={datos_Agrupado_Compañias}
                                    refreshContent_back={this.refreshContent_back}
                                />
                            </MultiViewItem>
                            <MultiViewItem>
                                <Box
                                    direction="col"
                                    width="100%"
                                    height="100%"
                                    align="space-around"
                                    crossAlign="stretch"
                                    elementAttr={this.dxBox_principal_elementAttr}
                                >
                                    <ItemBox baseSize={70}>
                                        <div className="d-flex">
                                            <div className="titForm">
                                                <h3>{entidadSel && entidadSel.denominacion}</h3>
                                            </div>
                                            <div
                                                style={{
                                                    color: "rgb(100,100,100",
                                                    fontWeight: "lighter",
                                                    fontSize: "30px",
                                                    paddingLeft: "50px",
                                                }}
                                            >
                                                {entidadSel &&
                                                    formatNumber(entidadSel.kgEntregados, 2, "decimal") + " Kg."}
                                            </div>
                                            <div id="contenedorInfo">
                                                {entidadSel && entidadSel.numRepNoValidados > 0 && (
                                                    <Fragment>
                                                        <Popover
                                                            target="#contenedorInfo"
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            position="bottom"
                                                            width={350}
                                                        >
                                                            <div
                                                                className="flex-row container_spanCentrado"
                                                                style={{
                                                                    width: 350,
                                                                    color: "white",
                                                                    fontSize: "16px",
                                                                    fontWeight: "lighter",
                                                                    textAlign: "center",
                                                                    backgroundColor: "rgb(217, 83, 79)",
                                                                    borderRadius: "5px",
                                                                    padding: "10px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {"Existen (" +
                                                                    entidadSel.numRepNoValidados +
                                                                    ") repartos sin validar"}
                                                            </div>
                                                        </Popover>
                                                        <div
                                                            className="icon_Warning"
                                                            style={{
                                                                fontSize: 25,
                                                                cursor: "pointer",
                                                                width: "25px",
                                                                marginLeft: "15px",
                                                            }}
                                                        />
                                                    </Fragment>
                                                )}
                                            </div>

                                            <div style={{ flex: 1 }}>
                                                <Toolbar>
                                                    <ToolbarItem
                                                        location="after"
                                                        render={(e) => this.btnLimpiarFiltro_render(e)}
                                                    />
                                                    <ToolbarItem
                                                        location="after"
                                                        render={(e) => this.btnActualizar_render(e)}
                                                    />
                                                </Toolbar>
                                            </div>
                                        </div>
                                    </ItemBox>
                                    <ItemBox ratio={1}>
                                        <div className="d-flex flex-row he-100 w-100">
                                            <div style={{ flex: tipoTablaSel === 1 ? 3 : 1 }}>
                                                <Box
                                                    direction="col"
                                                    width="100%"
                                                    height="100%"
                                                    align="space-around"
                                                    crossAlign="stretch"
                                                >
                                                    <ItemBox baseSize={50}>
                                                        <Toolbar>
                                                            <ToolbarItem
                                                                location="before"
                                                                render={(e) =>
                                                                    this.dxRadioGroup_toolbarPrendas_render(e)
                                                                }
                                                            />
                                                            <ToolbarItem
                                                                location="after"
                                                                render={(e) => this.dxToolbar_prendas_render(e)}
                                                            />
                                                        </Toolbar>
                                                    </ItemBox>
                                                    <ItemBox ratio={1}>
                                                        {tipoTablaSel === 1
                                                            ? this.dxDataGrid_prendas_render()
                                                            : this.dxDataGrid_repartos_render()}
                                                    </ItemBox>
                                                </Box>
                                            </div>
                                            <div style={{ flexBasis: 20 }}></div>
                                            <div style={{ flex: 2 }}>
                                                <Box
                                                    direction="col"
                                                    width="100%"
                                                    height="100%"
                                                    align="space-around"
                                                    crossAlign="stretch"
                                                >
                                                    <ItemBox baseSize={50}>
                                                        <Toolbar>
                                                            <ToolbarItem location="after">
                                                                <Button
                                                                    text={getTrad("exportar")}
                                                                    icon="exportxlsx"
                                                                    onClick={this.dxButton_toolbarSecundario_exportar}
                                                                />
                                                            </ToolbarItem>
                                                        </Toolbar>
                                                    </ItemBox>
                                                    <ItemBox ratio={1}>
                                                        {tipoTablaSel === 1
                                                            ? this.dxDataGrid_repartos_render()
                                                            : this.dxDataGrid_prendas_render()}
                                                    </ItemBox>
                                                </Box>
                                            </div>
                                        </div>
                                    </ItemBox>
                                </Box>
                            </MultiViewItem>
                        </MultiView>
                        <Popover ref={this.dxPopover_REF} width={"auto"} height={"auto"}></Popover>
                        <Popup
                            height="auto"
                            width="auto"
                            minWidth={300}
                            maxWidth={500}
                            visible={dxPopup_noDatos_isVisible}
                            showTitle={false}
                            deferRendering={false}
                            contentRender={this.dxPopup_noDatos_contentRender}
                            onContentReady={this.dxPopup_noDatos_onContentReady}
                        >
                            <ToolbarItemPopup
                                toolbar="bottom"
                                location="center"
                                widget="dxButton"
                                options={this.aceptar_dxButton}
                            ></ToolbarItemPopup>
                        </Popup>
                    </div>
                    {/* </ReactCSSTransitionGroup> */}
                </div>
            </Fragment>
        );
    }

    dxPopup_noDatos_contentRender(e) {
        return (
            <div style={{ fontSize: 14, whiteSpace: "pre-wrap" }}>
                <p>{getTrad("alert_sinDatos_lavanderia").replace("<br />", "\n")}</p>
            </div>
        );
    }

    dxPopup_noDatos_onContentReady(e) {
        var html = e.component.content();
        $(html).css("padding-top", "20px");
        $(html).css("padding-bottom", "10px");
        $(html).css("text-align", "center");
    }

    dxPopup_noDatos_buttonOnClick(e) {
        history.push("/");
    }

    btnAtrasTarjetas_onClick(e) {
        this.setState(
            {
                showSelector: true,
                entidadSel: null,
                prendaSel: null,
                tipoExport: null,
                is_abono: false,
                fechaSel_redireccion: null,
                fechaDesde_redireccion: null,
                fechaHasta_redireccion: null,
            },
            () => {
                this.idsRepartos = [];
                this.idsAbonos = [];
                this.familiaSel = null;
                this.dxDataGrid_prendas.deselectAll();
                this.dataSource_load(this.state.fechaSel);
            }
        );
    }

    dxBox_principal_elementAttr = {
        id: "dxBox_principal",
    };

    isAbono_customizeText(data) {
        return data.value === 0 ? getTrad("reparto").toUpperCase() : getTrad("abono").toUpperCase();
    }

    dxDataGrid_prendas_calculateCustomSummary(options) {
        let { is_abono } = this.state;
        if (options.name === "kgEntregados") {
            if (options.summaryProcess === "start") {
                options.totalValue = 0;
            }
            if (options.summaryProcess === "calculate") {
                var entregadas = is_abono
                    ? options.value.udsEntregadas - options.value.udsAbonadas
                    : options.value.udsEntregadas;

                options.totalValue = options.totalValue + entregadas * (options.value.peso * 0.001);
            }
            if (options.summaryProcess === "finalize") {
                options.totalValue = formatNumber(options.totalValue, 2, "decimal");
            }
        }
    }

    dxRadioGroup_toolbarPrendas_render(e) {
        return (
            <RadioGroup
                elementAttr={this.elementAttr_radioGroup_prendas}
                dataSource={this.enum_tipoTabla}
                valueExpr="idTipoTabla"
                displayExpr="denominacion"
                layout="horizontal"
                height="100%"
                value={this.state.tipoTablaSel}
                width={350}
                onValueChanged={this.onValueChanged_dxRadioGroup_prendas}
            />
        );
    }

    elementAttr_radioGroup_prendas = {
        id: "dxRadioGroup_tipoTabla",
        class: "dxRadioGroup_opciones",
    };

    resetGrids() {
        if (this.dxDataGrid_prendas.getDataSource()) {
            this.dxDataGrid_prendas.clearSelection();
            this.dxDataGrid_prendas.clearFilter();
            if (this.dxDataGrid_prendas.getScrollable()) this.dxDataGrid_prendas.getScrollable().scrollTo(0);
        }

        if (this.dxDataGrid_repartos.getDataSource()) {
            this.dxDataGrid_repartos.clearSelection();
            this.dxDataGrid_repartos.clearFilter();
            if (this.dxDataGrid_repartos.getScrollable()) this.dxDataGrid_repartos.getScrollable().scrollTo(0);
        }
    }

    dxRadioGroup_familias_onValueChanged(e) {
        this.familiaSel = e.value;
        this.dxDataGrid_prendas.refresh();
    }

    carga_opcionesInforme() {
        let { datasource_familias } = this.state;
        $("#dxForm_opciones").dxForm({
            labelLocation: "top",
            height: "100%",
            scrollingEnabled: true,
            colCount: 1,
            items: [
                {
                    label: { text: getTrad("familias") },
                    editorType: "dxRadioGroup",
                    editorOptions: {
                        elementAttr: {
                            id: "dxRadioGroup_familias",
                            class: "dxRadioGroup_opciones",
                        },
                        dataSource: datasource_familias,
                        valueExpr: "value",
                        displayExpr: "text",
                        width: "100%",
                        value: null,
                        onValueChanged: this.dxRadioGroup_familias_onValueChanged,
                    },
                },
            ],
        });
    }

    onValueChanged_dxRadioGroup_prendas(e) {
        let tipoTablaSel;
        $("#dxRadioGroup_familias").dxRadioGroup("instance").option("value", null);
        switch (e.value) {
            case 1: //Prendas
                tipoTablaSel = 1;
                $("#dxRadioGroup_familias").dxRadioGroup("instance").option("disabled", false);
                break;
            case 2: //Repartos
                tipoTablaSel = 2;
                $("#dxRadioGroup_familias").dxRadioGroup("instance").option("disabled", true);
                break;
            default:
                break;
        }

        this.idsAbonos = [];
        this.idsRepartos = [];
        this.dxDataGrid_prendas.deselectAll();

        this.loadPanel_show();
        this.setState({
            prendaSel: null,
            tipoTablaSel: tipoTablaSel,
        });
    }

    dxToolbar_prendas_render() {
        let { tipoTablaSel } = this.state;
        return (
            <Toolbar>
                <ToolbarItem location="after">
                    <Button text={getTrad("exportar")} icon="exportxlsx" onClick={this.exportar_prendas_onClick} />
                </ToolbarItem>
                <ToolbarItem location="after" showText="inMenu">
                    <Button
                        text={tipoTablaSel === 1 ? getTrad("imprimir") : null}
                        icon=" icon_Impresora"
                        onClick={this.imprimir_prendas_onClick}
                    />
                </ToolbarItem>
            </Toolbar>
        );
    }

    exportar_prendas_onClick(e) {
        let { tipoTablaSel } = this.state;
        if (tipoTablaSel === 1) this.dxDataGrid_prendas.exportToExcel();
        else if (tipoTablaSel === 2) this.dxDataGrid_repartos.exportToExcel();
    }

    imprimir_prendas_onClick(e) {
        const cookies = new Cookies();
        const currentUser = cookies.get("user");
        var refreshToken = currentUser.refreshToken;

        let { entidadSel, fechaSel } = this.state;
        let { lavanderia } = this.props;
        var params = {
            idEntidad: entidadSel.idEntidad,
            fechaDesde: formatDate_parameter(fechaSel.startDate),
            fechaHasta: formatDate_parameter(fechaSel.endDate),
            idLavanderia: lavanderia ? lavanderia.idLavanderia : "",
            idToken: refreshToken,
            idsRepartos: this.idsRepartos.toString().replace(/,/g, "|"),
            idsAbonos: this.idsAbonos.toString().replace(/,/g, "|"),
        };
        this.setParams(params);
    }

    cantidad_calculateCellValue(rowData) {
        return rowData.cantidad + rowData.rechazo + rowData.retiro;
    }

    kgEntregados_calculateCellValue(e) {
        if (e.isAbono) return formatNumber(-e.kgEntregados, 2, "decimal") + " Kg.";
        return formatNumber(e.kgEntregados, 2, "decimal") + " Kg.";
    }

    dxDataGrid_repartos_calculateCustomSummary(options) {
        let isAbono;
        if (options.name === "kgEntregados") {
            if (options.summaryProcess === "start") {
                options.totalValue = 0;
            }
            if (options.summaryProcess === "calculate") {
                isAbono = options.value.isAbono;

                options.totalValue =
                    options.totalValue + (isAbono ? -options.value.kgEntregados : options.value.kgEntregados);
            }
            if (options.summaryProcess === "finalize") {
                options.totalValue = formatNumber(options.totalValue, 2, "decimal");
            }
        } else if (options.name === "cantidad") {
            if (options.summaryProcess === "start") {
                options.totalValue = 0;
            }
            if (options.summaryProcess === "calculate") {
                isAbono = options.value.isAbono;

                options.totalValue =
                    options.totalValue +
                    (isAbono
                        ? -options.value.cantidad
                        : options.value.cantidad + options.value.rechazo + options.value.retiro);
            }
            if (options.summaryProcess === "finalize") {
                options.totalValue = formatNumber(options.totalValue, 0, "decimal");
            }
        }
    }

    dxDataGrid_selectRepartos_onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Sheet");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function ({ gridCell, excelCell }) {
                if (
                    gridCell.rowType === "data" &&
                    (gridCell.column.dataField === "cantidad" || gridCell.column.dataField === "kgEntregados")
                ) {
                    if (gridCell.column.dataField === "cantidad") {
                        excelCell.value = parseInt(gridCell.value);
                        excelCell.numFmt = "#,##";
                    } else {
                        excelCell.value = parseFloat(gridCell.value.replace(".", "").replace(",", "."));
                        excelCell.numFmt = "0.00";
                    }
                }
            },
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "RepGeneral_" + getTrad("repartos") + ".xlsx"
                );
            });
        });
        e.cancel = true;
    }

    dxButton_toolbarSecundario_exportar(e) {
        let { tipoTablaSel } = this.state;
        if (tipoTablaSel === 1) this.dxDataGrid_repartos.exportToExcel();
        else if (tipoTablaSel === 2) this.dxDataGrid_prendas.exportToExcel();
    }

    dxDataGrid_selectRepartos_onRowPrepared(e) {
        let { tipoTablaSel } = this.state;
        if (e.rowType === "data" && tipoTablaSel === 2) e.rowElement.css("cursor", "pointer");
    }

    udsEntregadas_calculateSortValue(rowData) {
        return rowData.cantidad + rowData.rechazo + rowData.retiro;
    }

    totalEntregadas_calculateSortValue(rowData) {
        var udsEntregadas = rowData.cantidad + rowData.rechazo + rowData.retiro;
        return udsEntregadas - rowData.udsAbonadas;
    }

    totalEntregadas_calculateCellValue(e) {
        return e.udsEntregadas - e.udsAbonadas;
    }

    udsDiarias_calculateCellValue = (e) => {
        let { fechaSel } = this.state;
        let numDiasDatos = fechaSel.endDate.getDate();
        return (e.udsEntregadas - e.udsAbonadas) / numDiasDatos;
    };

    udsDiarias_calculateDisplayValue = (rowData) => {
        let { fechaSel } = this.state;
        let numDiasDatos = fechaSel.endDate.getDate();
        let value = (rowData.udsEntregadas - rowData.udsAbonadas) / numDiasDatos;

        return formatNumber(value, 2, "decimal");
    };

    peso_calculateSortValue(rowData) {
        let { is_abono } = this.state;
        var udsEntregadas = rowData.cantidad + rowData.rechazo + rowData.retiro;
        var entregadas = is_abono ? udsEntregadas - rowData.udsAbonadas : udsEntregadas;

        return entregadas * (rowData.peso * 0.001);
    }

    peso_calculateCellValue(e) {
        let { is_abono } = this.state;
        var entregadas = is_abono ? e.udsEntregadas - e.udsAbonadas : e.udsEntregadas;
        return formatNumber(entregadas * (e.peso * 0.001), 2, "decimal");
    }

    dxDataGrid_selectPrendas_onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Sheet");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function ({ gridCell, excelCell }) {
                if (
                    gridCell.rowType === "data" &&
                    (gridCell.column.dataField === "udsEntregadas" || gridCell.column.dataField === "peso")
                ) {
                    if (["udsEntregadas", "peso"].includes(gridCell.column.dataField)) {
                        excelCell.value = parseInt(gridCell.value);
                        excelCell.numFmt = "#,##";
                    } else {
                        excelCell.value = parseFloat(gridCell.value.replace(".", "").replace(",", "."));
                        excelCell.numFmt = "0.00";
                    }
                }
            },
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "RepGeneral_" + getTrad("prendas") + ".xlsx"
                );
            });
        });
        e.cancel = true;
    }

    dxDataGrid_selectPrendas_onSelectionChanged(e) {
        let prendaSel;
        if (e.selectedRowKeys.length > 0) {
            prendaSel = e.selectedRowsData[0];
        } else {
            prendaSel = null;
        }
        this.setState({ prendaSel: prendaSel });

        this.dxDataGrid_repartos.refresh();
    }

    dxDataGrid_selectPrendas_onContentReady(e) {
        this.dxDataGrid_prendas_rendered = true;
        if (this.dxDataGrid_repartos_rendered) {
            setTimeout(() => {
                this.loadPanel_hide();
            }, 0);
            this.dxDataGrid_prendas_rendered = false;
        }
    }

    dxDataGrid_repartos_onContentReady(e) {
        this.dxDataGrid_repartos_rendered = true;

        if (this.dxDataGrid_prendas_rendered) {
            setTimeout(() => {
                this.loadPanel_hide();
            }, 0);
            this.dxDataGrid_repartos_rendered = false;
        }
    }

    dxDataGrid_selectPrendas_onCellHoverChanged(e) {
        if (e.column && e.row && e.rowType == "data" && e.column.dataField == "udsEntregadas") {
            if (e.eventType === "mouseover") {
                this.dxPopover.option("contentTemplate", function (contentElement) {
                    let container = $("<div/>");

                    if (e.column.dataField == "udsEntregadas" && e.data.reparteRechazoRetiro) {
                        let msg = "";
                        msg += "<b>Cantidad:</b> " + formatNumber(e.data.cantidad, 0);
                        msg += "</br>";
                        msg += "<b>Rechazo:</b> " + formatNumber(e.data.rechazo, 0);
                        msg += "</br>";
                        msg += "<b>Retiro:</b> " + formatNumber(e.data.retiro, 0);

                        container.append("<div>" + msg + "</div>");
                    }
                    container.appendTo(contentElement);
                });

                this.dxPopover.option("target", e.cellElement);
                if (e.column.dataField == "udsEntregadas" && e.data.reparteRechazoRetiro) this.dxPopover.show();
            } else this.dxPopover.hide();
        }
    }

    dxDataGrid_selectPrendas_onRowPrepared(e) {
        let { tipoTablaSel } = this.state;
        if (e.rowType === "data" && tipoTablaSel === 1) e.rowElement.css("cursor", "pointer");
    }

    dxDataGrid_selectRepartos_onCellHoverChanged(e) {
        if (e.column && e.row && e.rowType == "data" && e.column.dataField == "cantidad") {
            if (e.eventType === "mouseover") {
                this.dxPopover.option("contentTemplate", function (contentElement) {
                    let container = $("<div/>");

                    if (e.column.dataField == "cantidad" && e.data.reparteRechazoRetiro && !e.data.isAbono) {
                        let msg = "";
                        msg += "<b>Cantidad:</b> " + formatNumber(e.data.cantidad, 0);
                        msg += "</br>";
                        msg += "<b>Rechazo:</b> " + formatNumber(e.data.rechazo, 0);
                        msg += "</br>";
                        msg += "<b>Retiro:</b> " + formatNumber(e.data.retiro, 0);

                        container.append("<div>" + msg + "</div>");
                    }
                    container.appendTo(contentElement);
                });

                this.dxPopover.option("target", e.cellElement);
                if (e.column.dataField == "cantidad" && e.data.reparteRechazoRetiro && !e.data.isAbono)
                    this.dxPopover.show();
            } else this.dxPopover.hide();
        }
    }

    dxDataGrid_selectRepartos_onSelectionChanged(e) {
        var itemsSel = e.component.getSelectedRowsData();

        let idsRepartos = [],
            idsAbonos = [];

        $.each(itemsSel, function (index, item) {
            if (!item.isAbono) idsRepartos.push(item.idReparto);
            else {
                idsAbonos.push(item.idAbono);
            }
        });

        this.idsRepartos = idsRepartos;
        this.idsAbonos = idsAbonos;

        this.dxDataGrid_prendas.refresh();
    }

    btnLimpiarFiltro_render(e) {
        return (
            <Button
                text={getTrad("limpiarFiltro")}
                icon=" icon_EliminarFiltros"
                onClick={this.dxButton_limpiarFiltro_onClick}
            />
        );
    }

    btnActualizar_render(e) {
        return (
            <Button
                text={getTrad("actualizar")}
                width="auto"
                icon="refresh"
                onClick={this.dxButton_actualizar_onClick}
            />
        );
    }

    dxButton_limpiarFiltro_onClick(e) {
        $("#dxRadioGroup_familias").dxRadioGroup("instance").option("value", null);

        if (this.dxDataGrid_prendas.getDataSource()) this.dxDataGrid_prendas.clearFilter();

        if (this.dxDataGrid_repartos.getDataSource()) this.dxDataGrid_repartos.clearFilter();

        notify({
            message: getTrad("aviso_C_FiltroRestaurado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
        });
    }

    dxButton_actualizar_onClick(e) {
        this.loadPanel_show(true);
        this.dxDataGrid_prendas.refresh();
        this.dxDataGrid_repartos.refresh();
    }

    getMinSize() {
        var minSize = {
            height: 210,
            width: 440,
        };
        return minSize;
    }

    getContent_header(itemData) {
        return (
            <div className="d-flex w-100" style={{ paddingTop: 6, paddingBottom: 3 }}>
                <span className="d-flex align-items-center" style={{ flex: 1 }}>
                    {itemData.denominacion}
                </span>
                <Button
                    icon="icon icon_Impresora"
                    text="Imprimir todo"
                    className="onlyActive"
                    width={160}
                    stylingMode="outlined"
                    onClick={(e) => {
                        this.dxButton_imprimirTodo_onClick(e, itemData);
                    }}
                />
            </div>
        );
    }

    dxButton_imprimirTodo_onClick(e, data) {
        let { fechaSel } = this.state;
        let { lavanderia } = this.props;

        const cookies = new Cookies();
        const currentUser = cookies.get("user");
        let refreshToken = currentUser.refreshToken;

        var params = {
            idCompania: data.idCompañia,
            idEntidad: "",
            fechaDesde: formatDate_parameter(fechaSel.startDate),
            fechaHasta: formatDate_parameter(fechaSel.endDate),
            idLavanderia: lavanderia ? lavanderia.idLavanderia : "",
            idToken: refreshToken,
        };
        this.setParams(params);
    }

    getContent_front(idCompañia) {
        let { datos_Agrupado_Compañias, datos_Agrupado_Entidades } = this.state;

        if (!this.state.showSelector) return null;

        let compañias = datos_Agrupado_Entidades
            ? $.grep(datos_Agrupado_Compañias, function (comp) {
                  return comp.idCompañia === idCompañia;
              })
            : null;

        let compañia = compañias && compañias.length > 0 ? compañias[0] : null;

        if (compañia) {
            let entidades = $.grep(datos_Agrupado_Entidades, function (entidad) {
                return entidad.idCompañia === idCompañia;
            });

            return (
                <div className="he-100 d-flex">
                    <div style={{ padding: "0px 10px" }} className="d-flex flex-column">
                        <div
                            style={{
                                flex: "1",
                                fontSize: "22px",
                                fontWeight: "400",
                                textAlign: "center",
                            }}
                            className="container_spanCentrado"
                        >
                            {formatNumber(compañia.kgEntregados, 2, "decimal") + " Kg."}
                        </div>
                        <div style={{ flex: "3" }} className="container_spanCentrado">
                            <CircularGauge value={compañia.perc}>
                                <Geometry startAngle={90} endAngle={90} />
                                <RangeContainer width={8} offset={13} backgroundColor="lightgrey" />
                                <Scale startValue={0} endValue={1}>
                                    <Label visible={false} />
                                    <Tick visible={false} />
                                </Scale>
                                <ValueIndicator type="rangebar" color="#EDB637" size={8} offset={0}></ValueIndicator>
                                <Size width={100} height={100} />
                            </CircularGauge>
                            <div style={{ position: "absolute", fontSize: "26px" }}>
                                {formatNumber(compañia.perc, 0, "percent")}
                            </div>
                        </div>
                    </div>

                    <div style={{ flex: 1 }} className="degradadoLista">
                        {entidades.map((entidad, i) => (
                            <Fragment key={i}>
                                <div
                                    style={{ padding: "0px 10px 5px 0px", height: 35 }}
                                    className="container_spanCentrado"
                                >
                                    <div style={{ flexBasis: "50px", fontSize: "14px" }}>
                                        {formatNumber(
                                            compañia.kgEntregados > 0
                                                ? entidad.kgEntregados / compañia.kgEntregados
                                                : 0,
                                            0,
                                            "percent"
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {entidad.denominacion}
                                    </div>
                                    <div style={{ fontWeight: "400" }}>
                                        {formatNumber(entidad.kgEntregados, 2, "decimal") + " Kg."}
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container_spanCentrado he-100 d-flex">
                    <div>{"SIN DATOS DE FACTURACIÓN"}</div>
                </div>
            );
        }
    }

    getContent_back(idCompañia) {
        let { datos_Agrupado_Compañias, datos_Agrupado_Entidades, datos_Agrupado_Fechas } = this.state;
        let { lavanderia } = this.props;

        let compañia = $.grep(datos_Agrupado_Compañias, function (comp) {
            return comp.idCompañia === idCompañia;
        })[0];

        let entidades = [];
        let datasource_chart = [];

        if (!compañia) {
            compañia = {
                imp: 0,
                perc: 0,
            };
        } else {
            entidades = $.grep(datos_Agrupado_Entidades, function (entidad) {
                return entidad.idCompañia === idCompañia;
            });
            datasource_chart = $.grep(datos_Agrupado_Fechas, function (item) {
                return item.idCompañia === idCompañia;
            });
        }

        let moneda = lavanderia ? lavanderia.moneda : entidades[0] ? entidades[0].moneda : "EUR";
        return (
            <div className="d-flex flex-column he-100">
                <div style={{ height: 200 }} className="w-100 d-flex">
                    <div className="d-flex flex-column">
                        <div
                            className="importe_compañiaSel container_spanCentrado text-center"
                            style={{
                                flex: 1,
                                fontSize: 25,
                                fontWeight: 400,
                                padding: "0px 10px",
                            }}
                        >
                            {formatNumber(compañia.kgEntregados, 2, "decimal") + " Kg."}
                        </div>

                        <div style={{ flex: "3" }} className="container_spanCentrado">
                            <CircularGauge ref={this.dxCircularGauge_compa_REF} value={compañia.perc}>
                                <Geometry startAngle={90} endAngle={90} />
                                <RangeContainer width={10} offset={13} backgroundColor="lightgrey" />
                                <Scale startValue={0} endValue={1}>
                                    <Label visible={false} />
                                    <Tick visible={false} />
                                </Scale>
                                <ValueIndicator type="rangebar" color="#EDB637" size={10} offset={0}></ValueIndicator>
                                <Size width={120} height={120} />
                            </CircularGauge>
                            <div style={{ position: "absolute", fontSize: "26px" }}>
                                {formatNumber(compañia.perc, 0, "percent")}
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                        <Chart
                            id="dxChart_compañiaSel"
                            ref={this.dxChart_compañiaSel_REF}
                            palette="Soft Blue"
                            dataSource={datasource_chart}
                            height={200}
                            onOptionChanged={this.dxChart_compañiaSel_onOptionChanged}
                        >
                            <CommonSeriesSettings type="spline" argumentField="arg">
                                <Aggregation method="sum" />
                            </CommonSeriesSettings>
                            <ArgumentAxis
                                argumentType="datetime"
                                aggregationInterval={this.getAggregationInterval()}
                                valueMarginsEnabled={true}
                            />
                            <ValueAxis name="imp" />
                            <Legend visible={false} />

                            <Series axis="imp" color="#EDB637" valueField="value">
                                <Point size={7} />
                                <Aggregation enabled={true} />
                            </Series>
                            <TooltipChart
                                enabled={true}
                                location="edge"
                                customizeTooltip={(e) => {
                                    return this.dxChart_customizeTooltip(e, moneda);
                                }}
                            />
                            <LoadingIndicator
                                backgroundColor="none"
                                font={{
                                    size: 14,
                                }}
                            />
                            <Margin top={0} bottom={0} left={5} right={5} />
                        </Chart>
                    </div>
                </div>

                <div style={{ marginTop: 30, flex: 1, overflow: "auto" }}>
                    <DataGrid
                        ref={this.dxDataGrid_compañiaSel_REF}
                        dataSource={entidades}
                        keyExpr="idEntidad"
                        //Propiedades
                        columnsAutoWidth={true}
                        repaintChangesOnly={true}
                        height="100%"
                        hoverStateEnabled={true}
                        showColumnLines={false}
                        noDataText="SIN DATOS"
                        importeCompañia={compañia.kgEntregados}
                        onCellHoverChanged={this.dxDataGrid_compañiaSel_onCellHoverChanged}
                        onRowPrepared={this.dxDataGrid_onRowPrepared}
                        onCellClick={this.dxDataGrid_onCellClick}
                    >
                        <Paging enabled={false} />
                        <Column
                            width="15%"
                            alignment="center"
                            cellRender={this.dxDataGrid_circularGauge_cellRender}
                            calculateCellValue={this.dxDataGrid_circularGauge_calculateCellValue}
                        />
                        <Column dataField="denominacion" caption={getTrad("denominacion")} minWidth={200} />
                        <Column
                            dataField="kgEntregados"
                            caption={getTrad("kgEntregados")}
                            width="15%"
                            alignment="center"
                            cellRender={this.dxDataGrid_kgEntregados_cellRender}
                        />
                        <Column
                            dataField="estanciasReal"
                            caption={getTrad("estancias")}
                            width="15%"
                            alignment="center"
                            format={this.decimal_0Digits_format}
                        />
                        <Column
                            name="print"
                            caption=" "
                            width={80}
                            alignment="center"
                            cssClass="p-0"
                            cellRender={this.dxDataGrid_print_cellRender}
                        />
                        <Summary>
                            <TotalItem
                                summaryType="sum"
                                displayFormat={"{0} Kg."}
                                valueFormat={this.decimal_2minMax_format}
                                column="totalKg"
                            />
                        </Summary>
                    </DataGrid>
                </div>
            </div>
        );
    }

    dxDataGrid_kgEntregados_cellRender(e) {
        let valueText = formatNumber(e.data.kgEntregados, 2, "decimal") + " Kg.";
        return (
            <div className="position-relative">
                {valueText}
                {e.data.numRepNoValidados > 0 ? (
                    <div
                        className="icon_Warning"
                        style={{
                            fontSize: 25,
                            cursor: "pointer",
                            position: "absolute",
                            right: 0,
                            top: "-3px",
                        }}
                    />
                ) : null}
            </div>
        );
    }

    getAggregationInterval() {
        let { fechaSel } = this.state;

        if (!fechaSel) return "day";
        var numDias = dayDiff(fechaSel.startDate, fechaSel.endDate);
        return numDias < 35 ? "day" : numDias < 180 ? "week" : "month";
    }

    decimal_2minMax_format = {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    decimal_2Digits_format = {
        style: "decimal",
        maximumFractionDigits: 2,
    };

    decimal_0Digits_format = {
        style: "decimal",
        maximumFractionDigits: 0,
    };

    dxDataGrid_compañiaSel_btnPrint_onClick(e, data) {
        let { lavanderia } = this.props;
        let { fechaSel } = this.state;

        const cookies = new Cookies();
        const currentUser = cookies.get("user");
        let refreshToken = currentUser.refreshToken;

        let params = {
            idEntidad: data.idEntidad,
            fechaDesde: formatDate_parameter(fechaSel.startDate),
            fechaHasta: formatDate_parameter(fechaSel.endDate),
            idLavanderia: lavanderia ? lavanderia.idLavanderia : "",
            idToken: refreshToken,
        };

        this.setParams(params);
    }

    dxDataGrid_print_cellRender(e) {
        return (
            <div
                className="container_spanCentrado"
                onMouseDown={(args) => {
                    this.dxDataGrid_compañiaSel_btnPrint_onClick(args, e.data);
                }}
            >
                <div className="icon icon_Impresora" style={{ fontSize: 25, cursor: "pointer" }}></div>
            </div>
        );
    }

    dxDataGrid_importeTotal_cellRender(e) {
        let valueText = formatNumber(e.data.kgEntregados, 2, "decimal");
        return (
            <div className="position-relative">
                {valueText}
                {e.data.numRepNoValidados > 0 && e.data.idTipoConsumoLenceria === 1 ? (
                    <div
                        className="icon_Warning"
                        style={{
                            fontSize: 25,
                            cursor: "pointer",
                            position: "absolute",
                            right: 0,
                            top: "-3px",
                        }}
                    />
                ) : null}
            </div>
        );
    }

    dxDataGrid_tipoFactCliente_cellRender(e) {
        let icon = e.data.tipoFactCliente_icon === "svg_bed" ? "icon_bed" : "icon_Kg";

        return <div className={icon} style={{ fontSize: 25, cursor: "pointer" }}></div>;
    }

    dxDataGrid_circularGauge_calculateCellValue(rowData) {
        let impCompa = this.dxDataGrid_compañiaSel.option("importeCompañia");

        let perc_entidad = impCompa > 0 ? rowData.kgEntregados / impCompa : 0;
        return perc_entidad;
    }

    dxDataGrid_circularGauge_cellRender(e) {
        return (
            <div className="container_spanCentrado">
                <CircularGauge className={"CircularGauge_selector"} value={e.value}>
                    <Geometry startAngle={90} endAngle={90} />
                    <RangeContainer width={3} backgroundColor="lightgrey" offset={3} />
                    <Scale startValue={0} endValue={1}>
                        <Tick visible={false} />
                        <Label visible={false} />
                    </Scale>
                    <ValueIndicator type="rangebar" color="#EDB637" offset={0} size={3} />
                    <Size width={53} height={50} />
                </CircularGauge>
                <div style={{ position: "absolute", fontSize: "14px" }}>{formatNumber(e.value, 0, "percent")}</div>
            </div>
        );
    }

    dxDataGrid_compañiaSel_onCellHoverChanged(e) {
        if (
            e.column &&
            e.row &&
            e.rowType == "data" &&
            (e.column.dataField == "kgEntregados" || e.column.name == "print")
        ) {
            if (e.eventType === "mouseover") {
                let error_numRepNoValidados = e.data.numRepNoValidados > 0;

                this.dxPopover.option("contentTemplate", function (contentElement) {
                    let container = $("<div/>");

                    if (e.column.dataField == "kgEntregados" && error_numRepNoValidados) {
                        var msg = "Existen (" + e.data.numRepNoValidados + ") repartos sin validar";
                        container.append("<div>" + msg + "</div>");
                    } else if (e.column.name == "print") {
                        container.append("<div>" + "Imprimir" + "</div>");
                    }
                    container.appendTo(contentElement);
                });

                this.dxPopover.option("target", e.cellElement);
                if ((error_numRepNoValidados && e.column.dataField == "kgEntregados") || e.column.name == "print")
                    this.dxPopover.show();
            } else this.dxPopover.hide();
        }
    }

    dxDataGrid_onRowPrepared(e) {
        if (e.rowType === "data") {
            e.rowElement.css("cursor", "pointer");
        }
    }

    dxDataGrid_onCellClick(e) {
        if (e.row) {
            if (e.row.rowType === "data" && e.column.name !== "print") {
                this.hideSelector({ entidadSel: e.row.data });
            }
        }
    }

    hideSelector(params) {
        let _this = this;
        this.loadPanel_show();
        this.setState({ showSelector: false, tipoTablaSel: 1, ...params }, () => {
            if (this.dxDataGrid_prendas_REF.current) {
                this.dxDataGrid_prendas.refresh().done(function () {
                    _this.loadPanel_hide();
                });
            }
        });
    }

    refreshContent_back() {
        if (this.dxChart_compañiaSel_REF.current) this.dxChart_compañiaSel.render();
    }

    dxChart_customizeTooltip(e, moneda) {
        var html = "";
        var title = "";
        var aggregation = e.point.aggregationInfo;

        if (aggregation.aggregationInterval === "day") {
            title = formatDate(e.point.argument);
        } else {
            var start = formatDate(aggregation.intervalStart);

            var dateEnd = new Date(aggregation.intervalEnd.getTime());
            var end = formatDate(new Date(dateEnd.setDate(dateEnd.getDate() - 1)));
            title = start + " - " + end;
        }

        html += "<div class='tooltip-header'>";
        html += title;
        html += "</div>";

        html += "<div class='tooltip-body'>";

        html += "<div class='tooltip-row'>";
        html += "<div class='series-name'>";
        html += "Kg. Entregados:";
        html += "</div>";
        html += "<div class='value-text'>";
        html += formatNumber(e.point.value, 2, "decimal") + "Kg.";
        html += "</div>";
        html += "</div>";

        return {
            html: html,
        };
    }

    dxChart_compañiaSel_onOptionChanged(e) {
        if (e.name === "dataSource") {
            var maxValueMargin = 0;

            if (e.value.length > 0) {
                var minValueMargin = 0;

                var min = Math.min.apply(
                        null,
                        e.value.map(function (a) {
                            return a.value;
                        })
                    ),
                    max = Math.max.apply(
                        null,
                        e.value.map(function (a) {
                            return a.value;
                        })
                    );

                var diff = max - min;
                if (diff > 0) {
                    var perc = diff / max;
                    var percFinal_max = perc < 0.5 ? 1.25 : perc < 0.75 ? 1.1 : 1;
                    var percFinal_min = perc < 0.5 ? 1.5 : perc < 0.75 ? 1.3 : 1;

                    maxValueMargin = (max * percFinal_max - max) / diff;
                    minValueMargin = (min * percFinal_min - min) / diff;
                }

                e.component.option("valueAxis[0].maxValueMargin", maxValueMargin);
                e.component.option("valueAxis[0].minValueMargin", minValueMargin);
            }
        }
    }

    onItemClick_Exportar(e) {
        let { id } = e.itemData;
        this.setState({ tipoExport: id });

        let _this = this;

        let { fechaSel } = this.state;
        let fechaDesde = fechaSel.startDate;
        let fechaHasta = fechaSel.endDate;

        let workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet("Informe_detallado");
        _this.datasource_spExportDetallado.load().done(function () {
            // Width de las columnas
            worksheet.columns = [
                { width: 11 },
                { width: 35 },
                { width: 35 },
                { width: 10 },
                { width: 56 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
            ];

            //#region Título
            Object.assign(worksheet.getRow(2).getCell(2), {
                value: getTrad("lavanderia"),
                font: { bold: true },
                alignment: { vertical: "middle", horizontal: "left" },
                fill: {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: {
                        argb: "FFCC00",
                    },
                },
                border: {
                    top: { style: "medium" },
                    left: { style: "medium" },
                    bottom: { style: "medium" },
                    right: { style: "medium" },
                },
            });
            Object.assign(worksheet.getRow(2).getCell(3), {
                value: _this.props.lavanderia.denominacion,
                font: { bold: true },
                alignment: { vertical: "middle", horizontal: "left" },
                border: {
                    top: { style: "medium" },
                    bottom: { style: "medium" },
                    right: { style: "medium" },
                },
            });
            // merge by start row, start column, end row, end column
            worksheet.mergeCells(1, 1, 1, 6);

            Object.assign(worksheet.getRow(3).getCell(2), {
                value: "Período",
                font: { bold: true },
                alignment: { vertical: "middle", horizontal: "left" },
                fill: {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: {
                        argb: "FFCC00",
                    },
                },
                border: {
                    top: { style: "medium" },
                    left: { style: "medium" },
                    bottom: { style: "medium" },
                    right: { style: "medium" },
                },
            });
            Object.assign(worksheet.getRow(3).getCell(3), {
                value: formatDate(fechaDesde) + " - " + formatDate(fechaHasta),
                font: { bold: true },
                alignment: { vertical: "middle", horizontal: "left" },
                border: {
                    top: { style: "medium" },
                    bottom: { style: "medium" },
                    right: { style: "medium" },
                },
            });
            //#endregion

            var initBodyExcel_row = 5;
            var objSumatorio = {
                entregadas: 0,
                kgEntregados: 0,
                abonadas: 0,
                kgAbonados: 0,
            };

            //#region Headers prendas
            var gridPrincipal_tableHeaders = [
                getTrad("compañia"),
                getTrad("entidad"),
                getTrad("codigo"),
                getTrad("denominacion"),
                getTrad("peso") + "(g)",
                getTrad("entregadas"),
                getTrad("kgEntregados"),
                getTrad("abonadas"),
                "Kg. " + getTrad("abonados"),
            ];

            $.each(gridPrincipal_tableHeaders, function (headerIndex, headerValue) {
                Object.assign(worksheet.getRow(initBodyExcel_row).getCell(headerIndex + 2), {
                    value: headerValue,
                    font: { bold: true },
                    fill: {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: {
                            argb: "FFCC00",
                        },
                    },
                    border: {
                        top: { style: "medium" },
                        left: { style: headerIndex === 0 ? "medium" : "" },
                        bottom: { style: "medium" },
                        right: { style: headerIndex === 8 ? "medium" : "" },
                    },
                    alignment: {
                        vertical: "middle",
                        horizontal: headerIndex === 0 || headerIndex === 1 || headerIndex === 3 ? "left" : "center",
                    },
                });
            });
            initBodyExcel_row++;
            //#endregion

            let totalPrendas = $.extend(true, [], _this.datasource_spExportDetallado.items());

            totalPrendas = totalPrendas.sort(function (a, b) {
                if (a.denoCompa < b.denoCompa) return -1;

                if (a.denoCompa > b.denoCompa) return 1;

                if (a.denoEnti < b.denoEnti) return -1;

                if (a.denoEnti > b.denoEnti) return 1;

                if (a.codigoPrenda < b.codigoPrenda) return -1;

                if (a.codigoPrenda > b.codigoPrenda) return 1;

                return 0;
            });

            $.each(totalPrendas, function (prendaIndex, prendaItem) {
                var peso = prendaItem.peso * 0.001;

                // Sumatorio subtotal y total
                objSumatorio.entregadas += prendaItem.udsEntregadas;
                objSumatorio.kgEntregados += prendaItem.udsEntregadas * peso;
                objSumatorio.abonadas += prendaItem.udsAbonadas;
                objSumatorio.kgAbonados += prendaItem.udsAbonadas * peso;

                //#region Columnas de prendas
                worksheet.getRow(initBodyExcel_row).getCell(2).value = prendaItem.denoCompa;
                worksheet.getRow(initBodyExcel_row).getCell(3).value = prendaItem.denoEnti;
                worksheet.getRow(initBodyExcel_row).getCell(4).value = prendaItem.codigoPrenda;
                worksheet.getRow(initBodyExcel_row).getCell(4).numFmt = "#";
                worksheet.getRow(initBodyExcel_row).getCell(5).value = prendaItem.denominacion;
                worksheet.getRow(initBodyExcel_row).getCell(6).value = prendaItem.peso;
                worksheet.getRow(initBodyExcel_row).getCell(7).value = prendaItem.udsEntregadas;
                worksheet.getRow(initBodyExcel_row).getCell(8).value = prendaItem.udsEntregadas * peso;
                worksheet.getRow(initBodyExcel_row).getCell(8).numFmt = "0.00";
                worksheet.getRow(initBodyExcel_row).getCell(9).value = prendaItem.udsAbonadas;
                worksheet.getRow(initBodyExcel_row).getCell(10).value = prendaItem.udsAbonadas * peso;
                worksheet.getRow(initBodyExcel_row).getCell(10).numFmt = "0.00";

                $.each(gridPrincipal_tableHeaders, function (headerIndex, headerValue) {
                    Object.assign(worksheet.getRow(initBodyExcel_row).getCell(headerIndex + 2), {
                        alignment: {
                            vertical: "middle",
                            horizontal: headerIndex === 0 || headerIndex === 1 || headerIndex === 3 ? "left" : "center",
                        },
                        border: {
                            left: { style: headerIndex === 0 ? "medium" : "thin" },
                            right: {
                                style: headerIndex === 1 || headerIndex === 15 ? "medium" : "thin",
                            },
                        },
                    });
                });

                if (prendaItem.idPrenda == null) {
                    $.each(gridPrincipal_tableHeaders, function (headerIndex, headerValue) {
                        Object.assign(worksheet.getRow(initBodyExcel_row).getCell(headerIndex + 2), {
                            alignment: { vertical: "middle", horizontal: "left" },
                        });
                    });

                    Object.assign(worksheet.getRow(initBodyExcel_row).getCell(4), {
                        value: getTrad("noLavPrendasEnti"),
                        border: {
                            left: { style: "thin" },
                            right: { style: "medium" },
                        },
                    });
                    worksheet.mergeCells(initBodyExcel_row, 4, initBodyExcel_row, 16);
                }

                initBodyExcel_row++;
                //#endregion
            });

            //#region Total
            worksheet.getRow(initBodyExcel_row).getCell(2).value = getTrad("total").toUpperCase();
            worksheet.getRow(initBodyExcel_row).getCell(7).value = objSumatorio.entregadas;
            worksheet.getRow(initBodyExcel_row).getCell(7).numFmt = "0";
            worksheet.getRow(initBodyExcel_row).getCell(8).value = objSumatorio.kgEntregados;
            worksheet.getRow(initBodyExcel_row).getCell(8).numFmt = "0.00";
            worksheet.getRow(initBodyExcel_row).getCell(9).value = objSumatorio.abonadas;
            worksheet.getRow(initBodyExcel_row).getCell(9).numFmt = "0";
            worksheet.getRow(initBodyExcel_row).getCell(10).value = objSumatorio.kgAbonados;
            worksheet.getRow(initBodyExcel_row).getCell(10).numFmt = "0.00";

            $.each(gridPrincipal_tableHeaders, function (headerIndex, headerValue) {
                var cellFont = worksheet.getRow(initBodyExcel_row).getCell(headerIndex + 2).font;
                if (typeof cellFont !== "undefined") cellFont.bold = true;
                else
                    worksheet.getRow(initBodyExcel_row).getCell(headerIndex + 2).font = {
                        bold: true,
                    };

                Object.assign(worksheet.getRow(initBodyExcel_row).getCell(headerIndex + 2), {
                    fill: {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: {
                            argb: "F39D03",
                        },
                    },
                    border: {
                        top: { style: "medium" },
                        left: { style: headerIndex === 0 ? "medium" : "thin" },
                        bottom: { style: "medium" },
                        right: { style: headerIndex === 14 ? "medium" : "thin" },
                    },
                });
                worksheet.getRow(initBodyExcel_row).getCell(headerIndex + 6).alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            //#endregion

            workbook.xlsx.writeBuffer().then(function (buffer) {
                var formatoFecha = new Date().getDate() + "_" + new Date().getMonth() + "_" + new Date().getFullYear();
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "InfReposicionesGeneral_" + formatoFecha + ".xlsx"
                );
            });
        });
    }

    dateRangePicker_onDateSelected(fecha) {
        if (fecha) {
            if (this.state.showSelector) {
                this.dataSource_load(fecha);
            } else {
                let _this = this;
                let { fechaSel_redireccion, entidadSel } = this.state;
                this.fechaSel = fechaSel_redireccion ? fechaSel_redireccion : fecha;
                this.loadPanel_show();

                setTimeout(() => {
                    _this.setState(
                        {
                            fechaSel: fechaSel_redireccion ? fechaSel_redireccion : fecha,
                            fechaSel_redireccion: null,
                        },
                        () => {
                            $.when(_this.datasource_infReposicionesGeneral_Agrupado_Entidades.reload()).then(
                                function () {
                                    _this.dxDataGrid_prendas.refresh(), _this.dxDataGrid_repartos.refresh();
                                    _this.loadPanel_hide();
                                    let entidadSel_;
                                    if (entidadSel) {
                                        entidadSel_ = $.grep(
                                            _this.datasource_infReposicionesGeneral_Agrupado_Entidades.items(),
                                            function (item) {
                                                return item.idEntidad === entidadSel.idEntidad;
                                            }
                                        )[0];
                                    }

                                    setTimeout(() => {
                                        _this.setState({
                                            entidadSel: entidadSel_ ? entidadSel_ : entidadSel,
                                        });
                                    }, 0);
                                }
                            );
                        }
                    );
                }, 0);
            }
        }
    }

    dataSource_load(fechaSel) {
        let _this = this;
        let { fechaSel_redireccion } = this.state;
        this.fechaSel = fechaSel_redireccion ? fechaSel_redireccion : fechaSel;
        this.loadPanel_show();

        $.when(
            this.datasource_infReposicionesGeneral_Agrupado_Compañias.reload(),
            this.datasource_infReposicionesGeneral_Agrupado_Entidades.reload(),
            this.datasource_infReposicionesGeneral_Agrupado_Fechas.reload()
        ).then(function () {
            let entidadSel;
            if (_this.state.entidadSel) {
                entidadSel = $.grep(
                    _this.datasource_infReposicionesGeneral_Agrupado_Entidades.items(),
                    function (item) {
                        return item.idEntidad === _this.state.entidadSel.idEntidad;
                    }
                )[0];
            }

            _this.setState({
                entidadSel: entidadSel ? entidadSel : _this.state.entidadSel,
                fechaSel: fechaSel_redireccion ? fechaSel_redireccion : fechaSel,
                fechaSel_redireccion: null,
                datos_Agrupado_Compañias: _this.datasource_infReposicionesGeneral_Agrupado_Compañias.items(),
                datos_Agrupado_Entidades: _this.datasource_infReposicionesGeneral_Agrupado_Entidades.items(),
                datos_Agrupado_Fechas: _this.datasource_infReposicionesGeneral_Agrupado_Fechas.items(),
            });

            if (_this.state.showSelector) {
                setTimeout(() => {
                    _this.refreshContent_back();
                    _this.loadPanel_hide();
                }, 0);
            }
        });
    }

    cargaDatos_lavanderia() {
        let _this = this;
        this.loadPanel_show();
        $.when(this.datasource_infReposicionesGeneral_filtroTiempo.reload(), this.datasource_familias.reload()).then(
            function () {
                let items_filtroTiempo = _this.datasource_infReposicionesGeneral_filtroTiempo.items();
                let ultimaFecha_dataSource = new Date(items_filtroTiempo[items_filtroTiempo.length - 1].arg);
                let primeraFecha_dataSource = items_filtroTiempo[0].arg && new Date(items_filtroTiempo[0].arg);
                if (primeraFecha_dataSource == null) {
                    // Sin datos
                    _this.setState({ dxPopup_noDatos_isVisible: true });
                } else {
                    _this.setState({
                        minDate: primeraFecha_dataSource,
                        maxDate: ultimaFecha_dataSource,
                        datasource_familias: _this.datasource_familias.items(),
                    });

                    _this.carga_opcionesInforme();
                    if (!_this.state.showSelector) {
                        setTimeout(() => {
                            _this.loadPanel_hide();
                        }, 0);
                    }
                }
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        let { fechaSel } = this.state;
        let { lavanderia, idioma, param_paginaSecundaria } = this.props;

        if (
            (lavanderia && lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
            this.state.showSelector !== prevState.showSelector ||
            idioma.idIdioma !== prevProps.idioma.idIdioma ||
            (param_paginaSecundaria !== prevProps.param_paginaSecundaria && param_paginaSecundaria != null) ||
            fechaSel !== prevState.fechaSel
        ) {
            if (lavanderia && lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) {
                this.setState({
                    showSelector: true,
                    fechaSel: null,
                    entidadSel: null,
                    tipoExport: null,
                    minDate: null,
                    maxDate: null,
                    tipoTablaSel: 1,
                    prendaSel: null,
                    is_abono: false,
                });
                this.cargaDatos_lavanderia();
            }
            this.props.close();
        }
    }

    componentDidMount() {
        let _this = this;
        this.cargaDatos_lavanderia();
        $.when(
            this.datasource_infReposicionesGeneral_Parametro_Familia.reload(),
            this.datasource_infReposicionesGeneral_Parametro_TipoFacturacion.reload()
        ).done(function () {
            setTimeout(() => {
                _this.setReport({
                    denominacion: "infReposicionesGeneral",
                    parameterEvent: _this.CustomizeParameterEditors,
                });
            }, 0);
        });
    }

    CustomizeParameterEditors(report, s, e) {
        if (e.parameter.type === "System.DateTime") {
            //DATE
            e.info.editor = $.extend({}, e.info.editor);
            e.info.editor.extendedOptions = $.extend(
                e.info.editor.extendedOptions || {},
                { type: "date" },
                { displayFormat: "dd/MM/yyyy" }
            );
        }
        if (
            e.parameter.name === "idLavanderia" ||
            e.parameter.name === "idCompania" ||
            e.parameter.name === "idsRepartos" ||
            e.parameter.name === "idsAbonos" ||
            e.parameter.name === "idToken" ||
            e.parameter.name === "idEntidad" ||
            e.parameter.name === "fechaDesde" ||
            e.parameter.name === "fechaHasta"
        ) {
            //INVISIBLES
            e.info.editor = $.extend({}, e.info.editor);
            e.info.visible = false;
        }
        if (
            e.parameter.name === "idFamilia" ||
            e.parameter.name === "idTipoFacturacion" ||
            e.parameter.name === "isExtra" ||
            e.parameter.name === "tipoInforme"
        ) {
            //PLACEHOLDER

            e.info.editor = $.extend({}, e.info.editor);

            e.info.displayName =
                e.parameter.name === "idFamilia"
                    ? getTrad("familia")
                    : e.parameter.name === "idTipoFacturacion"
                    ? getTrad("tipoFacturacion")
                    : e.parameter.name === "isExtra"
                    ? getTrad("tipoPrenda")
                    : e.parameter.name === "tipoInforme"
                    ? getTrad("agrupacion")
                    : [];

            var dataSource =
                e.parameter.name === "idFamilia"
                    ? this.datasource_infReposicionesGeneral_Parametro_Familia.items()
                    : e.parameter.name === "idTipoFacturacion"
                    ? this.datasource_infReposicionesGeneral_Parametro_TipoFacturacion.items()
                    : e.parameter.name === "isExtra"
                    ? this.enum_infReposicionesGeneral_Parametro_TipoPrenda
                    : e.parameter.name === "tipoInforme"
                    ? this.enum_infReposicionesGeneral_Parametro_TipoInforme
                    : [];

            var showClearButton = e.parameter.name !== "tipoInforme" ? true : false;

            var dataSourceString = dataSource
                .map(function (item) {
                    return JSON.stringify(item).replace(/"/g, "'");
                })
                .toString();

            var editorTemplate =
                '<div data-bind="dxSelectBox: { dataSource: [' +
                dataSourceString +
                "] , valueExpr: 'value', value: value, displayExpr: 'text', placeholder: 'TODAS', showClearButton: " +
                showClearButton +
                ',  dropDownOptions: { container: $root.getPopupContainer($element) } }">    </div>';

            var script = document.createElement("script");
            script.type = "text/hhtml";
            script.id = "Custom-editor-" + report + "-" + e.parameter.name;
            script.innerHTML = editorTemplate;

            document.head.append(script);
            e.info.editor = { header: script.id };
        }
    }

    componentWillUnmount() {
        this.closeReport();
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    setReport: (report) => dispatch(impresionActions.setReport(report)),
    setParams: (params) => dispatch(impresionActions.setParams(params)),
    close: () => dispatch(impresionActions.close()),
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
    setIsPrimeraRedireccion: (redireccion) => dispatch(globalActions.setIsPrimeraRedireccion(redireccion)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfReposicionesGeneral);
