import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";

import { getTrad } from "helpers";
import Form from "./components/Form";
import notify from "devextreme/ui/notify";
import { saveData } from "pages/Administracion/shared/model";
import { payloadBuilder } from "../../model";

const FormPedidoProveedor = forwardRef(({ pedidoSel, validations, reloadData, closePopup }, ref) => {
    const [idMoneda, setIdMoneda] = useState(1);
    const formPedidoProveedor_REF = useRef();

    useEffect(() => {
        setIdMoneda(pedidoSel.idMoneda);
    }, [pedidoSel]);

    useImperativeHandle(ref, () => ({
        onSubmit_FormPedidoProv,
        hasEditData: formPedidoProveedor_REF.current.hasEditData,
    }));

    const onSubmit_FormPedidoProv = () => {
        const formData = formPedidoProveedor_REF.current.getFormData();
        formData.tblArticuloNAdmPedidoProveedor = formData.articulosDraft;
        delete formData.articulosDraft;
        let promises = [];
        validations.forEach((fnValidation) => {
            promises.push(fnValidation());
        });
        Promise.all(promises)
            .then(() => {
                saveData("POST", payloadBuilder(formData, true), "postAdmPedidoProveedor").then((data) => {
                    closePopup();
                    reloadData();
                });
            })
            .catch((message) => {
                if (message == null || message == undefined || message == "") {
                    message = getTrad("errorIntroduccionDatos");
                }
                notify({
                    message: message,
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            });
    };

    return (
        <>
            <div>
                <Form
                    ref={formPedidoProveedor_REF}
                    formData={pedidoSel}
                    onSubmit={onSubmit_FormPedidoProv}
                    validations={validations}
                    setIdMoneda={setIdMoneda}
                    idMoneda={idMoneda}
                />
            </div>
        </>
    );
});

export default FormPedidoProveedor;
