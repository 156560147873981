import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { loadPanelActions } from "../../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import OpcionesTitle from "../../../../layout/AppOpciones/Title";
import OpcionesContainer from "../../../../layout/AppOpciones/Container";

//Layout
import PageTitle from "../../../../layout/AppMain/PageTitle";

import { getNombreFormulario, getTrad } from "../../../../helpers";

import { Tooltip } from "devextreme-react/tooltip";
import $ from "jquery";

//Js
import cargaJs from "./Js.js";

//Css
import "./Css.scss";

class InfLenceria extends React.Component {
  constructor(props) {
    super(props);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  render() {
    let { getBackButton_paginaSecundaria, param_paginaSecundaria } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={
            param_paginaSecundaria != null
              ? param_paginaSecundaria.nombre_paginaSecundaria
              : getNombreFormulario(this)
          }
          postHeading={<OpcionesTitle />}
          preHeading={
            getBackButton_paginaSecundaria
              ? getBackButton_paginaSecundaria()
              : ""
          }
        />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1200}
                transitionEnter={false}
                transitionLeave={false}> */}
          <OpcionesContainer />
          <div id="InfLenceria" className={"formContainer"}>
            <div id="dxContainer"></div>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma, param_paginaSecundaria } = this.props;

    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma ||
      (param_paginaSecundaria !== prevProps.param_paginaSecundaria &&
        param_paginaSecundaria != null)
    ) {
      this.loadPanel_show();
      cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
    } else if (
      param_paginaSecundaria !== prevProps.param_paginaSecundaria &&
      param_paginaSecundaria != null
    ) {
      this.loadPanel_show();
      cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
    }
  }

  componentDidMount() {
    let { lavanderia, idioma } = this.props;

    cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfLenceria);
