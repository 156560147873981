import React from "react";
import { connect } from "react-redux";

import Lottie from "react-lottie";
import { allIcons as lottie_iconos } from "../assets/lottiesFiles";

class LottieIcon extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { icon, height, width, isLoop } = this.props;

    return (
      <Lottie
        height={height ? height : "100%"}
        width={width ? width : "100%"}
        options={{
          loop: isLoop != null ? isLoop : true,
          true: false,
          animationData: lottie_iconos[icon],
          renderSettings: this.renderSettings,
        }}
      />
    );
  }

  renderSettings = {
    preserveAspectRatio: "xMidYMid slice",
  };
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(LottieIcon);
