import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { loadPanelActions } from "../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { getNombreFormulario } from "../../../helpers";

//Js
import cargaJs from "./Js.js";
import PopupInformeMovimientosInventario from "./components/PopupInformeMovimientos";

class Movimientos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible_PopupInformeMovimientos: false,
    };

    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  render() {
    const { isVisible_PopupInformeMovimientos } = this.state;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1200}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="Movimientos" className={"formContainer"}>
            <div id="dxContainer" />
            <div id="popup_añadirPrendas" />
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
        <PopupInformeMovimientosInventario
          visible={isVisible_PopupInformeMovimientos}
          onHiding={this.onHiding_PopupInformeMovimientos}
        />
      </Fragment>
    );
  }

  onShow_PopupInformeMovimientos = () =>
    this.setState({ isVisible_PopupInformeMovimientos: true });

  onHiding_PopupInformeMovimientos = () =>
    this.setState({ isVisible_PopupInformeMovimientos: false });

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      cargaJs(
        this,
        lavanderia,
        idioma,
        this.onShow_PopupInformeMovimientos,
      ).then(() => this.loadPanel_hide());
    }
  }

  componentDidMount() {
    let { lavanderia, idioma } = this.props;
    cargaJs(this, lavanderia, idioma, this.onShow_PopupInformeMovimientos).then(
      () => this.loadPanel_hide(),
    );
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Movimientos);
