import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  tooltipControl_creacion,
  dxMensajePregunta,
  get_template_colorTapa,
  patchRequestHandler,
} from "../../../helpers";
import {
  svg_remove,
  svg_elemTrans_ud,
  svg_elemTrans_bac,
} from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import notify from "devextreme/ui/notify";

export default function cargaJs(
  COMPONENT,
  LAVANDERIA,
  IDIOMA,
  onShow_PopupInformeMovimientos,
) {
  var deferred = $.Deferred();

  var movimientoSel = null,
    dxDropDownBoxSelCompaEnti_isCompañia = null,
    tipoMovSel = { isEntrada: null, idTipoMovimiento: null },
    is_guardadoNuevoMov = false,
    movimientoSel_noMod = null,
    isCodigoAlbaranExists = false,
    isCodigoAlbaranValidating = false;
  // Para que al seleccionar nuevo reparto no salte el popup de que se está editando

  //#region DATASOURCES
  var datasource_tblMovimiento = new DataSource({
      store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMovimiento",
        key: "idMovimiento",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          request.params.idLavanderia = LAVANDERIA
            ? LAVANDERIA.idLavanderia
            : null;
        },
        version: 4,
      }),
      expand: [
        "idCompañiaNavigation($select=denominacion)",
        "idEntidadNavigation($select=denominacion)",
        "idTipoMovimientoNavigation($select=isEntrada)",
      ],
    }),
    datasource_spSelectPrendaNMovimiento = new DataSource({
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL +
          "EF_inventarios_spSelectPrendaNMovimiento",
        key: "idPrenda",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          if (request.method !== "get") {
            request.url = request.url.replace(
              "EF_inventarios_spSelectPrendaNMovimiento",
              "tblPrendaNMovimiento",
            );
          }

          var idCompañia = null,
            idEntidad = null,
            idMovimiento = null;

          if (movimientoSel) {
            idCompañia = movimientoSel.idCompañia;
            idEntidad = movimientoSel.idEntidad;
            idMovimiento = movimientoSel.idMovimiento;
          } else if (
            typeof $("#dxDropDownBox_selCompaEnti")[0] !== "undefined"
          ) {
            var dxDropDownBox_selCompaEnti = $(
              "#dxDropDownBox_selCompaEnti",
            ).dxDropDownBox("instance");

            if (dxDropDownBoxSelCompaEnti_isCompañia != null) {
              if (dxDropDownBoxSelCompaEnti_isCompañia)
                idCompañia = dxDropDownBox_selCompaEnti.option("value");
              else idEntidad = dxDropDownBox_selCompaEnti.option("value");
            } else idMovimiento = -1;
          }

          request.params.idCompañia = idCompañia;
          request.params.idEntidad = idEntidad;
          request.params.idMovimiento = idMovimiento;
        },
        version: 4,
      }),
    }),
    datasource_tblCompañia = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCompañia",
        key: "idCompañia",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          request.params.idLavanderia = LAVANDERIA
            ? LAVANDERIA.idLavanderia
            : null;
        },
        version: 4,
      }),
      sort: "denominacion",
      select: ["idCompañia", "denominacion"],
      filter: "tblPrenda/any() and tblPrenda/any(p: p/activo eq true)",
    }),
    datasource_tblEntidad = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEntidad",
        key: "idEntidad",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          request.params.idLavanderia = LAVANDERIA
            ? LAVANDERIA.idLavanderia
            : null;
        },
        version: 4,
      }),
      expand: ["idMonedaNavigation"],
      sort: "denominacion",
      select: ["idCompañia", "idEntidad", "denominacion"],
      filter: "tblPrenda/any() and tblPrenda/any(p: p/activo eq true)",
      postProcess: (data) => {
        data = data.map((item) => {
          item.tblMoneda = item.idMonedaNavigation;
          delete item.idMonedaNavigation;
          return item;
        });
        return data;
      },
    }),
    datasource_tblTipoMovimiento = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.ODATA_URL + "tblTipoMovimiento",
        key: "idTipoMovimiento",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
        },
        version: 4,
      }),
      expand: ["tblTraduccion($select = " + IDIOMA.codigo + ")"],
      postProcess: function (data) {
        $.each(data, function (index, tipoMov) {
          tipoMov.denominacion =
            tipoMov.tblTraduccion[IDIOMA.codigo].toUpperCase();
        });
        return data;
      },
    }),
    enum_entradaSalida = [
      {
        value: true,
        denominacion: getTrad("entrada"),
      },
      {
        value: false,
        denominacion: getTrad("salida"),
      },
    ],
    datasource_inventarios_spSelectAddPrendasMov = new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL + "inventarios_spSelectAddPrendasMov",
        key: "idPrenda",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          request.params.idMovimiento = movimientoSel.idMovimiento;
        },
        version: 4,
      }),
      sort: ["codigoPrenda"],
    }),
    context_Movimientos = new ODataContext({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL + "Inventarios/Movimientos/",
      entities: {
        fn_isCodigoAlbaranExists: {
          key: ["idMovimientoRecambio", "codigoAlbaran"],
          keyType: {
            idMovimientoRecambio: "Int32",
            codigoAlbaran: "String",
          },
        },
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
    });
  //#endregion

  $.when(
    datasource_tblCompañia.load(),
    datasource_tblEntidad.load(),
    datasource_tblTipoMovimiento.load(),
  )
    .then(function () {
      $("#Movimientos #dxContainer").dxBox({
        direction: "row",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
          {
            ratio: 1,
            template: function (e, index, item) {
              item.append(
                $("<div />")
                  .css("padding-right", 15)
                  .dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        baseSize: 50,
                        template: function (e, index, item) {
                          item.append(
                            $(
                              "<div id='dxToolbar_movimientosAnteriores' />",
                            ).dxToolbar({
                              items: [
                                {
                                  location: "before",
                                  template: function (e, index, item) {
                                    item.append(
                                      $(
                                        "<div id='dxDropDownBox_selCompaEnti' />",
                                      ).dxDropDownBox({
                                        width: 305,
                                        deferRendering: false,
                                        remoteOperations: true,
                                        placeholder: getTrad(
                                          "seleccionaCompaEnti",
                                        ),
                                        // EVENTS
                                        dropDownOptions: {
                                          width: 800,
                                          height: 500,
                                          position: {
                                            my: "center",
                                            at: "center",
                                            of: window,
                                          },
                                          closeOnOutsideClick: true,
                                        },
                                        dataSource: !movimientoSel
                                          ? null
                                          : dxDropDownBoxSelCompaEnti_isCompañia
                                            ? datasource_tblCompañia.items()
                                            : datasource_tblEntidad.items(),
                                        value: !movimientoSel
                                          ? null
                                          : dxDropDownBoxSelCompaEnti_isCompañia ===
                                              null
                                            ? null
                                            : dxDropDownBoxSelCompaEnti_isCompañia
                                              ? movimientoSel.idCompañia
                                              : movimientoSel.idEntidad,
                                        displayExpr: "denominacion",
                                        valueExpr:
                                          dxDropDownBoxSelCompaEnti_isCompañia
                                            ? "idCompañia"
                                            : "idEntidad",
                                        disabled: !movimientoSel ? false : true,
                                        onOpened: function (e) {
                                          if (
                                            $("#dxDatagrid_nuevoMovimiento")
                                              .dxDataGrid("instance")
                                              .getController("editing")
                                              .hasChanges() === true
                                          ) {
                                            dxMensajePregunta(
                                              getTrad("preg_PerderCambios"),
                                              [
                                                [
                                                  getTrad("aceptar"),
                                                  function () {},
                                                  "normal",
                                                  "btnAceptar",
                                                ],
                                                [
                                                  getTrad("cancelar"),
                                                  function () {
                                                    e.component.close();
                                                  },
                                                  "normal",
                                                  "btnCancelar",
                                                ],
                                              ],
                                            );
                                          }
                                        },
                                        onClosed: function (e) {
                                          //#region Si no se limpian se quedan filtradas
                                          $("#dxList_compañias")
                                            .dxList("instance")
                                            .option("searchValue", "");
                                          $("#dxList_entidades")
                                            .dxList("instance")
                                            .option("searchValue", "");
                                          //#endregion
                                        },
                                        onValueChanged: function (e) {
                                          var dxList_EntradaSalida = $(
                                            "#dxList_EntradaSalida",
                                          ).dxList("instance");
                                          if (e.component.option("value")) {
                                            $("#dxDatagrid_nuevoMovimiento")
                                              .dxDataGrid("instance")
                                              .option(
                                                "dataSource",
                                                datasource_spSelectPrendaNMovimiento,
                                              );
                                            // Tiene un error de validación, se ha intentado escoger movimiento sin tener compa/enti seleccionada.

                                            if (
                                              tipoMovSel.isEntrada &&
                                              dxList_EntradaSalida.option(
                                                "validationError",
                                              )
                                            ) {
                                              dxList_EntradaSalida.option(
                                                "validationError",
                                                false,
                                              );

                                              var dataSource_TiposMov = $.grep(
                                                datasource_tblTipoMovimiento.items(),
                                                function (item) {
                                                  return (
                                                    item.isEntrada ===
                                                    tipoMovSel.isEntrada
                                                  );
                                                },
                                              );
                                              $("#dxList_tiposMov")
                                                .dxList("instance")
                                                .option(
                                                  "dataSource",
                                                  dataSource_TiposMov,
                                                );
                                              $("#dxSelectBox_entradaSalida")
                                                .dxSelectBox("instance")
                                                .option(
                                                  "value",
                                                  tipoMovSel.isEntrada,
                                                );
                                              $("#dxTabPanel_nuevoMov")
                                                .dxTabPanel("instance")
                                                .option("selectedIndex", 1);
                                            }
                                          }
                                        },
                                        contentTemplate: function (
                                          dxDropDownBox,
                                        ) {
                                          return $("<div />").dxBox({
                                            direction: "row",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            height: 400,
                                            width: "100%",
                                            items: [
                                              {
                                                ratio: 1,
                                                template: function (
                                                  itemData,
                                                  itemIndex,
                                                  itemElement,
                                                ) {
                                                  itemElement.css(
                                                    "padding-right",
                                                    "15px",
                                                  );

                                                  // COMPAÑÍAS
                                                  itemElement.append(
                                                    $("<div/>").dxBox({
                                                      direction: "col",
                                                      align: "space-around",
                                                      crossAlign: "stretch",
                                                      height: "100%",
                                                      width: "100%",
                                                      items: [
                                                        {
                                                          baseSize: 50,
                                                          shrink: 0,
                                                          ratio: 0,
                                                          template:
                                                            function () {
                                                              return $(
                                                                "<div class='font-size' style='margin-left:9px;'>" +
                                                                  getTrad(
                                                                    "compañia",
                                                                  ) +
                                                                  "</div>",
                                                              );
                                                            },
                                                        },
                                                        {
                                                          ratio: 1,
                                                          template:
                                                            function () {
                                                              return $(
                                                                "<div id='dxList_compañias'>",
                                                              ).dxList({
                                                                dataSource:
                                                                  datasource_tblCompañia.items(),
                                                                selectionMode:
                                                                  "single",
                                                                height: "100%",
                                                                focusStateEnabled: false,
                                                                width: "95%",
                                                                keyExpr:
                                                                  "idCompañia",
                                                                scrolling: {
                                                                  mode: "infinite",
                                                                },
                                                                pageLoadMode:
                                                                  "scrollBottom",
                                                                searchEnabled: true,
                                                                searchExpr: [
                                                                  "denominacion",
                                                                ],
                                                                // EVENTOS
                                                                onSelectionChanged:
                                                                  function (e) {
                                                                    if (
                                                                      e.component.option()
                                                                        .selectedItems
                                                                        .length >
                                                                      0
                                                                    ) {
                                                                      var valor =
                                                                        e.component.option()
                                                                          .selectedItems[0]
                                                                          .idCompañia;
                                                                      dxDropDownBoxSelCompaEnti_selectionChanged(
                                                                        true,
                                                                        valor,
                                                                      );
                                                                      dxDropDownBox.component.close();

                                                                      var grid =
                                                                        $(
                                                                          "#dxDatagrid_nuevoMovimiento",
                                                                        ).dxDataGrid(
                                                                          "instance",
                                                                        );
                                                                      grid.cancelEditData();
                                                                      grid.refresh(
                                                                        true,
                                                                      );
                                                                    }
                                                                  },
                                                                itemTemplate:
                                                                  function (
                                                                    data,
                                                                    index,
                                                                  ) {
                                                                    var result =
                                                                      $(
                                                                        "<div>",
                                                                      );
                                                                    $("<div>")
                                                                      .text(
                                                                        data.denominacion,
                                                                      )
                                                                      .appendTo(
                                                                        result,
                                                                      );

                                                                    return result;
                                                                  },
                                                              });
                                                            },
                                                        },
                                                      ],
                                                    }),
                                                  );
                                                },
                                              },
                                              {
                                                ratio: 1,
                                                template: function (
                                                  itemData,
                                                  itemIndex,
                                                  itemElement,
                                                ) {
                                                  itemElement.css(
                                                    "padding-right",
                                                    "15px",
                                                  );

                                                  //ENTIDADES
                                                  itemElement.append(
                                                    $("<div/>").dxBox({
                                                      direction: "col",
                                                      align: "space-around",
                                                      crossAlign: "stretch",
                                                      height: "100%",
                                                      width: "100%",
                                                      items: [
                                                        {
                                                          baseSize: 50,
                                                          shrink: 0,
                                                          ratio: 0,
                                                          template:
                                                            function () {
                                                              return $(
                                                                "<div class='font-size' style='margin-left:9px;'>" +
                                                                  getTrad(
                                                                    "entidad",
                                                                  ) +
                                                                  "</div>",
                                                              );
                                                            },
                                                        },
                                                        {
                                                          ratio: 1,
                                                          template:
                                                            function () {
                                                              return $(
                                                                "<div id='dxList_entidades'>",
                                                              ).dxList({
                                                                dataSource:
                                                                  datasource_tblEntidad,
                                                                selectionMode:
                                                                  "single",
                                                                keyExpr:
                                                                  "idEntidad",
                                                                focusStateEnabled: false,
                                                                height: "100%",
                                                                scrolling: {
                                                                  mode: "infinite",
                                                                },
                                                                pageLoadMode:
                                                                  "scrollBottom",
                                                                searchEnabled: true,
                                                                searchExpr: [
                                                                  "denominacion",
                                                                ],
                                                                //EVENTOS
                                                                onSelectionChanged:
                                                                  function (e) {
                                                                    if (
                                                                      e.component.option()
                                                                        .selectedItems
                                                                        .length >
                                                                      0
                                                                    ) {
                                                                      var valor =
                                                                        e.component.option()
                                                                          .selectedItems[0]
                                                                          .idEntidad;

                                                                      dxDropDownBoxSelCompaEnti_selectionChanged(
                                                                        false,
                                                                        valor,
                                                                      );
                                                                      dxDropDownBox.component.close();

                                                                      var grid =
                                                                        $(
                                                                          "#dxDatagrid_nuevoMovimiento",
                                                                        ).dxDataGrid(
                                                                          "instance",
                                                                        );
                                                                      grid.cancelEditData();
                                                                      grid.refresh(
                                                                        true,
                                                                      );
                                                                    }
                                                                  },
                                                                itemTemplate:
                                                                  function (
                                                                    data,
                                                                    index,
                                                                  ) {
                                                                    var result =
                                                                      $(
                                                                        "<div>",
                                                                      );
                                                                    $("<div>")
                                                                      .text(
                                                                        data.denominacion,
                                                                      )
                                                                      .appendTo(
                                                                        result,
                                                                      );

                                                                    return result;
                                                                  },
                                                              });
                                                            },
                                                        },
                                                      ],
                                                    }),
                                                  );
                                                },
                                              },
                                            ],
                                          });
                                        },
                                      }),
                                    );
                                  },
                                },
                                {
                                  location: "before",
                                  widget: "dxDateBox",
                                  disabled: !movimientoSel ? false : true,
                                  options: {
                                    elementAttr: {
                                      id: "dxDateBox_fechaMovimiento",
                                    },
                                    displayFormat: "dd/MM/yyyy",
                                    value: new Date(),
                                    width: 130,
                                  },
                                },
                                {
                                  location: "before",
                                  widget: "dxSelectBox",
                                  options: {
                                    width: 105,
                                    elementAttr: {
                                      id: "dxSelectBox_entradaSalida",
                                    },
                                    items: enum_entradaSalida,
                                    valueExpr: "value",
                                    displayExpr: "denominacion",
                                    value: null,
                                    disabled: true,
                                    visible: false,
                                    onValueChanged: function (e) {
                                      e.component.option("visible", e.value);
                                    },
                                  },
                                },
                                {
                                  location: "before",
                                  widget: "dxSelectBox",
                                  options: {
                                    width: 215,
                                    elementAttr: {
                                      id: "dxSelectBox_tipoMovimiento",
                                    },
                                    items: datasource_tblTipoMovimiento.items(),
                                    valueExpr: "idTipoMovimiento",
                                    displayExpr: "denominacion",
                                    value: null,
                                    disabled: true,
                                    visible: false,
                                    onValueChanged: function (e) {
                                      e.component.option("visible", e.value);
                                    },
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                      {
                        baseSize: 80,
                        template: template_dxToolbar_codigos,
                      },
                      {
                        ratio: 1,
                        template: function (e, index, item) {
                          item.append(
                            $("<div id='dxTabPanel_nuevoMov' />")
                              .addClass("no-header")
                              .dxTabPanel({
                                height: "100%",
                                width: "100%",
                                deferRendering: false,
                                animationEnabled: true,
                                swipeEnabled: false,
                                items: [
                                  {
                                    name: "entradaSalida",
                                    template: function (
                                      itemData,
                                      itemIndex,
                                      itemElement,
                                    ) {
                                      itemElement.css("padding-top", 50);
                                      itemElement.append(
                                        $(
                                          "<div id='dxList_EntradaSalida' />",
                                        ).dxList({
                                          dataSource: enum_entradaSalida,
                                          height: "100%",
                                          selectionMode: "single",
                                          keyExpr: "value",
                                          scrolling: { mode: "infinite" },
                                          pageLoadMode: "scrollBottom",
                                          validationError: false, // si lo tiene true, al seleccionar compa o enti pasará subtipo
                                          onItemClick: function (e) {
                                            tipoMovSel.isEntrada =
                                              e.itemData.value;

                                            if (
                                              $("#dxDropDownBox_selCompaEnti")
                                                .dxDropDownBox("instance")
                                                .option("value")
                                            ) {
                                              var dataSource_TiposMov = $.grep(
                                                datasource_tblTipoMovimiento.items(),
                                                function (item) {
                                                  return (
                                                    item.isEntrada ===
                                                    tipoMovSel.isEntrada
                                                  );
                                                },
                                              );
                                              $("#dxList_tiposMov")
                                                .dxList("instance")
                                                .option(
                                                  "dataSource",
                                                  dataSource_TiposMov,
                                                );
                                              $("#dxSelectBox_entradaSalida")
                                                .dxSelectBox("instance")
                                                .option(
                                                  "value",
                                                  tipoMovSel.isEntrada,
                                                );
                                              $("#dxTabPanel_nuevoMov")
                                                .dxTabPanel("instance")
                                                .option("selectedIndex", 1);
                                            } else {
                                              $("#dxDropDownBox_selCompaEnti")
                                                .dxDropDownBox("instance")
                                                .open();
                                              e.component.option(
                                                "validationError",
                                                true,
                                              );
                                            }
                                          },
                                          itemTemplate: function (
                                            data,
                                            index,
                                            element,
                                          ) {
                                            var listHeight = $(
                                              "#dxList_EntradaSalida",
                                            ).height();
                                            element
                                              .parent()
                                              .css("height", listHeight / 2);

                                            var result = $("<div/>")
                                              .addClass(
                                                "container_spanCentrado",
                                              )
                                              .css("height", "100%");
                                            $("<div class='font-size'>")
                                              .text(
                                                data.denominacion.toUpperCase(),
                                              )
                                              .appendTo(result);
                                            return result;
                                          },
                                        }),
                                      );
                                    },
                                  },
                                  {
                                    name: "tipoMovimiento",
                                    template: function (
                                      itemData,
                                      itemIndex,
                                      itemElement,
                                    ) {
                                      itemElement.append(
                                        $("<div/>").dxBox({
                                          direction: "col",
                                          align: "space-around",
                                          crossAlign: "stretch",
                                          height: "100%",
                                          width: "100%",
                                          items: [
                                            {
                                              baseSize: 50,
                                              shrink: 0,
                                              ratio: 0,
                                              template: function (
                                                itemData,
                                                itemIndex,
                                                itemElement,
                                              ) {
                                                itemElement.append(
                                                  $("<div />").dxButton({
                                                    text: getTrad("atras"),
                                                    icon: "chevronleft",
                                                    width: 100,
                                                    onClick: function (e) {
                                                      tipoMovSel.idTipoMovimiento =
                                                        null;
                                                      $("#dxTabPanel_nuevoMov")
                                                        .dxTabPanel("instance")
                                                        .option(
                                                          "selectedIndex",
                                                          0,
                                                        );
                                                      $(
                                                        "#dxSelectBox_entradaSalida",
                                                      )
                                                        .dxSelectBox("instance")
                                                        .option("value", null);
                                                      $(
                                                        "#dxSelectBox_tipoMovimiento",
                                                      )
                                                        .dxSelectBox("instance")
                                                        .option("value", null);
                                                    },
                                                  }),
                                                );
                                              },
                                            },
                                            {
                                              ratio: 1,
                                              template: function (
                                                itemData,
                                                itemIndex,
                                                itemElement,
                                              ) {
                                                itemElement.append(
                                                  $(
                                                    "<div id='dxList_tiposMov'/>",
                                                  ).dxList({
                                                    height: "100%",
                                                    selectionMode: "single",
                                                    keyExpr: "idTipoMovimiento",
                                                    scrolling: {
                                                      mode: "infinite",
                                                    },
                                                    pageLoadMode:
                                                      "scrollBottom",
                                                    onSelectionChanged:
                                                      function (e) {
                                                        if (
                                                          e.addedItems.length >
                                                          0
                                                        ) {
                                                          var dxDatagrid_nuevoMovimiento =
                                                            $(
                                                              "#dxDatagrid_nuevoMovimiento",
                                                            ).dxDataGrid(
                                                              "instance",
                                                            );
                                                          tipoMovSel.idTipoMovimiento =
                                                            e.addedItems[0].idTipoMovimiento;

                                                          dxDatagrid_nuevoMovimiento.columnOption(
                                                            "precio",
                                                            "visible",
                                                            tipoMovSel.isEntrada,
                                                          );
                                                          dxDatagrid_nuevoMovimiento.columnOption(
                                                            "precio",
                                                            "format",
                                                            currency_format(),
                                                          );
                                                          dxDatagrid_nuevoMovimiento.columnOption(
                                                            "precio",
                                                            "editorOptions.format",
                                                            currency_format(),
                                                          );

                                                          dxDatagrid_nuevoMovimiento.repaint();
                                                          $(
                                                            "#dxTabPanel_nuevoMov",
                                                          )
                                                            .dxTabPanel(
                                                              "instance",
                                                            )
                                                            .option(
                                                              "selectedIndex",
                                                              2,
                                                            );
                                                          $(
                                                            "#dxSelectBox_tipoMovimiento",
                                                          )
                                                            .dxSelectBox(
                                                              "instance",
                                                            )
                                                            .option(
                                                              "value",
                                                              tipoMovSel.idTipoMovimiento,
                                                            );
                                                        }
                                                      },
                                                    itemTemplate: function (
                                                      data,
                                                      index,
                                                      element,
                                                    ) {
                                                      var listHeight =
                                                          $(
                                                            "#dxList_tiposMov",
                                                          ).height(),
                                                        dxList_tiposMov_items =
                                                          $("#dxList_tiposMov")
                                                            .dxList("instance")
                                                            .getDataSource()
                                                            .items().length;

                                                      element
                                                        .parent()
                                                        .css(
                                                          "height",
                                                          listHeight /
                                                            dxList_tiposMov_items,
                                                        );
                                                      var result = $("<div/>")
                                                        .addClass(
                                                          "container_spanCentrado",
                                                        )
                                                        .css("height", "100%");
                                                      $(
                                                        "<div class='font-size'>",
                                                      )
                                                        .text(
                                                          data.denominacion.toUpperCase(),
                                                        )
                                                        .appendTo(result);
                                                      return result;
                                                    },
                                                  }),
                                                );
                                              },
                                            },
                                          ],
                                        }),
                                      );
                                    },
                                  },
                                  {
                                    name: "prendasNMovimiento",
                                    template: (
                                      itemData,
                                      itemIndex,
                                      itemElement,
                                    ) =>
                                      dxBox_prendasNMovimiento(
                                        itemData,
                                        itemIndex,
                                        itemElement,
                                      ),
                                  },
                                ],
                                onSelectionChanged: function (e) {
                                  var tabSelected =
                                    e.component.option("selectedItem.name");
                                  switch (tabSelected) {
                                    case "entradaSalida":
                                      $("#dxList_EntradaSalida")
                                        .dxList("instance")
                                        .unselectAll();
                                      break;
                                    case "tipoMovimiento":
                                      $("#dxList_tiposMov")
                                        .dxList("instance")
                                        .unselectAll();
                                      break;
                                    default:
                                      break;
                                  }
                                },
                              }),
                          );
                        },
                      },
                    ],
                  }),
              );
            },
          },
          {
            //grid secundario
            ratio: 1,
            template: function (e, index, item) {
              item.append(
                $("<div />")
                  .css("padding-right", 15)
                  .dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        ratio: 1,
                        template: function (e, index, item) {
                          item.append(() =>
                            dxDatagrid_movimientosAnteriores(
                              onShow_PopupInformeMovimientos,
                            ),
                          );
                        },
                      },
                    ],
                  }),
              );
            },
          },
        ],
      });
    })
    .always(function () {
      setTimeout(() => {
        var dxList_EntradaSalida = $("#dxList_EntradaSalida").dxList(
          "instance",
        );
        var dxList_tiposMov = $("#dxList_tiposMov").dxList("instance");

        if (dxList_EntradaSalida && dxList_tiposMov) {
          dxList_EntradaSalida.repaint();
          dxList_tiposMov.repaint();
        }
      }, 5);

      deferred.resolve();
    });

  //POPUP AÑADIR PRENDAS
  $("#Movimientos #popup_añadirPrendas").dxPopup({
    showTitle: true,
    title: getTrad("añadirPrenda"),
    height: "80%",
    width: "35%",
    minWidth: "500px",
    contentTemplate: function () {
      return $("<div id='dxList_añadirPrendas' />").dxList({
        dataSource: datasource_inventarios_spSelectAddPrendasMov,
        selectionMode: "multiple",
        showSelectionControls: false,
        pageLoadMode: "scrollBottom",
        itemTemplate: function (data, index, item) {
          var contenedor = $("<div>")
            .css("display", "flex")
            .css("margin", "0px 15px");
          contenedor.append(
            $("<div>").text(data.codigoPrenda).css("flex-basis", 80),
          );
          contenedor.append($("<div>").text(data.denoPrenda).css("flex", 1));

          var container = $("<div />")
            .addClass("container_spanCentrado")
            .css("padding-left", "10px")
            .css("flex-basis", 100);
          var svg;
          var htmlTooltip = "";
          if (parseInt(data.codigoElemTransPedido) === 1) {
            // POR UDS
            svg = svg_elemTrans_ud.clone();
            htmlTooltip = "<p>" + getTrad("porUds") + "</p>";
          } else if (parseInt(data.codigoElemTransPedido) === 2) {
            // BAC/SACA
            svg = svg_elemTrans_bac.clone();
            htmlTooltip =
              "<p>" + getTrad("bacSaca") + ": " + data.udsXBacPedido + "</p>";
          }

          svg
            .css("height", "20px")
            .css("width", "25px")
            .css("cursor", "pointer")
            .appendTo(container);

          tooltipControl_creacion(container, htmlTooltip);

          contenedor.append(container);

          let colorTapa = get_template_colorTapa(item, data);
          colorTapa.appendTo(contenedor);

          return contenedor;
        },
      });
    },
    onShown: () => {
      $("#dxList_añadirPrendas").dxList("instance").option("selectedItems", []);
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("añadir"),
          type: "success",
          onClick: function (e) {
            //Añadir prenda a las prendas N reparto
            var prendasSeleccionadas = $("#dxList_añadirPrendas")
              .dxList("instance")
              .option("selectedItems");

            if (prendasSeleccionadas.length > 0) {
              $.each(prendasSeleccionadas, function (index, item) {
                var isLastElement = index === prendasSeleccionadas.length - 1;
                var prendaNMovimiento = {
                  idMovimiento: movimientoSel.idMovimiento,
                  idPrenda: item.idPrenda,
                  cantidad: 0,
                  precio: 0,
                };
                datasource_spSelectPrendaNMovimiento
                  .store()
                  .insert(prendaNMovimiento)
                  .done(function () {
                    if (isLastElement) {
                      $("#dxDatagrid_nuevoMovimiento")
                        .dxDataGrid("instance")
                        .refresh();
                    }
                  });
              });

              $("#popup_añadirPrendas").dxPopup("instance").hide();

              notify({
                message: getTrad("aviso_C_RegistroInsertado"),
                type: "success",
                displayTime: "600",
                closeOnClick: true,
              });
            }
          },
        },
      },
    ],
  });

  //#region FUNCIONES
  function dxDropDownBoxSelCompaEnti_selectionChanged(isCompañia, valor) {
    var dxDropDownBox = $("#dxDropDownBox_selCompaEnti").dxDropDownBox(
      "instance",
    );
    dxDropDownBox.option("value", null);
    dxDropDownBox.option({
      value: valor,
      valueExpr: isCompañia ? "idCompañia" : "idEntidad",
      dataSource: isCompañia
        ? datasource_tblCompañia.items()
        : datasource_tblEntidad.items(),
    });
    dxDropDownBoxSelCompaEnti_isCompañia = isCompañia;

    if (isCompañia) {
      $("#dxList_entidades").dxList("instance").unselectAll();
    } else {
      $("#dxList_compañias").dxList("instance").unselectAll();
    }
  }
  function currency_format() {
    var moneda = "EUR";
    var dxDropDownBox_selCompaEnti = $(
      "#dxDropDownBox_selCompaEnti",
    ).dxDropDownBox("instance");
    if (!dxDropDownBoxSelCompaEnti_isCompañia && dxDropDownBox_selCompaEnti) {
      var idEntidad = dxDropDownBox_selCompaEnti.option("value");
      var entidad = datasource_tblEntidad
        .items()
        .find((x) => x.idEntidad === idEntidad);

      if (entidad) {
        moneda = entidad.tblMoneda.codigo;
      }
    } else if (LAVANDERIA) {
      moneda = LAVANDERIA.moneda;
    } else if (
      dxDropDownBoxSelCompaEnti_isCompañia &&
      dxDropDownBox_selCompaEnti
    ) {
      var idCompañia = dxDropDownBox_selCompaEnti.option("value");
      var entidad = datasource_tblEntidad
        .items()
        .find((x) => x.idCompañia === idCompañia);

      if (entidad) {
        moneda = entidad.tblMoneda.codigo;
      }
    }
    return { style: "currency", currency: moneda, minimumFractionDigits: 3 };
  }

  function reiniciarNuevoMov() {
    //#region Resetear variables globales
    movimientoSel = null;
    dxDropDownBoxSelCompaEnti_isCompañia = null;
    tipoMovSel.isEntrada = null;
    tipoMovSel.idTipoMovimiento = null;
    movimientoSel_noMod = null;
    //#endregion

    $("#dxDatagrid_movimientosAnteriores")
      .dxDataGrid("instance")
      .option("selectedRowKeys", null);

    $("#dxTabPanel_nuevoMov").dxTabPanel("instance").option("selectedIndex", 0);

    //#region Toolbar
    $("#dxToolbar_movimientosAnteriores").dxToolbar("instance").repaint();
    $("#dxToolbar_codigos").dxToolbar("instance").repaint();
    $("#dxDropDownBox_selCompaEnti").dxDropDownBox("instance").close();

    $("#dxDateBox_fechaMovimiento").dxDateBox("instance").option({
      value: new Date(),
      disabled: false,
    });

    $("#dxTextArea_observaciones")
      .dxTextArea("instance")
      .option({ value: null });

    $("#dxSelectBox_entradaSalida")
      .dxSelectBox("instance")
      .option("value", null);
    $("#dxSelectBox_tipoMovimiento")
      .dxSelectBox("instance")
      .option("value", null);
    //#endregion
  }

  function generarMovimiento(e) {
    var grid = e.component,
      nuevoMov = {};

    isCodigoAlbaranExists = false;

    nuevoMov.idCompañia = null;
    nuevoMov.idEntidad = null;

    var dxDropDownBox_selCompaEnti = $(
      "#dxDropDownBox_selCompaEnti",
    ).dxDropDownBox("instance");

    if (dxDropDownBoxSelCompaEnti_isCompañia)
      nuevoMov.idCompañia = dxDropDownBox_selCompaEnti.option("value");
    else nuevoMov.idEntidad = dxDropDownBox_selCompaEnti.option("value");

    nuevoMov.idTipoMovimiento = tipoMovSel.idTipoMovimiento;
    nuevoMov.fecha = $("#dxDateBox_fechaMovimiento")
      .dxDateBox("instance")
      .option("value");
    nuevoMov.tblPrendaNMovimiento = [];
    nuevoMov.codigoAlbaran = $("#dxTextBox_codigoAlbaran")
      .dxTextBox("instance")
      .option("value");
    nuevoMov.codigoFactura = $("#dxTextBox_codigoFactura")
      .dxTextBox("instance")
      .option("value");
    nuevoMov.observaciones = $("#dxTextArea_observaciones")
      .dxTextArea("instance")
      .option("value");

    $.each(grid.getVisibleRows(), function (index, item) {
      if (item.rowType === "data") {
        var prenda = {};
        prenda.idPrenda = grid.cellValue(item.rowIndex, "idPrenda");
        prenda.cantidad = grid.cellValue(item.rowIndex, "cantidad");
        prenda.precio = 0;

        if (tipoMovSel.isEntrada) {
          //ENTRADA -- PRECIO
          prenda.precio = grid.cellValue(item.rowIndex, "precio");
        }

        if (prenda.cantidad > 0) {
          nuevoMov.tblPrendaNMovimiento.push(prenda);
        }
      }
    });

    if (nuevoMov.tblPrendaNMovimiento.length > 0) {
      datasource_tblMovimiento
        .store()
        .insert(nuevoMov)
        .done(function (item) {
          var gridAnteriores = $(
            "#dxDatagrid_movimientosAnteriores",
          ).dxDataGrid("instance");
          gridAnteriores.refresh().done(function () {
            is_guardadoNuevoMov = true;
            gridAnteriores.selectRows(item.idMovimiento);
            gridAnteriores.getScrollable().scrollTo(0);
          });
        });
    } else {
      notify({
        message: getTrad("alerta_cantidadRepartir"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    }
    $("#dxButton_atrasNuevoMov").dxButton("instance").option("disabled", false);
  }

  function asyncValidation_codigoAlbaran(value) {
    const codigoAlbaran = value.trim();
    isCodigoAlbaranValidating = true;

    return new Promise((resolve, reject) => {
      if (codigoAlbaran.length > 0) {
        let objRequest = { codigoAlbaran };
        if (movimientoSel?.idMovimiento)
          objRequest.idMovimiento = movimientoSel.idMovimiento;

        context_Movimientos
          .invoke("fn_isCodigoAlbaranExists", objRequest)
          .done((exists) => {
            isCodigoAlbaranValidating = false;
            isCodigoAlbaranExists = exists;
            !exists ? resolve() : reject();
          });
      } else {
        isCodigoAlbaranValidating = false;
        isCodigoAlbaranExists = false;
        resolve();
      }
    });
  }

  function template_dxToolbar_codigos(e, index, item) {
    item.append(
      $("<div id='dxToolbar_codigos' />").dxToolbar({
        items: [
          {
            location: "before",
            template: () => {
              const container = $("<div />");

              const label = $("<label />").addClass("dx-form pl-1");

              $("<span />")
                .addClass("dx-field-item-label-text")
                .text(getTrad("codigoAlbaran"))
                .appendTo(label);

              label.appendTo(container);

              $('<div id="dxTextBox_codigoAlbaran" />')
                .dxTextBox({
                  value: movimientoSel?.codigoAlbaran,
                  onValueChanged: ({ value }) => {
                    if (value && movimientoSel)
                      movimientoSel.codigoAlbaran = value.trim();
                  },
                })
                .dxValidator({
                  validationRules: [
                    {
                      type: "async",
                      message: getTrad("aviso_I_CodigoAlbaranExistente"),
                      validationCallback: ({ value }) =>
                        asyncValidation_codigoAlbaran(value),
                    },
                  ],
                })
                .appendTo(container);

              return container;
            },
          },
          {
            location: "before",
            template: () => {
              const container = $("<div />");

              const label = $("<label />").addClass("dx-form pl-1");

              $("<span />")
                .addClass("dx-field-item-label-text")
                .text(getTrad("codigoFactura"))
                .appendTo(label);

              label.appendTo(container);

              $('<div id="dxTextBox_codigoFactura" />')
                .dxTextBox({
                  value: movimientoSel?.codigoFactura,
                  onValueChanged: ({ value }) => {
                    if (value && movimientoSel)
                      movimientoSel.codigoFactura = value;
                  },
                })
                .appendTo(container);

              return container;
            },
          },
        ],
      }),
    );
  }

  function dxBox_prendasNMovimiento(itemData, itemIndex, itemElement) {
    itemElement.append(
      $("<div />").dxBox({
        direction: "col",
        height: "100%",
        width: "100%",
        items: [
          {
            ratio: 1,
            template: () =>
              $('<div id="dxDatagrid_nuevoMovimiento"/>').dxDataGrid({
                //Datos
                dataSource: datasource_spSelectPrendaNMovimiento,
                remoteOperations: true,
                //Propiedades
                height: "100%",
                columnsAutoWidth: true,
                editing: {
                  mode: "batch",
                  allowUpdating: true,
                  useIcons: true,
                  selectTextOnEditStart: true,
                },
                keyboardNavigation: {
                  enterKeyAction: "moveFocus",
                  enterKeyDirection: "row",
                  editOnKeyPress: true,
                },
                loadPanel: { enabled: false },
                scrolling: { mode: "infinite" },
                columns: [
                  {
                    dataField: "idPrenda",
                    visible: false,
                    allowEditing: false,
                  },
                  {
                    dataField: "codigoPrenda",
                    caption: getTrad("codigo"),
                    width: "120px",
                    alignment: "center",
                    allowEditing: false,
                    sortOrder: "asc",
                  },
                  {
                    dataField: "denoPrenda",
                    caption: getTrad("denominacion"),
                    minWidth: "150px",
                    allowEditing: false,
                  },
                  {
                    dataField: "cantidad",
                    caption: getTrad("cantidad"),
                    width: 110,
                    alignment: "center",
                    dataType: "number",
                    cssClass: "dx-Cell_Editable",
                    format: { style: "decimal", maximumFractionDigits: 0 },
                    allowEditing: true,
                    editorOptions: {
                      step: 0,
                      format: { style: "decimal", maximumFractionDigits: 0 },
                      min: 0,
                      max: 999999,
                    },
                  },
                  {
                    dataField: "precio",
                    caption: getTrad("precio"),
                    width: 120,
                    alignment: "center",
                    cssClass: "dx-Cell_Editable",
                    dataType: "number",
                    visible: true,
                    format: currency_format(),
                    editorOptions: {
                      format: currency_format(),
                      step: 0,
                      min: 0,
                      max: 9999.999,
                    },
                  },
                ],
                onToolbarPreparing: function (e) {
                  //Modificar los iconos por defecto c guardar y cancelar del batchEdti
                  $.each(e.toolbarOptions.items, function (i, item) {
                    if (item.name === "saveButton") {
                      item.visible = false;
                    } else {
                      item.options.tooltipText = item.options.hint;
                      item.options.hint = null;

                      tooltipControl_creacion(
                        item,
                        item.options.tooltipText,
                        true,
                      );
                    }
                  });

                  e.toolbarOptions.items.unshift(
                    {
                      widget: "dxButton",
                      showText: "always",
                      location: "after",
                      options: {
                        icon: "check",
                        type: "success",
                        text: !movimientoSel
                          ? getTrad("realizarMovimiento")
                          : getTrad("guardar"),
                        onClick: () => {
                          $("#dxTextBox_codigoAlbaran")
                            .dxTextBox("instance")
                            .blur();
                          onClick_btnGuardarMovimiento(e);
                        },
                      },
                    },
                    {
                      location: "before",
                      widget: "dxButton",
                      toolbar: "bottom",
                      options: {
                        text: getTrad("atras"),
                        icon: "chevronleft",
                        elementAttr: {
                          id: "dxButton_atrasNuevoMov",
                        },
                        disabled: movimientoSel,
                        width: 100,
                        onClick: function (e) {
                          tipoMovSel.idTipoMovimiento = null;
                          $("#dxList_tiposMov")
                            .dxList("instance")
                            .unselectAll();
                          $("#dxTabPanel_nuevoMov")
                            .dxTabPanel("instance")
                            .option("selectedIndex", 1);
                          $("#dxSelectBox_tipoMovimiento")
                            .dxSelectBox("instance")
                            .option("value", null);
                        },
                      },
                    },
                    {
                      location: "before",
                      widget: "dxButton",
                      toolbar: "bottom",
                      options: {
                        width: "200px",
                        icon: "plus",
                        text: getTrad("nuevoMovimiento"),
                        onClick: function () {
                          const cambios_movimientoSel =
                            getCambios_movimientoSel();

                          if (
                            e.component
                              .getController("editing")
                              .hasChanges() === true ||
                            cambios_movimientoSel.someUpdate
                          ) {
                            dxMensajePregunta(getTrad("preg_PerderCambios"), [
                              [
                                getTrad("aceptar"),
                                function () {
                                  reiniciarNuevoMov();
                                  $("#dxDropDownBox_selCompaEnti")
                                    .dxDropDownBox("instance")
                                    .open();
                                },
                                "normal",
                                "btnAceptar",
                              ],
                              [
                                getTrad("cancelar"),
                                function () {},
                                "normal",
                                "btnCancelar",
                              ],
                            ]);
                          } else {
                            reiniciarNuevoMov();
                            $("#dxDropDownBox_selCompaEnti")
                              .dxDropDownBox("instance")
                              .open();
                          }
                        },
                      },
                    },
                    {
                      location: "before",
                      widget: "dxButton",
                      toolbar: "bottom",
                      options: {
                        width: 170,
                        icon: "plus",
                        text: getTrad("añadirPrenda"),
                        type: "success",
                        visible: movimientoSel,
                        onClick: function () {
                          if (
                            typeof $("#dxList_añadirPrendas")[0] !== "undefined"
                          ) {
                            datasource_inventarios_spSelectAddPrendasMov.load();
                          }
                          $("#popup_añadirPrendas").dxPopup("instance").show();
                        },
                      },
                    },
                  );
                },
                //Estilos
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: true,
              }),
          },
          {
            baseSize: 133,
            template: (e, index, item) =>
              template_observaciones(e, index, item),
          },
        ],
      }),
    );
  }

  function template_observaciones(e, index, item) {
    item.append(
      $('<div id="tit_observaciones" style="height:38px" />')
        .css("marginBottom", "10px")
        .css("marginTop", "10px")
        .addClass("font-size")
        .text(getTrad("observaciones")),
    );
    item.append(
      $('<div id="dxTextArea_observaciones" />').dxTextArea({
        value: movimientoSel?.observaciones,
        height: "75px",
        width: "100%",
        onValueChanged: ({ value }) => {
          if (value) movimientoSel.observaciones = value;
        },
      }),
    );
  }

  function onClick_btnGuardarMovimiento(e) {
    if (
      !movimientoSel &&
      typeof $("#dxDropDownBox_selCompaEnti")[0] !== "undefined"
    ) {
      generarMovimiento(e);
      return;
    }

    const cambios_movimientoSel = getCambios_movimientoSel();

    if (isCodigoAlbaranValidating) return;

    if (
      isCodigoAlbaranExists &&
      movimientoSel?.codigoAlbaran.trim().length > 0
    ) {
      notify({
        message: getTrad("aviso_I_CodigoAlbaranExistente"),
        type: "error",
        displayTime: 1500,
        closeOnClick: true,
      });
      return;
    }

    const isUpdated_tblPrendaNMovimiento = $("#dxDatagrid_nuevoMovimiento")
      .dxDataGrid("instance")
      .hasEditData();

    if (!cambios_movimientoSel.someUpdate && !isUpdated_tblPrendaNMovimiento) {
      notify({
        message: getTrad("alerta_SinCambios"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
      return;
    }

    Object.keys(cambios_movimientoSel.objUpdate).forEach((prop) => {
      movimientoSel_noMod[prop] = cambios_movimientoSel.objUpdate[prop];
    });

    const updatePromise_tblMovimiento = cambios_movimientoSel.someUpdate
      ? datasource_tblMovimiento
          .store()
          .update(
            movimientoSel.idMovimiento,
            patchRequestHandler(cambios_movimientoSel.objUpdate),
          )
      : Promise.resolve();

    Promise.all([e.component.saveEditData(), updatePromise_tblMovimiento]).then(
      () => {
        $("#dxDatagrid_movimientosAnteriores").dxDataGrid("instance").refresh();

        notify({
          message: getTrad("aviso_C_RegistroActualizado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
    );
  }

  function dxDatagrid_movimientosAnteriores(onShow_PopupInformeMovimientos) {
    return $("<div id='dxDatagrid_movimientosAnteriores' />")
      .css("padding-top", 50)
      .dxDataGrid({
        //Datos
        dataSource: datasource_tblMovimiento,
        remoteOperations: true,
        //Propiedades
        width: "100%",
        height: "100%",
        columnsAutoWidth: true,
        filterRow: {
          visible: true,
          showAllText: getTrad("todos").toUpperCase(),
          applyFilter: "auto",
        },
        headerFilter: {
          visible: true,
        },
        searchPanel: {
          visible: true,
          width: 240,
          placeholder: getTrad("busqueda"),
        },
        selection: {
          mode: "single",
        },
        paging: {
          pageSize: 50,
        },
        pager: {
          showPageSizeSelector: true,
          allowedPageSizes: [20, 50, 100],
          showInfo: true,
        },
        hoverStateEnabled: true,
        columnChooser: { enabled: true },
        allowColumnReordering: true,
        allowColumnResizing: false,
        columns: [
          {
            dataField: "idMovimiento",
            caption: getTrad("codigo"),
            minWidth: 60,
            width: 60,
            allowHeaderFiltering: false,
            alignment: "center",
          },
          {
            dataField: "fecha",
            caption: getTrad("fecha"),
            dataType: "date",
            format: "dd/MM/yyyy",
            width: 160,
            minWidth: 160,
            alignment: "center",
            sortOrder: "desc",
            editorOptions: {
              showClearButton: true,
            },
            allowHeaderFiltering: false,
          },
          {
            dataField: "denoCliente",
            caption: getTrad("compañia") + " / " + getTrad("entidad"),
            minWidth: 200,
            allowHeaderFiltering: false,
            allowSorting: false,
            allowHiding: false,
            editorOptions: {
              showClearButton: true,
            },
            calculateCellValue: function (rowData) {
              return rowData.idCompañia
                ? rowData.idCompañiaNavigation.denominacion
                : rowData.idEntidadNavigation.denominacion;
            },
            calculateFilterExpression: function (filterValue, filterOperation) {
              return [
                ["idCompañiaNavigation/denominacion", "contains", filterValue],
                "or",
                ["idEntidadNavigation/denominacion", "contains", filterValue],
              ];
            },
          },
          {
            dataField: "idTipoMovimientoNavigation.isEntrada",
            caption: getTrad("tipo"),
            width: 95,
            minWidth: 95,
            alignment: "left",
            lookup: {
              dataSource: enum_entradaSalida,
              valueExpr: "value",
              displayExpr: "denominacion",
            },
          },
          {
            dataField: "idTipoMovimiento",
            caption: getTrad("subTipo"),
            width: 110,
            minWidth: 110,
            alignment: "left",
            lookup: {
              dataSource: datasource_tblTipoMovimiento.items(),
              valueExpr: "idTipoMovimiento",
              displayExpr: "denominacion",
            },
          },
          {
            dataField: "observaciones",
            caption: getTrad("observaciones"),
            width: 200,
            allowHeaderFiltering: false,
            visible: false,
          },
          {
            dataField: "codigoAlbaran",
            caption: getTrad("codigoAlbaran"),
            width: 120,
            allowHeaderFiltering: false,
            visible: false,
          },
          {
            dataField: "codigoFactura",
            caption: getTrad("codigoFactura"),
            width: 120,
            allowHeaderFiltering: false,
            visible: false,
          },
          {
            caption: " ",
            width: "60px",
            alignment: "center",
            cssClass: "p-0",
            allowHiding: false,
            allowReordering: false,
            cellTemplate: function (cellElement, cellInfo) {
              var contenedor = $("<div/>").addClass("container_spanCentrado");
              svg_remove
                .clone()

                .css("height", "25px")
                .css("width", "25px")
                .css("cursor", "pointer")
                .appendTo(contenedor);

              cellElement.on("click", function () {
                cellInfo.component.deleteRow(cellInfo.rowIndex);
              });

              contenedor.appendTo(cellElement);
            },
          },
          {
            visible: false,
            showInColumnChooser: false,
            calculateFilterExpression: (
              filterValue,
              selectedFilterOperation,
            ) => {
              return [
                [
                  `tblPrendaNMovimiento/any(pnm: contains(tolower(pnm/idPrendaNavigation/denominacion), '${filterValue}'))`,
                ],
                "or",
                [
                  `tblPrendaNMovimiento/any(pnm: contains(tolower(pnm/idPrendaNavigation/codigoPrenda), '${filterValue}'))`,
                ],
              ];
            },
          },
        ],
        //Eventos
        onRowPrepared: function (info) {
          if (info.rowType === "data") {
            info.rowElement.css("cursor", "pointer");
          }
        },
        onRowRemoved: function (e) {
          reiniciarNuevoMov();

          notify({
            message: getTrad("aviso_C_RegistroEliminado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
        },
        onSelectionChanged: function (e) {
          var rowSel = e.selectedRowsData;
          if (rowSel.length > 0) {
            function selectMovimientoAnterior(rowSel) {
              $("#dxTabPanel_nuevoMov").dxTabPanel("instance").beginUpdate();
              movimientoSel = $.extend(true, {}, rowSel[0]);
              movimientoSel_noMod = { ...movimientoSel };

              isCodigoAlbaranExists = false;

              var dxDatagrid_nuevoMovimiento = $(
                "#dxDatagrid_nuevoMovimiento",
              ).dxDataGrid("instance");

              //#region Dropdown compaEnti
              dxDropDownBoxSelCompaEnti_isCompañia =
                movimientoSel.idCompañia !== null;
              if (movimientoSel.idCompañia)
                $("#dxList_entidades").dxList("instance").unselectAll();
              else $("#dxList_compañias").dxList("instance").unselectAll();

              //#endregion
              $("#dxToolbar_movimientosAnteriores")
                .dxToolbar("instance")
                .repaint();
              $("#dxToolbar_codigos").dxToolbar("instance").repaint();

              $("#dxTextArea_observaciones")
                .dxTextArea("instance")
                .option({ value: movimientoSel?.observaciones });

              $("#dxTextBox_codigoAlbaran")
                .dxTextBox("instance")
                .option({ value: movimientoSel?.codigoAlbaran });
              $("#dxTextBox_codigoFactura")
                .dxTextBox("instance")
                .option({ value: movimientoSel?.codigoFactura });

              $("#dxDateBox_fechaMovimiento").dxDateBox("instance").option({
                value: movimientoSel.fecha,
                disabled: true,
              });

              $("#dxTabPanel_nuevoMov")
                .dxTabPanel("instance")
                .option("selectedIndex", 2);
              $("#dxSelectBox_entradaSalida")
                .dxSelectBox("instance")
                .option(
                  "value",
                  movimientoSel.idTipoMovimientoNavigation.isEntrada,
                );
              $("#dxSelectBox_tipoMovimiento")
                .dxSelectBox("instance")
                .option("value", movimientoSel.idTipoMovimiento);

              datasource_spSelectPrendaNMovimiento
                .reload()
                .done(function (items) {
                  dxDatagrid_nuevoMovimiento.beginUpdate();
                  dxDatagrid_nuevoMovimiento.cancelEditData();
                  dxDatagrid_nuevoMovimiento.columnOption(
                    "precio",
                    "visible",
                    movimientoSel.idTipoMovimientoNavigation.isEntrada,
                  );
                  dxDatagrid_nuevoMovimiento.columnOption(
                    "precio",
                    "format",
                    currency_format(),
                  );
                  dxDatagrid_nuevoMovimiento.columnOption(
                    "precio",
                    "editorOptions.format",
                    currency_format(),
                  );

                  dxDatagrid_nuevoMovimiento.endUpdate();
                });
              dxDatagrid_nuevoMovimiento.repaint(); // Para repintar el botón de guardado
              $("#dxTabPanel_nuevoMov").dxTabPanel("instance").endUpdate();
            }

            const cambios_movimientoSel = getCambios_movimientoSel();

            if (
              ($("#dxDatagrid_nuevoMovimiento")
                .dxDataGrid("instance")
                .getController("editing")
                .hasChanges() === true &&
                !is_guardadoNuevoMov) ||
              cambios_movimientoSel.someUpdate
            ) {
              dxMensajePregunta(getTrad("preg_PerderCambios"), [
                [
                  getTrad("aceptar"),
                  function () {
                    selectMovimientoAnterior(rowSel);
                  },
                  "normal",
                  "btnAceptar",
                ],
                [
                  getTrad("cancelar"),
                  function () {
                    $("#dxDatagrid_movimientosAnteriores")
                      .dxDataGrid("instance")
                      .selectRows([movimientoSel.idMovimiento]);
                  },
                  "normal",
                  "btnCancelar",
                ],
              ]);
            } else {
              selectMovimientoAnterior(rowSel);
              is_guardadoNuevoMov = false;
            }
          }
        },

        onToolbarPreparing: function (e) {
          e.toolbarOptions.items.unshift(
            {
              location: "before",
              template: function () {
                return $(
                  "<div class ='font-size' style='margin-top : 10px;'>",
                ).text(getTrad("movimientosAnteriores"));
              },
            },
            {
              location: "after",
              widget: "dxButton",
              options: {
                icon: "paste",
                text: getTrad("informe"),
                onClick: onShow_PopupInformeMovimientos,
              },
            },
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              locateInMenu: "auto",
              options: {
                text: getTrad("limpiarFiltro"),
                icon: " icon_EliminarFiltros",
                onInitialized: function (e) {
                  tooltipControl_creacion(
                    e.element,
                    e.component.option("text"),
                  );
                },
                onClick: function () {
                  e.component.clearFilter();
                  notify({
                    message: getTrad("aviso_C_FiltroRestaurado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                },
              },
            },
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              options: {
                icon: "refresh",
                text: getTrad("actualizar"),
                onInitialized: function (e) {
                  tooltipControl_creacion(
                    e.element,
                    e.component.option("text"),
                  );
                },
                onClick: function () {
                  e.component.refresh(true);
                },
              },
            },
          );
        },
        //Estilos
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });
  }

  function getCambios_movimientoSel() {
    let objUpdate = {};
    let someUpdate = false;

    const revisarCampo = (campo, value) => {
      if (movimientoSel_noMod && value !== movimientoSel_noMod?.[campo]) {
        objUpdate[campo] = value;
        someUpdate = true;
      }
    };

    const observaciones = $("#dxTextArea_observaciones")
      .dxTextArea("instance")
      .option("value");
    const codigoAlbaran = $("#dxTextBox_codigoAlbaran")
      .dxTextBox("instance")
      .option("value");
    const codigoFactura = $("#dxTextBox_codigoFactura")
      .dxTextBox("instance")
      .option("value");

    revisarCampo("observaciones", observaciones);
    revisarCampo("codigoAlbaran", codigoAlbaran);
    revisarCampo("codigoFactura", codigoFactura);

    return { someUpdate, objUpdate };
  }

  //#endregion

  return deferred.promise();
}
