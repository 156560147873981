import React, { Fragment } from "react";
import { getNombreFormulario, getTrad } from "../../../helpers";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

//Components
import CierreDatos_Facturacion from "./components/Facturacion/CierreDatos_Facturacion";
import CierreDatos_Lavanderia from "./components/Lavanderia/CierreDatos_Lavanderia";
import CierreDatos_Uniformidad from "./components/Uniformidad/CierreDatos_Uniformidad";
import CierreDatos_Valet from "./components/Valet/CierreDatos_Valet";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";

class CierreDatos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <PageTitle heading={"Cierre de datos"} />
        <div className={"media-body"}>
          <div id="CierreDatos" className="formContainer scrollbar-container">
            <TabPanel
              className="h3"
              height="100%"
              width="100%"
              deferRendering={false}
              animationEnabled={true}
              swipeEnabled={false}
            >
              <TabPanelItem title={getTrad("facturacion")} tabIndex={0}>
                <CierreDatos_Facturacion />
              </TabPanelItem>
              <TabPanelItem title={getTrad("lavanderia")} tabIndex={0}>
                <CierreDatos_Lavanderia />
              </TabPanelItem>
              <TabPanelItem title={getTrad("uniformidad")} tabIndex={0}>
                <CierreDatos_Uniformidad />
              </TabPanelItem>
              <TabPanelItem title={getTrad("valet")} tabIndex={0}>
                <CierreDatos_Valet />
              </TabPanelItem>
            </TabPanel>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CierreDatos;
