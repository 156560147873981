import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import "./JornadaPopup.scss";

import { Button, DropDownButton, ScrollView, SelectBox, Tooltip } from "devextreme-react";
import Popup, { ToolbarItem as PopupToolbarItem } from "devextreme-react/popup";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Box, { Item as ItemBox } from "devextreme-react/box";
import DateBox, { Button as DateBoxButton } from "devextreme-react/date-box";

import Guid from "devextreme/core/guid";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";

import LottieIcon from "components/LottieIcon";
import { connectionConstants } from "constants";
import {
    addDays,
    addMinutes,
    capitalize,
    datetimeToDuration,
    durationToDatetime,
    dxMensajePregunta,
    formatDate_noTime_parameter,
    formatTime_parameter,
    getTrad,
    minuteDiff,
    timeToDatetime,
} from "helpers";
import { ds_authHeader, ds_errorHandler, setBalances } from "../../services/apiCalls";
import { authHeader, patchRequestHandler } from "./../../../../../helpers/connection";
import FotoPersona from "components/FotoPersona";

export const jornadaEstado = {
    valido: { tipo: "valido", estado: "Jornada finalizada" },
    validoEstado: { tipo: "valido", estado: "Día sin trabajo válido" },
    validoIncumplimiento: { tipo: "valido", estado: "Jornada finalizada con incumplimiento horario" },
    diferenciaHoraria: { tipo: "warning", estado: "La jornada ha infringido el horario del cuadrante" },
    errorEstado: { tipo: "error", estado: "El estado del cuadrante difiere de la realidad" },
    sinDatos: { tipo: "error", estado: "No se ha registrado ninguna jornada" },
    error: { tipo: "error", estado: "Datos de la jornada incompletos" },

    errorJornadaSinCuadrante: { tipo: "error", estado: "Jornada sin cuadrante asociado", hideRevision: true },
    jornadaSinCuadranteCompleta: { tipo: "error", estado: "Jornada sin cuadrante asociado", hideRevision: true },
    validoJornadaSinCuadrante: { tipo: "valido", estado: "Jornada válida sin cuadrante asociado", hideRevision: true },

    activoSinEmpezar: { tipo: "activo", estado: "Aún no ha empezado el cuadrante establecido" },
    activoDiferenciaHoraria: {
        tipo: "warning",
        estado: "Trabajando - La jornada ha infringido el horario del cuadrante",
    },
    activoError: { tipo: "error", estado: "Trabajando - Datos de la jornada incompletos" },
    activoTrabajando: { tipo: "activo", estado: "Trabajando" },
    activoDescansando: { tipo: "activo", estado: "Descansando" },
};

class JornadaPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dxPopup_isVisible: false,
            data: {},
            index: 0,
            datosDia: [],
            jornadas: [],
            cuadrante: null,
            calendario: null,
            tooltipData: {},
            isValidacionDisabled: false,

            fnSaveCuadrante: undefined,
            balanceHoras: 0,
        };

        this.tblEventoPersona_Estado = [];
        this.hasModificaciones = false;

        this.tblCalendarioPersonal_Estado_Seleccionable = [];
    }

    // #region TRADUCCIONES

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    // #endregion

    dateFormat = { day: "2-digit", month: "2-digit", year: "numeric" };
    timeFormat = { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };
    dxDateBox_props = {
        type: "time",
        displayFormat: { hour: "2-digit", minute: "2-digit" },
        // format: "HH:mm",
        showDropDownButton: false,
        useMaskBehavior: true,
    };

    componentDidMount = () => {
        this.dataSource_tblEventoPersona_Estado.load().then((data) => {
            this.tblEventoPersona_Estado = data;
        });
    };

    // #region Contexts

    dataSource_tblEventoPersona_Estado = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEventoPersona_Estado",
            errorHandler: ds_errorHandler,
            beforeSend: ds_authHeader,
            version: 4,
        }),
        select: ["idEventoPersona_Estado", "denominacion"],
        expand: ["idTraduccionNavigation($select=clave)"],
    });

    dataSource_tblJornada = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblJornada",
            errorHandler: ds_errorHandler,
            beforeSend: (request) => this.dataSource_tblJornada_beforeSend(request),
            onLoading: (loadOptions) => this.dataSource_tblJornada_onLoading(loadOptions),
            version: 4,
        }),
        select: [
            "idJornada",
            "fecha",
            "idPersona",
            "idLavanderia",
            "idTurno",
            "idCuadrantePersonal",
            "horaIni",
            "horaFin",
            "tiempoDescanso",
            "isRegValido",
            "isRevisado",
            "idMotivoIncumplimiento_horaIni",
            "idMotivoIncumplimiento_horaFin",
            "idMotivoIncumplimiento_tiempoDescanso",
        ],
        expand: ["tblBalanceHorasExtra", "tblBalanceHoras", "tblEventoPersona($orderby=fecha asc)"],
        map: (item) => {
            item.tblBalanceHorasExtra = setBalances(item, "tblBalanceHorasExtra");
            item.tblBalanceHoras = setBalances(item, "tblBalanceHoras");
            return item;
        },
    });

    dataSource_tblJornada_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { lavanderia } = this.props;
        request.params.idLavanderia = lavanderia.idLavanderia;
    };

    tblJornada_onLoading_data = {};
    dataSource_tblJornada_onLoading = (loadOptions) => {
        const { idPersona, fecha } = this.tblJornada_onLoading_data;
        loadOptions.filter = [[`idPersona eq ${idPersona}`], [`fecha eq ${formatDate_noTime_parameter(fecha)}`]];
    };

    context_jornadaController = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/Jornada/",
        entities: {
            DeclararAbsentismo: {},
            ModificarRegistrosDia: {},
        },
        errorHandler: ds_errorHandler,
        beforeSend: (request) => this.context_jornadaController_beforeSend(request),
    });

    paramsContext = {};
    payload = [];
    context_jornadaController_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        let action = request.url.split("/").pop();

        this.setState({ isValidacionDisabled: true });

        if (action === "ModificarRegistrosDia") {
            request.payload = this.payload;
        }
        request.params = this.paramsContext;
    };

    context_tblBalanceHoras = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblBalanceHoras",
        entities: {
            GetBalanceTotal: {},
        },
        errorHandler: ds_errorHandler,
        beforeSend: ds_authHeader,
    });

    // #endregion

    show = (defaultData, datosDia) => {
        let array = datosDia.filter(
            (x) =>
                ((x.estimacion || x.cuadrante) &&
                    x.jornadas.length === 0 &&
                    this.getJornadaEstado(null, x.cuadrante, x.calendarioPersonal).tipo !== "valido") ||
                (x.jornadas.length > 0 &&
                    x.jornadas.filter(
                        (jornada) =>
                            this.getJornadaEstado(jornada, x.cuadrante, x.calendarioPersonal).tipo !== "valido" &&
                            !jornada.isRevisado
                    ).length > 0)
        );

        let index = defaultData ? array.findIndex((x) => x.idPersona === defaultData.idPersona) : 0;
        let data = index === -1 ? defaultData : array.at(index);

        if (index === -1) {
            array = [];
        }

        this.loadJornada(data).then((jornadas) => {
            this.tblCalendarioPersonal_Estado_Seleccionable = this.props.CuadranteContext.tblCalendarioPersonal_Estado
                .filter((x) => x.idEstado !== 10 && x.idEstado !== 4 && x.idEstado !== 3)
                .map((x) => {
                    let isFestivo =
                        this.props.CuadranteContext.tblCalendarioLavanderia.filter(
                            (x) => x.idEstado === 8 && x.fecha === formatDate_noTime_parameter(data.fecha)
                        ).length > 0;
                    if (x.idEstado === 8) {
                        x.disabled = !isFestivo;
                    }
                    return x;
                });

            this.setState({
                dxPopup_isVisible: true,
                datosDia: array,
                index,
                data,
                jornadas,
                cuadrante: data.cuadrante,
                calendario: data.calendarioPersonal,
            });
        });
    };

    loadJornada = (data) => {
        let deferred = $.Deferred();

        this.context_tblBalanceHoras
            .invoke("GetBalanceTotal", { idPersona: data.idPersona }, "GET")
            .then((balanceHoras) => this.setState({ balanceHoras }));

        this.tblJornada_onLoading_data = {
            idPersona: data.idPersona,
            fecha: data.fecha,
        };
        this.dataSource_tblJornada.reload().then((jornadas) => {
            if (
                jornadas.length === 0 &&
                (data.calendarioPersonal.idEstado === 3 || data.calendarioPersonal.idEstado === 4)
            ) {
                jornadas.push(
                    this.newJornada(data.idPersona, data.fecha, data.idTurno, data.cuadrante?.idCuadrantePersonal)
                );
            }
            deferred.resolve(jornadas);
        });

        return deferred.promise();
    };

    newJornada = (idPersona, fecha, idTurno, idCuadrantePersonal) => {
        const { lavanderia } = this.props;
        let jornada = {
            idJornada: new Guid(),
            fecha: formatDate_noTime_parameter(fecha),
            idPersona: idPersona,
            idLavanderia: lavanderia.idLavanderia,
            idTurno: idTurno,
            idCuadrantePersonal: idCuadrantePersonal,
            horaIni: null,
            horaFin: null,
            tiempoDescanso: null,
            isRevisado: false,
            isRegValido: false,
            idMotivoIncumplimiento_horaIni: null,
            idMotivoIncumplimiento_horaFin: null,
            idMotivoIncumplimiento_tiempoDescanso: null,
            tblBalanceHorasExtra: [],
            tblBalanceHoras: [],
            tblEventoPersona: [],
        };
        jornada.tblBalanceHorasExtra = setBalances(jornada, "tblBalanceHorasExtra");
        jornada.tblBalanceHoras = setBalances(jornada, "tblBalanceHoras");
        return jornada;
    };

    validarContinuar = () => {
        const { CuadranteContext } = this.props;
        const { data, datosDia } = this.state;

        CuadranteContext.reload();
        if (datosDia.length <= 1) {
            this.dxPopup_onHiding();
        } else {
            let newDatosDia = datosDia.filter((x) => x !== data);
            this.dxToolbarItem_onClick(null, newDatosDia);
        }

        this.setState({ isValidacionDisabled: false });
    };

    render() {
        const { dxPopup_isVisible, data, index, datosDia, jornadas, tooltipData, isValidacionDisabled } = this.state;

        const estado = this.getEstadoJornadasDia();

        return (
            <Popup
                visible={dxPopup_isVisible}
                onHiding={this.dxPopup_onHiding}
                onHidden={this.dxPopup_onHidden}
                showTitle={true}
                title={data?.fecha?.toLocaleDateString(undefined, this.dateFormat)}
                width={"60%"}
                height={"70%"}
                dragEnabled={false}
            >
                <PopupToolbarItem toolbar="top" location="center">
                    <div className="w-100 font-size-lg">
                        {datosDia.length > 1 ? (
                            <span>
                                CONFLICTOS{" "}
                                <span className="text-danger">
                                    {index + 1}/{datosDia.length}
                                </span>
                            </span>
                        ) : (
                            "Jornada"
                        )}
                    </div>
                </PopupToolbarItem>
                <PopupToolbarItem toolbar="bottom" location="after">
                    <Button
                        disabled={
                            (estado.tipo !== "valido" &&
                                estado !== jornadaEstado.validoJornadaSinCuadrante &&
                                estado !== jornadaEstado.jornadaSinCuadranteCompleta) ||
                            isValidacionDisabled
                        }
                        visible={estado !== jornadaEstado.errorEstado}
                        type="success"
                        text={"Validar y continuar"}
                        onClick={this.dxToolbarItem_onClick_Validar}
                    />
                </PopupToolbarItem>
                <PopupToolbarItem toolbar="bottom" location="after">
                    <Button text={this.getTrad("cancelar")} onClick={this.dxPopup_onHiding} />
                </PopupToolbarItem>

                <Toolbar>
                    <ToolbarItem disabled={index <= 0} toolbar="top" location="before">
                        <Button
                            id="anterior"
                            icon="chevronprev"
                            text={this.getTrad("anterior")}
                            onClick={this.dxToolbarItem_onClick}
                        />
                    </ToolbarItem>
                    <ToolbarItem toolbar="top" location="before">
                        <Button
                            disabled={estado.tipo !== "valido" && estado !== jornadaEstado.jornadaSinCuadranteCompleta}
                            icon=" icon_Edit"
                            type="success"
                            text={"Añadir jornada"}
                            onClick={this.dxToolbarItem_onClick_Add_Jornada}
                        />
                    </ToolbarItem>
                    <ToolbarItem toolbar="top" location="before" widget={"dxDropDownButton"}>
                        <DropDownButton
                            icon=" icon_CalendarioMes"
                            className="dxButtonDropDownEventoCalendario"
                            type={"success"}
                            text={"Añadir evento de calendario"}
                            dataSource={this.tblCalendarioPersonal_Estado_Seleccionable}
                            displayExpr={"denominacion"}
                            onItemClick={this.dxToolbarItem_onSelectionChanged_Add_Calendario}
                        />
                    </ToolbarItem>
                    <ToolbarItem disabled={index >= datosDia.length - 1} toolbar="top" location="after">
                        <Button
                            id="siguiente"
                            icon="chevronprev"
                            rtlEnabled={true}
                            text={this.getTrad("siguiente")}
                            onClick={this.dxToolbarItem_onClick}
                        />
                    </ToolbarItem>
                </Toolbar>

                <Box>
                    <ItemBox baseSize={300}>
                        <div className="p-3 d-flex flex-column justify-content-center">
                            <div className="mb-3">
                                <this.renderFoto />
                            </div>
                            <span className="font-size-xl font-weight-semiBold text-center">
                                {data.listId?.split("-")[0]}
                            </span>
                            <this.renderBalanceHoras />
                        </div>
                    </ItemBox>
                    <ItemBox ratio={1}>
                        <ScrollView height={"35rem"}>
                            <div className="jornadas">
                                {estado === jornadaEstado.errorEstado ? (
                                    <this.renderErrorEstado jornadas={jornadas} />
                                ) : estado === jornadaEstado.validoEstado ? (
                                    <this.renderEstado />
                                ) : (
                                    jornadas?.map((jornada) => {
                                        return (
                                            <this.renderJornada
                                                key={`jornadaCard-${jornada.idJornada}`}
                                                jornada={jornada}
                                            />
                                        );
                                    })
                                )}
                            </div>
                        </ScrollView>
                    </ItemBox>
                </Box>
                <Tooltip visible={tooltipData.target != null} target={tooltipData.target} position={"right"}>
                    <div>{tooltipData.content}</div>
                </Tooltip>
            </Popup>
        );
    }

    // #region ToolbarItems

    dxToolbarItem_onClick = (e, datosDia = this.state.datosDia) => {
        let { index } = this.state;

        if (e) {
            const accion = e?.element[0].id;
            if (accion === "anterior") index--;
            if (accion === "siguiente") index++;
            if (index > datosDia.length - 1) index = datosDia.length - 1;
            if (index <= 0) index = 0;

            if (this.hasModificaciones)
                dxMensajePregunta("Tienes cambios sin guardar. ¿Deseas perderlos y continuar?", [
                    [
                        "Aceptar",
                        () => {
                            this.setDatosDia(index, datosDia);
                        },
                        "danger",
                    ],
                    ["Cancelar", () => {}],
                ]);
            else this.setDatosDia(index, datosDia);
        } else {
            if (index > datosDia.length - 1) index = datosDia.length - 1;
            this.setDatosDia(index, datosDia);
        }
    };

    setDatosDia = (index, datosDia) => {
        let data = datosDia.at(index);
        this.loadJornada(data).then((jornadas) => {
            this.setState({
                index,
                data,
                jornadas,
                datosDia,
                cuadrante: data.cuadrante,
                calendario: data.calendarioPersonal,
                fnSaveCuadrante: undefined,
            });
            this.hasModificaciones = false;
        });
    };

    dxToolbarItem_onClick_Add_Jornada = () => {
        const { jornadas, data } = this.state;

        jornadas.push(this.newJornada(data.idPersona, data.fecha, data.idTurno));

        this.setState({ jornadas });
    };

    dxToolbarItem_onSelectionChanged_Add_Calendario = (e) => {
        const { data } = this.state;

        let calendario = {
            idPersona: data.idPersona,
            fecha: data.fecha,
            idEstado: e.itemData.idEstado,
        };

        this.setState({ calendario });
    };

    dxToolbarItem_onClick_Validar = () => {
        const { jornadas, data, calendario, fnSaveCuadrante } = this.state;

        let promises = [];
        if (fnSaveCuadrante) promises.push(fnSaveCuadrante());
        Promise.all(promises).then(([result]) => {
            this.paramsContext = {
                idPersona: data.idPersona,
                fecha: formatDate_noTime_parameter(data.fecha),
            };

            this.payload = {
                calendario,
                jornadas: [],
            };

            let mapBalance = (x) => {
                let item = {
                    idPersona: x.idPersona,
                    fecha: x.fecha,
                    idJornada: typeof x.idJornada !== "number" ? null : x.idJornada,
                    minutos: x.minutos,
                    isInicio: x.isInicio,
                };
                return item;
            };

            for (const jornada of jornadas) {
                let obj = {
                    horaIni: formatTime_parameter(durationToDatetime(jornada.horaIni)),
                    horaFin: formatTime_parameter(durationToDatetime(jornada.horaFin)),
                    tiempoDescanso: formatTime_parameter(durationToDatetime(jornada.tiempoDescanso)),
                    idMotivoIncumplimiento_horaIni: jornada.idMotivoIncumplimiento_horaIni,
                    idMotivoIncumplimiento_horaFin: jornada.idMotivoIncumplimiento_horaFin,
                    idMotivoIncumplimiento_tiempoDescanso: jornada.idMotivoIncumplimiento_tiempoDescanso,
                    tblBalanceHorasExtra: jornada.tblBalanceHorasExtra.filter((x) => x.minutos !== 0).map(mapBalance),
                    tblBalanceHoras: jornada.tblBalanceHoras.filter((x) => x.minutos !== 0).map(mapBalance),
                };

                if (typeof jornada.idJornada !== "number") {
                    obj.fecha = jornada.fecha;
                    obj.idPersona = jornada.idPersona;
                    obj.idLavanderia = jornada.idLavanderia;
                    obj.idTurno = jornada.idTurno;
                    obj.idCuadrantePersonal = jornada.idCuadrantePersonal;
                }

                if (result) obj.idCuadrantePersonal = result.inserted[0] ?? result.updated[0];

                this.payload.jornadas.push({
                    idJornada: typeof jornada.idJornada === "number" ? jornada.idJornada : null,
                    patch: patchRequestHandler(obj),
                });
            }

            this.context_jornadaController.invoke("ModificarRegistrosDia", {}, "POST").then(this.validarContinuar);
        });
    };

    // #endregion

    // #region Renders custom

    renderFoto = () => {
        const { data } = this.state;

        return (
            <div className="jornadaFotoPersona">
                <FotoPersona idPersona={data.idPersona} />
            </div>
        );
    };

    renderBalanceHoras = () => {
        const { balanceHoras } = this.state;

        return (
            <Box className="mt-3 align-items-center">
                <ItemBox ratio={2}>
                    <span className="text-secondary font-size-xs">{getTrad("balanceHoras")}</span>
                </ItemBox>
                <ItemBox ratio={1}>
                    <div>
                        <span
                            className={`text-center font-size-xs font-weight-bolder dx-texteditor dx-editor-filled p-1${
                                balanceHoras < 0 ? " text-danger" : ""
                            }`}
                        >
                            {balanceHoras < 0 ? "-" : ""}
                            {Math.floor(Math.abs(balanceHoras / 60))}:{Math.abs(balanceHoras % 60) < 10 && "0"}
                            {Math.abs(balanceHoras % 60)}
                        </span>
                    </div>
                </ItemBox>
            </Box>
        );
    };

    renderJornada = ({ jornada }) => {
        const { isValidacionDisabled, jornadas } = this.state;

        const hasJornadConCuadrante = jornadas.filter((x) => x.idCuadrantePersonal != null).length > 0;

        return (
            <div className="w-100">
                <div className="jornadaContainer">
                    <div className="d-flex">
                        <div
                            className="jornadaCard text-white"
                            style={{ background: `var(--${this.getColorJornada(jornada)})` }}
                        >
                            <div className="d-flex">
                                <div className="py-2 cuadranteBody text-body">
                                    <table className="jornadaTable">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {(jornada.idCuadrantePersonal != null ||
                                                        (jornada.idCuadrantePersonal == null &&
                                                            !hasJornadConCuadrante)) && (
                                                        <div
                                                            className="editarCuadrante"
                                                            onClick={() => this.editarCuadrante(jornada.idJornada)}
                                                        >
                                                            {jornada.idCuadrantePersonal != null ? (
                                                                <>
                                                                    <i className="dx-icon-edit" />
                                                                    Editar cuadrante
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <i className="dx-icon-plus" />
                                                                    Añadir cuadrante
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </th>
                                                <th className="title">Cuadrante</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className="jornadaDateLabel">Hora inicio</span>
                                                </td>
                                                <this.render_dxDateBox_Cuadrante
                                                    jornada={jornada}
                                                    campo="horaEntrada"
                                                />
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="jornadaDateLabel">Hora fin</span>
                                                </td>
                                                <this.render_dxDateBox_Cuadrante jornada={jornada} campo="horaSalida" />
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span className="jornadaDateLabel">Tiempo descanso</span>
                                                </td>
                                                <this.render_dxDateBox_Cuadrante
                                                    jornada={jornada}
                                                    campo="tiempoDescanso"
                                                />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="py-2">
                                    <table className="jornadaTable">
                                        <thead>
                                            <tr>
                                                <th className="title">Real</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <this.render_dxDateBox_Real jornada={jornada} campo={"horaIni"} />
                                            </tr>
                                            <tr>
                                                <this.render_dxDateBox_Real jornada={jornada} campo={"horaFin"} />
                                            </tr>
                                            <tr>
                                                <this.render_dxDateBox_Real
                                                    jornada={jornada}
                                                    campo={"tiempoDescanso"}
                                                />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {this.isRevisionVisible(jornada) && (
                            <div className="controlJornadaCard">
                                <table className="jornadaTable he-100">
                                    <thead>
                                        <tr>
                                            <th className="controlJornadaTitle">REVISIÓN</th>
                                        </tr>
                                    </thead>
                                    {this.getJornadaEstado(jornada) === jornadaEstado.sinDatos ? (
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Button
                                                        disabled={isValidacionDisabled}
                                                        className="w-100 bg-red jornadaEditor"
                                                        type="danger"
                                                        text={this.getTrad("faltas")}
                                                        onClick={this.dxButton_onClick_absentismo}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {this.getEstadoCampo(jornada, "horaIni") === "tiempoIncumplido" ||
                                                    jornada.idMotivoIncumplimiento_horaIni ||
                                                    this.getEstadoCampo(jornada, "horaIni") === "error" ? (
                                                        <this.renderFaltaDato jornada={jornada} campo={"horaIni"} />
                                                    ) : (
                                                        (this.getEstadoCampo(jornada, "horaIni") === "tiempoExcedido" ||
                                                            this.hasHorasAcumuladas(jornada, "horaIni") ||
                                                            this.hasHorasExtra(jornada, "horaIni")) && (
                                                            <this.renderControlHoras
                                                                jornada={jornada}
                                                                campo={"horaIni"}
                                                                tbl={
                                                                    jornada.horaIni_isHoraExtraRechazada ||
                                                                    this.hasHorasAcumuladas(jornada, "horaIni")
                                                                        ? "tblBalanceHoras"
                                                                        : "tblBalanceHorasExtra"
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {this.getEstadoCampo(jornada, "horaFin") === "tiempoIncumplido" ||
                                                    jornada.idMotivoIncumplimiento_horaFin ||
                                                    this.getEstadoCampo(jornada, "horaFin") === "error" ? (
                                                        <this.renderFaltaDato jornada={jornada} campo={"horaFin"} />
                                                    ) : (
                                                        (this.getEstadoCampo(jornada, "horaFin") === "tiempoExcedido" ||
                                                            this.hasHorasAcumuladas(jornada, "horaFin") ||
                                                            this.hasHorasExtra(jornada, "horaFin")) && (
                                                            <this.renderControlHoras
                                                                jornada={jornada}
                                                                campo={"horaFin"}
                                                                tbl={
                                                                    jornada.horaFin_isHoraExtraRechazada ||
                                                                    this.hasHorasAcumuladas(jornada, "horaFin")
                                                                        ? "tblBalanceHoras"
                                                                        : "tblBalanceHorasExtra"
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {(this.getEstadoCampo(jornada, "tiempoDescanso") ===
                                                        "tiempoIncumplido" ||
                                                        jornada.idMotivoIncumplimiento_tiempoDescanso ||
                                                        this.getEstadoCampo(jornada, "tiempoDescanso") === "error") && (
                                                        <this.renderFaltaDato
                                                            jornada={jornada}
                                                            campo={"tiempoDescanso"}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                            </div>
                        )}
                        {!jornada.idCuadrantePersonal && <this.renderEliminarJornada jornada={jornada} />}
                    </div>
                </div>
            </div>
        );
    };

    renderErrorEstado = ({ jornadas }) => {
        return (
            <div>
                <h2 className="text-center mb-3">Seleccione evento final</h2>
                <div className="d-flex flex-row justify-content-center errorEstado">
                    <this.renderEstado handleClick />
                    <div id="jornadas" className="estadoContainer" onClick={this.onClick_SelectEstado}>
                        <div className="estado">
                            <table className="jornadaTable">
                                <thead>
                                    <tr>
                                        <th>Jornada</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jornadas?.map((jornada) => (
                                        <tr key={`jornadaCard-${jornada.idJornada}`}>
                                            <td>
                                                <div className="jornadaContainer">
                                                    <div className="d-flex">
                                                        <div className="jornadaCard text-white">
                                                            <div className="d-flex">
                                                                <div className="cuadranteBody text-body">
                                                                    <table className="jornadaTable">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <span className="jornadaDateLabel">
                                                                                        Hora inicio
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <DateBox
                                                                                        className="jornadaEditor"
                                                                                        value={durationToDatetime(
                                                                                            jornada.horaIni
                                                                                        )}
                                                                                        readOnly={true}
                                                                                        {...this.dxDateBox_props}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <span className="jornadaDateLabel">
                                                                                        Hora fin
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <DateBox
                                                                                        className="jornadaEditor"
                                                                                        value={durationToDatetime(
                                                                                            jornada.horaFin
                                                                                        )}
                                                                                        readOnly={true}
                                                                                        {...this.dxDateBox_props}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <span className="jornadaDateLabel">
                                                                                        Tiempo descanso
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    <DateBox
                                                                                        className="jornadaEditor"
                                                                                        value={durationToDatetime(
                                                                                            jornada?.tiempoDescanso
                                                                                        )}
                                                                                        readOnly={true}
                                                                                        {...this.dxDateBox_props}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderEstado = ({ handleClick }) => {
        const { cuadrante, calendario } = this.state;
        const { jornadas } = this.state.data;

        const { CuadranteContext } = this.props;
        const estado = CuadranteContext.tblCalendarioPersonal_Estado.find(
            (x) => x.idEstado === (calendario?.idEstado ?? cuadrante?.idEstado)
        );

        return (
            <div
                id="cuadrante"
                className="estadoContainer h-100"
                onClick={handleClick ? this.onClick_SelectEstado : undefined}
                style={{ background: estado?.colorHexa, maxHeight: "13.5rem" }}
            >
                <div className="estado">
                    <table className="jornadaTable he-100 w-100">
                        <thead>
                            <tr>
                                <th>Cuadrante</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-body bg-white">{this.getTrad(estado?.traduccion)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    render_dxDateBox_Cuadrante = ({ jornada, campo }) => {
        const { cuadrante: cuadranteDia } = this.state;
        const cuadrante = jornada.idCuadrantePersonal === cuadranteDia?.idCuadrantePersonal ? cuadranteDia : {};

        if (cuadrante && campo === "tiempoDescanso") {
            const { CuadranteContext } = this.props;
            const turno = CuadranteContext.tblTurno.find((x) => x.idTurno === cuadrante?.idTurno);
            cuadrante.tiempoDescanso = turno?.descanso;
        }

        return (
            <td>
                <DateBox
                    className="jornadaEditor"
                    value={cuadrante ? durationToDatetime(cuadrante[campo]) : null}
                    readOnly={true}
                    {...this.dxDateBox_props}
                />
                {(!cuadrante || !cuadrante[campo]) && (
                    <div className="LottieIconWarning">
                        <LottieIcon isLoop={false} width={"75%"} icon={"exclamation_circle"} />
                    </div>
                )}
            </td>
        );
    };

    render_dxDateBox_Real = ({ jornada, campo }) => {
        let estadoCampo = this.getEstadoCampo(jornada, campo);
        return (
            <td
                onMouseEnter={(e) => this.render_dxDateBox_Real_onMouseEnter(e, jornada, campo)}
                onMouseLeave={this.render_dxDateBox_Real_onMouseLeave}
            >
                <DateBox
                    id={`${jornada?.idJornada}-${campo}`}
                    className="jornadaEditor editorButtonsAbsolute"
                    showClearButton={true}
                    {...this.dxDateBox_props}
                    placeholder={
                        campo === "tiempoDescanso" && estadoCampo === "descansando"
                            ? jornadaEstado.activoDescansando.estado
                            : ""
                    }
                    readOnly={
                        jornada.fecha === formatDate_noTime_parameter(new Date()) ||
                        this.getJornadaEstado(jornada) === jornadaEstado.errorEstado ||
                        this.getEstadoCampo(jornada, campo) === "tiempoExcedido" ||
                        this.hasHorasExtra(jornada, campo) ||
                        this.hasHorasAcumuladas(jornada, campo)
                    }
                    value={jornada ? durationToDatetime(jornada[campo]) : null}
                    onValueChanged={(e) => this.dxDateBox_onValueChanged_Real(e, jornada.idJornada, campo)}
                />
                {this.getJornadaEstado(jornada) !== jornadaEstado.errorEstado &&
                    estadoCampo !== "valido" &&
                    estadoCampo !== "descansando" &&
                    estadoCampo !== "incumplimientoEspecificado" && (
                        <div className="LottieIconWarning">
                            <LottieIcon
                                isLoop={false}
                                width={"75%"}
                                icon={jornada && !jornada[campo] ? "exclamation_circle" : "warning"}
                            />
                        </div>
                    )}
            </td>
        );
    };

    render_dxDateBox_Real_onMouseEnter = (e, jornada, campo) => {
        let eventoEntrada = jornada.tblEventoPersona.find((x) => x.idEventoPersona_Estado === 1);
        let eventoSalida = jornada.tblEventoPersona.find((x) => x.idEventoPersona_Estado === 4);
        let eventosDescanso = jornada.tblEventoPersona.filter(
            (x) => x.idEventoPersona_Estado === 2 || x.idEventoPersona_Estado === 3
        );

        this.setState({
            tooltipData: {
                target: e.target,
                content: (
                    <div className="listaEventos">
                        <ul className="evento">
                            {campo === "horaIni" ? (
                                eventoEntrada ? (
                                    <li className="dx-icon-check">
                                        <span className="estado">
                                            {this.getTrad(
                                                this.tblEventoPersona_Estado.find(
                                                    (x) =>
                                                        x.idEventoPersona_estado ===
                                                        eventoEntrada.idEventoPersona_Estado
                                                )?.idTraduccionNavigation.clave
                                            )}
                                        </span>
                                        <span className="px-2">·</span>
                                        <span>
                                            {eventoEntrada.fecha.toLocaleTimeString(undefined, this.timeFormat)}
                                        </span>
                                    </li>
                                ) : (
                                    <li className="dx-icon-search">
                                        <span className="estado">No se ha encontrado evento de entrada</span>
                                    </li>
                                )
                            ) : campo === "horaFin" ? (
                                eventoSalida ? (
                                    <li className="dx-icon-check">
                                        <span className="estado">
                                            {this.getTrad(
                                                this.tblEventoPersona_Estado.find(
                                                    (x) =>
                                                        x.idEventoPersona_estado === eventoSalida.idEventoPersona_Estado
                                                )?.idTraduccionNavigation.clave
                                            )}
                                        </span>
                                        <span className="px-2">·</span>
                                        <span>{eventoSalida.fecha.toLocaleTimeString(undefined, this.timeFormat)}</span>
                                    </li>
                                ) : (
                                    <li className="dx-icon-search">
                                        <span className="estado">No se ha encontrado evento de salida</span>
                                    </li>
                                )
                            ) : eventosDescanso.length > 0 ? (
                                eventosDescanso.map((evento) => {
                                    return (
                                        <li
                                            key={`evento-${
                                                evento.idEventoPersona_Estado
                                            }-${evento.fecha.toLocaleTimeString(undefined, this.timeFormat)}`}
                                            className={
                                                evento.idEventoPersona_Estado === 2
                                                    ? "dx-icon-arrowright"
                                                    : "dx-icon-arrowleft"
                                            }
                                        >
                                            <span className="estado">
                                                {this.getTrad(
                                                    this.tblEventoPersona_Estado.find(
                                                        (x) =>
                                                            x.idEventoPersona_estado === evento.idEventoPersona_Estado
                                                    )?.idTraduccionNavigation.clave
                                                )}
                                            </span>
                                            <span className="px-2">·</span>
                                            <span>{evento.fecha.toLocaleTimeString(undefined, this.timeFormat)}</span>
                                        </li>
                                    );
                                })
                            ) : (
                                <li className="dx-icon-search">
                                    <span className="estado">No se han encontrado eventos de descanso</span>
                                </li>
                            )}
                        </ul>
                    </div>
                ),
            },
        });
    };

    render_dxDateBox_Real_onMouseLeave = () => {
        this.setState({ tooltipData: {} });
    };

    renderControlHoras = ({ jornada, campo, tbl }) => {
        let balance = jornada[tbl].find((x) => (campo === "horaIni" ? x.isInicio : !x.isInicio));
        let value = new Date(Math.abs(balance?.minutos * 60 * 1000) + new Date(0).getTimezoneOffset() * 60000);

        let step = tbl === "tblBalanceHorasExtra" ? 30 : 1;

        return this.hasHorasExtra(jornada, campo) || this.hasHorasAcumuladas(jornada, campo) ? (
            <>
                <DateBox
                    className="jornadaEditor"
                    {...this.dxDateBox_props}
                    value={value ?? null}
                    onValueChanged={(e) =>
                        this.dxDateBox_onValueChanged_Revision(e, jornada.idJornada, campo, tbl, step)
                    }
                >
                    <DateBoxButton
                        name={"restarHora"}
                        location={"before"}
                        options={{
                            icon: "minus",
                            stylingMode: "text",
                            onClick: (e) =>
                                this.dxDateBoxButton_onClick(e, jornada.idJornada, campo, "restarHora", tbl, step),
                        }}
                    />
                    <DateBoxButton
                        name={"sumarHora"}
                        location={"after"}
                        options={{
                            icon: "plus",
                            stylingMode: "text",
                            onClick: (e) =>
                                this.dxDateBoxButton_onClick(e, jornada.idJornada, campo, "sumarHora", tbl, step),
                        }}
                    />
                </DateBox>
                <span className="position-absolute-top-left px-2 input-label">
                    {tbl === "tblBalanceHorasExtra" ? getTrad("horasExtra") : getTrad("bolsaHoras")}
                </span>
            </>
        ) : (
            <div className="d-flex flex-row align-items-center justify-content-between jornadaEditor">
                <span>
                    ¿
                    {capitalize(
                        getTrad("añadir") +
                            " " +
                            (tbl === "tblBalanceHorasExtra" ? getTrad("horasExtra") : getTrad("bolsaHoras")),
                        true
                    )}
                    ?
                </span>
                <div className="d-flex flex-row align-items-center justify-content-between solicitudExtras">
                    <Button
                        icon={"check"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={(e) =>
                            this.dxDateBoxButton_onClick(
                                e,
                                jornada.idJornada,
                                campo,
                                tbl === "tblBalanceHorasExtra" ? "aceptarExtras" : "aceptarBolsa",
                                tbl,
                                step
                            )
                        }
                    />
                    <Button
                        icon={"close"}
                        type={"danger"}
                        stylingMode={"contained"}
                        onClick={(e) =>
                            this.dxDateBoxButton_onClick(
                                e,
                                jornada.idJornada,
                                campo,
                                tbl === "tblBalanceHorasExtra" ? "rechazarExtras" : "rechazarBolsa",
                                tbl
                            )
                        }
                    />
                </div>
            </div>
        );
    };

    renderFaltaDato = ({ jornada, campo }) => {
        const { CuadranteContext } = this.props;
        const { cuadrante } = this.state;

        const dataSource = CuadranteContext.tblMotivoIncumplimientoJornada.map((x) => {
            let item = { ...x };
            if (item.idMotivoIncumplimientoJornada === 4 && campo === "tiempoDescanso")
                item.disabled =
                    durationToDatetime(jornada.tiempoDescanso) < durationToDatetime(cuadrante.tiempoDescanso);
            if (item.idMotivoIncumplimientoJornada === 5) item.disabled = campo !== "horaIni";

            return item;
        });

        return (
            <>
                <SelectBox
                    className="jornadaEditor"
                    dataSource={dataSource}
                    valueExpr={"idMotivoIncumplimientoJornada"}
                    displayExpr={"denominacion"}
                    value={jornada["idMotivoIncumplimiento_" + campo]}
                    showClearButton={true}
                    onValueChanged={(e) =>
                        this.dxSelectBox_onValueChanged(e, jornada.idJornada, "idMotivoIncumplimiento_" + campo)
                    }
                />
                <span className="position-absolute-top-left px-2 input-label">{getTrad("motivoIncumplimiento")}</span>
            </>
        );
    };

    renderEliminarJornada = ({ jornada }) => {
        return (
            <div
                className="align-items-center d-flex justify-content-center eliminarJornada"
                onClick={() => this.onClick_eliminarJornada(jornada)}
            >
                <i className="dx-icon-close font-size-xl p-2" />
            </div>
        );
    };

    // #endregion

    // #region

    getJornadaEstado = (
        jornada,
        cuadrante = this.state.cuadrante,
        calendario = this.state.calendario,
        estimacion = this.state.data?.estimacion
    ) => {
        if (calendario && calendario.idEstado !== 3 && calendario.idEstado !== 4)
            // Si hay calendario NO TRABAJO
            return !jornada ? jornadaEstado.validoEstado : jornadaEstado.errorEstado;

        if (jornada && (!cuadrante || !jornada.idCuadrantePersonal))
            // Si hay jornada SIN cuadrante
            return jornada.isRevisado
                ? jornadaEstado.validoJornadaSinCuadrante
                : jornada.horaIni && jornada.horaFin && jornada.tiempoDescanso
                ? jornadaEstado.jornadaSinCuadranteCompleta
                : jornadaEstado.errorJornadaSinCuadrante;

        if (!jornada && !cuadrante) return jornadaEstado.error;

        if (!jornada || (!jornada.horaIni && !jornada.horaFin && !jornada.tiempoDescanso)) {
            // Si no hay jornada o hay jornada SIN datos
            let cuadranteHoraEntrada = durationToDatetime(cuadrante.horaEntrada);
            if (cuadranteHoraEntrada) {
                let cuadranteMomento = new Date(cuadrante.fecha);
                cuadranteMomento.setHours(
                    cuadranteHoraEntrada.getHours(),
                    cuadranteHoraEntrada.getMinutes(),
                    cuadranteHoraEntrada.getSeconds(),
                    cuadranteHoraEntrada.getMilliseconds()
                );
                return new Date() < cuadranteMomento ? jornadaEstado.activoSinEmpezar : jornadaEstado.sinDatos;
            }
            return jornadaEstado.sinDatos;
        }

        if (jornada && !jornada.horaFin && jornada.idCuadrantePersonal && cuadrante) {
            let estadoHoraIni = this.getEstadoCampo(jornada, "horaIni", cuadrante);
            let cuadranteHoraSalida = durationToDatetime(cuadrante.horaSalida);
            let cuadranteMomento = new Date(cuadrante.fecha);
            cuadranteMomento.setHours(
                cuadranteHoraSalida?.getHours(),
                cuadranteHoraSalida?.getMinutes(),
                cuadranteHoraSalida?.getSeconds(),
                cuadranteHoraSalida?.getMilliseconds()
            );

            if (new Date() < cuadranteMomento) {
                let estadoDescanso = this.getEstadoCampo(jornada, "tiempoDescanso", cuadrante);
                if (estadoDescanso === "descansando") return jornadaEstado.activoDescansando;
                else
                    return estadoHoraIni === "valido"
                        ? jornadaEstado.activoTrabajando
                        : estadoHoraIni === "error"
                        ? jornadaEstado.activoError
                        : jornadaEstado.activoDiferenciaHoraria;
            }
        }

        if (jornada.horaIni || jornada.horaFin || jornada.tiempoDescanso) {
            // Si hay jornada CON datos
            let estadoHoraIni = this.getEstadoCampo(jornada, "horaIni", cuadrante);
            let estadoHoraFin = this.getEstadoCampo(jornada, "horaFin", cuadrante);
            let estadoTiempoDescanso = this.getEstadoCampo(jornada, "tiempoDescanso", cuadrante);
            if (estadoHoraIni === "valido" && estadoHoraFin === "valido" && estadoTiempoDescanso === "valido")
                return jornadaEstado.valido; // Si todos los datos son VÁLIDOS
            if (estadoHoraIni === "error" || estadoHoraFin === "error" || estadoTiempoDescanso === "error")
                return jornadaEstado.error; // Si todos los datos son VÁLIDOS
            if (
                (estadoHoraIni === "valido" || jornada.idMotivoIncumplimiento_horaIni) &&
                (estadoHoraFin === "valido" || jornada.idMotivoIncumplimiento_horaFin) &&
                (estadoTiempoDescanso === "valido" ||
                    jornada.idMotivoIncumplimiento_tiempoDescanso ||
                    estadoTiempoDescanso === "descansando")
            )
                return jornadaEstado.validoIncumplimiento; // Si todos los datos son VÁLIDOS
            return jornadaEstado.diferenciaHoraria; // Si alguno de los datos NO es VÁLIDO
        }
        if (
            (!jornada.horaIni && !jornada.idMotivoIncumplimiento_horaIni) ||
            (!jornada.horaFin && !jornada.idMotivoIncumplimiento_horaFin) ||
            (!jornada.tiempoDescanso && !jornada.idMotivoIncumplimiento_tiempoDescanso)
        )
            return jornadaEstado.error; // Si falta ALGÚN dato pero NO TODOS
    };

    getEstadoJornadasDia = (
        jornadas = this.state.jornadas,
        cuadrante = this.state.cuadrante,
        calendario = this.state.calendario,
        estimacion = this.state.data?.estimacion
    ) => {
        let estado;

        if (jornadas?.length > 0) {
            estado = jornadas.reduce((estado, jornada) => {
                if (estado.tipo === "error") return estado;
                let newEstado = this.getJornadaEstado(jornada, cuadrante, calendario, estimacion);
                if (newEstado !== jornadaEstado.valido) return newEstado;
                if (estado.tipo === "warning") return estado;
                return estado;
            }, jornadaEstado.valido);
        } else {
            estado = this.getJornadaEstado(null, cuadrante, calendario, estimacion);
        }
        return estado;
    };

    diffTiempo = 30 * (60 * 1000); // Tiempo de gracia
    maxExtra = new Date("01-01-1970 13:00:00"); // 10 horas
    getEstadoCampo = (jornada, campo, cuadranteDia = this.state.cuadrante) => {
        const cuadrante = jornada.idCuadrantePersonal === cuadranteDia?.idCuadrantePersonal ? cuadranteDia : {};

        if (!jornada || !cuadrante) return "error";

        let horaJornada = durationToDatetime(jornada[campo]);

        if (!horaJornada && campo !== "tiempoDescanso") return "error";

        let horaCuadrante = durationToDatetime(
            cuadrante[campo === "horaIni" ? "horaEntrada" : campo === "horaFin" ? "horaSalida" : campo]
        );
        if (campo === "tiempoDescanso") {
            // Válido si ha salido en los 30 mins siguientes a su hora de salida
            const { CuadranteContext } = this.props;
            const turno = CuadranteContext.tblTurno.find((x) => x.idTurno === cuadrante.idTurno);
            horaCuadrante = durationToDatetime(turno?.descanso);
        }
        if (!horaCuadrante) return "valido";

        if (campo === "horaIni" || campo === "horaFin") {
            let balance =
                jornada.tblBalanceHorasExtra.find(
                    (x) => x.idBalanceHorasExtra && (campo === "horaIni" ? x.isInicio : !x.isInicio) && x.minutos !== 0
                ) ??
                jornada.tblBalanceHoras.find(
                    (x) => x.idBalanceHoras && (campo === "horaIni" ? x.isInicio : !x.isInicio) && x.minutos !== 0
                );
            if (balance) {
                horaCuadrante = addMinutes(horaCuadrante, campo === "horaIni" ? -balance?.minutos : balance?.minutos);
            }
        }

        let diff = new Date(horaCuadrante - horaJornada);

        // Controlar la fecha en la que se encuentra el DateTime
        if (Math.abs(diff.getTime()) >= 24 * 60 * 60 * 1000) {
            diff = addDays(diff, diff.getTime() > 0 ? -1 : 1);
        }

        let condicionMinimo;
        let condicionLimite;

        if (campo === "horaIni") {
            // Válido si queda menos de 30 mins a su hora de entrada
            condicionMinimo = diff.getTime() < this.diffTiempo && diff.getTime() > -this.maxExtra.getTime();
            condicionLimite = diff.getTime() >= 0 || diff.getTime() <= -this.maxExtra.getTime();
        }
        if (campo === "horaFin") {
            // Válido si ha salido en los 30 mins siguientes a su hora de salida
            condicionMinimo = diff.getTime() > -this.diffTiempo && diff.getTime() < this.maxExtra.getTime();
            condicionLimite = diff.getTime() <= 0 || diff.getTime() >= this.maxExtra.getTime();
        }
        if (campo === "tiempoDescanso") {
            // Válido si es la diferencia es 0
            condicionMinimo = diff.getTime() === 0 || jornada.tiempoDescanso != null;
            condicionLimite = diff.getTime() === 0;

            let ultimoEvento = jornada.tblEventoPersona.at(-1);
            if (ultimoEvento?.idEventoPersona_Estado === 2 && horaCuadrante.getTime() === diff.getTime())
                return "descansando";
        }

        if ((condicionMinimo && condicionLimite) || jornada["idMotivoIncumplimiento_" + campo]) return "valido";
        if (condicionMinimo) return "tiempoIncumplido";
        if (condicionLimite) return "tiempoExcedido";
        return "error";
    };

    hasHorasExtra = (jornada, campo) => {
        if (campo === "tiempoDescanso") return false;
        let balance = jornada?.tblBalanceHorasExtra.find((x) => (campo === "horaIni" ? x.isInicio : !x.isInicio));
        return balance && balance?.minutos !== 0;
    };

    hasHorasAcumuladas = (jornada, campo) => {
        if (campo === "tiempoDescanso") return false;
        let balance = jornada?.tblBalanceHoras.find((x) => (campo === "horaIni" ? x.isInicio : !x.isInicio));
        return balance && balance?.minutos !== 0;
    };

    isRevisionVisible = (jornada) => {
        const { cuadrante } = this.state;
        return (
            jornada.fecha !== formatDate_noTime_parameter(new Date()) &&
            cuadrante &&
            cuadrante.horaEntrada &&
            cuadrante.horaSalida &&
            this.getJornadaEstado(jornada).hideRevision !== true &&
            (jornada.idMotivoIncumplimiento_horaIni ||
                jornada.idMotivoIncumplimiento_horaFin ||
                jornada.idMotivoIncumplimiento_tiempoDescanso ||
                this.getJornadaEstado(jornada).tipo !== "valido" ||
                this.getJornadaEstado(jornada) === jornadaEstado.validoIncumplimiento ||
                this.hasHorasExtra(jornada, "horaIni") ||
                this.hasHorasExtra(jornada, "horaFin") ||
                this.hasHorasAcumuladas(jornada, "horaIni") ||
                this.hasHorasAcumuladas(jornada, "horaFin"))
        );
    };

    getColorJornada = (jornada) => {
        if (jornada) {
            const { horaIni, horaFin, tiempoDescanso } = jornada;
            const { cuadrante } = this.state;
            let estado = this.getJornadaEstado(jornada);

            if (jornada.isRevisado) return "success";
            if (estado.tipo !== "error" || estado === jornadaEstado.jornadaSinCuadranteCompleta) {
                let estadoHoraIni = this.getEstadoCampo(jornada, "horaIni", cuadrante);
                let estadoHoraFin = this.getEstadoCampo(jornada, "horaFin", cuadrante);
                let estadoTiempoDescanso = this.getEstadoCampo(jornada, "tiempoDescanso", cuadrante);

                if (
                    (horaIni && estadoHoraIni !== "valido" && !jornada.idMotivoIncumplimiento_horaIni) ||
                    (horaFin && estadoHoraFin !== "valido" && !jornada.idMotivoIncumplimiento_horaFin) ||
                    (tiempoDescanso &&
                        estadoTiempoDescanso !== "valido" &&
                        !jornada.idMotivoIncumplimiento_tiempoDescanso &&
                        estadoTiempoDescanso !== "descansando")
                ) {
                    return "warning"; // Si alguno de los datos está fuera de lo normal
                }

                return "success"; // Si todo está dentro de los parámetros esperados
            }
            if (estado === jornadaEstado.errorJornadaSinCuadrante) {
                if ((horaIni, horaFin, tiempoDescanso)) {
                    return "warning";
                }
            }
        }

        return "red"; // Si hay parámetros sin datos
    };

    getDiffCampo = (jornada, campo) => {
        const { cuadrante } = this.state;
        return new Date(
            durationToDatetime(
                cuadrante[campo === "horaIni" ? "horaEntrada" : campo === "horaFin" ? "horaSalida" : campo]
            ) - durationToDatetime(jornada[campo])
        );
    };

    editarCuadrante = (idJornada) => {
        const { CuadranteContext } = this.props;
        const { data, cuadrante, jornadas } = this.state;

        const nombre = data.listId
            .split("-")[0]
            .split(" ")
            .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLocaleLowerCase())
            .join(" ");
        const fecha = data.fecha
            .toLocaleString(undefined, { weekday: "long", month: "long", day: "numeric" })
            .split(" ");
        const dia = fecha.shift();
        const mes = fecha.pop();
        const join = fecha.join(" ");
        const fechaString = [
            dia.charAt(0).toUpperCase() + dia.slice(1),
            join,
            mes.charAt(0).toUpperCase() + mes.slice(1),
        ].join(" ");
        const title = `${nombre} - ${fechaString}`;

        let jornada = jornadas.find((x) => x.idJornada === idJornada);
        const turno = CuadranteContext.tblTurno.find((x) => x.idTurno === jornada.idTurno);
        const newCuadrante = {
            idCuadrantePersonal: cuadrante?.idCuadrantePersonal ?? -1,
            idPersona: jornada.idPersona,
            idLavanderia: jornada.idLavanderia,
            idTurno: jornada.idTurno,
            idEstado: 3,
            fecha: data.fecha,
            horaEntrada: durationToDatetime(cuadrante?.horaEntrada) ?? durationToDatetime(turno.horaEntrada),
            horaSalida: durationToDatetime(cuadrante?.horaSalida) ?? durationToDatetime(turno.horaSalida),
        };

        CuadranteContext.refSettingsPopup.current.updatePopupState({
            popupVisible: true,
            prev: newCuadrante,
            popupMode: true,
            popupTitle: title,
            hasJornada: true,
            saveHandler: (fnSave, data) => {
                let { jornadas, balanceHoras } = this.state;
                let jornada = jornadas.find((x) => x.idJornada === idJornada);
                jornada.isRevisado = false;
                jornada.idCuadrantePersonal = data.idCuadrantePersonal;

                for (const balance of jornada.tblBalanceHoras) {
                    balanceHoras -= balance.minutos;
                }

                jornada.tblBalanceHoras = [];
                jornada.tblBalanceHoras = setBalances(jornada, "tblBalanceHoras");
                jornada.tblBalanceHorasExtra = [];
                jornada.tblBalanceHorasExtra = setBalances(jornada, "tblBalanceHorasExtra");

                jornada.idMotivoIncumplimiento_horaIni = null;
                jornada.idMotivoIncumplimiento_horaFin = null;
                jornada.idMotivoIncumplimiento_tiempoDescanso = null;

                this.setState({
                    jornadas,
                    balanceHoras,
                    fnSaveCuadrante: fnSave,
                    cuadrante: {
                        ...cuadrante,
                        ...data,
                        horaEntrada: datetimeToDuration(timeToDatetime(data.horaEntrada)),
                        horaSalida: datetimeToDuration(timeToDatetime(data.horaSalida)),
                    },
                });
            },
        });
    };

    // #endregion

    // #region dxPopup

    dxPopup_onHiding = () => this.setState({ dxPopup_isVisible: false });

    dxPopup_onHidden = () => {
        this.setState({
            data: {},
            jornadas: [],
            isValidacionDisabled: false,
            index: 0,
            datosDia: [],
            fnSaveCuadrante: undefined,
        });
    };

    // #endregion

    // #region dxDateBox

    dxDateBox_onValueChanged_Real = (e, idJornada, campo) => {
        let { jornadas, cuadrante, balanceHoras } = this.state;

        let jornada = jornadas.find((x) => x.idJornada === idJornada);

        const prevValue = durationToDatetime(jornada[campo]);
        jornada[campo] = !e.value ? e.value : datetimeToDuration(e.value);

        if (!e.value || (cuadrante && jornada.idCuadrantePersonal)) {
            const { CuadranteContext } = this.props;
            const turno = CuadranteContext.tblTurno.find((x) => x.idTurno === cuadrante.idTurno);
            if (
                (campo === "horaIni" &&
                    durationToDatetime(jornada[campo]) <= durationToDatetime(cuadrante["horaEntrada"])) ||
                (campo === "horaFin" &&
                    durationToDatetime(jornada[campo]) >= durationToDatetime(cuadrante["horaSalida"])) ||
                (campo === "tiempoDescanso" &&
                    durationToDatetime(jornada[campo]) <= durationToDatetime(turno?.descanso))
            ) {
                if (jornada["idMotivoIncumplimiento_" + campo] === 4) {
                    var diff = minuteDiff(
                        prevValue,
                        durationToDatetime(
                            cuadrante[campo === "horaIni" ? "horaEntrada" : campo === "horaFin" ? "horaSalida" : campo]
                        )
                    );

                    balanceHoras += diff;
                }

                jornada["idMotivoIncumplimiento_" + campo] = null;
            }
        }

        if (jornada["idMotivoIncumplimiento_" + campo] === 4) {
            // Si el motivo es "Compensación de horas"
            var diff = minuteDiff(prevValue, durationToDatetime(jornada[campo]));
            if (campo === "horaIni" || campo === "tiempoDescanso") {
                balanceHoras += prevValue < e.value ? -diff : diff;
            }
            if (campo === "horaFin") {
                balanceHoras += prevValue < e.value ? diff : -diff;
            }
        }

        jornada.isRevisado = false;

        this.setState({ jornadas, balanceHoras });
        this.hasModificaciones = true;
    };

    dxDateBox_onValueChanged_Revision = (e, idJornada, campo, tbl, step) => {
        let { jornadas, cuadrante, balanceHoras } = this.state;
        let jornada = jornadas.find((x) => x.idJornada === idJornada);
        let balance = jornada[tbl].find((x) => (campo === "horaIni" ? x.isInicio : !x.isInicio));
        let cuadranteValue = cuadrante[campo === "horaIni" ? "horaEntrada" : "horaSalida"];

        jornada.isRevisado = false;

        const prevMins = balance.minutos;
        balance.minutos = Math.floor(Math.abs(e.value.getHours() * 60 + e.value.getMinutes()) / step) * step;
        jornada[campo] = datetimeToDuration(
            addMinutes(durationToDatetime(cuadranteValue), balance.isInicio ? -balance.minutos : balance.minutos)
        );

        if (tbl === "tblBalanceHoras") balanceHoras -= prevMins - balance.minutos;

        this.setState({ jornadas, balanceHoras });
    };

    dxDateBoxButton_onClick = (e, idJornada, campo, accion, tbl, value) => {
        let { data, jornadas, cuadrante, balanceHoras } = this.state;

        let jornada = jornadas.find((x) => x.idJornada === idJornada);

        let balance = jornada[tbl].find((x) => (campo === "horaIni" ? x.isInicio : !x.isInicio));

        jornada.isRevisado = false;

        let cuadranteValue = cuadrante[campo === "horaIni" ? "horaEntrada" : "horaSalida"];

        if (accion === "restarHora" && Math.abs(balance.minutos) > 0) {
            if (tbl === "tblBalanceHoras") balanceHoras -= value;
            balance.minutos -= value;
            jornada[campo] = datetimeToDuration(
                addMinutes(durationToDatetime(cuadranteValue), balance.isInicio ? -balance.minutos : balance.minutos)
            );
        }

        if (accion === "sumarHora" && Math.abs(balance.minutos) < 12 * 60) {
            if (tbl === "tblBalanceHoras") balanceHoras += value;
            balance.minutos += value;
            jornada[campo] = datetimeToDuration(
                addMinutes(durationToDatetime(cuadranteValue), balance.isInicio ? -balance.minutos : balance.minutos)
            );
        }

        if (accion === "aceptarExtras" || accion === "aceptarBolsa") {
            delete jornada[campo + "_isHoraExtraRechazada"];

            let horaCuadrante = durationToDatetime(cuadrante[balance.isInicio ? "horaEntrada" : "horaSalida"]);
            let horaJornada = durationToDatetime(jornada[campo]);

            if (campo === "horaIni") {
                horaJornada = addDays(horaJornada, horaCuadrante < horaJornada ? -1 : 0);
            } else {
                horaJornada = addDays(horaJornada, horaCuadrante > horaJornada ? 1 : 0);
            }

            let diffHora = new Date(horaCuadrante - horaJornada);
            let minutos = Math.floor(Math.abs(diffHora.getTime() / 1000 / 60) / value) * value;

            balance[accion === "aceptarExtras" ? "idBalanceHorasExtra" : "idBalanceHoras"] = -1;
            if (tbl === "tblBalanceHoras") balanceHoras += minutos;
            balance.minutos = minutos;
            jornada[campo] = datetimeToDuration(
                addMinutes(durationToDatetime(cuadranteValue), balance.isInicio ? -minutos : minutos)
            );
        }

        if (accion === "rechazarExtras") {
            jornada[campo + "_isHoraExtraRechazada"] = true;
        }

        if (accion === "rechazarBolsa") {
            delete jornada[campo + "_isHoraExtraRechazada"];
            jornada[campo] = cuadrante[balance.isInicio ? "horaEntrada" : "horaSalida"];
        }

        this.setState({ data, jornadas, balanceHoras });
        this.hasModificaciones = true;
    };

    // #endregion

    // #region dxButton

    dxButton_onClick_absentismo = () => {
        const { data, datosDia } = this.state;

        this.paramsContext = {
            idPersona: data.idPersona,
            fecha: formatDate_noTime_parameter(data.fecha),
        };

        this.context_jornadaController.invoke("DeclararAbsentismo", {}, "GET").then(this.validarContinuar);
    };

    // #endregion

    // #region Eventos gestionar jornada

    dxSelectBox_onValueChanged = (e, idJornada, campo) => {
        let { jornadas, cuadrante, balanceHoras } = this.state;

        let jornada = jornadas.find((x) => x.idJornada === idJornada);

        let target = campo.replace("idMotivoIncumplimiento_", "");
        var diff = minuteDiff(
            durationToDatetime(jornada[target]),
            durationToDatetime(
                cuadrante[target === "horaIni" ? "horaEntrada" : target === "horaFin" ? "horaSalida" : target]
            )
        );

        let bal = jornada.tblBalanceHoras.find((x) => (target === "horaIni" ? x.isInicio : !x.isInicio));
        // Si tenía un motivo "Compensación de horas" y se cambia
        if (jornada[campo] === 4) {
            bal.minutos += diff;
            balanceHoras += diff;
        }

        jornada[campo] = e.value;

        // Si el motivo es "Compensación de horas"
        if (jornada[campo] === 4) {
            bal.minutos -= diff;
            balanceHoras -= diff;
        }

        jornada.isRevisado = false;

        this.setState({ jornadas, balanceHoras });
        this.hasModificaciones = true;
    };

    onClick_SelectEstado = (e) => {
        let mantenerJornada = e.target.id === "jornadas";

        if (mantenerJornada) {
            let calendario = null;

            this.setState({ calendario });
        } else {
            let { jornadas, balanceHoras } = this.state;

            for (const jornada of jornadas) {
                for (const balance of jornada.tblBalanceHoras) {
                    balanceHoras -= balance.minutos;
                }
            }

            this.setState({ jornadas: [], balanceHoras });
        }
        this.hasModificaciones = true;
    };

    onClick_eliminarJornada = (jornada) => {
        const { jornadas, data } = this.state;

        let newJornadas = jornadas.filter((x) => x.idJornada !== jornada.idJornada);

        if (newJornadas.length === 0) {
            newJornadas.push(this.newJornada(data.idPersona, data.fecha, data.idTurno));
        }

        this.setState({ jornadas: newJornadas });
    };

    // #endregion
}

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(JornadaPopup);
