import React, { useEffect, useState } from 'react';
import { Button } from "devextreme-react";
import ChipEstado from 'pages/Administracion/shared/components/ChipEstado';
import { dxMensajePregunta, getTrad } from 'helpers';

const CancelarFacturaComponent = ({formData, updateState}) => {
    // Component logic goes here
    const [estado, setEstado] = useState(formData.idAdmFactura_Estado);

    useEffect(() => {
        setEstado(formData.idAdmFactura_Estado);
    }, [formData.idAdmFactura_Estado]);

    const cancelarFactura_onButtonClick = (e) => {
        dxMensajePregunta("¿Estás seguro que quieres cancelar la factura? Este cambio es permanente. ", [
            [
                getTrad("aceptar"),
                () => {
                    cancelarFactura();
                },
                "danger",
            ],
            [getTrad("atras"), () => {}],
        ]);
    };

    const getFormStatus = () => {
        const idFactura = formData?.idAdmFacturaVenta ?? formData?.idAdmFacturaCompra;
        if(idFactura != null && idFactura != undefined){
            if(formData.idAdmFactura_Estado === 1){ //no contabilizado
                return true
            }
        }
        return false
    }

    const getToggleButtonText = () => {
        return getTrad("cancelar");
    }

    const cancelarFactura = () => {
        updateState(3);
        setEstado(3);
    }

    return (
        // JSX markup goes here
        <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
                <Button
                    text={getToggleButtonText()}
                    visible={getFormStatus()}
                    disabled={formData.isCerrado}
                    onClick={cancelarFactura_onButtonClick}
                />
            </div>
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                {ChipEstado(formData.idAdmFactura_Estado, "", "factura")}
            </div>
        </div>
    );
};

export default CancelarFacturaComponent;