import { connectionConstants } from "constants";
import {
    datasource_tblMoneda,
    datasource_tblIvaNPais_Compra,
    datasource_tblIvaNPais_Venta,
    empresaPolarierSel,
    formats,
    datasource_tblEmpresasPolarier,
} from "../model";
import { authHeader, formatDate_noTime_parameter, formatNumber } from "helpers";
import { codigosMoneda } from "constants/enums/General/tblMoneda";

export const calculateDisplayValue = (value, idMoneda) => {
    const codigoMoneda = datasource_tblMoneda.items().find((m) => m?.idMoneda === idMoneda)?.codigo;
    if (codigoMoneda != null) return formatNumber(value, 2, "currency", codigoMoneda);
    else return formatNumber(value, 2, "largeNumber");
};

export const getFormatCurrencyPrecio = (idMoneda) => {
    if (idMoneda != null) {
        const codigo = datasource_tblMoneda.items().find((moneda) => moneda?.idMoneda === idMoneda)?.codigo;
        if (codigo != null)
            return {
                type: "currency",
                precision: 3,
                currency: codigo,
            };
    }
    return {
        type: "decimal",
        precision: 3,
    };
};

export const getLabel_impuesto = (idEmpresa) => {
    if (idEmpresa == 4) {
        return "ITBIS";
    }
    return "IVA";
};

export const getFormatCurrency = (idMoneda) => {
    if (idMoneda != null) {
        const codigo = datasource_tblMoneda.items().find((moneda) => moneda?.idMoneda === idMoneda)?.codigo;
        if (codigo != null)
            return {
                type: "currency",
                precision: 2,
                currency: codigo,
            };
    }
    return {
        type: "decimal",
        precision: 2,
    };
};

export const filterArticulo = (filterValue) => {
    return ["(tblArticuloNPedido/any(art: contains(tolower(art/idArticulo), tolower('" + filterValue + "'))) eq true)"];
};

export const getSymbol = (idMoneda) => {
    return datasource_tblMoneda.items().find((moneda) => moneda?.idMoneda === idMoneda)?.simbolo || "-";
};

export const calcularValorNeto = (bruto, descuento, tipoDescuento, idIvaNPais = null, apartado) => {
    let neto = bruto;
    const iva = retrieveIvaValue(idIvaNPais, apartado);
    if (iva !== null) {
        neto = neto * (1 + iva);
    }
    if (tipoDescuento === 1) {
        neto = neto * (1 - descuento);
    } else if (tipoDescuento === 2) {
        neto = neto - descuento;
    }
    return neto;
};

export const generateCode = async (
    fechaParam = new Date(),
    setState,
    tipoElemento,
    tipoDocumento,
    idEmpresa = empresaPolarierSel.idEmpresaPolarier,
    isNCF = false
) => {
    await fetch(
        connectionConstants.WEB_API_CORE_ODATA_URL +
            `MyPolarier/Administracion/GetLastCode?tipoDocumento=${tipoDocumento}&tipoElemento=${tipoElemento}&idEmpresa=${idEmpresa}${
                isNCF ? "" : "&fecha=" + formatDate_noTime_parameter(new Date(fechaParam))
            }`,
        {
            method: "GET",
            headers: {
                ...authHeader(),
                "Content-Type": "application/json",
            },
        }
    )
        .then(async (data) => {
            const result = await data.text();
            setState(result);
        })
        .catch((error) => {
            console.error(error);
            return 0;
        });
};

export const getArticulos = (articulos, queryReady = false) => {
    if (articulos === undefined || articulos === null || articulos.length === 0) return [];
    return articulos.map((articulo) => {
        if (queryReady) {
            return {
                descripcion: articulo.descripcion,
                cantidad: articulo.cantidad,
                descuento: articulo.descuento,
                idIvaNPais: articulo.idIvaNPais,
                precio: articulo.precio,
                idAdmCuentaContable: articulo.idAdmCuentaContable,
                [articulo.tipoArticulo === 1
                    ? "idRecambio"
                    : articulo.tipoArticulo === 2
                    ? "idArticuloLenceria"
                    : articulo.tipoArticulo === 3
                    ? "idArticuloMaquinaria"
                    : articulo.tipoArticulo === 4
                    ? "idGrupoArticulos"
                    : ""]: articulo.idArticulo,
            };
        } else {
            let idArticulo =
                articulo.idArticuloLenceria ??
                articulo.idArticuloMaquinaria ??
                articulo.idRecambio ??
                articulo.idGrupoArticulos ??
                articulo.idArticulo;
            let dataArticulo =
                articulo.idArticuloLenceriaNavigation ??
                articulo.idArticuloMaquinariaNavigation ??
                articulo.idRecambioNavigation ??
                articulo.idGrupoArticulosNavigation ??
                articulo;

            return {
                idArticulo: idArticulo,
                tipoArticulo:
                    articulo.idRecambio != null
                        ? 1
                        : articulo.idArticuloLenceria != null
                        ? 2
                        : articulo.idArticuloMaquinaria != null
                        ? 3
                        : articulo.idGrupoArticulos != null
                        ? 4
                        : articulo.tipoArticulo,
                codigo: dataArticulo.codigo ?? dataArticulo.referenciaInterna,
                denominacion: dataArticulo.denominacion,
                idAdmCuentaContable: articulo.idAdmCuentaContable,
                descripcion: articulo.descripcion,
                descuento: articulo.descuento,
                cantidad: articulo.cantidad,
                precio: articulo.precio,
                idIvaNPais: articulo.idIvaNPais,
            };
        }
    });
};

export const retrieveIvaValue = (iva, apartado = "compra") => {
    if (apartado === "venta") {
        return datasource_tblIvaNPais_Venta.items().find((i) => i.idIvaNPais === iva)?.valor || 0;
    } else if (apartado === "compra") {
        return datasource_tblIvaNPais_Compra.items().find((i) => i.idIvaNPais === iva)?.valor || 0;
    }
};

export const getSortedArray = (array, sortProperty, desc = false) => {
    return array.sort((a, b) => {
        if (a[sortProperty] < b[sortProperty]) {
            return desc ? 1 : -1;
        } else {
            return desc ? -1 : 1;
        }
    });
};

export const custom_displayExpr = (e) => {
    if (e) {
        return e.codigo + " - " + e.denominacion;
    }
};

export const getDefaultIvaId = (isCompra = true) => {
    let tblAdmIva_items = [];

    if (!isCompra) {
        tblAdmIva_items = datasource_tblIvaNPais_Venta.items();
    } else {
        tblAdmIva_items = datasource_tblIvaNPais_Compra.items();
    }

    const valor = tblAdmIva_items.reduce((a, b) => (a.valor > b.valor ? a : b), { valor: 0 }).valor;

    return tblAdmIva_items.find((i) => i.valor === valor)?.idIvaNPais;
};

export const getDefaultMonedaId = () => {
    return datasource_tblEmpresasPolarier
        .items()
        .find((e) => e.idEmpresaPolarier === empresaPolarierSel.idEmpresaPolarier)?.idMoneda;
};

export const getDefaultTasaCambio = () => 1;

export const getFormatDescuento = (idAdmTipoDescuento) => {
    if (idAdmTipoDescuento === 2) {
        return { ...formats.decimal_2.format };
    }
    return { ...formats.percent };
};

export const handleDefaultFieldsCliente = (fields, ref, cliente) => {
    fields.forEach((field) => {
        const isObject = typeof field === "object";
        const fieldValue = isObject ? cliente[field.cliente] : cliente[field];
        const dataField = isObject ? field.form : field;

        ref.updateData(dataField, fieldValue);
    });
};

export const totalCustomSummary = (options) => {
    let { summaryProcess } = options;
    if (summaryProcess === "start") {
        options.totalValue = { valor: 0, idMoneda: null };
    }

    if (summaryProcess === "calculate" && options.totalValue != null) {
        const { value } = options;
        if (options.totalValue.idMoneda == null) {
            options.totalValue.idMoneda = value.idMoneda;
        }
        if (options.totalValue.idMoneda !== value.idMoneda) {
            options.totalValue = null;
        } else {
            if (value.idTipoFactura === 4) options.totalValue.valor -= value.valor;
            else options.totalValue.valor += value.valor;
        }
    }
};

export const onCellPrepared_Total = (e) => {
    const { column, rowType, data, cellElement, component } = e;
    if ((column?.dataField === "total" || column?.dataField === "bruto") && rowType === "totalFooter") {
        const value = component.getTotalSummaryValue(column?.dataField);
        let element = cellElement.children().first();
        if (value && value.idMoneda) {
            element.text(formatNumber(value.valor, 2, "currency", codigosMoneda[value.idMoneda]));
        } else {
            element.text("---");
        }
    }
};
