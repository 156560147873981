import React from "react";
import { connect } from "react-redux";

//Layout
import Box, { Item as ItemBox } from "devextreme-react/box";
import { Button } from "devextreme-react";
import LottieIcon from "components/LottieIcon";
import { deepClone } from "../../../helpers/utils";

import "../css.scss";

//Js

class PreguntaOpciones extends React.Component {
  setRespuesta = () => {
    const {
      onValueChange,
      respuestas,
      idPregunta,
      datosEncuesta,
      preguntas,
      dataSource,
    } = this.props;
    const resp = { idPregunta, idOpcion: dataSource.idOpcion };
    const hayRespuesta = respuestas.find((x) => x.idPregunta === idPregunta);

    let respuestas_ = deepClone(respuestas);
    let preguntas_ = deepClone(preguntas);
    let newState = {};

    const preguntaAnidada = datosEncuesta.tblPreguntaNOpcionNPregunta.find(
      (x) => x.idPregunta === idPregunta,
    );

    if (preguntaAnidada) {
      const pregAnidadaExiste = preguntas.some(
        (x) => x.idPregunta === preguntaAnidada.idPreguntaAnidada,
      );
      const respuestaExpande = preguntaAnidada.idOpcion === resp.idOpcion;

      if (respuestaExpande && !pregAnidadaExiste) {
        const addPregunta = datosEncuesta.tblPregunta.find(
          (x) => x.idPregunta === preguntaAnidada.idPreguntaAnidada,
        );
        preguntas_.push(addPregunta);
        newState.preguntas = preguntas_;
        newState.preguntas.sort((a, b) => a.orden - b.orden);
      }

      if (!respuestaExpande && pregAnidadaExiste) {
        preguntas_ = preguntas_.filter(
          (x) => x.idPregunta !== preguntaAnidada.idPreguntaAnidada,
        );
        respuestas_ = respuestas_.filter(
          (x) =>
            x.idPregunta !== preguntaAnidada.idPreguntaAnidada &&
            x.idOpcion !== dataSource.idOpcion,
        );
        respuestas_.push(resp);
        newState.preguntas = preguntas_;
      }
    }

    if (hayRespuesta) {
      respuestas_ = respuestas_.filter(
        (x) =>
          x.idPregunta !== idPregunta && x.idOpcion !== dataSource.idOpcion,
      );
    }

    respuestas_.push(resp);
    newState.respuestasUsuario = respuestas_;

    onValueChange(newState);
  };

  render() {
    const { dataSource, respuestas, idPregunta } = this.props;
    const respuestaSel = respuestas.some(
      (x) => x.idPregunta === idPregunta && x.idOpcion === dataSource.idOpcion,
    );

    return (
      <Button
        className="m-2 border-radius border-0 shadowHover"
        style={respuestaSel ? { backgroundColor: "var(--primary)" } : {}}
        onClick={this.setRespuesta}
      >
        <Box direction="row" width="100%" height="100%">
          <ItemBox ratio={1}>
            <div className="contentPosition_start">
              {dataSource.icon && (
                <LottieIcon
                  icon={dataSource.icon}
                  isLoop={false}
                  height={45}
                  width={45}
                />
              )}
              <div
                className="font-size-lg text-left flex-1 mx-3 "
                style={
                  respuestaSel
                    ? { color: "var(--white)" }
                    : { color: "var(--gray)" }
                }
              >
                {dataSource.descripcion}
              </div>
            </div>
          </ItemBox>
        </Box>
      </Button>
    );
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(PreguntaOpciones);
