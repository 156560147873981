import React, { Fragment } from "react";
import { connect } from "react-redux";

import { ButtonGroup } from "devextreme-react/button-group";

class ButtonGroupCustom extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      idSel: null,
    };

    //EVENTOS
    this.dxButtonGroup_buttonRender =
      this.dxButtonGroup_buttonRender.bind(this);
    this.dxButtonGroup_onItemClick = this.dxButtonGroup_onItemClick.bind(this);
  }

  //PROPS ACEPTADAS
  render() {
    let { items, visible, idSel } = this.props;

    return (
      <Fragment>
        <ButtonGroup
          height="100%"
          width="100%"
          items={items}
          keyExpr="id"
          selectedItemKeys={
            idSel != null ? [idSel] : items.length > 0 ? [items[0].id] : null
          }
          visible={visible == null ? true : visible}
          buttonRender={this.dxButtonGroup_buttonRender}
          onItemClick={this.dxButtonGroup_onItemClick}
        />
      </Fragment>
    );
  }

  dxButtonGroup_buttonRender(data, $buttonContent) {
    return (
      <div
        className="d-flex he-100 justify-content-center align-items-center"
        style={{ "--buttonGroup_color": "var(" + data.color + ")" }}
      >
        {data.icon && (
          <i
            style={{ flexBasis: 30, color: "red" }}
            className={"font-size-xxl w-100 " + data.icon}
          ></i>
        )}
        <span
          style={{ lineHeight: "12px" }}
          className="d-flex flex-column w-100 he-100 align-items-center justify-content-center"
        >
          {data.denominacion}
        </span>
      </div>
    );
  }

  dxButtonGroup_onItemClick(e) {
    this.props.dxButtonGroup_onValueChanged(e.itemData.id);
  }

  componentDidUpdate() {
    let { idSel } = this.props;
    this.setState({
      idSel: idSel,
    });
  }

  componentDidMount() {}
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
  idioma: state.Global.idioma,
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(ButtonGroupCustom);
