import React, { useEffect, useState, useCallback, useRef } from "react";

import Lookup, { DropDownOptions } from "devextreme-react/lookup";
import RadioGroup from "devextreme-react/radio-group";

import { datasource_tblAlmacenRecambios } from "../../../api";
import { getTrad } from "helpers";

const SharedLookupAlmacen = ({
  value,
  readOnly,
  noDataText,
  filter,
  setCodigoMoneda = null,
  onlyAlmacenPadre = false,
  onlySubAlmacen = false,
  onChange,
}) => {
  const lookupRef = useRef(null);

  const [isAlmacenPadre, setIsAlmacenPadre] = useState(!onlySubAlmacen);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const updateDataSource = () => {
      const data = datasource_tblAlmacenRecambios.items();
      const newDataSource = data.filter(
        (ar) =>
          (isAlmacenPadre
            ? ar.idAlmacenPadre === null
            : ar.idAlmacenPadre !== null) &&
          (!filter || filter(ar))
      );
      setDataSource(newDataSource);
    };

    updateDataSource();

    if (datasource_tblAlmacenRecambios.items().length === 0) {
      datasource_tblAlmacenRecambios.load().done(updateDataSource);
    }
  }, [isAlmacenPadre]);

  useEffect(() => {
    const almacenSel = datasource_tblAlmacenRecambios
      .items()
      .find((ar) => ar.idAlmacen === value);

    setIsAlmacenPadre(!value || almacenSel?.idAlmacenPadre === null);
  }, [value]);

  const enum_tipoAlmacen = [
    {
      isAlmacenPadre: true,
      denominacion: getTrad("almacenes").toUpperCase(),
    },
    {
      isAlmacenPadre: false,
      denominacion: getTrad("subAlmacenes").toUpperCase(),
    },
  ];

  const itemRender = useCallback(
    ({ denominacion }) => <div className={"text-center"}>{denominacion}</div>,
    []
  );

  const titleRender = useCallback(
    () => (
      <RadioGroup
        dataSource={enum_tipoAlmacen}
        valueExpr={"isAlmacenPadre"}
        displayExpr={"denominacion"}
        layout={"horizontal"}
        value={isAlmacenPadre}
        itemRender={itemRender}
        onValueChanged={({ value }) => {
          setIsAlmacenPadre(value);
          lookupRef?.current?.instance?.repaint();
        }}
      />
    ),
    [isAlmacenPadre, itemRender, onChange]
  );

  const onValueChanged = useCallback(
    ({ value }) => {
      onChange(value);

      if (setCodigoMoneda) {
        const codigoMoneda = datasource_tblAlmacenRecambios
          .items()
          .find((ar) => ar.idAlmacen === value)?.idMonedaNavigation?.codigo;

        setCodigoMoneda(codigoMoneda);
      }
    },
    [onChange, setCodigoMoneda]
  );

  return (
    <Lookup
      ref={lookupRef}
      dataSource={dataSource}
      value={value}
      valueExpr={"idAlmacen"}
      displayExpr={"denominacion"}
      placeholder={getTrad("seleccioneAlmacen")}
      showCancelButton={false}
      searchTimeout={0}
      noDataText={noDataText}
      readOnly={readOnly}
      onValueChanged={onValueChanged}
    >
      <DropDownOptions
        closeOnOutsideClick={true}
        width={400}
        showTitle={!onlyAlmacenPadre && !onlySubAlmacen}
        titleRender={titleRender}
      />
    </Lookup>
  );
};

export default SharedLookupAlmacen;
