import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { TagBox } from "devextreme-react/tag-box";

import { connectionConstants } from "../../../../../../constants";
import { authHeader, errorHandler, getTrad } from "../../../../../../helpers";
import { Validator, CustomRule } from "devextreme-react/validator";

class ContadorVirtual extends Component {
  constructor(props) {
    super(props);

    this.state = { tblRecursoVirtual_Calculo: [] };

    this.tagRender = this.tagRender.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
  }

  datasource_tblRecursoContador = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblRecursoContador",
      key: "idRecursoContador",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      onLoading: (loadOptions) =>
        this.datasource_tblRecursoContador_onLoading(loadOptions),
      version: 4,
    }),
    select: ["idRecursoContador", "denominacion"],
  });

  datasource_tblRecursoContador_onLoading = (loadOptions) => {
    const { lavanderia, recursoEnergeticoMod } = this.props;

    let customFilter = [
      ["idLavanderia", "=", lavanderia.idLavanderia],
      "and",
      ["eliminado", "=", false],
      "and",
      ["activo", "=", true],
      "and",
      ["idRecursoContador", "<>", recursoEnergeticoMod?.idRecursoContador ?? 0],
      "and",
      ["idGrupoEnergetico", "=", recursoEnergeticoMod?.idGrupoEnergetico ?? 0],
      "and",
      [
        "idUnidadMedida_Contador",
        "=",
        recursoEnergeticoMod?.idUnidadMedida_Contador ?? 0,
      ],
      "and",
      ["isVirtual", "=", false],
    ];

    if (loadOptions.filter) {
      loadOptions.filter = [loadOptions.filter, "and", customFilter];
    } else {
      loadOptions.filter = customFilter;
    }
  };

  pagingFalse = { enabled: false };

  render() {
    const { tblRecursoVirtual_Calculo } = this.state;

    return (
      <TagBox
        dataSource={this.datasource_tblRecursoContador}
        valueExpr="idRecursoContador"
        displayExpr={"denominacion"}
        value={tblRecursoVirtual_Calculo.map((x) => x.idRecursoContador)}
        showSelectionControls={true}
        applyValueMode="instantly"
        stylingMode="underlined"
        openOnFieldClick={false}
        showDropDownButton={true}
        tagRender={this.tagRender}
        onValueChanged={this.onValueChanged}
      >
        <Validator validationGroup="validationGroup_form">
          <CustomRule
            reevaluate={true}
            validationCallback={this.validationCallback}
            message={
              "Debes seleccionar algún contador para el cálculo del contador virtual."
            }
          />
        </Validator>
      </TagBox>
    );
  }

  tagRender = (data) => {
    const { setRecursoEnergeticoMod, recursoEnergeticoMod } = this.props;
    let { tblRecursoVirtual_Calculo } = this.state;
    let _this = this;
    let item = tblRecursoVirtual_Calculo.filter(
      (x) => x.idRecursoContador === data.idRecursoContador,
    )[0];
    if (!item) {
      return;
    }
    return (
      <div
        className={
          "container_spanCentrado p-1 m-2 bg-white rounded shadow-sm border border-" +
          (item.operando === 1 ? "success" : "danger")
        }
        onClick={() => {
          item.operando = item.operando * -1;
          _this.setState({ tblRecursoVirtual_Calculo });
          recursoEnergeticoMod.tblRecursoVirtual_CalculoidRecursoVirtualNavigation =
            tblRecursoVirtual_Calculo;
          setRecursoEnergeticoMod(recursoEnergeticoMod);
        }}
      >
        <div
          className={
            "font-size-xl rounded text-white container_spanCentrado pb-1 bg-" +
            (item.operando === 1 ? "success" : "danger")
          }
          style={{ width: 30, height: 30 }}
        >
          {item.operando === 1 ? "+" : "-"}
        </div>
        <div className="font-size-sm  pl-2 pr-2">{data.denominacion}</div>
      </div>
    );
  };

  onValueChanged = (e) => {
    const { setRecursoEnergeticoMod, recursoEnergeticoMod } = this.props;
    const { tblRecursoVirtual_Calculo } = this.state;

    let newValue = [...e.value];
    newValue = newValue.map((idRecursoContador) => {
      let existente = tblRecursoVirtual_Calculo.find(
        (x) => x.idRecursoContador === idRecursoContador,
      );

      return {
        idRecursoContador,
        operando: existente?.operando ?? 1,
      };
    });

    this.setState({ tblRecursoVirtual_Calculo: newValue });
    recursoEnergeticoMod.tblRecursoVirtual_CalculoidRecursoVirtualNavigation =
      newValue;
    setRecursoEnergeticoMod(recursoEnergeticoMod);
  };

  validationCallback = (e) => {
    const { recursoEnergeticoMod } = this.props;
    const { tblRecursoVirtual_Calculo } = this.state;
    return true; // !recursoEnergeticoMod.isVirtual || (recursoEnergeticoMod.isVirtual && tblRecursoVirtual_Calculo.length > 0)
  };

  componentDidMount() {
    const { recursoEnergeticoMod } = this.props;
    this.setState({
      tblRecursoVirtual_Calculo:
        recursoEnergeticoMod.tblRecursoVirtual_CalculoidRecursoVirtualNavigation,
    });
  }

  componentDidUpdate(prevProps) {
    const { recursoEnergeticoMod } = this.props;
    if (
      JSON.stringify(prevProps.recursoEnergeticoMod) !==
      JSON.stringify(recursoEnergeticoMod)
    ) {
      this.datasource_tblRecursoContador.reload();
      this.setState({
        tblRecursoVirtual_Calculo:
          recursoEnergeticoMod.tblRecursoVirtual_CalculoidRecursoVirtualNavigation,
      });
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(ContadorVirtual);
