import React from "react";

import DxForm, {
  AsyncRule,
  ButtonItem,
  EmptyItem,
  Item,
  Label,
  RequiredRule,
  Tab,
  TabbedItem,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";

import "./Css.scss";
import DataGridTblRecambioNAlmacen from "./components/DataGridTblRecambioNAlmacen";
import LayoutTblRecambioNProveedor from "./components/LayoutTblRecambioNProveedor";
import { deepClone, getTrad, patchRequestHandler } from "helpers";
import { useRecambiosProvider } from "../../../RecambiosProvider";

const tabPanelOptions = {
  elementAttr: { class: "h4" },
  deferRendering: false,
  onContentReady: ({ element }) => element.find(".dx-tabs").addClass("fill"),
};

const labelNoVisible = { visible: false };

const defaultNotify = (isError, trad) => {
  notify({
    message: getTrad(trad),
    type: isError ? "error" : "success",
    displayTime: 1500,
    closeOnClick: true,
  });
};

const Form = ({ buscadorRef, onHiding }) => {
  const {
    recambioSel: formData,
    setRecambioSel,
    context_recambios,
    datasource_tblRecambio,
    tblProveedor,
    isSending,
    setIsSending,
    refreshData,
  } = useRecambiosProvider();

  const onValueChanged = (field, value) => {
    if (formData.idRecambio) {
      setRecambioSel((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const editorOptions = {
    denominacion: {
      onValueChanged: ({ value }) => onValueChanged("denominacion", value),
    },
    idProveedor: {
      dataSource: tblProveedor,
      valueExpr: "idProveedor",
      displayExpr: "nombreComercial",
      searchTimeout: 0,
      showCancelButton: false,
      dropDownOptions: {
        closeOnOutsideClick: true,
        showTitle: false,
        width: 400,
      },
    },
    referencia: {
      onValueChanged: ({ value }) => onValueChanged("referencia", value),
    },
    referenciaInterna: {
      onValueChanged: ({ value }) => onValueChanged("referenciaInterna", value),
    },
    activo: {
      switchedOffText: getTrad("noActivo"),
      switchedOnText: getTrad("activo"),
    },
    buttonOptions: {
      text: getTrad("guardar"),
      type: "success",
      useSubmitBehavior: true,
      disabled: isSending,
    },
  };

  const formatFormData = () => {
    const formatedFormData = deepClone(formData);

    formatedFormData.tblRecambioNAlmacenRecambios =
      formData.tblRecambioNAlmacenRecambios.map((item) => ({
        idAlmacen: item.idAlmacen,
        idRecambio: item.idRecambio,
        ubicacion: item.ubicacion,
      }));

    formatedFormData.tblRecambioNProveedor = formData.tblRecambioNProveedor.map(
      (item) => {
        const { idPaisNavigation, idProveedorNavigation, ...rest } = item;
        return { ...rest };
      }
    );

    return formatedFormData;
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formatedFormData = formatFormData();

    setIsSending(true);
    if (formatedFormData.idRecambio) {
      datasource_tblRecambio
        .store()
        .update(
          formatedFormData.idRecambio,
          patchRequestHandler(formatedFormData)
        )
        .done(() => {
          refreshData().then(() => buscadorRef.current.refresh());
          defaultNotify(false, "aviso_C_RegistroActualizado");
          onHiding();
        })
        .catch(() => defaultNotify(true, "aviso_RegistroNoActualizado"))
        .always(() => setIsSending(false));
    } else {
      datasource_tblRecambio
        .store()
        .insert(formatedFormData)
        .done(() => {
          refreshData().then(() => buscadorRef.current.refresh());
          defaultNotify(false, "aviso_C_RegistroInsertado");
          onHiding();
        })
        .catch(() => defaultNotify(true, "aviso_I_RegistroNoInsertado"))
        .always(() => setIsSending(false));
    }
  };

  const validateAsync_referencia = (params) => {
    let referencia = params.value.replace(/\s+/g, " ").trim();
    return new Promise((resolve) => {
      context_recambios
        .invoke("CheckReferencia", {
          idRecambio: formData.idRecambio,
          referencia,
        })
        .done(resolve);
    });
  };

  const validateAsync_referenciaInterna = (params) => {
    let referenciaInterna = params.value.replace(/\s+/g, " ").trim();
    return new Promise((resolve) => {
      context_recambios
        .invoke("CheckReferenciaInterna", {
          idRecambio: formData.idRecambio,
          referenciaInterna,
        })
        .done(resolve);
    });
  };

  const render_tblRecambioNAlmacen = () => (
    <DataGridTblRecambioNAlmacen
      dataSource={formData.tblRecambioNAlmacenRecambios}
    />
  );

  const render_tblRecambioNProveedor = () => (
    <LayoutTblRecambioNProveedor dataSource={formData.tblRecambioNProveedor} />
  );

  return (
    <form
      id={"formRecambio"}
      className={"he-100"}
      onKeyDown={onKeyDown}
      onSubmit={onSubmit}
    >
      <DxForm
        formData={formData}
        width={"100%"}
        height={"100%"}
        labelLocation={"top"}
        colCount={12}
        showColonAfterLabel={false}
      >
        <Item
          dataField={"denominacion"}
          colSpan={7}
          editorOptions={editorOptions.denominacion}
        >
          <Label text={getTrad("denominacion")} />
          <RequiredRule />
        </Item>
        <Item
          dataField={"idProveedor"}
          editorType={"dxLookup"}
          colSpan={5}
          editorOptions={editorOptions.idProveedor}
        >
          <Label text={getTrad("fabricante")} />
        </Item>
        <Item
          dataField={"referencia"}
          colSpan={5}
          editorOptions={editorOptions.referencia}
        >
          <Label text={getTrad("referencia")} />
          <AsyncRule
            ignoreEmptyValue
            reevaluate
            message={getTrad("referenciaYaExiste")}
            validationCallback={validateAsync_referencia}
          />
        </Item>
        <Item
          dataField={"referenciaInterna"}
          colSpan={5}
          editorOptions={editorOptions.referenciaInterna}
        >
          <Label text={getTrad("referenciaInterna")} />
          <AsyncRule
            ignoreEmptyValue
            reevaluate
            message={getTrad("referenciaInternaYaExiste")}
            validationCallback={validateAsync_referenciaInterna}
          />
        </Item>
        <Item
          dataField={"activo"}
          editorType={"dxSwitch"}
          colSpan={2}
          editorOptions={editorOptions.activo}
        >
          <Label text={getTrad("activo")} />
        </Item>
        <Item
          dataField={"descripcionArticulo"}
          editorType={"dxTextArea"}
          colSpan={12}
        >
          <Label text={getTrad("descripcionArticulo")} />
        </Item>
        <TabbedItem colSpan={12} tabPanelOptions={tabPanelOptions}>
          <Tab title={getTrad("almacenes")}>
            <Item
              dataField={"tblRecambioNAlmacenRecambios"}
              render={render_tblRecambioNAlmacen}
              label={labelNoVisible}
            />
          </Tab>
          <Tab title={getTrad("proveedores")}>
            <Item
              dataField={"tblRecambioNProveedor"}
              render={render_tblRecambioNProveedor}
              label={labelNoVisible}
            />
          </Tab>
        </TabbedItem>
        <EmptyItem colSpan={10} />
        <ButtonItem
          buttonOptions={editorOptions.buttonOptions}
          horizontalAlignment={"right"}
          verticalAlignment={"bottom"}
          colSpan={2}
        />
      </DxForm>
    </form>
  );
};

export default Form;
