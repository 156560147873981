export const balanceHorasRender = (e) => {
    return (
        <div>
            <span className={`font-weight-semiBold`} style={{ color: "inherit" }}>
                {e.value < 0 ? "-" : ""}
                {Math.floor(Math.abs(e.value / 60))}:{Math.abs(e.value % 60) < 10 && "0"}
                {Math.abs(e.value % 60)}
            </span>
        </div>
    );
};
