import DataSource from "devextreme/data/data_source";
import { getSymbol, getSortedArray, getArticulos, getDefaultTasaCambio } from "../functions";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "constants";
import { authHeader, errorHandler } from "helpers";
import notify from "devextreme/ui/notify";
import { store } from "config";
// import { useContext } from "react";

import idsUsuario from "constants/enums/GestionInterna/tblUsuario";

export let empresaPolarierSel = {
    idEmpresaPolarier: null,
    idPais: null,
};

export const setEmpresaPolarierSel = (empresa) => {
    empresaPolarierSel = empresa;
};

let idMoneda = null;

export const getUserState = () => {
    const state = store.getState();
    return state.Authentication.user;
};

const filterEmpresasAutorizadas = (loadOptions) => {
    const user = getUserState();

    if (user.tblEmpresaPolarierNUsuario == undefined || user.tblEmpresaPolarierNUsuario == null) {
        notify({
            message: "No tiene empresas asignadas.",
            type: "error",
            displayTime: 2500,
            // closeOnClick: true,
        });
        return;
    }

    const filter = [
        `idEmpresaPolarier in (${user.tblEmpresaPolarierNUsuario.map((e) => e.idEmpresaPolarier).join(",")})`,
    ];

    if (loadOptions?.filter?.length > 0) {
        loadOptions.filter = [loadOptions.filter, "and", filter];
    } else {
        loadOptions.filter = filter;
    }
};

const filterMonedaEmpresa = (loadOptions) => {
    const filter = [
        ["idMoneda", "=", idMoneda],
        ["idEmpresaPolarier", "=", empresaPolarierSel.idEmpresaPolarier],
    ];

    if (idMoneda != null) {
        if (loadOptions?.filter?.length > 0) {
            loadOptions.filter = [loadOptions.filter, "and", filter];
        } else {
            loadOptions.filter = filter;
        }
    } else {
        filterEmpresa(loadOptions);
    }
};

const filterEmpresa = (loadOptions) => {
    const filter = ["idEmpresaPolarier", "=", empresaPolarierSel.idEmpresaPolarier];

    if (loadOptions?.filter?.length > 0) {
        loadOptions.filter = [loadOptions.filter, "and", filter];
    } else {
        loadOptions.filter = filter;
    }
};

const filterEmpresaIsEliminado = (loadOptions) => {
    const filter = [
        ["idEmpresaPolarier", "=", empresaPolarierSel.idEmpresaPolarier],
        ["isEliminado", "=", false],
    ];

    if (loadOptions?.filter?.length > 0) {
        loadOptions.filter = [loadOptions.filter, "and", filter];
    } else {
        loadOptions.filter = filter;
    }
};
const filterIdPais = (loadOptions) => {
    const filter = ["idPais", "=", empresaPolarierSel.idPais];

    if (loadOptions?.filter?.length > 0) {
        loadOptions.filter = [loadOptions.filter, "and", filter];
    } else {
        loadOptions.filter = filter;
    }
};

//#region Métodos auxiliares para los Datasources
export const authHeaderAdd = (request) => {
    request.headers = { ...authHeader() };
};

//#endregion

//#region Datasources de SAP
export const datasource_tblCliente = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCliente",
        // key: "idAdmCliente",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresaIsEliminado(loadOptions),
        version: 4,
    }),
    sort: ["nombreFiscal"],
    postProcess: (data) => {
        return getSortedArray(data, "nombreFiscal", false);
    },
});

export const datasource_tblProveedor = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmProveedor",
        // key: "idAdmProveedor",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (e) => filterEmpresaIsEliminado(e),
        version: 4,
    }),
    sort: ["nombreFiscal"],
    postProcess: (data) => {
        return getSortedArray(data, "nombreFiscal", false);
    },
});

export const datasource_tblViasPagoCobro = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmFormaPago",
        // key: "idAdmFormaPago",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterIdPais(loadOptions),
        version: 4,
    }),
    // map: (itemData) => {
    //     return tblViasCobro_map(itemData);
    // },
});

const tblViasCobro_map = (itemData) => {
    return {
        ...itemData,
        idViaCobro: itemData.Country + "_" + itemData.PaymentMethod,
    };
};

export const datasource_tblCondicionPagoCobro = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCondicionPago",
        // key: ["PaymentTerms", "PaymentTermsValidityMonthDay"],
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

// export const datasource_tblCondicionCobro = new DataSource({
//     paginate: false,
//     store: new ODataStore({
//         url: connectionConstants.WEB_API_CORE_ODATA_URL + "SAP/Get_CondicionesCobro",
//         key: ["PaymentTerms", "PaymentTermsValidityMonthDay"],
//         errorHandler: function (error) {
//             errorHandler(error, null);
//         },
//         beforeSend: (request) => {
//             authHeaderAdd(request);
//         },
//         version: 4,
//     }),
// });

//#endregion

//#region Datasources de tablas estáticas

export const datasource_tblAdmCentroCoste = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCentroCoste",
        // key: "idMoneda",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresaIsEliminado(loadOptions),
        version: 4,
    }),
    sort: ["denominacion"],
    postProcess: (data) => {
        return getSortedArray(data, "denominacion", false);
    },
});

export const datasource_tblAdmElementoPEP = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmElementoPEP",
        // key: "idMoneda",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresa(loadOptions),
        version: 4,
    }),
    sort: ["denominacion"],
    postProcess: (data) => {
        return getSortedArray(data, "denominacion", false);
    },
});

export const datasource_tblMoneda = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMoneda",
        // key: "idMoneda",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblAdmTipoElemento = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmTipoElemento",
        // key: "idAdmTipoElemento",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblAdmTipoCambio = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmTipoCambio",
        // key: "idAdmTipoCambio",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblIncoterm = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblIncoterm",
        // key: "idAdmTipoCambio",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

const store_tblIvaNPais = new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblIvaNPais",
    // key: "customer",
    errorHandler: function (error) {
        errorHandler(error, null);
    },
    beforeSend: (request) => {
        authHeaderAdd(request);
    },
    onLoading: (loadOptions) => {
        loadOptions.filter = ["idPais", "=", empresaPolarierSel.idPais];
    },
    version: 4,
});

export const datasource_tblIvaNPais_Venta = new DataSource({
    paginate: false,
    store: store_tblIvaNPais,
    expand: ["idAdmIvaNavigation"],
    postProcess: (data) => {
        return data
            .filter((inp) => inp.indicadorIVA_Venta !== null)
            .map((inp) => {
                return {
                    idIvaNPais: inp.idIvaNPais,
                    denominacion: `${inp.idAdmIvaNavigation.denominacion} ${inp.descripcionIVA_Venta ?? ""} (${
                        inp.indicadorIVA_Venta
                    })`,
                    valor: inp.idAdmIvaNavigation.valor,
                };
            })
            .sort((a, b) => a.denominacion.localeCompare(b.denominacion));
    },
});

export const datasource_tblIvaNPais_Compra = new DataSource({
    paginate: false,
    store: store_tblIvaNPais,
    expand: ["idAdmIvaNavigation"],
    postProcess: (data) => {
        return data
            .filter((inp) => inp.indicadorIVA_Compra !== null)
            .map((inp) => {
                return {
                    idIvaNPais: inp.idIvaNPais,
                    denominacion: `${inp.idAdmIvaNavigation.denominacion} ${inp.descripcionIVA_Compra ?? ""} (${
                        inp.indicadorIVA_Compra
                    })`,
                    valor: inp.idAdmIvaNavigation.valor,
                };
            })
            .sort((a, b) => a.denominacion.localeCompare(b.denominacion));
    },
});

export const datasource_tblAdmPedido_Estado = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmPedido_Estado",
        // key: "idAdmPedido_Estado",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblAdmAlbaran_Estado = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmAlbaran_Estado",
        // key: "idAdmAlbaran_Estado",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblAdmPresupuestoVenta_Estado = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmPresupuestoVenta_Estado",
        // key: "idAdmPresupuestoVenta_Estado",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblAdmTipoFactura = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmTipoFactura",
        // key: "idAdmTipoElemento",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblAdmFactura_Estado = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmFactura_Estado",
        // key: "idAdmTipoElemento",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblAdmBanco = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmBanco",
        // key: "idAdmTipoElemento",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblAdmCuentaBancaria = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCuentaBancaria",
        // key: "idAdmTipoElemento",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => {
            filterMonedaEmpresa(loadOptions);
        },
        version: 4,
    }),
});

export const datasource_tblAdmTipoNCF = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmTipoNCF",
        // key: "idAdmTipoElemento",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        version: 4,
    }),
});

export const datasource_tblEmpresasPolarier = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEmpresasPolarier",
        key: "idEmpresaPolarier",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            request.headers = { ...authHeader() };
        },
        onLoading: (loadOptions) => filterEmpresasAutorizadas(loadOptions),
        version: 4,
        sort: ["denominacion"],
        expand: ["idPaisNavigation($Select=idMoneda)"],
    }),
});

export const refreshStaticDatasources = async (empresaSel) => {
    if (empresaSel != null) {
        empresaPolarierSel = empresaSel;
    }
    datasource_tblMoneda.load();
    datasource_tblAdmTipoElemento.load();
    datasource_tblAdmTipoCambio.load();
    datasource_tblIncoterm.load();
    datasource_tblIvaNPais_Compra.load();
    datasource_tblIvaNPais_Venta.load();
    datasource_tblAdmPedido_Estado.load();
    datasource_tblAdmAlbaran_Estado.load();
    datasource_tblAdmPresupuestoVenta_Estado.load();
    datasource_tblAdmCentroCoste.load();
    datasource_tblAdmElementoPEP.load();
    datasource_tblAdmTipoFactura.load();
    datasource_tblAdmFactura_Estado.load();
    datasource_tblAdmBanco.load();
    datasource_tblAdmTipoNCF.load();
    datasource_tblAdmCuentaBancaria.load();
    datasource_tblCliente.load();
    datasource_tblProveedor.load();
    datasource_tblViasPagoCobro.load();
    datasource_tblCondicionPagoCobro.load();
};

export const reloadCuentasBancarias = (idMonedaSel) => {
    return new Promise((resolve, reject) => {
        idMoneda = idMonedaSel;
        datasource_tblAdmCuentaBancaria
            .load()
            .then((data) => {
                idMoneda = null;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

//#endregion

//#region Modelos estáticos

export const formats = {
    decimal_2: {
        format: {
            style: "decimal",
            maximumFractionDigits: 2,
            type: "",
        },
    },
    percent: {
        type: "percent",
        maximumFractionDigits: 0,
        style: null,
    },
};

export const displayDeno_CondicionTermino = (e) => {
    if (e) {
        return e.denominacionCondicion + (e.denominacionTermino.length > 0 ? " - " + e.denominacionTermino : "");
    }
};

export const sharedEditorOptions = {
    idMoneda: {
        dataSource: datasource_tblMoneda,
        valueExpr: "idMoneda",
        displayExpr: "codigo",
    },
    codigo: {
        readOnly: true,
    },
    idAdmCliente: {
        dataSource: datasource_tblCliente, //REVISAR LOOKUPS CUANDO SE CAMBIEN LAS VARIABLES
        valueExpr: "idAdmCliente",
        displayExpr: "nombreFiscal",
        searchEnabled: true,
    },
    idAdmProveedor: {
        dataSource: datasource_tblProveedor,
        valueExpr: "idAdmProveedor",
        displayExpr: "nombreFiscal",
        searchEnabled: true,
    },
    idAdmFormaPago: {
        dataSource: datasource_tblViasPagoCobro,
        valueExpr: "idAdmFormaPago",
        displayExpr: "denominacion",
    },
    idAdmCondicionPago: {
        dataSource: datasource_tblCondicionPagoCobro,
        valueExpr: "idAdmCondicionPago",
        displayExpr: displayDeno_CondicionTermino,
        searchEnabled: true,
        dropDownOptions: { minWidth: 425 },
    },
    fechaCreacion: {
        displayFormat: "dd/MM/yyyy",
    },
    idAdmTipoCambio: {
        dataSource: datasource_tblAdmTipoCambio,
        valueExpr: "idAdmTipoCambio",
        displayExpr: "denominacion",
    },
    idIncoterm: {
        dataSource: datasource_tblIncoterm,
        valueExpr: "idIncoterm",
        displayExpr: "denominacion",
        showClearButton: true,
    },
    idAdmPresupuestoVenta_Estado: {
        dataSource: datasource_tblAdmPresupuestoVenta_Estado,
        valueExpr: "idAdmPresupuestoVenta_Estado",
        displayExpr: "denominacion",
    },
    idAdmPedido_Estado: {
        dataSource: datasource_tblAdmPedido_Estado,
        valueExpr: "idAdmPedido_Estado",
        displayExpr: "denominacion",
    },
    idAdmBanco: {
        dataSource: datasource_tblAdmBanco, //tblBanco,
        valueExpr: "idAdmBanco",
        displayExpr: "denominacion",
    },
    idCuentaBancaria: {
        dataSource: datasource_tblAdmCuentaBancaria, //tblBanco,
        valueExpr: "idCuentaBancaria",
        displayExpr: "denominacion",
        showClearButton: true,
        dropDownOptions: { minWidth: 350 },
    },
    idTipoFactura: {
        dataSource: datasource_tblAdmTipoFactura,
        valueExpr: "idTipoFactura",
        displayExpr: "denominacion",
    },
    tasaCambio: {
        format: {
            type: "fixedPoint",
            precision: 5,
        },
    },
};

//#endregion

//#region Datasources de los distintos elementos

export const datasource_tblAdmPedidoCliente = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmPedidoCliente",
        // key: "customer",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresa(loadOptions),
        version: 4,
    }),
    expand: [
        "idAdmPresupuestoVentaNavigation($select=codigo)",
        "tblArticuloNAdmPedidoCliente($expand=idArticuloLenceriaNavigation, idArticuloMaquinariaNavigation, idGrupoArticulosNavigation, idRecambioNavigation)",
    ],
    map: (itemData) => datasource_tblAdmPedidoCliente_map(itemData),
});

const datasource_tblAdmPedidoCliente_map = (data) => {
    return {
        idAdmPedidoCliente: data.idAdmPedidoCliente,
        codigo: data.codigo,
        fechaCreacion: data.fechaCreacion,
        idAdmPedido_Estado: data.idAdmPedido_Estado,
        idMoneda: data.idMoneda,
        idAdmCliente: data.idAdmCliente,
        idAdmTipoCambio: data.idAdmTipoCambio,
        tasaCambio: data.tasaCambio,
        ordenCompraCliente: data.numPedidoCliente,
        idAdmFormaPago: data.idAdmFormaPago,
        idTipoPedido: data.idTipoPedido,
        idAdmCentroCoste: data.idAdmCentroCoste,
        idAdmElementoPEP: data.idAdmElementoPEP,
        idIncoterm: data.idIncoterm,
        descuento: data.descuento,
        idAdmTipoDescuento: data.idAdmTipoDescuento,
        numPresupuesto_id: data.idAdmPresupuestoVenta,
        numPresupuesto_codigo: data?.idAdmPresupuestoVentaNavigation?.codigo,
        // numPresupuesto: data.numPresupuesto,
        idIvaNPais: data.idIvaNPais,
        observaciones: data.observaciones,
        // numPresupuestoNavigation: data.numPresupuestoNavigation,
        isCerrado: data.isCerrado,
        tblArticuloNAdmPedidoCliente: getArticulos(data.tblArticuloNAdmPedidoCliente),
    };
};

export const datasource_tblAdmPedidoProveedor = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmPedidoProveedor",
        // key: "customer",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresa(loadOptions),
        version: 4,
    }),
    expand: [
        "tblArticuloNAdmPedidoProveedor($expand=idArticuloLenceriaNavigation, idArticuloMaquinariaNavigation, idGrupoArticulosNavigation, idRecambioNavigation)",
    ],
    map: (itemData) => datasource_tblAdmPedidoProveedor_map(itemData),
});

const datasource_tblAdmPedidoProveedor_map = (data) => {
    return {
        idAdmPedidoProveedor: data.idAdmPedidoProveedor,
        idTipoPedido: data.idTipoPedido,
        codigo: data.codigo,
        fechaCreacion: data.fechaCreacion,
        idAdmPedido_Estado: data.idAdmPedido_Estado,
        idMoneda: data.idMoneda,
        idAdmProveedor: data.idAdmProveedor,
        idAdmTipoCambio: data.idAdmTipoCambio,
        tasaCambio: data.tasaCambio,
        idAdmFormaPago: data.idAdmFormaPago,
        idAdmCentroCoste: data.idAdmCentroCoste,
        idAdmElementoPEP: data.idAdmElementoPEP,
        descuento: data.descuento,
        idAdmTipoDescuento: data.idAdmTipoDescuento,
        idIncoterm: data.idIncoterm,
        fechaEstimadaRecepcion: data.fechaEstimadaRecepcion,
        numPresupuestoProveedor: data.numPresupuestoProveedor,
        observaciones: data.observaciones,
        isCerrado: data.isCerrado,
        tblArticuloNAdmPedidoProveedor: getArticulos(data.tblArticuloNAdmPedidoProveedor),
    };
};

export const datasource_tblAdmAlbaranCompra = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmAlbaranCompra",
        // key: "customer",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresa(loadOptions),
        version: 4,
    }),
    expand: [
        "idAdmPedidoProveedorNavigation($select=codigo)",
        "tblArticuloNAdmAlbaranCompra($expand=idArticuloLenceriaNavigation, idArticuloMaquinariaNavigation, idGrupoArticulosNavigation, idRecambioNavigation)",
    ],
    map: (itemData) => datasource_tblAdmAlbaranCompra_map(itemData),
});

export const datasource_tblAdmAlbaranCompra_map = (data) => {
    return {
        idAdmAlbaranCompra: data.idAdmAlbaranCompra,
        codigo: data.codigo,
        fechaCreacion: data.fechaCreacion,
        idTipoAlbaran: data.idTipoAlbaran,
        idAdmAlbaran_Estado: data.idAdmAlbaran_Estado,
        idAdmProveedor: data.idAdmProveedor,
        idMoneda: data.idMoneda,
        idAdmTipoCambio: data.idAdmTipoCambio,
        tasaCambio: data.tasaCambio,
        descuento: data.descuento,
        idAdmTipoDescuento: data.idAdmTipoDescuento,
        idAdmFormaPago: data.idAdmFormaPago,
        idIncoterm: data.idIncoterm,
        numPedidoProveedor_id: data.idAdmPedidoProveedor,
        numPedidoProveedor_codigo: data?.idAdmPedidoProveedorNavigation?.codigo,
        observaciones: data.observaciones,
        numAlbaranProveedor: data.numAlbaranProveedor,
        idAdmCentroCoste: data.idAdmCentroCoste,
        idAdmElementoPEP: data.idAdmElementoPEP,
        isCerrado: data.isCerrado,
        tblArticuloNAdmAlbaranCompra: getArticulos(data.tblArticuloNAdmAlbaranCompra),
    };
};

export const datasource_tblAdmAlbaranVenta = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmAlbaranVenta",
        // key: "customer",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresa(loadOptions),
        version: 4,
    }),
    expand: [
        "idAdmPedidoClienteNavigation($select=codigo)",
        "tblArticuloNAdmAlbaranVenta($expand=idArticuloLenceriaNavigation, idArticuloMaquinariaNavigation, idGrupoArticulosNavigation, idRecambioNavigation)",
    ],
    map: (itemData) => datasource_tblAdmAlbaranVenta_map(itemData),
});

export const datasource_tblAdmAlbaranVenta_map = (data) => {
    return {
        idAdmAlbaranVenta: data.idAdmAlbaranVenta,
        codigo: data.codigo,
        fechaCreacion: data.fechaCreacion,
        idTipoAlbaran: data.idTipoAlbaran,
        idAdmAlbaran_Estado: data.idAdmAlbaran_Estado,
        idAdmCliente: data.idAdmCliente,
        idMoneda: data.idMoneda,
        idAdmTipoCambio: data.idAdmTipoCambio,
        tasaCambio: data.tasaCambio,
        descuento: data.descuento,
        idAdmTipoDescuento: data.idAdmTipoDescuento,
        idAdmFormaPago: data.idAdmFormaPago,
        numPedidoCliente_id: data.idAdmPedidoCliente,
        numPedidoCliente_codigo: data?.idAdmPedidoClienteNavigation?.codigo,
        idTipoFactura: data.idTipoFactura,
        idIvaNPais: data.idIvaNPais,
        observaciones: data.observaciones,
        idAdmCentroCoste: data.idAdmCentroCoste,
        idAdmElementoPEP: data.idAdmElementoPEP,
        idIncoterm: data.idIncoterm,
        // numPedidoClienteNavigation: data.numPedidoClienteNavigation,
        isCerrado: data.isCerrado,
        tblArticuloNAdmAlbaranVenta: getArticulos(data.tblArticuloNAdmAlbaranVenta),
    };
};

export const datasource_tblAdmFacturaCompra = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmFacturaCompra",
        // key: "customer",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresa(loadOptions),
        version: 4,
    }),
    expand: [
        "tblAdmConceptoCompra",
        "idAdmAlbaranCompra($expand=tblArticuloNAdmAlbaranCompra($expand=idArticuloLenceriaNavigation, idArticuloMaquinariaNavigation, idGrupoArticulosNavigation, idRecambioNavigation))",
    ],
});

export const datasource_tblAdmFacturaVenta = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmFacturaVenta",
        // key: "customer",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresa(loadOptions),
        version: 4,
    }),
    expand: [
        "tblAdmConceptoVenta",
        "idAdmAlbaranVenta($expand=tblArticuloNAdmAlbaranVenta($expand=idArticuloLenceriaNavigation, idArticuloMaquinariaNavigation, idGrupoArticulosNavigation, idRecambioNavigation))",
        "idReferenciaFacturaVentaNavigation($select=idAdmFacturaVenta, codigo, NCF)",
    ],
    postProcess: (data) => {
        const state = store.getState();
        const user = state.Authentication.user;

        // Devuelvo data. Si es Silverio, Jorge o Maribel, mapeo isCerrado a false
        return data.map((item) => {
            if (
                [idsUsuario.silverioLaraudogoitia, idsUsuario.jorgeGarcia, idsUsuario.maribelMoranta].includes(
                    user.idUsuario
                )
            ) {
                item.isCerrado = false;
            }
            return item;
        });
    },
});

export const datasource_tblAdmPresupuestoVenta = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmPresupuestoVenta",
        // key: "customer",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            authHeaderAdd(request);
        },
        onLoading: (loadOptions) => filterEmpresa(loadOptions),
        version: 4,
    }),
    expand: [
        "tblArticuloNAdmPresupuestoVenta($expand=idArticuloLenceriaNavigation, idArticuloMaquinariaNavigation, idGrupoArticulosNavigation, idRecambioNavigation)",
    ],
    map: (itemData) => datasource_tblAdmPresupuestoVenta_map(itemData),
});

const datasource_tblAdmPresupuestoVenta_map = (data) => {
    return {
        idAdmPresupuestoVenta: data.idAdmPresupuestoVenta,
        codigo: data.codigo,
        idTipoPresupuesto: data.idTipoPresupuesto,
        idAdmCliente: data.idAdmCliente,
        fechaCreacion: data.fechaCreacion,
        idMoneda: data.idMoneda,
        idAdmTipoCambio: data.idAdmTipoCambio,
        tasaCambio: data.tasaCambio,
        idAdmFormaPago: data.idAdmFormaPago,
        descuento: data.descuento,
        idIvaNPais: data.idIvaNPais,
        idAdmTipoDescuento: data.idAdmTipoDescuento,
        observaciones: data.observaciones,
        idAdmPresupuestoVenta_Estado: data.idAdmPresupuestoVenta_Estado,
        isCerrado: data.isCerrado,
        tblArticuloNAdmPresupuestoVenta: getArticulos(data.tblArticuloNAdmPresupuestoVenta),
    };
};

export const saveData = async (method, body, url) => {
    return new Promise((resolve, reject) => {
        let payload = { ...body };
        if (payload.fechaCreacion != null) {
            if (typeof payload.fechaCreacion === "object") {
                payload.fechaCreacion = new Date(payload.fechaCreacion.setDate(payload.fechaCreacion.getDate() + 1))
                    .toISOString()
                    .split("T")[0];
            } else {
                payload.fechaCreacion = new Date(payload.fechaCreacion).toISOString().split("T")[0];
            }
        }
        if (payload.idCliente_SAP != null) {
            payload.idCliente_SAP = payload.idCliente_SAP.toString();
        }
        fetch(connectionConstants.WEB_API_CORE_ODATA_URL + "Administracion/" + url, {
            method: method,
            headers: {
                ...authHeader(),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (response.ok) {
                    notify({
                        message: "Documentación enviada correctamente",
                        type: "success",
                        displayTime: 1500,
                        closeOnClick: true,
                    });
                    resolve(response);
                    // cerrarPopup();
                } else {
                    notify({
                        message: "Error al enviar la documentación",
                        type: "error",
                        displayTime: 1500,
                        closeOnClick: true,
                    });
                    reject(error);
                }
            })
            .catch((error) => {
                console.error("There has been a problem with your fetch operation:", error);
                notify({
                    message: error.message,
                    type: "error",
                    displayTime: 1500,
                    closeOnClick: true,
                });
                reject(error);
            });
    });
};

//#endregion

//#region Modelos dinámicos

export const tblTipoDescuento = (idMoneda) => {
    return [
        {
            idAdmTipoDescuento: 1,
            simbolo: "%",
        },
        {
            idAdmTipoDescuento: 2,
            simbolo: getSymbol(idMoneda),
        },
    ];
};

//#endregion
