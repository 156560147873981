import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";

import { getTrad } from "helpers";
import Form from "./components/Form";
import notify from "devextreme/ui/notify";
import { saveData } from "pages/Administracion/shared/model";
import { payloadBuilder } from "../../model";

const FormAlbaranVenta = forwardRef(({ albaranVentaSel, validations, reloadData, closePopup }, ref) => {
    const [idMoneda, setIdMoneda] = useState(1);
    const formAlbaranVenta_REF = useRef();

    useImperativeHandle(ref, () => ({
        onSubmit_FormAlbaranVenta,
        hasEditData: formAlbaranVenta_REF.current.hasEditData,
    }));

    useEffect(() => {
        setIdMoneda(albaranVentaSel.idMoneda);
    }, [albaranVentaSel]);

    const onSubmit_FormAlbaranVenta = () => {
        const formData = formAlbaranVenta_REF.current.getFormData();
        formData.tblArticuloNAdmAlbaranVenta = formData.articulosDraft;
        let promises = [];

        validations.forEach((fnValidation) => {
            promises.push(fnValidation());
        });

        Promise.all(promises)
            .then(() => {
                delete formData.articulosDraft;
                saveData("POST", payloadBuilder(formData, true), "postAdmAlbaranVenta").then((data) => {
                    closePopup();
                    reloadData();
                });
            })
            .catch((message) => {
                if (message == null || message == undefined || message == "") {
                    message = getTrad("errorIntroduccionDatos");
                }
                notify({
                    message: message,
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            });
    };

    return (
        <>
            <div>
                <Form
                    ref={formAlbaranVenta_REF}
                    formData={albaranVentaSel}
                    validations={validations}
                    setIdMoneda={setIdMoneda}
                    idMoneda={idMoneda}
                />
            </div>
        </>
    );
});

export default FormAlbaranVenta;
