import React, { Fragment } from "react";
import { connect } from "react-redux";

import { history, password_Pattern } from "../../../helpers";

import { userActions } from "../../../actions";
import { userService } from "../../../services";

import { Link } from "react-router-dom";
import { Col, Row, Form, FormGroup, Alert } from "reactstrap";

import { Button } from "devextreme-react";
import Loader from "react-loaders";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";

import {
  Validator,
  RequiredRule,
  PatternRule,
  CompareRule,
} from "devextreme-react/validator";
import ValidationGroup from "devextreme-react/validation-group";

class RecoveryPassword extends React.Component {
  constructor(props) {
    super(props);

    //Reset login status
    this.props.dispatch(userActions.logout());

    this.state = {
      newPassword: "",
      passwordMode_1: "password",
      passwordMode_2: "password",
      loading: false,
      error: false,
    };
    //Referencias
    this.textBox_contraseña_REF = React.createRef();
    this.button_submit_REF = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.enterKey = this.enterKey.bind(this);
    this.passwordComparison = this.passwordComparison.bind(this);
    this.disablePaste = this.disablePaste.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  get textBox_contraseña() {
    return this.textBox_contraseña_REF.current.instance;
  }

  get button_submit() {
    return this.button_submit_REF.current.instance;
  }

  handleChange(e) {
    const { name, value } = e.event.target;
    setTimeout(() => {
      this.setState({ [name]: value });
    }, 50);
  }

  enterKey(e) {
    this.button_submit.element().click();
  }

  passwordComparison() {
    return this.textBox_contraseña.option("value");
  }

  disablePaste(e) {
    e.event.preventDefault();
  }

  onSubmit(e) {
    const res = e.validationGroup.validate();
    const { dispatch } = this.props;

    if (res.isValid) {
      const { token } = this.props.match.params;
      const newPassword = this.textBox_contraseña.option("value");

      this.setState({ loading: true });
      userService
        .changePassword(token, newPassword)
        .then((response) => {
          this.setState({ success: true, error: false });
          dispatch(userActions.login(response.Email, response.NewPassword));
        })
        .catch((error) => this.setState({ loading: false, error: true }));
    }
  }

  render() {
    const { loading, error, passwordMode_1, passwordMode_2 } = this.state;
    return (
      <Fragment>
        <div className="app-container">
          <div className="he-100 bg-animation bg-white">
            <div className="d-flex he-100 justify-content-center align-items-center">
              <Col md="6" className="mx-auto app-login-box">
                <div className="logo-LoveYourLinen mx-auto mb-3" />
                {!loading ? (
                  <div className="w-100 mx-auto modal-dialog">
                    <div
                      className={"modal-content " + (!error ? "" : "warning")}
                    >
                      <ValidationGroup>
                        <div className="modal-body">
                          <div className="modal-title text-center mb-4">
                            <h4 className="mt-2">
                              <div>Configuración de contraseña</div>
                            </h4>
                            <h6 className="mt-1">
                              <span>
                                Por favor, indique su nueva contraseña.
                              </span>
                            </h6>
                          </div>
                          {error ? (
                            <Alert className="w-100" color="danger">
                              <div className="text-center">
                                <span>
                                  Ha ocurrido un error al actualizar su
                                  contraseña.
                                </span>
                              </div>
                            </Alert>
                          ) : (
                            ""
                          )}
                          <Form>
                            <Row form>
                              <Col md={12} className="mt-3">
                                <FormGroup>
                                  <span>Contraseña</span>
                                  <TextBox
                                    ref={this.textBox_contraseña_REF}
                                    name="newPassword"
                                    stylingMode="underlined"
                                    placeholder="Introduzca contraseña..."
                                    mode={passwordMode_1}
                                    onPaste={this.disablePaste}
                                    onEnterKey={this.enterKey}
                                    onFocusOut={this.onFocusOut}
                                  >
                                    <TextBoxButton
                                      name="newPassword"
                                      location="after"
                                      options={{
                                        stylingMode: "text",
                                        tabIndex: -1,
                                        icon:
                                          passwordMode_1 === "text"
                                            ? " icon_Eye_Blocked"
                                            : " icon_Eye",
                                        onClick: () => {
                                          this.setState({
                                            passwordMode_1:
                                              passwordMode_1 === "text"
                                                ? "password"
                                                : "text",
                                          });
                                        },
                                      }}
                                    />
                                    <Validator>
                                      <RequiredRule message="Campo necesario" />
                                      <PatternRule
                                        pattern={password_Pattern}
                                        message="La contraseña debe ser de almenos 6 carácteres y contener almenos una mayúscula, una minúscula y un número."
                                        // message="<h6>La contraseña debe cumplir:</h6><ol class='mb-0'><li>Tener más de 6 carácteres.</li><li>Tener almenos una mayúscula, una minúscula.</li><li>Tener almenos un número.</li></ol>"
                                      />
                                    </Validator>
                                  </TextBox>
                                </FormGroup>
                              </Col>
                              <Col md={12} className="mt-3">
                                <FormGroup>
                                  <span>Confirmar contraseña</span>
                                  <TextBox
                                    name="confirmPassword"
                                    stylingMode="underlined"
                                    placeholder="Introduzca la contraseña de nuevo..."
                                    mode={passwordMode_2}
                                    onPaste={this.disablePaste}
                                    onEnterKey={this.enterKey}
                                  >
                                    <TextBoxButton
                                      name="confirmPassword"
                                      location="after"
                                      options={{
                                        stylingMode: "text",
                                        tabIndex: -1,
                                        icon:
                                          passwordMode_2 === "text"
                                            ? " icon_Eye_Blocked"
                                            : " icon_Eye",
                                        onClick: () => {
                                          this.setState({
                                            passwordMode_2:
                                              passwordMode_2 === "text"
                                                ? "password"
                                                : "text",
                                          });
                                        },
                                      }}
                                    />
                                    <Validator>
                                      <RequiredRule message="Campo necesario" />
                                      <CompareRule
                                        message="Las contraseñas no coinciden."
                                        comparisonTarget={
                                          this.passwordComparison
                                        }
                                      />
                                    </Validator>
                                  </TextBox>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <div className="modal-footer clearfix">
                          <div className="float-left w-100">
                            <h6 className="mb-0">
                              <Link to={`/Login`} className="text-primary">
                                Utilizar una cuenta ya existente.
                              </Link>
                            </h6>
                          </div>
                          <div className="float-right ">
                            <Button
                              ref={this.button_submit_REF}
                              width={100}
                              text="Actualizar"
                              type="default"
                              stylingMode="contained"
                              onClick={this.onSubmit}
                            />
                          </div>
                        </div>
                      </ValidationGroup>
                    </div>
                  </div>
                ) : (
                  <div className="loader-container">
                    <div className="loader-container-inner m-5">
                      <div className="text-center">
                        <Loader type="line-scale" />
                      </div>
                    </div>
                  </div>
                )}

                <div className="text-center opacity-8 mt-3">
                  <a href="https://www.polarier.com/aviso-legal/ ">
                    Copyright &copy; Polarier {new Date().getFullYear()}{" "}
                  </a>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  //FORM EDITOR
  onFocusOut(e) {
    if (!e.component.option("isValid")) {
      e.component.focus();
    }
  }

  componentDidMount() {
    const { token } = this.props.match.params;

    userService.checkRecoveryPassword_Token(token).catch((error) => {
      history.push("/");
    });

    this.textBox_contraseña.focus();
  }
}

function mapStateToProps(state) {}

const connectedRecoveryPassword = connect(mapStateToProps)(RecoveryPassword);
export default connectedRecoveryPassword;
