import React, { useEffect, useRef } from "react";

import DataGrid, {
    Column,
    Editing,
    FieldLookup,
    KeyboardNavigation,
    Lookup,
    RequiredRule,
    Sorting,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";

import $ from "jquery";

import { errorHandler, authHeader, formatNumber, getTrad } from "helpers";
import { removeCellComponent } from "components/DataGrid/Cells";
import { datasource_tblIvaNPais_Compra, datasource_tblMoneda } from "pages/Administracion/shared/model";
import {
    custom_displayExpr,
    getFormatCurrency,
    getFormatCurrencyPrecio,
    retrieveIvaValue,
} from "pages/Administracion/shared/functions";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "constants";

const datasource_tblAdmCuentaContable = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCuentaContable",
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => store_tblAdmCuentaContable_beforeSend(request),
        version: 4,
    }),
    select: ["idAdmCuentaContable", "codigo", "denominacion"],
    sort: ["codigo"],
});

const store_tblAdmCuentaContable_beforeSend = (request) => {
    request.headers = { ...authHeader() };
};

const DataGridConceptos = ({ idMoneda, dataSource, validations, updateSummary, isCompra, showIva = true }) => {
    //Variable auxiliar que evita la autogestion de los cambios realizados en el datagrid, dandonos mayor control sobre lo que se guarda y lo que no.
    //La variable en si no hace nada, y no debe hacer nada salvo que queramos hacer algo con los cambios que implique su persistencia entre renders
    let changes = [];
    const dataGridRef = useRef(null);

    useEffect(() => {
        datasource_tblAdmCuentaContable.filter(
            isCompra
                ? ["codigo", "startsWith", "6"]
                : [
                      ["codigo", "startsWith", "7"],
                      "or",
                      ["codigo", "startsWith", "43800002"],
                      "or",
                      ["codigo", "startsWith", "30000019"],
                      "or",
                      ["codigo", "startsWith", "30000034"],
                      "or",
                      ["codigo", "startsWith", "30000055"],
                      "or",
                      ["codigo", "startsWith", "30000056"],
                      "or",
                      ["codigo", "startsWith", "30000057"],
                  ]
        );
        datasource_tblAdmCuentaContable.load();
    }, []);

    // const dataGrid = dataGridRef?.current?.instance ?? null;

    const formats = {
        percent: {
            type: "percent",
            maximumFractionDigits: 0,
        },
        currencyPrecio: getFormatCurrencyPrecio(idMoneda),
        currency: getFormatCurrency(idMoneda),
        number: {
            style: "decimal",
            maximumFractionDigits: 0,
        },
    };

    const editorOptions = {
        cantidad: {
            step: 0,
            format: { type: "fixedPoint", precision: 2 /* style: "decimal", maximumFractionDigits: 2 */ },
            min: 0,
            max: 99999999,
        },
        precio: {
            step: 0,
            format: formats.currencyPrecio,
            min: 0,
            max: 9999999999,
        },
        porcentajes: {
            step: 0.01,
            format: formats.percent,
            min: 0,
            max: 1,
        },
        cuentaContable: {
            dropDownOptions: { minWidth: 500 },
        },
    };

    const onToolbarPreparing = ({ toolbarOptions }) => {
        // if(validado){
        toolbarOptions.items.unshift(
            {
                location: "before",
                template: () =>
                    $('<div id="conceptos_title" class="font-size" style="margin-top:10px" >').text(
                        getTrad("conceptos")
                    ),
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "plus",
                    text: getTrad("añadirConcepto"),
                    onClick: () => dataGridRef.current.instance.addRow(),
                },
            }
        );
    };

    useEffect(() => {
        validations.set("datagrid", checkIsValid);
    }, []);

    const checkIsValid = () => {
        let isValid = true;
        return new Promise((resolve, reject) => {
            dataGridRef.current.instance.saveEditData().done(function () {
                setTimeout(() => {
                    dataGridRef.current.instance.getVisibleRows().forEach((row) => {
                        if (row.isEditing === true) {
                            if (dataGridRef.current.instance.hasEditData()) {
                                //Esto evita un falso negativo
                                isValid = false;
                            }
                        }
                    });

                    if (isValid) {
                        resolve();
                    } else {
                        reject();
                    }
                }, 0);
            });
        });
    };

    const calculateCellValue_bruto = ({ cantidad = 0, precio = 0 }) => {
        let resultado = cantidad * precio;
        return isNaN(resultado) ? 0 : resultado;
    };

    const calculateCellValue_total = ({ cantidad = 0, precio = 0, idIvaNPais = 0, descuento = 0 }) => {
        let apartado = "venta";
        if (showIva == true) {
            apartado = "compra";
        }
        let iva = retrieveIvaValue(idIvaNPais, apartado);
        let resultado = cantidad * precio * (1 + iva) * (1 - descuento);
        return isNaN(resultado) ? 0 : resultado;
    };

    const netoSummary = (e) => {
        if (updateSummary) {
            let apartado = "venta";
            if (showIva == true) {
                apartado = "compra";
            }
            updateSummary(e, apartado);
        }
        return customSummary(e);
    };

    const customSummary = (e) => {
        const codigoMoneda = datasource_tblMoneda.items().find((m) => m.idMoneda === idMoneda)?.codigo || "EUR";
        return formatNumber(e, 2, "currency", codigoMoneda);
    };

    return (
        <DataGrid
            ref={dataGridRef}
            dataSource={dataSource}
            height={"267px"}
            width={"100%"}
            showRowLines
            columnsAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            showColumnLines={false}
            remoteOperations={false}
            onToolbarPreparing={onToolbarPreparing}
        >
            <Sorting mode={"multiple"} />
            <KeyboardNavigation enterKeyAction={"moveFocus"} enterKeyDirection={"row"} ditOnKeyPress />
            <Editing mode={"cell"} allowUpdating changes={changes} />
            <Column dataField={"descripcion"} caption={getTrad("descripcion")} dataType={"string"} minWidth={300}>
                <RequiredRule />
            </Column>
            <Column
                dataField={"idAdmCuentaContable"}
                caption={getTrad("cuentaContable")}
                dataType={"string"}
                alignment={"left"}
                width={200}
                editorOptions={editorOptions.cuentaContable}
            >
                <FieldLookup
                    dataSource={datasource_tblAdmCuentaContable.items()}
                    displayExpr={custom_displayExpr}
                    valueExpr={"idAdmCuentaContable"}
                />
                <RequiredRule />
            </Column>
            <Column
                dataField={"cantidad"}
                caption={getTrad("cantidad")}
                dataType={"number"}
                // sortOrder={'desc'}
                width={100}
                editorOptions={editorOptions.cantidad}
            >
                <RequiredRule />
            </Column>
            <Column
                dataField={"precio"}
                caption={getTrad("precio")}
                dataType={"number"}
                width={100}
                format={formats.currencyPrecio}
                editorOptions={editorOptions.precio}
            >
                <RequiredRule />
            </Column>
            <Column
                dataField={"descuento"}
                caption={getTrad("descuento")}
                dataType={"number"}
                width={100}
                format={formats.percent}
                editorOptions={editorOptions.porcentajes} //quizás poner algunas opciones
            />
            {showIva && (
                <Column
                    dataField={"idIvaNPais"}
                    caption={getTrad("iva")}
                    dataType={"number"}
                    width={100}
                    format={formats.percent}
                    editorOptions={editorOptions.porcentajes}
                >
                    <RequiredRule />
                    <Lookup
                        dataSource={datasource_tblIvaNPais_Compra.items()}
                        valueExpr={"idIvaNPais"}
                        displayExpr={"valor"}
                    />
                </Column>
            )}
            <Column
                caption={getTrad("bruto")}
                dataType={"number"}
                width={100}
                format={formats.currency}
                calculateCellValue={calculateCellValue_bruto} //cambiar
                allowEditing={false}
            />
            <Column
                caption={getTrad("total")}
                dataType={"number"}
                width={150}
                format={formats.currency}
                calculateCellValue={calculateCellValue_total} //cambiar
                allowEditing={false}
            />
            <Column
                caption={" "}
                width={30}
                alignment={"center"}
                cssClass={"p-0"}
                cellComponent={removeCellComponent}
            />
            <Summary>
                <TotalItem column={getTrad("total")} summaryType="sum" displayFormat={netoSummary} />
            </Summary>
        </DataGrid>
    );
};

export default DataGridConceptos;
