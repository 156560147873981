import React, { Fragment, createContext, useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { loadPanelActions } from "../../../actions";
import notify from "devextreme/ui/notify";

import DataGridAlbaranesCompra from "./components/DataGridAlbaranesCompra";
import FormAlbaranCompra from "./components/FormAlbaranCompra";
import PageTitle from "layout/AppMain/PageTitle";
import { dxMensajePregunta, getNombreFormulario, getTrad } from "helpers";
import { Button, Popup, ScrollView } from "devextreme-react";
import { ToolbarItem as ToolbarItem_Popup } from "devextreme-react/popup";
import { getClearAlbaran, payloadBuilder } from "./model";
import {
    refreshStaticDatasources,
    datasource_tblAdmAlbaranCompra,
    empresaPolarierSel,
    datasource_tblAdmPedidoProveedor,
} from "../shared/model";

import SelectorEmpresa from "../shared/components/SelectorEmpresa";

export const MainScreenContext = createContext({ mainScreen: true });

const AlbaranCompra = ({ idioma, user, loadPanel_show, loadPanel_hide, updateSummary }) => {
    const ScreenContext = useContext(MainScreenContext);

    useEffect(() => {
        if (!ScreenContext.mainScreen) {
            ScreenContext.validations.set("datagrid", () => true);
        }
    }, []);

    const heading = getNombreFormulario({ props: { idioma, user } });
    const [albaranCompraSel, setAlbaranCompraSel] = useState({
        tblArticuloNAdmAlbaranCompra: [],
    });
    const [adminItemPopup_visibility, setAdminItemPopup_visibility] = useState(false);
    const [empresaSel, setEmpresaSel] = useState(empresaPolarierSel);
    const formAlbaranCompra_REF = useRef();
    const savedData = useRef();
    const validations = useRef([]);

    useEffect(() => {
        if (empresaSel.idEmpresaPolarier == null) {

            

            const empresaAux = {
                idEmpresaPolarier: user.tblEmpresaPolarierNUsuario[0]?.idEmpresaPolarier,
                idPais: user.tblEmpresaPolarierNUsuario[0]?.idPais,
            };
            setEmpresaSel(empresaAux);
        }
        return () => {
            validations.current = [];
        };
    }, []);

    useEffect(() => {
        savedData.current = { ...albaranCompraSel };
    }, [albaranCompraSel]);

    const openPopup = () => {
        setAdminItemPopup_visibility(true);
    };

    const popup_onHiding = (e) => {
        if (formAlbaranCompra_REF.current.hasEditData(savedData.current)) {
            e.cancel = true;
            dxMensajePregunta("Hay cambios sin guardar que se van a perder. ¿Desea salir sin guardar? ", [
                [
                    getTrad("aceptar"),
                    () => {
                        closePopup();
                    },
                    "danger",
                ],
                [getTrad("cancelar"), () => {}],
            ]);
        } else {
            closePopup();
        }
    };

    const closePopup = () => {
        datasource_tblAdmAlbaranCompra.reload();
        loadPanel_hide();
        setAdminItemPopup_visibility(false);
    };

    const getTitle = () => {
        if (albaranCompraSel.idAdmAlbaranCompra) {
            const string = getTrad("modificandoAlbaranCompra") + " - " + albaranCompraSel.codigo;
            return string;
        } else {
            return getTrad("nuevoAlbaranCompra");
        }
    };

    const getButtonText = () => {
        if (albaranCompraSel.idAdmAlbaranCompra) {
            return getTrad("actualizar");
        } else {
            return getTrad("guardar");
        }
    };

    const onContentReady = (e) => {
        e.component.content().addClass("admScrollPopup");
    };

    const selectorEmpresa_onSelectionChanged = (e) => {
        loadPanel_show();
        refreshStaticDatasources(e.selectedItem);
        setEmpresaSel(e.selectedItem);
    };

    const reloadData = () => {
        datasource_tblAdmAlbaranCompra.reload();
        datasource_tblAdmPedidoProveedor.reload();
    };

    return (
        <>
            {/* <ConstantData.Provider value={datasources}> */}
            {ScreenContext.mainScreen && (
                <PageTitle
                    heading={heading}
                    postHeading={
                        <SelectorEmpresa value={empresaSel} onSelectionChanged={selectorEmpresa_onSelectionChanged} />
                    }
                />
            )}
            <div className="media-body">
                <Popup
                    showTitle={true}
                    title={getTitle()}
                    visible={adminItemPopup_visibility}
                    showCloseButton={true}
                    closeOnOutsideClick={false}
                    height="90%"
                    onHiding={popup_onHiding}
                    onContentReady={onContentReady}
                >
                    <ScrollView>
                        <FormAlbaranCompra
                            ref={formAlbaranCompra_REF}
                            albaranCompraSel={albaranCompraSel}
                            validations={validations.current}
                            reloadData={reloadData}
                            closePopup={closePopup}
                        />
                    </ScrollView>
                    <ToolbarItem_Popup toolbar="bottom" location="after">
                        <Button
                            text={getButtonText()}
                            type="success"
                            onClick={formAlbaranCompra_REF.current?.onSubmit_FormAlbaranCompra}
                            useSubmitBehavior={false}
                            disabled={albaranCompraSel.isCerrado}
                        />
                    </ToolbarItem_Popup>
                </Popup>
                <div className={`${ScreenContext.mainScreen ? "formContainer scrollbar-container" : ""}`}>
                    <DataGridAlbaranesCompra
                        datasource={datasource_tblAdmAlbaranCompra}
                        setAlbaranCompraSel={setAlbaranCompraSel}
                        updateSummary={updateSummary}
                        openPopup={openPopup}
                        reloadData={reloadData}
                        idEmpresaPolarier={empresaSel.idEmpresaPolarier}
                        loadPanel_hide={loadPanel_hide}
                    />
                </div>
            </div>
            {/* </ConstantData.Provider> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlbaranCompra);
