import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";
import { connect } from "react-redux";

import DxForm, { GroupItem, Item } from "devextreme-react/form";
import { formatDate_noTime_parameter, getTrad } from "helpers";

import { Card, CardBody } from "reactstrap";

import {
    datasource_tblAdmTipoElemento,
    datasource_tblAdmTipoFactura,
    empresaPolarierSel,
    sharedEditorOptions,
    datasource_tblAdmTipoNCF,
    reloadCuentasBancarias,
    datasource_tblCliente,
    datasource_tblCondicionPagoCobro,
    datasource_tblViasPagoCobro,
} from "pages/Administracion/shared/model";
import { generateCode, getDefaultMonedaId, handleDefaultFieldsCliente } from "pages/Administracion/shared/functions";
import { Button } from "devextreme-react";
import AlbaranVenta, { MainScreenContext } from "pages/Administracion/AlbaranVenta";
import DataGridConceptos from "components/Administracion/DataGridConceptos";
import { payloadBuilder } from "pages/Administracion/AlbaranVenta/model";
import TablaResumen from "pages/Administracion/shared/components/TablaResumen";
import CargarAlbaran from "pages/Administracion/shared/components/CargarAlbaran";
import CentroCosteElementoPepComponent from "pages/Administracion/shared/components/CentroCosteElementoPepComponent";
import TasaCambioTipoCambioComponent from "pages/Administracion/shared/components/TasaCambioTipoCambioComponent";
import CancelarFacturaComponent from "pages/Administracion/shared/components/CancelarFacturaComponent";
import { getClearFactura } from "pages/Administracion/FacturaVenta/model";
import SelectorFacturaVentaComponent from "./SelectorFacturaVentaComponent";
import RetencionComponent from "./RetencionComponent";
import { getTasaCambio } from "helpers/tasaCambio";
import { idsAdmTipoCambio } from "constants/enums/Administracion/tblAdmTipoCambio";

const Form = forwardRef(({ formData = {}, setIdMoneda, validations, idMoneda, userCantEdit }, ref) => {
    const [cargarAlbaranPopup_visibility, setCargarAlbaranPopup_visibility] = useState(false);
    const [codigoElemento, setCodigoElemento] = useState();
    const [ncf, setNCF] = useState();
    const [isCuentaBancariaRequired, setIsCuentaBancariaRequired] = useState(true);

    const getText = (clave) => ({ text: getTrad(clave) });
    const previousCode = useRef();
    const previousNCF = useRef();
    const formSuperiorRef = useRef();
    const formInferiorRef = useRef();
    const summaryFormRef = useRef();
    const CCEPFormRef = useRef();
    const TCTCFormRef = useRef();
    const selectorFacturaVenta_REF = useRef();
    const retencionComponent_REF = useRef();
    const colCount = 5;
    const multiplier = 3;

    useImperativeHandle(ref, () => ({
        getFormData: () => formSuperiorRef.current.instance.option("formData"),
        hasEditData,
    }));

    useEffect(() => {
        validations.set("forms", checkIsValid);
    }, []);

    useEffect(() => {
        if (formData.codigo != null) {
            previousCode.current = formData.codigo;
        }
        setCodigoElemento(formData.codigo);
        if (formData.NCF != null) {
            previousNCF.current = formData.NCF;
        }
        setNCF(formData.NCF);
        formSuperiorRef.current.instance.itemOption("idMoneda", "disabled", formData?.idReferenciaFacturaVenta != null);
    }, [formData]);

    const setCodigoElemento_Wrapper = (code) => {
        const prefijo = code.substring(0, 2);
        const fecha = code.substring(2, 6);
        const prevPrefijo = previousCode.current?.substring(0, 2);
        const prevFecha = previousCode.current?.substring(2, 6);
        if (prefijo == prevPrefijo && fecha == prevFecha) {
            formData.codigo = previousCode.current;
            setCodigoElemento(code);
        } else {
            formData.codigo = code;
            setCodigoElemento(code);
        }
    };

    const setCodigoNCF_Wrapper = (code) => {
        if (formData.NCF != null) {
            if (formData.NCF.substring(0, 3) !== code.substring(0, 3)) {
                if (previousNCF.current != null && code.substring(0, 3) === previousNCF.current.substring(0, 3)) {
                    formData.NCF = previousNCF.current;
                    setNCF(code);
                } else {
                    formData.NCF = code;
                    setNCF(code);
                }
            }
        } else {
            formData.NCF = code;
            setNCF(code);
        }
    };

    const checkIsValid = () => {
        const isCCEPFormValidated = CCEPFormRef?.current?.checkIsValid();
        const TCTCFormValidated = TCTCFormRef?.current?.checkIsValid();
        const isSuperiorFormValidated = formSuperiorRef.current.instance.validate();
        const isInferiorFormValidated = formInferiorRef.current.instance.validate();
        const isNotaCredito = formSuperiorRef.current.instance.option("formData").idAdmTipoNCF == 2 ? true : false;
        const isFormValidated =
            isSuperiorFormValidated.isValid &&
            isInferiorFormValidated.isValid &&
            isCCEPFormValidated.isValid &&
            TCTCFormValidated.isValid;
        return new Promise((resolve, reject) => {
            if (isFormValidated) {
                if (isNotaCredito) {
                    if (formSuperiorRef.current.instance.option("formData").idReferenciaFacturaVenta != null) {
                        resolve();
                    } else {
                        selectorFacturaVenta_REF.current.setVisibility(true);
                        reject("Las notas de crédito se deben asociar a una factura");
                    }
                } else {
                    resolve();
                }
            }
            reject();
        });
    };

    const cargarAlbaranes = () => {
        setCargarAlbaranPopup_visibility(true);
    };

    const setDefaultConfiguration = (idAdmCliente) => {
        const cliente = datasource_tblCliente.items().find((x) => x.idAdmCliente === idAdmCliente);

        if (cliente) {
            const formSuperior = formSuperiorRef.current.instance;
            const formInferior = formInferiorRef.current.instance;

            const fieldsCentroCosteElementoPep = ["idAdmCentroCoste", "idAdmElementoPEP"];

            handleDefaultFieldsCliente(fieldsCentroCosteElementoPep, CCEPFormRef?.current, cliente);

            formSuperior.updateData("codigoRetencion", cliente.codigoRetencion ? cliente.codigoRetencion * 100 : null);

            const fieldsFormSuperior = ["tipoRetencion", "idMoneda", "idIvaNPais"];

            handleDefaultFieldsCliente(fieldsFormSuperior, formSuperior, cliente);

            const fieldsFormInferior = ["idAdmFormaCobro", "idAdmCondicionPago"];

            handleDefaultFieldsCliente(fieldsFormInferior, formInferior, cliente);

            if (cliente.idCuentaBancaria && cliente.idMoneda) {
                reloadCuentasBancarias(cliente.idMoneda).then(() => {
                    formInferior.updateData("idCuentaBancaria", cliente.idCuentaBancaria);
                });
            }
        }
    };

    const labels = {
        codigo: getText("codigo"),
        idAdmCliente: getText("cliente"),
        fecha: getText("fecha"),
        fechaVencimiento: getText("fechaVencimiento"),
        idTipoFactura: getText("tipoFactura"),
        idTipoAlbaran: getText("tipoAlbaran"),
        idMoneda: getText("moneda"),
        idAdmFormaCobro: getText("formaPago"),
        idAdmCondicionPago: getText("condicionesPagoCobro"),
        idCuentaBancaria: getText("cuentaBancaria"),
        descuento: getText("descuento"),
        comentario: getText("comentario"),
        idIncoterm: getText("incoterms"),
        numPedido: getText("numPedido"),
        observaciones: getText("observaciones"),
        bruto: { text: "Total Bruto" }, //getText("totalBruto"),
        neto: getText("neto"),
        idAdmTipoNCF: getText("tipoNCF"),
        NCF: getText("NCF"),
    };

    const editorOptions = {
        idAdmCliente: {
            ...sharedEditorOptions.idAdmCliente,
            readOnly: formData.idAdmAlbaranVenta?.length > 0 && userCantEdit,
        },
        idAdmTipoNCF: {
            dataSource: datasource_tblAdmTipoNCF,
            valueExpr: "idAdmTipoNCF",
            displayExpr: "denominacion",
            onValueChanged: (e) =>
                e.value != null &&
                generateCode(
                    formSuperiorRef.current.instance.option("formData").fecha ?? new Date(),
                    setCodigoNCF_Wrapper,
                    e.value,
                    "NCF",
                    empresaPolarierSel.idEmpresaPolarier,
                    true
                ),
        },
        NCF: {
            readOnly: true,
        },
        idTipoFactura: {
            dataSource: datasource_tblAdmTipoFactura, //el tipo de Factura se determina por el tipo de factura
            valueExpr: "idTipoFactura",
            displayExpr: "denominacion",
            placeholder: getTrad("tipoFactura"),
            readOnly: formData.idAdmAlbaranVenta?.length > 0 && userCantEdit,
        },
        idTipoAlbaran: {
            dataSource: datasource_tblAdmTipoElemento, //el tipo de Factura se determina por el tipo de factura
            valueExpr: "idAdmTipoElemento",
            displayExpr: "denominacion",
            onValueChanged: (e) =>
                e.value != null &&
                generateCode(
                    formSuperiorRef.current.instance.option("formData").fecha ?? new Date(),
                    setCodigoElemento_Wrapper,
                    e.value,
                    "facturaVenta"
                ),
            placeholder: getTrad("tipoAlbaran"),
            readOnly: formData.idAdmAlbaranVenta?.length > 0 && userCantEdit,
        },
        idMoneda: {
            ...sharedEditorOptions.idMoneda,
            readOnly: formData.idAdmAlbaranVenta?.length > 0 && userCantEdit,
            onValueChanged: (e) => {
                reloadCuentasBancarias(e.value);
            },
        },
        cargarAlbaranes: {
            text: getTrad("asociarAlbaranes"),
            disabled: formData.isCerrado && userCantEdit,
            onClick: cargarAlbaranes,
        },
        idAdmFormaCobro: {
            ...sharedEditorOptions.idAdmFormaPago,
            onValueChanged: (e) => {
                setIsCuentaBancariaRequired(
                    datasource_tblViasPagoCobro.items().find((x) => x.idAdmFormaPago === e.value)
                        ?.isCuentaBancariaRequired
                );
            },
        },
    };

    const onFieldDataChanged = (e) => {
        if (e.dataField === "idMoneda") {
            setIdMoneda(e.value);
            TCTCFormRef.current?.recalcularTasaCambio();
        }
        if (e.dataField === "fecha") {
            setFechaVencimiento();
            TCTCFormRef.current?.recalcularTasaCambio();
            formData.idTipoAlbaran != null &&
                generateCode(e.value, setCodigoElemento_Wrapper, formData.idTipoAlbaran, "facturaVenta");
        }
        if (e.dataField === "idAdmCondicionPago") {
            setFechaVencimiento();
        }
        if (e.dataField === "idAdmCliente") {
            setDefaultConfiguration(e.value);
            clearFacturaAsociada();
            setFechaVencimiento();
        }
        if (e.dataField === "idTipoFactura" && e.value === 4) {
            retencionComponent_REF.current.setComponentDisabled(true);

            const form = formSuperiorRef.current.instance;
            form.option("formData").tipoRetencion = null;
            form.option("formData").codigoRetencion = null;
        } else if (e.dataField === "idTipoFactura" && formData.idTipoFactura !== 4) {
            retencionComponent_REF.current.setComponentDisabled(false);
            asociarFactura({});
        }
        if ((e.dataField === "idTipoFactura" && e.value === 4) || (e.dataField == "idAdmTipoNCF" && e.value === 2)) {
            selectorFacturaVenta_REF.current.setVisibility(true);
        } else if (e.dataField === "idTipoFactura" || e.dataField === "idAdmTipoNCF") {
            if (formData.idAdmTipoNCF != 2 && formData.idTipoFactura != 4) {
                clearFacturaAsociada();
                selectorFacturaVenta_REF.current.setVisibility(false);
            }
        }
    };

    const setFechaVencimiento = () => {
        if (formData.fecha == null) return;

        const condicionPago = datasource_tblCondicionPagoCobro
            .items()
            .find((x) => x.idAdmCondicionPago === formData.idAdmCondicionPago);

        if (!condicionPago || (!condicionPago?.numDiasPago && condicionPago?.numDiasPago !== 0)) return;

        // Nueva instancia de la fecha para no modificarla
        let newDate = new Date(formData.fecha);

        newDate = new Date(newDate.setDate(newDate.getDate() + condicionPago.numDiasPago));

        const cliente = datasource_tblCliente.items().find((x) => x.idAdmCliente === formData.idAdmCliente);
        const formInstance = formSuperiorRef.current.instance;

        if (!cliente || !cliente?.diaPago) {
            return formInstance.updateData("fechaVencimiento", newDate);
        }

        const ultimoDiaDelMes = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();
        // Si el diaPago es mayor al ultimo dia del mes, se ajusta al ultimo dia del mes
        if (cliente.diaPago > ultimoDiaDelMes) {
            newDate = new Date(newDate.getFullYear(), newDate.getMonth(), ultimoDiaDelMes);
        } else {
            if (newDate.getDate() > cliente.diaPago) {
                // Mover al próximo mes si newDate ya pasó el diaPago
                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, cliente.diaPago);
            } else if (newDate.getDate() < cliente.diaPago) {
                // Ajustar al diaPago del mismo mes si newDate es antes del diaPago
                newDate.setDate(cliente.diaPago);
            }
            // Si newDate es exactamente el diaPago, no se necesita ajuste
        }

        formInstance.updateData("fechaVencimiento", newDate);
    };

    const clearFacturaAsociada = () => {
        formSuperiorRef.current.instance.option("formData").idReferenciaFacturaVenta = null;
        formSuperiorRef.current.instance.option("formData").idReferenciaFacturaVentaNavigation = null;
        selectorFacturaVenta_REF.current.clearFacturaAsociada();
    };

    const [contextValue, setContextValue] = useState({
        mainScreen: false,
        validations,
        updateSummary: summaryFormRef?.current?.updateSummary,
    });

    const setAlbaran = (data) => {
        const form = formSuperiorRef.current.instance;

        form.option("formData").idAdmCliente = data.idAdmCliente;
        form.option("formData").idAdmCentroCoste = data.idAdmCentroCoste;
        form.option("formData").idAdmElementoPEP = data.idAdmElementoPEP;
        form.option("formData").idMoneda = data.idMoneda;
        form.option("formData").tasaCambio = data.tasaCambio;
        form.option("formData").idAdmTipoCambio = data.idAdmTipoCambio;
        form.option("formData").idAdmAlbaranVenta = data.idAdmAlbaranVenta;
        form.option("formData").idTipoFactura = data.idTipoFactura;
        form.option("formData").idTipoAlbaran = data.idTipoAlbaran;
        form.option("formData").idIvaNPais = data.idIvaNPais;
        form.option("formData").idAdmFormaCobro = data.idAdmFormaPago;
        form.option("formData").idIncoterm = data.idIncoterm;
        setDefaultConfiguration(data.idAdmCliente);

        if (data.idTipoAlbaran !== undefined) {
            const fecha = formSuperiorRef.current.instance.option("formData").fecha ?? new Date();
            generateCode(fecha, setCodigoElemento_Wrapper, data.idTipoAlbaran, "facturaVenta");
        }

        if (data.idMoneda !== contextValue.idMoneda) {
            let obj = { ...contextValue };
            obj.idMoneda = data.idMoneda;
            setContextValue(obj);
        }
        setIdMoneda(data.idMoneda);
    };

    const asociarFactura = (factura) => {
        formSuperiorRef.current.instance.option("formData").idReferenciaFacturaVenta = factura.idReferenciaFacturaVenta;
        formSuperiorRef.current.instance.option("formData").idMoneda = factura?.idMoneda ?? getDefaultMonedaId();
        formSuperiorRef.current.instance.itemOption("idMoneda", "disabled", factura?.idMoneda != null);
        TCTCFormRef.current.setTasaReferenciaFactura(factura);
        formSuperiorRef.current.instance.repaint();
    };

    const updateState = (state) => {
        formSuperiorRef.current.instance.option("formData").idAdmFactura_Estado = state;
    };

    const hasEditData = (referenceFormData = getClearFactura()) => {
        let editData = true;
        const {
            idAdmCliente,
            idAdmCentroCoste,
            idAdmElementoPEP,
            idMoneda,
            tasaCambio,
            idAdmTipoCambio,
            idAdmAlbaranVenta,
            idTipoFactura,
            // idTipoAlbaran,
            idIvaNPais,
            idAdmFormaCobro,
            idIncoterm,
            comentario,
            fecha,
            fechaVencimiento,
            numPedido,
            idAdmCondicionPago,
            idCuentaBancaria,
            observaciones,
        } = formSuperiorRef.current.instance.option("formData");
        if (
            idAdmCliente == referenceFormData.idAdmCliente &&
            idAdmCentroCoste == referenceFormData.idAdmCentroCoste &&
            idAdmElementoPEP == referenceFormData.idAdmElementoPEP &&
            idMoneda == referenceFormData.idMoneda &&
            tasaCambio == referenceFormData.tasaCambio &&
            idAdmTipoCambio == referenceFormData.idAdmTipoCambio &&
            idAdmAlbaranVenta.length == referenceFormData.idAdmAlbaranVenta.length &&
            idTipoFactura == referenceFormData.idTipoFactura &&
            // idTipoAlbaran == referenceFormData.idTipoAlbaran &&
            idIvaNPais == referenceFormData.idIvaNPais &&
            idAdmFormaCobro == referenceFormData.idAdmFormaCobro &&
            idIncoterm == referenceFormData.idIncoterm &&
            comentario == referenceFormData.comentario &&
            fecha == referenceFormData.fecha &&
            fechaVencimiento == referenceFormData.fechaVencimiento &&
            numPedido == referenceFormData.numPedido &&
            idAdmCondicionPago == referenceFormData.idAdmCondicionPago &&
            idCuentaBancaria == referenceFormData.idCuentaBancaria &&
            observaciones == referenceFormData.observaciones
        ) {
            editData = false;
        }
        return editData;
    };

    const isSelectorVisible = () => {
        return formData.idAdmTipoNCF === 2 || formData.idTipoFactura === 4;
    };

    return (
        <>
            <Card
                style={{
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    borderWidth: 0.1,
                    overflow: "visible",
                }}
            >
                <CardBody>
                    <div className={"d-flex"}>
                        <Button {...editorOptions.cargarAlbaranes} />
                        <div className="ml-4">
                            <SelectorFacturaVentaComponent
                                ref={selectorFacturaVenta_REF}
                                visible={isSelectorVisible()}
                                formData={formData}
                                asociarFactura={asociarFactura}
                            />
                        </div>
                        <div className="ml-auto ">
                            <CancelarFacturaComponent formData={formData} updateState={updateState} />
                        </div>
                    </div>
                    <div className={"d-flex mt-4"}>
                        <DxForm
                            ref={formSuperiorRef}
                            formData={formData}
                            onFieldDataChanged={onFieldDataChanged}
                            colCount={colCount * multiplier}
                            height={"100%"}
                            width={"100%"}
                            labelLocation={"top"}
                            readOnly={formData.isCerrado && userCantEdit}
                        >
                            <Item
                                dataField={"idTipoFactura"}
                                label={labels.idTipoFactura}
                                editorOptions={editorOptions.idTipoFactura}
                                editorType={"dxSelectBox"}
                                colSpan={1 * multiplier - 1}
                                isRequired
                            />
                            <Item
                                dataField={"idTipoAlbaran"}
                                label={labels.idTipoAlbaran}
                                editorOptions={editorOptions.idTipoAlbaran}
                                editorType={"dxSelectBox"}
                                colSpan={1 * multiplier - 1}
                                isRequired
                            />
                            <Item
                                dataField={"codigo"}
                                label={labels.codigo}
                                editorOptions={sharedEditorOptions.codigo}
                                editorType={"dxTextBox"}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"fecha"}
                                label={labels.fecha}
                                editorOptions={sharedEditorOptions.fechaCreacion}
                                editorType={"dxDateBox"}
                                colSpan={2}
                                isRequired
                            />
                            <Item colSpan={1 * multiplier}>
                                <TasaCambioTipoCambioComponent
                                    keyExpr={"idAdmFacturaVenta"}
                                    ref={TCTCFormRef}
                                    formData={formData}
                                    userCantEdit={userCantEdit}
                                />
                            </Item>
                            <Item dataField={"numPedido"} label={labels.numPedido} colSpan={1 * multiplier} />
                            <Item
                                dataField={"fechaVencimiento"}
                                label={labels.fechaVencimiento}
                                editorOptions={sharedEditorOptions.fechaCreacion}
                                editorType={"dxDateBox"}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"idMoneda"}
                                label={labels.idMoneda}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idMoneda}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"idAdmCliente"}
                                label={labels.idAdmCliente}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idAdmCliente}
                                colSpan={2 * multiplier}
                                isRequired
                            />
                            <Item colSpan={2 * multiplier - 1}>
                                <RetencionComponent
                                    ref={retencionComponent_REF}
                                    formData={formData}
                                    readOnly={formData.isCerrado && userCantEdit}
                                />
                            </Item>
                            <Item colSpan={2 * multiplier}>
                                <CentroCosteElementoPepComponent
                                    ref={CCEPFormRef}
                                    formData={formData}
                                    readOnly={formData?.idAdmAlbaranVenta?.length > 0 ? true : false}
                                    userCantEdit={userCantEdit}
                                />
                            </Item>
                            <Item
                                dataField={"idAdmTipoNCF"}
                                label={labels.idAdmTipoNCF}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idAdmTipoNCF}
                                colSpan={1 * multiplier - 1}
                                isRequired
                                visible={empresaPolarierSel.idEmpresaPolarier === 4} // 4 = DOMINICANA
                            />
                            <Item
                                dataField={"NCF"}
                                label={labels.NCF}
                                editorOptions={editorOptions.NCF}
                                colSpan={1 * multiplier}
                                isRequired
                                visible={empresaPolarierSel.idEmpresaPolarier === 4} // 4 = DOMINICANA
                            />
                        </DxForm>
                    </div>
                </CardBody>
            </Card>

            <div className={"d-flex mt-4"}>
                <Card
                    style={{
                        width: "100%",
                        height: "302px",
                        margin: "auto",
                        borderWidth: 0.1,
                        overflow: "hidden",
                    }}
                >
                    <CardBody>
                        <MainScreenContext.Provider
                            value={{
                                ...contextValue,
                                idMoneda,
                                datasource:
                                    formData.idAdmAlbaranVenta != null
                                        ? formData.idAdmAlbaranVenta.map((x) => payloadBuilder(x, false))
                                        : null,
                            }}
                        >
                            {formData.idAdmAlbaranVenta?.length > 0 ? (
                                <AlbaranVenta updateSummary={summaryFormRef?.current?.updateSummary} />
                            ) : (
                                <DataGridConceptos
                                    idMoneda={idMoneda}
                                    dataSource={formData.tblAdmConceptoVenta}
                                    validations={validations}
                                    isCompra={false}
                                    showIva={false}
                                    updateSummary={summaryFormRef?.current?.updateSummary}
                                />
                            )}
                        </MainScreenContext.Provider>
                    </CardBody>
                </Card>
            </div>
            <div className={"d-flex mt-4"} style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    style={{
                        alignItems: "stretch",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <Card
                        style={{
                            width: "80%",
                            height: "100%",
                            marginLeft: 0,
                            marginTop: "auto",
                            marginBottom: "auto",
                            borderWidth: 0.1,
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <DxForm
                                ref={formInferiorRef}
                                formData={formData}
                                onFieldDataChanged={onFieldDataChanged}
                                colCount={4 * multiplier}
                                height={"100%"}
                                width={"100%"}
                                labelLocation={"top"}
                                readOnly={formData.isCerrado && userCantEdit}
                            >
                                <GroupItem colSpan={2 * multiplier} colCount={2 * multiplier} alignItemLabels={true}>
                                    <Item
                                        dataField={"idIncoterm"}
                                        label={labels.idIncoterm}
                                        editorType={"dxSelectBox"}
                                        editorOptions={sharedEditorOptions.idIncoterm}
                                        colSpan={1 * multiplier}
                                    />
                                    <Item
                                        dataField={"idAdmFormaCobro"}
                                        label={labels.idAdmFormaCobro}
                                        editorType={"dxSelectBox"}
                                        editorOptions={editorOptions.idAdmFormaCobro}
                                        colSpan={1 * multiplier}
                                        isRequired
                                    />
                                    <Item
                                        dataField={"idAdmCondicionPago"}
                                        label={labels.idAdmCondicionPago}
                                        editorType={"dxSelectBox"}
                                        editorOptions={sharedEditorOptions.idAdmCondicionPago}
                                        colSpan={1 * multiplier}
                                        isRequired
                                    />
                                    <Item
                                        dataField={"idCuentaBancaria"}
                                        label={labels.idCuentaBancaria}
                                        editorType={"dxSelectBox"}
                                        editorOptions={sharedEditorOptions.idCuentaBancaria}
                                        colSpan={1 * multiplier}
                                        isRequired={isCuentaBancariaRequired}
                                    />
                                </GroupItem>
                                <GroupItem colSpan={2 * multiplier} colCount={1 * multiplier} alignItemLabels={true}>
                                    <Item
                                        dataField={"comentario"}
                                        label={labels.comentario}
                                        editorType={"dxTextBox"}
                                        editorOptions={{ maxLength: 18 }}
                                        colSpan={1 * multiplier}
                                        isRequired
                                    />
                                    <Item
                                        dataField={"observaciones"}
                                        label={labels.observaciones}
                                        editorType={"dxTextArea"}
                                        colSpan={1 * multiplier}
                                    />
                                </GroupItem>
                            </DxForm>
                        </CardBody>
                    </Card>
                    <Card
                        style={{
                            width: "18%",
                            height: "100%",
                            marginRight: 0,
                            marginLeft: "auto",
                            borderWidth: 0.1,
                            borderColor: "lightgray",
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <TablaResumen
                                ref={summaryFormRef}
                                formData={formData}
                                idMoneda={idMoneda}
                                showIva={true}
                                userCantEdit={userCantEdit}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
            <CargarAlbaran
                apartado={"Venta"}
                idAdmFactura={formData.idAdmFacturaVenta}
                albaranesSeleccionados={formData.idAdmAlbaranVenta}
                cargarAlbaranPopup_visibility={cargarAlbaranPopup_visibility}
                setCargarAlbaranPopup_visibility={setCargarAlbaranPopup_visibility}
                setAlbaran={setAlbaran}
                hasEditData={hasEditData}
            />
        </>
    );
});

export default Form;
