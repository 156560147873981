import { connect } from "react-redux";
import { Component } from "react";
import $ from "jquery";

import { errorHandler, authHeader, getTrad } from "helpers";

import { connectionConstants } from "constants";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";

import Box, { Item as ItemBox } from "devextreme-react/box";
import { List } from "devextreme-react";

class SelectorDenoPrenda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            familiaSel: null,
            tipoPrendaSel: null,
            datasource_dxList_familia: null,
            datasource_dxList_tipoPrenda: null,
            datasource_dxList_denoPrenda: null,
        };
    }

    // #region Lifecycle

    componentDidMount() {
        this.datasource_datos_tblFamilia.load().then((data) => this.setState({ datasource_dxList_familia: data }));
    }

    componentDidUpdate(prevProps) {
        const { denoPrendaSel: prevDenoPrendaSel } = prevProps;
        const { denoPrendaSel } = this.props;
        if (denoPrendaSel == null && prevDenoPrendaSel !== denoPrendaSel) {
            this.setState({
                familiaSel: null,
                tipoPrendaSel: null,
                datasource_dxList_tipoPrenda: null,
                datasource_dxList_denoPrenda: null,
            });
        }
        if (denoPrendaSel != null && (prevDenoPrendaSel !== denoPrendaSel || typeof denoPrendaSel === "number")) {
            const { datasource_dxList_familia } = this.state;
            for (const familia of datasource_dxList_familia) {
                for (const tipoPrenda of familia.tblTipoPrenda) {
                    for (const denoPrenda of tipoPrenda.tblDenoPrenda) {
                        if (denoPrenda.idDenoPrenda === denoPrendaSel) {
                            this.setState(
                                {
                                    familiaSel: familia,
                                    tipoPrendaSel: tipoPrenda,
                                    datasource_dxList_tipoPrenda: familia.tblTipoPrenda,
                                    datasource_dxList_denoPrenda: tipoPrenda.tblDenoPrenda,
                                },
                                () =>
                                    this.props.onSelectionChanged({
                                        familiaSel: familia,
                                        tipoPrendaSel: tipoPrenda,
                                        denoPrendaSel: denoPrenda,
                                    })
                            );
                            return;
                        }
                    }
                }
            }
        }
    }

    // #endregion

    // #region DataSource

    datasource_datos_tblFamilia = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblFamilia",
            key: "idFamilia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        expand: [
            "tblTipoPrenda($orderby = codigo;$expand=tblDenoPrenda($orderby = codigo; $expand=  tblTraduccion($select=" +
                this.props.idioma.codigo +
                ")), tblTraduccion($select=" +
                this.props.idioma.codigo +
                ")), tblTraduccion($select=" +
                this.props.idioma.codigo +
                ")",
        ],
        sort: ["codigo"],
        postProcess: function (data) {
            $.each(data, function (index, familia) {
                $.each(familia.tblTipoPrenda, function (index, tipoPrenda) {
                    tipoPrenda.tblDenoPrenda = $.grep(tipoPrenda.tblDenoPrenda, function (denoPrenda) {
                        if (denoPrenda.codigo === "00" && tipoPrenda.tblDenoPrenda.length > 1) {
                            //Quitamos el SIN ASIGNAR con más denoPrendas
                            return false;
                        }
                        return true;
                    });
                });
            });
            return data;
        },
    });

    // #endregion

    render() {
        return (
            <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                <ItemBox ratio={1}>
                    <Box direction="col" align="space-around" crossAlign="stretch" height="100%" width="100%">
                        <ItemBox baseSize={40}>
                            <div className="font-size">{getTrad("familia")}</div>
                        </ItemBox>
                        <ItemBox ratio={1}>
                            <List
                                items={this.state.datasource_dxList_familia}
                                pageLoadMode="scrollBottom"
                                selectionMode="single"
                                height="100%"
                                keyExpr="idFamilia"
                                selectedItems={[this.state.familiaSel]}
                                itemTemplate={this.dxList_itemTemplate}
                                onSelectionChanged={this.dxList_familia_onSelectionChanged}
                            />
                        </ItemBox>
                    </Box>
                </ItemBox>
                <ItemBox baseSize={20}></ItemBox>
                <ItemBox ratio={1}>
                    <Box direction="col" align="space-around" crossAlign="stretch" height="100%" width="100%">
                        <ItemBox baseSize={40}>
                            <div className="font-size">{getTrad("tipoPrenda")}</div>
                        </ItemBox>
                        <ItemBox ratio={1}>
                            <List
                                items={this.state.familiaSel ? this.state.datasource_dxList_tipoPrenda : []}
                                pageLoadMode="scrollBottom"
                                selectionMode="single"
                                height="100%"
                                keyExpr="idTipoPrenda"
                                itemTemplate={this.dxList_itemTemplate}
                                onSelectionChanged={this.dxList_tipoPrenda_onSelectionChanged}
                            />
                        </ItemBox>
                    </Box>
                </ItemBox>
                <ItemBox baseSize={20}></ItemBox>
                <ItemBox ratio={1}>
                    <Box direction="col" align="space-around" crossAlign="stretch" height="100%" width="100%">
                        <ItemBox baseSize={40}>
                            <div className="font-size">{getTrad("denoPrenda")}</div>
                        </ItemBox>
                        <ItemBox ratio={1}>
                            <List
                                items={this.state.tipoPrendaSel ? this.state.datasource_dxList_denoPrenda : []}
                                pageLoadMode="scrollBottom"
                                selectionMode="single"
                                height="100%"
                                keyExpr="idDenoPrenda"
                                searchExpr="denominacion"
                                itemTemplate={this.dxList_itemTemplate}
                                onSelectionChanged={this.dxList_denoPrenda_onSelectionChanged}
                            />
                        </ItemBox>
                    </Box>
                </ItemBox>
            </Box>
        );
    }

    // #region dxList

    dxList_itemTemplate = (itemData) => {
        return itemData.tblTraduccion[this.props.idioma.codigo];
    };

    dxList_familia_onSelectionChanged = (e) => {
        let dataSource_tipoPrenda = null,
            familiaSel = null;

        if (e.addedItems.length > 0) {
            dataSource_tipoPrenda = e.addedItems[0].tblTipoPrenda;
            familiaSel = e.addedItems[0];
        }

        this.setState({
            datasource_dxList_denoPrenda: null,
            datasource_dxList_tipoPrenda: dataSource_tipoPrenda,
            familiaSel: familiaSel,
        });
    };

    dxList_tipoPrenda_onSelectionChanged = (e) => {
        if (e.addedItems.length > 0) {
            this.setState({
                datasource_dxList_denoPrenda: e.addedItems[0].tblDenoPrenda,
                tipoPrendaSel: e.addedItems[0],
            });
        } else {
            this.setState({
                datasource_dxList_denoPrenda: null,
                tipoPrendaSel: null,
            });
        }
    };

    dxList_denoPrenda_onSelectionChanged = (e) => {
        if (e.addedItems.length > 0) {
            const { familiaSel, tipoPrendaSel } = this.state;
            const denoPrendaSel = e.addedItems[0];
            this.props.onSelectionChanged({ familiaSel, tipoPrendaSel, denoPrendaSel });
        }
    };

    // #endregion
}

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(SelectorDenoPrenda);
