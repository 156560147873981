import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import DataSource from "devextreme/data/data_source";
import List from "devextreme-react/list";
import ODataStore from "devextreme/data/odata/store";

import { connectionConstants } from "constants";
import { authHeader, errorHandler, getTrad } from "helpers";
import Box, { Item } from "devextreme-react/box";
import { SelectBox } from "devextreme-react";

const SelectorLavanderia = ({ setIdsLavanderia, user }) => {
  const selectBoxRef = useRef(null);
  const listRef = useRef(null);
  const [tblPais, setTblPais] = useState([]);
  const [tblLavanderia, setTblLavanderia] = useState([]);
  const [idPaisSel, setIdPaisSel] = useState(null);

  useEffect(() => {
    datasource_tblPais.load().then(setTblPais);
  }, []);

  useEffect(() => {
    if (!idPaisSel && tblPais.length > 0) {
      setIdPaisSel(tblPais[0].idPais);
      selectBoxRef?.current?.instance?.option("value", tblPais[0]);
    }
  }, [idPaisSel, tblPais]);

  useEffect(() => {
    if (idPaisSel) datasource_tblLavanderia.load().then(setTblLavanderia);
    listRef?.current?.instance?.unselectAll();
  }, [idPaisSel]);

  const datasource_tblPais = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPais",
      key: "idPais",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
    select: ["idPais", "denominacion"],
    filter: `tblLavanderia/any(l: l/idUsuario/any(u: u/idUsuario eq ${user.idUsuario}))`,
    sort: ["denominacion"],
  });

  const datasource_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
    select: ["idLavanderia", "denominacion"],
    expand: ["idPaisNavigation($select=idPais)"],
    filter: (itemData) =>
      !idPaisSel || itemData.idPaisNavigation.idPais === idPaisSel,
    sort: ["denominacion"],
  });

  const onSelectionChanged = (e) => setIdPaisSel(e.selectedItem.idPais);

  const onOptionChanged = ({ name, value }) => {
    if (name === "selectedItemKeys") setIdsLavanderia(value);
  };

  return (
    <Box direction={"col"} width={"100%"} height={"100%"}>
      <Item baseSize={40}>
        <div className={"font-size p-2"}>{getTrad("pais")}</div>
      </Item>
      <Item baseSize={50}>
        <SelectBox
          ref={selectBoxRef}
          dataSource={tblPais}
          value={tblPais.find((p) => p.idPais === idPaisSel)}
          keyExpr={"idPais"}
          displayExpr={"denominacion"}
          onSelectionChanged={onSelectionChanged}
        />
      </Item>
      <Item baseSize={40}>
        <div className={"font-size p-2"}>{getTrad("lavanderia")}</div>
      </Item>
      <Item ratio={1}>
        <List
          ref={listRef}
          dataSource={tblLavanderia}
          height={"100%"}
          showSelectionControls={true}
          keyExpr={"idLavanderia"}
          displayExpr={"denominacion"}
          selectionMode={"all"}
          selectAllMode={"allPages"}
          searchExpr={"denominacion"}
          searchEnabled
          selectByClick
          onOptionChanged={onOptionChanged}
        />
      </Item>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(SelectorLavanderia);
