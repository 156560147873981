import React, { useRef, forwardRef, useImperativeHandle, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import GestionCarpetas from "./GestionCarpetas";
import GestionDestinatarios from "./GestionDestinatarios";
import { getTrad, authHeader } from "helpers";
import { connectionConstants } from "constants";
import notify from "devextreme/ui/notify";

import "./Css.scss";
import GestionDocumentos from "./GestionDocumentos";
import { Button } from "devextreme-react";

/**
 * Props de `EnvioDocumentacion` Component
 *
 * @prop {boolean} preventSend - (opcional) Previene que se haga el envio a la API cuando se pretende guardar la documentación. Por defecto es `false`
 * @prop {boolean} overruleValidation - (opcional) Anula la validación del payload. Por defecto es `false`
 * @prop {function} beforeSend - (opcional) Funcion para ser ejecutada antes de hacer el envio a la API
 *
 * Funciones públicas de `EnvioDocumentacion` Component
 * @function clearComponent - Limpia el componente
 * @function saveComponentData - Guarda los datos del componente @returns {object} - Retorna el payload
 * @function isPayloadValid - Valida si el payload es valido @returns {boolean} - Retorna `true` si el payload es valido, `false` en caso contrario
 */

const EnvioDocumentacion = forwardRef(({ preventSend = false, overruleValidation = false, beforeSend = null }, ref) => {
    //#region Bloque declarativo

    const [carpetaDocumentos, setCarpetaDocumentos] = useState([]);
    const documentos = useRef([]);
    const destinatarios = useRef([]);

    const gestionCarpeta_REF = useRef();
    const gestionDocumentos_REF = useRef();
    const gestionDestinatarios_REF = useRef();

    //#endregion Bloque declarativo
    //#region Bloque de funciones públicas

    useImperativeHandle(ref, () => ({
        clearComponent,
        saveComponentData,
        isPayloadValid,
    }));

    const clearComponent = () => {
        gestionCarpeta_REF.current.dxList_carpetas_REF.current.instance.unselectAll();
        gestionCarpeta_REF.current.dxList_subCarpetas_REF.current.instance.unselectAll();
        gestionDocumentos_REF.current.clearComponent();
        gestionDestinatarios_REF.current.clearComponent();
        setCarpetas([]);
        setDestinatarios([]);
        setDocumentos([]);
    };

    const saveComponentData = async () => {
        return new Promise((resolve, reject) => {
            if (!isPayloadValid()) {
                return reject();
            }
            //////////////////////////
            function extractIdPersona(destinatarios) {
                return destinatarios.current.map((destinatario) => destinatario.idPersona);
            }
            function getDocumentos(documentos) {
                return documentos.current.map((documento) => {
                    return {
                        denominacion: documento.nombre,
                        extension: documento.extension,
                        idCarpetaDocumentos: documento.idPadre,
                        firmado: documento.firmado,
                        subCarpeta: documento.subCarpeta,
                        isLeido: false,
                        documento: documento.documento,
                    };
                });
            }
            //////////////////////////

            let payload = {
                idsPersona: extractIdPersona(destinatarios),
                documentos: getDocumentos(documentos),
            };

            if (preventSend) {
                return resolve(payload);
            }
            if (beforeSend != null) {
                beforeSend(payload);
            }

            fetch(connectionConstants.WEB_API_CORE_ODATA_URL + "tblDocumento/PostMasivoMultDoc", {
                method: "POST",
                headers: {
                    ...authHeader(),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (response.ok) {
                        notify({
                            message: "Documentación enviada correctamente",
                            type: "success",
                            displayTime: 1500,
                            closeOnClick: true,
                        });
                        return resolve(payload);
                        // cerrarPopup();
                    } else {
                        notify({
                            message: "Error al enviar la documentación",
                            type: "error",
                            displayTime: 1500,
                            closeOnClick: true,
                        });
                        return reject();
                    }
                })
                .catch((error) => {
                    console.error("There has been a problem with your fetch operation:", error);
                    notify({
                        message: error.message,
                        type: "error",
                        displayTime: 1500,
                        closeOnClick: true,
                    });
                    return reject();
                });
        });
    };

    const isPayloadValid = () => {
        if (overruleValidation) {
            return true;
        }

        if (documentos.current.length === 0) {
            notify({
                message: "No hay documentos para enviar",
                type: "error",
                displayTime: 1500,
                closeOnClick: true,
            });
            return false;
        }
        if (destinatarios.current.length === 0) {
            notify({
                message: "No hay destinatarios para enviar",
                type: "error",
                displayTime: 1500,
                closeOnClick: true,
            });
            return false;
        }
        return true;
    };

    //#endregion Bloque de funciones públicas
    //#region Bloque de funciones privadas

    const setDocumentos = (data) => {
        documentos.current = data;
    };

    const setDestinatarios = (data) => {
        destinatarios.current = data;
    };

    const setCarpetas = (data) => {
        setCarpetaDocumentos(data);
    };

    const documentosNSubcarpetas = () => {
        const documentosNCarpeta = documentos.current.filter(
            (documento) => documento.idPadre === carpetaDocumentos.idCarpetaDocumentos
        );
        return documentosNCarpeta.some((documento) => documento.subCarpeta !== "null" && documento.subCarpeta !== null);
    };

    const hasSubfolders = () => {
        //mirar si hay subcarpeta
        //si no hay subcarpeta, mirar si los documentos de la carpeta seleccionada tienen subcarpetas
        //de ser asi, cambiar el header para mostrar el boton para volver atras
        if (
            carpetaDocumentos == undefined ||
            carpetaDocumentos == "null" ||
            carpetaDocumentos.idCarpetaDocumentos == undefined ||
            carpetaDocumentos.idCarpetaDocumentos == null
        ) {
            return false;
        }
        if (
            ("subCarpeta" in carpetaDocumentos &&
                carpetaDocumentos.subCarpeta !== null &&
                carpetaDocumentos.subCarpeta !== undefined) ||
            documentosNSubcarpetas()
        ) {
            // gestionCarpeta_REF.current.setMultiViewCarpetasIndex(1);
            return true;
        } else {
            return false;
        }
    };

    const goBackUp = () => {
        //deseleccionar la carpeta padre en la que estamos
        //ir al multiview con indice 0
        gestionCarpeta_REF.current.setMultiViewCarpetasIndex(0);
        gestionCarpeta_REF.current.dxList_carpetas_REF.current.instance.unselectAll();
        gestionCarpeta_REF.current.dxList_subCarpetas_REF.current.instance.unselectAll();
        setCarpetas([]);
    };

    const unselectFolder = () => {
        gestionCarpeta_REF.current.unselectAll();
    };

    //#endregion Bloque de funciones privadas

    return (
        <div
            style={{
                display: "flex",
                height: "100%",
                justifyContent: "space-between",
            }}
        >
            <Card
                id="EnvioDocumentacion"
                className="d-flex he-100 card"
                style={{
                    width: "32.5%",
                    overflowY: "hidden",
                }}
            >
                <CardHeader>Destinatarios</CardHeader>
                <CardBody style={{ height: "49%" }}>
                    <GestionDestinatarios ref={gestionDestinatarios_REF} setDestinatarios={setDestinatarios} />
                </CardBody>
            </Card>

            <Card
                id="EnvioDocumentacion"
                className="d-flex he-100 card"
                style={{
                    width: "32.5%",
                    overflowY: "hidden",
                }}
            >
                <CardHeader
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {hasSubfolders() ? (
                        <div
                            onClick={() => {
                                goBackUp();
                            }}
                        >
                            <i className="dx-icon dx-icon-folder mx-2"></i>
                            <i className="dx-icon dx-icon-arrowleft mx-2"></i>
                            <span>
                                {getTrad("carpetas").toUpperCase()} / {carpetaDocumentos.denominacion.toUpperCase()}
                            </span>
                        </div>
                    ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <i className="dx-icon dx-icon-folder mx-2"></i>
                            <div>{getTrad("carpetas").toUpperCase()}</div>
                        </div>
                    )}
                    <Button
                        onClick={unselectFolder}
                        text={"Deseleccionar carpeta"}
                        type="normal"
                        style={{
                            marginBottom: "10px",
                            marginTop: "auto",
                            marginBottom: "auto",
                            textTransform: "none",
                        }}
                        // visible={multiViewCarpetasIndex === 0}
                    />
                </CardHeader>
                <CardBody>
                    <GestionCarpetas
                        ref={gestionCarpeta_REF}
                        carpetaDocumentos={carpetaDocumentos}
                        documentos={documentos}
                        setCarpetas={setCarpetas}
                    />
                </CardBody>
            </Card>

            <Card
                id="EnvioDocumentacion"
                className="d-flex card"
                style={{
                    overflowY: "hidden",
                    paddingBottom: "50px",
                    width: "32.5%",
                }}
            >
                <CardHeader>Documentos</CardHeader>
                <CardBody style={{ maxHeight: "100%" }}>
                    <GestionDocumentos
                        ref={gestionDocumentos_REF}
                        setCarpetas={setCarpetas}
                        carpetaDocumentos={carpetaDocumentos}
                        setDocumentos={setDocumentos}
                        documentos={documentos.current}
                    />
                </CardBody>
            </Card>
        </div>
    );
});

export default EnvioDocumentacion;
