import React, { useRef } from "react";

import Box, { Item } from "devextreme-react/box";
import Button from "devextreme-react/button";

import Buscador from "./components/Buscador";
import { getTrad } from "helpers";
import { useRecambiosProvider } from "../../../RecambiosProvider";

const gap = { gap: 10 };

const Layout = ({
  buscadorRef,
  dataGridRef,
  onClick_nuevoRecambio,
  refreshData,
  children,
}) => {
  const { setIdsRecambio } = useRecambiosProvider();

  const onClick_eliminarFiltros = () => {
    buscadorRef?.current?.clear();
    dataGridRef?.current?.instance?.clearFilter();
  };

  const onClick_columnchooser = () =>
    dataGridRef?.current?.instance?.showColumnChooser();

  return (
    <Box direction={"col"} className={"he-100"}>
      <Item baseSize={50}>
        <Box
          direction={"row"}
          className={"p-2 justify-content-between he-100"}
          style={gap}
        >
          <Item baseSize={"auto"}>
            <Box className={"he-100"} style={gap}>
              <Item baseSize={"auto"}>
                <Button
                  icon={"add"}
                  text={getTrad("nuevoRecambio")}
                  onClick={onClick_nuevoRecambio}
                />
              </Item>
            </Box>
          </Item>
          <Item baseSize={"auto"}>
            <Box className={"he-100"} style={gap}>
              <Item baseSize={"auto"}>
                <Button icon={"refresh"} onClick={refreshData} />
              </Item>
              <Item baseSize={"auto"}>
                <Button
                  icon={" icon_EliminarFiltros"}
                  onClick={onClick_eliminarFiltros}
                />
              </Item>
              <Item baseSize={"auto"}>
                <Button
                  icon={"columnchooser"}
                  onClick={onClick_columnchooser}
                />
              </Item>
              <Item baseSize={"auto"}>
                <Buscador ref={buscadorRef} setIdsRecambio={setIdsRecambio} />
              </Item>
            </Box>
          </Item>
        </Box>
      </Item>
      <Item ratio={1}>{children}</Item>
    </Box>
  );
};

export default Layout;
