import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";

import { getTrad } from "helpers";
import Form from "./components/Form";
import notify from "devextreme/ui/notify";
import { datasource_tblAdmFacturaVenta, saveData } from "pages/Administracion/shared/model";
import { payloadBuilder } from "../../model";

const FormFacturaVenta = forwardRef(
    ({ facturaVentaSel, validations, closePopup, loadPanel_show, loadPanel_hide, userCantEdit }, ref) => {
        const [idMoneda, setIdMoneda] = useState(1);
        const formFacturaVenta_REF = useRef();

        useImperativeHandle(ref, () => ({
            onSubmit_FormFacturaVenta,
            hasEditData: formFacturaVenta_REF.current.hasEditData,
        }));

        useEffect(() => {
            setIdMoneda(facturaVentaSel.idMoneda);
        }, [facturaVentaSel]);

        const onSubmit_FormFacturaVenta = async () => {
            return new Promise((resolve, reject) => {
                const formData = formFacturaVenta_REF.current.getFormData();
                delete formData.idReferenciaFacturaVentaNavigation;
                if (formData.codigoRetencion != null) {
                    formData.codigoRetencion = formData.codigoRetencion / 100;
                }

                let promises = [];
                validations.forEach((fnValidation) => {
                    promises.push(fnValidation());
                });

                loadPanel_show(true);
                Promise.all(promises)
                    .then(() => {
                        saveData("POST", payloadBuilder(formData), "IU_AdmFacturaVenta").then((data) => {
                            resolve(data);
                            datasource_tblAdmFacturaVenta.reload().then(() => {
                                loadPanel_hide();
                            });
                        });
                        notify({
                            message: getTrad("aviso_C_RegistroInsertado"),
                            type: "success",
                            displayTime: "1500",
                            closeOnClick: true,
                        });
                        closePopup();
                    })
                    .catch((e) => {
                        loadPanel_hide();
                        notify({
                            message: getTrad("errorIntroduccionDatos"),
                            type: "error",
                            displayTime: "1500",
                            closeOnClick: true,
                        });
                        reject(e);
                    });
            });
        };

        return (
            <>
                <div>
                    <Form
                        ref={formFacturaVenta_REF}
                        formData={facturaVentaSel}
                        validations={validations}
                        setIdMoneda={setIdMoneda}
                        idMoneda={idMoneda}
                        userCantEdit={userCantEdit}
                    />
                </div>
            </>
        );
    }
);

export default FormFacturaVenta;
