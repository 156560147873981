import React, { createRef } from "react";

import notify from "devextreme/ui/notify";
import { dxMensajePregunta, errorHandler, getTrad } from "helpers";
import {
    authHeaderAdd,
    datasource_tblCliente,
    datasource_tblProveedor,
    datasource_tblAdmAlbaran_Estado,
    datasource_tblMoneda,
    datasource_tblAdmTipoElemento,
    sharedEditorOptions,
    datasource_tblViasPagoCobro,
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblIvaNPais_Venta,
    datasource_tblIncoterm,
    datasource_tblAdmAlbaranVenta_map,
    datasource_tblAdmAlbaranCompra_map,
    empresaPolarierSel,
} from "pages/Administracion/shared/model";

import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import {
    calcularValorNeto,
    calculateDisplayValue,
    custom_displayExpr,
    getDefaultIvaId,
    retrieveIvaValue,
} from "pages/Administracion/shared/functions";
import { connectionConstants } from "constants";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { Button, Popup } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/autocomplete";
import DataGrid, { Column, ColumnChooser, Lookup, SearchPanel, Selection } from "devextreme-react/data-grid";
import TipoElemento from "pages/Administracion/shared/components/TipoElemento";

const formats = {
    percent: {
        type: "percent",
        maximumFractionDigits: 0,
    },
    currency: {
        style: "currency",
        maximumFractionDigits: 2,
        currency: datasource_tblMoneda.items().find((m) => m.idMoneda === idMoneda)?.codigo || "EUR",
    },
    number: {
        style: "decimal",
        maximumFractionDigits: 0,
    },
};

class CargarAlbaran extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            selectedRowKeys: [],
            selectedTarget: null,
            albaranData: null,
        };

        this.keyExpr = "idAdmAlbaran" + props.apartado;

        this.isCliente = props.apartado === "Venta";
        this.keyExprTarget = this.isCliente ? "idAdmCliente" : "idAdmProveedor";

        this.datasource_tblAdmAlbaran = new DataSource({
            paginate: false,
            store: new ODataStore({
                url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmAlbaran" + props.apartado,
                key: this.keyExpr,
                errorHandler: (error) => errorHandler(error, null),
                beforeSend: (request) => authHeaderAdd(request),
                onLoading: (loadOptions) => this.datasource_tblAdmAlbaran_onLoading(loadOptions),
                version: 4,
            }),
            expand: [
                `tblArticuloNAdmAlbaran${props.apartado}($expand=idArticuloLenceriaNavigation, idArticuloMaquinariaNavigation, idGrupoArticulosNavigation, idRecambioNavigation)`,
                this.isCliente
                    ? "idAdmPedidoClienteNavigation($select=codigo)"
                    : "idAdmPedidoProveedorNavigation($select=codigo)",
            ],
            map: (item) =>
                this.isCliente ? datasource_tblAdmAlbaranVenta_map(item) : datasource_tblAdmAlbaranCompra_map(item),
        });

        this.dataGridRef = createRef();
    }

    // #region LifeCycle

    componentDidUpdate(prevProps, prevState) {
        const { cargarAlbaranPopup_visibility, albaranesSeleccionados } = this.props;
        if (cargarAlbaranPopup_visibility !== prevProps.cargarAlbaranPopup_visibility) {
            if (Array.isArray(albaranesSeleccionados) && albaranesSeleccionados.length > 0) {
                this.setState({ selectedRowKeys: albaranesSeleccionados?.map((x) => x[this.keyExpr]) });
            }
            if (cargarAlbaranPopup_visibility) {
                this.datasource_tblAdmAlbaran.reload();
            } else {
                this.dataGridRef.current.instance.hideColumnChooser();
                this.setState({ selectedItems: [], selectedRowKeys: [], selectedTarget: null, albaranData: null });
            }
        }
    }

    // #endregion

    // #region Datasource

    datasource_tblAdmAlbaran_onLoading = (loadOptions) => {
        const { selectedTarget, albaranData } = this.state;
        const { idAdmFactura, apartado } = this.props;

        loadOptions.filter = [
            ["idEmpresaPolarier", "=", empresaPolarierSel.idEmpresaPolarier],
            [
                ["idAdmAlbaran_Estado", "=", 1],
                "or",
                [`idAdmFactura${apartado}/any(x: x/idAdmFactura${apartado} eq ${idAdmFactura ?? 0})`],
            ],
        ];

        if (selectedTarget) loadOptions.filter.push([this.keyExprTarget, "=", selectedTarget]);
        if (albaranData?.idMoneda) loadOptions.filter.push(["idMoneda", "=", albaranData.idMoneda]);
        if (albaranData?.tasaCambio) loadOptions.filter.push(["tasaCambio", "=", albaranData.tasaCambio]);
        if (albaranData?.idTipoAlbaran) loadOptions.filter.push(["idTipoAlbaran", "=", albaranData.idTipoAlbaran]);
        if (albaranData?.idTipoFactura) loadOptions.filter.push(["idTipoFactura", "=", albaranData.idTipoFactura]);
        if (albaranData?.idAdmTipoCambio)
            loadOptions.filter.push(["idAdmTipoCambio", "=", albaranData.idAdmTipoCambio]);
        if (albaranData?.idAdmCentroCoste)
            loadOptions.filter.push(["idAdmCentroCoste", "=", albaranData.idAdmCentroCoste]);
        if (albaranData?.idAdmElementoPEP)
            loadOptions.filter.push(["idAdmElementoPEP", "=", albaranData.idAdmElementoPEP]);
        if (albaranData?.idIvaNPais) loadOptions.filter.push(["idIvaNPais", "=", albaranData.idIvaNPais]);
    };

    // #endregion

    render() {
        return (
            <Popup
                showTitle={true}
                title={getTrad("albaranes")}
                visible={this.props.cargarAlbaranPopup_visibility}
                showCloseButton={true}
                closeOnOutsideClick={true}
                height={"450px"}
                width={"60%"}
                onHiding={this.closePopup}
            >
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("seleccionar")} type="success" onClick={this.setAlbaranesSeleccionados} />
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="before" widget="dxButton">
                    <Button text={getTrad("quitarSeleccion")} type="default" onClick={this.limpiarSeleccion} />
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("cancelar")} onClick={this.closePopup} />
                </ToolbarItem>
                <div className={"d-flex he-100"}>
                    <DataGrid
                        ref={this.dataGridRef}
                        dataSource={this.datasource_tblAdmAlbaran}
                        height={"100%"}
                        width={"100%"}
                        showRowLines
                        columnsAutoWidth
                        repaintChangesOnly
                        rowAlternationEnabled
                        showColumnLines={false}
                        remoteOperations={false}
                        hoverStateEnabled={true}
                        onRowPrepared={this.onRowPrepared_tblFacturas}
                        selectedRowKeys={this.state.selectedRowKeys}
                        onSelectionChanged={this.onSelectionChanged}
                        onRowClick={this.onRowClick}
                        onToolbarPreparing={this.onToolbarPreparing}
                    >
                        <ColumnChooser enabled />
                        <SearchPanel visible width={240} />
                        <Selection mode={"multiple"} showCheckBoxesMode={"always"} />

                        <Column
                            dataField={"codigo"}
                            caption={getTrad("codigo")}
                            dataType={"string"}
                            alignment={"center"}
                            width={150}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idTipoAlbaran"}
                            caption={getTrad("tipoAlbaran")}
                            dataType={"number"}
                            visible={false}
                            showInColumnChooser={true}
                            cellComponent={(e) => TipoElemento(e.data.data.idTipoAlbaran, "")}
                            allowReordering
                            allowResizing
                        >
                            <Lookup
                                dataSource={datasource_tblAdmTipoElemento.store()}
                                valueExpr="idAdmTipoElemento"
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"fechaCreacion"}
                            caption={getTrad("fecha")}
                            dataType={"date"}
                            alignment={"center"}
                            width={150}
                            sortOrder={"desc"}
                            format={"dd/MM/yyyy"}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idAdmAlbaran_Estado"}
                            caption={getTrad("estado")}
                            dataType={"number"}
                            alignment={"center"}
                            width={150}
                            cellComponent={(e) => ChipEstado(e.data.data.idAdmAlbaran_Estado, "", "albaran")}
                            allowReordering
                            allowResizing
                        >
                            <Lookup
                                dataSource={datasource_tblAdmAlbaran_Estado.store()}
                                valueExpr="idAdmAlbaran_Estado"
                                // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={this.keyExprTarget}
                            caption={this.isCliente ? getTrad("cliente") : getTrad("proveedor")}
                            dataType={"number"}
                            alignment={"left"}
                            minWidth={300}
                            allowReordering
                            allowResizing
                        >
                            <Lookup
                                dataSource={
                                    this.isCliente ? datasource_tblCliente.store() : datasource_tblProveedor.store()
                                }
                                valueExpr={this.keyExprTarget}
                                displayExpr="nombreFiscal"
                            />
                        </Column>
                        <Column
                            dataField={"idMoneda"}
                            caption={getTrad("moneda")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <Lookup
                                dataSource={datasource_tblMoneda.store()}
                                valueExpr="idMoneda"
                                displayExpr="codigo"
                            />
                        </Column>
                        <Column
                            dataField={"tasaCambio"}
                            caption={getTrad("tasaCambio")}
                            dataType={"number"}
                            format={sharedEditorOptions.tasaCambio.format}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={this.isCliente ? "numPedidoCliente_codigo" : "numPedidoProveedor_codigo"}
                            caption={this.isCliente ? getTrad("numPedidoCliente") : getTrad("numPedidoProveedor")}
                            dataType={"string"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idAdmFormaPago"}
                            caption={getTrad("formaPago")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <Lookup
                                dataSource={datasource_tblViasPagoCobro.store()}
                                valueExpr="idAdmFormaPago"
                                displayExpr="denominacion"
                            />
                        </Column>
                        {/* <Column
                            dataField={"numPresupuesto"}
                            caption={getTrad("numPresupuesto")}
                            visible={false}
                            allowReordering
                            allowResizing
                        /> */}
                        <Column
                            dataField={"descuento"}
                            caption={getTrad("descuento")}
                            dataType={"number"}
                            visible={false}
                            format={formats.percent}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idIvaNPais"}
                            caption={getTrad("iva")}
                            dataType={"number"}
                            visible={false}
                            format={formats.percent}
                            allowReordering
                            allowResizing
                        >
                            <Lookup
                                dataSource={datasource_tblIvaNPais_Venta.store()}
                                valueExpr="idIvaNPais"
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"idAdmCentroCoste"}
                            caption={getTrad("centroCoste")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <Lookup
                                dataSource={datasource_tblAdmCentroCoste.store()}
                                valueExpr="idAdmCentroCoste"
                                displayExpr={custom_displayExpr}
                            />
                        </Column>
                        <Column
                            dataField={"idAdmElementoPEP"}
                            caption={getTrad("elementoPep")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <Lookup
                                dataSource={datasource_tblAdmElementoPEP.store()}
                                valueExpr="idAdmElementoPEP"
                                displayExpr={custom_displayExpr}
                            />
                        </Column>
                        <Column
                            dataField={"idIncoterm"}
                            caption={getTrad("incoterms")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <Lookup
                                dataSource={datasource_tblIncoterm.store()}
                                valueExpr="idIncoterm"
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"observaciones"}
                            caption={getTrad("observaciones")}
                            dataType={"string"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            name={"total"}
                            caption={getTrad("total")}
                            dataType={"number"}
                            visible={true}
                            calculateCellValue={this.calculateCellValue}
                            calculateDisplayValue={this.calculateCellDisplayValue}
                        />
                    </DataGrid>
                </div>
            </Popup>
        );
    }

    closePopup = () => {
        this.props.setCargarAlbaranPopup_visibility(false);
    };

    confirmarAlbaranes = () => {
        const { setAlbaran } = this.props;
        const { selectedItems } = this.state;
        setAlbaran({
            // Moneda - Proveedor - Tipo cambio - Tasa cambio - Centro coste
            [this.keyExprTarget]: selectedItems[0][this.keyExprTarget],
            idAdmCentroCoste: selectedItems[0].idAdmCentroCoste,
            idAdmElementoPEP: selectedItems[0].idAdmElementoPEP,
            idMoneda: selectedItems[0].idMoneda,
            tasaCambio: selectedItems[0].tasaCambio,
            idAdmTipoCambio: selectedItems[0].idAdmTipoCambio,
            idIvaNPais: selectedItems[0].idIvaNPais,
            [this.keyExpr]: selectedItems ?? [],
            idTipoFactura: selectedItems[0].idTipoFactura,
            idTipoAlbaran: selectedItems[0].idTipoAlbaran,
            idIncoterm: selectedItems[0].idIncoterm,
            idAdmFormaPago: selectedItems[0].idAdmFormaPago,
        });
    }

    setAlbaranesSeleccionados = () => {
        const { selectedItems } = this.state;
        const { hasEditData } = this.props;

        if (selectedItems) {
            if (hasEditData()) {
                dxMensajePregunta("Se van a sobreescribir datos al asociar el pedido. ¿Quieres continuar? ", [
                    [
                        getTrad("aceptar"),
                        () => {
                            this.confirmarAlbaranes();
                            this.closePopup();
                        },
                        "danger",
                    ],
                    [getTrad("cancelar"), () => {}],
                ]);
            } else {
                this.confirmarAlbaranes();
                this.closePopup();
            }
        } else {
            notify({
                message: getTrad("seleccioneAlbaranes"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    };

    onSelectionChanged = (e) => {
        let filter = e.selectedRowsData[0] ?? {};
        let selectedItems =
            e.currentSelectedRowKeys.length > 1
                ? e.selectedRowsData.filter(
                      (x) =>
                          x[this.keyExprTarget] === filter[this.keyExprTarget] &&
                          x.idMoneda === filter.idMoneda &&
                          x.tasaCambio === filter.tasaCambio &&
                          x.idAdmTipoCambio === filter.idAdmTipoCambio &&
                          x.idTipoFactura === filter.idTipoFactura &&
                          x.idTipoAlbaran === filter.idTipoAlbaran &&
                          x.idAdmCentroCoste === filter.idAdmCentroCoste &&
                          x.idAdmElementoPEP === filter.idAdmElementoPEP
                  )
                : e.selectedRowsData;
        this.setState(
            {
                selectedItems,
                selectedRowKeys: selectedItems.map((x) => x[this.keyExpr]),
                selectedTarget: filter[this.keyExprTarget],
                albaranData: {
                    idMoneda: filter.idMoneda,
                    tasaCambio: filter.tasaCambio,
                    idAdmTipoCambio: filter.idAdmTipoCambio,
                    idAdmCentroCoste: filter.idAdmCentroCoste,
                    idAdmElementoPEP: filter.idAdmElementoPEP,
                    idTipoAlbaran: filter.idTipoAlbaran,
                    idTipoFactura: filter.idTipoFactura,
                },
            },
            () => {
                if (e.selectedRowKeys.length <= 1) this.datasource_tblAdmAlbaran.reload();
            }
        );
    };

    limpiarSeleccion = () => {
        const { setAlbaran } = this.props;
        this.setState({ selectedItems: [], selectedRowKeys: [], albaranData: null, selectedTarget: null });
        setAlbaran({
            [this.keyExprTarget]: null,
            idAdmCentroCoste: null,
            idAdmElementoPEP: null,
            idMoneda: null,
            tasaCambio: null,
            idAdmTipoCambio: 1,
            idIvaNPais: 0,
            [this.keyExpr]: [],
            idTipoFactura: null,
            idTipoAlbaran: null,
            idIncoterm: null,
            idIvaNPais: getDefaultIvaId(false),
            idAdmFormaPago: null,
        });
        this.closePopup();
    };

    onRowPrepared_tblFacturas = (e) => {
        if (e.rowType === "data") {
            e.rowElement.css("cursor", "pointer");
        }
    };

    onRowClick = (e) => {
        if (!e.component.isRowSelected(e.key)) {
            e.component.selectRows([e.key], true);
        } else {
            e.component.deselectRows([e.key]);
        }
    };

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxSelectBox",
            options: {
                dataSource: this.isCliente ? datasource_tblCliente : datasource_tblProveedor,
                value: this.state.selectedTarget,
                showClearButton: true,
                valueExpr: this.keyExprTarget,
                displayExpr: "nombreFiscal",
                width: 300,
                onValueChanged: (e) => this.setState({ selectedTarget: e.value }),
            },
        });
    };

    calculateCellValue = (e) => {
        const { apartado } = this.props;
        if (e["tblArticuloNAdmAlbaran" + apartado]?.length > 0) {
            let total = 0;
            e["tblArticuloNAdmAlbaran" + apartado].forEach((articulo) => {
                const { cantidad = 0, descuento = 0, idIvaNPais = 0, precio = 0 } = articulo;
                let iva = retrieveIvaValue(idIvaNPais, this.isCliente ? "venta" : "compra");
                total += cantidad * precio * (1 + iva) * (1 - descuento);
            });
            const { descuento = 0, idAdmTipoDescuento, idIvaNPais } = e;
            return calcularValorNeto(total, descuento, idAdmTipoDescuento, idIvaNPais, apartado.toLowerCase());
        }
    };

    calculateCellDisplayValue = (e) => {
        return calculateDisplayValue(this.calculateCellValue(e), e.idMoneda);
    };
}

export default CargarAlbaran;
