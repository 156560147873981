import React, { createContext, useContext, useState, useEffect } from "react";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "../../../../../../../constants/connection";
import {
  errorHandler,
  authHeader,
  formatDateTime_parameter,
  addHours,
  convertClienteUtcToLavanderiaUtc,
} from "../../../../../../../helpers";

const visorRutasContext = createContext();

export const useVisorRutasContext = () => {
  const context = useContext(visorRutasContext);
  return context;
};

function getRandomRgb() {
  var num = Math.round(0xffffff * Math.random());
  var r = num >> 16;
  var g = (num >> 8) & 255;
  var b = num & 255;
  return [r, g, b];
}

function miliSecondsToHMS(milis) {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var secs = Math.round(milis / 1000);
  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return (
    sign +
    z((secs / 3600) | 0) +
    ":" +
    z(((secs % 3600) / 60) | 0) +
    ":" +
    z(secs % 60)
  );
}

let refreshInterval = null;
export const VisorRutasProvider = ({ children, lavanderia }) => {
  const [idParteTransporteSel, setIdParteTransporteSel] = useState(null);
  const [dataParteTrabajo, setDataParteTrabajo] = useState(null);
  const [dataParadaNParteTrabajo, setDataParadaNParteTrabajo] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [lavanderias, setLavanderias] = useState([]);
  const [entidades, setEntidades] = useState([]);
  const [pausas, setPausas] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [flags, setFlags] = useState([]);

  const [lastFechaLocalizacion, setLastFechaLocalizacion] = useState(undefined);

  const [date, setDate] = useState(new Date());

  const stopInterval = () => {
    if (refreshInterval != null) {
      clearInterval(refreshInterval);
    }
  };

  useEffect(() => {
    setIdParteTransporteSel(null);
  }, [lavanderia]);

  useEffect(() => {
    if (idParteTransporteSel) {
      datasource_tblParteTransporte_Localizacion.reload();
    }
    datasource_tblParteTransporte.reload();
    if (refreshInterval != null) {
      clearInterval(refreshInterval);
    }
    refreshInterval = setInterval(() => {
      if (
        idParteTransporteSel &&
        dataParteTrabajo.find(
          (x) => x.idParteTransporte === idParteTransporteSel,
        ).idEstado === 1
      ) {
        datasource_tblParteTransporte_Localizacion.reload();
      }
      datasource_tblParteTransporte.reload();
    }, 4000);
  }, [date, idParteTransporteSel, lavanderia]);

  const datasource_tblParteTransporte_Localizacion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblParteTransporte_Localizacion",
      key: "idParteTransporte",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        let _authHeader = authHeader();
        request.headers = { ..._authHeader };
        request.params.idParteTransporte = idParteTransporteSel;
      },
      version: 4,
      onLoading: (loadOptions) => {
        datasource_tblParteTransporte_Localizacion_onLoading(loadOptions);
      },
    }),
    select: [
      "idParteTransporte",
      "fecha",
      "coordenadas",
      "idParadaNParteTransporte",
      "isOffline",
      "accuracy",
      "heading",
      "speed",
    ],
    sort: "fecha",
    postProcess: (data) => {
      return datasource_tblParteTransporte_Localizacion_postProcess(data);
    },
  });

  const datasource_tblParteTransporte_Localizacion_onLoading = (
    loadOptions,
  ) => {
    loadOptions.filter = [
      `${lastFechaLocalizacion ? `fecha gt ${new Date(lastFechaLocalizacion).toISOString()} and ` : ""}(accuracy le 55 or accuracy eq null) and idParteTransporte eq ${idParteTransporteSel}`,
    ];
  };

  const datasource_tblParteTransporte_Localizacion_postProcess = (data) => {
    let fechaLocalizacion = lastFechaLocalizacion;

    data.forEach((d) => {
      d.fecha = addHours(2, d.fecha);
      fechaLocalizacion = !fechaLocalizacion
        ? d.fecha
        : fechaLocalizacion < d.fecha
          ? d.fecha
          : fechaLocalizacion;
    });

    // setLastFechaLocalizacion(fechaLocalizacion); //TODO: Solo actualizar puntos nuevos

    setRutas([
      {
        idParteTransporte: idParteTransporteSel,
        localizaciones: data.map((e, i) => {
          let coordenadas = e.coordenadas.split(",");
          coordenadas = [
            parseFloat(coordenadas[1]),
            parseFloat(coordenadas[0]),
          ];
          return {
            index: i,
            coordenadas,
            fecha: e.fecha,
            isOffline: e.isOffline,
            isParada: e.idParadaNParteTransporte != null,
          };
        }),
      },
    ]);

    setVehiculos([
      () => {
        let localizacion = data[data.length - 1];
        let coordenadas = localizacion.coordenadas.split(",");
        coordenadas = [parseFloat(coordenadas[1]), parseFloat(coordenadas[0])];
        const parteTrabajo = dataParteTrabajo.filter(
          (x) => x.idParteTransporte === idParteTransporteSel,
        ).idParteTransporteNavigation;
        return {
          conductor: parteTrabajo.idUsuarioResponsableNavigation.nombre,
          matricula: parteTrabajo.idVehiculoNavigation.matricula,
          fecha: localizacion.fecha,
          coordenadas,
          color: getRandomRgb(),
        };
      },
    ]);

    return data;
  };

  const datasource_tblParteTransporte = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblParteTransporte",
      key: "idParteTransporte",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        let _authHeader = authHeader();
        request.headers = { ..._authHeader };
      },
      version: 4,
      onLoading: (loadOptions) => {
        datasource_tblParteTransporte_onLoading(loadOptions);
      },
    }),
    expand: [
      "idVehiculoNavigation($select=denominacion,matricula)",
      "idUsuarioResponsableNavigation($select=idPersona;$expand=idPersonaNavigation($select=nombre,apellidos))",
      "idEstadoNavigation($select=denominacion)",
      "tblParadaNParteTransporte($select=idParadaNParteTransporte,idParteTransporte,fechaLlegada,fechaSalida,idLavanderia,idEntidad,orden,fechaOmitido,fechaCancelado;$expand=idLavanderiaNavigation($select=denominacion, coordenadas),idEntidadNavigation($select=denominacion, coordenadas),idMotivoPausaNavigation;$orderby=orden)",
    ],
    select: ["idParteTransporte", "denoRutaExpedicion", "idEstado"],
    sort: "idParteTransporte",
    postProcess: (data) => {
      return datasource_tblParteTransporte_postProcess(data);
    },
  });

  const datasource_tblParteTransporte_onLoading = (loadOptions) => {
    var start = new Date(date.getTime());
    start.setHours(0, 0, 0, 0);

    var end = new Date(date.getTime());
    end.setHours(23, 59, 59, 999);

    loadOptions.filter = [
      [
        "tblParadaNParteTransporte/any(l: l/idLavanderia eq " +
          lavanderia.idLavanderia +
          ") ",
      ],
      "and",
      [
        "(tblParadaNParteTransporte/any(p: p/fechaLlegada ge " +
          formatDateTime_parameter(start) +
          " and p/fechaLlegada le " +
          formatDateTime_parameter(end) +
          ") eq true)",
      ],
    ];
  };

  const datasource_tblParteTransporte_postProcess = (data) => {
    data.forEach((d) => {
      d.tblParadaNParteTransporte.forEach((l) => {
        let fechaLlegada =
          l.fechaLlegada &&
          new Date(
            Date.UTC(
              l.fechaLlegada.getFullYear(),
              l.fechaLlegada.getMonth(),
              l.fechaLlegada.getDate(),
              l.fechaLlegada.getHours(),
              l.fechaLlegada.getMinutes(),
              l.fechaLlegada.getSeconds(),
            ),
          );
        let fechaSalida =
          l.fechaSalida &&
          new Date(
            Date.UTC(
              l.fechaSalida.getFullYear(),
              l.fechaSalida.getMonth(),
              l.fechaSalida.getDay(),
              l.fechaSalida.getHours(),
              l.fechaSalida.getMinutes(),
              l.fechaSalida.getSeconds(),
            ),
          );
        l.fechaLlegada =
          l.fechaLlegada &&
          convertClienteUtcToLavanderiaUtc(lavanderia.GMT, fechaLlegada);
        l.fechaSalida =
          l.fechaSalida &&
          convertClienteUtcToLavanderiaUtc(lavanderia.GMT, fechaSalida);
      });
    });

    setDataParteTrabajo(
      data.map((e) => {
        const { nombre, apellidos } =
          e.idUsuarioResponsableNavigation.idPersonaNavigation;

        return {
          idEstado: e.idEstado,
          idParteTransporte: e.idParteTransporte,
          denoRutaExpedicion: e.denoRutaExpedicion,
          vehiculo_denominacion: e.idVehiculoNavigation.denominacion,
          vehiculo_matricula: e.idVehiculoNavigation.matricula,
          nombreUsuario: `${nombre} ${apellidos}`,
          estado: e.idEstadoNavigation.denominacion,
          fecha:
            e.tblParadaNParteTransporte[0].fechaLlegada &&
            e.tblParadaNParteTransporte[0].fechaLlegada.toLocaleDateString(
              "es-ES",
            ),
        };
      }),
    );

    setDataParadaNParteTrabajo(
      data.map((e) => {
        return {
          idParteTransporte: e.idParteTransporte,
          tblParadaNParteTransporte: e.tblParadaNParteTransporte
            .filter((x) => !x.fechaCancelado)
            .map((x) => {
              return {
                ...x,
                idEstado: e.idEstado,
              };
            }),
        };
      }),
    );

    let paradas_ = [];
    data.map((d) => {
      return (paradas_ = [
        ...paradas_,
        ...d.tblParadaNParteTransporte
          .filter((e) => {
            return (
              (e.idLavanderiaNavigation &&
                e.idLavanderiaNavigation.coordenadas) ||
              (e.idEntidadNavigation && e.idEntidadNavigation.coordenadas)
            );
          })
          .map((e) => {
            let { denominacion, coordenadas } =
              e.idLavanderiaNavigation || e.idEntidadNavigation;

            coordenadas = coordenadas.split(",");
            coordenadas = [
              parseFloat(coordenadas[1]),
              parseFloat(coordenadas[0]),
            ];

            return {
              denominacion,
              type: e.idLavanderiaNavigation ? "lavanderia" : "entidad",
              coordenadas,
            };
          }),
      ]);
    });
    paradas_ = [
      ...new Map(paradas_.map((item) => [item["denominacion"], item])).values(),
    ];

    setLavanderias(paradas_.filter((x) => x.type === "lavanderia"));
    setEntidades(paradas_.filter((x) => x.type === "entidad"));

    let flags_ = [];

    data.forEach((d) => {
      let parada = d.tblParadaNParteTransporte
        .filter((e) => {
          return (
            (e.idLavanderiaNavigation &&
              e.idLavanderiaNavigation.coordenadas) ||
            (e.idEntidadNavigation && e.idEntidadNavigation.coordenadas)
          );
        })
        .map((e) => {
          let { coordenadas } =
            e.idLavanderiaNavigation || e.idEntidadNavigation;

          coordenadas = coordenadas.split(",");
          coordenadas = [
            parseFloat(coordenadas[1]),
            parseFloat(coordenadas[0]),
          ];

          return {
            coordenadas,
            fechaLlegada: e.fechaLlegada,
            fechaSalida: e.fechaSalida,
          };
        });

      parada.forEach((parada) => {
        if (parada.fechaSalida != null) {
          flags_.push({
            idParteTransporte: d.idParteTransporte,
            coordenadas: parada.coordenadas,
            fecha: parada.fechaSalida,
            duracion: miliSecondsToHMS(
              parada.fechaSalida - parada.fechaLlegada,
            ),
            type: "salida",
          });
        } else if (parada.fechaLlegada != null) {
          flags_.push({
            idParteTransporte: d.idParteTransporte,
            coordenadas: parada.coordenadas,
            fecha: parada.fechaLlegada,
            duracion: miliSecondsToHMS(new Date() - parada.fechaLlegada),
            type: "llegada",
          });
        }
      });
    });

    setFlags(flags_);

    return data;
  };

  return (
    <visorRutasContext.Provider
      value={{
        idParteTransporteSel,
        setIdParteTransporteSel,
        dataParteTrabajo,
        setDataParteTrabajo,
        dataParadaNParteTrabajo,
        setDataParadaNParteTrabajo,
        rutas,
        setRutas,
        lavanderias,
        setLavanderias,
        entidades,
        setEntidades,
        pausas,
        setPausas,
        vehiculos,
        setVehiculos,
        flags,
        setFlags,
        date,
        setDate,
        lastFechaLocalizacion,
        setLastFechaLocalizacion,
        stopInterval,
      }}
    >
      {children}
    </visorRutasContext.Provider>
  );
};
