import { tasaCambioConstants } from "constants/tasaCambio";
import { tasaCambioService } from "services/tasaCambio";

export const tasaCambioActions = {
    loadTasaCambio,
    clearTasaCambio,
};

function loadTasaCambio(fechaDesde, fechaHasta) {
    return (dispatch) => {
        let promise = tasaCambioService.loadTasaCambio(fechaDesde, fechaHasta);

        promise.then(
            (data) => {
                dispatch(success(data));
            },
            (error) => {
                dispatch(failure(error));
            }
        );

        return promise;
    };

    function success(data) {
        return { type: tasaCambioConstants.LOAD_TASACAMBIO_SUCCESS, data };
    }
    function failure(error) {
        return { type: tasaCambioConstants.LOAD_TASACAMBIO_FAILURE, error };
    }
}

function clearTasaCambio() {
    return (dispatch) => {
        dispatch(success());
    };

    function success() {
        return { type: tasaCambioConstants.CLEAR_TASACAMBIO_SUCCESS };
    }
}
