import React from "react";
import { Row } from "reactstrap";
import { SVContext } from "../../components/SmartViewContext";
import Persona from "./Persona";
import { ScrollView } from "devextreme-react";

class Area extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <SVContext.Consumer>
                {({ tblPersonas }) => {
                    const { id } = this.props;
                    const data = tblPersonas.filter((x) => x.idAreaLavanderia === id);

                    return (
                        <div className="he-100 position-absolute" style={{ right: 0, left: 0 }}>
                            <ScrollView>
                                <Row className={`justify-content-center align-content-start flex-wrap flex-row px-3`}>
                                    {data.map((x, i) => {
                                        return <Persona key={`idPers-${x.idPersona}-${i}`} data={x} />;
                                    })}
                                </Row>
                            </ScrollView>
                        </div>
                    );
                }}
            </SVContext.Consumer>
        );
    }
}

export default Area;
