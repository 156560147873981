import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  dxMensajePregunta,
} from "../../../helpers";
import { svg_edit } from "../../../styles/svg_iconos";
import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var grupoSel;
  var grupoMod;

  //#region Datasource

  //Datasource GrupoMaquina
  var datasource_grupoMaquina = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblGrupoMaquina",
      key: "idGrupoMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idGrupoMaquina", "peso", "denominacion"],
  });
  //Datasource maquinaGrupo
  var datasource_maquina = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMaquina",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;

        if (request.method === "get") {
          request.params.idGrupoMaquina = grupoSel
            ? grupoSel.idGrupoMaquina
            : -1;
        }
      },
      version: 4,
    }),
    select: [
      "idMaquina",
      "etiqueta",
      "denominacion",
      "numSerie",
      "peso",
      "restrictiva",
    ],
  });

  //Datasource añadir máquina
  var datasource_añadirMaquina = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMaquina",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;

        if (request.method === "get") {
          request.params.idGrupoMaquina = null;
        }
      },
      version: 4,
    }),
    select: ["idMaquina", "etiqueta", "denominacion", "numSerie"],
  });

  //#endregion

  //#region Controles

  $("#GruposMaquinas #dxContainer").dxBox({
    direction: "row",
    height: "100%",
  });

  //Grid Maquinária
  $("#GruposMaquinas #dgvGruposMaquinas").dxDataGrid({
    //Datos
    dataSource: datasource_grupoMaquina,
    remoteOperations: {
      groupPaging: true,
      paging: true,
      filtering: true,
      sorting: true,
      grouping: true,
      summary: false,
    },
    //Propiedades
    height: "100%",
    columnAutoWidth: true,
    selection: {
      mode: "single",
    },
    paging: {
      enabled: false,
    },
    editing: {
      mode: "batch",
      allowUpdating: true,
      allowDeleting: true,
      allowAdding: true,
      useIcons: true,
    },
    keyboardNavigation: {
      enterKeyAction: "moveFocus",
      enterKeyDirection: "column",
      editOnKeyPress: true,
    },
    columns: [
      {
        caption: " ",
        width: "30px",
        alignment: "center",
        cssClass: "p-0",
        cellTemplate: function (cellElement, cellInfo) {
          var contenedor = $("<div/>").addClass("container_spanCentrado");
          svg_edit
            .clone()
            .css("height", "25px")
            .css("width", "25px")
            .css("cursor", "pointer")
            .appendTo(contenedor);

          cellElement.on("click", function () {
            grupoMod = cellInfo.data;
            $("#popup_añadirGrupoMaquina").dxPopup("instance").show();
          });

          contenedor.appendTo(cellElement);
        },
        allowSearch: false,
      },
      {
        dataField: "denominacion",
        caption: getTrad("denominacion"),
        minWidth: 100,
        sortIndex: 1,
        sortOrder: "asc",
        allowEditing: false,
        allowHeaderFiltering: false,
        allowUpdating: false,
      },
      {
        dataField: "peso",
        caption: getTrad("peso"),
        width: 100,
        allowSearch: false,
        alignment: "center",
        cssClass: "dx-Cell_Editable",
        format: { style: "decimal", maximumFractionDigits: 0 },
        editorOptions: {
          step: 0,
          format: { style: "decimal", maximumFractionDigits: 0 },
          min: 0,
          max: 100,
        },
      },
    ],
    summary: {
      recalculateWhileEditing: true,
      totalItems: [
        {
          name: "sumaPesoGrupo",
          column: "peso",
          summaryType: "sum",
        },
      ],
    },

    //Eventos
    onSelectionChanged: function (e) {
      var gridMaquinas = $("#dgvMaquinas").dxDataGrid("instance");
      var hasChanges = gridMaquinas.getController("editing").hasChanges();

      if (e.selectedRowsData.length > 0) {
        if (hasChanges === true) {
          dxMensajePregunta(getTrad("preg_PerderCambios"), [
            [
              getTrad("aceptar"),
              function () {
                var gridMaquinas = $("#dgvMaquinas").dxDataGrid("instance");
                var data = e.selectedRowsData[0];
                gridMaquinas.cancelEditData();
                grupoSel = data;
                gridMaquinas.refresh(true);
              },
              "normal",
              "btnAceptar_alert",
            ],
            [
              getTrad("cancelar"),
              function () {
                e.component.option(
                  "selectedRowKeys",
                  e.currentDeselectedRowKeys,
                );
              },
              "normal",
              "btnCancelar_alert",
            ],
          ]);
        } else {
          var data = e.selectedRowsData[0];
          grupoSel = data;
          gridMaquinas.refresh(true);
        }
      } else {
        grupoSel = null;
        gridMaquinas.refresh(true);
      }
    },

    onRowUpdating: function (e) {
      var pesoSum = e.component.getTotalSummaryValue("sumaPesoGrupo");
      if (comprobarPeso(pesoSum) === false) {
        e.cancel = true;
      }
    },
    onRowRemoving: function (e) {
      var pesoSum = e.component.getTotalSummaryValue("sumaPesoGrupo");
      if (comprobarPeso(pesoSum) === false) {
        e.cancel = true;
      }
      var dgvGruposMaquinas = $("#dgvGruposMaquinas").dxDataGrid("instance");
      dgvGruposMaquinas.option("focusedRowKey", null);
      dgvGruposMaquinas.option("focusedRowIndex", -1);
      dgvGruposMaquinas.repaint();
      var dgvMaquina = $("#dgvMaquinas").dxDataGrid("instance");
      dgvMaquina.refresh();
      dgvMaquina.cancelEditData(true);
    },

    onToolbarPreparing: function (e) {
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(true);
          },
        },
      });
      $.each(e.toolbarOptions.items, function (index, item) {
        switch (item.name) {
          case "addRowButton":
            item.location = "before";
            item.showText = "always";
            item.options.text = getTrad("añadir");
            item.options.icon = "plus";
            item.options.onClick = function () {
              $("#popup_añadirGrupoMaquina").dxPopup("instance").show();
            };
            break;
          default:
            break;
        }
      });
    },
    //Estilos
    showColumnLines: false,
    showRowLines: true,
    rowAlternationEnabled: true,
  });

  $("#GruposMaquinas #dgvMaquinas").dxDataGrid({
    //Datos
    dataSource: datasource_maquina,
    remoteOperations: {
      groupPaging: true,
      paging: true,
      filtering: true,
      sorting: true,
      grouping: true,
      summary: false,
    },
    //Propiedades
    height: "100%",
    columnAutoWidth: true,
    paging: {
      enabled: false,
    },
    sorting: {
      mode: "multiple",
    },
    editing: {
      mode: "batch",
      allowUpdating: true,
      allowAdding: true,
      allowDeleting: true,
      useIcons: true,
    },
    keyboardNavigation: {
      enterKeyAction: "moveFocus",
      enterKeyDirection: "column",
      editOnKeyPress: true,
    },
    columns: [
      {
        dataField: "etiqueta",
        caption: getTrad("etiqueta"),
        minWidth: 40,
        allowEditing: false,
      },
      {
        dataField: "denominacion",
        caption: getTrad("denominacion"),
        minWidth: 100,
        sortIndex: 1,
        sortOrder: "asc",
        allowEditing: false,
      },
      {
        dataField: "numSerie",
        caption: getTrad("numSerie"),
        minWidth: 100,
        allowEditing: false,
        hidingPriority: 0,
      },
      {
        dataField: "peso",
        caption: getTrad("peso"),
        width: 100,
        allowEditing: true,
        alignment: "center",
        cssClass: "dx-Cell_Editable",
        format: { style: "decimal", maximumFractionDigits: 0 },
        editorOptions: {
          step: 0,
          format: { style: "decimal", maximumFractionDigits: 0 },
          min: 0,
          max: 100,
        },
      },
      {
        dataField: "restrictiva",
        caption: getTrad("restrictiva"),
        width: 130,
        sortIndex: 0,
        sortOrder: "desc",
        allowEditing: false,
        editCellTemplate: function (container, options) {
          return $("<div/>").dxSwitch({
            switchedOnText: getTrad("restrictiva"),
            switchedOffText: getTrad("noRestrictiva"),
            value:
              typeof options.data.restrictiva !== "undefined"
                ? options.data.restrictiva
                : true,
            onValueChanged: function (e) {
              if (options.component.getController("editing").isEditing()) {
                options.setValue(e.value);
              } else {
                //Update
                var grupo = {};
                grupo.restrictiva = e.value;
                datasource_maquina
                  .store()
                  .update(options.data.idMaquina, grupo)
                  .done(function () {
                    notify({
                      message: getTrad("aviso_C_RegistroActualizado"),
                      type: "success",
                      displayTime: "1500",
                      closeOnClick: true,
                    });
                    options.component.refresh(true);
                  });
              }
            },
          });
        },
      },
    ],
    summary: {
      recalculateWhileEditing: true,
      totalItems: [
        {
          name: "sumaPesoMaquina",
          column: "peso",
          summaryType: "sum",
        },
      ],
    },
    //Eventos
    onToolbarPreparing: function (e) {
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(true);
          },
        },
      });
      $.each(e.toolbarOptions.items, function (index, item) {
        switch (item.name) {
          case "addRowButton":
            item.location = "before";
            item.showText = "always";
            item.options.text = getTrad("añadir");
            item.options.onClick = function () {
              if (!grupoSel) {
                notify({
                  message: getTrad("alerta_SeleccioneGrupoMaquina"),
                  type: "error",
                  displayTime: "2500",
                  closeOnClick: true,
                });
              } else {
                $("#popup_añadirMaquina").dxPopup("instance").show();
                $("#dgvAñadirMaquina").dxDataGrid("instance").refresh(true);
              }
            };
            break;
          default:
            break;
        }
      });
    },
    onRowUpdating: function (e) {
      var pesoSum = e.component.getTotalSummaryValue("sumaPesoMaquina");
      if (comprobarPeso(pesoSum) === false) {
        e.cancel = true;
      }
    },

    onRowRemoving: function (e) {
      e.cancel = true;
      var pesoSum = e.component.getTotalSummaryValue("sumaPesoMaquina");
      if (comprobarPeso(pesoSum)) {
        var idMaquina = e.data.idMaquina;
        var peso = 0;
        var idGrupoMaquina = null;
        var maquinaselect = {};
        maquinaselect.peso = peso;
        maquinaselect.idGrupoMaquina = idGrupoMaquina;

        datasource_añadirMaquina
          .store()
          .update(idMaquina, maquinaselect)
          .done(function () {
            e.component.refresh().done(function () {
              e.component.cancelEditData(); // Como los cambios no se hacen a través del grid, hay que cancelarlos una vez hechos para que el grid esté en su estado inicial.
              e.component.repaint();

              if (
                typeof $("#dgvAñadirMaquina").data("dxDataGrid") !== "undefined"
              )
                $("#dgvAñadirMaquina").dxDataGrid("instance").refresh(true);

              notify({
                message: getTrad("aviso_C_RegistroEliminado"),
                type: "success",
                displayTime: "2500",
                closeOnClick: true,
              });
            });
          });
      }
    },
    //Estilos
    showColumnLines: false,
    showRowLines: true,
    rowAlternationEnabled: true,
  });
  //Popup añadirMaquina
  $("#GruposMaquinas #popup_añadirMaquina").dxPopup({
    title: getTrad("seleccioneMaq"),
    width: "800px",
    height: "500px",
    contentTemplate: function () {
      return $("<div id='dgvAñadirMaquina'/>").dxDataGrid({
        //Datos
        dataSource: datasource_añadirMaquina,
        remoteOperations: true,
        //Propiedades
        height: "100%",
        columnAutoWidth: true,
        searchPanel: {
          visible: true,
          width: 240,
          placeholder: getTrad("busqueda"),
        },
        paging: {
          pageSize: 50,
        },
        pager: {
          showPageSizeSelector: true,
          allowedPageSizes: [20, 50, 100],
          showInfo: true,
        },
        //Columnas
        columns: [
          {
            dataField: "etiqueta",
            caption: getTrad("etiqueta"),
            allowFiltering: true,
            allowHeaderFiltering: true,
          },
          {
            dataField: "denominacion",
            caption: getTrad("denominacion"),
            allowFiltering: true,
            allowHeaderFiltering: true,
            sortOrder: "asc",
            sortIndex: 1,
          },
          {
            dataField: "numSerie",
            caption: getTrad("numSerie"),
            allowFiltering: true,
            allowHeaderFiltering: true,
          },
        ],
        //Eventos
        onToolbarPreparing: function (e) {
          e.toolbarOptions.items.unshift(
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              locateInMenu: "auto",
              options: {
                text: getTrad("limpiarFiltro"),
                icon: " icon_EliminarFiltros",
                onClick: function () {
                  e.component.clearFilter();
                  notify({
                    message: getTrad("aviso_C_FiltroRestaurado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                },
              },
            },
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              options: {
                icon: "refresh",
                text: getTrad("actualizar"),
                onClick: function () {
                  e.component.refresh(true);
                },
              },
            },
          );
        },
        onRowClick: function (e) {
          var maquinaselect = e.data;

          var idMaquina = maquinaselect.idMaquina;
          maquinaselect.peso = 0;
          maquinaselect.idGrupoMaquina = grupoSel.idGrupoMaquina;
          datasource_añadirMaquina
            .store()
            .update(idMaquina, maquinaselect)
            .done(function () {
              $("#dgvMaquinas").dxDataGrid("instance").refresh(true);
              e.component.refresh(true);
              $("#popup_añadirMaquina").dxPopup("instance").hide();
              notify({
                message: getTrad("aviso_C_RegistroInsertado"),
                type: "success",
                displayTime: "2500",
                closeOnClick: true,
              });
            });
          e.component.repaint();
        },
        //Estilos
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });
    },
  });

  $("#GruposMaquinas #popup_añadirGrupoMaquina").dxPopup({
    title: getTrad("nuevoGrupoMaq"),
    width: "350px",
    height: "235px",
    onHidden: function (e) {
      $("#dxForm_añadirGrupoMaquina")
        .dxForm("instance")
        .getEditor("denominacion")
        .option("value", "");
      grupoMod = null;
    },
    onShowing: function (e) {
      if (grupoMod) {
        $("#dxForm_añadirGrupoMaquina")
          .dxForm("instance")
          .getEditor("denominacion")
          .option("value", grupoMod.denominacion);
      }
    },
    contentTemplate: function () {
      var container = $("<div></div>");
      $("<div id='dxForm_añadirGrupoMaquina'>")
        .dxForm({
          validationGroup: datasource_grupoMaquina,
          formData: datasource_grupoMaquina,
          items: [
            {
              dataField: "denominacion",
              label: {
                location: "top",
              },
              validationRules: [
                {
                  type: "required",
                  message: "Campo requerido",
                },
                {
                  type: "pattern",
                  pattern: "^[_A-zÀ-ú0-9 ]*((-|s)*[_A-zÀ-ú0-9 ])*$",
                  message: "La denominacion no admite valores especiales",
                },
              ],
            },
          ],
        })
        .appendTo(container);
      return container;
    },

    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("guardar"),

          onClick: function (e) {
            var dxFormPopup = $("#dxForm_añadirGrupoMaquina").dxForm(
              "instance",
            );
            if (dxFormPopup.validate().isValid === true) {
              var denominacionGrupo = $("#dxForm_añadirGrupoMaquina")
                .dxForm("instance")
                .getEditor("denominacion")
                .option("value");

              if (grupoMod) {
                var idGrupo = grupoMod.idGrupoMaquina;
                var grupoModInsert = {};

                grupoModInsert.denominacion = denominacionGrupo;
                grupoModInsert.peso = grupoMod.peso;
                datasource_grupoMaquina
                  .store()
                  .update(idGrupo, grupoModInsert)
                  .done(function () {
                    $("#popup_añadirGrupoMaquina").dxPopup("instance").hide();
                    $("#dgvGruposMaquinas")
                      .dxDataGrid("instance")
                      .refresh(true);
                  });
              } else {
                var grupoInsert = {};

                grupoInsert.denominacion = denominacionGrupo;
                grupoInsert.peso = 0;
                var dgvGrupoMaquinas =
                  $("#dgvGruposMaquinas").dxDataGrid("instance");
                dgvGrupoMaquinas.option("focusedRowIndex", -1);
                dgvGrupoMaquinas.option("focusedRowKey", null);
                datasource_grupoMaquina
                  .store()
                  .insert(grupoInsert)
                  .done(function (data, idGrupoMaquina) {
                    $("#dxForm_añadirGrupoMaquina")
                      .dxForm("instance")
                      .getEditor("denominacion")
                      .option("value", "");
                    $("#popup_añadirGrupoMaquina").dxPopup("instance").hide();
                    dgvGrupoMaquinas.refresh(true).done(function () {
                      var index =
                        dgvGrupoMaquinas.getRowIndexByKey(idGrupoMaquina);
                      if (index !== -1) {
                        dgvGrupoMaquinas.option("focusedRowIndex", index);
                      } else {
                        dgvGrupoMaquinas.option(
                          "focusedRowKey",
                          idGrupoMaquina,
                        );
                      }
                    });
                  });
              }
            }
          },
        },
      },
    ],
  });

  //#endregion

  function comprobarPeso(pesoSum) {
    if (pesoSum < 100) {
      notify({
        message: getTrad("alerta_PesoMayor"),
        type: "error",
        displayTime: "2500",
        closeOnClick: true,
      });
      return false;
    }
    if (pesoSum > 100) {
      notify({
        message: getTrad("alerta_PesoMenor"),
        type: "error",
        displayTime: "2500",
        closeOnClick: true,
      });
      return false;
    }
    return true;
  }

  deferred.resolve();
  return deferred.promise();
}
