import React, { Fragment } from "react";
import { connect } from "react-redux";

//Layout
//Js

//Componentes
import { authHeader, errorHandler, getTrad } from "helpers";

import "./css.scss";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "constants";
import { Button, DataGrid, Popup } from "devextreme-react";
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  KeyboardNavigation,
  Paging,
  Selection,
} from "devextreme-react/data-grid";
import Box, { Item as ItemBox } from "devextreme-react/box";

class PopupPreguntasGrafico extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      descripcionPregunta: null,
    };

    this.dxDataGrid_REF = React.createRef();
    this.dxPopUp_REF = React.createRef();
  }

  render() {
    const { visible } = this.props;

    return (
      <Popup
        ref={this.dxPopUp_REF}
        visible={visible}
        onHiding={this.onHiding}
        titleRender={this.titleRender}
        showTitle={true}
        width={"50%"}
        height={"80%"}
        dragEnabled={false}
      >
        <DataGrid
          ref={this.dxDataGrid_REF}
          dataSource={this.dataSource_infRespuestas}
          className="he-100"
          remoteOperations={false}
          showColumnLines={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
          onRowClick={this.seleccionaPregunta}
          noDataText={getTrad("sinDatos")}
          keyExpr="idPregunta"
        >
          <Editing
            mode="cell"
            allowUpdating={true}
            selectTextOnEditStart={true}
          />
          <Paging enabled={false} />
          <HeaderFilter visible={true} />
          <FilterRow visible={true} />
          <Selection mode={"single"} />
          <KeyboardNavigation
            enterKeyAction="moveFocus"
            enterKeyDirection="row"
            editOnKeyPress={true}
          />
          <Column
            dataField="nombre"
            caption={getTrad("usuario")}
            dataType="string"
            allowHeaderFiltering={true}
            allowSearch={true}
            minWidth={225}
            allowEditing={false}
            sortOrder="asc"
            sortIndex={1}
          />
          <Column
            dataField="descripcionOpcion"
            caption={getTrad("respuesta")}
            dataType="string"
            allowHeaderFiltering={true}
            allowSearch={true}
            minWidth={225}
            allowEditing={false}
          />
        </DataGrid>
      </Popup>
    );
  }

  titleRender = () => {
    const { descripcionPregunta } = this.state;

    return (
      <Box className="d-flex align-items-center">
        <ItemBox ratio={1}>
          <div className="font-size-lg">{descripcionPregunta}</div>
        </ItemBox>
        <ItemBox baseSize={20}>
          <Button
            icon="close"
            stylingMode="text"
            hoverStateEnabled={false}
            activeStateEnabled={false}
            focusStateEnabled={false}
            onClick={this.onHiding}
          />
        </ItemBox>
      </Box>
    );
  };

  onHiding = () => {
    const { onValueChange } = this.props;
    onValueChange({ idPreguntaSel: null });
  };

  get dxDataGrid() {
    return this.dxDataGrid_REF.current.instance;
  }

  get dxPopUp() {
    return this.dxPopUp_REF.current.instance;
  }

  dataSource_infRespuestas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Encuestas/getDatosEncuestas_infRespuestas",
      key: "idRespuesta",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_infRespuestas_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_infRespuestas_beforeSend = (request) => {
    const { idEncuestaSel, idLavanderiaSel, idPreguntaSel, idCompañiaSel } =
      this.props;

    request.headers = { ...authHeader() };
    request.params.idEncuesta = idEncuestaSel;
    request.params.idPregunta = idPreguntaSel;
    request.params.idLavanderia = idLavanderiaSel;
    request.params.idCompañia = idCompañiaSel;

    if (idEncuestaSel) {
      request.params.idEncuesta = idEncuestaSel;
    } else {
      request.response = { value: [] }; // Return an empty array in the response
    }
  };

  componentDidUpdate(prevProps) {
    const { idPreguntaSel } = this.props;
    if (prevProps.idPreguntaSel == null && idPreguntaSel !== null) {
      this.dxDataGrid.clearFilter();
      this.dataSource_infRespuestas.reload().done((data) => {
        this.setState({ descripcionPregunta: data[0]?.pregunta });
      });
    }
  }
}
const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(PopupPreguntasGrafico);
