import React, { Fragment } from "react";

import { getTrad } from "../../../../helpers";

import $ from "jquery";

import Box, { Item as ItemBox } from "devextreme-react/box";
import { ButtonGroup } from "devextreme-react/button-group";

import DateRangePicker from "components/DateRangePicker/DateRangePicker";
import ViewSmartHub from "./ViewSmartHub";
import ViewPersonaNArea from "./ViewPersonaNArea";

class ControlRendimiento extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group: this.enum_filtro[0],
      fechaIni: new Date(),
      fechaFin: new Date(),
    };
  }

  //DATSOURCES
  enum_filtro = [
    {
      key: "maquina",
      denominacion: getTrad("maquina"),
      icon: "icon_Lavadora",
      color: "--red",
      subGroup: { key: "tipoPrenda", denominacion: getTrad("familia") },
    },
    {
      key: "tipoPrenda",
      denominacion: getTrad("familia"),
      icon: "icon_Camisa",
      color: "--blue",
      subGroup: { key: "cliente", denominacion: getTrad("cliente") },
    },
    {
      key: "cliente",
      denominacion: getTrad("cliente"),
      icon: "icon_Entidad",
      color: "--yellow",
      subGroup: { key: "tipoPrenda", denominacion: getTrad("familia") },
    },
    {
      key: "persona",
      denominacion: getTrad("persona"),
      icon: "icon_Persona",
      color: "--success",
      subGroup: { key: "fecha", denominacion: getTrad("fecha") },
    },
  ];

  render() {
    const { group, fechaIni, fechaFin } = this.state;
    const { filtro_1 } = this.props;

    const fechaSel = {
      startDate: fechaIni,
      endDate: fechaFin,
      idTipoCalendario: 2,
    };

    const hoy = new Date();
    const ayer = new Date(hoy.setDate(hoy.getDate() - 1));

    return (
      <Fragment>
        <Box direction="col" width="auto" height="100%">
          <ItemBox baseSize={60}>
            <Box direction="row" width="auto" height="100%">
              <ItemBox ratio={1}>
                <ButtonGroup
                  height="100%"
                  width="600"
                  items={this.enum_filtro}
                  keyExpr="key"
                  selectedItemKeys={[group.key]}
                  buttonRender={this.dxButtonGroup_buttonRender}
                  onItemClick={this.dxButtonGroup_onItemClick}
                />
              </ItemBox>
              <ItemBox baseSize={"auto"}>
                <div className="d-flex flex-row he-100 align-items-center">
                  <div className="font-weight-bold pr-3">Fecha:</div>
                  <DateRangePicker
                    month
                    year
                    day
                    defaultType="personalizado"
                    fechaSel={fechaSel}
                    align={"right"}
                    maxDate={group.key === "persona" ? ayer : new Date()}
                    onDateSelected={this.dateRangePicker_onDateSelected}
                  />
                </div>
              </ItemBox>
            </Box>
          </ItemBox>
          <ItemBox baseSize={20} />
          <ItemBox ratio={1}>
            <div className="he-100">
              {group.key === "persona" ? (
                <ViewPersonaNArea
                  group={group}
                  fechaIni={fechaIni}
                  fechaFin={fechaFin}
                />
              ) : (
                <ViewSmartHub
                  group={group}
                  fechaIni={fechaIni}
                  fechaFin={fechaFin}
                  filtro_1={filtro_1}
                />
              )}
            </div>
          </ItemBox>
        </Box>
      </Fragment>
    );
  }

  dxButtonGroup_buttonRender = (data) => {
    return (
      <div
        className="d-flex he-100 justify-content-center align-items-center"
        style={{ "--buttonGroup_color": "var(" + data.color + ")" }}
      >
        <i
          style={{ flexBasis: 30, color: "red" }}
          className={"font-size-xxl w-100 " + data.icon}
        ></i>
        <span
          style={{ lineHeight: "12px" }}
          className="d-flex flex-column w-100 he-100 align-items-center justify-content-center"
        >
          {data.denominacion}
        </span>
      </div>
    );
  };

  dxButtonGroup_onItemClick = (e) => {
    const { fechaIni, fechaFin } = this.state;

    const group = e.itemData;
    let newState = { group };

    if (group.key === "persona") {
      const hoy = new Date();
      const fechaFinComparable = new Date(fechaFin).setHours(0, 0, 0, 0);
      const hoyComparable = hoy.setHours(0, 0, 0, 0);

      if (fechaFinComparable === hoyComparable) {
        newState.fechaFin = new Date(new Date().setDate(hoy.getDate() - 1));
        newState.fechaIni =
          fechaIni > newState.fechaFin ? newState.fechaFin : fechaIni;
      }
    }
    this.setState(newState);
  };

  dateRangePicker_onDateSelected = (fecha) => {
    if (fecha) {
      this.setState({ fechaIni: fecha.startDate, fechaFin: fecha.endDate });
    }
  };

  componentDidUpdate(prevProps) {
    let { group } = this.props;

    let newState = {};
    if (prevProps.group !== group) {
      if (group) {
        newState.group = this.enum_filtro.filter((x) => x.key === group)[0];
      } else {
        newState.group = this.enum_filtro[0];
      }
    }

    if (!$.isEmptyObject(newState)) {
      this.setState(newState);
    }
  }
}

export default ControlRendimiento;
