import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
    errorHandler,
    authHeader,
    formatNumber,
    getTrad,
    getNombreFormulario,
    formatDate_parameter,
    formatDate,
    tooltipControl_creacion,
} from "../../../helpers";

//Actions
import { loadPanelActions, globalActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import CardSelector from "components/CardSelector";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { Col } from "reactstrap";

import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import { SelectBox } from "devextreme-react/select-box";
import { Tooltip } from "devextreme-react/tooltip";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";

import Chart, {
    CommonSeriesSettings,
    Legend,
    Series,
    ValueAxis,
    ArgumentAxis,
    CommonAxisSettings,
    Grid,
    Tooltip as TooltipChart,
} from "devextreme-react/chart";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Pager,
    Selection,
    Summary,
    TotalItem,
    RemoteOperations,
    Grouping,
    GroupItem,
    Paging,
} from "devextreme-react/data-grid";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import { Switch } from "devextreme-react";

class IngresosCostes extends React.Component {
    constructor(props) {
        super(props);

        //El primer día del mes carga el mes anterior por defecto.
        var month = new Date().getDay() > 1 ? new Date().getMonth() : new Date().getMonth() - 1;

        this.state = {
            fechaSel: new Date(new Date().getFullYear(), month, 1, 0, 0, 0),
            datosSelector_Lavanderia: [],
            datosSelector_Informes: [],
            breaks_Chart: [],
            dxMultiView_index: 0,
            tabPanel_selectedIndex: 0,
            idPaisSel: null,
            idCorporacionSel: null,
            kgEstancia_compañiaSel: null,
            logistica_compañiaSel: null,
            tipoKpiSel: null,
            soloJornadaPersona: false,
        };

        //DATASOURCE
        this.datasource_datos_lavanderia_beforeSend = this.datasource_datos_lavanderia_beforeSend.bind(this);
        this.datasource_datos_lavanderia_postProcess = this.datasource_datos_lavanderia_postProcess.bind(this);
        this.dataSource_tblCorporacion_onLoaded = this.dataSource_tblCorporacion_onLoaded.bind(this);
        this.dataSource_tblPais_onLoaded = this.dataSource_tblPais_onLoaded.bind(this);

        this.datasource_datos_compañia_beforeSend = this.datasource_datos_compañia_beforeSend.bind(this);
        this.datasource_datos_compañia_diario_beforeSend = this.datasource_datos_compañia_diario_beforeSend.bind(this);
        this.datasource_datos_entidad_beforeSend = this.datasource_datos_entidad_beforeSend.bind(this);
        this.datasource_datos_entidad_diario_beforeSend = this.datasource_datos_entidad_diario_beforeSend.bind(this);
        this.datasource_datos_tipoKpi_beforeSend = this.datasource_datos_tipoKpi_beforeSend.bind(this);
        this.datasource_datos_tipoKpi_diario_beforeSend = this.datasource_datos_tipoKpi_diario_beforeSend.bind(this);
        this.datasource_datos_tipoKpi_onLoaded = this.datasource_datos_tipoKpi_onLoaded.bind(this);
        this.datasource_datos_RRHH_diario_beforeSend = this.datasource_datos_RRHH_diario_beforeSend.bind(this);

        this.datasource_tblCalendarioLavanderia_beforeSend =
            this.datasource_tblCalendarioLavanderia_beforeSend.bind(this);
        this.datasource_tblCalendarioLavanderia_onLoaded = this.datasource_tblCalendarioLavanderia_onLoaded.bind(this);

        //SELECTOR LAVANDERIA
        this.onCardClick_Lavanderia = this.onCardClick_Lavanderia.bind(this);

        //DATE PICKER
        this.prevDate_Click = this.prevDate_Click.bind(this);
        this.nextDate_Click = this.nextDate_Click.bind(this);
        this.datePicker_onValueChanged = this.datePicker_onValueChanged.bind(this);
        this.datePicker_displayFormat = this.datePicker_displayFormat.bind(this);

        //SELECTOR CORPORACIÓN
        this.getSelectorCorporacion = this.getSelectorCorporacion.bind(this);
        this.selectorCorporacion_onSelectionChanged = this.selectorCorporacion_onSelectionChanged.bind(this);

        //SELECTOR PAÍS
        this.getSelectorPais = this.getSelectorPais.bind(this);
        this.selectorPais_onSelectionChanged = this.selectorPais_onSelectionChanged.bind(this);

        //BACK BUTTON
        this.backButton_onClick = this.backButton_onClick.bind(this);

        //TABPANEL
        this.dxTabPanel_onSelectionChanged = this.dxTabPanel_onSelectionChanged.bind(this);

        //GRIDS
        this.onCellPrepared_ingresos = this.onCellPrepared_ingresos.bind(this);
        this.onCellPrepared_costes = this.onCellPrepared_costes.bind(this);

        //GRID COMPAÑIA
        this.onSelectionChanged_Compañia = this.onSelectionChanged_Compañia.bind(this);
        this.onContentReady_Compañia = this.onContentReady_Compañia.bind(this);

        //GRID ENTIDAD
        this.onSelectionChanged_Entidad = this.onSelectionChanged_Entidad.bind(this);
        this.onContentReady_Entidad = this.onContentReady_Entidad.bind(this);

        //GRID TIPOKPI
        this.onSelectionChanged_TipoKpi = this.onSelectionChanged_TipoKpi.bind(this);
        this.onContentReady_TipoKpi = this.onContentReady_TipoKpi.bind(this);
        this.switch_soloJornadaPersona_valueChanged = this.switch_soloJornadaPersona_valueChanged.bind(this);

        //CHARTS
        this.dxChart_tooltip_contentRender = this.dxChart_tooltip_contentRender.bind(this);
        this.dxChart_numPersonas_tooltip_contentRender = this.dxChart_numPersonas_tooltip_contentRender.bind(this);

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);
        this.loadPanel_show();
    }

    datasource_datos_lavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectIngresosCostes_Lavanderia",
            key: "idLavanderia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_lavanderia_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: (data) => {
            return this.datasource_datos_lavanderia_postProcess(data);
        },
        onChanged: () => {
            this.datasource_datos_lavanderia_onChanged();
        },
    });
    datasource_datos_lavanderia_beforeSend(request) {
        const { fechaSel } = this.state;

        let fechaDesde = new Date(fechaSel.getTime());
        var fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);

        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
    }
    datasource_datos_lavanderia_postProcess(data) {
        let _this = this;

        let paises = this.dataSource_tblPais.items();

        $.each(data, function (index, item) {
            item.id = item.idLavanderia;
            item.cardClass = "row-1";
            item.pais = paises.filter((x) => x.idPais === item.idPais)[0].tblTraduccion.clave;

            item.colorCostes =
                item.costesPrevistos - item.costesPresupuestados > 0
                    ? "danger"
                    : item.costesPrevistos - item.costesPresupuestados < 0
                    ? "success"
                    : "";
            item.colorIngresos =
                item.ingresosPrevistos - item.ingresosPresupuestados < 0
                    ? "danger"
                    : item.ingresosPrevistos - item.ingresosPresupuestados > 0
                    ? "success"
                    : "";
            item.color =
                item.colorCostes === "danger" && item.colorIngresos === "danger"
                    ? "border-danger"
                    : item.colorCostes === "success" && item.colorIngresos === "success"
                    ? "border-success"
                    : item.colorCostes === "" && item.colorIngresos === ""
                    ? ""
                    : "border-primary";

            item.headerContent = function (item) {
                return _this.get_HeaderContent(item);
            };
            item.bodyContent = function (item) {
                return _this.get_BodyContent(item);
            };
        });
        return data;
    }
    datasource_datos_lavanderia_onChanged() {
        const { idPaisSel, idCorporacionSel } = this.state;
        let items = this.datasource_datos_lavanderia.items();

        let datosSelector_Lavanderia_Filtrado = items.filter(
            (x) => ((!idPaisSel || x.idPais === idPaisSel) && !idCorporacionSel) || x.idCorporacion === idCorporacionSel
        );

        this.setState({
            datosSelector_Lavanderia: datosSelector_Lavanderia_Filtrado,
        });
        if (!this.datasource_datos_lavanderia.isLoading()) {
            this.loadPanel_hide();
        }
    }

    dataSource_tblCorporacion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCorporacion",
            key: "idCorporacion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.dataSource_tblCorporacion_beforeSend(request);
            },
            onLoaded: (data) => {
                this.dataSource_tblCorporacion_onLoaded(data);
            },
            version: 4,
        }),
        sort: ["denominacion asc"],
        postProcess: (data) => {
            return this.dataSource_tblCorporacion_postProcess(data);
        },
    });
    dataSource_tblCorporacion_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.conLavanderia = true;
    }
    dataSource_tblCorporacion_onLoaded(data) {
        if (data.length === 1) {
            this.setState({ idCorporacionSel: data[0].idCorporacion });
        }
    }
    dataSource_tblCorporacion_postProcess(data) {
        if (data.length > 1) {
            data.splice(0, 0, { idCorporacion: null, denominacion: "" });
        }
        return data;
    }

    dataSource_tblPais = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPais",
            key: "idPais",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.dataSource_tblPais_beforeSend(request);
            },
            onLoaded: (data) => {
                this.dataSource_tblPais_onLoaded(data);
            },
            version: 4,
        }),
        expand: "tblTraduccion",
        sort: ["denominacion asc"],
        postProcess: (data) => {
            return this.dataSource_tblPais_postProcess(data);
        },
    });
    dataSource_tblPais_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.conLavanderia = true;
    }
    dataSource_tblPais_onLoaded(data) {
        if (data.length === 1) {
            this.setState({ idPaisSel: data[0].idPais });
        }
    }
    dataSource_tblPais_postProcess(data) {
        if (data.length > 1) {
            data.splice(0, 0, { idPais: null, denominacion: "" });
        }
        return data;
    }

    datasource_datos_compañia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectIngresosCostes_Compañia",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_compañia_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_datos_compañia_beforeSend(request) {
        const { lavanderia } = this.props;
        const { fechaSel } = this.state;

        let fechaDesde = new Date(fechaSel.getTime());
        var fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);

        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idLavanderia = lavanderia.idLavanderia;
    }

    datasource_datos_entidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectIngresosCostes_Entidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_entidad_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_datos_entidad_beforeSend(request) {
        const { lavanderia } = this.props;
        const { fechaSel, compañiaSel } = this.state;

        let fechaDesde = new Date(fechaSel.getTime());
        var fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);

        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.idCompañia = compañiaSel ? compañiaSel.idCompañia : -1;
    }

    datasource_datos_tipoKpi = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectIngresosCostes_TipoKpi",
            key: ["idTipoKpi", "idTipoTrabajo"],
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tipoKpi_beforeSend(request);
            },
            onLoaded: (data) => {
                return this.datasource_datos_tipoKpi_onLoaded(data);
            },
            version: 4,
        }),
    });
    datasource_datos_tipoKpi_beforeSend(request) {
        const { lavanderia } = this.props;
        const { fechaSel } = this.state;

        let fechaDesde = new Date(fechaSel.getTime());
        var fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);

        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idLavanderia = lavanderia.idLavanderia;
    }
    datasource_datos_tipoKpi_onLoaded(data) {
        $.each(data, function (index, item) {
            if (item.idTipoKpi !== 5) {
                item.group = "RECURSOS ENERGÉTICOS";
            } //RENDIMIENTO
            else {
                item.group = "RECURSOS HUMANOS";
            }
        });
        return data;
    }

    datasource_datos_compañia_diario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectIngresosCostes_Compañia_diario",
            key: "arg",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_compañia_diario_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_datos_compañia_diario_beforeSend(request) {
        const { lavanderia } = this.props;
        const { fechaSel, compañiaSel } = this.state;

        let fechaDesde = new Date(fechaSel.getTime());
        var fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);

        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.idCompañia = compañiaSel ? compañiaSel.idCompañia : -1;
    }

    datasource_datos_entidad_diario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectIngresosCostes_Entidad_diario",
            key: "arg",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_entidad_diario_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_datos_entidad_diario_beforeSend(request) {
        const { lavanderia } = this.props;
        const { fechaSel, entidadSel } = this.state;

        let fechaDesde = new Date(fechaSel.getTime());
        var fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);

        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.idEntidad = entidadSel ? entidadSel.idEntidad : -1;
    }

    datasource_datos_tipoKpi_diario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectIngresosCostes_TipoKpi_diario",
            key: "arg",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tipoKpi_diario_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_datos_tipoKpi_diario_beforeSend(request) {
        const { lavanderia } = this.props;
        const { fechaSel, tipoKpiSel } = this.state;

        let fechaDesde = new Date(fechaSel.getTime());
        var fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);
        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.idTipoKpi = tipoKpiSel ? tipoKpiSel.idTipoKpi : -1;
        request.params.idTipoTrabajo = tipoKpiSel ? tipoKpiSel.idTipoTrabajo : null;
    }

    datasource_datos_RRHH_diario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectIngresosCostes_RRHH_diario",
            key: "arg",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_RRHH_diario_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_datos_RRHH_diario_beforeSend(request) {
        const { lavanderia } = this.props;
        const { fechaSel, soloJornadaPersona, tipoKpiSel } = this.state;

        let fechaDesde = new Date(fechaSel.getTime());
        var fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);
        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.idTipoTrabajo = tipoKpiSel ? tipoKpiSel.idTipoTrabajo : null;
        request.params.soloJornadaPersona = soloJornadaPersona;
    }

    datasource_tblCalendarioLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCalendarioLavanderia",
            key: ["fecha", "idLavanderia", "idEstado"],
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCalendarioLavanderia_beforeSend(request);
            },
            onLoaded: (data) => {
                return this.datasource_tblCalendarioLavanderia_onLoaded(data);
            },
            version: 4,
        }),
        filter: ["idEstado eq 10"],
    });
    datasource_tblCalendarioLavanderia_beforeSend(request) {
        const { lavanderia } = this.props;
        const { fechaSel } = this.state;

        let fechaDesde = new Date(fechaSel.getTime());
        var fechaHasta = new Date(fechaDesde.getFullYear(), fechaDesde.getMonth() + 1, 0);
        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idLavanderia = lavanderia.idLavanderia;
    }
    datasource_tblCalendarioLavanderia_onLoaded(data) {
        //BREAKS
        let breaks_Chart = [];
        for (let i = 0; i < data.length; i++) {
            let data_ = $.extend([], data);
            const item = data[i];
            const itemSiguiente = data[i + 1];
            const itemAnterior = data[i - 1];

            let ayer = new Date(data_[i].fecha.getTime());
            ayer.setDate(ayer.getDate() - 1);

            let mañana = new Date(data_[i].fecha.getTime());
            mañana.setDate(mañana.getDate() + 1);

            let ultimo_break = breaks_Chart[breaks_Chart.length - 1];
            if (itemSiguiente != null && itemSiguiente.fecha.toString() == mañana.toString()) {
                // Si la siguiente fecha es mañana
                mañana.setDate(mañana.getDate() + 1); // Sumar un día a mañana para que coja ambos días
                breaks_Chart.push({ startValue: ayer, endValue: mañana });
            } else if (
                (itemAnterior != null && item.fecha > ultimo_break.endValue) || // Si no es el 1er item y el item es mayor a la última fecha(es que no está incluido en el array)
                itemAnterior == null
            ) {
                // o si es el 1er item
                breaks_Chart.push({ startValue: ayer, endValue: mañana });
            }
        }
        this.setState({ breaks_Chart: breaks_Chart });
        return data;
    }

    cargaDatos_Lavanderia() {
        let _this = this;

        $.when(
            this.datasource_datos_compañia.reload(),
            this.datasource_datos_tipoKpi.reload(),
            this.datasource_tblCalendarioLavanderia.load()
        ).done(function (items_compañia, items_kpi) {
            _this.setState(
                {
                    compañiaSel: items_compañia[0][0],
                    tipoKpiSel: items_kpi[0][0].items[0],
                },
                () =>
                    setTimeout(() => {
                        _this.cargaDatos_Compañia();
                        _this.cargaDatos_TipoKpi();
                    }, 0)
            );
        });
    }

    cargaDatos_Compañia() {
        let _this = this;
        this.datasource_datos_entidad.reload().done(function (items) {
            _this.setState({ entidadSel: items[0] }, () => _this.cargaDatos_Entidad());
        });
        this.datasource_datos_compañia_diario.reload();
    }

    cargaDatos_Entidad() {
        this.loadPanel_hide();
        this.datasource_datos_entidad_diario.reload();
    }

    cargaDatos_TipoKpi() {
        let { tipoKpiSel } = this.state;
        this.datasource_datos_tipoKpi_diario.load();
        if (tipoKpiSel && tipoKpiSel.idTipoTrabajo !== -1) {
            this.datasource_datos_RRHH_diario.load();
        }
    }

    render() {
        const {
            dxMultiView_index,
            datosSelector_Lavanderia,
            tabPanel_selectedIndex,
            compañiaSel,
            entidadSel,
            tipoKpiSel,
            soloJornadaPersona,
            breaks_Chart,
        } = this.state;

        const { lavanderia } = this.props;
        return (
            <Fragment>
                <PageTitle
                    heading={getNombreFormulario(this)}
                    preHeading={this.getBackButton()}
                    postHeading={[this.getSelectorCorporacion(), this.getSelectorPais(), this.getDatePicker()]}
                />
                <div className={"media-body"}>
                    {/* <ReactCSSTransitionGroup
                className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
                    <MultiView
                        height="100%"
                        width="100%"
                        selectedIndex={dxMultiView_index}
                        loop={true}
                        swipeEnabled={false}
                        deferRendering={false}
                        animationEnabled={true}
                    >
                        <MultiViewItem>
                            <CardSelector
                                data={datosSelector_Lavanderia}
                                onClick={this.onCardClick_Lavanderia}
                                bodyClick={true}
                                groupField="pais"
                                zoom={true}
                            ></CardSelector>
                        </MultiViewItem>
                        <MultiViewItem>
                            <TabPanel
                                className={"font-size-xl pt-2"}
                                height="100%"
                                width="100%"
                                deferRendering={false}
                                animationEnabled={true}
                                swipeEnabled={false}
                                tabIndex={tabPanel_selectedIndex}
                                onSelectionChanged={this.dxTabPanel_onSelectionChanged}
                            >
                                <TabPanelItem title={getTrad("ingresos").toUpperCase()} tabIndex={0}>
                                    <Box direction="col" width="100%" height={"100%"}>
                                        <ItemBox ratio={1}>
                                            <Box direction="row" width="100%" height={"100%"}>
                                                <ItemBox ratio={1}>
                                                    <DataGrid
                                                        className="font-size-md headerRow-lg pr-2"
                                                        //Datos
                                                        dataSource={this.datasource_datos_compañia}
                                                        //Propiedades
                                                        columnsAutoWidth={true}
                                                        height={"100%"}
                                                        width={"100%"}
                                                        hoverStateEnabled={true}
                                                        repaintChangesOnly={true}
                                                        selectedRowKeys={compañiaSel ? [compañiaSel.idCompañia] : []}
                                                        //Estilos
                                                        showColumnLines={false}
                                                        onRowPrepared={this.onRowPrepared_pointer}
                                                        onCellPrepared={this.onCellPrepared_ingresos}
                                                        onSelectionChanged={this.onSelectionChanged_Compañia}
                                                    >
                                                        <RemoteOperations summary={false}></RemoteOperations>
                                                        <Selection mode="single" />
                                                        <SearchPanel visible={false} />
                                                        <HeaderFilter visible={false} />
                                                        <FilterRow visible={false} />
                                                        <Pager visible={false} />
                                                        <Paging enabled={false} />
                                                        <Column
                                                            dataField="denominacion"
                                                            caption={getTrad("denominacion")}
                                                            minWidth={100}
                                                        />
                                                        <Column
                                                            dataField="ingresosPresupuestados"
                                                            caption={getTrad("presupuestado")}
                                                            alignment="right"
                                                            format="currency"
                                                            width={130}
                                                        />
                                                        <Column
                                                            dataField="ingresosPrevistos"
                                                            caption={getTrad("realEstimado")}
                                                            alignment="right"
                                                            format="currency"
                                                            width={130}
                                                        />
                                                        <Column
                                                            name="desviacion"
                                                            caption={getTrad("desviacion")}
                                                            width={250}
                                                            alignment="center"
                                                            format="currency"
                                                            calculateCellValue={
                                                                this.calculateCellValue_desviacion_ingresos
                                                            }
                                                            cellRender={this.cellRender_desviacion_ingresos}
                                                        />
                                                        <Summary>
                                                            <TotalItem
                                                                column="ingresosPresupuestados"
                                                                summaryType="sum"
                                                                valueFormat="currency"
                                                                displayFormat="{0}"
                                                            />
                                                            <TotalItem
                                                                column="ingresosPrevistos"
                                                                summaryType="sum"
                                                                valueFormat="currency"
                                                                displayFormat="{0}"
                                                            />
                                                            <TotalItem
                                                                column="desviacion"
                                                                summaryType="sum"
                                                                valueFormat="currency"
                                                                displayFormat="{0}"
                                                            />
                                                        </Summary>
                                                    </DataGrid>
                                                </ItemBox>
                                                <ItemBox ratio={1}>
                                                    <Chart
                                                        className="dxChart_responsive"
                                                        dataSource={this.datasource_datos_compañia_diario}
                                                        redrawOnResize={true}
                                                    >
                                                        <CommonSeriesSettings axis="ingresos" type="spline" />
                                                        <Series
                                                            name={getTrad("presupuestado")}
                                                            valueField="ingresosPresupuestados"
                                                            color="var(--primary)"
                                                            type="line"
                                                            point={this.chart_series_point}
                                                            selectionMode="none"
                                                            dashStyle="dash"
                                                            width={3}
                                                        />
                                                        <Series
                                                            name={getTrad("realEstimado")}
                                                            valueField="ingresosPrevistos"
                                                            color="var(--blue)"
                                                        />
                                                        <CommonAxisSettings>
                                                            <Grid visible={true} />
                                                        </CommonAxisSettings>
                                                        <ValueAxis
                                                            name="ingresos"
                                                            position="left"
                                                            showZero={true}
                                                            label={this.chart_valueAxis_label}
                                                        />
                                                        <ArgumentAxis
                                                            minorTickInterval={
                                                                this.chart_argumentAxis_minorTickInterval
                                                            }
                                                            tickInterval={this.chart_argumentAxis_tickInterval}
                                                            label={this.chart_argumentAxis_label}
                                                            breaks={breaks_Chart}
                                                            breakStyle={this.chart_breakStyle}
                                                            argumentType="datetime"
                                                            //tickInterval="day"
                                                        />
                                                        <TooltipChart
                                                            enabled={true}
                                                            location="edge"
                                                            contentRender={this.dxChart_tooltip_contentRender}
                                                            shared={true}
                                                        />
                                                        <Legend
                                                            visible={true}
                                                            orientation="horizontal"
                                                            horizontalAlignment="right"
                                                        />
                                                    </Chart>
                                                </ItemBox>
                                            </Box>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <Box direction="row" width="100%" height={"100%"}>
                                                <ItemBox ratio={1}>
                                                    <DataGrid
                                                        className="font-size-md headerRow-lg pr-2"
                                                        //Datos
                                                        dataSource={this.datasource_datos_entidad}
                                                        //Propiedades
                                                        columnsAutoWidth={true}
                                                        height={"100%"}
                                                        width={"100%"}
                                                        hoverStateEnabled={true}
                                                        repaintChangesOnly={true}
                                                        selectedRowKeys={entidadSel ? [entidadSel.idEntidad] : []}
                                                        //Estilos
                                                        showColumnLines={false}
                                                        onRowPrepared={this.onRowPrepared_pointer}
                                                        onCellPrepared={this.onCellPrepared_ingresos}
                                                        onSelectionChanged={this.onSelectionChanged_Entidad}
                                                    >
                                                        <RemoteOperations summary={false}></RemoteOperations>
                                                        <Selection mode="single" />
                                                        <SearchPanel visible={false} />
                                                        <HeaderFilter visible={false} />
                                                        <FilterRow visible={false} />
                                                        <Pager visible={false} />
                                                        <Paging enabled={false} />
                                                        <Column
                                                            dataField="denominacion"
                                                            caption={getTrad("denominacion")}
                                                            minWidth={100}
                                                        />
                                                        <Column
                                                            dataField="ingresosPresupuestados"
                                                            caption={getTrad("presupuestado")}
                                                            alignment="right"
                                                            format="currency"
                                                            width={130}
                                                        />
                                                        <Column
                                                            dataField="ingresosPrevistos"
                                                            caption={getTrad("realEstimado")}
                                                            alignment="right"
                                                            format="currency"
                                                            width={130}
                                                        />
                                                        <Column
                                                            name="desviacion"
                                                            caption={getTrad("desviacion")}
                                                            width={250}
                                                            alignment="center"
                                                            format="currency"
                                                            calculateCellValue={
                                                                this.calculateCellValue_desviacion_ingresos
                                                            }
                                                            cellRender={this.cellRender_desviacion_ingresos}
                                                        />
                                                        <Summary>
                                                            <TotalItem
                                                                column="ingresosPresupuestados"
                                                                summaryType="sum"
                                                                valueFormat="currency"
                                                                displayFormat="{0}"
                                                            />
                                                            <TotalItem
                                                                column="ingresosPrevistos"
                                                                summaryType="sum"
                                                                valueFormat="currency"
                                                                displayFormat="{0}"
                                                            />
                                                            <TotalItem
                                                                column="desviacion"
                                                                summaryType="sum"
                                                                valueFormat="currency"
                                                                displayFormat="{0}"
                                                            />
                                                        </Summary>
                                                    </DataGrid>
                                                </ItemBox>
                                                <ItemBox ratio={1}>
                                                    <Chart
                                                        className="dxChart_responsive"
                                                        dataSource={this.datasource_datos_entidad_diario}
                                                        redrawOnResize={true}
                                                    >
                                                        <CommonSeriesSettings axis="ingresos" type="spline" />
                                                        <Series
                                                            name={getTrad("presupuestado")}
                                                            valueField="ingresosPresupuestados"
                                                            color="var(--primary)"
                                                            type="line"
                                                            point={this.chart_series_point}
                                                            selectionMode="none"
                                                            dashStyle="dash"
                                                            width={3}
                                                        />
                                                        <Series
                                                            name={getTrad("realEstimado")}
                                                            valueField="ingresosPrevistos"
                                                            color="var(--blue)"
                                                        />
                                                        <CommonAxisSettings>
                                                            <Grid visible={true} />
                                                        </CommonAxisSettings>
                                                        <ValueAxis
                                                            name="ingresos"
                                                            position="left"
                                                            showZero={true}
                                                            label={this.chart_valueAxis_label}
                                                        />
                                                        <ArgumentAxis
                                                            minorTickInterval={
                                                                this.chart_argumentAxis_minorTickInterval
                                                            }
                                                            tickInterval={this.chart_argumentAxis_tickInterval}
                                                            label={this.chart_argumentAxis_label}
                                                            breaks={breaks_Chart}
                                                            breakStyle={this.chart_breakStyle}
                                                            argumentType="datetime"
                                                            //tickInterval="day"
                                                        />
                                                        <TooltipChart
                                                            enabled={true}
                                                            location="edge"
                                                            contentRender={this.dxChart_tooltip_contentRender}
                                                            shared={true}
                                                        />
                                                        <Legend
                                                            visible={true}
                                                            orientation="horizontal"
                                                            horizontalAlignment="right"
                                                        />
                                                    </Chart>
                                                </ItemBox>
                                            </Box>
                                        </ItemBox>
                                    </Box>
                                </TabPanelItem>
                                <TabPanelItem title={getTrad("costes").toUpperCase()} tabIndex={1}>
                                    <Box direction="row" width="100%" height={"100%"}>
                                        <ItemBox ratio={1}>
                                            <DataGrid
                                                className="font-size-md headerRow-lg pr-2"
                                                //Datos
                                                dataSource={this.datasource_datos_tipoKpi}
                                                //Propiedades
                                                columnsAutoWidth={true}
                                                height={"100%"}
                                                width={"100%"}
                                                hoverStateEnabled={true}
                                                repaintChangesOnly={true}
                                                selectedRowKeys={
                                                    tipoKpiSel
                                                        ? [
                                                              {
                                                                  idTipoKpi: tipoKpiSel.idTipoKpi,
                                                                  idTipoTrabajo: tipoKpiSel.idTipoTrabajo,
                                                              },
                                                          ]
                                                        : []
                                                }
                                                //Estilos
                                                showColumnLines={false}
                                                onRowPrepared={this.onRowPrepared_pointer}
                                                onCellPrepared={this.onCellPrepared_costes}
                                                onSelectionChanged={this.onSelectionChanged_TipoKpi}
                                            >
                                                <RemoteOperations summary={false} grouping={false}></RemoteOperations>
                                                <Selection mode="single" />
                                                <SearchPanel visible={false} />
                                                <HeaderFilter visible={false} />
                                                <FilterRow visible={false} />
                                                <Pager visible={false} />
                                                <Paging enabled={false} />
                                                <Column
                                                    dataField="group"
                                                    groupIndex={0}
                                                    groupCellRender={this.groupCellRender_tipoKpi}
                                                />
                                                <Column
                                                    dataField="denominacion"
                                                    caption={getTrad("denominacion")}
                                                    minWidth={100}
                                                    cellRender={this.cellRender_denominacion_observaciones}
                                                />
                                                <Column dataField="observaciones" visible={false} />
                                                <Column
                                                    dataField="costesPresupuestados"
                                                    caption={getTrad("presupuestado")}
                                                    alignment="right"
                                                    format="currency"
                                                    width={130}
                                                />
                                                <Column
                                                    dataField="costesPrevistos"
                                                    caption={getTrad("realEstimado")}
                                                    alignment="right"
                                                    format="currency"
                                                    width={130}
                                                />
                                                <Column
                                                    name="desviacion"
                                                    caption={getTrad("desviacion")}
                                                    width={250}
                                                    alignment="center"
                                                    format="currency"
                                                    calculateCellValue={this.calculateCellValue_desviacion_costes}
                                                    cellRender={this.cellRender_desviacion_costes}
                                                />
                                                <Summary>
                                                    <GroupItem
                                                        column="costesPresupuestados"
                                                        summaryType="sum"
                                                        valueFormat="currency"
                                                        displayFormat="{0}"
                                                        alignByColumn={true}
                                                    />
                                                    <GroupItem
                                                        column="costesPrevistos"
                                                        summaryType="sum"
                                                        valueFormat="currency"
                                                        displayFormat="{0}"
                                                        alignByColumn={true}
                                                    />
                                                    <GroupItem
                                                        column="desviacion"
                                                        summaryType="sum"
                                                        valueFormat="currency"
                                                        displayFormat="{0}"
                                                        alignByColumn={true}
                                                    />
                                                    <TotalItem
                                                        column="costesPresupuestados"
                                                        summaryType="sum"
                                                        valueFormat="currency"
                                                        displayFormat="{0}"
                                                    />
                                                    <TotalItem
                                                        column="costesPrevistos"
                                                        summaryType="sum"
                                                        valueFormat="currency"
                                                        displayFormat="{0}"
                                                    />
                                                    <TotalItem
                                                        column="desviacion"
                                                        summaryType="sum"
                                                        valueFormat="currency"
                                                        displayFormat="{0}"
                                                    />
                                                </Summary>
                                            </DataGrid>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <Box direction="col" width="100%" height={"100%"}>
                                                <ItemBox ratio={1}>
                                                    <Chart
                                                        className="dxChart_responsive"
                                                        dataSource={this.datasource_datos_tipoKpi_diario}
                                                        redrawOnResize={true}
                                                    >
                                                        <CommonSeriesSettings axis="costes" type="spline" />
                                                        <Series
                                                            name={getTrad("presupuestado")}
                                                            valueField="costesPresupuestados"
                                                            color="var(--primary)"
                                                            point={this.chart_series_point}
                                                            selectionMode="none"
                                                            width={3}
                                                        />
                                                        <Series
                                                            name={getTrad("realEstimado")}
                                                            valueField="costesPrevistos"
                                                            color="var(--blue)"
                                                        />
                                                        <CommonAxisSettings>
                                                            <Grid visible={true} />
                                                        </CommonAxisSettings>
                                                        <ValueAxis
                                                            name="costes"
                                                            position="left"
                                                            showZero={true}
                                                            label={this.chart_valueAxis_label}
                                                        />
                                                        <ArgumentAxis
                                                            minorTickInterval={
                                                                this.chart_argumentAxis_minorTickInterval
                                                            }
                                                            tickInterval={this.chart_argumentAxis_tickInterval}
                                                            label={this.chart_argumentAxis_label}
                                                            breaks={breaks_Chart}
                                                            breakStyle={this.chart_breakStyle}
                                                            argumentType="datetime"
                                                            //tickInterval="day"
                                                        />
                                                        <TooltipChart
                                                            enabled={true}
                                                            location="edge"
                                                            contentRender={this.dxChart_tooltip_contentRender}
                                                            shared={true}
                                                        />
                                                        <Legend
                                                            visible={true}
                                                            orientation="horizontal"
                                                            horizontalAlignment="right"
                                                        />
                                                    </Chart>
                                                </ItemBox>
                                                {lavanderia.idPais != 1 && tipoKpiSel && tipoKpiSel.idTipoKpi === 5 ? (
                                                    <ItemBox ratio={1}>
                                                        <Fragment>
                                                            <Switch
                                                                className="position-absolute ml-4 mt-1"
                                                                switchedOnText={"Personal en planta"}
                                                                switchedOffText={"Personal contratado"}
                                                                height={40}
                                                                width={180}
                                                                defaultValue={soloJornadaPersona}
                                                                onValueChanged={
                                                                    this.switch_soloJornadaPersona_valueChanged
                                                                }
                                                            />
                                                            <Chart
                                                                className="dxChart_responsive"
                                                                dataSource={this.datasource_datos_RRHH_diario}
                                                                redrawOnResize={true}
                                                            >
                                                                <CommonSeriesSettings axis="costes" />
                                                                <Series
                                                                    name={"Personas activas"}
                                                                    valueField="numPersonas_activas"
                                                                    color="var(--blue)"
                                                                    type="stackedBar"
                                                                />
                                                                <Series
                                                                    name={"Personas inactivas"}
                                                                    valueField="numPersonas_inactivas"
                                                                    color="var(--red)"
                                                                    type="stackedBar"
                                                                />
                                                                <Series
                                                                    name={"Personas teóricas"}
                                                                    valueField="numPersonasTeoricas"
                                                                    color="var(--primary)"
                                                                    // point={this.chart_series_point}
                                                                    selectionMode="none"
                                                                    width={3}
                                                                    type="spline"
                                                                />
                                                                <CommonAxisSettings>
                                                                    <Grid visible={true} />
                                                                </CommonAxisSettings>
                                                                <ValueAxis
                                                                    name="costes"
                                                                    position="left"
                                                                    showZero={true}
                                                                />
                                                                <ArgumentAxis
                                                                    minorTickInterval={
                                                                        this.chart_argumentAxis_minorTickInterval
                                                                    }
                                                                    tickInterval={this.chart_argumentAxis_tickInterval}
                                                                    label={this.chart_argumentAxis_label}
                                                                    breaks={breaks_Chart}
                                                                    breakStyle={this.chart_breakStyle}
                                                                    argumentType="datetime"
                                                                    //tickInterval="day"
                                                                />
                                                                <TooltipChart
                                                                    enabled={true}
                                                                    location="edge"
                                                                    contentRender={
                                                                        this.dxChart_numPersonas_tooltip_contentRender
                                                                    }
                                                                    shared={true}
                                                                />
                                                                <Legend
                                                                    visible={true}
                                                                    orientation="horizontal"
                                                                    horizontalAlignment="right"
                                                                />
                                                            </Chart>
                                                        </Fragment>
                                                    </ItemBox>
                                                ) : null}
                                            </Box>
                                        </ItemBox>
                                    </Box>
                                </TabPanelItem>
                            </TabPanel>
                        </MultiViewItem>
                    </MultiView>
                    {/* </ReactCSSTransitionGroup> */}
                </div>
            </Fragment>
        );
    }

    //SELECTOR LAVANDERIA
    onCardClick_Lavanderia(lavanderia) {
        this.setState({ dxMultiView_index: 1 }, () => {
            this.setLavanderia(lavanderia.idLavanderia);
        });
    }
    get_HeaderContent(lavanderia) {
        return <div className="container_spanCentrado"></div>;
    }
    get_BodyContent(lavanderia) {
        return (
            <Fragment>
                <div className="pl-4 pr-4 pt-2 pb-2 w-100 he-100">
                    <table className="table w-100 he-100">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col" className="text-center font-size-xxxs">
                                    {getTrad("presupuestado").toUpperCase()}
                                </th>
                                <th scope="col" className="text-center font-size-xxxs">
                                    {getTrad("realEstimado").toUpperCase()}
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <div
                                        id={"icon_Ingresos_" + lavanderia.idLavanderia}
                                        className="font-size-xxll icon_Euro"
                                    />
                                </th>
                                <td className="text-center font-size-xl">
                                    {formatNumber(
                                        lavanderia.ingresosPresupuestados,
                                        0,
                                        "currency",
                                        lavanderia.codigoMoneda
                                    )}
                                </td>
                                <td
                                    className={
                                        "text-center font-weight-bold font-size-xl text-" + lavanderia.colorIngresos
                                    }
                                >
                                    {formatNumber(lavanderia.ingresosPrevistos, 0, "currency", lavanderia.codigoMoneda)}
                                </td>
                                <td>
                                    <div
                                        className={
                                            "font-size-xs " +
                                            (lavanderia.colorIngresos === "danger"
                                                ? "icon_ArrowDown danger"
                                                : lavanderia.colorIngresos === "success"
                                                ? "icon_ArrowUp success"
                                                : "")
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <div
                                        id={"icon_Costes_" + lavanderia.idLavanderia}
                                        className="font-size-xxll icon_Grafica"
                                    />
                                </th>
                                <td className="text-center font-size-xl">
                                    {formatNumber(
                                        lavanderia.costesPresupuestados,
                                        0,
                                        "currency",
                                        lavanderia.codigoMoneda
                                    )}
                                </td>
                                <td
                                    className={
                                        "text-center font-weight-bold font-size-xl text-" + lavanderia.colorCostes
                                    }
                                >
                                    {formatNumber(lavanderia.costesPrevistos, 0, "currency", lavanderia.codigoMoneda)}
                                </td>
                                <td>
                                    <div
                                        className={
                                            "font-size-xs " +
                                            (lavanderia.colorCostes === "danger"
                                                ? "icon_ArrowUp danger"
                                                : lavanderia.colorCostes === "success"
                                                ? "icon_ArrowDown success"
                                                : "")
                                        }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Tooltip
                    target={"#icon_Ingresos_" + lavanderia.idLavanderia}
                    position="bottom"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                >
                    <div>{getTrad("ingresos")}</div>
                </Tooltip>
                <Tooltip
                    target={"#icon_Costes_" + lavanderia.idLavanderia}
                    position="bottom"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                >
                    <div>{getTrad("costes")}</div>
                </Tooltip>
            </Fragment>
        );
    }
    get_CardColor(lavanderia, isBorder = false) {
        let value = lavanderia.desviacion;
        if (!isBorder) {
            return value <= 0 ? "danger" : "success";
        }
        return value <= 0 ? "border-danger" : "border-success";
    }

    //DATE PICKER
    getDatePicker() {
        return (
            <DateBox
                className="centerText font-size-sm"
                inputAttr={{ class: "font-size-sm" }}
                value={this.state.fechaSel}
                width="100%"
                stylingMode="underlined"
                displayFormat={this.datePicker_displayFormat}
                openOnFieldClick={true}
                calendarOptions={{
                    maxZoomLevel: "year",
                    minZoomLevel: "year",
                }}
                onValueChanged={this.datePicker_onValueChanged}
            >
                <DateBoxButton
                    name="prevDate"
                    location="before"
                    options={{
                        icon: "spinprev",
                        stylingMode: "text",
                        onClick: this.prevDate_Click,
                    }}
                />
                <DateBoxButton
                    name="nextDate"
                    location="after"
                    options={{
                        icon: "spinnext",
                        stylingMode: "text",
                        onClick: this.nextDate_Click,
                    }}
                />
            </DateBox>
        );
    }
    prevDate_Click(e) {
        const { fechaSel } = this.state;
        const _fechaSel = new Date(fechaSel.getTime());
        this.setState({
            fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() - 1)),
        });
    }
    nextDate_Click(e) {
        let { fechaSel } = this.state;
        let _fechaSel = new Date(fechaSel.getTime());
        this.setState({
            fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() + 1)),
        });
    }
    datePicker_onValueChanged(e) {
        this.setState({ fechaSel: e.value });
    }
    datePicker_displayFormat(fecha) {
        let { idioma } = this.props;
        if (new Date().getFullYear() === fecha.getFullYear()) {
            return fecha.toLocaleString(idioma.codigo, { month: "long" }).toUpperCase();
        } else {
            return fecha.toLocaleString(idioma.codigo, { month: "long", year: "numeric" }).toUpperCase();
        }
    }

    //SELECTOR CORPORACIÓN
    getSelectorCorporacion() {
        const { idioma } = this.props;
        let { idCorporacionSel, dxMultiView_index } = this.state;
        let is_corpUnica = this.dataSource_tblCorporacion.items().length === 1;
        return (
            <SelectBox
                deferRendering={false}
                className="centerText font-size-sm mr-3"
                inputAttr={{ class: "font-size-sm" }}
                dataSource={this.dataSource_tblCorporacion}
                displayExpr={function (item) {
                    if (item && item.denominacion) {
                        return item.denominacion.toUpperCase();
                    } else if (item) {
                        return getTrad("todasCorporaciones");
                    } else {
                        return;
                    }
                }}
                disabled={is_corpUnica}
                valueExpr="idCorporacion"
                value={idCorporacionSel}
                defaultValue={idCorporacionSel}
                visible={dxMultiView_index === 0}
                width="100%"
                stylingMode="underlined"
                placeholder={getTrad("todasCorporaciones")}
                onSelectionChanged={this.selectorCorporacion_onSelectionChanged}
            />
        );
    }
    selectorCorporacion_onSelectionChanged(e) {
        let { idPaisSel } = this.state;

        let idCorporacionSel = e.selectedItem.idCorporacion;
        let items = this.datasource_datos_lavanderia.items();

        let datosSelector_Lavanderia_Filtrado = items.filter(
            (x) => (!idPaisSel || x.idPais === idPaisSel) && (!idCorporacionSel || x.idCorporacion === idCorporacionSel)
        );
        this.setState({
            idCorporacionSel: idCorporacionSel,
            datosSelector_Lavanderia: datosSelector_Lavanderia_Filtrado,
        });
    }

    //SELECTOR PAÍS
    getSelectorPais() {
        const { idioma } = this.props;
        let { idPaisSel, dxMultiView_index } = this.state;
        let is_paisUnico = this.dataSource_tblPais.items().length === 1;

        return (
            <SelectBox
                deferRendering={false}
                className="centerText font-size-sm mr-3"
                inputAttr={{ class: "font-size-sm" }}
                dataSource={this.dataSource_tblPais}
                displayExpr={function (item) {
                    if (item && item.tblTraduccion) {
                        return item.tblTraduccion[idioma.codigo].toUpperCase();
                    } else if (item) {
                        return getTrad("todosPaises");
                    } else {
                        return;
                    }
                }}
                disabled={is_paisUnico}
                valueExpr="idPais"
                value={idPaisSel}
                defaultValue={idPaisSel}
                visible={dxMultiView_index === 0}
                width="100%"
                stylingMode="underlined"
                placeholder={getTrad("todosPaises")}
                onSelectionChanged={this.selectorPais_onSelectionChanged}
            />
        );
    }
    selectorPais_onSelectionChanged(e) {
        let { idCorporacionSel } = this.state;
        let idPaisSel = e.selectedItem.idPais;
        let items = this.datasource_datos_lavanderia.items();

        let datosSelector_Lavanderia_Filtrado = items.filter(
            (x) => (!idPaisSel || x.idPais === idPaisSel) && (!idCorporacionSel || x.idCorporacion === idCorporacionSel)
        );
        this.setState({
            idPaisSel: idPaisSel,
            datosSelector_Lavanderia: datosSelector_Lavanderia_Filtrado,
        });
    }

    //BACK BUTTON
    getBackButton() {
        let { dxMultiView_index } = this.state;
        return (
            <Fragment>
                <div
                    id="backButton"
                    className="backButton dx-icon-chevronleft container_spanCentrado"
                    onClick={this.backButton_onClick}
                    hidden={dxMultiView_index === 0}
                />
                <Tooltip target="#backButton" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div>{getTrad("volverLavanderia")}</div>
                </Tooltip>
            </Fragment>
        );
    }
    backButton_onClick() {
        this.setState({
            dxMultiView_index: 0,
            tabPanel_selectedIndex: 0,
        });
    }

    ////CONFIGURACIÓN////

    //CHART
    chart_breakStyle = { color: "lightgray" };
    chart_valueAxis_label = { format: { type: "currency" } };
    chart_argumentAxis_minorTickInterval = { days: 1 };
    chart_argumentAxis_tickInterval = { days: 1 };
    chart_argumentAxis_label = { format: "day" };
    chart_series_point = { visible: false };

    //GRID
    grid_Singleselection = { mode: "single" };
    grid_allowedPageSizes = [20, 50, 100];

    onRowPrepared_pointer(e) {
        if (e.rowType === "data") {
            e.rowElement.css("cursor", "pointer");

            if (e.data.activo != undefined && !e.data.activo) {
                e.rowElement.addClass("warningRow_onlyText");
            }
        }
    }

    dxChart_tooltip_contentRender(arg) {
        let { lavanderia } = this.props;

        return (
            <div>
                <div className="tooltip_header">
                    <div className="tooltip_title"> {formatDate(arg.argument)}</div>
                </div>
                <div className="tooltip_body">
                    {arg.points.map((point, i) => (
                        <div key={point.seriesName} className="tooltip_row">
                            <div className="tooltip_series-name">{point.seriesName.toUpperCase()}</div>
                            <div className="tooltip_value-text">
                                {formatNumber(point.value, 0, "currency", lavanderia.moneda)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    dxChart_numPersonas_tooltip_contentRender(arg) {
        let { soloJornadaPersona } = this.state;
        let data = arg.point.data;

        return (
            <div>
                <div className="tooltip_header">
                    <div className="tooltip_title"> {formatDate(arg.argument)}</div>
                </div>
                <div className="tooltip_body">
                    <div className="tooltip_row">
                        <div className="tooltip_series-name">{"Personas teóricas".toUpperCase()}</div>
                        <div className="tooltip_value-text">{formatNumber(data.numPersonasTeoricas, 0, "decimal")}</div>
                    </div>
                    <div className="tooltip_row">
                        <div className="tooltip_series-name">{"Personas activas".toUpperCase()}</div>
                        <div className="tooltip_value-text">{formatNumber(data.numPersonas_activas, 0, "decimal")}</div>
                    </div>
                    {!soloJornadaPersona ? (
                        <div className="tooltip_row">
                            <div className="tooltip_series-name">{"Personas inactivas".toUpperCase()}</div>
                            <div className="tooltip_value-text">
                                {formatNumber(data.numPersonas_inactivas, 0, "decimal")}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    //TABPANEL
    dxTabPanel_onSelectionChanged(e) {
        this.setState({
            tabPanel_selectedIndex: e.component.option("selectedIndex"),
        });
    }

    //GRIDS
    calculateCellValue_desviacion_ingresos(rowData) {
        return rowData.ingresosPrevistos - rowData.ingresosPresupuestados;
    }

    calculateCellValue_desviacion_costes(rowData) {
        return rowData.costesPrevistos - rowData.costesPresupuestados;
    }

    cellRender_desviacion_ingresos(itemData) {
        let data = itemData.data;
        let desviacion = itemData.value;
        let textDesviacion = (desviacion > 0 ? "+" : "") + itemData.text;

        var porcentajeDesviacion = 0;
        if (data.ingresosPresupuestados > 0) {
            porcentajeDesviacion = data.ingresosPrevistos / data.ingresosPresupuestados - 1;
        } else if (data.ingresosPrevistos > 0) {
            porcentajeDesviacion = 1;
        }

        if (porcentajeDesviacion > 1) {
            porcentajeDesviacion = 1;
        }
        if (porcentajeDesviacion < -1) {
            porcentajeDesviacion = -1;
        }

        var textPorcentaje = formatNumber(porcentajeDesviacion, 2, "percent");
        if (porcentajeDesviacion > 0) {
            textPorcentaje = "+" + textPorcentaje;
        }

        var textColorClass = porcentajeDesviacion > 0 ? "text-success" : porcentajeDesviacion < 0 ? "text-danger" : "";

        return (
            <div className={"he-100 w-100 d-flex justify-content-between " + textColorClass}>
                <div style={{ flex: 1 }} className="text-right">
                    {textDesviacion}
                </div>
                <div
                    style={{ flexBasis: "45px" }}
                    className={
                        "font-size-lg " +
                        (porcentajeDesviacion > 0
                            ? "icon_ArrowUp success"
                            : porcentajeDesviacion < 0
                            ? "icon_ArrowDown danger"
                            : "")
                    }
                />
                <div style={{ flex: 1 }}>{textPorcentaje}</div>
            </div>
        );
    }

    cellRender_desviacion_costes(itemData) {
        let data = itemData.data;
        let desviacion = itemData.value;
        let textDesviacion = (desviacion > 0 ? "+" : "") + itemData.text;

        var porcentajeDesviacion = 0;
        if (data.costesPresupuestados > 0) {
            porcentajeDesviacion = data.costesPrevistos / data.costesPresupuestados - 1;
        } else if (data.costesPrevistos > 0) {
            porcentajeDesviacion = 1;
        }

        if (porcentajeDesviacion > 1) {
            porcentajeDesviacion = 1;
        }
        if (porcentajeDesviacion < -1) {
            porcentajeDesviacion = -1;
        }

        var textPorcentaje = formatNumber(porcentajeDesviacion, 2, "percent");
        if (porcentajeDesviacion > 0) {
            textPorcentaje = "+" + textPorcentaje;
        }

        var textColorClass = porcentajeDesviacion > 0 ? "text-danger" : porcentajeDesviacion < 0 ? "text-success" : "";

        return (
            <div className={"he-100 w-100 d-flex justify-content-between " + textColorClass}>
                <div style={{ flex: 1 }} className="text-right">
                    {textDesviacion}
                </div>
                <div
                    style={{ flexBasis: "45px" }}
                    className={
                        "pl-2 pr-2 font-size-lg " +
                        (porcentajeDesviacion > 0
                            ? "icon_ArrowUp danger"
                            : porcentajeDesviacion < 0
                            ? "icon_ArrowDown success"
                            : "")
                    }
                />
                <div style={{ flex: 1 }}>{textPorcentaje}</div>
            </div>
        );
    }

    onCellPrepared_ingresos(e) {
        let { lavanderia } = this.props;
        if (e.rowType === "totalFooter") {
            var isDesviacion = $.grep(e.summaryItems, function (v) {
                return v.column === "desviacion";
            });

            if (isDesviacion.length > 0) {
                var container = e.cellElement.find(".dx-datagrid-summary-item");
                container.empty();

                var desviacion = e.component.getTotalSummaryValue("desviacion");
                var ingresosPrevistos = e.component.getTotalSummaryValue("ingresosPrevistos");
                var ingresosPresupuestados = e.component.getTotalSummaryValue("ingresosPresupuestados");

                let textDesviacion = formatNumber(desviacion, 0, "currency", lavanderia.moneda);

                var porcentajeDesviacion = 0;
                if (ingresosPresupuestados > 0) {
                    porcentajeDesviacion = ingresosPrevistos / ingresosPresupuestados - 1;
                } else if (ingresosPrevistos > 0) {
                    porcentajeDesviacion = 1;
                }

                if (porcentajeDesviacion > 1) {
                    porcentajeDesviacion = 1;
                }
                if (porcentajeDesviacion < -1) {
                    porcentajeDesviacion = -1;
                }

                var textPorcentaje = formatNumber(porcentajeDesviacion, 2, "percent");
                if (porcentajeDesviacion > 0) {
                    textPorcentaje = "+" + textPorcentaje;
                }

                let textColorClass =
                    porcentajeDesviacion > 0 ? "text-success" : porcentajeDesviacion < 0 ? "text-danger" : "";

                container.append(
                    $("<div />")
                        .addClass("he-100 w-100 d-flex justify-content-between " + textColorClass)
                        .append($("<div />").css("flex", "1").addClass("text-right").text(textDesviacion))
                        .append(
                            $("<div />")
                                .css("flex-basis", "45px")
                                .addClass(
                                    "font-size-lg " +
                                        (porcentajeDesviacion > 0
                                            ? "icon_ArrowUp success"
                                            : porcentajeDesviacion < 0
                                            ? "icon_ArrowDown danger"
                                            : "")
                                )
                        )
                        .append($("<div />").css("flex", "1").text(textPorcentaje))
                );
            }
        }
    }

    onCellPrepared_costes(e) {
        let { lavanderia } = this.props;
        if ((e.rowType === "totalFooter" || e.rowType === "group") && e.column.name === "desviacion") {
            var container, desviacion, costesPrevistos, costesPresupuestados;

            if (e.rowType === "totalFooter") {
                container = e.cellElement.find(".dx-datagrid-summary-item");
                desviacion = e.component.getTotalSummaryValue("desviacion");
                costesPrevistos = e.component.getTotalSummaryValue("costesPrevistos");
                costesPresupuestados = e.component.getTotalSummaryValue("costesPresupuestados");
            } else {
                //Group
                container = e.cellElement;

                costesPrevistos = $.grep(e.totalItem.summaryCells, function (item) {
                    return item.length > 0 && item[0].column === "costesPrevistos";
                })[0][0].value;

                costesPresupuestados = $.grep(e.totalItem.summaryCells, function (item) {
                    return item.length > 0 && item[0].column === "costesPresupuestados";
                })[0][0].value;

                desviacion = $.grep(e.totalItem.summaryCells, function (item) {
                    return item.length > 0 && item[0].column === "desviacion";
                })[0][0].value;
            }

            container.empty();

            let textDesviacion = formatNumber(desviacion, 0, "currency", lavanderia.moneda);

            var porcentajeDesviacion = 0;
            if (costesPresupuestados > 0) {
                porcentajeDesviacion = costesPrevistos / costesPresupuestados - 1;
            } else if (costesPrevistos > 0) {
                porcentajeDesviacion = 1;
            }

            if (porcentajeDesviacion > 1) {
                porcentajeDesviacion = 1;
            }
            if (porcentajeDesviacion < -1) {
                porcentajeDesviacion = -1;
            }

            var textPorcentaje = formatNumber(porcentajeDesviacion, 2, "percent");
            if (porcentajeDesviacion > 0) {
                textPorcentaje = "+" + textPorcentaje;
            }

            var textColorClass =
                porcentajeDesviacion > 0 ? "text-danger" : porcentajeDesviacion < 0 ? "text-success" : "";

            container.append(
                $("<div />")
                    .addClass("he-100 w-100 d-flex justify-content-between " + textColorClass)
                    .append($("<div />").addClass("text-right").css("flex-basis", "40%").text(textDesviacion))
                    .append(
                        $("<div />").addClass(
                            "font-size-lg " +
                                (porcentajeDesviacion > 0
                                    ? "icon_ArrowUp danger"
                                    : porcentajeDesviacion < 0
                                    ? "icon_ArrowDown success"
                                    : "")
                        )
                    )
                    .append($("<div />").css("flex-basis", "40%").text(textPorcentaje))
            );
        }
    }

    groupCellRender_tipoKpi = (cellInfo) => {
        return <div className="font-size-lg font-weight-light">{cellInfo.displayValue}</div>;
    };

    cellRender_denominacion_observaciones = (itemData) => {
        let data = itemData.data;
        return (
            <Fragment>
                <Col id={"gridTipoKpi_cell_denominacion_" + data.idTipoKpi + data.idTipoTrabajo} className="d-flex">
                    <div>{itemData.text}</div>
                    {data.observaciones.length > 0 ? <div className="font-size-xl icon_Warning pl-2" /> : null}
                </Col>
                {data.observaciones.length > 0 ? (
                    <Tooltip
                        target={"#gridTipoKpi_cell_denominacion_" + data.idTipoKpi + data.idTipoTrabajo}
                        position="bottom"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                    >
                        <div className="tooltip_header">
                            <div className="tooltip_title text-left">
                                {" "}
                                {"No se ha podido calcular el salario bruto anual de las siguientes personas:"}
                            </div>
                        </div>
                        <div className="tooltip_body">
                            {data.observaciones.split("|").map((pers, i) => (
                                <div key={"denominacion_observaciones_row_" + i} className="tooltip_row">
                                    {pers}
                                </div>
                            ))}
                        </div>
                    </Tooltip>
                ) : null}
            </Fragment>
        );
    };

    //GRID COMPAÑIA
    onSelectionChanged_Compañia(e) {
        this.setState({ compañiaSel: e.selectedRowsData[0], entidadSel: null }, () => this.cargaDatos_Compañia());
    }
    onContentReady_Compañia(e) {
        var grid = e.component;
        var selection = grid.getSelectedRowKeys();
        if (selection.length === 0) {
            grid.selectRowsByIndexes([0]);
        }
    }

    //GRID ENTIDAD
    onSelectionChanged_Entidad(e) {
        this.setState({ entidadSel: e.selectedRowsData[0] }, () => this.cargaDatos_Entidad());
    }
    onContentReady_Entidad(e) {
        let { compañiaSel } = this.state;
        if (compañiaSel) {
            var grid = e.component;
            var selection = grid.getSelectedRowKeys();
            if (selection.length === 0) {
                grid.selectRowsByIndexes([0]);
            }
        }
    }

    //GRID TIPOKPI
    onSelectionChanged_TipoKpi(e) {
        this.setState({ tipoKpiSel: e.selectedRowsData[0] });
        this.cargaDatos_TipoKpi();
    }
    onContentReady_TipoKpi(e) {
        let { compañiaSel } = this.state;
        if (compañiaSel) {
            var grid = e.component;
            var selection = grid.getSelectedRowKeys();
            if (selection.length === 0) {
                grid.selectRowsByIndexes([0]);
            }
        }
    }
    switch_soloJornadaPersona_valueChanged(e) {
        let _this = this;
        this.setState({ soloJornadaPersona: e.value }, () => {
            _this.datasource_datos_RRHH_diario.load();
        });
    }

    //UTILS
    setLavanderia(idLavanderia) {
        let lavanderias = JSON.parse(localStorage.getItem("lavanderias"));

        var lav = lavanderias.find((lav) => {
            return lav.idLavanderia === idLavanderia;
        });
        this.props.setLavanderia(lav);
    }

    get_responsiveFont_gauge() {
        let width = $(window).width();
        // 3 Columnas
        if (width > 2150) return "font-size-xxl";
        if (width >= 1630 && width < 2150) return "font-size-xl";
        else if (width >= 1260 && width < 1630) return "font-size-lg";
        else if (width >= 1200 && width < 1260) return "font-size-md";
        // 2 Columnas
        else if (width >= 800 && width < 1200) return "font-size-xl";
        else if (width >= 650 && width < 800) return "font-size-lg";
        else if (width >= 576 && width < 650) return "font-size-md";
        // 1 Column
        else if (width >= 400 && width < 576) return "font-size-xl";
        else if (width < 400) return "font-size-lg";
        else return "font-size-xl";
    }

    //LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }
    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    componentDidMount() {
        let _this = this;
        this.dataSource_tblPais.load().done(function () {
            _this.datasource_datos_lavanderia.load().done(function () {
                _this.cargaDatos_Lavanderia();
            });
        });
    }
    componentDidUpdate(prevProps, prevState) {
        let { lavanderia } = this.props;
        let { fechaSel, compañiaSel, tipoKpiSel, dxMultiView_index } = this.state;

        let hayCambiosLavanderia = lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia;
        let hayCambiosFecha =
            fechaSel.getFullYear() !== prevState.fechaSel.getFullYear() ||
            fechaSel.getMonth() !== prevState.fechaSel.getMonth();

        if (hayCambiosLavanderia || hayCambiosFecha) {
            if (hayCambiosFecha) {
                //Se carga los datos del selector y después el de los informes si se ha selecciona una lavandería.
                if (dxMultiView_index === 0) {
                    this.loadPanel_show();
                }

                this.datasource_datos_lavanderia.load();
                this.datasource_tblCalendarioLavanderia.load();
                if (compañiaSel != null && tipoKpiSel != null) {
                    this.datasource_datos_compañia.reload();
                    this.datasource_datos_tipoKpi.reload();
                    this.datasource_datos_entidad.reload();
                    this.datasource_datos_compañia_diario.load();
                    this.datasource_datos_tipoKpi_diario.load();
                    if (tipoKpiSel.idTipoTrabajo !== -1) {
                        this.datasource_datos_RRHH_diario.load();
                    }
                    this.datasource_datos_entidad_diario.load();
                } else {
                    this.cargaDatos_Lavanderia();
                }
            } else {
                this.loadPanel_show();
                this.cargaDatos_Lavanderia();
            }
        }
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
    resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
    setLavanderia: (lavanderia) => dispatch(globalActions.setLavanderia(lavanderia)),
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IngresosCostes);
