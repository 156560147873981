import React, { useEffect, useRef, useState } from "react";

import { EmptyItem, Item } from "devextreme-react/form";

import AlmacenOrigenItem, {
  origenOptions,
} from "pages/AssistantNew/Movimientos/shared/components/FormItems/AlmacenOrigenItem";
import NumRegistroItem from "pages/AssistantNew/Movimientos/shared/components/FormItems/NumRegistroItem";
import SharedForm from "pages/AssistantNew/Movimientos/shared/components/SharedFormMovimientoRecambio";
import { getTrad } from "helpers";

const FormMovimientoRecambio = ({
  formData,
  setCodigoMoneda,
  disableCoreFields,
  ...props
}) => {
  const formRef = useRef(null);

  const { fecha, idAlmacenOrigen, idMovimientoRecambio } = formData;

  const [fieldsDisableBuscador, setFieldsDisableBuscador] = useState({
    fecha,
    idAlmacenOrigen,
  });

  useEffect(() => {
    setFieldsDisableBuscador({
      fecha,
      idAlmacenOrigen,
    });
  }, [idMovimientoRecambio]);

  const getText = (label) => ({ text: getTrad(label) });

  const origen = (
    <AlmacenOrigenItem
      formRef={formRef}
      value={idAlmacenOrigen}
      readOnly={disableCoreFields}
      onlyAlmacenPadre
      setCodigoMoneda={setCodigoMoneda}
      onChange={(value) =>
        setFieldsDisableBuscador({
          ...fieldsDisableBuscador,
          idAlmacenOrigen: value.idAlmacenOrigen,
        })
      }
    />
  );

  const origenOptionsFinal = {
    label: getText("almacenOrigen"),
    ...origenOptions,
  };

  const destinoOptions = {
    dataField: "clienteDestino",
    label: getText("clienteDestino"),
    colSpan: 3,
    editorOptions: {
      readOnly: disableCoreFields,
    },
  };

  const disableBuscador = Object.keys(fieldsDisableBuscador).some(
    (key) => fieldsDisableBuscador[key] === null
  );

  const onChangeFecha = ({ value }) =>
    setFieldsDisableBuscador({ ...fieldsDisableBuscador, fecha: value });

  return (
    <SharedForm
      ref={formRef}
      formData={formData}
      origen={origen}
      origenOptions={origenOptionsFinal}
      destinoOptions={destinoOptions}
      disableCoreFields={disableCoreFields}
      disableBuscador={disableBuscador}
      onChangeFecha={onChangeFecha}
      {...props}
    >
      <Item {...NumRegistroItem(getText)} />
      <EmptyItem colSpan={3} />
    </SharedForm>
  );
};

export default FormMovimientoRecambio;
