import { formatNumber } from "helpers";

export const displayExpr_CodigoDenominacion = (data) => {
    if (!data) return data;
    return data?.codigo + " - " + data?.denominacion;
};

export function getPorcentajeDesviacion(valorPorcentaje) {
    let porcentaje;
    if (valorPorcentaje * 100 > 10000) {
        porcentaje = "+10.000 %";
    } else if (valorPorcentaje * 100 < -10000) {
        porcentaje = "-10.000 %";
    } else {
        porcentaje = formatNumber(valorPorcentaje, 2, "percent");
    }
    return porcentaje;
}
