import React from "react";

import { getTrad } from "helpers";

import DataGrid, { Column, FilterRow, Paging, Selection, Pager } from "devextreme-react/data-grid";
import CierreCell from "./CierreCell";

class DataGrid_Centro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: props.dataSource ?? [],
        };
    }

    componentDidMount() {
        const { dataSource, keyExpr } = this.props;
        let new_dataSource = dataSource;
        if (keyExpr === "idAdmElementoPEP") {
            new_dataSource = dataSource.filter(
                (x) => x.codigo.length === 8 || (x.codigo.length === 12 && x.codigo.includes(".REF"))
            );
        }
        this.setState({ dataSource: new_dataSource });
    }

    componentDidUpdate(prevProps, prevState) {
        const { dataSource, keyExpr } = this.props;
        if (dataSource !== prevProps.dataSource) {
            let new_dataSource = dataSource;
            if (keyExpr === "idAdmElementoPEP") {
                new_dataSource = dataSource.filter(
                    (x) => x.codigo.length === 8 || (x.codigo.length === 12 && x.codigo.includes(".REF"))
                );
            }
            this.setState({ dataSource: new_dataSource });
        }
    }

    render() {
        const { keyExpr, caption, value, onSelectionChanged } = this.props;
        const { dataSource } = this.state;
        let isEditorActive = value.some((x) => x[keyExpr] != null);
        return (
            <DataGrid
                className="he-100"
                dataSource={dataSource}
                remoteOperations={false}
                showColumnLines={false}
                showRowLines={false}
                hoverStateEnabled={true}
                selectedRowKeys={isEditorActive ? value : []}
                onSelectionChanged={onSelectionChanged}
                onRowClick={this.onRowClick}
            >
                <FilterRow visible={true} />
                <Selection mode={"multiple"} showCheckBoxesMode={"always"} />
                <Paging enabled={true} />
                <Pager visible={true} />

                <Column dataField={keyExpr} caption={" "} visible={false} />
                <Column dataField={"denominacion"} caption={getTrad(caption)} dataType={"string"} />
                <Column
                    dataField={"codigo"}
                    caption={getTrad("codigo")}
                    dataType={"string"}
                    width={"33%"}
                    alignment={"center"}
                    sortIndex={0}
                    sortOrder={"asc"}
                    sortingMethod={this.sortingMethod}
                />
                <Column
                    dataField={"tblCierrePresupuestario"}
                    cssClass={"position-relative"}
                    caption={""}
                    width={35}
                    dataType={"object"}
                    allowFiltering={false}
                    allowSorting={false}
                    cellComponent={CierreCell}
                />
            </DataGrid>
        );
    }

    onRowClick = (e) => {
        if (e.isSelected) {
            e.component.deselectRows([e.key]);
        } else {
            e.component.selectRows([e.key], true);
        }
    };

    sortingMethod = (a, b) => {
        if (a.length < b.length) return -1;
        if (a.length > b.length) return 1;
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    };
}

export default DataGrid_Centro;
