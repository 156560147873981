import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
    getNombreFormulario,
    patchRequestHandler,
    errorHandler,
    authHeader,
    getTrad,
    tooltipControl_creacion,
    dxMensajePregunta,
    capitalize,
    phone_Pattern,
    durationToDatetime,
    leadingZero,
    isSameDay,
    stringToDateTime_timeZero,
    startOfYear,
    endOfYear,
    compressBase64Img_scaleWidth,
    rotateBase64Image,
    formatTime_parameter,
    formatDate_noTime_parameter,
    dayDiff,
    monthDiff,
    formatDateTime,
    formatDate_parameter,
    addDays,
    flatObject,
} from "../../../helpers";

import $, { data } from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import query from "devextreme/data/query";

import notify from "devextreme/ui/notify";

import { EmptyItem, Form, GroupItem, SimpleItem } from "devextreme-react/form";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    LoadPanel as LoadPanel_DataGrid,
    Pager,
    Paging,
    Editing,
    Lookup as DataGrid_Lookup,
    Popup as Popup_dataGrid,
    Form as Popup_Form,
    EmailRule,
    PatternRule,
    KeyboardNavigation,
} from "devextreme-react/data-grid";

import { Popover } from "devextreme-react/popover";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Validator, { RequiredRule, CustomRule, AsyncRule, NumericRule } from "devextreme-react/validator";
import validationEngine from "devextreme/ui/validation_engine";

import { LoadPanel } from "devextreme-react/load-panel";
import { NumberBox, DateBox, TextArea, Gallery, FileUploader } from "devextreme-react";
import TextBox, { Button as TextButton } from "devextreme-react/text-box";

import { Item, Tab, TabPanelOptions } from "devextreme-react/form";
import List from "devextreme-react/list";
import Box, { Item as ItemBox } from "devextreme-react/box";
import MultiView, { Item as MultiviewItem } from "devextreme-react/multi-view";
import { Switch } from "devextreme-react/switch";
import SelectBox from "devextreme-react/select-box";
import { Button } from "devextreme-react/button";

import { Popup, ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";

import { TileView } from "devextreme-react/tile-view";
import { Lookup, DropDownOptions } from "devextreme-react/lookup";
import { Tooltip } from "devextreme-react/tooltip";
import NoDataPopup from "../../../components/NoDataPopup/index";

//CUSTOM COMPONENTS
import { editCellComponent } from "../../../components/DataGrid/Cells";
import AdjuntarArchivos from "../../../components/AdjuntarArchivos/AdjuntarArchivos";
import CountryPicker from "../../../components/CountryPicker/CountryPicker";
import LottieIcon from "../../../components/LottieIcon";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import es from "react-phone-input-2/lang/es.json";
import * as events from "devextreme/events";
import YearCalendar from "../../../components/YearCalendar/YearCalendar";
import LoadPanel_ProgressBar from "../../../components/LoadPanel_ProgressBar/LoadPanel_ProgressBar";
import SelectorLavanderias from "./components/SelectorLavanderias";
import TablaHistorialNominas from "./components/TablaHistorialNominas";

//Css
import "./Css.scss";

//SignalRCore
import { signalRCoreService } from "services";

import SelectorDiasLibres from "components/SelectorDiasLibres/SelectorDiasLibres";
import AsignarLlamamientos from "./components/AsignarLlamamientos/AsignarLlamamientos";

import PopupIdentificacion from "./components/PopupIdentificacion";
import PopupAltaGestoria from "./components/PopupAltaGestoria";
import DatosLaboralesForm from "./components/DatosLaboralesForm";
import FechaAntiguedadPopup from "./components/FechaAntiguedadPopup";

let codigosGestoria = [];
const tabDatosLaborales_Padre_personal = {
    of: ".tabDatosLaborales_Padre_personal",
};
const tabDocumentos_Padre_personal = { of: "#tabDocumentos_Padre_personal" };
const tabCalendarioLaboral_Padre_personal = {
    of: "#tabCalendarioLaboral_Padre_personal",
};

export const PersonaSel = React.createContext({
    personaSel: {},
    dxFormItem_render: () => {},
});

class PersonalGeneral extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            personaSel: null,
            contratosNPersonaSel: null,
            contratosPopup_isVisible: false,
            dxTabPanel_fichaPersona_selectedTabName: "datosPersonales",
            isLoading_personaNLavanderia_dxList: false,
            idLavSel_documentosGeneral: null,
            isVisible_dxPopup_gestionAlmRecambios: false,
            isVisible_warning_datosPersonales: false,
            isVisible_warning_datosSalariales: false,
            isVisible_dxPopup_gestionarDocumentacion_comun: false,
            isVisible_dxPopup_addNewPersona: false,
            isVisible_dxPopup_ImportarDocumentos: false,
            datasource_persona: [],
            datasource_tblTurno_todos: [],
            datasource_tblTurno: [],
            datasource_tblTipoTrabajo: [],
            datasource_lavanderia_documentoGeneral: [],
            datasource_tblGenero: [],
            documentos: [],
            carpetaDocumentosSel: null,
            dataSource_documentos: [],
            datasource_tblTallaAlfa: [],
            datasource_tblDiscapacidad: [],
            datasource_tblNivelEstudios: [],
            datasource_tblTipoDocumentoIdentidad: [],
            datasource_tblPais: [],
            datasource_tblEstadoCivil: [],
            accionesModificarDatos_fileUploader: [],
            datasource_datos_loadPromise_documentos: null,
            datasource_datos_loadPromise_fotos: null,
            dxList_persona_listaGeneral_selectedItemKeys: [],
            data_personaGeneralSel: [],
            documentosNGrandParent: [],
            documentosPerfilPersona: [],
            validationError_telefono: false,
            yearCalendar_currentYear: new Date().getFullYear(),
            isVisible_dxPopup_enviarAltasGestoria: false,
            personasSel_enviarAltasGestoria: [],
            yearCalendar_newData: [],
            tblCalendarioPersonal_cache: [],
            isValid_warning_datosLaborales: true,
            isValid_diasLibres: true,
            isValid_warning_calendario: true,
            minFechaBaja_altaPersona: null,
            maxFechaAlta_altaPersona: null,
            disabledDays: [],
            documentosImportarDocumentos: [],
            accionesModificarDatos_fileUploader_ImportarDocumentos: [],
            codigosGestoria: [],
            numNominasError: 0,
            isVisible_LoadPanel_ProgressBar: false,
            numArchivos_LoadPanel_ProgressBar: 0,
            tabDatosPersonales: false,
            showLoading_tabDatosLaborales: false,
            showLoading_tabDocumentos: false,
            tabPanelPersona_deferRendering: true,
            calendar_firstRender: true,
            showLoading_adjuntarArchivos: null,
            nombreNominasImportadas: null,
            popup_nombrarNominas_visible: false,
            formData_nombrarNominas: {},
            isRendered_tabCalendario: false,
            isVisible_GaleriaArchivos: false,
            archivoSel: null,
            dataField_archivoSel: null,
            removeFotos: [],
            isVisible_dxPopup_enviarAltasGestoria_loading: false,
            progress: 0,
            filterRow_idLavanderia: null,
            filterRow_idCentro: null,
            maxFileExceeded: false,
            isGuardando_fichaPersona: false,
            datasource_tblCategoriaInterna_lookupGrid: [],
            datasource_tblCategoriaInterna_lookupGrid_grouped: [],
            idSelection_tblCategoriaInterna_lookupGrid: null,
            idSelection_centroLav_lookupGrid: null,
            idsSubturnosVisibles: [],
            isVisible_noDataPopup: false,
            datasource_tblCentroTrabajo_Lavanderia_todos: [],
            yearCalendar_estadosPorDefecto: [],
            idAsuntoMailAltasGestorias: 1,
            multiViewCarpetasIndex: 0,
            multiViewCarpetas_DocumentosAgrupados: null,
            dxPopup_llamamientos_isVisible: false,
            dxPopup_llamamientos_consulta: true,
            datasource_tblLlamamiento: [],
            isSalarioBrutoMensualManual: false,
            identifyNeeded: false,
            identifySuccess: false,
            isVisible_FechaAntiguedadPopup: false,
        };

        // #region REFERENCIAS
        this.dxDataGrid_personas_REF = React.createRef();
        this.dxList_documentos_REF = React.createRef();
        this.dxList_documentos_personas_REF = React.createRef();
        this.dxList_documentos_personasGeneral_REF = React.createRef();
        this.dxForm_cambiosPersonal_new_REF = React.createRef();
        this.dxDataGrid_personas_enviarAltasGestoria_REF = React.createRef();
        this.dxDataGrid_tblPersonaNTipoContrato_REF = React.createRef();
        this.dxLoadPanel_tabCalendario_REF = React.createRef();
        this.dxForm_nombrarNominas_REF = React.createRef();
        this.dxTextBox_nombre_nombrarNominas_REF = React.createRef();
        this.dxPopover_REF = React.createRef();
        this.dxValidator_codigoGestoria_REF = React.createRef();
        this.AsignarLlamamientos_REF = React.createRef();
        this.dxButton_fichaPersona_guardar_REF = React.createRef();
        // #endregion

        // #region DATASOURCE
        this.datasource_persona_beforeSend = this.datasource_persona_beforeSend.bind(this);
        this.datasource_persona_onLoading = this.datasource_persona_onLoading.bind(this);
        this.datasource_persona_altasGestoria_onLoading = this.datasource_persona_altasGestoria_onLoading.bind(this);
        this.datasource_persona_onLoaded = this.datasource_persona_onLoaded.bind(this);
        this.datasource_persona_enviarAltasGestoria_onLoaded =
            this.datasource_persona_enviarAltasGestoria_onLoaded.bind(this);
        this.datasource_tblDocumento_beforeSend = this.datasource_tblDocumento_beforeSend.bind(this);
        this.datasource_tblDocumento_onLoading = this.datasource_tblDocumento_onLoading.bind(this);
        this.datasource_tblDocumento_map = this.datasource_tblDocumento_map.bind(this);
        this.datasource_personaGeneral_beforeSend = this.datasource_personaGeneral_beforeSend.bind(this);
        this.datasource_tblDocumento_documentoGeneral_beforeSend =
            this.datasource_tblDocumento_documentoGeneral_beforeSend.bind(this);
        this.datasource_tblGenero_map = this.datasource_tblGenero_map.bind(this);
        this.datasource_tblDocumentoPerfilPersona_onLoading =
            this.datasource_tblDocumentoPerfilPersona_onLoading.bind(this);
        this.datasource_tblUsuario_beforeSend = this.datasource_tblUsuario_beforeSend.bind(this);
        this.context_calendarioLaboral_beforeSend = this.context_calendarioLaboral_beforeSend.bind(this);
        this.datasource_datos_tblCalendarioPersonal_Estado_postProcess =
            this.datasource_datos_tblCalendarioPersonal_Estado_postProcess.bind(this);
        this.datasource_persona_codigoGestoria_onLoading = this.datasource_persona_codigoGestoria_onLoading.bind(this);
        this.context_CodigoGestoria_beforeSend = this.context_CodigoGestoria_beforeSend.bind(this);
        this.datasource_tblCategoriaConvenio_onLoading = this.datasource_tblCategoriaConvenio_onLoading.bind(this);
        this.datasource_tbltblCategoriaInterna_onLoading = this.datasource_tbltblCategoriaInterna_onLoading.bind(this);
        this.datasource_tblCategoriaInterna_lookupGrid_onLoading =
            this.datasource_tblCategoriaInterna_lookupGrid_onLoading.bind(this);
        this.datasource_tblCategoriaInterna_lookupGrid_postProcess =
            this.datasource_tblCategoriaInterna_lookupGrid_postProcess.bind(this);
        this.datasource_tblCorreoAltaGestoriaNCentroLav_onLoading =
            this.datasource_tblCorreoAltaGestoriaNCentroLav_onLoading.bind(this);
        this.datasource_tblCalendarioLavanderia_beforeSend =
            this.datasource_tblCalendarioLavanderia_beforeSend.bind(this);
        this.datasource_tblCalendarioLavanderia_onLoaded = this.datasource_tblCalendarioLavanderia_onLoaded.bind(this);
        // #endregion

        // #region LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);
        // #endregion

        // #region EVENTOS
        this.dxDataGrid_personas_onCellHoverChanged = this.dxDataGrid_personas_onCellHoverChanged.bind(this);
        this.dxDataGrid_personas_onRowInserting = this.dxDataGrid_personas_onRowInserting.bind(this);
        this.dxDataGrid_personas_onRowInserted = this.dxDataGrid_personas_onRowInserted.bind(this);
        this.dxDataGrid_personas_onRowUpdating = this.dxDataGrid_personas_onRowUpdating.bind(this);
        this.dxDataGrid_personas_onRowRemoving = this.dxDataGrid_personas_onRowRemoving.bind(this);
        this.dxDataGrid_personas_onRowValidating = this.dxDataGrid_personas_onRowValidating.bind(this);
        this.dxDataGrid_personas_onToolbarPreparing = this.dxDataGrid_personas_onToolbarPreparing.bind(this);
        this.dxPopup_persona_titleRender = this.dxPopup_persona_titleRender.bind(this);
        this.dxPopupPersona_titleRender_hide = this.dxPopupPersona_titleRender_hide.bind(this);
        this.dxButton_fichaPersona_guardar_onClick = this.dxButton_fichaPersona_guardar_onClick.bind(this);
        this.dxButton_fichaPersona_edit_cancelar_onClick = this.dxButton_fichaPersona_edit_cancelar_onClick.bind(this);
        this.dxDataGrid_personaNTipoContrato_onToolbarPreparing =
            this.dxDataGrid_personaNTipoContrato_onToolbarPreparing.bind(this);
        this.dxDataGrid_personaNTipoContrato_onRowValidating =
            this.dxDataGrid_personaNTipoContrato_onRowValidating.bind(this);
        this.validationCallback_CustomRule_fechaBajaContrato =
            this.validationCallback_CustomRule_fechaBajaContrato.bind(this);
        this.dxDataGrid_personaNTipoContrato_removeCellRender =
            this.dxDataGrid_personaNTipoContrato_removeCellRender.bind(this);
        this.dxDataGrid_personaNTipoContrato_removeCellRender_onClick =
            this.dxDataGrid_personaNTipoContrato_removeCellRender_onClick.bind(this);
        this.cellRender_avisos_datos = this.cellRender_avisos_datos.bind(this);
        this.cellRender_docIdentidad = this.cellRender_docIdentidad.bind(this);
        this.validationCallback_CustomRule_contratoExistente_fechaBajaContrato =
            this.validationCallback_CustomRule_contratoExistente_fechaBajaContrato.bind(this);
        this.validationCallback_CustomRule_contratoExistente_fechaAltaContrato =
            this.validationCallback_CustomRule_contratoExistente_fechaAltaContrato.bind(this);
        this.validationCallback_CustomRule_idMotivoPausa = this.validationCallback_CustomRule_idMotivoPausa.bind(this);
        this.validationCallback_CustomRule_numDiasPeriodoPrueba =
            this.validationCallback_CustomRule_numDiasPeriodoPrueba.bind(this);
        this.validationCallback_CustomRule_nuevaPersona_fechaBajaContrato =
            this.validationCallback_CustomRule_nuevaPersona_fechaBajaContrato.bind(this);
        this.validationCallback_CustomRule_nuevaPersona_fechaAltaContrato =
            this.validationCallback_CustomRule_nuevaPersona_fechaAltaContrato.bind(this);
        this.validationCallback_CustomRule_nuevaPersona_numDiasPeriodoPrueba =
            this.validationCallback_CustomRule_nuevaPersona_numDiasPeriodoPrueba.bind(this);
        this.dxDataGrid_personaNTipoContrato_onRowRemoving =
            this.dxDataGrid_personaNTipoContrato_onRowRemoving.bind(this);
        this.dxDataGrid_personaNTipoContrato_onRowUpdating =
            this.dxDataGrid_personaNTipoContrato_onRowUpdating.bind(this);
        this.dxDataGrid_personaNTipoContrato_onRowInserted =
            this.dxDataGrid_personaNTipoContrato_onRowInserted.bind(this);
        this.dxDataGrid_personaNTipoContrato_onEditorPreparing =
            this.dxDataGrid_personaNTipoContrato_onEditorPreparing.bind(this);
        this.onClick_gestionarDocumentacion_comun = this.onClick_gestionarDocumentacion_comun.bind(this);
        this.dxDataGrid_tblPersonas_activo_editCellRender =
            this.dxDataGrid_tblPersonas_activo_editCellRender.bind(this);
        this.dxDataGrid_tblPersonas_activo_onValueChanged =
            this.dxDataGrid_tblPersonas_activo_onValueChanged.bind(this);
        this.onInitNewRow_dxDataGrid_tblPersona = this.onInitNewRow_dxDataGrid_tblPersona.bind(this);
        this.onEditorPreparing_dxDataGrid_tblPersona = this.onEditorPreparing_dxDataGrid_tblPersona.bind(this);
        this.dxDataGrid_tblPersona_lookUpFilter_idCategoriaInterna =
            this.dxDataGrid_tblPersona_lookUpFilter_idCategoriaInterna.bind(this);
        this.dxPopup_fichaPersona_onContentReady = this.dxPopup_fichaPersona_onContentReady.bind(this);
        this.dxPopup_fichaPersona_onShown = this.dxPopup_fichaPersona_onShown.bind(this);
        this.dxPopup_fichaPersona_onHidden = this.dxPopup_fichaPersona_onHidden.bind(this);
        this.dxPopup_fichaPersona_addPersona_onContentReady =
            this.dxPopup_fichaPersona_addPersona_onContentReady.bind(this);
        this.dxPopup_personal_ImportarDocumentos_onContentReady =
            this.dxPopup_personal_ImportarDocumentos_onContentReady.bind(this);
        this.onRowPrepared_dxDataGrid_tblPersona = this.onRowPrepared_dxDataGrid_tblPersona.bind(this);
        this.onEditingStart_dxDataGrid_tblPersona = this.onEditingStart_dxDataGrid_tblPersona.bind(this);
        this.dxSelectBox_lavanderia_documentosGeneral_onValueChanged =
            this.dxSelectBox_lavanderia_documentosGeneral_onValueChanged.bind(this);
        this.dxList_persona_listaGeneral_onContentReady = this.dxList_persona_listaGeneral_onContentReady.bind(this);
        this.dxList_persona_listaGeneral_onOptionChanged = this.dxList_persona_listaGeneral_onOptionChanged.bind(this);
        this.listTipoDocumento_onContentReady = this.listTipoDocumento_onContentReady.bind(this);
        this.listTipoDocumento_onSelectionChanged = this.listTipoDocumento_onSelectionChanged.bind(this);
        this.itemTitleRender_dxTabPanel_Persona = this.itemTitleRender_dxTabPanel_Persona.bind(this);
        this.dxTabPanel_fichaPersona_onSelectionChanged = this.dxTabPanel_fichaPersona_onSelectionChanged.bind(this);
        this.reset_personaSel = this.reset_personaSel.bind(this);
        this.dxPopup_fichaPersona_addPersona_onShowing = this.dxPopup_fichaPersona_addPersona_onShowing.bind(this);
        this.dxPopup_fichaPersona_addPersona_onHiding = this.dxPopup_fichaPersona_addPersona_onHiding.bind(this);
        this.dxPopup_ImportarDocumentos_onHiding = this.dxPopup_ImportarDocumentos_onHiding.bind(this);
        this.dxPopup_ImportarDocumentos_onHidden = this.dxPopup_ImportarDocumentos_onHidden.bind(this);
        this.onShown_popup_nombrarNominas = this.onShown_popup_nombrarNominas.bind(this);
        this.onHiding_popup_modificarNominas = this.onHiding_popup_modificarNominas.bind(this);
        this.dxButton_altaNuevaPersona_guardar_onClick = this.dxButton_altaNuevaPersona_guardar_onClick.bind(this);
        this.dxButton_fichaPersona_cancelar_onClick = this.dxButton_fichaPersona_cancelar_onClick.bind(this);
        this.listTipoDocumento_groupRender = this.listTipoDocumento_groupRender.bind(this);
        this.nuevaPersona_onClick = this.nuevaPersona_onClick.bind(this);
        this.limpiarFiltros_onClick = this.limpiarFiltros_onClick.bind(this);
        this.actualizar_onClick = this.actualizar_onClick.bind(this);
        this.ImportarDocumentos_onClick = this.ImportarDocumentos_onClick.bind(this);
        this.dxTextBox_nombrarNominas_onValueChanged = this.dxTextBox_nombrarNominas_onValueChanged.bind(this);
        this.dxSelectBox_nombrarNominas_onValueChanged = this.dxSelectBox_nombrarNominas_onValueChanged.bind(this);
        this.dxButton_nombrarNominas_guardar_onClick = this.dxButton_nombrarNominas_guardar_onClick.bind(this);
        this.listTipoDocumento_personas_onContentReady = this.listTipoDocumento_personas_onContentReady.bind(this);
        this.listTipoDocumento_itemRender = this.listTipoDocumento_itemRender.bind(this);
        this.dxPopup_gestionarDocumentacion_onContentReady =
            this.dxPopup_gestionarDocumentacion_onContentReady.bind(this);
        this.dxPopup_gestionarDocumentacion_onHiding = this.dxPopup_gestionarDocumentacion_onHiding.bind(this);
        this.dxPopup_gestionarDocumentacion_onHide = this.dxPopup_gestionarDocumentacion_onHide.bind(this);
        this.dxButton_gestionarDocumentacion_guardar_onClick =
            this.dxButton_gestionarDocumentacion_guardar_onClick.bind(this);
        this.dxButton_gestionarDocumentacion_cancelar_onClick =
            this.dxButton_gestionarDocumentacion_cancelar_onClick.bind(this);
        this.yearCalendar_onYearChanged = this.yearCalendar_onYearChanged.bind(this);
        this.idCentroLav_setCellValue = this.idCentroLav_setCellValue.bind(this);
        this.plusAsistencia_setCellValue = this.plusAsistencia_setCellValue.bind(this);
        this.plusResponsabilidad_setCellValue = this.plusResponsabilidad_setCellValue.bind(this);
        this.plusPeligrosidad_setCellValue = this.plusPeligrosidad_setCellValue.bind(this);
        this.incentivo_setCellValue = this.incentivo_setCellValue.bind(this);
        this.impHoraExtra_setCellValue = this.impHoraExtra_setCellValue.bind(this);
        this.plusProductividad_setCellValue = this.plusProductividad_setCellValue.bind(this);
        this.percSegSocial_setCellValue = this.percSegSocial_setCellValue.bind(this);
        this.salarioBase_setCellValue = this.salarioBase_setCellValue.bind(this);
        this.salarioBrutoAnual_calculateCellValue = this.salarioBrutoAnual_calculateCellValue.bind(this);
        this.acuerdoNC_setCellValue = this.acuerdoNC_setCellValue.bind(this);
        this.salarioEspecie_setCellValue = this.salarioEspecie_setCellValue.bind(this);
        this.costeEmpresa_calculateCellValue = this.costeEmpresa_calculateCellValue.bind(this);
        this.yearCalendar_onDateChanged = this.yearCalendar_onDateChanged.bind(this);
        this.yearCalendar_onEstadoDeleting = this.yearCalendar_onEstadoDeleting.bind(this);
        this.dxButton_rotarImagen_onClick = this.dxButton_rotarImagen_onClick.bind(this);
        // #endregion

        // #region Altas gestoría
        this.onClick_enviarAltasGestoria = this.onClick_enviarAltasGestoria.bind(this);
        this.onClick_enviarMailAltasGestoria = this.onClick_enviarMailAltasGestoria.bind(this);
        this.onClick_cancelarAltasGestoria = this.onClick_cancelarAltasGestoria.bind(this);
        this.dxPopup_enviarAltasGestoria_onHiding = this.dxPopup_enviarAltasGestoria_onHiding.bind(this);
        this.onRowClick_dxDataGrid_tblPersona_EnviarAltasGestoria =
            this.onRowClick_dxDataGrid_tblPersona_EnviarAltasGestoria.bind(this);
        this.dxList_persona_enviarAltasGestoria_onItemDeleted =
            this.dxList_persona_enviarAltasGestoria_onItemDeleted.bind(this);
        this.onToolbarPreparing_dxDataGrid_tblPersona_EnviarAltasGestoria =
            this.onToolbarPreparing_dxDataGrid_tblPersona_EnviarAltasGestoria.bind(this);
        // #endregion

        this.dxFormItem_render = this.dxFormItem_render.bind(this);
        this.dxFormItem_nuevaPersona_render = this.dxFormItem_nuevaPersona_render.bind(this);
        this.fotos_itemRender = this.fotos_itemRender.bind(this);
        this.dxFormItem_render_tblPersonaNTipoContrato = this.dxFormItem_render_tblPersonaNTipoContrato.bind(this);
        this.dxButton_ImportarDocumentos_continuar_onClick =
            this.dxButton_ImportarDocumentos_continuar_onClick.bind(this);
        this.dxSelectionBox_ImportarDocumentos_continuar_onSelectionChanged =
            this.dxSelectionBox_ImportarDocumentos_continuar_onSelectionChanged.bind(this);
        this.dxButton_ImportarDocumentos_cancelar_onClick =
            this.dxButton_ImportarDocumentos_cancelar_onClick.bind(this);
        this.context_documento_beforeSend = this.context_documento_beforeSend.bind(this);
        this.onHiding_popup_galeriaArchivos = this.onHiding_popup_galeriaArchivos.bind(this);
        this.dxTile_onClick = this.dxTile_onClick.bind(this);
        this.fotos_onUploadStarted = this.fotos_onUploadStarted.bind(this);
        this.dxPopup_galeriaFotos_aceptar_onClick = this.dxPopup_galeriaFotos_aceptar_onClick.bind(this);
        this.btnDownloadDocument_onClick = this.btnDownloadDocument_onClick.bind(this);

        // #region METODOS
        this.updateArchivos = this.updateArchivos.bind(this);
        this.updateArchivos_ImportarDocumentos = this.updateArchivos_ImportarDocumentos.bind(this);
        this.updateValor_paises = this.updateValor_paises.bind(this);
        this.saveDocuments = this.saveDocuments.bind(this);
        this.saveCalendario = this.saveCalendario.bind(this);
        this.removeFotos = this.removeFotos.bind(this);
        this.getSalarioBrutoAnual = this.getSalarioBrutoAnual.bind(this);
        this.dxFormEditor_onValueChanged = this.dxFormEditor_onValueChanged.bind(this);
        this.dxFormEditor_nuevaPersona_telefono_onValueChanged =
            this.dxFormEditor_nuevaPersona_telefono_onValueChanged.bind(this);
        this.diasLibres_onValidChange = this.diasLibres_onValidChange.bind(this);
        this.diasLibres_modify_onValueChanged = this.diasLibres_modify_onValueChanged.bind(this);
        this.dxFormEditor_telefono_onValueChanged = this.dxFormEditor_telefono_onValueChanged.bind(this);
        this.dxFormEditor_nuevaPersona_onValueChanged = this.dxFormEditor_nuevaPersona_onValueChanged.bind(this);
        this.dxTextBox_onKeyPress_noEspacio = this.dxTextBox_onKeyPress_noEspacio.bind(this);
        this.dxSelectBox_displayExpr = this.dxSelectBox_displayExpr.bind(this);
        this.dxSelectBox_displayExpr_upperCase = this.dxSelectBox_displayExpr_upperCase.bind(this);
        this.dxValidator_emailEmpresa_asyncRule_validationCallback =
            this.dxValidator_emailEmpresa_asyncRule_validationCallback.bind(this);
        this.dxValidator_emailPersonal_asyncRule_validationCallback =
            this.dxValidator_emailPersonal_asyncRule_validationCallback.bind(this);
        this.dxValidator_numDocumentoIdentidad_asyncRule_validationCallback =
            this.dxValidator_numDocumentoIdentidad_asyncRule_validationCallback.bind(this);
        this.dxValidator_numDocumentoIdentidad_nuevaPersona_asyncRule_validationCallback =
            this.dxValidator_numDocumentoIdentidad_nuevaPersona_asyncRule_validationCallback.bind(this);
        this.loadCalendar = this.loadCalendar.bind(this);
        this.validateAsync_codigoGestoria = this.validateAsync_codigoGestoria.bind(this);
        // #endregion

        this.noNum_pattern = /^[^0-9]+$/;
        this.alfaNumeric_pattern = /^[a-zA-Z0-9]+$/;
        this.horasSemanales_pattern = /^([01]?[0-9]|2[0-3]):?[0-5][0-9]$/;
    }

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    //#region REFERENCIAS
    get dxDataGrid_personas() {
        return this.dxDataGrid_personas_REF.current.instance;
    }

    get dxList_documentos() {
        return this.dxList_documentos_REF.current.instance;
    }

    get dxList_documentos_personas() {
        return this.dxList_documentos_personas_REF.current.instance;
    }

    get dxList_documentos_personasGeneral() {
        return this.dxList_documentos_personasGeneral_REF.current.instance;
    }

    get dxForm_cambiosPersonal_new() {
        return this.dxForm_cambiosPersonal_new_REF.current.instance;
    }

    get dxDataGrid_personas_enviarAltasGestoria() {
        return this.dxDataGrid_personas_enviarAltasGestoria_REF.current.instance;
    }

    get dxDataGrid_tblPersonaNTipoContrato() {
        return this.dxDataGrid_tblPersonaNTipoContrato_REF.current.instance;
    }

    get dxLoadPanel_tabCalendario() {
        return this.dxLoadPanel_tabCalendario_REF.current.instance;
    }

    get dxForm_nombrarNominas() {
        return this.dxForm_nombrarNominas_REF.current.instance;
    }

    get dxTextBox_nombre_nombrarNominas() {
        return this.dxTextBox_nombre_nombrarNominas_REF.current.instance;
    }

    get dxPopover() {
        return this.dxPopover_REF.current.instance;
    }

    get dxValidator_codigoGestoria() {
        return this.dxValidator_codigoGestoria_REF.current.instance;
    }

    get AsignarLlamamientos() {
        return this.AsignarLlamamientos_REF.current;
    }

    get dxButton_fichaPersona_guardar() {
        return this.dxButton_fichaPersona_guardar_REF.current.instance;
    }

    get personaSelContextValue() {
        return {
            personaSel: this.state.personaSel,
            dxFormItem_render: this.dxFormItem_render,
        };
    }

    //#endregion

    //#region DATA SOURCE
    datasource_tblFormularioNUsuario = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblFormularioNUsuario",
            key: "idUsuario",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => (request.headers = { ...authHeader() }),
            onLoading: (loadOptions) => this.datasource_tblFormularioNUsuario_onLoading(loadOptions),
            onLoaded: (data) => this.datasource_tblFormularioNUsuario_onLoaded(data),
            version: 4,
        }),
    });

    datasource_tblFormularioNUsuario_onLoading = (loadOptions) => {
        const { personaSel } = this.state;
        loadOptions.filter = [["idUsuario", "=", personaSel.tblUsuario[0].idUsuario], "and", ["idFormulario", "=", 30]]; //* Form Pedidos
    };

    datasource_tblFormularioNUsuario_onLoaded = (data) => {
        const { personaSel } = this.state;

        let personaSel_ = { ...personaSel };
        personaSel_.isFormPedidoAllowed = data.length > 0;

        this.setState({ personaSel: personaSel_ });
    };

    datasource_persona = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_persona_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_persona_onLoading(data);
            },
            onLoaded: (data) => {
                this.datasource_persona_onLoaded(data);
            },
            version: 4,
        }),
    });

    datasource_persona_enviarAltasGestoria = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_persona_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_persona_altasGestoria_onLoading(data);
            },
            onLoaded: (data) => {
                this.datasource_persona_enviarAltasGestoria_onLoaded(data);
            },
            version: 4,
        }),
        select: [
            "idPersona",
            "nombre",
            "apellidos",
            "idCentroTrabajo",
            "idLavanderia",
            "idFotoPerfil",
            "idFotoDocumentoIdentidad_A",
            "idFotoDocumentoIdentidad_B",
            "idFotoNAF",
            "idFotoIBAN",
        ],
        expand: ["tblPersonaNTipoContrato($select=fechaAltaContrato;$orderby=fechaAltaContrato desc;$top=1)"],
    });

    datasource_persona_beforeSend(request) {
        request.headers = { ...authHeader() };

        if (request.method == "get") {
            request.params.filtroTipoTrabajoRRHH = false;
        }

        if (request.method === "POST") {
            let { personaSel } = this.state;
            request.params.emailEmpresa = personaSel.emailEmpresa === "" ? null : personaSel.emailEmpresa;
            request.params.idLocalizacion = personaSel.idLocalizacion;
            request.params.idLlamamiento = personaSel.idLlamamiento;
            request.url += "/PostPersonaUsuario";
        }
    }

    datasource_persona_altasGestoria_onLoading(loadOptions) {
        $.each(loadOptions.filter, function (index, item) {
            if (Array.isArray(item)) {
                if (Array.isArray(item[index])) {
                    $.each(item, function (index2, item2) {
                        if (Array.isArray(item2[0])) {
                            $.each(item2, function (subIndex, subItem) {
                                if (subItem[0] === "idCentroLav") {
                                    let parsedValue = JSON.parse(subItem["filterValue"]);
                                    if (parsedValue[0] === -1 && parsedValue[1]) {
                                        //lavanderia
                                        subItem[0] = "idLavanderia";
                                        subItem[2] = parsedValue[1];
                                    } else if (parsedValue[1] === -1 && parsedValue[0]) {
                                        // centro
                                        subItem[0] = "idCentroTrabajo";
                                        subItem[2] = parsedValue[0];
                                    }
                                }
                            });
                        } else if (item2[0] === "idCentroLav") {
                            let parsedValue = JSON.parse(item2["filterValue"]);
                            if (parsedValue[0] === -1 && parsedValue[1]) {
                                //lavanderia
                                item2[0] = "idLavanderia";
                                item2[2] = parsedValue[1];
                            } else if (parsedValue[1] === -1 && parsedValue[0]) {
                                // centro
                                item2[0] = "idCentroTrabajo";
                                item2[2] = parsedValue[0];
                            }
                        }
                    });
                }

                if (item[0] === "idCentroLav") {
                    let parsedValue = JSON.parse(item[2]);

                    if (parsedValue[0] === -1) {
                        //lavanderia
                        item[0] = "idLavanderia";
                        item[2] = parsedValue[1];
                    } else {
                        // centro
                        item[0] = "idCentroTrabajo";
                        item[2] = parsedValue[0];
                    }
                } else if (item[0] === "codigoGestoria") {
                    item[2] = String(item[2]);
                }
            } else {
                if (loadOptions.filter[0] === "idCentroLav") {
                    let centroLav = JSON.parse(loadOptions.filter[2]);

                    if (centroLav[0] === -1) {
                        //lavanderia
                        loadOptions.filter[0] = "idLavanderia";
                        loadOptions.filter[2] = centroLav[1];
                    } else {
                        //centro
                        loadOptions.filter[0] = "idCentroTrabajo";
                        loadOptions.filter[2] = centroLav[0];
                    }
                } else if (loadOptions.filter[0] === "codigoGestoria") {
                    loadOptions.filter[2] = String(loadOptions.filter[2]);
                }
            }
        });

        if (!loadOptions.filter || loadOptions.filter.length === 0) {
            loadOptions.filter = ["activo eq true and eliminado eq false"];
        } else {
            loadOptions.filter = [loadOptions.filter, "and", ["activo eq true and eliminado eq false"]];
        }

        $.each(loadOptions.sort, function (index, item) {
            if (item.selector === "idCentroLav") {
                item.selector = "idLavanderia";
                loadOptions.sort.push({ selector: "idCentroTrabajo", desc: item.desc });
            }
        });
    }

    datasource_persona_onLoading(loadOptions) {
        let { user } = this.props;
        $.each(loadOptions.filter, function (index, item) {
            if (Array.isArray(item)) {
                if (Array.isArray(item[index])) {
                    $.each(item, function (index2, item2) {
                        if (Array.isArray(item2[0])) {
                            $.each(item2, function (subIndex, subItem) {
                                if (subItem[0] === "idCentroLav") {
                                    let parsedValue = JSON.parse(subItem["filterValue"]);
                                    if (parsedValue[0] === -1 && parsedValue[1]) {
                                        //lavanderia
                                        subItem[0] = "idLavanderia";
                                        subItem[2] = parsedValue[1];
                                    } else if (parsedValue[1] === -1 && parsedValue[0]) {
                                        // centro
                                        subItem[0] = "idCentroTrabajo";
                                        subItem[2] = parsedValue[0];
                                    }
                                }
                            });
                        } else if (item2[0] === "idCentroLav") {
                            let parsedValue = JSON.parse(item2["filterValue"]);
                            if (parsedValue[0] === -1 && parsedValue[1]) {
                                //lavanderia
                                item2[0] = "idLavanderia";
                                item2[2] = parsedValue[1];
                            } else if (parsedValue[1] === -1 && parsedValue[0]) {
                                // centro
                                item2[0] = "idCentroTrabajo";
                                item2[2] = parsedValue[0];
                            }
                        }
                    });
                }

                if (item[0] === "idCentroLav") {
                    let parsedValue = JSON.parse(item[2]);

                    if (parsedValue[0] === -1) {
                        //lavanderia
                        item[0] = "idLavanderia";
                        item[2] = parsedValue[1];
                    } else {
                        // centro
                        item[0] = "idCentroTrabajo";
                        item[2] = parsedValue[0];
                    }
                } else if (item[0] === "codigoGestoria") {
                    item[2] = String(item[2]);
                } else if (item[0] === "idTurno" && item[2] == -1) {
                    item[2] = null;
                }
            } else {
                if (loadOptions.filter[0] === "idCentroLav") {
                    let centroLav = JSON.parse(loadOptions.filter[2]);

                    if (centroLav[0] === -1) {
                        //lavanderia
                        loadOptions.filter[0] = "idLavanderia";
                        loadOptions.filter[2] = centroLav[1];
                    } else {
                        //centro
                        loadOptions.filter[0] = "idCentroTrabajo";
                        loadOptions.filter[2] = centroLav[0];
                    }
                } else if (loadOptions.filter[0] === "codigoGestoria") {
                    loadOptions.filter[2] = String(loadOptions.filter[2]);
                }
            }
        });

        if (!loadOptions.filter || loadOptions.filter.length === 0) {
            loadOptions.filter = [
                ["eliminado eq false"],
                "and",
                [["tblUsuario/any(x:x/idLocalizacion eq 1 or x/idLocalizacion eq 2)"]],
            ]; // 1 y 2 - ESPAÑA - TEMPORAL!
        } else {
            loadOptions.filter = [
                [loadOptions.filter],
                "and",
                ["eliminado eq false"],
                "and",
                [["tblUsuario/any(x:x/idLocalizacion eq 1 or x/idLocalizacion eq 2)"]],
            ]; // 1 y 2 - ESPAÑA - TEMPORAL!
        }

        $.each(loadOptions.sort, function (index, item) {
            if (item.selector === "idCentroLav") {
                item.selector = "idLavanderia";
                loadOptions.sort.push({ selector: "idCentroTrabajo", desc: item.desc });
            }
        });
        if (user.enableDatosRRHH) {
            loadOptions.select = [
                "idPersona",
                "nombre",
                "apellidos",
                "horasDiarias",
                "idTurno",
                "idTipoTrabajo",
                "idGenero",
                "activo",
                "telefono",
                "prefijoTelefonico",
                "telefonoEmpresa",
                "prefijoTelefonicoEmpresa",
                "email",
                "fechaNacimiento",
                "direccion",
                "codigoPostal",
                "poblacion",
                "observaciones",
                "observacionesLaborales",
                "idCentroTrabajo",
                "idLavanderia",
                "calle",
                "numDomicilio",
                "piso",
                "puerta",
                "codigoPostal",
                "localidad",
                "idPais",
                "idTipoDocumentoIdentidad",
                "numDocumentoIdentidad",
                "NAF",
                "IBAN",
                "idEstadoCivil",
                "numHijos",
                "idNivelEstudios",
                "idDiscapacidad",
                "idTallaAlfa_Camiseta",
                "tallaPantalon",
                "idComunidadAutonoma",
                "nacionalidad",
                "idFotoPerfil",
                "idFotoDocumentoIdentidad_A",
                "idFotoDocumentoIdentidad_B",
                "idFotoNAF",
                "idFotoIBAN",
                "avisoLegalAceptado",
                "codigoGestoria",
                "caducidadDocumentoIdentidad",
                "isCodigoGestoriaValidado",
                "idCategoriaInterna",
                "isIBANSolicitado",
                "idEmpresaPolarier",
                "idFormatoDiasLibres",
                "idUsuario_validacion_nomina",
                "idAdmElementoPEP",
                "idAdmCentroCoste",
                "idAdmCuentaContable_Salario",
                "idAdmCuentaContable_SSEmpresa",
            ];
            loadOptions.expand = [
                "tblDatosSalariales($select=idPersona,salarioBase,plusAsistencia,plusResponsabilidad,plusPeligrosidad,incentivo,numPagas,percSegSocial,fechaAntiguedad,isTrienio,plusProductividad,impHoraExtra,salarioBrutoMensual,acuerdoNC,salarioEspecie)",
                "idCategoriaInternaNavigation",
                "tblUsuario($count=true;$expand=idLocalizacionNavigation($select=idPais),idLavanderia($select=idLavanderia);$select=idLocalizacion, idUsuario)",
                "tblCalendarioPersonal($top=0)",
                "tblPersonaNTipoContrato($select=idTipoContrato,fechaBajaContrato,fechaAltaContrato;$count=true;$orderby=fechaAltaContrato desc;$top=1)",
                "tblDiasLibresPersonal($select=idDiasLibresPersonal,idPersona,idDiaSemana,idDiaMes,numDia)",
                "tblCuadrantePersonal($filter=idEstado ne null;$orderby=fecha desc;$top=1;$select=fecha)",
                "tblJornadaPersona($orderby=fecha desc;$top=1;$select=fecha)",
                "tblSolicitudGestoria($filter= idEstadoSolicitudGestoria ne 3  )",
            ];
        } else {
            loadOptions.select = [
                "idPersona",
                "nombre",
                "apellidos",
                "horasDiarias",
                "idTurno",
                "idTipoTrabajo",
                "activo",
                "telefono",
                "prefijoTelefonico",
                "telefonoEmpresa",
                "prefijoTelefonicoEmpresa",
                "email",
                "observaciones",
                "observacionesLaborales",
                "idCentroTrabajo",
                "idLavanderia",
                "idFotoPerfil",
                "idCategoriaInterna",
                "idFormatoDiasLibres",
                "idEmpresaPolarier",
                "idAdmElementoPEP",
                "idAdmCentroCoste",
                "idAdmCuentaContable_Salario",
                "idAdmCuentaContable_SSEmpresa",
            ];
            loadOptions.expand = [
                "tblDatosSalariales($select=idPersona)",
                "idCategoriaInternaNavigation($select=idCategoriaInterna,idCategoriaConvenio,denominacion)",
                "tblUsuario($count=true;$expand=idLocalizacionNavigation($select=idPais),idLavanderia($select=idLavanderia);$select=idLocalizacion, idUsuario)",
                "tblCalendarioPersonal($top=0)",
                "tblPersonaNTipoContrato($select=idTipoContrato,fechaBajaContrato,fechaAltaContrato;$count=true;$orderby=fechaAltaContrato desc;$top=1)",
                "tblDiasLibresPersonal($select=idDiasLibresPersonal,idPersona,idDiaSemana,idDiaMes,numDia)",
                "tblCuadrantePersonal($filter=idEstado ne null;$orderby=fecha desc;$top=1;$select=fecha)",
            ];
        }
    }

    datasource_persona_enviarAltasGestoria_onLoaded(data) {
        $.each(data, function (index, item) {
            item.idCentroLav = JSON.stringify([
                item.idCentroTrabajo ? item.idCentroTrabajo : -1,
                item.idLavanderia ? item.idLavanderia : -1,
            ]);
            item.fechaAltaContrato =
                item.tblPersonaNTipoContrato.length > 0 ? item.tblPersonaNTipoContrato[0].fechaAltaContrato : null;
        });
    }

    datasource_persona_onLoaded(data) {
        $.each(data, function (index, item) {
            item.isFormPedidoAllowed = false;
            if (item.horasDiarias) {
                let horasDiarias_dateTime = durationToDatetime(item.horasDiarias);
                item.horasDiarias =
                    leadingZero(horasDiarias_dateTime.getHours()) +
                    ":" +
                    leadingZero(horasDiarias_dateTime.getMinutes());
            }

            if (item.tblUsuario) {
                item.tblLavanderiaNUsuario = item.tblUsuario[0].idLavanderia;
                delete item.tblUsuario[0].idLavanderia;

                item.idLocalizacion = item.tblUsuario.length > 0 ? item.tblUsuario[0].idLocalizacion : null;
                item.idPais_localizacion =
                    item.tblUsuario.length > 0
                        ? item.tblUsuario[0].idLocalizacionNavigation
                            ? item.tblUsuario[0].idLocalizacionNavigation.idPais
                            : null
                        : null;
            }

            if (item.idCategoriaInternaNavigation) {
                item.idCategoriaConvenio = item.idCategoriaInternaNavigation.idCategoriaConvenio;
            }

            item.idCentroLav = JSON.stringify([
                item.idCentroTrabajo ? item.idCentroTrabajo : -1,
                item.idLavanderia ? item.idLavanderia : -1,
            ]);

            item.isUsuario = item["tblUsuario@odata.count"] > 0;

            let numAños = 0;
            if (item.tblDatosSalariales && item.tblDatosSalariales.fechaAntiguedad)
                numAños =
                    new Date(
                        new Date().getTime() - item.tblDatosSalariales.fechaAntiguedad.getTime()
                    ).getUTCFullYear() - 1970;
            item.numAños = numAños;

            let numTrienios = 0,
                numQuinquenios = 0;
            if (item.tblDatosSalariales && item.tblDatosSalariales.fechaAntiguedad) {
                let numAños =
                    new Date(
                        new Date().getTime() - item.tblDatosSalariales.fechaAntiguedad.getTime()
                    ).getUTCFullYear() - 1970;
                numTrienios = Math.floor(numAños / 3);
                numQuinquenios = Math.floor(numAños / 5);
                item.numTrienios = item.tblDatosSalariales.isTrienio ? numTrienios : numQuinquenios;
            }

            if (item.tblDatosSalariales == null) item.tblDatosSalariales = {};

            item.tblCuadrantePersonal = $.map(item.tblCuadrantePersonal, function (item, index) {
                return new Date(item.fecha).setHours(0, 0, 0, 0);
            });

            item.tblJornadaPersona = $.map(item.tblJornadaPersona, function (item, index) {
                return new Date(item.fecha).setHours(0, 0, 0, 0);
            });
        });
    }

    datasource_persona_enviarAltasGestoria = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_persona_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_persona_altasGestoria_onLoading(data);
            },
            onLoaded: (data) => {
                this.datasource_persona_enviarAltasGestoria_onLoaded(data);
            },
            version: 4,
        }),
        select: [
            "idPersona",
            "nombre",
            "apellidos",
            "idCentroTrabajo",
            "idLavanderia",
            "idFotoPerfil",
            "idFotoDocumentoIdentidad_A",
            "idFotoDocumentoIdentidad_B",
            "idFotoNAF",
            "idFotoIBAN",
        ],
        expand: [
            "tblPersonaNTipoContrato($select=fechaAltaContrato;$orderby=fechaAltaContrato desc;$top=1)",
            "tblLlamamiento($select=activo,codigoLlamamiento;$filter=activo eq true;$orderby=fechaIni desc;$top=1)",
            "tblSolicitudGestoria($filter= idEstadoSolicitudGestoria ne 3)",
        ],
    });

    searchFilter = (filter = [], fields, fn) => {
        for (const item of filter) {
            if (Array.isArray(item[0])) {
                this.searchFilter(item, fields, fn);
            } else if (fields.includes(item[0])) {
                fn(item);
            }
        }
    };

    datasource_persona_altasGestoria_onLoading = (loadOptions) => {
        const fechaActual = formatDate_parameter(new Date());
        const { personasSel_enviarAltasGestoria } = this.state;

        if (!loadOptions.filter || loadOptions.filter.length === 0) {
            loadOptions.filter = [
                [
                    "tblPersonaNTipoContrato/any(x:date(x/fechaAltaContrato) ge " +
                        fechaActual +
                        ") eq true and eliminado eq false",
                ],
            ];
        } else {
            loadOptions.filter = [
                [loadOptions.filter],
                "and",
                [
                    "tblPersonaNTipoContrato/any(x:date(x/fechaAltaContrato) ge " +
                        fechaActual +
                        ") eq true and eliminado eq false",
                ],
            ];
        }

        if (personasSel_enviarAltasGestoria.length > 0) {
            personasSel_enviarAltasGestoria.forEach((item, index) => {
                loadOptions.filter.push(["idPersona ne " + item.idPersona]);
            });
        }

        this.searchFilter(loadOptions.filter, ["idCentroLav", "codigoGestoria", "tblLlamamiento[0]"], (item) => {
            if (item[0] === "idCentroLav") {
                let centroLav = JSON.parse(item[2]);

                if (centroLav[0] === -1) {
                    //lavanderia
                    item[0] = "idLavanderia";
                    item[2] = centroLav[1];
                } else {
                    //centro
                    item[0] = "idCentroTrabajo";
                    item[2] = centroLav[0];
                }
            } else if (item[0] === "codigoGestoria") {
                item[2] = String(loadOptions.filter[2]);
            } else if (item[0] === "tblLlamamiento[0]") {
                item[0] = "tblLlamamiento/any(x:x/activo eq true)";
            }
        });

        loadOptions?.sort?.forEach((item, index) => {
            if (item.selector === "idCentroLav") {
                item.selector = "idLavanderia";
                loadOptions.sort.push({ selector: "idCentroTrabajo", desc: item.desc });
            }
        });
    };

    datasource_persona_enviarAltasGestoria_onLoaded(data) {
        $.each(data, function (index, item) {
            item.idCentroLav = JSON.stringify([
                item.idCentroTrabajo ? item.idCentroTrabajo : -1,
                item.idLavanderia ? item.idLavanderia : -1,
            ]);
            item.fechaAltaContrato =
                item.tblPersonaNTipoContrato.length > 0 ? item.tblPersonaNTipoContrato[0].fechaAltaContrato : null;
        });
    }

    datasource_tblTurno = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTurno",
            key: "idTurno",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTurno_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
        select: ["idTurno", "denominacion", "idLavanderia"],
        filter: ["eliminado eq false and activo eq true"],
        expand: ["idTurnoPadreNavigation($select=denominacion)"],
    });

    datasource_tblTurno_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = null;
    }

    datasource_tblTipoTrabajo = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoTrabajo",
            key: "idTipoTrabajo",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTipoTrabajo_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
        select: ["idTipoTrabajo", "denominacion", "idAdmCuentaContable_Salario", "idAdmCuentaContable_SSEmpresa"],
    });

    datasource_tblTipoTrabajo_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblDocumento = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDocumento",
            key: "idDocumento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblDocumento_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_tblDocumento_onLoading(data);
            },
            version: 4,
        }),
        map: (data) => {
            return this.datasource_tblDocumento_map(data);
        },
        select: [
            "idDocumento,denominacion,fecha,extension,idCarpetaDocumentos,idPersona,nuevo,requerido,firmado,idPersonaNavigation,subCarpeta",
        ],
        sort: ["fecha"],
    });

    datasource_tblDocumento_onLoading(loadOptions) {
        let { personaSel } = this.state;
        let idPersona = personaSel ? personaSel.idPersona : -1;
        loadOptions.filter = "idPersona eq " + idPersona + " and idCarpetaDocumentos ne null";
    }

    datasource_tblDocumento_map(data) {
        return {
            id: data.idDocumento,
            idDocumento: data.idDocumento,
            nombre: data.denominacion,
            fecha: data.fecha,
            fechaModificacion: data.fecha,
            icon: this.getIcon(data),
            extension: data.extension,
            idPadre: data.idCarpetaDocumentos,
            idCarpetaDocumentos: data.idCarpetaDocumentos,
            idPersona: data.idPersona,
            nuevo: data.nuevo,
            requerido: data.requerido,
            firmado: data.firmado,
            colorIcon: data.firmado == null ? "color-primary" : data.firmado == true ? "color-green" : "color-red",
            subTitle: data.requerido
                ? this.getTrad("documentoRequerido")
                : data.firmado == null || data.firmado === true
                ? null
                : this.getTrad("firmaRequerida"),
            nombreCompuesto: !data.idPersonaNavigation
                ? null
                : data.idPersonaNavigation.nombre + " " + data.idPersonaNavigation.apellidos,
            subCarpeta: data.subCarpeta,
        };
    }

    datasource_tblDocumento_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.timeout = 300000;

        if (request.method === "get") request.params.todos = true;
    }

    datasource_tblPersonaNTipoContrato = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersonaNTipoContrato",
            key: "idDocumento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblPersonaNTipoContrato_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblPersonaNTipoContrato_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { personaSel } = this.state;

        request.params.idPersona = personaSel ? personaSel.idPersona : -1;
    }

    context_persona = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
        entities: {
            fn_isNumDocIdentidadPersonaExists: {},
            fn_isEmailExists: {},
            SendMail_DatosPersonales: {},
        },
        beforeSend: (request) => {
            this.context_persona_beforeSend(request);
        },
    });

    context_persona_beforeSend(request) {
        request.headers = { ...authHeader() };

        var entityName = request.url.substring(request.url.lastIndexOf("/") + 1);

        if (entityName === "SendMail_DatosPersonales") {
            request.timeout = 300000;
            let { personasSel_enviarAltasGestoria, idAsuntoMailAltasGestorias } = this.state;

            let lavCentros_ = [];
            $.each(personasSel_enviarAltasGestoria, function (index, item) {
                if (item.idCentroTrabajo != null) {
                    lavCentros_.push({
                        idLavanderia: null,
                        idCentroTrabajo: item.idCentroTrabajo,
                        idPersona: item.idPersona,
                    });
                } else if (item.idLavanderia != null) {
                    lavCentros_.push({
                        idLavanderia: item.idLavanderia,
                        idCentroTrabajo: null,
                        idPersona: item.idPersona,
                    });
                }
            });

            let agruparPersona_lavCentro = query(lavCentros_)
                .groupBy(function (dataItem) {
                    return [dataItem.idLavanderia, dataItem.idCentroTrabajo];
                })
                .toArray();

            let lavCentros = $.map(agruparPersona_lavCentro, function (item) {
                return {
                    idAsuntoMailAltasGestorias: idAsuntoMailAltasGestorias,
                    idLavanderia: item.key[0],
                    idCentroTrabajo: item.key[1],
                    idsPersonas: $.map(item.items, function (subItem) {
                        return subItem.idPersona;
                    }),
                };
            });

            let SendMail_DatosPersonales_body = {
                lavCentros,
            };
            request.payload = SendMail_DatosPersonales_body;
        }
    }

    datasource_tblCarpetaDocumentos = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCarpetaDocumentos",
            key: "idCarpetaDocumentos",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCarpetaDocumentos_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
    });

    datasource_tblCarpetaDocumentos_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.todos = true;
    }

    datasource_persona_listaGeneral = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_personaGeneral_beforeSend(request);
            },
            version: 4,
        }),
        select: ["activo", "idPersona", "nombre", "apellidos"],
        sort: ["activo desc", "nombre", "apellidos"],
        filter: ["eliminado eq false"],
        map: (data) => {
            return this.datasource_persona_listaGeneral_map(data);
        },
    });

    datasource_personaGeneral_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.filtroTipoTrabajoRRHH = false;
    }

    datasource_persona_listaGeneral_map(data) {
        return {
            idPersona: data.idPersona,
            nombreCompuesto: data.nombre + " " + data.apellidos,
        };
    }

    datasource_tblDocumento_documentoGeneral = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDocumento",
            key: "idDocumento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblDocumento_documentoGeneral_beforeSend(request);
            },
            version: 4,
        }),
        select: [
            "idDocumento,fecha,fechaModificacion,denominacion,extension,idCarpetaDocumentos,idPersona,nuevo,requerido,firmado",
        ],
        expand: ["idPersonaNavigation($select=nombre,apellidos)"],
        map: (data) => {
            return this.datasource_tblDocumento_map(data);
        },
    });

    datasource_tblDocumento_documentoGeneral_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.todos = true;
    }

    datasource_tblGenero = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblGenero",
            key: "idGenero",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblGenero_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["idTraduccionNavigation"],
        map: (data) => {
            return this.datasource_tblGenero_map(data);
        },
    });

    datasource_tblGenero_map(data) {
        return {
            idGenero: data.idGenero,
            denominacion: data.idTraduccionNavigation[this.props.idioma.codigo],
        };
    }

    datasource_tblGenero_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblCentroTrabajo = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCentroTrabajo",
            key: "idCentroTrabajo",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCentroTrabajo_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
    });

    datasource_tblCentroTrabajo_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.todas = false;
    }

    datasource_tblLavanderiaNUsuario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
            key: "idLavanderia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblLavanderiaNUsuario_beforeSend(request);
            },
            version: 4,
        }),
        select: ["idLavanderia", "denominacion", "idPais", "idEmpresaPolarier", "idLocalizacion", "idAdmElementoPEP"],
        sort: ["denominacion"],
    });

    datasource_tblLavanderiaNUsuario_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.todas = false;
    }

    datasource_tblPais = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPais",
            key: "idPais",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblPais_beforeSend(request);
            },
            version: 4,
        }),
        sort: "denominacion",
    });
    datasource_tblPais_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblEstadoCivil = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEstadoCivil",
            key: "idEstadoCivil",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblEstadoCivil_beforeSend(request);
            },
            version: 4,
        }),
        map: (data) => {
            return this.datasource_tblEstadoCivil_map(data);
        },
    });

    datasource_tblEstadoCivil_map(data) {
        return {
            idEstadoCivil: data.idEstadoCivil,
            denominacion: data.idTraduccionNavigation[this.props.idioma.codigo],
        };
    }

    datasource_tblEstadoCivil_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblTipoDocumentoIdentidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoDocumentoIdentidad",
            key: "idTipoDocumentoIdentidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTipoDocumentoIdentidad_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblTipoDocumentoIdentidad_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblNivelEstudios = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblNivelEstudios",
            key: "idNivelEstudios",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblNivelEstudios_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["idTraduccionNavigation"],
        map: (data) => {
            return this.datasource_tblNivelEstudios_map(data);
        },
    });

    datasource_tblNivelEstudios_map(data) {
        return {
            idNivelEstudios: data.idNivelEstudios,
            denominacion: data.idTraduccionNavigation[this.props.idioma.codigo],
        };
    }

    datasource_tblNivelEstudios_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblDiscapacidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDiscapacidad",
            key: "idDiscapacidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblDiscapacidad_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["idTraduccionNavigation"],
        map: (data) => {
            return this.datasource_tblDiscapacidad_map(data);
        },
    });

    datasource_tblDiscapacidad_map(data) {
        return {
            idDiscapacidad: data.idDiscapacidad,
            denominacion: data.idTraduccionNavigation[this.props.idioma.codigo],
        };
    }

    datasource_tblDiscapacidad_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblTallaAlfa = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTallaAlfa",
            key: "idTallaAlfa",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTallaAlfa_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblTallaAlfa_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblEstadoCivil = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEstadoCivil",
            key: "idEstadoCivil",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblEstadoCivil_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["idTraduccionNavigation"],
        map: (data) => {
            return this.datasource_tblEstadoCivil_map(data);
        },
    });

    datasource_tblEstadoCivil_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblEstadoCivil_map(data) {
        return {
            idEstadoCivil: data.idEstadoCivil,
            denominacion: data.idTraduccionNavigation[this.props.idioma.codigo],
        };
    }

    datasource_tblComunidadAutonoma = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblComunidadAutonoma",
            key: "idComunidadAutonoma",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblComunidadAutonoma_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblComunidadAutonoma_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblDocumentoPerfilPersona = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idDocumento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblDocumentoPerfilPersona_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_tblDocumentoPerfilPersona_onLoading(data);
            },
            version: 4,
        }),
        select: ["idPersona"],
    });

    datasource_tblDocumentoPerfilPersona_onLoading(loadOptions) {
        let { personaSel } = this.state;
        let { user } = this.props;
        let idPersona = personaSel ? personaSel.idPersona : -1;
        loadOptions.filter = "eliminado eq false and idPersona eq " + idPersona;

        if (user.enableDatosRRHH)
            loadOptions.expand = [
                "idFotoDocumentoIdentidad_ANavigation,idFotoDocumentoIdentidad_BNavigation,idFotoIBANNavigation,idFotoNAFNavigation, idFotoPerfilNavigation",
            ];
        else loadOptions.expand = ["idFotoPerfilNavigation"];
    }

    datasource_tblDocumentoPerfilPersona_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.filtroTipoTrabajoRRHH = false;
    }

    datasource_tblUsuario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblUsuario",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblUsuario_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblUsuario_beforeSend(request) {
        let { personaSel } = this.state;
        request.headers = { ...authHeader() };

        if (request.method !== "PATCH")
            request.params.email =
                personaSel.emailEmpresa === "" || personaSel.emailEmpresa === " " ? -1 : personaSel.emailEmpresa;
    }

    datasource_tblLocalizacion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLocalizacion",
            key: "idLocalizacion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblLocalizacion_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
        expand: ["idPaisNavigation($select=idPais)"],
        filter: ["idLocalizacion eq 1 or idLocalizacion eq 2"],
    });

    datasource_tblLocalizacion_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblEmpresasPolarier = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEmpresasPolarier",
            key: "idEmpresaPolarier",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblEmpresasPolarier_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
    });

    datasource_tblEmpresasPolarier_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblMotivoBaja = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMotivoBaja",
            key: "idMotivoBaja",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblMotivoBaja_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
    });

    datasource_tblMotivoBaja_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblTipoContrato = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoContrato",
            key: "idTipoContrato",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTipoContrato_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
    });

    datasource_tblTipoContrato_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    enum_activo_noActivo = [
        {
            text: capitalize(this.getTrad("activo")),
            value: true,
        },
        {
            text: capitalize(this.getTrad("noActivo")),
            value: false,
        },
    ];

    datasource_datos_tblCalendarioPersonal_Estado = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioPersonal_Estado",
            key: "idEstado",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tblCalendarioPersonal_Estado_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["denominacion"],
        expand: ["idTraduccionNavigation"],
        postProcess: (data) => {
            return this.datasource_datos_tblCalendarioPersonal_Estado_postProcess(data);
        },
    });

    datasource_datos_tblCalendarioPersonal_Estado_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_datos_tblCalendarioPersonal_Estado_postProcess(data) {
        if (this.datasource_datos_tblCalendarioPersonal_Estado.items().length === 0) {
            data.splice(0, 0, {
                idEstado: 0,
                denominacion: "Sin estado",
                traduccion: "sinEstado",
                colorHexa: "#FFFFFF",
                isLavanderia: false,
            });
        }
        return data;
    }

    context_calendarioLaboral = new ODataContext({
        url: connectionConstants.ODATA_URL + "tblCalendarioPersonal",
        entities: {
            fn_spSelectCalendarioLaboral: {
                key: ["idPersona", "fecha"],
            },
            fn_iudCalendarioPersonal: {},
        },
        beforeSend: (request) => {
            this.context_calendarioLaboral_beforeSend(request);
        },
    });

    context_calendarioLaboral_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    context_calendarioPersonal = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioPersonal",
        entities: {
            fn_IU_tblCalendarioPersonal: {},
        },
        beforeSend: (request) => {
            this.context_calendarioPersonal_beforeSend(request);
        },
    });

    context_calendarioPersonal_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.timeout = 300000;

        let { yearCalendar_newData, personaSel } = this.state;
        request.params.idPersona = personaSel ? personaSel.idPersona : -1;

        yearCalendar_newData = $.map(yearCalendar_newData, function (item) {
            return {
                idPersona: personaSel.idPersona,
                idEstado: item.idEstado,
                fecha: item.fecha,
            };
        });
        yearCalendar_newData = yearCalendar_newData.sort(function (a, b) {
            return new Date(a.fecha) - new Date(b.fecha);
        });

        let rangos = [];
        $.each(yearCalendar_newData, function (index, date) {
            let date_ = new Date(date.fecha);
            let diaAnterior = new Date(new Date(date_).setDate(new Date(date_).getDate() - 1));
            let diaSiguiente = new Date(new Date(date_).setDate(new Date(date_).getDate() + 1));

            let obj_diaActual = $.grep(yearCalendar_newData, function (item) {
                return item.fecha.toDateString() == date_.toDateString();
            });
            let obj_diaAnterior = $.grep(yearCalendar_newData, function (item) {
                return item.fecha.toDateString() == diaAnterior.toDateString();
            });
            let obj_diaSiguiente = $.grep(yearCalendar_newData, function (item) {
                return item.fecha.toDateString() == diaSiguiente.toDateString();
            });

            if (obj_diaAnterior.length > 0) {
                if (obj_diaAnterior[0].idEstado != obj_diaActual[0].idEstado) {
                    rangos.push([date]);
                }
            } else {
                rangos.push([date]);
            }

            if (obj_diaSiguiente.length > 0) {
                if (obj_diaSiguiente[0].idEstado != obj_diaActual[0].idEstado) {
                    rangos[rangos.length - 1].push(date);
                }
            } else {
                rangos[rangos.length - 1].push(date);
            }
        });

        let formatRangos = [];
        $.each(rangos, function (index, items) {
            formatRangos.push({
                idEstado: items[0].idEstado,
                fechaDesde: items[0].fecha,
                fechaHasta: items[1].fecha,
                idPersona: items[0].idPersona,
            });
        });
        request.payload = { fechasCalendario: formatRangos };
    }

    datasource_persona_codigoGestoria = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_persona_codigoGestoria_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_persona_codigoGestoria_onLoading(data);
            },
            version: 4,
        }),
        select: ["idPersona", "nombre", "apellidos", "codigoGestoria", "isCodigoGestoriaValidado"],
        expand: ["tblUsuario($select=idUsuario,notificationToken)"],
    });

    datasource_persona_codigoGestoria_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.filtroTipoTrabajoRRHH = false;
    }

    datasource_persona_codigoGestoria_onLoading(loadOptions) {
        const { ImportarDocumentos_EmpresaSel } = this.state;
        loadOptions.filter = `eliminado eq false and codigoGestoria in (${$.map(codigosGestoria, function (item) {
            return `'${item}'`;
        })})`;
        if (ImportarDocumentos_EmpresaSel) {
            loadOptions.filter += ` and idEmpresaPolarier eq ${ImportarDocumentos_EmpresaSel?.idEmpresaPolarier}`;
        }
    }

    context_documento = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDocumento",
        entities: {
            fn_importacionMasivaDocumentos: {},
        },
        beforeSend: (request) => {
            this.context_documento_beforeSend(request);
        },
    });

    context_documento_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.timeout = 300000;
        let { documentosImportarDocumentos } = this.state;

        let documentosFiltrados = documentosImportarDocumentos.filter((x) => !x.error);
        let documentos = $.map(documentosFiltrados, function (item) {
            return {
                documento: item.documento,
                extension: item.extension,
                fecha: item.fecha,
                fechaModificacion: item.fechaModificacion,
                denominacion: item.nombre,
                idCarpetaDocumentos: item.idCarpetaDocumentos,
                idPersona: item.idPersona,
                idUsuario: item.idUsuario,
                notificationToken: item.notificationToken,
                nuevo: false,
                firmado: false,
                requerido: null,
                notificacion: true,
            };
        });
        request.payload = { documentos: documentos };
    }

    objCheckCodigoGestoria = {};
    context_CodigoGestoria = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
        entities: {
            fn_CheckCodigoGestoria: {},
        },
        beforeSend: (request) => {
            this.context_CodigoGestoria_beforeSend(request);
        },
    });
    context_CodigoGestoria_beforeSend(request) {
        let { personaSel } = this.state;
        request.headers = { ...authHeader() };
        request.params.idPersona = personaSel ? personaSel.idPersona : null;
        request.params.codigoGestoria = this.objCheckCodigoGestoria.codigo;
        request.params.idEmpresaPolarier = personaSel ? personaSel.idEmpresaPolarier : null;
    }

    datasource_tblCategoriaConvenio = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaConvenio",
            key: "idCategoriaConvenio",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCategoriaConvenio_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_tblCategoriaConvenio_onLoading(data);
            },
            version: 4,
        }),
        sort: ["denominacion"],
        select: ["idCategoriaConvenio", "denominacion"],
    });

    datasource_tblCategoriaConvenio_onLoading(loadOptions) {
        let { personaSel } = this.state;
        let idLavanderia = -1;

        if (personaSel && personaSel.idCentroLav != null) {
            let parsed = JSON.parse(personaSel.idCentroLav);
            idLavanderia = parsed[1] > 0 ? parsed[1] : null;
        }

        let idPais = personaSel && personaSel.idPais_localizacion ? personaSel.idPais_localizacion : -1;

        if (idLavanderia != -1)
            loadOptions.filter = "idPais eq " + idPais + " and isOficina eq " + (idLavanderia == null);
        else loadOptions.filter = "idPais eq -1"; //Para que no devuelva ningún valor
    }

    datasource_tblCategoriaConvenio_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblCategoriaInterna = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
            key: "idCategoriaInterna",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCategoriaInterna_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_tbltblCategoriaInterna_onLoading(data);
            },
            version: 4,
        }),
        sort: ["denominacion"],
    });

    datasource_tbltblCategoriaInterna_onLoading(loadOptions) {
        let { personaSel } = this.state;
        let { user } = this.props;
        if (personaSel) {
            loadOptions.filter =
                "idCategoriaConvenio eq " +
                (personaSel && personaSel.idCategoriaConvenio ? personaSel.idCategoriaConvenio : -1);
        }

        if (user.enableDatosRRHH)
            loadOptions.select = [
                "idCategoriaInterna,idCategoriaConvenio,denominacion,incentivo,impHoraExtra,percSegSocial,plusProductividad,plusAsistencia,plusPeligrosidad,plusResponsabilidad,salarioBase",
            ];
        else loadOptions.select = ["idCategoriaInterna,denominacion"];
    }

    datasource_tblCategoriaInterna_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblCategoriaInterna_lookupGrid = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
            key: "idCategoriaInterna",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCategoriaInterna_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_tblCategoriaInterna_lookupGrid_onLoading(data);
            },
            version: 4,
        }),
        sort: ["denominacion"],
        select: ["idCategoriaInterna", "denominacion"],
        postProcess: (data) => {
            return this.datasource_tblCategoriaInterna_lookupGrid_postProcess(data);
        },
    });
    datasource_tblCategoriaInterna_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblCategoriaInterna_lookupGrid_onLoading(loadOptions) {
        loadOptions.expand = ["idCategoriaConvenioNavigation($select=denominacion),tblCategoriaInternaNTurno"];
    }

    datasource_tblCategoriaInterna_lookupGrid_postProcess(data) {
        let groupedData = query(data)
            .groupBy(function (dataItem) {
                return dataItem.idCategoriaConvenioNavigation.denominacion;
            })
            .sortBy("key")
            .toArray();
        this.setState({
            datasource_tblCategoriaInterna_lookupGrid_grouped: groupedData,
        });

        return data;
    }

    datasource_tblCorreoAltaGestoriaNCentroLav = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCorreoAltaGestoriaNCentroLav",
            key: "idCorreoAltaPersonaNCentroLav",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCorreoAltaGestoriaNCentroLav_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_tblCorreoAltaGestoriaNCentroLav_onLoading(data);
            },
            version: 4,
        }),
    });

    datasource_tblCorreoAltaGestoriaNCentroLav_beforeSend(request) {
        request.headers = { ...authHeader() };
    }
    datasource_tblCorreoAltaGestoriaNCentroLav_onLoading(loadOptions) {
        let { personasSel_enviarAltasGestoria } = this.state;
        let idsLavanderia = [],
            idsCentroTrabajo = [];

        $.each(personasSel_enviarAltasGestoria, function (index, item) {
            if (item.idCentroTrabajo != null) {
                idsCentroTrabajo.push(item.idCentroTrabajo);
            } else if (item.idLavanderia != null) {
                idsLavanderia.push(item.idLavanderia);
            }
        });

        if (idsLavanderia.length > 0 || idsCentroTrabajo.length > 0) {
            loadOptions.filter =
                "idLavanderia in (" + idsLavanderia + ")" + " or " + "idCentroTrabajo in (" + idsCentroTrabajo + ")";
        } else {
            loadOptions.filter = "idLavanderia eq -1";
        }
    }

    enum_isTrienio = [
        {
            dbValue: true,
            value: 1,
            denominacion: "Trienio",
        },
        {
            dbValue: false,
            value: 0, //false hace salar la validación required
            denominacion: "Quinquenio",
        },
    ];

    datasource_tblCalendarioLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCalendarioLavanderia",
            key: ["fecha"],
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCalendarioLavanderia_beforeSend(request);
            },
            onLoaded: (data) => {
                return this.datasource_tblCalendarioLavanderia_onLoaded(data);
            },
            version: 4,
        }),
        // filter: ["idEstado eq 8"]
    });

    datasource_tblCalendarioLavanderia_beforeSend(request) {
        const { personaSel } = this.state;

        request.headers = { ...authHeader() };
        request.params.idLavanderia = personaSel.idLavanderia;
    }

    datasource_tblCalendarioLavanderia_onLoaded(data) {
        this.setState({ yearCalendar_estadosPorDefecto: data });
        return data;
    }

    datasource_tblFormatoDiasLibres = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblFormatoDiasLibres",
            key: "idFormatoDiasLibres",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblFormatoDiasLibres_beforeSend(request);
            },
            //onLoading: (data) => { this.datasource_tblFormatoDiasLibres_onLoading(data) },
            version: 4,
        }),
    });

    datasource_tblFormatoDiasLibres_onLoading(loadOptions) {
        let { personaSel } = this.state;
        let { user } = this.props;
    }

    datasource_tblFormatoDiasLibres_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblUsuario_Validacion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblUsuario_Validacion_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_tblUsuario_Validacion_onLoading(data);
            },
            version: 4,
        }),
        select: ["nombre,apellidos"],
        expand: ["tblUsuario($top=1;$select=idUsuario)"],
        map: (item) => this.datasource_tblUsuario_Validacion_map(item),
    });

    datasource_tblUsuario_Validacion_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.filtroTipoTrabajoRRHH = false;
    }

    datasource_tblUsuario_Validacion_onLoading(loadOptions) {
        loadOptions.filter =
            "tblUsuario/any(x: x/idUsuario eq 180 or x/idUsuario eq 500 or x/idUsuario eq 55 or x/idUsuario eq 578 or x/idUsuario eq 53 or x/idUsuario eq 61 or x/idUsuario eq 1926)";
    }

    datasource_tblUsuario_Validacion_map = (item) => {
        item.nombreCompleto = (item.nombre + " " + item.apellidos).toUpperCase();
        item.idUsuario = item.tblUsuario[0].idUsuario;
        delete item.nombre;
        delete item.apellidos;
        delete item.tblUsuario;
        return item;
    };

    dataSource_tblLlamamiento = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLlamamiento",
            key: "idLlamamiento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                request.headers = { ...authHeader() };
            },
            version: 4,
            onUpdated: () => this.dataSource_tblLlamamiento_onUpdated(),
        }),
        filter: [["activo", "=", true]],
        expand: [
            "tblDiasLibresPersonal_Llamamiento($select=idDiaSemana,idDiaMes,numDia)",
            "idPersonaNavigation($select=nombre,apellidos)",
        ],
        sort: ["fechaIni DESC"],
        map: (item) => this.dataSource_tblLlamamiento_map(item),
    });

    dataSource_tblLlamamiento_onUpdated = () => {
        this.datasource_persona.reload();
        this.dataSource_tblLlamamiento.reload().then((datasource_tblLlamamiento) => {
            let prevCount = this.state.datasource_tblLlamamiento?.filter((x) => x.idPersona == null).length;
            let count = datasource_tblLlamamiento.filter((x) => x.idPersona == null).length;
            if (prevCount !== count) {
                $("#llamamientosCount").html(count > 0 ? `(${count})` : "");
            }

            this.setState({ datasource_tblLlamamiento });
        });
    };

    dataSource_tblLlamamiento_map = (item) => {
        if (item.idPersonaNavigation)
            item.idPersonaNavigation.nombreCompuesto =
                item.idPersonaNavigation.nombre + " " + item.idPersonaNavigation.apellidos;
        return item;
    };

    datasource_tblAdmElementoPEP = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmElementoPEP",
            key: "idAdmElementoPEP",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblAdm_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblAdm_onLoading(loadOptions),
            version: 4,
        }),
    });

    datasource_tblAdmCentroCoste = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCentroCoste",
            key: "idAdmCentroCoste",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblAdm_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblAdm_onLoading(loadOptions),
            version: 4,
        }),
    });

    datasource_tblAdmCuentaContable = new DataSource({
        paginate: true,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCuentaContable",
            key: "idAdmCuentaContable",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblAdm_beforeSend(request),
            version: 4,
        }),
    });

    datasource_tblAdm_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    datasource_tblAdm_onLoading = (loadOptions) => {
        const { personaSel } = this.state;

        if (!loadOptions.filter) loadOptions.filter = [];

        loadOptions.filter.push(["isEliminado", "=", false], ["idEmpresaPolarier", "=", personaSel?.idEmpresaPolarier]);
    };

    //#endregion

    //#region Métodos

    cargaDatosInicio() {
        let _this = this;
        let { user } = this.props;
        this.loadPanel_show();
        $.when(
            this.datasource_tblTipoTrabajo.reload(),
            this.datasource_tblTurno.reload(),
            user.enableDatosRRHH ? this.datasource_tblGenero.reload() : [],
            this.datasource_tblCentroTrabajo.reload(),
            this.datasource_tblLavanderiaNUsuario.reload(),
            user.enableDatosRRHH ? this.datasource_tblTallaAlfa.reload() : [],
            user.enableDatosRRHH ? this.datasource_tblDiscapacidad.reload() : [],
            user.enableDatosRRHH ? this.datasource_tblNivelEstudios.reload() : [],
            user.enableDatosRRHH ? this.datasource_tblTipoDocumentoIdentidad.reload() : [],
            this.datasource_tblPais.reload(),
            user.enableDatosRRHH ? this.datasource_tblEstadoCivil.reload() : [],
            user.enableDatosRRHH ? this.datasource_tblComunidadAutonoma.reload() : [],
            this.datasource_tblLocalizacion.reload(),
            this.datasource_tblEmpresasPolarier.reload(),
            this.datasource_datos_tblCalendarioPersonal_Estado.reload(),
            this.datasource_tblTipoContrato.reload(),
            this.datasource_tblMotivoBaja.reload(),
            this.datasource_persona.reload(),
            this.datasource_tblCategoriaInterna.reload(),
            this.datasource_tblCategoriaInterna_lookupGrid.reload(),
            this.datasource_tblFormatoDiasLibres.reload(),
            user.enableDatosRRHH ? this.datasource_tblCarpetaDocumentos.reload() : [],
            this.dataSource_tblLlamamiento.reload()
        ).then(function (
            tblTipoTrabajo,
            tblTurno,
            tblGenero,
            tblCentroTrabajo,
            tblLavanderiaNUsuario,
            tblTallaAlfa,
            tblDiscapacidad,
            tblNivelEstudios,
            tblTipoDocumentoIdentidad,
            tblPais,
            tblEstadoCivil,
            tblComunidadAutonoma,
            tblLocalizacion,
            tblEmpresasPolarier,
            tblCalendarioEstado,
            tblTipoContrato,
            tblMotivoBaja,
            persona,
            tblCategoriaInterna,
            datasource_tblCategoriaInterna_lookupGrid,
            tblFormatoDiasLibres,
            tblCarpetaDocumentos,
            tblLlamamiento
        ) {
            //#region tblCarpetaDocumentos
            $.each(tblCarpetaDocumentos[0], function (index, item) {
                item.tblDocumento = [];
            });

            let groupedData = [
                {
                    key: 1,
                    items: tblCarpetaDocumentos[0],
                },
            ];
            //#endregion

            let centrosEspaña = tblCentroTrabajo[0].filter((x) => x.idPais == 1);
            let lavanderiasEspaña = tblLavanderiaNUsuario[0].filter((x) => x.idPais == 1);

            let datasource_tblCentroTrabajo_Lavanderia_España = $.map(
                $.merge(centrosEspaña, lavanderiasEspaña),
                function (item, index) {
                    return {
                        groupTitle: item.idCentroTrabajo
                            ? _this.getTrad("centrosTrabajo")
                            : _this.getTrad("lavanderías"),
                        idCentroLav: JSON.stringify([
                            item.idCentroTrabajo ? item.idCentroTrabajo : -1,
                            item.idLavanderia ? item.idLavanderia : -1,
                        ]),
                        denominacion: item.denominacion,
                    };
                }
            );

            let groupedData_centroLav_España = query(datasource_tblCentroTrabajo_Lavanderia_España)
                .groupBy(function (dataItem) {
                    return dataItem.groupTitle;
                })
                .toArray();

            let datasource_tblCentroTrabajo_Lavanderia = [...centrosEspaña, ...lavanderiasEspaña].map((item) => {
                return {
                    groupTitle: item.idCentroTrabajo ? _this.getTrad("centrosTrabajo") : _this.getTrad("lavanderías"),
                    idCentroLav: JSON.stringify([
                        item.idCentroTrabajo ? item.idCentroTrabajo : -1,
                        item.idLavanderia ? item.idLavanderia : -1,
                    ]),
                    denominacion: item.denominacion,
                    idAdmElementoPEP: item.idAdmElementoPEP,
                    idAdmCuentaContable_Salario: item.idAdmCuentaContable_Salario,
                    idAdmCuentaContable_SSEmpresa: item.idAdmCuentaContable_SSEmpresa,
                };
            });

            let turnos = $.map(tblTurno[0], function (item) {
                if (item.idTurnoPadreNavigation != null) {
                    return {
                        idTurno: item.idTurno,
                        idLavanderia: item.idLavanderia,
                        denominacion: item.idTurnoPadreNavigation.denominacion + " - " + item.denominacion,
                        idTurnoPadreNavigation: item.idTurnoPadreNavigation,
                    };
                }
                return item;
            });
            turnos = query(turnos).sortBy("denominacion").toArray();

            if (tblLlamamiento[0]) {
                let count = tblLlamamiento[0].filter((x) => x.idPersona == null).length;
                $("#llamamientosCount").html(count > 0 ? `(${count})` : "");
            }

            _this.setState(
                {
                    datasource_tblTipoTrabajo: tblTipoTrabajo[0],
                    datasource_tblTurno: turnos,
                    datasource_tblTurno_todos: turnos,
                    datasource_tblGenero: tblGenero[0],
                    dataSource_documentos: groupedData,
                    dataSource_documentos_bk: groupedData,
                    datasource_tblCentroTrabajo_Lavanderia: datasource_tblCentroTrabajo_Lavanderia_España,
                    datasource_tblCentroTrabajo_Lavanderia_todos: datasource_tblCentroTrabajo_Lavanderia,
                    datasource_tblTallaAlfa: tblTallaAlfa[0],
                    datasource_tblDiscapacidad: tblDiscapacidad[0],
                    datasource_tblNivelEstudios: tblNivelEstudios[0],
                    datasource_tblTipoDocumentoIdentidad: tblTipoDocumentoIdentidad[0],
                    datasource_tblPais: tblPais[0],
                    datasource_tblEstadoCivil: tblEstadoCivil[0],
                    datasource_tblComunidadAutonoma: tblComunidadAutonoma[0],
                    datasource_tblLocalizacion: tblLocalizacion[0],
                    datasource_tblEmpresasPolarier: tblEmpresasPolarier[0],
                    datasource_tblCalendarioEstado: tblCalendarioEstado[0],
                    datasource_tblTipoContrato: tblTipoContrato[0],
                    datasource_tblMotivoBaja: tblMotivoBaja[0],
                    datasource_tblCategoriaInterna: tblCategoriaInterna[0],
                    datasource_tblCategoriaInterna_lookupGrid: datasource_tblCategoriaInterna_lookupGrid[0],
                    datasource_centroLav_lookupGrid_grouped: groupedData_centroLav_España,
                    isVisible_noDataPopup:
                        datasource_tblCategoriaInterna_lookupGrid[0].length == 0 && !user.enableDatosRRHH,
                    datasource_tblFormatoDiasLibres: tblFormatoDiasLibres[0],
                    datasource_tblLlamamiento: tblLlamamiento[0],
                    datasource_tblLavanderiaNUsuario: tblLavanderiaNUsuario[0],
                    datasource_tblCentroTrabajo: tblCentroTrabajo[0],
                },
                () => {
                    _this.loadPanel_hide();
                }
            );
        });
    }

    getCentrosEmpresa = (idEmpresaPolarier) => {
        const { datasource_tblCentroTrabajo, datasource_tblLavanderiaNUsuario } = this.state;

        let centrosEspaña = datasource_tblCentroTrabajo.filter((x) => x.idPais == 1);
        let lavanderiasEspaña = datasource_tblLavanderiaNUsuario.filter(
            (x) => x.idPais == 1 && x.idEmpresaPolarier == idEmpresaPolarier
        );

        let datasource_tblCentroTrabajo_Lavanderia = [...centrosEspaña, ...lavanderiasEspaña].map((item) => {
            return {
                groupTitle: item.idCentroTrabajo ? this.getTrad("centrosTrabajo") : this.getTrad("lavanderías"),
                idCentroLav: JSON.stringify([
                    item.idCentroTrabajo ? item.idCentroTrabajo : -1,
                    item.idLavanderia ? item.idLavanderia : -1,
                ]),
                denominacion: item.denominacion,
            };
        });

        return datasource_tblCentroTrabajo_Lavanderia;
    };

    //#endregion

    render() {
        let {
            dataSource_documentos,
            carpetaDocumentosSel,
            datasource_tblCategoriaInterna,
            datasource_tblFormatoDiasLibres,
            datasource_tblCategoriaInterna_lookupGrid,
            datasource_tblTurno,
            datasource_tblTipoTrabajo,
            dxTabPanel_fichaPersona_selectedTabName,
            isVisible_dxPopup_gestionarDocumentacion_comun,
            datasource_lavanderia_documentoGeneral,
            idLavSel_documentosGeneral,
            dxList_persona_listaGeneral_selectedItemKeys,
            datasource_tblGenero,
            documentosNGrandParent,
            datasource_tblCentroTrabajo_Lavanderia,
            datasource_tblEmpresasPolarier,
            datasource_tblTallaAlfa,
            datasource_tblDiscapacidad,
            datasource_tblNivelEstudios,
            datasource_tblTipoDocumentoIdentidad,
            datasource_tblPais,
            datasource_tblEstadoCivil,
            datasource_tblComunidadAutonoma,
            isVisible_dxPopup_addNewPersona,
            isVisible_dxPopup_ImportarDocumentos,
            ImportarDocumentos_EmpresaSel,
            yearCalendar_currentYear,
            datasource_tblCalendarioEstado,
            personaSel,
            isVisible_dxPopup_enviarAltasGestoria,
            personasSel_enviarAltasGestoria,
            yearCalendar_newData,
            documentosImportarDocumentos,
            isVisible_LoadPanel_ProgressBar,
            disabledDays,
            numNominasError,
            isRendered_tabCalendario,
            showLoading_tabDatosLaborales,
            showLoading_tabDocumentos,
            popup_nombrarNominas_visible,
            formData_nombrarNominas,
            isVisible_GaleriaArchivos,
            archivoSel,
            isVisible_dxPopup_enviarAltasGestoria_loading,
            progress,
            dataField_archivoSel,
            maxFileExceeded,
            isGuardando_fichaPersona,
            isVisible_noDataPopup,
            datasource_tblCentroTrabajo_Lavanderia_todos,
            yearCalendar_estadosPorDefecto,
            multiViewCarpetasIndex,
            multiViewCarpetas_DocumentosAgrupados,
            contratosPopup_isVisible,
            dxPopup_llamamientos_isVisible,
            dxPopup_llamamientos_consulta,
            datasource_tblLlamamiento,
            datasource_tblLavanderiaNUsuario,
            datasource_tblTipoContrato,
            datasource_tblMotivoBaja,
            datasource_tblLocalizacion,
            identifyNeeded,
            identifySuccess,
        } = this.state;

        let { resolucion, user } = this.props;

        let ImportarDocumentosNoValidados = $.grep(documentosImportarDocumentos, function (item) {
            return item.isCodigoGestoriaValidado == false;
        });

        return (
            <Fragment>
                <PageTitle heading={getNombreFormulario(this)} />
                <div className={"media-body"}>
                    <div id="PersonalGeneral" className="formContainer scrollbar-container">
                        <LoadPanel
                            shadingColor="transparent"
                            deferRendering={false}
                            animation={null}
                            visible={false}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                        />
                        <LoadPanel
                            shadingColor="transparent"
                            deferRendering={false}
                            animation={null}
                            position={tabDatosLaborales_Padre_personal}
                            visible={
                                showLoading_tabDatosLaborales &&
                                dxTabPanel_fichaPersona_selectedTabName == "datosLaborales"
                            }
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                        />
                        <LoadPanel
                            ref={this.dxLoadPanel_tabCalendario_REF}
                            shadingColor="#FFF"
                            deferRendering={false}
                            animation={null}
                            position={tabCalendarioLaboral_Padre_personal}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                        />
                        <PersonaSel.Provider value={this.personaSelContextValue}>
                            <DataGrid
                                ref={this.dxDataGrid_personas_REF}
                                dataSource={this.datasource_persona}
                                remoteOperations={true}
                                //Propiedades
                                columnsAutoWidth={true}
                                height="100%"
                                width="100%"
                                //Estilos
                                repaintChangesOnly={true}
                                showColumnLines={false}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                hoverStateEnabled={true}
                                //Eventos
                                onCellHoverChanged={this.dxDataGrid_personas_onCellHoverChanged}
                                onInitNewRow={this.onInitNewRow_dxDataGrid_tblPersona}
                                onRowPrepared={this.onRowPrepared_dxDataGrid_tblPersona}
                                onEditorPreparing={this.onEditorPreparing_dxDataGrid_tblPersona}
                                onEditingStart={this.onEditingStart_dxDataGrid_tblPersona}
                                onRowInserting={this.dxDataGrid_personas_onRowInserting}
                                onRowInserted={this.dxDataGrid_personas_onRowInserted}
                                onRowUpdating={this.dxDataGrid_personas_onRowUpdating}
                                onRowRemoving={this.dxDataGrid_personas_onRowRemoving}
                                onRowValidating={this.dxDataGrid_personas_onRowValidating}
                                onToolbarPreparing={this.dxDataGrid_personas_onToolbarPreparing}
                            >
                                <LoadPanel_DataGrid enabled={false} />
                                <SearchPanel
                                    visible={true}
                                    width={200}
                                    placeholder={this.getTrad("busqueda")}
                                    searchVisibleColumnsOnly={true}
                                />
                                <HeaderFilter visible={true} />
                                <FilterRow
                                    visible={true}
                                    showAllText={this.getTrad("todos").toUpperCase()}
                                    applyFilter="auto"
                                />
                                <Paging defaultPageSize={20} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={this.tblPersona_allowedPageSizes}
                                    showInfo={true}
                                />
                                <Editing
                                    mode="popup"
                                    allowAdding={user.enableDatosRRHH}
                                    allowUpdating={false}
                                    allowDeleting={false}
                                >
                                    <Popup_dataGrid
                                        elementAttr={this.dxDataGrid_Popup_Edit_elementAttr}
                                        titleRender={this.dxPopup_persona_titleRender}
                                        showTitle={true}
                                        width={"89%"}
                                        height={"85%"}
                                        position={this.dxPopup_center_position}
                                        onContentReady={this.dxPopup_fichaPersona_onContentReady}
                                        onShown={this.dxPopup_fichaPersona_onShown}
                                        onHidden={this.dxPopup_fichaPersona_onHidden}
                                    >
                                        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                            <Button
                                                ref={this.dxButton_fichaPersona_guardar_REF}
                                                text={this.getTrad("guardar")}
                                                type="success"
                                                onClick={this.dxButton_fichaPersona_guardar_onClick}
                                            />
                                        </ToolbarItem_popup>
                                        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                            <Button
                                                text={this.getTrad("cancelar")}
                                                type="normal"
                                                onClick={this.dxButton_fichaPersona_edit_cancelar_onClick}
                                            />
                                        </ToolbarItem_popup>
                                    </Popup_dataGrid>
                                    {/* FORMULARIO EDITA PERSONA */}
                                    <Popup_Form
                                        labelLocation="top"
                                        deferRendering={false}
                                        height="100%"
                                        scrollingEnabled={true}
                                        validationGroup="validacionPersona"
                                    >
                                        <Item itemType="tabbed" colCount={2} colSpan={2}>
                                            <TabPanelOptions
                                                animationEnabled={true}
                                                elementAttr={this.dxTabPanel_fichaPersona_elementAttr}
                                                itemTitleRender={this.itemTitleRender_dxTabPanel_Persona}
                                                onSelectionChanged={this.dxTabPanel_fichaPersona_onSelectionChanged}
                                                deferRendering={false}
                                            />
                                            {/* Datos personales */}
                                            <Tab name="datosPersonales" title={"Datos personales"} index={0}>
                                                <Item
                                                    itemType="group"
                                                    colCount={4}
                                                    colSpan={4}
                                                    colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                    screenByWidth={this.dxForm_personal_screenByWidth}
                                                    cssClass="tabDatosPersonales_Padre_personal"
                                                >
                                                    <Item
                                                        itemType="group"
                                                        cssClass="dxForm_group"
                                                        colCount={4}
                                                        colSpan={2}
                                                        colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                        screenByWidth={this.dxForm_personal_screenByWidth}
                                                    >
                                                        {/* 1ª Columna */}
                                                        <Item itemType="group" colCount={1} colSpan={1}>
                                                            <Item
                                                                dataField="idFotoPerfil"
                                                                colSpan={1}
                                                                label={this.formLabel_idFotoPerfil}
                                                                render={this.dxFormItem_render}
                                                            />
                                                            <EmptyItem colSpan={1} />
                                                            <Item
                                                                dataField="activo"
                                                                colSpan={1}
                                                                cssClass={"container_spanCentrado"}
                                                                label={this.formLabel_activo}
                                                                render={() => {
                                                                    if (personaSel != null && personaSel != undefined)
                                                                        return this.getActiveTag(
                                                                            personaSel,
                                                                            "font-size-xl"
                                                                        );
                                                                }}
                                                            />
                                                        </Item>
                                                        {/* 2ª Columna */}
                                                        <Item itemType="group" colCount={3} colSpan={3}>
                                                            <Item
                                                                dataField="nombre"
                                                                colSpan={3}
                                                                label={this.formLabel_nombre}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="apellidos"
                                                                colSpan={3}
                                                                label={this.formLabel_apellidos}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="email"
                                                                colSpan={3}
                                                                label={this.formLabel_email}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="telefono"
                                                                editorOptions={this.telefono_editorOptions}
                                                                label={this.formLabel_telefono}
                                                                colSpan={3}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="telefonoEmpresa"
                                                                editorOptions={this.telefono_editorOptions}
                                                                label={this.formLabel_telefonoEmpresa}
                                                                colSpan={3}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="idLocalizacion"
                                                                colSpan={3}
                                                                label={this.formLabel_idLocalizacion}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                                cssClass={"p-0"}
                                                            />
                                                            <Item
                                                                visible={user.enableDatosRRHH}
                                                                dataField="idEmpresaPolarier"
                                                                colSpan={3}
                                                                label={this.formLabel_idEmpresaPolarier}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                                cssClass={"p-0"}
                                                            />
                                                            <Item
                                                                visible={user.enableDatosRRHH}
                                                                dataField="codigoGestoria"
                                                                label={this.formLabel_codigoGestoria}
                                                                colSpan={3}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="observaciones"
                                                                label={this.formLabel_observaciones}
                                                                colSpan={3}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                        </Item>
                                                    </Item>
                                                    <Item
                                                        visible={user.enableDatosRRHH}
                                                        itemType="group"
                                                        cssClass="dxForm_group"
                                                        colCount={4}
                                                        colSpan={2}
                                                        colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                        screenByWidth={this.dxForm_personal_screenByWidth}
                                                    >
                                                        {/* 3ª Columna */}
                                                        <Item itemType="group" colCount={2} colSpan={2}>
                                                            <Item
                                                                dataField="nacionalidad"
                                                                colSpan={2}
                                                                label={this.formLabel_apellidos}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="idTipoDocumentoIdentidad"
                                                                colSpan={2}
                                                                label={this.formLabel_idTipoDocumentoIdentidad}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="numDocumentoIdentidad"
                                                                colSpan={2}
                                                                label={this.formLabel_numDocumentoIdentidad}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="caducidadDocumentoIdentidad"
                                                                colSpan={2}
                                                                label={this.formLabel_caducidadDocumentoIdentidad}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="idFotoDocumentoIdentidad_A"
                                                                colSpan={2}
                                                                label={this.formLabel_idFotoDocumentoIdentidad_A}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="idFotoDocumentoIdentidad_B"
                                                                colSpan={2}
                                                                label={this.formLabel_idFotoDocumentoIdentidad_B}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                        </Item>
                                                        {/* 4ª Columna */}
                                                        <Item itemType="group" colCount={2} colSpan={2}>
                                                            <Item
                                                                dataField="IBAN"
                                                                colSpan={2}
                                                                label={this.formLabel_IBAN}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="idFotoIBAN"
                                                                colSpan={2}
                                                                label={this.formLabel_idFotoIBAN}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="NAF"
                                                                colSpan={2}
                                                                label={this.formLabel_NAF}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                            <Item
                                                                dataField="idFotoNAF"
                                                                colSpan={2}
                                                                label={this.formLabel_idFotoNAF}
                                                                render={this.dxFormItem_render}
                                                                onValueChanged={this.dxFormEditor_onValueChanged}
                                                            />
                                                        </Item>
                                                    </Item>
                                                    <Item
                                                        visible={user.enableDatosRRHH}
                                                        itemType="group"
                                                        cssClass="dxForm_group"
                                                        colCount={2}
                                                        colSpan={2}
                                                        colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                        screenByWidth={this.dxForm_personal_screenByWidth}
                                                    >
                                                        <Item
                                                            dataField="calle"
                                                            colSpan={2}
                                                            label={this.formLabel_calle}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="numDomicilio"
                                                            colSpan={2}
                                                            label={this.formLabel_numDomicilio}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="piso"
                                                            colSpan={2}
                                                            label={this.formLabel_piso}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="puerta"
                                                            colSpan={2}
                                                            label={this.formLabel_puerta}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="codigoPostal"
                                                            colSpan={2}
                                                            label={this.formLabel_codigoPostal}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="localidad"
                                                            colSpan={2}
                                                            label={this.formLabel_localidad}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="idComunidadAutonoma"
                                                            colSpan={2}
                                                            label={this.formLabel_idComunidadAutonoma}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="idPais"
                                                            colSpan={2}
                                                            label={this.formLabel_idPais}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                    </Item>

                                                    <Item
                                                        visible={user.enableDatosRRHH}
                                                        itemType="group"
                                                        cssClass="dxForm_group"
                                                        colCount={2}
                                                        colSpan={2}
                                                        colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                        screenByWidth={this.dxForm_personal_screenByWidth}
                                                    >
                                                        <Item
                                                            dataField="idGenero"
                                                            colSpan={2}
                                                            label={this.formLabel_idGenero}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="fechaNacimiento"
                                                            colSpan={2}
                                                            label={this.formLabel_fechaNacimiento}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="idEstadoCivil"
                                                            colSpan={2}
                                                            label={this.formLabel_idEstadoCivil}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="numHijos"
                                                            colSpan={2}
                                                            label={this.formLabel_numHijos}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="idNivelEstudios"
                                                            colSpan={2}
                                                            label={this.formLabel_idNivelEstudios}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="idDiscapacidad"
                                                            colSpan={2}
                                                            label={this.formLabel_idDiscapacidad}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="idTallaAlfa_Camiseta"
                                                            colSpan={2}
                                                            label={this.formLabel_idTallaAlfa_Camiseta}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                        <Item
                                                            dataField="tallaPantalon"
                                                            colSpan={2}
                                                            label={this.formLabel_tallaPantalon}
                                                            render={this.dxFormItem_render}
                                                            onValueChanged={this.dxFormEditor_onValueChanged}
                                                        />
                                                    </Item>
                                                </Item>
                                            </Tab>
                                            {/* Datos laborales */}
                                            <Tab name="datosLaborales" title={this.getTrad("datosLaborales")} index={1}>
                                                <Item
                                                    itemType="group"
                                                    colCount={4}
                                                    colSpan={4}
                                                    colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                    screenByWidth={this.dxForm_personal_screenByWidth}
                                                    cssClass="tabDatosLaborales_Padre_personal"
                                                >
                                                    <Item
                                                        itemType="group"
                                                        name="datosLaborales"
                                                        cssClass="dxForm_group"
                                                        colCount={8}
                                                        colSpan={2}
                                                        colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                        screenByWidth={this.dxForm_personal_screenByWidth}
                                                        component={DatosLaboralesForm}
                                                    ></Item>
                                                    <Item
                                                        itemType="group"
                                                        name="Contratos"
                                                        cssClass="dxForm_group"
                                                        colCount={2}
                                                        colSpan={2}
                                                        screenByWidth={this.dxForm_personal_screenByWidth}
                                                    >
                                                        <Item
                                                            cssClass="p-0"
                                                            dataField="tblPersonaNTipoContrato"
                                                            colSpan={2}
                                                            label={this.formLabel_contratos}
                                                            render={this.dxFormItem_render_tblPersonaNTipoContrato}
                                                        />
                                                    </Item>
                                                    <GroupItem cssClass="p-0" colSpan={2}>
                                                        <Item
                                                            dataField={"tblLavanderiaNUsuario"}
                                                            colSpan={8}
                                                            label={this.formLabel_tblLavanderiaNUsuario}
                                                            render={this.dxFormItem_render}
                                                        />
                                                    </GroupItem>
                                                </Item>
                                            </Tab>
                                            {/* Datos salariales */}
                                            <Tab
                                                visible={user.enableDatosRRHH}
                                                name="tabatosSalariales_Padre_controlEnvios"
                                                title={this.getTrad("datosSalariales")}
                                                index={2}
                                            >
                                                <Item
                                                    itemType="group"
                                                    cssClass="dxForm_group"
                                                    colCount={3}
                                                    colSpan={3}
                                                    colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                    screenByWidth={this.dxForm_personal_screenByWidth}
                                                >
                                                    <Item
                                                        colCount={1}
                                                        colSpan={1}
                                                        dataField="idUsuario_validacion_nomina"
                                                        label={this.formLabel_idUsuario_validacion_nomina}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <EmptyItem colSpan={1} />
                                                    <Item
                                                        colCount={1}
                                                        colSpan={1}
                                                        dataField={"tblDatosSalariales.salarioBrutoMensual"}
                                                        label={this.formLabel_salarioBrutoMensual}
                                                        render={this.salarioBrutoMensual_render}
                                                    />
                                                </Item>
                                                <Item
                                                    itemType="group"
                                                    cssClass="dxForm_group"
                                                    colCount={4}
                                                    colSpan={4}
                                                    colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                    screenByWidth={this.dxForm_personal_screenByWidth}
                                                >
                                                    <Item
                                                        dataField="tblDatosSalariales.fechaAntiguedad"
                                                        label={this.formLabel_tblDatosSalariales_fechaAntiguedad}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="numAños"
                                                        label={this.formLabel_numAños}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.isTrienio"
                                                        label={this.formLabel_tblDatosSalariales_isTrienio}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="numTrienios"
                                                        label={this.formLabel_numTrienios}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.salarioBase"
                                                        label={this.formLabel_tblDatosSalariales_salarioBase}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.plusAsistencia"
                                                        label={this.formLabel_tblDatosSalariales_plusAsistencia}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.plusResponsabilidad"
                                                        label={this.formLabel_tblDatosSalariales_plusResponsabilidad}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.plusPeligrosidad"
                                                        label={this.formLabel_tblDatosSalariales_plusPeligrosidad}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.incentivo"
                                                        label={this.formLabel_tblDatosSalariales_incentivo}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.impHoraExtra"
                                                        label={this.formLabel_tblDatosSalariales_impHoraExtra}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.plusProductividad"
                                                        label={this.formLabel_tblDatosSalariales_plusProductividad}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.acuerdoNC"
                                                        label={this.formLabel_tblDatosSalariales_acuerdoNC}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.salarioEspecie"
                                                        label={this.formLabel_tblDatosSalariales_salarioEspecie}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.numPagas"
                                                        label={this.formLabel_tblDatosSalariales_numPagas}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="tblDatosSalariales.percSegSocial"
                                                        label={this.formLabel_tblDatosSalariales_percSegSocial}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="salarioBrutoAnual"
                                                        label={this.formLabel_salarioBrutoAnual}
                                                        render={this.dxFormItem_render}
                                                    />
                                                    <Item
                                                        dataField="costeEmpresa"
                                                        label={this.formLabel_costeEmpresa}
                                                        render={this.dxFormItem_render}
                                                    />
                                                </Item>
                                                <Item
                                                    itemType="group"
                                                    cssClass="dxForm_group p-0"
                                                    colCount={1}
                                                    colSpan={4}
                                                    colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                    screenByWidth={this.dxForm_personal_screenByWidth}
                                                >
                                                    <Item
                                                        colSpan={1}
                                                        colCountByScreen={this.dxForm_personal_colCountByScreen}
                                                        screenByWidth={this.dxForm_personal_screenByWidth}
                                                    >
                                                        <div
                                                            className="w-100 position-relative"
                                                            style={{ height: 200 }}
                                                        >
                                                            <div
                                                                className="position-absolute w-100"
                                                                style={{ height: 200, left: 0, top: 0 }}
                                                            >
                                                                <TablaHistorialNominas
                                                                    idPersona={personaSel?.idPersona}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Item>
                                                </Item>
                                            </Tab>
                                            {/* Documentos */}
                                            <Tab
                                                visible={user.enableDatosRRHH}
                                                name="documentos"
                                                title={this.getTrad("documentos")}
                                                index={3}
                                            >
                                                <LoadPanel
                                                    deferRendering={false}
                                                    animation={null}
                                                    position={tabDocumentos_Padre_personal}
                                                    width={220}
                                                    maxWidth={220}
                                                    visible={
                                                        showLoading_tabDocumentos &&
                                                        dxTabPanel_fichaPersona_selectedTabName == "documentos"
                                                    }
                                                    shadingColor="transparent"
                                                    showIndicator={true}
                                                    message={isGuardando_fichaPersona ? "Guardando ..." : "Cargando"}
                                                    shading={true}
                                                    showPane={true}
                                                />
                                                <Box
                                                    direction="row"
                                                    width="100%"
                                                    height="100%"
                                                    elementAttr={this.elemAttr_tabDocumentos_Padre_personal}
                                                >
                                                    <ItemBox ratio={1}>
                                                        <Box
                                                            direction="col"
                                                            width="100%"
                                                            height={$("#dxPopup_personal_fichaPersona").height() - 101}
                                                            onContentReady={this.ItemBoxTipoDocumento_onContentReady}
                                                        >
                                                            <ItemBox ratio={1}>
                                                                <div className="dx-list-group-header adjuntarArchivos_personal_header">
                                                                    {!multiViewCarpetas_DocumentosAgrupados?.padre ? (
                                                                        <div>
                                                                            <i className="dx-icon dx-icon-folder mx-2"></i>
                                                                            {this.getTrad("carpetas").toUpperCase()}
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            onClick={this.resetMultiViewCarpetas}
                                                                            id="dxMultiView_personal_fichaPersona_Carpetas_Return"
                                                                        >
                                                                            <i className="dx-icon dx-icon-arrowleft mx-2"></i>
                                                                            <span>
                                                                                {this.getTrad("carpetas").toUpperCase()}{" "}
                                                                                /{" "}
                                                                                {multiViewCarpetas_DocumentosAgrupados.padre.toUpperCase()}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <MultiView
                                                                    animationEnabled={true}
                                                                    selectedIndex={multiViewCarpetasIndex}
                                                                    swipeEnabled={false}
                                                                    id="dxMultiView_personal_fichaPersona_Carpetas"
                                                                >
                                                                    <MultiviewItem>
                                                                        <List
                                                                            ref={this.dxList_documentos_REF}
                                                                            dataSource={dataSource_documentos[0]?.items}
                                                                            height={
                                                                                $(
                                                                                    "#dxPopup_personal_fichaPersona"
                                                                                ).height() - 145
                                                                            }
                                                                            selectionMode="single"
                                                                            selectedItemKeys={
                                                                                carpetaDocumentosSel
                                                                                    ? [
                                                                                          carpetaDocumentosSel.idCarpetaDocumentos,
                                                                                      ]
                                                                                    : null
                                                                            }
                                                                            displayExpr="denominacion"
                                                                            keyExpr="idCarpetaDocumentos"
                                                                            noDataText=""
                                                                            onContentReady={
                                                                                this.listTipoDocumento_onContentReady
                                                                            }
                                                                            onSelectionChanged={
                                                                                this
                                                                                    .listTipoDocumento_onSelectionChanged
                                                                            }
                                                                            itemRender={
                                                                                this.listTipoDocumento_itemRender
                                                                            }
                                                                        />
                                                                    </MultiviewItem>
                                                                    <MultiviewItem>
                                                                        <List
                                                                            // ref={this.dxList_documentos_REF}
                                                                            dataSource={
                                                                                multiViewCarpetas_DocumentosAgrupados?.grupos
                                                                            }
                                                                            height={
                                                                                $(
                                                                                    "#dxPopup_personal_fichaPersona"
                                                                                ).height() - 101
                                                                            }
                                                                            selectionMode="single"
                                                                            selectedItemKeys={
                                                                                carpetaDocumentosSel
                                                                                    ? [carpetaDocumentosSel.subCarpeta]
                                                                                    : null
                                                                            }
                                                                            displayExpr="denominacion"
                                                                            keyExpr={"subCarpeta"}
                                                                            noDataText=""
                                                                            onContentReady={
                                                                                this.listTipoDocumento_onContentReady
                                                                            }
                                                                            onSelectionChanged={
                                                                                this
                                                                                    .listTipoDocumento_onSelectionChanged
                                                                            }
                                                                            itemRender={
                                                                                this.listTipoDocumento_itemRender
                                                                            }
                                                                        />
                                                                    </MultiviewItem>
                                                                </MultiView>
                                                            </ItemBox>
                                                        </Box>
                                                    </ItemBox>
                                                    <ItemBox baseSize={40} />
                                                    <ItemBox ratio={2}>
                                                        <Box
                                                            direction="col"
                                                            width="100%"
                                                            height={$("#dxPopup_personal_fichaPersona").height() - 101}
                                                            onContentReady={this.ItemBoxTipoDocumento_onContentReady}
                                                        >
                                                            <ItemBox ratio={1}>
                                                                <div className="dx-list-group-header adjuntarArchivos_personal_header">
                                                                    <div>
                                                                        <i className="dx-icon dx-icon-fields mx-2"></i>
                                                                        {this.getTrad("archivos").toUpperCase()}
                                                                    </div>
                                                                </div>
                                                                <AdjuntarArchivos
                                                                    elementAttr={
                                                                        this.adjuntarArchivos_container_elementAttr
                                                                    }
                                                                    data={
                                                                        dxTabPanel_fichaPersona_selectedTabName !==
                                                                        "documentos"
                                                                            ? []
                                                                            : carpetaDocumentosSel
                                                                            ? $.extend(
                                                                                  true,
                                                                                  [],
                                                                                  carpetaDocumentosSel.tblDocumento.filter(
                                                                                      (x) =>
                                                                                          !carpetaDocumentosSel.subCarpeta ||
                                                                                          carpetaDocumentosSel.subCarpeta ==
                                                                                              x.subCarpeta
                                                                                  )
                                                                              )
                                                                            : []
                                                                    }
                                                                    idPadreSel={
                                                                        dxTabPanel_fichaPersona_selectedTabName !==
                                                                        "documentos"
                                                                            ? null
                                                                            : carpetaDocumentosSel
                                                                            ? carpetaDocumentosSel.idCarpetaDocumentos
                                                                            : null
                                                                    }
                                                                    subcarpetas={
                                                                        multiViewCarpetas_DocumentosAgrupados?.grupos
                                                                    }
                                                                    subCarpetaSel={carpetaDocumentosSel?.subCarpeta}
                                                                    tipoArchivos="documentos"
                                                                    allowedFileExtensions={
                                                                        this
                                                                            .AdjuntarArchivos_fichaPersona_allowedFileExtensions
                                                                    }
                                                                    acceptedTypes="image/*,.pdf"
                                                                    documentosNGrandParent={null}
                                                                    isRemoveAllowed={true}
                                                                    isEditAllowed={true}
                                                                    isUploadAllowed={carpetaDocumentosSel != null}
                                                                    isMultipleLoadFile={false}
                                                                    isVisibleRequerirDocumento={true}
                                                                    isDropZone={true}
                                                                    accionesModificarDatos={
                                                                        this.state.accionesModificarDatos_fileUploader
                                                                    }
                                                                    maxSize={500000000}
                                                                    numItemPerRow={2}
                                                                    tieneCabecera={true}
                                                                    showLoading_adjuntarArchivos={
                                                                        this.state.showLoading_adjuntarArchivos
                                                                    }
                                                                    updateArchivos={this.updateArchivos}
                                                                />
                                                            </ItemBox>
                                                        </Box>
                                                    </ItemBox>
                                                </Box>
                                            </Tab>
                                            {/* Calendario laboral */}
                                            <Tab name="calendarioLaboral" title={this.getTrad("calendario")} index={4}>
                                                <Box
                                                    direction="row"
                                                    width="100%"
                                                    height={$("#dxPopup_personal_fichaPersona").height() - 101}
                                                    elementAttr={this.elemAttr_tabCalendarioLaboral_Padre_personal}
                                                >
                                                    <ItemBox ratio={1}>
                                                        <LoadPanel
                                                            visible={!isRendered_tabCalendario}
                                                            shadingColor="#FFF"
                                                            deferRendering={false}
                                                            animation={null}
                                                            position={tabCalendarioLaboral_Padre_personal}
                                                            showIndicator={true}
                                                            shading={true}
                                                            showPane={true}
                                                        />
                                                        {isRendered_tabCalendario && (
                                                            <YearCalendar
                                                                data={
                                                                    personaSel ? personaSel.tblCalendarioPersonal : []
                                                                }
                                                                estadosPorDefecto={yearCalendar_estadosPorDefecto}
                                                                yearCalendar_newData={yearCalendar_newData}
                                                                tipoCalendario="personal"
                                                                allowDelete={true}
                                                                enableRangeSelection={true}
                                                                listEstados_selectMode="single"
                                                                estados={datasource_tblCalendarioEstado}
                                                                soloMostrar_idEstados_seleccion={[
                                                                    0, 2, 5, 6, 7, 9, 11, 12, 13,
                                                                ]}
                                                                readOnly_idEstados_seleccion={[3, 4]}
                                                                currentYear={yearCalendar_currentYear}
                                                                yearCalendar_onDateChanged={
                                                                    this.yearCalendar_onDateChanged
                                                                }
                                                                yearCalendar_onYearChanged={
                                                                    this.yearCalendar_onYearChanged
                                                                }
                                                                yearCalendar_onEstadoDeleting={
                                                                    this.yearCalendar_onEstadoDeleting
                                                                }
                                                                disabledDays={disabledDays}
                                                            />
                                                        )}
                                                    </ItemBox>
                                                </Box>
                                            </Tab>
                                        </Item>
                                    </Popup_Form>
                                </Editing>
                                <Column dataField={"isFormPedidoAllowed"} visible={false} />
                                <Column dataField={"tblLavanderiaNUsuario"} visible={false} />
                                <Column
                                    caption=" "
                                    visible={user.enableDatosRRHH}
                                    name="datosFaltantes"
                                    width={30}
                                    alignment="center"
                                    cssClass="p-0"
                                    allowEditing={false}
                                    cellRender={this.cellRender_avisos_datos}
                                />
                                <Column
                                    caption=" "
                                    visible={user.enableDatosRRHH}
                                    name="docIdentidad"
                                    width={30}
                                    alignment="center"
                                    cssClass="p-0"
                                    allowEditing={false}
                                    cellRender={this.cellRender_docIdentidad}
                                />
                                <Column
                                    caption=" "
                                    name="editar"
                                    width={40}
                                    alignment="center"
                                    cssClass="p-0"
                                    cellComponent={editCellComponent}
                                    allowEditing={false}
                                />
                                <Column
                                    visible={user.enableDatosRRHH}
                                    dataField="codigoGestoria"
                                    caption={this.getTrad("codGestoria")}
                                    allowHeaderFiltering={false}
                                    dataType="string"
                                    alignment="center"
                                    width={100}
                                />
                                <Column
                                    dataField="nombre"
                                    caption={this.getTrad("nombre")}
                                    sortOrder="asc"
                                    sortIndex={1}
                                    allowHeaderFiltering={false}
                                    editorOptions={this.showClearButton_editorOptions}
                                />
                                <Column
                                    dataField="apellidos"
                                    caption={this.getTrad("apellidos")}
                                    sortOrder="asc"
                                    sortIndex={2}
                                    allowHeaderFiltering={false}
                                    editorOptions={this.showClearButton_editorOptions}
                                />
                                <Column
                                    visible={user.enableDatosRRHH}
                                    dataField="idEmpresaPolarier"
                                    caption={this.getTrad("empresa")}
                                    allowHeaderFiltering={false}
                                    alignment="center"
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblEmpresasPolarier}
                                        valueExpr="idEmpresaPolarier"
                                        displayExpr="denominacion"
                                    />
                                </Column>
                                <Column
                                    dataField="idCentroLav"
                                    caption={this.getTrad("centroLavanderia")}
                                    width={"15%"}
                                    allowSearch={false}
                                    visible={true}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblCentroTrabajo_Lavanderia_todos}
                                        valueExpr="idCentroLav"
                                        displayExpr="denominacion"
                                    />
                                </Column>
                                <Column dataField="idCategoriaInterna" caption={this.getTrad("categoriaInterna")}>
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblCategoriaInterna_lookupGrid}
                                        valueExpr={"idCategoriaInterna"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="horasDiarias"
                                    caption={this.getTrad("horas")}
                                    width={60}
                                    allowFiltering={false}
                                    editorOptions={this.horasDiarias_editorOptions}
                                />
                                <Column
                                    dataField="idTurno"
                                    caption={this.getTrad("turno")}
                                    width={130}
                                    allowHeaderFiltering={false}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblTurno}
                                        valueExpr={"idTurno"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column dataField="idTipoTrabajo" caption={this.getTrad("tipoTrabajo")} width={150}>
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblTipoTrabajo}
                                        valueExpr={"idTipoTrabajo"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="activo"
                                    name="activo"
                                    caption={this.getTrad("activo")}
                                    width={125}
                                    cssClass="p-0"
                                    sortOrder="desc"
                                    sortIndex={0}
                                    allowFiltering={false}
                                    allowHeaderFiltering={true}
                                    readOnly={!user.enableDatosRRHH}
                                    cellRender={this.dxDataGrid_tblPersonas_activo_editCellRender}
                                >
                                    <HeaderFilter dataSource={this.enum_activo_noActivo} />
                                </Column>
                                <Column
                                    visible={user.enableDatosRRHH}
                                    caption=" "
                                    width={50}
                                    alignment="center"
                                    cssClass="p-0"
                                    cellComponent={btnRemovePersona_Component}
                                    allowEditing={false}
                                />
                                <Column
                                    dataField="idGenero"
                                    caption={this.getTrad("genero")}
                                    allowSearch={false}
                                    visible={false}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblGenero}
                                        valueExpr={"idGenero"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="numDocumentoIdentidad"
                                    caption={this.getTrad("numDocumentoIdentidad")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="fechaNacimiento"
                                    caption={this.getTrad("fechaNacimiento")}
                                    dataType="date"
                                    editorType="dxDateBox"
                                    editorOptions={this.dxDateBox_editorOptions}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="direccion"
                                    caption={this.getTrad("direccion")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="codigoPostal"
                                    caption={this.getTrad("codigoPostal")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="poblacion"
                                    caption={this.getTrad("poblacion")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="telefono"
                                    caption={this.getTrad("telefonoPersonal")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column dataField="prefijoTelefonico" allowSearch={false} visible={false} />
                                <Column
                                    dataField="telefonoEmpresa"
                                    caption={this.getTrad("telefonoEmpresa")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column dataField="prefijoTelefonicoEmpresa" allowSearch={false} visible={false} />
                                <Column
                                    dataField="calle"
                                    caption={this.getTrad("calle")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="numDomicilio"
                                    caption={this.getTrad("numDomicilio")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="piso"
                                    caption={this.getTrad("piso")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="puerta"
                                    caption={this.getTrad("puerta")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="codigoPostal"
                                    caption={this.getTrad("codigoPostal")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="localidad"
                                    caption={this.getTrad("localidad")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="idComunidadAutonoma"
                                    caption={this.getTrad("comunidadAutonoma")}
                                    allowSearch={false}
                                    visible={false}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblComunidadAutonoma}
                                        valueExpr={"idComunidadAutonoma"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="nacionalidad"
                                    caption={this.getTrad("nacionalidad")}
                                    allowSearch={false}
                                    visible={false}
                                ></Column>
                                <Column
                                    dataField="idPais"
                                    caption={this.getTrad("pais")}
                                    allowSearch={false}
                                    visible={false}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblPais}
                                        valueExpr={"idPais"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="idTipoDocumentoIdentidad"
                                    caption={this.getTrad("tipoDocumento")}
                                    allowSearch={false}
                                    visible={false}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblTipoDocumentoIdentidad}
                                        valueExpr={"idTipoDocumentoIdentidad"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="numDocumentoIdentidad"
                                    caption={this.getTrad("numDocumentoIdentidad")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="NAF"
                                    caption={this.getTrad("NAF")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="IBAN"
                                    caption={this.getTrad("IBAN")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="isIBANSolicitado"
                                    caption={this.getTrad("IBAN")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="idEstadoCivil"
                                    caption={this.getTrad("estadoCivil")}
                                    allowSearch={false}
                                    visible={false}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblEstadoCivil}
                                        valueExpr={"idEstadoCivil"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="idNivelEstudios"
                                    caption={this.getTrad("nivelEstudios")}
                                    allowSearch={false}
                                    visible={false}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblNivelEstudios}
                                        valueExpr={"idNivelEstudios"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="numHijos"
                                    caption={this.getTrad("numHijos")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="idDiscapacidad"
                                    caption={this.getTrad("discapacidad")}
                                    allowSearch={false}
                                    visible={false}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblDiscapacidad}
                                        valueExpr={"idDiscapacidad"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="idTallaAlfa_Camiseta"
                                    caption={this.getTrad("tallaCamiseta")}
                                    allowSearch={false}
                                    visible={false}
                                >
                                    <DataGrid_Lookup
                                        dataSource={datasource_tblTallaAlfa}
                                        valueExpr={"idTallaAlfa"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="tallaPantalon"
                                    caption={this.getTrad("tallaPantalon")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="email"
                                    caption={this.getTrad("email")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="numSegSocial"
                                    caption={this.getTrad("numSegSocial")}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="observaciones"
                                    caption={this.getTrad("observaciones")}
                                    editorType="dxTextArea"
                                    editorOptions={this.dxTextArea_editorOptions}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="observacionesLaborales"
                                    caption={this.getTrad("observaciones")}
                                    editorType="dxTextArea"
                                    editorOptions={this.dxTextArea_editorOptions}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.fechaAntiguedad"
                                    caption={this.getTrad("fechaAntiguedad")}
                                    dataType="date"
                                    editorType="dxDateBox"
                                    editorOptions={this.dxDateBox_editorOptions}
                                    setCellValue={this.fechaAntiguedad_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="numAños"
                                    caption={this.getTrad("numAños")}
                                    calculateCellValue={this.numAños_calculateCellValue}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_placeholder_editorOptions}
                                    allowEditing={false}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.isTrienio"
                                    caption={"isTrienio"}
                                    allowSearch={false}
                                    visible={false}
                                    setCellValue={this.isTrienio_setCellValue}
                                >
                                    <DataGrid_Lookup
                                        dataSource={this.enum_isTrienio}
                                        valueExpr={"value"}
                                        displayExpr={"denominacion"}
                                    />
                                </Column>
                                <Column
                                    dataField="numTrienios"
                                    caption={this.getTrad("numTrienios")}
                                    calculateCellValue={this.numTrienios_calculateCellValue}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_placeholder_editorOptions}
                                    allowEditing={false}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.salarioBase"
                                    caption={this.getTrad("salarioBase")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    setCellValue={this.salarioBase_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.plusAsistencia"
                                    caption={this.getTrad("plusAsistencia")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    setCellValue={this.plusAsistencia_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.plusResponsabilidad"
                                    caption={this.getTrad("plusResponsabilidad")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    setCellValue={this.plusResponsabilidad_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.plusPeligrosidad"
                                    caption={this.getTrad("plusPeligrosidad")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    setCellValue={this.plusPeligrosidad_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.incentivo"
                                    caption={this.getTrad("incentivo")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    setCellValue={this.incentivo_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.impHoraExtra"
                                    caption={this.getTrad("impHoraExtra")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    setCellValue={this.impHoraExtra_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.plusProductividad"
                                    caption={this.getTrad("plusProductividad")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    setCellValue={this.plusProductividad_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.acuerdoNC"
                                    caption={this.getTrad("acuerdoNC")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    setCellValue={this.acuerdoNC_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.salarioEspecie"
                                    caption={this.getTrad("salarioEnEspecie")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    setCellValue={this.salarioEspecie_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column dataField="tblDatosSalariales" allowSearch={false} visible={false} />
                                <Column
                                    dataField="tblDatosSalariales.numPagas"
                                    caption={this.getTrad("numPagas")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.numPagas_editorOptions}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="tblDatosSalariales.percSegSocial"
                                    caption={this.getTrad("percSegSocial")}
                                    editorType="dxNumberBox"
                                    editorOptions={this.percSegSocial_editorOptions}
                                    setCellValue={this.percSegSocial_setCellValue}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField={"salarioBrutoAnual"}
                                    caption={this.getTrad("salarioBrutoAnual")}
                                    calculateCellValue={this.salarioBrutoAnual_calculateCellValue}
                                    editorType={"dxNumberBox"}
                                    editorOptions={this.salarioBrutoAnual_editorOptions}
                                    allowEditing={false}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="costeEmpresa"
                                    caption={this.getTrad("costeEmpresa")}
                                    calculateCellValue={this.costeEmpresa_calculateCellValue}
                                    editorType="dxNumberBox"
                                    editorOptions={this.costeEmpresa_editorOptions}
                                    allowEditing={false}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column dataField="idLavanderia" allowSearch={false} visible={false} />
                                <Column dataField="idPersona" allowSearch={false} visible={false} />
                                <Column dataField="tblDocumento" allowSearch={false} visible={false} />
                                <Column dataField="tblCalendarioPersonal" allowSearch={false} visible={false} />
                                <Column dataField="tblPersonaNTipoContrato" allowSearch={false} visible={false} />
                                <Column dataField="tblDiasLibresPersonal" allowSearch={false} visible={false} />
                                <Column dataField="idFotoPerfilNavigation" allowSearch={false} visible={false} />
                                <Column
                                    dataField="idFotoDocumentoIdentidad_ANavigation"
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column
                                    dataField="idFotoDocumentoIdentidad_BNavigation"
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column dataField="idFotoIBANNavigation" allowSearch={false} visible={false} />
                                <Column dataField="idFotoNAFNavigation" allowSearch={false} visible={false} />
                                <Column dataField="idFotoPerfil" allowSearch={false} visible={false} />
                                <Column dataField="idFotoDocumentoIdentidad_A" allowSearch={false} visible={false} />
                                <Column dataField="idFotoDocumentoIdentidad_B" allowSearch={false} visible={false} />
                                <Column dataField="idFotoIBAN" allowSearch={false} visible={false} />
                                <Column dataField="idFotoNAF" allowSearch={false} visible={false} />
                                <Column dataField="caducidadDocumentoIdentidad" allowSearch={false} visible={false} />
                                <Column dataField="idFormatoDiasLibres" allowSearch={false} visible={false} />
                                <Column dataField="idUsuario_validacion_nomina" allowSearch={false} visible={false} />
                                <Column
                                    dataField={"tblDatosSalariales.salarioBrutoMensual"}
                                    caption={getTrad("salarioBrutoMensual")}
                                    editorType={"dxNumberBox"}
                                    editorOptions={this.dxNumberBox_currency_editorOptions}
                                    allowSearch={false}
                                    visible={false}
                                />
                                <Column dataField="idLlamamiento" allowSearch={false} visible={false} />
                                <Column
                                    dataField="idAdmElementoPEP"
                                    allowSearch={false}
                                    visible={false}
                                    caption={this.getTrad("elementoPep")}
                                    width={150}
                                />
                                <Column
                                    dataField="idAdmCentroCoste"
                                    allowSearch={false}
                                    visible={false}
                                    caption={this.getTrad("centroCoste")}
                                    width={150}
                                />
                                <Column
                                    dataField="idAdmCuentaContable_Salario"
                                    allowSearch={false}
                                    visible={false}
                                    caption={this.getTrad("cuentaContableSalario")}
                                    width={150}
                                />
                                <Column
                                    dataField="idAdmCuentaContable_SSEmpresa"
                                    allowSearch={false}
                                    visible={false}
                                    caption={this.getTrad("cuentaContableSSEmpresa")}
                                    width={150}
                                />
                            </DataGrid>
                        </PersonaSel.Provider>

                        {identifyNeeded ? (
                            <PopupIdentificacion
                                identifyNeeded={identifyNeeded}
                                onIdentifySuccess={this.onIdentifySuccess}
                                onCancelIdentify={this.onCancelIdentify}
                            />
                        ) : null}

                        {/* GESTIONAR DOCUMENTOS */}
                        <Popup
                            title={this.getTrad("gestionarDocumentacion")}
                            showTitle={true}
                            visible={isVisible_dxPopup_gestionarDocumentacion_comun}
                            width={resolucion != "xl" ? "95%" : "80%"}
                            height={resolucion != "xl" ? "95%" : "85%"}
                            position={this.dxPopup_center_position}
                            onContentReady={this.dxPopup_gestionarDocumentacion_onContentReady}
                            onHiding={this.dxPopup_gestionarDocumentacion_onHiding}
                        >
                            <Box direction="row" width="100%" height="100%">
                                <ItemBox ratio={1}>
                                    <Box direction="col" width="100%" height="100%">
                                        <ItemBox ratio={1}>
                                            <div className="dx-list-group-header adjuntarArchivos_personal_header border">
                                                <div>{this.getTrad("personas").toUpperCase()}</div>
                                            </div>
                                            <SelectBox
                                                visible={false}
                                                className="mb-2"
                                                items={datasource_lavanderia_documentoGeneral}
                                                value={
                                                    datasource_lavanderia_documentoGeneral.length === 1
                                                        ? datasource_lavanderia_documentoGeneral[0].idLavanderia
                                                        : idLavSel_documentosGeneral
                                                }
                                                disabled={datasource_lavanderia_documentoGeneral.length === 1}
                                                valueExpr="idLavanderia"
                                                displayExpr="denominacion"
                                                placeholder={this.getTrad("todos").toUpperCase()}
                                                width="100%"
                                                onValueChanged={
                                                    this.dxSelectBox_lavanderia_documentosGeneral_onValueChanged
                                                }
                                            ></SelectBox>
                                            <List
                                                ref={this.dxList_documentos_personasGeneral_REF}
                                                height="100%"
                                                selectionMode="all"
                                                selectedItemKeys={dxList_persona_listaGeneral_selectedItemKeys}
                                                displayExpr="nombreCompuesto"
                                                keyExpr="idPersona"
                                                noDataText=""
                                                pageLoadMode="scrollBottom"
                                                searchEnabled={true}
                                                searchExpr={this.dxList_persona_listaGeneral_searchExpr}
                                                onContentReady={this.dxList_persona_listaGeneral_onContentReady}
                                                onOptionChanged={this.dxList_persona_listaGeneral_onOptionChanged}
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox baseSize={40} />
                                <ItemBox ratio={1}>
                                    <List
                                        ref={this.dxList_documentos_personas_REF}
                                        height="100%"
                                        dataSource={
                                            isVisible_dxPopup_gestionarDocumentacion_comun ? dataSource_documentos : []
                                        }
                                        selectionMode="single"
                                        selectedItemKeys={
                                            carpetaDocumentosSel ? [carpetaDocumentosSel.idCarpetaDocumentos] : null
                                        }
                                        displayExpr="denominacion"
                                        keyExpr="idCarpetaDocumentos"
                                        noDataText=""
                                        grouped={true}
                                        onContentReady={this.listTipoDocumento_onContentReady}
                                        onSelectionChanged={this.listTipoDocumento_onSelectionChanged}
                                        groupRender={this.listTipoDocumento_groupRender}
                                        itemRender={this.listTipoDocumento_itemRender}
                                    />
                                </ItemBox>
                                <ItemBox baseSize={40} />
                                <ItemBox ratio={2}>
                                    <Box direction="col" width="100%" height="100%">
                                        <ItemBox ratio={1}>
                                            <div className="dx-list-group-header adjuntarArchivos_personal_header">
                                                <div>{this.getTrad("archivos").toUpperCase()}</div>
                                            </div>
                                            {isVisible_dxPopup_gestionarDocumentacion_comun ? (
                                                <AdjuntarArchivos
                                                    elementAttr={this.adjuntarArchivos_container_elementAttr}
                                                    data={
                                                        carpetaDocumentosSel
                                                            ? $.extend(true, [], carpetaDocumentosSel.tblDocumento)
                                                            : []
                                                    }
                                                    idPadreSel={
                                                        carpetaDocumentosSel
                                                            ? carpetaDocumentosSel.idCarpetaDocumentos
                                                            : null
                                                    }
                                                    tipoArchivos="documentos"
                                                    allowedFileExtensions={
                                                        this.AdjuntarArchivos_fichaPersona_allowedFileExtensions
                                                    }
                                                    acceptedTypes={"image/*,.pdf"}
                                                    isOnlyImages={false}
                                                    isEditAllowed={false}
                                                    isRemoveAllowed={true}
                                                    isMultipleLoadFile={false}
                                                    isVisibleRequerirDocumento={true}
                                                    documentosNGrandParent={documentosNGrandParent}
                                                    isDropZone={true}
                                                    accionesModificarDatos={
                                                        this.state.accionesModificarDatos_fileUploader
                                                    }
                                                    maxSize={500000000}
                                                    numItemPerRow={2}
                                                    tieneCabecera={true}
                                                    showLoading_adjuntarArchivos={
                                                        this.state.showLoading_adjuntarArchivos
                                                    }
                                                    updateArchivos={this.updateArchivos}
                                                />
                                            ) : null}
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                            </Box>

                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("aceptar")}
                                    type="success"
                                    onClick={this.dxButton_gestionarDocumentacion_guardar_onClick}
                                />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("cancelar")}
                                    type="normal"
                                    onClick={this.dxButton_gestionarDocumentacion_cancelar_onClick}
                                />
                            </ToolbarItem_popup>
                        </Popup>
                        {/* IMPORTAR DOCUMENTOS */}
                        <Popup
                            title={this.getTrad("importarDocumentos")}
                            visible={isVisible_dxPopup_ImportarDocumentos}
                            showTitle={true}
                            width={980}
                            height={"75%"}
                            position={this.dxPopup_center_position}
                            onContentReady={this.dxPopup_personal_ImportarDocumentos_onContentReady}
                            onHiding={this.dxPopup_ImportarDocumentos_onHiding}
                            onHidden={this.dxPopup_ImportarDocumentos_onHidden}
                        >
                            <Box
                                direction="col"
                                width="100%"
                                height="100%"
                                onContentReady={this.ItemBoxTipoDocumento_Importar_onContentReady}
                            >
                                <ItemBox ratio={0} baseSize={60} class>
                                    <div className="h-100 d-flex flex-wrap align-content-center ml-4">
                                        <div className={"align-items-center d-flex"}>
                                            <span className={"pr-4 pe-none"}>{this.getTrad("empresa")}</span>
                                        </div>
                                        <SelectBox
                                            dataSource={datasource_tblEmpresasPolarier}
                                            valueExpr={"idEmpresaPolarier"}
                                            displayExpr={"denominacion"}
                                            stylingMode="underlined"
                                            width={200}
                                            value={ImportarDocumentos_EmpresaSel?.idEmpresaPolarier}
                                            showClearButton={true}
                                            onSelectionChanged={
                                                this.dxSelectionBox_ImportarDocumentos_continuar_onSelectionChanged
                                            }
                                        />
                                    </div>
                                </ItemBox>
                                <ItemBox ratio={1}>
                                    <AdjuntarArchivos
                                        idPadreSel={null}
                                        data={documentosImportarDocumentos}
                                        tipoArchivos="documentos"
                                        acceptedTypes=".pdf"
                                        documentosNGrandParent={null}
                                        isRemoveAllowed={true}
                                        isEditAllowed={false}
                                        isMultipleLoadFile={true}
                                        isDropZone={true}
                                        accionesModificarDatos={
                                            this.state.accionesModificarDatos_fileUploader_ImportarDocumentos
                                        }
                                        showProgressBar={true}
                                        showLoading_adjuntarArchivos={this.state.showLoading_adjuntarArchivos}
                                        maxSize={500000000}
                                        numItemPerRow={2}
                                        tieneCabecera={false}
                                        updateArchivos={this.updateArchivos_ImportarDocumentos}
                                    />
                                    <div
                                        style={{
                                            marginTop: -25,
                                            marginLeft: 20,
                                            fontWeight: 600,
                                            flex: 1,
                                        }}
                                    >
                                        <span>{documentosImportarDocumentos.length + " elementos "}</span>
                                        {numNominasError > 0 || ImportarDocumentosNoValidados.length > 0 ? "(" : ""}
                                        {numNominasError > 0 && (
                                            <span className="text-danger">
                                                {numNominasError + " incorrecto" + (numNominasError > 1 ? "s" : "")}
                                            </span>
                                        )}
                                        {numNominasError > 0 && ImportarDocumentosNoValidados.length > 0 && ", "}
                                        {ImportarDocumentosNoValidados.length > 0 ? (
                                            <span>
                                                {+ImportarDocumentosNoValidados.length +
                                                    " no validado" +
                                                    (ImportarDocumentosNoValidados.length > 1 ? "s" : "") +
                                                    ")"}
                                            </span>
                                        ) : numNominasError > 0 ? (
                                            ")"
                                        ) : (
                                            ""
                                        )}
                                        {maxFileExceeded && documentosImportarDocumentos?.length > 0 && (
                                            <Fragment>
                                                <div className="text-danger">
                                                    {"El tamaño de los archivos es mayor al límite permitido."}
                                                </div>
                                                <div className="text-danger">
                                                    {"Reduzca el número de archivos o su peso. (Máx 500 MB)"}
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                </ItemBox>
                            </Box>
                            <ToolbarItem_popup toolbar="bottom" location="after">
                                <Button
                                    text={this.getTrad("continuar")}
                                    type="success"
                                    disabled={maxFileExceeded}
                                    onClick={this.dxButton_ImportarDocumentos_continuar_onClick}
                                />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup toolbar="bottom" location="after">
                                <Button
                                    text={this.getTrad("cancelar")}
                                    type="normal"
                                    onClick={this.dxButton_ImportarDocumentos_cancelar_onClick}
                                />
                            </ToolbarItem_popup>
                        </Popup>
                        {/* AÑADIR PERSONA */}
                        <Popup
                            title={this.getTrad("altaNuevoPersonal")}
                            visible={isVisible_dxPopup_addNewPersona}
                            showTitle={true}
                            width={"40%"}
                            height={"80%"}
                            deferRendering={false}
                            position={this.dxPopup_center_position}
                            onContentReady={this.dxPopup_fichaPersona_addPersona_onContentReady}
                            onShowing={this.dxPopup_fichaPersona_addPersona_onShowing}
                            onHiding={this.dxPopup_fichaPersona_addPersona_onHiding}
                        >
                            <Form
                                ref={this.dxForm_cambiosPersonal_new_REF}
                                labelLocation="left"
                                validationGroup="validacionPersona_nueva"
                                readOnly={true}
                            >
                                <GroupItem cssClass="ActionToast_dxForm_group" caption={"Datos personales"}>
                                    <SimpleItem
                                        dataField="emailEmpresa"
                                        name="emailEmpresa"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_emailEmpresa}
                                    />
                                    <SimpleItem
                                        dataField="nombre"
                                        name="nombre"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_nombre}
                                    />
                                    <SimpleItem
                                        dataField="apellidos"
                                        name="apellidos"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_apellidos}
                                    />
                                    <SimpleItem
                                        dataField="emailPersonal"
                                        name="email"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_emailPersonal}
                                    />
                                    <SimpleItem
                                        dataField="telefono"
                                        name="telefono"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_telefono}
                                    />
                                    <SimpleItem
                                        dataField="idTipoDocumentoIdentidad"
                                        name="idTipoDocumentoIdentidad"
                                        label={this.formLabel_idTipoDocumentoIdentidad}
                                        render={this.dxFormItem_nuevaPersona_render}
                                    />
                                    <SimpleItem
                                        dataField="numDocumentoIdentidad"
                                        name="numDocumentoIdentidad"
                                        label={this.formLabel_numDocumentoIdentidad}
                                        render={this.dxFormItem_nuevaPersona_render}
                                    />
                                    <SimpleItem
                                        dataField="isIBANSolicitado"
                                        name="isIBANSolicitado"
                                        label={this.formLabel_isIBANSolicitado}
                                        render={this.dxFormItem_nuevaPersona_render}
                                    />
                                </GroupItem>

                                <GroupItem cssClass="ActionToast_dxForm_group" caption={this.getTrad("datosLaborales")}>
                                    <SimpleItem render={this.renderAsociarLlamamiento} />
                                    <SimpleItem
                                        dataField="idLocalizacion"
                                        name="idLocalizacion"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idLocalizacion}
                                    />
                                    <SimpleItem
                                        dataField="idEmpresaPolarier"
                                        name="idEmpresaPolarier"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idEmpresaPolarier}
                                    />
                                    <SimpleItem
                                        dataField="idCentroLav"
                                        name="idCentroLav"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idCentroLav}
                                    />
                                    <SimpleItem
                                        dataField="idCategoriaConvenio"
                                        name="idCategoriaConvenio"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idCategoriaConvenio}
                                    />
                                    <SimpleItem
                                        dataField="idCategoriaInterna"
                                        name="idCategoriaInterna"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idCategoriaInterna}
                                    />
                                    <SimpleItem
                                        dataField="idAdmElementoPEP"
                                        name="idAdmElementoPEP"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idAdmElementoPEP}
                                    />
                                    <SimpleItem
                                        dataField="idAdmCentroCoste"
                                        name="idAdmCentroCoste"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idAdmCentroCoste}
                                    />
                                    <SimpleItem
                                        dataField="idTipoTrabajo"
                                        name="idTipoTrabajo"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idTipoTrabajo}
                                    />
                                    <SimpleItem
                                        dataField="idTurno"
                                        name="idTurno"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idTurno}
                                    />
                                    <SimpleItem
                                        dataField="horasDiarias"
                                        name="horasDiarias"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_horasDiarias}
                                    />
                                    <SimpleItem
                                        dataField="idFormatoDiasLibres"
                                        name="idFormatoDiasLibres"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idFormatoDiasLibres}
                                    />
                                    <SimpleItem
                                        dataField="selectorDiasLibres"
                                        name="selectorDiasLibres"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idFormatoDiasLibres}
                                    />
                                    <SimpleItem
                                        dataField="isFormPedidoAllowed"
                                        name="isFormPedidoAllowed"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_isFormPedidoAllowed}
                                    />
                                    <SimpleItem
                                        dataField="tblLavanderiaNUsuario"
                                        name="tblLavanderiaNUsuario"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_tblLavanderiaNUsuario}
                                    />
                                </GroupItem>

                                <GroupItem cssClass="ActionToast_dxForm_group" caption={"Contrato"}>
                                    <SimpleItem
                                        dataField="fechaAltaContrato"
                                        name="fechaAltaContrato"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_fechaAltaContrato}
                                    />
                                    <SimpleItem
                                        dataField="fechaBajaContrato"
                                        name="fechaBajaContrato"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_fechaBajaContrato}
                                    />
                                    <SimpleItem
                                        dataField="numDiasPeriodoPrueba"
                                        name="numDiasPeriodoPrueba"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_numDiasPeriodoPrueba}
                                    />
                                    <SimpleItem
                                        dataField="idTipoContrato"
                                        name="idTipoContrato"
                                        render={this.dxFormItem_nuevaPersona_render}
                                        label={this.formLabel_idTipoContrato}
                                    />
                                </GroupItem>
                            </Form>

                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("guardar")}
                                    type="success"
                                    onClick={this.dxButton_altaNuevaPersona_guardar_onClick}
                                />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("cancelar")}
                                    type="normal"
                                    onClick={this.dxButton_fichaPersona_cancelar_onClick}
                                />
                            </ToolbarItem_popup>
                        </Popup>
                        {/* ALTA GESTORIA */}
                        <PopupAltaGestoria
                            datasource_tblCorreoAltaGestoriaNCentroLav={this.datasource_tblCorreoAltaGestoriaNCentroLav}
                            datasource_tblCentroTrabajo_Lavanderia_todos={datasource_tblCentroTrabajo_Lavanderia_todos}
                            datasource_persona_enviarAltasGestoria={this.datasource_persona_enviarAltasGestoria}
                            context_persona={this.context_persona}
                            isVisible_dxPopup_enviarAltasGestoria={isVisible_dxPopup_enviarAltasGestoria}
                            isVisible_dxPopup_enviarAltasGestoria_loading={
                                isVisible_dxPopup_enviarAltasGestoria_loading
                            }
                            idAsuntoMailAltasGestorias={this.state.idAsuntoMailAltasGestorias}
                            personasSel_enviarAltasGestoria={personasSel_enviarAltasGestoria}
                            dxList_persona_listaGeneral_onContentReady={this.dxList_persona_listaGeneral_onContentReady}
                            loadPanel_hide={this.loadPanel_hide}
                            onValueChange={this.onValueChange}
                        />

                        {/* DOCUMENTOS NOMINAS */}
                        <Popup
                            showTitle={true}
                            title={"Título del archivo"}
                            visible={popup_nombrarNominas_visible}
                            showCloseButton={true}
                            closeOnOutsideClick={false}
                            width={350}
                            height={275}
                            onShown={this.onShown_popup_nombrarNominas}
                            onHiding={this.onHiding_popup_modificarNominas}
                        >
                            <Form
                                ref={this.dxForm_nombrarNominas_REF}
                                formData={formData_nombrarNominas}
                                labelLocation="top"
                                colCount={1}
                                height="100%"
                                width="100%"
                                validationGroup="dxForm_nombrarNominas_validationGroup"
                            >
                                <Item dataField="nombre" label={this.formLabel_nombrarNominas}>
                                    <TextBox
                                        ref={this.dxTextBox_nombre_nombrarNominas_REF}
                                        value={formData_nombrarNominas.nombre}
                                        onValueChanged={this.dxTextBox_nombrarNominas_onValueChanged}
                                    >
                                        <Validator validationGroup="dxForm_nombrarNominas_validationGroup">
                                            <RequiredRule />
                                        </Validator>
                                    </TextBox>
                                </Item>
                                <Item dataField="idCarpetaDocumentos" label={this.formLabel_idCarpetaDocumentos}>
                                    <SelectBox
                                        dxForm_dataField="idCarpetaDocumentos"
                                        dataSource={
                                            dataSource_documentos.length > 0 ? dataSource_documentos[0].items : []
                                        }
                                        value={formData_nombrarNominas.idCarpetaDocumentos}
                                        displayExpr="denominacion"
                                        valueExpr="idCarpetaDocumentos"
                                        onValueChanged={this.dxSelectBox_nombrarNominas_onValueChanged}
                                    >
                                        <Validator validationGroup="dxForm_nombrarNominas_validationGroup">
                                            <RequiredRule />
                                        </Validator>
                                    </SelectBox>
                                </Item>
                            </Form>
                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={this.getTrad("guardar")}
                                    type="success"
                                    onClick={this.dxButton_nombrarNominas_guardar_onClick}
                                />
                            </ToolbarItem_popup>
                        </Popup>
                        {/* GALERIA DE ARCHIVOS */}
                        <Popup
                            showTitle={true}
                            title={this.getTrad("añadirFoto")}
                            visible={isVisible_GaleriaArchivos}
                            showCloseButton={true}
                            closeOnOutsideClick={true}
                            width={"60%"}
                            minWidth={850}
                            height={"85%"}
                            onContentReady={this.dxPopup_añadirFoto_onContentReady}
                            onHiding={this.onHiding_popup_galeriaArchivos}
                        >
                            <Box direction="col" width="100%" height="100%">
                                <ItemBox ratio={1}>
                                    <Gallery
                                        items={
                                            archivoSel
                                                ? ["data:" + archivoSel.extension + ";base64," + archivoSel.documento]
                                                : []
                                        }
                                        showIndicator={false}
                                        showNavButtons={false}
                                        loop={false}
                                        height="100%"
                                        width="100%"
                                        stretchImages={false}
                                        noDataText={this.getTrad("sinFoto")}
                                    />
                                </ItemBox>
                                <ItemBox baseSize={50}>
                                    <div className="align-items-center he-100 d-flex pl-2">
                                        <Button
                                            width={60}
                                            hint="Rotar"
                                            icon="pulldown"
                                            onClick={this.dxButton_rotarImagen_onClick}
                                        />
                                    </div>
                                </ItemBox>
                                <ItemBox
                                    baseSize="auto"
                                    visible={
                                        dataField_archivoSel == "idFotoDocumentoIdentidad_A" ||
                                        dataField_archivoSel == "idFotoDocumentoIdentidad_B" ||
                                        dataField_archivoSel == "idFotoNAF" ||
                                        dataField_archivoSel == "idFotoIBAN"
                                    }
                                >
                                    <Form width="50%" cssClass="m-auto" labelLocation="left">
                                        <GroupItem cssClass="ActionToast_dxForm_group">
                                            <Item
                                                dataField={"numDocumentoIdentidad"}
                                                label={this.formLabel_numDocumentoIdentidad}
                                                visible={
                                                    dataField_archivoSel == "idFotoDocumentoIdentidad_A" ||
                                                    dataField_archivoSel == "idFotoDocumentoIdentidad_B"
                                                }
                                                render={this.dxFormItem_render}
                                            />
                                            <Item
                                                dataField={"caducidadDocumentoIdentidad"}
                                                label={this.formLabel_caducidadDocumentoIdentidad}
                                                visible={
                                                    dataField_archivoSel == "idFotoDocumentoIdentidad_A" ||
                                                    dataField_archivoSel == "idFotoDocumentoIdentidad_B"
                                                }
                                                render={this.dxFormItem_render}
                                            />
                                            <Item
                                                dataField={"NAF"}
                                                label={this.formLabel_NAF}
                                                visible={dataField_archivoSel == "idFotoNAF"}
                                                render={this.dxFormItem_render}
                                            />
                                            <Item
                                                dataField={"IBAN"}
                                                label={this.formLabel_IBAN}
                                                visible={dataField_archivoSel == "idFotoIBAN"}
                                                render={this.dxFormItem_render}
                                            />
                                        </GroupItem>
                                    </Form>
                                </ItemBox>
                                <ItemBox baseSize={40} />
                                <ItemBox baseSize={60}>
                                    <Box direction="row" width="100%" height="100%">
                                        <ItemBox ratio={1}>
                                            <FileUploader
                                                width="100%"
                                                labelText={this.getTrad("msg_arrastraArchivo5mb")}
                                                accept={"image/*"}
                                                minFileSize={1}
                                                uploadMode="instantly"
                                                uploadFailedMessage={this.getTrad("subidaFallida")}
                                                selectButtonText={this.getTrad("subirArchivo")}
                                                onUploadStarted={this.fotos_onUploadStarted}
                                            ></FileUploader>
                                        </ItemBox>
                                        <ItemBox baseSize={120}>
                                            <Button
                                                text={this.getTrad("aceptar")}
                                                type="success"
                                                onClick={this.dxPopup_galeriaFotos_aceptar_onClick}
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                            </Box>
                        </Popup>
                        {/* POPUP DE CONTRATOS DE SOLO LECTURA */}

                        <Popup
                            title={this.getTrad("contratos")}
                            showTitle={true}
                            visible={contratosPopup_isVisible}
                            width={"850px"}
                            height={"600px"}
                            position={this.dxPopup_center_position}
                            onHiding={this.hideContratos_popup}
                        >
                            {this.dxFormItem_render_tblPersonaNTipoContrato(false, this.state.contratosNPersonaSel)}
                        </Popup>
                        {/* LLAMAMIENTOS */}
                        <Popup
                            visible={dxPopup_llamamientos_isVisible}
                            onHiding={this.dxPopup_llamamientos_onHiding}
                            dragEnabled={false}
                            title={this.getTrad("incorporacionesActivas")}
                            showTitle={true}
                        >
                            <ToolbarItem_popup location={"after"} toolbar={"top"}>
                                <Button icon="refresh" onClick={this.dxPopup_llamamientos_refresh} />
                            </ToolbarItem_popup>
                            <div>
                                <AsignarLlamamientos
                                    ref={this.AsignarLlamamientos_REF}
                                    popupVisible={dxPopup_llamamientos_isVisible}
                                    isVistaGeneral={dxPopup_llamamientos_consulta}
                                    AsignarLlamamientos_onSelectionChanged={this.AsignarLlamamientos_onSelectionChanged}
                                    tblPersona={this.datasource_persona}
                                    tblLavanderia={datasource_tblLavanderiaNUsuario}
                                    tblTurno={datasource_tblTurno}
                                    tblCategoriaInterna={datasource_tblCategoriaInterna}
                                    tblTipoTrabajo={datasource_tblTipoTrabajo}
                                    tblFormatoDiasLibres={datasource_tblFormatoDiasLibres}
                                    tblLlamamiento={datasource_tblLlamamiento}
                                    tblTipoContrato={datasource_tblTipoContrato}
                                    tblMotivoBaja={datasource_tblMotivoBaja}
                                    dataSource_tblLlamamiento={this.dataSource_tblLlamamiento}
                                    refresh={this.dxPopup_llamamientos_refresh}
                                />
                            </div>
                        </Popup>
                        <Popover ref={this.dxPopover_REF} width={"auto"} height={"auto"}></Popover>

                        <LoadPanel_ProgressBar progress={progress} visible={isVisible_LoadPanel_ProgressBar} />
                    </div>
                </div>
                <NoDataPopup
                    visible={isVisible_noDataPopup}
                    texto={this.getTrad("usuarioSinCategorias")}
                    isRedirect={true}
                />
            </Fragment>
        );
    }

    onChange_selectorLavanderia = (selectedRowKeys) => {
        let personaSel = { ...this.state.personaSel };
        const index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        const value = selectedRowKeys.map((key) => {
            return { idLavanderia: key };
        });

        personaSel.tblLavanderiaNUsuario = value;

        this.setState({ personaSel });
        this.dxDataGrid_personas.cellValue(index, "tblLavanderiaNUsuario", value);
    };

    //#region Control visión tblLavanderiaNUsuario , tblAlmacenRecambiosNPersona y tblFormularioNUsuario (formulario pedidos)

    isVisible_isFormPedidoAllowed = () => {
        const { personaSel } = this.state;

        if (personaSel) {
            const { idCategoriaConvenio, idCategoriaInterna } = personaSel;

            const isLogisticos = this.categoriasLogisticos?.[idCategoriaConvenio]?.includes(idCategoriaInterna);

            return isLogisticos;
        }
        return false;
    };

    isVisible_selectorLavanderia = () => {
        const { personaSel } = this.state;

        if (personaSel) {
            const { idCategoriaConvenio, idCategoriaInterna } = personaSel;

            const isChofer = this.categoriasChofer?.[idCategoriaConvenio]?.includes(idCategoriaInterna);
            const isTecnico = this.categoriasTecnicos?.[idCategoriaConvenio]?.includes(idCategoriaInterna);
            const isLogisticos = this.categoriasLogisticos?.[idCategoriaConvenio]?.includes(idCategoriaInterna);

            return isChofer || isTecnico || isLogisticos;
        }
        return false;
    };

    categoriasChofer = {
        1: [
            //& Conductor
            1, //^ TRANSPORTISTA TIPO B
            2, //^ TRANSPORTISTA TIPO C
            33, //^ SUPERVISOR TRANSPORTE
        ],
        2: [
            //& Especialista
            36, //^ OPERARIO TRANSPORTE
        ],
        21: [
            //& Jefe de sección
            37, //^ ENCARGADO TRANSPORTE (RODI)
            15, //^ ENCARGADO TRANSPORTE
        ],
    };

    categoriasTecnicos = {
        5: [
            //& Oficial 2ª
            39, //^ JEFE EQUIPO MANTENIMIENTO
        ],
        23: [
            //& JEFE DE MANTENIMIENTO
            24, //^ JEFE DE MANTENIMIENTO
        ],
        24: [
            //& TÉCNICO DE MANTENIMIENTO
            26, //^ TECNICO DE MANTENIMIENTO
        ],
        26: [
            //& INGENIERO
            27, //^ JEFE DE MANTENIMIENTO
        ],
        27: [
            //& OFICIAL 1ª
            20, //^ TECNICO DE MANTENIMIENTO
        ],
        28: [
            //& AUXILIAR MANTENIMIENTO
            38, //^ OPERARIO MANTENIMIENTO
            34, //^ AUXILIAR MANTENIMIENTO
        ],
        29: [
            //& TECNICOS
            35, //^ TECNICO MANTENIMIENTO
        ],
    };

    categoriasLogisticos = {
        2: [
            //& Especialista
            5, //^ OPERARIO ALMACÉN
        ],
        4: [
            //& OFICIAL 1ª
            9, //^ ENCARGADO ALMACÉN
            10, //^ SUPERVISOR ALMACÉN
        ],
    };

    categoriasOperarios = {
        2: [
            //& Especialista
            3, //^ OPERARIO PRODUCCIÓN
            4, //^ OPERARIO PRODUCCIÓN - LAVADOR
        ],
        4: [
            //& OFICIAL 1ª
            8, //^ SUPERVISOR PRODUCCIÓN
            17, //^ JEFE DE EQUIPO PRODUCCIÓN
        ],
        5: [
            //& Oficial 2ª
            11, //^ JEFE DE EQUIPO PRODUCCIÓN
            12, //^ JEFE DE EQUIPO PRODUCCIÓN - LAVADOR
        ],
    };

    //#region GRID PERSONAS
    dxForm_personal_screenByWidth = function (width) {
        return width >= 1200 ? "xl" : width >= 992 ? "lg" : width >= 768 ? "md" : width >= 576 ? "sm" : "xs";
    };

    // #region OPTIONS Y PROPS
    AdjuntarArchivos_fichaPersona_allowedFileExtensions = [".jpg", ".jpeg", ".gif", ".png", ".pdf"];
    showClearButton_editorOptions = { showClearButton: true };
    dxDateBox_editorOptions = {
        displayFormat: "dd/MM/yyyy",
        min: "01/01/1900",
    };
    dxDateBox_personaNTipoContrato_editorOptions = {
        displayFormat: "dd/MM/yyyy",
        min: "01/01/1900",
    };
    dxNumberBox_periodoPrueba_editorOptions = {
        max: 9999,
    };
    colCountByScreen = {
        xl: 4,
        lg: 4,
        md: 3,
        sm: 2,
        xs: 1,
    };

    telefono_editorOptions = {
        maskInvalidMessage: this.getTrad("validation_Formato"),
        useMaskedValue: true,
    };
    numPagas_editorOptions = {
        step: 0,
        format: { style: "decimal", maximumFractionDigits: 0 },
        min: 0,
        max: 20,
    };
    percSegSocial_editorOptions = {
        step: 0,
        format: { style: "percent", maximumFractionDigits: 1 },
        min: 0,
        max: 1,
    };
    dxNumberBox_currency_editorOptions = {
        step: 0,
        format: { style: "currency", maximumFractionDigits: 2, currency: "EUR" },
        min: 0,
        max: 999999999999.99,
    };
    salarioBrutoAnual_editorOptions = {
        placeholder: 0,
        step: 0,
        format: { style: "currency", maximumFractionDigits: 2, currency: "EUR" },
        min: 0,
        max: 999999999999.99,
    };
    costeEmpresa_editorOptions = {
        placeholder: 0,
        step: 0,
        format: { style: "currency", maximumFractionDigits: 2, currency: "EUR" },
        min: 0,
        max: 999999999999.99,
    };
    adjuntarArchivos_container_elementAttr = {
        id: "adjuntarArchivos_container_personal",
    };
    dxDataGrid_tblPersonaNTipoContrato_elementAttr = {
        id: "dxDataGrid_tblPersonaNTipoContrato",
    };
    dxDataGrid_Popup_Edit_elementAttr = {
        id: "dxDataGrid_Popup_Edit",
    };
    horasDiarias_editorOptions = { mask: "00:00", useMaskedValue: true };
    dxTabPanel_fichaPersona_elementAttr = {
        id: "dxTabPanel_fichaPersona",
        class: "h4",
    };
    dxPopup_center_position = { at: "center", of: window };
    numAños_label = { text: this.getTrad("numAños") };
    decimal_format = { style: "decimal", maximumFractionDigits: 0 };
    scrolling_infinite = { mode: "infinite" };
    dxNumberBox_placeholder_editorOptions = { placeholder: "0" };
    label_turno = { text: this.getTrad("turno") };
    dxTextArea_editorOptions = {
        height: "80px",
    };
    dxList_persona_listaGeneral_searchExpr = ["nombre", "apellidos"];
    tblPersona_allowedPageSizes = [20, 50, 100];

    circular_tileView = {
        class: "circular_tileView",
    };
    dxTileViewItems_size = {
        fotoPerfil: {
            width: 100,
            itemMargin: 0,
        },
        fotos: {
            width: 200,
            itemMargin: 0,
        },
    };

    elemAttr_tabDocumentos_Padre_personal = {
        id: "tabDocumentos_Padre_personal",
    };

    elemAttr_tabCalendarioLaboral_Padre_personal = {
        id: "tabCalendarioLaboral_Padre_personal",
    };
    template_tile_documento_elemAttr = {
        class: "dxToolbar_opciones dxToolbar_position",
    };

    formLabel_idFotoPerfil = {
        text: this.getTrad("fotoPerfil"),
        visible: false,
        icon: "icon_face_recognition",
    };
    formLabel_activo = { visible: false };
    formLabel_nombre = {
        text: this.getTrad("nombre"),
        visible: false,
        icon: "icon_Rendimiento",
    };
    formLabel_apellidos = {
        text: this.getTrad("apellidos"),
        visible: false,
        icon: "icon_account_details_outline",
    };
    formLabel_email = {
        text: this.getTrad("email"),
        visible: false,
        icon: "icon_email_outline",
    };
    formLabel_telefono = {
        text: this.getTrad("telefonoPersonal"),
        visible: false,
        icon: "icon_Telefono",
    };
    formLabel_telefonoEmpresa = {
        text: this.getTrad("telefonoEmpresa"),
        visible: false,
        icon: "icon_Telefono",
    };
    formLabel_codigoGestoria = {
        text: this.getTrad("codigoGestoria"),
        visible: false,
        icon: "icon_mailbox_open_outline",
    };
    formLabel_calle = {
        text: this.getTrad("calle"),
        visible: false,
        icon: "inf_icons_RutasExpedicion",
    };
    formLabel_numDomicilio = {
        text: this.getTrad("numDomicilio"),
        visible: false,
        icon: "icon_numeric",
    };
    formLabel_piso = {
        text: this.getTrad("piso"),
        visible: false,
        icon: "icon_format_list_number_outline",
    };
    formLabel_puerta = {
        text: this.getTrad("puerta"),
        visible: false,
        icon: "icon_door_outline",
    };
    formLabel_codigoPostal = {
        text: this.getTrad("codigoPostal"),
        visible: false,
        icon: "icon_mailbox_open_outline",
    };
    formLabel_localidad = {
        text: this.getTrad("localidad"),
        visible: false,
        icon: "icon_city_variant_outline",
    };
    formLabel_nacionalidad = {
        text: this.getTrad("nacionalidad"),
        visible: false,
        icon: "icon_map_outline",
    };
    formLabel_idComunidadAutonoma = {
        text: this.getTrad("comunidadAutonoma"),
        visible: false,
        icon: "icon_map_outline",
    };
    formLabel_idPais = {
        text: this.getTrad("pais"),
        visible: false,
        icon: "icon_flag_variant_outline",
    };
    formLabel_idTipoDocumentoIdentidad = {
        text: this.getTrad("tipoDocumento"),
        visible: false,
        icon: "icon_card_account_details_outline",
    };
    formLabel_numDocumentoIdentidad = {
        text: this.getTrad("numDocumento"),
        visible: false,
        icon: "icon_numeric",
    };
    formLabel_isIBANSolicitado = {
        text: "Solicitud de IBAN" /* this.getTrad("numDocumento") */,
        visible: false,
        icon: "icon_credit_card_outline",
    };
    formLabel_caducidadDocumentoIdentidad = {
        text: this.getTrad("caducidad"),
        visible: false,
        icon: "icon_CalendarioMes",
    };
    formLabel_idFotoDocumentoIdentidad_A = {
        text: this.getTrad("fotoCaraDelantera"),
        visible: false,
        icon: "icon_badge_account_horizontal_outline",
    };
    formLabel_idFotoDocumentoIdentidad_B = {
        text: this.getTrad("fotoCaraTrasera"),
        visible: false,
        icon: "icon_badge_account_horizontal_outline",
    };
    formLabel_idFotoNAF = {
        text: this.getTrad("fotoNAF"),
        visible: false,
        icon: "icon_id_card",
    };
    formLabel_idFotoIBAN = {
        text: this.getTrad("fotoIBAN"),
        visible: false,
        icon: "icon_credit_card_outline",
    };
    formLabel_NAF = {
        text: this.getTrad("NAF"),
        visible: false,
        icon: "icon_id_card",
    };
    formLabel_IBAN = {
        text: this.getTrad("IBAN"),
        visible: false,
        icon: "icon_credit_card_outline",
    };
    formLabel_idGenero = {
        text: this.getTrad("genero"),
        visible: false,
        icon: "icon_gender_male_female_variant",
    };
    formLabel_fechaNacimiento = {
        text: this.getTrad("fechaNacimiento"),
        visible: false,
        icon: "icon_cake",
    };
    formLabel_idEstadoCivil = {
        text: this.getTrad("estadoCivil"),
        visible: false,
        icon: "inf_icons_RRHH",
    };
    formLabel_numHijos = {
        text: this.getTrad("numHijos"),
        visible: false,
        icon: "icon_account_child_outline",
    };
    formLabel_idNivelEstudios = {
        text: this.getTrad("nivelEstudios"),
        visible: false,
        icon: "icon_book_account_outline",
    };
    formLabel_idDiscapacidad = {
        text: this.getTrad("discapacidad"),
        visible: false,
        icon: "icon_book_account_outline",
    };
    formLabel_idTallaAlfa_Camiseta = {
        text: this.getTrad("tallaCamiseta"),
        visible: false,
        icon: "menu_icons_Valet",
    };
    formLabel_tallaPantalon = {
        text: this.getTrad("tallaPantalon"),
        visible: false,
        icon: "icon_numeric",
    };
    formLabel_emailEmpresa = {
        text: this.getTrad("emailEmpresa"),
        visible: false,
        icon: "icon_email_outline",
    };
    formLabel_emailPersonal = {
        text: this.getTrad("emailPersonal"),
        visible: false,
        icon: "icon_email_outline",
    };
    formLabel_idLocalizacion = {
        text: this.getTrad("localizacion"),
        visible: false,
        icon: "icon_earth",
    };
    formLabel_idEmpresaPolarier = {
        text: this.getTrad("empresa"),
        visible: false,
        icon: "icon_office_building",
    };
    formLabel_idCentroLav = {
        text: this.getTrad("centro"),
        visible: false,
        icon: "icon_city_variant_outline",
    };
    formLabel_idCategoriaConvenio = {
        text: this.getTrad("categoriaConvenio_abv"),
        visible: false,
        icon: "inf_icons_RRHH",
    };
    formLabel_idCategoriaInterna = {
        text: this.getTrad("categoriaInterna_abv"),
        visible: false,
        icon: "inf_icons_RRHH",
    };
    formLabel_idTipoTrabajo = {
        text: this.getTrad("tipoTrabajo"),
        visible: false,
        icon: "icon_ManoObra",
    };
    formLabel_idAdmElementoPEP = {
        text: this.getTrad("elementoPep"),
        visible: false,
        icon: "icon_Entidad",
    };
    formLabel_idAdmCentroCoste = {
        text: this.getTrad("centroCoste"),
        visible: false,
        icon: "icon_Entidad",
    };
    formLabel_idAdmCuentaContable_Salario = {
        text: this.getTrad("cuentaContableSalario"),
        visible: false,
        icon: "icon_account_details_outline",
    };
    formLabel_idAdmCuentaContable_SSEmpresa = {
        text: this.getTrad("cuentaContableSSEmpresa"),
        visible: false,
        icon: "icon_account_details_outline",
    };
    formLabel_idTurno = {
        text: this.getTrad("turno"),
        visible: false,
        icon: "icon_ManoObra",
    };
    formLabel_horasDiarias = {
        text: this.getTrad("horas"),
        visible: false,
        icon: "icon_timer",
    };
    // TODO: Añadir traduccion
    formLabel_idFormatoDiasLibres = {
        text: "Formato días libres",
        visible: false,
        icon: "icon_CalendarioMes",
    };
    formLabel_selectorDiasLibres = {
        text: "Días libres",
        visible: false,
        icon: "icon_CalendarioMes",
    };

    formLabel_isFormPedidoAllowed = {
        text: "Formulario Pedidos",
        visible: false,
        icon: "icon_Cuaderno",
    };
    formLabel_tblLavanderiaNUsuario = { visible: false, isVisible: false };

    formLabel_contratos = {
        text: this.getTrad("contratos"),
        visible: false,
        icon: "",
    };
    formLabel_fechaAltaContrato = {
        text: this.getTrad("fechaAlta"),
        visible: false,
        icon: "icon_sort-calendar-descending",
    };
    formLabel_fechaBajaContrato = {
        text: this.getTrad("fechaBaja"),
        visible: false,
        icon: "icon_sort-calendar-ascending",
    };
    formLabel_numDiasPeriodoPrueba = {
        text: this.getTrad("periodoPruebaDias"),
        visible: false,
        icon: "icon_numeric",
    };
    formLabel_idTipoContrato = {
        text: this.getTrad("tipoContrato"),
        visible: false,
        icon: "inf_icons_PartesTrabajo",
    };
    formLabel_observaciones = {
        text: this.getTrad("observaciones"),
        visible: false,
        icon: "icon_Comment",
    };
    formLabel_observacionesLaborales = {
        text: this.getTrad("observaciones"),
        visible: false,
        icon: "icon_Comment",
    };

    // TODO: añadir traduccion
    formLabel_idUsuario_validacion_nomina = {
        text: "Responsable nómina",
        visible: false,
        icon: "icon_account_details_outline",
    };

    formLabel_salarioBrutoMensual = {
        text: getTrad("salarioBrutoMensual"),
        visible: false,
        icon: "inf_icons_Facturacion",
    };

    formLabel_tblDatosSalariales_fechaAntiguedad = {
        text: this.getTrad("fechaAntiguedad"),
        visible: false,
        icon: "icon_sort-calendar-descending",
    };
    formLabel_tblDatosSalariales_isTrienio = {
        text: "isTrienio",
        visible: false,
        icon: "icon_numeric",
    };
    formLabel_numAños = {
        text: this.getTrad("numAños"),
        visible: false,
        icon: "icon_numeric",
    };
    formLabel_numTrienios = {
        text: this.getTrad("numTrienios"),
        visible: false,
        icon: "inf_icons_PartesTrabajo",
    };
    formLabel_numQuinquenios = {
        text: "Nº Quinquenios",
        visible: false,
        icon: "inf_icons_PartesTrabajo",
    };
    formLabel_tblDatosSalariales_salarioBase = {
        text: "Salario base",
        visible: false,
        icon: "icon_Comment",
    };
    formLabel_tblDatosSalariales_plusAsistencia = {
        text: this.getTrad("plusAsistencia"),
        visible: false,
        icon: "icon_sort-calendar-ascending",
    };
    formLabel_tblDatosSalariales_plusResponsabilidad = {
        text: this.getTrad("plusResponsabilidad"),
        visible: false,
        icon: "icon_numeric",
    };
    formLabel_tblDatosSalariales_plusPeligrosidad = {
        text: this.getTrad("plusPeligrosidad"),
        visible: false,
        icon: "icon_numeric",
    };
    formLabel_tblDatosSalariales_incentivo = {
        text: this.getTrad("incentivo"),
        visible: false,
        icon: "inf_icons_PartesTrabajo",
    };
    formLabel_tblDatosSalariales_impHoraExtra = {
        text: this.getTrad("impHoraExtra"),
        visible: false,
        icon: "inf_icons_PartesTrabajo",
    };
    formLabel_tblDatosSalariales_plusProductividad = {
        text: this.getTrad("plusProductividad"),
        visible: false,
        icon: "inf_icons_PartesTrabajo",
    };
    formLabel_tblDatosSalariales_percSegSocial = {
        text: this.getTrad("percSegSocial"),
        visible: false,
        icon: "icon_Comment",
    };
    formLabel_tblDatosSalariales_acuerdoNC = {
        text: this.getTrad("acuerdoNC"),
        visible: false,
        icon: "icon_Comment",
    };
    formLabel_tblDatosSalariales_salarioEspecie = {
        text: this.getTrad("salarioEnEspecie"),
        visible: false,
        icon: "icon_Comment",
    };
    formLabel_salarioBrutoAnual = {
        text: this.getTrad("salarioBrutoAnual"),
        visible: false,
        icon: "icon_Comment",
    };
    formLabel_costeEmpresa = {
        text: this.getTrad("costeEmpresa"),
        visible: false,
        icon: "icon_Comment",
    };
    formLabel_tblDatosSalariales_numPagas = {
        text: this.getTrad("numPagas"),
        visible: false,
        icon: "icon_Comment",
    };
    formLabel_nombrarNominas = { text: this.getTrad("nombre") };
    formLabel_idCarpetaDocumentos = { text: this.getTrad("carpetaDocumentos") };
    // #endregion

    dxLookup_centroTrabajoLav_groupRender(item) {
        return <div>{this.getTrad("carpetas").toUpperCase()}</div>;
    }

    dxLookup_onContenReady(e) {
        let popup = e.component._popup;
        popup.option("showTitle", false);
        popup.content().css("top", 0);
    }

    dxFormEditor_onContentReady(e) {
        $(e.element).find("input").css({
            "text-align": "end",
            "font-weight": 500,
        });

        setTimeout(() => {
            $(e.element).find(".dx-texteditor-input").css({
                "text-align": "end",
                "font-weight": 500,
            });
        }, 0);

        setTimeout(() => {
            $(e.element).find(".dx-selectbox-container .dx-texteditor-input").css({
                "font-weight": 500,
                "text-align": "start",
            });
        }, 0);
    }

    dxFormEditor_nuevaPersona_onValueChanged(e) {
        let dataField = e.component.option("name");
        let personaSel = { ...this.state.personaSel };
        personaSel[dataField] = e.value;

        if (dataField === "idCentroLav" && personaSel.idCentroLav != null) {
            let parsed = JSON.parse(personaSel.idCentroLav);
            let idLavanderia = parsed[1] > 0 ? parsed[1] : null;
            personaSel.idTipoTrabajo = idLavanderia == null ? null : personaSel.idTipoTrabajo;
            personaSel.idTurno = idLavanderia == null ? null : personaSel.idTurno;
            personaSel.horasDiarias = idLavanderia == null ? null : personaSel.horasDiarias;

            if (idLavanderia == null) {
                personaSel.horasDiarias = null;
                personaSel.idTipoTrabajo = null;
            }

            let nuevo_parsed = JSON.parse(e.value);
            let nuevo_idLavanderia = nuevo_parsed[1] > 0 ? nuevo_parsed[1] : null;

            let antiguo_parsed = e.previousValue != null ? JSON.parse(e.previousValue) : null;
            let antiguo_idLavanderia =
                antiguo_parsed != null ? (antiguo_parsed[1] > 0 ? antiguo_parsed[1] : null) : null;
            let antiguo_idCentro = antiguo_parsed != null ? (antiguo_parsed[0] > 0 ? antiguo_parsed[0] : null) : null;

            if (antiguo_idCentro == null && antiguo_idLavanderia == null) {
                //1era modificacion
                setTimeout(() => {
                    this.setState({ personaSel }, () => {
                        this.datasource_tblCategoriaConvenio.reload();
                        this.datasource_tblCategoriaInterna.reload();
                    });
                }, 0);
            }
            if (
                (antiguo_idLavanderia != null && nuevo_idLavanderia == null) ||
                (antiguo_idLavanderia == null && nuevo_idLavanderia != null)
            ) {
                //Si se cambia entre oficina y lavandería
                personaSel.idCategoriaInterna = null;
                personaSel.idCategoriaConvenio = null;

                personaSel.idAdmCentroCoste = null;
                personaSel.idAdmElementoPEP = null;

                setTimeout(() => {
                    this.setState({ personaSel }, () => {
                        this.datasource_tblCategoriaConvenio.reload();
                        this.datasource_tblCategoriaInterna.reload();
                    });
                }, 0);
            }

            if (nuevo_idLavanderia != null) {
                const lavanderia = this.state.datasource_tblLavanderiaNUsuario.find(
                    (lav) => lav.idLavanderia == nuevo_idLavanderia
                );

                personaSel.idAdmElementoPEP = lavanderia?.idAdmElementoPEP;
            }
        } else if (dataField === "idLocalizacion") {
            let dataSource = e.component.getDataSource().items();
            let localizacionSel = $.grep(dataSource, function (item) {
                return item.idLocalizacion == e.value;
            });

            let localizacionSel_anterior = $.grep(dataSource, function (item) {
                return item.idLocalizacion == personaSel.idPais_localizacion;
            });

            let idPais_localizacion_anterior =
                localizacionSel_anterior.length > 0 ? localizacionSel_anterior[0].idPais : null;
            let idPais_localizacion = localizacionSel.length > 0 ? localizacionSel[0].idPais : null;

            if (idPais_localizacion_anterior != idPais_localizacion) {
                personaSel.idCategoriaInterna = null;
                personaSel.idCategoriaConvenio = null;
            }

            personaSel.idPais_localizacion = idPais_localizacion;

            setTimeout(() => {
                this.setState({ personaSel }, () => {
                    this.datasource_tblCategoriaConvenio.reload();
                    this.datasource_tblCategoriaInterna.reload();
                });
            }, 0);
        } else if (dataField == "idCategoriaConvenio") {
            personaSel.idCategoriaInterna = null;
            personaSel.idCategoriaConvenio = e.value;

            setTimeout(() => {
                this.setState({ personaSel }, () => {
                    this.datasource_tblCategoriaInterna.reload();
                });
            }, 0);
        } else if (dataField == "idCategoriaInterna") {
            personaSel.tblDatosSalariales = e.component.option("selectedItem");
            personaSel.tblDatosSalariales = {
                ...personaSel.tblDatosSalariales,
                numPagas: 12,
                isTrienio: false,
            };

            let catSel = $.grep(
                $.extend(true, [], this.state.datasource_tblCategoriaInterna_lookupGrid),
                function (item) {
                    return item.idCategoriaInterna === e.value;
                }
            );

            let idsTurnosNCategoria = [];
            if (catSel.length > 0) {
                idsTurnosNCategoria = $.map(catSel[0].tblCategoriaInternaNTurno, function (item) {
                    return item.idTurno;
                });
            }

            this.setState({
                personaSel: personaSel,
                idsSubturnosVisibles: idsTurnosNCategoria,
            });

            let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
            this.dxDataGrid_personas.cellValue(index, "tblDatosSalariales", personaSel.tblDatosSalariales);
            this.dxDataGrid_personas.cellValue(index, dataField, e.value);
        }

        if (dataField === "fechaAltaContrato") {
            personaSel.tblDatosSalariales = {
                ...personaSel.tblDatosSalariales,
                fechaAntiguedad: e.value,
            };

            let date = null;
            if (e.value) date = new Date(new Date(e.value).setDate(new Date(e.value).getDate()));

            this.setState({ minFechaBaja_altaPersona: date, personaSel: personaSel });
        } else if (dataField === "fechaBajaContrato") {
            let date = null;
            if (e.value) date = new Date(new Date(e.value).setDate(new Date(e.value).getDate()));
            this.setState({ maxFechaAlta_altaPersona: date, personaSel: personaSel });
        } else if (dataField === "idEmpresaPolarier") {
            let datasource_tblCentroTrabajo_Lavanderia = this.getCentrosEmpresa(personaSel.idEmpresaPolarier);

            personaSel.idAdmElementoPEP = null;
            personaSel.idAdmCentroCoste = null;

            this.setState({
                personaSel,
                datasource_tblCentroTrabajo_Lavanderia,
            });
        } else {
            this.setState({
                personaSel: personaSel,
            });
        }
    }

    dxFormEditor_nuevaPersona_telefono_onValueChanged(value, data) {
        let prefijoTelefonico = data.dialCode;
        let telefono = value.slice(data.dialCode.length).replace(/\s/g, "");
        let personaSel = { ...this.state.personaSel };

        personaSel.prefijoTelefonico = prefijoTelefonico;
        personaSel.telefono = telefono;

        let isValid_telefono = personaSel.telefono && personaSel.telefono.length > 6;
        if (isValid_telefono)
            this.setState({
                personaSel: personaSel,
                validationError_telefono: !isValid_telefono,
            });
        else this.setState({ personaSel: personaSel });
    }

    diasLibres_modify_onValueChanged(diasLibres) {
        let personaSel = { ...this.state.personaSel };
        let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        this.dxDataGrid_personas.cellValue(index, "tblDiasLibresPersonal", diasLibres);

        personaSel.tblDiasLibresPersonal = diasLibres;

        this.setState({ personaSel: personaSel });
    }

    diasLibres_onValidChange(isValid) {
        this.setState({ isValid_diasLibres: isValid });
    }

    dxFormEditor_telefono_onValueChanged(value, data, key = "telefono") {
        let keyPrefijo = key === "telefono" ? "prefijoTelefonico" : "prefijoTelefonicoEmpresa";
        let prefijoTelefonico = data.dialCode;
        let valTelefono = value.slice(data.dialCode.length).replace(/\s/g, "");
        let personaSel = { ...this.state.personaSel };

        personaSel[keyPrefijo] = prefijoTelefonico;
        personaSel[key] = valTelefono;

        let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        this.dxDataGrid_personas.cellValue(index, key, valTelefono);
        this.dxDataGrid_personas.cellValue(index, keyPrefijo, prefijoTelefonico);
        if (personaSel) {
            personaSel[key] = valTelefono;
            personaSel[keyPrefijo] = prefijoTelefonico;

            let isValid_telefono = personaSel[key] && personaSel[key].length > 6;
            if (isValid_telefono)
                this.setState({
                    personaSel: personaSel,
                    validationError_telefono: !isValid_telefono,
                });
            else this.setState({ personaSel: personaSel });
        }
    }

    dxFormEditor_telefonoPersonal_onValueChanged = (value, data) => {
        this.dxFormEditor_telefono_onValueChanged(value, data, "telefono");
    };

    dxFormEditor_telefonoEmpresa_onValueChanged = (value, data) => {
        this.dxFormEditor_telefono_onValueChanged(value, data, "telefonoEmpresa");
    };

    // #region Funcion cambia valores de ficha persona
    dxFormEditor_onValueChanged(e) {
        let dataField = e.component.option("name");
        let personaSel = { ...this.state.personaSel };
        let { user } = this.props;

        if (dataField === "isFormPedidoAllowed") {
            let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;

            personaSel.isFormPedidoAllowed = e.value;

            this.setState({ personaSel });

            this.dxDataGrid_personas.cellValue(index, dataField, e.value);
        } else if (dataField === "idLocalizacion") {
            let dataSource = e.component.getDataSource().items();
            let localizacionSel = $.grep(dataSource, function (item) {
                return item.idLocalizacion == e.value;
            });

            let localizacionSel_anterior = $.grep(dataSource, function (item) {
                return item.idLocalizacion == personaSel.idPais_localizacion;
            });

            let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
            this.dxDataGrid_personas.cellValue(index, "activo", personaSel.activo);

            let idPais_localizacion_anterior =
                localizacionSel_anterior.length > 0 ? localizacionSel_anterior[0].idPais : null;
            let idPais_localizacion = localizacionSel.length > 0 ? localizacionSel[0].idPais : null;

            if (idPais_localizacion_anterior != idPais_localizacion) {
                personaSel.idCategoriaInterna = null;
                personaSel.idCategoriaConvenio = null;
            }

            personaSel.idPais_localizacion = idPais_localizacion;

            setTimeout(() => {
                this.setState({ personaSel }, () => {
                    this.datasource_tblCategoriaConvenio.reload();
                    this.datasource_tblCategoriaInterna.reload();
                });
            }, 0);
        } else if (dataField == "idCategoriaConvenio") {
            personaSel.idCategoriaInterna = null;
            personaSel.idCategoriaConvenio = e.value;

            setTimeout(() => {
                this.setState({ personaSel }, () => {
                    this.datasource_tblCategoriaInterna.reload();
                });
            }, 0);
        } else if (dataField == "idCategoriaInterna") {
            let _this = this;

            let parsed = JSON.parse(personaSel.idCentroLav);
            let idCentroTrabajo = parsed[0] > 0 ? parsed[0] : null;
            if (idCentroTrabajo == null) {
                if (
                    user.enableDatosRRHH &&
                    (personaSel.tblDatosSalariales == null || $.isEmptyObject(personaSel.tblDatosSalariales))
                )
                    sobreescribirDatosSalariales(this);
                else {
                    dxMensajePregunta(_this.getTrad("alerta_sobreescribirDatosSalariales_catInterna"), [
                        [
                            _this.getTrad("actualizarDatos"),
                            function () {
                                sobreescribirDatosSalariales(_this);
                            },
                            "danger",
                        ],
                        [
                            _this.getTrad("mantenerDatos"),
                            function () {
                                mantenerDatos(_this);
                            },
                        ],
                    ]);
                }
            } else {
                mantenerDatos(_this);
            }

            function sobreescribirDatosSalariales(_this) {
                if (personaSel.tblDatosSalariales != null && !$.isEmptyObject(personaSel.tblDatosSalariales)) {
                    $.each(personaSel.tblDatosSalariales, function (index, item) {
                        $.each($.extend(true, {}, e.component.option("selectedItem")), function (iSelItem, selItem) {
                            if (index == iSelItem) {
                                personaSel.tblDatosSalariales[index] = selItem;
                            }
                        });
                    });
                } else {
                    personaSel.tblDatosSalariales = $.extend(true, {}, e.component.option("selectedItem"));
                }

                _this.setState({ personaSel });

                let index = personaSel ? _this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                _this.dxDataGrid_personas.cellValue(index, "tblDatosSalariales", personaSel.tblDatosSalariales);
                _this.dxDataGrid_personas.cellValue(index, "idCategoriaInterna", e.value);
            }

            function mantenerDatos(_this) {
                let index = personaSel ? _this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                _this.dxDataGrid_personas.cellValue(index, "idCategoriaInterna", e.value);
            }

            let catSel = $.grep(
                $.extend(true, [], _this.state.datasource_tblCategoriaInterna_lookupGrid),
                function (item) {
                    return item.idCategoriaInterna === e.value;
                }
            );

            let idsTurnosNCategoria = [];
            if (catSel.length > 0) {
                idsTurnosNCategoria = $.map(catSel[0].tblCategoriaInternaNTurno, function (item) {
                    return item.idTurno;
                });
            }

            this.setState({ idsSubturnosVisibles: idsTurnosNCategoria });
        } else if (dataField === "idTurno") {
            personaSel.idTurno = e.value;

            let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
            if (e.value == null) {
                personaSel.horasDiarias = null;
                personaSel.idTipoTrabajo = null;
                personaSel.horasDiarias = null;
            }

            this.setState({
                personaSel: personaSel,
            });

            this.dxDataGrid_personas.cellValue(index, dataField, e.value);
        } else if (dataField === "idFormatoDiasLibres") {
            personaSel.idFormatoDiasLibres = e.value;

            let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
            this.setState({ personaSel: personaSel });

            this.dxDataGrid_personas.cellValue(index, dataField, e.value);
        } else {
            let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
            if (dataField === "idCentroLav") {
                let parsed = JSON.parse(personaSel.idCentroLav);
                let idLavanderia = parsed[1] > 0 ? parsed[1] : null;

                let nuevo_parsed = JSON.parse(e.value);
                let nuevo_idLavanderia = nuevo_parsed[1] > 0 ? nuevo_parsed[1] : null;
                let nuevo_idCentroTrabajo = nuevo_parsed[0] > 0 ? nuevo_parsed[0] : null;

                if (
                    (idLavanderia != null && nuevo_idLavanderia == null) ||
                    (idLavanderia == null && nuevo_idLavanderia != null)
                ) {
                    //Si se cambia entre oficina y lavandería
                    personaSel.idCategoriaInterna = null;
                    personaSel.idCategoriaConvenio = null;

                    personaSel.idTipoTrabajo = nuevo_idLavanderia == null ? null : personaSel.idTipoTrabajo;
                    personaSel.idTurno = nuevo_idLavanderia == null ? null : personaSel.idTurno;
                    personaSel.horasDiarias = nuevo_idLavanderia == null ? null : personaSel.horasDiarias;
                    personaSel.idAdmCuentaContable_Salario =
                        nuevo_idLavanderia == null ? null : personaSel.idAdmCuentaContable_Salario;
                    personaSel.idAdmCuentaContable_SSEmpresa =
                        nuevo_idLavanderia == null ? null : personaSel.idAdmCuentaContable_SSEmpresa;

                    this.dxDataGrid_personas.cellValue(index, "idTipoTrabajo", personaSel.idTipoTrabajo);
                    this.dxDataGrid_personas.cellValue(index, "idTurno", personaSel.idTurno);
                    this.dxDataGrid_personas.cellValue(index, "horasDiarias", personaSel.horasDiarias);
                    this.dxDataGrid_personas.cellValue(
                        index,
                        "idAdmCuentaContable_Salario",
                        personaSel.idAdmCuentaContable_Salario
                    );
                    this.dxDataGrid_personas.cellValue(
                        index,
                        "idAdmCuentaContable_SSEmpresa",
                        personaSel.idAdmCuentaContable_SSEmpresa
                    );

                    personaSel.idAdmCentroCoste = null;
                    personaSel.idAdmElementoPEP = null;
                    this.dxDataGrid_personas.cellValue(index, "idAdmCentroCoste", personaSel.idAdmCentroCoste);
                    this.dxDataGrid_personas.cellValue(index, "idAdmElementoPEP", personaSel.idAdmElementoPEP);

                    setTimeout(() => {
                        this.setState({ personaSel }, () => {
                            this.datasource_tblCategoriaConvenio.reload();
                            this.datasource_tblCategoriaInterna.reload();
                        });
                    }, 0);
                }

                if (nuevo_idLavanderia != null) {
                    const lavanderia = this.state.datasource_tblLavanderiaNUsuario.find(
                        (lav) => lav.idLavanderia == nuevo_idLavanderia
                    );

                    personaSel.idAdmCentroCoste = null;
                    personaSel.idAdmElementoPEP = lavanderia?.idAdmElementoPEP;

                    this.dxDataGrid_personas.cellValue(index, "idAdmCentroCoste", personaSel.idAdmCentroCoste);
                    this.dxDataGrid_personas.cellValue(index, "idAdmElementoPEP", personaSel.idAdmElementoPEP);
                }

                if (nuevo_idCentroTrabajo != null) {
                    const centroLav = this.state.datasource_tblCentroTrabajo_Lavanderia_todos.find(
                        (cl) => cl.idCentroLav === e.value
                    );

                    personaSel.idAdmCuentaContable_Salario = centroLav?.idAdmCuentaContable_Salario;
                    personaSel.idAdmCuentaContable_SSEmpresa = centroLav?.idAdmCuentaContable_SSEmpresa;

                    this.dxDataGrid_personas.cellValue(
                        index,
                        "idAdmCuentaContable_Salario",
                        personaSel.idAdmCuentaContable_Salario
                    );
                    this.dxDataGrid_personas.cellValue(
                        index,
                        "idAdmCuentaContable_SSEmpresa",
                        personaSel.idAdmCuentaContable_SSEmpresa
                    );
                }
            }

            this.dxDataGrid_personas.cellValue(index, dataField, e.value);
        }

        if (personaSel) {
            if (dataField === "idTipoTrabajo") {
                const tipoTrabajo = this.state.datasource_tblTipoTrabajo?.find((tt) => tt.idTipoTrabajo == e.value);

                personaSel.idAdmCuentaContable_Salario = tipoTrabajo?.idAdmCuentaContable_Salario;
                personaSel.idAdmCuentaContable_SSEmpresa = tipoTrabajo?.idAdmCuentaContable_SSEmpresa;

                let index =
                    personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                this.dxDataGrid_personas.cellValue(
                    index,
                    "idAdmCuentaContable_Salario",
                    personaSel.idAdmCuentaContable_Salario
                );
                this.dxDataGrid_personas.cellValue(
                    index,
                    "idAdmCuentaContable_SSEmpresa",
                    personaSel.idAdmCuentaContable_SSEmpresa
                );

                if (personaSel.tblCuadrantePersonal[0] && personaSel.tblCuadrantePersonal[0] > Date.now()) {
                    dxMensajePregunta(
                        "Esta persona tiene cuadrantes fijados, si cambias el tipo de trabajo se eliminarán.",
                        [
                            [this.getTrad("aceptar"), () => {}, "danger"],
                            [
                                this.getTrad("cancelar"),
                                () => {
                                    personaSel[dataField] = e.previousValue;

                                    let index =
                                        personaSel.idPersona > 0
                                            ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona)
                                            : 0;
                                    this.setState({ personaSel: personaSel });

                                    this.dxDataGrid_personas.cellValue(index, dataField, e.previousValue);
                                },
                            ],
                        ]
                    );
                }
            }

            if (dataField.includes(".")) {
                let campo = dataField.split(".");
                if (personaSel[campo[0]]) personaSel[campo[0]][campo[1]] = e.value;
            } else personaSel[dataField] = e.value;

            if (dataField === "tblDatosSalariales.fechaAntiguedad") {
                let añosTrienio = personaSel.tblDatosSalariales.isTrienio == 1 ? 3 : 5;
                let numAños = new Date(new Date().getTime() - e.value.getTime()).getUTCFullYear() - 1970;
                personaSel.numAños = numAños;
                personaSel.numTrienios = Math.floor(numAños / añosTrienio);
            } else if (dataField === "tblDatosSalariales.isTrienio") {
                let añosTrienio = e.value == 1 ? 3 : 5;
                let numAños = 0;
                if (personaSel.tblDatosSalariales && personaSel.tblDatosSalariales.fechaAntiguedad)
                    numAños =
                        new Date(
                            new Date().getTime() - personaSel.tblDatosSalariales.fechaAntiguedad.getTime()
                        ).getUTCFullYear() - 1970;
                personaSel.numAños = numAños;
                personaSel.numTrienios = Math.floor(numAños / añosTrienio);
            } else if (
                dataField === "tblDatosSalariales.salarioBase" ||
                dataField === "tblDatosSalariales.plusAsistencia" ||
                dataField === "tblDatosSalariales.plusResponsabilidad" ||
                dataField === "tblDatosSalariales.plusPeligrosidad" ||
                dataField === "tblDatosSalariales.incentivo" ||
                dataField === "tblDatosSalariales.plusProductividad" ||
                dataField === "tblDatosSalariales.acuerdoNC" ||
                dataField === "tblDatosSalariales.salarioEspecie"
            ) {
                let salarioBrutoAnual = this.getSalarioBrutoAnual(personaSel);
                let total =
                    salarioBrutoAnual *
                    (1 + (personaSel.tblDatosSalariales != null ? personaSel.tblDatosSalariales.percSegSocial : 0));
                personaSel.costeEmpresa = total;

                let salario = this.getSalarioBrutoAnual(personaSel);
                personaSel.salarioBrutoAnual = salario;
            } else if (dataField === "costeEmpresa") {
                let salarioBrutoAnual = this.getSalarioBrutoAnual(personaSel);
                let total =
                    salarioBrutoAnual *
                    (1 + (personaSel.tblDatosSalariales != null ? personaSel.tblDatosSalariales.percSegSocial : 0));
                e.value = total;
            } else if (dataField === "salarioBrutoAnual") {
                let salario = this.getSalarioBrutoAnual(personaSel);
                e.value = salario;
            } else if (dataField === "numTrienios") {
                let añosTrienio = personaSel.tblDatosSalariales.isTrienio == 1 ? 3 : 5;
                let numTrienios = 0;
                if (personaSel.tblDatosSalariales && personaSel.tblDatosSalariales.fechaAntiguedad) {
                    let numAños =
                        new Date(
                            new Date().getTime() - personaSel.tblDatosSalariales.fechaAntiguedad.getTime()
                        ).getUTCFullYear() - 1970;
                    numTrienios = Math.floor(numAños / añosTrienio);
                }
                e.value = numTrienios;
            } else if (dataField === "numAños") {
                let numAños = 0;
                if (personaSel.tblDatosSalariales && personaSel.tblDatosSalariales.fechaAntiguedad)
                    numAños =
                        new Date(
                            new Date().getTime() - personaSel.tblDatosSalariales.fechaAntiguedad.getTime()
                        ).getUTCFullYear() - 1970;
                e.value = numAños;
            } else if (dataField === "tblDatosSalariales.percSegSocial") {
                let salarioBrutoAnual = this.getSalarioBrutoAnual(personaSel);
                let total =
                    salarioBrutoAnual *
                    (1 + (personaSel.tblDatosSalariales != null ? personaSel.tblDatosSalariales.percSegSocial : 0));
                personaSel.costeEmpresa = total;
            } else if (dataField === "codigoGestoria") {
                let codigo = e.value.length > 0 ? e.value.padStart(6, "0") : "";
                personaSel.codigoGestoria = codigo;

                let index =
                    personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                this.dxDataGrid_personas.cellValue(index, dataField, codigo);
            }

            if (dataField === "idEmpresaPolarier") {
            }

            this.setState({ personaSel: personaSel });

            if (dataField === "idEmpresaPolarier") {
                personaSel.idAdmCentroCoste = null;
                personaSel.idAdmElementoPEP = null;
                personaSel.idCentroLav = "[-1,-1]";

                let index =
                    personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                this.dxDataGrid_personas.cellValue(index, "idAdmCentroCoste", personaSel.idAdmCentroCoste);
                this.dxDataGrid_personas.cellValue(index, "idAdmElementoPEP", personaSel.idAdmElementoPEP);
                this.dxDataGrid_personas.cellValue(index, "idCentroLav", personaSel.idCentroLav);

                this.datasource_tblAdmElementoPEP.reload();
                this.datasource_tblAdmCentroCoste.reload();

                let datasource_tblCentroTrabajo_Lavanderia = this.getCentrosEmpresa(personaSel.idEmpresaPolarier);

                this.setState(
                    {
                        personaSel,
                        datasource_tblCentroTrabajo_Lavanderia,
                    },
                    () => {
                        notify({
                            message: "Se ha cambiado la empresa, por favor, revise los campos de datos laborales.",
                            type: "error",
                            displayTime: "2000",
                            closeOnClick: true,
                        });
                    }
                );
                // Ejecutar validación luego de actualizar personaSel
                if (validationEngine.getGroupConfig("validacionCodigoGestoria")) {
                    validationEngine.validateGroup("validacionCodigoGestoria");
                }
                if (validationEngine.getGroupConfig("validacionDatosLaborales")) {
                    let validacionDatosLaborales = validationEngine.validateGroup("validacionDatosLaborales");
                    this.setState({ isValid_warning_datosLaborales: validacionDatosLaborales.isValid });
                }
            }

            if (dataField === "idCentroLav" || dataField === "idTurno" || dataField === "idTipoTrabajo") {
                this.datasource_tblUsuario_Validacion.reload().then((data) => {
                    this.setState({ tblUsuario_Validacion: data });
                });
            }
        }

        if (!this.state.isValid_warning_datosLaborales && this.isFieldDatosLaboralesValidator(dataField)) {
            let validation = validationEngine.validateGroup("validacionDatosLaborales");
            this.setState({ isValid_warning_datosLaborales: validation.isValid });
        }
    }
    // #endregion

    dxFormItem_nuevaPersona_render(data) {
        let {
            personaSel,
            idsSubturnosVisibles,
            datasource_tblLocalizacion,
            datasource_tblEmpresasPolarier,
            datasource_tblCentroTrabajo_Lavanderia,
            datasource_tblTipoTrabajo,
            datasource_tblTurno,
            validationError_telefono,
            datasource_tblTipoContrato,
            minFechaBaja_altaPersona,
            maxFechaAlta_altaPersona,
            datasource_tblTipoDocumentoIdentidad,
            datasource_tblFormatoDiasLibres,
        } = this.state;

        let value = !personaSel ? null : personaSel[data.editorOptions.name];
        value = typeof value === "undefined" || value === null ? null : value;

        let formLabel = this["formLabel_" + data.dataField.replace(".", "_")];

        let idLavanderia = null;
        let idCentroTrabajo = null;
        if (personaSel && personaSel.idCentroLav) {
            let parsed = JSON.parse(personaSel.idCentroLav);
            idLavanderia = parsed[1] > 0 ? parsed[1] : null;
            idCentroTrabajo = parsed[0] > 0 ? parsed[0] : null;
        }

        let datasource_tblTurno_filtrado = $.grep(datasource_tblTurno, function (item) {
            return (
                (item.idTurnoPadreNavigation == null ||
                    (item.idTurnoPadreNavigation != null && $.inArray(item.idTurno, idsSubturnosVisibles) != -1)) &&
                (item.idLavanderia === idLavanderia || item.idLavanderia == null)
            );
        });
        datasource_tblTurno_filtrado = [...datasource_tblTurno_filtrado];

        let isRequired =
            data.dataField != "emailEmpresa" &&
            data.dataField != "numDiasPeriodoPrueba" &&
            data.dataField != "fechaBajaContrato" &&
            (data.name != "idTurno" || (data.name == "idTurno" && idLavanderia));

        let idTurno = personaSel ? personaSel.idTurno : null;
        if (!idTurno && data.name == "horasDiarias") return null;

        let formatoDiasLibres =
            data.dataField == "selectorDiasLibres" && personaSel
                ? datasource_tblFormatoDiasLibres?.find((x) => x.idFormatoDiasLibres === personaSel.idFormatoDiasLibres)
                : null;
        let isSelectorDiasLibresHeight = formatoDiasLibres
            ? formatoDiasLibres.numDiasLibres * 2 + formatoDiasLibres.numDiasLibres * 34
            : 0;

        if (
            (data.name == "selectorDiasLibres" && !formatoDiasLibres) ||
            (data.name === "tblLavanderiaNUsuario" && !this.isVisible_selectorLavanderia()) ||
            (data.name === "isFormPedidoAllowed" && !this.isVisible_isFormPedidoAllowed())
        )
            return null;

        let isDisabledByLlamamiento =
            (data.name === "idLocalizacion" ||
                data.name === "idEmpresaPolarier" ||
                data.name === "idCentroLav" ||
                data.name === "idCategoriaConvenio" ||
                data.name === "idCategoriaInterna" ||
                data.name === "idTipoTrabajo" ||
                data.name === "idTurno" ||
                data.name === "idFormatoDiasLibres" ||
                data.name === "selectorDiasLibres" ||
                data.name === "fechaAltaContrato" ||
                data.name === "idTipoContrato" ||
                data.name === "idAdmElementoPEP") &&
            personaSel?.idLlamamiento != null;

        if (
            (idLavanderia == null &&
                (data.name === "idTipoTrabajo" || data.name === "idTurno" || data.name === "idAdmElementoPEP")) ||
            (idCentroTrabajo == null && data.name === "idAdmCentroCoste")
        )
            return null;

        return (
            <Fragment>
                <div className="d-flex flex-row align-items-center" style={{ paddingTop: 10 }}>
                    <i
                        style={{
                            alignSelf: data.name == "selectorDiasLibres" ? "flex-start" : "auto",
                        }}
                        className={"font-size-lg pe-none align-middle pr-3 " + formLabel.icon}
                    ></i>
                    {formLabel && (formLabel.isVisible || formLabel.isVisible == null) ? (
                        <div
                            style={{
                                flex: 3,
                                alignSelf: data.name == "selectorDiasLibres" ? "flex-start" : "auto",
                            }}
                            className={"pr-3 d-flex flex-row"}
                        >
                            <div style={{ flex: "0 0 auto" }} className="d-flex align-items-center">
                                {formLabel.text}
                                <span className="pl-2 dx-field-item-required-mark">{isRequired && " *"}</span>
                            </div>
                        </div>
                    ) : null}

                    <div
                        style={{ flex: 2, height: "100%" }}
                        className={"ml-auto d-flex justify-content-end position-relative "}
                    >
                        {personaSel && personaSel.emailPersonal_exists && data.name === "email" ? (
                            <div
                                style={{ height: 34, flex: 1 }}
                                className="d-flex flex-row align-items-center justify-content-end overflow-hidden"
                            >
                                <div
                                    id="tooltip_check_emailPersonal"
                                    style={{ flex: "0 0 auto" }}
                                    className={"icon_Cerrar success font-size-lg"}
                                ></div>
                                <Tooltip
                                    container="#dxPopup_personal_addPersona_fichaPersona"
                                    target="#tooltip_check_emailPersonal"
                                    position="top"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                >
                                    <div className="text-left">
                                        {this.getTrad("emailPersonaExistente")
                                            .split("\\n")
                                            .map(function (item) {
                                                return (
                                                    <Fragment>
                                                        <span>{item}</span>
                                                        <br />
                                                    </Fragment>
                                                );
                                            })}
                                    </div>
                                </Tooltip>
                            </div>
                        ) : personaSel && personaSel.nombreUsuario != null && data.name === "emailEmpresa" ? (
                            <div
                                style={{ height: 34, flex: 1 }}
                                className="d-flex flex-row align-items-center justify-content-end overflow-hidden"
                            >
                                <div style={{ color: "var(--secondary)", flex: 1 }} className="pe-none text-right">
                                    {personaSel.nombreUsuario}
                                </div>
                                <div className="pl-2" />
                                <div
                                    id="tooltip_check_emailEmpresa"
                                    style={{ flex: "0 0 auto" }}
                                    className={
                                        (personaSel && !personaSel.usuario_tienePersona
                                            ? "icon_Check"
                                            : "icon_Cerrar") + " success font-size-lg"
                                    }
                                ></div>
                                <Tooltip
                                    container="#dxPopup_personal_addPersona_fichaPersona"
                                    target="#tooltip_check_emailEmpresa"
                                    position="top"
                                    showEvent="dxhoverstart"
                                    hideEvent="dxhoverend"
                                >
                                    <div className="text-left">
                                        {personaSel && !personaSel.usuario_tienePersona
                                            ? this.getTrad("emailUsuarioExistente_vinculacion_valido")
                                                  .split("\\n")
                                                  .map(function (item) {
                                                      return (
                                                          <Fragment>
                                                              <span>{item}</span>
                                                              <br />
                                                          </Fragment>
                                                      );
                                                  })
                                            : this.getTrad("emailUsuarioExistente_vinculacion_error")
                                                  .split("\\n")
                                                  .map(function (item) {
                                                      return (
                                                          <Fragment>
                                                              <span>{item}</span>
                                                              <br />
                                                          </Fragment>
                                                      );
                                                  })}
                                    </div>
                                </Tooltip>
                            </div>
                        ) : data.name === "isFormPedidoAllowed" ? (
                            <Switch
                                name={data.dataField}
                                value={value}
                                width={"50%"}
                                switchedOnText={getTrad("permitido")}
                                switchedOffText={getTrad("noPermitido")}
                                onValueChanged={this.dxFormEditor_onValueChanged}
                            />
                        ) : data.name == "idLocalizacion" ||
                          data.name == "idEmpresaPolarier" ||
                          data.name == "idCategoriaConvenio" ||
                          data.name == "idCategoriaInterna" ||
                          data.name == "idTipoTrabajo" ||
                          data.name == "idTurno" ||
                          data.name == "idTipoContrato" ||
                          data.name == "idTipoDocumentoIdentidad" ||
                          data.dataField == "idFormatoDiasLibres" ||
                          data.dataField == "idAdmElementoPEP" ||
                          data.dataField == "idAdmCentroCoste" ? (
                            <SelectBox
                                name={data.name}
                                disabled={isDisabledByLlamamiento}
                                dataSource={
                                    data.name == "idLocalizacion"
                                        ? datasource_tblLocalizacion
                                        : data.name == "idEmpresaPolarier"
                                        ? datasource_tblEmpresasPolarier
                                        : data.name == "idCategoriaConvenio"
                                        ? this.datasource_tblCategoriaConvenio
                                        : data.name == "idCategoriaInterna"
                                        ? this.datasource_tblCategoriaInterna
                                        : data.name == "idTipoTrabajo"
                                        ? datasource_tblTipoTrabajo
                                        : data.name == "idTurno"
                                        ? datasource_tblTurno_filtrado
                                        : data.name == "idTipoContrato"
                                        ? datasource_tblTipoContrato
                                        : data.name == "idTipoDocumentoIdentidad"
                                        ? datasource_tblTipoDocumentoIdentidad
                                        : data.name == "idFormatoDiasLibres"
                                        ? datasource_tblFormatoDiasLibres
                                        : data.name == "idAdmElementoPEP"
                                        ? this.datasource_tblAdmElementoPEP
                                        : data.name == "idAdmCentroCoste"
                                        ? this.datasource_tblAdmCentroCoste
                                        : []
                                }
                                displayExpr={
                                    data.name == "idCategoriaInterna" || data.name == "idCategoriaConvenio"
                                        ? this.dxSelectBox_displayExpr_upperCase
                                        : data.name == "idAdmElementoPEP" || data.name == "idAdmCentroCoste"
                                        ? this.dxSelectBox_displayExpr_idAdm
                                        : this.dxSelectBox_displayExpr
                                }
                                valueExpr={data.name}
                                value={
                                    (data.name === "idTurno" && datasource_tblTurno_filtrado.length > 0) ||
                                    data.name !== "idTurno"
                                        ? value
                                        : null
                                }
                                width="100%"
                                showClearButton={data.name == "idTurno"}
                                stylingMode={data.name == "idAdmElementoPEP" ? "filled" : "underlined"}
                                onInitialized={this.dxFormEditor_onContentReady}
                                onValueChanged={this.dxFormEditor_nuevaPersona_onValueChanged}
                                readOnly={data.name == "idAdmElementoPEP"}
                                dropDownOptions={
                                    data.name == "idadmElementoPEP" || data.name == "idAdmCentroCoste"
                                        ? this.dxSelectBox_dropdowOptions
                                        : null
                                }
                            >
                                <Validator validationGroup="validacionPersona_nueva">
                                    {(data.name != "idTurno" || (data.name == "idTurno" && idLavanderia)) && (
                                        <RequiredRule />
                                    )}
                                </Validator>
                            </SelectBox>
                        ) : data.name == "fechaAltaContrato" || data.name == "fechaBajaContrato" ? (
                            <DateBox
                                displayFormat="dd/MM/yyyy"
                                disabled={isDisabledByLlamamiento}
                                name={data.dataField}
                                stylingMode="underlined"
                                value={value}
                                width="100%"
                                min={data.name === "fechaBajaContrato" ? minFechaBaja_altaPersona : null}
                                max={data.name === "fechaAltaContrato" ? maxFechaAlta_altaPersona : null}
                                onContentReady={this.dxFormEditor_onContentReady}
                                onValueChanged={this.dxFormEditor_nuevaPersona_onValueChanged}
                            >
                                <Validator validationGroup="validacionPersona_nueva">
                                    {isRequired && <RequiredRule />}
                                    {data.name == "fechaAltaContrato" ? (
                                        <CustomRule
                                            reevaluate={true}
                                            message={"La fecha tiene que ser menor a la fecha de baja"}
                                            validationCallback={
                                                this.validationCallback_CustomRule_nuevaPersona_fechaAltaContrato
                                            }
                                        />
                                    ) : data.name == "fechaBajaContrato" ? (
                                        <CustomRule
                                            reevaluate={true}
                                            message={"La fecha tiene que ser mayor o igual a la fecha de alta"}
                                            validationCallback={
                                                this.validationCallback_CustomRule_nuevaPersona_fechaBajaContrato
                                            }
                                        />
                                    ) : null}
                                </Validator>
                            </DateBox>
                        ) : data.name == "numDiasPeriodoPrueba" ? (
                            <NumberBox
                                name={data.dataField}
                                stylingMode="underlined"
                                value={!value ? null : parseInt(value)}
                                step={0}
                                min={0}
                                max={9999}
                                width="100%"
                                onContentReady={this.dxFormEditor_onContentReady}
                                onValueChanged={this.dxFormEditor_nuevaPersona_onValueChanged}
                            >
                                <Validator validationGroup="validacionPersona_nueva">
                                    <CustomRule
                                        reevaluate={true}
                                        message={
                                            "El nº de días debe ser menor a la diferencia entre fecha de inicio y fecha de fin"
                                        }
                                        validationCallback={
                                            this.validationCallback_CustomRule_nuevaPersona_numDiasPeriodoPrueba
                                        }
                                    />
                                </Validator>
                            </NumberBox>
                        ) : data.name == "idCentroLav" ? (
                            <Lookup
                                name={data.name}
                                disabled={isDisabledByLlamamiento}
                                dataSource={
                                    new DataSource({
                                        group: "groupTitle",
                                        store: datasource_tblCentroTrabajo_Lavanderia,
                                    })
                                }
                                className={value == null ? "idCentroLav_placeholder" : "idCentroLav_value"}
                                displayExpr={this.dxSelectBox_displayExpr}
                                valueExpr={data.name}
                                value={value}
                                width="100%"
                                stylingMode="underlined"
                                grouped={true}
                                searchEnabled={false}
                                showCancelButton={false}
                                onContentReady={this.dxLookup_onContenReady}
                                onInitialized={this.dxFormEditor_onContentReady}
                                onValueChanged={this.dxFormEditor_nuevaPersona_onValueChanged}
                            >
                                <Validator validationGroup="validacionPersona_nueva">
                                    <RequiredRule />
                                </Validator>
                            </Lookup>
                        ) : data.name == "telefono" ? (
                            <Fragment>
                                <ReactPhoneInput
                                    inputClass={validationError_telefono ? "validationError_telefono" : ""}
                                    containerClass={validationError_telefono ? "validationError_telefono" : ""}
                                    country="es"
                                    localization={es}
                                    preferredCountries={["es"]}
                                    enableSearch={true}
                                    placeholder=""
                                    searchPlaceholder={"Buscar..."}
                                    searchClass="search-class"
                                    searchStyle={{ margin: "0", width: "97%", height: "30px" }}
                                    disableSearchIcon={true}
                                    inputProps={{
                                        required: true,
                                        bordercolor: "red",
                                    }}
                                    value={
                                        personaSel
                                            ? "+" +
                                              (personaSel.prefijoTelefonico ? personaSel.prefijoTelefonico : "34") +
                                              " " +
                                              personaSel.telefono
                                            : ""
                                    }
                                    onChange={this.dxFormEditor_nuevaPersona_telefono_onValueChanged}
                                />
                                {validationError_telefono ? <div className="validationError_icon">!</div> : null}
                            </Fragment>
                        ) : data.name == "selectorDiasLibres" ? (
                            <div className="d-flex w-100 justify-content-end">
                                <SelectorDiasLibres
                                    disabled={isDisabledByLlamamiento}
                                    stylingMode="underlined"
                                    formato={formatoDiasLibres}
                                    persona={personaSel}
                                    isLabelsVisible={true}
                                    //isRequired={personaSel && personaSel.idTurno == -1}
                                    onContentReady={this.dxFormEditor_onContentReady}
                                    onValidChange={this.diasLibres_onValidChange}
                                    onValueChanged={this.diasLibres_modify_onValueChanged}
                                />
                            </div>
                        ) : data.name == "isIBANSolicitado" ? (
                            <div className="d-flex w-100  justify-content-end">
                                <div className="w-50">
                                    <Switch
                                        name={data.name}
                                        defaultValue={true}
                                        value={value == null ? true : value}
                                        switchedOnText={this.getTrad("activo")}
                                        switchedOffText={this.getTrad("noActivo")}
                                        onValueChanged={this.dxFormEditor_nuevaPersona_onValueChanged}
                                    />
                                </div>
                            </div>
                        ) : data.name == "tblLavanderiaNUsuario" ? (
                            <SelectorLavanderias personaSel={personaSel} onChange={this.onChange_selectorLavanderia} />
                        ) : (
                            <TextBox
                                name={data.name}
                                className={
                                    data.name == "emailEmpresa"
                                        ? personaSel && personaSel.usuario_tienePersona
                                            ? "emailEmpresa tienePersona "
                                            : ""
                                        : data.name == "email"
                                        ? personaSel && personaSel.emailPersonal_exists
                                            ? " emailPersonal tienePersona "
                                            : ""
                                        : data.name == "numDocumentoIdentidad"
                                        ? "textUpperCase "
                                        : null
                                }
                                width="100%"
                                stylingMode="underlined"
                                value={value}
                                mask={data.name == "horasDiarias" ? "00:00" : ""}
                                useMaskedValue={data.name == "horasDiarias"}
                                onKeyDown={
                                    data.name == "numDocumentoIdentidad" ||
                                    data.name == "email" ||
                                    data.name == "emailEmpresa"
                                        ? this.dxTextBox_onKeyPress_noEspacio
                                        : null
                                }
                                onContentReady={this.dxFormEditor_onContentReady}
                                onValueChanged={this.dxFormEditor_nuevaPersona_onValueChanged}
                            >
                                {data.name == "nombre" ||
                                data.name == "apellidos" ||
                                data.name == "email" ||
                                data.name == "horasDiarias" ||
                                data.name == "emailEmpresa" ||
                                data.name == "numDocumentoIdentidad" ? (
                                    <Validator
                                        validationGroup="validacionPersona_nueva"
                                        onValidated={this.dxValidation_onOptionChanged}
                                    >
                                        {data.name != "emailEmpresa" ? <RequiredRule /> : null}

                                        {data.name == "nombre" || data.name == "apellidos" ? (
                                            <PatternRule
                                                pattern={this.noNum_pattern}
                                                message={this.getTrad("validation_NoNumeros")}
                                            />
                                        ) : null}
                                        {data.name == "email" || data.name == "emailEmpresa" ? <EmailRule /> : null}
                                        {data.name == "email" ? (
                                            <AsyncRule
                                                validationCallback={
                                                    this.dxValidator_emailPersonal_asyncRule_validationCallback
                                                }
                                            />
                                        ) : null}
                                        {data.name == "emailEmpresa" ? (
                                            <AsyncRule
                                                validationCallback={
                                                    this.dxValidator_emailEmpresa_asyncRule_validationCallback
                                                }
                                            />
                                        ) : null}
                                        {data.name == "horasDiarias" ? (
                                            <PatternRule
                                                pattern={this.horasSemanales_pattern}
                                                message={"Tiempo incorrecto"}
                                            />
                                        ) : null}
                                        {data.name == "numDocumentoIdentidad" &&
                                        this.state.isVisible_dxPopup_addNewPersona ? (
                                            <AsyncRule
                                                ignoreEmptyValue={true}
                                                reevaluate={true}
                                                message="Este nº documento ya ha sido registrado"
                                                validationCallback={
                                                    this
                                                        .dxValidator_numDocumentoIdentidad_nuevaPersona_asyncRule_validationCallback
                                                }
                                            />
                                        ) : null}
                                    </Validator>
                                ) : null}
                            </TextBox>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }

    dxValidator_numDocumentoIdentidad_nuevaPersona_asyncRule_validationCallback(e) {
        let _this = this;
        let value = e.value.replace(/\s+/g, " ").trim();

        let personaSel = { ..._this.state.personaSel };
        personaSel.numDocumentoIdentidad = value === "" ? null : value;
        return new Promise((resolve, reject) => {
            if (!personaSel.numDocumentoIdentidad) {
                reject();
            } else {
                _this.setState({ personaSel }, () => {
                    let numDocumentoIdentidad =
                        personaSel.numDocumentoIdentidad === "" || personaSel.numDocumentoIdentidad === " "
                            ? -1
                            : personaSel.numDocumentoIdentidad;
                    _this.context_persona
                        .invoke(
                            "fn_isNumDocIdentidadPersonaExists",
                            {
                                numDocumentoIdentidad: numDocumentoIdentidad,
                                idPersona: personaSel.idPersona,
                            },
                            "POST"
                        )
                        .done(function (exists) {
                            personaSel.numDocIdentidad_exists = exists;

                            _this.setState({ personaSel });
                            if (!exists) {
                                resolve();
                            } else {
                                reject();
                            }
                        });
                });
            }
        });
    }

    dxValidator_emailPersonal_asyncRule_validationCallback(e) {
        let value = e.value.replace(/\s+/g, " ").trim();
        let _this = this;
        let personaSel = { ..._this.state.personaSel };
        personaSel.email = value === "" ? null : value;

        return new Promise((resolve, reject) => {
            if (!personaSel.email) {
                resolve();
            } else {
                _this.setState({ personaSel }, () => {
                    let email = personaSel.email === "" || personaSel.email === " " ? -1 : personaSel.email;
                    this.context_persona.invoke("fn_isEmailExists", { email: email }, "POST").done(function (exists) {
                        personaSel.emailPersonal_exists = exists;

                        _this.setState({ personaSel });
                        if (!exists) {
                            resolve();
                        } else {
                            reject();
                        }
                    });
                });
            }
        });
    }

    validateAsync_codigoGestoria(params) {
        let value = params.value.replace(/\s+/g, " ").trim();
        let _this = this;
        return new Promise((resolve) => {
            this.objCheckCodigoGestoria = { codigo: value };
            this.context_CodigoGestoria.invoke("fn_CheckCodigoGestoria", {}, "POST").done(function (result) {
                const isValid = result === -1;
                _this.setState({ isVisible_warning_datosPersonales: !isValid });
                return resolve(isValid);
            });
        });
    }

    dxValidator_numDocumentoIdentidad_asyncRule_validationCallback(e) {
        let value = e.value.replace(/\s+/g, " ").trim();
        let _this = this;
        let personaSel = { ..._this.state.personaSel };
        personaSel.numDocumentoIdentidad = value === "" ? null : value;
        return new Promise((resolve, reject) => {
            if (!personaSel.numDocumentoIdentidad) {
                resolve();
            } else {
                _this.setState({ personaSel }, () => {
                    let numDocumentoIdentidad =
                        personaSel.numDocumentoIdentidad === "" || personaSel.numDocumentoIdentidad === " "
                            ? -1
                            : personaSel.numDocumentoIdentidad;
                    _this.context_persona
                        .invoke(
                            "fn_isNumDocIdentidadPersonaExists",
                            {
                                numDocumentoIdentidad: numDocumentoIdentidad,
                                idPersona: personaSel.idPersona,
                            },
                            "POST"
                        )
                        .done(function (exists) {
                            personaSel.numDocIdentidad_exists = exists;

                            _this.setState({ personaSel });
                            if (!exists) {
                                resolve();
                            } else {
                                reject();
                            }
                        });
                });
            }
        });
    }

    datasource_tblUsuario_GetByEmail = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblUsuario/GetByEmail",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblUsuario_GetByEmail_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblUsuario_GetByEmail_beforeSend(request) {
        let { personaSel } = this.state;
        request.headers = { ...authHeader() };
        request.params.email =
            personaSel.emailEmpresa === "" || personaSel.emailEmpresa === " " ? -1 : personaSel.emailEmpresa;
    }

    dxValidator_emailEmpresa_asyncRule_validationCallback(e) {
        let value = e.value ? e.value.replace(/\s+/g, " ").trim() : "";
        let _this = this;
        let personaSel = { ..._this.state.personaSel };
        personaSel.emailEmpresa = value === "" ? null : value;
        return new Promise((resolve, reject) => {
            if (!personaSel.emailEmpresa) {
                personaSel.nombreUsuario = null;
                personaSel.usuario_tienePersona = null;
                _this.setState({ personaSel: personaSel });
                resolve();
            } else {
                _this.setState({ personaSel }, () => {
                    _this.datasource_tblUsuario_GetByEmail.reload().done(function (usuario) {
                        if (usuario.length > 0) {
                            personaSel.nombreUsuario = usuario[0].nombre;
                            personaSel.usuario_tienePersona = usuario[0].usuario_tienePersona;

                            _this.setState({ personaSel });
                            if (usuario[0].usuario_tienePersona) {
                                reject();
                            } else resolve();
                        } else {
                            personaSel.nombreUsuario = null;
                            personaSel.usuario_tienePersona = null;
                            _this.setState({ personaSel });
                            resolve();
                        }
                    });
                });
            }
        });
    }

    dxFormItem_render_tblPersonaNTipoContrato(editable, datasource) {
        let { datasource_tblTipoContrato, datasource_tblMotivoBaja, personaSel, isVisible_FechaAntiguedadPopup } =
            this.state;
        let { user } = this.props;
        const data = datasource != null ? datasource : personaSel;
        return (
            <div style={{ minHeight: 425 }}>
                <DataGrid
                    ref={this.dxDataGrid_tblPersonaNTipoContrato_REF}
                    elementAttr={this.dxDataGrid_tblPersonaNTipoContrato_elementAttr}
                    dataSource={data ? data.tblPersonaNTipoContrato : []}
                    columnsAutoWidth={true}
                    height={"100%"}
                    //Estilos
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    //Eventos
                    onToolbarPreparing={editable ? this.dxDataGrid_personaNTipoContrato_onToolbarPreparing : undefined}
                    onEditorPreparing={editable ? this.dxDataGrid_personaNTipoContrato_onEditorPreparing : undefined}
                    onRowInserted={editable ? this.dxDataGrid_personaNTipoContrato_onRowInserted : undefined}
                    onRowUpdating={editable ? this.dxDataGrid_personaNTipoContrato_onRowUpdating : undefined}
                    onRowRemoving={editable ? this.dxDataGrid_personaNTipoContrato_onRowRemoving : undefined}
                    onRowValidating={editable ? this.dxDataGrid_personaNTipoContrato_onRowValidating : undefined}
                >
                    {editable && (
                        <Editing
                            refreshMode={"repaint"}
                            mode={"batch"}
                            allowAdding={user.enableDatosRRHH}
                            allowUpdating={user.enableDatosRRHH}
                            useIcons={true}
                            selectTextOnEditStart={true}
                        />
                    )}
                    <KeyboardNavigation enterKeyAction="moveFocus" enterKeyDirection="row" editOnKeyPress={true} />
                    <Paging enabled={false} />
                    <Column
                        dataField="fechaAltaContrato"
                        caption={this.getTrad("fechaAlta")}
                        sortOrder="desc"
                        dataType="date"
                        alignment="center"
                        format="dd/MM/yyyy"
                        width={142}
                        sortIndex={1}
                        allowHeaderFiltering={false}
                        allowEditing={editable}
                        editorOptions={this.dxDateBox_personaNTipoContrato_editorOptions}
                    >
                        <RequiredRule />
                        <CustomRule
                            reevaluate={true}
                            message={getTrad("fechaContratoExistente")}
                            validationCallback={this.validationCallback_CustomRule_contratoExistente_fechaAltaContrato}
                        />
                    </Column>
                    <Column
                        dataField="fechaBajaContrato"
                        caption={this.getTrad("fechaBaja")}
                        sortOrder="desc"
                        dataType="date"
                        alignment="center"
                        format="dd/MM/yyyy"
                        width={150}
                        sortIndex={2}
                        allowHeaderFiltering={false}
                        allowEditing={editable}
                        editorOptions={this.dxDateBox_personaNTipoContrato_editorOptions}
                    >
                        <CustomRule
                            reevaluate={true}
                            message={getTrad("fechaMayorIgualFechaAlta")}
                            validationCallback={this.validationCallback_CustomRule_fechaBajaContrato}
                        />
                        <CustomRule
                            reevaluate={true}
                            message={getTrad("fechaContratoExistente")}
                            validationCallback={this.validationCallback_CustomRule_contratoExistente_fechaBajaContrato}
                        />
                    </Column>
                    <Column
                        dataField="idMotivoBaja"
                        caption={this.getTrad("motivoBaja")}
                        allowSearch={false}
                        visible={true}
                        width={142}
                    >
                        <CustomRule
                            reevaluate={true}
                            message={"Es necesario determinar un motivo de baja."}
                            validationCallback={this.validationCallback_CustomRule_idMotivoPausa}
                        />
                        <DataGrid_Lookup
                            dataSource={datasource_tblMotivoBaja}
                            valueExpr="idMotivoBaja"
                            displayExpr="denominacion"
                            allowClearing={true}
                        />
                    </Column>
                    <Column
                        dataField="numDiasPeriodoPrueba"
                        caption={this.getTrad("periodoPruebaDias")}
                        width={180}
                        alignment="center"
                        dataType="number"
                        allowSorting={false}
                        allowHeaderFiltering={false}
                        editorType="dxNumberBox"
                        allowEditing={editable}
                        editorOptions={this.dxNumberBox_periodoPrueba_editorOptions}
                    >
                        <CustomRule
                            reevaluate={true}
                            message={
                                "El nº de días debe ser menor a la diferencia entre fecha de inicio y fecha de fin."
                            }
                            validationCallback={this.validationCallback_CustomRule_numDiasPeriodoPrueba}
                        />
                    </Column>
                    <Column
                        dataField="idTipoContrato"
                        caption={this.getTrad("tipoContrato")}
                        allowSearch={false}
                        visible={true}
                    >
                        <RequiredRule />
                        <DataGrid_Lookup
                            dataSource={datasource_tblTipoContrato}
                            valueExpr="idTipoContrato"
                            displayExpr="denominacion"
                        />
                    </Column>
                    {editable && (
                        <Column
                            caption=" "
                            visible={user.enableDatosRRHH}
                            width="40px"
                            alignment="center"
                            cssClass="p-0"
                            cellRender={this.dxDataGrid_personaNTipoContrato_removeCellRender}
                            allowEditing={false}
                        />
                    )}
                </DataGrid>
                <FechaAntiguedadPopup
                    visible={isVisible_FechaAntiguedadPopup}
                    idPersona={personaSel?.idPersona}
                    tblPersonaNTipoContrato={personaSel?.tblPersonaNTipoContrato}
                    fechaAntiguedad={personaSel?.tblDatosSalariales?.fechaAntiguedad}
                    nombreCompleto={personaSel ? personaSel?.nombre + " " + personaSel?.apellidos : ""}
                    onHiding={this.FechaAntiguedaPopup_onHiding}
                    onGuardar={this.FechaAntiguedaPopup_onGuardar}
                />
            </div>
        );
    }

    cellRender_avisos_datos(e) {
        const datosFaltantes = this.isPersona_datosFaltantes(e.data).length > 0;
        const datosSalarialesCambiados = this.isPersona_datosSalarialesCambiados(e.data);

        return (
            <div className="container_spanCentrado">
                {datosFaltantes && <div className="icon_Warning font-size-xl" />}
                {!datosFaltantes && datosSalarialesCambiados && <div className="icon_Warning lightGray font-size-xl" />}
            </div>
        );
    }

    isPersona_datosSalarialesCambiados = (persona) => {
        const { datasource_tblCategoriaInterna } = this.state;

        const categoriaInterna = datasource_tblCategoriaInterna?.find(
            (x) => x.idCategoriaInterna === persona.idCategoriaInterna
        );

        const { idCategoriaInterna, denominacion, ...restCategoriaInterna } = categoriaInterna || {};

        const datosSalarialesCambiados = Object.keys(restCategoriaInterna).filter(
            (key) => persona.tblDatosSalariales[key] !== restCategoriaInterna[key]
        );

        return datosSalarialesCambiados.length > 0;
    };

    checkDocIdentidad(data) {
        const { caducidadDocumentoIdentidad, tblSolicitudGestoria } = data;
        const isSolicitudPendiente = tblSolicitudGestoria && tblSolicitudGestoria.length > 0;

        const today = formatDate_noTime_parameter(new Date()),
            isCaducado = caducidadDocumentoIdentidad < today,
            isMenosDeUnMes = dayDiff(new Date(today), new Date(caducidadDocumentoIdentidad)) < 30,
            color = isCaducado ? "dangerColor" : isMenosDeUnMes || isSolicitudPendiente ? "warningColor" : "";

        return { isCaducado, isMenosDeUnMes, color };
    }

    cellRender_docIdentidad(e) {
        const { isCaducado, isMenosDeUnMes, color } = this.checkDocIdentidad(e.data);
        const { tblSolicitudGestoria } = e.data || {};
        const isSolicitudPendiente = tblSolicitudGestoria && tblSolicitudGestoria.length > 0;
        return (
            <div className="container_spanCentrado">
                {(isCaducado || isMenosDeUnMes || isSolicitudPendiente) && (
                    <div className={"icon_card_account_details_outline " + color + " font-size-xl pe-none"} />
                )}
            </div>
        );
    }

    isPersona_datosFaltantes(persona) {
        let campos = [
            { denominacion: "Nombre", value: persona.nombre },
            { denominacion: "Apellidos", value: persona.apellidos },
            { denominacion: "Email", value: persona.email },
            { denominacion: "Teléfono", value: persona.telefono },
            {
                denominacion: "Usuario",
                value: persona.tblUsuario.length > 0 ? true : null,
            },
            {
                denominacion: "Localización",
                value: persona.tblUsuario.length > 0 ? persona.tblUsuario[0].idLocalizacion : false,
            },
            {
                denominacion: "Empresa",
                value: persona.idEmpresaPolarier ? persona.idEmpresaPolarier : null,
            },
            {
                denominacion: "Centro",
                value: [persona.idCentroTrabajo, persona.idLavanderia],
            },
            {
                denominacion: "Tipo trabajo",
                value: persona.idLavanderia != null ? persona.idTipoTrabajo : false,
            },
            {
                denominacion: "Turno",
                value: persona.idLavanderia != null ? persona.idTurno : false,
            },
            {
                denominacion: "Horas",
                value: persona.idLavanderia != null ? persona.horasDiarias : false,
            },
            {
                denominacion: "Fecha de alta",
                value: persona["tblPersonaNTipoContrato@odata.count"] > 0 ? true : null,
            },
            {
                denominacion: "Tipo de contrato",
                value:
                    persona["tblPersonaNTipoContrato@odata.count"] > 0
                        ? persona.tblPersonaNTipoContrato[0].idTipoContrato
                        : false,
            },
            {
                denominacion: "Código gestoría",
                value: persona.codigoGestoria != "" ? persona.codigoGestoria : null,
            },
            { denominacion: "Categoría interna", value: persona.idCategoriaInterna },
        ];

        let camposFaltantes = [];
        $.each(campos, function (index, item) {
            if (item.denominacion === "Centro") {
                if (item.value[0] == null && item.value[1] == null) camposFaltantes.push(item.denominacion);
            } else {
                if (item.value == null || (typeof item.value == "string" && item.value == ""))
                    camposFaltantes.push(item.denominacion);
            }
        });
        return camposFaltantes;
    }

    dxDataGrid_personas_onCellHoverChanged(e) {
        if (e.column && e.row && e.column.name === "datosFaltantes" && e.rowType === "data") {
            let content = "";

            if (this.isPersona_datosFaltantes(e.data).length > 0) {
                content += "<p class='mb-1'>A este trabajador le faltan los siguientes datos: </p>";

                $.each(this.isPersona_datosFaltantes(e.data), function (index, item) {
                    content += "<p class='mb-1' style='font-weight:500;'> - " + item + "</p>";
                });
            }

            if (this.isPersona_datosSalarialesCambiados(e.data)) {
                content += "<p class='mb-1'>" + getTrad("datosSalarialesDistintos") + "</p>";
            }

            if (content !== "") {
                if (e.eventType === "mouseover") {
                    this.dxPopover.option("contentTemplate", function (contentElement) {
                        let container = $("<div/>");
                        container.html(content);
                        container.appendTo(contentElement);
                    });
                    this.dxPopover.option("target", e.cellElement);
                    this.dxPopover.show();
                } else {
                    this.dxPopover.hide();
                }
            }
        }

        if (e.column && e.row && e.column.name === "docIdentidad" && e.rowType == "data") {
            const { isCaducado, isMenosDeUnMes } = this.checkDocIdentidad(e.data);
            const { tblSolicitudGestoria } = e.data || {};
            const isSolicitudPendiente = tblSolicitudGestoria && tblSolicitudGestoria.length > 0;

            if (!isCaducado && !isMenosDeUnMes && !isSolicitudPendiente) {
                return;
            }

            if (e.eventType === "mouseover") {
                this.dxPopover.option("contentTemplate", function (contentElement) {
                    let container = $("<div/>");
                    if (isCaducado)
                        container.append(
                            "<p class='mb-1'>- Este trabajador tiene el documento de identidad caducado. </span>"
                        );

                    if (isMenosDeUnMes && !isCaducado)
                        container.append(
                            "<p class='mb-1'>- A este trabajador le queda menos de un mes para que le caduque el documento de identidad. </span>"
                        );

                    if (isSolicitudPendiente) {
                        container.append(
                            "<p class='mb-1'>- Este trabajador tiene una solicitud de gestoría pendiente. </span>"
                        );
                    }

                    container.appendTo(contentElement);
                });
                this.dxPopover.option("target", e.cellElement);
                this.dxPopover.show();
            } else this.dxPopover.hide();
        }
    }

    // #region Contratos

    dxDataGrid_personaNTipoContrato_removeCellRender(args) {
        return (
            <div
                className="container_spanCentrado pointer"
                onMouseDown={(e) => {
                    this.dxDataGrid_personaNTipoContrato_removeCellRender_onClick(e, args);
                }}
            >
                <div className="icon_Cerrar font-size-lg" />
            </div>
        );
    }

    dxDataGrid_personaNTipoContrato_removeCellRender_onClick(e, args) {
        dxMensajePregunta(this.getTrad("preg_RegistroEliminarCorto"), [
            [
                this.getTrad("si"),
                () => {
                    if (args.row.isNewRow) {
                        this.dxDataGrid_tblPersonaNTipoContrato.cancelEditData();
                        this.setState({ isValid_warning_datosLaborales: true });
                        this.dxButton_fichaPersona_guardar.option("disabled", false);
                    } else {
                        this.dxDataGrid_tblPersonaNTipoContrato.deleteRow(args.rowIndex);
                        this.dxDataGrid_tblPersonaNTipoContrato.saveEditData();
                    }
                },
            ],
            [this.getTrad("no"), () => {}],
        ]);
    }

    // GUARDAR CAMBIOS TIPO CONTRATO
    updateGridDataField(dataField, data) {
        let personaSel = { ...this.state.personaSel };
        let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        this.dxDataGrid_personas.cellValue(index, dataField, data);

        personaSel.tblPersonaNTipoContrato = data;

        this.setState({ personaSel: personaSel }, () => {
            setTimeout(() => {
                this.loadCalendar({ currentYear: this.state.yearCalendar_currentYear }, false);
            }, 100);
        });
    }

    dxDataGrid_personaNTipoContrato_onRowRemoving(e) {
        let data = e.component.getDataSource().items();
        this.updateGridDataField("tblPersonaNTipoContrato", data);
    }

    dxDataGrid_personaNTipoContrato_onRowUpdating(e) {
        let data = e.component.getDataSource().items();

        $.each(data, function (index, item) {
            if (item["__KEY__"] && item["__KEY__"] == e.key["__KEY__"]) {
                $.each(e.newData, function (idata, newData) {
                    item[idata] = newData;
                });
            }
        });
        this.updateGridDataField("tblPersonaNTipoContrato", data);
    }

    dxDataGrid_personaNTipoContrato_onEditorPreparing(e) {
        const _this = this;
        this.dxButton_fichaPersona_guardar.option("disabled", true);

        e.editorOptions.onFocusOut = (ev) => {
            this.dxDataGrid_personaNTipoContrato_guardarContratos(e);
        };

        if (e.dataField === "fechaAltaContrato" && e.parentType === "dataRow" && e.row.data.fechaBajaContrato != null) {
            let date = new Date(
                new Date(e.row.data.fechaBajaContrato).setDate(new Date(e.row.data.fechaBajaContrato).getDate())
            );
            e.editorOptions.max = date;
        }
        if (e.dataField === "fechaBajaContrato" && e.parentType === "dataRow") {
            e.editorOptions.min = this.getUltFecha_JornadaPersona();

            var onValueChanged = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (c) {
                onValueChanged.apply(this, arguments);
                _this.dxDataGrid_personaNTipoContrato_guardarContratos(e).then(() => {
                    e.component.editCell(e.row.rowIndex, "idMotivoBaja");
                });
            };
        }
        if (e.dataField === "idMotivoBaja" && e.parentType === "dataRow") {
            var onValueChanged = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (c) {
                onValueChanged.apply(this, arguments);
                _this.dxDataGrid_personaNTipoContrato_guardarContratos(e);
            };
        }
        if (e.dataField === "idTipoContrato" && e.parentType === "dataRow") {
            var onValueChanged = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (c) {
                onValueChanged.apply(this, arguments);
                _this.dxDataGrid_personaNTipoContrato_guardarContratos(e);
            };
        }
    }

    dxDataGrid_personaNTipoContrato_onRowInserted(e) {
        let data = e.component.getDataSource().items();
        this.updateGridDataField("tblPersonaNTipoContrato", data);
    }

    dxDataGrid_personaNTipoContrato_onRowValidating(e) {
        this.setState({
            isValid_warning_datosLaborales: e.brokenRules.length === 0,
        });
    }

    dxDataGrid_personaNTipoContrato_guardarContratos = (e) => {
        let prom = this.dxDataGrid_tblPersonaNTipoContrato.saveEditData();
        prom.then((result) => {
            let isEditing = e.component.hasEditData();
            this.dxButton_fichaPersona_guardar.option("disabled", isEditing);
        });

        return prom;
    };

    validationCallback_CustomRule_fechaBajaContrato(e) {
        let { fechaAltaContrato } = e.data;

        return fechaAltaContrato == null || e.value == null
            ? true
            : new Date(e.value.toDateString()) >= new Date(new Date(fechaAltaContrato).toDateString());
    }

    validationCallback_CustomRule_idMotivoPausa(e) {
        let { fechaBajaContrato } = e.data;

        return fechaBajaContrato == null ? true : e.value != null ? true : false;
    }

    FechaAntiguedaPopup_onHiding = (e) => {
        this.setState({ isVisible_FechaAntiguedadPopup: false });
    };

    FechaAntiguedaPopup_onGuardar = ({ value: fechaAntiguedad }) => {
        let personaSel = { ...this.state.personaSel };

        const fecha = fechaAntiguedad instanceof Date ? fechaAntiguedad : new Date(fechaAntiguedad);
        personaSel.tblDatosSalariales.fechaAntiguedad = fecha;

        let rowIndex = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        this.dxDataGrid_personas.cellValue(rowIndex, "tblDatosSalariales.fechaAntiguedad", fecha);

        this.setState({ personaSel, isVisible_FechaAntiguedadPopup: false });

        this.fichaPersona_guardarPersona();
    };

    // #endregion

    getUltFecha_CalendarioPersonal = () => {
        const { personaSel } = this.state;
        let ultFecha;
        personaSel.tblCalendarioPersonal.forEach((evento) => {
            if (evento.idEstado !== 8 && evento.idEstado !== 10) {
                ultFecha =
                    (ultFecha === undefined || evento.fecha > ultFecha) && evento.idEstado !== 0
                        ? evento.fecha
                        : ultFecha;
            }
        });
        return ultFecha;
    };

    getUltFecha_CuadrantePersonal = () => {
        const { personaSel } = this.state;
        let ultFecha = null;
        personaSel.tblCuadrantePersonal.forEach((fecha) => {
            ultFecha = ultFecha === undefined || fecha > ultFecha ? fecha : ultFecha;
        });
        if (ultFecha != null) {
            return new Date(ultFecha);
        }
        return;
    };

    getUltFecha_JornadaPersona = () => {
        const { personaSel } = this.state;
        let ultFecha = null;
        personaSel.tblJornadaPersona.forEach((fecha) => {
            ultFecha = ultFecha === undefined || fecha > ultFecha ? fecha : ultFecha;
        });
        if (ultFecha != null) {
            return new Date(ultFecha);
        }
        return;
    };

    validationCallback_CustomRule_contratoExistente_fechaBajaContrato(e) {
        let fechaAltaContrato = stringToDateTime_timeZero(e.data.fechaAltaContrato);
        let dxDataGrid_items = this.dxDataGrid_tblPersonaNTipoContrato.getVisibleRows();

        let isFiltrado_valorActual = false;
        let filtroValorActual = [];

        $.each(dxDataGrid_items, function (index, item) {
            let row = item.data;
            if (
                isSameDay(stringToDateTime_timeZero(row.fechaBajaContrato), e.value) ||
                isSameDay(stringToDateTime_timeZero(row.fechaAltaContrato), fechaAltaContrato)
            ) {
                if (isFiltrado_valorActual == false) isFiltrado_valorActual = true;
                else {
                    filtroValorActual.push(row);
                }
            } else {
                filtroValorActual.push(row);
            }
        });

        let isContratoExistente = false;
        $.each(filtroValorActual, function (index, item) {
            if (
                (e.value <= stringToDateTime_timeZero(item.fechaBajaContrato) &&
                    e.value >= stringToDateTime_timeZero(item.fechaAltaContrato)) ||
                (e.value >= stringToDateTime_timeZero(item.fechaAltaContrato) &&
                    fechaAltaContrato <= stringToDateTime_timeZero(item.fechaAltaContrato)) ||
                (e.value >= stringToDateTime_timeZero(item.fechaAltaContrato) && item.fechaBajaContrato == null) ||
                (e.value == null &&
                    (stringToDateTime_timeZero(item.fechaAltaContrato) >= fechaAltaContrato ||
                        stringToDateTime_timeZero(item.fechaBajaContrato) >= fechaAltaContrato))
            )
                isContratoExistente = true;
        });

        return !isContratoExistente || e.value == null;
    }

    validationCallback_CustomRule_contratoExistente_fechaAltaContrato(e) {
        let fechaBajaContrato =
            e.data.fechaBajaContrato != null ? stringToDateTime_timeZero(e.data.fechaBajaContrato) : null;
        let dxDataGrid_items = this.dxDataGrid_tblPersonaNTipoContrato.getVisibleRows();

        let isFiltrado_valorActual = false;
        let filtroValorActual = [];

        $.each(dxDataGrid_items, function (index, item) {
            let row = item.data;
            if (
                isSameDay(stringToDateTime_timeZero(row.fechaAltaContrato), e.value) ||
                isSameDay(stringToDateTime_timeZero(row.fechaBajaContrato), fechaBajaContrato)
            ) {
                if (isFiltrado_valorActual == false) isFiltrado_valorActual = true;
                else {
                    filtroValorActual.push(row);
                }
            } else {
                filtroValorActual.push(row);
            }
        });

        let isContratoExistente = false;
        $.each(filtroValorActual, function (index, item) {
            if (
                (e.value >= stringToDateTime_timeZero(item.fechaAltaContrato) &&
                    e.value <= stringToDateTime_timeZero(item.fechaBajaContrato)) ||
                (e.value >= stringToDateTime_timeZero(item.fechaAltaContrato) && item.fechaBajaContrato == null) ||
                (e.value <= stringToDateTime_timeZero(item.fechaAltaContrato) && fechaBajaContrato == null)
            )
                isContratoExistente = true;
        });

        return !isContratoExistente;
    }

    validationCallback_CustomRule_numDiasPeriodoPrueba(e) {
        let fechaAltaContrato = stringToDateTime_timeZero(e.data.fechaAltaContrato);
        let fechaBajaContrato = stringToDateTime_timeZero(e.data.fechaBajaContrato);

        var Difference_In_Time = fechaBajaContrato.getTime() - fechaAltaContrato.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
        return (
            e.value == null ||
            (e.value != null &&
                (e.data.fechaBajaContrato == null ||
                    (e.data.fechaBajaContrato != null && e.value <= Difference_In_Days)))
        );
    }

    isFieldDatosLaboralesValidator = (field) => {
        return (
            field === "idTurno" ||
            field === "horasDiarias" ||
            field === "idCentroLav" ||
            field === "idTipoTrabajo" ||
            field === "idCategoriaInterna" ||
            field === "idFormatoDiasLibres" ||
            field === "idAdmCentroCoste" ||
            field === "idAdmElementoPEP" ||
            field === "idAdmCuentaContable_Salario" ||
            field === "idAdmCuentaContable_SSEmpresa"
        );
    };

    dxDataGrid_personaNTipoContrato_onToolbarPreparing(e) {
        let _this = this;
        let { user } = this.props;

        e.toolbarOptions.items.unshift({
            location: "before",
            text: this.getTrad("contratos"),
        });

        $.each(e.toolbarOptions.items, function (index, item) {
            switch (item.name) {
                case "addRowButton":
                    item.options.text = _this.getTrad("nuevoContrato");
                    item.options.icon = "plus";
                    item.options.type = "success";
                    item.showText = "always";
                    item.visible = user.enableDatosRRHH;
                    break;
                case "saveButton":
                    item.visible = false;
                    break;
                case "revertButton":
                    item.visible = false;
                    break;
                default:
                    break;
            }
        });
    }

    validationCallback_CustomRule_nuevaPersona_fechaAltaContrato(e) {
        let { personaSel } = this.state;
        if (!personaSel) return true;
        return (
            personaSel.fechaBajaContrato == null ||
            (personaSel.fechaBajaContrato != null &&
                new Date(e.value.toDateString()) <= new Date(new Date(personaSel.fechaBajaContrato).toDateString()))
        );
    }

    validationCallback_CustomRule_nuevaPersona_fechaBajaContrato(e) {
        let { personaSel } = this.state;
        if (!personaSel) return true;
        return (
            e.value == null ||
            personaSel.fechaAltaContrato == null ||
            (personaSel.fechaAltaContrato != null &&
                new Date(e.value.toDateString()) >= new Date(new Date(personaSel.fechaAltaContrato).toDateString()))
        );
    }

    validationCallback_CustomRule_nuevaPersona_numDiasPeriodoPrueba(e) {
        let { personaSel } = this.state;

        if (personaSel && personaSel.fechaAltaContrato && personaSel.fechaBajaContrato) {
            var Difference_In_Time = personaSel.fechaBajaContrato.getTime() - personaSel.fechaAltaContrato.getTime();

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;

            return e.value <= Difference_In_Days;
        } else return true;
    }

    validationCallback_CustomRule_formItemRender_idCentroLav = (e) => {
        const validationGroup = e.validator._validationGroup;
        const value = e.value;

        if (validationGroup === "validacionDatosLaborales") {
            const valueStringify = JSON.stringify(value);
            const isNull = JSON.stringify("[-1,-1]");

            if (value === null || value === undefined || valueStringify === isNull) {
                return false;
            }
        }
        return true;
    };

    dxFormItem_render(data) {
        let {
            personaSel,
            idsSubturnosVisibles,
            documentosPerfilPersona,
            datasource_tblTallaAlfa,
            datasource_tblDiscapacidad,
            datasource_tblNivelEstudios,
            datasource_tblPais,
            datasource_tblGenero,
            datasource_tblTipoDocumentoIdentidad,
            datasource_tblEstadoCivil,
            datasource_tblComunidadAutonoma,
            validationError_telefono,
            datasource_tblTipoTrabajo,
            datasource_tblTurno,
            datasource_tblCentroTrabajo_Lavanderia,
            datasource_tblLocalizacion,
            datasource_tblEmpresasPolarier,
            datasource_tblFormatoDiasLibres,
            isSalarioBrutoMensualManual,
        } = this.state;

        let { user } = this.props;

        let name = data.editorOptions.name;
        let value = !personaSel
            ? null
            : personaSel[
                  name === "idAdmCuentaContable_Salario" || name === "idAdmCuentaContable_SSEmpresa"
                      ? name
                      : name != "idTallaAlfa_Camiseta"
                      ? name.replace("_", ".")
                      : "idTallaAlfa_Camiseta"
              ];
        if (data.editorOptions.name.includes(".") && personaSel != null) {
            let campo = data.editorOptions.name.split(".");
            if (personaSel[campo[0]]) value = personaSel[campo[0]][campo[1]];
        }

        if (personaSel != null) {
            if (data.dataField === "costeEmpresa") {
                let salarioBrutoAnual = isSalarioBrutoMensualManual
                    ? personaSel?.tblDatosSalariales?.salarioBrutoMensual * 12
                    : this.getSalarioBrutoAnual(personaSel);
                let total =
                    salarioBrutoAnual *
                    (1 +
                        (personaSel.tblDatosSalariales && personaSel.tblDatosSalariales.percSegSocial
                            ? personaSel.tblDatosSalariales.percSegSocial
                            : 0));
                value = total;
            } else if (data.dataField === "salarioBrutoAnual") {
                let salario = isSalarioBrutoMensualManual
                    ? personaSel?.tblDatosSalariales?.salarioBrutoMensual * 12
                    : this.getSalarioBrutoAnual(personaSel);
                value = salario;
            }
        }

        if (data.dataField != "tblDatosSalariales.isTrienio") value = !value ? null : value;

        if (data.dataField === "idLocalizacion" && personaSel && personaSel["tblUsuario@odata.count"] === 0)
            return null;

        let formLabel = this["formLabel_" + data.dataField.replace(".", "_")];
        if (data.dataField == "numTrienios") {
            if (personaSel && personaSel.tblDatosSalariales && personaSel.tblDatosSalariales.isTrienio == 1) {
                formLabel = this["formLabel_numTrienios"];
            } else {
                formLabel = this["formLabel_numQuinquenios"];
            }
        }
        let date = new Date();

        let idTurno = personaSel ? personaSel.idTurno : null;
        let idLavanderia = null;
        let idCentroTrabajo = null;
        if (personaSel && personaSel.idCentroLav) {
            let parsed = JSON.parse(personaSel.idCentroLav);
            idCentroTrabajo = parsed[0] > 0 ? parsed[0] : null;
            idLavanderia = parsed[1] > 0 ? parsed[1] : null;
        }

        let datasource_tblTurno_filtrado = $.grep(datasource_tblTurno, function (item) {
            return (
                ((item.idTurnoPadreNavigation == null ||
                    (item.idTurnoPadreNavigation != null && $.inArray(item.idTurno, idsSubturnosVisibles) != -1)) &&
                    item.idLavanderia === idLavanderia) ||
                item.idTurno == -1
            ); //(item.idTurno == -1 && idLavanderia != null)
        });
        datasource_tblTurno_filtrado = [...datasource_tblTurno_filtrado];

        if (data.dataField === "idTurno") {
            let isInDataSource =
                $.grep(datasource_tblTurno_filtrado, function (item) {
                    return item.idTurno === value;
                }).length > 0;
            value = isInDataSource ? value : null;
        }

        let isImage =
            data.dataField == "idFotoDocumentoIdentidad_A" ||
            data.dataField == "idFotoDocumentoIdentidad_B" ||
            data.dataField == "idFotoNAF" ||
            data.dataField == "idFotoIBAN" ||
            data.dataField == "idFotoPerfil";
        let isTextArea = data.dataField == "observaciones" || data.dataField == "observacionesLaborales";
        let isRequired =
            (personaSel && personaSel.avisoLegalAceptado == true) || !personaSel
                ? data.dataField === "nombre" ||
                  data.dataField === "apellidos" ||
                  data.dataField === "email" ||
                  data.dataField === "telefono" ||
                  data.dataField === "idCentroLav" ||
                  data.dataField === "idCategoriaInterna" ||
                  data.dataField === "nacionalidad" ||
                  data.dataField === "IBAN" ||
                  data.dataField === "NAF" ||
                  data.dataField === "idTipoDocumentoIdentidad" ||
                  data.dataField === "numDocumentoIdentidad" ||
                  data.dataField === "calle" ||
                  data.dataField === "numDomicilio" ||
                  data.dataField === "codigoPostal" ||
                  data.dataField === "localidad" ||
                  data.dataField === "idComunidadAutonoma" ||
                  data.dataField === "idPais" ||
                  data.dataField === "idLocalizacion" ||
                  data.dataField === "idEmpresaPolarier" ||
                  data.dataField == "idGenero" ||
                  data.dataField == "fechaNacimiento" ||
                  data.dataField == "idNivelEstudios" ||
                  data.dataField === "idAdmCentroCoste" ||
                  data.dataField === "idAdmElementoPEP" ||
                  data.dataField == "idAdmCuentaContable_Salario" ||
                  data.dataField == "idAdmCuentaContable_SSEmpresa" ||
                  (idLavanderia &&
                      (data.dataField === "idTurno" ||
                          data.dataField === "horasDiarias" ||
                          data.dataField === "idTipoTrabajo" ||
                          data.dataField === "idAdmCentroCoste" ||
                          data.dataField === "idAdmElementoPEP" ||
                          data.dataField === "idCategoriaInterna")) ||
                  (idTurno &&
                      (data.dataField === "horasDiarias" ||
                          data.dataField === "idTipoTrabajo" ||
                          data.dataField === "idAdmCentroCoste" ||
                          data.dataField === "idAdmElementoPEP" ||
                          data.dataField === "idCategoriaInterna"))
                : data.dataField === "nombre" ||
                  data.dataField === "apellidos" ||
                  data.dataField === "email" ||
                  data.dataField === "telefono" ||
                  data.dataField === "idCentroLav" ||
                  data.dataField === "idLocalizacion" ||
                  data.dataField === "idEmpresaPolarier" ||
                  data.dataField === "numDocumentoIdentidad" ||
                  data.dataField === "idTipoDocumentoIdentidad" ||
                  data.dataField === "idCategoriaInterna" ||
                  data.dataField === "idAdmCentroCoste" ||
                  data.dataField === "idAdmElementoPEP" ||
                  (idLavanderia &&
                      (data.dataField === "idTurno" ||
                          data.dataField === "horasDiarias" ||
                          data.dataField === "idTipoTrabajo" ||
                          data.dataField === "idAdmCentroCoste" ||
                          data.dataField === "idAdmElementoPEP" ||
                          data.dataField === "idCategoriaInterna")) ||
                  (idTurno &&
                      (data.dataField === "horasDiarias" ||
                          data.dataField === "idTipoTrabajo" ||
                          data.dataField === "idAdmCentroCoste" ||
                          data.dataField === "idAdmElementoPEP" ||
                          data.dataField === "idCategoriaInterna"));

        let isDatosSalariales =
            data.dataField === "numAños" ||
            data.dataField === "numTrienios" ||
            data.dataField === "tblDatosSalariales.salarioBase" ||
            data.dataField === "tblDatosSalariales.plusAsistencia" ||
            data.dataField === "tblDatosSalariales.plusResponsabilidad" ||
            data.dataField === "tblDatosSalariales.plusPeligrosidad" ||
            data.dataField === "tblDatosSalariales.incentivo" ||
            data.dataField === "tblDatosSalariales.impHoraExtra" ||
            data.dataField === "tblDatosSalariales.plusProductividad" ||
            data.dataField === "tblDatosSalariales.numPagas" ||
            data.dataField === "tblDatosSalariales.percSegSocial" ||
            data.dataField === "salarioBrutoAnual" ||
            data.dataField === "costeEmpresa" ||
            data.dataField === "tblDatosSalariales.acuerdoNC" ||
            data.dataField === "tblDatosSalariales.salarioEspecie";

        let isFormatCurrency =
            data.dataField === "tblDatosSalariales.salarioBase" ||
            data.dataField === "tblDatosSalariales.plusAsistencia" ||
            data.dataField === "tblDatosSalariales.plusResponsabilidad" ||
            data.dataField === "tblDatosSalariales.plusPeligrosidad" ||
            data.dataField === "tblDatosSalariales.incentivo" ||
            data.dataField === "tblDatosSalariales.impHoraExtra" ||
            data.dataField === "tblDatosSalariales.plusProductividad" ||
            data.dataField === "salarioBrutoAnual" ||
            data.dataField === "costeEmpresa" ||
            data.dataField === "tblDatosSalariales.acuerdoNC" ||
            data.dataField === "tblDatosSalariales.salarioEspecie";

        let noLabel = isImage || data.dataField === "activo" || data.dataField == "tblDatosSalariales.isTrienio";
        let noIcon =
            data.dataField == "tblDatosSalariales.isTrienio" ||
            data.dataField === "tblDatosSalariales.fechaAntiguedad" ||
            data.dataField === "numAños" ||
            data.dataField === "numTrienios" ||
            data.dataField === "tblDatosSalariales.salarioBase" ||
            data.dataField === "tblDatosSalariales.plusAsistencia" ||
            data.dataField === "tblDatosSalariales.plusResponsabilidad" ||
            data.dataField === "tblDatosSalariales.plusPeligrosidad" ||
            data.dataField === "tblDatosSalariales.incentivo" ||
            data.dataField === "tblDatosSalariales.impHoraExtra" ||
            data.dataField === "tblDatosSalariales.plusProductividad" ||
            data.dataField === "tblDatosSalariales.numPagas" ||
            data.dataField === "tblDatosSalariales.percSegSocial" ||
            data.dataField === "salarioBrutoAnual" ||
            data.dataField === "costeEmpresa" ||
            data.dataField === "tblDatosSalariales.acuerdoNC" ||
            data.dataField === "tblDatosSalariales.salarioEspecie";

        let editor_cercaLabel =
            data.dataField === "NAF" || data.dataField === "IBAN" || data.dataField == "tblDatosSalariales.isTrienio";
        let editor_sm =
            data.dataField === "tblDatosSalariales.percSegSocial" ||
            data.dataField === "salarioBrutoAnual" ||
            data.dataField == "tblDatosSalariales.isTrienio" ||
            data.dataField === "nacionalidad" ||
            data.dataField === "idTipoDocumentoIdentidad" ||
            data.dataField === "numDocumentoIdentidad" ||
            data.dataField === "idTipoTrabajo";

        let editor_lg =
            data.dataField === "idCategoriaConvenio" ||
            data.dataField === "idCategoriaInterna" ||
            data.dataField === "idCentroLav";

        let flexLabel = editor_cercaLabel
            ? 2
            : editor_sm
            ? 8
            : editor_lg
            ? 3
            : data.dataField === "idTurno" || data.dataField === "horasDiarias"
            ? 3
            : data.dataField === "selectorDiasLibres"
            ? 4
            : data.dataField === "idFormatoDiasLibres" || data.dataField === "idAdmCentroCoste"
            ? 7
            : 6;
        let flexEditor = editor_cercaLabel
            ? 5
            : editor_sm
            ? 8
            : editor_lg
            ? 5
            : data.dataField === "idTurno" || data.dataField === "horasDiarias"
            ? 7
            : data.dataField === "selectorDiasLibres" ||
              data.dataField === "idAdmCuentaContable_Salario" ||
              data.dataField === "idAdmCuentaContable_SSEmpresa"
            ? 8
            : data.dataField === "idFormatoDiasLibres"
            ? 7
            : 7;
        let fotoActual =
            documentosPerfilPersona.length > 0
                ? $.grep(documentosPerfilPersona, function (item) {
                      return item && item.denominacion === data.dataField;
                  })
                : [{}];

        let validationGroup = this.isFieldDatosLaboralesValidator(data.dataField)
            ? "validacionDatosLaborales"
            : data.dataField === "codigoGestoria"
            ? "validacionCodigoGestoria"
            : "validacionPersona";

        let isSelectorDiasLibres = data.dataField == "selectorDiasLibres";
        let formatoDiasLibres =
            data.dataField == "selectorDiasLibres" && personaSel
                ? datasource_tblFormatoDiasLibres.find((x) => x.idFormatoDiasLibres === personaSel.idFormatoDiasLibres)
                : null;
        let isSelectorDiasLibresHeight = formatoDiasLibres
            ? formatoDiasLibres.numDiasLibres * 2 + formatoDiasLibres.numDiasLibres * 34
            : 0;
        let isGrid = data.dataField === "tblLavanderiaNUsuario";

        if (
            (data.dataField == "selectorDiasLibres" && !formatoDiasLibres) ||
            (data.dataField === "tblLavanderiaNUsuario" && !this.isVisible_selectorLavanderia()) ||
            (data.dataField === "isFormPedidoAllowed" && !this.isVisible_isFormPedidoAllowed()) ||
            (idCentroTrabajo != null &&
                (data.dataField === "idTipoTrabajo" ||
                    data.dataField === "idTurno" ||
                    data.dataField === "horasDiarias" ||
                    data.dataField === "idAdmElementoPEP")) ||
            (idLavanderia != null && data.dataField === "idAdmCentroCoste")
        )
            return null;

        return (
            <div
                className={
                    "container d-flex " +
                    (isTextArea ? "flex-column align-items-start " : "flex-row align-items-center px-0")
                }
                style={{
                    paddingTop:
                        data.dataField === "isFormPedidoAllowed" ||
                        data.dataField === "idLocalizacion" ||
                        data.dataField === "idEmpresaPolarier"
                            ? 10
                            : 0,
                }}
            >
                {formLabel && (formLabel.isVisible || formLabel.isVisible == null) ? (
                    <div
                        className={"flex-row " + (isTextArea ? "pt-2 pb-3 " : " ") + (noLabel ? "d-none" : "d-flex")}
                        style={{ flex: flexLabel, paddingRight: editor_lg ? 0 : 0 }}
                    >
                        <i
                            className={
                                "align-content-center font-size-lg pe-none align-middle pr-3 " +
                                (noLabel || noIcon ? "d-none " : " ") +
                                formLabel.icon
                            }
                        ></i>
                        <div className={"pr-3 pe-none " + (noLabel ? "d-none " : "")}>
                            {formLabel.text}
                            <span className="dx-field-item-required-mark p-absolute pl-1 ">{isRequired && " *"}</span>
                        </div>
                    </div>
                ) : null}
                <div
                    style={{
                        flex: flexEditor,
                        height: isGrid
                            ? null
                            : isSelectorDiasLibres
                            ? isSelectorDiasLibresHeight
                            : isImage
                            ? 120
                            : isDatosSalariales
                            ? 38
                            : isTextArea
                            ? 150
                            : 34,
                    }}
                    className={
                        "ml-auto d-flex " +
                        (data.dataField === "idFotoPerfil" ? "justify-content-center " : "justify-content-end ") +
                        (noLabel ? "d-none " : " ") +
                        (isTextArea ? "ml-0 w-100 " : " ")
                    }
                >
                    {data.dataField === "isFormPedidoAllowed" ? (
                        <Switch
                            name={data.dataField}
                            value={value}
                            width={150}
                            switchedOnText={getTrad("permitido")}
                            switchedOffText={getTrad("noPermitido")}
                            onValueChanged={this.dxFormEditor_onValueChanged}
                        />
                    ) : data.dataField == "numHijos" ||
                      data.dataField == "tallaPantalon" ||
                      data.dataField == "numDomicilio" ||
                      isDatosSalariales ? (
                        /* Selector numérico */
                        <NumberBox
                            name={data.dataField}
                            readOnly={
                                data.dataField === "numAños" ||
                                data.dataField === "numTrienios" ||
                                data.dataField === "salarioBrutoAnual" ||
                                data.dataField === "costeEmpresa"
                            }
                            stylingMode="underlined"
                            value={
                                !value
                                    ? data.dataField === "numAños" ||
                                      data.dataField === "numTrienios" ||
                                      data.dataField === "salarioBrutoAnual" ||
                                      data.dataField === "costeEmpresa"
                                        ? 0
                                        : null
                                    : data.dataField === "tblDatosSalariales.percSegSocial" || isFormatCurrency
                                    ? parseFloat(value)
                                    : parseInt(value)
                            }
                            step={0}
                            min={0}
                            max={
                                isDatosSalariales && data.dataField != "tblDatosSalariales.percSegSocial"
                                    ? 999999999999.99
                                    : data.dataField == "tblDatosSalariales.percSegSocial"
                                    ? 1
                                    : 9999
                            }
                            format={
                                isFormatCurrency
                                    ? {
                                          style: "currency",
                                          maximumFractionDigits: 2,
                                          currency: "EUR",
                                      }
                                    : data.dataField == "tblDatosSalariales.percSegSocial"
                                    ? { style: "percent", maximumFractionDigits: 1 }
                                    : null
                            }
                            width="100%"
                            onContentReady={this.dxFormEditor_onContentReady}
                            onValueChanged={this.dxFormEditor_onValueChanged}
                        >
                            <Validator validationGroup={validationGroup}>{isRequired && <RequiredRule />}</Validator>
                        </NumberBox>
                    ) : data.dataField == "caducidadDocumentoIdentidad" ||
                      data.dataField == "fechaNacimiento" ||
                      data.dataField == "tblDatosSalariales.fechaAntiguedad" ? (
                        /* Selector fechas */
                        <DateBox
                            displayFormat="dd/MM/yyyy"
                            disabled={data.dataField == "tblDatosSalariales.fechaAntiguedad" && value != null}
                            name={data.dataField}
                            stylingMode="underlined"
                            value={value}
                            min={
                                data.dataField == "caducidadDocumentoIdentidad"
                                    ? date.setFullYear(date.getFullYear() - 2)
                                    : null
                            }
                            max={data.dataField == "fechaNacimiento" ? date.setFullYear(date.getFullYear() - 15) : null}
                            width="100%"
                            onContentReady={this.dxFormEditor_onContentReady}
                            onValueChanged={this.dxFormEditor_onValueChanged}
                        >
                            <Validator validationGroup={validationGroup}>{isRequired && <RequiredRule />}</Validator>
                        </DateBox>
                    ) : data.dataField == "idTallaAlfa_Camiseta" ||
                      data.dataField == "idDiscapacidad" ||
                      data.dataField == "idNivelEstudios" ||
                      data.dataField == "idPais" ||
                      data.dataField == "idGenero" ||
                      data.dataField == "idTipoDocumentoIdentidad" ||
                      data.dataField == "idEstadoCivil" ||
                      data.dataField == "idComunidadAutonoma" ||
                      data.dataField == "idCategoriaConvenio" ||
                      data.dataField == "idCategoriaInterna" ||
                      data.dataField == "idTipoTrabajo" ||
                      data.dataField == "idAdmElementoPEP" ||
                      data.dataField == "idAdmCentroCoste" ||
                      data.dataField == "idTurno" ||
                      data.dataField == "idLocalizacion" ||
                      data.dataField == "idEmpresaPolarier" ||
                      data.dataField == "idFormatoDiasLibres" ||
                      data.dataField == "idAdmCuentaContable_Salario" ||
                      data.dataField == "idAdmCuentaContable_SSEmpresa" ? (
                        /* Selector items */
                        <SelectBox
                            name={data.dataField}
                            dataSource={
                                data.dataField == "idTallaAlfa_Camiseta"
                                    ? datasource_tblTallaAlfa
                                    : data.dataField == "idDiscapacidad"
                                    ? datasource_tblDiscapacidad
                                    : data.dataField == "idNivelEstudios"
                                    ? datasource_tblNivelEstudios
                                    : data.dataField == "idPais"
                                    ? datasource_tblPais
                                    : data.dataField == "idGenero"
                                    ? datasource_tblGenero
                                    : data.dataField == "idTipoDocumentoIdentidad"
                                    ? datasource_tblTipoDocumentoIdentidad
                                    : data.dataField == "idEstadoCivil"
                                    ? datasource_tblEstadoCivil
                                    : data.dataField == "idComunidadAutonoma"
                                    ? datasource_tblComunidadAutonoma
                                    : data.dataField == "idTipoTrabajo"
                                    ? datasource_tblTipoTrabajo
                                    : data.dataField == "idTurno"
                                    ? datasource_tblTurno_filtrado
                                    : data.dataField == "idLocalizacion"
                                    ? datasource_tblLocalizacion
                                    : data.dataField == "idEmpresaPolarier"
                                    ? datasource_tblEmpresasPolarier
                                    : data.dataField == "idCategoriaConvenio"
                                    ? this.datasource_tblCategoriaConvenio
                                    : data.dataField == "idCategoriaInterna"
                                    ? this.datasource_tblCategoriaInterna
                                    : data.dataField == "idFormatoDiasLibres"
                                    ? this.datasource_tblFormatoDiasLibres
                                    : data.dataField == "idAdmElementoPEP"
                                    ? this.datasource_tblAdmElementoPEP
                                    : data.dataField == "idAdmCentroCoste"
                                    ? this.datasource_tblAdmCentroCoste
                                    : data.dataField == "idAdmCuentaContable_Salario" ||
                                      data.dataField == "idAdmCuentaContable_SSEmpresa"
                                    ? this.datasource_tblAdmCuentaContable
                                    : []
                            }
                            displayExpr={
                                data.dataField == "idCategoriaInterna" || data.dataField == "idCategoriaConvenio"
                                    ? this.dxSelectBox_displayExpr_upperCase
                                    : data.dataField == "idAdmElementoPEP" ||
                                      data.dataField == "idAdmCentroCoste" ||
                                      data.dataField == "idAdmCuentaContable_Salario" ||
                                      data.dataField == "idAdmCuentaContable_SSEmpresa"
                                    ? this.dxSelectBox_displayExpr_idAdm
                                    : this.dxSelectBox_displayExpr
                            }
                            valueExpr={
                                data.dataField == "idTallaAlfa_Camiseta"
                                    ? "idTallaAlfa"
                                    : data.dataField == "idAdmCuentaContable_Salario" ||
                                      data.dataField == "idAdmCuentaContable_SSEmpresa"
                                    ? "idAdmCuentaContable"
                                    : data.dataField
                            }
                            value={
                                (data.dataField === "idTurno" && datasource_tblTurno_filtrado.length > 0) ||
                                data.dataField !== "idTurno"
                                    ? value
                                    : null
                            }
                            width="100%"
                            showClearButton={data.dataField == "idTurno"}
                            stylingMode={
                                data.dataField == "idAdmElementoPEP" ||
                                ((data.dataField == "idAdmCuentaContable_SSEmpresa" ||
                                    data.dataField == "idAdmCuentaContable_Salario") &&
                                    user.idUsuario !== 56 &&
                                    user.idCargo !== 1)
                                    ? "filled"
                                    : "underlined"
                            }
                            onInitialized={this.dxFormEditor_onContentReady}
                            onValueChanged={this.dxFormEditor_onValueChanged}
                            readOnly={
                                data.dataField == "idAdmElementoPEP" ||
                                ((data.dataField == "idAdmCuentaContable_SSEmpresa" ||
                                    data.dataField == "idAdmCuentaContable_Salario") &&
                                    user.idUsuario !== 56 &&
                                    user.idCargo !== 1)
                            }
                            dropDownOptions={
                                data.dataField == "idadmElementoPEP" ||
                                data.dataField == "idAdmCentroCoste" ||
                                data.dataField == "idAdmCuentaContable_Salario" ||
                                data.dataField == "idAdmCuentaContable_SSEmpresa"
                                    ? this.dxSelectBox_dropdowOptions
                                    : null
                            }
                            searchEnabled={
                                data.dataField == "idadmElementoPEP" ||
                                data.dataField == "idAdmCentroCoste" ||
                                data.dataField == "idAdmCuentaContable_Salario" ||
                                data.dataField == "idAdmCuentaContable_SSEmpresa"
                            }
                        >
                            <Validator validationGroup={validationGroup}>{isRequired && <RequiredRule />}</Validator>
                        </SelectBox>
                    ) : data.dataField == "idCentroLav" || data.dataField == "tblDatosSalariales.isTrienio" ? (
                        <Lookup
                            name={data.dataField}
                            dataSource={
                                data.dataField == "idCentroLav"
                                    ? new DataSource({
                                          group: "groupTitle",
                                          store: datasource_tblCentroTrabajo_Lavanderia,
                                      })
                                    : this.enum_isTrienio
                            }
                            className={
                                data.dataField != "idCentroLav"
                                    ? ""
                                    : value == null
                                    ? "idCentroLav_placeholder"
                                    : "idCentroLav_value"
                            }
                            displayExpr={
                                data.dataField == "tblDatosSalariales.isTrienio"
                                    ? "denominacion"
                                    : this.dxSelectBox_displayExpr
                            }
                            valueExpr={data.dataField == "tblDatosSalariales.isTrienio" ? "value" : data.dataField}
                            value={data.dataField == "tblDatosSalariales.isTrienio" ? +value : value}
                            width="100%"
                            stylingMode="underlined"
                            grouped={data.dataField == "idCentroLav"}
                            searchEnabled={false}
                            showCancelButton={false}
                            onContentReady={this.dxLookup_onContenReady}
                            onInitialized={this.dxFormEditor_onContentReady}
                            onValueChanged={this.dxFormEditor_onValueChanged}
                        >
                            <DropDownOptions showTitle={data.dataField == "idCentroLav"}></DropDownOptions>
                            <Validator validationGroup={validationGroup}>
                                <RequiredRule />
                                <CustomRule
                                    reevaluate={true}
                                    validationCallback={this.validationCallback_CustomRule_formItemRender_idCentroLav}
                                />
                            </Validator>
                        </Lookup>
                    ) : isImage ? (
                        <TileView
                            elementAttr={data.dataField === "idFotoPerfil" ? this.circular_tileView : null}
                            itemMargin={this.dxTileViewItems_size.fotos.itemMargin}
                            baseItemWidth={
                                data.dataField === "idFotoPerfil"
                                    ? this.dxTileViewItems_size.fotoPerfil.width
                                    : this.dxTileViewItems_size.fotos.width
                            }
                            activeStateEnabled={false}
                            items={fotoActual.length > 0 ? fotoActual : [{}]}
                            itemRender={(e) => {
                                return this.fotos_itemRender(e, data);
                            }}
                        />
                    ) : data.dataField == "nacionalidad" ? (
                        <CountryPicker value={value} updateValor_paises={this.updateValor_paises} />
                    ) : data.dataField == "telefono" || data.dataField == "telefonoEmpresa" ? (
                        <ReactPhoneInput
                            inputClass={validationError_telefono ? "validationError_telefono" : ""}
                            country="es"
                            localization={es}
                            preferredCountries={["es"]}
                            enableSearch={true}
                            placeholder=""
                            searchPlaceholder={"Buscar..."}
                            searchClass="search-class"
                            searchStyle={{ margin: "0", width: "97%", height: "30px" }}
                            disableSearchIcon={true}
                            inputProps={{
                                required: true,
                                bordercolor: "red",
                            }}
                            value={
                                personaSel
                                    ? "+" +
                                      ((data.dataField === "telefono"
                                          ? personaSel.prefijoTelefonico
                                          : personaSel.prefijoTelefonicoEmpresa) ?? "34") +
                                      " " +
                                      value
                                    : ""
                            }
                            onChange={
                                data.dataField == "telefono"
                                    ? this.dxFormEditor_telefonoPersonal_onValueChanged
                                    : this.dxFormEditor_telefonoEmpresa_onValueChanged
                            }
                        />
                    ) : data.dataField == "observaciones" || data.dataField == "observacionesLaborales" ? (
                        <TextArea
                            name={data.dataField}
                            height={70}
                            width="100%"
                            stylingMode={
                                data.dataField == "observaciones" || data.dataField == "observacionesLaborales"
                                    ? "outlined"
                                    : "underlined"
                            }
                            value={value}
                            onContentReady={this.dxFormEditor_onContentReady}
                            onValueChanged={this.dxFormEditor_onValueChanged}
                        />
                    ) : data.dataField == "selectorDiasLibres" ? (
                        <div className="d-flex w-100 justify-content-end">
                            <SelectorDiasLibres
                                stylingMode="underlined"
                                formato={formatoDiasLibres}
                                persona={personaSel}
                                isLabelsVisible={true}
                                //isRequired={personaSel && personaSel.idTurno == -1}
                                onContentReady={this.dxFormEditor_onContentReady}
                                onValidChange={this.diasLibres_onValidChange}
                                onValueChanged={this.diasLibres_modify_onValueChanged}
                            />
                        </div>
                    ) : data.dataField == "tblLavanderiaNUsuario" ? (
                        <div className="dxForm_group">
                            <SelectorLavanderias personaSel={personaSel} onChange={this.onChange_selectorLavanderia} />
                        </div>
                    ) : data.dataField == "idUsuario_validacion_nomina" ? (
                        <SelectBox
                            name={data.dataField}
                            width={"100%"}
                            value={personaSel?.idUsuario_validacion_nomina}
                            dataSource={this.state.tblUsuario_Validacion}
                            valueExpr={"idUsuario"}
                            displayExpr={"nombreCompleto"}
                            searchExpr={"nombreCompleto"}
                            searchMode="contains"
                            searchEnabled={true}
                            stylingMode="underlined"
                            onValueChanged={this.dxFormEditor_onValueChanged}
                        />
                    ) : (
                        <TextBox
                            name={data.dataField}
                            width="100%"
                            stylingMode={
                                data.dataField == "codigoGestoria" &&
                                personaSel &&
                                personaSel.isCodigoGestoriaValidado &&
                                personaSel.activo
                                    ? "filled"
                                    : "underlined"
                            }
                            value={data.dataField == "horasDiarias" && value == ":" ? "00:00" : value}
                            readOnly={
                                data.dataField == "codigoGestoria" &&
                                personaSel &&
                                personaSel.isCodigoGestoriaValidado &&
                                personaSel.activo
                            }
                            maxLength={data.dataField == "codigoGestoria" ? 6 : -1}
                            className={data.dataField == "numDocumentoIdentidad" ? "textUpperCase" : null}
                            mask={data.dataField == "horasDiarias" ? "00:00" : ""}
                            useMaskedValue={data.dataField == "horasDiarias"}
                            onKeyDown={
                                data.dataField == "numDocumentoIdentidad" ||
                                data.dataField == "codigoGestoria" ||
                                data.dataField == "email" ||
                                data.dataField == "NAF" ||
                                data.dataField == "codigoPostal"
                                    ? this.dxTextBox_onKeyPress_noEspacio
                                    : null
                            }
                            onContentReady={this.dxFormEditor_onContentReady}
                            onValueChanged={this.dxFormEditor_onValueChanged}
                        >
                            <Validator ref={this.dxValidator_codigoGestoria_REF} validationGroup={validationGroup}>
                                {isRequired && <RequiredRule />}

                                {data.dataField == "codigoGestoria" && <NumericRule />}
                                {data.dataField == "codigoGestoria" && (
                                    <AsyncRule
                                        ignoreEmptyValue={true}
                                        reevaluate={true}
                                        message="El código gestoría introducido ya está asignado a otra persona"
                                        validationCallback={this.validateAsync_codigoGestoria}
                                    />
                                )}
                                {data.dataField == "codigoGestoria" && (
                                    <PatternRule pattern={/^.{6,}$/} message={this.getTrad("validation_Formato")} />
                                )}

                                {data.dataField == "nombre" ||
                                    (data.dataField == "apellidos" && (
                                        <PatternRule
                                            ignoreEmptyValue={true}
                                            pattern={this.noNum_pattern}
                                            message={this.getTrad("validation_NoNumeros")}
                                        />
                                    ))}

                                {data.dataField == "email" && <EmailRule />}

                                {data.dataField == "telefono" ||
                                    (data.dataField == "telefonoEmpresa" && (
                                        <PatternRule
                                            pattern={phone_Pattern}
                                            message={this.getTrad("validation_Formato")}
                                        />
                                    ))}

                                {data.dataField == "horasDiarias" && isRequired && (
                                    <PatternRule pattern={this.horasSemanales_pattern} message={"Tiempo incorrecto"} />
                                )}

                                {data.dataField == "numDocumentoIdentidad" && (
                                    <AsyncRule
                                        ignoreEmptyValue={true}
                                        reevaluate={true}
                                        message="Este nº documento ya ha sido registrado"
                                        validationCallback={
                                            this.dxValidator_numDocumentoIdentidad_asyncRule_validationCallback
                                        }
                                    />
                                )}
                            </Validator>
                        </TextBox>
                    )}
                </div>
            </div>
        );
    }

    salarioBrutoMensual_render = (data) => {
        const { personaSel, isSalarioBrutoMensualManual } = this.state;

        const format = {
            style: "currency",
            maximumFractionDigits: 2,
            currency: "EUR",
        };

        return (
            <Box align={"center"} direction={"row"} height={"100%"} width={"100%"}>
                <ItemBox ratio={3}>
                    <Box align={"center"} direction={"row"} height={"100%"} width={"100%"}>
                        <ItemBox baseSize={30}>
                            <div className={"he-100 d-flex align-items-center"}>
                                <i
                                    className={`font-size-lg pe-none align-middle pr-3 ${this.formLabel_salarioBrutoMensual.icon}`}
                                />
                            </div>
                        </ItemBox>
                        <ItemBox ratio={1}>
                            <div className={"pr-3 pe-none he-100 d-flex align-items-center"}>
                                {this.formLabel_salarioBrutoMensual.text}
                            </div>
                        </ItemBox>
                    </Box>
                </ItemBox>
                <ItemBox ratio={2}>
                    <NumberBox
                        name={data.dataField}
                        stylingMode={isSalarioBrutoMensualManual ? "underlined" : "filled"}
                        readOnly={!isSalarioBrutoMensualManual}
                        value={
                            isSalarioBrutoMensualManual
                                ? personaSel?.tblDatosSalariales?.salarioBrutoMensual ?? 0
                                : personaSel
                                ? this.getSalarioBrutoAnual(personaSel) / 12
                                : 0
                        }
                        step={0}
                        min={0}
                        max={999999999999.99}
                        format={format}
                        width={"100%"}
                        onContentReady={this.dxFormEditor_onContentReady}
                        onValueChanged={this.salarioBrutoMensual_onValueChanged}
                    />
                </ItemBox>
                <ItemBox baseSize={20} />
                <ItemBox baseSize={150}>
                    <Switch
                        value={isSalarioBrutoMensualManual}
                        switchedOffText={getTrad("calculado")}
                        switchedOnText={getTrad("manual")}
                        className={"no-disabled"}
                        onValueChanged={this.switchSalarioBrutoMensual_onValueChanged}
                    />
                </ItemBox>
            </Box>
        );
    };

    switchSalarioBrutoMensual_onValueChanged = (e) => {
        const personaSel = { ...this.state.personaSel };
        let newState = { isSalarioBrutoMensualManual: e.value };

        if (e.value && personaSel.tblDatosSalariales.salarioBrutoMensual == null) {
            const value = this.getSalarioBrutoAnual(personaSel) / 12;

            personaSel.tblDatosSalariales.salarioBrutoMensual = value;
            newState.personaSel = personaSel;

            let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;

            this.dxDataGrid_personas.cellValue(index, "tblDatosSalariales.salarioBrutoMensual", value);
        }

        this.setState(newState);
    };

    salarioBrutoMensual_onValueChanged = (e) => {
        let dataField = e.component.option("name");
        let personaSel = { ...this.state.personaSel };

        let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;

        personaSel.tblDatosSalariales.salarioBrutoMensual = e.value;

        this.setState({ personaSel });

        this.dxDataGrid_personas.cellValue(index, dataField, e.value);
    };

    dxTextBox_onKeyPress_noEspacio = (e) => {
        if (e.event.originalEvent.keyCode == 32) e.event.preventDefault();
    };

    dxSelectBox_dropdowOptions = { minWidth: 450 };

    dxSelectBox_displayExpr = function (item) {
        if (!item) return null;
        return item.denominacion;
    };

    dxSelectBox_displayExpr_idAdm = function (item) {
        if (!item) return null;
        return item.codigo + " - " + item.denominacion;
    };

    dxSelectBox_displayExpr_upperCase = function (item) {
        if (!item) return null;
        return item.denominacion.toUpperCase();
    };

    fotos_itemRender(foto, data) {
        let { user } = this.props;
        if (!foto.documento) {
            let emptyIcon;

            switch (data.dataField) {
                case "idFotoPerfil":
                    emptyIcon = null;
                    break;
                case "idFotoDocumentoIdentidad_A":
                    emptyIcon = "icon_badge_account_horizontal_outline";
                    break;
                case "idFotoDocumentoIdentidad_B":
                    emptyIcon = "icon_badge_account_horizontal_outline";
                    break;
                case "idFotoNAF":
                    emptyIcon = "icon_id_card";
                    break;
                case "idFotoIBAN":
                    emptyIcon = "icon_credit_card_outline";
                    break;
            }

            return (
                <div
                    className={
                        emptyIcon + " he-100 align-items-center justify-content-center font-size-max d-flex pointer"
                    }
                    onClick={() => {
                        this.dxTile_onClick(data.dataField, foto);
                    }}
                >
                    {!emptyIcon && <LottieIcon height="70%" width="70%" icon={"defaultUserPhoto"} />}
                </div>
            );
        }

        return (
            <Fragment>
                <img
                    className="image pointer"
                    style={{
                        backgroundImage: "url(data:" + foto.extension + ";base64," + foto.documento + ")",
                    }}
                    onClick={
                        user.enableDatosRRHH
                            ? () => {
                                  this.dxTile_onClick(data.dataField, foto);
                              }
                            : null
                    }
                />
                {user.enableDatosRRHH && (
                    <Toolbar elementAttr={this.template_tile_documento_elemAttr}>
                        <ToolbarItem location="after" render={(e) => this.btnDownloadDocument_template(e, data)} />
                        <ToolbarItem location="after" render={(e) => this.btnRemoveDocument_template(e, data)} />
                    </Toolbar>
                )}
            </Fragment>
        );
    }

    btnDownloadDocument_onClick(e, itemData) {
        let downloadLink = document.createElement("a");
        if (itemData.documento) {
            downloadLink.href = "data:" + itemData.extension + ";base64," + itemData.documento;
            downloadLink.download = itemData.nombre;
            downloadLink.click();
        } else {
            let { documentosPerfilPersona } = this.state;
            let docFiltrado = $.grep(documentosPerfilPersona, function (item) {
                return item.denominacion == itemData.dataField;
            });
            if (docFiltrado.length > 0) {
                downloadLink.href = "data:" + docFiltrado[0].extension + ";base64," + docFiltrado[0].documento;
                downloadLink.download = this["formLabel_" + itemData.dataField.replace(".", "_")].text;
                downloadLink.click();
            }
        }
    }

    btnDownloadDocument_template(e, itemData) {
        return (
            <div className="container_spanCentrado dxToolbar_opcion mr-1">
                <div
                    className="icon_Arrow_Down icon_color_hover iconos_svg"
                    onClick={(e) => this.btnDownloadDocument_onClick(e, itemData)}
                ></div>
            </div>
        );
    }

    btnRemoveDocument_template(e, itemData) {
        return (
            <div className="container_spanCentrado dxToolbar_opcion">
                <div
                    className="icon_Cerrar icon_color_hover iconos_svg"
                    onClick={(e) => this.btnRemoveDocument_onClick(e, itemData)}
                ></div>
            </div>
        );
    }

    btnRemoveDocument_onClick(e, itemData) {
        let _this = this;
        let { removeFotos, personaSel } = this.state;
        let value = personaSel[itemData.dataField];
        dxMensajePregunta(this.getTrad("preg_RegistroEliminarCorto"), [
            [
                _this.getTrad("aceptar"),
                function () {
                    let documentosPerfilPersona = [..._this.state.documentosPerfilPersona];

                    let removeItem = {
                        idDocumento: value,
                        extension: "image/png",
                        isRemove: true,
                    };

                    let personaSel = { ..._this.state.personaSel };
                    personaSel[itemData.dataField] = null;
                    let docsFiltrados = $.grep(documentosPerfilPersona, function (item) {
                        return item.denominacion != itemData.dataField;
                    });

                    let index = personaSel ? _this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                    _this.dxDataGrid_personas.cellValue(index, itemData.dataField, null);
                    _this.dxDataGrid_personas.cellValue(index, itemData.dataField + "Navigation", null);

                    let isFoto_inArray =
                        removeFotos.filter(function (foto) {
                            return foto.idDocumento === removeItem.idDocumento;
                        }).length > 0;
                    if (!isFoto_inArray) {
                        removeFotos.push(removeItem);
                    }

                    _this.setState({
                        personaSel: personaSel,
                        removeFotos: removeFotos,
                        documentosPerfilPersona: docsFiltrados,
                    });
                },
                "danger",
            ],
            [_this.getTrad("cancelar"), function () {}],
        ]);
    }

    dxTile_onClick(dataField, archivo) {
        if (dataField) {
            this.setState({
                isVisible_GaleriaArchivos: true,
                dataField_archivoSel: dataField,
                archivoSel: $.isEmptyObject(archivo) ? null : archivo,
            });
        }
    }

    fotos_onUploadStarted(e) {
        let personaSel = { ...this.state.personaSel };
        let { dataField_archivoSel } = this.state;

        let reader = new FileReader(),
            _this = this;

        if (e.file.size > 5242880) {
            notify({
                message: _this.getTrad("subirArchivo"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        } else {
            reader.onloadend = function () {
                if (reader.result !== "") {
                    let fileSplit = reader.result.split(",");
                    let extension = e.file.type;
                    let base64 = fileSplit[1];

                    let idDocumento = personaSel[dataField_archivoSel];

                    function insertarDocumento() {
                        let addedItem = {
                            idDocumento: idDocumento,
                            denominacion: dataField_archivoSel,
                            documento: base64,
                            extension: extension,
                            fecha: new Date(),
                            fechaModificacion: new Date(),
                            idPersona: personaSel.idPersona,
                            idCarpetaDocumentos: null,
                        };
                        e.component.option({
                            value: [],
                        });

                        if (!idDocumento) delete addedItem.idDocumento;

                        _this.setState({ archivoSel: addedItem });
                    }

                    //Compress
                    if (extension.split("/")[0] === "image") {
                        compressBase64Img_scaleWidth(reader.result, 800).then(function (compressedBase64) {
                            base64 = compressedBase64;
                            insertarDocumento();
                        });
                    } else {
                        insertarDocumento();
                    }
                }
            };
            reader.readAsDataURL(e.file);
        }
        e.request.abort();
    }

    dxPopup_galeriaFotos_aceptar_onClick(e) {
        let personaSel = { ...this.state.personaSel };
        let { archivoSel, dataField_archivoSel } = this.state;
        let documentosPerfilPersona = [...this.state.documentosPerfilPersona];

        let docsFiltrados = $.grep(documentosPerfilPersona, function (item) {
            return item && item.denominacion != dataField_archivoSel;
        });
        docsFiltrados.push(archivoSel);

        let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        this.dxDataGrid_personas.cellValue(index, dataField_archivoSel + "Navigation", archivoSel);
        this.setState({
            personaSel: personaSel,
            isVisible_GaleriaArchivos: false,
            documentosPerfilPersona: docsFiltrados,
        });
    }

    numAños_calculateCellValue(rowData) {
        let numAños = 0;
        if (rowData.tblDatosSalariales && rowData.tblDatosSalariales.fechaAntiguedad)
            numAños =
                new Date(new Date().getTime() - rowData.tblDatosSalariales.fechaAntiguedad.getTime()).getUTCFullYear() -
                1970;
        return numAños;
    }

    numTrienios_calculateCellValue(rowData) {
        let numTrienios = 0,
            numQuinquenios = 0;
        if (rowData.tblDatosSalariales && rowData.tblDatosSalariales.fechaAntiguedad) {
            let numAños =
                new Date(new Date().getTime() - rowData.tblDatosSalariales.fechaAntiguedad.getTime()).getUTCFullYear() -
                1970;
            numTrienios = Math.floor(numAños / 3);
            numQuinquenios = Math.floor(numAños / 5);
        }
        return rowData.tblDatosSalariales.isTrienio == 1 ? numTrienios : numQuinquenios;
    }

    salarioBrutoAnual_calculateCellValue(rowData) {
        let salario = this.getSalarioBrutoAnual(rowData);
        return salario ? salario : 0;
    }

    costeEmpresa_calculateCellValue(rowData) {
        let salarioBrutoAnual = this.getSalarioBrutoAnual(rowData);
        let total =
            salarioBrutoAnual *
            (1 +
                (rowData.tblDatosSalariales && rowData.tblDatosSalariales.percSegSocial
                    ? rowData.tblDatosSalariales.percSegSocial
                    : 0));
        return total ? total : 0;
    }

    fechaAntiguedad_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.fechaAntiguedad = value;

        let numAños = new Date(new Date().getTime() - value.getTime()).getUTCFullYear() - 1970;
        newData.numAños = numAños;

        let añosTrienio = currentRowData.tblDatosSalariales.isTrienio == 1 ? 3 : 5;
        newData.numTrienios = Math.floor(numAños / añosTrienio);
    }

    isTrienio_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.isTrienio = value;
    }

    plusAsistencia_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.plusAsistencia = value;
    }

    plusResponsabilidad_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.plusResponsabilidad = value;
    }

    plusPeligrosidad_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.plusPeligrosidad = value;
    }

    incentivo_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.incentivo = value;
    }

    impHoraExtra_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.impHoraExtra = value;
    }

    plusProductividad_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.plusProductividad = value;
    }

    percSegSocial_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.percSegSocial = value;
    }

    acuerdoNC_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.acuerdoNC = value;
    }

    salarioEspecie_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.salarioEspecie = value;
    }

    salarioBase_setCellValue(newData, value, currentRowData) {
        newData.tblDatosSalariales = currentRowData.tblDatosSalariales != null ? currentRowData.tblDatosSalariales : {};
        newData.tblDatosSalariales.salarioBase = value;
    }

    dxPopup_personal_ImportarDocumentos_onContentReady(e) {
        e.component.content().attr("id", "dxPopup_personal_ImportarDocumentos");
    }

    onShown_popup_nombrarNominas(e) {
        this.dxTextBox_nombre_nombrarNominas.focus();
    }

    onHiding_popup_modificarNominas(e) {
        this.setState({
            popup_nombrarNominas_visible: false,
            formData_nombrarNominas: {},
        });
        this.dxForm_nombrarNominas.resetValues();
    }

    dxButton_nombrarNominas_guardar_onClick(e) {
        let formData_nombrarNominas = $.extend(true, {}, this.state.formData_nombrarNominas);
        let isValid = this.dxForm_nombrarNominas.validate().isValid;

        if (
            isValid ||
            (formData_nombrarNominas.nombre != null &&
                formData_nombrarNominas.nombre != "" &&
                formData_nombrarNominas.idCarpetaDocumentos != null)
        ) {
            let { documentosImportarDocumentos } = this.state;
            let documentosFiltrados = documentosImportarDocumentos.filter((x) => !x.error);
            documentosFiltrados.forEach((x) => {
                x.nombre = formData_nombrarNominas.nombre;
                x.idCarpetaDocumentos = formData_nombrarNominas.idCarpetaDocumentos;
            });

            let _this = this;
            this.setState({
                numArchivos_LoadPanel_ProgressBar: documentosFiltrados.length,
                isVisible_LoadPanel_ProgressBar: true,
                isVisible_dxPopup_ImportarDocumentos: false,
                popup_nombrarNominas_visible: false,
                progress: 0,
            });

            this.loadPanel_show(true);

            signalRCoreService.setEvents([
                {
                    type: "initProgressBar",
                    listener: () => _this.setState({ progress: 0 }),
                },
                {
                    type: "updateProgressBar",
                    listener: (perc) => _this.setState({ progress: parseInt(perc) }),
                },
            ]);

            setTimeout(() => {
                this.context_documento.invoke("fn_importacionMasivaDocumentos", {}, "POST").done(function (result) {
                    notify({
                        message: _this.getTrad("aviso_C_RegistroActualizado"),
                        type: "success",
                        displayTime: "1500",
                        closeOnClick: true,
                    });

                    _this.setState({
                        documentosImportarDocumentos: [],
                        numArchivos_LoadPanel_ProgressBar: 0,
                        numNominasError: 0,
                        nombreNominasImportadas: null,
                        isVisible_LoadPanel_ProgressBar: false,
                        isVisible_dxPopup_ImportarDocumentos: false,
                        formData_nombrarNominas: {},
                        progress: 100,
                    });

                    _this.dxDataGrid_personas.refresh();
                    _this.dxForm_nombrarNominas.resetValues();
                    _this.loadPanel_hide();
                    signalRCoreService.clearEvents();
                });
            }, 200);
        }
    }

    dxTextBox_nombrarNominas_onValueChanged(e) {
        let formData_nombrarNominas = { ...this.state.formData_nombrarNominas };
        formData_nombrarNominas.nombre = e.value;

        this.setState({ formData_nombrarNominas });
    }

    dxSelectBox_nombrarNominas_onValueChanged(e) {
        let formData_nombrarNominas = { ...this.state.formData_nombrarNominas };
        formData_nombrarNominas.idCarpetaDocumentos = e.value;

        this.setState({ formData_nombrarNominas });
    }

    dxPopup_fichaPersona_addPersona_onContentReady(e) {
        e.component.content().attr("id", "dxPopup_personal_addPersona_fichaPersona");

        events.on(e.component.content(), "dxmousewheel", null, function (ev) {
            ev.stopPropagation();
        });
    }

    dxPopup_fichaPersona_onContentReady(e) {
        let personaSel = { ...this.state.personaSel };
        if (personaSel && personaSel.idPersona) {
            let value = JSON.parse(personaSel.idCentroLav);
            let idLavanderia = value.length == 0 || value[1] <= 0 ? null : value[1];

            let { datasource_tblTurno_todos } = this.state;
            let turnos = [];
            $.each(datasource_tblTurno_todos, function (index, item) {
                if (item.idLavanderia === idLavanderia) turnos.push(item);
            });
        }
        e.component.content().attr("id", "dxPopup_personal_fichaPersona");
        e.component.content().parent().css("opacity", "1");
    }

    dxPopup_fichaPersona_onShown(e) {
        let _this = this;
        let { user } = this.props;
        let { datasource_datos_loadPromise_fotos } = this.state;

        if (user.enableDatosRRHH) {
            let dataSource_documentos_bk = $.extend(true, [], this.state.dataSource_documentos_bk);
            this.setState({
                showLoading_tabDocumentos: true,
                showLoading_tabDatosLaborales: true,
                showLoading_tabDatosPersonales: true,
                tabPanelPersona_deferRendering: false,
                calendar_firstRender: true,
            });

            // Con $.when devuelve los items vacíos
            this.datasource_tblDocumento.load().done(function (documentos) {
                $.each(documentos, function (index, item) {
                    $.each(dataSource_documentos_bk[0].items, function (iCarpeta, carpeta) {
                        if (
                            carpeta.idCarpetaDocumentos === item.idCarpetaDocumentos &&
                            carpeta.tblDocumento.findIndex((x) => x.idDocumento === item.idDocumento) === -1
                        ) {
                            carpeta.tblDocumento.push(item);
                        }
                    });
                });

                _this.setState({
                    dataSource_documentos: dataSource_documentos_bk,
                    // carpetaDocumentosSel: dataSource_documentos_bk.length > 0 ? dataSource_documentos_bk[0].items[0] : null,
                    datasource_datos_loadPromise_documentos: null,
                    showLoading_tabDocumentos: false,
                });
            });
        } else {
            _this.setState({
                showLoading_tabDocumentos: false,
            });
        }

        this.datasource_tblPersonaNTipoContrato.load().done(function (tblPersonaNTipoContrato) {
            let personaSel = { ..._this.state.personaSel };
            personaSel.tblPersonaNTipoContrato = tblPersonaNTipoContrato;

            _this.setState(
                {
                    personaSel: personaSel,
                    showLoading_tabDatosLaborales: false,
                },
                () => {
                    _this.loadCalendar({ currentYear: _this.state.yearCalendar_currentYear }, true);
                }
            );
        });

        datasource_datos_loadPromise_fotos = this.datasource_tblDocumentoPerfilPersona.load();
        datasource_datos_loadPromise_fotos.done(function (persona) {
            let documentos = [];
            if (persona.length > 0) {
                $.each(persona[0], function (index, item) {
                    if (index !== "idPersona" && item) {
                        documentos.push(item);
                    }
                });
            }

            _this.setState({
                showLoading_tabDatosPersonales: false,
                documentosPerfilPersona: documentos,
            });
        });

        _this.setState({
            datasource_datos_loadPromise_fotos: datasource_datos_loadPromise_fotos,
        });

        this.datasource_tblCategoriaConvenio.reload();
        this.datasource_tblCategoriaInterna.reload();
        this.datasource_tblUsuario_Validacion.reload().then((data) => {
            _this.setState({
                tblUsuario_Validacion: data,
            });
        });
    }

    dxPopup_fichaPersona_onHidden(e) {
        const { isGuardando_fichaPersona } = this.state;
        if (!isGuardando_fichaPersona) this.reset_personaSel();
    }

    loadCalendar(data, showLoadPanel) {
        let personaSel = { ...this.state.personaSel };
        let { tblCalendarioPersonal_cache } = this.state;
        let _this = this;

        let is_chargedYear =
            $.grep(tblCalendarioPersonal_cache, function (item) {
                return item.fecha.getFullYear() === data.currentYear;
            }).length > 0;

        let enabledRange = $.map(this.state.personaSel.tblPersonaNTipoContrato, function (item) {
            return {
                fechaDesde: item.fechaAltaContrato,
                fechaHasta: item.fechaBajaContrato,
            };
        });

        var enabledDays = [];
        $.each(enabledRange, function (index, item) {
            var startDate = new Date(new Date(item.fechaDesde).setHours(0));
            var endDate =
                item.fechaHasta == null
                    ? new Date(data.currentYear, 11, 31, 23, 59)
                    : new Date(new Date(item.fechaHasta).setHours(23));
            for (var i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
                enabledDays.push(
                    new Date(new Date(i.getFullYear() + "-" + (i.getMonth() + 1) + "-" + i.getDate()).setHours(0))
                );
            }
        });

        var date = startOfYear(new Date(data.currentYear, 0, 1));
        var end = endOfYear(new Date(data.currentYear, 0, 1));
        var diasDisabled = [];

        while (date <= end) {
            let is_SameDay =
                $.grep(enabledDays, function (item) {
                    return isSameDay(item, date);
                }).length > 0;
            if (!is_SameDay) {
                diasDisabled.push(new Date(new Date(date).setHours(0)));
            }
            date.setDate(date.getDate() + 1);
        }

        if (!is_chargedYear) {
            if (this.state.dxTabPanel_fichaPersona_selectedTabName == "calendarioLaboral" && showLoadPanel)
                this.dxLoadPanel_tabCalendario.option("visible", true);

            this.context_calendarioLaboral
                .invoke(
                    "fn_spSelectCalendarioLaboral",
                    {
                        idPersona: personaSel ? personaSel.idPersona : null,
                        año: data.currentYear,
                    },
                    "GET"
                )
                .done(function (result) {
                    result = result.filter(
                        (x) =>
                            (x.idEstado != 8 && x.idEstado != 10) ||
                            ((x.idEstado == 8 || x.idEstado == 10) &&
                                diasDisabled.filter((d) => isSameDay(d, x.fecha)).length === 0)
                    );

                    $.merge(tblCalendarioPersonal_cache, result);

                    if (personaSel) {
                        personaSel.tblCalendarioPersonal = $.extend(
                            true,
                            [],
                            $.merge(personaSel.tblCalendarioPersonal, result)
                        );
                    }

                    _this.setState({
                        personaSel: personaSel,
                        tblCalendarioPersonal_cache: tblCalendarioPersonal_cache,
                        yearCalendar_currentYear: data.currentYear,
                        disabledDays: diasDisabled,
                    });

                    _this.dxLoadPanel_tabCalendario.option("visible", false);
                });
        } else {
            if (this.state.dxTabPanel_fichaPersona_selectedTabName == "calendarioLaboral" && showLoadPanel)
                _this.dxLoadPanel_tabCalendario.option("visible", true);
            setTimeout(() => {
                _this.setState({
                    disabledDays: diasDisabled,
                    yearCalendar_currentYear: data.currentYear,
                });
                _this.dxLoadPanel_tabCalendario.option("visible", false);
            }, 0);
        }
    }

    yearCalendar_onYearChanged(data) {
        if (this.state.calendar_firstRender == false) this.loadCalendar(data, true);
        else {
            this.state.calendar_firstRender = false;
            this.dxLoadPanel_tabCalendario.option("visible", false);
        }
    }

    dxPopup_añadirFoto_onContentReady(e) {
        e.component.content().attr("id", "dxPopup_personal_fichaPersona_añadirFoto");
    }

    dxButton_rotarImagen_onClick(e) {
        let _this = this;
        let archivoSel = { ...this.state.archivoSel };

        rotateBase64Image("data:image/jpeg;base64," + archivoSel.documento, 90, function (resultBase64) {
            archivoSel.documento = resultBase64.replace("data:image/png;base64,", "");
            _this.setState({ archivoSel });
        });
    }

    onHiding_popup_galeriaArchivos(e) {
        this.setState({ isVisible_GaleriaArchivos: false });
    }

    dxButton_altaNuevaPersona_guardar_onClick(e) {
        let _this = this;
        setTimeout(() => {
            let { isValid_diasLibres } = this.state;
            let personaSel = $.extend(true, {}, _this.state.personaSel);

            let isValid = validationEngine.validateGroup("validacionPersona_nueva").isValid;
            let isValid_telefono = personaSel.telefono && personaSel.telefono.length > 6;

            if (
                isValid &&
                isValid_telefono &&
                !personaSel.usuario_tienePersona &&
                !personaSel.emailPersonal_exists &&
                personaSel.numDocIdentidad_exists == false &&
                isValid_diasLibres
            ) {
                let parsed = JSON.parse(personaSel.idCentroLav);
                personaSel.idCentroTrabajo = parsed[0] > 0 ? parsed[0] : null;
                personaSel.idLavanderia = parsed[1] > 0 ? parsed[1] : null;
                personaSel.tblPersonaNTipoContrato = [
                    {
                        fechaAltaContrato: personaSel.fechaAltaContrato,
                        fechaBajaContrato: personaSel.fechaBajaContrato,
                        numDiasPeriodoPrueba: personaSel.numDiasPeriodoPrueba,
                        idTipoContrato: personaSel.idTipoContrato,
                    },
                ];

                delete personaSel.idCentroLav;
                delete personaSel.emailEmpresa;
                delete personaSel.idLocalizacion;
                delete personaSel.nombreUsuario;
                delete personaSel.usuario_tienePersona;
                delete personaSel.emailPersonal_exists;
                delete personaSel.numDocIdentidad_exists;
                delete personaSel.fechaAltaContrato;
                delete personaSel.fechaBajaContrato;
                delete personaSel.numDiasPeriodoPrueba;
                delete personaSel.idTipoContrato;
                delete personaSel.idCategoriaConvenio;
                delete personaSel.idPais_localizacion;

                if (personaSel.tblLavanderiaNUsuario || personaSel.isFormPedidoAllowed) {
                    var usuario = {};
                    if (personaSel.tblLavanderiaNUsuario) {
                        usuario.idLavanderia = personaSel.tblLavanderiaNUsuario;
                        delete personaSel.tblLavanderiaNUsuario;
                    }
                    if (personaSel.isFormPedidoAllowed) {
                        usuario.tblFormularioNUsuario = [{ idFormulario: 30 }];
                        delete personaSel.isFormPedidoAllowed;
                    }
                    personaSel.tblUsuario = [usuario];
                }

                if (personaSel.idTurno == -1) personaSel.idTurno = null;
                this.loadPanel_show(true);
                this.setState(
                    {
                        isVisible_dxPopup_addNewPersona: false,
                    },
                    () => {
                        this.datasource_persona
                            .store()
                            .insert(personaSel)
                            .done(function () {
                                notify({
                                    message: _this.getTrad("aviso_C_RegistroInsertado"),
                                    type: "success",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                });

                                _this.dataSource_tblLlamamiento.reload().then((datasource_tblLlamamiento) => {
                                    _this.setState({
                                        showLoading_tabDocumentos: false,
                                        isGuardando_fichaPersona: false,
                                        datasource_tblLlamamiento,
                                    });
                                });

                                _this.datasource_persona.reload().done(function () {
                                    _this.loadPanel_hide();
                                });
                                _this.datasource_persona_listaGeneral.reload();
                            });
                    }
                );
            } else if (!isValid_telefono) {
                _this.setState({
                    personaSel: personaSel,
                    validationError_telefono: true,
                });
            } else {
                _this.setState({
                    personaSel: personaSel,
                    validationError_telefono: false,
                });
            }
        }, 0);
    }

    dxButton_fichaPersona_cancelar_onClick(e) {
        this.setState({
            isVisible_dxPopup_addNewPersona: false,
        });
    }

    dxPopup_fichaPersona_addPersona_onShowing(e) {
        this.setState({
            personaSel: { isIBANSolicitado: true, emailEmpresa: "" },
            validationError_telefono: false,
        });
        this.dxForm_cambiosPersonal_new.resetValues();
    }

    dxPopup_fichaPersona_addPersona_onHiding(e) {
        this.setState({
            isVisible_dxPopup_addNewPersona: false,
        });
    }

    dxPopup_ImportarDocumentos_onHidden(e) {
        this.setState({
            ImportarDocumentos_EmpresaSel: null,
        });
    }

    dxPopup_ImportarDocumentos_onHiding(e) {
        this.setState({
            isVisible_dxPopup_ImportarDocumentos: false,
            accionesModificarDatos_fileUploader: [],
            documentosImportarDocumentos: [],
            numNominasError: 0,
            nombreNominasImportadas: null,
            maxFileExceeded: false,
        });
    }

    reset_personaSel() {
        let { datasource_datos_loadPromise_documentos, personaSel, datasource_datos_loadPromise_fotos } = this.state;
        if (datasource_datos_loadPromise_documentos) {
            this.datasource_tblDocumento.cancel(datasource_datos_loadPromise_documentos.operationId);
        }

        if (datasource_datos_loadPromise_fotos) {
            this.datasource_tblDocumentoPerfilPersona.cancel(datasource_datos_loadPromise_fotos.operationId);
        }

        const gridPersonas = this.dxDataGrid_personas;

        let index = personaSel ? gridPersonas.getRowIndexByKey(personaSel.idPersona) : 0;
        gridPersonas.cellValue(index, "tblCalendarioPersonal", []);

        this.setState({
            personaSel: null,
            tblCalendarioPersonal_cache: [],
            isVisible_warning_datosPersonales: false,
            isVisible_warning_datosSalariales: false,
            dxTabPanel_fichaPersona_selectedTabName: "datosPersonales",
            carpetaDocumentosSel: null,
            dataSource_documentos: this.state.dataSource_documentos_bk,
            datasource_datos_loadPromise_documentos: null,
            datasource_datos_loadPromise_fotos: null,
            validationError_telefono: false,
            isValid_warning_datosLaborales: true,
            isValid_warning_calendario: true,
            showLoading_tabDatosPersonales: false,
            showLoading_tabDatosLaborales: false,
            showLoading_tabDocumentos: false,
            calendar_firstRender: true,
            yearCalendar_currentYear: new Date().getFullYear(),
            yearCalendar_newData: [],
            isRendered_tabCalendario: false,
            documentosPerfilPersona: [],
            removeFotos: [],
            isGuardando_fichaPersona: false,
            idsSubturnosVisibles: [],
            multiViewCarpetasIndex: 0,
            multiViewCarpetas_DocumentosAgrupados: null,
        });
    }

    dxTabPanel_fichaPersona_onSelectionChanged(e) {
        if (e.addedItems.length > 0) {
            let { isRendered_tabCalendario } = this.state;

            if (isRendered_tabCalendario) {
                this.setState({
                    dxTabPanel_fichaPersona_selectedTabName: e.addedItems[0].name,
                });
            } else {
                setTimeout(() => {
                    this.setState({
                        dxTabPanel_fichaPersona_selectedTabName: e.addedItems[0].name,
                        isRendered_tabCalendario:
                            e.addedItems[0].name === "calendarioLaboral" ? true : this.state.isRendered_tabCalendario,
                    });
                }, 500);
            }
        }
    }

    itemTitleRender_dxTabPanel_Persona(e) {
        let {
            isVisible_warning_datosPersonales,
            isVisible_warning_datosSalariales,
            isValid_warning_datosLaborales,
            isValid_warning_calendario,
            isValid_diasLibres,
        } = this.state;

        if (e.name === "datosPersonales")
            return (
                <div>
                    <i className={isVisible_warning_datosPersonales ? "icon_Warning" : ""} /> {e.title}
                </div>
            );

        if (e.name === "datosSalariales")
            return (
                <div>
                    <i className={isVisible_warning_datosSalariales ? "icon_Warning" : ""} /> {e.title}
                </div>
            );

        if (e.name === "datosLaborales")
            return (
                <div>
                    <i className={!isValid_warning_datosLaborales || !isValid_diasLibres ? "icon_Warning" : ""} />{" "}
                    {e.title}
                </div>
            );

        if (e.name === "calendarioLaboral")
            return (
                <div>
                    <i className={!isValid_warning_calendario ? "icon_Warning" : ""} /> {e.title}
                </div>
            );

        return <div>{e.title}</div>;
    }

    yearCalendar_onDateChanged(data, newData) {
        let personaSel = { ...this.state.personaSel };
        let yearCalendar_newData = [...this.state.yearCalendar_newData];
        let calendarData = personaSel ? personaSel.tblCalendarioPersonal : [];
        let datasource_tblCalendarioEstado = this.state.datasource_tblCalendarioEstado;
        const { user } = this.props;

        let hasAbsentismo = false;
        let isSinEstado = newData[0].idEstado === 0;

        let diasAfectados = newData.filter((x) => {
            if (
                user.enableDatosRRHH &&
                calendarData.filter(
                    (c) => c.fecha.setHours(0, 0, 0, 0) === x.fecha.setHours(0, 0, 0, 0) && c.idEstado === 5
                ).length > 0
            ) {
                hasAbsentismo = true;
                return x.idEstado === 0 && personaSel.tblCuadrantePersonal[0] >= x.fecha.getTime();
            }
            return personaSel.tblCuadrantePersonal[0] >= x.fecha.getTime();
        });

        if (diasAfectados.length === 0) {
            if (hasAbsentismo) {
                // dxMensajePregunta(`Los <b>absentismos</b> serán modificados a <b>"${getTrad(datasource_tblCalendarioEstado.find(x => x.idEstado === newData[0].idEstado).traduccion)}"</b> y no se podrán volver a modificar.<br/>¿Estás seguro de este cambio?`, [
                dxMensajePregunta(
                    getTrad("aviso_cambiarEstadoCalendario").replace(
                        "{0}",
                        getTrad(
                            datasource_tblCalendarioEstado.find((x) => x.idEstado === newData[0].idEstado).traduccion
                        )
                    ),
                    [
                        [
                            getTrad("guardar"),
                            () => {
                                $.each(newData, function (index, item) {
                                    let exist = $.grep(yearCalendar_newData, function (item2) {
                                        return isSameDay(item.fecha, item2.fecha) && item2.idEstado != 255;
                                    });
                                    if (exist.length > 0) {
                                        let objIndex = yearCalendar_newData.findIndex(
                                            (obj) => isSameDay(obj.fecha, item.fecha) && obj.idEstado != 255
                                        );
                                        yearCalendar_newData[objIndex].idEstado = item.idEstado;
                                    } else {
                                        yearCalendar_newData.push(item);
                                    }
                                });

                                let index = personaSel
                                    ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona)
                                    : 0;
                                this.dxDataGrid_personas.cellValue(index, "tblCalendarioPersonal", data);

                                personaSel.tblCalendarioPersonal = data;

                                this.setState({
                                    yearCalendar_newData: yearCalendar_newData,
                                    personaSel: personaSel,
                                });
                            },
                            "default",
                        ],
                        [getTrad("cancelar"), () => {}],
                    ]
                );
            } else {
                $.each(newData, function (index, item) {
                    let exist = $.grep(yearCalendar_newData, function (item2) {
                        return isSameDay(item.fecha, item2.fecha) && item2.idEstado != 255;
                    });
                    if (exist.length > 0) {
                        let objIndex = yearCalendar_newData.findIndex(
                            (obj) => isSameDay(obj.fecha, item.fecha) && obj.idEstado != 255
                        );
                        yearCalendar_newData[objIndex].idEstado = item.idEstado;
                    } else {
                        yearCalendar_newData.push(item);
                    }
                });

                let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                this.dxDataGrid_personas.cellValue(index, "tblCalendarioPersonal", data);

                personaSel.tblCalendarioPersonal = data;

                this.setState({
                    yearCalendar_newData: yearCalendar_newData,
                    personaSel: personaSel,
                });
            }
        } else {
            if (isSinEstado) {
                notify({
                    message: `No se puede asignar "Sin estado" a un evento con cuadrante fijado`,
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            } else {
                const format = { day: "2-digit", month: "2-digit", year: "numeric" };

                notify({
                    message: `Se ha encontrado un día fijado posterior: ${new Date(
                        personaSel.tblCuadrantePersonal[0]
                    ).toLocaleString(undefined, format)}`,
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }
        }
        this.loadPanel_hide();
    }

    yearCalendar_onEstadoDeleting(data, fechaSel) {
        let personaSel = { ...this.state.personaSel };
        let yearCalendar_newData = [...this.state.yearCalendar_newData];
        let dataCalendar = $.extend(true, [], personaSel.tblCalendarioPersonal);

        let exist = $.grep(yearCalendar_newData, function (item2) {
            return isSameDay(fechaSel, item2.fecha) && item2.idEstado != 255;
        });
        if (exist.length > 0) {
            let objIndex = yearCalendar_newData.findIndex(
                (obj) => isSameDay(obj.fecha, data.fecha) && obj.idEstado != 255
            );
            yearCalendar_newData[objIndex].idEstado = data.idEstado;
        } else if (data.idEstado != 3 && data.idEstado != 4) {
            yearCalendar_newData.push({
                color: "#FFF",
                date: fechaSel,
                fecha: fechaSel,
                idEstado: 0,
                isError: false,
            });
        } else {
            yearCalendar_newData.push({
                color: "#FFF",
                date: fechaSel,
                fecha: fechaSel,
                idEstado: 255, // Eliminar jornada - Máx. tipo byte c#
                isError: false,
            });
        }

        dataCalendar = $.grep(dataCalendar, function (item) {
            return !(isSameDay(item.fecha, fechaSel) && item.idEstado == data.idEstado);
        });

        let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        this.dxDataGrid_personas.cellValue(index, "tblCalendarioPersonal", dataCalendar);

        personaSel.tblCalendarioPersonal = dataCalendar;

        this.setState({
            yearCalendar_newData: yearCalendar_newData,
            personaSel: personaSel,
        });
        this.loadPanel_hide();
    }

    onRowPrepared_dxDataGrid_tblPersona(e) {
        let { user } = this.props;
        if (e.rowType === "data") {
            if (!e.data.avisoLegalAceptado && user.enableDatosRRHH) {
                e.rowElement.addClass("warningRow_onlyText");
            }
        }
    }

    onInitNewRow_dxDataGrid_tblPersona(e) {
        let { personaSel } = this.state;
        personaSel = null;

        let dataSource_documentos_bk = $.extend(true, [], this.state.dataSource_documentos_bk);

        $.each(e.data.tblDocumento, function (index, item) {
            $.each(dataSource_documentos_bk[0].items, function (iCarpeta, carpeta) {
                if (
                    carpeta.idCarpetaDocumentos === item.idCarpetaDocumentos &&
                    carpeta.tblDocumento.findIndex((x) => x.idDocumento === item.idDocumento) === -1
                ) {
                    carpeta.tblDocumento.push(item);
                }
            });
        });

        this.setState({
            dataSource_documentos: dataSource_documentos_bk,
            carpetaDocumentosSel: dataSource_documentos_bk[0].items[0],
        });
    }

    onEditorPreparing_dxDataGrid_tblPersona(e) {
        let _this = this;
        let {
            datasource_tblCategoriaInterna_lookupGrid_grouped,
            idSelection_tblCategoriaInterna_lookupGrid,
            idSelection_centroLav_lookupGrid,
            datasource_centroLav_lookupGrid_grouped,
        } = this.state;
        if (e.parentType == "dataRow") {
            let rowIndex = e.row && e.row.rowIndex;
            if (e.dataField == "idCentroLav") {
                e.editorOptions.grouped = true;
                e.editorOptions.dataSource.group = "groupTitle";
                e.editorOptions.onValueChanged = (args) => {
                    let value = JSON.parse(args.value);

                    let idLavanderia = value.length == 0 || value[1] <= 0 ? null : value[1];
                    let { datasource_tblTurno_todos } = this.state;
                    let turnos = [];
                    $.each(datasource_tblTurno_todos, function (index, item) {
                        if (item.idLavanderia === idLavanderia) turnos.push(item);
                    });

                    let personaSel = { ...this.state.personaSel };
                    personaSel.idLavanderia = idLavanderia;
                    personaSel.idCentroLav = args.value;

                    this.setState({ personaSel }, () => {
                        e.component.beginUpdate();
                        e.component
                            .getCellElement(rowIndex, "idTurno")
                            .find(".dx-selectbox")
                            .dxSelectBox("instance")
                            .option("dataSource", turnos);
                        e.component.cellValue(rowIndex, "idTurno", -1);
                        e.component.cellValue(rowIndex, "idTurno", null);
                        e.component.cellValue(rowIndex, "horasDiarias", null);
                        e.component.endUpdate();
                    });
                    e.setValue(args.value);
                };
            } else if (e.dataField == "idTurno") {
                e.editorElement.dxSelectBox({
                    onOpened: function (e) {
                        let { datasource_tblTurno_todos, personaSel } = _this.state;
                        let turnos = [];

                        if (personaSel && personaSel.idLavanderia) {
                            $.each(datasource_tblTurno_todos, function (index, item) {
                                if (item.idLavanderia === personaSel.idLavanderia) turnos.push(item);
                            });
                        }
                        e.component.option("dataSource", turnos);
                    },
                });
            }
        } else if (e.parentType == "filterRow" && e.dataField == "idCentroLav") {
            e.editorName = "dxSelectBox";
            e.editorOptions = {
                items: datasource_centroLav_lookupGrid_grouped,
                displayExpr: "denominacion",
                valueExpr: "idCentroLav",
                value: idSelection_centroLav_lookupGrid,
                grouped: true,
                elementAttr: this.categoriaInterna_lookupGrid_grouped_personal_elementAttr,
                showClearButton: true,
                placeholder: _this.getTrad("todos").toUpperCase(),
                onContentReady: this.dxDataGrid_tblPersona_lookUpFilter_catLav_onContentReady,
                onValueChanged: function (args) {
                    e.component.columnOption("idTurno", "filterValue", null);
                    let value = JSON.parse(args.value);

                    let idLavanderia = args.value == null || value.length == 0 || value[1] <= 0 ? null : value[1];
                    let idCentroTrabajo = args.value == null || value.length == 0 || value[0] <= 0 ? null : value[0];

                    _this.setState({
                        filterRow_idLavanderia: idLavanderia,
                        filterRow_idCentro: idCentroTrabajo,
                        idSelection_centroLav_lookupGrid: args.value,
                    });
                    e.setValue(args.value);
                },
            };
        } else if (e.parentType == "filterRow" && e.dataField == "idTurno") {
            let _this = this;
            e.editorOptions.onOpened = function (arg) {
                let { filterRow_idLavanderia, filterRow_idCentro, datasource_tblTurno_todos } = _this.state;

                let turnos = [];
                $.each(datasource_tblTurno_todos, function (index, item) {
                    if (filterRow_idLavanderia) {
                        if (filterRow_idLavanderia === item.idLavanderia || item.idTurno == -1) turnos.push(item);
                    } else if (filterRow_idCentro) {
                        if (filterRow_idCentro === item.idCentro) turnos.push(item);
                    }
                });

                turnos = [...turnos];

                let todos = {
                    idTurno: null,
                    denominacion: getTrad("todos").toUpperCase(),
                };
                if (turnos.length == 0) arg.component.option("dataSource", [todos]);
                else {
                    turnos.unshift(todos);
                    arg.component.option("dataSource", turnos);
                }
            };
        } else if (e.parentType == "filterRow" && e.dataField == "idCategoriaInterna") {
            e.editorName = "dxSelectBox";
            e.editorOptions = {
                items: datasource_tblCategoriaInterna_lookupGrid_grouped,
                displayExpr: "denominacion",
                valueExpr: "idCategoriaInterna",
                value: idSelection_tblCategoriaInterna_lookupGrid,
                grouped: true,
                elementAttr: this.categoriaInterna_lookupGrid_grouped_personal_elementAttr,
                showClearButton: true,
                placeholder: _this.getTrad("todos").toUpperCase(),
                onContentReady: this.dxDataGrid_tblPersona_lookUpFilter_idCategoriaInterna_onContentReady,
                onValueChanged: (arg) => {
                    this.dxDataGrid_tblPersona_lookUpFilter_idCategoriaInterna(arg, e);
                },
            };
        }
    }

    categoriaInterna_lookupGrid_grouped_personal_elementAttr = {
        id: "categoriaInterna_lookupGrid_grouped_personal",
    };

    dxDataGrid_tblPersona_lookUpFilter_idCategoriaInterna_onContentReady(e) {
        e.component.content().attr("id", "dxPopup_dxDataGrid_tblPersona_lookUpFilter_idCategoriaInterna");
    }

    dxDataGrid_tblPersona_lookUpFilter_catLav_onContentReady(e) {
        e.component.content().attr("id", "dxPopup_dxDataGrid_tblPersona_lookUpFilter_catLav");
    }

    dxDataGrid_tblPersona_lookUpFilter_idCategoriaInterna(arg, e) {
        e.component.columnOption("idCategoriaInterna", "filterValue", arg.value);

        this.setState({ idSelection_tblCategoriaInterna_lookupGrid: arg.value });
    }

    idCentroLav_setCellValue(newData, value) {
        newData.idCentroLav = value;
    }

    idTurno_setCellValue(newData, value, currentRowData) {
        let column = this;
        column.defaultSetCellValue(newData, value);
    }

    horasDiarias_setCellValue(newData, value, currentRowData) {
        let column = this;
        column.defaultSetCellValue(newData, value);
    }

    onEditingStart_dxDataGrid_tblPersona(e) {
        const { identifySuccess } = this.state;

        let personaSel = $.extend(true, {}, e.data);

        if (personaSel?.idCentroTrabajo !== null && !identifySuccess) {
            e.cancel = true;
            this.setState({ personaSel, identifyNeeded: true });
        } else {
            this.onEditingStart_dxDataGrid_tblPersona_action(e);
        }
    }

    onEditingStart_dxDataGrid_tblPersona_action = (e) => {
        const { personaSel, identifySuccess } = this.state;

        if (personaSel && identifySuccess == false) {
            e.cancel = true;
            return;
        }

        let _this = this;
        let personaSel_ = $.extend(true, {}, e.data);
        let { datasource_tblCategoriaInterna_lookupGrid } = this.state;

        let salarioBrutoAnual = _this.getSalarioBrutoAnual(e.data);
        e.data.salarioBrutoAnual = salarioBrutoAnual;
        e.data.costeEmpresa =
            salarioBrutoAnual *
            (1 +
                (e.data.tblDatosSalariales != null && e.data.tblDatosSalariales.percSegSocial != null
                    ? e.data.tblDatosSalariales.percSegSocial
                    : 0));

        let catSel = $.grep($.extend(true, [], datasource_tblCategoriaInterna_lookupGrid), function (item) {
            return item.idCategoriaInterna == e.data.idCategoriaInterna;
        });

        let idsTurnosNCategoria = [];
        if (catSel.length > 0) {
            idsTurnosNCategoria = $.map(catSel[0].tblCategoriaInternaNTurno, function (item) {
                return item.idTurno;
            });
        }

        let datasource_tblCentroTrabajo_Lavanderia = this.getCentrosEmpresa(personaSel_.idEmpresaPolarier);

        this.setState(
            {
                personaSel: personaSel_,
                isSalarioBrutoMensualManual: e.data?.tblDatosSalariales?.salarioBrutoMensual !== null,
                accionesModificarDatos_fileUploader: [],
                yearCalendar_currentYear: new Date().getFullYear(),
                yearCalendar_newData: [],
                idsSubturnosVisibles: idsTurnosNCategoria,
                identifySuccess: false,
                datasource_tblCentroTrabajo_Lavanderia,
            },
            () => {
                this.datasource_tblFormularioNUsuario.reload();
            }
        );
    };

    dxDataGrid_personas_onRowInserting(e) {
        let d = $.Deferred();
        let { carpetaDocumentosSel } = this.state;

        const validations = [];
        let validacionPersona = validationEngine.validateGroup("validacionPersona");
        if (validacionPersona.status === "pending") {
            validations.push(validacionPersona);
        }

        if (validationEngine.getGroupConfig("validacionCodigoGestoria")) {
            validations.push(validationEngine.validateGroup("validacionCodigoGestoria"));
        }

        Promise.all(validations).then(([responsePersona, responseCodigoGestoria]) => {
            if (!responsePersona) {
                responsePersona = validacionPersona;
            }
            let isValid = responsePersona.isValid && (responseCodigoGestoria == null || responseCodigoGestoria.isValid);

            let isValid_datosLaborales = validationEngine.validateGroup("validacionDatosLaborales").isValid;
            let _this = this;
            this.context_persona
                .invoke(
                    "fn_isNumDocIdentidadPersonaExists",
                    { numDocumentoIdentidad: e.data.numDocumentoIdentidad },
                    "POST"
                )
                .done(function (result) {
                    if (result) {
                        e.cancel = true;
                        notify({
                            message: _this.getTrad("numDocIdentidadExistente"),
                            type: "error",
                            displayTime: "1500",
                            closeOnClick: true,
                        });
                        return d.resolve("cancel");
                    } else if (isValid && isValid_datosLaborales) {
                        $.each(e.data.tblDocumento, function (index, item) {
                            item.denominacion = item.nombre;
                            item.idCarpetaDocumentos = carpetaDocumentosSel
                                ? carpetaDocumentosSel.idCarpetaDocumentos
                                : -1;
                            item.idPersona = e.key;

                            delete item.nombre;
                            delete item.id;
                        });

                        //Como el Switch se instancia como TRUE(Activo) el valor por defecto sin modificaciones ha de ser TRUE.
                        if (e.data.activo == null) {
                            e.data.activo = true;
                        }

                        if (e.data.idCentroLav) {
                            let parsed = JSON.parse(e.data.idCentroLav);
                            e.data.idCentroTrabajo = parsed[0] > 0 ? parsed[0] : null;
                            e.data.idLavanderia = parsed[1] > 0 ? parsed[1] : null;
                        }
                        if (e.data.tallaPantalon) e.data.tallaPantalon = e.data.tallaPantalon.toString();
                        delete e.data.idCentroLav;
                        delete e.data.numAños;
                        delete e.data.numTrienios;
                        delete e.data.tblDocumento;
                    } else {
                        _this.setState({ isVisible_warning_datosPersonales: true });
                        notify({
                            message: _this.getTrad("alerta_rellenarCamposObligatorios"),
                            type: "error",
                            displayTime: "1500",
                            closeOnClick: true,
                        });

                        e.cancel = true;
                        return d.resolve("cancel");
                    }
                    return d.resolve();
                });
            e.cancel = d.promise();
        });
    }

    dxDataGrid_personas_onRowInserted(e) {
        let _this = this;
        this.loadPanel_show(true);

        this.saveDocuments(e.key).done(function () {
            _this.reset_personaSel();
            _this.loadPanel_hide();
            notify({
                message: _this.getTrad("aviso_C_RegistroInsertado"),
                type: "success",
                displayTime: "1500",
                closeOnClick: true,
            });

            _this.setState({
                accionesModificarDatos_fileUploader: [],
            });
        });
    }

    dxDataGrid_personas_onRowUpdating(e) {
        let _this = this;
        let { isValid_warning_calendario } = this.state;
        let validation = validationEngine.validateGroup("validacionPersona");

        let validation_datosLaborales = null;
        if (validationEngine.getGroupConfig("validacionDatosLaborales")) {
            validation_datosLaborales = validationEngine.validateGroup("validacionDatosLaborales");
        }

        let validacionDatosLaborales_isValid =
            (validation_datosLaborales != null && validation_datosLaborales.isValid) ||
            validation_datosLaborales == null;
        if (!validation.isValid || !validacionDatosLaborales_isValid || !isValid_warning_calendario) {
            this.setState({
                isVisible_warning_datosPersonales: !validation.isValid,
                isValid_warning_datosLaborales: validacionDatosLaborales_isValid,
                showLoading_tabDocumentos: false,
            });

            if (!isValid_warning_calendario) {
                notify({
                    message: "Debe corregir campos con datos erróneos",
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            } else {
                notify({
                    message: this.getTrad("alerta_rellenarCamposObligatorios"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }
            _this.reset_personaSel();

            e.cancel = true;
            return false;
        } else {
            $("#dxDataGrid_Popup_Edit").dxPopup("instance").hide();

            let { personaSel } = this.state;
            let { activo, idCentroLav, idTurno, tblDatosSalariales } = e.newData;
            delete e.newData.tblDocumento;

            if (activo == null) e.newData.activo = e.oldData.activo;

            if (idCentroLav) {
                let parsed = JSON.parse(idCentroLav);
                e.newData.idCentroTrabajo = parsed[0] > 0 ? parsed[0] : null;
                e.newData.idLavanderia = parsed[1] > 0 ? parsed[1] : null;
            }

            if (tblDatosSalariales != null) {
                e.newData.tblDatosSalariales = {
                    ...e.oldData.tblDatosSalariales,
                    ...tblDatosSalariales,
                };
            }

            delete e.newData.idCentroLav;
            delete e.newData.numAños;
            delete e.newData.numTrienios;
            delete e.newData.tblCalendarioPersonal;

            if (e.newData.tblDatosSalariales != null) {
                var flat = flatObject(e.newData.tblDatosSalariales);
                Object.entries(flat).forEach(([key, value]) => {
                    e.newData["tblDatosSalariales/" + key] = value;
                });
                delete e.newData.tblDatosSalariales;
            }
            e.newData = patchRequestHandler(e.newData);

            this.loadPanel_show(true);
            this.setState({ showLoading_tabDocumentos: false });

            e.cancel = true;
            this.datasource_persona
                .store()
                .update(e.key, e.newData)
                .done(function () {
                    let dataSource;
                    if (personaSel["tblUsuario@odata.count"] > 0)
                        dataSource = _this.datasource_tblUsuario.store().update(
                            personaSel.tblUsuario[0].idUsuario,
                            patchRequestHandler({
                                idLocalizacion: personaSel.idLocalizacion,
                            })
                        );

                    $.when(_this.saveDocuments(personaSel.idPersona), dataSource).then(function () {
                        $.when(_this.removeFotos()).then(function () {
                            setTimeout(() => {
                                _this.setState({});
                            }, 1000);

                            notify({
                                message: _this.getTrad("aviso_C_RegistroActualizado"),
                                type: "success",
                                displayTime: "1500",
                                closeOnClick: true,
                            });

                            _this.datasource_persona.reload().done(function () {
                                _this.reset_personaSel();
                                _this.loadPanel_hide();
                            });
                            e.component.cancelEditData();
                        });
                    });
                });
        }
    }

    dxDataGrid_personas_onRowRemoving(e) {
        let _this = this;
        this.datasource_persona
            .store()
            .remove(e.key)
            .done(function () {
                notify({
                    message: _this.getTrad("aviso_C_RegistroEliminado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            });
    }

    dxDataGrid_personas_onRowValidating(e) {
        let validationPersona = validationEngine.validateGroup("validacionPersona");
        let validationCodigoGestoria = null;
        if (validationEngine.getGroupConfig("validacionCodigoGestoria")) {
            validationCodigoGestoria = validationEngine.validateGroup("validacionCodigoGestoria");
        }
        let validacionDatosLaborales = null;
        if (validationEngine.getGroupConfig("validacionDatosLaborales")) {
            validacionDatosLaborales = validationEngine.validateGroup("validacionDatosLaborales");
        }

        let validacionDatosLaborales_isValid =
            (validacionDatosLaborales != null && validacionDatosLaborales.isValid) || validacionDatosLaborales == null;
        e.promise =
            validationPersona.status === "pending" &&
            validationPersona.complete.then((response) => {
                e.isValid = e.isValid && response.isValid && validacionDatosLaborales_isValid;

                if (!e.isValid || !response.isValid) {
                    this.setState({
                        isVisible_warning_datosPersonales:
                            !response.isValid &&
                            (validacionDatosLaborales == null || !validationCodigoGestoria.isValid),
                        isValid_warning_datosLaborales: validacionDatosLaborales_isValid,
                    });
                } else {
                    this.setState({
                        isValid_warning_datosLaborales: validacionDatosLaborales_isValid,
                    });
                }
            });
    }

    onClick_gestionarDocumentacion_comun(e) {
        let dataSource_documentos_bk = $.extend(true, [], this.state.dataSource_documentos_bk);
        this.dxList_documentos_personasGeneral.option("searchValue", "");

        this.setState({
            isVisible_dxPopup_gestionarDocumentacion_comun: true,
            dataSource_documentos: dataSource_documentos_bk,
            carpetaDocumentosSel: dataSource_documentos_bk.length === 0 ? null : dataSource_documentos_bk[0].items[0],
        });
    }

    // BOTON DE GUARDAR PERSONA
    dxButton_fichaPersona_guardar_onClick(e) {
        let _this = this;
        let validationPersona = validationEngine.validateGroup("validacionPersona");
        let {
            isValid_warning_datosLaborales,
            isValid_warning_calendario,
            isValid_diasLibres,
            personaSel,
            yearCalendar_newData,
            isSalarioBrutoMensualManual,
        } = this.state;

        const validations = [];

        if (validationPersona.status === "pending") {
            validations.push(validationPersona.complete);
        }

        let validationCodigoGestoria = null;
        if (validationEngine.getGroupConfig("validacionCodigoGestoria")) {
            validationCodigoGestoria = validationEngine.validateGroup("validacionCodigoGestoria");
            validations.push(validationCodigoGestoria.complete);
        }

        let validation_datosLaborales = null;
        if (validationEngine.getGroupConfig("validacionDatosLaborales")) {
            validation_datosLaborales = validationEngine.validateGroup("validacionDatosLaborales");
        }

        let validacionDatosLaborales_isValid =
            (validation_datosLaborales != null && validation_datosLaborales.isValid) ||
            validation_datosLaborales == null;

        //* Comprueba que hay al menos una lavandería seleccionada cuando el selectorLavanderia es visible
        if (this.isVisible_selectorLavanderia() && personaSel.tblLavanderiaNUsuario.length <= 0) {
            validationPersona.isValid = false;
            return notify({
                message: "Debe dar acceso a al menos una lavandería.",
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }

        if (!isSalarioBrutoMensualManual) {
            let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;

            this.dxDataGrid_personas.cellValue(index, "tblDatosSalariales.salarioBrutoMensual", null);
        }

        // #region Validaciones campos calculados

        if (personaSel.idAdmElementoPEP != null && personaSel.idAdmCentroCoste != null) {
            let index = personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
            if (personaSel.idCentroTrabajo != null) {
                personaSel.idAdmElementoPEP = null;
                this.dxDataGrid_personas.cellValue(index, "idAdmElementoPEP", null);
            } else {
                personaSel.idAdmCentroCoste = null;
                this.dxDataGrid_personas.cellValue(index, "idAdmCentroCoste", null);
            }
        }

        if (personaSel.idAdmElementoPEP != null) {
            const lavanderia = this.state.datasource_tblCentroTrabajo_Lavanderia_todos.find(
                (x) => x.idCentroLav === personaSel.idCentroLav
            );

            if (lavanderia.idAdmElementoPEP !== personaSel.idAdmElementoPEP) {
                personaSel.idAdmElementoPEP = lavanderia.idAdmElementoPEP;
                let index =
                    personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                this.dxDataGrid_personas.cellValue(index, "idAdmElementoPEP", lavanderia.idAdmElementoPEP);
            }
        }

        // #endregion

        Promise.all(validations).then(([responsePersona, responseCodigoGestoria]) => {
            if (!responsePersona) {
                responsePersona = validationPersona;
            }
            if (
                isValid_warning_datosLaborales &&
                isValid_warning_calendario &&
                validacionDatosLaborales_isValid &&
                responsePersona.isValid &&
                (responseCodigoGestoria == null || responseCodigoGestoria.isValid) &&
                isValid_diasLibres
            ) {
                this.setState({
                    showLoading_tabDocumentos: true,
                    isGuardando_fichaPersona: true,
                });
                e.component.option("disabled", true);

                const promises = [];

                if (this.dxDataGrid_tblPersonaNTipoContrato_REF.current) {
                    promises.push(this.dxDataGrid_tblPersonaNTipoContrato.saveEditData());
                }

                if (yearCalendar_newData.length > 0) {
                    promises.push(this.saveCalendario());
                }

                Promise.all(promises).then((result) => {
                    let index =
                        personaSel.idPersona > 0 ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                    let isTblPersonaNTipoContratoEdited = this.dxDataGrid_personas
                        .cellValue(index, "tblPersonaNTipoContrato")
                        .some((x) => x.idPersona != null);
                    if (isTblPersonaNTipoContratoEdited) {
                        this.setState({ isVisible_FechaAntiguedadPopup: true });
                    } else {
                        this.fichaPersona_guardarPersona();
                    }
                });
            } else {
                this.setState({
                    isValid_warning_datosLaborales: validacionDatosLaborales_isValid,
                });
                notify({
                    message: "Datos inválidos",
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }
        });
    }

    fichaPersona_guardarPersona = () => {
        this.dxDataGrid_personas.saveEditData().done(() => {
            this.dxButton_fichaPersona_guardar.option("disabled", false);
            this.dataSource_tblLlamamiento.reload().then((datasource_tblLlamamiento) => {
                this.setState({
                    showLoading_tabDocumentos: false,
                    isGuardando_fichaPersona: false,
                    datasource_tblLlamamiento,
                });
            });
        });
    };

    dxButton_fichaPersona_edit_cancelar_onClick(e) {
        this.dxDataGrid_personas.cancelEditData();
    }

    dxPopup_persona_titleRender(arg) {
        let { personaSel } = this.state;
        return (
            <Toolbar>
                <ToolbarItem location="before">
                    <div style={{ fontSize: 20 }}>
                        {this.getTrad("fichaPersona") +
                            (personaSel && ": " + personaSel.nombre + " " + personaSel.apellidos)}
                    </div>
                </ToolbarItem>
                <ToolbarItem location="after">
                    <Button
                        icon="close"
                        stylingMode="text"
                        hoverStateEnabled={false}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        onClick={this.dxPopupPersona_titleRender_hide}
                    />
                </ToolbarItem>
            </Toolbar>
        );
    }

    dxPopupPersona_titleRender_hide() {
        this.dxDataGrid_personas.cancelEditData();
    }

    dxDataGrid_personas_onToolbarPreparing(e) {
        let _this = this;
        let { user } = this.props;

        $.each(e.toolbarOptions.items, function (index, item) {
            switch (item.name) {
                case "addRowButton":
                    item.visible = false;
                    break;
                default:
                    break;
            }
        });

        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    visible: 1 > 0,
                    template: () => {
                        // TODO: Traducción
                        const { tblLlamamiento } = this.state;
                        return `<span class="dx-button-text">${this.getTrad(
                            "incorporacionesActivas"
                        )} <span id="llamamientosCount" class="color-red">${
                            tblLlamamiento?.length > 0 ? "(${tblLlamamiento?.length})" : ""
                        }</span></span>`;
                    },
                    onClick: this.onClick_asignarLlamamientos_consulta,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    visible: user.enableDatosRRHH,
                    text: _this.getTrad("enviarAltasGestoria"),
                    onClick: this.onClick_enviarAltasGestoria,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    visible: false,
                    text: _this.getTrad("gestionarDocumentacion"),
                    onClick: this.onClick_gestionarDocumentacion_comun,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    visible: user.enableDatosRRHH,
                    text: _this.getTrad("importarDocumentos"),
                    icon: "upload",
                    width: "205px",
                    onClick: this.ImportarDocumentos_onClick,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    text: _this.getTrad("añadir"),
                    visible: user.enableDatosRRHH,
                    icon: "plus",
                    width: "150px",
                    onClick: this.nuevaPersona_onClick,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    text: _this.getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: (args) => {
                        this.limpiarFiltros_onClick(args, e, this);
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    text: _this.getTrad("actualizar"),
                    icon: "refresh",
                    onClick: (args) => {
                        this.actualizar_onClick(this, e);
                    },
                },
            }
        );
    }

    actualizar_onClick(_this, e) {
        _this.loadPanel_show(true);
        _this.datasource_persona.reload().done(function () {
            _this.loadPanel_hide();
        });
    }

    limpiarFiltros_onClick(args, e, _this) {
        //LIMPIA TODOS LOS FILTROS EXCEPTO EL DEL DATASOURCE
        e.component.beginUpdate();
        e.component.clearFilter("search");
        e.component.clearFilter("header");
        e.component.clearFilter("filterValue");
        e.component.clearFilter("row");
        e.component.endUpdate();

        notify({
            message: _this.getTrad("aviso_C_FiltroRestaurado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
        });
    }

    dxSelectBox_lavanderia_documentosGeneral_onValueChanged(e) {
        this.setState({ idLavSel_documentosGeneral: e.value });
    }

    dxList_persona_listaGeneral_onContentReady(e) {
        e.element.css({
            "box-shadow": "0 0 5px #ccc",
            "border-radius": 10,
        });

        e.element.addClass("listTipoDocumento");
    }

    onClick_enviarAltasGestoria = () => {
        this.setState({
            isVisible_dxPopup_enviarAltasGestoria: true,
        });
    };

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    dxList_persona_listaGeneral_onOptionChanged(e) {
        if (e.name === "selectedItems") {
            let { carpetaDocumentosSel } = this.state;
            let documentos = $.grep(this.datasource_tblDocumento_documentoGeneral.items(), function (item) {
                return (
                    $.inArray(
                        item.idPersona,
                        e.value.map((x) => x.idPersona)
                    ) > -1
                );
            });

            let groups = {};
            let dataSource_documentos_bk = $.extend(true, [], this.state.dataSource_documentos_bk);

            $.each(documentos, function (index, doc) {
                //#region ORGANIZAR EN CARPETAS
                $.each(dataSource_documentos_bk[0].items, function (iCarpeta, carpeta) {
                    if (
                        carpeta.idCarpetaDocumentos === doc.idCarpetaDocumentos &&
                        carpeta.tblDocumento.findIndex((x) => x.idDocumento === doc.idDocumento) === -1
                    ) {
                        carpeta.tblDocumento.push(doc);
                    }
                });
                //#endregion
                if (carpetaDocumentosSel.idCarpetaDocumentos === doc.idCarpetaDocumentos) {
                    if (groups[doc.idPersona]) {
                        groups[doc.idPersona]["items"].push(doc);
                    } else {
                        groups[doc.idPersona] = {
                            idGrandParent: doc.idPersona,
                            denominacion: doc.nombreCompuesto,
                            items: [doc],
                        };
                    }
                }
            });

            let documentosMapeados = Object.keys(groups).map(function (k) {
                return groups[k];
            });
            $.each(e.value, function (index, item) {
                let persona = $.grep(documentosMapeados, function (doc) {
                    return doc.idGrandParent === item.idPersona;
                });
                if (persona.length === 0) {
                    documentosMapeados.push({
                        denominacion: item.nombreCompuesto,
                        idGrandParent: item.idPersona,
                        items: [],
                    });
                }
            });

            this.setState({
                dxList_persona_listaGeneral_selectedItemKeys: e.value.map((x) => x.idPersona),
                documentosNGrandParent: documentosMapeados.sort((a, b) => a.denominacion.localeCompare(b.denominacion)),
                dataSource_documentos: dataSource_documentos_bk,
            });
        }
    }

    nuevaPersona_onClick(e) {
        this.setState(
            {
                isVisible_dxPopup_addNewPersona: true,
                personaSel: null,
                validationError_telefono: false,
                datasource_tblCentroTrabajo_Lavanderia: [],
            },
            () => {
                this.dxForm_cambiosPersonal_new.resetValues();
                this.datasource_tblCategoriaConvenio.reload();
                this.datasource_tblCategoriaInterna.reload();
            }
        );
    }

    ImportarDocumentos_onClick(e) {
        this.setState({ isVisible_dxPopup_ImportarDocumentos: true });
    }

    listTipoDocumento_personas_onContentReady(e) {
        e.element.css({
            "box-shadow": "0 0 5px #ccc",
            "border-radius": 10,
        });

        e.element.addClass("listTipoDocumento");
        setTimeout(() => {
            e.element.css("max-height", e.element.parent().height());
        }, 0);
        e.component.option("height", "fit-content");
    }

    listTipoDocumento_onContentReady(e) {
        e.element.addClass("listTipoDocumento");
        $(e.element).css("height", $(e.element).parent().height() - 50);
    }

    ItemBoxTipoDocumento_onContentReady(e) {
        e.element.css({
            "box-shadow": "0 0 5px #ccc",
            "border-radius": "10px",
        });
    }

    ItemBoxTipoDocumento_Importar_onContentReady(e) {
        $(e.element)
            .children()
            .css({
                "box-shadow": "0 0 5px #ccc",
                "border-radius": "10px",
            })
            .first()
            .addClass("mb-3");
    }

    resetMultiViewCarpetas = () => {
        const { multiViewCarpetas_DocumentosAgrupados, carpetaDocumentosSel } = this.state;

        this.setState({
            multiViewCarpetasIndex: 0,
            multiViewCarpetas_DocumentosAgrupados: {
                ...multiViewCarpetas_DocumentosAgrupados,
                padre: null,
                subCarpeta: undefined,
            },
            carpetaDocumentosSel: null,
        });
    };

    listTipoDocumento_onSelectionChanged({ addedItems }) {
        if (addedItems.length > 0) {
            let item = { ...addedItems[0] };

            const { carpetaDocumentosSel, multiViewCarpetasIndex } = this.state;
            let listaDocumentosAgrupados = [];

            if (multiViewCarpetasIndex === 0) {
                Object.entries(Object.groupBy(item.tblDocumento, ({ subCarpeta }) => subCarpeta))
                    .reverse()
                    .forEach(([carpeta, tblDocumento]) => {
                        if (carpeta == "null" || carpeta == "undefined")
                            tblDocumento.map((x) => {
                                x.subCarpeta = carpeta;
                                return x;
                            });
                        listaDocumentosAgrupados.push({
                            denominacion: carpeta == "null" || carpeta == "undefined" ? "Sin agrupación" : carpeta,
                            subCarpeta: carpeta,
                            tblDocumento: tblDocumento,
                        });
                    });
            }

            if (listaDocumentosAgrupados.length > 1) {
                this.setState({
                    carpetaDocumentosSel: item,
                    multiViewCarpetasIndex: 1,
                    multiViewCarpetas_DocumentosAgrupados: {
                        padre: item.denominacion,
                        grupos: listaDocumentosAgrupados,
                    },
                });
            } else {
                if (multiViewCarpetasIndex === 1) {
                    delete item.tblDocumento;
                    delete item.denominacion;
                }

                let newCarpetaDocumentosSel = {
                    ...carpetaDocumentosSel,
                    ...item,
                    multiViewCarpetas_DocumentosAgrupados: null,
                    subCarpeta: item.subCarpeta,
                };

                this.setState({
                    carpetaDocumentosSel: newCarpetaDocumentosSel,
                });
            }
        }
    }

    listTipoDocumento_groupRender(item) {
        return <div>{this.getTrad("carpetas").toUpperCase()}</div>;
    }

    listTipoDocumento_itemRender(item) {
        let hasSubfolders = Object.keys(Object.groupBy(item.tblDocumento, ({ subCarpeta }) => subCarpeta)).length > 1;
        return (
            <div className="d-flex flex-row listTipoDocumento_item align-items-center">
                <div className="d-flex flex-60 itemIcon">
                    {" "}
                    <div className="font-size-xxxl mx-auto dx-icon-folder" />
                </div>
                <div className="d-flex flex-10px" />
                <div className="d-flex flex-1 folderTitle_documentos">
                    <div className="d-flex font-weight-bold font-size-sm align-items-center">{item.denominacion}</div>
                    <div className="d-flex text-secondary font-size-xxxs align-items-center">
                        {item.tblDocumento?.length + " ficheros"}
                    </div>
                </div>
                {hasSubfolders && item.idCarpetaDocumentos === this.state.carpetaDocumentosSel?.idCarpetaDocumentos && (
                    <>
                        <div className="d-flex flex-10px" />
                        <Button
                            className="d-flex flex-60 itemIcon"
                            icon="chevrondoubleright"
                            type="default"
                            onClick={() => {
                                this.setState({
                                    multiViewCarpetasIndex: 1,
                                    multiViewCarpetas_DocumentosAgrupados: {
                                        ...this.state.multiViewCarpetas_DocumentosAgrupados,
                                        padre: item.denominacion,
                                    },
                                });
                            }}
                        />
                    </>
                )}
            </div>
        );
    }

    getSalarioBrutoAnual(data) {
        if (data.tblDatosSalariales == null) return 0;

        let salarioBase = data.tblDatosSalariales.salarioBase == null ? 0 : data.tblDatosSalariales.salarioBase;
        let plusAsistencia =
            data.tblDatosSalariales.plusAsistencia == null ? 0 : data.tblDatosSalariales.plusAsistencia;
        let plusResponsabilidad =
            data.tblDatosSalariales.plusResponsabilidad == null ? 0 : data.tblDatosSalariales.plusResponsabilidad;
        let plusPeligrosidad =
            data.tblDatosSalariales.plusPeligrosidad == null ? 0 : data.tblDatosSalariales.plusPeligrosidad;
        let incentivo = data.tblDatosSalariales.incentivo == null ? 0 : data.tblDatosSalariales.incentivo;
        let plusProductividad =
            data.tblDatosSalariales.plusProductividad == null ? 0 : data.tblDatosSalariales.plusProductividad;
        let acuerdoNC = data.tblDatosSalariales.acuerdoNC == null ? 0 : data.tblDatosSalariales.acuerdoNC;
        let salarioEspecie =
            data.tblDatosSalariales.salarioEspecie == null ? 0 : data.tblDatosSalariales.salarioEspecie;

        return (
            salarioBase * 14 +
            12 *
                (plusAsistencia +
                    plusResponsabilidad +
                    plusPeligrosidad +
                    incentivo +
                    plusProductividad +
                    acuerdoNC +
                    salarioEspecie)
        );
    }

    updateValor_paises(value) {
        let personaSel = { ...this.state.personaSel };
        let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        this.dxDataGrid_personas.cellValue(index, "nacionalidad", value);

        personaSel.nacionalidad = value;

        this.setState({ personaSel });
    }

    updateArchivos(data, accionesModificarDatos) {
        let {
            carpetaDocumentosSel,
            dataSource_documentos,
            personaSel,
            documentosNGrandParent,
            multiViewCarpetas_DocumentosAgrupados,
        } = this.state;

        if (!documentosNGrandParent || (documentosNGrandParent && documentosNGrandParent.length === 0)) {
            if (data.length === 1 && Array.isArray(data[0])) {
                data = data[0];
            }
            if (data.length > 0 && data[0].idPadre === carpetaDocumentosSel.idCarpetaDocumentos) {
                data = data.concat(
                    carpetaDocumentosSel.tblDocumento.filter((x) => {
                        return (
                            data.filter((y) => y.id === x.id).length === 0 &&
                            (carpetaDocumentosSel.subCarpeta == undefined ||
                                carpetaDocumentosSel.subCarpeta !== x.subCarpeta) &&
                            accionesModificarDatos.filter((y) => y.action === "delete" && y.id === x.id).length === 0 &&
                            (typeof x.id != "object" ||
                                accionesModificarDatos.filter((y) => y.action === "insert" && y.data?.id === x.id)
                                    .length > 0)
                        );
                    })
                );

                carpetaDocumentosSel.tblDocumento = data;
            } else if (data.length == 0) {
                data = carpetaDocumentosSel.tblDocumento.filter(
                    (x) =>
                        (carpetaDocumentosSel.subCarpeta == undefined ||
                            carpetaDocumentosSel.subCarpeta !== x.subCarpeta) &&
                        accionesModificarDatos.filter((y) => y.action === "delete" && y.id === x.id).length === 0 &&
                        (typeof x.id != "object" ||
                            accionesModificarDatos.filter((y) => y.action === "insert" && y.data?.id === x.id).length >
                                0)
                );

                carpetaDocumentosSel.tblDocumento = data;
                carpetaDocumentosSel.subCarpeta = null;
            }

            $.each(dataSource_documentos[0].items, function (index, item) {
                if (item.idCarpetaDocumentos === carpetaDocumentosSel.idCarpetaDocumentos) item.tblDocumento = data;
            });

            let grupos = Object.entries(
                Object.groupBy(carpetaDocumentosSel.tblDocumento, ({ subCarpeta }) => subCarpeta)
            ).reverse();
            if (multiViewCarpetas_DocumentosAgrupados || grupos.length > 1) {
                if (!multiViewCarpetas_DocumentosAgrupados) multiViewCarpetas_DocumentosAgrupados = {};
                multiViewCarpetas_DocumentosAgrupados.grupos = [];

                if (grupos.length <= 1) {
                    multiViewCarpetas_DocumentosAgrupados = null;
                } else {
                    grupos.forEach(([carpeta, tblDocumento]) => {
                        if (carpeta == "null" || carpeta == "undefined")
                            tblDocumento.map((x) => {
                                x.subCarpeta = carpeta;
                                return x;
                            });
                        multiViewCarpetas_DocumentosAgrupados.grupos.push({
                            denominacion: carpeta == "null" || carpeta == "undefined" ? "Sin agrupación" : carpeta,
                            subCarpeta: carpeta,
                            tblDocumento: tblDocumento,
                        });
                    });

                    multiViewCarpetas_DocumentosAgrupados.padre = carpetaDocumentosSel.denominacion;
                }
            }

            if (grupos.filter((x) => x[0] === carpetaDocumentosSel.subCarpeta).length === 0) {
                carpetaDocumentosSel.subCarpeta = null;
            }

            if (!documentosNGrandParent || (documentosNGrandParent && documentosNGrandParent.length == 0)) {
                let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
                this.dxDataGrid_personas.cellValue(index, "tblDocumento", data);
            }
        }

        if (documentosNGrandParent.length > 0 && data[0].idPadre === carpetaDocumentosSel.idCarpetaDocumentos) {
            let items = [];
            // tiene que insertarse la diferencia entre los añadidos(todos) y los que ya hay
            $.each(documentosNGrandParent, function (iPersona, persona) {
                $.each(data, function (index, item) {
                    persona.items.push(item);
                    items.push(item);
                });
            });

            carpetaDocumentosSel.tblDocumento = items;
            $.each(dataSource_documentos[0].items, function (index, item) {
                if (item.idCarpetaDocumentos === carpetaDocumentosSel.idCarpetaDocumentos) item.tblDocumento = items;
            });
        }

        this.setState(
            {
                dataSource_documentos: dataSource_documentos,
                carpetaDocumentosSel: carpetaDocumentosSel,
                multiViewCarpetas_DocumentosAgrupados: multiViewCarpetas_DocumentosAgrupados,
                multiViewCarpetasIndex: multiViewCarpetas_DocumentosAgrupados?.grupos?.length > 1 ? 1 : 0,
                accionesModificarDatos_fileUploader: accionesModificarDatos,
                documentosNGrandParent: documentosNGrandParent,
                showLoading_adjuntarArchivos: false,
            },
            () => {
                this.setState({ showLoading_adjuntarArchivos: null });
            }
        );
    }

    updateArchivos_ImportarDocumentos(data, accionesModificarDatos) {
        let _this = this;
        this.updateArchivos_ImportarDocumentos_CheckCodigoGestoria(data, (data, numNominasError, totalSize, maxSize) =>
            _this.setState(
                {
                    documentosImportarDocumentos: data,
                    accionesModificarDatos_fileUploader_ImportarDocumentos: accionesModificarDatos,
                    numNominasError: numNominasError,
                    showLoading_adjuntarArchivos: false,
                    maxFileExceeded: totalSize >= maxSize,
                },
                () => {
                    _this.setState({ showLoading_adjuntarArchivos: null });
                }
            )
        );
    }

    updateArchivos_ImportarDocumentos_CheckCodigoGestoria(data, cb) {
        let totalSize = 0;
        let maxSize = 0;
        codigosGestoria = [];
        $.each(data, function (index, item) {
            const regex = /\(([^,]+), ([^)]+)\)_empresa_\d+_trabajador_(\d{6})_\d{6}_/g;
            const match = regex.exec(item.nombre);
            if (match) {
                const nombre = match[2].trim().toUpperCase();
                const apellidos = match[1].trim().toUpperCase();
                const codigoGestoria = match[3];

                item.codigoGestoria = codigoGestoria;
                item.nombre = `${nombre} ${apellidos}`;
                codigosGestoria.push(codigoGestoria);
            } else if (item.codigoGestoria) {
                codigosGestoria.push(item.codigoGestoria);
            }

            totalSize += item.size;
            maxSize = item.maxSize;
        });

        let _this = this;
        _this.datasource_persona_codigoGestoria.load().done(function (result) {
            codigosGestoria = [];
            let numNominasError = 0;
            $.each(data, function (index, item) {
                let persona = result.filter((x) => x.codigoGestoria === item.codigoGestoria);
                if (persona.length > 0 && persona[0].idPersona > 0) {
                    if (persona.length === 1) {
                        if (persona[0].isCodigoGestoriaValidado) {
                            item.icon = "icon_Success";
                            item.subTitle = persona[0].nombre + " " + persona[0].apellidos;
                            item.error = false;
                            item.idPersona = persona[0].idPersona;
                            item.idUsuario =
                                persona[0].tblUsuario.length > 0 ? persona[0].tblUsuario[0].idUsuario : null;
                            item.notificationToken =
                                persona[0].tblUsuario.length > 0 ? persona[0].tblUsuario[0].notificationToken : null;
                            item.isCodigoGestoriaValidado = persona[0].isCodigoGestoriaValidado;
                        } else {
                            item.icon = "icon_helpCircle";
                            item.subTitle2 = persona[0].nombre + " " + persona[0].apellidos;
                            item.subTitle = "Código gestoría no validado".toUpperCase();
                            item.error = false;
                            item.idPersona = persona[0].idPersona;
                            item.idUsuario =
                                persona[0].tblUsuario.length > 0 ? persona[0].tblUsuario[0].idUsuario : null;
                            item.notificationToken =
                                persona[0].tblUsuario.length > 0 ? persona[0].tblUsuario[0].notificationToken : null;
                            item.isCodigoGestoriaValidado = persona[0].isCodigoGestoriaValidado;
                        }
                    } else {
                        item.icon = "icon_Warning";
                        item.subTitle = _this.getTrad("codigoMasUnaPersona").toUpperCase();
                        item.error = true;
                        numNominasError++;
                    }
                } else {
                    item.icon = "icon_Warning";
                    item.subTitle = _this.getTrad("personaNoEncontrada").toUpperCase();
                    item.error = true;
                    numNominasError++;
                }
            });

            data.sort((a, b) =>
                a.isCodigoGestoriaValidado === b.isCodigoGestoriaValidado ? 0 : a.isCodigoGestoriaValidado ? 1 : -1
            ); // Ordenación Código gestoria validado
            data.sort((a, b) => (a.error === b.error ? 0 : a.error ? -1 : 1)); // Ordenación errores

            cb(data, numNominasError, totalSize, maxSize);
        });
    }

    dxSelectionBox_ImportarDocumentos_continuar_onSelectionChanged(e) {
        this.setState({ ImportarDocumentos_EmpresaSel: e.selectedItem });

        this.updateArchivos_ImportarDocumentos_CheckCodigoGestoria(
            [...this.state.documentosImportarDocumentos],
            (data, numNominasError) =>
                this.setState({
                    documentosImportarDocumentos: data,
                    numNominasError: numNominasError,
                    showLoading_adjuntarArchivos: false,
                })
        );
    }

    dxButton_ImportarDocumentos_cancelar_onClick(e) {
        this.setState({
            documentosImportarDocumentos: [],
            numArchivos_LoadPanel_ProgressBar: 0,
            numNominasError: 0,
            nombreNominasImportadas: null,
            isVisible_LoadPanel_ProgressBar: false,
            isVisible_dxPopup_ImportarDocumentos: false,
            maxFileExceeded: false,
        });
    }

    dxButton_ImportarDocumentos_continuar_onClick(e) {
        let { documentosImportarDocumentos } = this.state;

        if (documentosImportarDocumentos.length > 0) this.setState({ popup_nombrarNominas_visible: true });
        else {
            notify({
                message: "Debe subir al menos un archivo",
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }

    dxDataGrid_tblPersonas_activo_editCellRender(e) {
        setTimeout(() => {
            if (e.data.isUsuario === 1) tooltipControl_creacion(e.cellElement, this.getTrad("persona_noDeshabilitar"));
        }, 200);

        // return (<div></div>)
        return this.getActiveTag(e.data, null, true);
    }

    getActiveTag = (data, fontSize, isClickable = false) => {
        const bgColor = data.activo ? "success" : "danger";
        const size = fontSize ? " " + fontSize : "";

        let properties = {
            className: "chip " + bgColor + size,
        };

        if (isClickable) {
            properties = {
                ...properties,
                onClick: () => this.showContratos_popup(data),
            };
        }

        return <div {...properties}>{data.activo ? this.getTrad("activo") : this.getTrad("noActivo")}</div>;
    };

    dxDataGrid_tblPersonas_activo_onValueChanged(data, e) {
        let _this = this;
        if (e.component.getController("editing").isEditing()) {
            e.setValue(data.value);
            let { personaSel } = this.state;
            personaSel.activo = data.value;

            this.setState({ personaSel });
        } else {
            //Update
            var persona = {};
            persona.activo = data.value;
            _this.loadPanel_show(true);
            _this.datasource_persona
                .store()
                .update(e.data.idPersona, patchRequestHandler(persona))
                .done(function () {
                    notify({
                        message: _this.getTrad("aviso_C_RegistroActualizado"),
                        type: "success",
                        displayTime: "1500",
                        closeOnClick: true,
                    });
                    _this.datasource_persona.reload().done(function () {
                        _this.loadPanel_hide();
                    });
                });
        }
    }

    showContratos_popup = (data) => {
        let _this = this;
        this.setState(
            {
                personaSel: data,
            },
            () => {
                this.datasource_tblPersonaNTipoContrato.load().done((tblPersonaNTipoContrato) => {
                    let personaSel = { ...this.state.personaSel };
                    personaSel.tblPersonaNTipoContrato = tblPersonaNTipoContrato;

                    _this.setState({
                        contratosNPersonaSel: personaSel,
                        personaSel: personaSel,
                        contratosPopup_isVisible: true,
                    });
                });
            }
        );
    };

    hideContratos_popup = () => {
        this.setState({
            contratosNPersonaSel: null,
            contratosPopup_isVisible: false,
        });
    };

    onIdentifySuccess = () => {
        const { personaSel } = this.state;

        this.setState({ identifyNeeded: false, identifySuccess: true }, () => {
            const selIndex = this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona);
            this.dxDataGrid_personas.editRow(selIndex);
        });
    };

    onCancelIdentify = () => {
        setTimeout(() => {
            this.setState({ identifyNeeded: false, personaSel: null });
        }, 0);
    };
    dxPopup_gestionarDocumentacion_onContentReady(e) {
        e.component.content().attr("id", "dxPopup_personal_gestionarDocumentacion");
    }

    dxPopup_gestionarDocumentacion_onHide() {
        let { datasource_datos_loadPromise_documentos } = this.state;
        if (datasource_datos_loadPromise_documentos) {
            this.datasource_tblDocumento.cancel(datasource_datos_loadPromise_documentos.operationId);
        }

        this.dxList_documentos_personasGeneral.option("searchValue", "");
        this.dxList_documentos_personasGeneral.scrollTo(0);
        this.setState({
            isVisible_dxPopup_gestionarDocumentacion_comun: false,
            carpetaDocumentosSel: null,
            dxList_persona_listaGeneral_selectedItemKeys: [],
            dataSource_documentos: this.state.dataSource_documentos_bk,
            datasource_datos_loadPromise_documentos: null,
            documentosNGrandParent: [],
        });
    }

    dxPopup_gestionarDocumentacion_onHiding(e) {
        this.dxPopup_gestionarDocumentacion_onHide();
    }

    dxButton_gestionarDocumentacion_guardar_onClick(e) {
        this.dxPopup_gestionarDocumentacion_onHide();
    }

    dxButton_gestionarDocumentacion_cancelar_onClick(e) {
        this.dxPopup_gestionarDocumentacion_onHide();
    }

    saveCalendario(idPersona) {
        var promises = [];
        var def = new $.Deferred();

        this.context_calendarioPersonal.invoke("fn_IU_tblCalendarioPersonal", {}, "POST").done(function (result) {
            def.resolve(result);
        });
        promises.push(def);
        return $.when.apply(undefined, promises).promise();
    }

    removeFotos() {
        let { removeFotos } = this.state;
        let _this = this;

        if (removeFotos.length === 0) return true;

        var promises = [];
        setTimeout(() => {
            $.each(removeFotos, function (index, item) {
                var promise = $.Deferred();
                if (item.idDocumento) {
                    _this.datasource_tblDocumento
                        .store()
                        .remove(item.idDocumento)
                        .done(function (item) {
                            promise.resolve();
                        });
                } else {
                    promise.resolve();
                }
                promises.push(promise);
            });
        }, 0);

        return $.when.apply(undefined, promises).promise();
    }

    saveDocuments(idPersona) {
        var promises = [];
        let { accionesModificarDatos_fileUploader } = this.state;
        let _this = this;
        $.each(accionesModificarDatos_fileUploader, function (index, item) {
            if (item.action === "delete") {
                var def = new $.Deferred();
                _this.datasource_tblDocumento
                    .store()
                    .remove(item.id)
                    .done(function (result) {
                        def.resolve(result);
                    });
                promises.push(def);
            } else if (item.action === "insert") {
                var def = new $.Deferred();
                let documento = {
                    denominacion: item.data.nombre ? item.data.nombre : item.data.denominacion,
                    subCarpeta:
                        !item.data.subCarpeta || item.data.subCarpeta == "undefined" || item.data.subCarpeta == "null"
                            ? null
                            : `${item.data.subCarpeta}`,
                    extension: item.data.extension,
                    documento: item.data.documento,
                    idCarpetaDocumentos: item.data.idPadre,
                    idPersona: idPersona,
                    requerido: item.data.requerido,
                    firmado: item.data.firmado,
                };
                _this.datasource_tblDocumento
                    .store()
                    .insert(documento)
                    .done(function (result) {
                        def.resolve(result);
                    });
                promises.push(def);
            } else if (item.action === "update") {
                var def = new $.Deferred();
                let documento = {
                    denominacion: item.data.nombre ? item.data.nombre : item.data.denominacion,
                    subCarpeta:
                        !item.data.subCarpeta || item.data.subCarpeta == "undefined" || item.data.subCarpeta == "null"
                            ? null
                            : `${item.data.subCarpeta}`,
                    firmado: item.data.firmado,
                };
                _this.datasource_tblDocumento
                    .store()
                    .update(item.data.idDocumento, patchRequestHandler(documento))
                    .done(function (result) {
                        def.resolve(result);
                    });
                promises.push(def);
            }
        });
        return $.when.apply(undefined, promises).promise();
    }

    getIcon(data) {
        let icon = "",
            extension = data.extension;

        if (data.requerido) {
            icon = "icon_Warning";
        } else if (data.firmado === false) icon = "icon_Warning";
        else if (extension) {
            if (extension.split("/")[0] === "image") icon = "dx-icon-photo";
            else if (extension.split("/")[1] === "pdf") icon = "dx-icon-exportpdf";
            else {
                if (extension.split("/")[0] === "video") icon = "dx-icon-video";
                else if (extension.includes("excel") || extension.includes("spreadsheetml"))
                    icon = "dx-icon-exportxlsx";
                else icon = "dx-icon-doc";
            }
        } else icon = "dx-icon-doc";

        return icon;
    }
    //#endregion

    //#region  ENVIAR ALTAS GESTORÍA

    onClick_enviarAltasGestoria(e) {
        this.setState({
            isVisible_dxPopup_enviarAltasGestoria: true,
        });
        this.dxDataGrid_personas_enviarAltasGestoria.clearFilter();
    }

    onClick_enviarMailAltasGestoria(e) {
        let _this = this;
        this.datasource_tblCorreoAltaGestoriaNCentroLav.load().done(function (result) {
            // LAVANDERIAS Y CENTROS DE TRABAJOS DE LAS PERSONAS SELECCIONADAS
            let { personasSel_enviarAltasGestoria } = _this.state;
            let idsLavanderia_persona = [],
                idsCentroTrabajo_persona = [];

            $.each(personasSel_enviarAltasGestoria, function (index, item) {
                if (item.idCentroTrabajo != null) {
                    idsCentroTrabajo_persona.push(item.idCentroTrabajo);
                } else if (item.idLavanderia != null) {
                    idsLavanderia_persona.push(item.idLavanderia);
                }
            });

            // LAVANDERIAS Y CENTROS DE TRABAJOS ENCONTRADOS
            let idsLavanderia_encontrados = [],
                idsCentroTrabajo_encontrados = [];

            $.each(result, function (index, item) {
                if (item.idCentroTrabajo != null) {
                    idsCentroTrabajo_encontrados.push(item.idCentroTrabajo);
                } else if (item.idLavanderia != null) {
                    idsLavanderia_encontrados.push(item.idLavanderia);
                }
            });

            // Quitar duplicados
            idsLavanderia_persona = idsLavanderia_persona.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            idsLavanderia_encontrados = idsLavanderia_encontrados.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            idsCentroTrabajo_persona = idsCentroTrabajo_persona.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            idsCentroTrabajo_encontrados = idsCentroTrabajo_encontrados.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });

            let coincidenLavs = idsLavanderia_persona.sort().join(",") === idsLavanderia_encontrados.sort().join(","),
                coincidenCentros =
                    idsCentroTrabajo_persona.sort().join(",") === idsCentroTrabajo_encontrados.sort().join(",");

            if (result.length > 0 && coincidenLavs && coincidenCentros) {
                if (_this.state.personasSel_enviarAltasGestoria.length > 0) {
                    _this.dxDataGrid_personas_enviarAltasGestoria.clearFilter();
                    _this.setState({
                        isVisible_dxPopup_enviarAltasGestoria_loading: true,
                    });
                    _this.context_persona.invoke("SendMail_DatosPersonales", {}, "POST").done(function (result) {
                        let mensaje = _this.getTrad("correoComunicacionAltaEnviadoDirecciones");
                        mensaje += "<div class='listStringContainer'>";
                        $.each(result, function (index, item) {
                            if (index > 0) mensaje += "<br />";
                            mensaje += "- " + item;
                        });
                        mensaje += "</div>";

                        dxMensajePregunta(mensaje, [[getTrad("aceptar"), function () {}, "danger"]]);

                        _this.setState({
                            personasSel_enviarAltasGestoria: [],
                            isVisible_dxPopup_enviarAltasGestoria_loading: false,
                            isVisible_dxPopup_enviarAltasGestoria: false,
                            idAsuntoMailAltasGestorias: 1,
                        });
                    });
                } else {
                    notify({
                        message: "Debe seleccionar por lo menos a una persona.",
                        type: "error",
                        displayTime: "1500",
                        closeOnClick: true,
                    });
                }
            } else if (_this.state.personasSel_enviarAltasGestoria.length == 0) {
                notify({
                    message: "Debe seleccionar por lo menos a una persona.",
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            } else {
                let { datasource_tblCentroTrabajo_Lavanderia_todos } = _this.state;

                let listaLavsCentros_faltantes = [];
                if (!coincidenLavs) {
                    let lavanderiasFaltantes = idsLavanderia_persona.filter(
                        (n) => !idsLavanderia_encontrados.includes(n)
                    );

                    $.each(lavanderiasFaltantes, function (index, item) {
                        let idCentroLav = "[-1," + item + "]";
                        let itemCentroLav = $.grep(datasource_tblCentroTrabajo_Lavanderia_todos, function (item) {
                            return item.idCentroLav == idCentroLav;
                        });

                        if (itemCentroLav.length > 0) listaLavsCentros_faltantes.push(itemCentroLav[0].denominacion);
                    });
                }

                if (!coincidenCentros) {
                    let centrosFaltantes = idsCentroTrabajo_persona.filter(
                        (n) => !idsCentroTrabajo_encontrados.includes(n)
                    );
                    $.each(centrosFaltantes, function (index, item) {
                        let idCentroLav = "[" + item + ",-1]";
                        let itemCentroLav = $.grep(datasource_tblCentroTrabajo_Lavanderia_todos, function (item) {
                            return item.idCentroLav == idCentroLav;
                        });

                        if (itemCentroLav.length > 0) listaLavsCentros_faltantes.push(itemCentroLav[0].denominacion);
                    });
                }

                listaLavsCentros_faltantes.sort(function (a, b) {
                    var textA = a.toUpperCase();
                    var textB = b.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                });

                let mensaje =
                    "No se han podido enviar los correos debido a que las siguientes lavanderías/centros de trabajo no tiene correos parametrizados:";
                mensaje += "<div class='listStringContainer'>";
                $.each(listaLavsCentros_faltantes, function (index, item) {
                    if (index > 0) mensaje += "<br />";
                    mensaje += "- " + item;
                });
                mensaje += "</div>";

                dxMensajePregunta(mensaje, [[getTrad("aceptar"), function () {}, "danger"]]);

                _this.loadPanel_hide();
            }
        });
    }

    onClick_cancelarAltasGestoria(e) {
        this.setState({
            isVisible_dxPopup_enviarAltasGestoria: false,
            personasSel_enviarAltasGestoria: [],
        });

        this.dxDataGrid_personas_enviarAltasGestoria.clearFilter();
    }

    dxPopup_enviarAltasGestoria_onHiding(e) {
        this.setState({
            isVisible_dxPopup_enviarAltasGestoria: false,
            personasSel_enviarAltasGestoria: [],
        });
        this.dxDataGrid_personas_enviarAltasGestoria.clearFilter();
    }

    onToolbarPreparing_dxDataGrid_tblPersona_EnviarAltasGestoria(e) {
        let _this = this;
        let { idAsuntoMailAltasGestorias } = this.state;
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    text: _this.getTrad("actualizar"),
                    icon: "refresh",
                    onClick: (args) => {
                        _this.datasource_persona_enviarAltasGestoria.reload();
                    },
                },
            },
            {
                location: "after",
                widget: "dxSelectBox",
                showText: "inMenu",
                options: {
                    width: 245,
                    value: idAsuntoMailAltasGestorias,
                    dataSource: _this.enum_asuntoMailAltasGestorias,
                    displayExpr: "denominacion",
                    valueExpr: "value",
                    onValueChanged: function (e) {
                        _this.setState({ idAsuntoMailAltasGestorias: e.value });
                    },
                },
            }
        );
    }

    onRowClick_dxDataGrid_tblPersona_EnviarAltasGestoria(e) {
        const { personasSel_enviarAltasGestoria } = this.state;
        let _personasSel_enviarAltasGestoria = [...personasSel_enviarAltasGestoria];
        const data = e.data;
        _personasSel_enviarAltasGestoria.push({
            idPersona: data.idPersona,
            nombre: data.nombre,
            apellidos: data.apellidos,
            idLavanderia: data.idLavanderia,
            idCentroTrabajo: data.idCentroTrabajo,
        });

        const filter = [
            "idPersona",
            "noneof",
            _personasSel_enviarAltasGestoria.map((x) => {
                return x.idPersona;
            }),
        ];
        e.component.option("filterValue", filter);

        this.setState({
            personasSel_enviarAltasGestoria: _personasSel_enviarAltasGestoria,
        });
    }

    dxList_persona_enviarAltasGestoria_onItemDeleted(e) {
        const { personasSel_enviarAltasGestoria } = this.state;
        let _personasSel_enviarAltasGestoria = [...personasSel_enviarAltasGestoria];
        const filter = [
            "idPersona",
            "noneof",
            _personasSel_enviarAltasGestoria.map((x) => {
                return x.idPersona;
            }),
        ];
        this.dxDataGrid_personas_enviarAltasGestoria.option("filterValue", filter);
        this.dxDataGrid_personas_enviarAltasGestoria.refresh(true);
    }

    //#endregion

    // #region Llamamientos

    renderAsociarLlamamiento = () => {
        const { datasource_tblLlamamiento, personaSel } = this.state;
        const llamamiento = datasource_tblLlamamiento.find(
            (x) =>
                (personaSel?.idLlamamiento != null && x.idLlamamiento === personaSel?.idLlamamiento) ||
                (personaSel?.idLlamamiento == null && x.idPersona === personaSel?.idPersona)
        );
        return (
            <div className="d-flex justify-content-end">
                {llamamiento && (
                    <div className="mr-3 position-relative">
                        <TextBox
                            value={`${this.getTrad("incorporacion")} #${llamamiento?.codigoLlamamiento}`}
                            stylingMode="outlined"
                            readOnly
                        />
                        <Button
                            className="clearButtonLlamamiento"
                            stylingMode="text"
                            icon="clear"
                            onClick={this.onClick_asignarLlamamientos_desasignar}
                        />
                    </div>
                )}
                <Button
                    text={this.getTrad("asociarIncorporacion")}
                    type={"default"}
                    onClick={this.onClick_asignarLlamamientos_asignar}
                />
            </div>
        );
    };

    onClick_asignarLlamamientos_consulta = () => {
        this.setState({
            dxPopup_llamamientos_isVisible: true,
            dxPopup_llamamientos_consulta: true,
        });
    };

    onClick_asignarLlamamientos_asignar = () => {
        this.setState({
            dxPopup_llamamientos_isVisible: true,
            dxPopup_llamamientos_consulta: false,
        });
    };

    onClick_asignarLlamamientos_desasignar = () => {
        const { personaSel } = this.state;

        let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        let objPersona = { ...personaSel };

        if (!personaSel.idPersona) {
            objPersona.idLlamamiento = null;
            objPersona.idLavanderia = null;
            objPersona.idTurno = null;
            objPersona.idTipoTrabajo = null;
            objPersona.idCategoriaInterna = null;
            objPersona.idFormatoDiasLibres = null;
            objPersona.tblDiasLibresPersonal = null;
            objPersona.idCentroLav = null;
            objPersona.idCategoriaConvenio = null;
            objPersona.idEmpresaPolarier = null;
            objPersona.idLocalizacion = null;
            objPersona.idAdmCentroCoste = null;
            objPersona.idAdmElementoPEP = null;

            if (index >= 0) {
                this.dxDataGrid_personas.cellValue(index, "idLlamamiento", objPersona.idLlamamiento);
                this.dxDataGrid_personas.cellValue(index, "idLavanderia", objPersona.idLavanderia);
                this.dxDataGrid_personas.cellValue(index, "idTurno", objPersona.idTurno);
                this.dxDataGrid_personas.cellValue(index, "idTipoTrabajo", objPersona.idTipoTrabajo);
                this.dxDataGrid_personas.cellValue(index, "idCategoriaInterna", objPersona.idCategoriaInterna);
                this.dxDataGrid_personas.cellValue(index, "idFormatoDiasLibres", objPersona.idFormatoDiasLibres);
                this.dxDataGrid_personas.cellValue(index, "tblDiasLibresPersonal", objPersona.tblDiasLibresPersonal);
                this.dxDataGrid_personas.cellValue(index, "idCentroLav", objPersona.idCentroLav);
                this.dxDataGrid_personas.cellValue(index, "idCategoriaConvenio", objPersona.idCategoriaConvenio);
                this.dxDataGrid_personas.cellValue(index, "idEmpresaPolarier", objPersona.idEmpresaPolarier);
                this.dxDataGrid_personas.cellValue(index, "idLocalizacion", objPersona.idLocalizacion);
                this.dxDataGrid_personas.cellValue(index, "idAdmCentroCoste", objPersona.idAdmCentroCoste);
                this.dxDataGrid_personas.cellValue(index, "idAdmElementoPEP", objPersona.idAdmElementoPEP);
            }
        } else {
            objPersona.idLlamamiento = 0;

            if (index >= 0) {
                this.dxDataGrid_personas.cellValue(index, "idLlamamiento", objPersona.idLlamamiento);
            }
        }

        this.setState({ personaSel: objPersona });
    };

    dxPopup_llamamientos_onHiding = () => {
        this.setState({
            dxPopup_llamamientos_isVisible: false,
            dxPopup_llamamientos_consulta: true,
        });
    };

    AsignarLlamamientos_onSelectionChanged = (value) => {
        const { personaSel, datasource_tblLavanderiaNUsuario, datasource_tblCategoriaInterna } = this.state;

        let index = personaSel ? this.dxDataGrid_personas.getRowIndexByKey(personaSel.idPersona) : 0;
        let objPersona = { ...personaSel };

        objPersona.idLlamamiento = value.idLlamamiento;
        objPersona.idLavanderia = value.idLavanderia;
        objPersona.idTurno = value.idTurno;
        objPersona.idTipoTrabajo = value.idTipoTrabajo;
        objPersona.idCategoriaInterna = value.idCategoriaInterna;
        objPersona.idFormatoDiasLibres = value.idFormatoDiasLibres;
        objPersona.tblDiasLibresPersonal = value.tblDiasLibresPersonal_Llamamiento;

        objPersona.idCentroLav = `[-1,${value.idLavanderia}]`;

        const categoriaInterna = datasource_tblCategoriaInterna.find(
            (x) => x.idCategoriaInterna === value.idCategoriaInterna
        );
        let idCategoriaConvenio = categoriaInterna?.idCategoriaConvenio;

        objPersona.idCategoriaConvenio = idCategoriaConvenio;
        objPersona.tblDatosSalariales = {
            numPagas: 12,
            isTrienio: false,
            ...personaSel.tblDatosSalariales,
            ...categoriaInterna,
        };

        const lavanderia = datasource_tblLavanderiaNUsuario?.find((x) => x.idLavanderia === value.idLavanderia);
        objPersona.idEmpresaPolarier = lavanderia.idEmpresaPolarier;
        objPersona.idLocalizacion = lavanderia.idLocalizacion;
        objPersona.idAdmElementoPEP = lavanderia.idAdmElementoPEP;
        objPersona.idAdmCentroCoste = null;

        if (personaSel.idPersona == null) {
            objPersona.fechaAltaContrato = new Date(value.fechaIni);

            if (objPersona.fechaAltaContrato < Date.now() || isSameDay(objPersona.fechaAltaContrato, new Date())) {
                objPersona.fechaAltaContrato = addDays(new Date(), 1);
            }

            objPersona.tblDatosSalariales.fechaAntiguedad = objPersona.fechaAltaContrato;
            objPersona.idTipoContrato = value.idTipoContrato;
        }

        let datasource_tblCentroTrabajo_Lavanderia = this.getCentrosEmpresa(lavanderia.idEmpresaPolarier);

        this.setState({ personaSel: objPersona, datasource_tblCentroTrabajo_Lavanderia });

        if (index >= 0) {
            this.dxDataGrid_personas.cellValue(index, "idLlamamiento", objPersona.idLlamamiento);
            this.dxDataGrid_personas.cellValue(index, "idLavanderia", objPersona.idLavanderia);
            this.dxDataGrid_personas.cellValue(index, "idTurno", objPersona.idTurno);
            this.dxDataGrid_personas.cellValue(index, "idTipoTrabajo", objPersona.idTipoTrabajo);
            this.dxDataGrid_personas.cellValue(index, "idCategoriaInterna", objPersona.idCategoriaInterna);
            this.dxDataGrid_personas.cellValue(index, "idFormatoDiasLibres", objPersona.idFormatoDiasLibres);
            this.dxDataGrid_personas.cellValue(index, "tblDiasLibresPersonal", objPersona.tblDiasLibresPersonal);
            this.dxDataGrid_personas.cellValue(index, "idCentroLav", objPersona.idCentroLav);
            this.dxDataGrid_personas.cellValue(index, "idCategoriaConvenio", objPersona.idCategoriaConvenio);
            this.dxDataGrid_personas.cellValue(index, "tblDatosSalariales", objPersona.tblDatosSalariales);
            this.dxDataGrid_personas.cellValue(index, "idEmpresaPolarier", objPersona.idEmpresaPolarier);
            this.dxDataGrid_personas.cellValue(index, "idLocalizacion", objPersona.idLocalizacion);
            this.dxDataGrid_personas.cellValue(index, "idAdmElementoPEP", objPersona.idAdmElementoPEP);
            this.dxDataGrid_personas.cellValue(index, "idAdmCentroCoste", objPersona.idAdmCentroCoste);
        }

        this.dxPopup_llamamientos_onHiding();
    };

    dxPopup_llamamientos_refresh = () => {
        this.dataSource_tblLlamamiento
            .reload()
            .then((datasource_tblLlamamiento) => this.setState({ datasource_tblLlamamiento }));
    };

    // #endregion

    // #region LOAD PANEL

    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }

    // #endregion

    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    onValueChange = (newState) => {
        this.setState(newState);
    };

    componentDidMount() {
        this.cargaDatosInicio();
    }

    componentWillUnmount() {
        //#region SignalRCore
        signalRCoreService.clearEvents();
        //#endregion
    }
}

class btnRemovePersona_Component extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    onClick = () => {
        const { data } = this.props;
        if (!data.data.activo) {
            this.props.data.component.deleteRow(data.rowIndex);
        } else {
            notify({
                message: "No se ha podido eliminar el registro debido a que la persona tiene contratos sin finalizar",
                type: "error",
                displayTime: "2000",
                closeOnClick: true,
            });
        }
    };

    render() {
        return (
            <div className="container_spanCentrado pointer" onMouseDown={this.onClick}>
                <div className="icon_Cerrar font-size-lg" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
    resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(PersonalGeneral);
