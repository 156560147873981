import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import $ from "jquery";

import ArrayStore from "devextreme/data/array_store";
import Query from "devextreme/data/query";
import notify from "devextreme/ui/notify";
import { Column } from "devextreme-react/data-grid";

import SharedDataGridHistorico from "../shared/SharedDataGridHistorico";
import SharedPopupHistorico from "../shared/SharedPopupHistorico";
import {
  convertClienteUtcToSinUtc,
  formatDate_parameter,
  getTrad,
} from "helpers";

const PopupHistoricoPrecioLavadoPrenda = ({
  visible,
  isEditing,
  prendaSel,
  datasource_tblPrenda,
  getIsCerrado,
  onHiding,
  onSave,
  lavanderia,
}) => {
  const dataGridRef = useRef(null);

  const [prendaSelState, setPrendaSelStateState] = useState(prendaSel);

  useEffect(() => {
    if (visible) {
      dataGridRef?.current?.instance?.repaint();
      setPrendaSelStateState($.extend(true, {}, prendaSel));
    }
  }, [visible]);

  const format = {
    style: "currency",
    currency: lavanderia.moneda,
    minimumFractionDigits: 4,
  };

  const editorOptions = {
    step: 0,
    format: format,
    min: 0,
    max: 99999.9999,
  };

  const handleOnSave = () => {
    const dataGridInstance = dataGridRef?.current?.instance;

    dataGridInstance.saveEditData().done(() => {
      setTimeout(() => {
        let isValid = true;

        const data = dataGridInstance.getVisibleRows();

        data.forEach((row) => (isValid = isValid && !row.isEditing));

        const tblPrecioLavadoPrenda = data.map(({ data }) => {
          return {
            precio: data.precio,
            fecha: data.fecha,
            idPrenda: prendaSelState.idPrenda ?? 0,
          };
        });

        var precioLavado = Query(tblPrecioLavadoPrenda)
          .filter(
            (tf) =>
              convertClienteUtcToSinUtc(
                new Date(formatDate_parameter(tf.fecha))
              ) <=
              convertClienteUtcToSinUtc(
                new Date(formatDate_parameter(new Date()))
              )
          )
          .sortBy("fecha", true)
          .toArray()?.[0]?.precio;

        if (!isValid) {
          notify({
            message: getTrad("errorIntroduccionDatos"),
            type: "error",
            displayTime: 1500,
            closeOnClick: true,
          });
        } else if (isEditing) {
          datasource_tblPrenda
            .store()
            .update(prendaSelState.idPrenda, {
              precioLavado,
              tblPrecioLavadoPrenda,
            })
            .done(() =>
              datasource_tblPrenda.reload().done((data) => {
                onSave(data);
                notify({
                  message: getTrad("aviso_C_RegistroActualizado"),
                  type: "success",
                  displayTime: 1500,
                  closeOnClick: true,
                });
              })
            )
            .catch(() => {
              notify({
                message: getTrad("aviso_RegistroNoActualizado"),
                type: "error",
                displayTime: 1500,
                closeOnClick: true,
              });
            });
        } else {
          onSave(tblPrecioLavadoPrenda, precioLavado);
        }
      }, 0);
    });
  };

  const validationRules = [
    {
      type: "required",
      message: getTrad("campoNecesario"),
    },
  ];

  const dataSource = new ArrayStore({
    data: prendaSelState?.tblPrecioLavadoPrenda,
  });

  return (
    <SharedPopupHistorico
      visible={visible}
      title={getTrad("precioLavado")}
      centerTitle={prendaSelState?.denominacion}
      onHiding={onHiding}
      onSave={handleOnSave}
    >
      <SharedDataGridHistorico
        ref={dataGridRef}
        dataSource={dataSource}
        keyExpr={"precio"}
        visible={visible}
        errorMessage={"error_historicoPrecioAlmenosUnPrecio"}
        getIsCerrado={getIsCerrado}
      >
        <Column
          dataField={"precio"}
          caption={getTrad("precio")}
          alignment={"center"}
          dataType={"number"}
          format={format}
          validationRules={validationRules}
          editorOptions={editorOptions}
        />
      </SharedDataGridHistorico>
    </SharedPopupHistorico>
  );
};

const mapStateToProps = ({ Global }) => ({
  lavanderia: Global.lavanderia,
});

export default connect(mapStateToProps)(PopupHistoricoPrecioLavadoPrenda);
