import React, { useEffect, useRef, useState } from "react";

import $ from "jquery";

import ArrayStore from "devextreme/data/array_store";
import Query from "devextreme/data/query";
import notify from "devextreme/ui/notify";
import { Column } from "devextreme-react/data-grid";

import SharedDataGridHistorico from "../shared/SharedDataGridHistorico";
import SharedPopupHistorico from "../shared/SharedPopupHistorico";
import {
  convertClienteUtcToSinUtc,
  formatDate_parameter,
  getTrad,
} from "helpers";

const format = "#,##0 g";

const editorOptions = {
  step: 0,
  format,
  min: 0,
  max: 100000,
};

const PopupHistoricoPesoPrenda = ({
  visible,
  isEditing,
  prendaSel,
  datasource_tblPrenda,
  getIsCerrado,
  onHiding,
  onSave,
}) => {
  const dataGridRef = useRef(null);

  const [prendaSelState, setPrendaSelStateState] = useState(prendaSel);

  useEffect(() => {
    if (visible) {
      dataGridRef?.current?.instance?.repaint();
      setPrendaSelStateState($.extend(true, {}, prendaSel));
    }
  }, [visible]);

  const handleOnSave = () => {
    const dataGridInstance = dataGridRef?.current?.instance;

    dataGridInstance.saveEditData().done(() => {
      setTimeout(() => {
        let isValid = true;

        const data = dataGridInstance.getVisibleRows();

        data.forEach((row) => (isValid = isValid && !row.isEditing));

        const tblPrenda_historico_peso = data.map(({ data }) => {
          return {
            peso: Number(data.peso),
            fecha: data.fecha,
            idPrenda: prendaSelState.idPrenda ?? 0,
          };
        });

        var peso = Query(tblPrenda_historico_peso)
          .filter(
            (tf) =>
              convertClienteUtcToSinUtc(
                new Date(formatDate_parameter(tf.fecha))
              ) <=
              convertClienteUtcToSinUtc(
                new Date(formatDate_parameter(new Date()))
              )
          )
          .sortBy("fecha", true)
          .toArray()?.[0]?.peso;

        if (!isValid) {
          notify({
            message: getTrad("errorIntroduccionDatos"),
            type: "error",
            displayTime: 1500,
            closeOnClick: true,
          });
        } else if (isEditing) {
          datasource_tblPrenda
            .store()
            .update(prendaSelState.idPrenda, {
              peso,
              tblPrenda_historico_peso,
            })
            .done(() =>
              datasource_tblPrenda.reload().done((data) => {
                onSave(data);
                notify({
                  message: getTrad("aviso_C_RegistroActualizado"),
                  type: "success",
                  displayTime: 1500,
                  closeOnClick: true,
                });
              })
            )
            .catch(() => {
              notify({
                message: getTrad("aviso_RegistroNoActualizado"),
                type: "error",
                displayTime: 1500,
                closeOnClick: true,
              });
            });
        } else {
          onSave(tblPrenda_historico_peso, peso);
        }
      }, 0);
    });
  };

  const validationRules = [
    {
      type: "required",
      message: getTrad("campoNecesario"),
    },
  ];

  const dataSource = new ArrayStore({
    data: prendaSelState?.tblPrenda_historico_peso,
  });

  return (
    <SharedPopupHistorico
      visible={visible}
      title={getTrad("peso")}
      centerTitle={prendaSelState?.denominacion}
      onHiding={onHiding}
      onSave={handleOnSave}
    >
      <SharedDataGridHistorico
        ref={dataGridRef}
        dataSource={dataSource}
        keyExpr={"peso"}
        visible={visible}
        errorMessage={"error_historicoPesoAlmenosUnPeso"}
        getIsCerrado={getIsCerrado}
      >
        <Column
          dataField={"peso"}
          caption={getTrad("peso")}
          alignment={"center"}
          format={format}
          editorOptions={editorOptions}
          validationRules={validationRules}
        />
      </SharedDataGridHistorico>
    </SharedPopupHistorico>
  );
};

export default PopupHistoricoPesoPrenda;
