import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";

import DxForm, { Item } from "devextreme-react/form";
import { getTrad } from "helpers";
import DataGridArticulos from "components/Administracion/DataGridArticulos";
import { Card, CardBody } from "reactstrap";
import {
    // ,tblFormaPago
    datasource_tblAdmTipoElemento, //tblTipoElemento,
    tblTipoDescuento,
    datasource_tblIvaNPais_Venta,
    sharedEditorOptions,
    datasource_tblCliente,
} from "pages/Administracion/shared/model";
import { generateCode, handleDefaultFieldsCliente } from "pages/Administracion/shared/functions";
import TablaResumen from "pages/Administracion/shared/components/TablaResumen";
import Observaciones from "pages/Administracion/shared/components/Observaciones";
import ToggleEstado from "./ToggleEstado";
import TasaCambioTipoCambioComponent from "pages/Administracion/shared/components/TasaCambioTipoCambioComponent";
import { getClearPresupuesto } from "pages/Administracion/PresupuestoVenta/model";

const Form = forwardRef(({ formData = {}, setIdMoneda, validations, idMoneda }, ref) => {
    const [codigoElemento, setCodigoElemento] = useState();
    const getText = (trad) => ({ text: getTrad(trad) });
    const previousCode = useRef();
    const formSuperiorRef = useRef();
    // const formInferiorRef = useRef();
    const summaryFormRef = useRef();
    const TCTCFormRef = useRef();
    const colCount = 5;
    const multiplier = 3;
    useImperativeHandle(ref, () => ({
        getFormData,
        hasEditData,
    }));

    const getFormData = () => formSuperiorRef?.current?.instance?.option("formData");

    useEffect(() => {
        validations.push(checkIsValid);
    }, []);

    useEffect(() => {
        if (formData.codigo != null) {
            previousCode.current = formData.codigo;
        }
        setCodigoElemento(formData.codigo);
    }, [formData]);

    const setCodigoElemento_Wrapper = (code) => {
        const prefijo = code.substring(0, 2);
        const fecha = code.substring(2, 6);
        const prevPrefijo = previousCode.current?.substring(0, 2);
        const prevFecha = previousCode.current?.substring(2, 6);
        if (prefijo == prevPrefijo && fecha == prevFecha) {
            formData.codigo = previousCode.current;
            setCodigoElemento(code);
        } else {
            formData.codigo = code;
            setCodigoElemento(code);
        }
    };

    const checkIsValid = () => {
        const superiorFormValidation = formSuperiorRef.current.instance.validate();
        // const inferiorFormValidation = formInferiorRef.current.instance.validate();
        const tctcFormValidation = TCTCFormRef.current.checkIsValid();
        const isformValidated =
            superiorFormValidation.isValid /* && inferiorFormValidation.isValid */ && tctcFormValidation.isValid;
        return new Promise((resolve, reject) => {
            if (isformValidated) {
                resolve();
            }
            reject();
        });
    };

    const setDefaultConfiguration = (idAdmCliente) => {
        const cliente = datasource_tblCliente.items().find((x) => x.idAdmCliente === idAdmCliente);

        if (cliente) {
            const formSuperior = formSuperiorRef.current.instance;

            const fieldsFormSuperior = [
                "idMoneda",
                "idIvaNPais",
                {
                    form: "idAdmFormaPago",
                    cliente: "idAdmFormaCobro",
                },
            ];

            handleDefaultFieldsCliente(fieldsFormSuperior, formSuperior, cliente);
        }
    };

    const labels = {
        codigo: getText("codigo"),
        idAdmCliente: getText("cliente"),
        idTipoPresupuesto: getText("tipoPresupuesto"),
        fechaCreacion: getText("fecha"),
        idMoneda: getText("moneda"),
        idAdmFormaPago: getText("formaPago"),
        descuento: getText("descuento"),
        idAdmPresupuestoVenta_Estado: getText("estado"),
        observaciones: getText("observaciones"),
        bruto: getText("totalBruto"),
        neto: getText("neto"),
    };

    const editorOptions = {
        idTipoPresupuesto: {
            dataSource: datasource_tblAdmTipoElemento,
            valueExpr: "idAdmTipoElemento",
            displayExpr: "denominacion",
            onValueChanged: (e) =>
                e.value != null &&
                generateCode(
                    formSuperiorRef.current.instance.option("formData").fechaCreacion ?? new Date(),
                    setCodigoElemento_Wrapper,
                    e.value,
                    "presupuestoVenta"
                ),
            placeholder: getTrad("tipoPresupuesto"),
        },
        idAdmCliente: {
            ...sharedEditorOptions.idAdmCliente,
        },
        idAdmTipoDescuento: {
            dataSource: tblTipoDescuento(idMoneda),
            valueExpr: "idAdmTipoDescuento",
            displayExpr: "simbolo",
            // value: summaryData.idAdmTipoDescuento
        },
        idIvaNPais: {
            dataSource: datasource_tblIvaNPais_Venta,
            // format: formats.percent,
            defaultValue: 0,
            valueExpr: "idIvaNPais",
            displayExpr: "valor",
            itemRender: (data) => {
                const displayExpr = data.valor * 100 + " %";
                return { displayExpr };
            },
        },
    };

    const onFieldDataChanged = (e) => {
        if (e.dataField === "idMoneda") {
            setIdMoneda(e.value);
        }
        if (e.dataField === "fechaCreacion" && formData.idTipoPresupuesto != null) {
            generateCode(e.value, setCodigoElemento_Wrapper, formData.idTipoPresupuesto, "presupuestoVenta");
            TCTCFormRef.current?.recalcularTasaCambio();
        }
        if (e.dataField === "idAdmTipoCambio") {
            TCTCFormRef.current?.recalcularTasaCambio();
        }
        if (e.dataField === "idAdmCliente") {
            setDefaultConfiguration(e.value);
        }
    };

    const updateState = (state) => {
        formSuperiorRef.current.instance.option("formData").idAdmPresupuestoVenta_Estado = state;
    };

    const hasEditData = (savedFormData = getClearPresupuesto()) => {
        let editData = true;
        const {
            idAdmCliente,
            fechaCreacion,
            idMoneda,
            idAdmFormaPago,
            idAdmTipoCambio,
            tasaCambio,
            observaciones,
            descuento,
            idAdmTipoDescuento,
            idIvaNPais,
        } = formSuperiorRef.current.instance.option("formData");
        if (
            idAdmCliente == savedFormData.idAdmCliente &&
            fechaCreacion == savedFormData.fechaCreacion &&
            idMoneda == savedFormData.idMoneda &&
            idAdmFormaPago == savedFormData.idAdmFormaPago &&
            idAdmTipoCambio == savedFormData.idAdmTipoCambio &&
            tasaCambio == savedFormData.tasaCambio &&
            observaciones == savedFormData.observaciones &&
            descuento == savedFormData.descuento &&
            idAdmTipoDescuento == savedFormData.idAdmTipoDescuento &&
            idIvaNPais == savedFormData.idIvaNPais
        ) {
            editData = false;
        }
        return editData;
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Card
                style={{
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    borderWidth: 0.1,
                    overflow: "visible",
                }}
            >
                <CardBody>
                    <div className={"d-flex"}>
                        <div className="ml-auto ">
                            <ToggleEstado formData={formData} updateState={updateState} />
                        </div>
                    </div>
                    <div className={"d-flex mt-4"}>
                        <DxForm
                            ref={formSuperiorRef}
                            formData={formData}
                            onFieldDataChanged={onFieldDataChanged}
                            colCount={colCount * multiplier}
                            height={"100%"}
                            width={"100%"}
                            labelLocation={"top"}
                            readOnly={formData.isCerrado}
                        >
                            <Item
                                dataField={"idTipoPresupuesto"}
                                label={labels.idTipoPresupuesto}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idTipoPresupuesto}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"codigo"}
                                label={labels.codigo}
                                colSpan={1 * multiplier}
                                editorOptions={sharedEditorOptions.codigo}
                                isRequired
                            />
                            <Item
                                dataField={"idAdmCliente"}
                                label={labels.idAdmCliente}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idAdmCliente}
                                colSpan={3 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"fechaCreacion"}
                                label={labels.fechaCreacion}
                                editorOptions={sharedEditorOptions.fechaCreacion}
                                editorType={"dxDateBox"}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"idMoneda"}
                                label={labels.idMoneda}
                                editorType={"dxSelectBox"}
                                editorOptions={sharedEditorOptions.idMoneda}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"idAdmFormaPago"}
                                label={labels.idAdmFormaPago}
                                editorType={"dxSelectBox"}
                                editorOptions={sharedEditorOptions.idAdmFormaPago}
                                colSpan={1 * multiplier}
                            />
                            <Item colSpan={2 * multiplier}>
                                <TasaCambioTipoCambioComponent
                                    keyExpr={"idAdmPresupuestoVenta"}
                                    ref={TCTCFormRef}
                                    idMoneda={idMoneda}
                                    formData={formData}
                                />
                            </Item>
                        </DxForm>
                    </div>
                </CardBody>
            </Card>
            <div className={"d-flex mt-4"}>
                <Card
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        borderWidth: 0.1,
                        overflow: "visible",
                    }}
                >
                    <CardBody>
                        <DataGridArticulos
                            idMoneda={idMoneda}
                            dataSource={formData.articulosDraft}
                            validations={validations}
                            showIva={false}
                            updateSummary={summaryFormRef?.current?.updateSummary}
                            tipoArticulos={formData.idTipoPresupuesto}
                            isCompra={false}
                            readOnly={formData.isCerrado}
                        />
                    </CardBody>
                </Card>
            </div>
            <div className={"d-flex mt-4"} style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    style={{
                        alignItems: "stretch",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <Card
                        style={{
                            width: "80%",
                            height: "100%",
                            marginLeft: 0,
                            marginTop: "auto",
                            marginBottom: "auto",
                            // alignItems: "stretch",
                            borderWidth: 0.1,
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <div /* className='container_spanCentrado' */>
                                <Observaciones formData={formData} labels={labels} />
                            </div>
                        </CardBody>
                    </Card>
                    <Card
                        style={{
                            width: "18%",
                            height: "100%",
                            marginLeft: "auto",
                            marginRight: 0,
                            borderWidth: 0.1,
                            borderColor: "lightgray",
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <TablaResumen ref={summaryFormRef} formData={formData} idMoneda={idMoneda} showIva={true} />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
});

export default Form;
