import React, { useState } from "react";
import { connect } from "react-redux";

import Box, { Item } from "devextreme-react/box";

import Calendario from "./components/CalendarioLavanderia";
import PageTitle from "layout/AppMain/PageTitle";
import SelectorLavanderia from "./components/SelectorLavanderia";
import { getNombreFormulario } from "helpers";

const CalendarioLavanderia = ({ user, idioma }) => {
  const [idsLavanderia, setIdsLavanderia] = useState([]);
  const objNombreFormulario = { props: { user, idioma } };

  return (
    <>
      <PageTitle heading={getNombreFormulario(objNombreFormulario)} />
      <div className={"media-body"}>
        <div id={"CalendarioLavanderia"} className={"formContainer"}>
          <Box direction={"row"} height={"100%"} width={"100%"}>
            <Item ratio={1}>
              <SelectorLavanderia setIdsLavanderia={setIdsLavanderia} />
            </Item>
            <Item ratio={4}>
              <Calendario idsLavanderia={idsLavanderia} />
            </Item>
          </Box>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.Authentication.user,
  idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(CalendarioLavanderia);
