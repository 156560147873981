import { Draggable, Tooltip } from "devextreme-react";
import { Box, Item as ItemBox } from "devextreme-react/box";
import { patchRequestHandler } from "helpers";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Posicion from "../components/Posicion";

const { Fragment } = require("react");
const { Component } = require("react");

class Area extends Component {
  constructor(props) {
    super(props);

    this.layout_REF = this.props.layout_REF;
  }

  render() {
    const { area, previsiones, svg } = this.props;

    const color = previsiones.length > 0 ? "info" : "secondary";

    return (
      <Col md={6} xl={4} className="p-2">
        <Card
          className={`card-btm-border he-100 px-1 border-${color}`}
          style={{ borderRadius: "0.5rem" }}
        >
          <CardHeader className="px-3">
            <CardTitle style={{ fontSize: "0.9rem", margin: 0 }}>
              {area.idAreaLavanderiaNavigation.denominacion}
            </CardTitle>
          </CardHeader>
          <CardBody className="px-3 py-4">
            {/* Crear zona dropeable para las areas */}
            <Draggable
              onDrop={(e) => {
                this.onClick_area(e, null, null, e.fromData);
              }}
              group={"draggingGroup"}
              clone={true}
              // Las posiciones no deberian poder moverse por lo tanto
              // cancela la accion según empieza
              onDragStart={(e) => {
                e.cancel();
              }}
              style={{
                borderRadius: 20,
                width: "100%",
                height: "100%",
                minHeight: 240,
              }}
              className=""
            >
              <Row className="justify-content-center">
                {previsiones.length > 0 ? (
                  previsiones.map((x) => {
                    return (
                      <Col
                        xs={"auto"}
                        style={{ padding: 0 }}
                        key={x.idCuadrantePersonal}
                      >
                        <this.PosicionArea
                          svg={svg}
                          prevision={x}
                          style={{ margin: 3 }}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <Col xs={"auto"} style={{ padding: 0 }}>
                    <this.PosicionArea
                      svg={svg}
                      prevision={null}
                      style={{ margin: 3 }}
                    />
                  </Col>
                )}
              </Row>
            </Draggable>
          </CardBody>
        </Card>
      </Col>
    );
  }

  // Aplica efectos de estilo cuando el raton pasa por encima
  onMouseEnter_area = (e, posicion, prevision) => {
    if (this.props.enablePosition) {
      const img = e.currentTarget.firstChild.childNodes[0];
      const remove = e.currentTarget.firstChild.childNodes[1];

      if (!prevision && this.layout_REF.previsionSeleccionada) {
        img.src = this.props.svg.addHover;
      } else if (prevision !== null && !this.layout_REF.previsionSeleccionada) {
        img.style.opacity = 0.5;
        remove.style.opacity = 1;
      }

      this.layout_REF.setTooltipData(posicion, prevision, img.id);
    }
  };

  // Elimina los efectos de estilo cuando el raton pasa por encima
  onMouseLeave_area = (e, prevision) => {
    if (this.props.enablePosition) {
      const img = e.currentTarget.firstChild.childNodes[0];
      const remove = e.currentTarget.firstChild.childNodes[1];

      if (prevision === null) {
        img.src =
          prevision && prevision !== null
            ? prevision.image
            : this.props.svg.add;
      }
      remove.style.opacity = 0;
      img.style.opacity = 1;
      this.layout_REF.resetTooltipData();
    }
  };

  onClick_area = (e, posicion, prevision, data) => {
    if (this.props.enablePosition) {
      // Si "data" contiene información es por que un elemento ha sido
      // arrastrado hasta allí por lo tanto reemplaza la posicion
      // Si "data" no tiene información es por que se ha clickado
      // directamente en la posicion para restablecerla (dejarla vacía)
      if (data) {
        const { layout_REF } = this.props;
        const id = data.idCuadrantePersonal;
        const payload = patchRequestHandler({
          idPosicionNAreaLavanderiaNLavanderia:
            this.props.posicion.idPosicionNAreaLavanderiaNLavanderia,
        });
        layout_REF.datasource_tblCuadrantePersonal
          .store()
          .update(id, payload)
          .then((data) => {
            layout_REF.notificarCambio([data]);
          });
      } else if (prevision) {
        const id = prevision.idCuadrantePersonal;
        const payload = patchRequestHandler({
          idPosicionNAreaLavanderiaNLavanderia: null,
        });

        this.layout_REF.datasource_tblCuadrantePersonal
          .store()
          .update(id, payload)
          .then((data) => {
            this.layout_REF.notificarCambio([data]);
          });
      }
    }
  };

  PosicionArea = (props) => {
    const { svg, prevision, style } = props;
    const { enablePosition } = this.props;
    return (
      <Posicion
        svg={svg}
        style={style}
        prevision={prevision}
        layout={this.layout_REF}
        enablePosition={enablePosition}
        onClick={this.onClick_area}
        onMouseEnter={this.onMouseEnter_area}
        onMouseLeave={this.onMouseLeave_area}
      />
    );
  };
}

export default Area;
