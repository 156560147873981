import { Box, Button, TextBox } from "devextreme-react";
import { Popup } from "devextreme-react/popup";

import { authHeader, getTrad, patchRequestHandler } from "helpers";
import React, { createRef } from "react";
import { dataSource_tblSolicitudGestoria, datasource_tblPersona } from "../datasources";
import { Validator, AsyncRule, NumericRule, PatternRule } from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import { Item } from "devextreme-react/box";
import ODataContext from "devextreme/data/odata/context";
import { connectionConstants } from "constants";
import LottieIcon from "components/LottieIcon";

class PopUpCodigoGestoria extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datosPersonaSel: null,
      codigoGestoriaSet: null,
      popUpUnMount: false,
    };
    this.textBoxCodigoGestoria = createRef();
  }

  componentDidUpdate(prevProps) {
    const { idPersonaSel, visible } = this.props;
    const { popUpUnMount } = this.state;

    if (visible && idPersonaSel && idPersonaSel !== prevProps.idPersonaSel) {
      datasource_tblPersona(idPersonaSel, ["codigoGestoria, nombre, apellidos, idEmpresaPolarier"])
        .load()
        .done((data) => {
          setTimeout(() => {
            this.setState({ datosPersonaSel: data[0] });
          }, 500);
        });
    }

    if (popUpUnMount === true) {
      setTimeout(() => {
        this.setState({ popUpUnMount: false });
      }, 200);
    }
  }

  hidePopup = () => {
    const { onValueChange } = this.props;
    onValueChange({ showPopupCodigoGestoria: false, solicitudGestoriaSel: null }, () => {
      onValueChange({ idPersonaSel: null });
    });
    this.setState({
      datosPersonaSel: null,
      codigoGestoriaSet: null,
    });
  };

  setCodigoGestoria = (e) => {
    let codigo = e.value.length > 0 ? e.value.padStart(6, "0") : null;
    this.setState({ codigoGestoriaSet: codigo });
  };

  confirmar = async () => {
    const { codigoGestoriaSet, datosPersonaSel } = this.state;
    const { estadoSel_aceptado, onValueChange, solicitudGestoriaSel, idPersonaSel } = this.props;
    const { idSolicitudGestoria } = solicitudGestoriaSel;

    if (codigoGestoriaSet != null) {
      const isValid = await this.validateAsync_codigoGestoria({ value: codigoGestoriaSet });
      if (!isValid) {
        notify({
          message: "Código de gestoria inválido.",
          type: "error",
          displayTime: "1500",
          closeOnClick: true,
        });
        return;
      }
    } else if (codigoGestoriaSet == null && datosPersonaSel?.codigoGestoria == null) {
      notify({
        message: "Por favor, inserta un código de gestoría.",
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
      return;
    }

    // Función para actualizar el estado de la solicitud gestoría
    const updateSolicitudGestoria = () => {
      const jsonData = patchRequestHandler({ idEstadoSolicitudGestoria: 2 });
      dataSource_tblSolicitudGestoria()
        .store()
        .update(idSolicitudGestoria, jsonData)
        .done(() => {
          dataSource_tblSolicitudGestoria(estadoSel_aceptado)
            .load()
            .done((data) => {
              onValueChange({ dataSource_tblSolicitudGestoria: data });
            });
          notify({
            message: "Solicitud de gestoría actualizada correctamente.",
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
          this.hidePopup();
        });
    };

    if (codigoGestoriaSet != null) {
      const jsonDataPersona = patchRequestHandler({ codigoGestoria: codigoGestoriaSet });
      datasource_tblPersona().store().update(idPersonaSel, jsonDataPersona).done(updateSolicitudGestoria);
    } else if (codigoGestoriaSet == null) {
      updateSolicitudGestoria();
    }
  };

  render() {
    const { visible } = this.props;
    const { datosPersonaSel, codigoGestoriaSet, popUpUnMount } = this.state;
    const codigoGestoria = datosPersonaSel?.codigoGestoria;
    const nombreCompleto = datosPersonaSel?.nombre + " " + datosPersonaSel?.apellidos;

    if (popUpUnMount) return null;

    return (
      <Popup
        showTitle={true}
        title={getTrad("codigoGestoria")}
        visible={visible}
        showCloseButton={true}
        width={"25%"}
        height={"25%"}
        onHiding={this.hidePopup}
        onHidden={() => this.setState({ popUpUnMount: true })}
        className="px-1 d-flex flex-column "
      >
        <div
          style={datosPersonaSel != null ? { height: "0px" } : {}}
          className="he-100 d-flex flex-column justify-content-center align-items-center "
        >
          <LottieIcon icon="loading_circular" height={"50%"} width={"50%"} />
          <h4 style={datosPersonaSel != null ? { display: "none" } : {}}>Cargando...</h4>
        </div>
        <Box direction="row" height={"100%"} width={"100%"} visible={datosPersonaSel != null}>
          <Item ratio={1}>
            <div className="d-flex flex-column flex-1 align-items-end ">
              <div className="d-flex mb-2">
                <div className="d-flex mx-1 font-weight-bold ">{getTrad("nombre")}:</div>
                <div className="d-flex mx-1 "> {datosPersonaSel?.nombre}</div>
                <div className="d-flex mx-1 font-weight-bold">{getTrad("apellidos")}:</div>
                <div className="d-flex  ">{datosPersonaSel?.apellidos}</div>
              </div>
              {codigoGestoria != null ? (
                <div className="d-flex justify-content-end">
                  Revisa que el <strong className="mx-1"> código de gestoría </strong> sea el correcto.
                </div>
              ) : (
                <div className="d-flex justify-content-end">
                  Introduce un <strong className="mx-1"> código de gestoría </strong>.
                </div>
              )}
              <Box width={"100%"} className="d-flex flex-column justify-content-end align-items-end py-3">
                <Item ratio={1}>
                  <TextBox
                    ref={(ref) => (this.textBoxCodigoGestoria = ref)}
                    value={codigoGestoriaSet}
                    maxLength={6}
                    onValueChanged={this.setCodigoGestoria}
                    className="p-1 m-1"
                    style={{ borderRadius: 10 }}
                    placeholder={codigoGestoria ?? "Código de gestoría"}
                  >
                    <Validator>
                      <AsyncRule
                        ignoreEmptyValue={true}
                        reevaluate={false}
                        message="El código gestoría introducido ya está asignado a otra persona"
                        validationCallback={this.validateAsync_codigoGestoria}
                      />
                      <NumericRule />
                      <PatternRule pattern={/^.{6,}$/} message={getTrad("validation_Formato")} />
                    </Validator>
                  </TextBox>
                </Item>
              </Box>
            </div>
            <div className="d-flex justify-content-end">
              <div className="btn btn-success mx-3 p-2 pointer" style={{ width: 100 }} onClick={this.confirmar}>
                {getTrad("confirmar")}
              </div>
              <div className="btn btn-danger p-2 pointer" style={{ width: 100 }} onClick={this.hidePopup}>
                {getTrad("cancelar")}
              </div>
            </div>
          </Item>
        </Box>
      </Popup>
    );
  }

  objCheckCodigoGestoria = {};
  context_CodigoGestoria = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
    entities: {
      fn_CheckCodigoGestoria: {},
    },
    beforeSend: (request) => {
      this.context_CodigoGestoria_beforeSend(request);
    },
  });
  context_CodigoGestoria_beforeSend(request) {
    let { datosPersonaSel } = this.state;

    request.headers = { ...authHeader() };
    request.params.idPersona = datosPersonaSel ? datosPersonaSel.idPersona : null;
    request.params.codigoGestoria = this.objCheckCodigoGestoria.codigo;
    request.params.idEmpresaPolarier = datosPersonaSel ? datosPersonaSel.idEmpresaPolarier : null;
  }

  validateAsync_codigoGestoria = (params) => {
    let value = params.value.replace(/\s+/g, " ").trim();
    return new Promise((resolve) => {
      this.objCheckCodigoGestoria = { codigo: value };
      this.context_CodigoGestoria.invoke("fn_CheckCodigoGestoria", {}, "POST").done(function (result) {
        const isValid = result === -1;
        return resolve(isValid);
      });
    });
  };
}

export default PopUpCodigoGestoria;
