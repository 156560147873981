const idsMoneda = {
    Euro: 1,
    Dolar: 2,
    PesoMexicano: 3,
    PesoDominicano: 4,
    EscudoCaboverdiano: 5,
    LibraEsterlina: 6,
};

export const codigosMoneda = {
    1: "EUR",
    2: "USD",
    3: "MXN",
    4: "DOP",
    5: "CVE",
    6: "GBP",
};

export default idsMoneda;
