import React, { Fragment } from "react";
import { connect } from "react-redux";
import { history } from "../../../helpers";
import {
  DropdownToggle,
  DropdownMenu,
  Col,
  Row,
  UncontrolledButtonDropdown,
  DropdownItem,
} from "reactstrap";

import { userActions, loadPanelActions } from "../../../actions";
import { logUsoService } from "../../../services";

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };

    this.logout = this.logout.bind(this);
    this.logout_changeUser = this.logout_changeUser.bind(this);
    this.loadPanel_show = this.loadPanel_show.bind(this);
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  logout_changeUser(e) {
    localStorage.setItem("idUsuarioOriginal", null);
    userActions.logout();
    window.location.reload();
  }

  logout(e) {
    // LogAcciones
    logUsoService.setLog(2);
    userActions.logout();
    window.location.reload();
  }

  goto_miCuenta(e) {
    history.push("/GestionInterna/MiCuenta");
  }

  render() {
    let { user, idUsuarioOriginal } = this.props;
    return (
      <Fragment>
        <div className="header-btn-lg pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="none" className="p-0">
                    <div className="widget-content-left container_spanCentrado">
                      <div className="icon_Persona p-2 font-size-xxl"></div>
                      <div>
                        <div
                          className="widget-heading header-user-info"
                          style={{ marginTop: 2 }}
                        >
                          {user.nombre}
                        </div>
                      </div>
                    </div>
                  </DropdownToggle>

                  <DropdownMenu right className="rm-pointers dropdown-menu-xs ">
                    <div className="dropdown-menu-header mb-2">
                      <div className="dropdown-menu-header-inner">
                        <div className="menu-header-image img-CabeceraPolarier" />
                        <div className="menu-header-content">
                          <h5 className="menu-header-title">{user.nombre}</h5>
                        </div>
                      </div>
                    </div>

                    <div className="grid-menu grid-menu-2col">
                      <Row className="no-gutters">
                        <Col sm="6">
                          <div key="item_miCuenta">
                            <DropdownItem id="item_miCuenta" className="p-0">
                              <div
                                className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 w-100"
                                onClick={this.goto_miCuenta}
                              >
                                <i className="menu_icons_Account icon-gradient bg-night-sky btn-icon-wrapper mb-2">
                                  {" "}
                                </i>
                                <span>Mi cuenta</span>
                              </div>
                            </DropdownItem>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div key="item_cerrarSesion">
                            <DropdownItem
                              id="item_cerrarSesion"
                              className="p-0"
                            >
                              <div
                                className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 w-100"
                                onClick={
                                  idUsuarioOriginal != null
                                    ? this.logout_changeUser
                                    : this.logout
                                }
                              >
                                <i className="menu_icons_Logout icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                                  {" "}
                                </i>
                                <span>Cerrar sesión</span>
                              </div>
                            </DropdownItem>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.Authentication.user,
  idUsuarioOriginal: state.Global.idUsuarioOriginal,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loginChangeUser: (idUsuarioSel, idUsuarioOriginal, isLogout) =>
    dispatch(
      userActions.loginChangeUser(idUsuarioSel, idUsuarioOriginal, isLogout),
    ),
});

const connectedUserBox = connect(mapStateToProps, mapDispatchToProps)(UserBox);
export default connectedUserBox;
