import React, { useEffect, useState } from "react";

import List from "devextreme-react/list";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import {
  errorHandler,
  authHeader,
  startOfMonth,
  formatDate_parameter,
} from "helpers";
import { connectionConstants } from "constants";

const ListAlmacenesPadre = ({
  visible,
  parteTrabajoSel,
  almacenes_agrupados,
  setAlmacenSel,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [almacenesHabilitados, setAlmacenesHabilitados] = useState([]);
  const [isVisibleListAlmacenesPadre, setIsVisibleListAlmacenesPadre] =
    useState(false);

  useEffect(() => {
    if (visible) {
      datasource_tblCierreRecambioNAlmacen.reload().done((data) => {
        setAlmacenesHabilitados(data.map((x) => x.idAlmacen));
        setIsVisibleListAlmacenesPadre(true);
      });
    } else setIsVisibleListAlmacenesPadre(false);
  }, [visible]);

  useEffect(() => {
    setDataSource(
      almacenes_agrupados.map((almP) => ({
        ...almP,
        items: almP.items.map((almH) => ({
          ...almH,
          disabled: (almH.disabled = !almacenesHabilitados.includes(
            almH.idAlmacenPadre,
          )),
        })),
      })),
    );
  }, [almacenesHabilitados]);

  const datasource_tblCierreRecambioNAlmacen = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCierreRecambioNAlmacen/GetFechasNAlmacen",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) =>
        datasource_tblCierreRecambioNAlmacen_beforeSend(request),
      onLoading: (loadOptions) =>
        datasource_tblCierreRecambioNAlmacen_onLoading(loadOptions),
      version: 4,
    }),
  });

  const datasource_tblCierreRecambioNAlmacen_beforeSend = (request) =>
    (request.headers = { ...authHeader() });

  const datasource_tblCierreRecambioNAlmacen_onLoading = (loadOptions) =>
    (loadOptions.filter =
      "date(fecha) lt " +
      formatDate_parameter(startOfMonth(parteTrabajoSel.fecha)).split("T")[0]);

  const groupRender_almacenes = ({ key, denominacion }) =>
    denominacion +
    (almacenesHabilitados && !almacenesHabilitados.includes(key)
      ? " (CERRADO)"
      : "");

  const onSelectionChanged_listAlmacen = (e) => {
    let data = e.addedItems;
    setAlmacenSel(data.length > 0 ? data[0] : null);
  };

  return isVisibleListAlmacenesPadre ? (
    <List
      dataSource={dataSource}
      keyExpr={"idAlmacen"}
      displayExpr={"denominacion"}
      selectionMode={"single"}
      height={"525px"}
      width={"100%"}
      grouped
      collapsibleGroups
      focusStateEnabled={false}
      activeStateEnabled={false}
      groupRender={groupRender_almacenes}
      onSelectionChanged={onSelectionChanged_listAlmacen}
    />
  ) : null;
};

export default ListAlmacenesPadre;
