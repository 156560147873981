import React, { Fragment } from "react";
import { connectionConstants } from "../../../../../constants";

import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  formatTime_parameter,
  dxMensajePregunta,
  formatDate_noTime_parameter,
  formatDate,
  convertClienteUtcToLavanderiaUtc,
  patchRequestHandler,
  getTrad,
  formatDate_parameter,
  durationToDatetime,
  leadingZero,
  subtractHours,
  timeDiff,
  addHours,
} from "../../../../../helpers";

import notify from "devextreme/ui/notify";
import $ from "jquery";
import query from "devextreme/data/query";

//Datasource
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import List from "devextreme-react/list";
import { Button } from "devextreme-react";
import { SelectBox } from "devextreme-react/select-box";
import Box, { Item as ItemBox } from "devextreme-react/box";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Form, { Item } from "devextreme-react/form";

//Css
import "./Css.scss";

export default class FiltrosPersona extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tblTurnos_items_grouped: [],
      datasource_lavanderias: [],
    };

    //DATASOURCE
    this.datasource_tblCategoriaInterna_onLoading =
      this.datasource_tblCategoriaInterna_onLoading.bind(this);
    this.datasource_tblTurno_onLoading =
      this.datasource_tblTurno_onLoading.bind(this);
    this.datasource_tblTurno_beforeSend =
      this.datasource_tblTurno_beforeSend.bind(this);
    this.datasource_tblTurno_postProcess =
      this.datasource_tblTurno_postProcess.bind(this);
    this.datasource_tblLavanderia_beforeSend =
      this.datasource_tblLavanderia_beforeSend.bind(this);

    this.dxSelectBox_lavanderiaFilter_onValueChanged =
      this.dxSelectBox_lavanderiaFilter_onValueChanged.bind(this);
    this.dxSelectBox_categoriaConvenioFilter_onValueChanged =
      this.dxSelectBox_categoriaConvenioFilter_onValueChanged.bind(this);
    this.dxSelectBox_turnoFilter_onValueChanged =
      this.dxSelectBox_turnoFilter_onValueChanged.bind(this);

    this.reloadTurnos = this.reloadTurnos.bind(this);
    this.set_time_fechaSel = this.set_time_fechaSel.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  get dxDataGrid_tblCategoriaInterna() {
    return this.dxDataGrid_tblCategoriaInterna_REF.current.instance;
  }

  //#region DATASOURCES
  datasource_tblCategoriaInterna = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaInterna_beforeSend(request);
      },
      onLoading: (request) => {
        this.datasource_tblCategoriaInterna_onLoading(request);
      },
      version: 4,
    }),
    select: ["idCategoriaInterna,denominacion"],
    sort: ["denominacion"],
    postProcess: (data) => {
      return this.atasource_tblCategoriaInterna_postProcess(data);
    },
  });

  datasource_tblCategoriaInterna_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblCategoriaInterna_onLoading(loadOptions) {
    let idPaisSel = 1;
    let isOficina = false;

    if (loadOptions.filter)
      loadOptions.expand = [
        loadOptions.filter,
        "and",
        "idCategoriaConvenioNavigation($filter=idPais eq " +
          idPaisSel +
          " and isOficina eq " +
          isOficina +
          ")",
      ];
    else
      loadOptions.expand = [
        "idCategoriaConvenioNavigation($filter=idPais eq " +
          idPaisSel +
          " and isOficina eq " +
          isOficina +
          ")",
      ];
  }

  atasource_tblCategoriaInterna_postProcess(data) {
    let groupedData = query(data)
      .groupBy(function (dataItem) {
        dataItem.idCategoriaConvenioNavigation.denominacion =
          dataItem.idCategoriaConvenioNavigation.denominacion.toUpperCase();
        dataItem.denominacion = dataItem.denominacion.toUpperCase();
        return dataItem.idCategoriaConvenioNavigation.denominacion;
      })
      .sortBy("key")
      .toArray();

    return groupedData;
  }

  datasource_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblLavanderia_beforeSend(request);
      },
      version: 4,
    }),
    sort: ["denominacion"],
    filter: ["idPais eq 1 and enableControlHorario eq true"],
  });

  datasource_tblLavanderia_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = false;
  }
  datasource_tblTurno = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTurno",
      key: "idTurno",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblTurno_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblTurno_onLoading(loadOptions);
      },
      version: 4,
    }),
    sort: "horaEntrada",
    expand: ["idTurnoPadreNavigation($select=denominacion)"],
    postProcess: (data) => {
      return this.datasource_tblTurno_postProcess(data);
    },
  });

  datasource_tblTurno_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblTurno_onLoading(loadOptions) {
    let { idLavanderiaSel, idCategoriaInternaSel } = this.state;

    let lavanderiaFilter =
      idLavanderiaSel != null
        ? ["idLavanderia eq " + idLavanderiaSel]
        : ["1 eq 1"];
    let categoriaInternaFilter =
      idCategoriaInternaSel != null
        ? [
            "tblCategoriaInternaNTurno/any(x:x/idCategoriaInterna eq " +
              idCategoriaInternaSel +
              ")",
          ]
        : ["1 eq 1"];

    //tblCategoriaInternaNTurno
    let { fechaSel } = this.props;
    loadOptions.filter = [
      [
        "idLavanderiaNavigation/idPais eq 1 and idLavanderiaNavigation/enableControlHorario eq true",
      ],
      "and",
      lavanderiaFilter,
      "and",
      categoriaInternaFilter,
      "and",
      ["idTurnoPadre eq null and activo eq true and eliminado eq false"],
    ];
    loadOptions.expand = [
      "idLavanderiaNavigation($select=denominacion,idPais),tblJornadaPersona($filter=fecha eq " +
        formatDate_noTime_parameter(fechaSel) +
        ";$top=1)",
    ];
  }

  datasource_tblTurno_postProcess(data) {
    let _this = this;
    $.each(data, function (index, item) {
      if (item.idTurnoPadreNavigation != null) {
        item.denominacion =
          item.idTurnoPadreNavigation.denominacion + " - " + item.denominacion;
      }
      item.horaEntrada = _this.set_time_fechaSel(
        durationToDatetime(item.horaEntrada),
      );
      item.horaSalida = _this.set_time_fechaSel(
        durationToDatetime(item.horaSalida),
      );
      item.tiempoDescanso = _this.set_time_fechaSel(
        durationToDatetime(item.descanso),
      );
      item.text = item.denominacion;
    });
    data.unshift({
      idTurno: -1,
      idLavanderia: null,
      idLavanderiaNavigation: { denominacion: "PERSONALIZADO", idPais: 1 },
      denominacion: "Personalizado",
      idTurnoPadreNavigation: null,
    });
    return data;
  }
  //#endregion

  render() {
    let {
      datasource_lavanderias,
      idCategoriaConvenioSel,
      idLavanderiaSel,
      tblTurnos_items_grouped,
      idTurnoSel,
    } = this.state;

    return (
      <Box direction="row" width="80%" height="100%">
        <ItemBox baseSize={110}>
          <h5 className="d-flex align-items-center mb-0 he-100 pt-4">
            {this.getTrad("filtros").toUpperCase()}
          </h5>
        </ItemBox>
        <ItemBox ratio={1}>
          <div className="pl-1 filterTitle">
            {this.getTrad("lavanderia").toUpperCase()}
          </div>
          <SelectBox
            height="100%"
            width="100%"
            elementAttr={this.dxSelectBox_filtrosPrincipales_elementAttr}
            dataSource={datasource_lavanderias}
            displayExpr="denominacion"
            valueExpr="idLavanderia"
            value={idLavanderiaSel}
            placeholder={this.getTrad("todos").toUpperCase()}
            showClearButton={true}
            onContentReady={this.dxSelectBox_filtrosPrincipales_onContentReady}
            onValueChanged={this.dxSelectBox_lavanderiaFilter_onValueChanged}
          />
        </ItemBox>
        <ItemBox baseSize={35} />
        <ItemBox ratio={1}>
          <div className="pl-1 filterTitle">
            {this.getTrad("categoria").toUpperCase()}
          </div>
          <SelectBox
            height="100%"
            width="100%"
            elementAttr={this.dxSelectBox_filtrosPrincipales_elementAttr}
            dataSource={this.datasource_tblCategoriaInterna}
            displayExpr="denominacion"
            valueExpr="idCategoriaInterna"
            value={idCategoriaConvenioSel}
            grouped={true}
            placeholder={this.getTrad("todos").toUpperCase()}
            showClearButton={true}
            onContentReady={this.dxSelectBox_filtrosPrincipales_onContentReady}
            onValueChanged={
              this.dxSelectBox_categoriaConvenioFilter_onValueChanged
            }
          />
        </ItemBox>
        <ItemBox baseSize={35} />
        <ItemBox ratio={1}>
          <div className="pl-1 filterTitle">
            {this.getTrad("turno").toUpperCase()}
          </div>
          <SelectBox
            height="100%"
            width="100%"
            elementAttr={this.dxSelectBox_filtrosPrincipales_elementAttr}
            dataSource={
              tblTurnos_items_grouped.length > 0
                ? tblTurnos_items_grouped.length > 1
                  ? tblTurnos_items_grouped
                  : tblTurnos_items_grouped[0].items
                : []
            }
            displayExpr="denominacion"
            valueExpr="idTurno"
            value={idTurnoSel}
            grouped={tblTurnos_items_grouped.length > 1}
            placeholder={this.getTrad("todos").toUpperCase()}
            showClearButton={true}
            onContentReady={this.dxSelectBox_filtrosPrincipales_onContentReady}
            onValueChanged={this.dxSelectBox_turnoFilter_onValueChanged}
          />
        </ItemBox>
      </Box>
    );
  }

  itemRender(item) {
    let has_categoriaInterna = item.tblCategoriaInterna.length > 0;
    return (
      <Box
        direction="row"
        align="space-around"
        crossAlign="stretch"
        height="100%"
        width="100%"
      >
        <Item ratio={1}>
          <div
            style={{ minHeight: 36 }}
            className={"font-size-md p-1 d-flex align-items-center"}
          >
            {item.denominacion}
          </div>
        </Item>
        <Item baseSize={70}>
          <Toolbar elementAttr={this.elementAttr_toolbarClass}>
            <ToolbarItem location="after">
              <div className="container_spanCentrado dxToolbar_opcion p-1">
                <div
                  className="font-size-lg icon_Edit center-svg"
                  onClick={this.onClick_editItem}
                />
              </div>
            </ToolbarItem>
            {!has_categoriaInterna && (
              <ToolbarItem location="after">
                <div className="container_spanCentrado dxToolbar_opcion p-1">
                  <div
                    className="font-size-lg icon_Cerrar center-svg center-svg"
                    onClick={this.onClick_removeItem}
                  />
                </div>
              </ToolbarItem>
            )}
          </Toolbar>
        </Item>
      </Box>
    );
  }

  dxSelectBox_filtrosPrincipales_elementAttr = {
    class: "dxSelectBox_filtrosPrincipales text-uppercase ",
  };

  elementAttr_toolbarClass = { class: "dxToolbar_opciones" };

  set_time_fechaSel(time) {
    let { fechaSel } = this.state;
    let newValue = new Date(fechaSel);
    newValue.setHours(time.getHours());
    newValue.setMinutes(time.getMinutes());
    newValue.setSeconds(0);

    return newValue;
  }

  dxSelectBox_filtrosPrincipales_onContentReady(e) {
    e.component
      .content()
      .attr("id", "dxPopup_jornadaPersona_dxSelectBox_filtrosPrincipales");
  }

  dxSelectBox_lavanderiaFilter_onValueChanged(e) {
    let { idCategoriaInternaSel, idTurnoSel } = this.state;
    this.setState({ idLavanderiaSel: e.value }, () => {
      this.reloadTurnos();
      this.props.dxDataGrids_reload(null, {
        idLavanderia: e.value,
        idCategoriaInterna: idCategoriaInternaSel,
        idTurno: idTurnoSel,
      });
    });
  }

  dxSelectBox_categoriaConvenioFilter_onValueChanged(e) {
    let { idLavanderiaSel, idTurnoSel } = this.state;
    this.setState({ idCategoriaInternaSel: e.value }, () => {
      this.reloadTurnos();
      this.props.dxDataGrids_reload(null, {
        idLavanderia: idLavanderiaSel,
        idCategoriaInterna: e.value,
        idTurno: idTurnoSel,
      });
    });
  }

  dxSelectBox_turnoFilter_onValueChanged(e) {
    let { idCategoriaInternaSel, idLavanderiaSel } = this.state;
    this.setState({ idTurnoSel: e.value }, () => {
      this.props.dxDataGrids_reload(null, {
        idLavanderia: idLavanderiaSel,
        idCategoriaInterna: idCategoriaInternaSel,
        idTurno: e.value,
      });
    });
  }

  reloadTurnos() {
    let _this = this;
    this.datasource_tblTurno.reload().done(function (tblTurno_items) {
      let groupedTurnos = query(tblTurno_items)
        .groupBy(function (dataItem) {
          return dataItem.idLavanderiaNavigation.denominacion;
        })
        .sortBy("key")
        .toArray();
      groupedTurnos = [
        ...groupedTurnos.filter((x) => x.key != "PERSONALIZADO"),
        ...groupedTurnos.filter((x) => x.key == "PERSONALIZADO"),
      ];

      _this.setState({
        tblTurnos_items_grouped: groupedTurnos,
      });
    });
  }

  componentDidMount() {
    let _this = this;
    $.when(
      this.datasource_tblCategoriaInterna.reload(),
      this.datasource_tblLavanderia.reload(),
      this.datasource_tblTurno.reload(),
    ).then(function (categorias, lavanderias, turnos) {
      let groupedTurnos = query(turnos[0])
        .groupBy(function (dataItem) {
          return dataItem.idLavanderiaNavigation.denominacion;
        })
        .sortBy("key")
        .toArray();

      groupedTurnos = [
        ...groupedTurnos.filter((x) => x.key != "PERSONALIZADO"),
        ...groupedTurnos.filter((x) => x.key == "PERSONALIZADO"),
      ];

      _this.setState({
        datasource_lavanderias: lavanderias[0],
        tblTurnos_items_grouped: groupedTurnos,
      });
    });
  }
}
