import { Component } from "react";
import React from "react";
import $ from "jquery";

import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

import PivotGrid, { FieldChooser, LoadPanel, HeaderFilter } from "devextreme-react/pivot-grid";

import "./PrevGrid.scss";
import {
    getPostCuadrantePromise,
    patchCuadrantePersonalRecalcular,
    postChangesIntoCuadrantePersonal,
} from "../../services/apiCalls";
import CustomStore from "devextreme/data/custom_store";
import { Button, Tooltip } from "devextreme-react";
import { connect } from "react-redux";
import { loadPanelActions } from "actions";
import { capitalize, dateTime_hourMinute, durationToDatetime, formatDate_noTime_parameter, getTrad } from "helpers";
import query from "devextreme/data/query";
import { jornadaEstado } from "../Jornada/JornadaPopup";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { svg_ask_circle, svg_user } from "styles/svg_iconos";

let isEditing;

function svg_color(svg, color) {
    $.each(svg[0].childNodes, function (i, node) {
        node.style["stroke"] = color;
        node.style.fill = color;
    });

    return svg;
}

class PrevGrid extends Component {
    pivotGrid;
    estadoSemanasValidadas = [true, true, true, true];
    estadoEstructuraOperativaEnFecha = [];
    estadoJornadasEnFecha = [];
    constructor(props) {
        super(props);
        this.refTooltip = React.createRef();
        this.pivotGrid = React.createRef();
        this.state = {
            datosTurno: [],
            calendarioPersonalEstado: {},
            headerCollapseFunction_reverseCondition: true, //permite invertir la condición para colapsar categorias
            footerVisible: true,
            tooltipData: {},
        };
        this.date = new Date();
        this.date.setHours(0, 0, 0, 0);
        this.popup_calendario = props.refCalendarioCuadrante.current;
        this.refPersonalGeneralPopup = props.refPersonalGeneralPopup;
        this.refEstructuraOperativa = props.refEstructuraOperativa.current;
    }

    get popup_edit() {
        return this.props.refSettingsPopup.current;
    }

    get tooltip() {
        return this.refTooltip.current.instance;
    }

    get jornadaPopup() {
        return this.props.CuadranteContext.refJornadaPopup.current;
    }

    get llamamientoPopup() {
        return this.props.CuadranteContext.refLlamamientoPopup.current;
    }

    shortColumnDateFormat = {
        weekday: "short",
        day: "numeric",
    };
    longColumnDateFormat = {
        weekday: "long",
        day: "numeric",
    };

    gap = { gap: 10 };

    svg_user = encodeURI("data:image/svg+xml;utf-8," + svg_color(svg_user.clone(), "rgb(150, 150, 150)")[0].outerHTML);
    svg_missing = encodeURI("data:image/svg+xml;utf-8," + svg_ask_circle.clone()[0].outerHTML);

    personalGeneral_onContentReady = (e) => {
        //este método de aqui tiene su propio this por algun motivo que solo el dios de javascript lo sabe. Es una copia del this que queda una vez ejecutado el render
        if (e.component.pageSize() > 1) {
            e.component.pageSize(1);
        }
        if (isEditing) {
            isEditing = false;
            // this.DataGrid_REF.current.endCustomLoading();
            e.component.editRow(0);
        }
    };

    componentDidMount = () => {
        const { enableMigracion } = this.props;
        this.setupPivotGrid();

        this.refPersonalGeneralPopup.current.dxDataGrid_personas.option(
            "onContentReady",
            this.personalGeneral_onContentReady
        );

        // #region botton importar

        const container = $(`#PivotCuadrante-${this.props.turno.idTurno} .dx-area-description-cell`);
        container.css("vertical-align", "bottom");

        let div = $(`<div class="p-2 d-flex flex-wrap" style="max-width: 260px; gap: .5rem">`);
        div.appendTo(container);

        let botonCrearLlamamiento = $(htmlButton(getTrad("crearIncorporacion"), "dx-icon dx-icon-add", `p-1`));
        botonCrearLlamamiento.appendTo(div);
        botonCrearLlamamiento.on("click", () => this.llamamientoPopup.open());

        let botonImportarPersonal = $(
            htmlButton("Importar personal", "dx-icon dx-icon-add", `p-1 ${enableMigracion ? "" : "dx-state-disabled"}`)
        );
        botonImportarPersonal.appendTo(div);
        if (enableMigracion) {
            botonImportarPersonal.on("click", () => {
                this.popup_calendario.abrirImportar();
            });
        } else {
            $("<div>")
                .dxTooltip({
                    target: botonImportarPersonal,
                    height: "42px",
                    showEvent: "dxhoverstart",
                    hideEvent: "dxhoverend",
                    position: "top",
                    contentTemplate: function (contentElement) {
                        contentElement.html("No tienes acceso a otras lavanderías");
                    },
                })
                .appendTo(container);
        }

        let botonRefrescarDatos = $(htmlButton("", "dx-icon dx-icon-refresh m-0", `p-1`));
        botonRefrescarDatos.appendTo(div);

        botonRefrescarDatos.on("click", () => {
            botonRefrescarDatos.addClass("dx-state-disabled");
            botonRefrescarDatos.children().addClass("rotate");
            this.props.CuadranteContext.reload().then(() => {
                botonRefrescarDatos.removeClass("dx-state-disabled");
                botonRefrescarDatos.children().removeClass("rotate");
            });
        });

        // #endregion
    };

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.CuadranteContext.data) !== JSON.stringify(this.props.CuadranteContext.data)) {
            this.pivotGridDataSource.reload();
        }
        if (prevProps.fotosPerfil !== this.props.fotosPerfil) {
            $(`.fotoPersona`).each((i, element) => {
                if (element.id.includes("fotoPersona")) {
                    const id = element.id.split("-")[1];
                    const src = this.props.fotosPerfil[id];
                    $(element).css("background", `url(${src ? "data:image/png;base64," + src : `'${this.svg_user}'`}`);
                }
            });
        }
        if (prevProps.viewType !== this.props.viewType) {
            this.pivotGridDataSource.reload();
        }
    }

    componentWillUnmount = () => {
        this.refPersonalGeneralPopup.current.dxDataGrid_personas.option("onContentReady", undefined);

        this.refPersonalGeneralPopup.current.datasource_persona
            .store()
            .off("modified", this.props.CuadranteContext.loadAll);
    };

    // #region onCellPrepared

    onCellPrepared = ({ cell, area, cellElement }) => {
        const { viewType, turno, CuadranteContext } = this.props;
        cell.area = area; //añadimos el campo area a la celda para futuras diferenciaciones.
        const value = cell.value;

        if (value && (cell.rowType !== "D" || cell.rowPath.length <= 1)) {
            value.texto = value.totalTrabajando;
        }

        const isDato = area === "data" && value && typeof value.texto === "string";

        /* CELDA DE DATOS VACIA */
        if (!value && area === "data") {
            //No hay prevision en este dia, para esta persona en este turno en concreto.
            cellElement[0].innerHTML = ``;
        }

        if (isDato) {
            cellElement[0].innerHTML = ``;

            const estado = this.getEstado(value.idEstado);

            const id = `dataCellPrevision_${
                value.idCuadrantePersonal
                    ? `${value.idCuadrantePersonal}_T${turno.idTurno}`
                    : `${value.idPersona ? value.idPersona : "llam" + value.idLlamamiento}_${value.fecha.getTime()}_T${
                          turno.idTurno
                      }`
            }`;

            const background = $("<div class='w-100 he-100 bg-white' style='border-radius: 8px'/>");
            const container = $("<div/>");
            container.attr("id", id);
            container.addClass("cellData px-3");
            container.css({
                display: "flex",
                "flex-wrap": "wrap",
                "word-wrap": "break-word",
                overflow: "hidden",
            });

            if (value.bloqueado && !value.isHidden) container.addClass("bloqueado");
            if (value.idEstado === 10) container.addClass("diaCierre");
            else {
                if (!value.isFijado && value.idEstado !== 8 && value.fecha > this.date) container.addClass("stripe");
                if (viewType === "detallado") container.addClass("cellDataDetailed py-2");
            }

            let color = value.fecha.getTime() < this.date.getTime() ? estado?.colorHexa + "80" : estado?.colorHexa;
            container.css("background-color", color);

            if (!value.isHidden) {
                container.appendTo(background);
                background.appendTo(cellElement[0]);
            }

            if (viewType == "detallado") {
                let elementEstado = $("<span/>");
                elementEstado.html(estado?.denominacion);
                elementEstado.appendTo(container);

                if (value.texto !== "") {
                    let elementHorarioCuadrante = $("<span/>");
                    elementHorarioCuadrante.html(value.texto);
                    elementHorarioCuadrante.appendTo(container);
                }
            }

            const { jornadas, cuadrante, calendarioPersonal, estimacion } = value;
            let isRevisado = value.idPersona ? this.isDiaRevisado(jornadas) : true;
            let estadoJornada = this.jornadaPopup.getEstadoJornadasDia(
                jornadas,
                cuadrante,
                calendarioPersonal,
                estimacion
            );
            let icon = {
                class:
                    estadoJornada.tipo === "error"
                        ? "icon_alert-circle"
                        : estadoJornada.tipo === "warning"
                        ? "dx-icon-warning"
                        : estadoJornada === jornadaEstado.activoDescansando
                        ? "icon_timer"
                        : estadoJornada === jornadaEstado.activoSinEmpezar
                        ? "icon_bell-circle-outline"
                        : "icon_Circle_check",
                color: isRevisado
                    ? "blue"
                    : estadoJornada.tipo === "error"
                    ? "red"
                    : estadoJornada.tipo === "warning"
                    ? "warning"
                    : "teal",
            };
            if (value.fecha <= this.date) {
                if (estadoJornada.tipo !== "valido") {
                    let iconElement = $("<i />");
                    iconElement.addClass(`${icon.class} position-absolute`);
                    iconElement.attr("style", `color: var(--${icon.color}) !important`);
                    !isRevisado &&
                        container.css({
                            outline: "3px solid",
                            "outline-color": `var(--${icon.color})`,
                        });
                    container.append(iconElement);
                    if (viewType === "detallado") iconElement.addClass("iconPivot");
                }
            }

            if (id !== null && viewType !== "detallado" && typeof value.texto !== "number" && !value.isHidden) {
                container.on("mouseover", () => {
                    this.tooltip.content()[0].innerHTML = `<div class="tooltipFlex">
              <span class="font-weight-bold">${estado?.denominacion}</span>
              <span>${value.texto}</span>
              ${
                  estadoJornada && value.fecha <= this.date && estadoJornada !== jornadaEstado.validoEstado
                      ? `<span style="color: var(--${icon.color})"><i style="color: var(--${icon.color}) !important" class="${icon.class}"></i> ${estadoJornada.estado}</span>`
                      : ""
              }
            </div>`;
                    this.tooltip.show(`div[id="${id}"]`);
                });
                container.on("mouseout", () => {
                    this.tooltip.hide();
                });
            }
        }

        // RESUMENES DE LOS DATOS
        if (value && typeof value.texto === "number") {
            if (this.isGrandTotalCell(cell)) {
                let htmlContent = "";
                const trabajando = this.isFestivo(cell.columnPath[1])
                    ? value.resumenEstados.find((x) => x.idEstado === 4)
                    : value.resumenEstados.find((x) => x.idEstado === 3);
                const vacaciones = value.resumenEstados.find((x) => x.idEstado === 2);
                const baja = value.resumenEstados.find((x) => x.idEstado === 6);
                const bajaLaboral = value.resumenEstados.find((x) => x.idEstado === 12);
                const libre = value.resumenEstados.find((x) => x.idEstado === 9);
                htmlContent += `<span class="py-2" style="color: ${trabajando.colorHexa}">${trabajando.total}</span>`;
                if (this.state.footerVisible) {
                    htmlContent += `<span class="py-1 font-size-xxxs" style="color: ${vacaciones?.colorHexa}">${vacaciones?.total}</span>`;
                    htmlContent += `<span class="py-1 font-size-xxxs" style="color: ${baja?.colorHexa}">${baja?.total}</span>`;
                    htmlContent += `<span class="py-1 font-size-xxxs" style="color: ${bajaLaboral?.colorHexa}">${bajaLaboral?.total}</span>`;
                    htmlContent += `<span class="py-1 font-size-xxxs" style="color: ${libre?.colorHexa}">${libre?.total}</span>`;
                    htmlContent += `<span class="py-1 font-size-xxxs">${value.totalEmpleados}</span>`;
                }

                cellElement[0].innerHTML = `<div class="totalCount grandTotalCount ${
                    this.state.footerVisible ? "grandTotalCount_expanded" : "grandTotalCount_collapsed"
                }">${htmlContent}</div>`;
            } else {
                cellElement[0].innerHTML = `<div class="totalCount py-1 px-2"><span>${value.texto}</span></div>`;
            }
        }

        // BORDES SEMANA
        area === "data" && cellElement[0].parentElement.classList.add("borderCell");
        area === "data" && typeof value?.texto === "number" && cellElement[0].parentElement.classList.add("rowHover");
        if ((cell.columnPath && cell.columnPath[1]) || (cell.path && typeof cell.path[1] === "object")) {
            if (this.isCierreNomina(cell.path ? cell.path[1] : cell.columnPath[1]))
                cellElement[0].classList.add("bordeCierreNomina");
            if (cell.columnPath?.at(1).getDay() === 0 || cell.path?.at(1).getDay() === 0)
                cellElement[0].classList.add("bordeSemana");
        }

        if (area == "column") {
            if (cell.path) {
                const dia = new Date(cell.path[1]);

                const columnText = capitalize(
                    dia.toLocaleString(
                        undefined,
                        viewType === "detallado" ? this.longColumnDateFormat : this.shortColumnDateFormat
                    ),
                    true
                );

                const headerColumnText = columnText.split(" ");

                const estadoDia = this.estadoJornadasEnFecha[cell.path[1]];
                let borderColor = estadoDia
                    ? estadoDia?.error > 0 || estadoDia?.warning > 0
                        ? "red"
                        : "teal"
                    : "transparent";
                let backgroundColor =
                    dia.getTime() === this.date.getTime()
                        ? "var(--primary)"
                        : this.isFestivo(dia)
                        ? "#E647FF20"
                        : "white";
                let textColor = dia.getTime() === this.date.getTime() ? "white" : "";

                let container = $(cellElement[0]);
                container.empty();

                let header = $(`<div class="cellColumn p-1 position-absolute-center">
                    <div class="cssPrevGrid-br ${
                        viewType !== "detallado" ? "flex-column" : "py-1"
                    }" style="outline: 2px solid var(--${borderColor}); background: ${backgroundColor}; color: ${textColor}">
                        <span class=${viewType === "detallado" ? "mr-2" : ""}>${headerColumnText[0]}</span>
                        <span>${headerColumnText[1]}</span>
                    </div>
                </div>`);
                header.appendTo(container);

                if (this.isCierreNomina(dia) && this.estadoSemanasValidadas[cell.path[0] - 1]) {
                    let headerCierreNomina = $("<div class='position-absolute-center-top headerCierreNomina'/>");
                    headerCierreNomina.html(getTrad("cierreNomina"));
                    headerCierreNomina.appendTo(container);
                }

                Object.assign(cellElement[0].style, { padding: "0", border: "none" });

                if (cell.path[1]) {
                    const estadoDia = this.estadoEstructuraOperativaEnFecha[cell.path[1]];
                    const id = `headerCellTurno_${turno.idTurno}_Dia_${cell.path[1].toLocaleString(undefined, {
                        month: "2-digit",
                        day: "2-digit",
                    })}`;
                    cellElement[0].id = id;
                    $(cellElement[0]).addClass("position-relative");

                    let element = $(cellElement[0].firstChild).children().last();
                    element.on("mouseover", ({ target }) => {
                        let htmlContent = "";
                        if (estadoDia.total === 0) {
                            htmlContent +=
                                '<span class="font-weight-bold color-red">No hay trabajadores disponibles</span>';
                        }
                        if (estadoDia.total > 0 && estadoDia.nofijado < estadoDia.total) {
                            htmlContent += '<span class="font-weight-bold">Abrir estructura operativa</span>';
                        }
                        if (estadoDia.nofijado > 0) {
                            htmlContent += `<span class="font-weight-bold color-red">Hay previsiones sin fijar</span>`;
                        } else if (estadoDia.incompleto > 0) {
                            htmlContent += `<span class="font-weight-bold color-red">Hay personas sin posición asignada</span>`;
                        }
                        this.tooltip.content()[0].innerHTML = `<div style="display: flex; flex-direction: column">${htmlContent}</div>`;
                        this.tooltip.show(`td[id="${id}"]`);
                    });
                    element.on("mouseout", () => {
                        this.tooltip.hide();
                    });
                    if (!cell.path[2] && this.estadoSemanasValidadas[cell.path[0] - 1] && cell.path[1] >= this.date) {
                        // ICONO ESTRUCTURA OPERATIVA

                        let alert = $("<i />");
                        alert.addClass("iconoPivotColumn dx-icon-isnotblank font-size-md");
                        alert.attr(
                            "style",
                            `color: var(${
                                estadoDia.total === 0
                                    ? "--teal"
                                    : estadoDia.incompleto === estadoDia.total
                                    ? "--red"
                                    : estadoDia.incompleto > 0
                                    ? "--yellow"
                                    : "--teal"
                            }) !important;`
                        );

                        alert.prependTo(cellElement[0]);
                        cell.estructuraOperativaEditable = true;
                    } else if (!cell.path[2] && !this.estadoSemanasValidadas[cell.path[0] - 1]) {
                        cell.estructuraOperativaEditable = false;
                    }
                } else if (!cell.path[1]) {
                    if (this.estadoSemanasValidadas[+cell.text - 1]) {
                        cellElement[0].innerHTML = htmlButton(
                            "Fijar Semana",
                            "dx-icon dx-icon-pin",
                            "dx-state-disabled opacity-01",
                            "width: 100%;"
                        );
                    } else {
                        cellElement[0].innerHTML = htmlButton(
                            "Fijar Semana",
                            "dx-icon dx-icon-pin",
                            "position-absolute-center-bottom",
                            "width: 100%;"
                        );
                    }
                }
            }
        }

        // FORMATEAR ENTRADAS ROW
        if (area === "row" && cellElement) {
            cellElement[0].classList.add("rowCell-CuadrantePersonal");
            // cellElement[0].classList.add("font-size-xxxxs");
            if (!this.isSummaryCell(cell) && cell.path.length > 1) {
                let tx = cell.text.split("-");
                const idRow = +tx.pop();
                const nombre = tx.join("-");

                const isLlamamiento = cell.text.includes("INCORPORACIÓN");
                let src = isLlamamiento ? null : this.props.fotosPerfil[idRow];

                let element = $(cellElement[0]);
                element.attr("id", `rowCell-${isLlamamiento ? "pers" : "llam"}-${idRow}`);

                let container = $(`<div class="he-100">`);

                let divPersona = $(`<div class="d-flex flex-row align-items-center p-0 ${
                    viewType === "detallado" ? "cellPerson" : "cellPersonCompacto"
                }">
                    ${
                        isLlamamiento
                            ? `<img src="${this.svg_missing}" class="fotoPersona"/>`
                            : `<div id="fotoPersona-${idRow}" style="background: url(${
                                  src ? "data:image/png;base64," + src : `'${this.svg_user}'`
                              })" class="fotoPersona" ></div>`
                    }
                    <span>${nombre}</span>
                </div>`);

                let divOpciones = $(`<div class="d-flex flex-row">
                    ${
                        isLlamamiento
                            ? '<i id="editarLlamamiento" class="dx-icon dx-icon-edit mr-2 p-1"></i>' +
                              '<i id="eliminarLlamamiento" class="dx-icon dx-icon-trash text-red mr-2 p-1"></i>'
                            : '<i id="calendario" class="dx-icon dx-icon-event mr-2 p-1"></i>'
                    }
                </div>`);
                container.append(divPersona, divOpciones);

                container.on("click", (e) => {
                    if (e.target.id === "calendario") this.popup_calendario.abrirExportarEditar(idRow, nombre);
                    else if (e.target.id === "editarLlamamiento") this.llamamientoPopup.open(idRow);
                    else if (e.target.id === "eliminarLlamamiento")
                        this.setState({
                            tooltipData: { idLlamamiento: idRow, target: element },
                        });
                    else {
                        let grid = this.refPersonalGeneralPopup.current.dxDataGrid_personas;

                        if (grid.getRowIndexByKey(idRow) === 0) {
                            //Persona ya cargada
                            grid.editRow(0);
                        } else {
                            isEditing = true;
                            grid.navigateToRow(idRow);
                        }
                    }
                });

                cellElement[0].innerHTML = "";
                element.addClass("personaHover");
                element.append(container);
            } else if (this.isTotalCell(cell)) {
                cellElement[0].innerHTML = `<div class="totalCount totalTitle py-1" title="${
                    cell.text.split("Total")[0]
                }"><span>${cell.text.split("Total")[0]}</span></div>`;
            } else if (this.isGrandTotalCell(cell)) {
                const estados = this.props.tblCalendarioPersonal_estado;

                const trabajando = estados.find((x) => x.idEstado === 3);
                const vacaciones = estados.find((x) => x.idEstado === 2);
                const baja = estados.find((x) => x.idEstado === 6);
                const bajaLaboral = estados.find((x) => x.idEstado === 12);
                const libre = estados.find((x) => x.idEstado === 9);
                if (this.state.footerVisible) {
                    //Titulos footer
                    cellElement[0].innerHTML = `
          <div class="grandTotal totalCount grandTotalCount grandTotalCount_expanded totalTitle">      
            <span class="pl-3 py-2" style="color: ${trabajando?.colorHexa}">
            <i class="dx-icon dx-icon-chevrondown" style="height: 5px !important"></i>
            Personal activo
            </span>
            <span class="pl-3 py-1 font-size-xxxs" style="color: ${vacaciones?.colorHexa}">${vacaciones?.denominacion}</span>
            <span class="pl-3 py-1 font-size-xxxs" style="color: ${baja?.colorHexa}">${baja?.denominacion}</span>
            <span class="pl-3 py-1 font-size-xxxs" style="color: ${bajaLaboral?.colorHexa}">${bajaLaboral?.denominacion}</span>
            <span class="pl-3 py-1 font-size-xxxs" style="color: ${libre?.colorHexa}">${libre?.denominacion}</span>
            <span class="pl-3 py-1 font-size-xxxs" >Empleados contratados</span>
          </div>`;
                } else {
                    cellElement[0].innerHTML = `
          <div class="grandTotal totalCount grandTotalCount grandTotalCount_collapsed totalTitle">
            <span class="pl-3 py-2" style="color: ${trabajando?.colorHexa}">
            <i class="dx-icon metismenu-state-icon dx-icon-chevronright" style="color: #9e9e9e !important"></i>
            Personal activo
            </span>
          </div>`;
                }
            }
        }

        if (!this.isGrandTotalCell(cell)) {
            $(cellElement[0]).addClass("font-size-xxxs position-relative");
        }

        if (this.isDataCell(cell) || this.isSummaryCell(cell)) {
            Object.assign(cellElement[0].style, {
                padding: this.isGrandTotalCell(cell) ? "0" : this.isTotalCell(cell) ? "3px 0" : "3px 5px",
            });
            (cell.rowType === "GT" || cell.type === "GT") && (cellElement[0].className += " stick");
        }
    };

    getEstado(value) {
        if (value) {
            const { tblCalendarioPersonal_estado } = this.props;
            return tblCalendarioPersonal_estado.filter((element) => {
                if (element.idEstado === value) {
                    return element;
                }
            })[0];
        }
    }

    // #endregion

    // #region onCellClick

    onCellClick = ({ area, cell, ...e }) => {
        if (cell.value && area === "data") {
            //click en una casilla de datos
            if (cell.rowPath.length == 1) {
                //click en una casilla de datos de resumen
                if (
                    this.pivotGridDataSource.state().rowExpandedPaths.some((headerPath) => {
                        return headerPath.join() === cell.rowPath.join();
                    })
                ) {
                    if (this.state.headerCollapseFunction_reverseCondition) {
                        this.setState({
                            headerCollapseFunction_reverseCondition: false,
                        });
                        this.pivotGridDataSource.expandHeaderItem("row", cell.rowPath);
                    } else {
                        this.pivotGridDataSource.collapseHeaderItem("row", cell.rowPath);
                    }
                } else {
                    if (this.state.headerCollapseFunction_reverseCondition) {
                        this.setState({
                            headerCollapseFunction_reverseCondition: false,
                        });
                        this.pivotGridDataSource.collapseHeaderItem("row", cell.rowPath);
                    } else {
                        this.pivotGridDataSource.expandHeaderItem("row", cell.rowPath);
                    }
                }
            } else if (!cell.value.bloqueado && !this.isGrandTotalCell(cell)) {
                if (cell.value.fecha > this.date) {
                    //celda de datos puros
                    const pivotGridDataSource = e.component.getDataSource();
                    const drilldown = pivotGridDataSource.createDrillDownDataSource(cell);

                    drilldown.load().then((data) => {
                        if (data[0]) {
                            const nombre = data[0].listId
                                .split("-")[0]
                                .split(" ")
                                .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLocaleLowerCase())
                                .join(" ");
                            const fecha = data[0].prevision.fecha
                                .toLocaleString(undefined, {
                                    weekday: "long",
                                    month: "long",
                                    day: "numeric",
                                })
                                .split(" ");
                            const dia = fecha.shift();
                            const mes = fecha.pop();
                            const join = fecha.join(" ");
                            const fechaString = [
                                dia.charAt(0).toUpperCase() + dia.slice(1),
                                join,
                                mes.charAt(0).toUpperCase() + mes.slice(1),
                            ].join(" ");
                            let title = `${nombre} - ${fechaString}`;
                            this.popup_edit.updatePopupState({
                                popupVisible: true,
                                prev: data[0].prevision,
                                popupMode: true,
                                popupTitle: title,
                            });
                        }
                    });
                } else {
                    const { value } = cell;
                    this.abrirJornadas(value, value.fecha);
                }
            }

            if (this.isGrandTotalCell(cell)) {
                this.toggleFooterVisibility();
            }
        }

        // Click en dias
        if (area === "column" && !$(e.event.target).is("i")) {
            //click en el header
            if (cell.expanded) {
                cell.expanded = false;
            }

            if (e.rowIndex === 0 && !this.estadoSemanasValidadas[e.columnIndex]) {
                //click en el boton de fijar semana
                let fechaDesde = this.props.selectedRange.firstDay;
                let fechaHasta = new Date(fechaDesde);
                fechaHasta.setHours(0, 0, 0);
                for (let i = 0; i <= e.columnIndex; i++) {
                    fechaHasta.setDate(fechaDesde.getDate() + 7);
                    if (e.columnIndex - i !== 0) {
                        fechaDesde = new Date(fechaHasta);
                    }
                }
                const payload = this.state.datosTurno.reduce((acc, { prevision }) => {
                    const fecha = new Date(prevision.fecha);
                    if (
                        !prevision.isFijado &&
                        prevision.idPersona &&
                        !this.isHidden(prevision) &&
                        prevision.idEstado !== 10 && // NO FIJAR CIERRES
                        prevision.idEstado !== 8 && // NO FIJAR FESTIVOS DISFRUTADOS
                        fecha < fechaHasta &&
                        fecha >= fechaDesde &&
                        fecha.getTime() > new Date().setHours(0, 0, 0, 0)
                    ) {
                        acc.push({
                            idCuadrantePersonal: prevision.idCuadrantePersonal,
                            idPersona: prevision.idPersona,
                            fecha: prevision.fecha,
                            idEstado: prevision.idEstado,
                            horaEntrada: prevision.horaEntrada ? dateTime_hourMinute(prevision.horaEntrada) : null,
                            horaSalida: prevision.horaSalida ? dateTime_hourMinute(prevision.horaSalida) : null,
                            idTurno: prevision.idTurno,
                            idPosicionNAreaLavanderiaNLavanderia: prevision.idPosicionNAreaLavanderiaNLavanderia,
                            idLavanderia: prevision.idLavanderia,
                        });
                    }
                    return acc;
                }, []);
                this.estadoSemanasValidadas[e.columnIndex] = true;
                this.props.loadPanel_show(true);

                postChangesIntoCuadrantePersonal(payload).then((serverResponse) => {
                    this.props.CuadranteContext.reload();
                    this.props.loadPanel_hide();
                });
            }

            // ESTRUCTURA OPERATIVA
            const estadoDia = this.estadoEstructuraOperativaEnFecha[cell.path[1]];
            if (
                e.rowIndex === 1 &&
                estadoDia.nofijado < estadoDia.total &&
                $(e.event.target).hasClass("cssPrevGrid-br")
            ) {
                const fecha = cell.path[1];
                this.refEstructuraOperativa.launch(fecha, this.props.turno);
            }
        }

        if (area === "row") {
            if (this.state.headerCollapseFunction_reverseCondition) {
                this.setState({
                    headerCollapseFunction_reverseCondition: false,
                });
            }

            if (this.isGrandTotalCell(cell)) {
                this.toggleFooterVisibility();
            }
        }
    };

    abrirJornadas = (value, fecha) => {
        const { datosTurno } = this.state;

        let datosDia = datosTurno
            .filter(({ prevision }) => prevision.fecha.getTime() == fecha.getTime())
            .map(({ prevision }) => {
                return prevision;
            });

        datosDia = query(datosDia).sortBy("categoriaInterna").thenBy("listId").toArray();

        this.jornadaPopup.show(value, datosDia);
    };

    toggleFooterVisibility = () => {
        this.setState({ footerVisible: !this.state.footerVisible });
        this.pivotGridDataSource.reload();
    };

    // #endregion

    onContentReady = () => {
        setTimeout(() => {
            if (getPostCuadrantePromise() == null || getPostCuadrantePromise().state() != "pending")
                this.props.loadPanel_hide();
        }, 150);
    };

    // #region Datasource

    setupPivotGrid = () => {
        this.pivotGridDataSource = new PivotGridDataSource({
            fields: [
                {
                    caption: "Categoria Interna",
                    dataField: "prevision.categoriaInterna",
                    area: "row",
                    sortBy: "prevision.categoriaInterna",
                    sortOrder: "asc",
                    expanded: true,
                },
                {
                    caption: "Nombre",
                    dataField: "listId",
                    sortingMethod: this.dxPivotGrid_sortingMethod,
                    width: 150,
                    area: "row",
                    allowCrossGroupCalculation: false,
                },
                {
                    dataField: "prevision.weekInterval",
                    dataType: "int",
                    expanded: true,
                    area: "column",
                },
                {
                    dataField: "prevision.fecha",
                    dataType: "string",
                    area: "column",
                },
                {
                    caption: "Turno",
                    dataField: "prevision",
                    dataType: "string",
                    summaryType: "custom",
                    calculateCustomSummary: this.customCollapseFunction,
                    area: "data",
                },
            ],
            store: new CustomStore({
                load: (loadOptions) => this.loadDataIntoPivotGrid(),
            }),
        });
        this.pivotGridDataSource.load();
    };

    dxPivotGrid_sortingMethod = ({ value: a }, { value: b }) => {
        if (a.includes("#") && !b.includes("#")) {
            return 1;
        }
        if (!a.includes("#") && b.includes("#")) {
            return -1;
        }
        if (a.includes("#") && b.includes("#")) {
            let ca = Number(a.split("#")[1].split("-")[0]);
            let cb = Number(b.split("#")[1].split("-")[0]);
            return ca < cb ? -1 : ca > cb ? 1 : 0;
        }
        return a < b ? -1 : a > b ? 1 : 0;
    };

    customCollapseFunction = (options) => {
        const { summaryProcess } = options;
        if (summaryProcess === "start") {
            const estados = [];
            this.props.tblCalendarioPersonal_estado.map((x) =>
                estados.push({
                    idEstado: x.idEstado,
                    colorHexa: x.colorHexa,
                    total: 0,
                })
            );

            options.totalValue = {
                texto: 0,
                resumenEstados: estados,
                totalEmpleados: 0,
                totalTrabajando: 0,
            };
        }

        if (summaryProcess === "calculate") {
            let { totalValue, value } = options;
            let jornada = "";

            if (!value.isHidden) {
                totalValue.totalEmpleados++;
                if (totalValue.resumenEstados.find((x) => x.idEstado === value.idEstado)) {
                    totalValue.resumenEstados.find((x) => x.idEstado === value.idEstado).total++;
                }
            }

            if (value.idEstado === 3 || value.idEstado === 4) {
                totalValue.totalTrabajando++;
                const { horaEntrada, horaSalida } = value;
                let timeFormat = { hour: "2-digit", minute: "2-digit", hour12: false };
                jornada = "";
                if (horaEntrada && horaSalida)
                    jornada = `${horaEntrada?.toLocaleTimeString(
                        undefined,
                        timeFormat
                    )} - ${horaSalida?.toLocaleTimeString(undefined, timeFormat)}`;
            }

            totalValue.idPersona = value.idPersona;
            totalValue.idLlamamiento = value.idLlamamiento;
            totalValue.idTurno = value.idTurno;
            totalValue.idCuadrantePersonal = value.idCuadrantePersonal;
            totalValue.colorHexa = value.colorHexa;
            totalValue.fecha = value.fecha;
            totalValue.fechaSalida = value.fechaSalida;
            totalValue.texto = jornada;
            totalValue.idEstado = value.idEstado;
            totalValue.isFijado = value.isFijado;
            totalValue.jornadas = value.jornadas;
            totalValue.cuadrante = value.cuadrante;
            totalValue.estimacion = value.estimacion;
            totalValue.calendarioPersonal = value.calendarioPersonal;
            totalValue.listId = value.listId;
            totalValue.isHidden = value.isHidden;

            /**
             * BORRAR COMENTARIO PARA VOLVER A BLOQUEAR LA
             * EDICIÓN DE TODAS LAS FECHAS PASADAS
             */
            totalValue.bloqueado =
                value.idLlamamiento ||
                value.idEstado == 10 ||
                totalValue.isHidden /*  || fecha.getTime() < this.date */;
        }

        if (summaryProcess === "finalize") {
            let { totalValue } = options;

            if (totalValue.totalTrabajando > 1) {
                totalValue.texto = totalValue.totalTrabajando;
                totalValue.idCuadrantePersonal = undefined;
                totalValue.colorHexa = undefined;
                totalValue.idEstado = undefined;
            }
        }
    };

    loadDataIntoPivotGrid = () => {
        const { CuadranteContext } = this.props;
        this.estadoEstructuraOperativaEnFecha = [];
        this.estadoJornadasEnFecha = [];

        for (let i = 0; i < this.estadoSemanasValidadas.length; i++) {
            this.estadoSemanasValidadas[i] = true;
        }
        const { firstDay } = this.props.selectedRange;
        let weekSeparator = [new Date(firstDay), new Date(firstDay), new Date(firstDay), new Date(firstDay)];
        weekSeparator.forEach((element) => {
            element.setHours(0, 0, 0, 0);
        });
        weekSeparator[0].setDate(firstDay.getDate() + 7);
        weekSeparator[1].setDate(firstDay.getDate() + 14);
        weekSeparator[2].setDate(firstDay.getDate() + 21);
        weekSeparator[3].setDate(firstDay.getDate() + 28);

        const idsToShow = CuadranteContext.tblPersona.filter(
            (x) =>
                CuadranteContext.data.filter(
                    (y) =>
                        x.idPersona === y.data.idPersona && !this.isHidden(y.data.cuadrante ?? y.data.estimacion ?? {})
                ).length > 0
        );

        let datosTurno = CuadranteContext.data
            .filter(
                (x) =>
                    (x.data.idLlamamiento && !this.isHidden(x.data.estimacion)) ||
                    idsToShow.find((y) => x.data.idPersona === y.idPersona)
            )
            .map((item) => {
                const { jornadas, cuadrante, calendarioPersonal, estimacion } = item.data;

                let cell = cuadrante && cuadrante.idEstado ? cuadrante : estimacion ?? {};
                cell.isFijado = cuadrante && cuadrante.idEstado ? true : estimacion != null ? false : null;

                const isLlamamiento = item.data.idLlamamiento != null;

                const newFecha = new Date(item.data.fecha);
                let weekInterval;
                if (newFecha < weekSeparator[0]) {
                    weekInterval = 1;
                } else if (newFecha < weekSeparator[1]) {
                    weekInterval = 2;
                } else if (newFecha < weekSeparator[2]) {
                    weekInterval = 3;
                } else if (newFecha < weekSeparator[3]) {
                    weekInterval = 4;
                }

                if (!this.estadoEstructuraOperativaEnFecha[newFecha]) {
                    this.estadoEstructuraOperativaEnFecha[newFecha] = {
                        nofijado: 0,
                        incompleto: 0,
                        total: 0,
                    };
                }

                if (!isLlamamiento) {
                    if (
                        this.estadoSemanasValidadas[weekInterval - 1] &&
                        !cell.isFijado &&
                        !this.isHidden(cell) &&
                        cell.idEstado !== 10 && // NO FIJAR CIERRES
                        cell.idEstado !== 8 && // NO FIJAR FESTIVOS DISFRUTADOS
                        newFecha.getTime() > new Date().setHours(0, 0, 0, 0)
                    ) {
                        this.estadoSemanasValidadas[weekInterval - 1] = false;
                    }
                    if (cell.idEstado === 3 || cell.idEstado === 4) {
                        //filtro dias trabajados y festivos trabajados
                        cell.isFijado == false && this.estadoEstructuraOperativaEnFecha[newFecha].nofijado++;
                        cell.idPosicionNAreaLavanderiaNLavanderia === null &&
                            this.estadoEstructuraOperativaEnFecha[newFecha].incompleto++;
                        this.estadoEstructuraOperativaEnFecha[newFecha].total++;
                    }

                    if (!this.estadoJornadasEnFecha[newFecha] && newFecha <= this.date) {
                        this.estadoJornadasEnFecha[newFecha] = {
                            error: 0,
                            warning: 0,
                            valido: 0,
                        };
                    }

                    let isRevisado = item.data.idPersona ? this.isDiaRevisado(jornadas) : true;
                    if (
                        newFecha <= this.date &&
                        !isRevisado &&
                        (cuadrante ||
                            estimacion ||
                            (calendarioPersonal && calendarioPersonal.idEstado !== 3) ||
                            jornadas.length > 0)
                    ) {
                        let estadoJornada = this.jornadaPopup.getEstadoJornadasDia(
                            jornadas,
                            cuadrante,
                            calendarioPersonal,
                            estimacion
                        );
                        this.estadoJornadasEnFecha[newFecha][estadoJornada.tipo]++;
                    }
                }

                const isHidden = !isLlamamiento
                    ? estimacion || cuadrante
                        ? this.isHidden(estimacion ?? cuadrante)
                        : (!(calendarioPersonal?.idEstado ?? cell.idEstado) || jornadas.length === 0) &&
                          !calendarioPersonal
                    : item.data.fechaIni.setHours(0, 0, 0, 0) > newFecha;

                const listId = item.data.listId;
                const prevision = {
                    listId: listId,
                    idCuadrantePersonal: cell.idCuadrantePersonal,
                    horaEntrada: cell.horaEntrada ? durationToDatetime(cell.horaEntrada) : null,
                    horaSalida: cell.horaSalida ? durationToDatetime(cell.horaSalida) : null,
                    weekInterval: weekInterval,
                    fecha: newFecha,
                    categoriaInterna: item.data.categoriaInterna,
                    idPersona: item.data.idPersona,
                    idLlamamiento: item.data.idLlamamiento,
                    idEstado: item.data.calendarioPersonal?.idEstado ?? cell.idEstado,
                    idPosicionNAreaLavanderiaNLavanderia: cell.idPosicionNAreaLavanderiaNLavanderia,
                    idTurno: cell.idTurno ?? this.props.turno.idTurno,
                    colorHexa:
                        this.props.tblCalendarioPersonal_estado.find((x) => x.idEstado === cell.idEstado)?.colorHexa ??
                        "#000000",
                    idLavanderia: cell.idLavanderia ?? item.data.jornadas[0]?.idLavanderia,
                    isFijado: cell.isFijado,
                    jornadas: item.data.jornadas,
                    cuadrante: item.data.cuadrante,
                    estimacion: item.data.estimacion,
                    calendarioPersonal: item.data.calendarioPersonal,
                    isHidden: isHidden || (!item.data.isContratado && !isLlamamiento),
                };
                return {
                    listId: listId,
                    prevision: prevision,
                };
            });

        this.setState({ datosTurno });
        return datosTurno;
    };

    // #endregion

    render() {
        return (
            <>
                <PivotGrid
                    id={`PivotCuadrante-${this.props.turno.idTurno}`}
                    className="PivotCuadrante"
                    style={{ height: "100%" }}
                    ref={this.pivotGrid}
                    showColumnGrandTotals={false}
                    showColumnTotals={false}
                    dataSource={this.pivotGridDataSource}
                    onCellPrepared={this.onCellPrepared}
                    onCellClick={this.onCellClick}
                    onContentReady={this.onContentReady}
                    rowHeaderLayout={"tree"}
                    showBorders={true}
                    wordWrapEnabled={true}
                >
                    <FieldChooser enabled={false} />
                    <LoadPanel enabled={false}></LoadPanel>
                    <HeaderFilter />
                </PivotGrid>
                <Tooltip ref={this.refTooltip} key={"dataPrevTooltip"}>
                    <span className="font-weight-bold">Abrir estructura operativa</span>
                </Tooltip>
                <Tooltip
                    animation={null}
                    position={"right"}
                    visible={this.state.tooltipData.target != null}
                    target={this.state.tooltipData.target}
                    closeOnOutsideClick={true}
                    onHiding={this.dxButton_onClick_Cancelar}
                >
                    <div className="d-flex flex-column">
                        <div className="font-size-md mb-3">{this.getTrad("preg_RegistroEliminarCorto")}</div>
                        <Box className="d-flex flex-row" width={220} style={this.gap}>
                            <ItemBox ratio={1}>
                                <Button text={"Cancelar"} onClick={this.dxButton_onClick_Cancelar} />
                            </ItemBox>
                            <ItemBox ratio={1}>
                                <Button
                                    text={"Confirmar"}
                                    type="danger"
                                    onClick={this.dxButton_onClick_EliminarLlamamiento}
                                />
                            </ItemBox>
                        </Box>
                    </div>
                </Tooltip>
            </>
        );
    }

    // #region TRADUCCIONES

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    // #endregion

    // #region Helpers

    isFestivo = (fecha) => {
        return this.props.CuadranteContext.tblCalendarioLavanderia.find((x) => {
            return x.idEstado === 8 && x.fecha === formatDate_noTime_parameter(fecha);
        });
    };

    isCierreNomina = (fecha) => {
        return this.props.CuadranteContext.tblCalendarioLavanderia.find((x) => {
            return x.idEstado === 14 && x.fecha === formatDate_noTime_parameter(fecha);
        });
    };

    isHidden(value) {
        return !(value?.idTurno === this.props.turno.idTurno || this.props.subturnos?.includes(value?.idTurno));
    }

    isDataCell(cell) {
        return cell.area === "data" && cell.rowType === "D" && cell.columnType === "D";
    }

    isSummaryCell(cell) {
        return this.isTotalCell(cell) || this.isGrandTotalCell(cell);
    }

    isTotalCell(cell) {
        return cell.type === "T" || cell.rowType === "T" || cell.columnType === "T";
    }

    isGrandTotalCell(cell) {
        return cell.type === "GT" || cell.rowType === "GT" || cell.columnType === "GT";
    }

    isDiaRevisado = (jornadas) => {
        let estado =
            jornadas.length > 0
                ? jornadas.reduce((estado, jornada) => {
                      if (!estado) return estado;
                      if (!jornada.isRevisado) return jornada.isRevisado;
                      return true;
                  }, true)
                : false;
        return estado;
    };

    // #endregion

    dxButton_onClick_EliminarLlamamiento = () => {
        const { CuadranteContext } = this.props;
        const { tooltipData } = this.state;
        CuadranteContext.getDS_tblLlamamiento()
            .store()
            .remove(tooltipData.idLlamamiento)
            .then(() => {
                CuadranteContext.reload();
                this.setState({ tooltipData: {} });
            });
    };

    dxButton_onClick_Cancelar = () => {
        this.setState({ tooltipData: {} });
    };
}

// FORMATEAR DIFERENCIACION ENTRE SEMANAS
const htmlButton = (text, icon, classes, style) => {
    return `
  <div class="dx-button dx-button-mode-outlined dx-button-primary dx-button-has-text dx-button-has-icon ${classes}" aria-label="${text}" tabindex="0" role="button" style="${style}">
    <div class="py-1 px-2">
      <i class="${icon}" style="color: #9e9e9e !important"></i>
      <span class="dx-button-text">${text}</span>
    </div>
  </div>
  `;
};

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
    fotosPerfil: state.fotos.fotosPerfil,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrevGrid);
