import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { getTrad } from "helpers";
import Form from "./components/Form";
import notify from "devextreme/ui/notify";
import { saveData } from "pages/Administracion/shared/model";
import { payloadBuilder } from "../../model";

const FormPresupuestoVenta = forwardRef(({ presupuestoSel, validations, datasources, reloadData, closePopup }, ref) => {
    const [idMoneda, setIdMoneda] = useState(1);
    const formPresupuestoVenta_REF = useRef();

    useImperativeHandle(ref, () => ({
        onSubmit_FormPresupuesto,
        hasEditData: formPresupuestoVenta_REF.current.hasEditData,
    }));

    useEffect(() => {
        setIdMoneda(presupuestoSel.idMoneda);
    }, [presupuestoSel]);

    const onSubmit_FormPresupuesto = async () => {
        return new Promise((resolve, reject) => {
            const formData = formPresupuestoVenta_REF.current.getFormData();
            formData.tblArticuloNAdmPresupuestoVenta = formData.articulosDraft;
            let promises = [];

            validations.forEach((fnValidation) => {
                promises.push(fnValidation());
            });

            Promise.all(promises)
                .then(() => {
                    delete formData.articulosDraft;
                    saveData("POST", payloadBuilder(formData, true), "postAdmPresupuestoVenta").then((data) => {
                        closePopup();
                        reloadData();
                        resolve(data);
                    });
                })
                .catch((message) => {
                    if (message == null || message == undefined || message == "") {
                        message = getTrad("errorIntroduccionDatos");
                    }
                    notify({
                        message: message,
                        type: "error",
                        displayTime: "1500",
                        closeOnClick: true,
                    });
                    reject(e);
                });
        });
    };

    return (
        <>
            <Form
                ref={formPresupuestoVenta_REF}
                formData={presupuestoSel}
                validations={validations}
                setIdMoneda={setIdMoneda}
                idMoneda={idMoneda}
                datasources={datasources}
            />
        </>
    );
});

export default FormPresupuestoVenta;
