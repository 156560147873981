import React, { Fragment } from "react";
import { connect } from "react-redux";
import { connectionConstants } from "../../../constants";

//Actions
import { loadPanelActions } from "../../../actions";

import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Pager,
    Paging,
    Editing,
    KeyboardNavigation,
    Lookup as Lookup_grid,
    Export,
    Selection,
} from "devextreme-react/data-grid";
import { DateBox } from "devextreme-react/date-box";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import Validator, { RequiredRule } from "devextreme-react/validator";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { Popup, ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";
import { Popover } from "devextreme-react/popover";
import { TagBox } from "devextreme-react/tag-box";
import { SelectBox } from "devextreme-react/select-box";
import {
    getNombreFormulario,
    errorHandler,
    authHeader,
    formatDate_noTime_parameter,
    patchRequestHandler,
    getTrad,
    formatDateTimeOffset_lavanderia,
    startOfMonth,
} from "../../../helpers";

import $ from "jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";
import { removeCellComponent } from "../../../components/DataGrid/Cells";
import ListsCompaEnti from "../../../components/ListsCompaEnti";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

//Css
import "./Css.scss";
import { DropDownBox, TextArea } from "devextreme-react";
import Form, { Item } from "devextreme-react/form";

import { saveAs } from "file-saver";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";

//SignalRCore
import { signalRCoreService } from "services";

class ReunionesComerciales extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entidadSel: null,
            items_tblFormatoReunion: [],
            items_tblTipoReunion: [],
            isVisible_dxPopup_tblParticipantesNReunion: false,
            isVisible_dxPopup_nuevaReunion: false,
            reunionEdit: null,
            formData_nuevaReunion: {},
            minDate: new Date(1900, 0, 0),
            maxDate: new Date(),
            dataSource_tblIncidenciaNReunion: [],
            fechaSel: {
                key: "selection",
                idTipoCalendario: 2,
                startDate: new Date(startOfMonth(new Date()).setHours(0, 0, 0, 0)),
                endDate: new Date(new Date().setHours(23, 59, 59, 999)),
            },
            isVisible_dxPopup_incidencia: false,
            incidenciaSel: {},
        };

        this.dxPopover_REF = React.createRef();
        this.dxForm_nuevaReunion_REF = React.createRef();
        this.dxDataGrid_incidencias_REF = React.createRef();
        this.dxForm_incidencia_REF = React.createRef();

        //DATASOURCE
        this.datasource_tblReunion_onLoading = this.datasource_tblReunion_onLoading.bind(this);
        this.cliente_calculateCellValue = this.cliente_calculateCellValue.bind(this);

        //EVENTOS
        this.dateRangePicker_onDateSelected = this.dateRangePicker_onDateSelected.bind(this);
        this.dxDropDownBox_idEntidad_onValueChanged = this.dxDropDownBox_idEntidad_onValueChanged.bind(this);
        this.dxDataGrid_tblReunion_onContentReady = this.dxDataGrid_tblReunion_onContentReady.bind(this);
        this.dxDataGrid_tblReunion_onRowUpdating = this.dxDataGrid_tblReunion_onRowUpdating.bind(this);
        this.dxDataGrid_tblReunion_onToolbarPreparing = this.dxDataGrid_tblReunion_onToolbarPreparing.bind(this);
        this.dxDataGrid_tblReunion_onExporting = this.dxDataGrid_tblReunion_onExporting.bind(this);
        this.dxDataGrid_tblReunion_onSelectionChanged = this.dxDataGrid_tblReunion_onSelectionChanged.bind(this);
        this.dxDatagrid_tblReunion_tblParticipantesNReunion_cellRender =
            this.dxDatagrid_tblReunion_tblParticipantesNReunion_cellRender.bind(this);
        this.dxDatagrid_tblReunion_tblParticipantesNReunion_calculateFilterExpression =
            this.dxDatagrid_tblReunion_tblParticipantesNReunion_calculateFilterExpression.bind(this);
        this.column_tblParticipantesNReunion_onMouseEnter =
            this.column_tblParticipantesNReunion_onMouseEnter.bind(this);
        this.onHiding_popup_tblParticipantesNReunion = this.onHiding_popup_tblParticipantesNReunion.bind(this);
        this.onClick_dxButton_aceptar_tblParticipantesNReunion =
            this.onClick_dxButton_aceptar_tblParticipantesNReunion.bind(this);
        this.onShowing_popup_nuevaReunion = this.onShowing_popup_nuevaReunion.bind(this);
        this.onHiding_popup_nuevaReunion = this.onHiding_popup_nuevaReunion.bind(this);
        this.onClick_dxButton_guardar_nuevaReunion = this.onClick_dxButton_guardar_nuevaReunion.bind(this);
        this.dxDataGrid_tblIncidencias_onToolbarPreparing =
            this.dxDataGrid_tblIncidencias_onToolbarPreparing.bind(this);
        this.dxDataGrid_tblParticipantesNReunion_onContentReady =
            this.dxDataGrid_tblParticipantesNReunion_onContentReady.bind(this);
        this.dxDataGrid_tblParticipantesNReunion_onRowInserting =
            this.dxDataGrid_tblParticipantesNReunion_onRowInserting.bind(this);
        this.dxDataGrid_tblParticipantesNReunion_onRowUpdating =
            this.dxDataGrid_tblParticipantesNReunion_onRowUpdating.bind(this);
        this.dxDataGrid_tblParticipantesNReunion_onRowRemoving =
            this.dxDataGrid_tblParticipantesNReunion_onRowRemoving.bind(this);
        this.dxDataGrid_tblParticipantesNReunion_onToolbarPreparing =
            this.dxDataGrid_tblParticipantesNReunion_onToolbarPreparing.bind(this);
        this.dxForm_nuevaReunion_onValueChanged = this.dxForm_nuevaReunion_onValueChanged.bind(this);
        this.dxDropDownBox_idEntidad_contentRender = this.dxDropDownBox_idEntidad_contentRender.bind(this);
        this.dxList_entidades_onSelectionChanged = this.dxList_entidades_onSelectionChanged.bind(this);
        this.onShowing_popup_incidencia = this.onShowing_popup_incidencia.bind(this);
        this.onHiding_popup_incidencia = this.onHiding_popup_incidencia.bind(this);
        this.onHidden_popup_incidencia = this.onHidden_popup_incidencia.bind(this);
        this.onSelectionChanged_dxDataGrid_incidencias = this.onSelectionChanged_dxDataGrid_incidencias.bind(this);

        this.editCellRender_hasIncidencia = this.editCellRender_hasIncidencia.bind(this);
        this.añadirReunion_onClick = this.añadirReunion_onClick.bind(this);
        this.limpiarFiltros_onClick = this.limpiarFiltros_onClick.bind(this);
        this.actualizar_onClick = this.actualizar_onClick.bind(this);

        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);

        this.signalR_reconnect = this.signalR_reconnect.bind(this);
    }

    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }

    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    get dxPopover() {
        return this.dxPopover_REF.current.instance;
    }
    get dxForm_nuevaReunion() {
        return this.dxForm_nuevaReunion_REF.current.instance;
    }

    get dxDataGrid_incidencias() {
        return this.dxDataGrid_incidencias_REF.current.instance;
    }

    get dxForm_incidencia() {
        return this.dxForm_incidencia_REF.current.instance;
    }

    //#region

    formData_nuevaReunion_init = {
        idFormatoReunion: null,
        idTipoReunion: null,
        observaciones: null,
        tblParticipantesNReunion: [],
    };

    datasource_tblReunion_todas = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblReunion",
            key: "idReunion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblReunion_beforeSend(request);
            },
            version: 4,
        }),
        expand: [
            "tblIncidenciaNReunion($select=idIncidenciaNavigation;$expand=idIncidenciaNavigation($select=estado))",
        ],
        postProcess: function (data) {
            $.each(data, function (index, item) {
                let hasIncidencia = false;
                let hasIncidenciaAbierta = false;

                hasIncidencia = item.tblIncidenciaNReunion.length > 0;
                if (hasIncidencia) {
                    $.each(item.tblIncidenciaNReunion, function (index2, item2) {
                        if (item2.idIncidenciaNavigation.estado === false) hasIncidenciaAbierta = true;
                    });
                }
                item.colorEvento = hasIncidenciaAbierta ? "red" : hasIncidencia ? "green" : "gray";
                item.idEvento = hasIncidenciaAbierta ? 1 : hasIncidencia ? 2 : 3;
                item.titulo = getTrad("reuniones");
                item.denoEventoSingular = hasIncidenciaAbierta
                    ? getTrad("reunionIncAbierta")
                    : hasIncidencia
                    ? getTrad("reunionIncCerrada")
                    : getTrad("reunionNoInc");
                item.denoEventoPlural = hasIncidenciaAbierta
                    ? getTrad("reunionesIncAbiertas")
                    : hasIncidencia
                    ? getTrad("reunionesIncCerradas")
                    : getTrad("reunionesSinInc");
            });
            return data;
        },
    });

    datasource_tblReunion = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblReunion",
            key: "idReunion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblReunion_beforeSend(request);
            },
            onLoading: (loadOptions) => {
                this.datasource_tblReunion_onLoading(loadOptions);
            },
            version: 4,
        }),
        expand: [
            "idCompañiaNavigation($select=denominacion),idEntidadNavigation($select=denominacion),idUsuarioNavigation($select=nombre),tblParticipantesNReunion($select=idParticipanteNReunion,nombre),tblIncidenciaNReunion($select=idIncidenciaNReunion;$expand=idIncidenciaNavigation($select=codigo,estado,fechaIncidencia,fechaPrevisionResolucion,descripcionIncidencia;$expand=idUsuarioCreaNavigation($select=nombre),idUsuarioResponsableNavigation($select=nombre,apellidos),idSubTipoIncidenciaNavigation($select=idSubTipoIncidencia)))",
        ],
    });

    datasource_tblReunion_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblReunion_onLoading(loadOptions) {
        let { entidadSel, fechaSel } = this.state;
        let start = fechaSel && new Date(fechaSel.startDate.getTime());
        if (start) {
            start.setHours(0, 0, 0, 0);
            start = formatDateTimeOffset_lavanderia(2, start);
        }

        let end = fechaSel && new Date(fechaSel.endDate.getTime());
        if (end) {
            end.setHours(23, 59, 59, 999);
            end = formatDateTimeOffset_lavanderia(2, end);
        }

        let filtroCompañiaEntidad =
            entidadSel != null
                ? entidadSel.idEntidad == null
                    ? null
                    : entidadSel.idEntidad != -1
                    ? ["idEntidad", "=", entidadSel.idEntidad]
                    : ["idCompañia", "=", entidadSel.idCompañia]
                : null;
        let filtroFecha = fechaSel != null ? ["fecha ge " + start + " and fecha le " + end] : null;

        let filtros = [filtroCompañiaEntidad, filtroFecha];
        if (loadOptions.filter) loadOptions.filter = [loadOptions.filter];
        else loadOptions.filter = [];

        $.each(filtros, function (index, item) {
            if (item != null) {
                if (loadOptions.filter.length > 0) loadOptions.filter.push("and");
                loadOptions.filter.push(item);
            }
        });

        if (loadOptions.filter.length === 0) loadOptions.filter = null;
    }

    datasource_tblFormatoReunion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblFormatoReunion",
            key: "idFormatoReunion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblFormatoReunion_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: function (data) {
            $.each(data, function (index, item) {
                item.denominacion = item.denominacion.toUpperCase();
            });
            return data;
        },
    });

    datasource_tblFormatoReunion_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblTipoReunion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoReunion",
            key: "idTipoReunion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTipoReunion_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: function (data) {
            $.each(data, function (index, item) {
                item.denominacion = item.denominacion.toUpperCase();
            });
            return data;
        },
    });

    datasource_tblTipoReunion_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblParticipantesNReunion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblParticipantesNReunion",
            key: "idParticipanteNReunion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblParticipantesNReunion_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblParticipantesNReunion_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblCompañia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCompañia",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCompañia_beforeSend(request);
            },
            version: 4,
        }),
        select: ["idCompañia", "denominacion"],
        filter: ["eliminado eq false"],
        sort: ["denominacion asc"],
    });

    datasource_tblCompañia_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEntidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblEntidad_beforeSend(request);
            },
            version: 4,
        }),
        select: ["idCompañia", "idEntidad", "denominacion"],
        sort: ["denominacion asc"],
    });

    datasource_tblEntidad_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblTipoSubIncidencia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoSubIncidencia",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTipoSubIncidencia_beforeSend(request);
            },
            version: 4,
        }),
        select: ["idSubTipoIncidencia", "denominacion"],
        sort: ["denominacion asc"],
    });

    datasource_tblTipoSubIncidencia_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    enum_estadoIncidencia = [
        {
            estado: true,
            denominacion: getTrad("cerrada").toUpperCase(),
        },
        {
            estado: false,
            denominacion: getTrad("abierta").toUpperCase(),
        },
    ];
    //#endregion

    render() {
        let {
            items_tblFormatoReunion,
            items_tblTipoReunion,
            isVisible_dxPopup_tblParticipantesNReunion,
            isVisible_dxPopup_nuevaReunion,
            reunionEdit,
            formData_nuevaReunion,
            fechaSel,
            maxDate,
            minDate,
            dataSource_tblIncidenciaNReunion,
            items_tblTipoSubIncidencia,
            isVisible_dxPopup_incidencia,
            incidenciaSel,
        } = this.state;

        return (
            <Fragment>
                <PageTitle
                    heading={getNombreFormulario(this)}
                    postHeadingEnd={
                        <Box direction="row" width="100%" height="100%">
                            <ItemBox baseSize={220}>
                                <div className="d-flex justify-content-end" style={{ width: 220, marginRight: 20 }}>
                                    <DateRangePicker
                                        month
                                        year
                                        day
                                        align="right"
                                        defaultType="personalizado"
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        fechaSel={fechaSel}
                                        dataSource={this.datasource_tblReunion_todas}
                                        onDateSelected={this.dateRangePicker_onDateSelected}
                                    />
                                </div>
                            </ItemBox>
                            <ItemBox baseSize={20} />
                            <ItemBox baseSize={295}>
                                <div className="d-flex justify-content-end" style={{ width: 295 }}>
                                    <DropDownBox
                                        elementAttr={this.dxDropDownBox_filter_elementAttr}
                                        width={"100%"}
                                        dxForm_dataField="idEntidad"
                                        deferRendering={false}
                                        displayExpr="denominacion"
                                        dropDownOptions={this.dxDropDownBox_filter_dropDownOptions}
                                        placeholder={getTrad("todasCompaEntis").toUpperCase()}
                                        contentRender={this.dxDropDownBox_idEntidad_contentRender}
                                        onValueChanged={this.dxDropDownBox_idEntidad_onValueChanged}
                                    />
                                </div>
                            </ItemBox>
                        </Box>
                    }
                />
                <div className={"media-body"}>
                    <div id="ReunionesComerciales" className={"formContainer"}>
                        <DataGrid
                            //Datos
                            dataSource={this.datasource_tblReunion}
                            //Propiedades
                            columnsAutoWidth={true}
                            height={"100%"}
                            width={"100%"}
                            className={"pt-2"}
                            hoverStateEnabled={true}
                            //Estilos
                            showColumnLines={false}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            onContentReady={this.dxDataGrid_tblReunion_onContentReady}
                            onRowUpdating={this.dxDataGrid_tblReunion_onRowUpdating}
                            onSelectionChanged={this.dxDataGrid_tblReunion_onSelectionChanged}
                            onToolbarPreparing={this.dxDataGrid_tblReunion_onToolbarPreparing}
                            onExporting={this.dxDataGrid_tblReunion_onExporting}
                        >
                            <KeyboardNavigation
                                enterKeyAction="moveFocus"
                                enterKeyDirection="column"
                                editOnKeyPress={true}
                            />
                            <Editing mode="cell" allowUpdating={true} selectTextOnEditStart={true} />
                            <SearchPanel visible={true} width={240} />
                            <HeaderFilter visible={false} />
                            <FilterRow visible={true} showAllText={getTrad("todos").toUpperCase()} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={this.dxDataGrid_allowedPageSizes}
                                showInfo={true}
                            />
                            <Paging defaultPageSize={50} />
                            <Export enabled={true} fileName={"Reuniones"} />
                            <Column
                                caption=" "
                                name="hasIncidencia"
                                width={35}
                                alignment="center"
                                cssClass="p-0"
                                allowEditing={true}
                                showEditorAlways={true}
                                editCellRender={this.editCellRender_hasIncidencia}
                            />
                            <Column
                                dataField="fecha"
                                caption={getTrad("fecha")}
                                width={150}
                                dataType="date"
                                format="dd/MM/yyyy"
                                alignment="center"
                                sortOrder="desc"
                                sortIndex={0}
                                allowEditing={false}
                            />
                            <Column
                                dataField="denoCliente"
                                caption={getTrad("cliente")}
                                width={"15%"}
                                allowEditing={false}
                                allowHeadingFiltering={false}
                                calculateFilterExpression={this.cliente_calculateFilterExpression}
                                calculateDisplayValue={this.cliente_calculateCellValue}
                            />
                            <Column
                                allowEditing={false}
                                dataField="idUsuarioNavigation.nombre"
                                caption={getTrad("registradoPor")}
                                width={108}
                            />
                            <Column
                                dataField="idFormatoReunion"
                                caption={getTrad("formato")}
                                cssClass="dx-Cell_Editable"
                                allowHeaderFiltering={false}
                                width={130}
                            >
                                <RequiredRule />
                                <Lookup_grid
                                    dataSource={items_tblFormatoReunion}
                                    valueExpr={"idFormatoReunion"}
                                    displayExpr={"denominacion"}
                                />
                            </Column>
                            <Column
                                dataField="idTipoReunion"
                                caption={getTrad("tipo")}
                                cssClass="dx-Cell_Editable"
                                allowHeaderFiltering={false}
                                width={245}
                            >
                                <RequiredRule />
                                <Lookup_grid
                                    dataSource={items_tblTipoReunion}
                                    valueExpr={"idTipoReunion"}
                                    displayExpr={"denominacion"}
                                />
                            </Column>
                            <Column
                                dataField="tblParticipantesNReunion"
                                dataType="string"
                                allowEditing={false}
                                allowSorting={false}
                                cssClass="dx-Cell_Editable"
                                cellRender={this.dxDatagrid_tblReunion_tblParticipantesNReunion_cellRender}
                                calculateFilterExpression={
                                    this.dxDatagrid_tblReunion_tblParticipantesNReunion_calculateFilterExpression
                                }
                                caption={getTrad("participantes")}
                                width={240}
                            />
                            <Column
                                dataField="observaciones"
                                dataType="string"
                                cssClass="dx-Cell_Editable"
                                caption={getTrad("observaciones")}
                            />
                        </DataGrid>
                        <Popup
                            title={getTrad("participantes")}
                            showTitle={true}
                            width="90%"
                            maxWidth={400}
                            height={600}
                            deferRendering={false}
                            visible={isVisible_dxPopup_tblParticipantesNReunion}
                            closeOnOutsideClick={false}
                            onHiding={this.onHiding_popup_tblParticipantesNReunion}
                        >
                            <DataGrid
                                //Datos
                                dataSource={reunionEdit ? reunionEdit.tblParticipantesNReunion : []}
                                //Propiedades
                                columnsAutoWidth={true}
                                height={"100%"}
                                width={"100%"}
                                className={"pt-2"}
                                hoverStateEnabled={true}
                                keyExpr="idParticipanteNReunion"
                                //Estilos
                                showColumnLines={false}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                onContentReady={this.dxDataGrid_tblParticipantesNReunion_onContentReady}
                                onRowInserting={this.dxDataGrid_tblParticipantesNReunion_onRowInserting}
                                onRowUpdating={this.dxDataGrid_tblParticipantesNReunion_onRowUpdating}
                                onRowRemoving={this.dxDataGrid_tblParticipantesNReunion_onRowRemoving}
                                onToolbarPreparing={this.dxDataGrid_tblParticipantesNReunion_onToolbarPreparing}
                            >
                                <KeyboardNavigation
                                    enterKeyAction="moveFocus"
                                    enterKeyDirection="column"
                                    editOnKeyPress={true}
                                />
                                <Editing mode="cell" allowUpdating={true} selectTextOnEditStart={true} />
                                <Column dataField="nombre" dataType="string" caption={getTrad("participantes")}>
                                    <RequiredRule />
                                </Column>
                                <Column
                                    caption=" "
                                    width={50}
                                    alignment="center"
                                    cssClass="p-0"
                                    cellComponent={removeCellComponent}
                                />
                            </DataGrid>
                            <ToolbarItem_popup
                                widget="dxButton"
                                location="after"
                                toolbar="bottom"
                                options={{
                                    text: getTrad("aceptar"),
                                    type: "success",
                                    onClick: this.onClick_dxButton_aceptar_tblParticipantesNReunion,
                                }}
                            />
                        </Popup>
                        <Popup
                            title={getTrad("nuevaReunion")}
                            showTitle={true}
                            width="90%"
                            maxWidth={830}
                            height={650}
                            deferRendering={false}
                            visible={isVisible_dxPopup_nuevaReunion}
                            closeOnOutsideClick={false}
                            onShowing={this.onShowing_popup_nuevaReunion}
                            onHiding={this.onHiding_popup_nuevaReunion}
                        >
                            <Form
                                ref={this.dxForm_nuevaReunion_REF}
                                formData={formData_nuevaReunion}
                                deferRendering={false}
                                labelLocation="top"
                                colCount={2}
                                height="100%"
                                width="100%"
                            >
                                <Item dataField="idEntidad" label={this.formLabel_idCompaEnti} colSpan={1}>
                                    <DropDownBox
                                        elementAttr={this.dxDropDownBox_nuevaReunion_elementAttr}
                                        deferRendering={false}
                                        displayExpr="denominacion"
                                        dropDownOptions={this.dxDropDownBox_nuevaReunion_dropDownOptions}
                                        placeholder={getTrad("seleccionar") + "..."}
                                        contentRender={this.dxDropDownBox_idEntidad_contentRender}
                                        onValueChanged={this.dxForm_nuevaReunion_onValueChanged}
                                    ></DropDownBox>
                                </Item>
                                <Item dataField="idFormatoReunion" label={this.formLabel_idFormatoReunion} colSpan={1}>
                                    <SelectBox
                                        dxForm_dataField="idFormatoReunion"
                                        dataSource={items_tblFormatoReunion}
                                        displayExpr="denominacion"
                                        valueExpr="idFormatoReunion"
                                        onValueChanged={this.dxForm_nuevaReunion_onValueChanged}
                                    >
                                        <Validator>
                                            <RequiredRule />
                                        </Validator>
                                    </SelectBox>
                                </Item>
                                <Item dataField="idTipoReunion" label={this.formLabel_idTipoReunion} colSpan={1}>
                                    <SelectBox
                                        dxForm_dataField="idTipoReunion"
                                        dataSource={items_tblTipoReunion}
                                        displayExpr="denominacion"
                                        valueExpr="idTipoReunion"
                                        onValueChanged={this.dxForm_nuevaReunion_onValueChanged}
                                    >
                                        <Validator>
                                            <RequiredRule />
                                        </Validator>
                                    </SelectBox>
                                </Item>
                                <Item dataField="observaciones" label={this.formLabel_observaciones} colSpan={2}>
                                    <TextArea
                                        dxForm_dataField="observaciones"
                                        placeholder={getTrad("observaciones")}
                                        height={70}
                                        width="100%"
                                        onValueChanged={this.dxForm_nuevaReunion_onValueChanged}
                                    />
                                </Item>
                                <Item
                                    dataField="tblParticipantesNReunion"
                                    label={this.formLabel_tblParticipantesNReunion}
                                    colSpan={2}
                                >
                                    <DataGrid
                                        //Datos
                                        dataSource={formData_nuevaReunion.tblParticipantesNReunion}
                                        //Propiedades
                                        columnsAutoWidth={true}
                                        height={"100%"}
                                        width={"100%"}
                                        className={"pt-2"}
                                        hoverStateEnabled={true}
                                        keyExpr="idParticipanteNReunion"
                                        //Estilos
                                        showColumnLines={false}
                                        showRowLines={true}
                                        rowAlternationEnabled={true}
                                        onToolbarPreparing={
                                            this.dxDataGrid_tblParticipantesNReunion_nuevaReunion_onToolbarPreparing
                                        }
                                    >
                                        <KeyboardNavigation
                                            enterKeyAction="moveFocus"
                                            enterKeyDirection="column"
                                            editOnKeyPress={true}
                                        />
                                        <Editing mode="cell" allowUpdating={true} selectTextOnEditStart={true} />
                                        <Column dataField="nombre" dataType="string" caption={getTrad("nombre")}>
                                            <RequiredRule />
                                        </Column>
                                        <Column
                                            caption=" "
                                            width={50}
                                            alignment="center"
                                            cssClass="p-0"
                                            cellComponent={removeCellComponent}
                                        />
                                    </DataGrid>
                                </Item>
                            </Form>
                            <ToolbarItem_popup
                                widget="dxButton"
                                location="after"
                                toolbar="bottom"
                                options={{
                                    text: getTrad("guardar"),
                                    type: "success",
                                    onClick: this.onClick_dxButton_guardar_nuevaReunion,
                                }}
                            />
                        </Popup>

                        <Popup
                            title={getTrad("incidencias")}
                            showTitle={true}
                            width="90%"
                            maxWidth={1100}
                            height={425}
                            deferRendering={false}
                            visible={isVisible_dxPopup_incidencia}
                            closeOnOutsideClick={false}
                            onShowing={this.onShowing_popup_incidencia}
                            onHiding={this.onHiding_popup_incidencia}
                            onHidden={this.onHidden_popup_incidencia}
                        >
                            <Box direction="row" width="100%" height="100%">
                                <ItemBox baseSize={300}>
                                    <DataGrid
                                        ref={this.dxDataGrid_incidencias_REF}
                                        //Datos
                                        dataSource={dataSource_tblIncidenciaNReunion}
                                        //Propiedades
                                        keyExpr="idIncidenciaNReunion"
                                        columnsAutoWidth={true}
                                        width={"100%"}
                                        hoverStateEnabled={true}
                                        //Estilos
                                        showColumnLines={false}
                                        showRowLines={true}
                                        rowAlternationEnabled={true}
                                        onSelectionChanged={this.onSelectionChanged_dxDataGrid_incidencias}
                                    >
                                        <Selection mode="single" />
                                        <Column
                                            dataField="idIncidenciaNavigation.codigo"
                                            width={85}
                                            sortIndex={0}
                                            sortOrder={"asc"}
                                            caption={getTrad("codigo")}
                                        />
                                        <Column
                                            dataField="idIncidenciaNavigation.idSubTipoIncidenciaNavigation.idSubTipoIncidencia"
                                            width={200}
                                            caption={getTrad("tipo")}
                                        >
                                            <Lookup_grid
                                                dataSource={items_tblTipoSubIncidencia}
                                                valueExpr={"idSubTipoIncidencia"}
                                                displayExpr={"denominacion"}
                                            />
                                        </Column>
                                    </DataGrid>
                                </ItemBox>
                                <ItemBox baseSize={60} />
                                <ItemBox ratio={1}>
                                    <Form
                                        ref={this.dxForm_incidencia_REF}
                                        formData={incidenciaSel}
                                        deferRendering={false}
                                        labelLocation="top"
                                        colCount={2}
                                        height="100%"
                                        width="100%"
                                        readOnly={true}
                                    >
                                        <Item dataField="fechaIncidencia" label={this.formLabel_fecha}>
                                            <DateBox
                                                value={incidenciaSel ? incidenciaSel.fechaIncidencia : null}
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={false}
                                                spellcheck={true}
                                                type="date"
                                                useMaskBehavior={true}
                                                readOnly={true}
                                            />
                                        </Item>
                                        <Item
                                            dataField="fechaPrevisionResolucion"
                                            label={this.formLabel_fechaPrevisionResolucion}
                                        >
                                            <DateBox
                                                value={incidenciaSel ? incidenciaSel.fechaPrevisionResolucion : null}
                                                displayFormat="dd/MM/yyyy"
                                                showAnalogClock={false}
                                                spellcheck={true}
                                                type="date"
                                                useMaskBehavior={true}
                                                readOnly={true}
                                            />
                                        </Item>

                                        <Item
                                            dataField="idUsuarioCreaNavigation.nombre"
                                            label={this.formLabel_registradoPor}
                                        />
                                        <Item dataField="responsable" label={this.formLabel_responsable} />

                                        <Item dataField="estado" label={this.formLabel_estado}>
                                            <SelectBox
                                                value={incidenciaSel ? incidenciaSel.estado : null}
                                                dataSource={this.enum_estadoIncidencia}
                                                displayExpr="denominacion"
                                                valueExpr="estado"
                                                readOnly={true}
                                            ></SelectBox>
                                        </Item>

                                        <Item
                                            dataField="idSubTipoIncidencia"
                                            label={this.formLabel_tipoInc}
                                            colSpan={1}
                                        >
                                            <SelectBox
                                                value={incidenciaSel ? incidenciaSel.idSubTipoIncidencia : null}
                                                dxForm_dataField="idSubTipoIncidencia"
                                                dataSource={items_tblTipoSubIncidencia}
                                                displayExpr="denominacion"
                                                valueExpr="idSubTipoIncidencia"
                                                readOnly={true}
                                            ></SelectBox>
                                        </Item>

                                        <Item
                                            dataField="descripcionIncidencia"
                                            label={this.formLabel_observaciones}
                                            colSpan={2}
                                        >
                                            <TextArea
                                                value={incidenciaSel ? incidenciaSel.descripcionIncidencia : null}
                                                placeholder={getTrad("descripcion")}
                                                height={70}
                                                width="100%"
                                                readOnly={true}
                                            />
                                        </Item>
                                    </Form>
                                </ItemBox>
                            </Box>
                        </Popup>

                        <Popover ref={this.dxPopover_REF} width="auto" height="auto"></Popover>
                    </div>
                </div>
            </Fragment>
        );
    }

    formLabel_idCompaEnti = { text: "Compañía/Entidad" };
    formLabel_tblIncidencia = { text: getTrad("incidencias") };
    formLabel_idFormatoReunion = { text: getTrad("formato") };
    formLabel_idTipoReunion = { text: getTrad("tipo") };
    formLabel_observaciones = { text: getTrad("observaciones") };
    formLabel_fecha = { text: getTrad("fecha") };
    formLabel_fechaPrevisionResolucion = { text: "Fecha previsión resolución" };
    formLabel_tblParticipantesNReunion = {
        text: getTrad("participantes"),
        visible: false,
    };
    formLabel_responsable = { text: getTrad("responsable") };
    formLabel_registradoPor = { text: getTrad("registradoPor") };
    formLabel_estado = { text: getTrad("estado") };
    formLabel_tipoInc = { text: getTrad("tipo") };

    dxDataGrid_allowedPageSizes = [20, 50, 100];

    dxDropDownBox_nuevaReunion_dropDownOptions = {
        maxWidth: 800,
        width: "95%",
        height: 500,
        position: { my: "center", at: "center", of: window },
        closeOnOutsideClick: true,
    };

    dxDropDownBox_filter_elementAttr = {
        id: "dxDropDownBox_filter_id",
    };

    dxDropDownBox_nuevaReunion_elementAttr = {
        id: "dxDropDownBox_nuevaReunion_id",
    };

    dxDropDownBox_filter_dropDownOptions = {
        maxWidth: 800,
        width: "80vw",
        height: 500,
        position: {
            my: "top right",
            at: "bottom right",
            of: "#dxDropDownBox_filter_id",
        },
        closeOnOutsideClick: true,
    };

    editCellRender_hasIncidencia(e) {
        let hasIncidencia = e.data.tblIncidenciaNReunion.length > 0;
        let _this = this;
        return (
            <div className="container_spanCentrado pointer" title="Tiene incidencia/s">
                {hasIncidencia && (
                    <div
                        className="icon_Warning font-size-xl"
                        onClick={() => {
                            _this.setState({
                                isVisible_dxPopup_incidencia: true,
                                dataSource_tblIncidenciaNReunion: e.data.tblIncidenciaNReunion,
                            });
                        }}
                    />
                )}
            </div>
        );
    }

    cliente_calculateCellValue(rowData) {
        if (rowData.idEntidad) return rowData.idEntidadNavigation.denominacion;
        else if (rowData.idCompañia) return rowData.idCompañiaNavigation.denominacion;
    }

    cliente_calculateFilterExpression(filterValue) {
        return [
            ["idEntidadNavigation.denominacion", "contains", filterValue],
            "or",
            ["idCompañiaNavigation.denominacion", "contains", filterValue],
        ];
    }

    render_idUsuarioCreaNavigation(e) {
        let { idUsuarioCreaNavigation } = e.data.idIncidenciaNavigation;
        return idUsuarioCreaNavigation ? idUsuarioCreaNavigation.nombre : getTrad("desconocido");
    }

    idUsuarioResponsableNavigation_calculateFilterExpression(filterValue) {
        return [
            ["idIncidenciaNavigation.idUsuarioResponsableNavigation.nombre", "contains", filterValue],
            "or",
            ["idIncidenciaNavigation.idUsuarioResponsableNavigation.apellidos", "contains", filterValue],
        ];
    }

    render_idUsuarioResponsableNavigation(e) {
        let { idUsuarioResponsableNavigation } = e.data.idIncidenciaNavigation;
        return idUsuarioResponsableNavigation
            ? idUsuarioResponsableNavigation.nombre + " " + idUsuarioResponsableNavigation.apellidos
            : getTrad("desconocido");
    }

    render_estado(e) {
        let { estado } = e.data;
        return estado == true ? getTrad("abierta").toUpperCase() : getTrad("cerrada").toUpperCase();
    }

    dxDropDownBox_idEntidad_onValueChanged(e) {
        let { value } = e;
        this.setState({ entidadSel: value });
        this.datasource_tblReunion.reload();
    }

    dateRangePicker_onDateSelected(fecha) {
        if (fecha) {
            this.setState({
                fechaSel: fecha,
            });
            this.datasource_tblReunion.reload();
        }
    }

    dxDataGrid_tblReunion_onContentReady(e) {
        e.element.find(".dx-datagrid-export-button").dxButton("instance").option("text", getTrad("exportar"));
    }

    dxDatagrid_tblReunion_tblParticipantesNReunion_cellRender(e) {
        let nombres = "";
        $.each(e.data.tblParticipantesNReunion, function (index, item) {
            if (index != 0) nombres += ", ";
            nombres += item.nombre;
        });

        return (
            <div onMouseDown={(args) => this.column_tblParticipantesNReunion_onMouseEnter(args, e.data)}>{nombres}</div>
        );
    }

    dxDatagrid_tblReunion_tblParticipantesNReunion_calculateFilterExpression(filterValue) {
        return ["tblParticipantesNReunion/any(x: contains(tolower(x/nombre),'" + filterValue + "'))"];
    }

    column_tblParticipantesNReunion_onMouseEnter(e, data) {
        this.setState({
            isVisible_dxPopup_tblParticipantesNReunion: true,
            reunionEdit: data,
        });
    }

    dxDatagrid_tblReunion_tblParticipantesNReunion_editCellRender(e) {
        return (
            <TagBox
                value={e.data.tblParticipantesNReunion}
                items={e.data.tblParticipantesNReunion}
                displayExpr="nombre"
                valueExpr="idParticipanteNReunion"
                applyValueMode="useButtons"
                showSelectionControls={true}
            ></TagBox>
        );
    }

    dxDataGrid_tblReunion_onRowUpdating(e) {
        e.newData = patchRequestHandler(e.newData);
    }

    dxDataGrid_tblReunion_onSelectionChanged(e) {
        let dataSource_tblIncidenciaNReunion = $.map(e.selectedRowsData, function (item) {
            return item.tblIncidenciaNReunion;
        });

        this.setState({
            dataSource_tblIncidenciaNReunion: dataSource_tblIncidenciaNReunion,
        });
    }

    dxDataGrid_tblReunion_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    text: getTrad("añadirReunion"),
                    icon: "plus",
                    onClick: (args) => {
                        this.añadirReunion_onClick(args, e);
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: (args) => {
                        this.limpiarFiltros_onClick(args, e);
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    text: getTrad("actualizar"),
                    icon: "refresh",
                    onClick: (args) => {
                        this.actualizar_onClick(this, e);
                    },
                },
            }
        );
    }

    dxDataGrid_tblReunion_onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Sheet");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function ({ gridCell, excelCell }) {
                if (gridCell.rowType === "data" && gridCell.column.dataField === "tblParticipantesNReunion") {
                    $.each(gridCell.data.tblParticipantesNReunion, function (index, item) {
                        var isLastElement = index == gridCell.data.tblParticipantesNReunion.length - 1;

                        if (index == 0) excelCell.value = item.nombre;
                        else excelCell.value += item.nombre;

                        if (!isLastElement) excelCell.value += ", ";
                    });
                }
            },
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), getTrad("reuniones") + ".xlsx");
            });
        });
        e.cancel = true;
    }

    añadirReunion_onClick(e) {
        this.setState({
            isVisible_dxPopup_nuevaReunion: true,
            formData_nuevaReunion: $.extend(true, {}, this.formData_nuevaReunion_init),
        });
    }

    dxForm_nuevaReunion_onValueChanged(e) {
        setTimeout(() => {
            // Para ejecutar antes -> dxList_entidades_onSelectionChanged y así tener los datos formateados
            let dataField = e.component.option("dxForm_dataField");
            let { value } = e;
            let formData_nuevaReunion = { ...this.state.formData_nuevaReunion };
            formData_nuevaReunion[dataField] = value;

            if (dataField === "idEntidad") formData_nuevaReunion["idCompañia"] = null;
            else if (dataField === "idCompañia") formData_nuevaReunion["idEntidad"] = null;

            this.setState({ formData_nuevaReunion });
        }, 0);
    }

    dxDropDownBox_idEntidad_contentRender(dxDropDownBox) {
        let isDropDownFilter = dxDropDownBox.component.option("elementAttr").id == "dxDropDownBox_filter_id";

        return (
            <ListsCompaEnti
                todasCompaEntiSeleccionable={isDropDownFilter}
                compañiaSeleccionable={true}
                dropDownBoxComponent={dxDropDownBox.component}
                dxList_entidades_onSelectionChanged={this.dxList_entidades_onSelectionChanged}
            />
        );
    }

    dxDataGrid_tblIncidencias_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                template: function (e, index, item) {
                    item.append($("<div/>").addClass("font-size").text(getTrad("incidencias"))); //getTrad('reunion')
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: (args) => {
                        this.limpiarFiltros_onClick(args, e);
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    text: getTrad("actualizar"),
                    icon: "refresh",
                    onClick: (args) => {
                        this.actualizar_onClick(this, e);
                    },
                },
            }
        );
    }

    dxList_entidades_onSelectionChanged(value, dropDownBoxComponent) {
        dropDownBoxComponent.beginUpdate();
        let isCompañiaSel = value.idEntidad == -1;
        dropDownBoxComponent.option({
            value: null,
            placeholder: " ",
        });
        dropDownBoxComponent.option({
            dataSource: isCompañiaSel ? this.datasource_tblCompañia : this.datasource_tblEntidad,
            valueExpr: isCompañiaSel ? "idCompañia" : "idEntidad",
            value: isCompañiaSel ? value.idCompañia : value.idEntidad,
        });

        if (dropDownBoxComponent.option("elementAttr").id == "dxDropDownBox_nuevaReunion_id") {
            dropDownBoxComponent.option({
                dxForm_dataField: isCompañiaSel ? "idCompañia" : "idEntidad",
            });
        }
        dropDownBoxComponent.endUpdate();
        dropDownBoxComponent.close();

        if (dropDownBoxComponent.option("elementAttr").id == "dxDropDownBox_filter_id") {
            this.setState({
                entidadSel: value,
            });
            this.datasource_tblReunion.reload();
        }
    }

    limpiarFiltros_onClick(args, e) {
        //LIMPIA TODOS LOS FILTROS EXCEPTO EL DEL DATASOURCE
        e.component.beginUpdate();
        e.component.clearFilter("search");
        e.component.clearFilter("header");
        e.component.clearFilter("filterValue");
        e.component.clearFilter("row");
        e.component.endUpdate();

        notify({
            message: getTrad("aviso_C_FiltroRestaurado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
        });
    }

    actualizar_onClick(_this, e) {
        _this.datasource_tblReunion.reload();
    }

    onHiding_popup_tblParticipantesNReunion(e) {
        this.setState({
            isVisible_dxPopup_tblParticipantesNReunion: false,
            reunionEdit: null,
        });
    }

    onClick_dxButton_aceptar_tblParticipantesNReunion(e) {
        this.setState({
            isVisible_dxPopup_tblParticipantesNReunion: false,
            reunionEdit: null,
        });
    }

    onShowing_popup_nuevaReunion(e) {
        this.dxForm_nuevaReunion.repaint();
    }

    onHiding_popup_nuevaReunion(e) {
        this.setState({
            isVisible_dxPopup_nuevaReunion: false,
            reunionEdit: null,
        });
    }

    onShowing_popup_incidencia(e) {
        let { dataSource_tblIncidenciaNReunion } = this.state;
        if (dataSource_tblIncidenciaNReunion.length > 1) {
            dataSource_tblIncidenciaNReunion.sort(function (a, b) {
                var a_codigo = a.idIncidenciaNavigation.codigo;
                var b_codigo = b.idIncidenciaNavigation.codigo;
                return a_codigo == b_codigo ? 0 : a_codigo > b_codigo ? 1 : -1;
            });
        }
        this.dxDataGrid_incidencias.selectRows([dataSource_tblIncidenciaNReunion[0].idIncidenciaNReunion]);
    }

    onHiding_popup_incidencia(e) {
        this.setState({ isVisible_dxPopup_incidencia: false });
    }

    onHidden_popup_incidencia(e) {
        this.dxDataGrid_incidencias.deselectAll();
    }

    onClick_dxButton_guardar_nuevaReunion(e) {
        let _this = this;
        let formData_nuevaReunion = { ...this.state.formData_nuevaReunion };

        $.each(formData_nuevaReunion.tblParticipantesNReunion, function (index, item) {
            delete item.idParticipanteNReunion;
        });
        formData_nuevaReunion.fecha = formatDateTimeOffset_lavanderia(2, new Date());
        this.datasource_tblReunion
            .store()
            .insert(formData_nuevaReunion)
            .done(function () {
                _this.setState({
                    isVisible_dxPopup_nuevaReunion: false,
                    reunionEdit: null,
                });
                _this.datasource_tblReunion.reload();
            });
    }

    dxDataGrid_tblParticipantesNReunion_onContentReady(e) {
        e.element.find(".dx-freespace-row").css("height", 0);
    }

    dxDataGrid_tblParticipantesNReunion_onRowInserting(e) {
        let { reunionEdit } = this.state;
        e.data.idReunion = reunionEdit.idReunion;
    }

    dxDataGrid_tblParticipantesNReunion_onRowUpdating(e) {
        this.datasource_tblParticipantesNReunion.store().update(e.key, patchRequestHandler(e.newData));
    }

    dxDataGrid_tblParticipantesNReunion_onRowRemoving(e) {
        this.datasource_tblParticipantesNReunion.store().remove(e.key);
    }

    dxDataGrid_tblParticipantesNReunion_nuevaReunion_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                template: function (e, index, item) {
                    item.append($("<div/>").addClass("font-size").text(getTrad("participantes")));
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    text: getTrad("añadir"),
                    icon: "plus",
                    onClick: function (args) {
                        e.component.addRow();
                    },
                },
            }
        );
    }

    dxDataGrid_tblParticipantesNReunion_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            showText: "always",
            locateInMenu: "auto",
            options: {
                text: getTrad("añadir"),
                icon: "plus",
                onClick: function (args) {
                    e.component.addRow();
                },
            },
        });
    }

    onSelectionChanged_dxDataGrid_incidencias(e) {
        if (e.selectedRowsData.length > 0) {
            let incidencia = e.selectedRowsData[0].idIncidenciaNavigation;
            incidencia.idSubTipoIncidencia = incidencia.idSubTipoIncidenciaNavigation.idSubTipoIncidencia;
            incidencia.responsable = incidencia.idUsuarioResponsableNavigation
                ? incidencia.idUsuarioResponsableNavigation.nombre +
                  " " +
                  incidencia.idUsuarioResponsableNavigation.apellidos
                : "";
            this.setState({ incidenciaSel: incidencia });
        } else this.setState({ incidenciaSel: null });
    }

    signalR_reconnect() {
        let _this = this;
        let { lavanderia } = this.props;
        let { fechaSel } = this.state;

        signalRCoreService.leaveAllGroups();
        signalRCoreService.joinGroup(
            "ReunionesComerciales_" + lavanderia.idLavanderia + "_" + formatDate_noTime_parameter(fechaSel),
            [
                {
                    type: "ReunionesComerciales/signalR_refresh",
                    listener: function () {
                        _this.dxDataGrid_tblReunionesComerciales.refresh();
                    },
                },
            ]
        );
    }

    componentDidUpdate(prevProps, prevState) {}

    componentDidMount() {
        let _this = this;
        $.when(
            this.datasource_tblFormatoReunion.load(),
            this.datasource_tblTipoReunion.load(),
            this.datasource_tblTipoSubIncidencia.load(),
            this.datasource_tblReunion_todas.load()
        ).then(function (itemsFormato, itemsTipo, itemsSubTipoIncidencia) {
            _this.setState({
                items_tblFormatoReunion: itemsFormato[0],
                items_tblTipoReunion: itemsTipo[0],
                items_tblTipoSubIncidencia: itemsSubTipoIncidencia[0],
            });
        });
    }

    componentWillUnmount() {
        //#region SignalR
        signalRCoreService.leaveAllGroups();
        //#endregion
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    enableFullScreen: state.ThemeOptions.enableFullScreen,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReunionesComerciales);
