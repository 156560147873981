import React from 'react';
import DxForm, { Item } from "devextreme-react/form";

function Observaciones({formData, labels}) {
    const colCount = 5;
    const multiplier = 3;

    return (
        <DxForm
            formData={formData}
            height={"100%"}
            width={"100%"}
            labelLocation={"top"}
            colCount={colCount * multiplier}
            readOnly={formData.isCerrado}
        >
            <Item
                dataField={"observaciones"}
                label={labels.observaciones}
                editorType={"dxTextArea"}
                editorOptions={{ height: 200, width: "100%"}}
                colSpan={colCount * multiplier}
            />
        </DxForm>
    );
}

export default Observaciones;