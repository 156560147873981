import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate,
  formatDate_parameter,
  dxMensajePregunta,
  tooltipControl_creacion,
  patchRequestHandler,
  formatDate_noTime_parameter,
  getCierresFacturacion,
  getDaysInRange,
} from "../../../helpers";
import {
  svg_edit,
  svg_remove,
  svg_elemTrans_ud,
  svg_elemTrans_bac,
  svg_warning,
  svg_lock_outline,
} from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";
import ODataContext from "devextreme/data/odata/context";
import notify from "devextreme/ui/notify";

import validationEngine from "devextreme/ui/validation_engine";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA, USER) {
  var deferred = $.Deferred();

  var pedidoSel;
  var repartoSel;
  var prendasPedidoProdSel = null;
  var addPrendaSel = null;
  var addPrenda_rechazoNPrenda = [];
  var tipoRech_guardado = false,
    addPrendas_guardado = false,
    tipoReportSel;
  var dxDateBox_disabledDates = [];

  var selectAllCheckBox_gridRepartos;
  var checkBoxUpdating_gridRepartos = false;
  let fechasEntidadesVisibles = [];
  //#region Datasource

  var datasource_pedidos_Cierres = new DataSource({
    store: new CustomStore({
      key: "idPedido",
      load: function (loadOptions) {
        var deferred = $.Deferred();

        let pageIndex =
          loadOptions.skip == 0 ? 0 : loadOptions.skip / loadOptions.take;

        datasource_pedidos.paginate(true);
        datasource_pedidos.pageSize(loadOptions.take);
        datasource_pedidos.sort(loadOptions.sort);
        datasource_pedidos.requireTotalCount(true);
        datasource_pedidos.searchValue(loadOptions.searchValue);
        datasource_pedidos.searchOperation(loadOptions.searchOperation);
        datasource_pedidos.filter(
          loadOptions.filter ? loadOptions.filter : null,
        );
        datasource_pedidos.pageIndex(pageIndex);

        datasource_pedidos.load().done(function (data) {
          fechasEntidadesVisibles = [];
          let pedidosMapeados = data.map((x) => {
            return {
              fecha: formatDate_noTime_parameter(x.fecha),
              idEntidad: x.idEntidad,
            };
          });
          fechasEntidadesVisibles = [...new Set(pedidosMapeados)];

          datasource_tblCierreFactEntidad.load().done((fechasEntidadCierre) => {
            data.forEach((pedido) => {
              fechasEntidadCierre.forEach((item) => {
                let { fechaDesde, fechaHasta } = item;
                let rangoFechas = getDaysInRange(fechaDesde, fechaHasta).map(
                  (x) => formatDate_noTime_parameter(x),
                );

                if (!pedido.isCerradoFactEntidad)
                  pedido.isCerradoFactEntidad =
                    pedido.idEntidad == item.idEntidad &&
                    rangoFechas.filter(
                      (fecha) =>
                        fecha == formatDate_noTime_parameter(pedido.fecha),
                    ).length > 0;
              });
            });
            deferred.resolve(data, {
              totalCount: datasource_pedidos.totalCount(),
            });
          });
        });

        return deferred.promise();
      },
      update: (key, values) => {
        var deferred = $.Deferred();
        datasource_pedidos
          .store()
          .update(key, values)
          .done(() => {
            deferred.resolve();
          });
        return deferred.promise();
      },
      remove: (key) => {
        var deferred = $.Deferred();
        datasource_pedidos
          .store()
          .remove(key)
          .done(() => {
            deferred.resolve();
          });
        return deferred.promise();
      },
    }),
  });

  var datasource_tblCierreFactEntidad = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCierreFactEntidad",
      key: ["idEntidad", "fechaDesde", "fechaHasta"],
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: function (loadOptions) {
        let idsEntidad = [
          ...new Set(fechasEntidadesVisibles.map((x) => x.idEntidad)),
        ];
        let fechas = [...new Set(fechasEntidadesVisibles.map((x) => x.fecha))];

        let filtroEntidades =
          idsEntidad.length > 0
            ? ["idEntidad in (" + idsEntidad + ")"]
            : ["true"];
        let filtroFechas = "";

        fechas.forEach((fecha, index) => {
          if (index != 0) filtroFechas += " or ";
          filtroFechas +=
            "(fechaDesde le " + fecha + " and fechaHasta ge " + fecha + ")";
        });

        if (filtroFechas == "") filtroFechas = "true";
        if (loadOptions.filter) {
          loadOptions.filter = [
            loadOptions.filter,
            "and",
            [filtroEntidades],
            "and",
            [filtroFechas],
          ];
        } else {
          loadOptions.filter = [[filtroEntidades], "and", [filtroFechas]];
        }
      },
      version: 4,
    }),
  });

  var datasource_pedidos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPedido",
      key: "idPedido",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (request.method === "get")
          request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      onLoading: function (loadOptions) {
        let filtroLavanderiaReparto_expand =
          "$filter=idLavanderia eq " + LAVANDERIA.idLavanderia;
        loadOptions.expand.push(
          "tblReparto($select=idRepartoEstado,idLavanderia;$orderby=idRepartoEstado asc;$top=1;$count=true; " +
            filtroLavanderiaReparto_expand +
            ")",
        );

        let filtroLavanderiaPedido = [
          [
            ["idLavanderia", "<>", null],
            "and",
            ["idLavanderia", "=", LAVANDERIA.idLavanderia],
          ],
          "or",
          ["idLavanderia", "=", null],
        ];
        let filtroLavaderiaReparto = [
          ["idEstadoPedidoNavigation/codigo", "=", 1],
          "or",
          ["idEstadoPedidoNavigation/codigo", "=", 2],
          "or",
          [
            ["idEstadoPedidoNavigation/codigo", "=", 3],
            "and",
            [
              "tblReparto/any(x: x/idRepartoEstado eq 1 and x/idLavanderia eq " +
                +LAVANDERIA.idLavanderia +
                ")",
            ],
          ],
        ];
        let filtroTipoRepartoEntidad = [
          [
            "((idTipoProduccion eq null) and (idEntidadNavigation/idTipoRepartoEntidad eq 1)) or (idEntidadNavigation/idTipoRepartoEntidad eq 3)",
          ],
        ];

        if (!loadOptions.filter || loadOptions.filter.length === 0) {
          loadOptions.filter = [
            [filtroLavaderiaReparto],
            "and",
            [filtroLavanderiaPedido],
            "and",
            [filtroTipoRepartoEntidad],
          ];
        } else {
          loadOptions.filter = [
            [loadOptions.filter],
            "and",
            [filtroLavaderiaReparto],
            "and",
            [filtroLavanderiaPedido],
            "and",
            [filtroTipoRepartoEntidad],
          ];
        }
      },
      version: 4,
    }),
    select: [
      "idPedido, codigo, fecha, observaciones, idTipoProduccion, porcentaje, isCerrado",
    ],
    expand: [
      "idEntidadNavigation($select=idEntidad, denominacion, reparteRechazoRetiro),idEstadoPedidoNavigation($select=idEstadoPedido, codigo, denominacion, abreviatura), idTipoProduccionNavigation($select=idTipoProduccion,denominacion),idTipoPedidoNavigation($select=idTipoPedido, denominacion)",
    ],
    filter: ["isCerrado eq false"],
    map: function (dataItem) {
      return {
        idPedido: dataItem.idPedido,
        idEstadoPedido: dataItem.idEstadoPedidoNavigation.idEstadoPedido,
        codigoPedido: dataItem.codigo,
        fecha: dataItem.fecha,
        idEntidad: dataItem.idEntidadNavigation.idEntidad,
        idEntidadNavigation: dataItem.idEntidadNavigation,
        codigoEstado: dataItem.idEstadoPedidoNavigation.codigo,
        idEstadoPedidoNavigation: dataItem.idEstadoPedidoNavigation,
        abreviatura: dataItem.idEstadoPedidoNavigation.abreviatura,
        observaciones: dataItem.observaciones,
        reparteRechazoRetiro: dataItem.idEntidadNavigation.reparteRechazoRetiro,
        denoTipoPedido: dataItem.idTipoProduccionNavigation
          ? dataItem.idTipoProduccionNavigation.denominacion
          : dataItem.idTipoPedidoNavigation.denominacion,
        idTipoPedidoNavigation: dataItem.idTipoPedidoNavigation,
        idTipoProduccionNavigation: dataItem.idTipoProduccionNavigation,
        idTipoProduccion: dataItem.idTipoProduccion,
        pedidoProd: dataItem.idTipoProduccion != null ? 1 : 0,
        porcentaje: dataItem.porcentaje,
        isCerrado: dataItem.isCerrado,
        isCerradoFactEntidad: dataItem.isCerradoFactEntidad,
        enPreparacion:
          dataItem.tblReparto.length > 0
            ? dataItem.tblReparto[0].idRepartoEstado === 1
              ? 1
              : 0
            : 0,
        asignadoPedCliente: dataItem.tblReparto.length > 0 ? 1 : 0,
        tblReparto: dataItem.tblReparto,
      };
    },
  });

  var datasource_tipoPedido = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoPedido",
      key: "idTipoPedido",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idEntidad = null;
      },
      version: 4,
    }),
    select: ["idTipoPedido", "denominacion"],
  });
  datasource_tipoPedido.load();

  var datasource_repartos_Cierres = new DataSource({
    store: new CustomStore({
      key: "idReparto",
      load: function (loadOptions) {
        var deferred = $.Deferred();

        let pageIndex =
          loadOptions.skip == 0 ? 0 : loadOptions.skip / loadOptions.take;

        datasource_repartos.paginate(true);
        datasource_repartos.pageSize(loadOptions.take);
        datasource_repartos.sort(loadOptions.sort);
        datasource_repartos.requireTotalCount(true);
        datasource_repartos.searchValue(loadOptions.searchValue);
        datasource_repartos.searchOperation(loadOptions.searchOperation);
        datasource_repartos.filter(
          loadOptions.filter ? loadOptions.filter : null,
        );
        datasource_repartos.pageIndex(pageIndex);

        datasource_repartos.load().done(function (data) {
          fechasEntidadesVisibles = [];
          let repartosMapeados = data
            .filter((x) => x.fecha != null)
            .map((x) => {
              return {
                fecha: formatDate_noTime_parameter(x.fecha),
                idEntidad: x.idEntidad,
              };
            });
          fechasEntidadesVisibles = [...new Set(repartosMapeados)];

          datasource_tblCierreFactEntidad.load().done((fechasEntidadCierre) => {
            data.forEach((reparto) => {
              fechasEntidadCierre.forEach((item) => {
                let { fechaDesde, fechaHasta } = item;
                let rangoFechas =
                  fechaDesde && fechaHasta
                    ? getDaysInRange(fechaDesde, fechaHasta)
                        .filter((x) => x != null)
                        .map((x) => formatDate_noTime_parameter(x))
                    : [];

                if (!reparto.isCerradoFactEntidad)
                  reparto.isCerradoFactEntidad =
                    reparto.idEntidad == item.idEntidad &&
                    rangoFechas.filter(
                      (fecha) =>
                        fecha ==
                        (reparto.fecha
                          ? formatDate_noTime_parameter(reparto.fecha)
                          : null),
                    ).length > 0;
              });
            });
            deferred.resolve(data, {
              totalCount: datasource_repartos.totalCount(),
            });
          });
        });

        return deferred.promise();
      },
      update: (key, values) => {
        var deferred = $.Deferred();
        datasource_repartos
          .store()
          .update(key, values)
          .done(() => {
            deferred.resolve();
          });
        return deferred.promise();
      },
      remove: (key) => {
        var deferred = $.Deferred();
        datasource_repartos
          .store()
          .remove(key)
          .done(() => {
            deferred.resolve();
          });
        return deferred.promise();
      },
    }),
  });

  var datasource_repartos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblReparto",
      key: "idReparto",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      onLoading: function (loadOptions) {
        var filtroEstado = ["idRepartoEstado", "=", 1];
        if (!loadOptions.filter || loadOptions.filter.length === 0) {
          loadOptions.filter = filtroEstado;
        } else {
          loadOptions.filter = [[loadOptions.filter], "and", filtroEstado];
        }
        loadOptions.expand = [
          "tblEntidad($select = denominacion, reparteRechazoRetiro;$expand=tblModeloImpresion_reparto($select=report))",
          "tblPedido($select = idTipoProduccion)",
          "tblRutaNParteTransporte($count=true;$top=0)",
          "tblPrendaNReparto($count=true;$top=0)",
        ];
      },
      inserted: function (item) {
        notify({
          message: getTrad("aviso_C_RegistroInsertado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    select: [
      "idReparto",
      "codigo",
      "fecha",
      "idEntidad",
      "idPedido",
      "idPedidoCliente",
      "numCarros",
    ],
    postProcess: function (data) {
      return data.filter((x) => x["tblPrendaNReparto@odata.count"] > 0);
    },
  });

  var datasource_repartos_core = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblReparto",
      key: "idReparto",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var datasource_prendasPedido = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "repartos_spSelectPrendasNPedidoPendientes",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idPedido =
          typeof repartoSel === "undefined" || repartoSel === null
            ? pedidoSel.idPedido
            : repartoSel.idPedido;
        request.params.idReparto =
          typeof repartoSel !== "undefined" && repartoSel !== null
            ? repartoSel.idReparto
            : null;
      },
      version: 4,
    }),
  });

  var datasource_prendasReparto = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrendaNReparto",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idReparto = repartoSel.idReparto;
      },
      version: 4,
    }),
    expand: [
      "tblPrenda($select = codigoPrenda, denominacion, udsXBacReparto,udsXBacPedido; $expand = tblElemTransReparto,tblElemTransPedido)",
    ],
    postProcess: function (data) {
      $.each(data, function (index, item) {
        if (
          parseInt(item.tblPrenda.tblElemTransReparto.codigo) === 2 &&
          repartoSel.pedidoProd === false
        ) {
          item.cantidad = item.cantidad / item.tblPrenda.udsXBacReparto;
        }
      });
      return data;
    },
  });

  var datasource_prendasReparto_peticion = new DataSource({
    store: new CustomStore({
      load: function (loadOptions) {
        var deferred = $.Deferred();

        $.when(
          datasource_prendasPedido.reload(),
          datasource_prendasReparto.reload(),
        ).done(function () {
          var prendasPedido = datasource_prendasPedido.items();
          var prendasReparto = datasource_prendasReparto.items();

          var datos = [];
          $.each(prendasReparto, function (index, prendaRep) {
            var prenda = $.extend(true, {}, prendaRep);
            var prendaPedido = $.grep(prendasPedido, function (prendaPed) {
              return prendaPed.idPrenda === prenda.idPrenda;
            });
            prenda.peticion =
              prendaPedido.length > 0 ? prendaPedido[0].peticion : 0;
            datos.push(prenda);
          });
          deferred.resolve(datos);
        });

        return deferred.promise();
      },
    }),
  });

  var context_tblPrendaNReparto = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblPrendaNReparto",
    entities: {
      OverrideMasivo: {},
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };

      request.params.idReparto = repartoSel.idReparto;
      request.payload = tratarPrendasReparto();
    },
  });

  var datasource_prendasPedidoDesgome = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectPrendasNuevoPedido",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idEntidad = $("#popup_selCompaEnti_lista_entidades")
          .dxList("instance")
          .option("selectedItemKeys")[0];
        request.params.idPedido = null;
      },
      version: 4,
    }),
  });

  var datasource_compañias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    postProcess: function (data) {
      if (datasource_compañias.items().length === 0) {
        data.splice(0, 0, { idCompañia: 0, denominacion: "TODAS" });
      }
      return data;
    },
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
  });

  var datasource_prendasProdPedido = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectPrendaNProd",
      key: "idPrendaNProduccion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method !== "get") {
          request.url = request.url.replace(
            "spSelectPrendaNProd",
            "tblPrendaNProduccion",
          );
        } else {
          var gridPrendasSel = $("#dgvPrendasPedidoProd_agrupado").dxDataGrid(
            "instance",
          );
          var idPrendaSel = gridPrendasSel.option().selectedRowKeys[0];
          request.params.idPrenda = idPrendaSel
            ? gridPrendasSel.option().selectedRowKeys[0]
            : -1;
          request.params.idEntidad = pedidoSel.idEntidad;
          request.params.idCompañia = null;
          request.params.idLavanderia = LAVANDERIA.idLavanderia;
          request.params.fecha = formatDate_parameter(pedidoSel.fecha);
        }
      },
      version: 4,
    }),
  });

  var datasource_prendasProdPedido_agrupado = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectPrendaNProd_agrupado",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var idTipoProduccion = null;
        if (
          typeof $("#sbTipoProduccion").dxSelectBox("instance") !== "undefined"
        ) {
          if ($("#sbTipoProduccion").dxSelectBox("instance").option("value")) {
            idTipoProduccion = $("#sbTipoProduccion")
              .dxSelectBox("instance")
              .option("value");
          }
        }
        request.params.idCompañia = null;
        request.params.idEntidad = pedidoSel.idEntidad;
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.fecha = formatDate_parameter(pedidoSel.fecha);
        request.params.idTipoProduccion = idTipoProduccion;
        request.params.idTurno = null;
      },
      version: 4,
    }),
  });

  var datasource_rechazoNProd = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectRechazoNProduccion",
      key: "idTipoRechazo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method !== "get") {
          request.url = request.url.replace(
            "spSelectRechazoNProduccion",
            "tblPrendaNProduccion",
          );
        } else {
          request.params.idPrendaNProduccion = prendasPedidoProdSel
            ? prendasPedidoProdSel.idPrendaNProduccion
            : null;
        }
      },
      version: 4,
    }),
  });

  //AÑADIR ITEM A DATASOURCE(ARRAY)

  var datasource_entidades = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
        request.params.fechaEntidadActiva = null;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion"],
  });

  var datasource_tipoProduccion = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoProduccion",
      key: "idTipoProduccion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idTipoProduccion", "denominacion"],
  });
  datasource_tipoProduccion.load();

  var datasource_prendasProdPedido_agrupado_añadir = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectPrendasNuevoPedido",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idEntidad = pedidoSel.idEntidad;
        request.params.idPedido = null;
      },
      version: 4,
    }),
    select: [
      "idPrenda",
      "codigoPrenda",
      "denoPrenda",
      "codigoElemTransPedido",
      "denoElemTransPedido",
      "udsXBacPedido",
      "cantidad",
      "codigoHexadecimal",
      "isEntidad",
    ],
    paginate: false,
    postProcess: function (data) {
      if (data.length > 0) {
        var grid = $("#dgvPrendasPedidoProd_agrupado").dxDataGrid("instance");
        var procesedData = data.filter(function (item) {
          return (
            item.isEntidad === 1 && grid.getRowIndexByKey(item.idPrenda) === -1
          );
        });

        $.each(procesedData, function (index, element) {
          element.producido = 0;
          element.rechazo = 0;
          element.retiro = 0;
        });
        return procesedData;
      }
    },
  });

  var datasource_turnos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTurno",
      key: "idTurno",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idTurno", "denominacion"],
  });

  var datasource_produccion = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblProduccion",
      key: "idProduccion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });
  //#endregion

  //#region Cierre Facturación Entidad
  function deshabilitarFechasFacturacion(idEntidad, fechaDesde, dxDateBox) {
    dxDateBox_disabledDates = [];
    return new Promise((resolve, reject) => {
      if (idEntidad) {
        getCierresFacturacion(idEntidad, null, null)
          .then((data) => {
            //region deshabilitar días
            let disabledDates = [];
            data.forEach((item) => {
              disabledDates.push(
                ...getDaysInRange(
                  new Date(item.fechaDesde),
                  new Date(item.fechaHasta),
                ),
              );
            });
            dxDateBox_disabledDates = [...disabledDates];

            if (dxDateBox) {
              let isFechaActualDisabled =
                dxDateBox_disabledDates
                  .map((x) => formatDate_noTime_parameter(x))
                  .filter((x) => x == formatDate_noTime_parameter(fechaDesde))
                  .length > 0;
              // Aplicar fechaFiltrada
              dxDateBox.option({
                value: isFechaActualDisabled ? null : fechaDesde,
                disabledDates: dxDateBox_disabledDates,
              });
              // //#endregion
            }
            resolve(data);
          })
          .catch(reject);
      } else {
        if (dxDateBox) {
          dxDateBox.option({
            value: fechaDesde,
            disabledDates: [],
          });
        }
        resolve();
      }
    });
  }
  //#endregion

  if (typeof $("#Repartos #dxContainer").data("dxBox") !== "undefined") {
    $("#Repartos #dxContainer").dxBox("instance").dispose();
  }

  $("#dxTooltip_Repartos").dxTooltip({
    showEvent: "mouseenter",
    hideEvent: "mouseleave",
  });

  $("#Repartos #dxContainer").dxBox({
    direction: "row",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
    width: "100%",
    items: [
      {
        ratio: 3,
        template: function (e, index, item) {
          item.css("padding-right", "20px");
          item.append(
            $("<div/>").dxBox({
              direction: "col",
              align: "space-around",
              crossAlign: "stretch",
              height: "100%",
              width: "100%",
              items: [
                {
                  ratio: 1,
                  template: function (e, index, item) {
                    item.append(
                      $("<div id='dgvPedidos' />").dxDataGrid({
                        //Datos
                        dataSource: datasource_pedidos_Cierres,
                        remoteOperations: true,
                        //Propiedades
                        columnsAutoWidth: true,
                        height: "100%",
                        headerFilter: {
                          visible: true,
                        },
                        filterRow: {
                          visible: true,
                          applyFilter: "auto",
                        },
                        searchPanel: {
                          visible: true,
                          width: 240,
                          placeholder: getTrad("busqueda"),
                        },
                        paging: {
                          pageSize: 50,
                        },
                        pager: {
                          showPageSizeSelector: true,
                          allowedPageSizes: [20, 50, 100],
                          showInfo: true,
                        },
                        columns: [
                          {
                            caption: " ",
                            width: "30px",
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (cellElement, cellInfo) {
                              cellElement.on("click", function () {
                                if (cellInfo.data.isCerradoFactEntidad) return;
                                if (
                                  cellInfo.data.idEstadoPedidoNavigation
                                    .codigo !== 3
                                ) {
                                  pedidoSel = cellInfo.data;
                                  $("#popup_prendasPedido")
                                    .dxPopup("instance")
                                    .show();

                                  //Limpiamos los campos del popup de datos reparto.
                                  if (
                                    typeof $("#dxCalendar_fechaReparto")[0] !==
                                    "undefined"
                                  ) {
                                    $("#dxCalendar_fechaReparto")
                                      .dxCalendar("instance")
                                      .option("value", new Date());
                                    $("#dxTextArea_observacionesReparto")
                                      .dxTextArea("instance")
                                      .option("value", "");
                                    if (
                                      !pedidoSel ||
                                      (pedidoSel && pedidoSel.pedidoProd === 1)
                                    ) {
                                      if (
                                        typeof $(
                                          "#dxDropDownBox_pedidoCliente",
                                        )[0] !== "undefined"
                                      ) {
                                        $("#dxDropDownBox_pedidoCliente")
                                          .dxDropDownBox("instance")
                                          .option("value", null);
                                        $("#dxDataGrid_PedidoClienteSel")
                                          .dxDataGrid("instance")
                                          .refresh(true);
                                      }
                                      //Hacemos visible la selección de un pedido de cliente
                                      $(
                                        "#popup_datosReparto_contenedorPedidoCliente",
                                      ).attr("style", "padding-top: 15px");
                                    } else {
                                      //Ocultamos la selección de un pedido de cliente
                                      $(
                                        "#popup_datosReparto_contenedorPedidoCliente",
                                      ).attr("style", "display:none");
                                    }
                                  }

                                  var grid =
                                    $("#dgvPrendasPedido").dxDataGrid(
                                      "instance",
                                    );
                                  grid.repaint();
                                  grid.cancelEditData();

                                  // CAMBIO GRID PRENDASPEDIDO SI ES O NO DESGOME
                                  grid.option(
                                    "dataSource",
                                    datasource_prendasPedido,
                                  );
                                  $("#dgvPrendasPedido").dxDataGrid(
                                    "columnOption",
                                    "peticion",
                                    "visible",
                                    true,
                                  );
                                  $("#dgvPrendasPedido").dxDataGrid(
                                    "columnOption",
                                    "rechazo",
                                    "visible",
                                    true,
                                  );
                                  $("#dgvPrendasPedido").dxDataGrid(
                                    "columnOption",
                                    "retiro",
                                    "visible",
                                    true,
                                  );
                                  $("#dgvPrendasPedido").dxDataGrid(
                                    "columnOption",
                                    "codigoElemTransReparto",
                                    "visible",
                                    true,
                                  );
                                  $("#dgvPrendasPedido").dxDataGrid(
                                    "columnOption",
                                    "codigoElemTransPedido",
                                    "visible",
                                    true,
                                  );
                                  $("#dgvPrendasPedido").dxDataGrid(
                                    "columnOption",
                                    "codigoElemTransPedido",
                                    "visibleIndex",
                                    6,
                                  );

                                  $("#btnActualizar")
                                    .dxButton("instance")
                                    .option("visible", true);
                                  $("#btnMarcarTodo")
                                    .dxButton("instance")
                                    .option("visible", true);

                                  $("#dgvPrendasPedido").dxDataGrid(
                                    "columnOption",
                                    "rechazo",
                                    "visible",
                                    pedidoSel.pedidoProd &&
                                      (pedidoSel.reparteRechazoRetiro === 0 ||
                                        pedidoSel.reparteRechazoRetiro === 1),
                                  );
                                  $("#dgvPrendasPedido").dxDataGrid(
                                    "columnOption",
                                    "retiro",
                                    "visible",
                                    pedidoSel.pedidoProd &&
                                      (pedidoSel.reparteRechazoRetiro === 0 ||
                                        pedidoSel.reparteRechazoRetiro === 2),
                                  );
                                } else {
                                  notify({
                                    message: getTrad(
                                      "aviso_I_PedidoSinPrendas",
                                    ),
                                    type: "error",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                  });
                                }
                              });
                              let tooltip = $("#dxTooltip_Repartos").dxTooltip(
                                "instance",
                              );

                              let editElement = $("<div/>")
                                .addClass(
                                  "container_spanCentrado " +
                                    (cellInfo.data.isCerradoFactEntidad &&
                                      "pe-none"),
                                )
                                .append(
                                  svg_edit
                                    .clone()
                                    .css(
                                      "opacity",
                                      cellInfo.data.isCerradoFactEntidad
                                        ? 0.5
                                        : 1,
                                    )
                                    .css("height", "25px")
                                    .css("width", "25px")
                                    .css("cursor", "pointer"),
                                );

                              cellElement.addClass(
                                "pe-auto id_modify" + cellInfo.data.idPedido,
                              );
                              cellElement
                                .on("mouseover", function () {
                                  if (
                                    cellInfo.data.isCerradoFactEntidad &&
                                    tooltip
                                  ) {
                                    tooltip.option({
                                      target:
                                        ".id_modify" + cellInfo.data.idPedido,
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("factBloqueadaEntiFecha"),
                                        );
                                      },
                                    });
                                    tooltip.show();
                                  }
                                })
                                .on("mouseout", function () {
                                  if (tooltip) tooltip.hide();
                                });

                              if (
                                !cellInfo.data.isCerradoFactEntidad &&
                                tooltip
                              ) {
                                tooltip.option({
                                  showEvent: null,
                                  hideEvent: null,
                                });
                              }

                              cellElement.append(editElement);
                            },
                            allowSearch: false,
                          },
                          {
                            caption: " ",
                            width: "30px",
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (container, options) {
                              $("<div />")
                                .attr("class", "icon_Impresora")
                                .css("font-size", "25px")
                                .css("cursor", "pointer")
                                .on("click", function () {
                                  //REPORT
                                  var params = {
                                    idPedido: options.data.idPedido,
                                  };

                                  COMPONENT.setReport({
                                    denominacion: "albaran_pedidos",
                                    parameterEvent: CustomizeParameterEditors,
                                  });
                                  setTimeout(() => {
                                    COMPONENT.setParams(params);
                                  }, 1000);
                                })
                                .appendTo(container);
                            },
                          },
                          {
                            dataField: "fecha",
                            caption: getTrad("fecha"),
                            dataType: "date",
                            format: "dd/MM/yyyy",
                            width: "125px",
                            sortOrder: "desc",
                            editorOptions: {
                              showClearButton: true,
                            },
                            allowHeaderFiltering: false,
                          },
                          {
                            dataField: "idEntidadNavigation.denominacion",
                            caption: getTrad("cliente"),
                            minWidth: 150,
                            editorOptions: {
                              showClearButton: true,
                            },
                            calculateFilterExpression(value, operation) {
                              return [
                                "tolower(idEntidadNavigation.denominacion)",
                                "contains",
                                value,
                              ];
                            },
                          },
                          {
                            dataField: "idEstadoPedido",
                            visible: false,
                          },
                          {
                            dataField: "isCerrado",
                            visible: false,
                          },
                          {
                            dataField: "idEstadoPedido",
                            caption: getTrad("estado"),
                            alignment: "left",
                            //minWidth: 60,
                            width: "12%",
                            cellTemplate: function (cellElement, cellInfo) {
                              var container = $("<div />").css(
                                "position",
                                "relative",
                              );

                              if (
                                cellInfo.data.enPreparacion === 1 &&
                                cellInfo.data.idEstadoPedidoNavigation
                                  .codigo !== 3
                              ) {
                                container.append(
                                  svg_warning
                                    .clone()
                                    .css("height", "25px")
                                    .css("width", "25px")
                                    .css("cursor", "pointer")
                                    .css("position", "absolute")
                                    .css("right", "0px")
                                    .css("top", "-3px"),
                                );

                                tooltipControl_creacion(
                                  container,
                                  getTrad("alerta_PedidoContienePrendas"),
                                );
                              }

                              var stringCompleto =
                                cellInfo.data.enPreparacion === 0
                                  ? cellInfo.data.idEstadoPedidoNavigation
                                      .denominacion
                                  : getTrad("enPreparacion");
                              var stringAbr =
                                cellInfo.data.enPreparacion === 0
                                  ? cellInfo.data.abrEstado
                                  : getTrad("enPreparacion_abr");

                              var textCompleto = $(
                                "<div class='completo' data-internalid='100'> " +
                                  stringCompleto +
                                  " </div>",
                              );
                              var textAbr = $(
                                "<div class='abreviado' data-internalid='100'>" +
                                  stringAbr +
                                  " </div>",
                              );

                              if (cellElement.width() > 80) {
                                textAbr.attr("style", "display: none");
                              } else {
                                textCompleto.attr("style", "display: none");
                              }

                              textCompleto.appendTo(container);
                              textAbr.appendTo(container);

                              cellElement.append(container);
                            },
                            editorOptions: {
                              showClearButton: true,
                            },
                            headerFilter: {
                              allowSearch: true,
                              dataSource: [
                                {
                                  text: getTrad("pendientes"),
                                  value: 1,
                                },
                                {
                                  text: getTrad("parcialmenteServido"),
                                  value: 2,
                                },
                                {
                                  text: getTrad("enPreparacion"),
                                  value: 3,
                                },
                              ],
                            },
                            calculateFilterExpression(value) {
                              if (value == 1)
                                return ["idEstadoPedido", "=", value];

                              if (value == 2) {
                                var filtroReparto =
                                  "((tblReparto/all(x: (x/idRepartoEstado ne 1))))";
                                return [
                                  [
                                    [filtroReparto],
                                    "or",
                                    ["tblReparto/$count", "=", 1],
                                  ],
                                  "and",
                                  ["idEstadoPedidoNavigation.codigo", "=", 2],
                                ]; // tblReparto vacío lo cuenta como 1, un item lo cuenta como 2, etc...
                              }

                              if (value == 3) {
                                var filtroReparto =
                                  "((tblReparto/any(x:x/idLavanderia eq " +
                                  LAVANDERIA.idLavanderia +
                                  " and x/idRepartoEstado eq 1)))";
                                return [
                                  filtroReparto +
                                    " or ((idEstadoPedidoNavigation.codigo eq 3) and " +
                                    filtroReparto +
                                    ") ",
                                ];
                              }
                            },
                          },
                          {
                            dataField: "idTipoProduccion",
                            caption: getTrad("tipo"),
                            width: "65px",
                            allowFiltering: false,
                            allowHeaderFiltering: true,
                            headerFilter: {
                              allowSearch: true,
                              dataSource: [
                                {
                                  text: getTrad("pedido"),
                                  value: 0,
                                },
                                {
                                  text: getTrad("produccion"),
                                  value: 1,
                                },
                              ],
                            },
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (container, options) {
                              var img;
                              var htmlTooltip;
                              if (options.data.idTipoProduccion == null) {
                                // PEDIDO
                                img = "icon_Carro";
                                htmlTooltip =
                                  "<p>" + getTrad("pedido") + "</p>";
                              } else if (
                                options.data.idTipoProduccion != null
                              ) {
                                // PRODUCCIÓN
                                img = "icon_Lavadora";
                                htmlTooltip =
                                  "<p>" + getTrad("produccion") + "</p>";
                              }

                              container.append(
                                $("<div />")
                                  .attr("class", img)
                                  .css("font-size", "25px")
                                  .appendTo(container),
                              );

                              tooltipControl_creacion(container, htmlTooltip);
                            },
                            calculateFilterExpression(value) {
                              let operation = value === 0 ? "=" : "<>";
                              return ["idTipoProduccion", operation, null];
                            },
                          },
                          {
                            dataField:
                              "idTipoProduccionNavigation.denominacion",
                            caption: getTrad("tipoPedido"),
                            width: "16%",
                            editorOptions: {
                              showClearButton: true,
                            },
                            allowSearch: true,
                            headerFilter: {
                              allowSearch: true,
                              dataSource: function (options) {
                                options.dataSource.postProcess = function (
                                  results,
                                ) {
                                  let items = $.map(
                                    $.merge(
                                      datasource_tipoProduccion.items(),
                                      datasource_tipoPedido.items(),
                                    ),
                                    function (item) {
                                      return {
                                        text: item.denominacion,
                                        value: item.denominacion,
                                      };
                                    },
                                  );
                                  return items;
                                };
                              },
                            },
                            calculateCellValue: function (e) {
                              return e.idTipoProduccionNavigation != null
                                ? e.idTipoProduccionNavigation.denominacion
                                : e.idTipoPedidoNavigation
                                  ? e.idTipoPedidoNavigation.denominacion
                                  : "";
                            },
                            calculateFilterExpression(
                              value,
                              selectedFilterOperations,
                            ) {
                              return [
                                [
                                  ["idTipoProduccionNavigation", "<>", null],
                                  "and",
                                  [
                                    "idTipoProduccionNavigation.denominacion",
                                    "contains",
                                    value,
                                  ],
                                ],
                                "or",
                                [
                                  ["idTipoProduccionNavigation", "=", null],
                                  "and",
                                  [
                                    "idTipoPedidoNavigation.denominacion",
                                    "contains",
                                    value,
                                  ],
                                ],
                              ]; //,"or",["idTipoPedidoNavigation.denominacion", "contains", value]
                            },
                          },
                          {
                            dataField: "porcentaje",
                            caption: getTrad("porcentaje"),
                            alignment: "center",
                            width: "12%",
                            //hidingPriority: 0,
                            allowHeaderFiltering: false,
                            allowFiltering: false,
                            cssClass: "p-0",
                            cellTemplate: function (container, options) {
                              $("<div />")
                                .attr("style", "padding-left: 10px")
                                .dxProgressBar({
                                  min: 0,
                                  max: 1,
                                  value: options.data.porcentaje,
                                  showStatus: true,
                                  statusFormat: function (ratio, value) {
                                    return Math.floor(ratio * 100) + "%";
                                  },
                                })
                                .appendTo(container);
                            },
                          },
                          {
                            caption: " ",
                            width: "50px",
                            alignment: "center",
                            cssClass: "p-0",
                            allowEditing: false,
                            allowHeaderFiltering: false,
                            allowUpdating: false,
                            cellTemplate: function (cellElement, cellInfo) {
                              cellElement.on("click", function () {
                                if (cellInfo.data.isCerradoFactEntidad) return;
                                pedidoSel = cellInfo.data;
                                $("#popup_deletePedido")
                                  .dxPopup("instance")
                                  .show();
                                $("#popup_deletePedido_dxButton_eliminar").attr(
                                  "style",
                                  cellInfo.data.idEstadoPedido !== 1
                                    ? "display:none"
                                    : "display:block",
                                ); //Mostrar btn Eliminar solo para pedidos pendientes
                              });

                              let tooltip = $("#dxTooltip_Repartos").dxTooltip(
                                "instance",
                              );

                              let editElement = $("<div/>")
                                .addClass(
                                  "container_spanCentrado " +
                                    (cellInfo.data.isCerradoFactEntidad &&
                                      "pe-none"),
                                )
                                .append(
                                  svg_remove
                                    .clone()
                                    .css(
                                      "opacity",
                                      cellInfo.data.isCerradoFactEntidad
                                        ? 0.5
                                        : 1,
                                    )
                                    .css("height", "25px")
                                    .css("width", "25px")
                                    .css("cursor", "pointer"),
                                );

                              cellElement.addClass(
                                "pe-auto id_remove_" + cellInfo.data.idPedido,
                              );
                              cellElement
                                .on("mouseover", function () {
                                  if (cellInfo.data.isCerradoFactEntidad) {
                                    tooltip.option({
                                      target:
                                        ".id_remove_" + cellInfo.data.idPedido,
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("factBloqueadaEntiFecha"),
                                        );
                                      },
                                    });
                                    tooltip.show();
                                  }
                                })
                                .on("mouseout", function () {
                                  tooltip.hide();
                                });

                              if (!cellInfo.data.isCerradoFactEntidad) {
                                if (tooltip) {
                                  tooltip.option({
                                    showEvent: null,
                                    hideEvent: null,
                                  });
                                }
                              }

                              cellElement.append(editElement);
                            },
                          },
                        ],
                        //Eventos
                        onRowRemoved: function () {
                          notify({
                            message: getTrad("aviso_C_RegistroEliminado"),
                            type: "success",
                            displayTime: "1500",
                            closeOnClick: true,
                          });
                        },
                        onRowUpdating: function (cellInfo) {
                          cellInfo.newData = patchRequestHandler(
                            cellInfo.newData,
                          );
                        },
                        onToolbarPreparing: function (e) {
                          e.toolbarOptions.items.unshift(
                            {
                              location: "after",
                              widget: "dxButton",
                              showText: "inMenu",
                              locateInMenu: "auto",
                              options: {
                                text: getTrad("limpiarFiltro"),
                                icon: " icon_EliminarFiltros",
                                onClick: function (e) {
                                  let dgvPedidos =
                                    $("#dgvPedidos").dxDataGrid("instance");
                                  dgvPedidos.clearFilter("row");
                                  dgvPedidos.clearFilter("header");
                                  dgvPedidos.clearFilter("search");
                                  notify({
                                    message: getTrad(
                                      "aviso_C_FiltroRestaurado",
                                    ),
                                    type: "success",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                  });
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              showText: "inMenu",
                              options: {
                                icon: "refresh",
                                text: getTrad("actualizar"),
                                onClick: function () {
                                  $("#dgvPedidos")
                                    .dxDataGrid("instance")
                                    .refresh();
                                },
                              },
                            },
                            {
                              location: "before",
                              widget: "dxButton",
                              toolbar: "bottom",
                              options: {
                                text: getTrad("repartirDesgome"),
                                icon: "check",
                                type: "normal",
                                onClick: function (options) {
                                  //Limpiamos los campos del popup de datos reparto.
                                  if (
                                    typeof $("#dxCalendar_fechaReparto")[0] !==
                                    "undefined"
                                  ) {
                                    $("#dxCalendar_fechaReparto")
                                      .dxCalendar("instance")
                                      .option("value", new Date());
                                    $("#dxTextArea_observacionesReparto")
                                      .dxTextArea("instance")
                                      .option("value", "");
                                    if (
                                      typeof $(
                                        "#dxDropDownBox_pedidoCliente",
                                      )[0] !== "undefined"
                                    ) {
                                      $("#dxDropDownBox_pedidoCliente")
                                        .dxDropDownBox("instance")
                                        .option("value", null);
                                    }
                                  }

                                  pedidoSel = null;
                                  $("#popup_selCompaEnti")
                                    .dxPopup("instance")
                                    .show();
                                  $("#popup_selCompaEnti_lista_compañias")
                                    .dxList("instance")
                                    .option("selectItem", 0);
                                },
                              },
                            },
                          );
                        },
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                        rowAlternationEnabled: true,
                      }),
                    );
                  },
                },
              ],
            }),
          );
        },
      },
      {
        ratio: 2,
        template: function (e, index, item) {
          item.append(
            $("<div/>").dxBox({
              direction: "col",
              align: "space-around",
              crossAlign: "stretch",
              height: "100%",
              width: "100%",
              items: [
                {
                  ratio: 1,
                  template: function (e, index, item) {
                    item.append(
                      $("<div id='dgvRepartos' />").dxDataGrid({
                        //Datos
                        dataSource: datasource_repartos_Cierres,
                        remoteOperations: true,
                        //Propiedades
                        columnsAutoWidth: true,
                        height: "100%",
                        width: "100%",
                        headerFilter: {
                          visible: true,
                        },
                        filterRow: {
                          visible: true,
                          applyFilter: "auto",
                        },
                        searchPanel: {
                          visible: true,
                          width: 240,
                          placeholder: getTrad("busqueda"),
                        },
                        paging: {
                          pageSize: 50,
                        },
                        pager: {
                          showPageSizeSelector: true,
                          allowedPageSizes: [20, 50, 100],
                          showInfo: true,
                        },
                        repaintChangesOnly: true,
                        editing: {
                          refreshMode: "repaint",
                          mode: "cell",
                          allowUpdating: true,
                        },
                        selection: {
                          mode: "multiple",
                          selectAllMode: "allPages",
                          showCheckBoxesMode: "always",
                        },
                        keyboardNavigation: {
                          enterKeyAction: "moveFocus",
                          enterKeyDirection: "row",
                          editOnKeyPress: true,
                        },
                        columns: [
                          {
                            caption: " ",
                            width: "30px",
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (cellElement, cellInfo) {
                              cellElement.on("click", function () {
                                if (cellInfo.data.isCerradoFactEntidad) return;
                                repartoSel = cellInfo.data;

                                repartoSel.pedidoProd =
                                  repartoSel.idPedido === null ||
                                  repartoSel.tblPedido.idTipoProduccion !==
                                    null;

                                datasource_prendasReparto_peticion
                                  .reload()
                                  .done(function (prendas) {
                                    $("#popup_prendasReparto")
                                      .dxPopup("instance")
                                      .show();

                                    var grid =
                                      $("#dgvPrendasReparto").dxDataGrid(
                                        "instance",
                                      );
                                    grid.beginUpdate();
                                    grid.cancelEditData();
                                    grid.option("dataSource", prendas);

                                    if (
                                      typeof $(
                                        "#dxDropDownBox_dgvPrendasReparto_pedidoCliente",
                                      )[0] !== "undefined"
                                    ) {
                                      var dxDropDownBox_dgvPrendasReparto_pedidoCliente =
                                        $(
                                          "#dxDropDownBox_dgvPrendasReparto_pedidoCliente",
                                        ).dxDropDownBox("instance");
                                      dxDropDownBox_dgvPrendasReparto_pedidoCliente
                                        .getDataSource()
                                        .reload()
                                        .done(function () {
                                          dxDropDownBox_dgvPrendasReparto_pedidoCliente.option(
                                            "value",
                                            repartoSel.idPedidoCliente,
                                          );
                                        });
                                    }

                                    grid.columnOption(
                                      "rechazo",
                                      "visible",
                                      repartoSel.pedidoProd &&
                                        (repartoSel.tblEntidad
                                          .reparteRechazoRetiro === 0 ||
                                          repartoSel.tblEntidad
                                            .reparteRechazoRetiro === 1),
                                    );
                                    grid.columnOption(
                                      "retiro",
                                      "visible",
                                      repartoSel.pedidoProd &&
                                        (repartoSel.tblEntidad
                                          .reparteRechazoRetiro === 0 ||
                                          repartoSel.tblEntidad
                                            .reparteRechazoRetiro === 2),
                                    );

                                    grid.endUpdate();
                                  });
                              });

                              let tooltip = $("#dxTooltip_Repartos").dxTooltip(
                                "instance",
                              );

                              let editElement = $("<div/>")
                                .addClass(
                                  "container_spanCentrado " +
                                    (cellInfo.data.isCerradoFactEntidad &&
                                      "pe-none"),
                                )
                                .append(
                                  svg_edit
                                    .clone()
                                    .css(
                                      "opacity",
                                      cellInfo.data.isCerradoFactEntidad
                                        ? 0.5
                                        : 1,
                                    )
                                    .css("height", "25px")
                                    .css("width", "25px")
                                    .css("cursor", "pointer"),
                                );

                              cellElement.addClass(
                                "pe-auto id_modify_" + cellInfo.data.idReparto,
                              );
                              cellElement
                                .on("mouseover", function () {
                                  if (cellInfo.data.isCerradoFactEntidad) {
                                    tooltip.option({
                                      target:
                                        ".id_modify_" + cellInfo.data.idReparto,
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("factBloqueadaEntiFecha"),
                                        );
                                      },
                                    });
                                    tooltip.show();
                                  }
                                })
                                .on("mouseout", function () {
                                  tooltip.hide();
                                });

                              if (!cellInfo.data.isCerradoFactEntidad) {
                                tooltip.option({
                                  showEvent: null,
                                  hideEvent: null,
                                });
                              }

                              cellElement.append(editElement);
                            },
                            allowSearch: false,
                          },
                          {
                            caption: " ",
                            width: "30px",
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (container, options) {
                              $("<div />")
                                .attr("class", "icon_Impresora")
                                .css("font-size", "25px")
                                .css("cursor", "pointer")
                                .on("click", function () {
                                  //REPORT
                                  tipoReportSel =
                                    options.data.tblEntidad
                                      .tblModeloImpresion_reparto.report;
                                  var report = {
                                    denominacion: tipoReportSel,
                                    parameterEvent: CustomizeParameterEditors,
                                  };
                                  var params = {
                                    idReparto: options.data.idReparto,
                                  };

                                  COMPONENT.setReport(report);
                                  setTimeout(() => {
                                    COMPONENT.setParams(params);
                                  }, 1000);
                                })
                                .appendTo(container);
                            },
                            allowSearch: false,
                          },
                          {
                            dataField: "codigo",
                            caption: getTrad("codigo"),
                            width: "80px",
                            allowEditing: false,
                            allowHeaderFiltering: false,
                          },
                          {
                            dataField: "fecha",
                            caption: getTrad("fecha"),
                            dataType: "date",
                            format: "dd/MM/yyyy",
                            width: "125px",
                            sortOrder: "desc",
                            cssClass: "dx-Cell_Editable",
                            allowHeaderFiltering: false,
                          },
                          {
                            dataField: "tblEntidad.denominacion",
                            caption: getTrad("cliente"),
                            minWidth: "125px",
                            allowEditing: false,
                            headerFilter: {
                              allowSearch: true,
                            },
                          },
                          {
                            dataField: "idRepartoEstado",
                            visible: false,
                            allowSearch: false,
                          },
                          {
                            caption: " ",
                            width: "50px",
                            alignment: "center",
                            cssClass: "p-0",
                            allowEditing: false,
                            allowHeaderFiltering: false,
                            allowUpdating: false,
                            cellTemplate: function (cellElement, cellInfo) {
                              cellElement.on("click", function () {
                                if (USER.idUsuario == 281) {
                                  fetch(
                                    connectionConstants.WEB_API_CORE_ODATA_URL +
                                      "AppLogisticaExterna/setLogError?text=BORRAR_REPARTO=" +
                                      cellInfo.data.idReparto,
                                    { method: "POST" },
                                  );
                                  return;
                                }
                                if (cellInfo.data.isCerradoFactEntidad) return;
                                if (
                                  cellInfo.data[
                                    "tblRutaNParteTransporte@odata.count"
                                  ] > 0
                                ) {
                                  dxMensajePregunta(
                                    getTrad("alert_repartoConParteTrans"),
                                    [
                                      // ACEPTAR
                                      [
                                        getTrad("aceptar"),
                                        function () {},
                                        "normal",
                                        "btnAceptar",
                                      ],
                                    ],
                                  );
                                } else
                                  cellInfo.component.deleteRow(
                                    cellInfo.rowIndex,
                                  );
                              });

                              let tooltip = $("#dxTooltip_Repartos").dxTooltip(
                                "instance",
                              );

                              let editElement = $("<div/>")
                                .addClass(
                                  "container_spanCentrado " +
                                    (cellInfo.data.isCerradoFactEntidad &&
                                      "pe-none"),
                                )
                                .append(
                                  svg_remove
                                    .clone()
                                    .css(
                                      "opacity",
                                      cellInfo.data.isCerradoFactEntidad
                                        ? 0.5
                                        : 1,
                                    )
                                    .css("height", "25px")
                                    .css("width", "25px")
                                    .css("cursor", "pointer"),
                                );

                              cellElement.addClass(
                                "pe-auto id_remove_" + cellInfo.data.idReparto,
                              );
                              cellElement
                                .on("mouseover", function () {
                                  if (cellInfo.data.isCerradoFactEntidad) {
                                    tooltip.option({
                                      target:
                                        ".id_remove_" + cellInfo.data.idReparto,
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("factBloqueadaEntiFecha"),
                                        );
                                      },
                                    });
                                    tooltip.show();
                                  }
                                })
                                .on("mouseout", function () {
                                  tooltip.hide();
                                });

                              if (!cellInfo.data.isCerradoFactEntidad) {
                                tooltip.option({
                                  showEvent: null,
                                  hideEvent: null,
                                });
                              }

                              cellElement.append(editElement);
                            },
                            allowSearch: false,
                          },
                          {
                            dataField: "idPedidoCliente",
                            visible: false,
                            allowSearch: false,
                          },
                          {
                            dataField: "numCarros",
                            visible: false,
                            allowSearch: false,
                          },
                        ],
                        //Eventos
                        onCellPrepared(e) {
                          if (
                            e.rowType === "data" &&
                            e.column.command === "select" &&
                            e.data.isCerradoFactEntidad
                          ) {
                            let tooltip = $("#dxTooltip_Repartos").dxTooltip(
                              "instance",
                            );

                            e.cellElement
                              .find(".dx-select-checkbox")
                              .addClass(
                                "pe-auto id_selection_" + e.data.idReparto,
                              )
                              .on("mouseover", function () {
                                if (e.data.isCerradoFactEntidad) {
                                  tooltip.option({
                                    target: ".id_selection_" + e.data.idReparto,
                                    contentTemplate: function (contentElement) {
                                      contentElement.html(
                                        getTrad("factBloqueadaEntiFecha"),
                                      );
                                    },
                                  });
                                  tooltip.show();
                                }
                              })
                              .on("mouseout", function () {
                                tooltip.hide();
                              });

                            if (!e.data.isCerradoFactEntidad) {
                              tooltip.option({
                                showEvent: null,
                                hideEvent: null,
                              });
                            }

                            var instance = e.cellElement
                              .find(".dx-select-checkbox")
                              .dxCheckBox("instance");
                            instance.option("disabled", true);
                            e.cellElement.off();
                          }
                        },
                        onEditorPreparing: function (e) {
                          var dataGrid = e.component;
                          if (
                            e.parentType == "dataRow" &&
                            e.dataField == "fecha"
                          ) {
                            e.editorOptions.disabled =
                              e.row.data.isCerradoFactEntidad;
                            e.editorOptions.elementAttr = {
                              class: "id_dxDateBox_" + e.row.data.idReparto,
                            };
                            e.editorOptions.onInitialized = (arg) => {
                              deshabilitarFechasFacturacion(
                                e.row.data.idEntidad,
                              ).then(function () {
                                arg.component.option(
                                  "disabledDates",
                                  dxDateBox_disabledDates,
                                );
                              });
                            };
                            e.editorOptions.calendarOptions = {
                              cellTemplate: (arg) => {
                                let tooltip = $(
                                  "#dxTooltip_Repartos",
                                ).dxTooltip("instance");
                                let result = $("<div>")
                                  .addClass(
                                    "id_" +
                                      formatDate_noTime_parameter(arg.date),
                                  )
                                  .text(arg.text)
                                  .on("mouseenter", function () {
                                    let fechasDeshabilitadas =
                                      dxDateBox_disabledDates.filter(
                                        (fecha) =>
                                          formatDate_noTime_parameter(fecha) ==
                                          formatDate_noTime_parameter(arg.date),
                                      );
                                    if (fechasDeshabilitadas.length > 0) {
                                      tooltip.option({
                                        target:
                                          ".id_" +
                                          formatDate_noTime_parameter(arg.date),
                                        contentTemplate: function (
                                          contentElement,
                                        ) {
                                          contentElement.html(
                                            getTrad("factBloqueadaEntiFecha"),
                                          );
                                        },
                                      });
                                      tooltip.show();
                                    }
                                  })
                                  .on("mouseleave", function () {
                                    tooltip.hide();
                                  });

                                return result;
                              },
                            };
                            e.editorOptions.onValueChanged = (arg) => {
                              deshabilitarFechasFacturacion(
                                e.row.data.idEntidad,
                                arg.value,
                              ).then(function () {
                                let datesDisabled = dxDateBox_disabledDates.map(
                                  (x) => formatDate_noTime_parameter(x),
                                );
                                let dateSelected = formatDate_noTime_parameter(
                                  arg.value,
                                );

                                let value;
                                if (
                                  datesDisabled.filter((x) => x == dateSelected)
                                    .length > 0
                                ) {
                                  value = arg.previousValue;

                                  notify({
                                    message: getTrad("factBloqueadaEntiFecha"),
                                    type: "error",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                  });
                                } else value = arg.value;
                                e.component.cellValue(
                                  e.row.rowIndex,
                                  "fecha",
                                  value,
                                );
                                e.component.saveEditData();
                              });
                            };

                            let tooltip = $("#dxTooltip_Repartos").dxTooltip(
                              "instance",
                            );
                            e.editorElement
                              .addClass(
                                "pe-auto id_fecha_" + e.row.data.idReparto,
                              )
                              .on("mouseover", function () {
                                if (e.row.data.isCerradoFactEntidad) {
                                  tooltip.option({
                                    target: ".id_fecha_" + e.row.data.idReparto,
                                    contentTemplate: function (contentElement) {
                                      contentElement.html(
                                        getTrad("factBloqueadaEntiFecha"),
                                      );
                                    },
                                  });
                                  tooltip.show();
                                }
                              })
                              .on("mouseout", function () {
                                tooltip.hide();
                              });

                            if (!e.row.data.isCerradoFactEntidad) {
                              tooltip.option({
                                showEvent: null,
                                hideEvent: null,
                              });
                            }
                          }

                          if (e.command === "select") {
                            if (e.parentType === "dataRow" && e.row) {
                              if (!isSelectable(e.row.data))
                                e.editorOptions.disabled = true;
                            } else if (e.parentType === "headerRow") {
                              e.editorOptions.onInitialized = function (e) {
                                selectAllCheckBox_gridRepartos = e.component;
                              };
                              e.editorOptions.value = isSelectAll(dataGrid);
                              e.editorOptions.onValueChanged = function (e) {
                                if (!e.event) {
                                  if (
                                    e.previousValue &&
                                    !checkBoxUpdating_gridRepartos
                                  ) {
                                    e.component.option(
                                      "value",
                                      e.previousValue,
                                    );
                                  }
                                  return;
                                }
                                if (isSelectAll(dataGrid) === e.value) {
                                  return;
                                }

                                e.value
                                  ? dataGrid.selectAll()
                                  : dataGrid.deselectAll();

                                e.event.preventDefault();
                              };
                            }
                          }
                        },
                        onSelectionChanged: function (e) {
                          var deselectRowKeys = [];
                          e.selectedRowsData.forEach((item) => {
                            if (!isSelectable(item))
                              deselectRowKeys.push(e.component.keyOf(item));
                          });
                          if (deselectRowKeys.length) {
                            e.component.deselectRows(deselectRowKeys);
                          }
                          checkBoxUpdating_gridRepartos = true;
                          selectAllCheckBox_gridRepartos.option(
                            "value",
                            isSelectAll(e.component),
                          );
                          checkBoxUpdating_gridRepartos = false;
                        },
                        onRowRemoved: function () {
                          $("#dgvPedidos").dxDataGrid("instance").refresh();
                          notify({
                            message: getTrad("aviso_C_RegistroEliminado"),
                            type: "success",
                            displayTime: "1500",
                            closeOnClick: true,
                          });
                        },
                        onToolbarPreparing: function (e) {
                          e.toolbarOptions.items.unshift(
                            {
                              location: "after",
                              widget: "dxButton",
                              showText: "inMenu",
                              locateInMenu: "auto",
                              options: {
                                text: getTrad("limpiarFiltro"),
                                icon: " icon_EliminarFiltros",
                                onClick: function (e) {
                                  $("#dgvRepartos")
                                    .dxDataGrid("instance")
                                    .clearFilter();
                                  notify({
                                    message: getTrad(
                                      "aviso_C_FiltroRestaurado",
                                    ),
                                    type: "success",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                  });
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              showText: "inMenu",
                              options: {
                                icon: "refresh",
                                text: getTrad("actualizar"),
                                onClick: function () {
                                  $("#dgvRepartos")
                                    .dxDataGrid("instance")
                                    .refresh(true);
                                },
                              },
                            },
                            {
                              location: "before",
                              widget: "dxButton",
                              toolbar: "bottom",
                              options: {
                                text: getTrad("repartir"),
                                icon: "check",
                                type: "success",
                                onClick: function (options) {
                                  var grid =
                                    $("#dgvRepartos").dxDataGrid("instance");
                                  if (grid.getSelectedRowKeys().length > 0) {
                                    try {
                                      options.component.option(
                                        "disabled",
                                        true,
                                      );
                                      grid.clearFilter();
                                      grid
                                        .refresh(true)
                                        .done(async function () {
                                          let eventos = [];
                                          $.each(
                                            grid.getSelectedRowKeys(),
                                            function (i, key) {
                                              eventos.push(
                                                datasource_repartos_core
                                                  .store()
                                                  .update(
                                                    key,
                                                    patchRequestHandler({
                                                      idRepartoEstado: 3,
                                                    }),
                                                  ),
                                              );
                                              //grid.cellValue(grid.getRowIndexByKey(key), "idRepartoEstado", 3);
                                            },
                                          );

                                          await Promise.all(eventos).then(
                                            () => {
                                              $("#dgvPedidos")
                                                .dxDataGrid("instance")
                                                .refresh();
                                              grid.option(
                                                "selectedRowKeys",
                                                [],
                                              );
                                              grid.refresh();
                                              options.component.option(
                                                "disabled",
                                                false,
                                              );
                                            },
                                          );
                                          // grid.saveEditData().done(function () {
                                          //     $("#dgvPedidos").dxDataGrid("instance").refresh();
                                          //     grid.option("selectedRowKeys", []);
                                          //     grid.refresh();
                                          //     options.component.option('disabled', false);
                                          // });
                                        });
                                    } catch (ex) {
                                      options.component.option(
                                        "disabled",
                                        false,
                                      );
                                    }
                                  } else {
                                    notify({
                                      message: getTrad(
                                        "aviso_I_SeleccionaReparto",
                                      ),
                                      type: "error",
                                      displayTime: "1500",
                                      closeOnClick: true,
                                    });
                                  }
                                },
                              },
                            },
                          );

                          e.toolbarOptions.items.unshift();
                        },
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                        rowAlternationEnabled: true,
                      }),
                    );
                  },
                },
              ],
            }),
          );
        },
      },
    ],
  });

  $("#Repartos #popup_prendasPedido").dxPopup({
    showTitle: true,
    title: getTrad("prendasPedido"),
    height: "80%",
    //EVENTOS
    onShowing: function (e) {
      var obervacionesPedido = $("#obervacionesPedido").dxTextArea("instance");
      if (pedidoSel) {
        obervacionesPedido.option("value", pedidoSel.observacionesPedido);

        if (pedidoSel.pedidoProd === 1) {
          obervacionesPedido.option("visible", false);
        } else {
          obervacionesPedido.option("visible", true);
        }
      } else {
        obervacionesPedido.option("visible", false);
      }
    },
    onHiding: function (e) {
      $("#btnGenerarRepartos_PrendasPedido")
        .dxButton("instance")
        .option("disabled", false);
    },
    onHidden: function (e) {
      // SI TENEMOS CAMBIOS SIN GUARDAR EN GRID, SE CANCELAN
      $("#dgvPrendasPedido").dxDataGrid("instance").cancelEditData();
      if (typeof $("#popup_selCompaEnti_lista_compañias")[0] !== "undefined") {
        $("#popup_selCompaEnti_lista_compañias")
          .dxList("instance")
          .selectItem(0);
        $("#popup_selCompaEnti_lista_entidades")
          .dxList("instance")
          .option("selectedItemKeys", []);
      }

      $("#tabpanel-container")
        .dxTabPanel("instance")
        .option("selectedIndex", 1);

      $("#dgvPedidos").dxDataGrid("instance").refresh();
    },
    contentTemplate: function () {
      return $("<div id='tabpanel-container' />")
        .addClass("no-header")
        .dxTabPanel({
          height: "100%",
          selectedIndex: 1,
          scrollByContent: true,
          scrollingEnabled: true,
          animationEnabled: true,
          items: [
            {
              template: function () {
                return $("<div>").dxBox({
                  height: "100%",
                  direction: "col",
                  align: "space-around",
                  crossAlign: "stretch",
                  items: [
                    {
                      baseSize: 40,
                      ratio: 0,
                      shrink: 0,
                      template: function () {
                        return $(
                          "<div id='dxToolbar_PrendasProducciones'>",
                        ).dxToolbar({
                          items: [
                            {
                              location: "after",
                              widget: "dxButton",
                              toolbar: "bottom",
                              options: {
                                icon: "chevronnext",
                                onClick: function () {
                                  $("#tabpanel-container")
                                    .dxTabPanel("instance")
                                    .option("selectedIndex", 1);
                                },
                              },
                            },
                          ],
                        });
                      },
                    },
                    {
                      ratio: 1,
                      template: function () {
                        return $("<div id='dxBox_prendasProd'>").dxBox({
                          height: "100%",
                          width: "100%",
                          direction: "row",
                          align: "space-around",
                          crossAlign: "stretch",
                          items: [
                            {
                              ratio: 1,
                              template: function () {
                                return $("<div />").dxBox({
                                  height: "100%",
                                  width: "100%",
                                  direction: "col",
                                  align: "space-around",
                                  crossAlign: "stretch",
                                  items: [
                                    {
                                      ratio: 1,
                                      template: function () {
                                        return $(
                                          "<div id='dgvPrendasPedidoProd_agrupado' />",
                                        ).dxDataGrid({
                                          //Datos
                                          dataSource:
                                            datasource_prendasProdPedido_agrupado,
                                          remoteOperations: true,
                                          //Propiedades
                                          height: "100%",
                                          columnsAutoWidth: true,
                                          headerFilter: {
                                            visible: true,
                                          },
                                          filterRow: {
                                            visible: true,
                                            applyFilter: "auto",
                                          },
                                          paging: {
                                            enabled: false,
                                          },
                                          selection: {
                                            mode: "single",
                                          },
                                          //Columnas
                                          columns: [
                                            {
                                              dataField: "idPrenda",
                                              visible: false,
                                            },
                                            {
                                              dataField: "codigoPrenda",
                                              caption: getTrad("codigo"),
                                              sortOrder: "asc",
                                              width: "15%",
                                              alignment: "left",
                                              allowHeaderFiltering: false,
                                            },
                                            {
                                              dataField: "denominacion",
                                              caption: getTrad("denominacion"),
                                              alignment: "left",
                                            },
                                            {
                                              dataField: "Producido",
                                              caption: getTrad("produccion"),
                                              width: "19%",
                                              alignment: "center",
                                              allowHeaderFiltering: false,
                                              allowFiltering: false,
                                              allowSorting: false,
                                              format: {
                                                style: "decimal",
                                                maximumFractionDigits: 0,
                                              },
                                            },
                                            {
                                              dataField: "Rechazo",
                                              caption: getTrad("rechazo"),
                                              width: "15.5%",
                                              alignment: "center",
                                              allowHeaderFiltering: false,
                                              allowFiltering: false,
                                              allowSorting: false,
                                              format: {
                                                style: "decimal",
                                                maximumFractionDigits: 0,
                                              },
                                            },
                                            {
                                              dataField: "Retiro",
                                              caption: getTrad("retiro"),
                                              width: "13.5%",
                                              alignment: "center",
                                              allowHeaderFiltering: false,
                                              allowFiltering: false,
                                              allowSorting: false,
                                              format: {
                                                style: "decimal",
                                                maximumFractionDigits: 0,
                                              },
                                            },
                                          ],
                                          // EVENTOS
                                          onSelectionChanged: function (
                                            selectedItems,
                                          ) {
                                            var dgvPrendasPedidoProd = $(
                                              "#dgvPrendasPedidoProd",
                                            ).dxDataGrid("instance");
                                            if (
                                              selectedItems.selectedRowKeys
                                                .length > 0
                                            ) {
                                              var sbTipoProduccion_value = $(
                                                "#sbTipoProduccion",
                                              )
                                                .dxSelectBox("instance")
                                                .option("value");
                                              if (sbTipoProduccion_value) {
                                                dgvPrendasPedidoProd.option(
                                                  "filterValue",
                                                  [
                                                    "idTipoProduccion",
                                                    "=",
                                                    sbTipoProduccion_value,
                                                  ],
                                                );
                                              } else {
                                                dgvPrendasPedidoProd.option(
                                                  "filterValue",
                                                  [],
                                                );
                                              }
                                              $("#dxBox_prendasProd")
                                                .dxBox("instance")
                                                .option(
                                                  "items[1].visible",
                                                  true,
                                                );
                                              dgvPrendasPedidoProd.refresh(
                                                true,
                                              );
                                            } else {
                                              $("#dxBox_prendasProd")
                                                .dxBox("instance")
                                                .option(
                                                  "items[1].visible",
                                                  false,
                                                );
                                              dgvPrendasPedidoProd.refresh(
                                                true,
                                              );
                                            }
                                          },
                                          onToolbarPreparing: function (e) {
                                            e.toolbarOptions.items.unshift(
                                              {
                                                location: "before",
                                                template: function () {
                                                  return $(
                                                    "<div class='font-size' style='margin-left:9px;'>" +
                                                      getTrad(
                                                        "seleccionePrenda",
                                                      ) +
                                                      "</div>",
                                                  );
                                                },
                                              },
                                              {
                                                location: "after",
                                                widget: "dxSelectBox",
                                                toolbar: "top",
                                                options: {
                                                  elementAttr: {
                                                    id: "sbTipoProduccion",
                                                  },
                                                  width: 260,
                                                  showClearButton: true,
                                                  dataSource:
                                                    datasource_tipoProduccion,
                                                  value:
                                                    pedidoSel.idTipoProduccion,
                                                  displayExpr: "denominacion",
                                                  valueExpr: "idTipoProduccion",
                                                  placeholder:
                                                    getTrad(
                                                      "seleccioneTipoProd",
                                                    ),
                                                  onValueChanged: function (
                                                    options,
                                                  ) {
                                                    $("#dxBox_prendasProd")
                                                      .dxBox("instance")
                                                      .option(
                                                        "items[1].visible",
                                                        false,
                                                      );
                                                    var dgvPrendasPedidoProd_agrupado =
                                                      $(
                                                        "#dgvPrendasPedidoProd_agrupado",
                                                      ).dxDataGrid("instance");

                                                    if (options.value) {
                                                      // SI EL VALOR NO ES TODOS
                                                      $(
                                                        "#dgvPrendasPedidoProd",
                                                      ).dxDataGrid(
                                                        "columnOption",
                                                        "idTipoProduccion",
                                                        "visible",
                                                        false,
                                                      );
                                                    } else {
                                                      $(
                                                        "#dgvPrendasPedidoProd",
                                                      ).dxDataGrid(
                                                        "columnOption",
                                                        "idTipoProduccion",
                                                        "visible",
                                                        true,
                                                      );
                                                    }
                                                    if (
                                                      dgvPrendasPedidoProd_agrupado.option(
                                                        "selectedRowKeys",
                                                      ).length > 0
                                                    ) {
                                                      dgvPrendasPedidoProd_agrupado.option(
                                                        "selectedRowKeys",
                                                        [],
                                                      );
                                                    }
                                                    dgvPrendasPedidoProd_agrupado.refresh(
                                                      true,
                                                    );
                                                  },
                                                },
                                              },
                                            );
                                          },
                                          //Estilos
                                          showColumnLines: false,
                                          showRowLines: true,
                                          rowAlternationEnabled: true,
                                        });
                                      },
                                    },
                                    {
                                      baseSize: 70,
                                      shrink: 0,
                                      ratio: 0,
                                      template: function () {
                                        return $(
                                          "<div id='dxToolbar_dgvPrendasPedidoProd_agrupado_añadirPrendas' style='margin-top:30px;'>",
                                        ).dxToolbar({
                                          items: [
                                            {
                                              location: "before",
                                              widget: "dxButton",
                                              options: {
                                                text: getTrad("añadirPrenda"),
                                                icon: "plus",
                                                type: "normal",
                                                onClick: function (e) {
                                                  $("#dxBox_prendasProd")
                                                    .dxBox("instance")
                                                    .option(
                                                      "items[1].visible",
                                                      false,
                                                    );
                                                  var dgvPrendasPedidoProd_agrupado =
                                                    $(
                                                      "#dgvPrendasPedidoProd_agrupado",
                                                    ).dxDataGrid("instance");
                                                  var dxDataGrid_prendasProdPedido_agrupado_añadir =
                                                    $(
                                                      "#dxDataGrid_prendasProdPedido_agrupado_añadir",
                                                    ).dxDataGrid("instance");

                                                  var sbTipoProduccion_value =
                                                    $("#sbTipoProduccion")
                                                      .dxSelectBox("instance")
                                                      .option("value");
                                                  if (sbTipoProduccion_value) {
                                                    dgvPrendasPedidoProd_agrupado.clearFilter(
                                                      "row",
                                                    );

                                                    dgvPrendasPedidoProd_agrupado
                                                      .refresh()
                                                      .done(function () {
                                                        if (
                                                          typeof $(
                                                            "#dxDataGrid_prendasProdPedido_agrupado_añadir",
                                                          )[0] !== "undefined"
                                                        ) {
                                                          dxDataGrid_prendasProdPedido_agrupado_añadir
                                                            .refresh()
                                                            .done(function () {
                                                              $(
                                                                "#popup_addPrenda_prodPedido",
                                                              )
                                                                .dxPopup(
                                                                  "instance",
                                                                )
                                                                .show();
                                                            });
                                                        } else {
                                                          // 1era vez AUTOCARGA
                                                          $(
                                                            "#popup_addPrenda_prodPedido",
                                                          )
                                                            .dxPopup("instance")
                                                            .show();
                                                        }
                                                      });
                                                  } else {
                                                    notify({
                                                      message:
                                                        getTrad(
                                                          "seleccioneTipoProd",
                                                        ),
                                                      type: "error",
                                                      displayTime: "1500",
                                                      closeOnClick: true,
                                                    });
                                                  }
                                                  dgvPrendasPedidoProd_agrupado.option(
                                                    "selectedRowKeys",
                                                    [],
                                                  );
                                                },
                                              },
                                            },
                                          ],
                                        });
                                      },
                                    },
                                  ],
                                });
                              },
                            },
                            {
                              ratio: 1,
                              visible: false,
                              template: function () {
                                return $(
                                  "<div id='dgvPrendasPedidoProd' style='padding-left:20px;' />",
                                ).dxDataGrid({
                                  dataSource: datasource_prendasProdPedido,
                                  remoteOperations: true,
                                  //Propiedades
                                  height: "100%",
                                  columnsAutoWidth: true,
                                  headerFilter: {
                                    visible: true,
                                  },
                                  filterRow: {
                                    visible: true,
                                    applyFilter: "auto",
                                  },
                                  editing: {
                                    mode: "cell",
                                    allowUpdating: true,
                                    allowDeleting: false,
                                    useIcons: true,
                                    selectTextOnEditStart: true,
                                  },
                                  keyboardNavigation: {
                                    enterKeyAction: "moveFocus",
                                    enterKeyDirection: "row",
                                    editOnKeyPress: true,
                                  },
                                  paging: {
                                    enabled: false,
                                  },
                                  repaintChangesOnly: true,
                                  loadPanel: { enabled: false },
                                  //Columnas
                                  columns: [
                                    {
                                      dataField: "idPrendaNProduccion",
                                      visible: false,
                                    },
                                    {
                                      dataField: "idPrenda",
                                      visible: false,
                                    },
                                    {
                                      dataField: "idProduccion",
                                      visible: false,
                                    },
                                    {
                                      dataField: "hora",
                                      caption: getTrad("hora"),
                                      format: "hh:mm",
                                      sortOrder: "asc",
                                      width: "15%",
                                      alignment: "center",
                                      allowHeaderFiltering: false,
                                      allowEditing: false,
                                    },
                                    {
                                      dataField: "idTurno",
                                      caption: getTrad("turno"),
                                      lookup: {
                                        dataSource: function (options) {
                                          return {
                                            store: datasource_turnos.store(),
                                          };
                                        },
                                        displayExpr: "denominacion",
                                        valueExpr: "idTurno",
                                      },
                                      visible: true,
                                      allowEditing: false,
                                      allowHeadFiltering: false,
                                    },
                                    {
                                      dataField: "idTipoProduccion",
                                      caption: getTrad("tipoProduccion"),
                                      width: "19%",
                                      lookup: {
                                        dataSource: function (options) {
                                          return {
                                            store:
                                              datasource_tipoProduccion.store(),
                                          };
                                        },
                                        displayExpr: "denominacion",
                                        valueExpr: "idTipoProduccion",
                                      },
                                      visible: true,
                                      allowEditing: false,
                                    },
                                    {
                                      dataField: "Producido",
                                      caption: getTrad("producido"),
                                      cssClass: "dx-Cell_Editable",
                                      sortOrder: "asc",
                                      width: "19%",
                                      alignment: "center",
                                      allowHeaderFiltering: false,
                                      allowFiltering: false,
                                      format: {
                                        style: "decimal",
                                        maximumFractionDigits: 0,
                                      },
                                      editorOptions: {
                                        step: 0,
                                        format: {
                                          style: "decimal",
                                          maximumFractionDigits: 0,
                                        },
                                        min: 0,
                                        max: 99999999,
                                      },
                                    },
                                    {
                                      dataField: "Rechazo",
                                      caption: getTrad("rechazo"),
                                      cssClass: "dx-Cell_Editable",
                                      sortOrder: "asc",
                                      width: "17%",
                                      alignment: "center",
                                      allowHeaderFiltering: false,
                                      allowFiltering: false,
                                      format: {
                                        style: "decimal",
                                        maximumFractionDigits: 0,
                                      },
                                      editorOptions: {
                                        step: 0,
                                        format: {
                                          style: "decimal",
                                          maximumFractionDigits: 0,
                                        },
                                        min: 0,
                                        max: 99999999,
                                      },
                                    },
                                    {
                                      dataField: "Retiro",
                                      caption: getTrad("retiro"),
                                      cssClass: "dx-Cell_Editable",
                                      sortOrder: "asc",
                                      width: "15%",
                                      alignment: "center",
                                      allowHeaderFiltering: false,
                                      allowFiltering: false,
                                      format: {
                                        style: "decimal",
                                        maximumFractionDigits: 0,
                                      },
                                      editorOptions: {
                                        step: 0,
                                        format: {
                                          style: "decimal",
                                          maximumFractionDigits: 0,
                                        },
                                        min: 0,
                                        max: 99999999,
                                      },
                                    },
                                  ],
                                  onEditingStart: function (e) {
                                    if (
                                      e.column.dataField === "Rechazo" &&
                                      LAVANDERIA.gestionaRechazo
                                    ) {
                                      prendasPedidoProdSel = e.data;

                                      var popup = $(
                                        "#popup_tiposRechazo",
                                      ).dxPopup("instance");
                                      var dgvTipoRechazo =
                                        $("#dgvTipoRechazo").dxDataGrid(
                                          "instance",
                                        );
                                      if (!popup.option("visible")) {
                                        popup.show();
                                        if (
                                          typeof dgvTipoRechazo !== "undefined"
                                        ) {
                                          dgvTipoRechazo.option(
                                            "dataSource",
                                            datasource_rechazoNProd,
                                          );
                                        }
                                      }
                                      e.cancel = true;
                                    }
                                  },
                                  onRowUpdating: function (option) {
                                    option.newData.idPrenda =
                                      option.oldData.idPrenda;
                                    option.newData.idProduccion =
                                      option.oldData.idProduccion;
                                    option.newData.idPrendaNProduccion =
                                      option.oldData.idPrendaNProduccion;
                                    prendasPedidoProdSel = option.newData;

                                    if (
                                      typeof option.newData.Producido ===
                                      "undefined"
                                    ) {
                                      option.newData.Producido =
                                        option.oldData.Producido;
                                    } else if (LAVANDERIA.gestionaRechazo) {
                                      var popup = $(
                                        "#popup_tiposRechazo",
                                      ).dxPopup("instance");
                                      var dgvTipoRechazo =
                                        $("#dgvTipoRechazo").dxDataGrid(
                                          "instance",
                                        );

                                      if (!popup.option("visible")) {
                                        popup.show();
                                        if (
                                          typeof dgvTipoRechazo !== "undefined"
                                        ) {
                                          dgvTipoRechazo.option(
                                            "dataSource",
                                            datasource_rechazoNProd,
                                          );
                                        }
                                      }
                                    }
                                    if (
                                      typeof option.newData.Rechazo ===
                                      "undefined"
                                    )
                                      option.newData.Rechazo =
                                        option.oldData.Rechazo;
                                    if (
                                      typeof option.newData.Retiro ===
                                      "undefined"
                                    )
                                      option.newData.Retiro =
                                        option.oldData.Retiro;
                                  },
                                  onRowUpdated: function (options) {
                                    var dgvTipoRechazo =
                                      $("#dgvTipoRechazo").dxDataGrid(
                                        "instance",
                                      );
                                    $("#dgvPrendasPedidoProd_agrupado")
                                      .dxDataGrid("instance")
                                      .refresh(true)
                                      .done(function () {
                                        dgvTipoRechazo.editCell(1, "Cantidad");
                                        dgvTipoRechazo.editCell(0, "Cantidad");
                                      });
                                  },
                                  onToolbarPreparing: function (e) {
                                    e.toolbarOptions.items.unshift({
                                      location: "before",
                                      template: function () {
                                        return $(
                                          "<div class='font-size' style='margin-left:9px;'>",
                                        ).text(getTrad("prendasProd"));
                                      },
                                    });
                                  }, //Estilos
                                  showColumnLines: false,
                                  showRowLines: true,
                                  rowAlternationEnabled: true,
                                });
                              },
                            },
                          ],
                        });
                      },
                    },
                  ],
                });
              },
            },
            {
              template: function () {
                return $("<div>").dxBox({
                  height: "100%",
                  direction: "col",
                  align: "space-around",
                  crossAlign: "stretch",
                  items: [
                    {
                      ratio: 8.5,
                      template: function () {
                        return $("<div id='dgvPrendasPedido' />").dxDataGrid({
                          //Datos
                          dataSource: pedidoSel
                            ? datasource_prendasPedido
                            : datasource_prendasPedidoDesgome,
                          remoteOperations: true,
                          //Propiedades
                          height: "100%",
                          columnsAutoWidth: true,
                          headerFilter: {
                            visible: true,
                          },
                          filterRow: {
                            visible: true,
                            applyFilter: "auto",
                          },
                          editing: {
                            mode: "batch",
                            allowUpdating: true,
                            allowDeleting: false,
                            useIcons: true,
                            selectTextOnEditStart: true,
                          },
                          keyboardNavigation: {
                            enterKeyAction: "moveFocus",
                            enterKeyDirection: "row",
                            editOnKeyPress: true,
                          },
                          paging: {
                            enabled: false,
                          },
                          //Columnas
                          columns: [
                            {
                              dataField: "idPrenda",
                              caption: "",
                              visible: false,
                            },
                            {
                              dataField: "codigoPrenda",
                              caption: getTrad("codigo"),
                              sortOrder: "asc",
                              width: "10%",
                              alignment: "center",
                              allowHeaderFiltering: false,
                              allowEditing: false,
                              editorOptions: { showClearButton: true },
                            },
                            {
                              dataField: "denoPrenda",
                              caption: getTrad("denominacion"),
                              allowEditing: false,
                              editorOptions: { showClearButton: true },
                            },
                            {
                              dataField: "udsXBacPedido",
                              caption: "",
                              visible: false,
                            },
                            {
                              dataField: "udsXBacReparto",
                              caption: "",
                              visible: false,
                            },
                            {
                              dataField: "peticion",
                              caption: getTrad("peticion"),
                              width: "10%",
                              alignment: "center",
                              allowEditing: false,
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              format: {
                                style: "decimal",
                                maximumFractionDigits: 0,
                              },
                              calculateCellValue: function (e) {
                                var cantidad = typeof (
                                  e.cantidad !== "undefined"
                                )
                                  ? e.cantidad
                                  : 0;
                                var udsPeticion,
                                  resultado = 0;

                                //#region Paso la petición y cantidad a uds y al final lo transformo a bacs si es necesario
                                if (
                                  pedidoSel === null ||
                                  pedidoSel.pedidoProd
                                ) {
                                  // POR UDS
                                  udsPeticion = e.peticion;
                                  resultado = udsPeticion - cantidad;
                                } else {
                                  if (parseInt(e.codigoElemTransPedido) === 1)
                                    // POR UDS
                                    udsPeticion = e.peticion;
                                  else if (
                                    parseInt(e.codigoElemTransPedido) === 2
                                  )
                                    // BAC/SACA
                                    udsPeticion = e.peticion * e.udsXBacPedido;

                                  if (parseInt(e.codigoElemTransReparto) === 1)
                                    // POR UDS
                                    resultado = udsPeticion - cantidad;
                                  else if (
                                    parseInt(e.codigoElemTransReparto) === 2
                                  )
                                    // BAC/SACA
                                    resultado = Math.ceil(
                                      (udsPeticion -
                                        cantidad * e.udsXBacReparto) /
                                        e.udsXBacPedido,
                                    );
                                }
                                return resultado >= 0 ? resultado : 0;
                                //#endregion
                              },
                            },
                            {
                              dataField: "codigoElemTransPedido",
                              caption: getTrad("elementoTransporte_abr"),
                              width: "70",
                              allowEditing: false,
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              alignment: "center",
                              cssClass: "p-0",
                              cellTemplate: function (container, options) {
                                var contenedor = $("<div />")
                                  .addClass("container_spanCentrado")
                                  .css("padding-left", "10px");

                                var svg;
                                var htmlTooltip = "";
                                if (
                                  parseInt(
                                    options.data.codigoElemTransPedido,
                                  ) === 1 ||
                                  pedidoSel === null ||
                                  pedidoSel.pedidoProd
                                ) {
                                  // POR UDS
                                  svg = svg_elemTrans_ud.clone();
                                  htmlTooltip =
                                    "<p>" + getTrad("porUds") + "</p>";
                                } else if (
                                  parseInt(
                                    options.data.codigoElemTransPedido,
                                  ) === 2
                                ) {
                                  // BAC/SACA
                                  svg = svg_elemTrans_bac.clone();
                                  htmlTooltip =
                                    "<p>" +
                                    getTrad("bacSaca") +
                                    ": " +
                                    options.data.udsXBacPedido +
                                    "</p>";
                                }

                                svg
                                  .css("height", "20px")
                                  .css("width", "25px")
                                  .css("cursor", "pointer")
                                  .appendTo(contenedor);

                                tooltipControl_creacion(
                                  contenedor,
                                  htmlTooltip,
                                );

                                return contenedor;
                              },
                            },
                            {
                              dataField: "cantidad",
                              caption: getTrad("cantidad"),
                              width: "10%",
                              alignment: "center",
                              dataType: "number",
                              cssClass: "dx-Cell_Editable",
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              allowSorting: false,
                              format: {
                                style: "decimal",
                                maximumFractionDigits: 0,
                              },
                              editorOptions: {
                                step: 0,
                                format: {
                                  style: "decimal",
                                  maximumFractionDigits: 0,
                                },
                                min: 0,
                                max: 99999999,
                              },
                            },
                            {
                              dataField: "rechazo",
                              caption: getTrad("rechazo"),
                              width: "10%",
                              alignment: "center",
                              dataType: "number",
                              cssClass: "dx-Cell_Editable",
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              allowSorting: false,
                              visible: false,
                              format: {
                                style: "decimal",
                                maximumFractionDigits: 0,
                              },
                              editorOptions: {
                                step: 0,
                                format: {
                                  style: "decimal",
                                  maximumFractionDigits: 0,
                                },
                              },
                            },
                            {
                              dataField: "retiro",
                              caption: getTrad("retiro"),
                              width: "10%",
                              alignment: "center",
                              dataType: "number",
                              cssClass: "dx-Cell_Editable",
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              allowSorting: false,
                              visible: false,
                              format: {
                                style: "decimal",
                                maximumFractionDigits: 0,
                              },
                              editorOptions: {
                                step: 0,
                                format: {
                                  style: "decimal",
                                  maximumFractionDigits: 0,
                                },
                              },
                            },
                            {
                              dataField: "codigoElemTransReparto",
                              caption: getTrad("elementoTransporte_abr"),
                              width: "70",
                              allowEditing: false,
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              alignment: "center",
                              cssClass: "p-0",
                              cellTemplate: function (container, options) {
                                var contenedor = $("<div />")
                                  .addClass("container_spanCentrado")
                                  .css("padding-left", "10px");

                                var svg;
                                var htmlTooltip = "";
                                if (
                                  options.data.codigoElemTransReparto === 1 ||
                                  pedidoSel === null ||
                                  pedidoSel.pedidoProd
                                ) {
                                  // POR UDS
                                  svg = svg_elemTrans_ud.clone();
                                  htmlTooltip =
                                    "<p>" + getTrad("porUds") + "</p>";
                                } else if (
                                  options.data.codigoElemTransReparto === 2
                                ) {
                                  // BAC/SACA
                                  svg = svg_elemTrans_bac.clone();
                                  htmlTooltip =
                                    "<p>" +
                                    getTrad("bacSaca") +
                                    ": " +
                                    options.data.udsXBacReparto +
                                    "</p>";
                                }

                                svg
                                  .css("height", "20px")
                                  .css("width", "25px")
                                  .css("cursor", "pointer")
                                  .appendTo(contenedor);

                                tooltipControl_creacion(
                                  contenedor,
                                  htmlTooltip,
                                );

                                return contenedor;
                              },
                            },
                            {
                              dataField: "total",
                              caption: getTrad("total"),
                              width: "10%",
                              alignment: "center",
                              allowEditing: false,
                              allowHeaderFiltering: false,
                              allowSorting: false,
                              allowFiltering: false,
                              format: {
                                style: "decimal",
                                maximumFractionDigits: 0,
                              },
                              calculateCellValue: function (e) {
                                var cantidad = 0;
                                var rechazo = 0;
                                var retiro = 0;

                                cantidad = typeof (e.cantidad !== "undefined")
                                  ? e.cantidad
                                  : 0;

                                if (
                                  pedidoSel !== null &&
                                  pedidoSel.reparteRechazoRetiro !== null &&
                                  pedidoSel.pedidoProd
                                ) {
                                  var reparteRechazo = false;
                                  var reparteRetiro = false;

                                  if (
                                    $(
                                      "#dxSwitch_dgvPrendasPedido_ReparteRechazo",
                                    ).length > 0
                                  ) {
                                    reparteRechazo = $(
                                      "#dxSwitch_dgvPrendasPedido_ReparteRechazo",
                                    )
                                      .dxSwitch("instance")
                                      .option("value");
                                  }

                                  if (
                                    $(
                                      "#dxSwitch_dgvPrendasPedido_ReparteRetiro",
                                    ).length > 0
                                  ) {
                                    reparteRetiro = $(
                                      "#dxSwitch_dgvPrendasPedido_ReparteRetiro",
                                    )
                                      .dxSwitch("instance")
                                      .option("value");
                                  }

                                  if (reparteRechazo && reparteRetiro) {
                                    rechazo = typeof (e.rechazo !== "undefined")
                                      ? e.rechazo
                                      : 0;
                                    retiro = typeof (e.retiro !== "undefined")
                                      ? e.retiro
                                      : 0;
                                  } else if (reparteRechazo) {
                                    rechazo = typeof (e.rechazo !== "undefined")
                                      ? e.rechazo
                                      : 0;
                                  } else if (reparteRetiro) {
                                    retiro = typeof (e.retiro !== "undefined")
                                      ? e.retiro
                                      : 0;
                                  }
                                  return (
                                    (cantidad + rechazo + retiro) *
                                    e.udsXBacReparto
                                  );
                                } else if (pedidoSel === null) {
                                  return cantidad;
                                } else {
                                  return (
                                    (cantidad + rechazo + retiro) *
                                    e.udsXBacReparto
                                  );
                                }
                              },
                            },
                          ],
                          //Eventos
                          onToolbarPreparing: function (e) {
                            e.toolbarOptions.items.unshift(
                              {
                                location: "after",
                                widget: "dxButton",
                                showText: "inMenu",
                                locateInMenu: "auto",
                                options: {
                                  text: getTrad("limpiarFiltro"),
                                  icon: " icon_EliminarFiltros",
                                  onClick: function (e) {
                                    $("#dgvPrendasPedido")
                                      .dxDataGrid("instance")
                                      .clearFilter();
                                    notify({
                                      message: getTrad(
                                        "aviso_C_FiltroRestaurado",
                                      ),
                                      type: "success",
                                      displayTime: "1500",
                                      closeOnClick: true,
                                    });
                                  },
                                },
                              },
                              {
                                location: "after",
                                widget: "dxButton",
                                showText: "inMenu",
                                options: {
                                  elementAttr: { id: "btnActualizar" },
                                  icon: "refresh",
                                  text: getTrad("actualizar"),
                                  onClick: function () {
                                    $("#dgvPrendasPedido")
                                      .dxDataGrid("instance")
                                      .refresh(true);
                                  },
                                },
                              },
                              {
                                location: "after",
                                widget: "dxButton",
                                options: {
                                  elementAttr: { id: "btnMarcarTodo" },
                                  value: false,
                                  width: 130,
                                  text: getTrad("marcarTodo"),
                                  onClick: function (data) {
                                    var grid =
                                      $("#dgvPrendasPedido").dxDataGrid(
                                        "instance",
                                      );
                                    $.each(
                                      grid.getVisibleRows(),
                                      function (index, item) {
                                        if (item.rowType === "data") {
                                          var peticion = grid.cellValue(
                                            item.rowIndex,
                                            "peticion",
                                          );
                                          var udsXBacPedido = grid.cellValue(
                                            item.rowIndex,
                                            "udsXBacPedido",
                                          );
                                          var udsXBacReparto = grid.cellValue(
                                            item.rowIndex,
                                            "udsXBacReparto",
                                          );

                                          var cantidad = Math.ceil(
                                            (peticion * udsXBacPedido) /
                                              udsXBacReparto,
                                          );

                                          grid.cellValue(
                                            item.rowIndex,
                                            "cantidad",
                                            cantidad,
                                          );
                                        }
                                      },
                                    );
                                  },
                                },
                              },
                              {
                                toolbar: "bottom",
                                location: "before",
                                template: function () {
                                  return $(
                                    "<div id='titleSelCompaEnti' />",
                                  ).css("font-size", "18px");
                                },
                              },
                            );

                            if (pedidoSel !== null && pedidoSel.pedidoProd) {
                              if (pedidoSel.reparteRechazoRetiro !== null) {
                                e.toolbarOptions.items.unshift({
                                  location: "after",
                                  template: function (item) {
                                    var container = $("<div></div>");

                                    if (
                                      pedidoSel.reparteRechazoRetiro === 0 ||
                                      pedidoSel.reparteRechazoRetiro === 1
                                    ) {
                                      $(
                                        "<div id='dxSwitch_dgvPrendasPedido_ReparteRechazo' />",
                                      )
                                        .dxSwitch({
                                          switchedOnText: getTrad("rechazo"),
                                          switchedOffText: getTrad("rechazo"),
                                          value: true,
                                          width: "100px",
                                          height: "40px",
                                          elementAttr: {
                                            class: "dxSwitch_toolbar",
                                          },
                                          onValueChanged: function () {
                                            $("#dgvPrendasPedido")
                                              .dxDataGrid("instance")
                                              .refresh(true);
                                          },
                                        })
                                        .appendTo(container);
                                    }

                                    if (
                                      pedidoSel.reparteRechazoRetiro === 0 ||
                                      pedidoSel.reparteRechazoRetiro === 2
                                    ) {
                                      $(
                                        "<div id='dxSwitch_dgvPrendasPedido_ReparteRetiro' style='padding-left:5px' />",
                                      )
                                        .dxSwitch({
                                          switchedOnText: getTrad("retiro"),
                                          switchedOffText: getTrad("retiro"),
                                          value: true,
                                          width: "100px",
                                          height: "40px",
                                          elementAttr: {
                                            class: "dxSwitch_toolbar",
                                          },
                                          onValueChanged: function () {
                                            $("#dgvPrendasPedido")
                                              .dxDataGrid("instance")
                                              .refresh(true);
                                          },
                                        })
                                        .appendTo(container);
                                    }

                                    return container;
                                  },
                                });
                              }

                              if (pedidoSel.pedidoProd === 1) {
                                e.toolbarOptions.items.unshift({
                                  location: "before",
                                  widget: "dxButton",
                                  options: {
                                    elementAttr: {
                                      class: "btnProduccion",
                                    },
                                    value: false,
                                    text: getTrad("modProd"),
                                    onClick: function (e) {
                                      $("#tabpanel-container")
                                        .dxTabPanel("instance")
                                        .option("selectedIndex", 0);
                                    },
                                  },
                                });
                              }
                            }

                            $.each(e.toolbarOptions.items, function (_, item) {
                              if (item.name === "saveButton") {
                                item.visible = false;
                              } else if (item.name === "revertButton") {
                                item.options.onClick = function (e) {
                                  var grid =
                                    $("#dgvPrendasPedido").dxDataGrid(
                                      "instance",
                                    );
                                  grid.cancelEditData();
                                  grid.refresh(true);
                                };
                              }
                            });
                          },
                          //Estilos
                          showColumnLines: false,
                          showRowLines: true,
                          rowAlternationEnabled: true,
                        });
                      },
                    },
                    { ratio: 0.4 },
                    {
                      ratio: 1,
                      template: function () {
                        return $("<div>").dxBox({
                          direction: "row",
                          align: "space-around",
                          crossAlign: "stretch",
                          items: [
                            {
                              ratio: 5,
                              template: function () {
                                return $(
                                  "<div id='obervacionesPedido'>",
                                ).dxTextArea({
                                  value: pedidoSel
                                    ? pedidoSel.observacionesPedido
                                    : "",
                                  readOnly: true,
                                });
                              },
                            },
                            {
                              ratio: 1,
                              template: function () {
                                return $(
                                  "<div id='dxToolbar_popup_PrendasPedido'>",
                                )
                                  .css({ "padding-top": "18px" })
                                  .dxToolbar({
                                    height: "100%",
                                    items: [
                                      {
                                        location: "after",
                                        widget: "dxButton",
                                        toolbar: "bottom",
                                        options: {
                                          elementAttr: {
                                            id: "btnGenerarRepartos_PrendasPedido",
                                          },
                                          text: getTrad("generarReparto"),
                                          icon: "check",
                                          type: "success",
                                          onClick: function (e) {
                                            e.component.option(
                                              "disabled",
                                              true,
                                            );
                                            var popup = $(
                                              "#popup_datosReparto",
                                            ).dxPopup("instance");
                                            popup.repaint();
                                            popup.show();
                                          },
                                        },
                                      },
                                    ],
                                  });
                              },
                            },
                          ],
                        });
                      },
                    },
                  ],
                });
              },
            },
          ],
          onSelectionChanged: function (e) {
            var index = e.component.option("selectedIndex");

            var dgvPrendasPedido =
              $("#dgvPrendasPedido").dxDataGrid("instance");
            var dgvPrendasPedidoProd_agrupado = $(
              "#dgvPrendasPedidoProd_agrupado",
            ).dxDataGrid("instance");

            if (index === 0) {
              //Producción
              dgvPrendasPedido.option("loadPanel.enabled", false);
              $("#dxBox_prendasProd")
                .dxBox("instance")
                .option("items[1].visible", false);

              var sbTipoProduccion =
                $("#sbTipoProduccion").dxSelectBox("instance");
              if (
                sbTipoProduccion.option("value") !== pedidoSel.idTipoProduccion
              ) {
                sbTipoProduccion.option("value", pedidoSel.idTipoProduccion);
              }

              dgvPrendasPedidoProd_agrupado.refresh(true);
            } //Pedido
            else {
              dgvPrendasPedido.option("loadPanel.enabled", true);

              if (
                dgvPrendasPedidoProd_agrupado.option("selectedRowKeys").length >
                0
              ) {
                dgvPrendasPedidoProd_agrupado.option("selectedRowKeys", []);
              }
              dgvPrendasPedido.refresh(true);
            }
          },
        });
    },
  });

  //POPUP AÑADIR PRENDAS

  $("#Repartos #popup_addPrenda_prodPedido").dxPopup({
    showTitle: true,
    title: getTrad("añadirPrenda"),
    height: 585,
    width: 800,
    contentTemplate: function () {
      return $(
        "<div id='dxDataGrid_prendasProdPedido_agrupado_añadir' />",
      ).dxDataGrid({
        //Datos
        dataSource: datasource_prendasProdPedido_agrupado_añadir,
        remoteOperations: true,
        //Propiedades
        height: "100%",
        columnsAutoWidth: true,
        headerFilter: {
          visible: true,
        },
        filterRow: {
          visible: true,
          applyFilter: "auto",
        },
        editing: {
          mode: "batch",
          allowUpdating: true,
          allowDeleting: false,
          useIcons: true,
          selectTextOnEditStart: true,
        },
        keyboardNavigation: {
          enterKeyAction: "moveFocus",
          enterKeyDirection: "row",
          editOnKeyPress: true,
        },
        paging: {
          enabled: false,
        },
        loadPanel: { enabled: false },
        columns: [
          {
            dataField: "idPrenda",
            visible: false,
            allowEditing: false,
          },
          {
            dataField: "codigoPrenda",
            width: "12%",
            caption: getTrad("codigo"),
            alignment: "left",
            allowEditing: false,
          },
          {
            dataField: "denoPrenda",
            caption: getTrad("denominacion"),
            alignment: "left",
            allowEditing: false,
          },
          {
            dataField: "producido",
            caption: getTrad("produccion"),
            width: "13.5%",
            alignment: "center",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
              min: 0,
              max: 99999999,
            },
          },
          {
            dataField: "rechazo",
            caption: getTrad("rechazo"),
            width: "13.5%",
            alignment: "center",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
              min: 0,
              max: 99999999,
            },
          },
          {
            dataField: "retiro",
            caption: getTrad("retiro"),
            width: "13.5%",
            alignment: "center",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
              min: 0,
              max: 99999999,
            },
          },
        ],
        onEditingStart: function (e) {
          var dgvTipoRechazo = $("#dgvTipoRechazo").dxDataGrid("instance");
          if (e.column.dataField === "rechazo" && LAVANDERIA.gestionaRechazo) {
            addPrendaSel = e.data;

            var popup = $("#popup_tiposRechazo").dxPopup("instance");

            if (!popup.option("visible")) {
              if (addPrenda_rechazoNPrenda.length > 0) {
                if (
                  typeof addPrenda_rechazoNPrenda[addPrendaSel.idPrenda] !==
                  "undefined"
                ) {
                  dgvTipoRechazo.option(
                    "dataSource",
                    addPrenda_rechazoNPrenda[addPrendaSel.idPrenda],
                  );
                } else {
                  dgvTipoRechazo.option("dataSource", datasource_rechazoNProd);
                }
              } else if (typeof dgvTipoRechazo !== "undefined") {
                dgvTipoRechazo.option("dataSource", datasource_rechazoNProd);
              }
              $("#popup_tiposRechazo").dxPopup("instance").show();
            }
            e.cancel = true;
          }
        },
        onToolbarPreparing: function (e) {
          $.each(e.toolbarOptions.items, function (_, item) {
            if (item.name === "saveButton") {
              item.visible = false;
            }
          });

          e.toolbarOptions.items.unshift(
            {
              location: "before",
              widget: "dxSelectBox",
              toolbar: "top",
              options: {
                elementAttr: {
                  id: "sbTipoProduccion_addPrenda",
                },
                readOnly: true,
                width: 260,
                dataSource: datasource_tipoProduccion,
                displayExpr: "denominacion",
                valueExpr: "idTipoProduccion",
                placeholder: getTrad("seleccioneTipoProd"),
              },
            },
            {
              location: "before",
              widget: "dxSelectBox",
              toolbar: "top",
              options: {
                elementAttr: {
                  id: "sbTurno",
                },
                width: 175,
                dataSource: datasource_turnos,
                displayExpr: "denominacion",
                valueExpr: "idTurno",
                placeholder: getTrad("seleccioneTurno"),
              },
            },
          );
        },
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });
    },
    toolbarItems: [
      {
        location: "after",
        widget: "dxButton",
        toolbar: "bottom",
        options: {
          text: getTrad("guardar"),
          type: "success",
          onClick: function (e) {
            if (!$("#sbTurno").dxSelectBox("instance").option("value")) {
              notify({
                message: getTrad("alerta_SeleccioneTurno"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
              });
            } else {
              if (
                !$("#dxDataGrid_prendasProdPedido_agrupado_añadir")
                  .dxDataGrid("instance")
                  .getController("editing")
                  .hasChanges()
              ) {
                notify({
                  message: getTrad("sinCambios"),
                  type: "error",
                  displayTime: "1500",
                  closeOnClick: true,
                });
              } else {
                //GUARDADO
                var rechazoNPrenda = $.extend(
                  true,
                  {},
                  addPrenda_rechazoNPrenda,
                );
                addPrendas_guardado = true;
                var dgvPrendasPedidoProd_agrupado = $(
                  "#dgvPrendasPedidoProd_agrupado",
                ).dxDataGrid("instance");
                var dxDataGrid_prendasProdPedido_agrupado_añadir = $(
                  "#dxDataGrid_prendasProdPedido_agrupado_añadir",
                ).dxDataGrid("instance");
                var gridAddPrenda_editedRows =
                  dxDataGrid_prendasProdPedido_agrupado_añadir
                    .getVisibleRows()
                    .filter(function (item) {
                      return (
                        item.data.producido +
                          item.data.rechazo +
                          item.data.retiro >
                        0
                      );
                    });

                var tblPrendasNProduccion = [];
                $.each(gridAddPrenda_editedRows, function (rowIndex, item) {
                  var prendaNProduccion = {};
                  prendaNProduccion.idPrenda = item.data.idPrenda;
                  prendaNProduccion.hora = formatDate_parameter(
                    pedidoSel.fecha,
                  );
                  prendaNProduccion.Producido = item.data.producido;
                  prendaNProduccion.Rechazo = item.data.rechazo;
                  prendaNProduccion.Retiro = item.data.retiro;

                  if (
                    LAVANDERIA.gestionaRechazo &&
                    prendaNProduccion.Rechazo > 0
                  ) {
                    prendaNProduccion.tblRechazoNProduccion =
                      typeof rechazoNPrenda[prendaNProduccion.idPrenda] !==
                      "undefined"
                        ? rechazoNPrenda[prendaNProduccion.idPrenda].filter(
                            function (item) {
                              delete item.denominacion;
                              return item.Cantidad > 0;
                            },
                          )
                        : null;
                  }

                  tblPrendasNProduccion.push(prendaNProduccion);
                });
                var produccion = {
                  fecha: pedidoSel.fecha,
                  idTipoProduccion: $("#sbTipoProduccion_addPrenda")
                    .dxSelectBox("instance")
                    .option("value"),
                  idEntidad: pedidoSel.idEntidad,
                  idLavanderia: LAVANDERIA.idLavanderia,
                  idTurno: $("#sbTurno")
                    .dxSelectBox("instance")
                    .option("value"),
                  tblPrendaNProduccion: tblPrendasNProduccion,
                };

                datasource_produccion
                  .store()
                  .insert(produccion)
                  .done(function () {
                    var dgvPrendasPedido =
                      $("#dgvPrendasPedido").dxDataGrid("instance");
                    dgvPrendasPedidoProd_agrupado.refresh(true);

                    dxDataGrid_prendasProdPedido_agrupado_añadir.refresh(true);
                    dgvPrendasPedido.refresh(true);
                  });
                $("#popup_addPrenda_prodPedido").dxPopup("instance").hide();
                dgvPrendasPedidoProd_agrupado.getScrollable().scrollTo(0);
              }
            }
          },
        },
      },
    ],
    onShown: function (args) {
      var dxDataGrid_prendasProdPedido_agrupado_añadir = $(
        "#dxDataGrid_prendasProdPedido_agrupado_añadir",
      ).dxDataGrid("instance");
      var sbTipoProduccion_value = $("#sbTipoProduccion")
        .dxSelectBox("instance")
        .option("value");
      $("#sbTipoProduccion_addPrenda")
        .dxSelectBox("instance")
        .option("value", sbTipoProduccion_value);
      dxDataGrid_prendasProdPedido_agrupado_añadir.editCell(0, "producido");
    },
    onHiding: function (args) {
      var dxDataGrid_prendasProdPedido_agrupado_añadir = $(
        "#dxDataGrid_prendasProdPedido_agrupado_añadir",
      ).dxDataGrid("instance");
      if (
        dxDataGrid_prendasProdPedido_agrupado_añadir
          .getController("editing")
          .hasChanges() &&
        addPrendas_guardado === false
      ) {
        args.cancel = true;
        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          // ACEPTAR
          [
            getTrad("aceptar"),
            function () {
              dxDataGrid_prendasProdPedido_agrupado_añadir.cancelEditData();
              args.component.hide();
            },
            "normal",
            "btnAceptar",
          ],
          // CANCELAR
          [getTrad("cancelar"), function () {}, "normal", "btnCancelar"],
        ]);
      }
      addPrendas_guardado = false;
    },
    onHidden: function (args) {
      var dxDataGrid_prendasProdPedido_agrupado_añadir = $(
        "#dxDataGrid_prendasProdPedido_agrupado_añadir",
      ).dxDataGrid("instance");
      dxDataGrid_prendasProdPedido_agrupado_añadir.cancelEditData();
      dxDataGrid_prendasProdPedido_agrupado_añadir.clearFilter();
      $("#sbTurno").dxSelectBox("instance").option("value", null);
      $("#sbTipoProduccion_addPrenda")
        .dxSelectBox("instance")
        .option("value", null);
      addPrenda_rechazoNPrenda = [];
    },
  });

  $("#Repartos #popup_tiposRechazo").dxPopup({
    showTitle: true,
    title: getTrad("tipoRechazo"), //TODO TRANS
    height: 400,
    width: "25%",
    contentTemplate: function () {
      return $("<div id='dgvTipoRechazo' />").dxDataGrid({
        //Datos
        dataSource: datasource_rechazoNProd,
        remoteOperations: true,
        //Propiedades
        height: "auto",
        columnsAutoWidth: true,
        headerFilter: {
          visible: true,
        },
        filterRow: {
          visible: true,
          applyFilter: "auto",
        },
        editing: {
          mode: "batch",
          allowUpdating: true,
          allowDeleting: false,
          useIcons: true,
          selectTextOnEditStart: true,
        },
        keyboardNavigation: {
          enterKeyAction: "moveFocus",
          enterKeyDirection: "row",
          editOnKeyPress: true,
        },
        paging: {
          enabled: false,
        },
        keyExpr: "idTipoRechazo",
        loadPanel: { enabled: false },
        columns: [
          {
            dataField: "idRechazoNProduccion",
            visible: false,
            allowEditing: false,
          },
          {
            dataField: "idPrendaNProduccion",
            visible: false,
            allowEditing: false,
          },
          {
            dataField: "idTipoRechazo",
            visible: false,
            allowEditing: false,
          },
          {
            dataField: "denominacion",
            caption: getTrad("denominacion"),
            sortOrder: "asc",
            alignment: "left",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowEditing: false,
          },
          {
            dataField: "Cantidad",
            caption: getTrad("cantidad"),
            cssClass: "dx-Cell_Editable",
            width: "40%",
            alignment: "center",
            allowHeaderFiltering: false,
            allowFiltering: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
              min: 0,
              max: 99999999,
            },
          },
        ],
        //Eventos
        onToolbarPreparing: function (e) {
          $.each(e.toolbarOptions.items, function (_, item) {
            if (item.name === "saveButton") {
              item.visible = false;
            }
          });

          e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxButton",
            locateInMenu: "auto",
            options: {
              text: getTrad("guardar"),
              type: "success",
              width: 120,
              onClick: function (args) {
                tipoRech_guardado = true;
                var dgvTipoRechazo =
                  $("#dgvTipoRechazo").dxDataGrid("instance");
                var dgvPrendasPedidoProd = $(
                  "#dgvPrendasPedidoProd",
                ).dxDataGrid("instance");
                var rechazoTotal = 0;

                if (prendasPedidoProdSel) {
                  var idPrendaNProduccion =
                    prendasPedidoProdSel.idPrendaNProduccion;

                  var tblRechazoNProduccion = [];
                  $.each(
                    dgvTipoRechazo.getVisibleRows(),
                    function (index, item) {
                      if (item.rowType === "data") {
                        var rechazoNProd = {};

                        if (
                          dgvTipoRechazo.cellValue(
                            item.rowIndex,
                            "idRechazoNProduccion",
                          )
                        ) {
                          rechazoNProd.idRechazoNProduccion =
                            dgvTipoRechazo.cellValue(
                              item.rowIndex,
                              "idRechazoNProduccion",
                            );
                        }

                        rechazoNProd.idPrendaNProduccion = idPrendaNProduccion;
                        rechazoNProd.idTipoRechazo = dgvTipoRechazo.cellValue(
                          item.rowIndex,
                          "idTipoRechazo",
                        );
                        rechazoNProd.Cantidad = dgvTipoRechazo.cellValue(
                          item.rowIndex,
                          "Cantidad",
                        );
                        rechazoTotal += dgvTipoRechazo.cellValue(
                          item.rowIndex,
                          "Cantidad",
                        );
                        tblRechazoNProduccion.push(rechazoNProd);
                      }
                    },
                  );

                  var tblPrendaNProduccion = {
                    idPrendaNProduccion: idPrendaNProduccion,
                    idPrenda: prendasPedidoProdSel.idPrenda,
                    idProduccion: prendasPedidoProdSel.idProduccion,
                    Producido: prendasPedidoProdSel.Producido,
                    Rechazo: rechazoTotal,
                    Retiro: prendasPedidoProdSel.Retiro,
                    tblRechazoNProduccion: tblRechazoNProduccion,
                  };

                  if (tblRechazoNProduccion.length > 0) {
                    datasource_rechazoNProd
                      .store()
                      .update(idPrendaNProduccion, tblPrendaNProduccion)
                      .done(function () {
                        $("#popup_tiposRechazo").dxPopup("instance").hide();
                        dgvPrendasPedidoProd.refresh(true);
                        $("#dgvPrendasPedidoProd_agrupado")
                          .dxDataGrid("instance")
                          .refresh(true);
                        $("#dgvPrendasPedido")
                          .dxDataGrid("instance")
                          .refresh(true);

                        var gridProd_rowIndex =
                          dgvPrendasPedidoProd.getRowIndexByKey(
                            prendasPedidoProdSel.idPrendaNProduccion,
                          );
                        dgvPrendasPedidoProd.editCell(
                          gridProd_rowIndex,
                          "Retiro",
                        );
                      });
                    return;
                  } else {
                    notify({
                      message: getTrad("sinCambios"),
                      type: "error",
                      displayTime: "1500",
                      closeOnClick: true,
                    });
                  }
                } else {
                  var dxDataGrid_prendasProdPedido_agrupado_añadir = $(
                    "#dxDataGrid_prendasProdPedido_agrupado_añadir",
                  ).dxDataGrid("instance");

                  addPrenda_rechazoNPrenda[addPrendaSel.idPrenda] = [];

                  $.each(
                    dgvTipoRechazo.getVisibleRows(),
                    function (index, item) {
                      if (item.rowType === "data") {
                        var rechazoNPrenda = {};
                        rechazoNPrenda.idTipoRechazo = dgvTipoRechazo.cellValue(
                          item.rowIndex,
                          "idTipoRechazo",
                        );
                        rechazoNPrenda.denominacion = dgvTipoRechazo.cellValue(
                          item.rowIndex,
                          "denominacion",
                        );
                        rechazoNPrenda.Cantidad = dgvTipoRechazo.cellValue(
                          item.rowIndex,
                          "Cantidad",
                        );

                        if (
                          dgvTipoRechazo.cellValue(item.rowIndex, "Cantidad") >
                          0
                        ) {
                          rechazoTotal += dgvTipoRechazo.cellValue(
                            item.rowIndex,
                            "Cantidad",
                          );
                        }
                        addPrenda_rechazoNPrenda[addPrendaSel.idPrenda].push(
                          rechazoNPrenda,
                        );
                      }
                    },
                  );

                  var gridAddPrenda_rowIndex =
                    dxDataGrid_prendasProdPedido_agrupado_añadir.getRowIndexByKey(
                      addPrendaSel.idPrenda,
                    );
                  dxDataGrid_prendasProdPedido_agrupado_añadir.cellValue(
                    gridAddPrenda_rowIndex,
                    "rechazo",
                    rechazoTotal,
                  );
                  dxDataGrid_prendasProdPedido_agrupado_añadir.editCell(
                    gridAddPrenda_rowIndex,
                    "retiro",
                  );
                  $("#popup_tiposRechazo").dxPopup("instance").hide();
                }
              },
            },
          });
        },
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });
    },
    ////EVENTOS
    onShown: function (args) {
      $("#dgvTipoRechazo").dxDataGrid("instance").editCell(0, "Cantidad");
    },
    onHiding: function (args) {
      var gridTipoRechazo = $("#dgvTipoRechazo").dxDataGrid("instance");
      if (
        gridTipoRechazo.getController("editing").hasChanges() &&
        tipoRech_guardado === false
      ) {
        args.cancel = true;
        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          // ACEPTAR
          [
            getTrad("aceptar"),
            function () {
              gridTipoRechazo.cancelEditData();
              args.component.hide();
            },
            "normal",
            "btnAceptar",
          ],
          // CANCELAR
          [getTrad("cancelar"), function () {}, "normal", "btnCancelar"],
        ]);
      }
      tipoRech_guardado = false;
    },
    onHidden: function (args) {
      $("#dgvTipoRechazo").dxDataGrid("instance").cancelEditData();
      prendasPedidoProdSel = null;
    },
  });

  if (
    typeof $("#Repartos #popup_prendasReparto").data("dxPopup") !== "undefined"
  ) {
    $("#Repartos #popup_prendasReparto").dxPopup("instance").dispose();
  }

  $("#Repartos #popup_prendasReparto").dxPopup({
    showTitle: true,
    title: getTrad("prendasReparto"),
    height: "80%",
    contentTemplate: function () {
      return $("<div id='dgvPrendasReparto' />").dxDataGrid({
        //Datos
        dataSource: datasource_prendasReparto_peticion.items(),
        remoteOperations: true,
        //Propiedades
        height: "100%",
        columnsAutoWidth: true,
        headerFilter: {
          visible: true,
        },
        filterRow: {
          visible: true,
          applyFilter: "auto",
        },
        editing: {
          mode: "batch",
          allowUpdating: true,
          allowDeleting: true,
          useIcons: true,
          selectTextOnEditStart: true,
        },
        keyboardNavigation: {
          enterKeyAction: "moveFocus",
          enterKeyDirection: "row",
          editOnKeyPress: true,
        },
        paging: {
          enabled: false,
        },
        //Columnas
        columns: [
          {
            dataField: "tblPrenda.codigoPrenda",
            caption: getTrad("codigo"),
            sortOrder: "asc",
            width: "10%",
            alignment: "center",
            allowHeaderFiltering: false,
            allowEditing: false,
            editorOptions: { showClearButton: true },
          },
          {
            dataField: "tblPrenda.denominacion",
            caption: getTrad("denominacion"),
            allowEditing: false,
            editorOptions: { showClearButton: true },
          },
          {
            dataField: "peticion",
            caption: getTrad("peticion"),
            width: "10%",
            alignment: "center",
            allowEditing: false,
            allowHeaderFiltering: false,
            allowFiltering: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            calculateCellValue: function (e) {
              var cantidad = typeof (e.cantidad !== "undefined")
                ? e.cantidad
                : 0;
              if (typeof e.tblPrenda !== "undefined") {
                var udsPeticion,
                  resultado = 0;

                //#region Paso la petición y cantidad a uds y al final lo transformo a bacs si es necesario
                if (repartoSel !== null && repartoSel.pedidoProd) {
                  // POR UDS
                  udsPeticion = e.peticion;
                  resultado = udsPeticion - cantidad;
                } else {
                  if (parseInt(e.tblPrenda.tblElemTransPedido.codigo) === 1)
                    // POR UDS
                    udsPeticion = e.peticion;
                  else if (
                    parseInt(e.tblPrenda.tblElemTransPedido.codigo) === 2
                  )
                    // BAC/SACA
                    udsPeticion = e.peticion * e.tblPrenda.udsXBacPedido;

                  if (parseInt(e.tblPrenda.tblElemTransReparto.codigo) === 1)
                    // POR UDS
                    resultado = udsPeticion - cantidad;
                  else if (
                    parseInt(e.tblPrenda.tblElemTransReparto.codigo) === 2
                  )
                    // BAC/SACA
                    resultado = Math.ceil(
                      (udsPeticion - cantidad * e.tblPrenda.udsXBacReparto) /
                        e.tblPrenda.udsXBacPedido,
                    );
                }
                return resultado >= 0 ? resultado : 0;
                //#endregion
              }
            },
          },
          {
            dataField: "tblPrenda.tblElemTransPedido.codigo",
            caption: getTrad("elementoTransporte_abr"),
            width: "70",
            allowEditing: false,
            allowHeaderFiltering: false,
            allowFiltering: false,
            alignment: "center",
            cssClass: "p-0",
            cellTemplate: function (container, options) {
              var contenedor = $("<div />")
                .addClass("container_spanCentrado")
                .css("padding-left", "10px");

              var svg;
              var htmlTooltip = "";
              if (
                parseInt(options.data.tblPrenda.tblElemTransPedido.codigo) ===
                  1 ||
                repartoSel.pedidoProd
              ) {
                // POR UDS
                svg = svg_elemTrans_ud.clone();
                htmlTooltip = "<p>" + getTrad("porUds") + "</p>";
              } else if (
                parseInt(options.data.tblPrenda.tblElemTransPedido.codigo) === 2
              ) {
                // BAC/SACA
                svg = svg_elemTrans_bac.clone();
                htmlTooltip =
                  "<p>" +
                  getTrad("bacSaca") +
                  ": " +
                  options.data.tblPrenda.udsXBacPedido +
                  "</p>";
              }

              svg
                .css("height", "20px")
                .css("width", "25px")
                .css("cursor", "pointer")
                .appendTo(contenedor);

              tooltipControl_creacion(contenedor, htmlTooltip);

              return contenedor;
            },
          },
          {
            dataField: "cantidad",
            caption: getTrad("cantidad"),
            width: "10%",
            alignment: "center",
            dataType: "number",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
            },
          },
          {
            dataField: "rechazo",
            caption: getTrad("rechazo"),
            width: "10%",
            alignment: "center",
            dataType: "number",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            visible: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
            },
          },
          {
            dataField: "retiro",
            caption: getTrad("retiro"),
            width: "10%",
            alignment: "center",
            dataType: "number",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            visible: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
            },
          },
          {
            dataField: "tblPrenda.tblElemTransReparto.codigo",
            caption: getTrad("elementoTransporte_abr"),
            width: "70",
            allowEditing: false,
            allowHeaderFiltering: false,
            allowFiltering: false,
            alignment: "center",
            cssClass: "p-0",
            cellTemplate: function (container, options) {
              var contenedor = $("<div />")
                .addClass("container_spanCentrado")
                .css("padding-left", "10px");

              var svg;
              var htmlTooltip = "";
              if (
                parseInt(options.data.tblPrenda.tblElemTransReparto.codigo) ===
                  1 ||
                repartoSel.pedidoProd
              ) {
                // POR UDS
                svg = svg_elemTrans_ud.clone();
                htmlTooltip = "<p>" + getTrad("porUds") + "</p>";
              } else if (
                parseInt(options.data.tblPrenda.tblElemTransReparto.codigo) ===
                2
              ) {
                // BAC/SACA
                svg = svg_elemTrans_bac.clone();
                htmlTooltip =
                  "<p>" +
                  getTrad("bacSaca") +
                  ": " +
                  options.data.tblPrenda.udsXBacReparto +
                  "</p>";
              }

              svg
                .css("height", "20px")
                .css("width", "25px")
                .css("cursor", "pointer")
                .appendTo(contenedor);

              tooltipControl_creacion(contenedor, htmlTooltip);

              return contenedor;
            },
          },
          {
            dataField: "total",
            caption: getTrad("total"),
            width: "10%",
            alignment: "center",
            allowEditing: false,
            allowHeaderFiltering: false,
            allowSorting: false,
            allowFiltering: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            calculateCellValue: function (e) {
              var cantidad = typeof (e.cantidad !== "undefined")
                  ? e.cantidad
                  : 0,
                rechazo = typeof (e.rechazo !== "undefined") ? e.rechazo : 0,
                retiro = typeof (e.retiro !== "undefined") ? e.retiro : 0;

              if (typeof e.tblPrenda !== "undefined") {
                if (repartoSel.pedidoProd)
                  // POR UDS
                  return cantidad + rechazo + retiro;
                else if (parseInt(e.tblPrenda.tblElemTransReparto.codigo) === 1)
                  // POR UDS
                  return cantidad + rechazo + retiro;
                else if (parseInt(e.tblPrenda.tblElemTransReparto.codigo) === 2)
                  // BAC/SACA
                  return (
                    (cantidad + rechazo + retiro) * e.tblPrenda.udsXBacReparto
                  );
              }
            },
          },
        ],
        //Eventos
        onToolbarPreparing: function (e) {
          e.toolbarOptions.items.unshift(
            {
              location: "after",
              template: (arg) => {
                $(e.element)
                  .find(".dx-toolbar-items-container")
                  .css("height", 45);
                $(e.element).find(".dx-toolbar-after").css({
                  display: "flex",
                  "align-items": "end",
                });

                var container = $("<div>");

                var title = $(
                  "<div style='font-weight:500; padding-bottom:4px;color:var(--secondary);'>" +
                    getTrad("numCarros") +
                    ":</div>",
                );
                var numCarros = $(
                  "<div id='dxNumberBox_prendasReparto' />",
                ).dxNumberBox({
                  placeholder: "0",
                  value: repartoSel.numCarros,
                  max: 255,
                });

                if (
                  LAVANDERIA.idLavanderia === 18 ||
                  LAVANDERIA.idLavanderia === 14
                ) {
                  numCarros.dxValidator({
                    validationGroup: "numCarrosValidatorRepartos",
                    validationRules: [
                      {
                        type: "required",
                      },
                    ],
                  });
                }
                title.appendTo(container);
                numCarros.appendTo(container);

                return container;
              },
            },
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              locateInMenu: "auto",
              options: {
                text: getTrad("limpiarFiltro"),
                icon: " icon_EliminarFiltros",
                onClick: function () {
                  e.component.clearFilter();
                  notify({
                    message: getTrad("aviso_C_FiltroRestaurado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                },
              },
            },
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              options: {
                icon: "refresh",
                text: getTrad("actualizar"),
                onClick: function () {
                  e.component.refresh(true);
                },
              },
            },
          );

          $.each(e.toolbarOptions.items, function (_, item) {
            if (item.name === "saveButton") {
              item.options.onContentReady = function (e) {
                e.component.option("disabled", false);
              };
              item.options.onClick = function () {
                var result =
                  LAVANDERIA.idLavanderia === 18 ||
                  LAVANDERIA.idLavanderia === 14
                    ? validationEngine.validateGroup(
                        "numCarrosValidatorRepartos",
                      )
                    : null;
                if ((result && result.isValid) || result === null) {
                  e.component.option("disabled", true);
                  var grid = e.component;

                  const numCarros = $("#dxNumberBox_prendasReparto")
                    .dxNumberBox("instance")
                    .option("value");
                  repartoSel.numCarros = numCarros;
                  $.when(
                    datasource_repartos
                      .store()
                      .update(repartoSel.idReparto, {
                        numCarros: repartoSel.numCarros,
                      }),
                    grid.saveEditData(),
                  ).then(function () {
                    notify({
                      message: getTrad("aviso_C_RegistroActualizado"),
                      type: "success",
                      displayTime: "600",
                      closeOnClick: true,
                    });

                    setTimeout(() => {
                      e.component.option("disabled", false);
                    }, 0);

                    context_tblPrendaNReparto
                      .invoke("OverrideMasivo", {}, "POST")
                      .done(function () {
                        // e.component.refresh();
                        var dgvPedidos =
                          $("#dgvPedidos").dxDataGrid("instance");
                        dgvPedidos.option("loadPanel.enabled", false);
                        dgvPedidos.refresh();
                        dgvPedidos.option("loadPanel.enabled", true);
                      });
                  });
                }
              };
            }
          });
        },
        //Estilos
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        template: function () {
          var container = $("<div>");
          var title = $(
            "<div style='padding-top: 8px; padding-right:8px; float:left'>" +
              getTrad("pedidoCliente") +
              ":</div>",
          );
          var dxDropDownBox = $(
            "<div id='dxDropDownBox_dgvPrendasReparto_pedidoCliente' style='float:left'>",
          ).dxDropDownBox({
            valueExpr: "idPedido",
            value: repartoSel.idPedidoCliente,
            width: 350,
            deferRendering: false,
            remoteOperations: true,
            placeholder: "Asocia un pedido",
            displayExpr: function (data) {
              return (
                data &&
                data.codigoPedido +
                  " - " +
                  data.denoEntidad +
                  " - " +
                  formatDate(data.fecha)
              );
            },
            showClearButton: true,
            dropDownOptions: {
              title: "Selección de pedido",
              width: 800,
              height: 500,
              position: { my: "center", at: "center", of: window },
              showTitle: true,
              showCloseButton: true,
            },
            dataSource: new DataSource({
              store: new ODataStore({
                url:
                  connectionConstants.ODATA_URL +
                  "repartos_spSelectPedidosCliente",
                key: "idPedido",
                errorHandler: function (error) {
                  errorHandler(error, COMPONENT);
                },
                beforeSend: function (request) {
                  request.headers = { ...authHeader() };
                  request.params.idEntidad = repartoSel
                    ? repartoSel.idEntidad
                    : -1;
                },
                version: 4,
              }),
            }),
            contentTemplate: function (dxDropDownBox) {
              var value = dxDropDownBox.component.option("value");
              var dxDataGrid_PedidosSel = $(
                "<div id='dxDataGrid_PedidoClienteSel_Repartos'>",
              ).dxDataGrid({
                //Datos
                dataSource: dxDropDownBox.component.option("dataSource"),
                height: "100%",
                remoteOperations: true,
                //Propiedades
                columnsAutoWidth: true,
                headerFilter: {
                  visible: true,
                },
                filterRow: {
                  visible: true,
                  applyFilter: "auto",
                },
                filterValue: ["asignadoPedCliente", "=", 0],
                searchPanel: {
                  visible: true,
                  width: 240,
                  placeholder: getTrad("busqueda"),
                },
                paging: {
                  pageSize: 10,
                },
                scrolling: { mode: "infinite" },
                selection: { mode: "single" },
                columns: [
                  {
                    dataField: "codigoPedido",
                    caption: getTrad("codigo"),
                    width: "80px",
                    allowEditing: false,
                    allowHeaderFiltering: false,
                  },
                  {
                    dataField: "fecha",
                    caption: getTrad("fecha"),
                    dataType: "date",
                    format: "dd/MM/yyyy",
                    width: "125px",
                    sortOrder: "desc",
                    editorOptions: {
                      showClearButton: true,
                    },
                    allowHeaderFiltering: false,
                  },
                  {
                    dataField: "denoEntidad",
                    caption: getTrad("cliente"),
                    minWidth: 150,
                    headerFilter: {
                      allowSearch: true,
                    },
                    editorOptions: {
                      showClearButton: true,
                    },
                  },
                  "observacionesPedido",
                  {
                    dataField: "asignadoPedCliente",
                    visible: false,
                  },
                ],
                selectedRowKeys: [value],
                onSelectionChanged: function (selectedItems) {
                  var keys = selectedItems.selectedRowKeys,
                    hasSelection = keys.length;

                  if (hasSelection && keys[0] !== repartoSel.idPedidoCliente) {
                    dxDropDownBox.component.option("value", keys[0]);
                  }
                },
                onToolbarPreparing: function (option) {
                  option.toolbarOptions.items.unshift(
                    {
                      location: "before",
                      widget: "dxCheckBox",
                      options: {
                        text: getTrad("mostrarPedidosAsignados"),
                        value: false,
                        onValueChanged: function (e) {
                          if (e.value === 0) {
                            option.component.option("filterValue", [
                              "asignadoPedCliente",
                              "=",
                              0,
                            ]);
                          } else {
                            option.component.option("filterValue", []);
                          }
                        },
                      },
                    },
                    {
                      location: "after",
                      widget: "dxButton",
                      showText: "inMenu",
                      options: {
                        icon: "refresh",
                        text: getTrad("actualizar"),
                        onClick: function () {
                          option.component.refresh(true);
                        },
                      },
                    },
                  );
                },
                //Estilos
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: true,
              });

              dxDropDownBox.component.on("valueChanged", function (args) {
                if (args.value === repartoSel.idPedidoCliente) {
                  return;
                }

                var dgvRepartos = $("#dgvRepartos").dxDataGrid("instance");
                dgvRepartos.cellValue(
                  dgvRepartos.getRowIndexByKey(repartoSel.idReparto),
                  "idPedidoCliente",
                  args.value,
                );
                dgvRepartos.option("loadPanel.enabled", false);

                dgvRepartos.saveEditData().done(function () {
                  dgvRepartos.option("loadPanel.enabled", true);

                  args.component.close();
                  notify({
                    message: "Se ha modificado el reparto",
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });

                  var dgvPedidos = $("#dgvPedidos").dxDataGrid("instance");
                  dgvPedidos.option("loadPanel.enabled", false);
                  dgvPedidos.refresh();
                  dgvPedidos.option("loadPanel.enabled", true);
                });
              });

              return dxDataGrid_PedidosSel;
            },
            onOpened: function () {
              var dxDataGrid_PedidoClienteSel_Repartos = $(
                "#dxDataGrid_PedidoClienteSel_Repartos",
              ).dxDataGrid("instance");
              dxDataGrid_PedidoClienteSel_Repartos.option("filterValue", [
                "asignadoPedCliente",
                "=",
                0,
              ]);
              dxDataGrid_PedidoClienteSel_Repartos.repaint();
            },
          });

          if (repartoSel.pedidoProd) {
            title.appendTo(container);
            dxDropDownBox.appendTo(container);
          }

          return container;
        },
      },
    ],
    onShowing: () => {
      const { numCarros } = { ...repartoSel };
      if (LAVANDERIA.idLavanderia === 18 || LAVANDERIA.idLavanderia === 14)
        $("#dxNumberBox_prendasReparto").dxValidator("instance").reset();
      $("#dxNumberBox_prendasReparto")
        .dxNumberBox("instance")
        .option("value", numCarros);
    },
    onHiding: function (e) {
      repartoSel = null;
    },
  });

  $("#Repartos #popup_datosReparto").dxPopup({
    showTitle: true,
    title: getTrad("datosReparto"),
    height: 590,
    width: 800,
    contentTemplate: function () {
      return $("<div>").dxBox({
        direction: "row",
        align: "space-around",
        crossAlign: "stretch",
        items: [
          {
            ratio: 1,
            template: function () {
              var container = $("<div>");
              var title = $(
                "<div class='font-size-xs pb-3'>" +
                  getTrad("preg_FechaRealizarReparto") +
                  "</div>",
              );
              var calendar = $("<div id='dxCalendar_fechaReparto'>").dxCalendar(
                {
                  value: new Date(),
                  disabled: false,
                  firstDayOfWeek: 1,
                  zoomLevel: "month",
                  minZoomLevel: "month",
                  cellTemplate: function (e) {
                    let tooltip = $("#dxTooltip_Repartos").dxTooltip(
                      "instance",
                    );
                    let result = $("<div>")
                      .addClass("id_" + formatDate_noTime_parameter(e.date))
                      .text(e.text)
                      .on("mouseenter", function () {
                        let fechasDeshabilitadas =
                          dxDateBox_disabledDates.filter(
                            (fecha) =>
                              formatDate_noTime_parameter(fecha) ==
                              formatDate_noTime_parameter(e.date),
                          );
                        if (fechasDeshabilitadas.length > 0) {
                          tooltip.option({
                            target:
                              ".id_" + formatDate_noTime_parameter(e.date),
                            contentTemplate: function (contentElement) {
                              contentElement.html(
                                getTrad("factBloqueadaEntiFecha"),
                              );
                            },
                          });
                          tooltip.show();
                        }
                      })
                      .on("mouseleave", function () {
                        tooltip.hide();
                      });

                    return result;
                  },
                },
              );

              title.appendTo(container);
              calendar.appendTo(container);

              return container;
            },
          },
          {
            ratio: 1,
            template: function () {
              return $("<div>").dxBox({
                direction: "col",
                width: "100%",
                height: 440,
                items: [
                  {
                    baseSize: 90,
                    template: function () {
                      var container = $("<div>");
                      var title = $(
                        "<div class='font-size-xs pb-3'>" +
                          getTrad("numCarros") +
                          "</div>",
                      );
                      var numCarros = $(
                        "<div id='dxNumberBox_prendasPedido' />",
                      ).dxNumberBox({
                        placeholder: "0",
                        value: null,
                        max: 255,
                        onValueChanged: function (e) {
                          pedidoSel.numCarros = e.value;
                        },
                      });

                      if (
                        LAVANDERIA.idLavanderia === 18 ||
                        LAVANDERIA.idLavanderia === 14
                      ) {
                        numCarros.dxValidator({
                          validationGroup: "numCarrosValidator",
                          validationRules: [
                            {
                              type: "required",
                            },
                          ],
                        });
                      }

                      title.appendTo(container);
                      numCarros.appendTo(container);
                      return container;
                    },
                  },
                  {
                    ratio: 1,
                    template: function () {
                      var container = $("<div>");
                      var title = $(
                        "<div class='font-size-xs pb-3'>" +
                          getTrad("observacionesReparto") +
                          "</div>",
                      );
                      var textArea = $(
                        "<div id='dxTextArea_observacionesReparto'>",
                      ).dxTextArea({
                        autoResizeEnabled: true,
                        height: 180,
                        value: "",
                      });

                      title.appendTo(container);
                      textArea.appendTo(container);

                      return container;
                    },
                  },
                  {
                    baseSize: 130,
                    shrink: 0,
                    ratio: 0,
                    template: function () {
                      var container = $(
                        "<div id='popup_datosReparto_contenedorPedidoCliente' style='padding-top: 15px'>",
                      );

                      if (
                        !pedidoSel ||
                        (pedidoSel && pedidoSel.pedidoProd === 1)
                      ) {
                        var title = $(
                          "<div class='font-size-xs pb-3'>" +
                            getTrad("asociaPedidoCliente") +
                            "</div>",
                        );
                        var dxDropDownBox = $(
                          "<div id='dxDropDownBox_pedidoCliente'>",
                        ).dxDropDownBox({
                          valueExpr: "idPedido",
                          deferRendering: false,
                          remoteOperations: true,
                          placeholder: "Asocia un pedido",
                          displayExpr: function (data) {
                            return (
                              data &&
                              data.codigoPedido +
                                " - " +
                                data.denoEntidad +
                                " - " +
                                formatDate(data.fecha)
                            );
                          },
                          showClearButton: true,
                          dropDownOptions: {
                            title: "Selección de pedido",
                            width: 800,
                            height: 500,
                            position: {
                              my: "center",
                              at: "center",
                              of: window,
                            },
                            showTitle: true,
                            showCloseButton: true,
                          },
                          dataSource: new DataSource({
                            store: new ODataStore({
                              url:
                                connectionConstants.ODATA_URL +
                                "repartos_spSelectPedidosCliente",
                              key: "idPedido",
                              errorHandler: function (error) {
                                errorHandler(error, COMPONENT);
                              },
                              beforeSend: function (request) {
                                request.headers = { ...authHeader() };
                                request.params.idEntidad = pedidoSel
                                  ? pedidoSel.idEntidad
                                  : $("#popup_selCompaEnti_lista_entidades")
                                      .dxList("instance")
                                      .option("selectedItemKeys")[0];
                              },
                              version: 4,
                            }),
                          }),
                          contentTemplate: function (dxDropDownBox) {
                            var value = dxDropDownBox.component.option("value");
                            var dxDataGrid_PedidosSel = $(
                              "<div id='dxDataGrid_PedidoClienteSel'>",
                            ).dxDataGrid({
                              //Datos
                              dataSource:
                                dxDropDownBox.component.option("dataSource"),
                              height: "100%",
                              remoteOperations: true,
                              //Propiedades
                              columnsAutoWidth: true,
                              headerFilter: {
                                visible: true,
                              },
                              filterRow: {
                                visible: true,
                                applyFilter: "auto",
                              },
                              filterValue: ["asignadoPedCliente", "=", 0],
                              searchPanel: {
                                visible: true,
                                width: 240,
                                placeholder: getTrad("busqueda"),
                              },
                              paging: {
                                pageSize: 10,
                              },
                              scrolling: { mode: "infinite" },
                              selection: { mode: "single" },
                              columns: [
                                {
                                  dataField: "codigoPedido",
                                  caption: getTrad("codigo"),
                                  width: "80px",
                                  allowEditing: false,
                                  allowHeaderFiltering: false,
                                },
                                {
                                  dataField: "fecha",
                                  caption: getTrad("fecha"),
                                  dataType: "date",
                                  format: "dd/MM/yyyy",
                                  width: "125px",
                                  sortOrder: "desc",
                                  editorOptions: {
                                    showClearButton: true,
                                  },
                                  allowHeaderFiltering: false,
                                },
                                {
                                  dataField: "denoEntidad",
                                  caption: getTrad("cliente"),
                                  minWidth: 150,
                                  headerFilter: {
                                    allowSearch: true,
                                  },
                                  editorOptions: {
                                    showClearButton: true,
                                  },
                                },
                                {
                                  dataField: "observacionesPedido",
                                  caption: "Observaciones pedido",
                                  alignment: "left",
                                  cellTemplate: function (
                                    cellElement,
                                    cellInfo,
                                  ) {
                                    var container = $("<div />")
                                      .text(cellInfo.text)
                                      .css("position", "relative");
                                    let text;

                                    if (cellInfo.data.isCerradoFactEntidad) {
                                      text = getTrad("factBloqueadaEntiFecha");
                                      container.append(
                                        svg_lock_outline
                                          .clone()
                                          .css("height", "25px")
                                          .css("width", "25px")
                                          .css("cursor", "pointer")
                                          .css("position", "absolute")
                                          .css("right", "0px")
                                          .css("top", "-12px"),
                                      );

                                      tooltipControl_creacion(container, text);
                                    }
                                    cellElement.append(container);
                                  },
                                },
                                {
                                  dataField: "asignadoPedCliente",
                                  visible: false,
                                },
                              ],
                              selectedRowKeys: [value],
                              onSelectionChanged: function (selectedItems) {
                                var selectedData =
                                  selectedItems.selectedRowsData.length > 0
                                    ? selectedItems.selectedRowsData[0]
                                    : null;
                                var keys = selectedItems.selectedRowKeys,
                                  hasSelection = keys.length;
                                if (
                                  selectedData != null &&
                                  selectedData.isCerradoFactEntidad
                                ) {
                                  selectedItems.currentSelectedRowKeys.splice(
                                    0,
                                    selectedItems.currentSelectedRowKeys.length,
                                  );
                                  selectedItems.component.deselectRows(keys);
                                } else {
                                  dxDropDownBox.component.option(
                                    "value",
                                    hasSelection ? keys[0] : null,
                                  );
                                }
                              },
                              onToolbarPreparing: function (option) {
                                option.toolbarOptions.items.unshift(
                                  {
                                    location: "before",
                                    widget: "dxCheckBox",
                                    options: {
                                      text: getTrad("mostrarPedidosAsignados"),
                                      value: false,
                                      onValueChanged: function (e) {
                                        if (e.value === 0) {
                                          option.component.option(
                                            "filterValue",
                                            ["asignadoPedCliente", "=", 0],
                                          );
                                        } else {
                                          option.component.option(
                                            "filterValue",
                                            [],
                                          );
                                        }
                                      },
                                    },
                                  },
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    options: {
                                      icon: "refresh",
                                      text: getTrad("actualizar"),
                                      onClick: function () {
                                        option.component.refresh(true);
                                      },
                                    },
                                  },
                                );
                              },
                              //Estilos
                              showColumnLines: false,
                              showRowLines: true,
                              rowAlternationEnabled: true,
                            });

                            dxDropDownBox.component.on(
                              "valueChanged",
                              function (args) {
                                dxDataGrid_PedidosSel
                                  .dxDataGrid("instance")
                                  .selectRows(args.value, false);
                                args.component.close();
                              },
                            );

                            return dxDataGrid_PedidosSel;
                          },
                          onOpened: function () {
                            var dxDataGrid_PedidoClienteSel = $(
                              "#dxDataGrid_PedidoClienteSel",
                            ).dxDataGrid("instance");
                            dxDataGrid_PedidoClienteSel.option("filterValue", [
                              "asignadoPedCliente",
                              "=",
                              0,
                            ]);
                            dxDataGrid_PedidoClienteSel.repaint();
                          },
                        });

                        title.appendTo(container);
                        dxDropDownBox.appendTo(container);
                      }

                      return container;
                    },
                  },
                ],
              });
            },
          },
        ],
      });
    },
    onShown: function () {
      let dxCalendar_fechaReparto = $("#dxCalendar_fechaReparto").dxCalendar(
        "instance",
      );
      let idEntidad = pedidoSel
        ? pedidoSel.idEntidad
        : $("#popup_selCompaEnti_lista_entidades")
            .dxList("instance")
            .option("selectedItemKeys")[0];
      deshabilitarFechasFacturacion(
        idEntidad,
        dxCalendar_fechaReparto.option("value"),
        dxCalendar_fechaReparto,
      );
    },
    onHiding: function (e) {
      $("#btnGenerarRepartos_PrendasPedido")
        .dxButton("instance")
        .option("disabled", false);
      $("#btnGenrarReparto").dxButton("instance").option("disabled", false);
      $("#dxNumberBox_prendasPedido")
        .dxNumberBox("instance")
        .option("value", null);
      if (LAVANDERIA.idLavanderia === 18 || LAVANDERIA.idLavanderia === 14)
        $("#dxNumberBox_prendasPedido").dxValidator("instance").reset();
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          elementAttr: {
            id: "btnGenrarReparto",
          },
          text: getTrad("generarReparto"),
          icon: "check",
          type: "success",
          onClick: function (e) {
            e.component.option("disabled", true);
            generarReparto(e);
          },
        },
      },
    ],
  });

  $("#Repartos #popup_deletePedido").dxPopup({
    width: 400,
    height: 125,
    showTitle: false,
    contentTemplate: function () {
      return $(
        "<div>¿Que acción desea realizar sobre el pedido?</div>",
      ).addClass("font-size-xs");
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "before",
        template: function (item) {
          var container = $("<div></div>");

          $("<div id='popup_deletePedido_dxButton_eliminar'>")
            .dxButton({
              text: "Eliminar",
              type: "danger",
              onClick: function (options) {
                $("#popup_deletePedido").dxPopup("instance").hide();
                var grid = $("#dgvPedidos").dxDataGrid("instance");
                grid.deleteRow(grid.getRowIndexByKey(pedidoSel.idPedido));
              },
            })
            .appendTo(container);

          $("<div>")
            .dxTooltip({
              target: "#popup_deletePedido_dxButton_eliminar",
              height: "42px",
              showEvent: "dxhoverstart",
              hideEvent: "dxhoverend",
              position: "bottom",
              contentTemplate: function (contentElement) {
                contentElement.html("<p>Elimina el pedido permanentemente</p>");
              },
              animation: {
                show: {
                  type: "pop",
                  from: {
                    scale: 0.1,
                    opacity: 0,
                  },
                  to: {
                    opacity: 1,
                    scale: 1,
                  },
                },
                hide: {
                  type: "pop",
                  from: {
                    scale: 1,
                    opacity: 1,
                  },
                  to: {
                    opacity: 0,
                    scale: 0.1,
                  },
                },
              },
            })
            .appendTo(container);

          return container;
        },
      },
      {
        toolbar: "bottom",
        location: "before",
        template: function (item) {
          var container = $("<div></div>");

          $("<div id='popup_deletePedido_dxButton_cerrar'>")
            .dxButton({
              text: "Cerrar pedido",
              onClick: function (e) {
                var grid = $("#dgvPedidos").dxDataGrid("instance");
                grid.cellValue(
                  grid.getRowIndexByKey(pedidoSel.idPedido),
                  "isCerrado",
                  true,
                );
                grid.saveEditData().done(function () {
                  $("#popup_deletePedido").dxPopup("instance").hide();
                  notify({
                    message: "Se ha cerrado el pedido",
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                  grid.refresh();
                });
              },
            })
            .appendTo(container);

          $("<div>")
            .dxTooltip({
              target: "#popup_deletePedido_dxButton_cerrar",
              height: "42px",
              showEvent: "dxhoverstart",
              hideEvent: "dxhoverend",
              position: "bottom",
              contentTemplate: function (contentElement) {
                contentElement.html(
                  "<p>Cierra el pedido y lo oculta de la lista de pedidos pendientes</p>",
                );
              },
              animation: {
                show: {
                  type: "pop",
                  from: {
                    scale: 0.1,
                    opacity: 0,
                  },
                  to: {
                    opacity: 1,
                    scale: 1,
                  },
                },
                hide: {
                  type: "pop",
                  from: {
                    scale: 1,
                    opacity: 1,
                  },
                  to: {
                    opacity: 0,
                    scale: 0.1,
                  },
                },
              },
            })
            .appendTo(container);

          return container;
        },
      },
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: "Cancelar",
          onClick: function (e) {
            $("#popup_deletePedido").dxPopup("instance").hide();
          },
        },
      },
    ],
  });

  //POPUP SELECCIÓN COMPAÑÍA ENTIDAD

  $("#Repartos #popup_selCompaEnti").dxPopup({
    showTitle: true,
    title: getTrad("seleccioneEntidad"),
    width: "800px",
    height: "500px",
    contentTemplate: function (e) {
      return $("<div>").dxBox({
        direction: "row",
        align: "space-around",
        crossAlign: "stretch",
        items: [
          {
            ratio: 1,
            template: function () {
              // COMPAÑÍAS
              var container = $("<div>");
              var title = $(
                "<div class='font-size' style='margin-left:9px;'>" +
                  getTrad("compañia") +
                  "</div>",
              );
              var lista_compañias = $(
                "<div id='popup_selCompaEnti_lista_compañias'>",
              ).dxList({
                dataSource: datasource_compañias,
                selectionMode: "single",
                height: "375px",
                width: "95%",
                keyExpr: "idCompañia",
                scrolling: { mode: "infinite" },
                pageLoadMode: "scrollBottom",
                // EVENTOS
                onContentReady: function (e) {
                  e.component.selectItem(0);
                },
                onSelectionChanged: function (e) {
                  var lista_entidades = $(
                    "#popup_selCompaEnti_lista_entidades",
                  ).dxList("instance");
                  if (e.addedItems[0].idCompañia !== 0) {
                    lista_entidades
                      .getDataSource()
                      .filter([
                        ["idCompañia", "=", e.addedItems[0].idCompañia],
                      ]);
                  } else if (lista_entidades !== undefined) {
                    // AL ABRIR POR PRIMERA VEZ lista_entidades es undefined
                    lista_entidades.getDataSource().filter("");
                  }
                  datasource_entidades.load();
                },
                itemTemplate: function (data, index) {
                  var result = $("<div>");
                  $("<div>").text(data.denominacion).appendTo(result);

                  return result;
                },
              });

              title.appendTo(container);
              lista_compañias.appendTo(container);

              return container;
            },
          },
          {
            // ENTIDADES
            ratio: 1,
            template: function () {
              var container = $("<div>");
              var title = $(
                "<div class='font-size' style='margin-left:9px;'>" +
                  getTrad("entidad") +
                  "</div>",
              );
              var lista_entidades = $(
                "<div id='popup_selCompaEnti_lista_entidades'>",
              ).dxList({
                //Datos
                dataSource: datasource_entidades,
                selectionMode: "single",
                keyExpr: "idEntidad",
                height: "375px",
                scrolling: { mode: "infinite" },
                pageLoadMode: "scrollBottom",
                //EVENTOS
                onSelectionChanged: function (e) {
                  if (typeof e.addedItems[0] !== "undefined") {
                    // MOSTRAR GRID PRENDASPEDIDO CON DATOS Y LAYOUT DESGOME
                    $("#popup_prendasPedido").dxPopup("instance").show();
                    var grid = $("#dgvPrendasPedido").dxDataGrid("instance");
                    grid.repaint();

                    $("#dgvPrendasPedido").dxDataGrid(
                      "columnOption",
                      "peticion",
                      "visible",
                      false,
                    );
                    $("#dgvPrendasPedido").dxDataGrid(
                      "columnOption",
                      "codigoElemTransReparto",
                      "visible",
                      false,
                    );
                    $("#dgvPrendasPedido").dxDataGrid(
                      "columnOption",
                      "rechazo",
                      "visible",
                      false,
                    );
                    $("#dgvPrendasPedido").dxDataGrid(
                      "columnOption",
                      "retiro",
                      "visible",
                      false,
                    );

                    $("#dgvPrendasPedido").dxDataGrid(
                      "columnOption",
                      "codigoElemTransPedido",
                      "visibleIndex",
                      8,
                    );

                    $("#btnActualizar")
                      .dxButton("instance")
                      .option("visible", false);
                    $("#btnMarcarTodo")
                      .dxButton("instance")
                      .option("visible", false);
                    $("#popup_selCompaEnti").dxPopup("instance").hide();

                    $("#titleSelCompaEnti").text(e.addedItems[0].denominacion);

                    //Hacemos visible la selección de un pedido de cliente
                    if (
                      typeof $(
                        "#popup_datosReparto_contenedorPedidoCliente",
                      )[0] !== "undefined"
                    ) {
                      $("#popup_datosReparto_contenedorPedidoCliente").attr(
                        "style",
                        "padding-top: 15px",
                      );
                    }

                    grid.option("dataSource", datasource_prendasPedidoDesgome);
                    grid.cancelEditData();
                  }
                },
                itemTemplate: function (data, index) {
                  var result = $("<div>");
                  $("<div>").text(data.denominacion).appendTo(result);

                  return result;
                },
              });

              title.appendTo(container);
              lista_entidades.appendTo(container);

              return container;
            },
          },
        ],
      });
    },
  });

  //FUNCIONES ODATA

  function generarReparto(e) {
    var grid = $("#dgvPrendasPedido").dxDataGrid("instance");
    grid.clearFilter();
    grid.refresh(true).done(function () {
      var tblPrendaNReparto = [];
      $.each(grid.getVisibleRows(), function (index, item) {
        if (item.rowType === "data") {
          var prenda = {};
          prenda.idPrenda = grid.cellValue(item.rowIndex, "idPrenda");
          prenda.cantidad = grid.cellValue(item.rowIndex, "cantidad");

          if (
            pedidoSel &&
            pedidoSel.reparteRechazoRetiro !== null &&
            pedidoSel.pedidoProd
          ) {
            var reparteRechazo = false;
            var reparteRetiro = false;

            if ($("#dxSwitch_dgvPrendasPedido_ReparteRechazo").length > 0) {
              reparteRechazo = $("#dxSwitch_dgvPrendasPedido_ReparteRechazo")
                .dxSwitch("instance")
                .option("value");
            }

            if ($("#dxSwitch_dgvPrendasPedido_ReparteRetiro").length > 0) {
              reparteRetiro = $("#dxSwitch_dgvPrendasPedido_ReparteRetiro")
                .dxSwitch("instance")
                .option("value");
            }

            if (reparteRechazo && reparteRetiro) {
              prenda.rechazo = grid.cellValue(item.rowIndex, "rechazo");
              prenda.retiro = grid.cellValue(item.rowIndex, "retiro");

              if (prenda.cantidad + prenda.rechazo + prenda.retiro > 0) {
                tblPrendaNReparto.push(prenda);
              }
            } else if (reparteRechazo) {
              prenda.rechazo = grid.cellValue(item.rowIndex, "rechazo");

              if (prenda.cantidad + prenda.rechazo > 0) {
                tblPrendaNReparto.push(prenda);
              }
            } else if (reparteRetiro) {
              prenda.retiro = grid.cellValue(item.rowIndex, "retiro");

              if (prenda.cantidad + prenda.retiro > 0) {
                tblPrendaNReparto.push(prenda);
              }
            } else {
              if (prenda.cantidad > 0) {
                tblPrendaNReparto.push(prenda);
              }
            }
          } else {
            if (prenda.cantidad > 0) {
              tblPrendaNReparto.push(prenda);
            }
          }
        }
      });

      var result =
        LAVANDERIA.idLavanderia === 18 || LAVANDERIA.idLavanderia === 14
          ? validationEngine.validateGroup("numCarrosValidator")
          : null;
      let fecha = $("#dxCalendar_fechaReparto")
        .dxCalendar("instance")
        .option("value");
      if (
        tblPrendaNReparto.length > 0 &&
        fecha &&
        ((result && result.isValid) || result === null)
      ) {
        var idPedidoCliente = null;
        if (typeof $("#dxDropDownBox_pedidoCliente")[0] !== "undefined") {
          idPedidoCliente = $("#dxDropDownBox_pedidoCliente")
            .dxDropDownBox("instance")
            .option("value");
        }
        var idEnti;
        if (pedidoSel) {
          idEnti = pedidoSel.idEntidad;
        } else {
          idEnti = $("#popup_selCompaEnti_lista_entidades")
            .dxList("instance")
            .option("selectedItemKeys")[0];
        }

        datasource_repartos
          .store()
          .insert({
            idPedido: pedidoSel ? pedidoSel.idPedido : null,
            fecha: fecha,
            observaciones: $("#dxTextArea_observacionesReparto")
              .dxTextArea("instance")
              .option("value"),
            idPedidoCliente: idPedidoCliente,
            tblPrendaNReparto: tblPrendaNReparto,
            idEntidad: idEnti,
            numCarros: pedidoSel?.numCarros,
          })
          .done(function () {
            //Se refresca el dgvPedido on hidden del popup
            $("#dgvRepartos").dxDataGrid("instance").refresh(true);
            $("#popup_datosReparto").dxPopup("instance").hide();
            $("#popup_prendasPedido").dxPopup("instance").hide();
          });
        return;
      } else {
        if (!fecha) {
          notify({
            message: "Debe seleccionar una fecha para realizar el reparto.",
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
        } else if (result == null || (result && result.isValid)) {
          notify({
            message: getTrad("alerta_cantidadRepartir"),
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
        }

        $("#btnGenrarReparto").dxButton("instance").option("disabled", false);
      }
    });
  }

  function tratarPrendasReparto() {
    var prendas = $.extend(
      true,
      [],
      datasource_prendasReparto_peticion.items(),
    );

    $.each(prendas, function (index, item) {
      if (
        parseInt(item.tblPrenda.tblElemTransReparto.codigo) === 2 &&
        repartoSel.pedidoProd === false
      )
        item.cantidad = item.cantidad * item.tblPrenda.udsXBacReparto;

      delete item.peticion;
      delete item.tblPrenda;
    });

    return prendas;
  }

  // FUNCIONES GRID REPARTOS - SELECCIÓN ROWS
  function isSelectable(item) {
    return !item.isCerradoFactEntidad;
  }

  function isSelectAll(dataGrid) {
    var items = [];

    var selectableItems = items.filter(isSelectable);
    var selectedRowKeys = dataGrid.option("selectedRowKeys");

    if (!selectedRowKeys.length) {
      return false;
    }
    return selectedRowKeys.length >= selectableItems.length ? true : undefined;
  }

  //IMPRESIÓN
  function CustomizeParameterEditors(report, s, e) {
    if (report === "albaran_pedidos") {
      if (e.parameter.name === "idPedido") {
        //INVISIBLES
        e.info.editor = $.extend({}, e.info.editor);
        e.info.visible = false;
      }
    }
    if (report === tipoReportSel) {
      if (e.parameter.name === "idReparto") {
        //INVISIBLES
        e.info.editor = $.extend({}, e.info.editor);
        e.info.visible = false;
      }
    }
  }

  setTimeout(() => {
    var dgvRepartos = $("#dgvRepartos").dxDataGrid("instance");
    if (dgvRepartos) {
      dgvRepartos.repaint();
    }
  }, 50);

  deferred.resolve();
  return deferred.promise();
}
