import React from "react";
import {
    datasource_tblAdmPedido_Estado,
    datasource_tblAdmAlbaran_Estado,
    datasource_tblAdmPresupuestoVenta_Estado,
    datasource_tblAdmFactura_Estado,
} from "../model";

const ChipEstado = (id, size = "", elemento) => {
    let bgColor = id === 1 ? "default" : id === 2 ? "warning" : "success";
    let text;
    switch (elemento) {
        case "pedido":
            text = datasource_tblAdmPedido_Estado.items().find((e) => e.idAdmPedido_Estado === id)?.denominacion;
            break;
        case "albaran":
            text = datasource_tblAdmAlbaran_Estado.items().find((e) => e.idAdmAlbaran_Estado === id)?.denominacion;
            bgColor = id === 1 ? "default" : id === 2 ? "success" : "danger";
            break;
        case "presupuesto":
            text = datasource_tblAdmPresupuestoVenta_Estado.items().find((e) => e.idAdmPresupuestoVenta_Estado === id)?.denominacion;
            bgColor = id === 1 ? "default" : id === 2 ? "success" : "danger";
            break;
        case "factura":
            text = datasource_tblAdmFactura_Estado.items().find((e) => e.idAdmFactura_Estado === id)?.denominacion;
            bgColor = id === 1 ? "default" : id === 2 ? "success" : "danger";
            break;
        default:
            text = "Fallo en el identificador";
            break;
    }
    if(text != undefined){
        text = text?.charAt(0)?.toUpperCase() + text?.slice(1);
    }
    return <div className={"chip " + bgColor + size}>{text}</div>;
};

export default ChipEstado;
