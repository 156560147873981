import React from "react";

import { connectionConstants } from "../../../../../constants";

import { errorHandler, authHeader, getTrad } from "../../../../../helpers";
import $ from "jquery";

//Datasource
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { SelectBox } from "devextreme-react/select-box";

export default class SelectPais extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pais_items: [],
      selectedIndex: null,
    };

    this.dataSource_tblPais_postProcess =
      this.dataSource_tblPais_postProcess.bind(this);
    this.renderValue_SelectPais = this.renderValue_SelectPais.bind(this);
    this.onItemChange = this.onItemChange.bind(this);
  }

  //#region DATASOURCES
  dataSource_tblPais = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPais",
      key: "idPais",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblPais_beforeSend(request);
      },
      version: 4,
    }),
    expand: "tblTraduccion",
    sort: ["denominacion asc"],
    postProcess: (data) => {
      return this.dataSource_tblPais_postProcess(data);
    },
  });
  dataSource_tblPais_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.conLavanderia = true;
  }
  dataSource_tblPais_postProcess(data) {
    $.each(data, function (index, item) {
      if (index != 0) item.denominacion = item.denominacion.toUpperCase();
    });
    return data;
  }

  //#endregion

  render() {
    let { pais_items, selectedIndex } = this.state;
    let { idioma } = this.props;

    return (
      <SelectBox
        deferRendering={false}
        className="centerText font-size-xs"
        inputAttr={{ class: "font-size-xs" }}
        dataSource={pais_items}
        displayExpr={function (item) {
          if (item && item.tblTraduccion) {
            return item.tblTraduccion[idioma.codigo].toUpperCase();
          } else if (item) {
            return getTrad("todosPaises");
          } else {
            return;
          }
        }}
        disabled={pais_items.length == 1}
        valueExpr="idPais"
        value={selectedIndex}
        defaultValue={selectedIndex}
        width="100%"
        stylingMode="underlined"
        placeholder={getTrad("todosPaises")}
        onSelectionChanged={this.onItemChange}
      />
    );
  }

  onItemChange(e) {
    this.setState({
      selectedIndex: e.selectedItem.idPais,
    });

    this.props.onItemChange(e.selectedItem.idPais);
  }

  renderValue_SelectPais(value) {
    let { pais_items } = this.state;
    let text = $.grep(pais_items, function (item) {
      return item.idPais == value;
    });
    return value === null
      ? "Todos los países"
      : text.length > 0
        ? text[0].denominacion
        : "";
  }

  componentDidMount() {
    let _this = this;
    this.dataSource_tblPais.load().done(function (items) {
      let selectedIndex = items.length > 0 ? items[0].idPais : null;
      _this.setState(
        {
          pais_items: items,
          selectedIndex: 1, // selectedIndex - 1er registro // 1 - España
        },
        () => {
          _this.props.onItemChange(_this.state.selectedIndex);
        },
      );
    });
  }
}
