import { connectionConstants } from "../../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  formatNumber,
  convertClienteUtcToSinUtc,
  createPalette,
  dxMensajePregunta,
  is_paramRedireccion_outOfRange,
  startOfMonth,
} from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import query from "devextreme/data/query";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var datasource_datos_kgEstancias;
  var datasource_datos_loadPromise_kgEstancias = null;

  var datasource_filtroTiempo_kgEstancias;
  var datasource_filtroTiempo_loadPromise_kgEstancias = null;

  var datasource_datos_ratiosCambio;
  var datasource_datos_loadPromise_ratiosCambio = null;

  var datasource_datos_ratiosCambio_tipoPrenda;
  var datasource_datos_loadPromise_ratiosCambio_tipoPrenda = null;

  var datasource_filtroTiempo_ratiosCambio;
  var datasource_filtroTiempo_loadPromise_ratiosCambio = null;

  //Variables de filtros
  var entidadesSel = [];

  //Variables de gráficas
  var fechaDesde_kgEstancias = new Date();
  var fechaHasta_kgEstancias = new Date();

  var opciones = {
    isAcumulado: false,
    isExtra: null,
  };

  var fechaDesde_ratiosCambio = new Date();
  var fechaHasta_ratiosCambio = new Date();

  var tipoPrendaSel = null;
  var isGrafica_agrupada = false;

  //#region Datasource

  datasource_datos_kgEstancias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "Informe",
      key: "entidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.fechaDesde = formatDate_parameter(
          fechaDesde_kgEstancias,
        );
        request.params.fechaHasta = formatDate_parameter(
          fechaHasta_kgEstancias,
        );

        if (isComparativa_kgEstancias()) {
          //Carga gráfica comparativa
          request.params.idEntidad = entidadesSel.toString().replace(/,/g, "|");
          request.url = request.url.replace(
            "Informe",
            "spInformeLenceria_KgEstancias_Comparativa",
          );
        } else {
          request.params.idsEntidad = entidadesSel
            .toString()
            .replace(/,/g, "|");
          request.url = request.url.replace(
            "Informe",
            "spInformeLenceria_KgEstancias",
          );
        }

        request.params.idioma = IDIOMA.codigo;
        request.params.isExtra = opciones.isExtra;
      },
      onLoaded: function (items) {
        datasource_datos_loadPromise_kgEstancias = null;

        var numEntidades = query(items).groupBy("arg").toArray().length;

        isGrafica_agrupada = numEntidades > 1; //5
      },
      version: 4,
    }),
    onChanged: function () {
      configurarChart_kgEstancias();
    },
  });

  datasource_filtroTiempo_kgEstancias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "spInformeLenceria_filtroTiempo_KgEstancias",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idsEntidad = entidadesSel.toString().replace(/,/g, "|");
        request.params.isExtra = opciones.isExtra;
      },
      version: 4,
    }),
    postProcess: function (data) {
      $.each(data, function (index, item) {
        item.arg = formatDate_parameter(item.fecha);
      });

      if (data.length === 1) {
        var fechaMesAnterior = new Date(data[0].fecha.getTime());
        fechaMesAnterior.setMonth(fechaMesAnterior.getMonth() - 1);

        data.splice(0, 0, {
          arg: formatDate_parameter(fechaMesAnterior),
          estancias: "0",
          fecha: fechaMesAnterior,
          kg: 0,
          val: 0,
        });
      } else if (data.length === 0 && entidadesSel.length > 0) {
        dxMensajePregunta(getTrad("msg_noDatosFiltrosSel"), [
          [
            getTrad("aceptar"),
            function (e) {
              var tabpanel = $("#tabpanel-container").dxTabPanel("instance");
              tabpanel.option("selectedIndex", 0);
            },
            "danger",
            "btnErrorTipoReparto",
          ],
        ]);
      }
      return data;
    },
    onChanged: function () {
      let { param_paginaSecundaria } = COMPONENT.props;
      var items = datasource_filtroTiempo_kgEstancias.items();

      if (param_paginaSecundaria != null && items.length > 0) {
        let ultimaFecha = items[items.length - 1].arg;

        let fechaDesde_redireccion =
          param_paginaSecundaria != null
            ? param_paginaSecundaria.fechaDesde
            : null;
        let fechaHasta_redireccion =
          param_paginaSecundaria != null
            ? param_paginaSecundaria.fechaHasta
            : null;

        let fechaDesde_ =
          new Date(fechaDesde_redireccion) <= ultimaFecha
            ? new Date(fechaDesde_redireccion)
            : startOfMonth(new Date(ultimaFecha));
        let fechaHasta_ =
          new Date(fechaHasta_redireccion) <= ultimaFecha
            ? new Date(fechaHasta_redireccion)
            : ultimaFecha;

        opciones.fechaDesde = new Date(fechaDesde_);
        opciones.fechaHasta = new Date(fechaHasta_);

        let fechas_paramAlert = {
          paramString: {
            desde: new Date(fechaDesde_),
            hasta: new Date(fechaHasta_),
          },
          bd: {
            desde: items[0].fecha,
            hasta: items[items.length - 1].fecha,
          },
        };
        is_paramRedireccion_outOfRange(fechas_paramAlert);
      }

      filtroTiempo_kgEstancias_Resize(items.length);
      calcularInfoGeneral_KgEstancias();

      datasource_filtroTiempo_loadPromise_kgEstancias = null;
    },
  });

  datasource_datos_ratiosCambio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spInformeLenceria_RatioCambio",
      key: "entidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.fechaDesde = formatDate_parameter(
          fechaDesde_ratiosCambio,
        );
        request.params.fechaHasta = formatDate_parameter(
          fechaHasta_ratiosCambio,
        );
        request.params.idsEntidad = entidadesSel.toString().replace(/,/g, "|");
        request.params.idioma = IDIOMA.codigo;
        request.params.isExtra = opciones.isExtra;
      },
      onLoaded: function (items) {
        datasource_datos_loadPromise_ratiosCambio = null;

        //Title  dxChart_grafica_ratiosCambio_tipoPrenda
        if (tipoPrendaSel) {
          var tipoPrenda = $.grep(items, function (v) {
            return v.idTipoPrenda === tipoPrendaSel.idTipoPrenda;
          });

          if (tipoPrenda.length > 0) {
            var title = {
              text: tipoPrendaSel.denominacion,
              subTitle: {
                text: getTrad("ratio") + ": " + tipoPrenda[0].val + "%",
              },
            };
            $("#dxChart_grafica_ratiosCambio_tipoPrenda")
              .dxChart("instance")
              .option("title", title);
          }
        }
      },
      version: 4,
    }),
  });

  datasource_datos_ratiosCambio_tipoPrenda = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "Informe",
      key: "arg",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.fechaDesde = formatDate_parameter(
          fechaDesde_ratiosCambio,
        );
        request.params.fechaHasta = formatDate_parameter(
          fechaHasta_ratiosCambio,
        );

        if (isComparativa_ratiosCambio_tipoPrenda()) {
          //Carga gráfica comparativa
          request.params.idEntidad = entidadesSel.toString().replace(/,/g, "|");
          request.url = request.url.replace(
            "Informe",
            "spInformeLenceria_RatioCambio_TipoPrenda_Comparativa",
          );
        } else {
          request.params.idsEntidad = entidadesSel
            .toString()
            .replace(/,/g, "|");
          request.url = request.url.replace(
            "Informe",
            "spInformeLenceria_RatioCambio_TipoPrenda",
          );
        }

        request.params.idTipoPrenda = tipoPrendaSel
          ? tipoPrendaSel.idTipoPrenda
          : -1;
        request.params.idioma = IDIOMA.codigo;
        request.params.isExtra = opciones.isExtra;
      },
      onLoaded: function (items) {
        datasource_datos_loadPromise_ratiosCambio_tipoPrenda = null;

        var numArgs = query(items).groupBy("arg").toArray().length;

        isGrafica_agrupada = numArgs > 1; //5

        var numSeries = query(items).groupBy("series").toArray().length;

        if (tipoPrendaSel) {
          var dxChart_grafica_ratiosCambio_tipoPrenda = $(
            "#dxChart_grafica_ratiosCambio_tipoPrenda",
          ).dxChart("instance");

          var paletteOld =
            dxChart_grafica_ratiosCambio_tipoPrenda.option("palette");
          var palette =
            numSeries > 2
              ? createPalette(tipoPrendaSel.color, numSeries)
              : [tipoPrendaSel.color];

          if (JSON.stringify(palette) !== JSON.stringify(paletteOld)) {
            dxChart_grafica_ratiosCambio_tipoPrenda.option("palette", palette);
          }
        }
      },
      version: 4,
    }),
    onChanged: function () {
      configurarChart_ratiosCambio_tipoPrenda();
    },
  });

  datasource_filtroTiempo_ratiosCambio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "spInformeLenceria_filtroTiempo_RatioCambio",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idsEntidad = entidadesSel.toString().replace(/,/g, "|");
        request.params.isExtra = opciones.isExtra;
      },
      version: 4,
    }),
    postProcess: function (data) {
      $.each(data, function (index, item) {
        item.arg = formatDate_parameter(item.fecha);
      });

      if (data.length === 1) {
        var fechaMesAnterior = new Date(data[0].fecha.getTime());
        fechaMesAnterior.setMonth(fechaMesAnterior.getMonth() - 1);

        data.splice(0, 0, {
          arg: formatDate_parameter(fechaMesAnterior),
          fecha: fechaMesAnterior,
          val: 0,
        });
      }

      return data;
    },
    onChanged: function () {
      var items = datasource_filtroTiempo_ratiosCambio.items();
      filtroTiempo_ratiosCambio_Resize(items.length);

      let { param_paginaSecundaria } = COMPONENT.props;
      var items = datasource_filtroTiempo_kgEstancias.items();

      if (param_paginaSecundaria != null && items.length > 0) {
        let ultimaFecha = items[items.length - 1].arg;
        let fechaDesde_redireccion =
          param_paginaSecundaria != null
            ? param_paginaSecundaria.fechaDesde
            : null;
        let fechaHasta_redireccion =
          param_paginaSecundaria != null
            ? param_paginaSecundaria.fechaHasta
            : null;

        let fechaDesde_ =
          new Date(fechaDesde_redireccion) <= ultimaFecha
            ? new Date(fechaDesde_redireccion)
            : startOfMonth(new Date(ultimaFecha));
        let fechaHasta_ =
          new Date(fechaHasta_redireccion) <= ultimaFecha
            ? new Date(fechaHasta_redireccion)
            : ultimaFecha;

        opciones.fechaDesde = new Date(fechaDesde_);
        opciones.fechaHasta = new Date(fechaHasta_);

        let fechas_paramAlert = {
          paramString: {
            desde: new Date(fechaDesde_),
            hasta: new Date(fechaHasta_),
          },
          bd: {
            desde: items[0].fecha,
            hasta: items[items.length - 1].fecha,
          },
        };
        is_paramRedireccion_outOfRange(fechas_paramAlert);
      }

      datasource_filtroTiempo_loadPromise_ratiosCambio = null;
    },
  });

  var datasource_compañias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
  });

  var datasource_entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "idCompañia", "denominacion"],
    expand: ["tblGrupoEntidad"],
    // filter: ["isPrincipal", "=", true],
    postProcess: function (data) {
      var grupos = {};

      $.each(data, function (index, entidad) {
        if (entidad.tblGrupoEntidad) {
          if (grupos[entidad.tblGrupoEntidad.idGrupoEntidad]) {
            //Ya existe el grupo
            grupos[entidad.tblGrupoEntidad.idGrupoEntidad].idEntidad +=
              "|" + entidad.idEntidad;
          } else {
            grupos[entidad.tblGrupoEntidad.idGrupoEntidad] = {
              idEntidad: entidad.idEntidad,
              idCompañia: entidad.idCompañia,
              denominacion: entidad.tblGrupoEntidad.denominacion,
            };
          }
        }
      });

      var entidadesSinGrupo = $.grep(data, function (entidad) {
        return !entidad.tblGrupoEntidad;
      });

      $.each(grupos, function (index, grupo) {
        entidadesSinGrupo.push(grupo);
      });

      entidadesSinGrupo = query(entidadesSinGrupo)
        .sortBy("denominacion")
        .toArray();

      return entidadesSinGrupo;
    },
  });

  var enum_informes = [
    {
      text: getTrad("kgEstancia"),
      value: 1,
      alignment: "center",
    },
    {
      text: getTrad("ratiosCambio"),
      value: 2,
      alignment: "center",
    },
  ];

  //#endregion

  //#region Parámetros URL
  let { param_paginaSecundaria } = COMPONENT.props;
  let selectedTab =
    param_paginaSecundaria != null ? param_paginaSecundaria.selectedTab : null;
  let fechaDesde_redireccion =
    param_paginaSecundaria != null ? param_paginaSecundaria.fechaDesde : null;
  let fechaHasta_redireccion =
    param_paginaSecundaria != null ? param_paginaSecundaria.fechaHasta : null;
  let idCompañiaSel_urlParam =
    param_paginaSecundaria != null
      ? param_paginaSecundaria.idCompañiaSel
      : null;
  //#endregion

  $.when(datasource_entidades.load())
    .done(function () {
      if (param_paginaSecundaria != null && selectedTab == 1) {
        entidadesSel = $.map(
          $.grep(datasource_entidades.items(), function (item) {
            return item.idCompañia == idCompañiaSel_urlParam;
          }),
          function (item) {
            return item.idEntidad;
          },
        );

        fechaDesde_kgEstancias = new Date(fechaDesde_redireccion);
        fechaHasta_kgEstancias = new Date(fechaHasta_redireccion);
      }

      $("#InfLenceria #dxContainer").dxBox({
        direction: "row",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        width: "100%",
        items: [
          {
            ratio: 1,
            template: function (e, index, item) {
              var tabpanel = $("<div id='tabpanel-container' />")
                .addClass("h3")
                .dxTabPanel({
                  height: "100%",
                  width: "100%",
                  deferRendering: false,
                  animationEnabled: true,
                  swipeEnabled: false,
                  items: [
                    {
                      title: getTrad("filtros"),
                      template: function () {
                        return $("<div />").dxBox({
                          direction: "col",
                          align: "space-around",
                          crossAlign: "stretch",
                          height: "100%",
                          items: [
                            {
                              baseSize: 50,
                              shrink: 0,
                              ratio: 0,
                              template: function () {
                                return $("<div />").dxToolbar({
                                  items: [
                                    {
                                      location: "after",
                                      widget: "dxButton",
                                      options: {
                                        elementAttr: {
                                          id: "dxButton_cargar",
                                          class: "dx-button-iconRight",
                                        },
                                        text: getTrad("cargar"),
                                        icon: "chevronright",
                                        type: "success",
                                        disabled: true,
                                        onClick: function () {
                                          var informeKey_tab = $(
                                            "#filtro_dxList_informe",
                                          )
                                            .dxList("instance")
                                            .option("selectedItemKeys")[0];
                                          tabpanel.option(
                                            "selectedIndex",
                                            informeKey_tab,
                                          );

                                          if (entidadesSel.length === 1) {
                                            var entidad = $(
                                              "#filtro_dxList_entidad",
                                            )
                                              .dxList("instance")
                                              .option("selectedItems")[0];
                                            $(".titulo_Entidad").text(
                                              entidad.denominacion,
                                            );
                                          } else {
                                            var entidades = $(
                                              "#filtro_dxList_entidad",
                                            )
                                              .dxList("instance")
                                              .option("selectedItems");
                                            var compañiasEntidades = $.grep(
                                              datasource_compañias.items(),
                                              function (compañia) {
                                                var isCompaSel =
                                                  $.grep(
                                                    entidades,
                                                    function (entidad) {
                                                      return (
                                                        entidad.idCompañia ===
                                                        compañia.idCompañia
                                                      );
                                                    },
                                                  ).length > 0;

                                                return isCompaSel;
                                              },
                                            );

                                            if (
                                              compañiasEntidades.length === 1
                                            ) {
                                              $(".titulo_Entidad").text(
                                                compañiasEntidades[0]
                                                  .denominacion,
                                              );
                                            } else {
                                              $(".titulo_Entidad").text(
                                                LAVANDERIA
                                                  ? LAVANDERIA.denominacion
                                                  : "Varias compañías",
                                              );
                                            }
                                          }

                                          recargaFiltroTiempo_kgEstancias();
                                          recargaFiltroTiempo_ratiosCambio();
                                        },
                                      },
                                    },
                                  ],
                                });
                              },
                            },
                            {
                              ratio: 1,
                              template: function () {
                                return $("<div id='dxBox_Filtros' />").dxBox({
                                  direction: "row",
                                  align: "space-around",
                                  crossAlign: "stretch",
                                  height: "100%",
                                  items: [
                                    {
                                      //INFORMES
                                      ratio: 1,
                                      template: function () {
                                        return $(
                                          "<div style='margin-right:15px;'/>",
                                        ).dxBox({
                                          direction: "col",
                                          align: "space-around",
                                          crossAlign: "stretch",
                                          height: "100%",
                                          items: [
                                            {
                                              baseSize: 45,
                                              shrink: 0,
                                              ratio: 0,
                                              template: function () {
                                                return $(
                                                  "<div class='font-size' style='margin-left:9px;'>1. " +
                                                    getTrad(
                                                      "seleccioneInforme",
                                                    ) +
                                                    "</div>",
                                                );
                                              },
                                            },
                                            {
                                              ratio: 1,
                                              template: function () {
                                                return $(
                                                  "<div id='filtro_dxList_informe'>",
                                                ).dxList({
                                                  dataSource: enum_informes,
                                                  focusStateEnabled: false,
                                                  activeStateEnabled: false,
                                                  selectionMode: "single",
                                                  keyExpr: "value",
                                                  scrolling: {
                                                    mode: "infinite",
                                                  },
                                                  pageLoadMode: "scrollBottom",
                                                  onSelectionChanged: function (
                                                    i,
                                                  ) {
                                                    var dxBox_Filtros =
                                                      $("#dxBox_Filtros").dxBox(
                                                        "instance",
                                                      );
                                                    dxBox_Filtros.option(
                                                      "items[1].visible",
                                                      true,
                                                    );
                                                    dxBox_Filtros.repaint();
                                                  },
                                                  itemTemplate: function (
                                                    data,
                                                    index,
                                                  ) {
                                                    var result = $(
                                                      "<div class='container_spanCentrado' style='height:250px;'>",
                                                    );
                                                    $("<div class='font-size'>")
                                                      .css(
                                                        "font-size",
                                                        "1.8rem",
                                                      )
                                                      .text(
                                                        data.text.toUpperCase(),
                                                      )
                                                      .appendTo(result);
                                                    return result;
                                                  },
                                                });
                                              },
                                            },
                                          ],
                                        });
                                      },
                                    },
                                    {
                                      //COMPAÑIAS
                                      ratio: 1,
                                      visible: false,
                                      template: function () {
                                        return $(
                                          "<div style='margin-right:15px;'/>",
                                        ).dxBox({
                                          direction: "col",
                                          align: "space-around",
                                          crossAlign: "stretch",
                                          height: "100%",
                                          items: [
                                            {
                                              baseSize: 45,
                                              shrink: 0,
                                              ratio: 0,
                                              template: function () {
                                                return $(
                                                  "<div class='font-size'>",
                                                ).text(
                                                  "2. " +
                                                    getTrad(
                                                      "seleccioneCompañia",
                                                    ),
                                                );
                                              },
                                            },
                                            {
                                              ratio: 1,
                                              template: function () {
                                                return $(
                                                  "<div id='filtro_dxList_compañia'>",
                                                ).dxList({
                                                  dataSource:
                                                    datasource_compañias,
                                                  selectionMode: "single",
                                                  keyExpr: "idCompañia",
                                                  height: "100%",
                                                  focusStateEnabled: false,
                                                  activeStateEnabled: false,
                                                  pageLoadMode: "scrollBottom",
                                                  onSelectionChanged: function (
                                                    e,
                                                  ) {
                                                    var idCompañiaSel =
                                                      e.addedItems[0]
                                                        .idCompañia;

                                                    var filtro_dxList_entidad =
                                                      $(
                                                        "#filtro_dxList_entidad",
                                                      ).dxList("instance");
                                                    filtro_dxList_entidad
                                                      .getDataSource()
                                                      .filter(
                                                        "idCompañia",
                                                        "=",
                                                        idCompañiaSel,
                                                      );
                                                    filtro_dxList_entidad
                                                      .getDataSource()
                                                      .load()
                                                      .done(function () {
                                                        var dxBox_Filtros =
                                                          $(
                                                            "#dxBox_Filtros",
                                                          ).dxBox("instance");
                                                        dxBox_Filtros.option(
                                                          "items[2].visible",
                                                          true,
                                                        );
                                                        dxBox_Filtros.repaint();

                                                        var checkValue = $(
                                                          "#dxCheckBox_compañia_" +
                                                            idCompañiaSel,
                                                        )
                                                          .dxCheckBox(
                                                            "instance",
                                                          )
                                                          .option("value");
                                                        if (
                                                          checkValue === true
                                                        ) {
                                                          filtro_dxList_entidad.selectAll();
                                                        } else if (
                                                          checkValue === false
                                                        ) {
                                                          filtro_dxList_entidad.unselectAll();
                                                        }
                                                      });
                                                  },
                                                  itemTemplate: function (
                                                    data,
                                                    index,
                                                  ) {
                                                    return $("<div/>").dxBox({
                                                      direction: "row",
                                                      align: "space-around",
                                                      crossAlign: "stretch",
                                                      height: "100%",
                                                      items: [
                                                        {
                                                          baseSize: 30,
                                                          shrink: 0,
                                                          ratio: 0,
                                                          template:
                                                            function () {
                                                              return $(
                                                                "<div id='dxCheckBox_compañia_" +
                                                                  data.idCompañia +
                                                                  "'/>",
                                                              ).dxCheckBox({
                                                                value: false,
                                                                onValueChanged:
                                                                  function (e) {
                                                                    var idCompañiaSel =
                                                                      $(
                                                                        "#filtro_dxList_compañia",
                                                                      )
                                                                        .dxList(
                                                                          "instance",
                                                                        )
                                                                        .option(
                                                                          "selectedItemKeys",
                                                                        )[0];
                                                                    if (
                                                                      data.idCompañia ===
                                                                      idCompañiaSel
                                                                    ) {
                                                                      //Solo cambio de estado sin cambio de compañia
                                                                      var filtro_dxList_entidad =
                                                                        $(
                                                                          "#filtro_dxList_entidad",
                                                                        ).dxList(
                                                                          "instance",
                                                                        );
                                                                      var checkValue =
                                                                        e.value;
                                                                      if (
                                                                        checkValue ===
                                                                        true
                                                                      ) {
                                                                        filtro_dxList_entidad.selectAll();
                                                                      } else if (
                                                                        checkValue ===
                                                                        false
                                                                      ) {
                                                                        filtro_dxList_entidad.unselectAll();
                                                                      }
                                                                    }
                                                                  },
                                                              });
                                                            },
                                                        },
                                                        {
                                                          ratio: 1,
                                                          template:
                                                            function () {
                                                              return $(
                                                                "<div/>",
                                                              ).text(
                                                                data.denominacion,
                                                              );
                                                            },
                                                        },
                                                      ],
                                                    });
                                                  },
                                                });
                                              },
                                            },
                                          ],
                                        });
                                      },
                                    },
                                    {
                                      //ENTIDADES
                                      ratio: 1,
                                      visible: false,
                                      template: function () {
                                        return $(
                                          "<div id='dxBox_Filtros_entidades' />",
                                        ).dxBox({
                                          direction: "col",
                                          align: "space-around",
                                          crossAlign: "stretch",
                                          height: "100%",
                                          items: [
                                            {
                                              baseSize: 45,
                                              shrink: 0,
                                              ratio: 0,
                                              template: function () {
                                                return $(
                                                  "<div class='font-size' style='margin-left:9px;'>",
                                                ).text(
                                                  "3. " +
                                                    getTrad(
                                                      "seleccioneEntidad",
                                                    ),
                                                );
                                              },
                                            },
                                            {
                                              ratio: 1,
                                              template: function () {
                                                return $(
                                                  "<div id='filtro_dxList_entidad'>",
                                                ).dxList({
                                                  dataSource:
                                                    datasource_entidades.items(),
                                                  height: "100%",
                                                  keyExpr: "idEntidad",
                                                  showSelectionControls: true,
                                                  selectionMode: "all",
                                                  focusStateEnabled: false,
                                                  activeStateEnabled: false,
                                                  pageLoadMode: "scrollBottom",
                                                  selectAllMode: "allPages",
                                                  onSelectionChanged: function (
                                                    e,
                                                  ) {
                                                    entidadesSel =
                                                      e.component.option(
                                                        "selectedItemKeys",
                                                      );

                                                    var idCompañiaSel = $(
                                                      "#filtro_dxList_compañia",
                                                    )
                                                      .dxList("instance")
                                                      .option(
                                                        "selectedItemKeys",
                                                      )[0];

                                                    var numEntidades =
                                                      e.component.option(
                                                        "items",
                                                      ).length;

                                                    var numEntidadesSel =
                                                      $.grep(
                                                        e.component.option(
                                                          "selectedItems",
                                                        ),
                                                        function (
                                                          selectedItem,
                                                        ) {
                                                          return (
                                                            selectedItem.idCompañia ===
                                                            idCompañiaSel
                                                          );
                                                        },
                                                      ).length;

                                                    var checkValue;
                                                    if (
                                                      numEntidades ===
                                                      numEntidadesSel
                                                    ) {
                                                      checkValue = true;
                                                    } else if (
                                                      numEntidadesSel === 0
                                                    ) {
                                                      checkValue = false;
                                                    }
                                                    //Sinó undefined

                                                    $(
                                                      "#dxCheckBox_compañia_" +
                                                        idCompañiaSel,
                                                    )
                                                      .dxCheckBox("instance")
                                                      .option(
                                                        "value",
                                                        checkValue,
                                                      );

                                                    $("#dxButton_cargar")
                                                      .dxButton("instance")
                                                      .option(
                                                        "disabled",
                                                        entidadesSel.length ===
                                                          0,
                                                      );
                                                  },
                                                  itemTemplate: function (
                                                    data,
                                                    index,
                                                  ) {
                                                    return $("<div>").text(
                                                      data.denominacion,
                                                    );
                                                  },
                                                });
                                              },
                                            },
                                          ],
                                        });
                                      },
                                    },
                                  ],
                                });
                              },
                            },
                          ],
                        });
                      },
                    },
                    {
                      title: getTrad("kgEstancia"),
                      visible: false,
                      template: function () {
                        return $("<div />").dxBox({
                          direction: "col",
                          align: "space-around",
                          crossAlign: "stretch",
                          height: "100%",
                          items: [
                            {
                              baseSize: 50,
                              shrink: 0,
                              ratio: 0,
                              template: function () {
                                return $("<div />").dxToolbar({
                                  items: [
                                    {
                                      location: "before",
                                      widget: "dxButton",
                                      options: {
                                        text: getTrad("volverFiltrar"),
                                        icon: "chevronleft",
                                        onClick: function () {
                                          var tabpanel = $(
                                            "#tabpanel-container",
                                          ).dxTabPanel("instance");
                                          tabpanel.option("selectedIndex", 0);
                                        },
                                      },
                                    },
                                    {
                                      location: "center",
                                      template: function () {
                                        return $(
                                          "<span class='titulo_Entidad' />",
                                        )
                                          .css(
                                            "color",
                                            "rgba(102, 102, 102, 1)",
                                          )
                                          .css("font-size", "24px");
                                      },
                                    },
                                  ],
                                });
                              },
                            },
                            {
                              ratio: 1,
                              template: function () {
                                var container = $("<div style='height:100%'/>");

                                var chart = $(
                                  "<div id='dxChart_grafica_kgEstancias' style='height:100%'/>",
                                ).dxChart({
                                  dataSource: datasource_datos_kgEstancias,
                                  palette: "Soft Blue",
                                  commonSeriesSettings: {
                                    argumentField: "arg",
                                    valueField: "val",
                                    type: "stackedBar",
                                    label: {
                                      customizeText: function (e) {
                                        return formatNumber(
                                          e.valueText,
                                          3,
                                          "decimal",
                                        );
                                      },
                                      showForZeroValues: false,
                                      visible: true,
                                      border: {
                                        width: 1,
                                        color: "darkgrey",
                                        visible: true,
                                      },
                                      font: {
                                        color: "white",
                                      },
                                    },
                                  },
                                  seriesTemplate: {
                                    nameField: "series",
                                    customizeSeries: function (
                                      valueFromNameField,
                                    ) {
                                      return valueFromNameField === "TOTAL"
                                        ? {
                                            axis: isGrafica_agrupada
                                              ? ""
                                              : "TOTAL",
                                            type: "scatter",
                                            color: "#008fd8",
                                            label: {
                                              showForZeroValues: false,
                                              visible: true,
                                              backgroundColor: "white",
                                              border: {
                                                width: 1,
                                                color: "darkgrey",
                                                visible: true,
                                              },
                                              font: {
                                                color: "grey",
                                              },
                                            },
                                            point: {
                                              size: isGrafica_agrupada ? 6 : 12,
                                              color: "white",
                                              border: {
                                                width: 2,
                                                color: "#008fd8",
                                                visible: true,
                                              },
                                              hoverStyle: {
                                                color: "#008fd8",
                                                border: {
                                                  width: 2,
                                                  color: "#008fd8",
                                                  visible: true,
                                                },
                                              },
                                            },
                                          }
                                        : {};
                                    },
                                  },
                                  valueAxis: [
                                    {
                                      position: "left",
                                      grid: {
                                        visible: true,
                                      },
                                      title: {
                                        text: enum_informes[0].text,
                                      },
                                    },
                                    {
                                      name: "TOTAL",
                                      position: "right",
                                      title: {
                                        text: " ",
                                      },
                                      maxValueMargin: 0.3,
                                      minValueMargin: 0.3,
                                    },
                                  ],
                                  pathModified: true,
                                  argumentAxis: {
                                    label: {
                                      overlappingBehavior: "none",
                                      rotationAngle: -45,
                                      customizeText: function (e) {
                                        if (isComparativa_kgEstancias()) {
                                          var fecha = convertClienteUtcToSinUtc(
                                            new Date(e.value),
                                          );
                                          if (
                                            new Date().getFullYear() ===
                                            fecha.getFullYear()
                                          ) {
                                            return fecha
                                              .toLocaleString(IDIOMA.codigo, {
                                                month: "long",
                                              })
                                              .toUpperCase();
                                          } else {
                                            return fecha
                                              .toLocaleString(IDIOMA.codigo, {
                                                month: "long",
                                                year: "numeric",
                                              })
                                              .toUpperCase();
                                          }
                                        } else {
                                          return e.value;
                                        }
                                      },
                                    },
                                  },
                                  legend: {
                                    verticalAlignment: "top",
                                    horizontalAlignment: "right",
                                    orientation: "horizontal",
                                  },
                                  tooltip: {
                                    enabled: true,
                                    location: "edge",
                                    customizeTooltip: function (arg) {
                                      var data = arg.point.data;

                                      var html = "<div>";

                                      html += "<div class='tooltip_header'>";
                                      html += "<div class='tooltip_title'>";
                                      html += data.series;
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text' style='background-color: " +
                                        arg.point.series.getColor() +
                                        "'>";
                                      html += formatNumber(
                                        data.val,
                                        3,
                                        "decimal",
                                      );
                                      html += "</div>";
                                      html += "</div>";

                                      html += "<div class='tooltip_body'>";

                                      html += "<div class='tooltip_row'>";
                                      html +=
                                        "<div class='tooltip_series-name'>";
                                      html += "KG:";
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text'>";
                                      html += formatNumber(
                                        data.kg,
                                        0,
                                        "decimal",
                                      );
                                      html += "</div>";
                                      html += "</div>";

                                      html += "<div class='tooltip_row'>";
                                      html +=
                                        "<div class='tooltip_series-name'>";
                                      html +=
                                        getTrad("estancias").toUpperCase() +
                                        ":";
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text'>";
                                      html += formatNumber(data.estancias);
                                      html += "</div>";
                                      html += "</div>";

                                      if (data.series === "TOTAL") {
                                        //Desglose se las series
                                        var chart = $(
                                          "#dxChart_grafica_kgEstancias",
                                        ).dxChart("instance");
                                        var series = chart.getAllSeries();

                                        $.each(series, function (index, serie) {
                                          var points = serie.getPointsByArg(
                                            arg.argument,
                                          );
                                          if (points.length > 0) {
                                            var dataSerie = points[0].data;
                                            if (
                                              dataSerie.series !== data.series
                                            ) {
                                              html +=
                                                "<div class='tooltip_row'>";
                                              html +=
                                                "<div class='tooltip_series-name'>";
                                              html += dataSerie.series + ":";
                                              html += "</div>";
                                              html +=
                                                "<div class='tooltip_value-text' style='padding: 1px 7px; border-radius: 15px; color:white; text-align:center; width:40px; background-color: " +
                                                serie.getColor() +
                                                "'>";
                                              html += formatNumber(
                                                dataSerie.val,
                                              );
                                              html += "</div>";
                                              html += "</div>";
                                            }
                                          }
                                        });
                                      }

                                      if (arg.percentText) {
                                        html += "<div class='tooltip_row'>";
                                        html +=
                                          "<div class='tooltip_series-name'>";
                                        html +=
                                          getTrad("porcentaje").toUpperCase() +
                                          ":";
                                        html += "</div>";
                                        html +=
                                          "<div class='tooltip_value-text'>";
                                        html += arg.percentText;
                                        html += "</div>";
                                        html += "</div>";
                                      }

                                      html += "</div>";
                                      html += "</div>";

                                      return {
                                        html: html,
                                      };
                                    },
                                  },
                                  onLegendClick: function (e) {
                                    var series = e.target;
                                    if (series.isVisible()) {
                                      series.hide();
                                    } else {
                                      series.show();
                                    }
                                  },
                                  adaptiveLayout: {
                                    width: 800,
                                  },
                                  loadingIndicator: {
                                    backgroundColor: "none",
                                    font: {
                                      size: 14,
                                    },
                                  },
                                });

                                var dxChart_contenedorSuperior = $(
                                  "<div class='dxChart_contenedorSuperior'/>",
                                ).dxToolbar({
                                  width: 450,
                                  items: [
                                    {
                                      location: "before",
                                      template: function () {
                                        var container = $(
                                          "<div style='padding: 3px 10px; border-radius: 3px; border: 1px dotted grey; width:165px; background-color: white;'/>",
                                        );

                                        $(
                                          "<div style='text-align: left; color: grey;'/>",
                                        )
                                          .text(getTrad("totalKgEntregados"))
                                          .appendTo(container);
                                        $(
                                          "<div id='lblInfoGeneral_KgEstancias_lblValKg' style='text-align: left; color: rgba(128, 128, 128, 1); font-size: 18px; font-weight: 600;'/>",
                                        ).appendTo(container);

                                        return container;
                                      },
                                    },
                                    {
                                      location: "before",
                                      template: function () {
                                        var container = $(
                                          "<div style='padding: 3px 10px; border-radius: 3px; border: 1px dotted grey; width:140px; background-color: white;'/>",
                                        );

                                        $(
                                          "<div style='text-align: left; color: grey;'/>",
                                        )
                                          .text(getTrad("totalEstancias"))
                                          .appendTo(container);
                                        $(
                                          "<div id='lblInfoGeneral_KgEstancias_lblValEstancias' style='text-align: left; color: rgba(128, 128, 128, 1); font-size: 18px; font-weight: 600;'/>",
                                        ).appendTo(container);

                                        return container;
                                      },
                                    },
                                    {
                                      location: "before",
                                      template: function () {
                                        var container = $(
                                          "<div style='padding: 3px 10px; border-radius: 3px; border: 1px dotted grey; width:150px; background-color: white;'/>",
                                        );

                                        $(
                                          "<div style='text-align: left; color: grey;'/>",
                                        )
                                          .text(getTrad("mediaKgEstancia"))
                                          .appendTo(container);
                                        $(
                                          "<div id='lblInfoGeneral_KgEstancias_lblValRatio' style='text-align: left; color: rgba(128, 128, 128, 1); font-size: 18px; font-weight: 600;'/>",
                                        ).appendTo(container);

                                        return container;
                                      },
                                    },
                                  ],
                                });

                                chart.appendTo(container);
                                dxChart_contenedorSuperior.appendTo(container);

                                return container;
                              },
                            },
                            {
                              baseSize: 200,
                              shrink: 0,
                              ratio: 0,
                              template: function (e, index, item) {
                                item.css("display", "flex");
                                item.css("flex-direction", "row");
                                item.css("margin-top", "20px");

                                item.append(
                                  $("<div />")
                                    .addClass("container_spanCentrado")
                                    .css("padding-left", "12px")
                                    .css("padding-right", "20px")
                                    .append(
                                      $("<span />")
                                        .addClass("spanVertical")
                                        .text(getTrad("kgEstancia")),
                                    ),
                                );

                                var scrollView = $(
                                  "<div id='scrollView_filtroTiempo_kgEstancias'/>",
                                );
                                var dxRangeSelector = $(
                                  "<div id='dxRangeSelector_filtroTiempo_kgEstancias'/>",
                                );

                                dxRangeSelector.appendTo(scrollView);

                                scrollView.dxScrollView({
                                  height: "100%",
                                  width: "100%",
                                  direction: "horizontal",
                                  showScrollbar: "always",
                                  onScroll: function (e) {
                                    var scrollView_filtroTiempo_ratiosCambio =
                                      $(
                                        "#scrollView_filtroTiempo_ratiosCambio",
                                      ).dxScrollView("instance");
                                    var scrollLeft_ratiosCambio =
                                      scrollView_filtroTiempo_ratiosCambio.scrollLeft();
                                    var scrollLeft_kgEstancias =
                                      e.scrollOffset.left;

                                    if (
                                      scrollLeft_kgEstancias !==
                                      scrollLeft_ratiosCambio
                                    ) {
                                      scrollView_filtroTiempo_ratiosCambio.scrollTo(
                                        {
                                          left: scrollLeft_kgEstancias,
                                          top: 0,
                                        },
                                      );
                                    }
                                  },
                                });

                                dxRangeSelector.dxRangeSelector({
                                  dataSource:
                                    datasource_filtroTiempo_kgEstancias,
                                  chart: {
                                    series: [
                                      {
                                        color: "#ff950c",
                                        type: "line",
                                        label: {
                                          visible: true,
                                          customizeText: function (e) {
                                            var kg = e.point.data.kg;
                                            var estancias =
                                              e.point.data.estancias;

                                            var text = "";

                                            if (kg === 0 || estancias === 0) {
                                              text =
                                                getTrad(
                                                  "sinDatos",
                                                ).toUpperCase();
                                            } else {
                                              text = formatNumber(
                                                e.valueText,
                                                3,
                                                "decimal",
                                              );
                                            }

                                            return text;
                                          },
                                        },
                                      },
                                    ],
                                    legend: {
                                      visible: false,
                                    },
                                  },
                                  scale: {
                                    minorTickCount: 1,
                                    label: {
                                      customizeText: function (e) {
                                        var fecha = convertClienteUtcToSinUtc(
                                          new Date(e.valueText),
                                        );
                                        var mes = fecha.toLocaleString(
                                          IDIOMA.codigo,
                                          { month: "short" },
                                        );
                                        var año = fecha.getFullYear();
                                        var text = mes;

                                        if (año !== new Date().getFullYear()) {
                                          text += " (" + año + ")";
                                        }

                                        return text.toUpperCase();
                                      },
                                    },
                                  },
                                  sliderMarker: {
                                    customizeText: function (e) {
                                      var fecha = convertClienteUtcToSinUtc(
                                        new Date(e.valueText),
                                      );
                                      var mes = fecha.toLocaleString(
                                        IDIOMA.codigo,
                                        { month: "long" },
                                      );
                                      var año = fecha.getFullYear();
                                      var text = mes;

                                      if (año !== new Date().getFullYear()) {
                                        text += " (" + año + ")";
                                      }

                                      return text.toUpperCase();
                                    },
                                  },
                                  behavior: {
                                    callValueChanged: "onMoving",
                                  },
                                  onValueChanged: function (e) {
                                    if (!e.component.option("value")) {
                                      var items = e.component
                                        .getDataSource()
                                        .items();

                                      var mesSeleccionado = null;
                                      var ultimaPosicion = items.length - 1;

                                      var datosEncontrados = $.grep(
                                        items,
                                        function (v) {
                                          return v.val !== 0;
                                        },
                                      );

                                      if (datosEncontrados.length > 0) {
                                        while (!mesSeleccionado) {
                                          if (items[ultimaPosicion].val !== 0) {
                                            mesSeleccionado =
                                              items[ultimaPosicion];
                                          } else {
                                            ultimaPosicion--;
                                          }
                                        }
                                        var fecha = mesSeleccionado.arg;
                                        if (
                                          param_paginaSecundaria != null &&
                                          selectedTab == 1
                                        )
                                          e.component.option("value", [
                                            formatDate_parameter(
                                              fechaDesde_kgEstancias,
                                            ),
                                            formatDate_parameter(
                                              fechaDesde_kgEstancias,
                                            ),
                                          ]);
                                        else
                                          e.component.option("value", [
                                            fecha,
                                            fecha,
                                          ]);
                                      } else {
                                        fechaDesde_kgEstancias = new Date();
                                        fechaHasta_kgEstancias = new Date();

                                        filtroTiempo_kgEstancias_ValueChanged();
                                      }
                                    } else {
                                      fechaDesde_kgEstancias =
                                        convertClienteUtcToSinUtc(
                                          new Date(e.value[0]),
                                        );
                                      fechaHasta_kgEstancias = endOfMonth(
                                        convertClienteUtcToSinUtc(
                                          new Date(e.value[1]),
                                        ),
                                      );

                                      filtroTiempo_kgEstancias_ValueChanged();

                                      //Propagamos cambio de fecha
                                      if (
                                        formatDate_parameter(
                                          fechaDesde_kgEstancias,
                                        ) !==
                                          formatDate_parameter(
                                            fechaDesde_ratiosCambio,
                                          ) ||
                                        formatDate_parameter(
                                          fechaHasta_kgEstancias,
                                        ) !==
                                          formatDate_parameter(
                                            fechaHasta_ratiosCambio,
                                          )
                                      ) {
                                        //Se propaga el cambio de tiempo es diferente
                                        var dxRangeSelector_filtroTiempo_ratiosCambio =
                                          $(
                                            "#dxRangeSelector_filtroTiempo_ratiosCambio",
                                          ).dxRangeSelector("instance");

                                        var fechasEncontradas = $.grep(
                                          datasource_filtroTiempo_ratiosCambio.items(),
                                          function (v) {
                                            return (
                                              v.arg === e.value[0] ||
                                              v.arg === e.value[1]
                                            );
                                          },
                                        );

                                        if (
                                          (fechasEncontradas.length === 1 &&
                                            e.value[0] === e.value[1]) ||
                                          fechasEncontradas.length === 2
                                        ) {
                                          dxRangeSelector_filtroTiempo_ratiosCambio.option(
                                            "value",
                                            [e.value[0], e.value[1]],
                                          );
                                        }
                                      }

                                      calcularInfoGeneral_KgEstancias();
                                    }
                                  },
                                });

                                item.append(scrollView);
                              },
                            },
                          ],
                        });
                      },
                    },
                    {
                      title: getTrad("ratiosCambio"),
                      visible: false,
                      template: function () {
                        return $("<div />").dxBox({
                          direction: "col",
                          align: "space-around",
                          crossAlign: "stretch",
                          height: "100%",
                          items: [
                            {
                              baseSize: 50,
                              shrink: 0,
                              ratio: 0,
                              template: function () {
                                return $("<div />").dxToolbar({
                                  items: [
                                    {
                                      location: "before",
                                      widget: "dxButton",
                                      options: {
                                        text: getTrad("volverFiltrar"),
                                        icon: "chevronleft",
                                        onClick: function () {
                                          var tabpanel = $(
                                            "#tabpanel-container",
                                          ).dxTabPanel("instance");
                                          tabpanel.option("selectedIndex", 0);
                                        },
                                      },
                                    },
                                    {
                                      location: "center",
                                      template: function () {
                                        return $(
                                          "<span class='titulo_Entidad' />",
                                        )
                                          .css(
                                            "color",
                                            "rgba(102, 102, 102, 1)",
                                          )
                                          .css("font-size", "24px");
                                      },
                                    },
                                  ],
                                });
                              },
                            },
                            {
                              ratio: 1,
                              template: function () {
                                var container = $("<div style='height:100%'/>");

                                var chart_ratios = $(
                                  "<div id='dxChart_grafica_ratiosCambio' style='height:100%' />",
                                ).dxChart({
                                  dataSource: datasource_datos_ratiosCambio,
                                  commonSeriesSettings: {
                                    type: "bar",
                                    ignoreEmptyPoints: true,
                                    label: {
                                      customizeText: function (e) {
                                        return formatNumber(
                                          e.valueText,
                                          3,
                                          "decimal",
                                        );
                                      },
                                      showForZeroValues: false,
                                      visible: true,
                                      border: {
                                        width: 1,
                                        color: "darkgrey",
                                        visible: true,
                                      },
                                      font: {
                                        color: "white",
                                      },
                                    },
                                  },
                                  seriesTemplate: {
                                    nameField: "arg",
                                  },
                                  valueAxis: [
                                    {
                                      position: "left",
                                      grid: {
                                        visible: true,
                                      },
                                      title: {
                                        text: enum_informes[1].text,
                                      },
                                      maxValueMargin: 0.3,
                                      minValueMargin: 0.3,
                                    },
                                  ],

                                  pathModified: true,
                                  legend: {
                                    visible: false,
                                  },
                                  onPointClick: function (e) {
                                    tipoPrendaSel = {};
                                    tipoPrendaSel.denominacion =
                                      e.target.argument;
                                    tipoPrendaSel.idTipoPrenda =
                                      e.target.data.idTipoPrenda;
                                    tipoPrendaSel.color =
                                      e.target.series.getColor();

                                    datasource_datos_ratiosCambio_tipoPrenda
                                      .load()
                                      .done(function () {
                                        $("#dxChart_grafica_ratiosCambio").css(
                                          "display",
                                          "none",
                                        );

                                        var dxChart_grafica_ratiosCambio_tipoPrenda =
                                          $(
                                            "#dxChart_grafica_ratiosCambio_tipoPrenda",
                                          );
                                        dxChart_grafica_ratiosCambio_tipoPrenda.css(
                                          "display",
                                          "block",
                                        );

                                        var title = {
                                          text: tipoPrendaSel.denominacion,
                                          subTitle: {
                                            text:
                                              getTrad("ratio") +
                                              ": " +
                                              e.target.value +
                                              "%",
                                          },
                                        };
                                        dxChart_grafica_ratiosCambio_tipoPrenda
                                          .dxChart("instance")
                                          .option("title", title);
                                        dxChart_grafica_ratiosCambio_tipoPrenda
                                          .dxChart("instance")
                                          .render();

                                        configOpciones();

                                        $("#backButton_ratio")
                                          .dxButton("instance")
                                          .option("visible", true);
                                      });
                                  },
                                  argumentAxis: {
                                    label: {
                                      overlappingBehavior: "none",
                                      rotationAngle: -45,
                                      displayMode: "rotate",
                                    },
                                  },
                                  onLegendClick: function (e) {
                                    var series = e.target;
                                    if (series.isVisible()) {
                                      series.hide();
                                    } else {
                                      series.show();
                                    }
                                  },
                                  tooltip: {
                                    enabled: true,
                                    location: "edge",
                                    customizeTooltip: function (arg) {
                                      var data = arg.point.data;

                                      var html = "<div>";
                                      html += "<div class='tooltip_header'>";
                                      html += "<div class='tooltip_title'>";
                                      html += data.arg;
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text' style='background-color: " +
                                        arg.point.series.getColor() +
                                        "'>";
                                      html += formatNumber(
                                        data.val,
                                        3,
                                        "decimal",
                                      );
                                      html += "</div>";
                                      html += "</div>";

                                      html += "<div class='tooltip_body'>";

                                      html += "<div class='tooltip_row'>";
                                      html +=
                                        "<div class='tooltip_series-name'>";
                                      html +=
                                        getTrad("cantidad").toUpperCase() + ":";
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text'>";
                                      html += formatNumber(data.cantidad);
                                      html += "</div>";
                                      html += "</div>";

                                      html += "<div class='tooltip_row'>";
                                      html +=
                                        "<div class='tooltip_series-name'>";
                                      html +=
                                        getTrad("estancias").toUpperCase() +
                                        ":";
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text'>";
                                      html += formatNumber(data.estancias);
                                      html += "</div>";
                                      html += "</div>";

                                      if (arg.percentText) {
                                        html += "<div class='tooltip_row'>";
                                        html +=
                                          "<div class='tooltip_series-name'>";
                                        html +=
                                          getTrad("porcentaje").toUpperCase() +
                                          ":";
                                        html += "</div>";
                                        html +=
                                          "<div class='tooltip_value-text'>";
                                        html += arg.percentText;
                                        html += "</div>";
                                        html += "</div>";
                                      }

                                      html += "</div>";
                                      html += "</div>";

                                      return {
                                        html: html,
                                      };
                                    },
                                  },
                                  loadingIndicator: {
                                    backgroundColor: "none",
                                    font: {
                                      size: 14,
                                    },
                                  },
                                  adaptiveLayout: {
                                    width: 800,
                                  },
                                  palette: "Soft Blue",
                                });

                                var chart_ratios_tipoPrenda = $(
                                  "<div id='dxChart_grafica_ratiosCambio_tipoPrenda' style='height:100%; display:none;'/>",
                                ).dxChart({
                                  dataSource:
                                    datasource_datos_ratiosCambio_tipoPrenda,
                                  commonSeriesSettings: {
                                    argumentField: "arg",
                                    valueField: "val",
                                    type: "stackedBar",
                                    label: {
                                      customizeText: function (e) {
                                        return formatNumber(
                                          e.valueText,
                                          3,
                                          "decimal",
                                        );
                                      },
                                      showForZeroValues: false,
                                      visible: true,
                                      border: {
                                        width: 1,
                                        color: "darkgrey",
                                        visible: true,
                                      },
                                      font: {
                                        color: "white",
                                      },
                                    },
                                  },
                                  seriesTemplate: {
                                    nameField: "series",
                                    customizeSeries: function (
                                      valueFromNameField,
                                    ) {
                                      return valueFromNameField === "TOTAL"
                                        ? {
                                            axis: isGrafica_agrupada
                                              ? ""
                                              : "TOTAL",
                                            type: "scatter",
                                            color: "#008fd8",
                                            label: {
                                              showForZeroValues: false,
                                              visible: true,
                                              backgroundColor: "white",
                                              border: {
                                                width: 1,
                                                color: "darkgrey",
                                                visible: true,
                                              },
                                              font: {
                                                color: "grey",
                                              },
                                            },
                                            point: {
                                              size: isGrafica_agrupada ? 6 : 12,
                                              color: "white",
                                              border: {
                                                width: 2,
                                                color: "#008fd8",
                                                visible: true,
                                              },
                                              hoverStyle: {
                                                color: "#008fd8",
                                                border: {
                                                  width: 2,
                                                  color: "#008fd8",
                                                  visible: true,
                                                },
                                              },
                                            },
                                          }
                                        : {};
                                    },
                                  },
                                  valueAxis: [
                                    {
                                      position: "left",
                                      grid: {
                                        visible: true,
                                      },
                                      title: {
                                        text: enum_informes[0].text,
                                      },
                                    },
                                    {
                                      name: "TOTAL",
                                      position: "right",
                                      title: {
                                        text: " ",
                                      },
                                      maxValueMargin: 0.3,
                                      minValueMargin: 0.3,
                                    },
                                  ],
                                  pathModified: true,
                                  argumentAxis: {
                                    label: {
                                      overlappingBehavior: "none",
                                      rotationAngle: -45,
                                      customizeText: function (e) {
                                        if (
                                          isComparativa_ratiosCambio_tipoPrenda()
                                        ) {
                                          var fecha = convertClienteUtcToSinUtc(
                                            new Date(e.value),
                                          );
                                          if (
                                            new Date().getFullYear() ===
                                            fecha.getFullYear()
                                          ) {
                                            return fecha
                                              .toLocaleString(IDIOMA.codigo, {
                                                month: "long",
                                              })
                                              .toUpperCase();
                                          } else {
                                            return fecha
                                              .toLocaleString(IDIOMA.codigo, {
                                                month: "long",
                                                year: "numeric",
                                              })
                                              .toUpperCase();
                                          }
                                        } else {
                                          return e.value;
                                        }
                                      },
                                    },
                                  },
                                  legend: {
                                    verticalAlignment: "top",
                                    horizontalAlignment: "right",
                                    orientation: "horizontal",
                                  },
                                  tooltip: {
                                    enabled: true,
                                    location: "edge",
                                    customizeTooltip: function (arg) {
                                      var data = arg.point.data;

                                      var html = "<div>";
                                      html += "<div class='tooltip_header'>";
                                      html += "<div class='tooltip_title'>";
                                      html += data.series;
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text' style='background-color: " +
                                        arg.point.series.getColor() +
                                        "'>";
                                      html += formatNumber(
                                        data.val,
                                        3,
                                        "decimal",
                                      );
                                      html += "</div>";
                                      html += "</div>";

                                      html += "<div class='tooltip_body'>";

                                      html += "<div class='tooltip_row'>";
                                      html +=
                                        "<div class='tooltip_series-name'>";
                                      html +=
                                        getTrad("cantidad").toUpperCase() + ":";
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text'>";
                                      html += formatNumber(data.cantidad);
                                      html += "</div>";
                                      html += "</div>";

                                      html += "<div class='tooltip_row'>";
                                      html +=
                                        "<div class='tooltip_series-name'>";
                                      html +=
                                        getTrad("estancias").toUpperCase() +
                                        ":";
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text'>";
                                      html += formatNumber(data.estancias);
                                      html += "</div>";
                                      html += "</div>";

                                      html += "<div class='tooltip_row'>";
                                      html +=
                                        "<div class='tooltip_series-name'>";
                                      html +=
                                        getTrad("salidas").toUpperCase() + ":";
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text'>";
                                      html += formatNumber(data.salidas);
                                      html += "</div>";
                                      html += "</div>";

                                      html += "<div class='tooltip_row'>";
                                      html +=
                                        "<div class='tooltip_series-name'>";
                                      html +=
                                        getTrad("ratioSalidas").toUpperCase() +
                                        ":";
                                      html += "</div>";
                                      html +=
                                        "<div class='tooltip_value-text'>";
                                      html += formatNumber(data.valSalidas);
                                      html += "</div>";
                                      html += "</div>";

                                      if (data.series === "TOTAL") {
                                        //Desglose se las series
                                        var chart = $(
                                          "#dxChart_grafica_ratiosCambio_tipoPrenda",
                                        ).dxChart("instance");
                                        var series = chart.getAllSeries();

                                        $.each(series, function (index, serie) {
                                          var points = serie.getPointsByArg(
                                            arg.argument,
                                          );
                                          if (points.length > 0) {
                                            var dataSerie = points[0].data;
                                            if (
                                              dataSerie.series !== data.series
                                            ) {
                                              html +=
                                                "<div class='tooltip_row'>";
                                              html +=
                                                "<div class='tooltip_series-name'>";
                                              html += dataSerie.series + ":";
                                              html += "</div>";
                                              html +=
                                                "<div class='tooltip_value-text' style='padding: 1px 7px; border-radius: 15px; color:white; text-align:center; width:40px; background-color: " +
                                                serie.getColor() +
                                                "'>";
                                              html += formatNumber(
                                                dataSerie.val,
                                              );
                                              html += "</div>";
                                              html += "</div>";
                                            }
                                          }
                                        });
                                      }

                                      if (arg.percentText) {
                                        html += "<div class='tooltip_row'>";
                                        html +=
                                          "<div class='tooltip_series-name'>";
                                        html +=
                                          getTrad("porcentaje").toUpperCase() +
                                          ":";
                                        html += "</div>";
                                        html +=
                                          "<div class='tooltip_value-text'>";
                                        html += arg.percentText;
                                        html += "</div>";
                                        html += "</div>";
                                      }

                                      html += "</div>";
                                      html += "</div>";

                                      return {
                                        html: html,
                                      };
                                    },
                                  },
                                  onLegendClick: function (e) {
                                    var series = e.target;
                                    if (series.isVisible()) {
                                      series.hide();
                                    } else {
                                      series.show();
                                    }
                                  },
                                  adaptiveLayout: {
                                    width: 800,
                                  },
                                  loadingIndicator: {
                                    backgroundColor: "none",
                                    font: {
                                      size: 14,
                                    },
                                  },
                                });

                                var dxChart_contenedorSuperior = $(
                                  "<div class='dxChart_contenedorSuperior'/>",
                                );
                                var backButton_ratio = $(
                                  "<div id='backButton_ratio'/>",
                                );

                                backButton_ratio.dxButton({
                                  text: getTrad("atras"),
                                  icon: "chevronleft",
                                  visible: false,
                                  onClick: function () {
                                    tipoPrendaSel = null;
                                    datasource_datos_ratiosCambio_tipoPrenda.load();

                                    this.option("visible", false);

                                    var dxChart_grafica_ratiosCambio = $(
                                      "#dxChart_grafica_ratiosCambio",
                                    );
                                    dxChart_grafica_ratiosCambio.css(
                                      "display",
                                      "block",
                                    );
                                    dxChart_grafica_ratiosCambio
                                      .dxChart("instance")
                                      .render();

                                    $(
                                      "#dxChart_grafica_ratiosCambio_tipoPrenda",
                                    ).css("display", "none");

                                    configOpciones();
                                  },
                                });

                                chart_ratios.appendTo(container);
                                chart_ratios_tipoPrenda.appendTo(container);

                                backButton_ratio.appendTo(
                                  dxChart_contenedorSuperior,
                                );
                                dxChart_contenedorSuperior.appendTo(container);

                                container.append(
                                  $("<div id='dxPopover_ratios'>").dxPopover({
                                    target: "#dxChart_grafica_ratiosCambio",
                                    showEvent: "mouseenter",
                                    hideEvent: "mouseleave",
                                    position: {
                                      at: "right top",
                                      offset: "-150, -50",
                                    },
                                    width: 300,
                                    contentTemplate: function (contentElement) {
                                      contentElement.append(
                                        $("<div />").text(
                                          getTrad("msg_drilldownTipoPrenda"),
                                        ),
                                      );
                                    },
                                  }),
                                );

                                return container;
                              },
                            },
                            {
                              baseSize: 200,
                              shrink: 0,
                              ratio: 0,
                              template: function (e, index, item) {
                                item.css("display", "flex");
                                item.css("flex-direction", "row");
                                item.css("margin-top", "20px");

                                item.append(
                                  $("<div />")
                                    .addClass("container_spanCentrado")
                                    .css("padding-left", "12px")
                                    .css("padding-right", "20px")
                                    .append(
                                      $("<span />")
                                        .addClass("spanVertical")
                                        .text(getTrad("estancias")),
                                    ),
                                );

                                var scrollView = $(
                                  "<div id='scrollView_filtroTiempo_ratiosCambio'  />",
                                );
                                var dxRangeSelector = $(
                                  "<div id='dxRangeSelector_filtroTiempo_ratiosCambio'/>",
                                );

                                dxRangeSelector.appendTo(scrollView);

                                scrollView.dxScrollView({
                                  height: "100%",
                                  width: "100%",
                                  direction: "horizontal",
                                  showScrollbar: "always",
                                  onScroll: function (e) {
                                    var scrollView_filtroTiempo_kgEstancias = $(
                                      "#scrollView_filtroTiempo_kgEstancias",
                                    ).dxScrollView("instance");
                                    var scrollLeft_kgEstancias =
                                      scrollView_filtroTiempo_kgEstancias.scrollLeft();
                                    var scrollLeft_ratiosCambio =
                                      e.scrollOffset.left;

                                    if (
                                      scrollLeft_kgEstancias !==
                                      scrollLeft_ratiosCambio
                                    ) {
                                      scrollView_filtroTiempo_kgEstancias.scrollTo(
                                        {
                                          left: scrollLeft_ratiosCambio,
                                          top: 0,
                                        },
                                      );
                                    }
                                  },
                                });

                                dxRangeSelector.dxRangeSelector({
                                  dataSource:
                                    datasource_filtroTiempo_ratiosCambio,
                                  chart: {
                                    series: [
                                      {
                                        color: "#68A9CC",
                                        type: "line",
                                        label: {
                                          visible: true,
                                          customizeText: function (e) {
                                            if (e.value === 0) {
                                              return getTrad(
                                                "sinDatos",
                                              ).toUpperCase();
                                            }
                                            return formatNumber(e.valueText);
                                          },
                                        },
                                      },
                                    ],
                                    legend: {
                                      visible: false,
                                    },
                                  },
                                  scale: {
                                    minorTickCount: 1,
                                    label: {
                                      customizeText: function (e) {
                                        var fecha = convertClienteUtcToSinUtc(
                                          new Date(e.valueText),
                                        );
                                        var mes = fecha.toLocaleString(
                                          IDIOMA.codigo,
                                          { month: "short" },
                                        );
                                        var año = fecha.getFullYear();
                                        var text = mes;

                                        if (año !== new Date().getFullYear()) {
                                          text += " (" + año + ")";
                                        }

                                        return text.toUpperCase();
                                      },
                                    },
                                  },
                                  sliderMarker: {
                                    customizeText: function (e) {
                                      var fecha = convertClienteUtcToSinUtc(
                                        new Date(e.valueText),
                                      );
                                      var mes = fecha.toLocaleString(
                                        IDIOMA.codigo,
                                        { month: "long" },
                                      );
                                      var año = fecha.getFullYear();
                                      var text = mes;

                                      if (año !== new Date().getFullYear()) {
                                        text += " (" + año + ")";
                                      }

                                      return text.toUpperCase();
                                    },
                                  },
                                  behavior: {
                                    callValueChanged: "onMoving",
                                  },
                                  onValueChanged: function (e) {
                                    if (!e.component.option("value")) {
                                      var items = e.component
                                        .getDataSource()
                                        .items();

                                      var mesSeleccionado = null;
                                      var ultimaPosicion = items.length - 1;

                                      var datosEncontrados = $.grep(
                                        items,
                                        function (v) {
                                          return v.val !== 0;
                                        },
                                      );

                                      if (datosEncontrados.length > 0) {
                                        while (!mesSeleccionado) {
                                          if (items[ultimaPosicion].val !== 0) {
                                            mesSeleccionado =
                                              items[ultimaPosicion];
                                          } else {
                                            ultimaPosicion--;
                                          }
                                        }
                                        var fecha = mesSeleccionado.arg;
                                        if (
                                          param_paginaSecundaria != null &&
                                          selectedTab == 1
                                        )
                                          e.component.option("value", [
                                            formatDate_parameter(
                                              fechaDesde_kgEstancias,
                                            ),
                                            formatDate_parameter(
                                              fechaDesde_kgEstancias,
                                            ),
                                          ]);
                                        else
                                          e.component.option("value", [
                                            fecha,
                                            fecha,
                                          ]);
                                      } else {
                                        $("#backButton_ratio").trigger("click");

                                        fechaDesde_ratiosCambio = new Date();
                                        fechaHasta_ratiosCambio = new Date();

                                        filtroTiempo_ratiosCambio_ValueChanged();
                                      }
                                    } else {
                                      fechaDesde_ratiosCambio =
                                        convertClienteUtcToSinUtc(
                                          new Date(e.value[0]),
                                        );
                                      fechaHasta_ratiosCambio = endOfMonth(
                                        convertClienteUtcToSinUtc(
                                          new Date(e.value[1]),
                                        ),
                                      );

                                      filtroTiempo_ratiosCambio_ValueChanged();

                                      //Propagamos cambio de fecha
                                      if (
                                        formatDate_parameter(
                                          fechaDesde_kgEstancias,
                                        ) !==
                                          formatDate_parameter(
                                            fechaDesde_ratiosCambio,
                                          ) ||
                                        formatDate_parameter(
                                          fechaHasta_kgEstancias,
                                        ) !==
                                          formatDate_parameter(
                                            fechaHasta_ratiosCambio,
                                          )
                                      ) {
                                        //Se propaga el cambio de tiempo es diferente
                                        var dxRangeSelector_filtroTiempo_kgEstancias =
                                          $(
                                            "#dxRangeSelector_filtroTiempo_kgEstancias",
                                          ).dxRangeSelector("instance");

                                        var fechasEncontradas = $.grep(
                                          datasource_filtroTiempo_kgEstancias.items(),
                                          function (v) {
                                            return (
                                              v.arg === e.value[0] ||
                                              v.arg === e.value[1]
                                            );
                                          },
                                        );

                                        if (
                                          (fechasEncontradas.length === 1 &&
                                            e.value[0] === e.value[1]) ||
                                          fechasEncontradas.length === 2
                                        ) {
                                          dxRangeSelector_filtroTiempo_kgEstancias.option(
                                            "value",
                                            [e.value[0], e.value[1]],
                                          );
                                        }
                                      }
                                    }
                                  },
                                });

                                item.append(scrollView);
                              },
                            },
                          ],
                        });
                      },
                    },
                  ],
                  onSelectionChanged: function (e) {
                    var tabpanel = e.component;
                    var index = tabpanel.option("selectedIndex");
                    if (index === 0) {
                      tabpanel.option("items[0].visible", true);
                      tabpanel.option("items[1].visible", false);
                      tabpanel.option("items[2].visible", false);

                      $("#backButton_ratio").trigger("click");
                      $("#backButton_ratio")
                        .dxButton("instance")
                        .option("visible", false);

                      $("#opciones_container").removeClass("opened");
                      $("#dxForm_opciones")
                        .dxForm("instance")
                        .option("disabled", true);

                      opciones.isAcumulado = false;
                      opciones.isExtra = null;
                    } else {
                      tabpanel.option("items[0].visible", false);
                      tabpanel.option("items[1].visible", true);
                      tabpanel.option("items[2].visible", true);

                      $("#dxChart_grafica_kgEstancias")
                        .dxChart("instance")
                        .render();
                      $("#dxChart_grafica_ratiosCambio")
                        .dxChart("instance")
                        .render();

                      $("#dxForm_opciones")
                        .dxForm("instance")
                        .option("disabled", false);

                      $("#filtro_dxList_informe")
                        .dxList("instance")
                        .option("selectedItemKeys", [
                          tabpanel.option("selectedIndex"),
                        ]);

                      configOpciones();

                      var items = datasource_filtroTiempo_ratiosCambio.items();
                      filtroTiempo_ratiosCambio_Resize(items.length);
                    }
                  },
                })
                .dxTabPanel("instance");

              item.append(tabpanel.element());
            },
          },
        ],
      });

      $("#dxForm_opciones").dxForm({
        formData: opciones,
        labelLocation: "top",
        height: "100%",
        scrollingEnabled: true,
        colCount: 1,
        disabled: true,
        items: [
          {
            dataField: "isAcumulado",
            label: { text: getTrad("tipoGrafica") },
            editorType: "dxRadioGroup",
            editorOptions: {
              items: [
                {
                  text: getTrad("acumulado"),
                  value: true,
                },
                {
                  text: getTrad("comparativa"),
                  value: false,
                },
              ],
              layout: "vertical",
              valueExpr: "value",
              elementAttr: {
                class: "dxRadioGroup_opciones",
              },
              onValueChanged: function (e) {
                recargarGrafica_kgEstancias();
                if (tipoPrendaSel) {
                  recargarGrafica_ratiosCambio_tipoPrenda();
                }
              },
            },
          },
          {
            dataField: "isExtra",
            label: { text: getTrad("categoriaPrendas") },
            editorType: "dxRadioGroup",
            editorOptions: {
              items: [
                {
                  text: getTrad("todas"),
                  value: null,
                },
                {
                  text: getTrad("estandar"),
                  value: false,
                },
                {
                  text: getTrad("extra"),
                  value: true,
                },
              ],
              layout: "vertical",
              valueExpr: "value",
              elementAttr: {
                class: "dxRadioGroup_opciones",
              },
              onValueChanged: function (e) {
                recargaFiltroTiempo_kgEstancias();
                recargaFiltroTiempo_ratiosCambio();
              },
            },
          },
        ],
      });
    })
    .always(function () {
      if (param_paginaSecundaria != null) {
        $("#tabpanel-container")
          .dxTabPanel("instance")
          .option("selectedIndex", selectedTab);

        if (selectedTab == 0)
          $("#filtro_dxList_informe").dxList("instance").selectItem(0);
      }

      setTimeout(() => {
        filtroTiempo_ratiosCambio_Resize();
      }, 50);
      deferred.resolve();
    });

  function recargarGrafica_kgEstancias() {
    var chart = $("#dxChart_grafica_kgEstancias").dxChart("instance");

    if (datasource_datos_loadPromise_kgEstancias) {
      datasource_datos_kgEstancias.cancel(
        datasource_datos_loadPromise_kgEstancias.operationId,
      );
      datasource_datos_loadPromise_kgEstancias = null;
    }

    chart.showLoadingIndicator();
    datasource_datos_loadPromise_kgEstancias =
      datasource_datos_kgEstancias.load();
  }

  function recargarGrafica_ratiosCambio_tipoPrenda() {
    var chart = $("#dxChart_grafica_ratiosCambio_tipoPrenda").dxChart(
      "instance",
    );

    if (datasource_datos_loadPromise_ratiosCambio_tipoPrenda) {
      datasource_datos_ratiosCambio_tipoPrenda.cancel(
        datasource_datos_loadPromise_ratiosCambio_tipoPrenda.operationId,
      );
      datasource_datos_loadPromise_ratiosCambio_tipoPrenda = null;
    }

    chart.showLoadingIndicator();
    datasource_datos_loadPromise_ratiosCambio_tipoPrenda =
      datasource_datos_ratiosCambio_tipoPrenda.load();
  }

  function recargaFiltroTiempo_kgEstancias() {
    if (datasource_filtroTiempo_loadPromise_kgEstancias) {
      datasource_filtroTiempo_kgEstancias.cancel(
        datasource_filtroTiempo_loadPromise_kgEstancias.operationId,
      );
      //datasource_filtroTiempo_kgEstancias = null;
    }
    $("#dxRangeSelector_filtroTiempo_kgEstancias")
      .dxRangeSelector("instance")
      .showLoadingIndicator();

    datasource_filtroTiempo_loadPromise_kgEstancias =
      datasource_filtroTiempo_kgEstancias.load();
  }

  function recargaFiltroTiempo_ratiosCambio() {
    if (datasource_filtroTiempo_loadPromise_ratiosCambio) {
      datasource_filtroTiempo_ratiosCambio.cancel(
        datasource_filtroTiempo_loadPromise_ratiosCambio.operationId,
      );
      //datasource_filtroTiempo_ratiosCambio = null;
    }
    $("#dxRangeSelector_filtroTiempo_ratiosCambio")
      .dxRangeSelector("instance")
      .showLoadingIndicator();

    datasource_filtroTiempo_loadPromise_ratiosCambio =
      datasource_filtroTiempo_ratiosCambio.load();
  }

  function configurarChart_kgEstancias() {
    var chart = $("#dxChart_grafica_kgEstancias").dxChart("instance");

    if (chart) {
      chart.option(
        "argumentAxis.label.displayMode",
        isGrafica_agrupada ? "rotate" : "standard",
      );
      chart.option(
        "valueAxis[0].maxValueMargin",
        isGrafica_agrupada ? 0.25 : 0.2,
      );
      chart.option(
        "commonSeriesSettings.type",
        isGrafica_agrupada ? "stackedBar" : "bar",
      );
      chart.option("commonSeriesSettings.label.visible", !isGrafica_agrupada);

      chart.render();
    }
  }

  function configurarChart_ratiosCambio_tipoPrenda() {
    var chart = $("#dxChart_grafica_ratiosCambio_tipoPrenda").dxChart(
      "instance",
    );

    if (chart) {
      chart.option(
        "argumentAxis.label.displayMode",
        isGrafica_agrupada ? "rotate" : "standard",
      );
      chart.option(
        "valueAxis[0].maxValueMargin",
        isGrafica_agrupada ? 0.25 : 0.2,
      );
      chart.option(
        "commonSeriesSettings.type",
        isGrafica_agrupada ? "stackedBar" : "bar",
      );
      chart.option("commonSeriesSettings.label.visible", !isGrafica_agrupada);

      chart.render();
    }
  }

  function filtroTiempo_kgEstancias_ValueChanged() {
    $("#dxChart_grafica_kgEstancias")
      .dxChart("instance")
      .showLoadingIndicator();

    if (datasource_datos_loadPromise_kgEstancias) {
      datasource_datos_kgEstancias.cancel(
        datasource_datos_loadPromise_kgEstancias.operationId,
      );
      datasource_datos_loadPromise_kgEstancias = null;
    }

    datasource_datos_loadPromise_kgEstancias =
      datasource_datos_kgEstancias.load();

    configOpciones();
  }

  function filtroTiempo_ratiosCambio_ValueChanged() {
    $("#dxChart_grafica_ratiosCambio")
      .dxChart("instance")
      .showLoadingIndicator();

    if (datasource_datos_loadPromise_ratiosCambio) {
      datasource_datos_ratiosCambio.cancel(
        datasource_datos_loadPromise_ratiosCambio.operationId,
      );
      datasource_datos_loadPromise_ratiosCambio = null;
    }

    datasource_datos_loadPromise_ratiosCambio =
      datasource_datos_ratiosCambio.load();

    if (tipoPrendaSel) {
      $("#dxChart_grafica_ratiosCambio_tipoPrenda")
        .dxChart("instance")
        .showLoadingIndicator();

      if (datasource_datos_loadPromise_ratiosCambio_tipoPrenda) {
        datasource_datos_ratiosCambio_tipoPrenda.cancel(
          datasource_datos_loadPromise_ratiosCambio_tipoPrenda.operationId,
        );
        datasource_datos_loadPromise_ratiosCambio_tipoPrenda = null;
      }

      datasource_datos_loadPromise_ratiosCambio_tipoPrenda =
        datasource_datos_ratiosCambio_tipoPrenda.load();
    }

    configOpciones();
  }

  function configOpciones() {
    var visible = entidadesSel.length === 1;
    var disabled = true;

    if (entidadesSel.length === 1) {
      //1 Entidad
      disabled =
        fechaDesde_kgEstancias.getMonth() === fechaHasta_kgEstancias.getMonth(); //Cantidad meses
    }

    var tabpanel = $("#tabpanel-container").dxTabPanel("instance");
    var index = tabpanel.option("selectedIndex");

    if (!tipoPrendaSel && index === 2) {
      visible = false;
    }

    $("#dxForm_opciones")
      .dxForm("instance")
      .itemOption("isAcumulado", "visible", visible);
    $("#dxForm_opciones")
      .dxForm("instance")
      .itemOption("isAcumulado", "disabled", disabled);
  }

  function isComparativa_kgEstancias() {
    var desde = fechaDesde_kgEstancias;
    var hasta = new Date(
      fechaHasta_kgEstancias.getFullYear(),
      fechaHasta_kgEstancias.getMonth() - 1,
      fechaHasta_kgEstancias.getDate(),
    );

    var isComparativa = !opciones.isAcumulado;
    var is1Mes = formatDate_parameter(desde) === formatDate_parameter(hasta);
    var is1Entidad = entidadesSel.length === 1;

    return !is1Mes && is1Entidad && isComparativa;
  }

  function isComparativa_ratiosCambio_tipoPrenda() {
    var desde = fechaDesde_ratiosCambio;
    var hasta = new Date(
      fechaHasta_ratiosCambio.getFullYear(),
      fechaHasta_ratiosCambio.getMonth() - 1,
      fechaHasta_ratiosCambio.getDate(),
    );

    var isComparativa = !opciones.isAcumulado;
    var is1Mes = formatDate_parameter(desde) === formatDate_parameter(hasta);
    var is1Entidad = entidadesSel.length === 1;

    return !is1Mes && is1Entidad && isComparativa;
  }

  function calcularInfoGeneral_KgEstancias() {
    var dxRangeSelector_filtroTiempo_kgEstancias = $(
      "#dxRangeSelector_filtroTiempo_kgEstancias",
    ).dxRangeSelector("instance");

    if (dxRangeSelector_filtroTiempo_kgEstancias) {
      var desde = new Date(
        fechaDesde_kgEstancias.getFullYear(),
        fechaDesde_kgEstancias.getMonth(),
        fechaDesde_kgEstancias.getDate(),
      );
      var hasta = new Date(
        fechaHasta_kgEstancias.getFullYear(),
        fechaHasta_kgEstancias.getMonth(),
        fechaHasta_kgEstancias.getDate(),
      );

      var totalKg = 0;
      var totalEstancias = 0;

      var fechasEncontradas = $.grep(
        dxRangeSelector_filtroTiempo_kgEstancias.getDataSource().items(),
        function (v) {
          return v.fecha >= desde && v.fecha <= hasta;
        },
      );

      $.each(fechasEncontradas, function (index, item) {
        totalKg += item.kg;
        totalEstancias += item.estancias;
      });

      $("#lblInfoGeneral_KgEstancias_lblValKg").text(
        formatNumber(Math.round(totalKg * 100) / 100, 0, "decimal") + " Kg.",
      );
      $("#lblInfoGeneral_KgEstancias_lblValEstancias").text(
        formatNumber(Math.round(totalEstancias * 100) / 100),
      );

      var msg;
      if (totalKg === 0 || totalEstancias === 0) {
        msg = getTrad("sinDatos").toUpperCase();
      } else {
        msg = formatNumber(
          Math.round((totalKg / totalEstancias) * 1000) / 1000,
          3,
          "decimal",
        );
      }
      $("#lblInfoGeneral_KgEstancias_lblValRatio").text(msg);
    }
  }

  function filtroTiempo_kgEstancias_Resize(itemsCount) {
    if (
      typeof $("#dxRangeSelector_filtroTiempo_kgEstancias")[0] !== "undefined"
    ) {
      var filtroTiempo = $(
        "#dxRangeSelector_filtroTiempo_kgEstancias",
      ).dxRangeSelector("instance");
      var contenedor_filtroTiempo = $(
        "#scrollView_filtroTiempo_kgEstancias",
      ).dxScrollView("instance");

      if (itemsCount && itemsCount > 0) {
        var contenedorWidth = contenedor_filtroTiempo.clientWidth();
        var mesesVisibles = itemsCount > 12 ? 12 : itemsCount;

        var widthMes = contenedorWidth / mesesVisibles;
        if (widthMes < 70) {
          widthMes = contenedorWidth / 6;
        }

        var widthVisible = widthMes * itemsCount;
        filtroTiempo.option("size.width", widthVisible);
      } else {
        filtroTiempo.option("size.width", "100%");
      }

      contenedor_filtroTiempo.scrollTo({
        left: $("#dxRangeSelector_filtroTiempo_kgEstancias svg").width(),
        top: 0,
      });
    }
  }

  function filtroTiempo_ratiosCambio_Resize(itemsCount) {
    if (
      typeof $("#dxRangeSelector_filtroTiempo_ratiosCambio")[0] !== "undefined"
    ) {
      var filtroTiempo = $(
        "#dxRangeSelector_filtroTiempo_ratiosCambio",
      ).dxRangeSelector("instance");
      var contenedor_filtroTiempo = $(
        "#scrollView_filtroTiempo_ratiosCambio",
      ).dxScrollView("instance");

      if (itemsCount && itemsCount > 0) {
        var contenedorWidth = contenedor_filtroTiempo.clientWidth();
        var mesesVisibles = itemsCount > 12 ? 12 : itemsCount;

        var widthMes = contenedorWidth / mesesVisibles;
        if (widthMes < 70) {
          widthMes = contenedorWidth / 6;
        }

        var widthVisible = widthMes * itemsCount;
        filtroTiempo.option("size.width", widthVisible);
      } else {
        filtroTiempo.option("size.width", "100%");
      }
      //var scrollWidth = $("#scrollView_filtroTiempo_ratiosCambio .selected-area-tracker").position().left - (contenedor_filtroTiempo.clientWidth() / 2)
      //contenedor_filtroTiempo.scrollTo({ left: scrollWidth, top: 0 });
      contenedor_filtroTiempo.scrollTo({
        left: $("#dxRangeSelector_filtroTiempo_ratiosCambio svg").width(),
        top: 0,
      });
    }
  }

  function endOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  return deferred.promise();
}
