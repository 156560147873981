import React from "react";

import { getTrad, dxMensajePregunta } from "../../../../../helpers";

import { Button } from "devextreme-react";

import "./Css.scss";

export default class EditCellComponent_isCerrado extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCerrado: props.data.value,
    };
  }

  render = () => {
    const { className } = this.props;
    const { isCerrado } = this.state;

    return (
      <Button
        className={"EditCellComponent_isCerrado font-size-lg " + className}
        icon={isCerrado ? " icon_lockOutline" : " icon_unlock"}
        type={isCerrado ? "danger" : "success"}
        onClick={this.onClick}
      />
    );
  };

  onClick = () => {
    const { data } = this.props;
    const isCerrado = data.value;
    let _this = this;
    const msg = isCerrado
      ? getTrad("alerta_aperturaDatos_mes")
      : getTrad("alerta_cierreDatos_mes");

    dxMensajePregunta(msg, [
      [
        getTrad("aceptar"),
        async function (e) {
          _this.setState({ isCerrado: !isCerrado });
          data.setValue(!isCerrado);
        },
        "danger",
      ],
      [getTrad("cancelar"), function () {}],
    ]);
  };
}
