export const megaphone = {
  v: "5.1.14",
  fr: 30,
  ip: 0,
  op: 90,
  w: 512,
  h: 512,
  nm: "Final",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: "Horn",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.3], y: [1] },
                  o: { x: [0.7], y: [0] },
                  n: ["0p3_1_0p7_0"],
                  t: 5,
                  s: [-90],
                  e: [0],
                },
                { t: 16 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [350, 350, 0], ix: 2 },
            a: { a: 0, k: [250.608, 201.17, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.18, 0.18, 0.18], y: [1, 1, 1] },
                  o: { x: [0.82, 0.82, 0.82], y: [0, 0, 0] },
                  n: ["0p18_1_0p82_0", "0p18_1_0p82_0", "0p18_1_0p82_0"],
                  t: 5,
                  s: [0, 0, 100],
                  e: [140, 140, 100],
                },
                {
                  i: { x: [0.18, 0.18, 0.18], y: [1, 1, 1] },
                  o: { x: [0.82, 0.82, 0.82], y: [0, 0, 0] },
                  n: ["0p18_1_0p82_0", "0p18_1_0p82_0", "0p18_1_0p82_0"],
                  t: 12,
                  s: [140, 140, 100],
                  e: [100, 100, 100],
                },
                { t: 24 },
              ],
              ix: 6,
            },
          },
          ao: 0,
          w: 500,
          h: 400,
          ip: 0,
          op: 90,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: "shine",
          refId: "comp_2",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 20,
                  s: [0],
                  e: [50],
                },
                { t: 26 },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0], y: [1] },
                  o: { x: [0.167], y: [0] },
                  n: ["0_1_0p167_0"],
                  t: 20,
                  s: [0],
                  e: [-270],
                },
                { t: 90 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [350, 350, 0], ix: 2 },
            a: { a: 0, k: [350, 350, 0], ix: 1 },
            s: { a: 0, k: [91.086, 91.086, 100], ix: 6 },
          },
          ao: 0,
          w: 697,
          h: 698,
          ip: 20,
          op: 90,
          st: 20,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Circle",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [349, 350, 0], ix: 2 },
            a: { a: 0, k: [350.25, 350.25, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.276, 0.276, 0.18], y: [1, 1, 1] },
                  o: { x: [0.82, 0.82, 0.82], y: [0, 0, 0] },
                  n: ["0p276_1_0p82_0", "0p276_1_0p82_0", "0p18_1_0p82_0"],
                  t: 0,
                  s: [0, 0, 100],
                  e: [100, 100, 100],
                },
                {
                  i: { x: [0.403, 0.403, 0.18], y: [1.002, 1.002, 1] },
                  o: { x: [0.54, 0.54, 0.82], y: [0.264, 0.264, 0] },
                  n: [
                    "0p403_1p002_0p54_0p264",
                    "0p403_1p002_0p54_0p264",
                    "0p18_1_0p82_0",
                  ],
                  t: 12,
                  s: [100, 100, 100],
                  e: [82.744, 82.744, 100],
                },
                {
                  i: { x: [0.64, 0.64, 0.64], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  n: ["0p64_1_0p167_0", "0p64_1_0p167_0", "0p64_1_0p167_0"],
                  t: 24,
                  s: [82.744, 82.744, 100],
                  e: [97.256, 97.256, 100],
                },
                {
                  i: { x: [0.64, 0.64, 0.64], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, -1.209] },
                  n: [
                    "0p64_1_0p167_0p167",
                    "0p64_1_0p167_0p167",
                    "0p64_1_0p167_-1p209",
                  ],
                  t: 34,
                  s: [97.256, 97.256, 100],
                  e: [90, 90, 100],
                },
                { t: 54 },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -193.3],
                        [193.3, 0],
                        [0, 193.3],
                        [-193.3, 0],
                      ],
                      o: [
                        [0, 193.3],
                        [-193.3, 0],
                        [0, -193.3],
                        [193.3, 0],
                      ],
                      v: [
                        [349.5, 0],
                        [-0.5, 350],
                        [-350.5, 0],
                        [-0.5, -350],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.195999998205, 0.149000010771, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [350.75, 350.25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 90,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Layer 2 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.24], y: [1] },
                  o: { x: [0.76], y: [0] },
                  n: ["0p24_1_0p76_0"],
                  t: 7,
                  s: [-84.905],
                  e: [0],
                },
                { t: 23 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [141.5, 279.72, 0], ix: 2 },
            a: { a: 0, k: [0.25, 0.25, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.24, 0.24, 0.24], y: [1, 1, 1] },
                  o: { x: [0.76, 0.76, 0.76], y: [0, 0, 0] },
                  n: ["0p24_1_0p76_0", "0p24_1_0p76_0", "0p24_1_0p76_0"],
                  t: 7,
                  s: [73, 73, 100],
                  e: [100, 100, 100],
                },
                { t: 23 },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [11.394, -2.109],
                        [0.48, -4.892],
                        [0, 0],
                        [5.222, -0.248],
                        [0, 0],
                        [0.373, 4.374],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.77, 19.53],
                      ],
                      o: [
                        [-2.814, 27.49],
                        [-4.832, 0.896],
                        [0, 0],
                        [-0.51, 5.203],
                        [0, 0],
                        [-4.384, 0.209],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [48.26, -45.433],
                        [14.298, -8.202],
                        [5.356, 1.612],
                        [2.128, 34.539],
                        [-7.853, 44.031],
                        [-32.906, 45.224],
                        [-41.429, 37.763],
                        [-48.26, -42.183],
                        [6.46, -44.023],
                        [7.24, -24.402],
                        [31.12, -44.852],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [48.51, 45.683], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 104,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Layer 3 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  n: "0p833_0p833_0p167_0p167",
                  t: 7,
                  s: [94.677, 236.717, 0],
                  e: [65.677, 244.717, 0],
                  to: [-4.83333349227905, 1.33333337306976, 0],
                  ti: [4.83333349227905, -1.33333337306976, 0],
                },
                { t: 18 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [22.373, 54.346, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.382, 0.382, 0.382], y: [0.79, 0.79, 1] },
                  o: { x: [0.9, 0.9, 0.9], y: [0, 0, 0] },
                  n: ["0p382_0p79_0p9_0", "0p382_0p79_0p9_0", "0p382_1_0p9_0"],
                  t: 7,
                  s: [0, 0, 100],
                  e: [105.585, 105.585, 100],
                },
                {
                  i: { x: [0.55, 0.55, 0.55], y: [1, 1, 1] },
                  o: { x: [0.273, 0.273, 0.273], y: [-0.798, -0.798, 0] },
                  n: [
                    "0p55_1_0p273_-0p798",
                    "0p55_1_0p273_-0p798",
                    "0p55_1_0p273_0",
                  ],
                  t: 18,
                  s: [105.585, 105.585, 100],
                  e: [100, 100, 100],
                },
                { t: 23 },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.039, -1.343],
                        [0, 0],
                        [1.343, 5.039],
                        [0, 0],
                        [-5.04, 1.343],
                        [-1.343, -5.039],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.04, 1.343],
                        [0, 0],
                        [-1.343, -5.039],
                        [5.039, -1.343],
                        [0, 0],
                        [1.343, 5.04],
                      ],
                      v: [
                        [14.06, 52.753],
                        [14.06, 52.753],
                        [2.456, 46.033],
                        [-20.779, -41.149],
                        [-14.059, -52.753],
                        [-2.454, -46.033],
                        [20.779, 41.149],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [22.372, 54.346], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 104,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Layer 4 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.382, y: 0.818 },
                  o: { x: 0.9, y: 0 },
                  n: "0p382_0p818_0p9_0",
                  t: 7,
                  s: [352.827, 174.156, 0],
                  e: [419.16, 157.257, 0],
                  to: [8.58332252502441, -2.40333080291748, 0],
                  ti: [-10.9508714675903, 3.06624460220337, 0],
                },
                {
                  i: { x: 0.55, y: 1 },
                  o: { x: 0.273, y: 0.202 },
                  n: "0p55_1_0p273_0p202",
                  t: 18,
                  s: [419.16, 157.257, 0],
                  e: [406.941, 159.004, 0],
                  to: [0.55583471059799, -0.15563376247883, 0],
                  ti: [-0.43566474318504, 0.12198615819216, 0],
                },
                { t: 23 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [50.564, 131.764, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.382, 0.382, 0.382], y: [0.79, 0.79, -21.191] },
                  o: { x: [0.9, 0.9, 0.9], y: [0, 0, 0] },
                  n: [
                    "0p382_0p79_0p9_0",
                    "0p382_0p79_0p9_0",
                    "0p382_-21p191_0p9_0",
                  ],
                  t: 7,
                  s: [0, 0, 100],
                  e: [105.585, 105.585, 100],
                },
                {
                  i: { x: [0.55, 0.55, 0.55], y: [1, 1, 1] },
                  o: { x: [0.273, 0.273, 0.273], y: [-0.798, -0.798, 4.459] },
                  n: [
                    "0p55_1_0p273_-0p798",
                    "0p55_1_0p273_-0p798",
                    "0p55_1_0p273_4p459",
                  ],
                  t: 18,
                  s: [105.585, 105.585, 100],
                  e: [100, 100, 100],
                },
                { t: 23 },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [9.983, -2.66],
                        [0, 0],
                        [2.661, 9.983],
                        [0, 0],
                        [-9.983, 2.661],
                        [-2.662, -9.982],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-9.982, 2.661],
                        [0, 0],
                        [-2.66, -9.983],
                        [9.984, -2.66],
                        [0, 0],
                        [2.661, 9.982],
                      ],
                      v: [
                        [34.34, 128.853],
                        [34.34, 128.853],
                        [11.352, 115.541],
                        [-47.654, -105.867],
                        [-34.341, -128.854],
                        [-11.352, -115.541],
                        [47.653, 105.867],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [50.564, 131.764], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: -4,
          op: 100,
          st: -4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Layer 5 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.13, 239.675, 0], ix: 2 },
            a: { a: 0, k: [11.26, 183.535, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [5.51, -2.61],
                        [0, 0],
                        [2.61, 5.51],
                        [0, 0],
                        [-5.51, 2.61],
                        [0, 0],
                        [-2.62, -5.51],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.52, 2.619],
                        [0, 0],
                        [-2.62, -5.51],
                        [0, 0],
                        [5.509, -2.62],
                        [0, 0],
                        [2.61, 5.51],
                      ],
                      v: [
                        [77.92, -54.305],
                        [-110.14, 34.975],
                        [-124.92, 29.715],
                        [-124.97, 29.605],
                        [-119.71, 14.835],
                        [68.351, -74.445],
                        [83.13, -69.185],
                        [83.18, -69.075],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.38, 1.41],
                        [0, 0],
                        [0.821, -0.42],
                        [0, 0],
                        [-0.87, -3.13],
                        [0, 0],
                        [-1.67, 0.06],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.24, -0.89],
                        [0, 0],
                        [-2.89, 1.48],
                        [0, 0],
                        [0.45, 1.61],
                        [0, 0],
                        [1.46, -0.049],
                      ],
                      v: [
                        [160.63, 101.565],
                        [102.83, -112.955],
                        [100.78, -113.865],
                        [-156.67, 17.785],
                        [-160.14, 25.695],
                        [-136.39, 111.585],
                        [-132.78, 114.225],
                        [158.521, 104.424],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [161.26, 114.535], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 104,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_2",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Layer 6 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [276.898, 521.059, 0], ix: 2 },
            a: { a: 0, k: [72.148, 171.452, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [71.898, -171.202],
                        [-20.142, 171.202],
                        [-71.898, 152.464],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [70, -172], ix: 5 },
                  e: { a: 0, k: [-46, 161], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [72.148, 171.452], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Layer 7 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [225.615, 494.561, 0], ix: 2 },
            a: { a: 0, k: [123.634, 144.811, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [123.385, -144.561],
                        [-81.351, 144.561],
                        [-123.385, 109.09],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [123, -142], ix: 5 },
                  e: { a: 0, k: [-99, 124], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [123.634, 144.811], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Layer 8 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [189.496, 450.483, 0], ix: 2 },
            a: { a: 0, k: [159.754, 100.733, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [159.504, -100.483],
                        [-132.252, 100.483],
                        [-159.504, 52.708],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [159, -99], ix: 5 },
                  e: { a: 0, k: [-141, 75], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [159.754, 100.733], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Layer 9 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [174.5, 394.459, 0], ix: 2 },
            a: { a: 0, k: [174.75, 44.709, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-174.5, -10.072],
                        [-174.5, 8.88],
                        [-168.432, 44.459],
                        [174.5, -44.459],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [173, -43], ix: 5 },
                  e: { a: 0, k: [-171, 15], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [174.75, 44.709], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Layer 10 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [174.5, 305.307, 0], ix: 2 },
            a: { a: 0, k: [174.75, 44.942, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-174.5, -9.406],
                        [-174.5, 9.724],
                        [174.5, 44.693],
                        [-167.909, -44.693],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [174, 46], ix: 5 },
                  e: { a: 0, k: [-168, -19], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [174.75, 44.943], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Layer 11 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [190.015, 248.325, 0], ix: 2 },
            a: { a: 0, k: [159.235, 101.925, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [158.985, 101.675],
                        [-158.985, -54.543],
                        [-130.64, -101.675],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [158, 102], ix: 5 },
                  e: { a: 0, k: [-139, -76], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [159.235, 101.926], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Layer 12 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [226.37, 204.885, 0], ix: 2 },
            a: { a: 0, k: [122.88, 145.365, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [122.63, 145.115],
                        [-122.63, -110.535],
                        [-79.859, -145.115],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [121, 144], ix: 5 },
                  e: { a: 0, k: [-97, -126], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [122.88, 145.365], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Layer 13 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [278.214, 178.807, 0], ix: 2 },
            a: { a: 0, k: [71.036, 171.443, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [70.786, 171.193],
                        [-70.786, -153.563],
                        [-18.688, -171.193],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [70, 171], ix: 5 },
                  e: { a: 0, k: [-40, -158], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [71.036, 171.443], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Layer 14 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [352.586, 175, 0], ix: 2 },
            a: { a: 0, k: [27.518, 175.25, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-27.268, -175],
                        [-3.586, 175],
                        [27.268, -175],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-4, 173], ix: 5 },
                  e: { a: 0, k: [2, -175], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [27.518, 175.25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Layer 15 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [423.951, 179.857, 0], ix: 2 },
            a: { a: 0, k: [75.201, 170.393, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-74.951, 170.143],
                        [23.608, -170.143],
                        [74.951, -150.423],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-77, 168], ix: 5 },
                  e: { a: 0, k: [49, -158], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [75.201, 170.393], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "Layer 16 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [474.812, 207.443, 0], ix: 2 },
            a: { a: 0, k: [126.062, 142.807, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-125.812, 142.556],
                        [84.472, -142.556],
                        [125.812, -106.28],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-126, 143], ix: 5 },
                  e: { a: 0, k: [103, -120], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [126.062, 142.807], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: "Layer 17 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [508.975, 250.423, 0], ix: 2 },
            a: { a: 0, k: [160.225, 99.827, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-159.975, 99.577],
                        [133.02, -99.577],
                        [159.975, -51.637],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-159, 96], ix: 5 },
                  e: { a: 0, k: [142, -73], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [160.225, 99.828], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "Layer 18 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [524.5, 305.762, 0], ix: 2 },
            a: { a: 0, k: [175.75, 44.487, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-175.5, 44.237],
                        [175.5, 10.111],
                        [175.5, 2.763],
                        [167.547, -44.237],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-173, 43], ix: 5 },
                  e: { a: 0, k: [167, -19], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [175.75, 44.488], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: "Layer 19 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [524.5, 393.566, 0], ix: 2 },
            a: { a: 0, k: [175.75, 43.817, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [167.489, 43.567],
                        [175.5, -0.942],
                        [175.5, -10.725],
                        [-175.5, -43.567],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-173, -43], ix: 5 },
                  e: { a: 0, k: [167, 15], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [175.75, 43.817], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: "Layer 20 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [509.417, 448.952, 0], ix: 2 },
            a: { a: 0, k: [160.667, 99.202, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-160.417, -98.952],
                        [160.417, 51.298],
                        [132.956, 98.952],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-160, -99], ix: 5 },
                  e: { a: 0, k: [142, 69], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [160.667, 99.202], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: "Layer 21 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [474.419, 492.733, 0], ix: 2 },
            a: { a: 0, k: [125.873, 143.126, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-125.623, -142.876],
                        [125.623, 107.3],
                        [83.62, 142.876],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-124, -142], ix: 5 },
                  e: { a: 0, k: [101, 119], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [125.873, 143.126], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: "Layer 22 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [424.83, 519.66, 0], ix: 2 },
            a: { a: 0, k: [76.284, 170.053, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-76.034, -169.803],
                        [76.034, 150.49],
                        [24.489, 169.803],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-75, -168], ix: 5 },
                  e: { a: 0, k: [53, 152], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [76.284, 170.053], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: "Layer 23 Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [351.276, 524.928, 0], ix: 2 },
            a: { a: 0, k: [27.527, 175.322, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-27.277, 175.072],
                        [27.277, 175.072],
                        [-2.479, -175.072],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  g: {
                    p: 5,
                    k: {
                      a: 0,
                      k: [
                        0, 1, 0.549, 0.408, 0.404, 0.998, 0.616, 0.497, 0.563,
                        0.996, 0.684, 0.586, 0.781, 0.998, 0.842, 0.793, 1, 1,
                        1, 1, 0, 1, 0.176, 1, 0.352, 1, 0.676, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-2, -174], ix: 5 },
                  e: { a: 0, k: [0, 173], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [27.527, 175.322], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 70,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "logo fiverr",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [256, 256, 0], ix: 2 },
        a: { a: 0, k: [350, 350, 0], ix: 1 },
        s: { a: 0, k: [72.224, 72.224, 100], ix: 6 },
      },
      ao: 0,
      w: 700,
      h: 700,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
