import React from "react";
import { Tooltip } from "devextreme-react/tooltip";
import Guid from "devextreme/core/guid";

export class elemTransCellComponent extends React.PureComponent {
  render() {
    let data = this.props.data.data;
    let prenda = data.tblPrenda;

    let id = new Guid();
    let color = null;
    let tooltipText = "";
    let icon = "";

    if (prenda) {
      color = prenda.tblColorTapa
        ? prenda.tblColorTapa.codigoHexadecimal
        : null;

      let tblElemTrans = prenda.tblElemTransPedido
        ? prenda.tblElemTransPedido
        : prenda.tblElemTransOffice
          ? prenda.tblElemTransOffice
          : prenda.tblElemTransProduccion
            ? prenda.tblElemTransProduccion
            : prenda.tblElemTransReparto
              ? prenda.tblElemTransReparto
              : null;

      let udsTrans = prenda.udsXBacPedido
        ? prenda.udsXBacPedido
        : prenda.udsXBacOffice
          ? prenda.udsXBacOffice
          : prenda.udsXBacProduccion
            ? prenda.udsXBacProduccion
            : prenda.udsXBacReparto
              ? prenda.udsXBacReparto
              : 0;

      tooltipText =
        tblElemTrans.codigo == 1 ? "POR UDS" : "BAC/SACA" + ": " + udsTrans;
      icon = tblElemTrans.codigo == 1 ? "icon_Camisa" : "icon_Bac";
    } else {
      color = data.colorTapa ? data.colorTapa : null;

      let udsTrans = data.udsXBacPedido
        ? data.udsXBacPedido
        : data.udsXBacOffice
          ? data.udsXBacOffice
          : data.udsXBacProduccion
            ? data.udsXBacProduccion
            : data.udsXBacReparto
              ? data.udsXBacReparto
              : 0;

      tooltipText =
        data.codigoElemTrans == 1 ? "POR UDS" : "BAC/SACA" + ": " + udsTrans;
      icon = data.codigoElemTrans == 1 ? "icon_Camisa" : "icon_Bac";
    }

    return (
      <div
        id={id}
        className={
          "container_spanCentrado elemTransCellComponent " +
          (color === "#FFFFFF" ? "white" : "")
        }
        style={{ color: color }}
      >
        {color != null ? (
          <div className={"content font-size-xl " + icon}></div>
        ) : (
          "N/A"
        )}

        <Tooltip
          target={"#" + id}
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div className="font-weight-bold">{tooltipText}</div>
        </Tooltip>
      </div>
    );
  }
}
