import React from "react";

import {
  authHeader,
  errorHandler,
  formatDate_parameter,
  formatNumber,
  getTrad,
} from "../../../../../helpers";

import { Button, LoadPanel, Popup } from "devextreme-react";
import { Box, Item as ItemBox } from "devextreme-react/box";

import DateRangePicker from "../../../../../components/DateRangePicker/DateRangePicker";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import { connectionConstants } from "../../../../../constants";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import GraficaContador from "../GraficaContador";

const hoy = new Date();
const startDate = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
const endDate = hoy;

export default class PopupGrafica extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      maxDate: new Date(),
      lecturaContador: null,
      unidadMedidaRecursoContaSel: null,
      fechaActualizacion: new Date(),
      loadPanelVisible: false,
      fechaSel: {
        key: "selection",
        idTipoCalendario: 2,
        fechaSel: hoy,
        startDate: startDate,
        endDate: endDate,
      },
    };
  }

  datasource_lecturaContador = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "lecturaContador_spSelectLecturaContador",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_lecturaContador_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_lecturaContador_beforeSend = (request) => {
    const { recursoContaSel } = this.props;
    const { fechaSel } = this.state;

    request.headers = { ...authHeader() };
    request.params.idRecursoContador = recursoContaSel?.idRecursoContador ?? -1;
    request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
    request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
  };

  datasource_unidadMedida = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecursoContador",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_lecturaContador_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idRecursoContador"],
    expand: ["tblUnidadMedida_Contador"],
  });

  datasource_unidadMedida_beforeSend = (request) => {
    const { recursoContaSel } = this.props;

    request.headers = { ...authHeader() };
    request.params.idRecursoContador = recursoContaSel?.idRecursoContador ?? -1;
  };

  render() {
    const { visible, recursoContaSel } = this.props;
    const {
      fechaSel,
      maxDate,
      lecturaContador,
      unidadMedidaRecursoContaSel,
      fechaActualizacion,
      loadPanelVisible,
    } = this.state;

    const ultActualizacion =
      fechaActualizacion.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }) +
      " - " +
      fechaActualizacion.toLocaleTimeString("es-ES", {
        hour: "2-digit",
        minute: "2-digit",
      });

    const solo_1_registro =
      lecturaContador &&
      lecturaContador.length === 1 &&
      lecturaContador[0].consumo === null;
    const sinRegistros = lecturaContador && lecturaContador.length <= 0;
    const IBox_visible_grafica =
      !solo_1_registro && lecturaContador && lecturaContador.length > 0;
    const IBox_visible_mensaje = solo_1_registro || sinRegistros;

    return (
      <Popup
        title={recursoContaSel?.denominacion}
        visible={visible}
        onShowing={this.onShowing_dxPopup_grafica}
        onHiding={this.onHiding_dxPopup_grafica}
      >
        <Box direction="col" height="100%" width="100%">
          <ItemBox ratio={0} baseSize={50}>
            <ResponsiveBox>
              <Row ratio={1} screen="sm md lg xl" />
              <Col ratio={0} baseSize={260} screen="md lg xl" />

              <Col ratio={1} screen="lg xl" />
              <Col ratio={3} screen="lg xl" />

              <Col ratio={0} screen="md" />
              <Col ratio={1} screen="md" />

              <Col ratio={1} screen="xs sm" />
              <Col ratio={1} screen="xs sm" />
              <Row ratio={1} screen="xs sm" />
              <Row ratio={1} screen="xs sm" />

              <Item>
                <Location row={0} col={0}></Location>
                <div className="d-flex align-items-center he-100">
                  <div className="d-flex align-items-center he-100 mr-3">
                    {visible && (
                      <DateRangePicker
                        month
                        year
                        day
                        defaultType="personalizado"
                        fechaSel={fechaSel}
                        maxDate={maxDate}
                        onDateSelected={this.dateRangePicker_onDateSelected}
                      />
                    )}
                  </div>
                  <Button icon="refresh" onClick={this.onClick_refresh} />
                </div>
              </Item>
            </ResponsiveBox>
          </ItemBox>
          <ItemBox baseSize={20} />
          <ItemBox visible={IBox_visible_grafica} ratio={0} baseSize={70}>
            <div
              className={"d-flex he-100"}
              style={{
                borderTop: "1px solid #D3D3D3",
                paddingLeft: 20,
                alignItems: "center",
              }}
            >
              <div className="flex flex-column font-size-xxs justify-content-center pr-4">
                <div className="d-flex flex-row">
                  <div>Consumo total</div>
                </div>
                <div
                  style={{ fontWeight: 600, paddingBottom: 10 }}
                  className="font-size-xs"
                >
                  {this.calculateTotal_consumo()} {unidadMedidaRecursoContaSel}
                </div>
              </div>
            </div>
          </ItemBox>
          <ItemBox visible={IBox_visible_grafica} ratio={1}>
            <div id="GraficaContador">
              <GraficaContador
                lecturaContador={lecturaContador}
                fechaSel={fechaSel}
                unidadMedidaRecursoContaSel={unidadMedidaRecursoContaSel}
              />
            </div>
            <LoadPanel
              position={{ of: "#GraficaContador" }}
              visible={loadPanelVisible}
            />
          </ItemBox>
          <ItemBox visible={IBox_visible_mensaje} ratio={1}>
            {recursoContaSel?.ultimoRegistroEnergyHub ? (
              sinRegistros ? (
                <div
                  className="w-100 h-100 font-size-lg d-flex"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 60,
                  }}
                >
                  {getTrad("sinRegistrosRango_lecturaContador")}
                </div>
              ) : (
                <div
                  className="w-100 h-100 font-size-lg d-flex"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 60,
                  }}
                >
                  {getTrad("1Registro_lecturaContador")}
                </div>
              )
            ) : (
              <div
                className="w-100 h-100 font-size-lg d-flex"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 60,
                }}
              >
                {getTrad("sinRegistros_lecturaContador")}
              </div>
            )}
          </ItemBox>
          <ItemBox baseSize={30}>
            <div
              className="d-flex"
              style={{ alignItems: "flex-end", height: "100%" }}
            >
              <div className="text-secondary">
                {getTrad("ultimaActualizacion")} {ultActualizacion}
              </div>
            </div>
          </ItemBox>
        </Box>
      </Popup>
    );
  }

  onClick_refresh = () => {
    this.setState({ loadPanelVisible: true }, () => {
      this.datasource_unidadMedida
        .reload()
        .done((data) =>
          this.setState({
            unidadMedidaRecursoContaSel:
              data[0]?.tblUnidadMedida_Contador.denominacion,
          }),
        );
      this.datasource_lecturaContador
        .reload()
        .done((lecturaContador) =>
          this.setState({
            lecturaContador,
            fechaActualizacion: new Date(),
            loadPanelVisible: false,
          }),
        );
    });
  };

  calculateTotal_consumo = () => {
    const { lecturaContador } = this.state;
    let total = 0;

    lecturaContador &&
      lecturaContador.forEach((x) => {
        total += x.consumo;
      });

    return formatNumber(total ?? 0, 2);
  };

  dateRangePicker_onDateSelected = (fecha) => {
    if (fecha) {
      this.setState({ fechaSel: fecha }, () =>
        this.datasource_lecturaContador.reload().done((lecturaContador) => {
          this.setState({ lecturaContador, fechaActualizacion: new Date() });
        }),
      );
    }
  };

  onShowing_dxPopup_grafica = () => {
    this.datasource_unidadMedida
      .reload()
      .done((data) =>
        this.setState({
          unidadMedidaRecursoContaSel:
            data[0]?.tblUnidadMedida_Contador.denominacion,
        }),
      );
    this.datasource_lecturaContador
      .reload()
      .done((lecturaContador) =>
        this.setState({ lecturaContador, fechaActualizacion: new Date() }),
      );
  };

  onHiding_dxPopup_grafica = () => {
    const { onHiding } = this.props;
    onHiding();
    this.setState({ lecturaContador: null });
  };

  componentDidMount() {
    this.datasource_unidadMedida
      .load()
      .done((data) =>
        this.setState({
          unidadMedidaRecursoContaSel:
            data[0]?.tblUnidadMedida_Contador?.denominacion,
        }),
      );
    this.datasource_lecturaContador
      .load()
      .done((lecturaContador) =>
        this.setState({ lecturaContador, fechaActualizacion: new Date() }),
      );
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;

    if (prevProps.visible === false && visible === true) {
      const fechaSel = {
        key: "selection",
        idTipoCalendario: 2,
        fechaSel: hoy,
        startDate: startDate,
        endDate: endDate,
      };
      this.setState({ fechaSel });
    }
  }
}
