import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
    getNombreFormulario,
    errorHandler,
    authHeader,
    getTrad,
    formatDate,
    formatDate_parameter,
    convertClienteUtcToLavanderiaUtc,
    convertClienteUtcToSinUtc,
    formatNumber,
    filtroActivo,
    pattern_denominacion,
    phone_Pattern,
    email_Pattern,
    dxMensajePregunta,
    datetimeToDuration,
    durationToDatetime,
    dateTime_hourMinute,
} from "../../../helpers";

import $, { each } from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import ScrollView from "devextreme-react/scroll-view";
import PageTitle from "../../../layout/AppMain/PageTitle";
import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";
import ArrayStore from "devextreme/data/array_store";
import query from "devextreme/data/query";
import { Button, DropDownBox, NumberBox, DateBox, SelectBox } from "devextreme-react";
import Popover from "devextreme-react/popover";
import RadioGroup from "devextreme-react/radio-group";
import { Switch } from "devextreme-react/switch";
import TreeView from "devextreme-react/tree-view";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { TagBox } from "devextreme-react/tag-box";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import { Popup, ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";
import List from "devextreme-react/list";
import { Form, Item, RequiredRule, TabbedItem, TabPanelOptions, Tab, EmptyItem } from "devextreme-react/form";
import Box, { Item as ItemBox } from "devextreme-react/box";
import ODataContext from "devextreme/data/odata/context";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    KeyboardNavigation,
    Pager,
    Paging,
    Editing,
    Lookup,
    ColumnChooser,
    Sorting,
    ColumnFixing,
    Grouping,
    GroupPanel,
    LoadPanel as Grid_LoadPanel,
    Scrolling,
    EmailRule,
    CustomRule,
    Selection,
    Export,
} from "devextreme-react/data-grid";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import ResponsiveBox, { Row, Col, Item as ResponsiveBoxItem, Location } from "devextreme-react/responsive-box";
import { LoadPanel } from "devextreme-react/load-panel";
import { Tooltip } from "devextreme-react/tooltip";
import Guid from "devextreme/core/guid";
import { Validator } from "devextreme-react/validator";

import config from "devextreme/core/config";

//CUSTOM COMPONENTS
import { editCellComponent, elemTransCellComponent, removeCellComponent } from "../../../components/DataGrid/Cells";

import YearCalendar from "../../../components/YearCalendar/YearCalendar";
import AdjuntarArchivos from "../../../components/AdjuntarArchivos/AdjuntarArchivos";
import ButtonGroupCustom from "../../../components/ButtonGroupCustom";

//DEVEXTREME JQUERY
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
//Css
import "./Css.scss";

import LocationMapMarker from "../../../components/LocationMapMarker";
import LottieIcon from "components/LottieIcon";
import PopupHistoricoTipoFacturacionEntidad from "./components/PopupHistoricoTipoFacturacionEntidad";
import PopupHistoricoPesoPrenda from "./components/PopupHistoricoPesoPrenda";
import PopupHistoricoPrecioLavadoPrenda from "./components/PopupHistoricoPrecioLavadoPrenda";
import PopupHistoricoTipoFacturacionPrenda from "./components/PopupHistoricoTipoFacturacionPrenda";

const initialState = {
    compañiaSel: null,
    entidadSel: null,
    entidadSel_popup: null,
    prendaSel: null,
    almacenSel: null,
    familiaSel: null,
    tipoPrendaSel: null,
    seccionNivel1_Sel: null,
    seccionNivel2_Sel: null,
    dxList_tipoHabitacionSel: null,
    yearCalendar_currentYear: new Date().getFullYear(),

    datasource_datos_compaEnti: [],
    datasource_datos_tblTipoFacturacion: [],
    datasource_datos_tblColorTapa: [],
    datasource_datos_tblMarcaTapa: [],
    datasource_tblPrendaNEntidad_compañia: [],
    datasource_tblPrendaNEntidad: [],
    datasource_dxDataGrid_tblPrendaNTipoHabitacion: [],
    datasource_dxDataGrid_tblEntidadesAsociadas: [],
    datasource_dxList_entidadesAsociada: [],
    datasource_dxList_denoPrenda: [],
    datasource_dxList_tipoPrenda: [],
    datasource_datos_tblTipoHabitacion_items: null,
    datasource_datos_tblPrendaNAlmacen_compañia: null,
    datasource_datos_tblPrendaNAlmacen_entidad: null,
    datasource_dxDataGrid_tblPrenda: [],
    datasource_importarEntidad_entidad: null,
    datasource_importarEntidad_compañia: null,
    datasource_tblSeccionNivel2: [],
    datasource_datos_secciones: [],
    datasource_datos_tblCalendarioEntidad_Estados: [],

    isVisible_dxPopup_configEntidad: false,
    isVisible_dxPopup_addTipoHabitacion: false,
    isVisible_dxPopup_tblPrendaNTipoHabitacion: false,
    isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia: false,
    isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad: false,
    isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario: false,
    isVisible_dxPopup_tblEntidadNEntidadAsociada: false,
    isVisible_dxPopup_compañia: false,
    isVisible_dxPopup_prendas: false,
    isVisible_dxPopup_precioLavadoPrenda: false,
    isVisible_dxPopup_historicoPesoPrenda: false,
    isVisible_dxPopup_historicoIdTipoFacturacionPrenda: false,
    isVisible_dxPopup_seleccionTipoPrenda: false,
    isVisible_dxPopup_addSeccionNivel1: false,
    isVisible_dxPopup_addSeccionNivel2: false,
    isVisible_dxPopup_configAlmacenes: false,
    isVisible_dxPopup_modificarPrecios: false,
    isVisible_dxPopup_tblPrendaNAlmacen: false,
    isVisible_dxPopup_importarEntidad: false,
    isVisible_dxDropDownBox_idCompañiaAsociada: false,
    isVisible_warning_general: false,
    isVisible_warning_entidadesAsociadas: false,
    isVisible_delete_prendas: true,
    isVisible_warning_pedidos: false,
    isVisible_loading_dxBox_secciones: false,
    isVisible_loading_dxPopup_configEntidad: false,
    isVisible_loading_dxPopup_configEntidad_pedidos: false,
    isCalendar_valid: true,

    selectedItems_tblPrendaNEntidad_NuevoPedido_compañia_datasource: [],
    selectedItems_tblPrendaNEntidad_NuevoPedido_entidad_datasource: [],
    selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource: [],
    selectedItems_dxList_tblPrendaNAlmacen_compañia: [],
    selectedItems_dxList_tblPrendaNAlmacen_entidad: [],
    selectedItems_dxList_importarEntidad_lavanderia: [],
    selectedItems_dxList_importarEntidad_compañia: [],
    selectedItems_dxList_importarEntidad_entidad: [],
    selectedItems_dxList_entidadesAsociada: [],
    selectedItems_dxList_prendasEntidad: [],
    selectedItems_dxList_prendasCompañia: [],
    selectedItemKey_dxList_addCompa: [],
    selectedIndex_dxTabPanel_almacenPrenda: 0,
    selectedIndex_dxForm_entidad: 0,
    dxList_tblTipoHabitacion_selectedItemKeys: [],

    isCellEditing_dxDataGrid_tblPrenda: false,
    isDisabled_dxForm_entidad_tblEntidadNEntidadAsociada: true,
    isSeccion2_editing: false,
    dxTabPanel_prendasAlm_selctedIndex: 0,

    items_datos_tblTipoPedido: [],
    arrayCompañias_dxDropDownBox_compañia: [],
    value_dxTagBox_tipoRep: [],
    isDisabled_dxButton_addPrendaTipoHabitacion: true,
    accionesModificarDatos_fileUploader: [],
    showLoading_adjuntarArchivos: null,

    isValid_dxDataGrid_tblPrendaNEntidad_NuevoPedido: true,
    bk_tblPrendaNEntidad_NuevoPedido_compañia: null,
    bk_tblPrendaNEntidad_NuevoPedido_entidad: null,
    usuarioSel_prendasNUsuario_NuevoPedido: null,
    dxList_prendaNCompañia_usuario_dataSource: [],
    dxList_prendaNEntidad_usuario_dataSource: [],
    dxTabPanel_Entidad_tabIndex: null,
    isLoaded_popupEntidad: false,

    isVisible_dxPopup_horarioReparto: false,
    horariosGuardados: [],

    isVisible_dxPopup_sacasPendientes: false,
    sacasPendientesGuardadas: [],

    isVisible_dxPopup_mapa: false,
    address_components: undefined,
    addressNoMod: undefined,
    address: undefined,
    coordinates: undefined,

    isVisible_PopupHistoricoTipoFacturacion: false,

    cellHover_cerrado: null
};

const dxBox_secciones = { of: "#dxBox_secciones" };
const dxPopup_configEntidad = {
    of: "#dxPopup_configEntidad .dx-popup-content",
};
const dxPopup_configEntidad_dxMultiView_pedidos_estructuraOrg = {
    of: "#dxMultiView_pedidos_estructuraOrg",
};
class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = initialState;

        // REFERENCIAS
        this.dxTreeView_compaEnti_REF = React.createRef();
        this.dxDataGrid_tblPrenda_REF = React.createRef();
        this.DropDownBox_tapa_REF = React.createRef();
        this.dxDropdownButton_colores_REF = React.createRef();
        this.dxList_marcas_REF = React.createRef();
        this.dxTagBox_tblTipoPedidoNEntidad_REF = React.createRef();
        this.dxForm_entidad_REF = React.createRef();
        this.tabPanel_dxForm_entidad_REF = React.createRef();
        this.dxScrollView_dxForm_dxTab_REF = React.createRef();
        this.dxPopup_addTipoHabitacion_REF = React.createRef();
        this.dxForm_addTipoHabitacion_REF = React.createRef();
        this.dxList_prendasCompañia_REF = React.createRef();
        this.dxList_prendasEntidad_REF = React.createRef();
        this.dxDataGrid_tblPrendaNTipoHabitacion_REF = React.createRef();
        this.dxList_tblTipoHabitacion_REF = React.createRef();
        this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_REF = React.createRef();
        this.dxList_tblPrendaNEntidad_NuevoPedido_entidad_REF = React.createRef();
        this.dxList_tblPrendaNEntidad_NuevoPedido_compañia_REF = React.createRef();
        this.dxList_tblPrenda_entidad_REF = React.createRef();
        this.dxList_tblPrenda_compañia_REF = React.createRef();
        this.dxList_addCompañia_REF = React.createRef();
        this.dxForm_compañia_REF = React.createRef();
        this.dxForm_addSeccionNivel1_REF = React.createRef();
        this.dxForm_addSeccionNivel2_REF = React.createRef();
        this.dxList_seccionNivel1_REF = React.createRef();
        this.dxList_seccionNivel2_REF = React.createRef();
        this.dxTabPanel_almacenes_REF = React.createRef();
        this.dxDataGrid_gestionCorreosAlbaranes_REF = React.createRef();
        this.dxForm_prenda_REF = React.createRef();
        this.dxList_entidadesAsociada_REF = React.createRef();

        this.dxButton_tblPrecioLavadoPrenda_REF = React.createRef();
        this.dxDataGrid_tblPrenda_precios_REF = React.createRef();
        this.dxDateBox_preciosPrenda_REF = React.createRef();
        this.dxNumberBox_precio_preciosPrenda_REF = React.createRef();

        this.dxButton_prenda_historico_peso_REF = React.createRef();
        
        this.dxButton_prenda_historico_idTipoFacturacion_REF = React.createRef();

        this.dxForm_almacen_REF = React.createRef();
        this.dxDataGrid_tblPrendaNAlmacen_REF = React.createRef();
        this.dxList_importarEntidad_entidad_REF = React.createRef();
        this.dxList_importarEntidad_lavanderia_REF = React.createRef();
        this.dxList_importarEntidad_compañia_REF = React.createRef();
        this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia_REF = React.createRef();
        this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad_REF = React.createRef();
        this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario_REF = React.createRef();
        this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_REF = React.createRef();
        this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_entidad_REF = React.createRef();
        this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_compañia_REF = React.createRef();
        this.dxDataGrid_tblHorarioRepartoNEntidad_REF = React.createRef();
        this.dxButton_tblhorarioRepartoNEntidad_REF = React.createRef();
        this.dxDataGrid_tblSacasPendientes_REF = React.createRef();

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);
        this.loadPanel_show();

        //#region BIND EVENTOS
        //#region TREEVIEW
        this.datasource_datos_compaEnti_compañia_beforeSend =
            this.datasource_datos_compaEnti_compañia_beforeSend.bind(this);
        this.datasource_datos_entidadSel_onLoading = this.datasource_datos_entidadSel_onLoading.bind(this);
        this.datasource_datos_entidadSel_onLoaded = this.datasource_datos_entidadSel_onLoaded.bind(this);
        this.datasource_datos_entidadSel_beforeSend = this.datasource_datos_entidadSel_beforeSend.bind(this);
        this.datasource_datos_compaEnti_load = this.datasource_datos_compaEnti_load.bind(this);
        this.datasource_datos_compaEnti_onLoading = this.datasource_datos_compaEnti_onLoading.bind(this);
        this.dxTreeView_compaEnti_onContentReady = this.dxTreeView_compaEnti_onContentReady.bind(this);
        this.dxTreeView_compaEnti_itemRender = this.dxTreeView_compaEnti_itemRender.bind(this);
        this.dxTreeView_compaEnti_onItemClick = this.dxTreeView_compaEnti_onItemClick.bind(this);
        this.onClick_AddEntidad = this.onClick_AddEntidad.bind(this);
        this.compañiaSel_removeButton_onClick = this.compañiaSel_removeButton_onClick.bind(this);
        this.compañiaSel_editButton_onClick = this.compañiaSel_editButton_onClick.bind(this);
        //#endregion

        //#region IMPORTAR ENTIDAD
        this.dxPopup_importarEntidad_onHiding = this.dxPopup_importarEntidad_onHiding.bind(this);
        this.dxPopup_importarEntidad_onShowing = this.dxPopup_importarEntidad_onShowing.bind(this);
        this.render_dxButton_importar_dxPopup_importarEntidaddes =
            this.render_dxButton_importar_dxPopup_importarEntidaddes.bind(this);
        this.onClick_dxButton_importar_dxPopup_importarEntidaddes =
            this.onClick_dxButton_importar_dxPopup_importarEntidaddes.bind(this);
        this.importarEntidad_onClick = this.importarEntidad_onClick.bind(this);
        this.datasource_datos_importarEntidad_beforeSend = this.datasource_datos_importarEntidad_beforeSend.bind(this);
        this.datasource_datos_importarEntidad_onLoading = this.datasource_datos_importarEntidad_onLoading.bind(this);
        this.datasource_datos_importarEntidad_postProcess =
            this.datasource_datos_importarEntidad_postProcess.bind(this);
        this.datasource_datos_tblEntidadNLavanderia_beforeSend =
            this.datasource_datos_tblEntidadNLavanderia_beforeSend.bind(this);
        this.dxList_importarEntidad_lavanderia_onOptionChanged =
            this.dxList_importarEntidad_lavanderia_onOptionChanged.bind(this);
        this.dxList_importarEntidad_compañia_onOptionChanged =
            this.dxList_importarEntidad_compañia_onOptionChanged.bind(this);
        this.dxList_importarEntidad_entidad_onOptionChanged =
            this.dxList_importarEntidad_entidad_onOptionChanged.bind(this);
        this.dxList_importarEntidad_lavanderia_changeSelItem =
            this.dxList_importarEntidad_lavanderia_changeSelItem.bind(this);
        //#endregion

        //#region DATAGRID PRENDAS
        this.datasource_tblPrenda_beforeSend = this.datasource_tblPrenda_beforeSend.bind(this);
        this.dxTabPanel_prendas_onSelectionChanged = this.dxTabPanel_prendas_onSelectionChanged.bind(this);
        //#endregion

        //#region DATAGRID PRENDA
        this.onEditorPreparing_tblPrenda = this.onEditorPreparing_tblPrenda.bind(this);
        this.onRowPrepared_tblPrenda = this.onRowPrepared_tblPrenda.bind(this);
        this.onRowUpdating_tblPrenda = this.onRowUpdating_tblPrenda.bind(this);
        this.onToolbarPreparing_tblPrenda = this.onToolbarPreparing_tblPrenda.bind(this);
        this.tblPrenda_addNuevaPrenda_onClick = this.tblPrenda_addNuevaPrenda_onClick.bind(this);
        this.dxDataGrid_limpiarFiltros_onClick = this.dxDataGrid_limpiarFiltros_onClick.bind(this);
        this.dxDataGrid_actualizar_onClick = this.dxDataGrid_actualizar_onClick.bind(this);
        this.onRowRemoving_tblPrenda = this.onRowRemoving_tblPrenda.bind(this);
        this.onRowRemoved_tblPrenda = this.onRowRemoved_tblPrenda.bind(this);
        this.editCellRender_tblPrenda_tblPrecioLavadoPrenda =
            this.editCellRender_tblPrenda_tblPrecioLavadoPrenda.bind(this);
        this.editCellRender_tblPrenda_tblPrenda_historico_peso =
            this.editCellRender_tblPrenda_tblPrenda_historico_peso.bind(this);
        this.editCellRender_tblPrenda_tblPrenda_historico_idTipoFacturacion =
            this.editCellRender_tblPrenda_tblPrenda_historico_idTipoFacturacion.bind(this);
        this.editCellRender_tblPrenda_tapa = this.editCellRender_tblPrenda_tapa.bind(this);
        this.onContentReady_tblPrenda_tapa = this.onContentReady_tblPrenda_tapa.bind(this);
        this.cellRender_tblPrenda_udsXBacReparto = this.cellRender_tblPrenda_udsXBacReparto.bind(this);
        this.cellRender_tblPrenda_udsXBacOffice = this.cellRender_tblPrenda_udsXBacOffice.bind(this);
        this.cellRender_tblPrenda_udsXBacPedido = this.cellRender_tblPrenda_udsXBacPedido.bind(this);
        this.cellRender_tblPrenda_udsXBacProduccion = this.cellRender_tblPrenda_udsXBacProduccion.bind(this);
        this.cellRender_tblPrenda_edit = this.cellRender_tblPrenda_edit.bind(this);
        this.tblPrenda_edit_onClick = this.tblPrenda_edit_onClick.bind(this);
        this.cellRender_tblElemTransPedido_codigo = this.cellRender_tblElemTransPedido_codigo.bind(this);
        this.datasource_tblPrenda_onLoaded = this.datasource_tblPrenda_onLoaded.bind(this);
        this.datasource_tblPrenda_onLoading = this.datasource_tblPrenda_onLoading.bind(this);
        this.editCellRender_tblPrenda_tblPrecioLavadoPrenda_onClick =
            this.editCellRender_tblPrenda_tblPrecioLavadoPrenda_onClick.bind(this);

        this.editCellRender_tblPrenda_tblPrenda_historico_peso_onClick =
            this.editCellRender_tblPrenda_tblPrenda_historico_peso_onClick.bind(this);

        this.editCellRender_tblPrenda_tblPrenda_historico_idTipoFacturacion_onClick =
            this.editCellRender_tblPrenda_tblPrenda_historico_idTipoFacturacion_onClick.bind(this);
        this.dropDownContentRender_tblPrenda_tapa_onClick =
            this.dropDownContentRender_tblPrenda_tapa_onClick.bind(this);
        //#endregion

        //#region MODIFICAR PRECIOS
        this.dxPopup_modificarPrecios_onClick = this.dxPopup_modificarPrecios_onClick.bind(this);
        //#endregion

        //#region DROPDOWNBOX TAPA PRENDA
        this.onOpened_tblPrenda_tapa = this.onOpened_tblPrenda_tapa.bind(this);
        this.onClick_dxButton_dropDownTapa = this.onClick_dxButton_dropDownTapa.bind(this);
        this.contentRender_dxDropDownBox_tapa = this.contentRender_dxDropDownBox_tapa.bind(this);
        this.fieldTemplate_dxDropDownBox_tapa = this.fieldTemplate_dxDropDownBox_tapa.bind(this);
        this.dropDownContentRender_tblPrenda_tapa = this.dropDownContentRender_tblPrenda_tapa.bind(this);
        //#endregion

        //#region TABPANEL ALMACENES
        this.dxTabPanel_almacenes_onSelectionChanged = this.dxTabPanel_almacenes_onSelectionChanged.bind(this);
        this.onClick_dxButton_edificio = this.onClick_dxButton_edificio.bind(this);
        this.onClick_dxButton_seccion2 = this.onClick_dxButton_seccion2.bind(this);
        this.onClick_guardar_dxPopup_nuevoEdificio = this.onClick_guardar_dxPopup_nuevoEdificio.bind(this);
        this.onClick_cancelar_dxPopup_nuevoEdificio = this.onClick_cancelar_dxPopup_nuevoEdificio.bind(this);
        //#endregion

        //#region EDIFICIOS_SECCIONES
        this.datasource_datos_secciones_beforeSend = this.datasource_datos_secciones_beforeSend.bind(this);
        this.datasource_datos_secciones_onLoading = this.datasource_datos_secciones_onLoading.bind(this);
        this.onSelectionChanged_tblSeccionNivel1 = this.onSelectionChanged_tblSeccionNivel1.bind(this);
        this.itemRender_tblSeccionNivel1 = this.itemRender_tblSeccionNivel1.bind(this);
        this.onItemClick_tblSeccionNivel2 = this.onItemClick_tblSeccionNivel2.bind(this);
        this.itemRender_tblSeccionNivel2 = this.itemRender_tblSeccionNivel2.bind(this);
        this.dxList_seccionNivel1_remove_onClick = this.dxList_seccionNivel1_remove_onClick.bind(this);
        this.dxList_seccionNivel1_edit_onClick = this.dxList_seccionNivel1_edit_onClick.bind(this);
        this.dxList_seccionNivel2_edit_onClick = this.dxList_seccionNivel2_edit_onClick.bind(this);
        this.dxList_seccionNivel2_remove_onClick = this.dxList_seccionNivel2_remove_onClick.bind(this);
        this.onClick_cancelar_dxPopup_nuevaSeccion = this.onClick_cancelar_dxPopup_nuevaSeccion.bind(this);
        this.onClick_guardar_dxPopup_nuevaSeccion = this.onClick_guardar_dxPopup_nuevaSeccion.bind(this);
        //#endregion

        //#region DATAGRID ALMACENES
        this.onRowPrepared_tblAlmacenes = this.onRowPrepared_tblAlmacenes.bind(this);
        this.onEditingStart_tblAlmacenes = this.onEditingStart_tblAlmacenes.bind(this);
        this.onToolbarPreparing_tblAlmacenes = this.onToolbarPreparing_tblAlmacenes.bind(this);
        this.editCellRender_activo_tblAlmacenes = this.editCellRender_activo_tblAlmacenes.bind(this);
        this.datasource_datos_tblAlmacen_beforeSend = this.datasource_datos_tblAlmacen_beforeSend.bind(this);
        this.datasource_datos_tblAlmacen_onLoaded = this.datasource_datos_tblAlmacen_onLoaded.bind(this);
        this.datasource_datos_tblAlmacen_delta_beforeSend =
            this.datasource_datos_tblAlmacen_delta_beforeSend.bind(this);
        this.onValueChanged_SwitchActivo_tblAlmacenes = this.onValueChanged_SwitchActivo_tblAlmacenes.bind(this);
        this.render_dxButton_cancelar_dxPopup_nuevoEdificio =
            this.render_dxButton_cancelar_dxPopup_nuevoEdificio.bind(this);
        this.render_dxButton_guardar_dxPopup_nuevoEdificio =
            this.render_dxButton_guardar_dxPopup_nuevoEdificio.bind(this);
        this.dxPopup_nuevoEdificio_onShown = this.dxPopup_nuevoEdificio_onShown.bind(this);
        this.dxPopup_nuevoEdificio_onHiding = this.dxPopup_nuevoEdificio_onHiding.bind(this);
        this.dxPopup_nuevoEdificio_onHidden = this.dxPopup_nuevoEdificio_onHidden.bind(this);
        this.render_dxButton_cancelar_dxPopup_nuevaSeccion =
            this.render_dxButton_cancelar_dxPopup_nuevaSeccion.bind(this);
        this.render_dxButton_guardar_dxPopup_nuevaSeccion =
            this.render_dxButton_guardar_dxPopup_nuevaSeccion.bind(this);
        this.render_dxButton_cancelar_dxPopup_prendasAlmacen =
            this.render_dxButton_cancelar_dxPopup_prendasAlmacen.bind(this);
        this.render_dxButton_guardar_dxPopup_prendasAlmacen =
            this.render_dxButton_guardar_dxPopup_prendasAlmacen.bind(this);
        this.dxPopup_nuevaSeccion_onShown = this.dxPopup_nuevaSeccion_onShown.bind(this);
        this.dxPopup_nuevaSeccion_onHiding = this.dxPopup_nuevaSeccion_onHiding.bind(this);
        this.dxPopup_nuevaSeccion_onHidden = this.dxPopup_nuevaSeccion_onHidden.bind(this);
        this.tblAlmacenes_back_onClick = this.tblAlmacenes_back_onClick.bind(this);
        this.tblAlmacenes_addAlmacen_onClick = this.tblAlmacenes_addAlmacen_onClick.bind(this);
        //#endregion

        //#region POPUP ENTIDADES
        this.datasource_datos_tblEntidad_beforeSend = this.datasource_datos_tblEntidad_beforeSend.bind(this);
        this.onShown_dxPopup_configEntidad = this.onShown_dxPopup_configEntidad.bind(this);
        this.yearCalendar_onDateChanged = this.yearCalendar_onDateChanged.bind(this);
        this.onHiding_dxPopup_configEntidad = this.onHiding_dxPopup_configEntidad.bind(this);
        this.onHidden_dxPopup_configEntidad = this.onHidden_dxPopup_configEntidad.bind(this);
        this.dxScrollView_dxForm_dxTab_onScroll = this.dxScrollView_dxForm_dxTab_onScroll.bind(this);
        this.onClick_PopupEntidad_guardar = this.onClick_PopupEntidad_guardar.bind(this);
        this.onClick_PopupEntidad_cancelar = this.onClick_PopupEntidad_cancelar.bind(this);
        this.dxTabPanel_Entidad_onOptionChanged = this.dxTabPanel_Entidad_onOptionChanged.bind(this);
        this.dxList_entidadesAsociada_onOptionChanged = this.dxList_entidadesAsociada_onOptionChanged.bind(this);
        this.dxList_prendasEntidad_onOptionChanged = this.dxList_prendasEntidad_onOptionChanged.bind(this);
        this.dxList_prendasCompañia_onOptionChanged = this.dxList_prendasCompañia_onOptionChanged.bind(this);
        this.dxList_tblTipoHabitacion_onClick_modify = this.dxList_tblTipoHabitacion_onClick_modify.bind(this);
        this.dxList_tblTipoHabitacion_onClick_delete = this.dxList_tblTipoHabitacion_onClick_delete.bind(this);
        this.onContentReady_dxDataGrid_gestionCorreosAlbaranes =
            this.onContentReady_dxDataGrid_gestionCorreosAlbaranes.bind(this);
        this.onRowPrepared_dxDataGrid_gestionCorreosAlbaranes =
            this.onRowPrepared_dxDataGrid_gestionCorreosAlbaranes.bind(this);
        this.onRowUpdating_dxDataGrid_gestionCorreosAlbaranes =
            this.onRowUpdating_dxDataGrid_gestionCorreosAlbaranes.bind(this);
        this.onRowInserting_dxDataGrid_gestionCorreosAlbaranes =
            this.onRowInserting_dxDataGrid_gestionCorreosAlbaranes.bind(this);
        this.onEditingStart_dxDataGrid_gestionCorreosAlbaranes =
            this.onEditingStart_dxDataGrid_gestionCorreosAlbaranes.bind(this);
        this.onToolbarPreparing_dxDataGrid_gestionCorreosAlbaranes =
            this.onToolbarPreparing_dxDataGrid_gestionCorreosAlbaranes.bind(this);
        this.itemTitleRender_dxTabPanel_Entidad = this.itemTitleRender_dxTabPanel_Entidad.bind(this);
        this.onSelectionChanged_dxTabPanel_Entidad = this.onSelectionChanged_dxTabPanel_Entidad.bind(this);
        this.validationCallback_CustomRule_emailCorreosAlb =
            this.validationCallback_CustomRule_emailCorreosAlb.bind(this);
        this.tipoInforme_gestionCorreos_render = this.tipoInforme_gestionCorreos_render.bind(this);
        this.tipoInforme_gestionCorreos_editCellRender = this.tipoInforme_gestionCorreos_editCellRender.bind(this);
        this.tipoInforme_gestionCorreos_delete_editCellRender =
            this.tipoInforme_gestionCorreos_delete_editCellRender.bind(this);
        this.tipoInforme_gestionCorreos_delete_onClick = this.tipoInforme_gestionCorreos_delete_onClick.bind(this);
        this.tblPrendaNEntidad_NuevoPedido_compañia_datasource_onOptionChanged =
            this.tblPrendaNEntidad_NuevoPedido_compañia_datasource_onOptionChanged.bind(this);
        this.tblPrendaNEntidad_NuevoPedido_entidad_datasource_onOptionChanged =
            this.tblPrendaNEntidad_NuevoPedido_entidad_datasource_onOptionChanged.bind(this);
        this.tblPrendaNEntidad_NuevoPedido_usuario_datasource_onOptionChanged =
            this.tblPrendaNEntidad_NuevoPedido_usuario_datasource_onOptionChanged.bind(this);
        this.contentRender_dxDropDownBox_idCompañia_popupEntidad =
            this.contentRender_dxDropDownBox_idCompañia_popupEntidad.bind(this);
        this.onValueChanged_dxDropDownBox_idCompañia_popupEntidad =
            this.onValueChanged_dxDropDownBox_idCompañia_popupEntidad.bind(this);
        this.onOptionChanged_dxDropDownBox_idCompañia_popupEntidad =
            this.onOptionChanged_dxDropDownBox_idCompañia_popupEntidad.bind(this);
        this.onSelectionChanged_dxDropDownBox_idCompañia_popupEntidad =
            this.onSelectionChanged_dxDropDownBox_idCompañia_popupEntidad.bind(this);
        this.onValueChanged_dxDropDownBox_denominacion_popupEntidad =
            this.onValueChanged_dxDropDownBox_denominacion_popupEntidad.bind(this);
        this.onClick_tblTipoPedidoNEntidad_TagBoxButtons = this.onClick_tblTipoPedidoNEntidad_TagBoxButtons.bind(this);
        this.itemRender_dxList_tblTipoHabitacion = this.itemRender_dxList_tblTipoHabitacion.bind(this);
        this.onClick_tipoHabitacion = this.onClick_tipoHabitacion.bind(this);
        this.onSelectionChanged_dxList_tblTipoHabitacion = this.onSelectionChanged_dxList_tblTipoHabitacion.bind(this);
        this.onToolbarPreparing_dxDataGrid_tblPrendaNTipoHabitacion =
            this.onToolbarPreparing_dxDataGrid_tblPrendaNTipoHabitacion.bind(this);
        this.onClick_dxDataGrid_tblPrendaNTipoHabitacion = this.onClick_dxDataGrid_tblPrendaNTipoHabitacion.bind(this);
        this.render_tblPrendaNEntidad_NuevoPedido = this.render_tblPrendaNEntidad_NuevoPedido.bind(this);
        this.onContentReady_tblPrendaNEntidad_NuevoPedido =
            this.onContentReady_tblPrendaNEntidad_NuevoPedido.bind(this);
        this.onToolbarPreparing_tblPrendaNEntidad_NuevoPedido =
            this.onToolbarPreparing_tblPrendaNEntidad_NuevoPedido.bind(this);
        this.onContentReady_tipoEntidad = this.onContentReady_tipoEntidad.bind(this);
        this.onValueChanged_tipoEntidad = this.onValueChanged_tipoEntidad.bind(this);
        this.onToolbarPreparing_entidadesAsociadas = this.onToolbarPreparing_entidadesAsociadas.bind(this);
        this.onClick_dxButtonAddEntidad_entidadesAsociadas =
            this.onClick_dxButtonAddEntidad_entidadesAsociadas.bind(this);
        this.onShown_addTipoHabitacion = this.onShown_addTipoHabitacion.bind(this);
        this.onHiding_addTipoHabitacion = this.onHiding_addTipoHabitacion.bind(this);
        this.onClick_cancelar_dxPopup_addTipoHabitacion = this.onClick_cancelar_dxPopup_addTipoHabitacion.bind(this);
        this.render_dxButton_guardar_addTipoHabitacion = this.render_dxButton_guardar_addTipoHabitacion.bind(this);
        this.render_dxButton_cancelar_addTipoHabitacion = this.render_dxButton_cancelar_addTipoHabitacion.bind(this);
        this.onClick_guardar_addTipoHabitacion = this.onClick_guardar_addTipoHabitacion.bind(this);
        this.tblPrendaNEntidad_datasource_beforeSend = this.tblPrendaNEntidad_datasource_beforeSend.bind(this);
        this.tblPrendaNEntidad_datasource_onLoading = this.tblPrendaNEntidad_datasource_onLoading.bind(this);
        this.tblPrendaNEntidad_compañia_datasource_beforeSend =
            this.tblPrendaNEntidad_compañia_datasource_beforeSend.bind(this);
        this.tblPrendaNEntidad_compañia_datasource_onLoading =
            this.tblPrendaNEntidad_compañia_datasource_onLoading.bind(this);
        this.onShowing_prendasTipoHabitacion = this.onShowing_prendasTipoHabitacion.bind(this);
        this.onHiding_prendasTipoHabitacion = this.onHiding_prendasTipoHabitacion.bind(this);
        this.itemRender_dxList_prendasCodigo = this.itemRender_dxList_prendasCodigo.bind(this);
        this.dxButton_guardar_tblPrendaNTipoHabitacion = this.dxButton_guardar_tblPrendaNTipoHabitacion.bind(this);
        this.onClick_guardar_tblPrendaNTipoHabitacion = this.onClick_guardar_tblPrendaNTipoHabitacion.bind(this);
        this.onShowing_tblPrendaNEntidad_NuevoPedido = this.onShowing_tblPrendaNEntidad_NuevoPedido.bind(this);
        this.onHiding_tblPrendaNEntidad_NuevoPedido = this.onHiding_tblPrendaNEntidad_NuevoPedido.bind(this);
        this.configList_popupPrendasPedido = this.configList_popupPrendasPedido.bind(this);
        this.dxButton_cancelar_tblPrendaNTipoHabitacion = this.dxButton_cancelar_tblPrendaNTipoHabitacion.bind(this);
        this.onClick_cancelar_tblPrendaNTipoHabitacion = this.onClick_cancelar_tblPrendaNTipoHabitacion.bind(this);
        this.onClick_guardar_tblPrendaNEntidad_NuevoPedido =
            this.onClick_guardar_tblPrendaNEntidad_NuevoPedido.bind(this);
        this.onClick_cancelar_tblPrendaNEntidad_NuevoPedido =
            this.onClick_cancelar_tblPrendaNEntidad_NuevoPedido.bind(this);
        this.onClick_dxDataGrid_tblPrendaNEntidad_NuevoPedido =
            this.onClick_dxDataGrid_tblPrendaNEntidad_NuevoPedido.bind(this);
        this.onShowing_tblEntidadNEntidadAsociada = this.onShowing_tblEntidadNEntidadAsociada.bind(this);
        this.onHiding_tblEntidadNEntidadAsociada = this.onHiding_tblEntidadNEntidadAsociada.bind(this);
        this.render_dxButton_guardar_tblEntidadNEntidadAsociada =
            this.render_dxButton_guardar_tblEntidadNEntidadAsociada.bind(this);
        this.render_dxButton_cancelar_tblEntidadNEntidadAsociada =
            this.render_dxButton_cancelar_tblEntidadNEntidadAsociada.bind(this);
        this.onClick_dxButton_guardar_tblEntidadNEntidadAsociada =
            this.onClick_dxButton_guardar_tblEntidadNEntidadAsociada.bind(this);
        this.onClick_dxButton_cancelar_tblEntidadNEntidadAsociada =
            this.onClick_dxButton_cancelar_tblEntidadNEntidadAsociada.bind(this);
        this.datasource_datos_tblTipoHabitacion_beforeSend =
            this.datasource_datos_tblTipoHabitacion_beforeSend.bind(this);
        this.onShowing_dxPopup_compañia = this.onShowing_dxPopup_compañia.bind(this);
        this.onShown_dxPopup_compañia = this.onShown_dxPopup_compañia.bind(this);
        this.onHiding_dxPopup_compañia = this.onHiding_dxPopup_compañia.bind(this);
        this.onHidden_dxPopup_compañia = this.onHidden_dxPopup_compañia.bind(this);
        this.render_dxButton_guardar_dxPopup_compañia = this.render_dxButton_guardar_dxPopup_compañia.bind(this);
        this.render_dxButton_cancelar_dxPopup_compañia = this.render_dxButton_cancelar_dxPopup_compañia.bind(this);
        this.onClick_guardar_dxPopup_compañia = this.onClick_guardar_dxPopup_compañia.bind(this);
        this.onClick_cancelar_dxPopup_compañia = this.onClick_cancelar_dxPopup_compañia.bind(this);
        this.onValueChanged_denominacion_popupCompañia = this.onValueChanged_denominacion_popupCompañia.bind(this);
        this.dxTagBox_tblTipoPedidoNEntidad_onValueChanged =
            this.dxTagBox_tblTipoPedidoNEntidad_onValueChanged.bind(this);
        this.dxTagBox_tipoRep_tagRender = this.dxTagBox_tipoRep_tagRender.bind(this);
        this.dxTagBox_tipoRep_onFocusIn = this.dxTagBox_tipoRep_onFocusIn.bind(this);
        this.dxTagBox_tipoRep_onValueChanged = this.dxTagBox_tipoRep_onValueChanged.bind(this);
        this.dxNumberBox_stockDefinido_onEditorPreparing = this.dxNumberBox_stockDefinido_onEditorPreparing.bind(this);
        this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowUpdating =
            this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowUpdating.bind(this);
        this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowRemoving =
            this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowRemoving.bind(this);
        this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario_onRowRemoving =
            this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario_onRowRemoving.bind(this);
        this.dxNumberBox_stockDefinido_onRowValidating = this.dxNumberBox_stockDefinido_onRowValidating.bind(this);
        this.validationCallback_CustomRule_stockDefinido = this.validationCallback_CustomRule_stockDefinido.bind(this);
        this.cellRender_isPedidoPorPeticion = this.cellRender_isPedidoPorPeticion.bind(this);
        this.cellRender_isPedidoPorPeticion_readOnly = this.cellRender_isPedidoPorPeticion_readOnly.bind(this);
        this.dxButtonCustomGroup_onValueChanged = this.dxButtonCustomGroup_onValueChanged.bind(this);
        this.dxMultiView_pedidos_onContentReady = this.dxMultiView_pedidos_onContentReady.bind(this);
        this.cellRender_tblPrendaNEntidad_NuevoPedido_remove =
            this.cellRender_tblPrendaNEntidad_NuevoPedido_remove.bind(this);
        this.onSelectionChanged_dxList_tblPrendaNUsuario = this.onSelectionChanged_dxList_tblPrendaNUsuario.bind(this);
        this.dxSwitch_filtroPrendas = this.dxSwitch_filtroPrendas.bind(this);
        this.groupCellRender_tblPrendaNUsuario_NuevoPedido =
            this.groupCellRender_tblPrendaNUsuario_NuevoPedido.bind(this);
        this.setCellValue_isPedidoPorPeticion = this.setCellValue_isPedidoPorPeticion.bind(this);
        this.dxPopup_entidad_tipoFact_onSelectionChanged = this.dxPopup_entidad_tipoFact_onSelectionChanged.bind(this);
        this.tblhorarioRepartoNEntidad_render = this.tblhorarioRepartoNEntidad_render.bind(this);
        this.dxButton_tblhorarioRepartoNEntidad_onClick = this.dxButton_tblhorarioRepartoNEntidad_onClick.bind(this);
        this.onHiding_dxPopup_horarioReparto = this.onHiding_dxPopup_horarioReparto.bind(this);
        this.validationCallback_tblHorarioRepartoNEntidad_fecha =
            this.validationCallback_tblHorarioRepartoNEntidad_fecha.bind(this);
        this.onRowRemoving_dxDataGrid_horarioReparto = this.onRowRemoving_dxDataGrid_horarioReparto.bind(this);
        this.onInitNewRow_dxDataGrid_horarioReparto = this.onInitNewRow_dxDataGrid_horarioReparto.bind(this);
        this.onToolbarPreparing_dxDataGrid_horarioReparto =
            this.onToolbarPreparing_dxDataGrid_horarioReparto.bind(this);
        this.onClick_PopupHorarioNEntidad_guardar = this.onClick_PopupHorarioNEntidad_guardar.bind(this);
        this.onRowInserting_dxDataGrid_horarioReparto = this.onRowInserting_dxDataGrid_horarioReparto.bind(this);
        this.validateStartTime = this.validateStartTime.bind(this);
        this.validateEndTime = this.validateEndTime.bind(this);

        //#endregion

        //#region POPUP PRENDA
        this.onHiding_dxPopup_prenda = this.onHiding_dxPopup_prenda.bind(this);
        this.render_dxButton_guardar_dxPopup_prenda = this.render_dxButton_guardar_dxPopup_prenda.bind(this);
        this.render_dxButton_cancelar_dxPopup_prenda = this.render_dxButton_cancelar_dxPopup_prenda.bind(this);
        this.onClick_guardar_dxPopup_prenda = this.onClick_guardar_dxPopup_prenda.bind(this);
        this.onClick_cancelar_dxPopup_prenda = this.onClick_cancelar_dxPopup_prenda.bind(this);
        this.onClick_tipoPrenda_dxPpopup_prenda = this.onClick_tipoPrenda_dxPpopup_prenda.bind(this);
        this.onValueChanged_replaceUpperCase_denomiancion =
            this.onValueChanged_replaceUpperCase_denomiancion.bind(this);
        this.updateArchivos_documentos = this.updateArchivos_documentos.bind(this);
        this.dxPopup_prendas_dxNumberBox_onValueChanged = this.dxPopup_prendas_dxNumberBox_onValueChanged.bind(this);
        this.dxPopup_prendas_transporte_dxSelectBox_onValueChanged =
            this.dxPopup_prendas_transporte_dxSelectBox_onValueChanged.bind(this);
        this.dxPopup_prendas_dxSelectBox_marcaTapa_onValueChanged =
            this.dxPopup_prendas_dxSelectBox_marcaTapa_onValueChanged.bind(this);
        this.dxPopup_prendas_dxSelectBox_colorTapa_onValueChanged =
            this.dxPopup_prendas_dxSelectBox_colorTapa_onValueChanged.bind(this);

        //PRECIO
        this.tblPrecioLavadoPrenda_render = this.tblPrecioLavadoPrenda_render.bind(this);
        this.dxButton_tblPrecioLavadoPrenda_onClick = this.dxButton_tblPrecioLavadoPrenda_onClick.bind(this);
        this.onHiding_dxPopup_precioLavadoPrenda = this.onHiding_dxPopup_precioLavadoPrenda.bind(this);

        //PESO
        this.tblPrenda_historico_peso_render = this.tblPrenda_historico_peso_render.bind(this);
        this.dxButton_prenda_historico_peso_onClick = this.dxButton_prenda_historico_peso_onClick.bind(this);
        this.onInitNewRow_dxDataGrid_prenda_historico_peso =
            this.onInitNewRow_dxDataGrid_prenda_historico_peso.bind(this);
        this.onRowRemoving_dxDataGrid_prenda_historico_peso =
            this.onRowRemoving_dxDataGrid_prenda_historico_peso.bind(this);
        this.onToolbarPreparing_dxDataGrid_prenda_historico_peso =
            this.onToolbarPreparing_dxDataGrid_prenda_historico_peso.bind(this);
        this.onHiding_dxPopup_prenda_historico_peso = this.onHiding_dxPopup_prenda_historico_peso.bind(this);
        this.dxDataGrid_prenda_historico_peso_onModified = this.dxDataGrid_prenda_historico_peso_onModified.bind(this);

        //ID TIPO FACUTRACION
        this.tblPrenda_historico_idTipoFacturacion_render =
            this.tblPrenda_historico_idTipoFacturacion_render.bind(this);
        this.dxButton_prenda_historico_idTipoFacturacion_onClick =
            this.dxButton_prenda_historico_idTipoFacturacion_onClick.bind(this);
        this.onHiding_dxPopup_prenda_historico_idTipoFacturacion =
            this.onHiding_dxPopup_prenda_historico_idTipoFacturacion.bind(this);

        this.previsualizacion_popupPrendas_render = this.previsualizacion_popupPrendas_render.bind(this);
        this.dxForm_prenda_onContentReady = this.dxForm_prenda_onContentReady.bind(this);
        this.dxList_addPrenda_itemTemplate = this.dxList_addPrenda_itemTemplate.bind(this);
        this.dxList_familia_onSelectionChanged = this.dxList_familia_onSelectionChanged.bind(this);
        this.dxList_tipoPrenda_onSelectionChanged = this.dxList_tipoPrenda_onSelectionChanged.bind(this);
        this.dxList_denoPrenda_onSelectionChanged = this.dxList_denoPrenda_onSelectionChanged.bind(this);
        this.onHiding_dxPopup_seleccionTipoPrenda = this.onHiding_dxPopup_seleccionTipoPrenda.bind(this);
        this.onHidden_dxPopup_seleccionTipoPrenda = this.onHidden_dxPopup_seleccionTipoPrenda.bind(this);
        this.dxForm_prenda_denominacion_onValueChanged = this.dxForm_prenda_denominacion_onValueChanged.bind(this);
        this.datasource_tblDocumentoPrenda_beforeSend = this.datasource_tblDocumentoPrenda_beforeSend.bind(this);
        this.datasource_tblUsuario_onLoading = this.datasource_tblUsuario_onLoading.bind(this);
        //endregion

        //#region MODIFICAR PRECIO PRENDA
        this.toolbarPreciosPrenda_render = this.toolbarPreciosPrenda_render.bind(this);
        this.onShowing_popup_preciosPrenda = this.onShowing_popup_preciosPrenda.bind(this);
        this.hiding_popup_preciosPrenda = this.hiding_popup_preciosPrenda.bind(this);
        this.onContentReady_dxDataGrid_tblPrenda_precios = this.onContentReady_dxDataGrid_tblPrenda_precios.bind(this);
        this.onRowPrepared_dxDataGrid_tblPrenda_precios = this.onRowPrepared_dxDataGrid_tblPrenda_precios.bind(this);
        this.context_tblPrenda_beforeSend = this.context_tblPrenda_beforeSend.bind(this);
        //#endregion

        //#region POPUP ALMACENES
        this.dxPopup_configAlmacenes_onShowing = this.dxPopup_configAlmacenes_onShowing.bind(this);
        this.dxPopup_configAlmacenes_onShown = this.dxPopup_configAlmacenes_onShown.bind(this);
        this.dxPopup_configAlmacenes_onHiding = this.dxPopup_configAlmacenes_onHiding.bind(this);
        this.dxSelectBox_tblTipoHabitacion_onValueChanged =
            this.dxSelectBox_tblTipoHabitacion_onValueChanged.bind(this);
        this.onToolbarPreparing_dxDataGrid_tblPrendaNAlmacen =
            this.onToolbarPreparing_dxDataGrid_tblPrendaNAlmacen.bind(this);
        this.dxDataGrid_tblPrendaNAlmacen_addPrenda_onClick =
            this.dxDataGrid_tblPrendaNAlmacen_addPrenda_onClick.bind(this);
        this.render_dxButton_guardar_dxPopup_configAlmacenes =
            this.render_dxButton_guardar_dxPopup_configAlmacenes.bind(this);
        this.render_dxButton_cancelar_dxPopup_configAlmacenes =
            this.render_dxButton_cancelar_dxPopup_configAlmacenes.bind(this);
        this.store_tblPrenda_entiSel_beforeSend = this.store_tblPrenda_entiSel_beforeSend.bind(this);
        this.dxPopup_tblPrendaNAlmacen_onShowing = this.dxPopup_tblPrendaNAlmacen_onShowing.bind(this);
        this.dxPopup_tblPrendaNAlmacen_onHiding = this.dxPopup_tblPrendaNAlmacen_onHiding.bind(this);
        this.onClick_cancelar_dxPopup_prendasAlmacen = this.onClick_cancelar_dxPopup_prendasAlmacen.bind(this);
        this.onOptionChanged_dxList_prendasAlmacen_compañia =
            this.onOptionChanged_dxList_prendasAlmacen_compañia.bind(this);
        this.onOptionChanged_dxList_prendasAlmacen_entidad =
            this.onOptionChanged_dxList_prendasAlmacen_entidad.bind(this);
        this.onClick_cancelar_dxPopup_configAlmacenes = this.onClick_cancelar_dxPopup_configAlmacenes.bind(this);
        this.onClick_guardar_dxPopup_configAlmacenes = this.onClick_guardar_dxPopup_configAlmacenes.bind(this);
        this.datasource_datos_tblApartado_postProcess = this.datasource_datos_tblApartado_postProcess.bind(this);
        //#endregion
        //#endregion

        //CALLBACK
        this.validationCallback_stock = this.validationCallback_stock.bind(this);
        this.validationCallback_tipoHabitacion = this.validationCallback_tipoHabitacion.bind(this);
        this.saveDocuments = this.saveDocuments.bind(this);

        this.cancel_isPedidoPorPeticion = false;

        //MÉTODO
        this.dxDataGrid_tblEntidadesAsociadas_set_dataSource =
            this.dxDataGrid_tblEntidadesAsociadas_set_dataSource.bind(this);
        this.getTrad = this.getTrad.bind(this);
        this.set_label = this.set_label.bind(this);

        //#endregion

        this.set_label();

        this.editorOptions_tipoFact = {
            dataSource: this.datasource_datos_tblTipoFacturacionCliente,
            displayExpr: "denominacion",
            valueExpr: "idTipoFacturacionCliente",
            onSelectionChanged: this.dxPopup_entidad_tipoFact_onSelectionChanged,
        };
    }

    set_label() {
        this.formLabel_habitaciones = {
            text: this.getTrad("habitaciones"),
        };

        this.formLabel_idCompañia = {
            text: this.getTrad("compañiaAsociada"),
        };

        this.formLabel_denominacion = {
            text: this.getTrad("denominacion"),
        };

        this.formLabel_direccion = {
            text: this.getTrad("direccion"),
        };

        this.formLabel_pais = {
            text: this.getTrad("pais"),
        };

        this.formLabel_provincia = {
            text: this.getTrad("provincia"),
        };

        this.formLabel_codigoPostal = {
            text: this.getTrad("codigoPostal"),
        };

        this.formLabel_poblacion = {
            text: this.getTrad("poblacion"),
        };

        this.formLabel_telefono = {
            text: this.getTrad("telefono"),
        };

        this.formLabel_email = {
            text: this.getTrad("email"),
        };

        this.formLabel_plazas = {
            text: this.getTrad("plazas"),
        };

        this.formLabel_habitaciones = {
            text: this.getTrad("habitaciones"),
        };

        this.formLabel_localizacion = {
            text: this.getTrad("localizacion"),
        };

        this.formLabel_moneda = {
            text: this.getTrad("moneda"),
        };

        this.formLabel_myProduction = {
            text: "MyProduction",
        };

        this.formLabel_tipoPedido = {
            text: this.getTrad("tipoPedido"),
        };

        this.formLabel_pedidosPermitidos = {
            text: this.getTrad("pedidosPermitidos"),
        };

        this.formLabel_tipoReparto = {
            text: this.getTrad("tipoReparto"),
        };

        this.formLabel_tipoInfConsumoLenceria = {
            text: this.getTrad("tipoInfConsumoLenceria"),
        };

        this.formLabel_reparteAlmaGen = {
            text: this.getTrad("reparteAlmaGen"),
        };

        this.formLabel_reparteRechazoRetiro = {
            text: this.getTrad("reparteRechazoRetiro"),
        };

        this.formLabel_gestionCorreosAlbaranes = {
            text: this.getTrad("gestionCorreosAlbaranes"),
        };

        this.formLabel_horarioReparto = {
            text: this.getTrad("horarioReparto"),
        };

        this.formLabel_costeEstancia = {
            text: this.getTrad("costeEstancia"),
        };

        this.formLabel_objetivoKgEstancia = {
            text: this.getTrad("objetivoKgEstancia"),
        };

        this.formLabel_tipoFact = {
            text: this.getTrad("tipoFact"),
        };

        this.formLabel_inventarioPorEntidad = {
            text: this.getTrad("inventarioPorEntidad"),
        };

        this.formLabel_tipoHab = {
            text: this.getTrad("tipoHab"),
        };

        this.formLabel_tipoEntidad = {
            text: this.getTrad("tipoEntidad"),
        };

        this.formLabel_prendasPedido = {
            visible: false,
            text: this.getTrad("prendasPedido"),
        };
        this.formLabel_codigo = {
            text: this.getTrad("codigo"),
        };

        this.formLabel_activo = {
            text: this.getTrad("activo"),
        };

        this.formLabel_elemReparto = {
            text: this.getTrad("elemReparto"),
        };

        this.formLabel_udsElemReparto = {
            text: this.getTrad("udsElemReparto"),
        };

        this.formLabel_elemOffice = {
            text: this.getTrad("elemOffice"),
        };

        this.formLabel_udsElemOffice = {
            text: this.getTrad("udsElemOffice"),
        };

        this.formLabel_elemPedido = {
            text: this.getTrad("elemPedido"),
        };

        this.formLabel_udsElemPedido = {
            text: this.getTrad("udsElemPedido"),
        };

        this.formLabel_elemProduccion = {
            text: this.getTrad("elemProduccion"),
        };

        this.formLabel_udsElemProducción = {
            text: this.getTrad("udsElemProducción"),
        };

        this.formLabel_udsAlmacenaje = {
            text: this.getTrad("udsAlmacenaje"),
        };

        this.formLabel_coste = {
            text: this.getTrad("coste"),
        };

        this.formLabel_parStock = {
            text: this.getTrad("parStock"),
        };

        this.formLabel_familiaFacturacion = {
            text: this.getTrad("familiaFacturacion"),
        };

        this.formLabel_precioLavado = {
            text: this.getTrad("precioLavado"),
        };

        this.formLabel_porcentajePrecioDesmanche = {
            text: this.getTrad("porcentajePrecioDesmanche"),
        };

        this.formLabel_categoria = {
            text: this.getTrad("categoria"),
        };

        this.formLabel_marcaTapa = {
            text: this.getTrad("marcaTapa"),
        };

        this.formLabel_colorTapa = {
            text: this.getTrad("colorTapa"),
        };

        this.formLabel_peso = {
            text: this.getTrad("peso"),
        };

        this.formLabel_tipoAlmacen = {
            text: this.getTrad("tipoAlmacen"),
        };

        this.formLabel_invisible = {
            visible: false,
        };
    }

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    //#region REFERENCIAS
    get dxTreeView_compaEnti() {
        return this.dxTreeView_compaEnti_REF.current.instance;
    }

    get dxDataGrid_tblPrenda() {
        return this.dxDataGrid_tblPrenda_REF.current.instance;
    }

    get DropDownBox_tapa() {
        return this.DropDownBox_tapa_REF.current.instance;
    }

    get dxDropdownButton_colores() {
        return this.dxDropdownButton_colores_REF.current.instance;
    }

    get dxList_marcas() {
        return this.dxList_marcas_REF.current.instance;
    }

    get dxForm_entidad() {
        return this.dxForm_entidad_REF.current.instance;
    }
    get tabPanel_dxForm_entidad() {
        return this.tabPanel_dxForm_entidad_REF.current.instance;
    }

    get dxScrollView_dxForm_dxTab() {
        return this.dxScrollView_dxForm_dxTab_REF.current.instance;
    }

    get dxPopup_addTipoHabitacion() {
        return this.dxPopup_addTipoHabitacion_REF.current.instance;
    }

    get dxForm_addTipoHabitacion() {
        return this.dxForm_addTipoHabitacion_REF.current.instance;
    }

    get dxList_prendasCompañia() {
        return this.dxList_prendasCompañia_REF.current.instance;
    }

    get dxList_prendasEntidad() {
        return this.dxList_prendasEntidad_REF.current.instance;
    }

    get dxDataGrid_tblPrendaNTipoHabitacion() {
        return this.dxDataGrid_tblPrendaNTipoHabitacion_REF.current.instance;
    }

    get dxList_tblTipoHabitacion() {
        return this.dxList_tblTipoHabitacion_REF.current.instance;
    }

    get dxList_tblPrendaNEntidad_NuevoPedido_entidad() {
        return this.dxList_tblPrendaNEntidad_NuevoPedido_entidad_REF.current.instance;
    }

    get dxList_tblPrendaNEntidad_NuevoPedido_compañia() {
        return this.dxList_tblPrendaNEntidad_NuevoPedido_compañia_REF.current.instance;
    }

    get dxList_tblPrenda_compañia() {
        return this.dxList_tblPrenda_compañia_REF.current.instance;
    }

    get dxList_tblPrenda_entidad() {
        return this.dxList_tblPrenda_entidad_REF.current.instance;
    }

    get dxList_addCompañia() {
        return this.dxList_addCompañia_REF.current.instance;
    }

    get dxForm_compañia() {
        return this.dxForm_compañia_REF.current.instance;
    }

    get dxForm_addSeccionNivel1() {
        return this.dxForm_addSeccionNivel1_REF.current.instance;
    }

    get dxForm_addSeccionNivel2() {
        return this.dxForm_addSeccionNivel2_REF.current.instance;
    }

    get dxTagBox_tblTipoPedidoNEntidad() {
        return this.dxTagBox_tblTipoPedidoNEntidad_REF.current.instance;
    }
    get dxList_seccionNivel1() {
        return this.dxList_seccionNivel1_REF.current.instance;
    }

    get dxList_seccionNivel2() {
        return this.dxList_seccionNivel2_REF.current.instance;
    }

    get dxTabPanel_almacenes() {
        return this.dxTabPanel_almacenes_REF.current.instance;
    }

    get dxDataGrid_gestionCorreosAlbaranes() {
        return this.dxDataGrid_gestionCorreosAlbaranes_REF.current.instance;
    }

    get dxForm_prenda() {
        return this.dxForm_prenda_REF.current.instance;
    }

    get dxList_entidadesAsociada() {
        return this.dxList_entidadesAsociada_REF.current.instance;
    }

    get dxButton_tblPrecioLavadoPrenda() {
        return this.dxButton_tblPrecioLavadoPrenda_REF.current.instance;
    }

    get dxDataGrid_tblPrenda_precios() {
        return this.dxDataGrid_tblPrenda_precios_REF.current.instance;
    }

    get dxDateBox_preciosPrenda() {
        return this.dxDateBox_preciosPrenda_REF.current.instance;
    }

    get dxNumberBox_precio_preciosPrenda() {
        return this.dxNumberBox_precio_preciosPrenda_REF.current.instance;
    }

    get dxButton_prenda_historico_peso() {
        return this.dxButton_prenda_historico_peso_REF.current.instance;
    }

    get dxButton_prenda_historico_idTipoFacturacion() {
        return this.dxButton_prenda_historico_idTipoFacturacion_REF.current.instance;
    }

    get dxForm_almacen() {
        return this.dxForm_almacen_REF.current.instance;
    }

    get dxDataGrid_tblPrendaNAlmacen() {
        return this.dxDataGrid_tblPrendaNAlmacen_REF.current.instance;
    }

    get dxList_importarEntidad_entidad() {
        return this.dxList_importarEntidad_entidad_REF.current.instance;
    }

    get dxList_importarEntidad_lavanderia() {
        return this.dxList_importarEntidad_lavanderia_REF.current.instance;
    }

    get dxList_importarEntidad_compañia() {
        return this.dxList_importarEntidad_compañia_REF.current.instance;
    }

    get dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia() {
        return this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia_REF.current.instance;
    }

    get dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad() {
        return this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad_REF.current.instance;
    }

    get dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario() {
        return this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario_REF.current.instance;
    }

    get dxList_tblPrendaNEntidad_NuevoPedido_usuario() {
        return this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_REF.current.instance;
    }

    get dxList_tblPrendaNEntidad_NuevoPedido_usuario_entidad() {
        return this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_entidad_REF.current.instance;
    }

    get dxList_tblPrendaNEntidad_NuevoPedido_usuario_compañia() {
        return this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_compañia_REF.current.instance;
    }

    get dxDataGrid_tblhorarioRepartoNEntidad() {
        return this.dxDataGrid_tblHorarioRepartoNEntidad_REF.current.instance;
    }

    get dxButton_tblhorarioRepartoNEntidad() {
        return this.dxButton_tblhorarioRepartoNEntidad_REF.current.instance;
    }

    get dxDataGrid_tblSacasPendientes() {
        return this.dxDataGrid_tblSacasPendientes_REF.current.instance;
    }

    //#endregion

    //#region TREEVIEW
    datasource_datos_compaEnti_compañia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCompañia",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_compaEnti_compañia_beforeSend(request);
            },
        }),
        select: ["idCompañia", "denominacion", "activo"],
        sort: ["denominacion"],
    });

    datasource_datos_compaEnti_compañia_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = this.props.lavanderia.idLavanderia;

        if (request.method == "MERGE") request.method = "PATCH";

        if (request.method === "get") {
            request.params.todas = true;
        }
    }

    datasource_datos_compaEnti = new DataSource({
        store: new CustomStore({
            load: () => {
                this.datasource_datos_compaEnti_load();
            },
            onLoading: () => {
                this.datasource_datos_compaEnti_onLoading();
            },
        }),
    });

    datasource_datos_compaEnti_onLoading() {
        if ($("#dxTreeView_compaEnti").length > 0) {
            this.scroll_dxTreeView_compaEnti = $("#dxTreeView_compaEnti")
                .find(".dx-scrollable")
                .data("dxScrollable")
                .scrollOffset();
        }
    }

    datasource_datos_compaEnti_load() {
        var _deferred = $.Deferred();
        let _this = this;
        let { entidadSel, compañiaSel, entidadSel_popup } = _this.state;

        $.when(
            _this.datasource_datos_tblColorTapa.load(),
            _this.datasource_datos_compaEnti_compañia.load(),
            _this.datasource_datos_tblEntidad.load(),
            _this.datasource_datos_tblTipoHabitacion.load()
        ).done(function () {
            var colores = _this.datasource_datos_tblColorTapa.items();

            var compañias = query(_this.datasource_datos_compaEnti_compañia.items())
                .filter(["activo", "=", true])
                .toArray();

            var entidades = _this.datasource_datos_tblEntidad.items();
            entidades = entidades.map((entidad) => {
                let maxIdSacasPendientes = entidad.tblSacasPendientes.reduce(
                    (maxObj, currentObj) => {
                        //* Saca el mayor idSacasPendientes
                        return maxObj.idSacasPendientes > currentObj.idSacasPendientes ? maxObj : currentObj;
                    },
                    { idSacasPendientes: 0 }
                ).idSacasPendientes;
                return {
                    ...entidad,
                    tblSacasPendientes: [
                        ...entidad.tblSacasPendientes,
                        ...[
                            ...colores,
                            {
                                idColorTapa: null,
                                tblColorTapa: { codigoHexadecimal: null },
                            },
                        ]
                            .filter(
                                (color) =>
                                    !entidad.tblSacasPendientes.some(
                                        (sacasPendientes) => sacasPendientes.idColorTapa === color.idColorTapa
                                    )
                            ) //* Filtra los colores que ya estan presentes en entidad.tblSacasPendientes
                            .map((color) => {
                                //* Añade los elementos lógisticos por defecto a cantidad 0
                                return {
                                    idEntidad: entidad.idEntidad,
                                    cantidad: 0,
                                    idSacasPendientes: ++maxIdSacasPendientes,
                                    idColorTapa: color.idColorTapa,
                                    tblColorTapa: { codigoHexadecimal: color.codigoHexadecimal },
                                };
                            }),
                    ],
                };
            });

            var compaEnti = [];

            var numCompañias = compañias.length;
            var numEntidades = 0;
            var maxEntidadesVisibles = 10;

            $.each(compañias, function (index, compañia) {
                var compa = $.extend(true, {}, compañia);

                compa.id = compañia.idCompañia;
                compa.tblEntidad = $.grep(entidades, function (entidad) {
                    return entidad.idCompañia === compa.id;
                });

                $.each(compa.tblEntidad, function (index, entidad) {
                    entidad.id = compañia.idCompañia + "-" + entidad.idEntidad;
                });

                if (compa.tblEntidad.length > 0) {
                    numEntidades += compa.tblEntidad.length;
                    compa.expanded =
                        numCompañias === 1
                            ? true //Una sola compañía siempre visible
                            : numCompañias > 5
                                ? false //Más de 5 compañias todo contraido
                                : numEntidades > maxEntidadesVisibles
                                    ? false
                                    : true; // (2,3,4,5) Compañias visibles hasta las maxEntidadesVisibles

                    compaEnti.push(compa);
                }
            });

            let arrayCompañias_dxDropDownBox_compañia = $.map(
                _this.datasource_datos_compaEnti_compañia.items(),
                function (compañia, index) {
                    return {
                        idCompañia: compañia.idCompañia,
                        denominacion: compañia.denominacion,
                    };
                }
            );

            let tiposHabitacion = _this.datasource_datos_tblTipoHabitacion.items();
            $.each(tiposHabitacion, function (index, tipoHabitacion) {
                $.each(tipoHabitacion.tblPrendaNTipoHabitacion, function (index, prendaNTipoHabitacion) {
                    prendaNTipoHabitacion.actual = 0;
                    prendaNTipoHabitacion.stock = prendaNTipoHabitacion.parStock;
                    delete prendaNTipoHabitacion.parStock;
                });
            });

            _this.setState(
                {
                    datasource_datos_compaEnti: compaEnti,
                    arrayCompañias_dxDropDownBox_compañia: arrayCompañias_dxDropDownBox_compañia,
                    dxTabPanel_prendasAlm_selctedIndex: 0,
                    selectedIndex_dxTabPanel_almacenPrenda: 0,
                    isVisible_dxPopup_configEntidad: false,
                    entidadSel: entidadSel_popup ? $.extend(true, {}, entidadSel_popup) : null,
                    datasource_datos_tblTipoHabitacion_items: tiposHabitacion,
                    seccionNivel1_Sel: null,
                    seccionNivel2_Sel: null,
                    datasource_tblSeccionNivel2: [],
                    yearCalendar_currentYear: null,
                },
                () => {
                    if (entidadSel_popup || entidadSel || compañiaSel) _this.dxTreeView_compaEnti.beginUpdate();

                    setTimeout(() => {
                        // Para seleccionar nueva entidad creada justo después de actualizar las compa/entis
                        if (entidadSel_popup) {
                            _this.dxTreeView_compaEnti.selectItem(
                                entidadSel_popup.idCompañia + "-" + entidadSel_popup.idEntidad
                            );
                            _this.dxTreeView_compaEnti.expandItem(
                                entidadSel_popup.idCompañia + "-" + entidadSel_popup.idEntidad
                            );

                            _this.dxScrollView_dxForm_dxTab.scrollTo(0);
                        } else if (compañiaSel) _this.dxTreeView_compaEnti.selectItem(compañiaSel.idCompañia);

                        _this.dxTreeView_compaEnti.endUpdate();

                        if (_this.datasource_tblPrenda && !compañiaSel) {
                            _this.datasource_tblPrenda.load().done(function (data) {
                                _this.setState({
                                    datasource_dxDataGrid_tblPrenda: data,
                                });
                            });
                        }
                    }, 0);
                }
            );

            _this.loadPanel_hide();
            _deferred.resolve(compaEnti);
        });

        return _deferred.promise();
    }

    // Actualiza la entidad seleccionada
    datasource_datos_entidadSel = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEntidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_entidadSel_beforeSend(request);
            },
            onLoading: (loadOptions) => {
                this.datasource_datos_entidadSel_onLoading(loadOptions);
            },
            onLoaded: (data) => {
                this.datasource_datos_entidadSel_onLoaded(data);
            },
            version: 4,
        }),
        select: [
            "idEntidad",
            "denominacion",
            "idCompañia",
            "isPrincipal",
            "isTodasPrendaNNuevoPedido_compañia",
            "isTodasPrendaNNuevoPedido_entidad",
        ],
        sort: ["denominacion"],
        expand: [
            "tblPrendaNEntidad_NuevoPedido($filter=tblPrenda/activo eq true;$expand=tblPrenda($select=idPrenda,codigoPrenda,denominacion,idEntidad,udsXBacPedido;$expand=tblElemTransPedido($select=codigo,denominacion),tblColorTapa($select=codigoHexadecimal),tblMarcaTapa($select=denominacion)))",
            "tblTipoHabitacion($expand = tblPrendaNTipoHabitacion($expand = tblPrenda($select = codigoPrenda, denominacion, idEntidad));$filter = (eliminado eq false))",
            "tblEntidad_historico_idTipoFacturacion",
            "tblCierreFactEntidad($select=fechaDesde, fechaHasta)"
        ],
    });

    datasource_datos_entidadSel_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = this.props.lavanderia.idLavanderia;
        request.params.todas = true;
    }

    datasource_datos_entidadSel_onLoading(loadOptions) {
        let { entidadSel } = this.state;
        if (entidadSel) {
            if (loadOptions.filter)
                loadOptions.filter = [
                    loadOptions.filter,
                    "and",
                    ["idEntidad", "=", entidadSel != null ? entidadSel.idEntidad : -1],
                ];
            else loadOptions.filter = ["idEntidad", "=", entidadSel != null ? entidadSel.idEntidad : -1];
        }
    }

    datasource_datos_entidadSel_onLoaded(data) {
        let { datasource_datos_compaEnti } = this.state;
        $.each(datasource_datos_compaEnti, function (iCompa, compa) {
            $.each(data, function (index, item) {
                $.each(compa.tblEntidad, function (iEnti, enti) {
                    if (enti.idEntidad == item.idEntidad) {
                        enti.tblPrendaNEntidad_NuevoPedido = item.tblPrendaNEntidad_NuevoPedido;
                        enti.tblTipoHabitacion = item.tblTipoHabitacion;
                    }
                });
            });
        });
        this.setState({ datasource_datos_compaEnti });
        this.dxDataGrid_tblPrenda.endCustomLoading();
    }
    //#endregion

    //#region DATAGRID PRENDA
    datasource_tblPrenda = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPrenda",
            key: "idPrenda",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblPrenda_beforeSend(request);
            },
            onLoading: (data) => {
                this.datasource_tblPrenda_onLoading(data);
            },
            onLoaded: (data) => {
                this.datasource_tblPrenda_onLoaded(data);
            },
            version: 4,
        }),
        expand: [
            "tblPrendaNAlmacen($count=true;$top=0),tblPrendaNEntidad_NuevoPedido($count=true;$top=0),tblPrendaNTipoHabitacion($count=true;$top=0), tblDocumentoPrenda($select= idDocumento,nombre,extension)",
        ],
        postProcess: data => this.datasource_tblPrenda_postProcess(data)
    });

    datasource_tblPrenda_beforeSend(request) {
        request.headers = { ...authHeader() };

        let { compañiaSel, entidadSel } = this.state;
        if (request.method === "get") {
            request.params.idCompañia = compañiaSel ? compañiaSel.idCompañia : null;
            request.params.idEntidad = entidadSel && entidadSel.idEntidad ? entidadSel.idEntidad : null;
        }
    }

    datasource_tblPrenda_onLoading(data) {
        if (this.state.entidadSel || this.state.compañiaSel) {
            this.dxDataGrid_tblPrenda.beginCustomLoading();
        }
    }

    datasource_tblPrenda_onLoaded(data) {
        this.dxDataGrid_tblPrenda.endCustomLoading();
        return data;
    }

    datasource_tblPrenda_postProcess = data => {
        return data.map(p => {
            return {
                ...p,
                tblPrecioLavadoPrenda: p.tblPrecioLavadoPrenda.map(plp => {
                    return {
                        ...plp,
                        isCerrado: this.getIsCerrado(plp.fecha)
                    }
                }),
                tblPrenda_historico_peso: p.tblPrenda_historico_peso.map(php => {
                    return {
                        ...php,
                        isCerrado: this.getIsCerrado(php.fecha)
                    }
                }),
                tblPrenda_historico_idTipoFacturacion: p.tblPrenda_historico_idTipoFacturacion.map(phtf => {
                    return {
                        ...phtf,
                        isCerrado: this.getIsCerrado(phtf.fecha)
                    }
                }),
            }
        })
    }

    odatastore_tblPrenda_codigo = new ODataStore({
        url: connectionConstants.ODATA_URL + "tblPrenda",
        key: "idPrenda",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            this.odatastore_tblPrenda_codigo_beforeSend(request);
        },
        version: 4,
    });

    odatastore_tblPrenda_codigo_beforeSend(request) {
        request.headers = { ...authHeader() };

        let { compañiaSel, entidadSel } = this.state;

        if (request.method === "get") {
            request.params.idCompañia = compañiaSel ? compañiaSel.idCompañia : null;
            request.params.idEntidad = entidadSel && entidadSel.idEntidad ? entidadSel.idEntidad : null;
        }
    }

    datasource_datos_tblTipoFacturacion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoFacturacion",
            key: "idTipoFacturacion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    //#endregion

    //#region POPUP ENTIDAD
    datasource_tblPrendaNEntidad_compañia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPrenda",
            key: "idPrenda",
            beforeSend: (request) => {
                this.tblPrendaNEntidad_compañia_datasource_beforeSend(request);
            },
            version: 4,
            onLoading: (loadOptions) => {
                this.tblPrendaNEntidad_compañia_datasource_onLoading(loadOptions);
            },
        }),
        select: ["idPrenda", "codigoPrenda", "denominacion", "idEntidad", "udsXBacPedido"],
        sort: ["codigoPrenda"],
        expand: [
            "tblElemTransPedido($select=codigo,denominacion)",
            "tblColorTapa($select=codigoHexadecimal)",
            "tblMarcaTapa($select=denominacion)",
        ],
    });

    tblPrendaNEntidad_compañia_datasource_onLoading(loadOptions) {
        let { entidadSel_popup } = this.state;
        if (loadOptions.filter)
            loadOptions.filter = [
                loadOptions.filter,
                "and",
                [
                    ["idCompañia", "=", entidadSel_popup != null ? entidadSel_popup.idCompañia : -1],
                    "and",
                    ["activo", "=", true],
                ],
            ];
        else
            loadOptions.filter = [
                ["idCompañia", "=", entidadSel_popup != null ? entidadSel_popup.idCompañia : -1],
                "and",
                ["activo", "=", true],
            ];
    }

    tblPrendaNEntidad_compañia_datasource_beforeSend(request) {
        let { entidadSel_popup } = this.state;
        request.headers = { ...authHeader() };

        request.params.idCompañia = entidadSel_popup.idCompañia;
        request.params.idEntidad = entidadSel_popup.idEntidad;
    }

    datasource_tblPrendaNEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPrenda",
            key: "idPrenda",
            beforeSend: (request) => {
                this.tblPrendaNEntidad_datasource_beforeSend(request);
            },
            version: 4,
            onLoading: (loadOptions) => {
                this.tblPrendaNEntidad_datasource_onLoading(loadOptions);
            },
        }),
        select: ["idPrenda", "codigoPrenda", "denominacion", "idEntidad", "udsXBacPedido"],
        sort: ["codigoPrenda"],
        expand: [
            "tblElemTransPedido($select=codigo,denominacion)",
            "tblColorTapa($select=codigoHexadecimal)",
            "tblMarcaTapa($select=denominacion)",
        ],
    });

    tblPrendaNEntidad_datasource_onLoading(loadOptions) {
        let { entidadSel_popup } = this.state;
        if (loadOptions.filter)
            loadOptions.filter = [
                loadOptions.filter,
                "and",
                [
                    ["idEntidad", "=", entidadSel_popup != null ? entidadSel_popup.idEntidad : -1],
                    "and",
                    ["activo", "=", true],
                ],
            ];
        else
            loadOptions.filter = [
                ["idEntidad", "=", entidadSel_popup != null ? entidadSel_popup.idEntidad : -1],
                "and",
                ["activo", "=", true],
            ];
    }

    tblPrendaNEntidad_datasource_beforeSend(request) {
        let { entidadSel_popup } = this.state;
        request.headers = { ...authHeader() };

        request.params.idCompañia = entidadSel_popup.idCompañia;
        request.params.idEntidad = entidadSel_popup.idEntidad;
    }

    datasource_datos_tblEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEntidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tblEntidad_beforeSend(request);
            },
            version: 4,
        }),
        expand: [
            "tblCorreosNEntidad($expand=tblReports($select=idReport,denominacion,idFormulario))",
            "tblEntidadNEntidadPrincipal($select=idEntidad,denominacion)",
            "tblEntidadNEntidadSecundaria($select=idEntidad,denominacion)",
            "tblPrendaNEntidad_NuevoPedido($expand=tblPrenda($select=idPrenda,codigoPrenda,denominacion,idEntidad,udsXBacPedido;$expand=tblElemTransPedido($select=codigo,denominacion),tblColorTapa($select=codigoHexadecimal),tblMarcaTapa($select=denominacion)))",
            "tblTipoHabitacion($expand = tblPrendaNTipoHabitacion($expand = tblPrenda($select = codigoPrenda, denominacion, idEntidad));$filter = (eliminado eq false))",
            "tblTipoPedidoNEntidad",
            "tblCalendarioEntidad($expand=tblCalendarioEntidad_Estados($select=colorHexa,idTipoEstado;$expand=tblTraduccion($select=es,en,pt)))",
            "tblHorarioRepartoNEntidad",
            "tblSacasPendientes($expand=tblColorTapa($select=codigoHexadecimal))",
            "tblEntidad_historico_idTipoFacturacion",
            "tblCierreFactEntidad($select=fechaDesde, fechaHasta)"
        ],
        postProcess: function (data) {
            data.forEach((entidad) => {
                entidad.tblHorarioRepartoNEntidad.forEach((horarioReparto) => {
                    horarioReparto.horarioInicioReparto = durationToDatetime(horarioReparto.horarioInicioReparto);
                    horarioReparto.horarioFinReparto = durationToDatetime(horarioReparto.horarioFinReparto);
                });
                $.each(entidad.tblPrendaNEntidad_NuevoPedido, function (iPrenda, prenda) {
                    let bacUds =
                        prenda.tblPrenda.tblElemTransPedido.codigo === "2" ? prenda.tblPrenda.udsXBacPedido : 1;
                    prenda.stockDefinido = prenda.stockDefinido / bacUds;
                    prenda.isPedidoPorPeticion = prenda.stockDefinido == null || prenda.stockDefinido == 0;
                });
            });
            return data;
        },
    });

    datasource_datos_tblEntidad_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = this.props.lavanderia.idLavanderia;
        if (request.method === "get") {
            request.params.todas = true;
        }
    }

    datasource_datos_tblTipoConsumoLenceria = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoConsumoLenceria",
            key: "idTipoConsumoLenceria",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblTipoFacturacionCliente = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoFacturacionCliente",
            key: "idTipoFacturacionCliente",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idTipoFacturacionCliente", "denominacion"],
    });

    datasource_datos_tblTipoPedido = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoPedido",
            key: "idTipoPedido",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblTipoRepartoEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoRepartoEntidad",
            key: "idTipoRepartoEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblElemTrans = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblElemTrans",
            key: "idElemTrans",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblLocalizacion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblLocalizacion",
            key: "idLocalizacion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblMoneda = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblMoneda",
            key: "idMoneda",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblUsuario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblUsuario",
            key: "idUsuario",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
            onLoading: (data) => {
                this.datasource_tblUsuario_onLoading(data);
            },
        }),
        select: ["idUsuario, usuario, nombre"],
    });

    datasource_tblUsuario_onLoading(loadOptions) {
        let { entidadSel_popup } = this.state;
        let idEntidad = entidadSel_popup.idEntidad;
        let idFormulario = 30; //Pedidos

        loadOptions.filter = [
            "(subtipoUsuario eq 0 or subtipoUsuario eq 2) and tblFormularioNUsuario/any(x: x/idFormulario eq " +
            idFormulario +
            ") and idEntidad/any(y: y/idEntidad eq " +
            idEntidad +
            ") and isEliminado eq false",
        ];
        loadOptions.expand = ["tblPrendaNUsuarioNEntidad($select=idPrenda;$filter=idEntidad eq " + idEntidad + ")"];
    }

    //#endregion

    //#region POPUP PRENDA
    datasource_datos_tblColorTapa = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblColorTapa",
            key: "idColorTapa",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblMarcaTapa = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblMarcaTapa",
            key: "idMarcaTapa",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblFamilia = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblFamilia",
            key: "idFamilia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        expand: [
            "tblTipoPrenda($orderby = codigo;$expand=tblDenoPrenda($orderby = codigo; $expand=  tblTraduccion($select=" +
            this.props.idioma.codigo +
            ")), tblTraduccion($select=" +
            this.props.idioma.codigo +
            ")), tblTraduccion($select=" +
            this.props.idioma.codigo +
            ")",
        ],
        sort: ["codigo"],
        postProcess: function (data) {
            $.each(data, function (index, familia) {
                $.each(familia.tblTipoPrenda, function (index, tipoPrenda) {
                    tipoPrenda.tblDenoPrenda = $.grep(tipoPrenda.tblDenoPrenda, function (denoPrenda) {
                        if (denoPrenda.codigo === "00" && tipoPrenda.tblDenoPrenda.length > 1) {
                            //Quitamos el SIN ASIGNAR con más denoPrendas
                            return false;
                        }
                        return true;
                    });
                });
            });
            return data;
        },
    });

    datasource_datos_tblElemTrans = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblElemTrans",
            key: "idElemTrans",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblCalendarioEntidad_Estados = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCalendarioEntidad_Estados",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tblCalendarioEntidad_Estados_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["tblTraduccion($select=es,en,pt)"],
    });

    datasource_datos_tblCalendarioEntidad_Estados_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblDocumentoPrenda = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDocumentoPrenda",
            key: "idDocumento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblDocumentoPrenda_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblDocumentoPrenda_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    //#endregion

    //#region IMPORTAR ENTIDAD
    datasource_datos_lavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblLavanderia",
            key: "idLavanderia",
            paginate: false,
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idLavanderia", "denominacion"],
    });

    datasource_datos_importarEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCompañia",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_importarEntidad_beforeSend(request);
            },
            version: 4,
            onLoading: (data) => {
                this.datasource_datos_importarEntidad_onLoading(data);
            },
        }),
        select: ["idCompañia", "denominacion"],
        sort: ["denominacion"],
        postProcess: (data) => {
            return this.datasource_datos_importarEntidad_postProcess(data);
        },
    });

    datasource_datos_importarEntidad_beforeSend(request) {
        request.headers = { ...authHeader() };

        let { selectedItems_dxList_importarEntidad_lavanderia } = this.state;
        let idLavanderia =
            selectedItems_dxList_importarEntidad_lavanderia.length > 0
                ? selectedItems_dxList_importarEntidad_lavanderia[0].idLavanderia
                : -1;
        request.params.idLavanderia = idLavanderia != undefined ? idLavanderia : -1;
        request.params.todas = true;
    }

    datasource_datos_importarEntidad_onLoading(loadOptions) {
        loadOptions.expand = ["tblEntidad($select = idEntidad, denominacion;$orderby = denominacion;)"];
    }

    datasource_datos_importarEntidad_postProcess(data) {
        //Se obtienen los ids de las entidades ya subscriptas
        var entidadesYaSubscritas = [];
        let { datasource_datos_compaEnti } = this.state;

        $.each(datasource_datos_compaEnti, function (index, compañia) {
            $.merge(
                entidadesYaSubscritas,
                $.map(compañia.tblEntidad, function (entidad) {
                    return entidad.idEntidad;
                })
            );
        });
        //Se filtran las entidades con las que NO están ya subscritas a la lavanderia y se obtiene una lista de todas las entidades
        var todasEntidades = [];

        $.each(data, function (index, compañia) {
            compañia.tblEntidad = $.grep(compañia.tblEntidad, function (entidad) {
                return $.inArray(entidad.idEntidad, entidadesYaSubscritas) === -1;
            });

            $.merge(todasEntidades, compañia.tblEntidad);
        });

        data = $.grep(data, function (compañia) {
            return compañia.tblEntidad.length > 0;
        });

        todasEntidades = query(todasEntidades).sortBy("denominacion").toArray();

        if (data.length > 0) {
            data.splice(0, 0, {
                idCompañia: -1,
                denominacion: getTrad("todas").toUpperCase(),
                tblEntidad: todasEntidades,
            });
        }

        this.setState({
            datasource_importarEntidad_compañia: data,
            selectedItems_dxList_importarEntidad_compañia: data.length > 0 ? [data[0]] : [],
            datasource_importarEntidad_entidad: data.length > 0 ? data[0].tblEntidad : [],
        });

        return data;
    }

    datasource_datos_tblEntidadNLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEntidadNLavanderia",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tblEntidadNLavanderia_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_datos_tblEntidadNLavanderia_beforeSend(request) {
        request.headers = { ...authHeader() };

        if (request.method === "DELETE") {
            request.params.idLavanderia = this.props.lavanderia.idLavanderia;
        }
    }

    datasource_apartados = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblApartado",
            key: "idApartado",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idApartado,denominacion"],
        filter: ["idApartado eq 9"], // De momento solo mostrar Valet
        expand: ["tblFormulario($select=idFormulario,denominacion;$expand=tblReports($select=idReport,denominacion))"],
        postProcess: (data) => {
            this.datasource_datos_tblApartado_postProcess(data);
        },
    });

    datasource_datos_tblApartado_postProcess(data) {
        let filterItems = [];
        $.each(data, function (iApartado, apartado) {
            $.each(apartado.tblFormulario, function (iForm, form) {
                if (form.tblReports.length > 0) {
                    // TODO COGERÁ EL PRIMER FORMULARIO DEL APARTADO VISIBLE - SI SE GUARDAR FORMULARIOS EN VEZ DE APARTADOS REVISAR, HAY OTRO SOME EN LA PARTE DE TBLREPORTS
                    if (!filterItems.some((apart) => apart.idApartado === apartado.idApartado))
                        filterItems.push(apartado);
                }
            });
        });
        return filterItems;
    }

    datasource_tipoReport = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblReports",
            key: "idReport",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idReport,tblFormulario"],
        expand: ["tblFormulario($expand=tblApartado($select=idApartado,denominacion))"],
    });

    enum_tipoEntidad = [
        {
            value: true,
            denominacion: getTrad("principal"),
        },
        {
            value: false,
            denominacion: getTrad("secundaria"),
        },
    ];
    //#endregion

    //#region POPUP ENTIDAD
    datasource_tipoReport = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblReports",
            key: "idReport",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tipoReport_beforeSend(request);
            },
            version: 4,
        }),
        select: ["idReport,tblFormulario"],
        expand: ["tblFormulario($expand=tblApartado($select=idApartado,denominacion))"],
    });

    datasource_tipoReport_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_apartados = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblApartado",
            key: "idApartado",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_apartados_beforeSend(request);
            },
            version: 4,
        }),
        select: ["idApartado,denominacion"],
        filter: ["idApartado eq 9"], // TODO de momento solo mostrar Valet
        expand: ["tblFormulario($select=idFormulario,denominacion;$expand=tblReports($select=idReport,denominacion))"],
        postProcess: function (data) {
            let filterItems = [];
            $.each(data, function (iApartado, apartado) {
                $.each(apartado.tblFormulario, function (iForm, form) {
                    if (form.tblReports.length > 0) {
                        // TODO COGERÁ EL PRIMER FORMULARIO DEL APARTADO VISIBLE - SI SE GUARDAR FORMULARIOS EN VEZ DE APARTADOS REVISAR, HAY OTRO SOME EN LA PARTE DE TBLREPORTS
                        if (!filterItems.some((apart) => apart.idApartado === apartado.idApartado))
                            filterItems.push(apartado);
                    }
                });
            });
            return filterItems;
        },
    });
    datasource_apartados_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    enum_reparteRechazoRetiro = [
        {
            id: 0,
            denominacion: this.getTrad("rechazoRetiro").toUpperCase(),
        },
        {
            id: 1,
            denominacion: this.getTrad("rechazo").toUpperCase(),
        },
        {
            id: 2,
            denominacion: this.getTrad("retiro").toUpperCase(),
        },
    ];

    enum_tipoEntidad = [
        {
            value: true,
            denominacion: this.getTrad("principal"),
        },
        {
            value: false,
            denominacion: this.getTrad("secundaria"),
        },
    ];
    //#endregion

    //#region GRID ALMACENES
    datasource_datos_tblAlmacen = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblAlmacen",
            key: "idAlmacen",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tblAlmacen_beforeSend(request);
            },
            onLoaded: (request) => {
                this.datasource_datos_tblAlmacen_onLoaded(request);
            },
            version: 4,
        }),
        expand: [
            "tblPrendaNAlmacen($select = stock, actual; $expand=tblPrenda($filter=eliminado eq false; $select=idPrenda,codigoPrenda,denominacion,idEntidad,udsXBacOffice;$expand=tblElemTransOffice($select=codigo,denominacion),tblColorTapa($select=codigoHexadecimal),tblMarcaTapa($select=denominacion))),tblTipoAlmacen($select = denominacion;),tblTipoHabitacion($select = denominacion)",
        ],
        select: ["idAlmacen", "denominacion", "activo", "idTipoAlmacen", "idTipoHabitacion"],
    });

    datasource_datos_tblAlmacen_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.idSeccionNivel2 = this.state.seccionNivel2_Sel.idSeccionNivel2;
    }

    datasource_datos_tblAlmacen_onLoaded(data) {
        $.each(data, function (index, almacen) {
            if (almacen.idTipoAlmacen === 2 || almacen.idTipoAlmacen === 3) {
                $.each(almacen.tblPrendaNAlmacen, function (index, prendaNAlma) {
                    var udsXBacOffice = prendaNAlma.tblPrenda.udsXBacOffice;
                    prendaNAlma.stock = prendaNAlma.stock / udsXBacOffice;
                    prendaNAlma.actual = prendaNAlma.actual / udsXBacOffice;
                });
            }
        });
    }

    datasource_datos_tblAlmacen_delta = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblAlmacen",
            key: "idAlmacen",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tblAlmacen_delta_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_datos_tblAlmacen_delta_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.isDelta = true;
    }
    //#endregion

    //#region POPUP ALMACEN
    datasource_datos_tblTipoAlmacen = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoAlmacen",
            key: "idTipoAlmacen",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        filter: ["!", ["idTipoAlmacen", "=", 7]],
    });

    datasource_datos_tblTipoHabitacion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoHabitacion",
            key: "idTipoHabitacion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_tblTipoHabitacion_beforeSend(request);
            },
            version: 4,
        }),
        expand: [
            "tblPrendaNTipoHabitacion($expand=tblPrenda($select=idPrenda,codigoPrenda,denominacion,idEntidad,udsXBacOffice;$expand=tblElemTransOffice($select=codigo,denominacion),tblColorTapa($select=codigoHexadecimal),tblMarcaTapa($select=denominacion);$filter = (eliminado eq false)))",
        ],
        select: ["idTipoHabitacion", "denominacion"],
        sort: ["denominacion"],
        filter: ["eliminado", "=", false],
    });

    datasource_datos_tblTipoHabitacion_beforeSend(request) {
        let { entidadSel } = this.state;

        request.headers = { ...authHeader() };
        request.params.idEntidad = entidadSel && entidadSel.idEntidad ? entidadSel.idEntidad : -1;
    }

    store_tblPrenda_entiSel = new ODataStore({
        url: connectionConstants.ODATA_URL + "tblPrenda",
        key: "idPrenda",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            this.store_tblPrenda_entiSel_beforeSend(request);
        },
        version: 4,
    });

    store_tblPrenda_entiSel_beforeSend(request) {
        let { entidadSel } = this.state;
        request.headers = { ...authHeader() };

        request.params.idCompañia = entidadSel.idCompañia;
        request.params.idEntidad = entidadSel.idEntidad;
    }

    datasource_tblPrendaNAlmacen_compañia = new DataSource({
        paginate: false,
        store: this.store_tblPrenda_entiSel,
        sort: ["codigoPrenda"],
        filter: [["activo", "=", true]],
        select: ["idPrenda", "codigoPrenda", "denominacion", "idEntidad", "udsXBacOffice", "idCompañia"],
        expand: [
            "tblElemTransOffice($select=codigo,denominacion)",
            "tblColorTapa($select=codigoHexadecimal)",
            "tblMarcaTapa($select=denominacion)",
        ],
    });

    datasource_tblPrendaNAlmacen_entidad = new DataSource({
        paginate: false,
        store: this.store_tblPrenda_entiSel,
        sort: ["codigoPrenda"],
        filter: [["activo", "=", true]],
        select: ["idPrenda", "codigoPrenda", "denominacion", "idEntidad", "udsXBacOffice"],
        expand: [
            "tblElemTransOffice($select=codigo,denominacion)",
            "tblColorTapa($select=codigoHexadecimal)",
            "tblMarcaTapa($select=denominacion)",
        ],
    });
    //#endregion

    //#region MODIFICAR PRECIOS
    context_tblPrenda = new ODataContext({
        url: connectionConstants.ODATA_URL + "tblPrenda",
        entities: {
            UpdateMasivo: {},
        },
        beforeSend: (request) => {
            this.context_tblPrenda_beforeSend(request);
        },
    });

    context_tblPrenda_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.payload = this.tratarDatos_UpdateMasivo_Prendas();
    }
    //#endregion

    //#region EDIFICIOS_SECCIONES
    datasource_datos_secciones = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblSeccionNivel1",
            key: "idSeccionNivel1",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_datos_secciones_beforeSend(request);
            },
            onLoading: (request) => {
                this.datasource_datos_secciones_onLoading(request);
            },
            version: 4,
        }),
        expand: [
            "tblSeccionNivel2($select=idSeccionNivel2,denominacion;$orderby=denominacion;$filter=(eliminado eq false))",
        ],
        filter: ["eliminado", "=", false],
        sort: ["denominacion"],
        select: ["idSeccionNivel1", "denominacion"],
    });

    datasource_datos_secciones_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = this.props.lavanderia.idLavanderia;
    }

    datasource_datos_secciones_onLoading(loadOptions) {
        let { entidadSel } = this.state;
        if (typeof $("#dxList_tblSeccionNivel1").dxList("instance") !== "undefined")
            this.dxList_seccionNivel1.unselectAll();

        var filtro = [];

        if (typeof loadOptions.filter !== "undefined") {
            filtro.push(loadOptions.filter);
            filtro.push("and");
        }

        filtro.push(["idEntidad", "=", entidadSel ? entidadSel.idEntidad : -1]);
        loadOptions.filter = filtro;
    }

    datasource_datos_tblSeccionNivel1 = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblSeccionNivel1",
            key: "idSeccionNivel1",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_datos_tblSeccionNivel2 = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblSeccionNivel2",
            key: "idSeccionNivel2",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    //#endregion

    enum_dxButtonGroup_prendasPedido = [
        {
            id: 0,
            denominacion: getTrad("prendasPedidos"),
            icon: null,
            color: "--secondary",
        },
        {
            id: 1,
            denominacion: getTrad("prendasUsuario"),
            icon: null,
            color: "--teal",
        },
    ];

    enum_selectorFiltro_prendasPedido = [
        {
            id: true,
            denominacion: getTrad("todas").toUpperCase(),
        },
        {
            id: false,
            denominacion: getTrad("ninguna").toUpperCase(),
        },
        {
            id: null,
            denominacion: getTrad("personalizado").toUpperCase(),
        },
    ];

    cargaDatos_lavanderia() {
        this.setState(initialState);

        let _this = this;
        this.loadPanel_show();
        $.when(
            this.datasource_datos_tblTipoPedido.load(),
            this.datasource_datos_tblMarcaTapa.load(),
            this.datasource_datos_tblColorTapa.load(),
            this.datasource_datos_tblTipoFacturacion.load(),
            this.datasource_datos_tblCalendarioEntidad_Estados.load(),
            this.datasource_datos_compaEnti.load(),
            this.datasource_apartados.load(),
            this.datasource_datos_tblFamilia.load(),
            this.datasource_tipoReport.load(),
            this.datasource_datos_tblTipoAlmacen.load(),
            this.datasource_datos_tblLocalizacion.load(),
            this.datasource_datos_tblTipoConsumoLenceria.load(),
            this.datasource_datos_tblTipoRepartoEntidad.load(),
            this.datasource_datos_tblTipoFacturacionCliente.load(),
            this.datasource_datos_tblMoneda.load()
        ).then(function (tipoPedido, marcaTapa, colorTapa, tipoFact, estadosCalendario) {
            _this.setState({
                items_datos_tblTipoPedido: tipoPedido[0],
                datasource_datos_tblMarcaTapa: marcaTapa[0],
                datasource_datos_tblColorTapa: colorTapa[0],
                datasource_datos_tblTipoFacturacion: tipoFact[0],
                datasource_datos_tblCalendarioEntidad_Estados: estadosCalendario[0],
            });
        });
    }

    dxDataGrid_tblEntidadesAsociadas_set_dataSource() {
        let { entidadSel_popup } = this.state;
        if (entidadSel_popup) {
            let datasource = new ArrayStore({
                key: "idEntidad",
                data: entidadSel_popup.isPrincipal
                    ? entidadSel_popup.tblEntidadNEntidadPrincipal
                    : entidadSel_popup.tblEntidadNEntidadSecundaria,
            });

            this.setState({
                datasource_dxDataGrid_tblEntidadesAsociadas: datasource,
            });
        }
    }

    render() {
        const { user, lavanderia } = this.props;
        let { importaEntidades, isDepartamentoControl } = user;
        let {
            compañiaSel,
            entidadSel,
            entidadSel_popup,
            prendaSel,
            almacenSel,
            datasource_dxDataGrid_tblPrenda,
            yearCalendar_currentYear,
            datasource_importarEntidad_compañia,
            datasource_importarEntidad_entidad,
            selectedItems_dxList_importarEntidad_entidad,
            selectedItems_dxList_importarEntidad_compañia,
            selectedItems_dxList_importarEntidad_lavanderia,
            isVisible_dxPopup_importarEntidad,
            selectedItems_dxList_tblPrendaNAlmacen_entidad,
            selectedItems_dxList_tblPrendaNAlmacen_compañia,
            datasource_datos_tblPrendaNAlmacen_entidad,
            datasource_datos_tblPrendaNAlmacen_compañia,
            datasource_datos_tblTipoHabitacion_items,
            isVisible_dxPopup_tblPrendaNAlmacen,
            isVisible_dxPopup_configAlmacenes,
            datasource_tblSeccionNivel2,
            seccionNivel1_Sel,
            seccionNivel2_Sel,
            isVisible_dxPopup_addSeccionNivel1,
            isVisible_dxPopup_addSeccionNivel2,
            isVisible_dxPopup_modificarPrecios,
            isCellEditing_dxDataGrid_tblPrenda,
            isVisible_dxPopup_seleccionTipoPrenda,
            isVisible_dxPopup_precioLavadoPrenda,
            isVisible_dxPopup_historicoPesoPrenda,
            isVisible_dxPopup_historicoIdTipoFacturacionPrenda,
            isVisible_dxPopup_configEntidad,
            isVisible_dxPopup_addTipoHabitacion,
            isVisible_dxPopup_tblPrendaNTipoHabitacion,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario,
            isVisible_dxPopup_tblEntidadNEntidadAsociada,
            isVisible_dxPopup_compañia,
            isVisible_dxPopup_prendas,
            accionesModificarDatos_fileUploader,
            isVisible_loading_dxPopup_configEntidad,
            dxButtonGroup_prendasPedido_idSel,
            datasource_tblPrendaNEntidad_compañia,
            selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource,
            dxList_prendaNCompañia_usuario_dataSource,
            dxList_prendaNEntidad_usuario_dataSource,
            isVisible_dxPopup_horarioReparto,
            isVisible_dxPopup_sacasPendientes,
            isVisible_dxPopup_mapa,
            isVisible_PopupHistoricoTipoFacturacion,
            address,
            coordinates,
            addressNoMod,
            cellHover_cerrado
        } = this.state;

        const allowEdit = (lavanderia.idCorporacion == 4 && isDepartamentoControl) || lavanderia.idCorporacion != 4;

        return (
            <Fragment>
                <PageTitle heading={getNombreFormulario(this)} />
                <div className={"media-body"}>
                    <div id="EstructuraOrganizativa" className="formContainer">
                        <Box direction="row" width="auto" height="100%">
                            <ItemBox baseSize={360}>
                                <Box direction="col" width="100%" height="100%">
                                    <ItemBox baseSize={60}>
                                        <div className="font-size p-2">{this.getTrad("compañiasEntidades")}</div>
                                    </ItemBox>
                                    <ItemBox baseSize={40}>
                                        <Toolbar>
                                            <ToolbarItem location="before">
                                                <Button
                                                    text={this.getTrad("entidad")}
                                                    icon="add"
                                                    onClick={this.onClick_AddEntidad}
                                                />
                                            </ToolbarItem>
                                            <ToolbarItem location="before">
                                                <Button
                                                    text={this.getTrad("importarEntidad")}
                                                    icon="arrowdown"
                                                    onClick={this.importarEntidad_onClick}
                                                    visible={importaEntidades || isDepartamentoControl}
                                                />
                                            </ToolbarItem>
                                        </Toolbar>
                                    </ItemBox>
                                    <ItemBox baseSize={5}></ItemBox>
                                    <ItemBox ratio={1}>
                                        <TreeView
                                            ref={this.dxTreeView_compaEnti_REF}
                                            elementAttr={this.elementAttr_dxTreeView_compaEnti}
                                            dataSource={this.state.datasource_datos_compaEnti}
                                            keyExpr="id"
                                            itemsExpr="tblEntidad"
                                            displayExpr="denominacion"
                                            height="100%"
                                            selectionMode="single"
                                            selectByClick={true}
                                            searchEnabled={true}
                                            searchTimeout={500}
                                            onContentReady={this.dxTreeView_compaEnti_onContentReady}
                                            onItemClick={this.dxTreeView_compaEnti_onItemClick}
                                            itemRender={this.dxTreeView_compaEnti_itemRender}
                                        />
                                    </ItemBox>
                                </Box>
                            </ItemBox>
                            <ItemBox baseSize={20}></ItemBox>
                            <ItemBox ratio={1}>
                                <TabPanel
                                    height="100%"
                                    width="100%"
                                    elementAttr={this.class_h3}
                                    animationEnabled={true}
                                    disabled={!compañiaSel && !entidadSel}
                                    swipeEnabled={false}
                                    repaintChangesOnly={true}
                                    deferRendering={false}
                                    selectedIndex={this.state.selectedIndex_dxTabPanel_almacenPrenda}
                                    onSelectionChanged={this.dxTabPanel_prendas_onSelectionChanged}
                                >
                                    <TabPanelItem tabIndex={0} title={this.getTrad("prendas")}>
                                        <DataGrid
                                            //Datos
                                            ref={this.dxDataGrid_tblPrenda_REF}
                                            dataSource={datasource_dxDataGrid_tblPrenda}
                                            //Propiedades
                                            columnsAutoWidth={true}
                                            height={"100%"}
                                            width={"100%"}
                                            //Estilos
                                            showColumnLines={false}
                                            showRowLines={true}
                                            rowAlternationEnabled={true}
                                            allowColumnReordering={true}
                                            hoverStateEnabled={true}
                                            //Eventos
                                            onEditorPreparing={this.onEditorPreparing_tblPrenda}
                                            onRowPrepared={this.onRowPrepared_tblPrenda}
                                            onRowUpdating={this.onRowUpdating_tblPrenda}
                                            onToolbarPreparing={this.onToolbarPreparing_tblPrenda}
                                            onRowRemoving={this.onRowRemoving_tblPrenda}
                                            onRowRemoved={this.onRowRemoved_tblPrenda}
                                            onCellPrepared={this.onCellPrepared_tblPrenda}
                                            customizeExportData={this.customizeExportData_tblPrenda}
                                        >
                                            <Grid_LoadPanel animation={false} />
                                            <Export enabled={true} fileName={"Prendas"} />
                                            <SearchPanel
                                                visible={true}
                                                width={200}
                                                placeholder={this.getTrad("busqueda")}
                                                searchVisibleColumnsOnly={true}
                                            />
                                            <HeaderFilter visible={true} />
                                            <FilterRow
                                                visible={true}
                                                showAllText={this.getTrad("todos").toUpperCase()}
                                                applyFilter="auto"
                                            />
                                            <Editing
                                                mode="cell"
                                                refreshMode="repaint"
                                                allowUpdating={true}
                                                allowDeleting={false}
                                                useIcons={true}
                                                selectTextOnEditStart={true}
                                                texts={this.dxDataGrid_tblPrenda_confirmDeleteMessage}
                                            ></Editing>
                                            <KeyboardNavigation
                                                enterKeyAction="moveFocus"
                                                enterKeyDirection="row"
                                                editOnKeyPress={true}
                                            />
                                            <Paging defaultPageSize={20} />
                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={this.tblPrenda_allowedPageSizes}
                                                showInfo={true}
                                            />
                                            <Sorting mode="multiple" />
                                            <ColumnChooser enabled={true} height={600} />
                                            <ColumnFixing enabled={true} />
                                            <Column
                                                caption=" "
                                                width={40}
                                                alignment="center"
                                                cssClass="p-0"
                                                fixed={true}
                                                allowFixing={false}
                                                allowHiding={false}
                                                allowReordering={false}
                                                cellRender={this.cellRender_tblPrenda_edit}
                                            />
                                            <Column
                                                dataField="codigoPrenda"
                                                caption={this.getTrad("codigo")}
                                                width={130}
                                                alignment="center"
                                                fixed={true}
                                                sortOrder="asc"
                                                sortIndex={1}
                                                allowHeaderFiltering={false}
                                                allowEditing={false}
                                            />
                                            <Column
                                                dataField="denominacion"
                                                caption={this.getTrad("denominacion")}
                                                minWidth={300}
                                                fixed={true}
                                                allowEditing={false}
                                                allowHeaderFiltering={false}
                                            />
                                            <Column dataField="peso" visible={false} showInColumnChooser={false} />
                                            <Column
                                                dataField="tblPrenda_historico_peso"
                                                caption={this.getTrad("peso")}
                                                alignment="center"
                                                cssClass="p-0"
                                                height={"100%"}
                                                width={150}
                                                allowSearch={false}
                                                allowFiltering={false}
                                                allowEditing={false}
                                                showEditorAlways={true}
                                                allowSorting={false}
                                                allowHeaderFiltering={false}
                                                editCellRender={this.editCellRender_tblPrenda_tblPrenda_historico_peso}
                                            />
                                            <Column
                                                dataField="precioLavado"
                                                visible={false}
                                                showInColumnChooser={false}
                                            />
                                            <Column
                                                dataField="tblPrecioLavadoPrenda"
                                                caption={this.getTrad("precioLavado")}
                                                alignment="center"
                                                cssClass="p-0"
                                                height={"100%"}
                                                width={150}
                                                allowSearch={false}
                                                allowFiltering={false}
                                                allowEditing={false}
                                                showEditorAlways={true}
                                                allowSorting={false}
                                                allowHeaderFiltering={false}
                                                editCellRender={this.editCellRender_tblPrenda_tblPrecioLavadoPrenda}
                                            />

                                            <Column dataField="tipoFact" visible={false} showInColumnChooser={false} />
                                            <Column
                                                dataField="tblPrenda_historico_idTipoFacturacion"
                                                caption={this.getTrad("tipoFact")}
                                                alignment="center"
                                                cssClass="p-0"
                                                height={"100%"}
                                                width={180}
                                                allowSearch={false}
                                                allowFiltering={false}
                                                allowEditing={false}
                                                showEditorAlways={true}
                                                allowSorting={false}
                                                allowHeaderFiltering={false}
                                                editCellRender={
                                                    this.editCellRender_tblPrenda_tblPrenda_historico_idTipoFacturacion
                                                }
                                            />

                                            <Column
                                                dataField="isExtra"
                                                caption={this.getTrad("categoria")}
                                                alignment="center"
                                                dataType="boolean"
                                                showEditorAlways={true}
                                                width={120}
                                                editorOptions={this.editorOptions_isExtra}
                                                visible={false}
                                                allowFiltering={false}
                                                allowHeaderFiltering={false}
                                            />
                                            <Column
                                                dataField="percPrecioDesmanche"
                                                caption={this.getTrad("porcentajePrecioDesmanche")}
                                                alignment={"center"}
                                                visible={false}
                                                width={155}
                                                editorType="dxNumberBox"
                                                format={this.format_percPrecioDesmanche}
                                                editorOptions={this.editorOptions_percPrecioDesmanche}
                                                allowHeaderFiltering={false}
                                            />
                                            <Column
                                                dataField="idColorTapa"
                                                visible={false}
                                                showInColumnChooser={false}
                                            />
                                            <Column
                                                dataField="idMarcaTapa"
                                                visible={false}
                                                showInColumnChooser={false}
                                            />
                                            <Column
                                                caption={this.getTrad("tapa")}
                                                name="tapa"
                                                width={65}
                                                alignment={"center"}
                                                cssClass="p-0"
                                                height={"100%"}
                                                showEditorAlways={true}
                                                allowSearch={false}
                                                allowFiltering={false}
                                                allowEditing={true}
                                                allowSorting={false}
                                                editCellRender={this.editCellRender_tblPrenda_tapa}
                                                allowExporting={false}
                                            />
                                            <Column
                                                dataField="udsAlmacenaje"
                                                caption={this.getTrad("udsAlmacenaje")}
                                                alignment={"center"}
                                                editorType={"dxNumberBox"}
                                                visible={false}
                                                width={150}
                                                format={this.format_decimal_maxCero}
                                                editorOptions={this.editorOptions_udsAlmacenaje}
                                                allowHeaderFiltering={false}
                                            />
                                            <Column
                                                dataField="udsXBacReparto"
                                                caption={this.getTrad("elemReparto")}
                                                visible={false}
                                                alignment={"center"}
                                                width={140}
                                                editorType={"dxNumberBox"}
                                                editorOptions={this.editorOptions_udsXBacReparto}
                                                allowHeaderFiltering={false}
                                                cellRender={this.cellRender_tblPrenda_udsXBacReparto}
                                                setCellValue={this.setCellValue_udsXBacReparto}
                                            />
                                            <Column
                                                dataField="udsXBacOffice"
                                                caption={this.getTrad("elemOffice")}
                                                visible={false}
                                                alignment={"center"}
                                                width={140}
                                                editorType={"dxNumberBox"}
                                                editorOptions={this.editorOptions_udsXBacOffice}
                                                allowHeaderFiltering={false}
                                                cellRender={this.cellRender_tblPrenda_udsXBacOffice}
                                                setCellValue={this.setCellValue_udsXBacOffice}
                                            />
                                            <Column
                                                dataField="udsXBacPedido"
                                                caption={this.getTrad("elemPedido")}
                                                alignment={"center"}
                                                visible={false}
                                                width={140}
                                                editorType={"dxNumberBox"}
                                                editorOptions={this.editorOptions_udsXBacPedido}
                                                allowHeaderFiltering={false}
                                                cellRender={this.cellRender_tblPrenda_udsXBacPedido}
                                                setCellValue={this.setCellValue_udsXBacPedido}
                                            />
                                            <Column
                                                dataField="udsXBacProduccion"
                                                caption={this.getTrad("elemProducción")}
                                                alignment={"center"}
                                                visible={false}
                                                width={140}
                                                editorType={"dxNumberBox"}
                                                editorOptions={this.editorOptions_udsXBacProduccion}
                                                allowHeaderFiltering={false}
                                                cellRender={this.cellRender_tblPrenda_udsXBacProduccion}
                                                setCellValue={this.setCellValue_udsXBacProduccion}
                                            />
                                            <Column
                                                dataField="coste"
                                                caption={this.getTrad("coste")}
                                                alignment={"center"}
                                                visible={false}
                                                width={145}
                                                editorType={"dxNumberBox"}
                                                allowHeaderFiltering={false}
                                                format={this.format_currency}
                                                editorOptions={this.editorOptions_currency}
                                            />
                                            <Column
                                                dataField="parStock"
                                                caption={this.getTrad("parStock")}
                                                alignment={"center"}
                                                visible={false}
                                                width={120}
                                                editorType={"dxNumberBox"}
                                                allowHeaderFiltering={false}
                                                format={this.format_parStock}
                                                editorOptions={this.editorOptions_parStock}
                                            />
                                            <Column
                                                dataField="activo"
                                                caption={this.getTrad("activo")}
                                                alignment={"center"}
                                                dataType={"boolean"}
                                                showEditorAlways={true}
                                                width={130}
                                                sortOrder={"desc"}
                                                sortIndex={0}
                                                visible={false}
                                                allowFiltering={false}
                                                allowHeaderFiltering={true}
                                                editorOptions={this.editorOptions_activo}
                                                headerFilter={this.headerFilter_filtroActivo}
                                            />
                                            <Column
                                                dataField="tblDocumentoPrenda"
                                                visible={false}
                                                allowFiltering={false}
                                                allowSearch={false}
                                                showInColumnChooser={false}
                                            />
                                            <Column
                                                caption={" "}
                                                width={"40px"}
                                                alignment={"center"}
                                                fixed={true}
                                                allowFixing={false}
                                                allowHiding={false}
                                                allowReordering={false}
                                                fixedPosition={"right"}
                                                cssClass={"p-0"}
                                                cellComponent={removeCellComponent}
                                            />
                                        </DataGrid>
                                    </TabPanelItem>
                                    <TabPanelItem tabIndex={1} disabled={!entidadSel} title={this.getTrad("almacenes")}>
                                        <TabPanel
                                            ref={this.dxTabPanel_almacenes_REF}
                                            height="100%"
                                            width="100%"
                                            repaintChangesOnly={true}
                                            elementAttr={this.elementAttr_dxTabPanel_almacenes}
                                            animationEnabled={true}
                                            swipeEnabled={false}
                                            selectedIndex={this.state.dxTabPanel_prendasAlm_selctedIndex}
                                            onSelectionChanged={this.dxTabPanel_almacenes_onSelectionChanged}
                                        >
                                            <TabPanelItem tabIndex={0} title={this.getTrad("edificiosSecciones")}>
                                                <LoadPanel
                                                    deferRendering={false}
                                                    animation={null}
                                                    position={dxBox_secciones}
                                                    visible={
                                                        this.state.isVisible_loading_dxBox_secciones &&
                                                        this.state.selectedIndex_dxTabPanel_almacenPrenda == 1
                                                    }
                                                    shadingColor="transparent"
                                                    showIndicator={true}
                                                    shading={true}
                                                    showPane={true}
                                                />
                                                <Box
                                                    elementAttr={this.dxBox_secciones_elementAttr}
                                                    direction="row"
                                                    align="space-around"
                                                    crossAlign="stretch"
                                                    width="100%"
                                                    height="100%"
                                                >
                                                    <ItemBox ratio={1}>
                                                        <Box
                                                            direction="col"
                                                            align="space-around"
                                                            crossAlign="stretch"
                                                            width="100%"
                                                            height="100%"
                                                        >
                                                            <ItemBox baseSize={40}>
                                                                <div className="font-size">
                                                                    {this.getTrad("edificios")}
                                                                </div>
                                                            </ItemBox>
                                                            <ItemBox baseSize={50}>
                                                                <Toolbar>
                                                                    <ToolbarItem location="before">
                                                                        <Button
                                                                            text={this.getTrad("edificio")}
                                                                            icon="plus"
                                                                            onClick={this.onClick_dxButton_edificio}
                                                                        />
                                                                    </ToolbarItem>
                                                                </Toolbar>
                                                            </ItemBox>
                                                            <ItemBox ratio={1}>
                                                                <List
                                                                    ref={this.dxList_seccionNivel1_REF}
                                                                    elementAttr={this.elementAttr_dxList_seccionNivel1}
                                                                    height={"100%"}
                                                                    dataSource={this.state.datasource_datos_secciones}
                                                                    selectionMode={"single"}
                                                                    scrolling={this.dxList_scrolling_infinite}
                                                                    selectedItemKeys={
                                                                        seccionNivel1_Sel
                                                                            ? [seccionNivel1_Sel.idSeccionNivel1]
                                                                            : []
                                                                    }
                                                                    searchEnabled={true}
                                                                    searchExpr="denominacion"
                                                                    displayExpr={"denominacion"}
                                                                    keyExpr={"idSeccionNivel1"}
                                                                    valueExpr={"idSeccionNivel1"}
                                                                    pageLoadMode={"scrollBottom"}
                                                                    itemRender={this.itemRender_tblSeccionNivel1}
                                                                    onSelectionChanged={
                                                                        this.onSelectionChanged_tblSeccionNivel1
                                                                    }
                                                                />
                                                            </ItemBox>
                                                        </Box>
                                                    </ItemBox>
                                                    <ItemBox baseSize={15}></ItemBox>
                                                    <ItemBox ratio={1}>
                                                        <Box
                                                            direction="col"
                                                            align="space-around"
                                                            crossAlign="stretch"
                                                            width="100%"
                                                            height="100%"
                                                        >
                                                            <ItemBox baseSize={40}>
                                                                <div className="font-size">
                                                                    {this.getTrad("secciones")}
                                                                </div>
                                                            </ItemBox>
                                                            <ItemBox baseSize={50}>
                                                                <Toolbar>
                                                                    <ToolbarItem location="before">
                                                                        <Button
                                                                            text={this.getTrad("seccion")}
                                                                            icon="plus"
                                                                            disabled={!seccionNivel1_Sel}
                                                                            onClick={this.onClick_dxButton_seccion2}
                                                                        />
                                                                    </ToolbarItem>
                                                                </Toolbar>
                                                            </ItemBox>
                                                            <ItemBox ratio={1}>
                                                                <List
                                                                    ref={this.dxList_seccionNivel2_REF}
                                                                    elementAttr={this.elementAttr_dxList_seccionNivel2}
                                                                    height={"100%"}
                                                                    dataSource={datasource_tblSeccionNivel2}
                                                                    selectionMode={"single"}
                                                                    scrolling={this.dxList_scrolling_infinite}
                                                                    selectedItems={[seccionNivel2_Sel]}
                                                                    searchEnabled={true}
                                                                    searchExpr="denominacion"
                                                                    valueExpr={"idSeccionNivel2"}
                                                                    displayExpr={"denominacion"}
                                                                    pageLoadMode={"scrollBottom"}
                                                                    itemRender={this.itemRender_tblSeccionNivel2}
                                                                    onItemClick={this.onItemClick_tblSeccionNivel2}
                                                                />
                                                            </ItemBox>
                                                        </Box>
                                                    </ItemBox>
                                                </Box>
                                            </TabPanelItem>
                                            <TabPanelItem tabIndex={1} title={this.getTrad("almacenes")}>
                                                <DataGrid
                                                    dataSource={this.datasource_datos_tblAlmacen}
                                                    remoteOperations={true}
                                                    //Propiedades
                                                    columnsAutoWidth={true}
                                                    repaintChangesOnly={true}
                                                    height={"100%"}
                                                    width={"100%"}
                                                    //Estilos
                                                    showColumnLines={false}
                                                    showRowLines={true}
                                                    rowAlternationEnabled={true}
                                                    hoverStateEnabled={true}
                                                    //Eventos
                                                    onEditingStart={this.onEditingStart_tblAlmacenes}
                                                    onRowPrepared={this.onRowPrepared_tblAlmacenes}
                                                    onToolbarPreparing={this.onToolbarPreparing_tblAlmacenes}
                                                >
                                                    <SearchPanel
                                                        visible={true}
                                                        width={200}
                                                        placeholder={this.getTrad("busqueda")}
                                                        searchVisibleColumnsOnly={true}
                                                    />
                                                    <HeaderFilter visible={true} />
                                                    <FilterRow
                                                        visible={true}
                                                        showAllText={this.getTrad("todos").toUpperCase()}
                                                        applyFilter="auto"
                                                    />
                                                    <Paging defaultPageSize={20} />
                                                    <Pager
                                                        showPageSizeSelector={true}
                                                        allowedPageSizes={this.tblPrenda_allowedPageSizes}
                                                        showInfo={true}
                                                    />
                                                    <Editing
                                                        refreshMode="repaint"
                                                        mode="cell"
                                                        allowUpdating={true}
                                                        allowAdding={false}
                                                        allowDeleting={false}
                                                        useIcons={true}
                                                    />
                                                    <KeyboardNavigation
                                                        enterKeyAction="moveFocus"
                                                        enterKeyDirection="row"
                                                        editOnKeyPress={true}
                                                    />
                                                    <Sorting mode="multiple" />
                                                    <Column
                                                        caption=" "
                                                        name="editar"
                                                        width={40}
                                                        alignment="center"
                                                        cssClass="p-0"
                                                        cellComponent={editCellComponent}
                                                        allowEditing={true}
                                                    />

                                                    <Column
                                                        dataField="denominacion"
                                                        caption={this.getTrad("denominacion")}
                                                        width={300}
                                                        sortOrder="asc"
                                                        sortIndex={1}
                                                        allowHeaderFiltering={false}
                                                        allowEditing={false}
                                                    />
                                                    <Column
                                                        dataField="tblTipoAlmacen.denominacion"
                                                        caption={this.getTrad("tipoAlmacen")}
                                                        allowEditing={false}
                                                    />
                                                    <Column
                                                        dataField="tblTipoHabitacion.denominacion"
                                                        caption={this.getTrad("tipoHabitacion")}
                                                        allowEditing={false}
                                                    />
                                                    <Column
                                                        dataField="activo"
                                                        caption={this.getTrad("activo")}
                                                        alignment={"center"}
                                                        dataType={"boolean"}
                                                        showEditorAlways={true}
                                                        cssClass="p-0"
                                                        width={130}
                                                        sortOrder={"desc"}
                                                        sortIndex={0}
                                                        allowFiltering={false}
                                                        allowHeaderFiltering={true}
                                                        headerFilter={this.headerFilter_filtroActivo}
                                                        editCellRender={this.editCellRender_activo_tblAlmacenes}
                                                    />
                                                    <Column
                                                        caption=" "
                                                        width={50}
                                                        alignment="center"
                                                        cssClass="p-0"
                                                        cellComponent={removeCellComponent}
                                                        allowEditing={false}
                                                    />
                                                </DataGrid>
                                            </TabPanelItem>
                                        </TabPanel>
                                    </TabPanelItem>
                                </TabPanel>
                            </ItemBox>
                        </Box>

                        <Popup
                            width={"80%"}
                            height={"85%"}
                            title={getTrad("seleccionarUbicacion")}
                            visible={isVisible_dxPopup_mapa}
                            onHiding={this.onHiding_dxPopup_mapa}
                        >
                            <div className="w-100 h-100">
                                <LocationMapMarker
                                    isVisible={isVisible_dxPopup_mapa}
                                    addressNoMod={addressNoMod}
                                    address={address}
                                    coordinates={coordinates}
                                    onChange={this.onChange_map}
                                />
                            </div>
                            <Button
                                type="success"
                                style={this.buttonAceptarStyle_map}
                                text={getTrad("aceptar")}
                                onClick={this.onClick_saveDireccion}
                            />
                        </Popup>

                        <Popup
                            width={"80%"}
                            height={"85%"}
                            title={this.getTrad("configuracion_entidad")}
                            visible={isVisible_dxPopup_configEntidad}
                            closeOnOutsideClick={false}
                            deferRendering={false}
                            onContentReady={this.onContentReady_dxPopup_configEntidad}
                            onShown={this.onShown_dxPopup_configEntidad}
                            onHiding={this.onHiding_dxPopup_configEntidad}
                            onHidden={this.onHidden_dxPopup_configEntidad}
                        >
                            <LoadPanel
                                deferRendering={false}
                                animation={null}
                                position={dxPopup_configEntidad}
                                visible={
                                    isVisible_loading_dxPopup_configEntidad && dxButtonGroup_prendasPedido_idSel == 1
                                }
                                shadingColor="transparent"
                                showIndicator={true}
                                shading={true}
                                showPane={true}
                            />
                            <ScrollView
                                showScrollbar="always"
                                ref={this.dxScrollView_dxForm_dxTab_REF}
                                onScroll={this.dxScrollView_dxForm_dxTab_onScroll}
                            >
                                <Form
                                    ref={this.dxForm_entidad_REF}
                                    elementAttr={this.elementAttr_dxForm_entidad}
                                    formData={entidadSel_popup}
                                    height="100%"
                                    labelLocation="top"
                                    colCount={1}
                                    validationGroup="dxForm_entidad_validationGroup"
                                    onFieldDataChanged={this.onFieldDataChanged_dxForm_entidad}
                                >
                                    <TabbedItem colSpan={5}>
                                        <TabPanelOptions
                                            height="100%"
                                            width="100%"
                                            activeStateEnabled={true}
                                            deferRendering={false}
                                            repaintChangesOnly={true}
                                            animationEnabled={true}
                                            swipeEnabled={false}
                                            elementAttr={this.elementAttr_dxTabPanel_Entidad}
                                            onContentReady={this.onContentReady_dxTabPanel_Entidad}
                                            itemTitleRender={this.itemTitleRender_dxTabPanel_Entidad}
                                            onSelectionChanged={this.onSelectionChanged_dxTabPanel_Entidad}
                                        />
                                        <Tab title={this.getTrad("general")} tabIndex={0} name="general">
                                            <Item
                                                itemType="group"
                                                caption={this.getTrad("general")}
                                                name="general"
                                                colCountByScreen={this.colCountByScreen}
                                                screenByWidth={this.screenByWidth()}
                                            >
                                                <Item dataField="idCompañia" label={this.formLabel_idCompañia}>
                                                    <DropDownBox
                                                        dataSource={
                                                            new ArrayStore({
                                                                key: "idCompañia",
                                                                data: this.state.arrayCompañias_dxDropDownBox_compañia,
                                                            })
                                                        }
                                                        value={entidadSel_popup ? entidadSel_popup.idCompañia : null}
                                                        displayExpr="denominacion"
                                                        valueExpr="idCompañia"
                                                        opened={this.state.isVisible_dxDropDownBox_idCompañiaAsociada}
                                                        dropDownOptions={this.dropDownOptions_dxDropDownBox_idCompañia}
                                                        contentRender={
                                                            this.contentRender_dxDropDownBox_idCompañia_popupEntidad
                                                        }
                                                        onOptionChanged={
                                                            this.onOptionChanged_dxDropDownBox_idCompañia_popupEntidad
                                                        }
                                                        onValueChanged={
                                                            this.onValueChanged_dxDropDownBox_idCompañia_popupEntidad
                                                        }
                                                    >
                                                        <Validator validationGroup="dxForm_entidad_validationGroup">
                                                            <RequiredRule />
                                                        </Validator>
                                                    </DropDownBox>
                                                </Item>
                                                <Item
                                                    dataField="denominacion"
                                                    label={this.formLabel_denominacion}
                                                    editorOptions={
                                                        this.editorOptions_dxDropDownBox_denominacion_popupEntidad
                                                    }
                                                    validationRules={this.validationRules_general_denominacion}
                                                />
                                                <Item
                                                    dataField="telefono"
                                                    label={this.formLabel_telefono}
                                                    editorOptions={this.editorOptions_telefono}
                                                    validationRules={this.validationRules_general_telefono}
                                                />
                                                <Item
                                                    dataField="email"
                                                    label={this.formLabel_email}
                                                    editorOptions={this.editorOptions_telefono}
                                                    validationRules={this.validationRules_general_email}
                                                />
                                                <Item
                                                    dataField="plazas"
                                                    label={this.formLabel_plazas}
                                                    editorType="dxNumberBox"
                                                    editorOptions={this.editorOptions_decimal}
                                                />
                                                <Item
                                                    dataField="habitaciones"
                                                    label={this.formLabel_habitaciones}
                                                    editorType="dxNumberBox"
                                                    editorOptions={this.editorOptions_decimal}
                                                />
                                                <Item
                                                    dataField="idMoneda"
                                                    name={this.getTrad("moneda")}
                                                    label={this.formLabel_moneda}
                                                    editorType="dxSelectBox"
                                                    editorOptions={this.editorOptions_idMoneda}
                                                    validationRules={this.validationRules_general_idMoneda}
                                                />
                                            </Item>
                                            <Item
                                                itemType="group"
                                                caption={getTrad("ubicacion")}
                                                name="general"
                                                colCountByScreen={this.colCountByScreen}
                                                screenByWidth={this.screenByWidth()}
                                            >
                                                <Item
                                                    visible={lavanderia.idCorporacion === 4}
                                                    cssClass={"d-flex justify-content-end"}
                                                    colSpan={4}
                                                >
                                                    {/* //* lavanderia.idCorporacion === 4 => POLARIER */}
                                                    <Button
                                                        type="default"
                                                        icon="map"
                                                        disabled={!isDepartamentoControl}
                                                        text={getTrad("seleccionarUbicacion")}
                                                        onClick={this.onClick_useMap}
                                                    />
                                                </Item>
                                                <Item
                                                    dataField="direccion"
                                                    colSpan={2}
                                                    label={this.formLabel_direccion}
                                                    editorOptions={this.editorOptions_disabled}
                                                />
                                                <Item
                                                    dataField="pais"
                                                    label={this.formLabel_pais}
                                                    editorOptions={this.editorOptions_disabled}
                                                />
                                                <Item
                                                    dataField="provincia"
                                                    label={this.formLabel_provincia}
                                                    editorOptions={this.editorOptions_disabled}
                                                />
                                                <Item
                                                    dataField="codigoPostal"
                                                    label={this.formLabel_codigoPostal}
                                                    editorOptions={this.editorOptions_disabled}
                                                />
                                                <Item
                                                    dataField="poblacion"
                                                    label={this.formLabel_poblacion}
                                                    editorOptions={this.editorOptions_disabled}
                                                />
                                                <Item
                                                    dataField="idLocalizacion"
                                                    label={this.formLabel_localizacion}
                                                    editorType="dxSelectBox"
                                                    editorOptions={this.editorOptions_idLocalizacion}
                                                    validationRules={this.validationRules_general_idLocalizacion}
                                                />
                                            </Item>
                                            <Item
                                                itemType="group"
                                                name="configuracion_adicional"
                                                caption={this.getTrad("configuracion_adicional")}
                                                colCountByScreen={this.colCountByScreen}
                                                screenByWidth={this.screenByWidth}
                                            >
                                                <Item
                                                    dataField="visibleGP"
                                                    label={this.formLabel_myProduction}
                                                    editorType="dxSwitch"
                                                    editorOptions={this.editorOptions_visibleGP}
                                                />
                                                <Item
                                                    dataField="idTipoRepartoEntidad"
                                                    label={this.formLabel_tipoReparto}
                                                    editorType="dxSelectBox"
                                                    editorOptions={this.editorOptions_idTipoRepartoEntidad}
                                                    validationRules={
                                                        this.validationRules_configAdicional_idTipoRepartoEntidad
                                                    }
                                                />
                                                <Item
                                                    dataField="idTipoConsumoLenceria"
                                                    label={this.formLabel_tipoInfConsumoLenceria}
                                                    editorType="dxSelectBox"
                                                    editorOptions={this.editorOptions_idTipoConsumoLenceria}
                                                />
                                                <Item
                                                    dataField="reparteAlmGeneral"
                                                    label={this.formLabel_reparteAlmaGen}
                                                    editorType="dxSwitch"
                                                    editorOptions={this.editorOptions_reparteAlmGeneral}
                                                />
                                                <Item
                                                    dataField="reparteRechazoRetiro"
                                                    label={this.formLabel_reparteRechazoRetiro}
                                                    editorType="dxSelectBox"
                                                    editorOptions={this.editorOptions_reparteRechazoRetiro}
                                                />
                                                <Item
                                                    dataField="horarioReparto"
                                                    label={this.formLabel_horarioReparto}
                                                    render={this.tblhorarioRepartoNEntidad_render}
                                                >
                                                    <EmptyItem colSpan={2} />
                                                    <Item
                                                        dataField="gestionCorreosAlbaranes"
                                                        label={this.formLabel_gestionCorreosAlbaranes}
                                                        colSpan={2}
                                                    >
                                                        <DataGrid
                                                            //Datos
                                                            ref={this.dxDataGrid_gestionCorreosAlbaranes_REF}
                                                            elementAttr={
                                                                this.dxDataGrid_gestionCorreosAlbaranes_elementAttr
                                                            }
                                                            dataSource={
                                                                new DataSource({
                                                                    store: new ArrayStore({
                                                                        data: entidadSel_popup
                                                                            ? entidadSel_popup.tblCorreosNEntidad
                                                                            : [],
                                                                    }),
                                                                    postProcess: function (data) {
                                                                        let filterItems = [];
                                                                        $.each(data, function (index, item) {
                                                                            if (item.tblReports.length > 0)
                                                                                filterItems.push(item);
                                                                        });
                                                                        return filterItems;
                                                                    },
                                                                })
                                                            }
                                                            remoteOperations={false}
                                                            repaintChangesOnly={true}
                                                            //Propiedades
                                                            columnsAutoWidth={true}
                                                            width={"100%"}
                                                            height={230}
                                                            hoverStateEnabled={false}
                                                            //Estilos
                                                            showColumnLines={false}
                                                            showRowLines={true}
                                                            rowAlternationEnabled={true}
                                                            onContentReady={
                                                                this.onContentReady_dxDataGrid_gestionCorreosAlbaranes
                                                            }
                                                            onRowPrepared={
                                                                this.onRowPrepared_dxDataGrid_gestionCorreosAlbaranes
                                                            }
                                                            onRowUpdating={
                                                                this.onRowUpdating_dxDataGrid_gestionCorreosAlbaranes
                                                            }
                                                            onRowInserting={
                                                                this.onRowInserting_dxDataGrid_gestionCorreosAlbaranes
                                                            }
                                                            onEditingStart={
                                                                this.onEditingStart_dxDataGrid_gestionCorreosAlbaranes
                                                            }
                                                            onToolbarPreparing={
                                                                this
                                                                    .onToolbarPreparing_dxDataGrid_gestionCorreosAlbaranes
                                                            }
                                                        >
                                                            <KeyboardNavigation
                                                                enterKeyAction="moveFocus"
                                                                enterKeyDirection="row"
                                                                editOnKeyPress={true}
                                                            />
                                                            <HeaderFilter visible={false} />
                                                            <FilterRow visible={false} applyFilter="auto" />
                                                            <Paging pageSize={50} />
                                                            <Pager
                                                                showPageSizeSelector={true}
                                                                allowedPageSizes={[20, 50, 100]}
                                                                showInfo={true}
                                                            />
                                                            <Editing
                                                                refreshMode="repaint"
                                                                mode="cell"
                                                                allowUpdating={true}
                                                                allowAdding={false}
                                                                allowDeleting={false}
                                                                useIcons={true}
                                                            />
                                                            <Scrolling showScrollbar="always" />
                                                            <Column
                                                                dataField="denominacion"
                                                                name={this.getTrad("gestionCorreosAlb")}
                                                                caption={this.getTrad("email")}
                                                                cssClass="dx-Cell_Editable"
                                                                editorOptions={this.editorOptions_maxLength_50}
                                                            >
                                                                <RequiredRule
                                                                    message={this.getTrad("campoNecesario")}
                                                                />
                                                                <EmailRule
                                                                    message={this.getTrad("validation_Formato")}
                                                                />
                                                                <CustomRule
                                                                    reevaluate={true}
                                                                    validationCallback={
                                                                        this
                                                                            .validationCallback_CustomRule_emailCorreosAlb
                                                                    }
                                                                />
                                                            </Column>
                                                            <Column
                                                                dataField="tblReports"
                                                                caption={this.getTrad("tipoInforme")}
                                                                cssClass="dx-Cell_Editable"
                                                                cellRender={this.tipoInforme_gestionCorreos_render}
                                                                editCellRender={
                                                                    this.tipoInforme_gestionCorreos_editCellRender
                                                                }
                                                            />
                                                            <Column
                                                                caption=" "
                                                                width={50}
                                                                alignment="center"
                                                                cssClass="p-0"
                                                                cellComponent={removeCellComponent}
                                                                allowEditing={false}
                                                            />
                                                        </DataGrid>
                                                    </Item>
                                                </Item>
                                            </Item>
                                            <Item
                                                itemType="group"
                                                cssClass="mb-4"
                                                caption={this.getTrad("configuracion_facturacion")}
                                                colCountByScreen={this.colCountByScreen}
                                                screenByWidth={this.screenByWidth}
                                            >
                                                <Item
                                                    dataField="costeEstancia"
                                                    label={this.formLabel_costeEstancia}
                                                    editorType="dxNumberBox"
                                                    editorOptions={this.editorOptions_costeEstancia}
                                                    validationRules={
                                                        entidadSel_popup &&
                                                            entidadSel_popup.idTipoFacturacionCliente == 1
                                                            ? null
                                                            : this.validationRules_configAdicional_costeEstancia
                                                    }
                                                />
                                                <Item
                                                    dataField="objKgEstancia"
                                                    label={this.formLabel_objetivoKgEstancia}
                                                    editorType="dxNumberBox"
                                                    editorOptions={this.editorOptions_objetivoKgEstancia}
                                                    validationRules={
                                                        entidadSel_popup &&
                                                            entidadSel_popup.idTipoFacturacionCliente == 1
                                                            ? null
                                                            : this.validationRules_configAdicional_objetivoKgEstancia
                                                    }
                                                />
                                                <Item
                                                    cssClass={"he-100 d-flex justify-content-center align-items-end"}
                                                    colSpan={4}
                                                >
                                                    <Button
                                                        type="default"
                                                        icon="edit"
                                                        text={getTrad("actualizarTipoFacturacion")}
                                                        onClick={this.onClick_actualizarTipoFacturacion}
                                                    />
                                                </Item>
                                            </Item>
                                        </Tab>
                                        <Tab title={this.getTrad("inventarios")} tabIndex={1}>
                                            <Item
                                                dataField="inventarioPorEntidad"
                                                label={this.formLabel_inventarioPorEntidad}
                                                editorType="dxSwitch"
                                                editorOptions={this.editorOptions_inventarioPorEntidad}
                                            />
                                            <Item dataField="tblTipoHabitacion" label={this.formLabel_tipoHab}>
                                                <Box
                                                    direction="row"
                                                    align="space-around"
                                                    crossAlign="stretch"
                                                    height="100%"
                                                    width="100%"
                                                >
                                                    <ItemBox ratio={1}>
                                                        <Box
                                                            direction="col"
                                                            align="space-around"
                                                            crossAlign="stretch"
                                                            width="100%"
                                                            height="100%"
                                                        >
                                                            <ItemBox baseSize={45}>
                                                                <Toolbar height="100%">
                                                                    <ToolbarItem location="before">
                                                                        <Button
                                                                            text={this.getTrad("tipoHabitacion")}
                                                                            icon="plus"
                                                                            onClick={this.onClick_tipoHabitacion}
                                                                        />
                                                                    </ToolbarItem>
                                                                </Toolbar>
                                                            </ItemBox>
                                                            <ItemBox ratio={1}>
                                                                <List
                                                                    ref={this.dxList_tblTipoHabitacion_REF}
                                                                    dataSource={
                                                                        entidadSel_popup
                                                                            ? entidadSel_popup["tblTipoHabitacion"]
                                                                            : []
                                                                    }
                                                                    selectedItemKeys={
                                                                        this.state
                                                                            .dxList_tblTipoHabitacion_selectedItemKeys
                                                                    }
                                                                    selectionMode={"single"}
                                                                    height={"100%"}
                                                                    keyExpr={"idTipoHabitacion"}
                                                                    pageLoadMode={"scrollBottom"}
                                                                    itemRender={
                                                                        this.itemRender_dxList_tblTipoHabitacion
                                                                    }
                                                                    onSelectionChanged={
                                                                        this.onSelectionChanged_dxList_tblTipoHabitacion
                                                                    }
                                                                ></List>
                                                            </ItemBox>
                                                        </Box>
                                                    </ItemBox>
                                                    <ItemBox baseSize={20}></ItemBox>
                                                    <ItemBox ratio={2}>
                                                        <DataGrid
                                                            //Datos
                                                            ref={this.dxDataGrid_tblPrendaNTipoHabitacion_REF}
                                                            dataSource={
                                                                this.state
                                                                    .datasource_dxDataGrid_tblPrendaNTipoHabitacion
                                                            }
                                                            remoteOperations={false}
                                                            //Propiedades
                                                            columnsAutoWidth={true}
                                                            repaintChangesOnly={true}
                                                            height={375}
                                                            //Estilos
                                                            showColumnLines={false}
                                                            showRowLines={true}
                                                            rowAlternationEnabled={true}
                                                            onToolbarPreparing={
                                                                this
                                                                    .onToolbarPreparing_dxDataGrid_tblPrendaNTipoHabitacion
                                                            }
                                                        >
                                                            <Editing
                                                                refreshMode={"repaint"}
                                                                mode={"cell"}
                                                                allowUpdating={true}
                                                                allowDeleting={false}
                                                                useIcons={true}
                                                                selectTextOnEditStart={true}
                                                            />
                                                            <KeyboardNavigation
                                                                enterKeyAction="moveFocus"
                                                                enterKeyDirection="row"
                                                                editOnKeyPress={true}
                                                            />
                                                            <Paging enabled={false} />
                                                            <Column
                                                                dataField="tblPrenda.codigoPrenda"
                                                                caption={this.getTrad("codigo")}
                                                                allowEditing={false}
                                                                alignment="center"
                                                                width={120}
                                                                sortOrder="asc"
                                                            />

                                                            <Column
                                                                dataField="tblPrenda.denominacion"
                                                                caption={this.getTrad("denominacion")}
                                                                minWidth={300}
                                                                allowEditing={false}
                                                            />
                                                            <Column
                                                                dataField="parStock"
                                                                caption={this.getTrad("parStock")}
                                                                cssClass="dx-Cell_Editable"
                                                                alignment="center"
                                                                width={120}
                                                                format={this.format_parStock}
                                                                editorOptions={this.editorOptions_parStock}
                                                            />
                                                            <Column
                                                                caption={" "}
                                                                width={"40px"}
                                                                alignment={"center"}
                                                                allowFixing={false}
                                                                allowHiding={false}
                                                                allowReordering={false}
                                                                cssClass={"p-0"}
                                                                cellComponent={removeCellComponent}
                                                                allowEditing={false}
                                                            />
                                                        </DataGrid>
                                                    </ItemBox>
                                                </Box>
                                            </Item>
                                        </Tab>
                                        <Tab
                                            tabIndex={2}
                                            title={this.getTrad("pedidos")}
                                            name="tblPrendaNEntidad_NuevoPedido"
                                        >
                                            <Item
                                                itemType="group"
                                                name="pedidos"
                                                colCountByScreen={this.colCountByScreen_pedidos}
                                                screenByWidth={this.screenByWidth()}
                                                alignment={"right"}
                                            >
                                                <Item
                                                    dataField="tblTipoPedidoNEntidad"
                                                    label={this.formLabel_pedidosPermitidos}
                                                    colSpan={3}
                                                    validationRules={this.validationRules_pedidos_tblTipoPedidoNEntidad}
                                                >
                                                    <TagBox
                                                        ref={this.dxTagBox_tblTipoPedidoNEntidad_REF}
                                                        value={
                                                            entidadSel_popup &&
                                                                entidadSel_popup.tblTipoPedidoNEntidad[0]
                                                                ? $.map(
                                                                    entidadSel_popup.tblTipoPedidoNEntidad,
                                                                    function (val, key) {
                                                                        return val.idTipoPedido;
                                                                    }
                                                                )
                                                                : null
                                                        }
                                                        items={this.state.items_datos_tblTipoPedido}
                                                        displayExpr="denominacion"
                                                        valueExpr="idTipoPedido"
                                                        applyValueMode="useButtons"
                                                        showSelectionControls={true}
                                                        multiline={false}
                                                        buttons={[
                                                            {
                                                                name: "addTipoPedido",
                                                                location: "after",
                                                                options: this.tblTipoPedidoNEntidad_TagBoxButtons,
                                                            },
                                                        ]}
                                                        onValueChanged={
                                                            this.dxTagBox_tblTipoPedidoNEntidad_onValueChanged
                                                        }
                                                    >
                                                        <Validator
                                                            elementAttr={this.dxValidator_tagBox_tipoPedido_elementAttr}
                                                            validationGroup="dxForm_entidad_validationGroup"
                                                        >
                                                            <RequiredRule />
                                                        </Validator>
                                                    </TagBox>
                                                </Item>
                                                <Item>
                                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                                        <Button
                                                            type="default"
                                                            style={{ marginTop: 25, color: "white" }}
                                                            text={this.getTrad("sacasPorRepartir")}
                                                            onClick={this.onClick_sacasPendientes}
                                                        />
                                                    </div>
                                                </Item>
                                                <Item
                                                    dataField="enableObservacionesPedido"
                                                    label={this.label_enableObservacionesPedido}
                                                    editorType={"dxSwitch"}
                                                    editorOptions={this.editorOptions_enableObservacionesPedido}
                                                />
                                                <Item
                                                    dataField="enablePlanificadorPedidos"
                                                    label={this.label_planificadorPedidos}
                                                    editorType={"dxSwitch"}
                                                    editorOptions={this.editorOptions_planificadorPedidos}
                                                />
                                            </Item>
                                            <Item
                                                dataField="tblPrendaNEntidad_NuevoPedido"
                                                label={this.formLabel_prendasPedido}
                                                render={this.render_tblPrendaNEntidad_NuevoPedido}
                                            />
                                        </Tab>
                                        <Tab
                                            tabIndex={3}
                                            title={this.getTrad("entidadesAsociadas")}
                                            name="tblEntidadNEntidadAsociada"
                                            repaintChangesOnly={true}
                                            disabled={this.state.isDisabled_dxForm_entidad_tblEntidadNEntidadAsociada}
                                        >
                                            <Item
                                                dataField="tblEntidadNEntidadAsociada"
                                                label={this.formLabel_tipoEntidad}
                                            >
                                                <Box
                                                    direction="col"
                                                    align="space-around"
                                                    crossAlign="stretch"
                                                    width="100%"
                                                    height={450}
                                                >
                                                    <ItemBox baseSize={45}>
                                                        <RadioGroup
                                                            dataSource={this.enum_tipoEntidad}
                                                            layout="horizontal"
                                                            valueExpr="value"
                                                            displayExpr="denominacion"
                                                            height="100%"
                                                            width={300}
                                                            value={
                                                                entidadSel_popup ? entidadSel_popup.isPrincipal : null
                                                            }
                                                            onContentReady={this.onContentReady_tipoEntidad}
                                                            onValueChanged={this.onValueChanged_tipoEntidad}
                                                        />
                                                    </ItemBox>
                                                    <ItemBox baseSize={20}></ItemBox>
                                                    <ItemBox baseSize={30}>
                                                        <div id="texto_tipoEntidad">
                                                            {entidadSel_popup && entidadSel_popup.isPrincipal
                                                                ? "Entidades secundarias:"
                                                                : "Entidades primarias:"}
                                                        </div>
                                                    </ItemBox>
                                                    <ItemBox ratio={1}>
                                                        <DataGrid
                                                            //Datos
                                                            elementAttr={
                                                                this.elementAttr_dxDataGrid_tblEntidadesAsociadas
                                                            }
                                                            dataSource={
                                                                new DataSource({
                                                                    store: this.state
                                                                        .datasource_dxDataGrid_tblEntidadesAsociadas,
                                                                })
                                                            }
                                                            remoteOperations={false}
                                                            //Propiedades
                                                            height={"100%"}
                                                            repaintChangesOnly={true}
                                                            width={317}
                                                            //Estilos
                                                            showColumnLines={false}
                                                            showRowLines={true}
                                                            rowAlternationEnabled={true}
                                                            onInitialized={this.onInitialized_entidadesAsociadas}
                                                            onToolbarPreparing={
                                                                this.onToolbarPreparing_entidadesAsociadas
                                                            }
                                                        >
                                                            <FilterRow visible={true} />
                                                            <Paging enabled={false} />
                                                            <Grid_LoadPanel enabled={false} />
                                                            <Column
                                                                dataField="denominacion"
                                                                caption={this.getTrad("denominacion")}
                                                                sortOrder="asc"
                                                            />
                                                            <Column
                                                                caption=" "
                                                                width={40}
                                                                alignment="center"
                                                                cssClass="p-0"
                                                                cellComponent={removeCellComponent}
                                                            />
                                                        </DataGrid>
                                                    </ItemBox>
                                                </Box>
                                            </Item>
                                        </Tab>
                                        <Tab title={this.getTrad("calendario")} name="calendario" tabIndex={4}>
                                            <YearCalendar
                                                data={entidadSel_popup ? entidadSel_popup.tblCalendarioEntidad : []}
                                                estados={this.state.datasource_datos_tblCalendarioEntidad_Estados}
                                                currentYear={yearCalendar_currentYear}
                                                tipoCalendario="calendarioEntidad"
                                                yearCalendar_onDateChanged={this.yearCalendar_onDateChanged}
                                            />
                                        </Tab>
                                    </TabbedItem>
                                </Form>
                            </ScrollView>
                            <ToolbarItem_popup
                                text={
                                    entidadSel_popup == null || entidadSel_popup.denominacion == null
                                        ? this.getTrad("nuevaEntidad")
                                        : entidadSel_popup.denominacion != null
                                            ? entidadSel_popup.denominacion
                                            : ""
                                }
                                location="center"
                                toolbar="top"
                            ></ToolbarItem_popup>
                            <ToolbarItem_popup widget="dxButton" location="after" toolbar="bottom">
                                <Button text={this.getTrad("guardar")} onClick={this.onClick_PopupEntidad_guardar} />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup widget="dxButton" location="after" toolbar="bottom">
                                <Button text={this.getTrad("cancelar")} onClick={this.onClick_PopupEntidad_cancelar} />
                            </ToolbarItem_popup>
                        </Popup>

                        <Popup
                            width={"30%"}
                            height={"75%"}
                            title={this.getTrad("sacasPorRepartir")}
                            visible={isVisible_dxPopup_sacasPendientes}
                            onHiding={this.onHidden_dxPopup_sacasPendientes}
                            onShown={this.onShown_dxPopup_sacasPendientes}
                        >
                            <DataGrid
                                ref={this.dxDataGrid_tblSacasPendientes_REF}
                                dataSource={
                                    new ArrayStore({
                                        data: entidadSel_popup ? entidadSel_popup.tblSacasPendientes : null,
                                    })
                                }
                                height={"100%"}
                                columnsAutoWidth={true}
                                repaintChangesOnly={true}
                                showColumnLines={false}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                            >
                                <Editing
                                    refreshMode={"repaint"}
                                    mode={"cell"}
                                    allowUpdating={true}
                                    useIcons={true}
                                    selectTextOnEditStart={true}
                                />
                                <KeyboardNavigation
                                    enterKeyAction="moveFocus"
                                    enterKeyDirection="column"
                                    editOnKeyPress={true}
                                />
                                <Paging enabled={false} />
                                <Column
                                    dataField="cantidad"
                                    allowEditing={true}
                                    caption={this.getTrad("cantidadPendiente")}
                                    cssClass="dx-Cell_Editable"
                                    alignment={"center"}
                                    sortOrder={"desc"}
                                    editorType={"dxNumberBox"}
                                    editorOptions={this.editorOptions_cantidadPendiente}
                                    format={this.format_decimal_maxCero}
                                    allowHeaderFiltering={false}
                                    cellRender={this.dxDataGrid_sacasPendientes_cantidadPendiente_render}
                                >
                                    <CustomRule
                                        reevaluate={true}
                                        ignoreEmptyValue={true}
                                        validationCallback={this.validationCallback_CustomRule_cantidadPendiente}
                                    />
                                </Column>
                                <Column
                                    dataField="tblColorTapa"
                                    caption={this.getTrad("elemPedido")}
                                    alignment={"center"}
                                    allowHeaderFiltering={false}
                                    allowEditing={false}
                                    cellRender={this.cellRender_tblSacasPendientes_idColorTapaNavigation}
                                />
                            </DataGrid>
                            <ToolbarItem_popup widget="dxButton" location="after" toolbar="bottom">
                                <Button
                                    text={this.getTrad("guardar")}
                                    onClick={this.onClick_PopupSacasPendientes_guardar}
                                />
                            </ToolbarItem_popup>
                        </Popup>

                        <Popup
                            ref={this.dxPopup_addTipoHabitacion_REF}
                            width={350}
                            height={270}
                            title={this.getTrad("nuevoTipoHabitacion")}
                            visible={isVisible_dxPopup_addTipoHabitacion}
                            onShown={this.onShown_addTipoHabitacion}
                            onHiding={this.onHiding_addTipoHabitacion}
                        >
                            <Form
                                ref={this.dxForm_addTipoHabitacion_REF}
                                formData={this.state.dxList_tipoHabitacionSel}
                                labelLocation="top"
                                height="100%"
                                scrollingEnabled={true}
                                colCount={1}
                            >
                                <Item
                                    dataField="denominacion"
                                    label={this.formLabel_denominacion}
                                    editorOptions={this.editorOptions_denominacion_addTipoHabitacion}
                                    validationRules={this.validationRules_general_denominacion}
                                />
                            </Form>
                            <ToolbarItem_popup
                                render={this.render_dxButton_guardar_addTipoHabitacion}
                                location="after"
                                toolbar="bottom"
                            />

                            <ToolbarItem_popup
                                render={this.render_dxButton_cancelar_addTipoHabitacion}
                                location="after"
                                toolbar="bottom"
                            />
                        </Popup>

                        <Popup
                            width={800}
                            height={500}
                            title={this.getTrad("prendasTipoHabitacion")}
                            visible={isVisible_dxPopup_tblPrendaNTipoHabitacion}
                            onShowing={this.onShowing_prendasTipoHabitacion}
                            onHiding={this.onHiding_prendasTipoHabitacion}
                        >
                            <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("prendasCompañia")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_prendasCompañia_REF}
                                                dataSource={datasource_tblPrendaNEntidad_compañia}
                                                searchEnabled={true}
                                                searchExpr={this.searchExpr_codigoDenoPrenda}
                                                focusStateEnabled={false}
                                                selectionMode="all"
                                                showSelectionControls={true}
                                                height="100%"
                                                keyExpr="idPrenda"
                                                pageLoadMode="scrollBottom"
                                                itemRender={this.itemRender_dxList_prendasCodigo}
                                                selectedItems={this.state.selectedItems_dxList_prendasCompañia}
                                                onOptionChanged={this.dxList_prendasCompañia_onOptionChanged}
                                            ></List>
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox baseSize={20}></ItemBox>
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("prendasEntidad")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_prendasEntidad_REF}
                                                dataSource={this.state.datasource_tblPrendaNEntidad}
                                                searchEnabled={true}
                                                searchExpr={this.searchExpr_codigoDenoPrenda}
                                                focusStateEnabled={false}
                                                selectionMode="all"
                                                showSelectionControls={true}
                                                height="100%"
                                                keyExpr="idPrenda"
                                                pageLoadMode="scrollBottom"
                                                itemRender={this.itemRender_dxList_prendasCodigo}
                                                selectedItems={this.state.selectedItems_dxList_prendasEntidad}
                                                onOptionChanged={this.dxList_prendasEntidad_onOptionChanged}
                                            ></List>
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                            </Box>
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.dxButton_guardar_tblPrendaNTipoHabitacion}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.dxButton_cancelar_tblPrendaNTipoHabitacion}
                            />
                        </Popup>

                        <Popup
                            width={500}
                            height={500}
                            title={this.getTrad("prendasPedido")}
                            visible={isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia}
                            onShowing={this.onShowing_tblPrendaNEntidad_NuevoPedido}
                            onHiding={this.onHiding_tblPrendaNEntidad_NuevoPedido}
                        >
                            <List
                                ref={this.dxList_tblPrendaNEntidad_NuevoPedido_compañia_REF}
                                items={this.state.datasource_tblPrendaNEntidad_compañia}
                                searchEnabled={true}
                                searchExpr={this.searchExpr_codigoDenoPrenda}
                                focusStateEnabled={false}
                                selectionMode="all"
                                showSelectionControls={true}
                                height="100%"
                                keyExpr="idPrenda"
                                pageLoadMode="scrollBottom"
                                itemRender={this.itemRender_dxList_prendasCodigo}
                                selectedItems={
                                    this.state.selectedItems_tblPrendaNEntidad_NuevoPedido_compañia_datasource
                                }
                                onOptionChanged={this.tblPrendaNEntidad_NuevoPedido_compañia_datasource_onOptionChanged}
                            ></List>
                            <ToolbarItem_popup toolbar="bottom" location="after">
                                <Button
                                    text={this.getTrad("guardar")}
                                    onClick={this.onClick_guardar_tblPrendaNEntidad_NuevoPedido}
                                />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup toolbar="bottom" location="after">
                                <Button
                                    text={this.getTrad("cancelar")}
                                    onClick={this.onClick_cancelar_tblPrendaNEntidad_NuevoPedido}
                                />
                            </ToolbarItem_popup>
                        </Popup>

                        <Popup
                            width={500}
                            height={500}
                            title={this.getTrad("prendasPedido")}
                            visible={isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad}
                            onShowing={this.onShowing_tblPrendaNEntidad_NuevoPedido}
                            onHiding={this.onHiding_tblPrendaNEntidad_NuevoPedido}
                        >
                            <List
                                ref={this.dxList_tblPrendaNEntidad_NuevoPedido_entidad_REF}
                                items={this.state.datasource_tblPrendaNEntidad}
                                searchEnabled={true}
                                searchExpr={this.searchExpr_codigoDenoPrenda}
                                focusStateEnabled={false}
                                selectionMode="all"
                                showSelectionControls={true}
                                height="100%"
                                keyExpr="idPrenda"
                                pageLoadMode="scrollBottom"
                                itemRender={this.itemRender_dxList_prendasCodigo}
                                selectedItems={
                                    this.state.selectedItems_tblPrendaNEntidad_NuevoPedido_entidad_datasource
                                }
                                onOptionChanged={this.tblPrendaNEntidad_NuevoPedido_entidad_datasource_onOptionChanged}
                            ></List>
                            <ToolbarItem_popup toolbar="bottom" location="after">
                                <Button
                                    text={this.getTrad("guardar")}
                                    onClick={this.onClick_guardar_tblPrendaNEntidad_NuevoPedido}
                                />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup toolbar="bottom" location="after">
                                <Button
                                    text={this.getTrad("cancelar")}
                                    onClick={this.onClick_cancelar_tblPrendaNEntidad_NuevoPedido}
                                />
                            </ToolbarItem_popup>
                        </Popup>

                        <Popup
                            width={800}
                            height={500}
                            title={this.getTrad("prendasPedido")}
                            visible={isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario}
                            onShowing={this.onShowing_tblPrendaNEntidad_NuevoPedido}
                            onHiding={this.onHiding_tblPrendaNEntidad_NuevoPedido}
                        >
                            <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("prendasCompañia")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_compañia_REF}
                                                items={dxList_prendaNCompañia_usuario_dataSource}
                                                searchEnabled={true}
                                                searchExpr={this.searchExpr_codigoDenoPrenda}
                                                focusStateEnabled={false}
                                                selectionMode="all"
                                                showSelectionControls={true}
                                                height="100%"
                                                keyExpr="idPrenda"
                                                pageLoadMode="scrollBottom"
                                                tipoLista="prendasCompañia"
                                                itemRender={this.itemRender_dxList_prendasCodigo}
                                                selectedItems={
                                                    selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource
                                                }
                                                onOptionChanged={
                                                    this
                                                        .tblPrendaNEntidad_NuevoPedido_usuario_datasource_onOptionChanged
                                                }
                                            ></List>
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox baseSize={20}></ItemBox>
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("prendasEntidad")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_entidad_REF}
                                                items={dxList_prendaNEntidad_usuario_dataSource}
                                                searchEnabled={true}
                                                searchExpr={this.searchExpr_codigoDenoPrenda}
                                                focusStateEnabled={false}
                                                selectionMode="all"
                                                showSelectionControls={true}
                                                height="100%"
                                                keyExpr="idPrenda"
                                                pageLoadMode="scrollBottom"
                                                tipoLista="prendasEntidad"
                                                itemRender={this.itemRender_dxList_prendasCodigo}
                                                selectedItems={
                                                    selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource
                                                }
                                                onOptionChanged={
                                                    this
                                                        .tblPrendaNEntidad_NuevoPedido_usuario_datasource_onOptionChanged
                                                }
                                            ></List>
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                            </Box>
                            <ToolbarItem_popup toolbar="bottom" location="after">
                                <Button
                                    text={this.getTrad("guardar")}
                                    onClick={this.onClick_guardar_tblPrendaNEntidad_NuevoPedido}
                                />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup toolbar="bottom" location="after">
                                <Button
                                    text={this.getTrad("cancelar")}
                                    onClick={this.onClick_cancelar_tblPrendaNEntidad_NuevoPedido}
                                />
                            </ToolbarItem_popup>
                        </Popup>

                        <Popup
                            width={600}
                            height={500}
                            title={this.getTrad("añadirEntidadesAsociadas")}
                            visible={isVisible_dxPopup_tblEntidadNEntidadAsociada}
                            onShowing={this.onShowing_tblEntidadNEntidadAsociada}
                            onHiding={this.onHiding_tblEntidadNEntidadAsociada}
                        >
                            <List
                                ref={this.dxList_entidadesAsociada_REF}
                                dataSource={this.state.datasource_dxList_entidadesAsociada}
                                searchEnabled={true}
                                searchExpr="denominacion"
                                displayExpr={"denominacion"}
                                selectionMode={"all"}
                                height={"100%"}
                                showSelectionControls={true}
                                focusStateEnabled={false}
                                scrolling={this.dxList_scrolling_infinite}
                                pageLoadMode={"scrollBottom"}
                                keyExpr={"idEntidad"}
                                selectedItems={this.state.selectedItems_dxList_entidadesAsociada}
                                onOptionChanged={this.dxList_entidadesAsociada_onOptionChanged}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_guardar_tblEntidadNEntidadAsociada}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_cancelar_tblEntidadNEntidadAsociada}
                            />
                        </Popup>

                        <Popup
                            width={350}
                            height={320}
                            showTitle={true}
                            visible={isVisible_dxPopup_compañia}
                            onShowing={this.onShowing_dxPopup_compañia}
                            onShown={this.onShown_dxPopup_compañia}
                            onHiding={this.onHiding_dxPopup_compañia}
                            onHidden={this.onHidden_dxPopup_compañia}
                        >
                            <Form
                                ref={this.dxForm_compañia_REF}
                                labelLocation="top"
                                height="100%"
                                scrollingEnabled={true}
                                colCount={1}
                            >
                                <Item
                                    dataField="denominacion"
                                    label={this.formLabel_denominacion}
                                    editorOptions={this.editorOptions_denominacion_popupCompañia}
                                    validationRules={this.validationRules_general_denominacion}
                                ></Item>
                                <Item
                                    dataField="visibleGP"
                                    label={this.formLabel_myProduction}
                                    editorType="dxSwitch"
                                    editorOptions={this.editorOptions_visibleGP}
                                ></Item>
                            </Form>
                            <ToolbarItem_popup
                                toolbar="top"
                                location="center"
                                html="<span id='dxPopup_compañia_title'></span>"
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_guardar_dxPopup_compañia}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_cancelar_dxPopup_compañia}
                            />
                        </Popup>

                        <Popup
                            width="80%"
                            height="80%"
                            title={this.getTrad("configuracion_prenda")}
                            showTitle={true}
                            visible={isVisible_dxPopup_prendas}
                            onHiding={this.onHiding_dxPopup_prenda}
                        >
                            <Form
                                ref={this.dxForm_prenda_REF}
                                labelLocation="top"
                                height="100%"
                                scrollingEnabled={true}
                                colCount={1}
                                formData={prendaSel}
                                validationGroup="dxForm_prenda_validationGroup"
                                onContentReady={this.dxForm_prenda_onContentReady}
                            >
                                <Item
                                    itemType="group"
                                    caption={this.getTrad("general")}
                                    name="general"
                                    colCountByScreen={this.colCountByScreen_configPrenda_general}
                                    screenByWidth={this.screenByWidth()}
                                >
                                    <Item itemType="group" colSpan={1}>
                                        <Item dataField="codigoPrenda" label={this.formLabel_codigo} disabled={true} />
                                        <Item
                                            dataField="activo"
                                            label={this.formLabel_activo}
                                            editorType="dxSwitch"
                                            editorOptions={this.activo_dxFormPrenda_editorOptions}
                                        />
                                    </Item>
                                    <Item
                                        dataField="denominacion"
                                        label={this.formLabel_denominacion}
                                        colSpan={5}
                                        validationRules={this.validationRules_general_denominacion_noReq}
                                    >
                                        <TextBox
                                            value={prendaSel ? prendaSel.denominacion : ""}
                                            onValueChanged={this.dxForm_prenda_denominacion_onValueChanged}
                                        >
                                            <TextBoxButton
                                                name="tipoPrenda"
                                                location="before"
                                                options={{
                                                    text: prendaSel != null ? prendaSel.denoTipoPrenda : null,
                                                    elementAttr: { class: "no-disabled" },
                                                    stylingMode: "outlined",
                                                    disabled: !allowEdit,
                                                    onClick: this.onClick_tipoPrenda_dxPpopup_prenda,
                                                }}
                                                onValueChanged={this.onValueChanged_replaceUpperCase_denomiancion}
                                            />
                                        </TextBox>
                                    </Item>
                                    <EmptyItem />
                                    <Item dataField={this.getTrad("fichaTecnica")} colSpan={1} cssClass="he-100">
                                        <AdjuntarArchivos
                                            acceptedTypes={".pdf"}
                                            allowedFileExtensions={[]}
                                            isRemoveAllowed={true}
                                            elementAttr={this.AdjuntarArchivos_elementAttr}
                                            data={
                                                this.state.prendaSel != null
                                                    ? $.extend(true, [], this.state.prendaSel.tblDocumentoPrenda)
                                                    : []
                                            }
                                            idPadreSel={null}
                                            tipoArchivos="fotosPequeñas"
                                            isMultipleLoadFile={false}
                                            isVisibleRequerirDocumento={false}
                                            documentosNGrandParent={null}
                                            isDropZone={false}
                                            tablaCargaDocumentos="tblDocumentoPrenda"
                                            archivoUnico={true}
                                            accionesModificarDatos={accionesModificarDatos_fileUploader}
                                            numItemPerRow={1}
                                            tieneCabecera={false}
                                            showLoading_adjuntarArchivos={this.state.showLoading_adjuntarArchivos}
                                            updateArchivos={this.updateArchivos_documentos}
                                        />
                                    </Item>
                                </Item>
                                <Item
                                    itemType="group"
                                    caption={this.getTrad("transporte")}
                                    name="transporte"
                                    colCountByScreen={this.colCountByScreen}
                                    screenByWidth={this.screenByWidth()}
                                >
                                    <Item
                                        dataField="elementoReparto"
                                        label={this.formLabel_elemReparto}
                                        editorType="dxSelectBox"
                                        editorOptions={this.elemTrans_editorOptions}
                                    />
                                    <Item
                                        dataField="udsXBacReparto"
                                        label={this.formLabel_udsElemReparto}
                                        editorType="dxNumberBox"
                                        editorOptions={this.dxNumberBox_udsPrenda_editorOptions}
                                    />
                                    <Item
                                        dataField="elementoOffice"
                                        label={this.formLabel_elemOffice}
                                        editorType="dxSelectBox"
                                        editorOptions={this.elemTrans_editorOptions}
                                    />
                                    <Item
                                        dataField="udsXBacOffice"
                                        label={this.formLabel_udsElemOffice}
                                        editorType="dxNumberBox"
                                        editorOptions={this.dxNumberBox_udsPrenda_editorOptions}
                                    />
                                    <Item
                                        dataField="elementoPedido"
                                        label={this.formLabel_elemPedido}
                                        editorType="dxSelectBox"
                                        editorOptions={this.elemTrans_editorOptions}
                                    />
                                    <Item
                                        dataField="udsXBacPedido"
                                        label={this.formLabel_udsElemPedido}
                                        editorType="dxNumberBox"
                                        editorOptions={this.dxNumberBox_udsPrenda_editorOptions}
                                    />
                                    <Item
                                        dataField="elementoProduccion"
                                        label={this.formLabel_elemProduccion}
                                        editorType="dxSelectBox"
                                        editorOptions={this.elemTrans_editorOptions}
                                    />
                                    <Item
                                        dataField="udsXBacProduccion"
                                        label={this.formLabel_udsElemProducción}
                                        editorType="dxNumberBox"
                                        editorOptions={this.dxNumberBox_udsPrenda_editorOptions}
                                    />
                                    <Item
                                        dataField="udsAlmacenaje"
                                        label={this.formLabel_udsAlmacenaje}
                                        editorType="dxNumberBox"
                                        editorOptions={this.dxNumberBox_udAlmacenaje_editorOptions}
                                    />
                                </Item>
                                <Item
                                    itemType="group"
                                    caption={this.getTrad("inventario")}
                                    name="inventario"
                                    colCountByScreen={this.colCountByScreen}
                                    screenByWidth={this.screenByWidth()}
                                >
                                    <Item
                                        dataField="coste"
                                        label={this.formLabel_coste}
                                        editorType="dxNumberBox"
                                        editorOptions={this.dxNumberBox_coste_editorOptions}
                                    />
                                    <Item
                                        dataField="parStock"
                                        label={this.formLabel_parStock}
                                        editorType="dxNumberBox"
                                        editorOptions={this.dxNumberBox_parStock_editorOptions}
                                    />
                                </Item>
                                <Item
                                    itemType="group"
                                    caption={this.getTrad("facturacion")}
                                    name="facturacion"
                                    colCountByScreen={this.colCountByScreen}
                                    screenByWidth={this.screenByWidth()}
                                >
                                    <Item
                                        dataField="tblPrenda_historico_idTipoFacturacion"
                                        cssClass={"dxButton_popup_modificarTipoFacturacion"}
                                        label={this.formLabel_tipoFact}
                                        render={this.tblPrenda_historico_idTipoFacturacion_render}
                                    />
                                    <Item
                                        dataField="idFamiliaFacturacion"
                                        label={this.formLabel_familiaFacturacion}
                                        editorType="dxSelectBox"
                                        editorOptions={this.dxSelectBox_idFamiliaFacturacion_editorOptions}
                                    />
                                    <Item
                                        dataField="tblPrecioLavadoPrenda"
                                        cssClass={"dxButton_popup_modificarPrecios"}
                                        label={this.formLabel_precioLavado}
                                        render={this.tblPrecioLavadoPrenda_render}
                                    ></Item>
                                    <Item
                                        dataField="percPrecioDesmanche"
                                        label={this.formLabel_porcentajePrecioDesmanche}
                                        editorType="dxNumberBox"
                                        editorOptions={this.dxNumberBox_percPrecioDesmanche_editorOptions}
                                    />
                                    <Item
                                        dataField="isExtra"
                                        label={this.formLabel_categoria}
                                        editorType="dxSwitch"
                                        editorOptions={this.dxSwitch_categoria_editorOptions}
                                    />
                                </Item>
                                <Item
                                    itemType="group"
                                    caption={this.getTrad("especiTecnicas")}
                                    name="especiTecnicas"
                                    colCountByScreen={this.colCountByScreen}
                                    screenByWidth={this.screenByWidth()}
                                >
                                    <Item
                                        dataField="tblPrenda_historico_peso"
                                        cssClass={"dxButton_popup_modificarPesos"}
                                        label={this.formLabel_peso}
                                        render={this.tblPrenda_historico_peso_render}
                                    />
                                    <Item
                                        dataField="idColorTapa"
                                        label={this.formLabel_colorTapa}
                                        editorType="dxSelectBox"
                                        editorOptions={this.dxSelectBox_colorTapa_editorOptions}
                                    />
                                    <Item
                                        dataField="idMarcaTapa"
                                        label={this.formLabel_marcaTapa}
                                        editorType="dxSelectBox"
                                        editorOptions={this.dxSelectBox_marcaTapa_editorOptions}
                                    />
                                    <Item
                                        dataField="previsualizacion"
                                        render={this.previsualizacion_popupPrendas_render}
                                    ></Item>
                                </Item>
                            </Form>
                            <ToolbarItem_popup
                                toolbar="top"
                                location="center"
                                text={
                                    !compañiaSel
                                        ? this.getTrad("entidad") +
                                        ": " +
                                        (entidadSel != null ? entidadSel.denominacion : "")
                                        : this.getTrad("compañia") +
                                        ": " +
                                        (compañiaSel != null ? compañiaSel.denominacion : "")
                                }
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_guardar_dxPopup_prenda}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_cancelar_dxPopup_prenda}
                            />
                        </Popup>

                        <Popup
                            width={"40%"}
                            height={"50%"}
                            title={getTrad("horarioReparto")}
                            visible={isVisible_dxPopup_horarioReparto}
                            onHiding={this.onHiding_dxPopup_horarioReparto}
                        >
                            <DataGrid
                                ref={this.dxDataGrid_tblHorarioRepartoNEntidad_REF}
                                dataSource={
                                    new ArrayStore({
                                        data: entidadSel_popup ? entidadSel_popup.tblHorarioRepartoNEntidad : null,
                                        onInserting: this.onRowInserting_dxDataGrid_horarioReparto,
                                    })
                                }
                                columnsAutoWidth={true}
                                repaintChangesOnly={true}
                                height={"100%"}
                                showColumnLines={false}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                onInitNewRow={this.onInitNewRow_dxDataGrid_horarioReparto}
                                onRowRemoving={this.onRowRemoving_dxDataGrid_horarioReparto}
                                onToolbarPreparing={this.onToolbarPreparing_dxDataGrid_horarioReparto}
                            >
                                <Editing
                                    refreshMode={"repaint"}
                                    mode={"cell"}
                                    allowAdding={true}
                                    allowUpdating={true}
                                    useIcons={true}
                                    selectTextOnEditStart={true}
                                />
                                <KeyboardNavigation
                                    enterKeyAction="moveFocus"
                                    enterKeyDirection="row"
                                    editOnKeyPress={true}
                                />
                                <Paging enabled={false} />
                                <Column
                                    dataField="fecha"
                                    dataType="date"
                                    caption={this.getTrad("fechaInicio")}
                                    alignment="center"
                                    format="dd/MM/yyyy"
                                    sortOrder={"desc"}
                                    editorOptions={this.column_fecha_editorOptions}
                                    validationRules={[
                                        {
                                            type: "required",
                                            message: this.getTrad("campoNecesario"),
                                        },
                                        {
                                            type: "range",
                                            max: new Date().setMinutes(new Date().getMinutes() + 1),
                                            message: this.getTrad("fechaInvalida"),
                                        },
                                        {
                                            type: "custom",
                                            reevaluate: true,
                                            validationCallback: this.validationCallback_tblHorarioRepartoNEntidad_fecha,
                                            message: "Ya existe un horario para esta fecha",
                                        },
                                    ]}
                                />
                                <Column
                                    dataField="horarioInicioReparto"
                                    caption={getTrad("inicioReparto")}
                                    dataType="date"
                                    format="HH:mm"
                                    editorOptions={this.editorOptions_horarioReparto}
                                    alignment="center"
                                    validationRules={[
                                        {
                                            type: "required",
                                            message: this.getTrad("campoNecesario"),
                                        },
                                        {
                                            type: "custom",
                                            validationCallback: this.validateStartTime,
                                        },
                                    ]}
                                />
                                <Column
                                    dataField="horarioFinReparto"
                                    caption={getTrad("finReparto")}
                                    dataType="date"
                                    format="HH:mm"
                                    editorOptions={this.editorOptions_horarioReparto}
                                    alignment="center"
                                    validationRules={[
                                        {
                                            type: "required",
                                            message: this.getTrad("campoNecesario"),
                                        },
                                        {
                                            type: "custom",
                                            validationCallback: this.validateEndTime,
                                        },
                                    ]}
                                />
                                <Column
                                    caption=" "
                                    width="40px"
                                    alignment="center"
                                    cssClass="p-0"
                                    cellComponent={removeCellComponent}
                                    allowEditing={false}
                                />
                            </DataGrid>
                            <ToolbarItem_popup widget="dxButton" location="after" toolbar="bottom">
                                <Button
                                    text={this.getTrad("guardar")}
                                    onClick={this.onClick_PopupHorarioNEntidad_guardar}
                                />
                            </ToolbarItem_popup>
                        </Popup>

                        <Popup
                            width={320}
                            height={270}
                            title={this.getTrad("nuevoEdificio")}
                            visible={isVisible_dxPopup_addSeccionNivel1}
                            onShown={this.dxPopup_nuevoEdificio_onShown}
                            onHiding={this.dxPopup_nuevoEdificio_onHiding}
                            onHidden={this.dxPopup_nuevoEdificio_onHidden}
                        >
                            <Form
                                ref={this.dxForm_addSeccionNivel1_REF}
                                labelLocation="top"
                                height="100%"
                                scrollingEnabled={true}
                                colCount={1}
                            >
                                <Item
                                    dataField="denominacion"
                                    label={this.formLabel_denominacion}
                                    editorOptions={this.editorOptions_denominacion_secciones}
                                    validationRules={this.validationRules_general_denominacion}
                                ></Item>
                            </Form>

                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_guardar_dxPopup_nuevoEdificio}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_cancelar_dxPopup_nuevoEdificio}
                            />
                        </Popup>

                        <Popup
                            width={320}
                            height={270}
                            title={this.getTrad("nuevaSeccion")}
                            visible={isVisible_dxPopup_addSeccionNivel2}
                            onShown={this.dxPopup_nuevaSeccion_onShown}
                            onHiding={this.dxPopup_nuevaSeccion_onHiding}
                            onHidden={this.dxPopup_nuevaSeccion_onHidden}
                        >
                            <Form
                                ref={this.dxForm_addSeccionNivel2_REF}
                                labelLocation="top"
                                height="100%"
                                scrollingEnabled={true}
                                colCount={1}
                            >
                                <Item
                                    dataField="denominacion"
                                    label={this.formLabel_denominacion}
                                    editorOptions={this.editorOptions_denominacion_secciones}
                                    validationRules={this.validationRules_general_denominacion}
                                ></Item>
                            </Form>

                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_guardar_dxPopup_nuevaSeccion}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_cancelar_dxPopup_nuevaSeccion}
                            />
                        </Popup>

                        <Popup
                            width={"90%"}
                            height={"80%"}
                            title={this.getTrad("configuracion_Almacen")}
                            visible={isVisible_dxPopup_configAlmacenes}
                            onShown={this.dxPopup_configAlmacenes_onShown}
                            onHiding={this.dxPopup_configAlmacenes_onHiding}
                        >
                            <Form
                                ref={this.dxForm_almacen_REF}
                                labelLocation="top"
                                height="100%"
                                formData={almacenSel}
                                validationGroup="dxForm_almacen_validationGroup"
                                scrollingEnabled={true}
                                colCountByScreen={this.colCountByScreen_form_configAlmacenes}
                            >
                                <Item
                                    itemType="group"
                                    caption={this.getTrad("general")}
                                    name="general"
                                    colCountByScreen={this.colCountByScreen_popup_configAlmacenes}
                                    screenByWidth={this.screenByWidth()}
                                >
                                    <Item
                                        dataField="activo"
                                        colSpan={3}
                                        label={this.formLabel_activo}
                                        editorType="dxSwitch"
                                        editorOptions={this.activo_dxFormPrenda_editorOptions}
                                    />
                                    <Item
                                        dataField="denominacion"
                                        label={this.formLabel_denominacion}
                                        colSpan={2}
                                        editorOptions={this.editorOptions_denominacion_almacen}
                                        validationRules={this.validationRules_general_denominacion}
                                    />
                                    <Item
                                        dataField="idTipoAlmacen"
                                        label={this.formLabel_tipoAlmacen}
                                        editorType="dxSelectBox"
                                        editorOptions={this.idTipoAlmacen_editorOptions}
                                        validationRules={this.validationRules_tipoAlmacen}
                                    ></Item>
                                    <Item
                                        dataField="idTipoHabitacion"
                                        label={this.formLabel_tipoHab}
                                        validationRules={this.validationRules_tipoHabitacion}
                                    >
                                        <SelectBox
                                            items={datasource_datos_tblTipoHabitacion_items}
                                            value={almacenSel ? almacenSel.idTipoHabitacion : null}
                                            disabled={almacenSel ? almacenSel.idTipoAlmacen !== 4 : true}
                                            displayExpr="denominacion"
                                            valueExpr="idTipoHabitacion"
                                            showClearButton={true}
                                            onValueChanged={this.dxSelectBox_tblTipoHabitacion_onValueChanged}
                                        >
                                            <Validator
                                                validationRules={[
                                                    {
                                                        type: "custom",
                                                        reevaluate: true,
                                                        validationCallback: this.validationCallback_tipoHabitacion,
                                                    },
                                                ]}
                                                validationGroup="dxForm_almacen_validationGroup"
                                            ></Validator>
                                        </SelectBox>
                                    </Item>
                                </Item>
                                <Item
                                    itemType="group"
                                    caption={this.getTrad("prendasAlmacen")}
                                    name="prendasNAlmacen"
                                    colSpan={3}
                                >
                                    <Item dataField="tblPrendaNAlmacen" label={this.formLabel_invisible}>
                                        <DataGrid
                                            //Datos
                                            ref={this.dxDataGrid_tblPrendaNAlmacen_REF}
                                            dataSource={almacenSel ? almacenSel.tblPrendaNAlmacen : null}
                                            //Propiedades
                                            remoteOperations={false}
                                            columnsAutoWidth={true}
                                            repaintChangesOnly={true}
                                            height={450}
                                            //Estilos
                                            showColumnLines={false}
                                            showRowLines={true}
                                            rowAlternationEnabled={true}
                                            onToolbarPreparing={this.onToolbarPreparing_dxDataGrid_tblPrendaNAlmacen}
                                        >
                                            <Editing
                                                refreshMode={"repaint"}
                                                mode={"cell"}
                                                allowUpdating={almacenSel ? almacenSel.idTipoAlmacen !== 4 : false}
                                                allowDeleting={false}
                                                useIcons={true}
                                                selectTextOnEditStart={true}
                                            />
                                            <KeyboardNavigation
                                                enterKeyAction="moveFocus"
                                                enterKeyDirection="row"
                                                editOnKeyPress={true}
                                            />
                                            <Paging enabled={false} />
                                            <Grouping autoExpandAll={true} allowCollapsing={false} />
                                            <GroupPanel visible={false} />
                                            <SearchPanel
                                                visible={true}
                                                width={200}
                                                placeholder={this.getTrad("busqueda")}
                                                searchVisibleColumnsOnly={true}
                                            />
                                            <Column
                                                dataField="tblPrenda.idEntidad"
                                                groupCellRender={
                                                    this.dxDataGrid_tblPrendaNAlmacen_idEntidad_groupCellRender
                                                }
                                                visible={false}
                                                groupIndex={0}
                                            />
                                            <Column
                                                dataField="tblPrenda.codigoPrenda"
                                                caption={this.getTrad("codigo")}
                                                width={100}
                                                alignment="center"
                                                sortIndex={0}
                                                sortOrder="asc"
                                                allowEditing={false}
                                            />
                                            <Column
                                                dataField="tblPrenda.denominacion"
                                                caption={this.getTrad("denominacion")}
                                                minWidth={250}
                                                allowEditing={false}
                                            />
                                            <Column
                                                dataField="stock"
                                                caption={this.getTrad("stock")}
                                                alignment="center"
                                                width={80}
                                                cssClass={
                                                    almacenSel && almacenSel.idTipoAlmacen !== 4
                                                        ? "dx-Cell_Editable"
                                                        : ""
                                                }
                                                format="#0"
                                                editorOption={this.stock_editorOptions}
                                                validationRules={[
                                                    {
                                                        type: "custom",
                                                        reevaluate: true,
                                                        validationCallback: this.validationCallback_stock,
                                                        message: this.getTrad("error_ValorEncimaLimite"),
                                                    },
                                                ]}
                                            />
                                            <Column
                                                dataField="actual"
                                                caption={this.getTrad("actual")}
                                                alignment="center"
                                                width={80}
                                                allowEditing={false}
                                                format="#0"
                                            />
                                            <Column
                                                dataField="tblPrenda.tblElemTransOffice.codigo"
                                                caption={this.getTrad("elementoTransporte_abr")}
                                                width={80}
                                                allowHeaderFiltering={false}
                                                allowFiltering={false}
                                                alignment="center"
                                                cssClass="p-0"
                                                allowEditing={false}
                                                cellComponent={elemTransCellComponent}
                                            />
                                            <Column
                                                caption=" "
                                                width={40}
                                                alignment="center"
                                                cssClass="p-0"
                                                visible={almacenSel && almacenSel.idTipoAlmacen !== 4}
                                                allowEditing={false}
                                                cellComponent={removeCellComponent}
                                            />
                                        </DataGrid>
                                    </Item>
                                </Item>
                            </Form>
                            <ToolbarItem_popup
                                toolbar="top"
                                location="center"
                                html={"<span id='dxPopup_almacen_title'></span>"}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_guardar_dxPopup_configAlmacenes}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_cancelar_dxPopup_configAlmacenes}
                            />
                        </Popup>

                        <Popup
                            width="80%"
                            height={500}
                            title={this.getTrad("selección_tipoPrenda")}
                            visible={isVisible_dxPopup_seleccionTipoPrenda}
                            onHiding={this.onHiding_dxPopup_seleccionTipoPrenda}
                            onHidden={this.onHidden_dxPopup_seleccionTipoPrenda}
                        >
                            <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("familia")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                items={this.datasource_datos_tblFamilia.items()}
                                                focusStateEnabled={false}
                                                pageLoadMode="scrollBottom"
                                                selectionMode="single"
                                                height="100%"
                                                keyExpr="idFamilia"
                                                selectedItems={[this.state.familiaSel]}
                                                itemTemplate={this.dxList_addPrenda_itemTemplate}
                                                onSelectionChanged={this.dxList_familia_onSelectionChanged}
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox baseSize={20}></ItemBox>
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("tipoPrenda")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                items={
                                                    this.state.familiaSel ? this.state.datasource_dxList_tipoPrenda : []
                                                }
                                                focusStateEnabled={false}
                                                pageLoadMode="scrollBottom"
                                                selectionMode="single"
                                                height="100%"
                                                keyExpr="idTipoPrenda"
                                                itemTemplate={this.dxList_addPrenda_itemTemplate}
                                                onSelectionChanged={this.dxList_tipoPrenda_onSelectionChanged}
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox baseSize={20}></ItemBox>
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("denoPrenda")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                items={
                                                    this.state.tipoPrendaSel
                                                        ? this.state.datasource_dxList_denoPrenda
                                                        : []
                                                }
                                                focusStateEnabled={false}
                                                pageLoadMode="scrollBottom"
                                                selectionMode="single"
                                                height="100%"
                                                keyExpr="idDenoPrenda"
                                                searchExpr="denominacion"
                                                itemTemplate={this.dxList_addPrenda_itemTemplate}
                                                onSelectionChanged={this.dxList_denoPrenda_onSelectionChanged}
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                            </Box>
                        </Popup>

                        <Popup
                            width={800}
                            height={500}
                            title={this.getTrad("prendasAlmacen")}
                            visible={isVisible_dxPopup_tblPrendaNAlmacen}
                            deferRendering={false}
                            onShowing={this.dxPopup_tblPrendaNAlmacen_onShowing}
                            onHiding={this.dxPopup_tblPrendaNAlmacen_onHiding}
                        >
                            <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("prendasCompañia")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_tblPrenda_compañia_REF}
                                                dataSource={datasource_datos_tblPrendaNAlmacen_compañia}
                                                selectedItems={selectedItems_dxList_tblPrendaNAlmacen_compañia}
                                                searchEnabled={true}
                                                searchExpr={this.searchExpr_codigoDenoPrenda}
                                                focusStateEnabled={false}
                                                selectionMode="all"
                                                showSelectionControls={true}
                                                pageLoadMode="scrollBottom"
                                                height="100%"
                                                keyExpr="idPrenda"
                                                itemRender={this.itemRender_dxList_prendasCodigo}
                                                onOptionChanged={this.onOptionChanged_dxList_prendasAlmacen_compañia}
                                            ></List>
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox baseSize={20}></ItemBox>
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("prendasEntidad")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_tblPrenda_entidad_REF}
                                                dataSource={datasource_datos_tblPrendaNAlmacen_entidad}
                                                searchExpr={this.searchExpr_codigoDenoPrenda}
                                                searchEnabled={true}
                                                selectedItems={selectedItems_dxList_tblPrendaNAlmacen_entidad}
                                                focusStateEnabled={false}
                                                selectionMode="all"
                                                showSelectionControls={true}
                                                pageLoadMode="scrollBottom"
                                                height="100%"
                                                keyExpr="idPrenda"
                                                itemRender={this.itemRender_dxList_prendasCodigo}
                                                onOptionChanged={this.onOptionChanged_dxList_prendasAlmacen_entidad}
                                            ></List>
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                            </Box>
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_guardar_dxPopup_prendasAlmacen}
                            />
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_cancelar_dxPopup_prendasAlmacen}
                            />
                        </Popup>

                        <Popup
                            title={"Modificar precios"}
                            showTitle={true}
                            width={"900"}
                            height={"700"}
                            shading={true}
                            visible={isVisible_dxPopup_modificarPrecios}
                            closeOnOutsideClick={false}
                            onShowing={this.onShowing_popup_preciosPrenda}
                            onHiding={this.hiding_popup_preciosPrenda}
                        >
                            <Box direction="col" width="100%" height="100%">
                                <Item baseSize={80}>
                                    <Toolbar elementAttr={this.elementAttr_class_mb2}>
                                        <ToolbarItem location="before" render={this.toolbarPreciosPrenda_render} />
                                        <ToolbarItem
                                            location="after"
                                            widget="dxButton"
                                            locateInMenu="auto"
                                            options={this.updatePreciosPrenda_buttonOption}
                                        />
                                    </Toolbar>
                                </Item>
                                <Item ratio={1}>
                                    <DataGrid
                                        ref={this.dxDataGrid_tblPrenda_precios_REF}
                                        dataSource={this.state.datasource_dxDataGrid_tblPrenda}
                                        remoteOperations={false}
                                        columnsAutoWidth={true}
                                        height={"100%"}
                                        width={"100%"}
                                        repaintChangesOnly={true}
                                        //Estilos
                                        showColumnLines={false}
                                        showRowLines={true}
                                        rowAlternationEnabled={true}
                                        onContentReady={this.onContentReady_dxDataGrid_tblPrenda_precios}
                                        onRowPrepared={this.onRowPrepared_dxDataGrid_tblPrenda_precios}
                                    >
                                        <Grid_LoadPanel enabled={false} />
                                        <HeaderFilter visible={false} />
                                        <FilterRow visible={true} />
                                        <Selection mode="multiple" showCheckBoxesMode="always" />
                                        <Scrolling mode="infinite" scrollByContent={true} />
                                        <Column dataField="activo" visible={false} sortIndex={0} sortOrder="desc" />
                                        <Column
                                            dataField="codigoPrenda"
                                            caption={this.getTrad("codigo")}
                                            width={90}
                                            sortIndex={1}
                                            sortOrder="asc"
                                            alignment="left"
                                        />
                                        <Column
                                            dataField="denominacion"
                                            caption={this.getTrad("denominacion")}
                                            alignment="left"
                                            allowHeaderFiltering={false}
                                        />
                                        <Column
                                            dataField="tipoFact"
                                            caption={this.getTrad("tipoFacturacion")}
                                            width={130}
                                            alignment="left"
                                            allowHeaderFiltering={false}
                                        >
                                            <Lookup
                                                dataSource={this.datasource_datos_tblTipoFacturacion.items()}
                                                valueExpr="idTipoFacturacion"
                                                displayExpr="denominacion"
                                            />
                                        </Column>
                                        <Column
                                            dataField="precioLavado"
                                            caption={this.getTrad("precio")}
                                            format={this.updatePreciosPrenda_precioFormat}
                                            alignment="center"
                                            width={80}
                                            allowHeaderFiltering={false}
                                        />
                                    </DataGrid>
                                </Item>
                            </Box>
                        </Popup>

                        <Popup
                            width="80%"
                            height="60%"
                            deferRendering={false}
                            title={this.getTrad("importarEntidad")}
                            visible={isVisible_dxPopup_importarEntidad}
                            onShowing={this.dxPopup_importarEntidad_onShowing}
                            onHiding={this.dxPopup_importarEntidad_onHiding}
                        >
                            <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("lavanderia")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_importarEntidad_lavanderia_REF}
                                                dataSource={this.datasource_datos_lavanderia}
                                                selectedItems={selectedItems_dxList_importarEntidad_lavanderia}
                                                focusStateEnabled={false}
                                                selectionMode="single"
                                                height="100%"
                                                keyExpr="idLavanderia"
                                                pageLoadMode="scrollBottom"
                                                searchEnabled={true}
                                                searchExpr="denominacion"
                                                displayExpr="denominacion"
                                                onOptionChanged={this.dxList_importarEntidad_lavanderia_onOptionChanged}
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox baseSize={20}></ItemBox>
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("compañia")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_importarEntidad_compañia_REF}
                                                dataSource={datasource_importarEntidad_compañia}
                                                selectedItems={selectedItems_dxList_importarEntidad_compañia}
                                                focusStateEnabled={false}
                                                selectionMode="single"
                                                height="100%"
                                                keyExpr="idCompañia"
                                                searchEnabled={true}
                                                searchExpr="denominacion"
                                                displayExpr="denominacion"
                                                onOptionChanged={this.dxList_importarEntidad_compañia_onOptionChanged}
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox baseSize={20}></ItemBox>
                                <ItemBox ratio={1}>
                                    <Box
                                        direction="col"
                                        align="space-around"
                                        crossAlign="stretch"
                                        height="100%"
                                        width="100%"
                                    >
                                        <ItemBox baseSize={40}>
                                            <div className="font-size">{this.getTrad("entidad")}</div>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <List
                                                ref={this.dxList_importarEntidad_entidad_REF}
                                                dataSource={datasource_importarEntidad_entidad}
                                                selectedItems={selectedItems_dxList_importarEntidad_entidad}
                                                focusStateEnabled={false}
                                                selectionMode="multiple"
                                                height="100%"
                                                keyExpr="idEntidad"
                                                searchEnabled={true}
                                                showSelectionControls={true}
                                                searchExpr="denominacion"
                                                displayExpr="denominacion"
                                                onOptionChanged={this.dxList_importarEntidad_entidad_onOptionChanged}
                                            />
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                            </Box>
                            <ToolbarItem_popup
                                toolbar="bottom"
                                location="after"
                                render={this.render_dxButton_importar_dxPopup_importarEntidaddes}
                            />
                        </Popup>

                        <PopupHistoricoTipoFacturacionEntidad
                            visible={isVisible_PopupHistoricoTipoFacturacion}
                            entidadSel={entidadSel_popup}
                            tblTipoFacturacionCliente={this.datasource_datos_tblTipoFacturacionCliente.items()}
                            getIsCerrado={this.getIsCerrado}
                            onHiding={this.onHiding_PopupHistoricoTipoFacturacion}
                            onSave={this.onSave_PopupHistoricoTipoFacturacion}
                        />

                        <PopupHistoricoPesoPrenda
                            visible={isVisible_dxPopup_historicoPesoPrenda}
                            isEditing={isCellEditing_dxDataGrid_tblPrenda}
                            prendaSel={prendaSel}
                            datasource_tblPrenda={this.datasource_tblPrenda}
                            getIsCerrado={this.getIsCerrado}
                            onHiding={this.onHiding_dxPopup_prenda_historico_peso}
                            onSave={this.onSave_PopupHistoricoPesoPrenda}
                        />

                        <PopupHistoricoPrecioLavadoPrenda
                            visible={isVisible_dxPopup_precioLavadoPrenda}
                            isEditing={isCellEditing_dxDataGrid_tblPrenda}
                            prendaSel={prendaSel}
                            datasource_tblPrenda={this.datasource_tblPrenda}
                            getIsCerrado={this.getIsCerrado}
                            onHiding={this.onHiding_dxPopup_precioLavadoPrenda}
                            onSave={this.onSave_PopupHistoricoPrecioLavadoPrenda}
                         />

                        <PopupHistoricoTipoFacturacionPrenda
                            visible={isVisible_dxPopup_historicoIdTipoFacturacionPrenda}
                            isEditing={isCellEditing_dxDataGrid_tblPrenda}
                            prendaSel={prendaSel}
                            tblTipoFacturacion={this.datasource_datos_tblTipoFacturacion.items()}
                            datasource_tblPrenda={this.datasource_tblPrenda}
                            getIsCerrado={this.getIsCerrado}
                            onHiding={this.onHiding_dxPopup_prenda_historico_idTipoFacturacion}
                            onSave={this.onSave_PopupHistoricoTipoFacturacionPrenda}
                        />

                        {!allowEdit && (
                            <Tooltip
                                target={".addButton_modificarPrecios"}
                                position="bottom"
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                            >
                                <div>{"Solo pueden modificar el precio las personas del departamento de control."}</div>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <Popover
                    target={cellHover_cerrado}
                    position={"bottom"}
                    showEvent={"mouseenter"}
                    hideEvent={"mouseleave"}
                >
                    {getTrad("cerrado")}
                </Popover>
            </Fragment>
        );
    }

    onClick_actualizarTipoFacturacion = () => this.setState({ isVisible_PopupHistoricoTipoFacturacion: true });

    onHiding_PopupHistoricoTipoFacturacion = () => this.setState({ isVisible_PopupHistoricoTipoFacturacion: false });

    onSave_PopupHistoricoTipoFacturacion = data => this.setState({ entidadSel_popup: data });

    onSave_PopupHistoricoTipoFacturacionPrenda = data => this.setState({ entidadSel_popup: data });

    getIsCerrado = fecha => this.state.entidadSel?.tblCierreFactEntidad?.some(row => row.fechaDesde <= fecha && row.fechaHasta >= fecha);

    onCellHoverChanged_historico = e => {
        if (e.rowType === "data" && e.column.name === "eliminar" && e.eventType === "mouseover" && e.data.isCerrado) {
            const key = e.rowIndex;
            const columnName = e.column.dataField ?? e.column.name;
            const id = "#" + columnName + "_" + key;

            this.setState({ cellHover_cerrado: id });
        }
    }

    //#region Ubicación | Mapa

    buttonAceptarStyle_map = {
        position: "absolute",
        right: 30,
        bottom: 50,
        zIndex: 1,
    };

    onClick_useMap = () => {
        const { entidadSel_popup } = this.state;

        let state = {};
        let coordinates = undefined;

        if (entidadSel_popup.coordenadas) {
            coordinates = entidadSel_popup.coordenadas.split(",");
            coordinates = {
                lat: parseFloat(coordinates[0]),
                lng: parseFloat(coordinates[1]),
            };
            state = { coordinates };
        }

        let address =
            (entidadSel_popup.direccion ? entidadSel_popup.direccion : "") +
            (entidadSel_popup.codigoPostal ? ", " + entidadSel_popup.codigoPostal : "") +
            (entidadSel_popup.poblacion ? ", " + entidadSel_popup.poblacion : "") +
            (entidadSel_popup.provincia ? ", " + entidadSel_popup.provincia : "") +
            (entidadSel_popup.pais ? ", " + entidadSel_popup.pais : "");

        if (address[0] === ",") {
            address = address.slice(2);
        }
        if (address.length > 0) {
            state = { ...state, address, addressNoMod: address };
        }

        this.setState({ isVisible_dxPopup_mapa: true, ...state }, () => {
            const autoCompletes = document.getElementsByClassName("pac-container pac-logo");

            for (let i = 0; i < autoCompletes.length - 1; i++) {
                autoCompletes[i].remove();
            }

            const autoComplete = autoCompletes[autoCompletes.length - 1];

            if (autoComplete && autoComplete.style.zIndex != 2000) {
                autoComplete.style.zIndex = 2000;
            }
        });
    };

    onHiding_dxPopup_mapa = () => {
        this.setState({
            isVisible_dxPopup_mapa: false,
            address: undefined,
            coordinates: undefined,
            address_components: undefined,
        });
    };

    onClick_saveDireccion = () => {
        const { address_components, address, coordinates, addressNoMod } = this.state;

        if (address === addressNoMod) {
            notify({
                message: getTrad("ubicacionSinModificacion"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        } else if (address_components === undefined && address.length === 0) {
            notify({
                message: getTrad("introduzcaDireccion"),
                type: "error",
                displayTime: "3000",
                closeOnClick: true,
            });
        } else if (address_components === undefined && address.length > 0) {
            notify({
                message: getTrad("error_sinConfirmarUbicacion"),
                type: "error",
                displayTime: "4500",
                closeOnClick: true,
            });
        } else if (address_components) {
            let update = {
                direccion: "",
                provincia: "",
                pais: "",
                codigoPostal: "",
                poblacion: "",
                coordenadas: coordinates.lat + ", " + coordinates.lng,
            };

            let numeroCalle = "";
            let hasRoute = false;

            address_components.forEach((component) => {
                if (component.types) {
                    switch (component.types[0]) {
                        case "street_number":
                            numeroCalle = component.long_name;
                            break;
                        case "locality":
                            update.poblacion = component.long_name;
                            break;
                        case "route":
                            hasRoute = true;
                            update.direccion = component.long_name;
                            break;
                        case "country":
                            update.pais = component.long_name;
                            break;
                        case "postal_code":
                            update.codigoPostal = component.long_name;
                            break;
                        case "administrative_area_level_1":
                            update.provincia = component.long_name;
                            break;
                    }
                }
            });
            if (hasRoute && numeroCalle.length > 0) {
                update.direccion = update.direccion + ", " + numeroCalle;
            } else if (!hasRoute) {
                update.direccion = address;
            }

            this.dxForm_entidad.updateData(update);
            this.onHiding_dxPopup_mapa();
        }
    };

    onChange_map = (onChangeObj) => {
        this.setState({ ...onChangeObj });
    };

    //#endregion

    //#region enableObservacionesPedido

    label_enableObservacionesPedido = {
        text: getTrad("observacionesNPedido"),
    };

    editorOptions_enableObservacionesPedido = {
        switchedOnText: getTrad("permitido"),
        switchedOffText: getTrad("noPermitido"),
    };

    //#endregion

    //#region planificadorPedidos

    label_planificadorPedidos = {
        text: getTrad("planificadorPedidos"),
    };

    editorOptions_planificadorPedidos = {
        switchedOnText: getTrad("activado"),
        switchedOffText: getTrad("desactivado"),
    };

    //#endregion

    //#region SACAS PENDIENTES

    onClick_sacasPendientes = () => {
        this.setState({ isVisible_dxPopup_sacasPendientes: true });
    };

    onHidden_dxPopup_sacasPendientes = () => {
        const { entidadSel_popup, sacasPendientesGuardadas } = this.state;

        this.dxDataGrid_tblSacasPendientes.option("editing.changes", []);

        const entidadSel_popup_ = {
            ...entidadSel_popup,
            tblSacasPendientes: [...sacasPendientesGuardadas.map((item) => ({ ...item }))],
        };

        this.setState({
            isVisible_dxPopup_sacasPendientes: false,
            entidadSel_popup: entidadSel_popup_,
        });
    };

    onShown_dxPopup_sacasPendientes = () => {
        this.dxDataGrid_tblSacasPendientes.repaint();
    };

    editorOptions_cantidadPendiente = {
        mode: "number",
        min: 0,
        max: 99999,
        step: 0,
        placeholder: "0",
        format: { style: "decimal", maximumFractionDigits: 0 },
    };

    validationCallback_CustomRule_cantidadPendiente = (e) => {
        e.rule.message = this.getTrad("mayorIgual0");
        if (e.data.cantidad >= 0) {
            return true;
        }
        return false;
    };

    dxDataGrid_sacasPendientes_cantidadPendiente_render = (e) => {
        let { cantidad } = e.data;
        let text = cantidad > 0 ? e.text : "0";

        return <div>{text}</div>;
    };

    cellRender_tblSacasPendientes_idColorTapaNavigation = ({ data }) => {
        const id = data.idSacasPendientes;
        const color = data.tblColorTapa?.codigoHexadecimal;

        return (
            <div
                id={"icon_Saca" + id}
                className={"container_spanCentrado elemTransCellComponent " + (color === "#FFFFFF" ? "white" : "")}
                style={{ color, height: 25 }}
            >
                {data.idColorTapa ? (
                    <>
                        <div className={"content font-size-xl icon_Saca"}></div>
                        <Tooltip
                            target={"#icon_Bac" + id}
                            position="bottom"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                        >
                            <div className="font-weight-bold">{this.getTrad("saca")}</div>
                        </Tooltip>
                    </>
                ) : (
                    <p style={{ margin: 0 }}>{this.getTrad("rechazo")}</p>
                )}
            </div>
        );
    };

    onClick_PopupSacasPendientes_guardar = () => {
        const { entidadSel_popup } = this.state;

        this.dxDataGrid_tblSacasPendientes.saveEditData();

        const entidadSel_popup_ = { ...entidadSel_popup };
        const sacasPendientesGuardadas = [...entidadSel_popup_.tblSacasPendientes.map((item) => ({ ...item }))];

        this.setState({
            isVisible_dxPopup_sacasPendientes: false,
            entidadSel_popup: entidadSel_popup_,
            sacasPendientesGuardadas,
        });
    };

    //#endregion

    //#region HORARIO REPARTO

    editorOptions_horarioReparto = {
        placeholder: "00:00",
        type: "time",
        displayFormat: "HH:mm",
        showDropDownButton: false,
        useMaskBehavior: true,
    };

    tblhorarioRepartoNEntidad_render(e) {
        const { entidadSel_popup } = this.state;

        const entidadSel_popup_ = entidadSel_popup ? $.extend(true, {}, entidadSel_popup) : null;
        let text = "Sin horario";
        const tblHorarioRepartoNEntidad =
            entidadSel_popup_ && entidadSel_popup_.tblHorarioRepartoNEntidad
                ? entidadSel_popup_.tblHorarioRepartoNEntidad.sort(function (a, b) {
                    var fechaA = new Date(a.fecha);
                    var fechaB = new Date(b.fecha);
                    return fechaB - fechaA;
                })
                : [];

        if (
            tblHorarioRepartoNEntidad.length > 0 &&
            tblHorarioRepartoNEntidad[0].horarioInicioReparto &&
            tblHorarioRepartoNEntidad[0].horarioFinReparto
        ) {
            text =
                dateTime_hourMinute(new Date(tblHorarioRepartoNEntidad[0].horarioInicioReparto)) +
                " - " +
                dateTime_hourMinute(new Date(tblHorarioRepartoNEntidad[0].horarioFinReparto));
        }

        return (
            <>
                <Button
                    ref={this.dxButton_tblhorarioRepartoNEntidad_REF}
                    text={text}
                    stylingMode="outlined"
                    width="100%"
                    onClick={this.dxButton_tblhorarioRepartoNEntidad_onClick}
                />
            </>
        );
    }

    dxButton_tblhorarioRepartoNEntidad_onClick(e) {
        this.setState({ isVisible_dxPopup_horarioReparto: true });
    }

    onHiding_dxPopup_horarioReparto(e) {
        const { entidadSel_popup, horariosGuardados } = this.state;

        const entidadSel_popup_ = $.extend(true, {}, entidadSel_popup);
        entidadSel_popup_.tblHorarioRepartoNEntidad = [...horariosGuardados];

        this.dxDataGrid_tblhorarioRepartoNEntidad.option("editing.changes", []);

        this.setState({
            isVisible_dxPopup_horarioReparto: false,
            entidadSel_popup: entidadSel_popup_,
        });
    }

    validationCallback_tblHorarioRepartoNEntidad_fecha(e) {
        var fecha = e.value;

        var rows = this.dxDataGrid_tblhorarioRepartoNEntidad.getVisibleRows();

        var existeMismaFecha =
            $.grep(rows, function (row) {
                return formatDate(new Date(row.data.fecha)) === formatDate(new Date(fecha));
            }).length > 1;

        return !existeMismaFecha;
    }

    validateStartTime = (params) => {
        const startTime = params.value;
        const rowData = params.data;
        const endTime = rowData.horarioFinReparto;

        if (startTime && endTime && startTime >= endTime) {
            params.rule.message = getTrad("inicioMasFin");
            return false;
        }
        return true;
    };

    validateEndTime = (params) => {
        const endTime = params.value;
        const rowData = params.data;
        const startTime = rowData.horarioInicioReparto;

        if (startTime && endTime && startTime >= endTime) {
            params.rule.message = getTrad("finMenosIncio");
            return false;
        }
        return true;
    };

    onInitNewRow_dxDataGrid_horarioReparto(e) {
        e.data.fecha = convertClienteUtcToLavanderiaUtc(this.props.lavanderia.GMT, new Date());
        e.data.idEntidad = this.state.entidadSel_popup ? this.state.entidadSel_popup.idEntidad : null;
    }

    onRowRemoving_dxDataGrid_horarioReparto(e) {
        if (e.component.getVisibleRows().length === 1) {
            e.cancel = true;
            notify({
                message: "Se debe indicar al menos un horario de reparto.",
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }

    onToolbarPreparing_dxDataGrid_horarioReparto(e) {
        let _this = this;
        $.each(e.toolbarOptions.items, function (index, item) {
            switch (item.name) {
                case "addRowButton":
                    item.options.text = _this.getTrad("añadir");
                    item.options.icon = "plus";
                    item.options.type = "success";
                    item.showText = "always";
                    break;
                default:
                    break;
            }
        });
    }

    onClick_PopupHorarioNEntidad_guardar = async (e) => {
        const { entidadSel_popup } = this.state;

        const activeElement = document.activeElement;
        await activeElement.blur();

        const entidadSel_popup_ = $.extend(true, {}, entidadSel_popup);
        const horariosGuardados = [...entidadSel_popup_.tblHorarioRepartoNEntidad];
        this.dxDataGrid_tblhorarioRepartoNEntidad.saveEditData();
        this.setState({
            isVisible_dxPopup_horarioReparto: false,
            entidadSel_popup: entidadSel_popup_,
            horariosGuardados,
        });
    };

    onRowInserting_dxDataGrid_horarioReparto(e) {
        delete e.__KEY__;
    }

    //#endregion

    //#region FORMAT

    //#region GENERAL
    format_parStock = { style: "decimal", maximumFractionDigits: 0 };

    editorOptions_parStock = {
        step: 0,
        min: 0,
        max: 999999,
        format: { style: "decimal", maximumFractionDigits: 0 },
    };

    format_currency_4_digits = {
        style: "currency",
        currency: this.props.lavanderia.moneda,
        minimumFractionDigits: 4,
    };

    format_currency = {
        style: "currency",
        currency: this.props.lavanderia.moneda,
        minimumFractionDigits: 3,
    };

    editorOptions_currency = {
        step: 0,
        format: {
            style: "currency",
            currency: this.props.lavanderia.moneda,
            minimumFractionDigits: 3,
        },
        min: 0,
        max: 99999.999,
    };

    headerFilter_filtroActivo = {
        allowSearch: true,
        dataSource: filtroActivo,
    };

    dxList_scrolling_infinite = { mode: "infinite" };

    elementAttr_toolbarClass = { class: "dxToolbar_opciones" };

    mode_infinite = { mode: "infinite" };

    format_parStock = {
        style: "decimal",
        maximumFractionDigits: 0,
    };
    editorOptions_parStock = {
        step: 0,
        min: 0,
        max: 99999,
        format: this.format_parStock,
    };

    colCountByScreen = {
        xl: 4,
        lg: 4,
        md: 3,
        sm: 2,
        xs: 1,
    };

    colCountByScreen_pedidos = {
        xl: 6,
        lg: 6,
        md: 5,
        sm: 4,
        xs: 3,
    };

    colCountByScreen_configPrenda_general = {
        xl: 8,
        lg: 8,
        md: 6,
        sm: 2,
        xs: 1,
    };
    //#endregion

    //#region POPUP ALMACEN
    activo_dxFormPrenda_editorOptions = {
        switchedOnText: this.getTrad("activo"),
        switchedOffText: this.getTrad("noActivo"),
    };

    stock_editorOptions = {
        step: 0,
        format: { style: "decimal", maximumFractionDigits: 0 },
        min: 0,
    };

    colCountByScreen_form_configAlmacenes = {
        xl: 4,
        lg: 4,
        md: 4,
        sm: 1,
        xs: 1,
    };

    colCountByScreen_popup_configAlmacenes = {
        xl: 1,
        lg: 1,
        md: 1,
        sm: 3,
        xs: 1,
    };

    validationRules_tipoAlmacen = [
        {
            type: "required",
            message: this.getTrad("tipoAlmacen") + ": " + this.getTrad("campoNecesario"),
        },
    ];

    validationCallback_tipoHabitacion(e) {
        let { almacenSel } = this.state;

        if (!almacenSel) return true;
        return (almacenSel.idTipoAlmacen === 4 && e.value != null) || almacenSel.idTipoAlmacen != 4;
    }

    validationRules_configAdicional_objetivoKgEstancia = [
        {
            type: "required",
            message: this.getTrad("objetivoKgEstancia") + ": " + this.getTrad("campoNecesario"),
        },
        {
            type: "custom",
            reevaluate: true,
            validationCallback: this.validationCallback_mayorCero,
        },
    ];

    idTipoAlmacen_editorOptions = {
        dataSource: this.datasource_datos_tblTipoAlmacen,
        displayExpr: "denominacion",
        valueExpr: "idTipoAlmacen",
        onValueChanged: (e) => {
            this.idTipoAlmacen_onValueChanged(e, this);
        },
    };
    //#endregion

    //#region POPUP MODIFICAR PRECIOS

    elementAttr_class_mb2 = { class: "mb-2" };

    updatePreciosPrenda_calendarOptions = { max: new Date() };

    updatePreciosPrenda_precioFormat = {
        style: "currency",
        currency: this.props.lavanderia.moneda,
        minimumFractionDigits: 4,
    };

    updatePreciosPrenda_buttonOption = {
        text: "Aplicar",
        type: "success",
        onClick: () => {
            let dxDateBox_preciosPrenda = this.dxDateBox_preciosPrenda.option("value");
            let rows_tblPrenda_precios = this.dxDataGrid_tblPrenda_precios.getSelectedRowsData();

            if (rows_tblPrenda_precios.length > 0) {
                let _this = this;
                _this.setState({ isVisible_dxPopup_modificarPrecios: false }, () => {
                    _this.loadPanel_show(true);
                    _this.context_tblPrenda.invoke("UpdateMasivo", {}, "POST").done(function () {
                        _this.loadPanel_hide();
                        notify({
                            message: _this.getTrad("aviso_C_RegistroActualizado"),
                            type: "success",
                            displayTime: "1500",
                            closeOnClick: true,
                        });

                        _this.reset_popup_preciosPrenda();
                        setTimeout(() => {
                            _this.datasource_tblPrenda.reload().done(function (data) {
                                _this.setState({
                                    datasource_dxDataGrid_tblPrenda: data,
                                });
                            });
                        }, 100);
                    });
                });
            } else {
                notify({
                    message: "Seleccione al menos una prenda",
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }
        },
    };

    dxMultiView_pedidos_elementAttr = { id: "dxMultiView_pedidos_estructuraOrg" };

    dxSwitch_noDisabled_elementAttr = { class: "no-disabled" };

    dxSwitch_noDisabled_usuario_elementAttr = {
        id: "dxSwitch_noDisabled_usuario",
        class: "no-disabled",
    };

    AdjuntarArchivos_elementAttr = {
        class: "adjuntarArchivos_estructOrg",
    };

    column_fecha_editorOptions = {
        useMaskBehavior: true,
        displayFormat: "dd/MM/yyyy",
        calendarOptions: { max: new Date() },
    };
    //#endregion

    //#region TREEVIEW
    class_h3 = { class: "h3" };

    scroll_dxTreeView_compaEnti = { left: 0, top: 0 };
    //#endregion

    //#region POPUP ENTIDAD
    editorOptions_denominacion_addTipoHabitacion = {
        onValueChanged: function (e) {
            e.component.option("value", e.value.replace(/\s\s+/g, " ").toUpperCase());
        },
    };

    editorOptions_maxLength_50 = {
        maxLength: 50,
    };

    editorOptions_disabled = {
        maxLength: 50,
        disabled: true,
    };

    editorOptions_telefono = {
        maskInvalidMessage: this.getTrad("validation_Formato"),
        useMaskedValue: true,
        maxLength: 50,
    };

    dropDownOptions_dxDropDownBox_idCompañia = {
        toolbarItems: [
            {
                toolbar: "bottom",
                location: "after",
                widget: "dxButton",
                options: {
                    text: this.getTrad("compañia"),
                    icon: "plus",
                },
                onClick: () => {
                    this.setState({
                        isVisible_dxPopup_compañia: true,
                        compañiaSel: null,
                    });
                },
            },
        ],
    };

    editorOptions_dxDropDownBox_denominacion_popupEntidad = {
        maxLength: 50,
        onValueChanged: this.onValueChanged_dxDropDownBox_denominacion_popupEntidad,
    };

    elementAttr_tblPrendaNEntidad_NuevoPedido_compañia = {
        id: "dxButton_tblPrendaNEntidad_NuevoPedido_compañia",
    };
    elementAttr_tblPrendaNEntidad_NuevoPedido_entidad = {
        id: "dxButton_tblPrendaNEntidad_NuevoPedido_entidad",
    };

    elementAttr_tblPrendaNEntidad_NuevoPedido_usuario = {
        id: "dxButton_tblPrendaNEntidad_NuevoPedido_usuario",
    };

    elementAttr_dxForm_Entidad = {
        id: "dxTabPanel_dxForm_entidad",
        class: "h3",
    };

    dxValidator_tagBox_tipoPedido_elementAttr = {
        id: "dxValidator_tagBox_tipoPedido",
    };

    dxDataGrid_gestionCorreosAlbaranes_elementAttr = {
        id: "dxDataGrid_gestionCorreosAlbaranes",
    };

    editorOptions_idLocalizacion = {
        dataSource: this.datasource_datos_tblLocalizacion,
        displayExpr: "denominacion",
        valueExpr: "idLocalizacion",
    };

    editorOptions_idMoneda = {
        dataSource: this.datasource_datos_tblMoneda,
        valueExpr: "idMoneda",
        displayExpr: "denominacion",
        showClearButton: false,
    };

    editorOptions_decimal = {
        placeholder: "0",
        format: { style: "decimal", maximumFractionDigits: 0 },
        step: 0,
        min: 0,
        max: 99999,
    };

    editorOptions_visibleGP = {
        switchedOnText: this.getTrad("visible"),
        switchedOffText: this.getTrad("noVisible"),
    };

    editorOptions_idTipoRepartoEntidad = {
        dataSource: this.datasource_datos_tblTipoRepartoEntidad,
        displayExpr: "denominacion",
        valueExpr: "idTipoRepartoEntidad",
    };

    editorOptions_idTipoConsumoLenceria = {
        dataSource: this.datasource_datos_tblTipoConsumoLenceria,
        displayExpr: "denominacion",
        valueExpr: "idTipoConsumoLenceria",
        showClearButton: true,
        placeholder: this.getTrad("sinInforme").toUpperCase(),
    };

    editorOptions_reparteAlmGeneral = {
        switchedOnText: this.getTrad("reparte"),
        switchedOffText: this.getTrad("noReparte"),
    };

    editorOptions_reparteRechazoRetiro = {
        dataSource: this.enum_reparteRechazoRetiro,
        displayExpr: "denominacion",
        valueExpr: "id",
        showClearButton: true,
        placeholder: this.getTrad("noReparte").toUpperCase(),
    };

    editorOptions_inventarioPorEntidad = {
        switchedOnText: this.getTrad("porEntidad"),
        switchedOffText: this.getTrad("porCompañia"),
        elementAttr: { id: "dxSwitch_inventarioPorEntidad" },
        width: 300,
    };

    editorOptions_costeEstancia = {
        placeholder: "0",
        step: 0,
        format: {
            style: "currency",
            currency: this.props.lavanderia.moneda,
            minimumFractionDigits: 3,
        },
        min: 0,
        max: 99999.999,
    };

    editorOptions_objetivoKgEstancia = {
        placeholder: "0",
        format: { style: "decimal", minimumFractionDigits: 3 },
        step: 0,
        min: 0,
        max: 99999.999,
    };

    dxPopup_entidad_tipoFact_onSelectionChanged(e) {
        let { entidadSel_popup } = this.state;
        if (entidadSel_popup) {
            let idTipoFacturacionCliente = e.selectedItem ? e.selectedItem.idTipoFacturacionCliente : null;
            entidadSel_popup.idTipoFacturacionCliente = idTipoFacturacionCliente;

            this.setState({ entidadSel_popup });
            if (this.dxScrollView_dxForm_dxTab)
                this.dxScrollView_dxForm_dxTab.scrollTo(this.dxScrollView_dxForm_dxTab.scrollHeight());
        }
    }

    validationRules_configAdicional_tipoFact = [
        {
            type: "required",
            message: this.getTrad("tipoFact") + ": " + this.getTrad("campoNecesario"),
        },
    ];

    validationRules_configAdicional_objetivoKgEstancia = [
        {
            type: "required",
            message: this.getTrad("objetivoKgEstancia") + ": " + this.getTrad("campoNecesario"),
        },
        {
            type: "custom",
            reevaluate: true,
            validationCallback: this.validationCallback_mayorCero,
        },
    ];

    validationRules_configAdicional_costeEstancia = [
        {
            type: "required",
            message: this.getTrad("costeEstancia") + ": " + this.getTrad("campoNecesario"),
        },
        {
            type: "custom",
            reevaluate: true,
            validationCallback: this.validationCallback_mayorCero,
        },
    ];

    validationRules_configAdicional_idTipoRepartoEntidad = [
        {
            type: "required",
            message: this.getTrad("tipoReparto") + ": " + this.getTrad("campoNecesario"),
        },
    ];

    validationRules_pedidos_tblTipoPedidoNEntidad = [
        {
            type: "required",
            message: this.getTrad("tipoPedido") + ": " + this.getTrad("campoNecesario"),
        },
    ];

    validationRules_general_idMoneda = [{ type: "required" }];

    validationRules_general_idLocalizacion = [
        {
            type: "required",
            message: this.getTrad("localizacion") + ": " + this.getTrad("campoNecesario"),
        },
    ];

    validationRules_general_email = [
        {
            type: "pattern",
            pattern: email_Pattern,
            message: this.getTrad("email") + ": " + this.getTrad("validation_Formato"),
        },
    ];

    validationRules_general_telefono = [
        {
            type: "pattern",
            pattern: phone_Pattern,
            message: this.getTrad("validation_Formato"),
        },
    ];

    validationRules_general_denominacion = [
        {
            type: "required",
            message: this.getTrad("denominacion") + ": " + this.getTrad("campoNecesario"),
        },
        {
            type: "pattern",
            pattern: pattern_denominacion,
            message: this.getTrad("denominacion") + ": " + this.getTrad("validation_NoCaracteresEspeciales"),
        },
    ];

    validationRules_general_denominacion_noReq = [
        {
            type: "pattern",
            pattern: pattern_denominacion,
            message: this.getTrad("denominacion") + ": " + this.getTrad("validation_NoCaracteresEspeciales"),
        },
    ];

    tblTipoPedidoNEntidad_TagBoxButtons = {
        height: "100%",
        icon: "spindown",
        type: "default",
        onClick: (e) => this.onClick_tblTipoPedidoNEntidad_TagBoxButtons(e, this),
    };

    searchExpr_codigoDenoPrenda = ["codigoPrenda", "denominacion"];
    //#endregion

    //#region TABPANEL
    dxBox_secciones_elementAttr = {
        id: "dxBox_secciones",
    };

    elementAttr_dxTabPanel_Entidad = {
        id: "elementAttr_dxTabPanel_Entidad",
        class: "h3 dxTabPanel_Entidad dxTabHeader_fijo",
    };

    elementAttr_dxTabPanel_almacenes = {
        class: "no-header",
    };
    //#endregion

    //#region LISTAS EDIFICIOS_SECCIONES
    elementAttr_dxList_seccionNivel1 = { class: "dxList_no_loadPanel" };

    elementAttr_dxList_seccionNivel2 = { class: "dxList_no_loadPanel" };

    editorOptions_denominacion_secciones = {
        maxLength: 200,
        onValueChanged: this.onValueChanged_replaceUpperCase_denomiancion,
    };
    //#endregion

    //#region ALMACENES

    editorOptions_denominacion_almacen = {
        maxLength: 50,
        onValueChanged: this.onValueChanged_replaceUpperCase_denomiancion,
    };
    //#endregion

    //#region POPUP COMPAÑIA
    editorOptions_denominacion_popupCompañia = {
        maxLength: 50,
        onValueChanged: this.onValueChanged_denominacion_popupCompañia,
    };
    //#endregion

    //#region DATAGRID PRENDAS
    dxDataGrid_tblPrenda_confirmDeleteMessage = { confirmDeleteMessage: "" };
    tblPrenda_allowedPageSizes = [20, 50, 100];

    editorOptions_isExtra = {
        switchedOnText: this.getTrad("extra"),
        switchedOffText: this.getTrad("estandar"),
        width: "100px",
        height: "30px",
    };
    format_percPrecioDesmanche = { style: "percent", minimumFractionDigits: 1 };

    editorOptions_percPrecioDesmanche = {
        step: 0,
        format: { style: "percent", minimumFractionDigits: 1 },
        min: 0,
        max: 1,
    };

    format_decimal_maxCero = { style: "decimal", maximumFractionDigits: 0 };

    editorOptions_isPedidoPorPeticion = {
        elementAttr: this.dxSwitch_noDisabled_elementAttr,
        height: 28,
        width: 90,
        switchedOffText: "Stock",
        switchedOnText: "Petición",
    };

    editorOptions_stockDefinido = {
        mode: "number",
        min: 0,
        step: 0,
        placeholder: "0",
        format: { style: "decimal", maximumFractionDigits: 0 },
    };

    editorOptions_ratio = {
        mode: "number",
        min: 0,
        max: 10,
        step: 0.1,
        placeholder: "0",
        format: { style: "decimal", maximumFractionDigits: 2 },
    };

    editorOptions_udsAlmacenaje = {
        step: 0,
        format: { style: "decimal", maximumFractionDigits: 0 },
        min: 0,
        max: 30000,
    };

    editorOptions_udsXBacReparto = {
        step: 0,
        format: { style: "decimal", maximumFractionDigits: 0 },
        min: 1,
        max: 30000,
    };

    setCellValue_udsXBacReparto(newData, value) {
        newData.elementoReparto = value === 1 ? 1 : 2;
        newData.udsXBacReparto = value;
    }

    editorOptions_udsXBacOffice = {
        step: 0,
        format: { style: "decimal", maximumFractionDigits: 0 },
        min: 1,
        max: 30000,
    };

    setCellValue_udsXBacOffice(newData, value) {
        newData.elementoOffice = value === 1 ? 1 : 2;
        newData.udsXBacOffice = value;
    }

    editorOptions_udsXBacPedido = {
        step: 0,
        format: { style: "decimal", maximumFractionDigits: 0 },
        min: 1,
        max: 30000,
    };

    setCellValue_udsXBacPedido(newData, value) {
        newData.elementoPedido = value === 1 ? 1 : 2;
        newData.udsXBacPedido = value;
    }

    editorOptions_udsXBacProduccion = {
        step: 0,
        format: { style: "decimal", maximumFractionDigits: 0 },
        min: 1,
        max: 30000,
    };

    setCellValue_udsXBacProduccion(newData, value) {
        newData.elementoProduccion = value === 1 ? 1 : 2;
        newData.udsXBacProduccion = value;
    }

    editorOptions_activo = {
        switchedOnText: this.getTrad("activo"),
        switchedOffText: this.getTrad("noActivo"),
        width: "100px",
        height: "30px",
    };
    //#endregion

    //#region POPUP PRENDA
    elemTrans_editorOptions = {
        dataSource: this.datasource_datos_tblElemTrans,
        valueExpr: "idElemTrans",
        displayExpr: "denominacion",
        onValueChanged: (e) => {
            this.dxPopup_prendas_transporte_dxSelectBox_onValueChanged(e, this);
        },
    };

    dxNumberBox_udAlmacenaje_editorOptions = {
        mode: "number",
        min: 0,
        max: 30000,
        format: "#0",
        onValueChanged: this.dxPopup_prendas_dxNumberBox_onValueChanged,
    };

    dxNumberBox_udsPrenda_editorOptions = {
        mode: "number",
        min: 1,
        max: 30000,
        format: "#0",
        onValueChanged: this.dxPopup_prendas_dxNumberBox_onValueChanged,
    };

    dxNumberBox_coste_editorOptions = {
        mode: "number",
        min: 0,
        max: 99999.999,
        placeholder: 0,
        format: {
            style: "currency",
            currency: this.props.lavanderia.moneda,
            minimumFractionDigits: 3,
        },
        onValueChanged: this.dxPopup_prendas_dxNumberBox_onValueChanged,
    };

    dxNumberBox_parStock_editorOptions = {
        mode: "number",
        min: 0,
        max: 999999,
        format: "#0",
        placeholder: 0,
        onValueChanged: this.dxPopup_prendas_dxNumberBox_onValueChanged,
    };

    dxNumberBox_percPrecioDesmanche_editorOptions = {
        format: { style: "percent", minimumFractionDigits: 1 },
        min: 0,
        max: 1,
        onValueChanged: function (e) {
            if (!e.value) {
                e.component.option("value", e.component.option("min"));
            }
        },
    };

    dxNumberBox_tipoFact_editorOptions = {
        dataSource: this.datasource_datos_tblTipoFacturacion,
        valueExpr: "idTipoFacturacion",
        displayExpr: "denominacion",
    };

    dxSelectBox_idFamiliaFacturacion_editorOptions = {
        dataSource: this.datasource_datos_tblFamilia,
        valueExpr: "idFamilia",
        displayExpr: "denominacion",
    };

    dxSwitch_categoria_editorOptions = {
        switchedOnText: getTrad("extra"),
        switchedOffText: getTrad("estandar"),
    };

    dxNumberBox_peso_editorOptions = {
        min: 0,
        max: 100000,
        format: "#,##0 g",
        onValueChanged: this.dxPopup_prendas_dxNumberBox_onValueChanged,
    };

    dxSelectBox_marcaTapa_editorOptions = {
        dataSource: this.datasource_datos_tblMarcaTapa,
        valueExpr: "idMarcaTapa",
        displayExpr: "marca",
        showClearButton: true,
        onValueChanged: this.dxPopup_prendas_dxSelectBox_marcaTapa_onValueChanged,
    };

    dxSelectBox_colorTapa_editorOptions = {
        dataSource: this.datasource_datos_tblColorTapa,
        valueExpr: "idColorTapa",
        displayExpr: "denominacion",
        showClearButton: true,
        onValueChanged: (e) => {
            this.dxPopup_prendas_dxSelectBox_colorTapa_onValueChanged(e, this);
        },
    };

    dropDownOptions_tapa = {
        onContentReady: this.onContentReady_tapa,
        width: 300,
        height: 410,
    };
    //#endregion

    elementAttr_dxForm_entidad = { id: "dxForm_entidad" };

    elementAttr_dxDataGrid_tblEntidadesAsociadas = {
        id: "dxDataGrid_tblEntidadesAsociadas",
    };

    elementAttr_dxTreeView_compaEnti = { id: "dxTreeView_compaEnti" };

    elementAttr_dxButton_addPrendaTipoHabitacion = {
        id: "dxButton_addPrendaTipoHabitacion",
    };

    //#endregion

    //#region EVENTOS

    //#region TREEVIEW
    onClick_AddEntidad(e) {
        const _this = this;

        this.datasource_datos_tblColorTapa.load().done((data) => {
            let idSacasPendientes = 0;

            const entidadSel_popup = {
                idEntidad: null,
                idCompañia: null,
                visibleGP: true,
                reparteAlmGeneral: true,
                inventarioPorEntidad: false,
                objKgEstancia: null,
                costeEstancia: null,

                plazas: null,
                habitaciones: null,
                idTipoFacturacionCliente: 0,
                idLocalizacion: null,
                idMoneda: null,

                isPrincipal: true,
                tblCorreosNEntidad: [],
                tblTipoHabitacion: [],
                tblTipoPedidoNEntidad: [null],
                tblPrendaNEntidad_NuevoPedido: [],
                tblEntidadNEntidadPrincipal: [],
                tblEntidadNEntidadSecundaria: [],
                isTodasPrendaNNuevoPedido_compañia: true,
                isTodasPrendaNNuevoPedido_entidad: true,
                tblHorarioRepartoNEntidad: [],
                tblSacasPendientes: [
                    ...data,
                    {
                        idColorTapa: null,
                        tblColorTapa: { codigoHexadecimal: null },
                    },
                ].map((color) => {
                    //* Añade los elementos lógisticos por defecto a cantidad 0
                    return {
                        idEntidad: null,
                        cantidad: 0,
                        idSacasPendientes: ++idSacasPendientes,
                        idColorTapa: color.idColorTapa,
                        tblColorTapa: { codigoHexadecimal: color.codigoHexadecimal },
                    };
                }),
            };

            _this.setState(
                {
                    isVisible_dxPopup_configEntidad: true,
                    entidadSel_popup: entidadSel_popup,
                    horariosGuardados: [],
                    sacasPendientesGuardadas: entidadSel_popup.tblSacasPendientes,
                    entidadSel: null,
                    isDisabled_dxForm_entidad_tblEntidadNEntidadAsociada: true,
                },
                () => {
                    this.dxForm_entidad.resetValues();
                }
            );
        });
    }

    dxTreeView_compaEnti_onContentReady(e) {
        //PARA QUE EL SCROLL QUEDE A LA DERECHA Y SE VEA MEJOR
        e.element.find(".dx-scrollable-scroll-content").css({
            "margin-left": 2,
        });
    }

    dxTreeView_compaEnti_onItemClick(e) {
        let data = e.node.itemData;

        if (e.node.selected) {
            e.event.preventDefault();
            this.setState({ entidadSel: data });
        } else {
            if (data.idEntidad) {
                data.idCompañia = e.node.parent.key;
                this.setState({
                    compañiaSel: null,
                    entidadSel: data,
                    dxTabPanel_prendasAlm_selctedIndex: 0,
                    seccionNivel1_Sel: null,
                    isVisible_loading_dxBox_secciones: true,
                });
            } else
                this.setState({
                    compañiaSel: data,
                    entidadSel: null,
                    dxTabPanel_prendasAlm_selctedIndex: 0,
                    selectedIndex_dxTabPanel_almacenPrenda: 0,
                    seccionNivel1_Sel: null,
                    isVisible_loading_dxBox_secciones: true,
                });

            setTimeout(() => {
                let _this = this;
                $.when(
                    this.datasource_tblPrenda.reload(),
                    this.datasource_datos_secciones.reload(),
                    this.datasource_datos_tblTipoHabitacion.reload()
                ).then(function (prendas, secciones, tiposHabitacion) {
                    $.each(tiposHabitacion[0], function (index, tipoHabitacion) {
                        $.each(tipoHabitacion.tblPrendaNTipoHabitacion, function (index, prendaNTipoHabitacion) {
                            prendaNTipoHabitacion.actual = 0;
                            prendaNTipoHabitacion.stock = prendaNTipoHabitacion.parStock;
                            delete prendaNTipoHabitacion.parStock;
                        });
                    });

                    _this.dxList_seccionNivel1.option("searchValue", "");
                    _this.dxList_seccionNivel2.option("searchValue", "");

                    _this.setState({
                        datasource_dxDataGrid_tblPrenda: prendas[0],
                        datasource_datos_secciones: secciones[0],
                        datasource_tblSeccionNivel2: [],
                        datasource_datos_tblTipoHabitacion_items: tiposHabitacion[0],
                        isVisible_loading_dxBox_secciones: false,
                    });
                });
            }, 0);
        }
    }

    dxTreeView_compaEnti_itemRender(item) {
        let is_disabled = item.activo === false;
        return (
            <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                <ItemBox ratio={1}>
                    <div
                        className={is_disabled ? "disabledRow dxTreeView_deno_compaEnti" : "dxTreeView_deno_compaEnti"}
                    >
                        {item.denominacion}
                    </div>
                </ItemBox>
                <ItemBox baseSize={70}>
                    <Toolbar elementAttr={this.elementAttr_toolbarClass}>
                        <ToolbarItem location="after">
                            <div className="container_spanCentrado dxToolbar_opcion p-2">
                                <div
                                    className="font-size-lg icon_Edit center-svg"
                                    onClick={(e) => {
                                        this.compañiaSel_editButton_onClick(e, item);
                                    }}
                                />
                            </div>
                        </ToolbarItem>
                        <ToolbarItem location="after">
                            <div className="container_spanCentrado dxToolbar_opcion p-2">
                                <div
                                    className="font-size-lg icon_Cerrar center-svg center-svg"
                                    onClick={(e) => {
                                        this.compañiaSel_removeButton_onClick(e, item);
                                    }}
                                />
                            </div>
                        </ToolbarItem>
                    </Toolbar>
                </ItemBox>
            </Box>
        );
    }

    compañiaSel_editButton_onClick(e, item) {
        let _this = this;
        if (item.idEntidad) {
            _this.setState({
                entidadSel_popup: $.extend(true, {}, item),
                horariosGuardados: [...item.tblHorarioRepartoNEntidad],
                sacasPendientesGuardadas: [...item.tblSacasPendientes],
                compañiaSel: null,
                isVisible_dxPopup_configEntidad: true,
                isVisible_loading_dxPopup_configEntidad: true,
                isVisible_loading_dxPopup_configEntidad_pedidos: true,
                isDisabled_dxForm_entidad_tblEntidadNEntidadAsociada: false,
            });
        } else {
            _this.datasource_datos_compaEnti_compañia
                .store()
                .byKey(item.idCompañia)
                .done(function (compañia) {
                    _this.setState({
                        entidadSel_popup: null,
                        compañiaSel: $.extend(true, {}, compañia),
                        isVisible_dxPopup_compañia: true,
                    });
                });
        }
    }

    compañiaSel_removeButton_onClick(e, item) {
        let _this = this;
        if (item.idEntidad) {
            dxMensajePregunta(getTrad("preg_RegistroEliminarCorto"), [
                [
                    _this.getTrad("aceptar"),
                    function (e) {
                        _this.datasource_datos_tblEntidadNLavanderia
                            .store()
                            .remove(item.idEntidad)
                            .done(function () {
                                notify({
                                    message: _this.getTrad("aviso_C_RegistroEliminado"),
                                    type: "success",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                });

                                _this.setState({
                                    compañiaSel: null,
                                    entidadSel: null,
                                    entidadSel_popup: null,
                                });
                                _this.datasource_datos_compaEnti.load().done(function () {
                                    $("#dxTreeView_compaEnti")
                                        .find(".dx-scrollable")
                                        .data("dxScrollable")
                                        .scrollTo(_this.scroll_dxTreeView_compaEnti);
                                });
                            });
                    },
                    "danger",
                ],
                [_this.getTrad("cancelar"), function () { }],
            ]);
        } else {
            dxMensajePregunta(this.getTrad("preg_eliminarCompañia"), [
                [
                    this.getTrad("aceptar"),
                    function (e) {
                        _this.datasource_datos_compaEnti_compañia
                            .store()
                            .remove(item.idCompañia)
                            .done(function () {
                                notify({
                                    message: _this.getTrad("aviso_C_RegistroEliminado"),
                                    type: "success",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                });

                                _this.setState({
                                    compañiaSel: null,
                                    entidadSel: null,
                                    entidadSel_popup: null,
                                });
                                _this.datasource_datos_compaEnti.load().done(function () {
                                    $("#dxTreeView_compaEnti")
                                        .find(".dx-scrollable")
                                        .data("dxScrollable")
                                        .scrollTo(_this.scroll_dxTreeView_compaEnti);
                                });
                            });
                    },
                    "danger",
                ],
                [this.getTrad("cancelar"), function () { }],
            ]);
        }
    }
    //#endregion

    //#region POPUP COMPAÑIA
    onValueChanged_denominacion_popupCompañia(e) {
        e.component.option("value", e.value.replace(/\s\s+/g, " ").toUpperCase());
    }

    onShowing_dxPopup_compañia(e) {
        let { compañiaSel } = this.state;
        var form = this.dxForm_compañia;
        var title = $("#dxPopup_compañia_title");
        if (!compañiaSel) {
            title.text(this.getTrad("nuevaCompañia"));
            form.option("formData", { activo: true, visibleGP: true });
        } else {
            title.text(compañiaSel.denominacion);
            form.option("formData", $.extend(true, {}, compañiaSel));
        }
    }

    onHiding_dxPopup_compañia(e) {
        this.setState({ isVisible_dxPopup_compañia: false });
    }

    onShown_dxPopup_compañia(e) {
        this.dxForm_compañia.focus();
    }

    onHidden_dxPopup_compañia(e) {
        this.dxForm_compañia.resetValues();
        this.dxForm_compañia.repaint();
    }

    render_dxButton_guardar_dxPopup_compañia(itemData) {
        return <Button text={this.getTrad("guardar")} onClick={this.onClick_guardar_dxPopup_compañia} />;
    }

    render_dxButton_cancelar_dxPopup_compañia(itemData) {
        return <Button text={this.getTrad("cancelar")} onClick={this.onClick_cancelar_dxPopup_compañia} />;
    }

    onClick_guardar_dxPopup_compañia(e) {
        let _this = this;
        var form = this.dxForm_compañia;
        let { entidadSel_popup, arrayCompañias_dxDropDownBox_compañia, compañiaSel } = this.state;
        var formData = form.option("formData");
        if (formData && form.validate().isValid) {
            if (typeof formData.idCompañia === "undefined") {
                //POST
                //Se inserta desde dentro del dxPopup_entidad.
                var idCompañia = this.state.arrayCompañias_dxDropDownBox_compañia.length * -1;
                arrayCompañias_dxDropDownBox_compañia.push({
                    idCompañia: idCompañia,
                    denominacion: formData.denominacion,
                    visibleGP: formData.visibleGP,
                });

                entidadSel_popup.idCompañia = idCompañia;

                _this.dxList_addCompañia.repaint();
                _this.setState({
                    entidadSel_popup: entidadSel_popup,
                    isVisible_dxPopup_compañia: false,
                    isVisible_dxDropDownBox_idCompañiaAsociada: false,
                    isDisabled_dxForm_entidad_tblEntidadNEntidadAsociada: true,
                });
            } else {
                this.datasource_datos_compaEnti_compañia
                    .store()
                    .update(formData.idCompañia, formData)
                    .done(function () {
                        _this.datasource_datos_compaEnti.load().done(function () {
                            compañiaSel.denominacion = formData.denominacion;

                            $("#dxTreeView_compaEnti")
                                .find(".dx-scrollable")
                                .data("dxScrollable")
                                .scrollTo(_this.scroll_dxTreeView_compaEnti);

                            _this.setState({
                                isVisible_dxPopup_compañia: false,
                                isDisabled_dxForm_entidad_tblEntidadNEntidadAsociada: true,
                                compañiaSel: compañiaSel,
                            });
                            notify({
                                message: _this.getTrad("aviso_C_RegistroActualizado"),
                                type: "success",
                                displayTime: "1500",
                                closeOnClick: true,
                            });
                        });
                    });
            }
        } else {
            form._scrollable.scrollToElement(form.element().find(".dx-invalid"));
        }
    }

    onClick_cancelar_dxPopup_compañia(e) {
        this.setState({ isVisible_dxPopup_compañia: false });
    }
    //#endregion

    //#region POPUP ENTIDAD
    //CALENDARIO

    yearCalendar_onDateChanged(data, isCalendar_valid) {
        let entidadSel_popup = { ...this.state.entidadSel_popup };
        entidadSel_popup.tblCalendarioEntidad = data;
        this.setState({ entidadSel_popup, isCalendar_valid: isCalendar_valid }, () => {
            this.loadPanel_hide();
        });
    }

    onContentReady_dxPopup_configEntidad(e) {
        e.component.content().parent().attr("id", "dxPopup_configEntidad");
    }

    onShown_dxPopup_configEntidad(e) {
        //#region Carga Datagrid Entidades Asociadas
        let entidadSel_popup = { ...this.state.entidadSel_popup };
        let datasource = new ArrayStore({
            key: "idEntidad",
            data: entidadSel_popup.isPrincipal
                ? entidadSel_popup.tblEntidadNEntidadPrincipal
                : entidadSel_popup.tblEntidadNEntidadSecundaria,
        });
        //#endregion

        //#region Crea backup grids tblPrendaNEntidad_NuevoPedido
        let bk_tblPrendaNEntidad_NuevoPedido_compañia = [];
        let bk_tblPrendaNEntidad_NuevoPedido_entidad = [];
        $.each($.extend(true, {}, entidadSel_popup.tblPrendaNEntidad_NuevoPedido), function (index, item) {
            if (item.tblPrenda.idEntidad) bk_tblPrendaNEntidad_NuevoPedido_entidad.push(item);
            else bk_tblPrendaNEntidad_NuevoPedido_compañia.push(item);
        });
        //#endregion

        let _this = this;

        //#region Carga Datasources Listas
        _this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia.repaint();
        _this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad.repaint();
        $.when(
            this.datasource_tblPrendaNEntidad.load(),
            this.datasource_tblPrendaNEntidad_compañia.load(),
            this.datasource_datos_tblUsuario.reload()
        ).then(function (prendasEnti, prendasCompa, tblPrendaNUsuarioNEntidad) {
            $.each(tblPrendaNUsuarioNEntidad[0], function (iUsuario, usuario) {
                usuario.isTodas_tblPrendaNUsuario = usuario.tblPrendaNUsuarioNEntidad.length === 0;
                $.each(usuario.tblPrendaNUsuarioNEntidad, function (iPrenda, prenda) {
                    let prenda_NuevoPedido = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
                        return item.idPrenda == prenda.idPrenda;
                    });
                    if (prenda_NuevoPedido.length > 0)
                        usuario.tblPrendaNUsuarioNEntidad[iPrenda] = prenda_NuevoPedido[0];
                });
            });
            let filtroPrendasCompa = entidadSel_popup["isTodasPrendaNNuevoPedido_compañia"];
            let filtroPrendasEnti = entidadSel_popup["isTodasPrendaNNuevoPedido_entidad"];

            $("#dxButton_tblPrendaNEntidad_NuevoPedido_compañia")
                .dxButton("instance")
                .option("disabled", filtroPrendasCompa);
            $("#dxButton_tblPrendaNEntidad_NuevoPedido_entidad")
                .dxButton("instance")
                .option("disabled", filtroPrendasEnti);

            $.each([prendasCompa[0], prendasEnti[0]], function (indexDataSource, dataSource) {
                let newItems = [];
                if (
                    (indexDataSource == 0 && filtroPrendasCompa == true) ||
                    (indexDataSource == 1 && filtroPrendasEnti == true)
                ) {
                    $(dataSource).each(function (index, item) {
                        let inArray = entidadSel_popup.tblPrendaNEntidad_NuevoPedido.find(function (element) {
                            return element.idPrenda === item.idPrenda;
                        });

                        if (!inArray) {
                            //nuevo
                            let itemFormated = {
                                idPrenda: item.idPrenda,
                                idEntidad: entidadSel_popup.idEntidad,
                                stockDefinido: null,
                                isPedidoPorPeticion: true,
                                tblPrenda: item,
                            };
                            newItems.push(itemFormated);
                        }
                    });
                    $.merge(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, newItems);
                    $.merge(bk_tblPrendaNEntidad_NuevoPedido_compañia, newItems);
                }
            });

            //#endregion
            entidadSel_popup.tblPrendaNUsuarioNEntidad = tblPrendaNUsuarioNEntidad[0];
            _this.setState({
                datasource_tblPrendaNEntidad: prendasEnti[0],
                datasource_tblPrendaNEntidad_compañia: prendasCompa[0],
                datasource_dxDataGrid_tblEntidadesAsociadas: datasource,
                bk_tblPrendaNEntidad_NuevoPedido_compañia: $.extend(
                    true,
                    [],
                    bk_tblPrendaNEntidad_NuevoPedido_compañia
                ),
                bk_tblPrendaNEntidad_NuevoPedido_entidad: $.extend(true, [], bk_tblPrendaNEntidad_NuevoPedido_entidad),
                yearCalendar_currentYear: new Date().getFullYear(),
                isCalendar_valid: true,
                entidadSel_popup: entidadSel_popup,
                isVisible_loading_dxPopup_configEntidad: false,
                isLoaded_popupEntidad: true,
                isVisible_loading_dxPopup_configEntidad_pedidos: false,
            });
        });
        this.dxDataGrid_tblPrendaNTipoHabitacion.repaint(); // Para repintar toolbar y activar o no botón
    }

    onHiding_dxPopup_configEntidad(e) {
        this.setState(
            {
                horariosGuardados: [],
                sacasPendientesGuardadas: [],
                isVisible_dxPopup_configEntidad: false,
                yearCalendar_currentYear: null,
            },
            () => {
                this.dxScrollView_dxForm_dxTab.scrollTo(0);
            }
        );
    }

    onHidden_dxPopup_configEntidad(e) {
        //SET STATE AQUÍ Y NO EN HIDING PARA QUE NO SE VEA COMO SE BORRAN DATOS
        this.setState({
            dxList_tipoHabitacionSel: null,
            isVisible_warning_general: false,
            isVisible_warning_pedidos: false,
            isVisible_warning_entidadesAsociadas: false,
            isValid_dxDataGrid_tblPrendaNEntidad_NuevoPedido: true,
            datasource_dxDataGrid_tblPrendaNTipoHabitacion: [],
            selectedIndex_dxForm_entidad: 0,
            isDisabled_dxButton_addPrendaTipoHabitacion: true,
            dxList_tblTipoHabitacion_selectedItemKeys: [],
            selectedItems_tblPrendaNEntidad_NuevoPedido_entidad_datasource: [],
            selectedItems_tblPrendaNEntidad_NuevoPedido_compañia_datasource: [],
            selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource: [],
            datasource_dxDataGrid_tblEntidadesAsociadas: [],
            bk_tblPrendaNEntidad_NuevoPedido_compañia: [],
            bk_tblPrendaNEntidad_NuevoPedido_entidad: [],
            entidadSel_popup: null,
            dxTabPanel_Entidad_tabIndex: null,
            isVisible_loading_dxPopup_configEntidad_pedidos: false,
            dxButtonGroup_prendasPedido_idSel: 0,
            usuarioSel_prendasNUsuario_NuevoPedido: null,
            isLoaded_popupEntidad: false,
        });

        if (this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia_REF["current"]) {
            this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia.cancelEditData();
        }
        if (this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad_REF["current"]) {
            this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad.cancelEditData();
        }
        if (this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario_REF["current"]) {
            this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario.cancelEditData();
        }

        e.component.option("disabled", false);
        $("#elementAttr_dxTabPanel_Entidad").dxTabPanel("instance").option("selectedIndex", 0);
    }

    dxScrollView_dxForm_dxTab_onScroll(e) {
        e.element.find(".dx-tabpanel-tabs").css({ top: e.scrollOffset.top });
    }

    onClick_guardar_tblPrendaNEntidad_NuevoPedido(e) {
        let {
            entidadSel_popup,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario,
            selectedItems_tblPrendaNEntidad_NuevoPedido_compañia_datasource,
            selectedItems_tblPrendaNEntidad_NuevoPedido_entidad_datasource,
            selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource,
            usuarioSel_prendasNUsuario_NuevoPedido,
        } = this.state;
        var prendasCompañia = selectedItems_tblPrendaNEntidad_NuevoPedido_compañia_datasource;
        var prendasEntidad = selectedItems_tblPrendaNEntidad_NuevoPedido_entidad_datasource;
        var prendasUsuario = selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource;
        let tblPrendaNEntidad_NuevoPedido = [];
        let tblPrendaNUsuario = [];

        if (isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario && prendasUsuario.length == 0) {
            notify({
                message: "Debe añadir al menos una prenda.",
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        } else {
            this.setState(
                {
                    isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia: false,
                    isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad: false,
                    isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario: false,
                    isVisible_loading_dxPopup_configEntidad_pedidos: true,
                },
                () => {
                    if (isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia) {
                        $.each(prendasCompañia, function (index, prendaCompa) {
                            let prendaNGrid = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
                                return item.idPrenda === prendaCompa.idPrenda;
                            });
                            let itemFormated = {
                                idPrenda: prendaCompa.idPrenda,
                                idEntidad: entidadSel_popup.idEntidad,
                                stockDefinido: prendaNGrid.length > 0 ? prendaNGrid[0].stockDefinido : null,
                                ratio: prendaNGrid.length > 0 ? prendaNGrid[0].ratio : null,
                                isPedidoPorPeticion: prendaNGrid.length > 0 ? prendaNGrid[0].isPedidoPorPeticion : true,
                                tblPrenda: prendaCompa,
                            };
                            tblPrendaNEntidad_NuevoPedido.push(itemFormated);
                        });

                        let prendasEnti = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
                            return item.tblPrenda.idEntidad;
                        });
                        $.merge(tblPrendaNEntidad_NuevoPedido, prendasEnti);
                        entidadSel_popup.tblPrendaNEntidad_NuevoPedido = tblPrendaNEntidad_NuevoPedido;

                        $.each(entidadSel_popup.tblPrendaNUsuarioNEntidad, function (index, item) {
                            if (
                                usuarioSel_prendasNUsuario_NuevoPedido &&
                                item.idUsuario == usuarioSel_prendasNUsuario_NuevoPedido.idUsuario &&
                                usuarioSel_prendasNUsuario_NuevoPedido.isTodas_tblPrendaNUsuario
                            ) {
                                item.tblPrendaNUsuarioNEntidad = $.extend(true, [], tblPrendaNEntidad_NuevoPedido);
                            }
                        });
                    } else if (isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad) {
                        $.each(prendasEntidad, function (index, prendaEnti) {
                            let prendaNGrid = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
                                return item.idPrenda === prendaEnti.idPrenda;
                            });
                            let itemFormated = {
                                idPrenda: prendaEnti.idPrenda,
                                idEntidad: prendaEnti.idEntidad,
                                stockDefinido: prendaNGrid.length > 0 ? prendaNGrid[0].stockDefinido : null,
                                ratio: prendaNGrid.length > 0 ? prendaNGrid[0].ratio : null,
                                isPedidoPorPeticion: prendaNGrid.length > 0 ? prendaNGrid[0].isPedidoPorPeticion : true,
                                tblPrenda: prendaEnti,
                            };
                            tblPrendaNEntidad_NuevoPedido.push(itemFormated);
                        });

                        let prendasCompa = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
                            return !item.tblPrenda.idEntidad;
                        });
                        $.merge(tblPrendaNEntidad_NuevoPedido, prendasCompa);
                        entidadSel_popup.tblPrendaNEntidad_NuevoPedido = tblPrendaNEntidad_NuevoPedido;

                        $.each(entidadSel_popup.tblPrendaNUsuarioNEntidad, function (index, item) {
                            if (
                                usuarioSel_prendasNUsuario_NuevoPedido &&
                                item.idUsuario == usuarioSel_prendasNUsuario_NuevoPedido.idUsuario &&
                                usuarioSel_prendasNUsuario_NuevoPedido.isTodas_tblPrendaNUsuario
                            ) {
                                item.tblPrendaNUsuarioNEntidad = $.extend(true, [], tblPrendaNEntidad_NuevoPedido);
                            }
                        });
                    } else if (isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario) {
                        $.each(prendasUsuario, function (index, prenda) {
                            let prendaNGrid = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
                                return item.idPrenda === prenda.idPrenda;
                            });
                            let itemFormated = {
                                idPrenda: prenda.idPrenda,
                                idEntidad: entidadSel_popup.idEntidad,
                                stockDefinido: prendaNGrid.length > 0 ? prendaNGrid[0].stockDefinido : null,
                                isPedidoPorPeticion: prendaNGrid.length > 0 ? prendaNGrid[0].isPedidoPorPeticion : true,
                                tblPrenda: prenda,
                            };
                            tblPrendaNUsuario.push(itemFormated);
                        });

                        $.each(entidadSel_popup.tblPrendaNUsuarioNEntidad, function (index, item) {
                            if (item.idUsuario === usuarioSel_prendasNUsuario_NuevoPedido.idUsuario) {
                                item.tblPrendaNUsuarioNEntidad = tblPrendaNUsuario;
                            }
                        });
                    }

                    this.setState({
                        isVisible_loading_dxPopup_configEntidad_pedidos: false,
                        entidadSel_popup: entidadSel_popup,
                    });
                }
            );
        }
    }

    onClick_cancelar_tblPrendaNEntidad_NuevoPedido(e) {
        this.setState({
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia: false,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad: false,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario: false,
        });
    }

    dxButton_guardar_tblPrendaNTipoHabitacion(itemData) {
        return <Button text={this.getTrad("guardar")} onClick={this.onClick_guardar_tblPrendaNTipoHabitacion} />;
    }

    dxButton_cancelar_tblPrendaNTipoHabitacion(itemData) {
        return <Button text={this.getTrad("cancelar")} onClick={this.onClick_cancelar_tblPrendaNTipoHabitacion} />;
    }

    render_dxButton_guardar_addTipoHabitacion(e) {
        return <Button text={this.getTrad("guardar")} onClick={this.onClick_guardar_addTipoHabitacion}></Button>;
    }

    render_dxButton_cancelar_addTipoHabitacion(e) {
        return (
            <Button text={this.getTrad("cancelar")} onClick={this.onClick_cancelar_dxPopup_addTipoHabitacion}></Button>
        );
    }

    onClick_guardar_tblPrendaNTipoHabitacion() {
        let {
            datasource_dxDataGrid_tblPrendaNTipoHabitacion,
            selectedItems_dxList_prendasEntidad,
            selectedItems_dxList_prendasCompañia,
        } = this.state;
        var tblPrendaNTipoHabitacion = this.dxDataGrid_tblPrendaNTipoHabitacion.getDataSource();

        var prendasCompañia = selectedItems_dxList_prendasCompañia;
        var prendasEntidad = selectedItems_dxList_prendasEntidad;

        //Borrar prendas de tblPrendaNTipoHabitacion que no estén en alguna lista
        $.each(tblPrendaNTipoHabitacion.items(), function (index, prenda) {
            var encontradaEnCompa = false;
            var encontradaEnEnti = false;

            encontradaEnCompa =
                $.grep(prendasCompañia, function (prendaCompa) {
                    return prendaCompa.idPrenda === prenda.idPrenda;
                }).length > 0;

            encontradaEnEnti =
                $.grep(prendasEntidad, function (prendaEnti) {
                    return prendaEnti.idPrenda === prenda.idPrenda;
                }).length > 0;

            if (!encontradaEnCompa && !encontradaEnEnti) {
                tblPrendaNTipoHabitacion.store().remove(prenda.idPrenda);
            }
        });

        //Añadir aquellas que se han seleccionado pero no están en el formData
        $.each(prendasCompañia, function (index, prendaCompa) {
            var isDuplicada =
                $.grep(tblPrendaNTipoHabitacion.items(), function (prenda) {
                    return prendaCompa.idPrenda === prenda.idPrenda;
                }).length > 0;

            if (!isDuplicada) {
                datasource_dxDataGrid_tblPrendaNTipoHabitacion.insert({
                    idPrenda: prendaCompa.idPrenda,
                    parStock: 0,
                    tblPrenda: {
                        codigoPrenda: prendaCompa.codigoPrenda,
                        denominacion: prendaCompa.denominacion,
                        idCompañia: prendaCompa.idCompañia,
                        idEntidad: prendaCompa.idEntidad,
                    },
                });
            }
        });

        $.each(prendasEntidad, function (index, prendaEnti) {
            var isDuplicada =
                $.grep(tblPrendaNTipoHabitacion.items(), function (prenda) {
                    return prendaEnti.idPrenda === prenda.idPrenda;
                }).length > 0;

            if (!isDuplicada) {
                datasource_dxDataGrid_tblPrendaNTipoHabitacion.insert({
                    idPrenda: prendaEnti.idPrenda,
                    parStock: 0,
                    tblPrenda: {
                        codigoPrenda: prendaEnti.codigoPrenda,
                        denominacion: prendaEnti.denominacion,
                        idCompañia: prendaEnti.idCompañia,
                        idEntidad: prendaEnti.idEntidad,
                    },
                });
            }
        });

        this.setState({
            datasource_dxDataGrid_tblPrendaNTipoHabitacion: datasource_dxDataGrid_tblPrendaNTipoHabitacion,
            isVisible_dxPopup_tblPrendaNTipoHabitacion: false,
        });
        this.dxDataGrid_tblPrendaNTipoHabitacion.refresh(true);
    }

    onClick_cancelar_tblPrendaNTipoHabitacion() {
        this.setState({ isVisible_dxPopup_tblPrendaNTipoHabitacion: false });
    }

    onClick_guardar_addTipoHabitacion(data) {
        var form = this.dxForm_addTipoHabitacion;
        var formData = form.option("formData");
        var item;

        let { entidadSel_popup } = this.state;

        if (formData && form.validate().isValid) {
            var tblTipoHabitacion = entidadSel_popup.tblTipoHabitacion;
            var dxList_tblTipoHabitacion = this.dxList_tblTipoHabitacion;
            var selectedItemKeys = dxList_tblTipoHabitacion.option("selectedItemKeys");

            if (selectedItemKeys.length === 0) {
                //INSERT
                item = {
                    idTipoHabitacion: (tblTipoHabitacion.length + 1) * -1,
                    denominacion: formData.denominacion,
                    tblPrendaNTipoHabitacion: [],
                };
                tblTipoHabitacion.push(item);
            } //UPDATE
            else {
                $.each(tblTipoHabitacion, function (index, tipoHabitacion) {
                    if (tipoHabitacion.idTipoHabitacion === selectedItemKeys[0]) {
                        tipoHabitacion.denominacion = formData.denominacion;
                    }
                });

                item = dxList_tblTipoHabitacion.option("selectedItems")[0];
            }

            dxList_tblTipoHabitacion.reload();
            dxList_tblTipoHabitacion.repaint();
            dxList_tblTipoHabitacion.selectItem(item);

            this.setState({ isVisible_dxPopup_addTipoHabitacion: false });

            this.dxDataGrid_tblPrendaNTipoHabitacion.repaint();
        }
    }

    onShown_addTipoHabitacion(e) {
        this.dxForm_addTipoHabitacion.focus();
    }

    onHiding_addTipoHabitacion(e) {
        this.setState({ isVisible_dxPopup_addTipoHabitacion: false });
        this.dxForm_addTipoHabitacion.resetValues();
    }

    onClick_PopupEntidad_guardar(e) {
        let _this = this;
        let { entidadSel_popup, isCalendar_valid, horariosGuardados, sacasPendientesGuardadas } = this.state;

        const entidadSel_popup_ = $.extend(true, {}, entidadSel_popup);

        function saveForm() {
            let { isValid_dxDataGrid_tblPrendaNEntidad_NuevoPedido } = _this.state;
            let isEntiSec_sinEntidad =
                !entidadSel_popup_.isPrincipal && entidadSel_popup_.tblEntidadNEntidadSecundaria.length === 0;

            let planificadorPedidos_isValid = entidadSel_popup_.enablePlanificadorPedidos
                ? entidadSel_popup_.tblPrendaNEntidad_NuevoPedido.reduce((value, prenda) => {
                    if (!value) return value;
                    return prenda.ratio != null;
                }, true)
                : true;

            if (!planificadorPedidos_isValid) {
                notify({
                    message: _this.getTrad("error_validacionPlanificadorPedidos"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            } else if (isEntiSec_sinEntidad || !isCalendar_valid) {
                _this.setState({
                    isVisible_warning_entidadesAsociadas: isEntiSec_sinEntidad,
                    isVisible_warning_general: false,
                    isVisible_warning_pedidos: false,
                    isValid_dxDataGrid_tblPrendaNEntidad_NuevoPedido: true,
                });

                notify({
                    message: isEntiSec_sinEntidad
                        ? _this.getTrad("aviso_EntidadSecundariaSinEntidad")
                        : _this.getTrad("error_validacionCalendario"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            } else {
                var form = _this.dxForm_entidad;
                let formData = $.extend(true, {}, form.option("formData"));

                if (formData && form.validate().isValid) {
                    //#region Tratamiento de datos

                    $.each(formData.tblTipoHabitacion, function (index, tipoHabitacion) {
                        $.each(tipoHabitacion.tblPrendaNTipoHabitacion, function (index, prendaNTipoHabitacion) {
                            delete prendaNTipoHabitacion.tblPrenda;
                        });
                    });

                    let tblPrendaNUsuarioNEntidad = [];
                    $.each(formData.tblPrendaNUsuarioNEntidad, function (index, usuario) {
                        if (!usuario.isTodas_tblPrendaNUsuario) {
                            let formatData = $.map(usuario.tblPrendaNUsuarioNEntidad, function (item) {
                                return {
                                    idPrenda: item.idPrenda,
                                    idEntidad: item.idEntidad,
                                    idUsuario: usuario.idUsuario,
                                };
                            });
                            $.merge(tblPrendaNUsuarioNEntidad, formatData);
                        } else {
                            tblPrendaNUsuarioNEntidad = $.grep(tblPrendaNUsuarioNEntidad, function (item) {
                                return item.idUsuario != usuario.idUsuario;
                            });
                        }

                        delete usuario.isTodas_tblPrendaNUsuario;
                        delete usuario.usuario;
                        delete usuario.nombre;
                    });
                    formData.tblPrendaNUsuarioNEntidad = tblPrendaNUsuarioNEntidad;

                    $.each(formData.tblPrendaNEntidad_NuevoPedido, function (index, prendaNEntidad_NuevoPedido) {
                        prendaNEntidad_NuevoPedido.stockDefinido =
                            prendaNEntidad_NuevoPedido.stockDefinido <= 0
                                ? null
                                : prendaNEntidad_NuevoPedido.stockDefinido *
                                prendaNEntidad_NuevoPedido.tblPrenda.udsXBacPedido;
                        delete prendaNEntidad_NuevoPedido.codigoPrenda;
                        delete prendaNEntidad_NuevoPedido.denominacion;
                        delete prendaNEntidad_NuevoPedido.idEntidad;
                        delete prendaNEntidad_NuevoPedido.udsXBacPedido;
                        delete prendaNEntidad_NuevoPedido.tblElemTransPedido;
                        delete prendaNEntidad_NuevoPedido.tblColorTapa;
                        delete prendaNEntidad_NuevoPedido.tblMarcaTapa;
                        delete prendaNEntidad_NuevoPedido.tblPrenda;
                        delete prendaNEntidad_NuevoPedido.isPedidoPorPeticion;
                    });

                    $.each(formData.tblCalendarioEntidad, function (i, fecha) {
                        if (!fecha.idEntidad) {
                            // Es nuevo, hay que parsear
                            fecha.idEntidad = entidadSel_popup_.idEntidad;
                            delete fecha.startDate;
                            delete fecha.endDate;
                        }
                        if (!formData.idEntidad) delete fecha.idEntidad;
                        delete fecha.tblCalendarioEntidad_Estados;
                        delete fecha.color;
                        delete fecha.denoEstado;
                        delete fecha.isError;
                        delete fecha.idTipoEstado;
                        delete fecha.id;
                        delete fecha.date;
                    });

                    const tblHorarioRepartoNEntidad = [...horariosGuardados].map((x) => {
                        if (x.idEntidad === null) {
                            delete x.idEntidad;
                        }
                        return {
                            ...x,
                            horarioInicioReparto: datetimeToDuration(new Date(x.horarioInicioReparto)),
                            horarioFinReparto: datetimeToDuration(new Date(x.horarioFinReparto)),
                        };
                    });

                    formData.tblHorarioRepartoNEntidad = tblHorarioRepartoNEntidad;

                    const tblSacasPendientes = [...sacasPendientesGuardadas]
                        .filter((x) => x.cantidad > 0)
                        .map((x) => {
                            if (x.idEntidad === null) {
                                delete x.idEntidad;
                            }
                            if (x.tblColorTapa) {
                                delete x.tblColorTapa;
                            }
                            if (x.idSacasPendientes) {
                                delete x.idSacasPendientes;
                            }
                            return { ...x };
                        });

                    formData.tblSacasPendientes = tblSacasPendientes;

                    //#endregion

                    function insertUpdate_Entidad() {
                        delete formData.id;
                        delete formData.selected;
                        delete formData.expanded;

                        _this.loadPanel_show(true);

                        if (!formData.idEntidad) {
                            //POST
                            delete formData.idEntidad;
                            _this.datasource_datos_tblEntidad
                                .store()
                                .insert(formData)
                                .done(function (entidad) {
                                    entidadSel_popup_.idEntidad = entidad.idEntidad;
                                    _this.setState({
                                        entidadSel_popup: entidadSel_popup_,
                                        isVisible_dxPopup_configEntidad: false,
                                    });

                                    _this.datasource_datos_compaEnti.load().done(function () {
                                        setTimeout(() => {
                                            _this.setState(
                                                {
                                                    compañiaSel: null,
                                                    yearCalendar_currentYear: null,
                                                },
                                                () => {
                                                    e.component.option("disabled", false);
                                                    _this.dxTreeView_compaEnti.selectItem(
                                                        entidad.idCompañia + "-" + entidad.idEntidad
                                                    );
                                                    $("#dxTreeView_compaEnti")
                                                        .find(".dx-scrollable")
                                                        .data("dxScrollable")
                                                        .scrollTo(_this.scroll_dxTreeView_compaEnti);

                                                    notify({
                                                        message: _this.getTrad("aviso_C_RegistroInsertado"),
                                                        type: "success",
                                                        displayTime: "1500",
                                                        closeOnClick: true,
                                                    });
                                                }
                                            );
                                        }, 200);
                                    });
                                })
                                .fail(function (error) {
                                    e.component.option("disabled", false);
                                });
                        } else {
                            //PATCH
                            _this.datasource_datos_tblEntidad
                                .store()
                                .update(formData.idEntidad, formData)
                                .done(function () {
                                    _this.datasource_datos_compaEnti.load().then(function (testItem) {
                                        _this.setState({ isVisible_dxPopup_configEntidad: false }, () => {
                                            $("#dxTreeView_compaEnti")
                                                .find(".dx-scrollable")
                                                .data("dxScrollable")
                                                .scrollTo(_this.scroll_dxTreeView_compaEnti);
                                            e.component.option("disabled", false);

                                            notify({
                                                message: _this.getTrad("aviso_C_RegistroActualizado"),
                                                type: "success",
                                                displayTime: "1500",
                                                closeOnClick: true,
                                            });
                                        });
                                    });
                                })
                                .fail(function (error) {
                                    e.component.option("disabled", false);
                                });
                        }
                    }

                    e.component.option("disabled", true);
                    if (formData.idCompañia <= 0) {
                        //NUEVA COMPAÑÍA
                        let compañia = $.grep(_this.state.arrayCompañias_dxDropDownBox_compañia, function (item) {
                            return item.idCompañia == formData.idCompañia;
                        });
                        _this.datasource_datos_compaEnti_compañia
                            .store()
                            .insert(compañia[0])
                            .done(function (compañia) {
                                formData.idCompañia = compañia.idCompañia;
                                insertUpdate_Entidad();
                            });
                    } else {
                        //SOLO ENTIDAD
                        insertUpdate_Entidad();
                    }
                } else {
                    if (isValid_dxDataGrid_tblPrendaNEntidad_NuevoPedido) {
                        if (
                            _this.dxTagBox_tblTipoPedidoNEntidad.option("value") == null ||
                            _this.dxTagBox_tblTipoPedidoNEntidad.option("value").length == 0
                        )
                            _this.setState({
                                isVisible_warning_pedidos: true,
                                isVisible_warning_entidadesAsociadas: false,
                                isCalendar_valid: true,
                            });
                        else
                            _this.setState({
                                isVisible_warning_general: true,
                                isVisible_warning_pedidos: false,
                                isVisible_warning_entidadesAsociadas: false,
                                isCalendar_valid: true,
                            });

                        form.validate();

                        _this.dxScrollView_dxForm_dxTab.scrollToElement(form.element().find(".dx-invalid"));
                    }
                }
            }
        }

        $.when(
            this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia.getController("validating").validate(true),
            this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad.getController("validating").validate(true)
        ).then(function (isValidCompa, isValidEnti) {
            let is_sinPrendaNUsuario = false;
            if (entidadSel_popup_.tblPrendaNEntidad_NuevoPedido.length > 0) {
                $.each(entidadSel_popup_.tblPrendaNUsuarioNEntidad, function (iUser, user) {
                    if (user.isTodas_tblPrendaNUsuario == false && user.tblPrendaNUsuarioNEntidad.length == 0)
                        is_sinPrendaNUsuario = true;
                });
            }

            if (entidadSel_popup_.tblEntidad_historico_idTipoFacturacion.length === 0) {
                _this.setState({ isVisible_warning_general: true });

                return notify({
                    message: getTrad("error_tipoFacturacionSinEspecificar"),
                    type: "error",
                    displayTime: 1500,
                    closeOnClick: true,
                });
            }

            if (
                isValidCompa &&
                isValidEnti &&
                !is_sinPrendaNUsuario &&
                (entidadSel_popup_.tblPrendaNEntidad_NuevoPedido.length > 0 ||
                    (entidadSel_popup_.tblPrendaNEntidad_NuevoPedido.length == 0 &&
                        (entidadSel_popup_.isTodasPrendaNNuevoPedido_compañia ||
                            entidadSel_popup_.isTodasPrendaNNuevoPedido_entidad)))
            ) {
                if (_this.dxDataGrid_tblPrendaNTipoHabitacion_REF["current"]) {
                    $.when(
                        _this.dxDataGrid_tblPrendaNTipoHabitacion.saveEditData(),
                        _this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia.saveEditData(),
                        _this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad.saveEditData()
                    ).then(function () {
                        saveForm();
                    });
                } else {
                    $.when(
                        _this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia.saveEditData(),
                        _this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad.saveEditData()
                    ).then(function () {
                        saveForm();
                    });
                }
            } else {
                if (entidadSel_popup_.tblPrendaNEntidad_NuevoPedido.length == 0) {
                    notify({
                        message: _this.getTrad("error_sinPrendasPedido"),
                        type: "error",
                        displayTime: "1500",
                        closeOnClick: true,
                    });
                } else if (is_sinPrendaNUsuario) {
                    notify({
                        message: _this.getTrad("error_sinPrendasUsuario"),
                        type: "error",
                        displayTime: "1500",
                        closeOnClick: true,
                    });
                }

                if (!_this.state.isVisible_warning_pedidos) {
                    _this.setState({ isVisible_warning_pedidos: true });
                }
            }
        });
    }

    tipoInforme_gestionCorreos_render(e) {
        let _this = this;
        let denoReport = $.map(e.data.tblReports, function (val, key) {
            return _this.datasource_tipoReport.items().find(function (item, index) {
                return item.idReport === val.idReport;
            }).tblFormulario.tblApartado.denominacion;
        });

        let filto_apartadosRepetidos = [];

        $.each(denoReport, function (index, item) {
            if ($.inArray(item, filto_apartadosRepetidos) === -1) filto_apartadosRepetidos.push(item);
        });

        var texto = filto_apartadosRepetidos.join(", "),
            color = "#333",
            padding = 0;

        if (filto_apartadosRepetidos.length === 0) {
            texto = _this.getTrad("selTipoRep");
            color = "#999999";
            padding = 2;
        }

        return <div style={{ color: color, paddingLeft: padding }}>{texto}</div>;
    }

    tipoInforme_gestionCorreos_delete_onClick(e, data) {
        let _this = this;
        let { entidadSel_popup } = this.state;
        this.dxDataGrid_gestionCorreosAlbaranes.cancelEditData();

        //para validar una nueva row
        this.dxDataGrid_gestionCorreosAlbaranes
            .getController("validating")
            .validate(true)
            .done(function (isValid) {
                if (isValid) {
                    dxMensajePregunta(_this.getTrad("preg_RegistroEliminarCorto"), [
                        // ACEPTAR
                        [
                            _this.getTrad("aceptar"),
                            function () {
                                $.each(entidadSel_popup.tblCorreosNEntidad, function (index, item) {
                                    if (item.idCorreo == data.data.idCorreo) {
                                        item.tblReports = [];
                                    }
                                });
                                _this.setState({ entidadSel_popup });
                                _this.dxDataGrid_gestionCorreosAlbaranes.refresh();
                            },
                            "normal",
                            "btnAceptar",
                        ],
                        // CANCELAR
                        [getTrad("cancelar"), function () { }, "normal", "btnCancelar"],
                    ]);
                }
            });
    }

    tipoInforme_gestionCorreos_delete_editCellRender(data) {
        return (
            <div
                className="container_spanCentrado pointer"
                onMouseDown={(e) => {
                    this.tipoInforme_gestionCorreos_delete_onClick(e, data);
                }}
            >
                <div className="icon_Cerrar center-svg font-size-lg" />
            </div>
        );
    }

    tipoInforme_gestionCorreos_editCellRender(e) {
        let _this = this;
        let denoReport = $.map(e.data.tblReports, function (val, key) {
            let tblFormulario = _this.datasource_tipoReport.items().find(function (item, index) {
                return item.idReport === val.idReport;
            }).tblFormulario;

            return {
                idFormulario: tblFormulario.idFormulario,
                idReport: val.idReport,
                idApartado: tblFormulario.tblApartado.idApartado,
                denominacion: tblFormulario.tblApartado.denominacion,
            };
        });
        let filto_apartadosRepetidos = [];

        $.each(denoReport, function (index, item) {
            if (!filto_apartadosRepetidos.some((apartado) => apartado.idApartado === item.idApartado))
                filto_apartadosRepetidos.push(item);
        });

        let idsApartado = $.map(filto_apartadosRepetidos, function (item) {
            return item.idApartado;
        });

        return (
            <TagBox
                elementAttr={{ id: "dxTagBox_tipoRep_" + e.rowIndex }}
                items={this.datasource_apartados.items()}
                showSelectionControls={true}
                defaultValue={idsApartado}
                displayExpr="denominacion"
                valueExpr="idApartado"
                buttons={[
                    {
                        name: "addTipoInc",
                        location: "after",
                        placeholder: this.getTrad("selTipoRep"),
                        options: {
                            height: "100%",
                            width: "100%",
                            icon: "spindown",
                            type: "default",
                            onClick: (x) => this.onClick_tipoRep_TagBoxButtons(x, this, e),
                        },
                    },
                ]}
                tagRender={this.dxTagBox_tipoRep_tagRender}
                onFocusIn={this.dxTagBox_tipoRep_onFocusIn}
                onValueChanged={(x) => {
                    this.dxTagBox_tipoRep_onValueChanged(x, e);
                }}
            ></TagBox>
        );
    }

    onClick_PopupEntidad_cancelar(e) {
        this.dxScrollView_dxForm_dxTab.scrollTo(0);
        this.setState({
            isVisible_dxPopup_configEntidad: false,
            yearCalendar_currentYear: null,
        });
    }

    onValueChanged_dxDropDownBox_denominacion_popupEntidad(e) {
        e.component.option("value", e.value.replace(/\s\s+/g, " ").toUpperCase());
    }

    contentRender_dxDropDownBox_idCompañia_popupEntidad(itemData) {
        let { entidadSel_popup } = this.state;
        return (
            <List
                ref={this.dxList_addCompañia_REF}
                dataSource={itemData.component.option("dataSource")}
                displayExpr="denominacion"
                keyExpr="idCompañia"
                repaintChangesOnly={true}
                selectionMode="single"
                pageLoadMode="scrollBottom"
                height="100%"
                selectedItemKeys={entidadSel_popup ? [entidadSel_popup.idCompañia] : []}
                onSelectionChanged={this.onSelectionChanged_dxDropDownBox_idCompañia_popupEntidad}
            />
        );
    }

    onOptionChanged_dxDropDownBox_idCompañia_popupEntidad(e) {
        if (e.name === "opened") {
            this.setState({ isVisible_dxDropDownBox_idCompañiaAsociada: e.value });
        }
    }

    onValueChanged_dxDropDownBox_idCompañia_popupEntidad(e) {
        let { entidadSel_popup } = this.state;
        if (entidadSel_popup != null) {
            entidadSel_popup.idCompañia = e.value;
            this.setState({ entidadSel_popup });
        }
    }

    onSelectionChanged_dxDropDownBox_idCompañia_popupEntidad(e) {
        let _this = this;
        let entidadSel_popup = { ...this.state.entidadSel_popup };
        let bk_tblPrendaNEntidad_NuevoPedido_compañia = {
            ...this.state.bk_tblPrendaNEntidad_NuevoPedido_compañia,
        };

        if (e.component.option("_customSuspendValueChanged")) {
            e.component.option("_customSuspendValueChanged", false);
            return;
        }

        let prendasCompañia = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
            return item.tblPrenda.idEntidad == null;
        });

        let revert = false;
        if (
            entidadSel_popup.tblEntidadNEntidadPrincipal.length > 0 ||
            entidadSel_popup.tblEntidadNEntidadSecundaria.length > 0 ||
            prendasCompañia.length > 0
        ) {
            revert = true;
        }

        function _onSelectionChanged() {
            let idCompañia =
                entidadSel_popup.idCompañia == e.addedItems[0].idCompañia
                    ? e.removedItems[0].idCompañia
                    : e.addedItems[0].idCompañia;
            entidadSel_popup.idCompañia = idCompañia;

            entidadSel_popup.tblEntidadNEntidadPrincipal = [];
            entidadSel_popup.tblEntidadNEntidadSecundaria = [];

            let prendasEntidad = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
                return item.tblPrenda.idEntidad != null;
            });
            entidadSel_popup.tblPrendaNEntidad_NuevoPedido = prendasEntidad;

            _this.state.entidadSel_popup = entidadSel_popup;

            _this.datasource_tblPrendaNEntidad_compañia.load().done(function (prendas) {
                _this.state.datasource_tblPrendaNEntidad_compañia = prendas;

                let filtroPrendasCompa = entidadSel_popup["isTodasPrendaNNuevoPedido_compañia"];
                if (filtroPrendasCompa == true) {
                    let newItems = [];
                    $(prendas).each(function (index, item) {
                        let inArray = entidadSel_popup.tblPrendaNEntidad_NuevoPedido.find(function (element) {
                            return element.idPrenda === item.idPrenda;
                        });

                        if (!inArray) {
                            //nuevo
                            let itemFormated = {
                                idPrenda: item.idPrenda,
                                idEntidad: entidadSel_popup.idEntidad,
                                stockDefinido: null,
                                isPedidoPorPeticion: true,
                                tblPrenda: item,
                            };
                            newItems.push(itemFormated);
                        }
                    });
                    $.merge(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, newItems);
                    $.merge(bk_tblPrendaNEntidad_NuevoPedido_compañia, newItems);
                }
            });

            _this.setState({
                entidadSel_popup,
                isVisible_dxDropDownBox_idCompañiaAsociada: false,
                isDisabled_dxForm_entidad_tblEntidadNEntidadAsociada: idCompañia < 0,
            });
            setTimeout(() => {
                if ($("#dxDataGrid_tblEntidadesAsociadas").data("dxDataGrid") != null)
                    _this.dxDataGrid_tblEntidadesAsociadas_set_dataSource();
            }, 0);
        }

        if (revert) {
            let texto =
                prendasCompañia.length > 0
                    ? "Se perderán las prendas de compañía de los pedidos. ¿Desea continuar?"
                    : this.getTrad("preg_PerderEntiAsociadas");
            dxMensajePregunta(texto, [
                [
                    this.getTrad("aceptar"),
                    function () {
                        _onSelectionChanged();
                    },
                    "normal",
                    "btnAceptar_alert",
                ],
                [
                    this.getTrad("cancelar"),
                    function () {
                        _this.setState({
                            isVisible_dxDropDownBox_idCompañiaAsociada: false,
                        });
                    },
                    "normal",
                    "btnCancelar_alert",
                ],
            ]);
        } else {
            _onSelectionChanged();
        }
    }

    onClick_tblTipoPedidoNEntidad_TagBoxButtons(e, _this) {
        var dxTagBox_tipoPedido = _this.dxTagBox_tblTipoPedidoNEntidad;
        dxTagBox_tipoPedido.option("opened") ? dxTagBox_tipoPedido.close() : dxTagBox_tipoPedido.open();
    }

    onClick_tipoHabitacion(e) {
        this.dxList_tblTipoHabitacion.option("selectedItemKeys", []);

        this.setState({
            dxList_tipoHabitacionSel: null,
            isVisible_dxPopup_addTipoHabitacion: true,
        });

        setTimeout(() => {
            this.dxForm_addTipoHabitacion.resetValues();
        }, 150);
    }

    itemRender_dxList_tblTipoHabitacion(item) {
        let _this = this;
        return (
            <div className="container_spanCentrado d-flex h-25px">
                <div className="list_denominacion_container">{item.denominacion}</div>

                <Toolbar elementAttr={_this.elementAttr_toolbarClass}>
                    <ToolbarItem location="after">
                        <div className="container_spanCentrado dxToolbar_opcion p-2">
                            <div
                                className="font-size-lg icon_Edit center-svg"
                                onClick={(e) => {
                                    this.dxList_tblTipoHabitacion_onClick_modify(e, item);
                                }}
                            ></div>
                        </div>
                    </ToolbarItem>
                    <ToolbarItem location="after">
                        <div className="container_spanCentrado dxToolbar_opcion p-2">
                            <div
                                className="font-size-lg center-svg icon_Cerrar"
                                onClick={(e) => {
                                    this.dxList_tblTipoHabitacion_onClick_delete(e, item);
                                }}
                            ></div>
                        </div>
                    </ToolbarItem>
                </Toolbar>
            </div>
        );
    }

    onSelectionChanged_dxList_tblTipoHabitacion(e) {
        let _this = this;
        this.dxDataGrid_tblPrendaNTipoHabitacion.saveEditData().done(function () {
            var data = { tblPrendaNTipoHabitacion: [] };
            if (e.addedItems.length > 0) {
                data = e.addedItems[0];
            }

            var arrayStore = new ArrayStore({
                key: "idPrenda",
                data: data.tblPrendaNTipoHabitacion,
            });

            _this.setState({
                datasource_dxDataGrid_tblPrendaNTipoHabitacion: arrayStore,
                isDisabled_dxButton_addPrendaTipoHabitacion: e.addedItems.length === 0,
                dxList_tblTipoHabitacion_selectedItemKeys: data.idTipoHabitacion ? [data.idTipoHabitacion] : [],
            });

            // Toolbar no se vuelve a renderizar siempre ,no puedo cambiarlo por state sin repintar
            setTimeout(() => {
                $("#dxButton_addPrendaTipoHabitacion")
                    .dxButton("instance")
                    .option("disabled", e.addedItems.length === 0);
            }, 0);
        });
    }

    onClick_tipoRep_TagBoxButtons(x, _this, data) {
        _this.dxDataGrid_gestionCorreosAlbaranes
            .getController("validating")
            .validate(true)
            .done(function (is_gridValid) {
                var dxTagBox_tipoRep = $("#dxTagBox_tipoRep_" + data.rowIndex).dxTagBox("instance");

                if (is_gridValid) {
                    dxTagBox_tipoRep.option("opened") ? dxTagBox_tipoRep.close() : dxTagBox_tipoRep.open();
                }
            });
    }

    dxTagBox_tipoRep_onValueChanged(e, data) {
        let _this = this;
        // Se guardará el 1er report del apartado seleccionado
        let idsApartado = e.value;
        let valueApartados = $.map(idsApartado, function (idApartado) {
            let tblFormulario = _this.datasource_apartados
                .items()
                .find(function (val, index) {
                    return idApartado === val.idApartado;
                })
                .tblFormulario.find(function (form, Iform) {
                    return form.tblReports.length > 0;
                });

            return {
                idFormulario: tblFormulario.idFormulario,
                idReport: tblFormulario.tblReports[0].idReport,
                denominacion: tblFormulario.tblReports[0].denominacion,
            };
        });

        data.setValue(valueApartados);
    }

    dxTagBox_tipoRep_onFocusIn(e) {
        let items = e.component.option("value");

        this.dxDataGrid_gestionCorreosAlbaranes
            .getController("validating")
            .validate(true)
            .done(function (is_gridValid) {
                if (items.length === 0 && is_gridValid) {
                    e.component.open();
                }
            });
    }

    dxTagBox_tipoRep_tagRender(e) {
        return (
            <div className="dx-tag-content">
                <span>{e.denominacion}</span>
                <div className="dx-tag-remove-button"></div>
            </div>
        );
    }

    dxTagBox_tblTipoPedidoNEntidad_onValueChanged(e) {
        let entidadSel_popup = $.extend(true, {}, this.state.entidadSel_popup);
        if (entidadSel_popup != null) {
            entidadSel_popup.tblTipoPedidoNEntidad = $.map(e.component.option("selectedItems"), function (val, key) {
                return { idTipoPedido: val.idTipoPedido };
            });

            this.setState({ entidadSel_popup: entidadSel_popup });
        }
    }

    onToolbarPreparing_dxDataGrid_tblPrendaNTipoHabitacion(e) {
        e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxButton",
            options: {
                elementAttr: this.elementAttr_dxButton_addPrendaTipoHabitacion,
                disabled: this.state.isDisabled_dxButton_addPrendaTipoHabitacion,
                text: this.getTrad("prenda"),
                icon: "plus",
                onClick: this.onClick_dxDataGrid_tblPrendaNTipoHabitacion,
            },
        });
    }

    onClick_dxDataGrid_tblPrendaNTipoHabitacion(e) {
        if (this.dxDataGrid_tblPrendaNTipoHabitacion.getDataSource()) {
            this.setState({ isVisible_dxPopup_tblPrendaNTipoHabitacion: true });
        }
    }

    get_heightNuevoPedido() {
        return $("#dxPopup_configEntidad .dx-popup-content").height() - 43 - 60 - 40 - 25 - 62.5 - 30; // 68 - dxTabPanel, 60 - dxButtonGroup, 40 - padding ButtonGroup, 25 - Padding Titulo Prendas de..., 92.5 - Editores tipoPedido + pedidos persmitidos
    }

    render_tblPrendaNEntidad_NuevoPedido(e) {
        let {
            entidadSel_popup,
            dxButtonGroup_prendasPedido_idSel,
            usuarioSel_prendasNUsuario_NuevoPedido,
            datasource_tblPrendaNEntidad_compañia,
            datasource_tblPrendaNEntidad,
            isVisible_loading_dxPopup_configEntidad_pedidos,
            isVisible_loading_dxPopup_configEntidad,
            isLoaded_popupEntidad,
        } = this.state;

        let prendasCompa = [];
        let prendasEnti = [];
        if (entidadSel_popup) {
            $.each(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (index, item) {
                if (item.tblPrenda.idEntidad) prendasEnti.push(item);
                else prendasCompa.push(item);
            });
        }

        return (
            <Fragment>
                <LoadPanel
                    deferRendering={false}
                    animation={null}
                    position={dxPopup_configEntidad_dxMultiView_pedidos_estructuraOrg}
                    visible={isVisible_loading_dxPopup_configEntidad_pedidos}
                    shadingColor="transparent"
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <div style={{ height: 60, width: 350 }}>
                    <ButtonGroupCustom
                        idSel={dxButtonGroup_prendasPedido_idSel}
                        items={this.enum_dxButtonGroup_prendasPedido}
                        dxButtonGroup_onValueChanged={this.dxButtonCustomGroup_onValueChanged}
                    />
                </div>
                <MultiView
                    height="100%"
                    width="100%"
                    elementAttr={this.dxMultiView_pedidos_elementAttr}
                    selectedIndex={dxButtonGroup_prendasPedido_idSel}
                    deferRendering={false}
                    // selectedIndex={0}
                    loop={false}
                    swipeEnabled={false}
                    animationEnabled={false}
                    onContentReady={this.dxMultiView_pedidos_onContentReady}
                >
                    <MultiViewItem>
                        <ResponsiveBox screenByWidth={this.screenByWidth_responsiveBox_prendasPedido}>
                            <Row ratio={1} screen="xs sm md lg xl" />

                            <Row ratio={0} baseSize={25} screen="xs sm md lg" />
                            <Row ratio={1} screen="xs sm md lg" />

                            <Col ratio={1} screen="xs sm md lg xl" />
                            <Col ratio={0} baseSize={25} screen="xl" />
                            <Col ratio={1} screen="xl" />

                            <ResponsiveBoxItem>
                                <Location row={0} col={0}></Location>
                                <h5 className="pl-1">{this.getTrad("prendasCompa")}</h5>
                                <DataGrid
                                    //Datos
                                    ref={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia_REF}
                                    keyExpr="idPrenda"
                                    dataSource={isLoaded_popupEntidad ? prendasCompa : []}
                                    remoteOperations={false}
                                    //Propiedades
                                    repaintChangesOnly={true}
                                    height={this.get_heightNuevoPedido()}
                                    tipoGrid="prendasCompañia"
                                    //Estilos
                                    showColumnLines={false}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    onContentReady={this.onContentReady_tblPrendaNEntidad_NuevoPedido}
                                    onEditorPreparing={this.dxNumberBox_stockDefinido_onEditorPreparing}
                                    onRowUpdating={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowUpdating}
                                    onInitialized={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onInitialized}
                                    onRowRemoving={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowRemoving}
                                    onRowValidating={this.dxNumberBox_stockDefinido_onRowValidating}
                                    onToolbarPreparing={this.onToolbarPreparing_tblPrendaNEntidad_NuevoPedido}
                                >
                                    <Paging enabled={false} />
                                    <Pager visible={false} />
                                    <Editing
                                        mode="cell"
                                        refreshMode="reshape"
                                        allowUpdating={true}
                                        allowDeleting={false}
                                        selectTextOnEditStart={true}
                                        texts={this.tblPrendaNEntidad_NuevoPedido_texts}
                                    ></Editing>
                                    <KeyboardNavigation
                                        enterKeyAction="moveFocus"
                                        enterKeyDirection="row"
                                        editOnKeyPress={true}
                                    />
                                    <Column dataField="idEntidad" allowEditing={false} visible={false} />
                                    <Column
                                        dataField="tblPrenda.codigoPrenda"
                                        caption={this.getTrad("codigo")}
                                        width={95}
                                        allowEditing={false}
                                        alignment={"center"}
                                        sortIndex={0}
                                        sortOrder={"asc"}
                                    />
                                    <Column
                                        dataField="tblPrenda.denominacion"
                                        allowEditing={false}
                                        caption={this.getTrad("denominacion")}
                                        minWidth={190}
                                    />
                                    <Column
                                        dataField="isPedidoPorPeticion"
                                        cssClass="p-0 no-CellEdit-Highlight"
                                        caption={this.getTrad("tipo")}
                                        width={105}
                                        editorType={!entidadSel_popup?.enablePlanificadorPedidos ? "dxSwitch" : null}
                                        editorOptions={
                                            !entidadSel_popup?.enablePlanificadorPedidos
                                                ? this.editorOptions_isPedidoPorPeticion
                                                : null
                                        }
                                        setCellValue={this.setCellValue_isPedidoPorPeticion}
                                        allowEditing={!entidadSel_popup?.enablePlanificadorPedidos}
                                        showEditorAlways={!entidadSel_popup?.enablePlanificadorPedidos}
                                        cellComponent={
                                            !entidadSel_popup?.enablePlanificadorPedidos
                                                ? null
                                                : this.cellComponent_isPedidoPorPeticion
                                        }
                                    />
                                    <Column
                                        dataField="stockDefinido"
                                        allowEditing={true}
                                        caption={this.getTrad("stockDefinido")}
                                        visible={!entidadSel_popup?.enablePlanificadorPedidos}
                                        cssClass="dx-Cell_Editable"
                                        width={105}
                                        alignment={"center"}
                                        editorType={"dxNumberBox"}
                                        editorOptions={this.editorOptions_stockDefinido}
                                        format={this.format_decimal_maxCero}
                                        allowHeaderFiltering={false}
                                        cellRender={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_render}
                                    >
                                        <CustomRule
                                            reevaluate={true}
                                            ignoreEmptyValue={true}
                                            validationCallback={this.validationCallback_CustomRule_stockDefinido}
                                        />
                                    </Column>
                                    <Column
                                        dataField="ratio"
                                        allowEditing={true}
                                        caption={this.getTrad("ratio")}
                                        visible={entidadSel_popup?.enablePlanificadorPedidos === true}
                                        cssClass="dx-Cell_Editable"
                                        width={105}
                                        alignment={"center"}
                                        editorType={"dxNumberBox"}
                                        editorOptions={this.editorOptions_ratio}
                                        format={"decimal"}
                                        allowHeaderFiltering={false}
                                        cellRender={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_render}
                                    >
                                        <CustomRule
                                            reevaluate={true}
                                            ignoreEmptyValue={true}
                                            validationCallback={this.validationCallback_CustomRule_ratio}
                                        />
                                    </Column>
                                    <Column
                                        dataField="tblPrenda.tblElemTransPedido.codigo"
                                        allowEditing={false}
                                        caption={this.getTrad("elementoTransporte_abr")}
                                        width={50}
                                        allowHeaderFiltering={false}
                                        allowFiltering={false}
                                        alignment="center"
                                        cssClass="p-0"
                                        visible={!entidadSel_popup?.enablePlanificadorPedidos}
                                        cellComponent={elemTransCellComponent}
                                    />
                                    <Column
                                        caption=" "
                                        name="remove"
                                        allowEditing={false}
                                        width={40}
                                        alignment="center"
                                        cssClass="p-0"
                                        cellRender={this.cellRender_tblPrendaNEntidad_NuevoPedido_remove}
                                    />
                                </DataGrid>
                            </ResponsiveBoxItem>
                            <ResponsiveBoxItem>
                                <Location row={0} col={1} screen="xl"></Location>
                                <Location row={1} col={0} screen="xs sm md lg"></Location>
                            </ResponsiveBoxItem>
                            <ResponsiveBoxItem>
                                <Location row={0} col={2} screen="xl"></Location>
                                <Location row={2} col={0} screen="xs sm md lg"></Location>
                                <h5 className="pl-1">{this.getTrad("prendasEnti")}</h5>
                                <DataGrid
                                    //Datos
                                    ref={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad_REF}
                                    keyExpr="idPrenda"
                                    dataSource={isLoaded_popupEntidad ? prendasEnti : []}
                                    remoteOperations={false}
                                    //Propiedades
                                    repaintChangesOnly={true}
                                    height={this.get_heightNuevoPedido()}
                                    tipoGrid="prendasEntidad"
                                    //Estilos
                                    showColumnLines={false}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    onContentReady={this.onContentReady_tblPrendaNEntidad_NuevoPedido}
                                    onEditorPreparing={this.dxNumberBox_stockDefinido_onEditorPreparing}
                                    onRowUpdating={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowUpdating}
                                    onInitialized={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onInitialized}
                                    onRowRemoving={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowRemoving}
                                    onRowValidating={this.dxNumberBox_stockDefinido_onRowValidating}
                                    onToolbarPreparing={this.onToolbarPreparing_tblPrendaNEntidad_NuevoPedido}
                                >
                                    <Paging enabled={false} />
                                    <Pager visible={false} />
                                    <Editing
                                        mode="cell"
                                        refreshMode="reshape"
                                        allowUpdating={true}
                                        allowDeleting={false}
                                        selectTextOnEditStart={true}
                                        texts={this.tblPrendaNEntidad_NuevoPedido_texts}
                                    ></Editing>
                                    <KeyboardNavigation
                                        enterKeyAction="moveFocus"
                                        enterKeyDirection="row"
                                        editOnKeyPress={true}
                                    />
                                    <Column dataField="idEntidad" allowEditing={false} visible={false} />
                                    <Column
                                        dataField="tblPrenda.codigoPrenda"
                                        caption={this.getTrad("codigo")}
                                        width={95}
                                        allowEditing={false}
                                        alignment={"center"}
                                        sortIndex={0}
                                        sortOrder={"asc"}
                                    />
                                    <Column
                                        dataField="tblPrenda.denominacion"
                                        allowEditing={false}
                                        caption={this.getTrad("denominacion")}
                                        minWidth={190}
                                    />
                                    <Column
                                        dataField="isPedidoPorPeticion"
                                        cssClass="p-0 no-CellEdit-Highlight"
                                        caption={this.getTrad("tipo")}
                                        width={105}
                                        editorType={!entidadSel_popup?.enablePlanificadorPedidos ? "dxSwitch" : null}
                                        editorOptions={
                                            !entidadSel_popup?.enablePlanificadorPedidos
                                                ? this.editorOptions_isPedidoPorPeticion
                                                : null
                                        }
                                        setCellValue={this.setCellValue_isPedidoPorPeticion}
                                        allowEditing={!entidadSel_popup?.enablePlanificadorPedidos}
                                        showEditorAlways={!entidadSel_popup?.enablePlanificadorPedidos}
                                        cellComponent={
                                            !entidadSel_popup?.enablePlanificadorPedidos
                                                ? null
                                                : this.cellComponent_isPedidoPorPeticion
                                        }
                                    />
                                    <Column
                                        dataField="stockDefinido"
                                        allowEditing={true}
                                        caption={this.getTrad("stockDefinido")}
                                        visible={!entidadSel_popup?.enablePlanificadorPedidos}
                                        cssClass="dx-Cell_Editable"
                                        width={105}
                                        alignment={"center"}
                                        editorType={"dxNumberBox"}
                                        editorOptions={this.editorOptions_stockDefinido}
                                        format={this.format_decimal_maxCero}
                                        allowHeaderFiltering={false}
                                        cellRender={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_render}
                                    >
                                        <CustomRule
                                            reevaluate={true}
                                            ignoreEmptyValue={true}
                                            validationCallback={this.validationCallback_CustomRule_stockDefinido}
                                        />
                                    </Column>
                                    <Column
                                        dataField="ratio"
                                        allowEditing={true}
                                        caption={this.getTrad("ratio")}
                                        visible={entidadSel_popup?.enablePlanificadorPedidos === true}
                                        cssClass="dx-Cell_Editable"
                                        width={105}
                                        alignment={"center"}
                                        editorType={"dxNumberBox"}
                                        editorOptions={this.editorOptions_ratio}
                                        format={"decimal"}
                                        allowHeaderFiltering={false}
                                        cellRender={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_render}
                                    >
                                        <CustomRule
                                            reevaluate={true}
                                            ignoreEmptyValue={true}
                                            validationCallback={this.validationCallback_CustomRule_ratio}
                                        />
                                    </Column>
                                    <Column
                                        dataField="tblPrenda.tblElemTransPedido.codigo"
                                        allowEditing={false}
                                        caption={this.getTrad("elementoTransporte_abr")}
                                        width={50}
                                        allowHeaderFiltering={false}
                                        allowFiltering={false}
                                        alignment="center"
                                        cssClass="p-0"
                                        visible={!entidadSel_popup?.enablePlanificadorPedidos}
                                        cellComponent={elemTransCellComponent}
                                    />
                                    <Column
                                        caption=" "
                                        name="remove"
                                        allowEditing={false}
                                        width={40}
                                        alignment="center"
                                        cssClass="p-0"
                                        cellRender={this.cellRender_tblPrendaNEntidad_NuevoPedido_remove}
                                    />
                                </DataGrid>
                            </ResponsiveBoxItem>
                        </ResponsiveBox>
                    </MultiViewItem>
                    <MultiViewItem>
                        <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                            <ItemBox ratio={2}>
                                <div className="font-size" style={this.dxList_PrendaNUsuario_NuevoPedido_style}>
                                    {this.getTrad("usuarios")}
                                </div>
                                <List
                                    ref={this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_REF}
                                    dataSource={
                                        entidadSel_popup && isVisible_loading_dxPopup_configEntidad == false
                                            ? entidadSel_popup.tblPrendaNUsuarioNEntidad
                                            : []
                                    }
                                    pageLoadMode="scrollBottom"
                                    displayExpr={this.dxList_PrendaNUsuario_NuevoPedido_displayExpr}
                                    keyExpr="idUsuario"
                                    selectionMode="single"
                                    height={this.get_heightNuevoPedido() - 20}
                                    onContentReady={this.onContentReady_dxList_tblPrendaNUsuario}
                                    onSelectionChanged={this.onSelectionChanged_dxList_tblPrendaNUsuario}
                                />
                            </ItemBox>
                            <ItemBox baseSize={35} />
                            <ItemBox ratio={6}>
                                <div className="font-size">{this.getTrad("prendasPedido")}</div>
                                <DataGrid
                                    //Datos
                                    ref={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario_REF}
                                    keyExpr="idPrenda"
                                    dataSource={
                                        usuarioSel_prendasNUsuario_NuevoPedido != null
                                            ? usuarioSel_prendasNUsuario_NuevoPedido.tblPrendaNUsuarioNEntidad
                                            : []
                                    }
                                    disabled={usuarioSel_prendasNUsuario_NuevoPedido == null}
                                    cacheEnabled={true}
                                    remoteOperations={false}
                                    //Propiedades
                                    columnsAutoWidth={false}
                                    tipoGrid="prendasUsuario"
                                    repaintChangesOnly={true}
                                    height={this.get_heightNuevoPedido() - 20}
                                    //Estilos
                                    showColumnLines={false}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    onContentReady={this.onContentReady_tblPrendaNEntidad_NuevoPedido}
                                    onEditorPreparing={this.dxNumberBox_stockDefinido_onEditorPreparing}
                                    onRowRemoving={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario_onRowRemoving}
                                    onRowValidating={this.dxNumberBox_stockDefinido_onRowValidating}
                                    onToolbarPreparing={this.onToolbarPreparing_tblPrendaNEntidad_NuevoPedido}
                                >
                                    <Grouping autoExpandAll={true} allowCollapsing={false} />
                                    <GroupPanel visible={false} />
                                    <Paging enabled={false} />
                                    <Pager visible={false} />
                                    <Editing
                                        mode="cell"
                                        refreshMode="reshape"
                                        allowUpdating={true}
                                        allowDeleting={false}
                                        selectTextOnEditStart={true}
                                    ></Editing>
                                    <KeyboardNavigation
                                        enterKeyAction="moveFocus"
                                        enterKeyDirection="row"
                                        editOnKeyPress={true}
                                    />
                                    <Column
                                        dataField="tblPrenda.idEntidad"
                                        allowEditing={false}
                                        visible={false}
                                        groupIndex={0}
                                        width={0}
                                        groupCellRender={this.groupCellRender_tblPrendaNUsuario_NuevoPedido}
                                    />
                                    <Column
                                        dataField="tblPrenda.codigoPrenda"
                                        caption={this.getTrad("codigo")}
                                        minWidth={85}
                                        width="10%"
                                        allowEditing={false}
                                        alignment={"center"}
                                        sortIndex={0}
                                        sortOrder={"asc"}
                                    />
                                    <Column
                                        dataField="tblPrenda.denominacion"
                                        allowEditing={false}
                                        caption={this.getTrad("denominacion")}
                                        minWidth={165}
                                    />
                                    <Column
                                        dataField="isPedidoPorPeticion"
                                        cssClass="p-0 no-CellEdit-Highlight"
                                        caption={this.getTrad("tipo")}
                                        width={90}
                                        cellRender={this.cellRender_isPedidoPorPeticion_readOnly}
                                        allowEditing={false}
                                    />
                                    <Column
                                        dataField="stockDefinido"
                                        allowEditing={false}
                                        caption={this.getTrad("stockDefinido")}
                                        minWidth={85}
                                        width={"12%"}
                                        alignment={"center"}
                                        editorType={"dxNumberBox"}
                                        editorOptions={this.editorOptions_stockDefinido}
                                        format={this.format_decimal_maxCero}
                                        allowHeaderFiltering={false}
                                        cellRender={this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_render}
                                    >
                                        <CustomRule
                                            reevaluate={true}
                                            ignoreEmptyValue={true}
                                            validationCallback={this.validationCallback_CustomRule_stockDefinido}
                                        />
                                    </Column>
                                    <Column
                                        dataField="tblPrenda.tblElemTransPedido.codigo"
                                        allowEditing={false}
                                        caption={this.getTrad("elementoTransporte_abr")}
                                        width={50}
                                        allowHeaderFiltering={false}
                                        allowFiltering={false}
                                        alignment="center"
                                        cssClass="p-0"
                                        cellComponent={elemTransCellComponent}
                                    />
                                    <Column
                                        caption=" "
                                        name="remove"
                                        allowEditing={false}
                                        width={40}
                                        alignment="center"
                                        cssClass="p-0"
                                        cellRender={this.cellRender_tblPrendaNEntidad_NuevoPedido_remove}
                                    />
                                </DataGrid>
                            </ItemBox>
                        </Box>
                    </MultiViewItem>
                </MultiView>
            </Fragment>
        );
    }

    tblPrendaNEntidad_NuevoPedido_texts = {
        confirmDeleteMessage: this.getTrad("aviso_eliminarPrendaNPedido"),
    };

    onClick_tblPrendaNEntidad_NuevoPedido_remove(arg, e) {
        e.component.deleteRow(e.rowIndex);
    }

    cellRender_tblPrendaNEntidad_NuevoPedido_remove(e) {
        let { entidadSel_popup, usuarioSel_prendasNUsuario_NuevoPedido } = this.state;
        let { tipoGrid } = e.component.option();

        if (
            entidadSel_popup &&
            ((tipoGrid == "prendasCompañia" && entidadSel_popup.isTodasPrendaNNuevoPedido_compañia) ||
                (tipoGrid == "prendasEntidad" && entidadSel_popup.isTodasPrendaNNuevoPedido_entidad) ||
                (tipoGrid == "prendasUsuario" &&
                    usuarioSel_prendasNUsuario_NuevoPedido &&
                    usuarioSel_prendasNUsuario_NuevoPedido.isTodas_tblPrendaNUsuario))
        )
            return null;

        return (
            <div
                className="container_spanCentrado pointer"
                onMouseDown={(arg) => {
                    this.onClick_tblPrendaNEntidad_NuevoPedido_remove(arg, e);
                }}
            >
                <div className="icon_Cerrar font-size-lg" />
            </div>
        );
    }

    dxButtonCustomGroup_onValueChanged(id) {
        this.setState({ dxButtonGroup_prendasPedido_idSel: id });
        this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario.refresh(true);
    }

    dxMultiView_pedidos_onContentReady(e) {
        e.element.find(".dx-multiview-item-content").css({ padding: "10px 5px 0px 5px" });
    }

    saveDocuments(idPrenda) {
        var promises = [];
        let { accionesModificarDatos_fileUploader, prendaSel } = this.state;
        let _this = this;
        let lastItem = accionesModificarDatos_fileUploader[accionesModificarDatos_fileUploader.length - 1];
        function isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

        if (lastItem) {
            $.each([lastItem], function (index, item) {
                let idDocumentoPrenda;

                if (item.action === "delete") {
                    if (!isNumeric(item.id)) {
                        let deletes_action = $.grep(accionesModificarDatos_fileUploader, function (item) {
                            return item.action === "delete";
                        });
                        idDocumentoPrenda = deletes_action.length > 0 ? deletes_action[0].id : null;
                    } else {
                        idDocumentoPrenda = item.id;
                    }
                }

                if (item.action === "delete" && idDocumentoPrenda && isNumeric(idDocumentoPrenda)) {
                    var def = new $.Deferred();
                    _this.datasource_tblDocumentoPrenda
                        .store()
                        .remove(idDocumentoPrenda)
                        .done(function (result) {
                            def.resolve(result);
                        });
                    promises.push(def);
                }
                if (item.action === "insert") {
                    var def = new $.Deferred();
                    let documento = {
                        nombre: item.data.nombre ? item.data.nombre : item.data.denominacion,
                        extension: item.data.extension,
                        documento: item.data.documento,
                        idPrenda: idPrenda,
                    };

                    _this.datasource_tblDocumentoPrenda
                        .store()
                        .insert(documento)
                        .done(function (result) {
                            def.resolve(result);
                        });
                    promises.push(def);
                }
            });
        }
        return $.when.apply(undefined, promises).promise();
    }
    dxList_PrendaNUsuario_NuevoPedido_style = { paddingBottom: "17px" };

    validationCallback_CustomRule_stockDefinido(e) {
        e.rule.message = "El valor debe ser mayor a 0";
        if (e.data.isPedidoPorPeticion || (!e.data.isPedidoPorPeticion && e.data.stockDefinido > 0)) {
            return true;
        }
        return false;
    }

    validationCallback_CustomRule_ratio = (e) => {
        e.rule.message = "El valor debe ser mayor a 0";
        const { entidadSel_popup } = this.state;
        if (
            !entidadSel_popup?.enablePlanificadorPedidos ||
            (entidadSel_popup?.enablePlanificadorPedidos === true && e.data.ratio >= 0)
        ) {
            return true;
        }
        return false;
    };

    cellRender_tblElemTransPedido_codigo(item) {
        let prenda = item.data;
        let color = prenda.tblPrenda.tblColorTapa ? prenda.tblPrenda.tblColorTapa.codigoHexadecimal : "black";
        let id = new Guid();

        let tblElemTrans = prenda.tblPrenda.tblElemTransPedido
            ? prenda.tblPrenda.tblElemTransPedido
            : prenda.tblPrenda.tblElemTransOffice
                ? prenda.tblPrenda.tblElemTransOffice
                : prenda.tblPrenda.tblElemTransProduccion
                    ? prenda.tblPrenda.tblElemTransProduccion
                    : prenda.tblPrenda.tblElemTransReparto
                        ? prenda.tblPrenda.tblElemTransReparto
                        : null;

        let udsTrans = prenda.tblPrenda.udsXBacPedido
            ? prenda.tblPrenda.udsXBacPedido
            : prenda.tblPrenda.udsXBacOffice
                ? prenda.tblPrenda.udsXBacOffice
                : prenda.tblPrenda.udsXBacProduccion
                    ? prenda.tblPrenda.udsXBacProduccion
                    : prenda.tblPrenda.udsXBacReparto
                        ? prenda.tblPrenda.udsXBacReparto
                        : 0;

        let tooltipText =
            tblElemTrans.codigo === "1" ? this.getTrad("porUds") : this.getTrad("bacSaca") + ": " + udsTrans;
        let icon = tblElemTrans.codigo === "1" ? "icon_Camisa" : "icon_Bac";

        return (
            <div
                id={id}
                className={"container_spanCentrado elemTransCellComponent " + (color === "#FFFFFF" ? "white" : "")}
                style={{ color: color }}
            >
                <div className={"content font-size-xl " + icon}></div>
                <Tooltip target={"#" + id} position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div className="font-weight-bold">{tooltipText}</div>
                </Tooltip>
            </div>
        );
    }

    cellRender_codigoHexadecimal(item) {
        if (item.rowType === "data") {
            let data_ = $.extend(true, {}, item.data);
            let data = {
                codigoHexadecimal:
                    data_.tblPrenda.tblColorTapa != null ? data_.tblPrenda.tblColorTapa.codigoHexadecimal : null,
                denoMarcaTapa: data_.tblPrenda.tblMarcaTapa != null ? data_.tblPrenda.tblMarcaTapa.denominacion : null,
            };

            if (data.codigoHexadecimal) {
                return (
                    <div
                        style={{
                            height: 19,
                            backgroundColor: data.codigoHexadecimal,
                            borderRadius: 4,
                            border: "1px solid #BFBFBF",
                            fontSize: "12px",
                            display: "flex",
                            margin: "auto",
                            alignItems: "normal",
                            color: data.codigoHexadecimal === "#FFFFFF" ? "#000000" : "#FFFFFF",
                            fontWeigth: "bold",
                        }}
                        className="container_spanCentrado flexBasis-50"
                    >
                        {data.denoMarcaTapa}
                    </div>
                );
            } else {
                return <div className="container_spanCentrado flexBasis-50">{"N/A"}</div>;
            }
        }
    }

    cellRender_isPedidoPorPeticion(item) {
        return (
            <Switch
                defaultValue={item.value}
                elementAttr={this.dxSwitch_noDisabled_elementAttr}
                height={28}
                width={90}
                switchedOffText="Stock"
                switchedOnText="Petición"
            />
        );
    }

    isValueCancel = false;
    setCellValue_isPedidoPorPeticion(newData, value, currentRowData) {
        if (this.isValueCancel) {
            this.isValueCancel = false;
            return null;
        } else {
            let _this = this;
            let grid;

            if (currentRowData.tblPrenda.idEntidad == null)
                grid = _this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_compañia;
            else grid = _this.dxDataGrid_tblPrendaNEntidad_NuevoPedido_entidad;

            let stockDefinido = currentRowData.stockDefinido;
            if (stockDefinido > 0 && value == true) {
                dxMensajePregunta("Se perderá el stock definido, ¿Desea continuar?", [
                    [
                        this.getTrad("aceptar"),
                        function () {
                            let rowIndex = grid.getRowIndexByKey(currentRowData.idPrenda);

                            grid.cellValue(rowIndex, "stockDefinido", null);
                            grid.cellValue(rowIndex, "isPedidoPorPeticion", value);
                            grid.repaintRows(rowIndex);
                        },
                        "normal",
                        "btnAceptar_alert",
                    ],
                    [
                        this.getTrad("cancelar"),
                        function () {
                            _this.isValueCancel = true;
                            let rowIndex = grid.getRowIndexByKey(currentRowData.idPrenda);
                            grid.cellValue(rowIndex, "isPedidoPorPeticion", !value);
                            grid.repaintRows(rowIndex);
                        },
                        "normal",
                        "btnCancelar_alert",
                    ],
                ]);
            } else {
                _valueChanged();
            }

            function _valueChanged() {
                let invalidCell = !currentRowData.isPedidoPorPeticion && currentRowData.stockDefinido > 0;
                if (!invalidCell) {
                    newData.isPedidoPorPeticion = value;
                    newData.stockDefinido = value ? null : 0;

                    if (value == false) {
                        let rowIndex = grid.getRowIndexByKey(currentRowData.idPrenda);
                        grid.editCell(rowIndex, "stockDefinido");
                    }
                } else {
                    if (_this.cancel_isPedidoPorPeticion == false) {
                        _this.cancel_isPedidoPorPeticion = true;
                        newData.isPedidoPorPeticion = value;
                        newData.stockDefinido = null;
                    }
                }
                _this.cancel_isPedidoPorPeticion = false;
            }
        }
    }

    cellRender_isPedidoPorPeticion_readOnly(item) {
        return (
            <Switch
                defaultValue={item.value}
                elementAttr={this.dxSwitch_noDisabled_elementAttr}
                height={28}
                width={90}
                switchedOffText="Stock"
                switchedOnText="Petición"
                readOnly={true}
            />
        );
    }

    cellComponent_isPedidoPorPeticion = (e) => {
        return <div className="chip warning">{this.getTrad("ratio")}</div>;
    };

    dxDataGrid_tblPrendaNEntidad_NuevoPedido_render(e) {
        let { isPedidoPorPeticion } = e.data;
        let { dataField } = e.column;
        let text = e.value == null ? (isPedidoPorPeticion || dataField === "ratio" ? "-" : "0") : e.text;
        let className = isPedidoPorPeticion && dataField !== "ratio" ? "disabledText" : "";

        return (
            <div className={className + " position-relative"}>
                {text}{" "}
                {dataField === "ratio" && e.value == null && (
                    <div className="position-absolute-center-right">
                        <LottieIcon icon={"exclamation_circle"} isLoop={false} width={25} />
                    </div>
                )}
            </div>
        );
    }

    dxList_PrendaNUsuario_NuevoPedido_displayExpr(item) {
        return item.nombre;
    }

    onContentReady_dxList_tblPrendaNUsuario(e) {
        let items = e.component.option("items");

        if (items.length > 0) {
            e.component.selectItem(0);
        }
    }

    onSelectionChanged_dxList_tblPrendaNUsuario(e) {
        let { entidadSel_popup } = this.state;
        if (e.addedItems.length > 0 && e.addedItems[0].isTodas_tblPrendaNUsuario)
            e.addedItems[0].tblPrendaNUsuarioNEntidad = $.extend(
                true,
                [],
                entidadSel_popup.tblPrendaNEntidad_NuevoPedido
            );

        this.setState(
            {
                entidadSel_popup: entidadSel_popup,
                usuarioSel_prendasNUsuario_NuevoPedido: e.addedItems.length > 0 ? e.addedItems[0] : null,
            },
            () => {
                //Si repinto todo el grid para actualizar toolbar va muy lento
                let isTodas_tblPrendaNUsuario =
                    e.addedItems.length > 0 ? e.addedItems[0].isTodas_tblPrendaNUsuario : false;
                $("#dxButton_tblPrendaNEntidad_NuevoPedido_usuario")
                    .dxButton("instance")
                    .option("disabled", isTodas_tblPrendaNUsuario);
                setTimeout(() => {
                    $("#dxSwitch_noDisabled_usuario").dxSwitch("instance").option("value", isTodas_tblPrendaNUsuario);
                }, 0);
            }
        );
    }

    onContentReady_tblPrendaNEntidad_NuevoPedido(e) {
        e.element.find(".dx-freespace-row").css("height", 0);
    }

    dxSelectBox_tblTipoHabitacion_onValueChanged(e) {
        let { almacenSel, datasource_datos_tblTipoHabitacion_items } = this.state;
        if (almacenSel) {
            almacenSel.idTipoHabitacion = e.value;

            almacenSel.tblPrendaNAlmacen = almacenSel
                ? almacenSel.idTipoHabitacion
                    ? $.grep(datasource_datos_tblTipoHabitacion_items, function (tipoHabitacion) {
                        return tipoHabitacion.idTipoHabitacion === almacenSel.idTipoHabitacion;
                    })[0].tblPrendaNTipoHabitacion
                    : almacenSel.tblPrendaNAlmacen
                : [];

            this.setState({ almacenSel });
        }
    }

    onToolbarPreparing_dxDataGrid_tblPrendaNAlmacen(e) {
        let { almacenSel } = this.state;
        e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxButton",
            options: {
                text: this.getTrad("prenda"),
                icon: "plus",
                disabled:
                    !almacenSel || (almacenSel && !almacenSel.idTipoAlmacen) ? true : almacenSel.idTipoAlmacen === 4,
                onClick: this.dxDataGrid_tblPrendaNAlmacen_addPrenda_onClick,
            },
        });
    }

    dxDataGrid_tblPrendaNAlmacen_addPrenda_onClick(e) {
        this.setState({ isVisible_dxPopup_tblPrendaNAlmacen: true });
    }

    groupCellRender_tblPrendaNUsuario_NuevoPedido(item) {
        if (item.data.items.length === 0 || item.data.items[0].tblPrenda == null) return null;
        let isEntidad = item.data.items[0].tblPrenda.idEntidad;
        return <div>{isEntidad ? this.getTrad("prendasEnti") : this.getTrad("prendasCompañia")}</div>;
    }

    onToolbarPreparing_tblPrendaNEntidad_NuevoPedido(e) {
        let entidadSel_popup = { ...this.state.entidadSel_popup };
        let {
            usuarioSel_prendasNUsuario_NuevoPedido,
            isTodasPrendaNNuevoPedido_compañia,
            isTodasPrendaNNuevoPedido_entidad,
        } = this.state;

        let toolbarItems = e.toolbarOptions.items;
        $.each(toolbarItems, function (_, item) {
            if (item.name == "saveButton" || item.name == "revertButton") {
                item.visible = false;
            }
        });

        let { tipoGrid } = e.component.option();
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxSelectBox",
                options: {
                    width: 200,
                    items: this.enum_selectorFiltro_prendasPedido,
                    displayExpr: "denominacion",
                    valueExpr: "id",
                    value: entidadSel_popup[
                        tipoGrid === "prendasCompañia"
                            ? "isTodasPrendaNNuevoPedido_compañia"
                            : "isTodasPrendaNNuevoPedido_entidad"
                    ],
                    visible: tipoGrid !== "prendasUsuario",
                    onValueChanged: (arg) => {
                        this.dxSelectBox_filtroPrendas(arg, e);
                    },
                },
            },
            {
                location: "before",
                widget: "dxSwitch",
                options: {
                    height: 31,
                    width: 170,
                    switchedOnText: this.getTrad("todasDisponibles"),
                    switchedOffText: this.getTrad("personalizado"),
                    elementAttr: tipoGrid === "prendasUsuario" ? this.dxSwitch_noDisabled_usuario_elementAttr : null,
                    value:
                        usuarioSel_prendasNUsuario_NuevoPedido != null
                            ? usuarioSel_prendasNUsuario_NuevoPedido.isTodas_tblPrendaNUsuario
                            : false,
                    visible: tipoGrid === "prendasUsuario",
                    onValueChanged: (arg) => {
                        this.dxSwitch_filtroPrendas(arg, e);
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    text: this.getTrad("prenda"),
                    icon: "plus",
                    disabled:
                        (tipoGrid === "prendasUsuario" &&
                            usuarioSel_prendasNUsuario_NuevoPedido != null &&
                            usuarioSel_prendasNUsuario_NuevoPedido.isTodas_tblPrendaNUsuario) ||
                        (tipoGrid === "prendasCompañia" && isTodasPrendaNNuevoPedido_compañia) ||
                        (tipoGrid === "prendasEntidad" && isTodasPrendaNNuevoPedido_entidad),
                    elementAttr:
                        tipoGrid === "prendasCompañia"
                            ? this.elementAttr_tblPrendaNEntidad_NuevoPedido_compañia
                            : tipoGrid === "prendasEntidad"
                                ? this.elementAttr_tblPrendaNEntidad_NuevoPedido_entidad
                                : this.elementAttr_tblPrendaNEntidad_NuevoPedido_usuario,
                    onClick: (arg) => {
                        this.onClick_dxDataGrid_tblPrendaNEntidad_NuevoPedido(arg, e);
                    },
                },
            }
        );
    }

    onClick_dxDataGrid_tblPrendaNEntidad_NuevoPedido(arg, e) {
        let { tipoGrid } = e.component.option();

        this.setState({
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia: tipoGrid === "prendasCompañia",
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad: tipoGrid === "prendasEntidad",
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario: tipoGrid === "prendasUsuario",
        });
    }

    dxSwitch_filtroPrendas(e, grid) {
        let { entidadSel_popup, usuarioSel_prendasNUsuario_NuevoPedido } = this.state;

        usuarioSel_prendasNUsuario_NuevoPedido.isTodas_tblPrendaNUsuario = e.value;
        if (e.value) {
            usuarioSel_prendasNUsuario_NuevoPedido.tblPrendaNUsuarioNEntidad = $.extend(
                true,
                [],
                entidadSel_popup.tblPrendaNEntidad_NuevoPedido
            );
        }

        $.each(entidadSel_popup.tblPrendaNUsuarioNEntidad, function (index, item) {
            if (item.idUsuario === usuarioSel_prendasNUsuario_NuevoPedido.idUsuario) {
                item.isTodas_tblPrendaNUsuario = usuarioSel_prendasNUsuario_NuevoPedido.isTodas_tblPrendaNUsuario;
                item.idPrenda = usuarioSel_prendasNUsuario_NuevoPedido.tblPrendaNUsuarioNEntidad;
            }
        });

        $("#dxButton_tblPrendaNEntidad_NuevoPedido_usuario").dxButton("instance").option("disabled", e.value);

        this.setState({
            entidadSel_popup: entidadSel_popup,
        });
    }

    suspendValueChagned = false;
    dxSelectBox_filtroPrendas(e, grid) {
        if (this.suspendValueChagned) {
            this.suspendValueChagned = false;
            return;
        }

        let _this = this;
        let entidadSel_popup = { ...this.state.entidadSel_popup };
        let {
            datasource_tblPrendaNEntidad_compañia,
            datasource_tblPrendaNEntidad,
            bk_tblPrendaNEntidad_NuevoPedido_compañia,
        } = this.state;
        let { tipoGrid } = grid.component.option();

        let prendasGrid = [];
        $.each($.extend(true, [], entidadSel_popup.tblPrendaNEntidad_NuevoPedido), function (index, item) {
            if (item.tblPrenda.idEntidad && tipoGrid === "prendasEntidad") prendasGrid.push(item);
            else if (tipoGrid === "prendasCompañia" && item.tblPrenda.idEntidad == null) prendasGrid.push(item);
        });

        if (e.value == false) {
            dxMensajePregunta("Se perderán los stocks de las prendas, ¿Seguro que desea continuar?", [
                [
                    this.getTrad("aceptar"),
                    function () {
                        _onValueChanged();
                    },
                    "normal",
                    "btnAceptar_alert",
                ],
                [
                    this.getTrad("cancelar"),
                    function () {
                        _this.suspendValueChagned = true;
                        e.component.option("value", e.previousValue);
                    },
                    "normal",
                    "btnCancelar_alert",
                ],
            ]);
        } else {
            _onValueChanged();
        }

        function _onValueChanged() {
            _this.setState({ isVisible_loading_dxPopup_configEntidad_pedidos: true });

            let data;

            if (tipoGrid === "prendasCompañia") {
                let field = "isTodasPrendaNNuevoPedido_compañia";
                data = datasource_tblPrendaNEntidad_compañia;
                entidadSel_popup[field] = e.value;
                $("#dxButton_tblPrendaNEntidad_NuevoPedido_compañia")
                    .dxButton("instance")
                    .option("disabled", e.value == true || e.value == false);
            } else {
                let field = "isTodasPrendaNNuevoPedido_entidad";
                data = datasource_tblPrendaNEntidad;
                entidadSel_popup[field] = e.value;
                $("#dxButton_tblPrendaNEntidad_NuevoPedido_entidad")
                    .dxButton("instance")
                    .option("disabled", e.value == true || e.value == false);
            }

            if (e.value === true) {
                //Todas
                let newItems = [];
                $($.extend(true, [], data)).each(function (index, item) {
                    let inArray = entidadSel_popup.tblPrendaNEntidad_NuevoPedido.find(function (element) {
                        return element.idPrenda === item.idPrenda;
                    });

                    if (!inArray) {
                        let itemFormated = {
                            idPrenda: item.idPrenda,
                            idEntidad: entidadSel_popup.idEntidad,
                            stockDefinido: null,
                            isPedidoPorPeticion: true,
                            tblPrenda: item,
                        };
                        newItems.push(itemFormated);
                    }
                });
                $.merge(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, newItems);

                $.each(entidadSel_popup.tblPrendaNUsuarioNEntidad, function (iUsuario, usuario) {
                    if (usuario.isTodas_tblPrendaNUsuario) $.merge(usuario.tblPrendaNUsuarioNEntidad, newItems);
                });
            } else if (e.value === false) {
                //Ninguna
                $($.extend(true, [], prendasGrid)).each(function (index, element) {
                    entidadSel_popup.tblPrendaNEntidad_NuevoPedido = $.grep(
                        entidadSel_popup.tblPrendaNEntidad_NuevoPedido,
                        function (item) {
                            return item.idPrenda != element.idPrenda;
                        }
                    );

                    $.each(entidadSel_popup.tblPrendaNUsuarioNEntidad, function (iUsuario, usuario) {
                        usuario.tblPrendaNUsuarioNEntidad = $.grep(usuario.tblPrendaNUsuarioNEntidad, function (item) {
                            return item.idPrenda != element.idPrenda;
                        });
                    });
                });

                bk_tblPrendaNEntidad_NuevoPedido_compañia = [];
            }

            setTimeout(() => {
                // Evita tardanza en cerrar el selectbox
                _this.setState({
                    entidadSel_popup: entidadSel_popup,
                    bk_tblPrendaNEntidad_NuevoPedido_compañia: bk_tblPrendaNEntidad_NuevoPedido_compañia,
                    isVisible_loading_dxPopup_configEntidad_pedidos: false,
                });
            }, 100);
            e.component.option("value", e.value);
        }
    }

    onToolbarPreparing_entidadesAsociadas(e) {
        e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxButton",
            options: {
                text: this.getTrad("entidad"),
                icon: "plus",
                onClick: this.onClick_dxButtonAddEntidad_entidadesAsociadas,
            },
        });
    }
    onClick_dxButtonAddEntidad_entidadesAsociadas(e) {
        this.setState({ isVisible_dxPopup_tblEntidadNEntidadAsociada: true });
    }

    onContentReady_tipoEntidad(e) {
        e.element.addClass("text-center");
    }

    dxList_tblTipoHabitacion_onClick_modify(e, item) {
        this.setState({
            isVisible_dxPopup_addTipoHabitacion: true,
            dxList_tipoHabitacionSel: item,
        });
    }

    dxList_tblTipoHabitacion_onClick_delete(e, item) {
        let _this = this;
        dxMensajePregunta(this.getTrad("preg_RegistroEliminarCorto"), [
            [
                this.getTrad("aceptar"),
                function (e) {
                    let { entidadSel_popup } = _this.state;
                    let tblTipoHabitacion = $.grep(entidadSel_popup["tblTipoHabitacion"], function (tipoHab) {
                        return tipoHab.idTipoHabitacion != item.idTipoHabitacion;
                    });

                    entidadSel_popup["tblTipoHabitacion"] = tblTipoHabitacion;
                    _this.setState({
                        entidadSel_popup,
                        datasource_dxDataGrid_tblPrendaNTipoHabitacion: [],
                        isDisabled_dxButton_addPrendaTipoHabitacion: false,
                    });
                },
                "danger",
            ],
            [this.getTrad("cancelar"), function () { }],
        ]);
    }

    onContentReady_dxDataGrid_gestionCorreosAlbaranes(e) {
        e.element.find(".dx-freespace-row").css("height", 0);
    }

    onRowPrepared_dxDataGrid_gestionCorreosAlbaranes(e) {
        if (e.rowType === "data") e.rowElement.addClass("tagBox-row");
    }

    onRowUpdating_dxDataGrid_gestionCorreosAlbaranes(e) {
        if (typeof e.newData.tblTipoIncidencia !== "undefined")
            e.newData.tblReports = $.map(e.newData.tblReports, function (id, index) {
                return id;
            });
    }

    onRowInserting_dxDataGrid_gestionCorreosAlbaranes(e) {
        e.data.tblReports = $.map(e.data.tblReports, function (id, index) {
            return id;
        });
        delete e.data.__KEY__;
    }

    onEditingStart_dxDataGrid_gestionCorreosAlbaranes(e) {
        if (e.column.dataField === "tblReports") {
            this.dxDataGrid_gestionCorreosAlbaranes
                .getController("validating")
                .validate(true)
                .done(function (is_gridValid) {
                    if (!is_gridValid) {
                        e.cancel = true;
                    }
                });
        }
    }

    onToolbarPreparing_dxDataGrid_gestionCorreosAlbaranes(e) {
        $.each(e.toolbarOptions.items, function (index, item) {
            if (item.name === "saveButton" && item.name === "addRowButton") {
                item.visible = false;
            }
        });

        e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxButton",
            toolbar: "bottom",
            options: {
                width: "100%",
                icon: "plus",
                text: this.getTrad("añadirCorreo"),
                type: "normal",
                onClick: (x) => {
                    this.dxDataGrid_gestionCorreosAlbaranes_onClick_add(x, e);
                },
            },
        });
    }

    onFieldDataChanged_dxForm_entidad = (e) => {
        if (e.dataField === "enablePlanificadorPedidos") {
            // if (
            //     e.value === false &&
            //     this.state.entidadSel_popup.tblPrendaNEntidad_NuevoPedido.filter((x) => x.ratio != null).length > 0
            // ) {
            //     dxMensajePregunta(this.getTrad("alerta_eliminarRatiosPlanificador"), [
            //         [this.getTrad("aceptar"), () => this.setState({})],
            //         [
            //             this.getTrad("cancelar"),
            //             () => {
            //                 let dxSwitch = Object.values(e.component._itemsRunTimeInfo._map).find(
            //                     (x) => x.item?.dataField === "enablePlanificadorPedidos"
            //                 ).widgetInstance;
            //                 dxSwitch.option("value", true);
            //             },
            //         ],
            //     ]);
            // } else {
            this.setState({});
            // }
        }
    };

    onContentReady_dxTabPanel_Entidad(e) {
        e.element.find(".dx-multiview-item-content").css({ padding: "30px 15px 0px 15px" });
    }

    itemTitleRender_dxTabPanel_Entidad(e) {
        let {
            isVisible_warning_entidadesAsociadas,
            isVisible_warning_general,
            isCalendar_valid,
            isValid_dxDataGrid_tblPrendaNEntidad_NuevoPedido,
            isVisible_warning_pedidos,
        } = this.state;

        if (e.name === "general")
            return (
                <div>
                    <i className={isVisible_warning_general ? "icon_Warning" : ""} /> {e.title}
                </div>
            );
        else if (e.name === "tblPrendaNEntidad_NuevoPedido")
            return (
                <div>
                    <i className={isVisible_warning_pedidos ? "icon_Warning" : ""} /> {e.title}
                </div>
            );
        else if (e.name === "tblEntidadNEntidadAsociada")
            return (
                <div>
                    <i className={isVisible_warning_entidadesAsociadas ? "icon_Warning" : ""} /> {e.title}
                </div>
            );
        else if (e.name === "calendario")
            return (
                <div>
                    <i className={!isCalendar_valid ? "icon_Warning" : ""} /> {e.title}
                </div>
            );
        else if (e.name === "tblPrendaNEntidad_NuevoPedido")
            return (
                <div>
                    <i className={!isValid_dxDataGrid_tblPrendaNEntidad_NuevoPedido ? "icon_Warning" : ""} /> {e.title}
                </div>
            );

        return e.title;
    }

    onSelectionChanged_dxTabPanel_Entidad(e) {
        let items = e.addedItems;
        if (items.length > 0) {
            this.state.dxTabPanel_Entidad_tabIndex = items[0].tabIndex;
        } else {
            this.state.dxTabPanel_Entidad_tabIndex = 0;
        }
    }

    dxDataGrid_gestionCorreosAlbaranes_onClick_add(x, e) {
        e.component.addRow();
    }

    onValueChanged_tipoEntidad(e) {
        let _this = this;
        let { entidadSel_popup } = this.state;
        //#region Para revertir onValueChanged si hay registros
        let revert = false;

        if (
            (entidadSel_popup.isPrincipal && entidadSel_popup.tblEntidadNEntidadPrincipal.length > 0) ||
            (!entidadSel_popup.isPrincipal && entidadSel_popup.tblEntidadNEntidadSecundaria.length > 0)
        ) {
            revert = true;
        }
        //#endregion

        function _onValueChanged() {
            let value = e.previousValue; // Es el anterior porque después de escoger un tipo de entidad se vuelve a poner el original para mostrar el alert.

            entidadSel_popup.isPrincipal = value;

            if (entidadSel_popup.isPrincipal) entidadSel_popup.tblEntidadNEntidadPrincipal = [];
            else entidadSel_popup.tblEntidadNEntidadSecundaria = [];

            $("#texto_tipoEntidad").text(value ? "Entidades secundarias:" : "Entidades principales:");

            entidadSel_popup.isPrincipal = value;
            _this.setState({ entidadSel_popup });

            setTimeout(() => {
                _this.dxDataGrid_tblEntidadesAsociadas_set_dataSource();
            }, 0);
        }

        if (revert) {
            dxMensajePregunta(this.getTrad("preg_PerderEntiSel"), [
                [
                    this.getTrad("aceptar"),
                    function () {
                        _onValueChanged();
                    },
                    "normal",
                    "btnAceptar_alert",
                ],
                [this.getTrad("cancelar"), function () { }, "normal", "btnCancelar_alert"],
            ]);
        } else {
            _onValueChanged();
        }
    }

    onShowing_prendasTipoHabitacion(e) {
        var prendasSel = this.dxDataGrid_tblPrendaNTipoHabitacion.getDataSource().items();
        var idsPrendasSel_compa = [];
        var idsPrendasSel_enti = [];
        $.each(prendasSel, function (index, prenda) {
            if (prenda.tblPrenda.idEntidad) {
                prenda.tblPrenda.idPrenda = prenda.idPrenda;
                idsPrendasSel_enti.push(prenda.tblPrenda);
            } else {
                prenda.tblPrenda.idPrenda = prenda.idPrenda;
                idsPrendasSel_compa.push(prenda.tblPrenda);
            }
        });

        this.setState({
            selectedItems_dxList_prendasCompañia: idsPrendasSel_compa,
            selectedItems_dxList_prendasEntidad: idsPrendasSel_enti,
        });

        setTimeout(() => {
            this.dxList_prendasCompañia.option("searchValue", "");
            this.dxList_prendasEntidad.option("searchValue", "");
            this.dxList_prendasCompañia.scrollTo(0);
            this.dxList_prendasEntidad.scrollTo(0);
        }, 0);
    }

    onHiding_prendasTipoHabitacion(e) {
        this.setState({ isVisible_dxPopup_tblPrendaNTipoHabitacion: false });
    }

    onShowing_tblPrendaNEntidad_NuevoPedido(e) {
        let {
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario,
        } = this.state;

        if (!isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario) {
            var prendasSel = this.state.entidadSel_popup.tblPrendaNEntidad_NuevoPedido;
            var idsPrendasSel_compa = [];
            var idsPrendasSel_enti = [];

            $.each(prendasSel, function (index, prenda) {
                if (prenda.tblPrenda.idEntidad) {
                    idsPrendasSel_enti.push(prenda.tblPrenda.idPrenda);
                } else {
                    idsPrendasSel_compa.push(prenda.tblPrenda.idPrenda);
                }
            });

            if (isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia) {
                this.dxList_tblPrendaNEntidad_NuevoPedido_compañia.option({
                    selectedItemKeys: idsPrendasSel_compa,
                });
            } else if (isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad) {
                this.dxList_tblPrendaNEntidad_NuevoPedido_entidad.option({
                    selectedItemKeys: idsPrendasSel_enti,
                });
            }
        } else {
            let entidadSel_popup = { ...this.state.entidadSel_popup };

            var prendasSel = this.state.usuarioSel_prendasNUsuario_NuevoPedido;
            var idsPrendasSel_compa = [];
            var idsPrendasSel_enti = [];
            $.each(prendasSel.tblPrendaNUsuarioNEntidad, function (index, prenda) {
                if (prenda.tblPrenda.idEntidad) {
                    idsPrendasSel_enti.push(prenda.idPrenda);
                } else {
                    idsPrendasSel_compa.push(prenda.idPrenda);
                }
            });

            let prendasCompa = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
                return item.tblPrenda.idEntidad == null;
            });
            let prendasEnti = $.grep(entidadSel_popup.tblPrendaNEntidad_NuevoPedido, function (item) {
                return item.tblPrenda.idEntidad != null;
            });

            prendasCompa = $.map(prendasCompa, function (item) {
                return item.tblPrenda;
            });
            prendasEnti = $.map(prendasEnti, function (item) {
                return item.tblPrenda;
            });

            prendasCompa = query(prendasCompa).sortBy("codigoPrenda").toArray();
            prendasEnti = query(prendasEnti).sortBy("codigoPrenda").toArray();

            this.setState({
                dxList_prendaNCompañia_usuario_dataSource: prendasCompa,
                dxList_prendaNEntidad_usuario_dataSource: prendasEnti,
            });
            this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_compañia.option({
                selectedItemKeys: idsPrendasSel_compa,
            });
            this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_entidad.option({
                selectedItemKeys: idsPrendasSel_enti,
            });
        }
        setTimeout(() => {
            this.configList_popupPrendasPedido();
        }, 0);
    }

    configList_popupPrendasPedido() {
        let {
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad,
        } = this.state;
        if (isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario) {
            this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_compañia.option({
                searchValue: "",
            });
            this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_compañia.scrollTo(0);
            this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_entidad.option({
                searchValue: "",
            });
            this.dxList_tblPrendaNEntidad_NuevoPedido_usuario_entidad.scrollTo(0);
        } else if (isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia) {
            this.dxList_tblPrendaNEntidad_NuevoPedido_compañia.scrollTo(0);
            this.dxList_tblPrendaNEntidad_NuevoPedido_compañia.option({
                searchValue: "",
            });
        } else if (isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad) {
            this.dxList_tblPrendaNEntidad_NuevoPedido_entidad.scrollTo(0);
            this.dxList_tblPrendaNEntidad_NuevoPedido_entidad.option({
                searchValue: "",
            });
        }
    }

    onHiding_tblPrendaNEntidad_NuevoPedido(e) {
        this.setState({
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_compañia: false,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_entidad: false,
            isVisible_dxPopup_tblPrendaNEntidad_NuevoPedido_usuario: false,
        });
    }

    onShowing_tblEntidadNEntidadAsociada(e) {
        //#region Get Entidades totales
        let { entidadSel_popup } = this.state;

        let compañias = $.extend(true, [], this.state.datasource_datos_compaEnti);
        let compaSel = $.grep(compañias, function (item) {
            return item.idCompañia === entidadSel_popup.idCompañia;
        });

        let entidades = [];
        if (compaSel.length > 0) {
            entidades = $.map(compaSel[0].tblEntidad, function (item) {
                // excepto la entidad actual
                return {
                    idEntidad: item.idEntidad,
                    denominacion: item.denominacion,
                    tblEntidadNEntidadPrincipal: item.tblEntidadNEntidadPrincipal,
                    tblEntidadNEntidadSecundaria: item.tblEntidadNEntidadSecundaria,
                    isPrincipal: item.isPrincipal,
                };
            });
        }

        let entidades_totales = $.grep(entidades, function (item) {
            return (
                entidadSel_popup.idEntidad !== item.idEntidad &&
                ((entidadSel_popup.isPrincipal && !item.isPrincipal) ||
                    (!entidadSel_popup.isPrincipal && item.isPrincipal))
            );
        });
        //#endregion

        let entidades_asociadas = entidadSel_popup.isPrincipal
            ? entidadSel_popup.tblEntidadNEntidadPrincipal
            : entidadSel_popup.tblEntidadNEntidadSecundaria;

        let entidades_noAsociadas = entidades_totales.filter(function (item) {
            return !entidades_asociadas.some(function (subItem) {
                return item.idEntidad === subItem.idEntidad;
            });
        });

        this.dxList_entidadesAsociada.option("searchValue", "");
        this.dxList_entidadesAsociada.scrollTo(0);

        this.setState({
            datasource_dxList_entidadesAsociada: new ArrayStore({
                key: "idEntidad",
                data: entidades_noAsociadas,
            }),
            selectedItems_dxList_entidadesAsociada: [],
        });
    }

    onHiding_tblEntidadNEntidadAsociada(e) {
        this.setState({
            isVisible_dxPopup_tblEntidadNEntidadAsociada: false,
            selectedItems_dxList_entidadesAsociada: [],
        });
    }

    render_dxButton_guardar_tblEntidadNEntidadAsociada(e) {
        return (
            <Button text={this.getTrad("guardar")} onClick={this.onClick_dxButton_guardar_tblEntidadNEntidadAsociada} />
        );
    }

    onClick_dxButton_guardar_tblEntidadNEntidadAsociada(e) {
        let { datasource_dxDataGrid_tblEntidadesAsociadas, selectedItems_dxList_entidadesAsociada } = this.state;
        let newItems = $.map(selectedItems_dxList_entidadesAsociada, function (item) {
            return {
                idEntidad: item.idEntidad,
                denominacion: item.denominacion,
            };
        });

        $.each(newItems, function (index, item) {
            datasource_dxDataGrid_tblEntidadesAsociadas.insert(item);
        });

        this.setState({
            isVisible_dxPopup_tblEntidadNEntidadAsociada: false,
            datasource_dxDataGrid_tblEntidadesAsociadas: datasource_dxDataGrid_tblEntidadesAsociadas,
        });
    }

    render_dxButton_cancelar_tblEntidadNEntidadAsociada(e) {
        return (
            <Button
                text={this.getTrad("cancelar")}
                onClick={this.onClick_dxButton_cancelar_tblEntidadNEntidadAsociada}
            />
        );
    }

    onClick_dxButton_cancelar_tblEntidadNEntidadAsociada(e) {
        this.setState({ isVisible_dxPopup_tblEntidadNEntidadAsociada: false });
    }

    // VALIDATION CALLBACK
    validationCallback_CustomRule_emailCorreosAlb(e) {
        var correo = e.value;

        var rows = this.dxDataGrid_gestionCorreosAlbaranes.getVisibleRows();

        var existe =
            $.grep(rows, function (row) {
                return row.data.denominacion.toLowerCase() === correo.toLowerCase();
            }).length > 1;

        return !existe;
    }

    validationCallback_mayorCero(e) {
        return e.value > 0;
    }

    //#endregion

    //#region IMPORTAR ENTIDAD
    importarEntidad_onClick(e) {
        let lavItems = this.datasource_datos_lavanderia.items();
        this.setState({ isVisible_dxPopup_importarEntidad: true });
        this.dxList_importarEntidad_lavanderia_changeSelItem([lavItems[0]]);
    }

    dxList_importarEntidad_lavanderia_changeSelItem(value) {
        let _this = this;
        this.setState({ selectedItems_dxList_importarEntidad_lavanderia: value }, () => {
            _this.datasource_datos_importarEntidad.reload();
        });
        this.dxList_importarEntidad_entidad.option("searchValue", "");
    }

    dxList_importarEntidad_lavanderia_onOptionChanged(e) {
        if (e.name == "selectedItems") {
            this.dxList_importarEntidad_lavanderia_changeSelItem(e.value);
        }
    }

    dxList_importarEntidad_compañia_onOptionChanged(e) {
        if (e.name == "selectedItems") {
            this.setState({
                selectedItems_dxList_importarEntidad_compañia: e.value,
                datasource_importarEntidad_entidad: e.value && e.value.length > 0 ? e.value[0].tblEntidad : [],
                selectedItems_dxList_importarEntidad_entidad: [],
            });
        }
    }

    dxList_importarEntidad_entidad_onOptionChanged(e) {
        if (e.name == "selectedItems") {
            this.setState({ selectedItems_dxList_importarEntidad_entidad: e.value });
        }
    }

    dxPopup_importarEntidad_onShowing(e) {
        setTimeout(() => {
            this.dxList_importarEntidad_entidad.option("searchValue", "");
            this.dxList_importarEntidad_lavanderia.option("searchValue", "");
            this.dxList_importarEntidad_compañia.option("searchValue", "");
            this.dxList_importarEntidad_lavanderia.scrollTo(0);
            this.dxList_importarEntidad_compañia.scrollTo(0);
            this.dxList_importarEntidad_entidad.scrollTo(0);
        }, 0);
    }

    dxPopup_importarEntidad_onHiding(e) {
        this.setState({
            isVisible_dxPopup_importarEntidad: false,
            selectedItems_dxList_importarEntidad_lavanderia: [],
            selectedItems_dxList_importarEntidad_entidad: [],
        });
    }

    render_dxButton_importar_dxPopup_importarEntidaddes(e) {
        return (
            <Button
                text={this.getTrad("importarEntidadesSeleccionadas")}
                type="success"
                disabled={this.state.selectedItems_dxList_importarEntidad_entidad.length === 0}
                onClick={this.onClick_dxButton_importar_dxPopup_importarEntidaddes}
            />
        );
    }

    onClick_dxButton_importar_dxPopup_importarEntidaddes(e) {
        let { selectedItems_dxList_importarEntidad_entidad } = this.state;
        let idsEntidad = $.map(selectedItems_dxList_importarEntidad_entidad, function (item) {
            return item.idEntidad;
        });
        let _this = this;
        e.component.option("disabled", true);
        this.datasource_datos_tblEntidadNLavanderia
            .store()
            .insert({
                idLavanderia: _this.props.lavanderia.idLavanderia,
                idsEntidad: idsEntidad,
            })
            .done(function () {
                _this.setState({ entidadSel: null, compañiaSel: null });
                _this.datasource_datos_compaEnti.load().done(function () {
                    $("#dxTreeView_compaEnti")
                        .find(".dx-scrollable")
                        .data("dxScrollable")
                        .scrollTo(_this.scroll_dxTreeView_compaEnti);

                    _this.setState({ isVisible_dxPopup_importarEntidad: false });
                    e.component.option("disabled", false);

                    notify({
                        message: _this.getTrad("aviso_C_RegistroInsertado"),
                        type: "success",
                        displayTime: "1500",
                        closeOnClick: true,
                    });
                });
            })
            .fail(function (error) {
                e.component.option("disabled", false);
            });
    }
    //#endregion

    //#region DATAGRID PRENDAS
    cellRender_tblPrenda_edit(itemData) {
        return (
            <div
                className="container_spanCentrado pointer"
                onMouseDown={(e) => {
                    this.tblPrenda_edit_onClick(e, itemData);
                }}
            >
                <div className="icon_Edit font-size-lg" />
            </div>
        );
    }

    tblPrenda_edit_onClick(e, rowData) {
        let prendaSel = $.extend(true, {}, rowData.data);

        let denoPrendaSel;
        let tipoPrendaSel;

        let _this = this;
        $.each(_this.datasource_datos_tblFamilia.items(), function (index, familia) {
            $.each(familia.tblTipoPrenda, function (index, tipoPrenda) {
                var deno = $.grep(tipoPrenda.tblDenoPrenda, function (denoPrenda) {
                    return denoPrenda.idDenoPrenda === prendaSel.idDenoPrenda;
                });

                if (deno.length > 0) {
                    denoPrendaSel = deno[0];
                    tipoPrendaSel = tipoPrenda;
                    return false; //Salimos del bucle
                }
            });

            if (denoPrendaSel != null) {
                return false; //Salimos del bucle
            }
        });

        if (denoPrendaSel != null) {
            let denoTipoPrenda =
                tipoPrendaSel.codigo !== "9" ? tipoPrendaSel.tblTraduccion[_this.props.idioma.codigo] : ""; //Sin asignar no saldrá

            if (denoPrendaSel.codigo !== "00" && denoPrendaSel.codigo !== "99") {
                //Varios y Otros no saldrá
                denoTipoPrenda += denoTipoPrenda && denoTipoPrenda.length > 0 ? " " : "";
                denoTipoPrenda += denoPrendaSel.tblTraduccion[_this.props.idioma.codigo];
            }

            prendaSel.denoTipoPrenda = denoTipoPrenda;
            prendaSel.denominacion = prendaSel.denominacion.replace(denoTipoPrenda, "");

            //Quitamos espacios
            while (prendaSel.denominacion.charAt(0) === " ") {
                prendaSel.denominacion = prendaSel.denominacion.substr(1);
            }

            if (prendaSel.tblPrecioLavadoPrenda.length === 0) {
                prendaSel.precioLavado = 0;
                prendaSel.tblPrecioLavadoPrenda = [{ fecha: new Date(), precio: 0 }];
            }
        } else {
            //PROVISIIONAL CODIGOS PRENDA ERRONEOS
            prendaSel.denoTipoPrenda = "";
        }

        $.each(prendaSel.tblDocumentoPrenda, function (index, item) {
            item.id = item.idDocumento;
        });

        _this.setState({ prendaSel: prendaSel, isVisible_dxPopup_prendas: true });
    }

    onCellPrepared_tblPrenda(e) {
        if (e.rowType === "data") {
            if (e.column.dataField == "tblPrecioLavadoPrenda") {
                e.cellElement.addClass("dxButton_popup_modificarPrecios_" + e.data.idPrenda);
            } else if (e.column.dataField == "tblPrenda_historico_peso") {
                e.cellElement.addClass("dxButton_popup_modificarPesos_" + e.data.idPrenda);
            } else if (e.column.dataField == "tblPrenda_historico_idTipoFacturacion") {
                e.cellElement.addClass("dxButton_popup_modificarTipoFacturacion_" + e.data.idPrenda);
            }
        }
    }

    customizeExportData_tblPrenda = (columns, data) => {
        const columnIndices = {
            peso: columns.findIndex((x) => x.dataField === "tblPrenda_historico_peso"),
            precioLavado: columns.findIndex((x) => x.dataField === "tblPrecioLavadoPrenda"),
            tipoFacturacion: columns.findIndex((x) => x.dataField === "tblPrenda_historico_idTipoFacturacion"),
        };

        return data.map((item) => {
            // Formatear peso para Excel
            item.values[columnIndices.peso] = item.values?.at(columnIndices.peso)?.at(0)?.peso + " g.";

            // Formatear precio para Excel
            let text = this.getTrad("sinPrecio");
            if (item.data.tblPrecioLavadoPrenda && item.data.tblPrecioLavadoPrenda.length > 0) {
                if (item.data.precioLavado > 0) {
                    text = formatNumber(
                        item.data.precioLavado,
                        columnIndices.precioLavado,
                        "currency",
                        config().defaultCurrency
                    );
                }
            }
            item.values[columnIndices.precioLavado] = text;

            // Formatear tipo de facturación para Excel
            let tipoFacturacion = this.datasource_datos_tblTipoFacturacion
                .items()
                .find((x) => x.idTipoFacturacion === item.data.tipoFact);
            item.values[columnIndices.tipoFacturacion] = tipoFacturacion ? tipoFacturacion.denominacion : "";

            return item;
        });
    };

    editCellRender_tblPrenda_tblPrecioLavadoPrenda(itemData) {
        const { user, lavanderia } = this.props;
        const allowEdit =
            (lavanderia.idCorporacion == 4 && user.isDepartamentoControl) || lavanderia.idCorporacion != 4;

        let text = this.getTrad("sinPrecio");
        if (itemData.data.tblPrecioLavadoPrenda && itemData.data.tblPrecioLavadoPrenda.length > 0) {
            if (itemData.data.precioLavado > 0) {
                text = formatNumber(itemData.data.precioLavado, 4, "currency", config().defaultCurrency);
            }
        }

        return (
            <>
                <Button
                    text={text}
                    className="w-100"
                    stylingMode={"text"}
                    disabled={!allowEdit}
                    onClick={(e) => {
                        this.editCellRender_tblPrenda_tblPrecioLavadoPrenda_onClick(e, itemData);
                    }}
                />
                {!allowEdit && (
                    <Tooltip
                        target={".dxButton_popup_modificarPrecios_" + itemData.data.idPrenda}
                        position="bottom"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                    >
                        <div>{"Solo pueden modificar el precio las personas del departamento de control."}</div>
                    </Tooltip>
                )}
            </>
        );
    }

    editCellRender_tblPrenda_tblPrecioLavadoPrenda_onClick(e, itemData) {
        this.setState({
            prendaSel: itemData.data,
            isCellEditing_dxDataGrid_tblPrenda: true,
            isVisible_dxPopup_precioLavadoPrenda: true,
        });
    }

    editCellRender_tblPrenda_tblPrenda_historico_peso(itemData) {
        const { user, lavanderia } = this.props;
        const allowEdit =
            (lavanderia.idCorporacion == 4 && user.isDepartamentoControl) || lavanderia.idCorporacion != 4;

        let text = this.getTrad("sinPeso");
        if (itemData.data.tblPrenda_historico_peso && itemData.data.tblPrenda_historico_peso.length > 0) {
            if (itemData.data.peso > 0) {
                text = itemData.data.peso + " g.";
            }
        }

        return (
            <>
                <Button
                    text={text}
                    className="w-100"
                    stylingMode={"text"}
                    disabled={!allowEdit}
                    onClick={(e) => {
                        this.editCellRender_tblPrenda_tblPrenda_historico_peso_onClick(e, itemData);
                    }}
                />
                {!allowEdit && (
                    <Tooltip
                        target={".dxButton_popup_modificarPesos_" + itemData.data.idPrenda}
                        position="bottom"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                    >
                        <div>{"Solo pueden modificar el peso las personas del departamento de control."}</div>
                    </Tooltip>
                )}
            </>
        );
    }

    editCellRender_tblPrenda_tblPrenda_historico_peso_onClick(e, itemData) {
        this.setState({
            prendaSel: itemData.data,
            isCellEditing_dxDataGrid_tblPrenda: true,
            isVisible_dxPopup_historicoPesoPrenda: true,
        });
    }

    editCellRender_tblPrenda_tblPrenda_historico_idTipoFacturacion(itemData) {
        const { user, lavanderia } = this.props;
        const allowEdit =
            (lavanderia.idCorporacion == 4 && user.isDepartamentoControl) || lavanderia.idCorporacion != 4;

        let text = this.getTrad("sinTipoFacturacion");
        let icon = null;
        if (
            itemData.data.tblPrenda_historico_idTipoFacturacion &&
            itemData.data.tblPrenda_historico_idTipoFacturacion.length > 0
        ) {
            if (itemData.data.tipoFact != null) {
                let tipoFacturacion = this.datasource_datos_tblTipoFacturacion
                    .items()
                    .filter((x) => x.idTipoFacturacion === itemData.data.tipoFact)[0];
                text = tipoFacturacion.denominacion;
                icon =
                    tipoFacturacion.icon === "svg_bed"
                        ? "_ icon_bed"
                        : tipoFacturacion.icon === "svg_kg"
                            ? "_ icon_Kg"
                            : "_ icon_Camisa";
            }
        }

        return (
            <>
                <Button
                    text={text}
                    stylingMode={"text"}
                    className="w-100"
                    icon={icon}
                    disabled={!allowEdit}
                    onClick={(e) => {
                        this.editCellRender_tblPrenda_tblPrenda_historico_idTipoFacturacion_onClick(e, itemData);
                    }}
                />
                {!allowEdit && (
                    <Tooltip
                        target={".dxButton_popup_modificarTipoFacturacion_" + itemData.data.idPrenda}
                        position="bottom"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                    >
                        <div>
                            {"Solo pueden modificar el tipo de facturacion las personas del departamento de control."}
                        </div>
                    </Tooltip>
                )}
            </>
        );
    }

    editCellRender_tblPrenda_tblPrenda_historico_idTipoFacturacion_onClick(e, itemData) {
        this.setState({
            prendaSel: itemData.data,
            isCellEditing_dxDataGrid_tblPrenda: true,
            isVisible_dxPopup_historicoIdTipoFacturacionPrenda: true,
        });
    }

    editCellRender_tblPrenda_tapa(itemData) {
        let { datasource_datos_tblColorTapa, datasource_datos_tblMarcaTapa } = this.state;

        var itemsColorTapa = datasource_datos_tblColorTapa.slice(0);
        itemsColorTapa.splice(0, 0, { idColorTapa: null });

        var itemsMarcaTapa = datasource_datos_tblMarcaTapa.slice(0);
        itemsMarcaTapa.splice(0, 0, { idMarcaTapa: null, marca: "N/A" });

        let datasource_tblTapa = {
            color: itemsColorTapa,
            marca: itemsMarcaTapa,
        };

        let value_tblTapa = {
            idPrenda: itemData.data.idPrenda,
            idColorTapa: itemData.data.idColorTapa,
            idMarcaTapa: itemData.data.idMarcaTapa,
        };

        return (
            <DropDownBox
                ref={this.DropDownBox_tapa_REF}
                dataSource={datasource_tblTapa}
                elementAttr={{ id: "DropDownBox_tapa_" + itemData.data.idPrenda }}
                value={value_tblTapa}
                showClearButton={false}
                stylingMode="underlined"
                contentRender={this.contentRender_dxDropDownBox_tapa}
                fieldTemplate={this.fieldTemplate_dxDropDownBox_tapa}
                onOpened={(e) => {
                    this.onOpened_tblPrenda_tapa(e, itemData);
                }}
                dropDownOptions={this.dropDownOptions_tapa}
            />
        );
    }

    onClick_dxButton_dropDownTapa(e, itemData) {
        let { prendaSel } = this.state;
        let _this = this;

        if (prendaSel) {
            $("#DropDownBox_tapa_" + prendaSel.idPrenda)
                .dxDropDownBox("instance")
                .close();

            let dxList_marcaTapa = ".dxList_marcas_" + prendaSel.idPrenda;
            let idMarcaTapa = $(dxList_marcaTapa).dxList("instance").option("selectedItemKeys")[0];
            let idColorTapa = parseInt($(".color_preview").attr("value"));

            prendaSel.idMarcaTapa = idMarcaTapa;
            prendaSel.idColorTapa = idColorTapa ? idColorTapa : null;

            this.datasource_tblPrenda
                .store()
                .update(prendaSel.idPrenda, {
                    idMarcaTapa: idMarcaTapa,
                    idColorTapa: idColorTapa ? idColorTapa : null,
                })
                .done(function () {
                    _this.setState({ prendaSel: null });
                });
        }
    }

    contentRender_dxDropDownBox_tapa(itemData) {
        let datasource_tblTapa = itemData.component.option("dataSource");
        return (
            <Box direction="col" align="space-around" crossAlign="stretch" height="100%" width="100%">
                <ItemBox ratio={1}>
                    <Box direction="row" align="space-around" crossAlign="stretch" height={"100%"} width="100%">
                        <ItemBox ratio={1}>
                            <Box direction="col" align="space-around" crossAlign="stretch" height="100%" width="100%">
                                <ItemBox baseSize={30}>
                                    <div className="d-flex flex-row ml-2 justify-content-center">
                                        <div className="font-size text-center flex-1">{this.getTrad("color")}</div>
                                        <div className="container_spanCentrado pl-3 flex-1 float-right">
                                            <span className="color_preview pt-2 text-center mb-1"></span>
                                        </div>
                                    </div>
                                </ItemBox>
                                <ItemBox baseSize={15} />
                                <ItemBox ratio={1}>
                                    <div className="pl-0 pt-2">
                                        {datasource_tblTapa.color.map((colorTapa, index) => {
                                            let isColor = colorTapa.idColorTapa != null;
                                            let color = colorTapa.codigoHexadecimal;

                                            return (
                                                <div
                                                    key={index}
                                                    className={isColor ? "" : "dx-theme-text-color"}
                                                    style={{
                                                        width: isColor ? "50%" : "100%",
                                                        marginTop: isColor ? "5px" : "9px",
                                                        paddingBottom: isColor ? "" : "11px",
                                                        marginBottom: isColor ? "" : "5px",
                                                        borderBottom: isColor ? "" : "1px solid #DDD",
                                                        display: "inline-block",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <i
                                                        className={isColor ? "dx-icon dx-icon-square" : ""}
                                                        ref={(element) => {
                                                            if (element)
                                                                element.style.setProperty("color", color, "important");
                                                        }}
                                                        style={{
                                                            cursor: "pointer",
                                                            border: color === "#FFFFFF" ? "1px solid #BFBFBF" : "",
                                                            fontSize:
                                                                !isColor && color === "#FFFFFF"
                                                                    ? "27px"
                                                                    : !isColor
                                                                        ? "normal"
                                                                        : "30px",
                                                        }}
                                                        onClick={() => {
                                                            let color_preview = $(".color_preview");
                                                            color_preview.css({
                                                                "background-color":
                                                                    colorTapa.idColorTapa != null
                                                                        ? colorTapa.codigoHexadecimal
                                                                        : "transparent",
                                                                display:
                                                                    colorTapa.idColorTapa != null ? "block" : "none",
                                                            });
                                                            color_preview.attr("value", colorTapa.idColorTapa);
                                                        }}
                                                    >
                                                        {isColor ? "" : "Sin color"}
                                                    </i>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </ItemBox>
                            </Box>
                        </ItemBox>
                        <ItemBox baseSize={15} />
                        <ItemBox ratio={1}>
                            <Box direction="col" align="space-around" crossAlign="stretch" height="100%" width="100%">
                                <ItemBox baseSize={30}>
                                    <div className="font-size text-center">{this.getTrad("marca")}</div>
                                </ItemBox>
                                <ItemBox baseSize={15} />
                                <ItemBox ratio={1}>
                                    <List
                                        ref={this.dxList_marcas_REF}
                                        elementAttr={{
                                            class: "dxList_marcas_" + itemData.value.idPrenda,
                                        }}
                                        height={"100%"}
                                        dataSource={datasource_tblTapa.marca}
                                        selectionMode={"single"}
                                        scrolling={this.dxList_scrolling_infinite}
                                        displayExpr={"marca"}
                                        keyExpr={"idMarcaTapa"}
                                        pageLoadMode={"scrollBottom"}
                                        itemRender={this.itemRender_marca}
                                    ></List>
                                </ItemBox>
                            </Box>
                        </ItemBox>
                    </Box>
                </ItemBox>
                <ItemBox baseSize={15} />
                <ItemBox baseSize={35}>
                    <Toolbar>
                        <ToolbarItem location="after">
                            <Button
                                text={this.getTrad("aceptar")}
                                onClick={(e) => {
                                    this.onClick_dxButton_dropDownTapa(e, itemData);
                                }}
                            />
                        </ToolbarItem>
                    </Toolbar>
                </ItemBox>
            </Box>
        );
    }

    fieldTemplate_dxDropDownBox_tapa(value, fieldElement) {
        if (value == null) {
            fieldElement.append(
                $("<div = 'container_spanCentrado' />").dxTextBox({
                    readOnly: true,
                })
            );
        } else {
            var colorTapaSel = $.grep(this.datasource_datos_tblColorTapa.items(), function (cTapa) {
                return cTapa.idColorTapa === value.idColorTapa;
            })[0];

            var marcaTapaSel = $.grep(this.datasource_datos_tblMarcaTapa.items(), function (mTapa) {
                return mTapa.idMarcaTapa === value.idMarcaTapa;
            })[0];

            let style = {};
            if (colorTapaSel) style["background-color"] = colorTapaSel.codigoHexadecimal;

            let text = marcaTapaSel ? marcaTapaSel.marca : "";
            let fontTapa =
                (colorTapaSel && colorTapaSel.codigoHexadecimal === "#FFFFFF") || !colorTapaSel
                    ? "color-gray"
                    : "color-white";
            fontTapa += colorTapaSel ? " border " : "";

            fieldElement.append(
                $("<div class='container_spanCentrado' />").append(
                    $("<span />").append(
                        $("<div />")
                            .dxTextBox({
                                height: 28,
                                width: "100%",
                                value: text,
                                elementAttr: { class: fontTapa + " mb-0 text-center" },
                                style: { "background-color": "black" },
                                readOnly: true,
                            })
                            .css(style)
                    )
                )
            );
        }
    }

    onOpened_tblPrenda_tapa(e, itemData) {
        e.component.option("tblPrenda_rowSel", itemData.row);

        let { data } = itemData.row;
        let { datasource_datos_tblColorTapa } = this.state;
        let itemsColorTapa = datasource_datos_tblColorTapa.slice(0);
        itemsColorTapa.splice(0, 0, { idColorTapa: null });

        let colorTapaSel = $.grep(itemsColorTapa, function (cTapa) {
            return cTapa.idColorTapa === data.idColorTapa;
        })[0];

        let color_preview = $(".color_preview");
        color_preview.css({
            "background-color":
                colorTapaSel != null && colorTapaSel.idColorTapa != null
                    ? colorTapaSel.codigoHexadecimal
                    : "transparent",
            display: colorTapaSel != null && colorTapaSel.idColorTapa != null ? "block" : "none",
        });
        color_preview.attr("value", colorTapaSel.idColorTapa);

        let dxList_marcas = ".dxList_marcas_" + data.idPrenda;
        $(dxList_marcas).dxList("instance").option("selectedItemKeys", [data.idMarcaTapa]);

        this.setState({ prendaSel: itemData.data });
    }

    dropDownContentRender_tblPrenda_tapa(data) {
        return (
            <div className="w-100 p-2">
                {data.map((colorTapa, index) => {
                    let isColor = colorTapa.idColorTapa != null;
                    let color = colorTapa.codigoHexadecimal;

                    return (
                        <div
                            key={index}
                            className={isColor ? "" : "dx-theme-text-color"}
                            style={{
                                width: isColor ? "50%" : "100%",
                                marginTop: isColor ? "5px" : "",
                                paddingBottom: isColor ? "" : "5px",
                                marginBottom: isColor ? "" : "5px",
                                borderBottom: isColor ? "" : "1px solid #BFBFBF",
                                display: "inline-block",
                                textAlign: "center",
                            }}
                        >
                            <i
                                className={isColor ? "dx-icon dx-icon-square" : ""}
                                ref={(element) => {
                                    if (element) element.style.setProperty("color", color, "important");
                                }}
                                style={{
                                    cursor: "pointer",
                                    border: color === "#FFFFFF" ? "1px solid #BFBFBF" : "",
                                    fontSize: !isColor && color === "#FFFFFF" ? "27px" : !isColor ? "normal" : "30px",
                                }}
                                onClick={(e) => {
                                    this.dropDownContentRender_tblPrenda_tapa_onClick(e, colorTapa);
                                }}
                            >
                                {isColor ? "" : "Sin color"}
                            </i>
                        </div>
                    );
                })}
            </div>
        );
    }

    dropDownContentRender_tblPrenda_tapa_onClick(e, colorTapa) {
        let { tblPrenda_rowSel } = this.state;

        this.dxDataGrid_tblPrenda.cellValue(tblPrenda_rowSel.rowIndex, "idColorTapa", colorTapa.idColorTapa);
        this.dxDataGrid_tblPrenda.saveEditData();
    }

    cellRender_tblPrenda_udsXBacReparto(itemData) {
        let icon = itemData.data.elementoReparto === 1 && itemData.data.udsXBacReparto < 2 ? "icon_Camisa" : "icon_Bac";
        return (
            <div className="w-100 he-100 d-flex text-center">
                <div className="container_cellRender_elemTrans">
                    <div className={icon + " content font-size-lg m-auto cellRender_elemTrans"}></div>
                </div>
                <div className="flex-50">{formatNumber(itemData.data.udsXBacReparto, 0, "decimal")}</div>
            </div>
        );
    }

    cellRender_tblPrenda_udsXBacOffice(itemData) {
        let icon = itemData.data.elementoReparto === 1 && itemData.data.udsXBacOffice < 2 ? "icon_Camisa" : "icon_Bac";
        return (
            <div className="w-100 he-100 d-flex text-center">
                <div className="container_cellRender_elemTrans">
                    <div className={icon + " content font-size-lg m-auto cellRender_elemTrans"}></div>
                </div>
                <div className="flex-50">{formatNumber(itemData.data.udsXBacOffice, 0, "decimal")}</div>
            </div>
        );
    }

    cellRender_tblPrenda_udsXBacPedido(itemData) {
        let icon = itemData.data.elementoReparto === 1 && itemData.data.udsXBacPedido < 2 ? "icon_Camisa" : "icon_Bac";
        return (
            <div className="w-100 he-100 d-flex text-center">
                <div className="container_cellRender_elemTrans">
                    <div className={icon + " content font-size-lg m-auto cellRender_elemTrans"}></div>
                </div>
                <div className="flex-50">{formatNumber(itemData.data.udsXBacPedido, 0, "decimal")}</div>
            </div>
        );
    }
    cellRender_tblPrenda_udsXBacProduccion(itemData) {
        let icon =
            itemData.data.elementoReparto === 1 && itemData.data.udsXBacProduccion < 2 ? "icon_Camisa" : "icon_Bac";
        return (
            <div className="w-100 he-100 d-flex text-center">
                <div className="container_cellRender_elemTrans">
                    <div className={icon + " content font-size-lg m-auto cellRender_elemTrans"}></div>
                </div>
                <div className="flex-50">{formatNumber(itemData.data.udsXBacProduccion, 0, "decimal")}</div>
            </div>
        );
    }

    itemRender_marca(data, index, elem) {
        return <div className="text-center font-size-md pt-1- pb-1">{data.marca}</div>;
    }

    onContentReady_tapa(e) {
        var html = e.component.content();
        $(html).css("padding", "15");
    }

    onContentReady_tblPrenda_tapa(e, itemData) {
        let { datasource_datos_tblColorTapa } = this.state;

        var itemsColorTapa = datasource_datos_tblColorTapa.slice(0);
        itemsColorTapa.splice(0, 0, { idColorTapa: null });

        var colorTapaSel = $.grep(itemsColorTapa, function (cTapa) {
            return cTapa.idColorTapa === itemData.data.idColorTapa;
        })[0];

        let dropDownButton = e.component;
        let element = dropDownButton.$element().find(".dx-dropdownbutton-action .dx-icon").first();
        let isColor = colorTapaSel && colorTapaSel.idColorTapa;

        element
            .addClass("dx-icon")
            .removeClass("dx-theme-text-color")
            .text(isColor ? "" : "N/A")
            .attr(
                "style",
                "color: " +
                (isColor ? colorTapaSel.codigoHexadecimal : "black") +
                " !important; width:24px; font-size: " +
                (isColor ? "18px" : "14px")
            );

        if (colorTapaSel.codigoHexadecimal === "#FFFFFF") {
            element.css("border", "1px solid #BFBFBF").css("font-size", "16px").css("width", "18px");
        }

        if (itemData.data.idColorTapa) {
            element.addClass("dx-icon-square");
        } else {
            element.removeClass("dx-icon-square");
            element.css("font-style", "normal");
        }
    }
    //#endregion

    //#region POPUP PRENDAS
    onHiding_dxPopup_seleccionTipoPrenda(e) {
        this.setState({
            isVisible_dxPopup_seleccionTipoPrenda: false,
        });
    }

    onHidden_dxPopup_seleccionTipoPrenda(e) {
        this.setState({
            familiaSel: null,
            tipoPrendaSel: null,
        });
    }

    dxList_denoPrenda_onSelectionChanged(e) {
        let _this = this;
        if (e.addedItems.length > 0) {
            let { familiaSel, tipoPrendaSel } = this.state;
            let denoPrendaSel = e.addedItems[0];

            var codigo = familiaSel.codigo + tipoPrendaSel.codigo + denoPrendaSel.codigo;
            var denominacion =
                tipoPrendaSel.codigo !== "9" ? tipoPrendaSel.tblTraduccion[this.props.idioma.codigo] : ""; //Sin asignar no saldrá

            if (denoPrendaSel.codigo !== "00" && denoPrendaSel.codigo !== "99") {
                //Varios y Otros no saldrá
                denominacion += denominacion.length > 0 ? " " : "";
                denominacion += denoPrendaSel.tblTraduccion[this.props.idioma.codigo];
            }

            let datasource_codigo_tblPrenda = new DataSource({
                paginate: false,
                store: this.odatastore_tblPrenda_codigo,
                filter: ["codigoPrenda", "startswith", codigo],
                select: ["idPrenda", "activo", "codigoPrenda"],
            });

            datasource_codigo_tblPrenda.load().done(function (data) {
                function continuarAltaPrenda() {
                    let values = $.map(data, function (item, index) {
                        return parseInt(item.codigoPrenda);
                    });

                    if (values.length === 0)
                        //Primera prenda
                        codigo += "00";
                    else codigo = Math.max.apply(Math, values) + 1;

                    let { prendaSel } = _this.state;

                    if (prendaSel) {
                        prendaSel.idDenoPrenda = denoPrendaSel.idDenoPrenda;
                        prendaSel.denoTipoPrenda = denominacion;
                        prendaSel.denominacion = "";
                        prendaSel.codigoPrenda = codigo.toString();
                        prendaSel.tipoFact = null;

                        _this.dxForm_prenda.repaint();
                        _this.setState({
                            prendaSel: prendaSel,
                            isVisible_dxPopup_seleccionTipoPrenda: false,
                            isVisible_dxPopup_prendas: true,
                        });
                    }
                }

                let prendasDesactivadas = query(data).filter(["activo", "=", false]).toArray();
                if (prendasDesactivadas.length > 0) {
                    dxMensajePregunta(_this.getTrad("preg_prendasDesactivadasMismoCodigo"), [
                        [
                            _this.getTrad("continuarAlta"),
                            function (e) {
                                continuarAltaPrenda();
                            },
                            "danger",
                        ],
                        [
                            _this.getTrad("verPrendasDesactivadas"),
                            function () {
                                _this.dxDataGrid_tblPrenda.beginUpdate();

                                _this.dxDataGrid_tblPrenda.clearFilter();
                                _this.dxDataGrid_tblPrenda.columnOption("codigoPrenda", "filterValue", codigo);
                                _this.dxDataGrid_tblPrenda.columnOption(
                                    "codigoPrenda",
                                    "selectedFilterOperation",
                                    "startswith"
                                );

                                _this.dxDataGrid_tblPrenda.endUpdate();

                                _this.setState({
                                    isVisible_dxPopup_seleccionTipoPrenda: false,
                                    isVisible_dxPopup_prendas: false,
                                });
                            },
                        ],
                    ]);
                } else {
                    continuarAltaPrenda();
                }
            });
        }
    }

    dxList_tipoPrenda_onSelectionChanged(e) {
        if (e.addedItems.length > 0) {
            this.setState({
                datasource_dxList_denoPrenda: e.addedItems[0].tblDenoPrenda,
                tipoPrendaSel: e.addedItems[0],
            });
        } else {
            this.setState({
                datasource_dxList_denoPrenda: null,
                tipoPrendaSel: null,
            });
        }
    }

    dxList_familia_onSelectionChanged(e) {
        let dataSource_tipoPrenda = null,
            familiaSel = null;

        if (e.addedItems.length > 0) {
            dataSource_tipoPrenda = e.addedItems[0].tblTipoPrenda;
            familiaSel = e.addedItems[0];
        }

        this.setState({
            datasource_dxList_denoPrenda: null,
            datasource_dxList_tipoPrenda: dataSource_tipoPrenda,
            familiaSel: familiaSel,
        });
    }

    dxList_addPrenda_itemTemplate(itemData) {
        return itemData.tblTraduccion[this.props.idioma.codigo];
    }

    dxForm_prenda_onContentReady(e) {
        var form = e.component;
        var formData = form.option("formData");

        function tratamiento_UdsXBac(val, editor) {
            var disabled = val === 1 ? true : false;
            var min = val === 1 ? 1 : 2;
            editor.option("disabled", disabled);
            editor.option("min", min);
        }

        tratamiento_UdsXBac(formData.elementoReparto, form.getEditor("udsXBacReparto"));
        tratamiento_UdsXBac(formData.elementoOffice, form.getEditor("udsXBacOffice"));
        tratamiento_UdsXBac(formData.elementoPedido, form.getEditor("udsXBacPedido"));
        tratamiento_UdsXBac(formData.elementoProduccion, form.getEditor("udsXBacProduccion"));

        //CSS
        e.element.find(".dx-scrollable-wrapper").css({
            "padding-right": 10,
            "padding-left": 10,
        });
    }

    previsualizacion_popupPrendas_render(e) {
        let { prendaSel } = this.state;
        let idColorTapa = prendaSel ? prendaSel.idColorTapa : null;
        let idMarcaTapa = prendaSel ? prendaSel.idMarcaTapa : null;

        let colorTapaSel = $.grep(this.datasource_datos_tblColorTapa.items(), function (cTapa) {
            return cTapa.idColorTapa === idColorTapa;
        })[0];

        let marcaTapaSel = $.grep(this.datasource_datos_tblMarcaTapa.items(), function (mTapa) {
            return mTapa.idMarcaTapa === idMarcaTapa;
        })[0];

        return (
            <div className="container_spanCentrado">
                <div
                    id="divColorTapa"
                    className="d-block text-center"
                    style={{
                        height: 36,
                        width: 150,
                        borderRadius: "3px",
                        border: "1px solid #DDDDDD",
                        fontSize: "18px",
                        padding: 5,
                        backgroundColor: colorTapaSel ? colorTapaSel.codigoHexadecimal : "",
                        color: colorTapaSel
                            ? colorTapaSel.codigoHexadecimal === "#FFFFFF"
                                ? "#000000"
                                : "#FFFFFF"
                            : "",
                    }}
                >
                    {!colorTapaSel ? "N/A" : ""}
                    {marcaTapaSel ? marcaTapaSel.marca : ""}
                </div>
            </div>
        );
    }

    dxPopup_prendas_dxSelectBox_marcaTapa_onValueChanged(e) {
        var denoMarcaTapa =
            !e.value || e.component.option("selectedItem") == null
                ? ""
                : e.component.option("selectedItem").denominacion;

        $("#divColorTapa").text(denoMarcaTapa);
    }

    dxPopup_prendas_dxSelectBox_colorTapa_onValueChanged(e, _this) {
        let selectedItem = e.component.option("selectedItem");
        if (selectedItem == null) return;

        let disabled = !e.value ? true : false;
        let codigoHexadecimal = !e.value ? "transparent" : e.component.option("selectedItem").codigoHexadecimal;

        var form = _this.dxForm_prenda;
        if (disabled) {
            form.updateData({ idMarcaTapa: null });
            $("#divColorTapa").text(!e.value ? "N/A" : " ");
        }

        form.getEditor("idMarcaTapa").option("disabled", disabled);

        $("#divColorTapa").css("background-color", codigoHexadecimal);
        $("#divColorTapa").css(
            "color",
            codigoHexadecimal === "#FFFFFF" || codigoHexadecimal === "transparent" ? "#000000" : "#FFFFFF"
        );
    }

    dxNumberBox_stockDefinido_onEditorPreparing(e) {
        if (e.parentType == "dataRow" && e.dataField == "stockDefinido") {
            e.editorOptions.value = e.editorOptions.value ? e.editorOptions.value : null;
            e.editorOptions.disabled = e.row.data.isPedidoPorPeticion;

            e.editorOptions.onValueChanged = function (arg) {
                e.setValue(arg.value);
            };
        }
    }

    dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowUpdating(e) {
        let entidadSel_popup = { ...this.state.entidadSel_popup };
        $.each(e.newData, function (dataField, value) {
            // Actualizar prendas n usuario
            $.each(entidadSel_popup.tblPrendaNUsuarioNEntidad, function (index, item) {
                let prendaNUser = $.grep(item.tblPrendaNUsuarioNEntidad, function (prenda) {
                    return prenda.idPrenda == e.key;
                });
                if (prendaNUser.length > 0) {
                    prendaNUser[0][dataField] = value;
                }
            });

            // Actualizar ratio / stock
            // if (dataField == "stockDefinido" && e.oldData.ratio != null) {
            //   e.newData.ratio = null;
            // }
            // if (dataField == "ratio" && e.oldData.stockDefinido != null) {
            //   e.newData.stockDefinido = null;
            // }
        });
    }

    dxDataGrid_tblPrendaNEntidad_NuevoPedido_onRowRemoving(e) {
        let entidadSel_popup = { ...this.state.entidadSel_popup };
        entidadSel_popup.tblPrendaNEntidad_NuevoPedido = $.grep(
            entidadSel_popup.tblPrendaNEntidad_NuevoPedido,
            function (item) {
                return item.idPrenda != e.key;
            }
        );

        $.each(entidadSel_popup.tblPrendaNUsuarioNEntidad, function (index, item) {
            item.tblPrendaNUsuarioNEntidad = $.grep(item.tblPrendaNUsuarioNEntidad, function (prenda) {
                return prenda.idPrenda != e.key;
            });
        });

        this.setState({ entidadSel_popup: entidadSel_popup });
    }

    dxDataGrid_tblPrendaNEntidad_NuevoPedido_onInitialized(e) {
        if (e.component.option("tipoGrid") == "prendasUsuario") e.component.columnOption("command:expand", "width", 0);
    }

    dxDataGrid_tblPrendaNEntidad_NuevoPedido_usuario_onRowRemoving(e) {
        let { usuarioSel_prendasNUsuario_NuevoPedido } = this.state;
        let entidadSel_popup = { ...this.state.entidadSel_popup };

        $.each(entidadSel_popup.tblPrendaNUsuarioNEntidad, function (index, usuario) {
            if (usuario.idUsuario == usuarioSel_prendasNUsuario_NuevoPedido.idUsuario) {
                let prendas = $.grep(usuario.tblPrendaNUsuarioNEntidad, function (item) {
                    return item.idPrenda != e.key;
                });
                entidadSel_popup.tblPrendaNUsuarioNEntidad[index].idPrenda = prendas;
            }
        });
        this.setState({ entidadSel_popup: entidadSel_popup });
    }

    dxNumberBox_stockDefinido_onRowValidating(e) {
        this.state.isValid_dxDataGrid_tblPrendaNEntidad_NuevoPedido = e.brokenRules.length === 0;
        return e.brokenRules.length === 0;
    }

    dxPopup_prendas_transporte_dxSelectBox_onValueChanged(e, _this) {
        let udsXBac = e.value === 1 ? 1 : 2;

        let { prendaSel } = this.state;

        if (prendaSel) {
            let editor;
            let dxSelectbox_name = e.component.option("name");

            if (dxSelectbox_name == "elementoReparto") {
                if (e.value === 1 || (e.value === 2 && prendaSel.udsXBacReparto < 2))
                    prendaSel.udsXBacReparto = udsXBac;
                editor = this.dxForm_prenda.getEditor("udsXBacReparto");
            } else if (dxSelectbox_name == "elementoOffice") {
                if (e.value === 1 || (e.value === 2 && prendaSel.udsXBacOffice < 2)) prendaSel.udsXBacOffice = udsXBac;
                editor = this.dxForm_prenda.getEditor("udsXBacOffice");
            } else if (dxSelectbox_name == "elementoPedido") {
                if (e.value === 1 || (e.value === 2 && prendaSel.udsXBacPedido < 2)) prendaSel.udsXBacPedido = udsXBac;
                editor = this.dxForm_prenda.getEditor("udsXBacPedido");
            } else if (dxSelectbox_name == "elementoProduccion") {
                if (e.value === 1 || (e.value === 2 && prendaSel.udsXBacProduccion < 2))
                    prendaSel.udsXBacProduccion = udsXBac;
                editor = this.dxForm_prenda.getEditor("udsXBacProduccion");
            }

            let disabled = prendaSel[dxSelectbox_name] === 1 ? true : false;
            let min = prendaSel[dxSelectbox_name] === 1 ? 1 : 2;
            if (editor) {
                editor.option("disabled", disabled);
                editor.option("min", min);
            }
            this.dxForm_prenda.updateData(prendaSel);
        }
    }

    dxPopup_prendas_dxNumberBox_onValueChanged(e) {
        if (!e.value === null) {
            e.component.option("value", e.component.option("min"));
        }
    }

    dxForm_prenda_denominacion_onValueChanged(e) {
        let { prendaSel } = this.state;
        prendaSel.denominacion = e.value;
        this.setState({ prendaSel });
    }

    onValueChanged_replaceUpperCase_denomiancion(e) {
        e.component.option("value", e.value.replace(/\s\s+/g, " ").toUpperCase());
    }

    updateArchivos_documentos(data, accionesModificarDatos) {
        let { prendaSel } = this.state;

        let ultimoArchivo = data[data.length - 1];

        prendaSel.tblDocumentoPrenda = [ultimoArchivo];
        this.setState(
            {
                prendaSel: prendaSel,
                accionesModificarDatos_fileUploader: accionesModificarDatos,
                showLoading_adjuntarArchivos: false,
            },
            () => {
                this.setState({ showLoading_adjuntarArchivos: null });
            }
        );
    }

    onClick_tipoPrenda_dxPpopup_prenda(e) {
        this.setState({ isVisible_dxPopup_seleccionTipoPrenda: true });
    }

    onHiding_dxPopup_prenda(e) {
        this.setState({
            isVisible_dxPopup_prendas: false,
            accionesModificarDatos_fileUploader: [],
        });
        this.dxForm_prenda._scrollable.scrollTo(0);
    }

    render_dxButton_guardar_dxPopup_prenda(itemData) {
        return <Button text={this.getTrad("guardar")} onClick={this.onClick_guardar_dxPopup_prenda} />;
    }

    render_dxButton_cancelar_dxPopup_prenda(itemData) {
        return <Button text={this.getTrad("cancelar")} onClick={this.onClick_cancelar_dxPopup_prenda} />;
    }

    onClick_guardar_dxPopup_prenda(e) {
        let prendaSel = $.extend(true, {}, this.state.prendaSel);
        let _this = this;
        let form = this.dxForm_prenda;
        if (prendaSel && form.validate().isValid) {
            //#region FORMAT DATA
            let denoTipoPrenda = prendaSel.denoTipoPrenda.length > 0 ? prendaSel.denoTipoPrenda + " " : "";
            prendaSel.denominacion = denoTipoPrenda + prendaSel.denominacion;

            delete prendaSel.denoTipoPrenda;
            delete prendaSel["tblPrendaNAlmacen@odata.count"];
            delete prendaSel["tblPrendaNEntidad_NuevoPedido@odata.count"];
            delete prendaSel["tblPrendaNTipoHabitacion@odata.count"];

            delete prendaSel["tblPrendaNAlmacen@odata"];
            delete prendaSel["tblPrendaNEntidad_NuevoPedido@odata"];
            delete prendaSel["tblPrendaNTipoHabitacion@odata"];

            delete prendaSel.tblPrendaNAlmacen;
            delete prendaSel.tblPrendaNEntidad_NuevoPedido;
            delete prendaSel.tblPrendaNTipoHabitacion;
            delete prendaSel.tblPrendaNUsuarioNEntidad;

            if (prendaSel.tblDocumentoPrenda) {
                $.each(prendaSel.tblDocumentoPrenda, function (index, item) {
                    item.idPrenda = prendaSel.idPrenda;
                    delete item.fecha;
                    delete item.fechaModificacion;
                    delete item.idPadre;
                    delete item.id;
                    delete item.icon;
                });
            }

            //#endregion

            e.component.option("disabled", true);
            if (!prendaSel.idPrenda) {
                this.datasource_tblPrenda
                    .store()
                    .insert(prendaSel)
                    .done(function () {
                        _this.setState({ isVisible_dxPopup_prendas: false });
                        form._scrollable.scrollTo(0);
                        notify({
                            message: _this.getTrad("aviso_C_RegistroInsertado"),
                            type: "success",
                            displayTime: "1500",
                            closeOnClick: true,
                        });

                        _this.datasource_tblPrenda.reload().done(function (data) {
                            _this.setState(
                                {
                                    prendaSel: null,
                                    datasource_dxDataGrid_tblPrenda: data,
                                    accionesModificarDatos_fileUploader: [],
                                },
                                () => {
                                    e.component.option("disabled", false);
                                }
                            );
                        });
                    })
                    .fail(function (error) {
                        e.component.option("disabled", false);
                    });
            } else {
                delete prendaSel.tblDocumentoPrenda;

                const objPrendaSel = {
                    ...prendaSel,
                    tblPrecioLavadoPrenda: prendaSel.tblPrecioLavadoPrenda.map(plp => {
                        delete plp.isCerrado;
                        return plp;
                    }),
                    tblPrenda_historico_peso: prendaSel.tblPrenda_historico_peso.map(php => {
                        delete php.isCerrado;
                        return php;
                    }),
                    tblPrenda_historico_idTipoFacturacion: prendaSel.tblPrenda_historico_idTipoFacturacion.map(phtf => {
                        delete phtf.isCerrado;
                        return phtf;
                    }),
                };

                this.saveDocuments(prendaSel.idPrenda).done(function () {
                    _this.datasource_tblPrenda
                        .store()
                        .update(prendaSel.idPrenda, objPrendaSel)
                        .done(function () {
                            _this.setState({
                                isVisible_dxPopup_prendas: false,
                                accionesModificarDatos_fileUploader: [],
                            });
                            form._scrollable.scrollTo(0);
                            notify({
                                message: _this.getTrad("aviso_C_RegistroActualizado"),
                                type: "success",
                                displayTime: "1500",
                                closeOnClick: true,
                            });

                            _this.datasource_tblPrenda.reload().done(function (data) {
                                _this.setState({ datasource_dxDataGrid_tblPrenda: data }, () => {
                                    e.component.option("disabled", false);
                                });
                            });
                        })
                        .fail(function () {
                            e.component.option("disabled", false);
                        });
                });
            }
        } else {
            form._scrollable.scrollToElement(form.element().find(".dx-invalid"));
        }
    }

    onClick_cancelar_dxPopup_prenda(e) {
        this.setState({ isVisible_dxPopup_prendas: false });
        this.dxForm_prenda._scrollable.scrollTo(0);
        setTimeout(() => {
            this.setState({ prendaSel: null });
        }, 100);
    }

    dxList_entidadesAsociada_onOptionChanged(args) {
        if (args.name === "selectedItems" && args.value != this.state.selectedItems_dxList_entidadesAsociada) {
            this.setState({ selectedItems_dxList_entidadesAsociada: args.value });
        }
    }

    dxList_prendasEntidad_onOptionChanged(args) {
        if (args.name === "selectedItems" && args.value != this.state.selectedItems_dxList_prendasEntidad) {
            this.setState({ selectedItems_dxList_prendasEntidad: args.value });
        }
    }

    dxList_prendasCompañia_onOptionChanged(args) {
        if (args.name === "selectedItems" && args.value != this.state.selectedItems_dxList_prendasCompañia) {
            this.setState({ selectedItems_dxList_prendasCompañia: args.value });
        }
    }

    tblPrendaNEntidad_NuevoPedido_compañia_datasource_onOptionChanged(args) {
        if (
            args.name === "selectedItems" &&
            args.value != this.state.selectedItems_tblPrendaNEntidad_NuevoPedido_compañia_datasource
        ) {
            this.setState({
                selectedItems_tblPrendaNEntidad_NuevoPedido_compañia_datasource: args.value,
            });
        }
    }

    tblPrendaNEntidad_NuevoPedido_entidad_datasource_onOptionChanged(args) {
        if (
            args.name === "selectedItems" &&
            args.value != this.state.selectedItems_tblPrendaNEntidad_NuevoPedido_entidad_datasource
        ) {
            this.setState({
                selectedItems_tblPrendaNEntidad_NuevoPedido_entidad_datasource: args.value,
            });
        }
    }

    tblPrendaNEntidad_NuevoPedido_usuario_datasource_onOptionChanged(args) {
        let selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource = [
            ...this.state.selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource,
        ];
        if (
            args.name === "selectedItems" &&
            args.value != selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource
        ) {
            let prendasCompañia = $.grep(
                selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource,
                function (item) {
                    return item.idEntidad == null;
                }
            );
            let prendasEntidad = $.grep(
                selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource,
                function (item) {
                    return item.idEntidad != null;
                }
            );

            if (args.component.option("tipoLista") == "prendasCompañia") {
                $.merge(args.value, prendasEntidad);
            } else if (args.component.option("tipoLista") == "prendasEntidad") {
                $.merge(args.value, prendasCompañia);
            }
            this.setState({
                selectedItems_tblPrendaNEntidad_NuevoPedido_usuario_datasource: args.value,
            });
        }
    }

    onClick_cancelar_dxPopup_addTipoHabitacion(e) {
        this.setState({ isVisible_dxPopup_addTipoHabitacion: false });
    }

    onEditorPreparing_tblPrenda(e) {
        if (e.parentType === "dataRow" && (e.dataField === "activo" || e.dataField === "isExtra")) {
            e.editorName = "dxSwitch";
        }
        if (e.parentType === "filterRow") {
            e.editorOptions.showClearButton = true;
        }
    }

    onRowPrepared_tblPrenda(e) {
        if (e.rowType === "data") {
            if (e.data.activo === false) {
                e.rowElement.addClass("disabledRow");
            }
        }
    }

    onRowUpdating_tblPrenda(e) {
        let _this = this;

        this.datasource_tblPrenda.store().update(e.key.idPrenda, e.newData);
    }

    onToolbarPreparing_tblPrenda(e) {
        const { user, lavanderia } = this.props;
        const allowEdit =
            (lavanderia.idCorporacion == 4 && user.isDepartamentoControl) || lavanderia.idCorporacion != 4;

        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxButton",
                toolbar: "bottom",
                options: {
                    width: "100%",
                    icon: "plus",
                    text: this.getTrad("prenda"),
                    type: "normal",
                    onClick: this.tblPrenda_addNuevaPrenda_onClick,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                toolbar: "bottom",
                cssClass: "addButton_modificarPrecios",
                options: {
                    width: "100%",
                    text: "Modificar precios",
                    type: "normal",
                    disabled: !allowEdit,
                    onClick: this.dxPopup_modificarPrecios_onClick,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: this.getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: (x) => {
                        this.dxDataGrid_limpiarFiltros_onClick(x, e);
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    icon: "refresh",
                    text: this.getTrad("actualizar"),
                    onClick: (x) => {
                        this.dxDataGrid_actualizar_onClick(x, e);
                    },
                },
            }
        );
    }

    tblPrenda_addNuevaPrenda_onClick(e) {
        let { compañiaSel, entidadSel } = this.state;
        let prendaSel = {
            activo: true,
            idCompañia: !compañiaSel ? null : compañiaSel.idCompañia,
            idEntidad: !compañiaSel ? entidadSel.idEntidad : null,
            denoTipoPrenda: null,
            idDenoPrenda: null,
            elementoReparto: 1,
            elementoOffice: 1,
            elementoPedido: 1,
            elementoProduccion: 1,
            udsXBacReparto: 1,
            udsXBacOffice: 1,
            udsXBacPedido: 1,
            udsXBacProduccion: 1,
            precioLavado: 0,
            peso: 0,
            tipoFact: null,
            isExtra: false,
            tblPrecioLavadoPrenda: [],
            tblPrenda_historico_peso: [],
            tblPrenda_historico_idTipoFacturacion: [],
        };

        this.setState({
            prendaSel: prendaSel,
            isVisible_dxPopup_seleccionTipoPrenda: true,
        });
    }

    dxDataGrid_limpiarFiltros_onClick(x, e) {
        e.component.clearFilter();
        notify({
            message: this.getTrad("aviso_C_FiltroRestaurado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
        });
    }

    dxDataGrid_actualizar_onClick(x, e) {
        e.component.refresh(true);
    }

    onRowRemoving_tblPrenda(e) {
        let { isVisible_delete_prendas } = this.state;
        if (isVisible_delete_prendas) {
            let mensaje = this.getTrad("alert_eliminarRegistro");
            let _this = this;

            let prendaNAlm = e.data["tblPrendaNAlmacen@odata.count"];
            let prendaNEnti_nuevoPed = e.data["tblPrendaNEntidad_NuevoPedido@odata.count"];
            let prendaNTipoHab = e.data["tblPrendaNTipoHabitacion@odata.count"];

            if (prendaNAlm + prendaNEnti_nuevoPed + prendaNTipoHab > 0) {
                //todo nombres definitivos y traducciones
                mensaje = this.getTrad("alert_eliminarPrenda");
                mensaje += "<div class='deletePrenda_alertList'>";
                mensaje += "<p>";
                if (prendaNAlm > 0) mensaje += " - Almacén";
                if (prendaNEnti_nuevoPed > 0) mensaje += "<br />" + " - Nuevo pedido de entidad";
                if (prendaNTipoHab > 0) mensaje += "<br />" + " - Tipo de habitación";
                mensaje += "</p>";
                mensaje += "</div>";

                mensaje += "<br />";
                mensaje += "<div class='importantText'>";
                mensaje +=
                    "<span>" +
                    "*" +
                    this.getTrad("importante").toUpperCase() +
                    ": " +
                    "</span> <span>" +
                    this.getTrad("alert_eliminarPrenda_importante") +
                    "</span>";
                mensaje += "</div>";
            }

            e.cancel = true;
            dxMensajePregunta(mensaje, [
                [
                    this.getTrad("si"),
                    function () {
                        _this.setState({ isVisible_delete_prendas: false });
                        let rowIndex = e.component.getRowIndexByKey(e.key);

                        e.component.deleteRow(rowIndex);
                        _this.setState({ isVisible_delete_prendas: true });
                    },
                    "normal",
                ],
                [this.getTrad("no"), function () { }],
            ]);
            $(".deletePrenda_alertList")
                .prev()
                .css({
                    "text-align": "justify",
                    "line-height": "22px",
                    "margin-bottom": "0.3rem",
                })
                .parent()
                .css("padding", "5px 15px 0 15px");
        }
    }

    onRowRemoved_tblPrenda(e) {
        let _this = this;
        let { entidadSel } = this.state;
        this.datasource_tblPrenda
            .store()
            .remove(e.data.idPrenda)
            .done(function () {
                _this.dxDataGrid_tblPrenda.beginCustomLoading();
                _this.datasource_datos_entidadSel.reload();
            });
    }

    //#endregion

    //#region MODIFICAR PRECIOS
    dxPopup_modificarPrecios_onClick(E) {
        this.setState({ isVisible_dxPopup_modificarPrecios: true });
    }

    onHiding_dxPopup_precioLavadoPrenda(e) {
        if (this.state.isCellEditing_dxDataGrid_tblPrenda) {
            this.setState({
                isVisible_dxPopup_precioLavadoPrenda: false,
                isCellEditing_dxDataGrid_tblPrenda: false,
                prendaSel: null,
            });
        } else
            this.setState({
                isVisible_dxPopup_precioLavadoPrenda: false,
                isCellEditing_dxDataGrid_tblPrenda: false,
            });
    }

    tblPrecioLavadoPrenda_render(e) {
        let text = this.getTrad("sinPrecio");
        let { prendaSel } = this.state;
        const { user, lavanderia } = this.props;
        const allowEdit =
            (lavanderia.idCorporacion == 4 && user.isDepartamentoControl) || lavanderia.idCorporacion != 4;

        if (prendaSel) {
            let tblPrecioLavadoPrenda = prendaSel.tblPrecioLavadoPrenda;
            let precioLavado = prendaSel.precioLavado;

            if (tblPrecioLavadoPrenda != null) {
                if (precioLavado > 0) {
                    text = formatNumber(precioLavado, 4, "currency", config().defaultCurrency);
                }
            }
        }

        return (
            <>
                <Button
                    ref={this.dxButton_tblPrecioLavadoPrenda_REF}
                    text={text}
                    stylingMode="outlined"
                    width="100%"
                    disabled={!allowEdit}
                    onClick={this.dxButton_tblPrecioLavadoPrenda_onClick}
                />
                {!allowEdit && (
                    <Tooltip
                        target={".dxButton_popup_modificarPrecios"}
                        position="bottom"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                    >
                        <div>{"Solo pueden modificar el precio las personas del departamento de control."}</div>
                    </Tooltip>
                )}
            </>
        );
    }

    dxButton_tblPrecioLavadoPrenda_onClick(e) {
        this.setState({ isVisible_dxPopup_precioLavadoPrenda: true });
    }
    
    reset_popup_preciosPrenda() {
        this.dxDataGrid_tblPrenda_precios.deselectAll();
        this.dxDateBox_preciosPrenda.option("value", new Date());
        this.dxNumberBox_precio_preciosPrenda.option("value", 0);
    }

    tratarDatos_UpdateMasivo_Prendas() {
        let tblPrendas = [];
        let precioPrenda = this.dxNumberBox_precio_preciosPrenda.option("value");
        let fecha = this.dxDateBox_preciosPrenda.option("value");
        $.each(this.dxDataGrid_tblPrenda_precios.getSelectedRowsData(), function (index, prenda) {
            //Si ya existe un precio para la fecha seleccionada, se elimina
            prenda.tblPrecioLavadoPrenda = prenda.tblPrecioLavadoPrenda.filter(
                (item) => formatDate(item.fecha) !== formatDate(fecha)
            );
            prenda.tblPrecioLavadoPrenda.push({
                idPrenda: prenda.idPrenda,
                fecha: fecha,
                precio: precioPrenda,
            });

            let prenda_ = {
                idPrenda: prenda.idPrenda,
                precioLavado: precioPrenda,
                tblPrecioLavadoPrenda: prenda.tblPrecioLavadoPrenda,
            };
            tblPrendas.push(prenda_);
        });

        return tblPrendas;
    }

    onContentReady_dxDataGrid_tblPrenda_precios(e) {
        e.component.endCustomLoading();
    }

    onRowPrepared_dxDataGrid_tblPrenda_precios(e) {
        if (e.rowType === "data") {
            if (e.data.activo === false) {
                e.rowElement.addClass("disabledRow");
            }
        }
    }

    toolbarPreciosPrenda_render(e) {
        return (
            <Box direction="row" width="100%" height="100%">
                <Item ratio={1}>
                    <div className="pb-2 font-weight-bold color-secondary">Fecha desde: </div>
                    <DateBox
                        ref={this.dxDateBox_preciosPrenda_REF}
                        displayFormat="dd/MM/yyyy"
                        calendarOptions={this.updatePreciosPrenda_calendarOptions}
                        width={120}
                    />
                </Item>
                <Item ratio={1}>
                    <div className="pb-2 font-weight-bold color-secondary">Nuevo precio: </div>
                    <NumberBox
                        ref={this.dxNumberBox_precio_preciosPrenda_REF}
                        step={0}
                        format={this.updatePreciosPrenda_precioFormat}
                        min={0}
                        max={99999.999}
                    ></NumberBox>
                </Item>
            </Box>
        );
    }

    onShowing_popup_preciosPrenda(e) {
        this.reset_popup_preciosPrenda();
    }

    hiding_popup_preciosPrenda(e) {
        this.dxDataGrid_tblPrenda_precios.option("dataSource", []);
        this.setState({ isVisible_dxPopup_modificarPrecios: false });
    }

    //#endregion

    //#region MODIFICAR TIPO FACTURACIÓN
    dxPopup_modificaridTipoFacturacions_onClick(e) {
        this.setState({ isVisible_dxPopup_historicoIdTipoFacturacionPrenda: true });
    }

    onHiding_dxPopup_prenda_historico_idTipoFacturacion(e) {
        if (this.state.isCellEditing_dxDataGrid_tblPrenda) {
            this.setState({
                isVisible_dxPopup_historicoIdTipoFacturacionPrenda: false,
                isCellEditing_dxDataGrid_tblPrenda: false,
                prendaSel: null,
            });
        } else
            this.setState({
                isVisible_dxPopup_historicoIdTipoFacturacionPrenda: false,
                isCellEditing_dxDataGrid_tblPrenda: false,
            });
    }

    onSave_PopupHistoricoPesoPrenda = (data, peso) => {
        const { isCellEditing_dxDataGrid_tblPrenda, prendaSel } = this.state;
        
        const newState = {
            isVisible_dxPopup_historicoPesoPrenda: false,
            isCellEditing_dxDataGrid_tblPrenda: false
        };

        if (isCellEditing_dxDataGrid_tblPrenda) {
            this.setState({
                ...newState,
                datasource_dxDataGrid_tblPrenda: data,
                prendaSel: null,
            });
        } else {            
            this.setState({
                ...newState,
                prendaSel: { ...prendaSel, peso, tblPrenda_historico_peso: data },
            });
        }
    }

    onSave_PopupHistoricoPrecioLavadoPrenda = (data, precioLavado) => {
        const { isCellEditing_dxDataGrid_tblPrenda, prendaSel } = this.state;

        const newState = {
            isVisible_dxPopup_precioLavadoPrenda: false,
            isCellEditing_dxDataGrid_tblPrenda: false,
        };

        if (isCellEditing_dxDataGrid_tblPrenda) {
            this.setState({
                ...newState,
                datasource_dxDataGrid_tblPrenda: data,
                prendaSel: null,
            });
        } else {            
            this.setState({
                ...newState,
                prendaSel: { ...prendaSel, precioLavado, tblPrecioLavadoPrenda: data },
            });
        }
    }

    onSave_PopupHistoricoTipoFacturacionPrenda = (data, tipoFact) => {
        const { isCellEditing_dxDataGrid_tblPrenda, prendaSel } = this.state;

        const newState = {
            isVisible_dxPopup_historicoIdTipoFacturacionPrenda: false,
            isCellEditing_dxDataGrid_tblPrenda: false,
        };

        if (isCellEditing_dxDataGrid_tblPrenda) {
            this.setState({
                ...newState,
                datasource_dxDataGrid_tblPrenda: data,
                prendaSel: null,
            });
        } else {            
            this.setState({
                ...newState,
                prendaSel: { ...prendaSel, tipoFact, tblPrenda_historico_idTipoFacturacion: data },
            });
        }
    }

    tblPrenda_historico_idTipoFacturacion_render(e) {
        let { prendaSel } = this.state;
        const { user, lavanderia } = this.props;
        const allowEdit =
            (lavanderia.idCorporacion == 4 && user.isDepartamentoControl) || lavanderia.idCorporacion != 4;

        let text = this.getTrad("sinTipoFacturacion");
        let icon = null;

        if (prendaSel) {
            let tblPrenda_historico_idTipoFacturacion = prendaSel.tblPrenda_historico_idTipoFacturacion;
            let idTipoFacturacion = prendaSel.tipoFact;

            if (tblPrenda_historico_idTipoFacturacion != null) {
                if (idTipoFacturacion != null) {
                    let tipoFacturacion = this.datasource_datos_tblTipoFacturacion
                        .items()
                        .filter((x) => x.idTipoFacturacion === idTipoFacturacion)[0];
                    text = tipoFacturacion.denominacion;
                    icon =
                        tipoFacturacion.icon === "svg_bed"
                            ? "_ icon_bed"
                            : tipoFacturacion.icon === "svg_kg"
                                ? "_ icon_Kg"
                                : "_ icon_Camisa";
                }
            }
        }

        return (
            <>
                <Button
                    ref={this.dxButton_prenda_historico_idTipoFacturacion_REF}
                    text={text}
                    icon={icon}
                    stylingMode="outlined"
                    width="100%"
                    disabled={!allowEdit}
                    onClick={this.dxButton_prenda_historico_idTipoFacturacion_onClick}
                />
                {!allowEdit && (
                    <Tooltip
                        target={".dxButton_popup_modificarTipoFacturacion"}
                        position="bottom"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                    >
                        <div>
                            {"Solo pueden modificar el idTipoFacturacion las personas del departamento de control."}
                        </div>
                    </Tooltip>
                )}
            </>
        );
    }

    dxButton_prenda_historico_idTipoFacturacion_onClick(e) {
        this.setState({ isVisible_dxPopup_historicoIdTipoFacturacionPrenda: true });
    }

    //#endregion

    //#region MODIFICAR PESOS
    dxDataGrid_prenda_historico_peso_onModified(e) {
        let { prendaSel, isCellEditing_dxDataGrid_tblPrenda } = this.state;
        if (prendaSel) {
            $.each(prendaSel.tblPrenda_historico_peso, function (index, pesoPrenda) {
                delete pesoPrenda.__KEY__;
            });

            var pesosOrdenados = query(prendaSel.tblPrenda_historico_peso)
                .filter(function (dataItem) {
                    return (
                        convertClienteUtcToSinUtc(new Date(formatDate_parameter(dataItem.fecha))) <=
                        convertClienteUtcToSinUtc(new Date(formatDate_parameter(new Date())))
                    );
                })
                .sortBy("fecha", true)
                .toArray();

            var peso = pesosOrdenados.length > 0 ? pesosOrdenados[0].peso : 0;
            prendaSel.peso = peso;

            if (!isCellEditing_dxDataGrid_tblPrenda) this.dxButton_prenda_historico_peso.option("text", peso + " g.");
        }
    }

    onHiding_dxPopup_prenda_historico_peso(e) {
        if (this.state.isCellEditing_dxDataGrid_tblPrenda) {
            this.setState({
                isVisible_dxPopup_historicoPesoPrenda: false,
                isCellEditing_dxDataGrid_tblPrenda: false,
                prendaSel: null,
            });
        } else
            this.setState({
                isVisible_dxPopup_historicoPesoPrenda: false,
                isCellEditing_dxDataGrid_tblPrenda: false,
            });
    }

    tblPrenda_historico_peso_render(e) {
        let text = this.getTrad("sinPeso");
        let { prendaSel } = this.state;
        const { user, lavanderia } = this.props;
        const allowEdit =
            (lavanderia.idCorporacion == 4 && user.isDepartamentoControl) || lavanderia.idCorporacion != 4;

        if (prendaSel) {
            let tblPrenda_historico_peso = prendaSel.tblPrenda_historico_peso;
            let peso = prendaSel.peso;

            if (tblPrenda_historico_peso != null) {
                if (peso > 0) {
                    text = peso + " g.";
                }
            }
        }

        return (
            <>
                <Button
                    ref={this.dxButton_prenda_historico_peso_REF}
                    text={text}
                    stylingMode="outlined"
                    width="100%"
                    disabled={!allowEdit}
                    onClick={this.dxButton_prenda_historico_peso_onClick}
                />
                {!allowEdit && (
                    <Tooltip
                        target={".dxButton_popup_modificarPesos"}
                        position="bottom"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                    >
                        <div>{"Solo pueden modificar el peso las personas del departamento de control."}</div>
                    </Tooltip>
                )}
            </>
        );
    }

    dxButton_prenda_historico_peso_onClick(e) {
        this.setState({ isVisible_dxPopup_historicoPesoPrenda: true });
    }

    onInitNewRow_dxDataGrid_prenda_historico_peso(e) {
        e.data.fecha = new Date(new Date().setHours(0, 0, 0, 0));
        e.data.peso = 0;
    }

    onRowRemoving_dxDataGrid_prenda_historico_peso(e) {
        if (e.component.getVisibleRows().length === 1) {
            e.cancel = true;
            notify({
                message: this.getTrad("error_historicoPesoAlmenosUnPeso"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }

    onToolbarPreparing_dxDataGrid_prenda_historico_peso(e) {
        let _this = this;
        $.each(e.toolbarOptions.items, function (index, item) {
            switch (item.name) {
                case "addRowButton":
                    item.options.text = _this.getTrad("añadir");
                    item.options.icon = "plus";
                    item.options.type = "success";
                    item.showText = "always";
                    break;
                default:
                    break;
            }
        });
    }

    //#endregion

    //#region LISTAS EDIFICIOS_SECCIONES
    onClick_dxButton_edificio(e) {
        this.setState({
            datasource_tblSeccionNivel2: null,
            seccionNivel1_Sel: null,
            seccionNivel2_Sel: null,
            isVisible_dxPopup_addSeccionNivel1: true,
        });
    }

    onClick_dxButton_seccion2(e) {
        this.setState({
            seccionNivel2_Sel: null,
            isVisible_dxPopup_addSeccionNivel2: true,
        });
    }

    itemRender_tblSeccionNivel1(itemData, index, itemElement) {
        return (
            <div className="container_spanCentrado d-flex h-25px">
                <div className="list_denominacion_container">{itemData.denominacion}</div>
                <Toolbar elementAttr={this.elementAttr_toolbarClass}>
                    <ToolbarItem location="after">
                        <div className="container_spanCentrado dxToolbar_opcion p-2">
                            <div
                                className="font-size-lg icon_Edit center-svg"
                                onClick={this.dxList_seccionNivel1_edit_onClick}
                            />
                        </div>
                    </ToolbarItem>
                    <ToolbarItem location="after">
                        <div className="container_spanCentrado dxToolbar_opcion p-2">
                            <div
                                className="font-size-lg icon_Cerrar center-svg center-svg"
                                onClick={this.dxList_seccionNivel1_remove_onClick}
                            />
                        </div>
                    </ToolbarItem>
                </Toolbar>
            </div>
        );
    }

    onSelectionChanged_tblSeccionNivel1(e) {
        var dataSource = [];
        if (e.addedItems.length > 0) {
            var seccionN1 = e.addedItems[0];
            dataSource = seccionN1.tblSeccionNivel2;
            this.setState({
                datasource_tblSeccionNivel2: dataSource,
                seccionNivel1_Sel: seccionN1,
            });
        } else if (
            e.removedItems.length > 0 &&
            e.removedItems[0].idSeccionNivel1 === this.state.seccionNivel1_Sel.idSeccionNivel1
        ) {
            var seccionN1 = e.removedItems[0];
            dataSource = seccionN1.tblSeccionNivel2;
            this.setState({
                datasource_tblSeccionNivel2: dataSource,
                seccionNivel1_Sel: seccionN1,
            });
        } else {
            this.setState({
                datasource_tblSeccionNivel2: dataSource,
                seccionNivel1_Sel: null,
                seccionNivel2_Sel: null,
            });
        }
    }

    itemRender_tblSeccionNivel2(itemData, index, itemElement) {
        return (
            <div className="container_spanCentrado d-flex h-25px">
                <div className="list_denominacion_container">{itemData.denominacion}</div>
                <Toolbar elementAttr={this.elementAttr_toolbarClass}>
                    <ToolbarItem location="after">
                        <div className="container_spanCentrado dxToolbar_opcion p-2">
                            <div
                                className="font-size-lg icon_Edit center-svg"
                                onClick={(e) => {
                                    this.dxList_seccionNivel2_edit_onClick(e, itemData);
                                }}
                            />
                        </div>
                    </ToolbarItem>
                    <ToolbarItem location="after">
                        <div className="container_spanCentrado dxToolbar_opcion p-2">
                            <div
                                className="font-size-lg icon_Cerrar center-svg center-svg"
                                onClick={(e) => {
                                    this.dxList_seccionNivel2_remove_onClick(e, itemData);
                                }}
                            />
                        </div>
                    </ToolbarItem>
                </Toolbar>
            </div>
        );
    }

    dxList_seccionNivel2_edit_onClick(e, itemData) {
        this.dxForm_addSeccionNivel2.updateData("denominacion", itemData.denominacion);
        this.setState({
            isVisible_dxPopup_addSeccionNivel2: true,
            isSeccion2_editing: true,
            seccionNivel2_Sel: itemData,
        });
    }

    dxList_seccionNivel2_remove_onClick(e, itemData) {
        let { seccionNivel1_Sel, seccionNivel2_Sel } = this.state;
        let _this = this;

        this.setState({ isSeccion2_editing: true, seccionNivel2_Sel: itemData });
        dxMensajePregunta(this.getTrad("preg_RegistroEliminarCorto"), [
            [
                this.getTrad("aceptar"),
                function (e) {
                    _this.datasource_datos_tblSeccionNivel2
                        .store()
                        .remove(itemData.idSeccionNivel2)
                        .done(function () {
                            _this.datasource_datos_secciones.reload().done(function (items) {
                                let itemSel = $.grep(items, function (item) {
                                    return item.idSeccionNivel1 == seccionNivel1_Sel.idSeccionNivel1;
                                });
                                _this.setState({
                                    datasource_datos_secciones: items,
                                    seccionNivel1_Sel: itemSel[0],
                                    datasource_tblSeccionNivel2: itemSel[0].tblSeccionNivel2,
                                    isSeccion2_editing: false,
                                    datasource_tblSeccionNivel2: [],
                                });
                            });
                        });
                },
                "danger",
            ],
            [
                this.getTrad("cancelar"),
                function () {
                    _this.setState({
                        isSeccion2_editing: false,
                        seccionNivel2_Sel: null,
                    });
                },
            ],
        ]);
    }

    onItemClick_tblSeccionNivel2(e) {
        setTimeout(() => {
            if (!this.state.isSeccion2_editing) {
                let value = $.extend(true, [], e.itemData);
                this.setState({
                    seccionNivel2_Sel: value,
                    dxTabPanel_prendasAlm_selctedIndex: 1,
                });
                this.datasource_datos_tblAlmacen.reload();
            }
        }, 0);
    }

    dxPopup_nuevoEdificio_onShown(e) {
        this.dxForm_addSeccionNivel1.focus();
    }

    dxPopup_nuevoEdificio_onHiding(e) {
        this.setState({ isVisible_dxPopup_addSeccionNivel1: false });
    }

    dxPopup_nuevoEdificio_onHidden(e) {
        setTimeout(() => {
            this.dxForm_addSeccionNivel1.resetValues();
        }, 0);
    }

    onClick_cancelar_dxPopup_nuevoEdificio(e) {
        this.setState({ isVisible_dxPopup_addSeccionNivel1: false });
    }

    render_dxButton_guardar_dxPopup_nuevoEdificio(itemData) {
        return <Button text={this.getTrad("guardar")} onClick={this.onClick_guardar_dxPopup_nuevoEdificio} />;
    }

    render_dxButton_cancelar_dxPopup_nuevoEdificio(itemData) {
        return <Button text={this.getTrad("cancelar")} onClick={this.onClick_cancelar_dxPopup_nuevoEdificio} />;
    }

    onClick_guardar_dxPopup_nuevoEdificio(e) {
        let form = this.dxForm_addSeccionNivel1;
        let formData = form.option("formData");
        let _this = this;
        let { seccionNivel1_Sel, entidadSel } = this.state;

        if (formData && form.validate().isValid) {
            var item = {
                idEntidad: entidadSel.idEntidad,
                denominacion: formData.denominacion,
            };

            e.component.option("disabled", true);
            if (!seccionNivel1_Sel) {
                //INSERT
                this.datasource_datos_tblSeccionNivel1
                    .store()
                    .insert(item)
                    .done(function (sn1) {
                        _this.datasource_datos_secciones.reload().done(function (data) {
                            delete sn1["@odata.context"];
                            _this.setState({
                                datasource_datos_secciones: data,
                                datasource_tblSeccionNivel2: [],
                                seccionNivel1_Sel: sn1,
                                isVisible_dxPopup_addSeccionNivel1: false,
                            });
                            e.component.option("disabled", false);
                        });
                    })
                    .fail(function (error) {
                        e.component.option("disabled", false);
                    });
            } //UPDATE
            else {
                seccionNivel1_Sel.denominacion = formData.denominacion;
                this.datasource_datos_tblSeccionNivel1
                    .store()
                    .update(seccionNivel1_Sel.idSeccionNivel1, item)
                    .done(function () {
                        _this.datasource_datos_secciones.reload().done(function (data) {
                            _this.setState({
                                seccionNivel1_Sel: seccionNivel1_Sel,
                                isVisible_dxPopup_addSeccionNivel1: false,
                                datasource_datos_secciones: data,
                                datasource_tblSeccionNivel2: seccionNivel1_Sel.tblSeccionNivel2,
                            });
                            e.component.option("disabled", false);
                        });
                    })
                    .fail(function (error) {
                        e.component.option("disabled", false);
                    });
            }
        }
    }

    dxList_seccionNivel1_remove_onClick(e) {
        let { seccionNivel1_Sel } = this.state;
        let _this = this;
        dxMensajePregunta(this.getTrad("preg_RegistroEliminarCorto"), [
            [
                this.getTrad("aceptar"),
                function (e) {
                    _this.datasource_datos_tblSeccionNivel1
                        .store()
                        .remove(seccionNivel1_Sel.idSeccionNivel1)
                        .done(function () {
                            _this.datasource_datos_secciones.reload().done(function (data) {
                                _this.setState({
                                    datasource_datos_secciones: data,
                                    datasource_tblSeccionNivel2: [],
                                });
                            });
                        });
                },
                "danger",
            ],
            [this.getTrad("cancelar"), function () { }],
        ]);
    }

    dxList_seccionNivel1_edit_onClick(e) {
        let { seccionNivel1_Sel } = this.state;
        this.dxForm_addSeccionNivel1.updateData("denominacion", seccionNivel1_Sel.denominacion);
        this.setState({ isVisible_dxPopup_addSeccionNivel1: true });
    }

    dxPopup_nuevaSeccion_onShown(e) {
        this.dxForm_addSeccionNivel2.focus();
    }

    dxPopup_nuevaSeccion_onHiding(e) {
        this.setState({
            isVisible_dxPopup_addSeccionNivel2: false,
            isSeccion2_editing: false,
            seccionNivel2_Sel: null,
        });
    }

    dxPopup_nuevaSeccion_onHidden(e) {
        this.dxForm_addSeccionNivel2.resetValues();
    }

    onClick_cancelar_dxPopup_nuevaSeccion(e) {
        this.setState({
            isVisible_dxPopup_addSeccionNivel2: false,
            isSeccion2_editing: false,
            seccionNivel2_Sel: null,
        });
    }

    render_dxButton_guardar_dxPopup_nuevaSeccion(itemData) {
        return (
            <Button
                text={this.getTrad("guardar")}
                onClick={(e) => {
                    this.onClick_guardar_dxPopup_nuevaSeccion(e, this);
                }}
            />
        );
    }

    render_dxButton_cancelar_dxPopup_nuevaSeccion(itemData) {
        return <Button text={this.getTrad("cancelar")} onClick={this.onClick_cancelar_dxPopup_nuevaSeccion} />;
    }

    onClick_guardar_dxPopup_nuevaSeccion(e, _this) {
        let form = _this.dxForm_addSeccionNivel2;
        let formData = form.option("formData");

        let { seccionNivel1_Sel, seccionNivel2_Sel } = _this.state;
        if (formData && form.validate().isValid) {
            var item = {
                idSeccionNivel1: seccionNivel1_Sel.idSeccionNivel1,
                denominacion: formData.denominacion,
            };

            e.component.option("disabled", true);

            if (!seccionNivel2_Sel) {
                //INSERT
                _this.datasource_datos_tblSeccionNivel2
                    .store()
                    .insert(item)
                    .done(function (sn2) {
                        _this.setState({
                            isVisible_dxPopup_addSeccionNivel2: false,
                        });
                        _this.datasource_datos_secciones.reload().done(function (items) {
                            let itemSel = $.grep(items, function (item) {
                                return item.idSeccionNivel1 == seccionNivel1_Sel.idSeccionNivel1;
                            });
                            _this.setState({
                                seccionNivel1_Sel: itemSel[0],
                                datasource_datos_secciones: items,
                                datasource_tblSeccionNivel2: itemSel[0].tblSeccionNivel2,
                                isSeccion2_editing: false,
                                seccionNivel2_Sel: null,
                            });

                            _this.dxList_seccionNivel2.repaint();
                            e.component.option("disabled", false);
                        });
                    })
                    .fail(function (error) {
                        e.component.option("disabled", false);
                    });
            } //UPDATE
            else {
                _this.datasource_datos_tblSeccionNivel2
                    .store()
                    .update(seccionNivel2_Sel.idSeccionNivel2, item)
                    .done(function (sn2) {
                        _this.setState({
                            isVisible_dxPopup_addSeccionNivel2: false,
                        });
                        _this.datasource_datos_secciones.reload().done(function (items) {
                            let itemSel = $.grep(items, function (item) {
                                return item.idSeccionNivel1 == seccionNivel1_Sel.idSeccionNivel1;
                            });

                            _this.setState({
                                datasource_datos_secciones: items,
                                seccionNivel1_Sel: itemSel[0],
                                datasource_tblSeccionNivel2: itemSel[0].tblSeccionNivel2,
                                isSeccion2_editing: false,
                                seccionNivel2_Sel: null,
                            });
                            _this.dxList_seccionNivel2.repaint();
                            e.component.option("disabled", false);
                        });
                    })
                    .fail(function (error) {
                        e.component.option("disabled", false);
                    });
            }
        }
    }
    //#endregion

    //#region DATAGRID ALMACENES
    editCellRender_activo_tblAlmacenes(e) {
        return (
            <Switch
                switchedOnText={this.getTrad("activo")}
                switchedOffText={this.getTrad("noActivo")}
                defaultValue={e.value}
                onValueChanged={(value) => this.onValueChanged_SwitchActivo_tblAlmacenes(value, e)}
            />
        );
    }

    onRowPrepared_tblAlmacenes(e) {
        if (e.rowType === "data") {
            if (e.data.activo === false) {
                e.rowElement.addClass("disabledRow");
            }
        }
    }

    onEditingStart_tblAlmacenes(e) {
        if (e.column && e.column.name == "editar") {
            let { datasource_datos_tblTipoHabitacion_items } = this.state;
            let almacenSel = $.extend(true, {}, e.data);

            almacenSel.tblPrendaNAlmacen = almacenSel
                ? almacenSel.idTipoHabitacion
                    ? $.grep(datasource_datos_tblTipoHabitacion_items, function (tipoHabitacion) {
                        return tipoHabitacion.idTipoHabitacion === almacenSel.idTipoHabitacion;
                    })[0].tblPrendaNTipoHabitacion
                    : almacenSel.tblPrendaNAlmacen
                : [];

            let prendasSel_compa = [],
                prendasSel_enti = [];

            $.each(almacenSel.tblPrendaNAlmacen, function (index, prenda) {
                if (prenda.tblPrenda.idEntidad) {
                    prendasSel_enti.push(prenda.tblPrenda);
                } else {
                    prendasSel_compa.push(prenda.tblPrenda);
                }
            });

            let _this = this;

            $.when(
                this.datasource_tblPrendaNAlmacen_entidad.reload(),
                this.datasource_tblPrendaNAlmacen_compañia.reload()
            ).then(function (prendasEnti, prendasCompa) {
                let { entidadSel } = _this.state;

                let filtered_data_enti = $.grep(prendasEnti[0], function (item) {
                    return item.idEntidad === (entidadSel.idEntidad ? entidadSel.idEntidad : -1);
                });

                let filtered_data_compa = $.grep(prendasCompa[0], function (item) {
                    return item.idCompañia === entidadSel.idCompañia;
                });

                _this.setState({
                    almacenSel: almacenSel,
                    datasource_datos_tblPrendaNAlmacen_entidad: filtered_data_enti,
                    datasource_datos_tblPrendaNAlmacen_compañia: filtered_data_compa,
                    selectedItems_dxList_tblPrendaNAlmacen_compañia: prendasSel_compa,
                    selectedItems_dxList_tblPrendaNAlmacen_entidad: prendasSel_enti,
                    isVisible_dxPopup_configAlmacenes: true,
                });
            });
        }
    }

    onValueChanged_SwitchActivo_tblAlmacenes(value, e) {
        this.datasource_datos_tblAlmacen_delta
            .store()
            .update(e.data.idAlmacen, { activo: value.value })
            .done(function () {
                e.component.refresh(true);
            });
    }

    onToolbarPreparing_tblAlmacenes(e) {
        let _this = this;
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxButton",
                options: {
                    text: this.getTrad("atras"),
                    icon: "back",
                    onClick: this.tblAlmacenes_back_onClick,
                },
            },
            {
                location: "before",
                widget: "dxButton",
                options: {
                    text: this.getTrad("almacen"),
                    icon: "plus",
                    onClick: this.tblAlmacenes_addAlmacen_onClick,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: this.getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: (x) => {
                        this.dxDataGrid_limpiarFiltros_onClick(x, e);
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    icon: "refresh",
                    text: this.getTrad("actualizar"),
                    onClick: (x) => {
                        this.dxDataGrid_actualizar_onClick(x, e);
                    },
                },
            }
        );
    }

    tblAlmacenes_back_onClick(e) {
        this.setState({
            seccionNivel2_Sel: null,
            dxTabPanel_prendasAlm_selctedIndex: 0,
        });
    }

    tblAlmacenes_addAlmacen_onClick(e) {
        let almacenSel = {
            idAlmacen: null,
            denominacion: "",
            activo: true,
            idTipoAlmacen: null,
            idTipoHabitacion: null,
            tblPrendaNAlmacen: [],
        };

        let _this = this;

        $.when(
            this.datasource_tblPrendaNAlmacen_entidad.reload(),
            this.datasource_tblPrendaNAlmacen_compañia.reload()
        ).then(function (prendasEnti, prendasCompa) {
            let { entidadSel } = _this.state;

            let filtered_data_enti = $.grep(prendasEnti[0], function (item) {
                return item.idEntidad === (entidadSel.idEntidad ? entidadSel.idEntidad : -1);
            });

            let filtered_data_compa = $.grep(prendasCompa[0], function (item) {
                return item.idCompañia === entidadSel.idCompañia;
            });

            _this.setState({
                isVisible_dxPopup_configAlmacenes: true,
                almacenSel: almacenSel,
                selectedItems_dxList_tblPrendaNAlmacen_compañia: [],
                selectedItems_dxList_tblPrendaNAlmacen_entidad: [],
                datasource_datos_tblPrendaNAlmacen_entidad: filtered_data_enti,
                datasource_datos_tblPrendaNAlmacen_compañia: filtered_data_compa,
            });
        });
    }
    //#endregion

    //#region POPUP ALMACENES
    validationCallback_stock(e) {
        let { almacenSel } = this.state;
        let idTipoAlmacen = almacenSel.idTipoAlmacen;
        let isOffice = idTipoAlmacen === 2 || idTipoAlmacen === 3;
        let maxLimite;

        if (parseInt(e.data.tblPrenda.tblElemTransOffice.codigo) === 1 || !isOffice)
            // POR UDS
            maxLimite = 30000;
        else if (parseInt(e.data.tblPrenda.tblElemTransOffice.codigo) === 2)
            // BAC/SACA
            maxLimite = Math.floor(30000 / e.data.tblPrenda.udsXBacOffice);
        else maxLimite = 30000;

        return e.value <= maxLimite ? true : false;
    }

    dxPopup_tblPrendaNAlmacen_onShowing(e) {
        this.dxList_tblPrenda_compañia.option("searchValue", "");
        this.dxList_tblPrenda_entidad.option("searchValue", "");
        setTimeout(() => {
            this.dxList_tblPrenda_compañia.scrollTo(0);
            this.dxList_tblPrenda_entidad.scrollTo(0);
        }, 0);
    }

    dxPopup_tblPrendaNAlmacen_onHiding(e) {
        this.setState({ isVisible_dxPopup_tblPrendaNAlmacen: false });
    }

    dxDataGrid_tblPrendaNAlmacen_idEntidad_groupCellRender(itemData) {
        let isEntidad = itemData.key.length > 0 && itemData.key[0] != null;
        return <div>{isEntidad ? getTrad("prendasEnti") : getTrad("prendasCompañia")}</div>;
    }

    onOptionChanged_dxList_prendasAlmacen_compañia(e) {
        if (e.name == "selectedItems") {
            this.setState({
                selectedItems_dxList_tblPrendaNAlmacen_compañia: e.value,
            });
        }
    }

    onOptionChanged_dxList_prendasAlmacen_entidad(e) {
        if (e.name == "selectedItems") {
            this.setState({
                selectedItems_dxList_tblPrendaNAlmacen_entidad: e.value,
            });
        }
    }

    itemRender_dxList_prendasCodigo(itemData) {
        return <div>{itemData.codigoPrenda + "  -  " + itemData.denominacion}</div>;
    }

    render_dxButton_guardar_dxPopup_prendasAlmacen(itemData) {
        return (
            <Button
                text={this.getTrad("guardar")}
                onClick={(e) => {
                    this.onClick_guardar_dxPopup_prendasAlmacen(e, this);
                }}
            />
        );
    }

    render_dxButton_cancelar_dxPopup_prendasAlmacen(itemData) {
        return <Button text={this.getTrad("cancelar")} onClick={this.onClick_cancelar_dxPopup_prendasAlmacen} />;
    }

    onClick_guardar_dxPopup_prendasAlmacen(e, _this) {
        let { almacenSel } = this.state;

        let selected_prendas_compa = $.extend(true, [], this.state.selectedItems_dxList_tblPrendaNAlmacen_compañia);
        let selected_prendas_enti = $.extend(true, [], this.state.selectedItems_dxList_tblPrendaNAlmacen_entidad);

        //Borrar prendas de tblPrendaNTipoHabitacion que no estén en alguna lista
        $.each(almacenSel.tblPrendaNAlmacen, function (index, prenda) {
            var encontradaEnCompa = false;
            var encontradaEnEnti = false;

            encontradaEnCompa =
                $.grep(selected_prendas_compa, function (prendaCompa) {
                    return prendaCompa.idPrenda === prenda.tblPrenda.idPrenda;
                }).length > 0;

            encontradaEnEnti =
                $.grep(selected_prendas_enti, function (prendaEnti) {
                    return prendaEnti.idPrenda === prenda.tblPrenda.idPrenda;
                }).length > 0;

            if (!encontradaEnCompa && !encontradaEnEnti) {
                almacenSel.tblPrendaNAlmacen = almacenSel.tblPrendaNAlmacen.filter(function (obj) {
                    return obj.tblPrenda.idPrenda !== prenda.tblPrenda.idPrenda;
                });
            }
        });

        //Añadir aquellas que se han seleccionado pero no están en el formData
        $.each(selected_prendas_compa, function (index, prendaCompa) {
            var isDuplicada =
                $.grep(almacenSel.tblPrendaNAlmacen, function (prenda) {
                    return prendaCompa.idPrenda === prenda.tblPrenda.idPrenda;
                }).length > 0;

            if (!isDuplicada) {
                almacenSel.tblPrendaNAlmacen.push({
                    stock: 0,
                    actual: 0,
                    tblPrenda: prendaCompa,
                });
            }
        });

        $.each(selected_prendas_enti, function (index, prendaEnti) {
            var isDuplicada =
                $.grep(almacenSel.tblPrendaNAlmacen, function (prenda) {
                    return prendaEnti.idPrenda === prenda.tblPrenda.idPrenda;
                }).length > 0;

            if (!isDuplicada) {
                almacenSel.tblPrendaNAlmacen.push({
                    stock: 0,
                    actual: 0,
                    tblPrenda: prendaEnti,
                });
            }
        });

        this.setState({ almacenSel: almacenSel, isVisible_dxPopup_tblPrendaNAlmacen: false }, () => {
            _this.dxDataGrid_tblPrendaNAlmacen.refresh();
        });
    }

    onClick_cancelar_dxPopup_prendasAlmacen(e, _this) {
        this.setState({ isVisible_dxPopup_tblPrendaNAlmacen: false });
    }

    dxPopup_configAlmacenes_onShowing(e) {
        let { almacenSel } = this.state;

        $("#dxPopup_almacen_title").text(almacenSel ? almacenSel.denominacion : this.getTrad("nuevoAlmacen"));
    }

    dxPopup_configAlmacenes_onShown(e) {
        this.dxDataGrid_tblPrendaNAlmacen.repaint();
        this.dxForm_almacen.focus();
    }

    dxPopup_configAlmacenes_onHiding(e) {
        this.setState({
            isVisible_dxPopup_configAlmacenes: false,
            almacenSel: null,
        });
    }

    render_dxButton_guardar_dxPopup_configAlmacenes(e) {
        return <Button text={this.getTrad("guardar")} onClick={this.onClick_guardar_dxPopup_configAlmacenes} />;
    }

    onClick_guardar_dxPopup_configAlmacenes(e) {
        var form = this.dxForm_almacen;
        var formData = form.option("formData");
        let _this = this;
        let { entidadSel, seccionNivel2_Sel } = this.state;

        var grid = this.dxDataGrid_tblPrendaNAlmacen;
        grid.saveEditData().done(function () {
            grid.getController("validating")
                .validate(true)
                .done(function (tblPrendaNAlmacen_isValid) {
                    if (formData && form.validate().isValid && tblPrendaNAlmacen_isValid) {
                        var almacenEnvio = $.extend(true, {}, formData);

                        //#region Tratamiento de datos

                        $.each(almacenEnvio.tblPrendaNAlmacen, function (index, prendaNAlmacen) {
                            //Guardamos en unidades
                            if (almacenEnvio.idTipoAlmacen === 2 || almacenEnvio.idTipoAlmacen === 3) {
                                var udsXBacOffice = prendaNAlmacen.tblPrenda.udsXBacOffice;
                                prendaNAlmacen.stock = prendaNAlmacen.stock * udsXBacOffice;
                                prendaNAlmacen.actual = prendaNAlmacen.actual * udsXBacOffice;
                            }

                            prendaNAlmacen.idPrenda = prendaNAlmacen.tblPrenda.idPrenda;
                            delete prendaNAlmacen.tblPrenda;
                        });

                        delete almacenEnvio.tblTipoAlmacen;
                        delete almacenEnvio.tblTipoHabitacion;

                        if (almacenEnvio.idTipoAlmacen === 4) {
                            //Habitación o Lavandería
                            delete almacenEnvio.tblPrendaNAlmacen;
                        }

                        //#endregion
                        e.component.option("disabled", true);
                        if (!formData.idAlmacen) {
                            //POST
                            delete almacenEnvio.idAlmacen;
                            almacenEnvio.idEntidad = entidadSel.idEntidad;
                            almacenEnvio.idSeccionNivel2 = seccionNivel2_Sel.idSeccionNivel2;

                            _this.datasource_datos_tblAlmacen
                                .store()
                                .insert(almacenEnvio)
                                .done(function (almacen) {
                                    e.component.option("disabled", false);

                                    _this.setState({
                                        isVisible_dxPopup_configAlmacenes: false,
                                        almacenSel: null,
                                    });

                                    notify({
                                        message: _this.getTrad("aviso_C_RegistroInsertado"),
                                        type: "success",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                    });

                                    _this.dxDataGrid_tblPrenda.beginCustomLoading();
                                    _this.datasource_tblPrenda.load().done(function (data) {
                                        _this.dxDataGrid_tblPrenda.endCustomLoading();
                                        _this.setState({
                                            datasource_dxDataGrid_tblPrenda: data,
                                        });
                                    });
                                    _this.datasource_datos_tblAlmacen.reload();
                                })
                                .fail(function (error) {
                                    e.component.option("disabled", false);
                                });
                        } else {
                            //PATCH
                            _this.datasource_datos_tblAlmacen
                                .store()
                                .update(almacenEnvio.idAlmacen, almacenEnvio)
                                .done(function () {
                                    e.component.option("disabled", false);

                                    _this.setState({
                                        isVisible_dxPopup_configAlmacenes: false,
                                        almacenSel: null,
                                    });
                                    notify({
                                        message: _this.getTrad("aviso_C_RegistroActualizado"),
                                        type: "success",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                    });

                                    _this.dxDataGrid_tblPrenda.beginCustomLoading();
                                    _this.datasource_tblPrenda.load().done(function (data) {
                                        _this.dxDataGrid_tblPrenda.endCustomLoading();
                                        _this.setState({
                                            datasource_dxDataGrid_tblPrenda: data,
                                        });
                                    });
                                    _this.datasource_datos_tblAlmacen.reload();
                                })
                                .fail(function (error) {
                                    e.component.option("disabled", false);
                                });
                        }
                    } else {
                        form._scrollable.scrollToElement(form.element().find(".dx-invalid"));
                    }
                });
        });
    }

    render_dxButton_cancelar_dxPopup_configAlmacenes(e) {
        return <Button text={this.getTrad("cancelar")} onClick={this.onClick_cancelar_dxPopup_configAlmacenes} />;
    }

    onClick_cancelar_dxPopup_configAlmacenes(e) {
        this.dxForm_almacen.resetValues();
        this.setState({ isVisible_dxPopup_configAlmacenes: false });
    }

    idTipoAlmacen_onValueChanged(e, _this) {
        let { almacenSel } = _this.state;
        if (almacenSel && almacenSel.idTipoAlmacen && e.previousValue != null) {
            almacenSel.idTipoAlmacen = e.value;

            if (
                e.value === 4 ||
                e.previousValue === 4 ||
                e.value === 2 ||
                e.previousValue === 2 ||
                e.value === 3 ||
                e.previousValue === 3
            ) {
                function continuarCambio() {
                    almacenSel.idTipoHabitacion = null;
                    almacenSel.tblPrendaNAlmacen = [];
                    _this.setState({
                        almacenSel,
                        selectedItems_dxList_tblPrendaNAlmacen_compañia: [],
                        selectedItems_dxList_tblPrendaNAlmacen_entidad: [],
                    });
                }

                if (_this.dxForm_almacen.option("formData").tblPrendaNAlmacen.length > 0) {
                    dxMensajePregunta(_this.getTrad("preg_perderPrendasAlmacen"), [
                        [
                            _this.getTrad("continuar"),
                            function (e) {
                                continuarCambio();
                            },
                            "danger",
                        ],
                        [
                            _this.getTrad("cancelar"),
                            function () {
                                e.component.option("value", e.previousValue);
                            },
                        ],
                    ]);
                } else {
                    continuarCambio();
                }
            }
        } else if (almacenSel) {
            almacenSel.idTipoAlmacen = e.value;
            _this.setState({ almacenSel });
            _this.dxDataGrid_tblPrendaNAlmacen.repaint();
        }
    }
    //#endregion

    //#region TABPANEL PRENDAS_ALMACENES
    dxTabPanel_Entidad_onOptionChanged(args) {
        if (args.name === "selectedIndex") {
            this.setState({ selectedIndex_dxForm_entidad: args.value });
        }
    }

    dxTabPanel_prendas_onSelectionChanged(e) {
        if (e.addedItems.length > 0)
            this.setState({
                selectedIndex_dxTabPanel_almacenPrenda: e.addedItems[0].tabIndex,
                dxTabPanel_prendasAlm_selctedIndex: 0,
            });
    }

    dxTabPanel_almacenes_onSelectionChanged(e) {
        var index = e.component.option("selectedIndex");
        if (index === 0) {
            //Edificios y secciones
            this.setState({ datasource_tblSeccionNivel2: [] });
        }
    }

    //#endregion

    //#endregion

    screenByWidth(width) {
        return width >= 1200 ? "xl" : width >= 992 ? "lg" : width >= 768 ? "md" : width >= 576 ? "sm" : "xs";
    }

    screenByWidth_responsiveBox_prendasPedido(width) {
        return width >= 1600 ? "xl" : width >= 992 ? "lg" : width >= 768 ? "md" : width >= 576 ? "sm" : "xs";
    }

    //LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }

    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    componentDidMount() {
        //DEVEXREME JQUERY
        this.cargaDatos_lavanderia();
    }

    componentDidUpdate(prevProps, prevState) {
        let { lavanderia, idioma } = this.props;

        if (lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) {
            this.cargaDatos_lavanderia();
        } else if (idioma.idIdioma !== prevProps.idioma.idIdioma) {
            this.set_label();
        }
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
    resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
