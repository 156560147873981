import { impresionConstants } from "../constants";

export const impresionActions = {
  setReport,
  setParams,
  close,
};

function setReport(report) {
  return (dispatch) => {
    dispatch(success(report));
  };
  function success(report) {
    return { type: impresionConstants.SET_IMPRESION_REPORT_SUCCESS, report };
  }
}

function setParams(params) {
  return (dispatch) => {
    dispatch(success(params));
  };
  function success(params) {
    return { type: impresionConstants.SET_IMPRESION_PARAMS_SUCCESS, params };
  }
}

function close() {
  return (dispatch) => {
    dispatch(success());
  };
  function success() {
    return { type: impresionConstants.SET_IMPRESION_CLOSE_SUCCESS };
  }
}
