import React, { useEffect, useRef } from "react";

import DataSource from "devextreme/data/data_source";
import DxTreeView from "devextreme-react/tree-view";
import ODataStore from "devextreme/data/odata/store";

import { authHeader, errorHandler, getTrad } from "helpers";
import { connectionConstants } from "constants";

const style = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const datasource_tblAlmacenRecambios_postProcess = (data) => {
  return data.map((ar) => ({
    ...ar,
    InverseidAlmacenPadreNavigation: ar.InverseidAlmacenPadreNavigation.sort(
      (a, b) => {
        if (a.activo > b.activo) return -1;
        if (a.activo < b.activo) return 1;
        if (a.denominacion < b.denominacion) return -1;
        if (a.denominacion > b.denominacion) return 1;
        return 0;
      }
    ),
  }));
};

const datasource_tblAlmacenRecambios = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAlmacenRecambios",
    key: "idAlmacen",
    errorHandler,
    beforeSend: (request) => (request.headers = { ...authHeader() }),
    version: 4,
  }),
  select: ["idAlmacen", "denominacion", "activo"],
  expand: [
    "InverseidAlmacenPadreNavigation($select=idAlmacen, denominacion, activo;$expand=idMonedaNavigation($select=codigo))",
    "idMonedaNavigation($select=codigo)",
  ],
  filter: ["idAlmacenPadre", "=", null],
  sort: ["denominacion"],
  postProcess: datasource_tblAlmacenRecambios_postProcess,
});

const TreeView = ({
  setIdAlmacenSel,
  setIdAlmacenPadreSel,
  setCodigoMoneda,
}) => {
  const treeViewRef = useRef(null);

  useEffect(() => {
    return () => {
      treeViewRef?.current?.instance?.collapseAll();
      treeViewRef?.current?.instance?.unselectAll();
    };
  }, []);

  const onItemClick = ({ itemData, node }) => {
    if (node.selected === false) {
      setIdAlmacenSel(itemData.idAlmacen);
      setIdAlmacenPadreSel(
        node.parent === null ? null : node.parent.itemData.idAlmacen
      );
      setCodigoMoneda(itemData.idMonedaNavigation.codigo);
    } else {
      setIdAlmacenSel(null);
      setIdAlmacenPadreSel(null);
      setCodigoMoneda(null);
    }
  };

  const itemRender = ({ activo, denominacion }) => (
    <div className={`p-2 ${!activo ? "disabledRow" : ""}`} style={style}>
      {denominacion}
    </div>
  );

  return (
    <>
      <div className="font-size pl-1">{getTrad("almacenesPrincipales")}</div>
      <DxTreeView
        ref={treeViewRef}
        dataSource={datasource_tblAlmacenRecambios}
        keyExpr={"idAlmacen"}
        itemsExpr={"InverseidAlmacenPadreNavigation"}
        displayExpr={"denominacion"}
        height={"100%"}
        width={"100%"}
        selectionMode={"single"}
        activeStateEnabled={false}
        focusStateEnabled={false}
        selectByClick
        onItemClick={onItemClick}
        itemRender={itemRender}
      />
    </>
  );
};

export default TreeView;
