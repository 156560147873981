import React from "react";
import { connect } from "react-redux";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Pager,
  Paging,
  Lookup as DataGrid_Lookup,
  Scrolling,
  Editing,
} from "devextreme-react/data-grid";

import Box, { Item as ItemBox } from "devextreme-react/box";

import { Popup, ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";

import LottieIcon from "../../../../components/LottieIcon";
import "react-phone-input-2/lib/style.css";

//Css
import "../Css.scss";
import { authHeader, dxMensajePregunta, errorHandler, getTrad } from "helpers";
import { Button, Tooltip } from "devextreme-react";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
import { connectionConstants } from "constants";

class PopupAltaGestoria extends React.Component {
  constructor(props) {
    super(props);

    
    this.dxDataGrid_personas_enviarAltasGestoria_REF = React.createRef();
  }

  dataSource_beforeSend = (request) => {
    request.headers = { ...authHeader() };
  };
  ds_errorHandler(error) {
    errorHandler(error, null);
  }

  dataSource_tblSolicitudGestoria = new DataSource({
    store: new ODataStore({
      key: "idSolicitudGestoria",
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblSolicitudGestoria",
      errorHandler: this.ds_errorHandler,
      beforeSend: (request) => this.dataSource_beforeSend(request),
      version: 4,
    }),
    paginate: false,
  });

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  get dxDataGrid_personas_enviarAltasGestoria() {
    return this.dxDataGrid_personas_enviarAltasGestoria_REF.current.instance;
  }

  onClick_enviarMailAltasGestoria = (e) => {
    const {
      datasource_tblCorreoAltaGestoriaNCentroLav,
      personasSel_enviarAltasGestoria,
      onValueChange,
      context_persona,
      datasource_tblCentroTrabajo_Lavanderia_todos,
      loadPanel_hide,
    } = this.props;

    // Cargar datos de correo electrónico y trabajar con los resultados
    datasource_tblCorreoAltaGestoriaNCentroLav.load().done((result) => {
      let idsLavanderia_persona = [],
        idsCentroTrabajo_persona = [];

      // Recopilar identificadores de lavanderías y centros de trabajo de las personas seleccionadas
      personasSel_enviarAltasGestoria.forEach((item) => {
        if (item.idCentroTrabajo != null) {
          idsCentroTrabajo_persona.push(item.idCentroTrabajo);
        } else if (item.idLavanderia != null) {
          idsLavanderia_persona.push(item.idLavanderia);
        }
      });

      let idsLavanderia_encontrados = [],
        idsCentroTrabajo_encontrados = [];

      // Verificar los identificadores obtenidos contra los datos cargados
      result.forEach((item) => {
        if (item.idCentroTrabajo != null) {
          idsCentroTrabajo_encontrados.push(item.idCentroTrabajo);
        } else if (item.idLavanderia != null) {
          idsLavanderia_encontrados.push(item.idLavanderia);
        }
      });

      // Eliminación de duplicados para limpiar los identificadores recogidos
      idsLavanderia_persona = [...new Set(idsLavanderia_persona)];
      idsLavanderia_encontrados = [...new Set(idsLavanderia_encontrados)];
      idsCentroTrabajo_persona = [...new Set(idsCentroTrabajo_persona)];
      idsCentroTrabajo_encontrados = [...new Set(idsCentroTrabajo_encontrados)];

      // Comparar las listas de identificadores para permitir o no el envío de correos
      let coincidenLavs = idsLavanderia_persona.sort().join(",") === idsLavanderia_encontrados.sort().join(",");
      let coincidenCentros =
        idsCentroTrabajo_persona.sort().join(",") === idsCentroTrabajo_encontrados.sort().join(",");

      // Enviar correos si hay coincidencias y hay resultados
      if (result.length > 0 && coincidenLavs && coincidenCentros) {
        if (personasSel_enviarAltasGestoria.length > 0) {
          this.dxDataGrid_personas_enviarAltasGestoria.clearFilter();
          onValueChange({
            isVisible_dxPopup_enviarAltasGestoria_loading: true,
          });
          context_persona.invoke("SendMail_DatosPersonales", {}, "POST").done((result) => {
            let mensaje = this.getTrad("correoComunicacionAltaEnviadoDirecciones");
            mensaje += "<div class='listStringContainer'>";
            result.forEach((item, index) => {
              if (index > 0) mensaje += "<br />";
              mensaje += `- ${item}`;
            });
            mensaje += "</div>";

            dxMensajePregunta(mensaje, [[getTrad("aceptar"), () => {}, "danger"]]);

            onValueChange({
              personasSel_enviarAltasGestoria: [],
              isVisible_dxPopup_enviarAltasGestoria_loading: false,
              isVisible_dxPopup_enviarAltasGestoria: false,
              idAsuntoMailAltasGestorias: 1,
            });
          });

          const solicitudAlta = personasSel_enviarAltasGestoria.map((item) => {
            return {
              idPersona: item.idPersona,
              isUrgente: item.isUrgente,
            };
          });

          this.dataSource_tblSolicitudGestoria.store().insert(solicitudAlta);
        } else {
          notify({
            message: "Debe seleccionar por lo menos a una persona.",
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
        }
      } else if (personasSel_enviarAltasGestoria.length == 0) {
        notify({
          message: "Debe seleccionar por lo menos a una persona.",
          type: "error",
          displayTime: "1500",
          closeOnClick: true,
        });
      } else {
        let listaLavsCentros_faltantes = [];
        if (!coincidenLavs) {
          let lavanderiasFaltantes = idsLavanderia_persona.filter((n) => !idsLavanderia_encontrados.includes(n));

          // Identificar las lavanderías faltantes en la carga de correo
          lavanderiasFaltantes.forEach((item) => {
            let idCentroLav = `[-1,${item}]`;
            let itemCentroLav = datasource_tblCentroTrabajo_Lavanderia_todos.filter(
              (item) => item.idCentroLav == idCentroLav
            );

            if (itemCentroLav.length > 0) listaLavsCentros_faltantes.push(itemCentroLav[0].denominacion);
          });
        }

        if (!coincidenCentros) {
          let centrosFaltantes = idsCentroTrabajo_persona.filter((n) => !idsCentroTrabajo_encontrados.includes(n));
          centrosFaltantes.forEach((item) => {
            let idCentroLav = `[${item},-1]`;
            let itemCentroLav = datasource_tblCentroTrabajo_Lavanderia_todos.filter(
              (item) => item.idCentroLav == idCentroLav
            );

            if (itemCentroLav.length > 0) listaLavsCentros_faltantes.push(itemCentroLav[0].denominacion);
          });
        }

        // Ordenar y mostrar los centros y lavanderías faltantes
        listaLavsCentros_faltantes.sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

        let mensaje =
          "No se han podido enviar los correos debido a que las siguientes lavanderías/centros de trabajo no tiene correos parametrizados:";
        mensaje += "<div class='listStringContainer'>";
        listaLavsCentros_faltantes.forEach((item, index) => {
          if (index > 0) mensaje += "<br />";
          mensaje += `- ${item}`;
        });
        mensaje += "</div>";

        dxMensajePregunta(mensaje, [[getTrad("aceptar"), () => {}, "danger"]]);

        loadPanel_hide();
      }
    });
  };

  onClick_cancelarAltasGestoria = () => {
    const { onValueChange } = this.props;
    onValueChange({
      isVisible_dxPopup_enviarAltasGestoria: false,
      personasSel_enviarAltasGestoria: [],
    });

    this.dxDataGrid_personas_enviarAltasGestoria.clearFilter();
  };

  dxPopup_enviarAltasGestoria_onHiding = () => {
    const { onValueChange } = this.props;
    onValueChange({
      isVisible_dxPopup_enviarAltasGestoria: false,
      personasSel_enviarAltasGestoria: [],
    });
    this.dxDataGrid_personas_enviarAltasGestoria.clearFilter();
  };

  onRowClick_dxDataGrid_tblPersona_EnviarAltasGestoria = (e) => {
    const { personasSel_enviarAltasGestoria, onValueChange } = this.props;
    let _personasSel_enviarAltasGestoria = [...personasSel_enviarAltasGestoria];
    const data = e.data;
    const { tblSolicitudGestoria } = data || {};

    if (tblSolicitudGestoria && tblSolicitudGestoria.length > 0) {
      return;
    }

    _personasSel_enviarAltasGestoria.push({
      idPersona: data.idPersona,
      nombre: data.nombre,
      apellidos: data.apellidos,
      idLavanderia: data.idLavanderia,
      idCentroTrabajo: data.idCentroTrabajo,
      isUrgente: false,
    });

    this.dxDataGrid_personas_enviarAltasGestoria.refresh(true);

    onValueChange({
      personasSel_enviarAltasGestoria: _personasSel_enviarAltasGestoria,
    });
  };

  renderDataGridPersSel = ({ data }) => {
    const { nombre, apellidos } = data;
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-1">{nombre + " " + apellidos}</div>
      </div>
    );
  };

  renderRemoveCellComponent = ({ data }) => {
    const idPersonaClicked = data.data.idPersona;

    const onClick = () => {
      const { personasSel_enviarAltasGestoria, onValueChange } = this.props;
      let _personasSel_enviarAltasGestoria = [...personasSel_enviarAltasGestoria];

      _personasSel_enviarAltasGestoria = _personasSel_enviarAltasGestoria.filter(
        (item) => item.idPersona !== idPersonaClicked
      );

      this.dxDataGrid_personas_enviarAltasGestoria.refresh(true);

      onValueChange({
        personasSel_enviarAltasGestoria: _personasSel_enviarAltasGestoria,
      });
    };

    return (
      <div className="container_spanCentrado pointer" onMouseDown={onClick}>
        <div className="icon_Cerrar font-size-lg" />
      </div>
    );
  };

  render() {
    const {
      isVisible_dxPopup_enviarAltasGestoria,
      isVisible_dxPopup_enviarAltasGestoria_loading,
      personasSel_enviarAltasGestoria,
      datasource_tblCentroTrabajo_Lavanderia_todos,
      datasource_persona_enviarAltasGestoria,
      dxList_persona_listaGeneral_onContentReady,
    } = this.props;

    return (
      <Popup
        title={this.getTrad("enviarAltasGestoria")}
        visible={isVisible_dxPopup_enviarAltasGestoria}
        deferRendering={true}
        showTitle={true}
        width="90%"
        height="80%"
        position={this.dxPopup_center_position}
        onHiding={this.dxPopup_enviarAltasGestoria_onHiding}
        onToolbarPreparing={this.onToolbarPreparing_dxDataGrid_tblPersona_EnviarAltasGestoria}
      >
        {isVisible_dxPopup_enviarAltasGestoria_loading ? (
          <div className="he-100 d-flex align-items-center">
            <LottieIcon icon="upload" isLoop={true} height={"40%"} width={"40%"} />
          </div>
        ) : (
          <div className="he-100">
            <Box direction="row" width="100%" height="100%">
              <ItemBox ratio={3}>
                <DataGrid
                  ref={this.dxDataGrid_personas_enviarAltasGestoria_REF}
                  dataSource={isVisible_dxPopup_enviarAltasGestoria ? datasource_persona_enviarAltasGestoria : []}
                  //Propiedades
                  columnsAutoWidth={true}
                  height="100%"
                  width="100%"
                  //Estilos
                  repaintChangesOnly={true}
                  showColumnLines={false}
                  showRowLines={true}
                  rowAlternationEnabled={true}
                  hoverStateEnabled={true}
                  //Eventos
                  onToolbarPreparing={this.onToolbarPreparing_dxDataGrid_tblPersona_EnviarAltasGestoria}
                  onRowClick={this.onRowClick_dxDataGrid_tblPersona_EnviarAltasGestoria}
                  className="shadow-sm border-radius"
                >
                  <SearchPanel
                    visible={true}
                    width={300}
                    placeholder={this.getTrad("busqueda")}
                    searchVisibleColumnsOnly={true}
                  />
                  <HeaderFilter visible={true} />
                  <FilterRow visible={true} showAllText={this.getTrad("todos").toUpperCase()} applyFilter="auto" />
                  <Scrolling mode="infinite" />
                  <Paging defaultPageSize={100} />
                  <Pager visible={false} />
                  <Column
                    allowFiltering={false}
                    allowEditing={false}
                    allowSorting={false}
                    width={50}
                    caption={""}
                    dataField="tblSolicitudGestoria"
                    cellComponent={this.cellComponent_solicitudGestoria}
                  />
                  <Column
                    dataField="nombre"
                    caption={this.getTrad("nombre")}
                    sortOrder="asc"
                    sortIndex={1}
                    allowHeaderFiltering={false}
                    editorOptions={this.showClearButton_editorOptions}
                  />
                  <Column
                    dataField="apellidos"
                    caption={this.getTrad("apellidos")}
                    sortOrder="asc"
                    sortIndex={1}
                    allowHeaderFiltering={false}
                    editorOptions={this.showClearButton_editorOptions}
                  />
                  <Column
                    dataField="idCentroLav"
                    caption={this.getTrad("centroLavanderia")}
                    width={"30%"}
                    allowSearch={false}
                    visible={true}
                  >
                    <DataGrid_Lookup
                      dataSource={datasource_tblCentroTrabajo_Lavanderia_todos}
                      valueExpr="idCentroLav"
                      displayExpr="denominacion"
                    />
                  </Column>
                  <Column
                    dataField="fechaAltaContrato"
                    caption={this.getTrad("fechaAlta")}
                    dataType="date"
                    format={"dd/MM/yyyy"}
                    width={120}
                    editorType="dxDateBox"
                    editorOptions={this.dxDateBox_editorOptions}
                    allowSearch={false}
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="tblLlamamiento[0]"
                    caption={this.getTrad("llamamiento")}
                    dataType={"boolean"}
                    width={130}
                    allowSearch={false}
                    allowSorting={false}
                    cellComponent={({ data }) => {
                      return data.value?.activo ? (
                        <code className="chip success">#{data.value.codigoLlamamiento}</code>
                      ) : null;
                    }}
                  />
                  <Column dataField="idPersona" allowSearch={false} visible={false} />
                </DataGrid>
              </ItemBox>
              <ItemBox ratio={1.3}>
                <div className="dx-list-group-header adjuntarArchivos_personal_header border ml-3">
                  <div>{this.getTrad("personas").toUpperCase()}</div>
                </div>
                <DataGrid
                  height={"92.5%"}
                  className="ml-3 mt-2 shadow-sm border-radius"
                  dataSource={personasSel_enviarAltasGestoria}
                  onContentReady={dxList_persona_listaGeneral_onContentReady}
                  hoverStateEnabled={true}
                >
                  <Editing mode={"cell"} allowUpdating />
                  <SearchPanel visible={true} placeholder={this.getTrad("busqueda")} searchVisibleColumnsOnly={false} />
                  <Column caption={this.getTrad("nombre")} cellRender={this.renderDataGridPersSel} />
                  <Column dataField={"nombre"} visible={false} allowSearch={true} />
                  <Column dataField={"apellidos"} visible={false} allowSearch={true} />
                  <Column width={70} caption={this.getTrad("urgente")} dataField={"isUrgente"} alignment={"center"} />
                  <Column
                    caption={""}
                    width={40}
                    alignment={"center"}
                    cssClass={"p-0"}
                    cellComponent={this.renderRemoveCellComponent}
                  />
                </DataGrid>
              </ItemBox>
            </Box>
          </div>
        )}

        <ToolbarItem_popup
          toolbar="bottom"
          location="after"
          widget="dxButton"
          visible={!isVisible_dxPopup_enviarAltasGestoria_loading}
        >
          <Button text={this.getTrad("enviarMail")} type="success" onClick={this.onClick_enviarMailAltasGestoria} />
        </ToolbarItem_popup>
        <ToolbarItem_popup
          toolbar="bottom"
          location="after"
          widget="dxButton"
          visible={!isVisible_dxPopup_enviarAltasGestoria_loading}
        >
          <Button text={this.getTrad("cancelar")} type="normal" onClick={this.onClick_cancelarAltasGestoria} />
        </ToolbarItem_popup>
      </Popup>
    );
  }

  onToolbarPreparing_dxDataGrid_tblPersona_EnviarAltasGestoria = (e) => {
    let _this = this;
    let { idAsuntoMailAltasGestorias , onValueChange} = this.props;
    const {datasource_persona_enviarAltasGestoria} = this.props;
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          text: _this.getTrad("actualizar"),
          icon: "refresh",
          onClick: (args) => {
            datasource_persona_enviarAltasGestoria.reload();
          },
        },
      },
      {
        location: "after",
        widget: "dxSelectBox",
        showText: "inMenu",
        options: {
          width: 245,
          value: idAsuntoMailAltasGestorias,
          dataSource: _this.enum_asuntoMailAltasGestorias,
          displayExpr: "denominacion",
          valueExpr: "value",
          onValueChanged: function (e) {
            onValueChange({ idAsuntoMailAltasGestorias: e.value });
          },
        },
      }
    );
  };

  cellComponent_solicitudGestoria = (cell) => {
    const { tblSolicitudGestoria } = cell.data.data || {};
    const iconId = "icon_Comment_" + cell.data.data.idPersona;

    return (
      <>
        <div id={iconId} className="container_spanCentrado">
          {tblSolicitudGestoria && tblSolicitudGestoria.length > 0 && (
            <div className="icon_Comment primary font-size-xl" />
          )}
        </div>
        <Tooltip
          animation={{
            hide: {
              type: "pop",
              from: {
                scale: 1,
                opacity: 1,
              },
              to: {
                opacity: 0,
                scale: 0.1,
              },
            },
            show: {
              type: "pop",
              from: {
                scale: 0.1,
                opacity: 0,
              },
              to: {
                opacity: 1,
                scale: 1,
              },
            },
          }}
          target={"#" + iconId}
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>Este trabajador tiene una solicitud de gestoría pendiente.</div>
        </Tooltip>
      </>
    );
  };

  dxList_persona_listaGeneral_searchExpr = ["nombre", "apellidos"];
  dxDateBox_editorOptions = {
    displayFormat: "dd/MM/yyyy",
    min: "01/01/1900",
  };
  dxPopup_center_position = { at: "center", of: window };
  showClearButton_editorOptions = { showClearButton: true };
  enum_asuntoMailAltasGestorias = [
    {
      value: 1,
      denominacion: "Nuevas altas",
    },
    {
      value: 2,
      denominacion: "Llamamiento",
    },
  ];
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PopupAltaGestoria);
