import React, { forwardRef } from "react";

import { Column } from "devextreme-react/data-grid";

import IdAlmacenDestinoColumn from "pages/AssistantNew/Movimientos/shared/components/DataGridColumns/IdAlmacenDestinoColumn";
import SharedDataGridMovimientos from "pages/AssistantNew/Movimientos/shared/components/SharedDataGridMovimientos";
import { getTrad } from "helpers";

const DataGridMovimientos = forwardRef(({ ...props }, ref) => {
  return (
    <SharedDataGridMovimientos
      ref={ref}
      newMovimientoTrad={"nuevaEntrada"}
      {...props}
    >
      <Column {...IdAlmacenDestinoColumn(getTrad)} />
      <Column
        dataField={"idProveedorNavigation.nombreComercial"}
        caption={getTrad("proveedor")}
        minWidth={250}
        alignment={"left"}
        allowResizing
        visible={false}
      />
      <Column
        dataField={"numPedidoAsociado"}
        caption={getTrad("pedidoAsociado")}
        minWidth={130}
        alignment={"left"}
        allowResizing
        allowHeaderFiltering={false}
        visible={false}
      />
      <Column
        dataField={"codigoAlbaranProveedor"}
        caption={getTrad("codigoProveedor")}
        minWidth={140}
        alignment={"left"}
        allowResizing
        allowHeaderFiltering={false}
        visible={false}
      />
    </SharedDataGridMovimientos>
  );
});

export default DataGridMovimientos;
