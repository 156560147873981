import { Box, Button, DataGrid, Popup, SelectBox } from "devextreme-react";
import { Component, createRef } from "react";
import { Item as ItemBox } from "devextreme-react/box";
import {
  Column,
  Editing,
  RequiredRule,
  Scrolling,
} from "devextreme-react/data-grid";
import { connectionConstants } from "../../../../constants";
import ODataContext from "devextreme/data/odata/context";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
import PosicionCell from "./SwitchGrid";
import { authHeader, errorHandler } from "../../../../helpers";

class PopupMaquinas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      maquinaSeleccionada: undefined,
      sourceMaquinas: [],
      sourcePosiciones: [],
    };

    this.grid_REF = createRef(null);
  }

  get grid() {
    return this.grid_REF.current?.instance;
  }

  componentDidMount() {
    this.props.datasource_tblMaquina.load().then((data) => {
      this.tblMaquina = data;
    });
  }

  toggle = () => {
    this.setState({ visible: !this.state.visible, sourcePosiciones: [] });
  };

  onHiding = () => {
    this.setState({ visible: false, maquinaSeleccionada: undefined });
  };

  selectMaquina = (idMaquina) => {
    const dataSource_tblPosicionNAreaLavanderiaNLavanderia = new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.WEB_API_CORE_ODATA_URL +
          "tblPosicionNAreaLavanderiaNLavanderia",
        key: "idPosicionNAreaLavanderiaNLavanderia",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
        },
        version: 4,
      }),
      select: ["*"],
      filter: [
        ["idLavanderia", "=", this.props.idLavanderia],
        "and",
        ["idAreaLavanderia", "=", this.props.areaSeleccionada.idAreaLavanderia],
        "and",
        ["idMaquina", "=", idMaquina],
      ],
    });

    dataSource_tblPosicionNAreaLavanderiaNLavanderia.load().then((data) => {
      this.setState({
        maquinaSeleccionada: idMaquina,
        sourcePosiciones: data,
      });
    });
  };

  guardarCambios = () => {
    if (this.grid.hasEditData()) {
      const idLavanderia = this.props.idLavanderia;
      const idAreaLavanderia = this.props.areaSeleccionada.idAreaLavanderia;
      const maquinaSeleccionada = this.state.maquinaSeleccionada;

      this.grid.saveEditData();
      const data = this.state.sourcePosiciones;
      data.map((x) => {
        x.idLavanderia = idLavanderia;
        x.idAreaLavanderia = idAreaLavanderia;
        x.idMaquina = maquinaSeleccionada;
      });

      const context_tblPosicionNAreaLavanderiaNLavanderia = new ODataContext({
        url:
          connectionConstants.WEB_API_CORE_ODATA_URL +
          "MyPolarier/ParametrosGenerales/EstructuraOperativa",
        entities: {
          IUD_tblPosicionNAreaLavanderiaNLavanderia: {},
        },
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          request.params = {
            idAreaLavanderia: idAreaLavanderia,
            idLavanderia: idLavanderia,
            idMaquina: maquinaSeleccionada,
          };
          request.payload = { lista: data };
        },
      });

      // TODO: ANALIZAR Y ENSEÑAR ERRORES
      context_tblPosicionNAreaLavanderiaNLavanderia
        .invoke("IUD_tblPosicionNAreaLavanderiaNLavanderia", {}, "POST")
        .done((data) => {
          this.props.dataSource_tblPosicionNAreaLavanderiaNLavanderia.reload();
        });
    }
    this.onHiding();
  };

  render() {
    return (
      <Popup
        visible={this.state.visible}
        onHiding={() => this.onHiding()}
        dragEnabled={false}
        width={700}
        height={550}
        title="Gestionar posiciones"
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Box style={{ marginBottom: 20 }}>
            <ItemBox ratio={3}>
              <SelectBox
                style={{ marginRight: 10 }}
                searchEnabled={true}
                searchExpr={"denominacion"}
                searchMode="contains"
                displayExpr={"denominacion"}
                dataSource={this.props.datasource_tblMaquina}
                valueExpr={"idMaquina"}
                value={this.state.maquinaSeleccionada}
                onItemClick={(e) => this.selectMaquina(e.itemData.idMaquina)}
              />
            </ItemBox>
            <ItemBox ratio={1}>
              <Button
                disabled={!this.state.maquinaSeleccionada}
                type="success"
                text="Añadir posición"
                onClick={() => this.grid.addRow()}
              />
            </ItemBox>
          </Box>
          <DataGrid
            ref={this.grid_REF}
            height={300}
            style={{ width: "95%", margin: "auto" }}
            dataSource={this.state.sourcePosiciones}
            onToolbarPreparing={(e) => (e.toolbarOptions.visible = false)}
            onInitNewRow={(e) => (e.data.activo = true)}
          >
            <Scrolling mode="virtual" />
            <Editing
              mode="batch"
              allowUpdating={true}
              allowAdding={false}
              allowDeleting={true}
              useIcons={true}
            />
            <Column
              dataField={"denominacion"}
              dataType={"string"}
              width={"40%"}
            >
              <RequiredRule />
            </Column>
            <Column
              dataField={"activo"}
              dataType={"boolean"}
              editCellComponent={PosicionCell}
            />
            <Column
              dataField={"numPos"}
              dataType={"number"}
              alignment={"center"}
              allowEditing={false}
            />
          </DataGrid>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: 20,
            }}
          >
            <Button
              type="success"
              text="Guardar cambios"
              style={{ margin: 5 }}
              onClick={() => this.guardarCambios()}
            />
            <Button
              type="danger"
              text="Cancelar"
              style={{ margin: 5 }}
              onClick={() => this.onHiding()}
            />
          </div>
        </div>
      </Popup>
    );
  }
}

export default PopupMaquinas;
