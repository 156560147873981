import { Tooltip } from "devextreme-react";
import { getTrad } from "helpers";
import { DataSourceSAPContext } from "./CurrencyCell";
import { useContext } from "react";

function HeaderCell(e) {
    const SAP = useContext(DataSourceSAPContext);

    const { component, column } = e.data;
    let datasource = component.option("dataSource");
    const conflictosDivisa = datasource.map((x) => x[column.dataField]).some((x) => Number.isNaN(x));

    const conflictosSAP =
        column.dataField === "idAdmCuentaContable" &&
        SAP.dataSource.length > 0 &&
        !(
            SAP.dataSource
                .map((x) => x.idAdmCuentaContable)
                .every((x) => datasource.map((x) => x.idAdmCuentaContable).includes(x)) &&
            datasource
                .map((x) => x.idAdmCuentaContable)
                .every((x) => SAP.dataSource.map((x) => x.idAdmCuentaContable).includes(x))
        );

    const hasAny = conflictosDivisa || conflictosSAP;
    let className = hasAny ? "text-danger" : "";
    if (conflictosSAP) className += " ml-4";

    return (
        <>
            {hasAny && (
                <i
                    id={`${column.dataField}Header`}
                    className="icon_Warning position-absolute-0 d-flex align-items-center font-size-xl"
                />
            )}
            {hasAny && (
                <Tooltip
                    target={`#${column.dataField}Header`}
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    position={"top"}
                >
                    <div>
                        {conflictosDivisa &&
                            getTrad("errorCambioDivisas")
                                .split("<br />")
                                .map((x) => (
                                    <span className="text-danger font-weight-bold d-flex flex-column">{x}</span>
                                ))}
                        {conflictosSAP && (
                            <span className="text-danger font-weight-bold d-flex flex-column">
                                {getTrad("errorCantidadRegistrosSAP")}
                            </span>
                        )}
                    </div>
                </Tooltip>
            )}
            <span className={className}>{column.caption}</span>
        </>
    );
}

export default HeaderCell;
