import React from "react";

import Box, { Item as ItemBox } from "devextreme-react/box";
import { Button } from "devextreme-react";
import LottieIcon from "components/LottieIcon";

import { getTrad } from "helpers";

import "../css.scss";

class AvisoEncuesta extends React.Component {
  responderEncuesta = () => {
    const { onValueChange } = this.props;
    onValueChange({ showEncuesta: true });
  };

  posponerEncuesta = () => {
    const { onValueChange } = this.props;
    onValueChange({ showPopup: false });
  };

  render() {
    const { isObligatorio } = this.props;

    return (
      <Box width="100%" direction="col">
        <ItemBox ratio={1}>
          <div className="d-flex flex-1 flex-column justify-content-center m-5">
            <div className="text-center font-size-xl m-3">
              {getTrad("agradecimientoEncuesta")}
            </div>
            <LottieIcon
              icon={"surveyResult"}
              isLoop={false}
              height={"90%"}
              width={"90%"}
            />
            <div className="text-center font-size-xl m-3">
              {getTrad("tuOpinionImporta")}
            </div>
          </div>
          <div className="d-flex justify-content-end m-4">
            <Button
              onClick={this.responderEncuesta}
              text={getTrad("responderEncuesta")}
              type="success"
              className="m-2 p-1"
            />
            {isObligatorio ? (
              <></>
            ) : (
              <Button
                onClick={this.posponerEncuesta}
                text={getTrad("posponer")}
                type="normal"
                className="m-2 p-1"
              />
            )}
          </div>
        </ItemBox>
      </Box>
    );
  }
}

export default AvisoEncuesta;
