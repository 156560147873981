import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import notify from "devextreme/ui/notify";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  startOfYear,
  endOfYear,
  formatDate_parameter,
} from "../../../helpers";

import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import Box, { Item as ItemBox } from "devextreme-react/box";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import { Lookup } from "devextreme-react/lookup";
import { LoadPanel } from "devextreme-react/load-panel";
import query from "devextreme/data/query";
//CUSTOM COMPONENTS
import "react-phone-input-2/lib/style.css";
import YearCalendar from "../../../components/YearCalendar/YearCalendar";

//Css
import "./Css.scss";

const calendarContainer = { of: ".calendar" };
class FestivosCierres extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idCentroLavSel: null,
      yearCalendar_currentYear: new Date().getFullYear(),
      calendarData: [],
      yearCalendar_newData: [],
      isFirstLoad: true,
      diasFijados: [],
    };

    // REFERENCIAS
    this.dxLoadPanel_calendario_REF = React.createRef();

    //DATASOURCE
    this.datasource_tblCalendarioLavanderia_beforeSend =
      this.datasource_tblCalendarioLavanderia_beforeSend.bind(this);
    this.context_tblCalendarioLavanderia_beforeSend =
      this.context_tblCalendarioLavanderia_beforeSend.bind(this);
    this.context_tblCalendarioCentroTrabajo_beforeSend =
      this.context_tblCalendarioCentroTrabajo_beforeSend.bind(this);
    this.datasource_tblCalendarioCentroTrabajo_beforeSend =
      this.datasource_tblCalendarioCentroTrabajo_beforeSend.bind(this);
    this.datasource_tblCalendarioCentroTrabajo_map =
      this.datasource_tblCalendarioCentroTrabajo_map.bind(this);
    this.datasource_diasFijados_beforeSend =
      this.datasource_diasFijados_beforeSend.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);

    //EVENTOS
    this.dxLookup_idCentroLav_onValueChanged =
      this.dxLookup_idCentroLav_onValueChanged.bind(this);
    this.yearCalendar_onDateChanged =
      this.yearCalendar_onDateChanged.bind(this);
    this.yearCalendar_onYearChanged =
      this.yearCalendar_onYearChanged.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  //REFERENCIAS
  get dxLoadPanel_calendario() {
    return this.dxLoadPanel_calendario_REF.current.instance;
  }

  //#region DATA SOURCE
  datasource_tblCentroTrabajo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCentroTrabajo",
      key: "idCentroTrabajo",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCentroTrabajo_beforeSend(request);
      },
      version: 4,
    }),
    sort: ["denominacion"],
  });

  datasource_tblCentroTrabajo_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = false;
  }

  datasource_tblLavanderiaNUsuario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblLavanderiaNUsuario_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idLavanderia", "denominacion"],
    sort: ["denominacion"],
  });

  datasource_tblLavanderiaNUsuario_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = false;
  }

  datasource_datos_tblCalendarioPersonal_Estado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCalendarioPersonal_Estado",
      key: "idEstado",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_datos_tblCalendarioPersonal_Estado_beforeSend(request);
      },
      version: 4,
    }),
    sort: ["denominacion"],
    filter: ["idEstado eq 8 or idEstado eq 10"],
    postProcess: (data) => {
      return this.datasource_datos_tblCalendarioPersonal_Estado_postProcess(
        data,
      );
    },
  });

  datasource_datos_tblCalendarioPersonal_Estado_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_datos_tblCalendarioPersonal_Estado_postProcess(data) {
    if (
      this.datasource_datos_tblCalendarioPersonal_Estado.items().length === 0
    ) {
      data.splice(0, 0, {
        idEstado: 0,
        denominacion: "Sin estado",
        traduccion: "sinEstado",
        colorHexa: "#FFFFFF",
        isLavanderia: false,
      });
      data.splice(0, 0, {
        idEstado: -1,
        denominacion: "Día fijado",
        traduccion: "sinEstado",
        colorHexa: "#f7f7f7",
        isLavanderia: false,
      });
    }
    return data;
  }

  context_tblCalendarioLavanderia = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioLavanderia",
    entities: {
      fn_IU_tblCalendarioLavanderia: {},
    },
    beforeSend: (request) => {
      this.context_tblCalendarioLavanderia_beforeSend(request);
    },
  });

  context_tblCalendarioLavanderia_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.timeout = 300000;

    let { idCentroLavSel } = this.state;

    let parsed = JSON.parse(idCentroLavSel);
    let idLavanderia = !parsed ? null : parsed[1] > 0 ? parsed[1] : null;

    let { yearCalendar_newData } = this.state;
    request.params.idLavanderia = idLavanderia;

    yearCalendar_newData = $.map(yearCalendar_newData, function (item) {
      return {
        idLavanderia: idLavanderia,
        idEstado: item.idEstado,
        fecha: item.fecha,
      };
    });
    yearCalendar_newData = yearCalendar_newData.sort(function (a, b) {
      if (a.idEstado == b.idEstado) {
        return new Date(a.fecha) - new Date(b.fecha);
      } else {
        return a.idEstado < b.idEstado ? 1 : -1;
      }
    });

    let rangos = [];
    for (var i = 0; i < yearCalendar_newData.length; i++) {
      let date = yearCalendar_newData[i];
      let date_ = new Date(date.fecha);
      let diaAnterior = new Date(
        new Date(date_).setDate(new Date(date_).getDate() - 1),
      );
      let diaSiguiente = new Date(
        new Date(date_).setDate(new Date(date_).getDate() + 1),
      );

      let obj_diaActual = $.grep(yearCalendar_newData, function (item) {
        return item.fecha.toDateString() == date_.toDateString();
      });
      let obj_diaAnterior = $.grep(yearCalendar_newData, function (item) {
        return item.fecha.toDateString() == diaAnterior.toDateString();
      });
      let obj_diaSiguiente = $.grep(yearCalendar_newData, function (item) {
        return item.fecha.toDateString() == diaSiguiente.toDateString();
      });

      if (obj_diaAnterior.length > 0) {
        if (obj_diaAnterior[0].idEstado != obj_diaActual[0].idEstado) {
          rangos.push([date]);
        }
      } else {
        rangos.push([date]);
      }

      if (obj_diaSiguiente.length > 0) {
        if (obj_diaSiguiente[0].idEstado != obj_diaActual[0].idEstado) {
          rangos[rangos.length - 1].push(date);
        }
      } else {
        rangos[rangos.length - 1].push(date);
      }
    }

    let formatRangos = $.map(rangos, function (item) {
      return {
        idEstado: item[0].idEstado,
        fechaDesde: item[0].fecha,
        fechaHasta: item[1].fecha,
        idLavanderia: item[0].idLavanderia,
      };
    });
    request.payload = { fechasCalendario: formatRangos };
  }

  context_tblCalendarioCentroTrabajo = new ODataContext({
    url:
      connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioCentroTrabajo",
    entities: {
      fn_IU_tblCalendarioCentroTrabajo: {},
    },
    beforeSend: (request) => {
      this.context_tblCalendarioCentroTrabajo_beforeSend(request);
    },
  });

  context_tblCalendarioCentroTrabajo_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.timeout = 300000;

    let { idCentroLavSel } = this.state;

    let parsed = JSON.parse(idCentroLavSel);
    let idCentroTrabajo = !parsed ? null : parsed[0] > 0 ? parsed[0] : null;

    let { yearCalendar_newData } = this.state;
    request.params.idCentroTrabajo = idCentroTrabajo;

    yearCalendar_newData = $.map(yearCalendar_newData, function (item) {
      return {
        idCentroTrabajo: idCentroTrabajo,
        idEstado: item.idEstado,
        fecha: item.fecha,
      };
    });
    yearCalendar_newData = yearCalendar_newData.sort(function (a, b) {
      if (a.idEstado == b.idEstado) {
        return new Date(a.fecha) - new Date(b.fecha);
      } else {
        return a.idEstado < b.idEstado ? 1 : -1;
      }
    });

    let rangos = [];
    for (var i = 0; i < yearCalendar_newData.length; i++) {
      let date = yearCalendar_newData[i];
      let date_ = new Date(date.fecha);
      let diaAnterior = new Date(
        new Date(date_).setDate(new Date(date_).getDate() - 1),
      );
      let diaSiguiente = new Date(
        new Date(date_).setDate(new Date(date_).getDate() + 1),
      );

      let obj_diaActual = $.grep(yearCalendar_newData, function (item) {
        return item.fecha.toDateString() == date_.toDateString();
      });
      let obj_diaAnterior = $.grep(yearCalendar_newData, function (item) {
        return item.fecha.toDateString() == diaAnterior.toDateString();
      });
      let obj_diaSiguiente = $.grep(yearCalendar_newData, function (item) {
        return item.fecha.toDateString() == diaSiguiente.toDateString();
      });

      if (obj_diaAnterior.length > 0) {
        if (obj_diaAnterior[0].idEstado != obj_diaActual[0].idEstado) {
          rangos.push([date]);
        }
      } else {
        rangos.push([date]);
      }

      if (obj_diaSiguiente.length > 0) {
        if (obj_diaSiguiente[0].idEstado != obj_diaActual[0].idEstado) {
          rangos[rangos.length - 1].push(date);
        }
      } else {
        rangos[rangos.length - 1].push(date);
      }
    }

    let formatRangos = $.map(rangos, function (item) {
      return {
        idEstado: item[0].idEstado,
        fechaDesde: item[0].fecha,
        fechaHasta: item[1].fecha,
        idLavanderia: item[0].idLavanderia,
      };
    });

    request.payload = { fechasCalendario: formatRangos };
  }

  datasource_tblCalendarioLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCalendarioLavanderia",
      key: ["fecha", "idLavanderia", "idEstado"],
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCalendarioLavanderia_beforeSend(request);
      },
      version: 4,
    }),
    expand: ["tblCalendarioPersonal_Estado($select=colorHexa)"],
    filter: "idEstado eq 8 or idEstado eq 10",
  });

  datasource_tblCalendarioLavanderia_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { idCentroLavSel, yearCalendar_currentYear } = this.state;

    let parsed = JSON.parse(idCentroLavSel);
    let idLavanderia = !parsed ? null : parsed[1] > 0 ? parsed[1] : null;

    let fechaDesde = formatDate_parameter(
      startOfYear(new Date(yearCalendar_currentYear, 0, 1)),
    );
    let fechaHasta = formatDate_parameter(
      endOfYear(new Date(yearCalendar_currentYear, 0, 1)),
    );

    if (request.method === "get") {
      request.params.idLavanderia = idLavanderia;
      request.params.fechaDesde = fechaDesde;
      request.params.fechaHasta = fechaHasta;
    }
  }

  datasource_tblCalendarioCentroTrabajo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCalendarioCentroTrabajo",
      key: ["fecha", "idLavanderia", "idEstado"],
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCalendarioCentroTrabajo_beforeSend(request);
      },
      version: 4,
    }),
    expand: ["idEstadoNavigation($select=colorHexa)"],
    map: (itemData) => {
      return this.datasource_tblCalendarioCentroTrabajo_map(itemData);
    },
  });

  datasource_tblCalendarioCentroTrabajo_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { idCentroLavSel, yearCalendar_currentYear } = this.state;
    let parsed = JSON.parse(idCentroLavSel);
    let idCentroTrabajo = !parsed ? null : parsed[0] > 0 ? parsed[0] : null;

    let fechaDesde = formatDate_parameter(
      startOfYear(new Date(yearCalendar_currentYear, 0, 1)),
    );
    let fechaHasta = formatDate_parameter(
      endOfYear(new Date(yearCalendar_currentYear, 0, 1)),
    );

    if (request.method === "get") {
      request.params.idCentroTrabajo = idCentroTrabajo;
      request.params.fechaDesde = fechaDesde;
      request.params.fechaHasta = fechaHasta;
    }
  }

  datasource_tblCalendarioCentroTrabajo_map(itemData) {
    let item = {
      fecha: new Date(new Date(itemData.fecha).setHours(0, 0, 0, 0)),
      idCentroTrabajo: itemData.idCentroTrabajo,
      idEstado: itemData.idEstado,
      tblCalendarioPersonal_Estado: {
        colorHexa: itemData.idEstadoNavigation.colorHexa,
      },
    };
    return item;
  }

  datasource_diasFijados = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/RRHH/GetDiasFijados",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_diasFijados_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_diasFijados_beforeSend(request) {
    request.headers = { ...authHeader() };

    if (request.method === "get") {
      let { idCentroLavSel, yearCalendar_currentYear } = this.state;

      let parsed = JSON.parse(idCentroLavSel);
      let idLavanderia = !parsed ? null : parsed[1] > 0 ? parsed[1] : null;

      let fechaDesde = formatDate_parameter(
        startOfYear(new Date(yearCalendar_currentYear, 0, 1)),
      );
      let fechaHasta = formatDate_parameter(
        endOfYear(new Date(yearCalendar_currentYear, 0, 1)),
      );

      request.params = {
        idLavanderia: idLavanderia,
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
      };
    }
  }

  //#endregion

  cargaDatos_inicio() {
    let _this = this;
    _this.loadPanel_show();
    $.when(
      this.datasource_tblCentroTrabajo.reload(),
      this.datasource_tblLavanderiaNUsuario.reload(),
    ).then(function (tblCentroTrabajo, tblLavanderiaNUsuario) {
      let datasource_tblCentroTrabajo_Lavanderia = $.map(
        $.merge(tblCentroTrabajo[0], tblLavanderiaNUsuario[0]),
        function (item, index) {
          return {
            groupTitle: item.idCentroTrabajo
              ? getTrad("centrosTrabajo")
              : getTrad("lavanderías"),
            idCentroLav: JSON.stringify([
              item.idCentroTrabajo ? item.idCentroTrabajo : -1,
              item.idLavanderia ? item.idLavanderia : -1,
            ]),
            denominacion: item.denominacion,
          };
        },
      );

      _this.setState(
        {
          datasource_tblCentroTrabajo_Lavanderia:
            datasource_tblCentroTrabajo_Lavanderia,
          idCentroLavSel: datasource_tblCentroTrabajo_Lavanderia[0].idCentroLav,
        },
        () => {
          _this.cargaCalendario(
            datasource_tblCentroTrabajo_Lavanderia[0].idCentroLav,
          );
        },
      );
    });
  }

  render() {
    let {
      datasource_tblCentroTrabajo_Lavanderia,
      idCentroLavSel,
      datasource_tblCalendarioEstado,
      yearCalendar_currentYear,
      calendarData,
      yearCalendar_newData,
      diasFijados,
    } = this.state;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div
            id="FestivosCierres"
            className="formContainer scrollbar-container"
          >
            <LoadPanel
              ref={this.dxLoadPanel_calendario_REF}
              shadingColor="#FFF"
              deferRendering={false}
              animation={null}
              position={calendarContainer}
              showIndicator={true}
              shading={true}
              showPane={true}
            />
            <Box direction="col" width="100%" height="100%">
              <ItemBox baseSize={50}>
                <Toolbar>
                  <ToolbarItem location="before">
                    <Lookup
                      dataSource={
                        new DataSource({
                          group: "groupTitle",
                          store: datasource_tblCentroTrabajo_Lavanderia,
                        })
                      }
                      width={275}
                      displayExpr={"denominacion"}
                      valueExpr={"idCentroLav"}
                      value={idCentroLavSel}
                      stylingMode="underlined"
                      closeOnOutsideClick={true}
                      grouped={true}
                      searchEnabled={false}
                      showCancelButton={false}
                      placeholder={"Centro / lavandería"}
                      onContentReady={this.dxLookup_onContenReady}
                      onValueChanged={this.dxLookup_idCentroLav_onValueChanged}
                    />
                  </ToolbarItem>
                </Toolbar>
              </ItemBox>
              <ItemBox ratio={3}>
                <YearCalendar
                  data={calendarData}
                  yearCalendar_newData={yearCalendar_newData}
                  tipoCalendario="festivosCierres"
                  enableRangeSelection={true}
                  listEstados_selectMode={"single"}
                  estados={datasource_tblCalendarioEstado}
                  soloMostrar_idEstados_seleccion={[-1, 0, 8, 10]}
                  readOnly_idEstados_seleccion={[-1]}
                  currentYear={yearCalendar_currentYear}
                  yearCalendar_onDateChanged={this.yearCalendar_onDateChanged}
                  yearCalendar_onYearChanged={this.yearCalendar_onYearChanged}
                  // disabledDays={diasFijados}
                  allowDelete={true}
                />
              </ItemBox>
            </Box>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  cargaCalendario() {
    let { idCentroLavSel, isFirstLoad } = this.state;
    let _this = this;
    let parsed = JSON.parse(idCentroLavSel);
    let idLavanderia = !parsed ? null : parsed[1] > 0 ? parsed[1] : null;

    if (!isFirstLoad) this.dxLoadPanel_calendario.option("visible", true);
    let dataSource = idLavanderia
      ? this.datasource_tblCalendarioLavanderia
      : this.datasource_tblCalendarioCentroTrabajo;

    const promises = [];

    promises.push(dataSource.load());
    idLavanderia && promises.push(this.datasource_diasFijados.load());

    Promise.all(promises).then(([eventoCalendario, diasFijados]) => {
      var resultado = query(eventoCalendario)
        .sortBy("tblCalendarioPersonal_Estado.colorHexa")
        .toArray(); // Se ordena para que los colores null aparezcan primero, así se sobreescribirán con el siguiente color.
      let dataSource = $.map(resultado, function (item, index) {
        return {
          idEstado: item.idEstado,
          fecha: item.fecha,
          colorHexa: item.tblCalendarioPersonal_Estado.colorHexa
            ? item.tblCalendarioPersonal_Estado.colorHexa
            : "transparent",
        };
      });

      diasFijados = $.map(diasFijados, function (item, index) {
        return new Date(item.split("T")[0]).setHours(0, 0, 0, 0);
      });

      _this.dxLoadPanel_calendario.option("visible", false);
      _this.setState({
        calendarData: dataSource,
        isFirstLoad: false,
        diasFijados: diasFijados,
      });
      _this.loadPanel_hide();
    });
  }

  dxLookup_idCentroLav_onValueChanged(e) {
    this.setState({ idCentroLavSel: e.value });
    this.cargaCalendario(e.value);
  }

  dxLookup_onContenReady(e) {
    let popup = e.component._popup;
    popup.option("showTitle", false);
    popup.content().css("top", 0);
  }

  yearCalendar_onDateChanged(data, newData) {
    let { idCentroLavSel, diasFijados } = this.state;
    let _this = this;
    let parsed = JSON.parse(idCentroLavSel);
    let idLavanderia = !parsed ? null : parsed[1] > 0 ? parsed[1] : null;

    let diasAfectados = newData.filter((x) =>
      diasFijados.includes(x.fecha.getTime()),
    );

    if (diasAfectados.length === 0) {
      this.setState({ yearCalendar_newData: [...newData] });
      if (idLavanderia) {
        this.context_tblCalendarioLavanderia
          .invoke("fn_IU_tblCalendarioLavanderia", {}, "POST")
          .done(function () {
            _this.setState(
              { calendarData: data, yearCalendar_newData: [] },
              () => {
                _this.loadPanel_hide();
              },
            );
          });
      } else {
        this.context_tblCalendarioCentroTrabajo
          .invoke("fn_IU_tblCalendarioCentroTrabajo", {}, "POST")
          .done(function () {
            _this.setState(
              { calendarData: data, yearCalendar_newData: [] },
              () => {
                _this.loadPanel_hide();
              },
            );
          });
      }
    } else {
      diasAfectados.sort((x) => x.date);
      let diaInicio = diasAfectados[0].fecha;
      let diaFin = diasAfectados[diasAfectados.length - 1].fecha;

      const format = { day: "2-digit", month: "2-digit", year: "numeric" };

      notify({
        message: `Se han encontrado días fijados: ${diaInicio === diaFin ? diaInicio.toLocaleString(undefined, format) : diaInicio.toLocaleString(undefined, format) + " - " + diaFin.toLocaleString(undefined, format)}`,
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    }
  }

  yearCalendar_onYearChanged(data) {
    let { idCentroLavSel, yearCalendar_currentYear } = this.state;
    if (yearCalendar_currentYear !== data.currentYear) {
      this.state.yearCalendar_currentYear = data.currentYear;
      let _this = this;
      let parsed = JSON.parse(idCentroLavSel);
      let idLavanderia = !parsed ? null : parsed[1] > 0 ? parsed[1] : null;

      this.dxLoadPanel_calendario.option("visible", true);
      let dataSource = idLavanderia
        ? this.datasource_tblCalendarioLavanderia
        : this.datasource_tblCalendarioCentroTrabajo;

      const promises = [];

      promises.push(dataSource.load());
      idLavanderia && promises.push(this.datasource_diasFijados.load());

      Promise.all(promises).then(([eventoCalendario, diasFijados]) => {
        var resultado = query(eventoCalendario)
          .sortBy("tblCalendarioPersonal_Estado.colorHexa")
          .toArray(); // Se ordena para que los colores null aparezcan primero, así se sobreescribirán con el siguiente color.
        let dataSource = $.map(resultado, function (item, index) {
          return {
            idEstado: item.idEstado,
            fecha: item.fecha,
            colorHexa: item.tblCalendarioPersonal_Estado.colorHexa
              ? item.tblCalendarioPersonal_Estado.colorHexa
              : "transparent",
          };
        });

        diasFijados = $.map(diasFijados, function (item, index) {
          return new Date(item.split("T")[0]).setHours(0, 0, 0, 0);
        });

        _this.setState({
          calendarData: dataSource,
          diasFijados: diasFijados,
        });

        _this.dxLoadPanel_calendario.option("visible", false);
      });
    }
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    //DEVEXREME JQUERY
    let _this = this;
    this.datasource_datos_tblCalendarioPersonal_Estado
      .reload()
      .done(function (tblCalendarioEstado) {
        _this.setState({
          datasource_tblCalendarioEstado: tblCalendarioEstado,
        });
        _this.cargaDatos_inicio();
      });
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FestivosCierres);
