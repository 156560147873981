import React from "react";

import { getTrad } from "helpers";

const OverlayCalendarioLavanderia = ({ idsLavanderia, children }) => {
  const overlayStyle = {
    zIndex: 1000,
    display: idsLavanderia.length > 0 ? "none" : "flex",
  };

  const calendarioContainerStyle = {
    height: idsLavanderia.length > 0 ? "100%" : 0,
  };

  return (
    <>
      <div
        className={
          "he-100 w-100 bg-white align-items-center justify-content-center"
        }
        style={overlayStyle}
      >
        <p className={"font-size"}>{getTrad("seleccioneLavanderia")}</p>
      </div>
      <div style={calendarioContainerStyle}>{children}</div>
    </>
  );
};

export default OverlayCalendarioLavanderia;
