import React from "react";
import { SVContext } from "../../components/SmartViewContext";
import { svg_user } from "styles/svg_iconos";
import Persona from "./Persona";

import "./Components.scss";

class Posicion extends React.Component {
    constructor(props) {
        super(props);
    }

    svgPersona = encodeURI("data:image/svg+xml;utf-8," + svg_user.clone()[0].outerHTML);
    styleCenter = { minHeight: 30, display: "flex", alignItems: "center" };

    render() {
        const { data, isMaquina, padding = true } = this.props;
        return (
            <div style={this.styleCenter}>
                <SVContext.Consumer>
                    {({ tblPersonas, setTooltipData }) => {
                        return tblPersonas.filter((y) => y.idPosicionNAreaLavanderiaNLavanderia === data.idPosicion)
                            .length > 0
                            ? tblPersonas
                                  .filter((y) => y.idPosicionNAreaLavanderiaNLavanderia === data.idPosicion)
                                  .map((persona) => {
                                      return (
                                          <Persona
                                              key={`idPos-${data.idPosicion}&idPers-${persona.idPersona}`}
                                              data={persona}
                                          />
                                      );
                                  })
                            : isMaquina && (
                                  <div className={`posicion ${padding ? "p-2" : ""}`}>
                                      <img
                                          className="foto bg-white"
                                          key={`pos-${data.idPosicion}`}
                                          src={this.svgPersona}
                                          onMouseEnter={(e) => setTooltipData(e.target, <this.tooltipContent />)}
                                          onMouseLeave={(e) => setTooltipData()}
                                      />
                                  </div>
                              );
                    }}
                </SVContext.Consumer>
            </div>
        );
    }

    tooltipContent = () => {
        const { data, isMaquina } = this.props;
        return (
            <div>
                <ul>
                    <li>{data.denominacion}</li>
                </ul>
            </div>
        );
    };
}

export default Posicion;
