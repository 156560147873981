import React, { useEffect, useState } from "react";

import Form, { ButtonItem, Item } from "devextreme-react/form";
import Popup from "devextreme-react/popup";
import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import {
  errorHandler,
  authHeader,
  getTrad,
  patchRequestHandler,
} from "helpers";
import { connectionConstants } from "constants";

const PopupEdit_Inventario = ({
  visible = false,
  inventarioSel = null,
  onSave_InventarioMod,
  onHiding,
}) => {
  const [inventarioMod, setInventarioMod] = useState(null);

  useEffect(() => {
    setInventarioMod({ ...inventarioSel });
  }, [visible]);

  const labels = {
    fecha: {
      text: getTrad("fecha"),
      location: "top",
      showColon: false,
    },
    denominacion: {
      text: getTrad("denominacion"),
      location: "top",
      showColon: false,
    },
  };

  const buttonOptions = {
    text: getTrad("guardar"),
    type: "success",
    useSubmitBehavior: true,
  };

  const datasource_tblInventario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblInventario",
      key: "idInventario",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const newDenominacion = inventarioMod.denominacion.trim();
    const newFecha = inventarioMod.fecha;
    let objUpdate = {};

    if (newFecha !== inventarioSel.fecha) objUpdate = { fecha: newFecha };

    if (newDenominacion !== inventarioSel.denominacion)
      objUpdate = { ...objUpdate, denominacion: newDenominacion };

    if (Object.keys(objUpdate).length > 0) {
      datasource_tblInventario
        .store()
        .update(inventarioMod.idInventario, patchRequestHandler(objUpdate))
        .done(() => {
          onSave_InventarioMod(objUpdate);
          notify({
            message: getTrad("aviso_C_RegistroActualizado"),
            type: "success",
            displayTime: 1500,
            closeOnClick: true,
          });
          onHiding();
        })
        .catch(() => {
          notify({
            message: getTrad("aviso_RegistroNoActualizado"),
            type: "error",
            displayTime: 1500,
            closeOnClick: true,
          });
        });
    } else {
      onHiding();
    }
  };

  return (
    <Popup
      visible={visible}
      title={getTrad("modificarInventario")}
      width={600}
      height={270}
      onHiding={onHiding}
    >
      <form onSubmit={onSubmit}>
        <Form formData={inventarioMod}>
          <Item
            dataField={"fecha"}
            label={labels.fecha}
            editorType={"dxDateBox"}
            isRequired
          />
          <Item
            dataField={"denominacion"}
            label={labels.denominacion}
            editorType={"dxTextBox"}
            isRequired
          />
          <ButtonItem buttonOptions={buttonOptions} />
        </Form>
      </form>
    </Popup>
  );
};

export default PopupEdit_Inventario;
