import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import TextBox, { Button } from "devextreme-react/text-box";

import { authHeader, errorHandler, getTrad } from "helpers";
import { connectionConstants } from "constants";

const textBoxButtonOptions = {
  icon: "find",
  stylingMode: "text",
  activeStateEnabled: false,
  focusStateEnabled: false,
  hoverStateEnabled: false,
};

const Buscador = forwardRef(({ disabled, setIdsRecambio }, ref) => {
  const textBoxRef = useRef(null);

  const [value, setValue] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => datasource_assistant.reload().done(setIdsRecambio),
      clear: () => textBoxRef?.current?.instance?.reset(),
    }),
    [value]
  );

  useEffect(() => {
    if (value.length > 0) {
      datasource_assistant.reload().done(setIdsRecambio);
    } else {
      setIdsRecambio(undefined);
    }
  }, [value]);

  const beforeSend = (request) => {
    request.headers = { ...authHeader() };

    request.params.campoBusqueda = value;
  };

  const datasource_assistant = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Assistant/BuscarRecambios",
      errorHandler,
      beforeSend,
      version: 4,
    }),
  });

  const handleValueChange = ({ value }) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => setValue(value), 300);
    setTimeoutId(newTimeoutId);
  };

  return (
    <TextBox
      ref={textBoxRef}
      placeholder={`${getTrad("buscar")}...`}
      valueChangeEvent={"keyup"}
      showClearButton
      disabled={disabled}
      onValueChanged={handleValueChange}
    >
      <Button
        name={"find"}
        location={"before"}
        options={textBoxButtonOptions}
      />
    </TextBox>
  );
});

export default Buscador;
