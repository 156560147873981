import React, { Fragment } from "react";
import { connect } from "react-redux";

import {
    convertClienteUtcToLavanderiaUtc,
    errorHandler,
    authHeader,
    getNombreFormulario,
    getTrad,
    hourDiff,
} from "../../../helpers";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";
import "./SmartView.scss";

import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import { Tooltip } from "devextreme-react/tooltip";
import Button from "devextreme-react/button";

import ControlRendimiento from "./ControlRendimiento";
import UserBox from "../../../layout/AppHeader/Components/UserBox";
import LastUpdateClock from "../../../components/LastUpdateClock";

import ODataContext from "devextreme/data/odata/context";
import { connectionConstants } from "constants";
import SmartViewContext from "./components/SmartViewContext";
import SmartViewLayout from "./Layout";
import { Popover } from "devextreme-react";
import ResumenOperarios from "./components/ResumenOperarios";

const tiempoRecargaProduccion = 30000;

class SmartView extends React.Component {
    constructor(props) {
        super(props);
        let { lavanderia } = this.props;

        this.state = {
            show_paginaSecundaria: false,
            idMaquinaSel: null,
            ultimaFecha_load: convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date()),
            operariosTotal: 0,
            operariosActuales: 0,
            operariosInactivos: [],
            operariosActivosProduccion: 0,
            kgProduccion: 0,
            produccionKgHoraActual: 0,
            hora: null,
        };

        this.onClick_button_ControlRendimiento = this.onClick_button_ControlRendimiento.bind(this);
        this.context_spSelectPersonasKgHora_beforeSend = this.context_spSelectPersonasKgHora_beforeSend.bind(this);
        this.backButton_onClick = this.backButton_onClick.bind(this);
        this.setMaquinaSel = this.setMaquinaSel.bind(this);
    }

    componentDidMount() {
        this.context_spSelectPersonasKgHora_StartTimer();
    }

    componentWillUnmount() {
        this.context_spSelectPersonasKgHora_StopTimer();
    }

    // #region Datasources y context

    context_spSelectPersonasKgHora = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "Dashboard/SmartView/spSelectPersonasKgHora",
        entities: {
            spSelectPersonasKgHora: {},
        },
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            this.context_spSelectPersonasKgHora_beforeSend(request);
        },
    });

    context_spSelectPersonasKgHora_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    context_spSelectPersonasKgHora_load = (tblPersona) => {
        let { lavanderia } = this.props;
        const params = { idLavanderia: lavanderia.idLavanderia };
        this.context_spSelectPersonasKgHora.invoke("", params, "GET").then((data) => {
            const { operariosActivosProduccion, kgProduccion, hora } = data[0];
            const produccionKgHoraActual = kgProduccion / operariosActivosProduccion;
            if (tblPersona) {
                this.setState({
                    operariosTotal: tblPersona.length,
                    operariosActuales: tblPersona.filter((x) => x.idPosicionNAreaLavanderiaNLavanderia).length,
                    operariosInactivos: tblPersona.filter((x) => !x.idPosicionNAreaLavanderiaNLavanderia),
                    operariosActivosProduccion: operariosActivosProduccion,
                    kgProduccion: kgProduccion,
                    produccionKgHoraActual: Number.isNaN(produccionKgHoraActual)
                        ? 0
                        : Math.round(produccionKgHoraActual),
                    hora: hora,
                });
            } else {
                this.setState({
                    operariosActivosProduccion: operariosActivosProduccion,
                    kgProduccion: kgProduccion,
                    produccionKgHoraActual: Number.isNaN(produccionKgHoraActual)
                        ? 0
                        : Math.round(produccionKgHoraActual),
                    hora: hora,
                });
            }
        });
    };

    context_spSelectPersonasKgHora_StartTimer = () => {
        this.spSelectPersonasKgHora_Timer = setTimeout(() => {
            this.context_spSelectPersonasKgHora_load();
            this.context_spSelectPersonasKgHora_StartTimer();
        }, tiempoRecargaProduccion);
    };

    context_spSelectPersonasKgHora_StopTimer = () => {
        if (this.spSelectPersonasKgHora_Timer) {
            clearInterval(this.spSelectPersonasKgHora_Timer);
        }
    };

    // #endregion

    // #region Multiview

    //BACK BUTTON
    getBackButton() {
        return (
            <Fragment>
                <div
                    id="backButtonLav"
                    className="backButton dx-icon-chevronleft container_spanCentrado"
                    onClick={this.backButton_onClick}
                />
                <Tooltip target="#backButtonLav" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div>{getTrad("volverLavanderia")}</div>
                </Tooltip>
            </Fragment>
        );
    }

    backButton_onClick() {
        this.setState({
            show_paginaSecundaria: false,
            idMaquinaSel: null,
        });
    }

    getbutton_ControlRendimiento() {
        return (
            <Button
                className="font-weight-bold "
                height={40}
                width={250}
                icon="clock"
                type="default"
                text={getTrad("controlRendimiento")}
                onClick={this.onClick_button_ControlRendimiento}
            />
        );
    }

    onClick_button_ControlRendimiento() {
        this.setState({
            show_paginaSecundaria: true,
            idMaquinaSel: null,
        });
    }

    // #endregion

    render() {
        let { show_paginaSecundaria, idMaquinaSel } = this.state;
        const { title, enableFullScreen, mainScreen = true } = this.props;
        const {
            operariosTotal,
            operariosActuales,
            operariosInactivos,
            operariosActivosProduccion,
            kgProduccion,
            produccionKgHoraActual,
            hora,
        } = this.state;

        return (
            <Fragment>
                <SmartViewContext
                    onRefreshPersonalActivo={this.context_spSelectPersonasKgHora_load}
                    setMaquinaSel={this.setMaquinaSel}
                >
                    <PageTitle
                        preHeading={show_paginaSecundaria ? this.getBackButton() : ""}
                        heading={
                            show_paginaSecundaria
                                ? getTrad("controlRendimiento")
                                : title
                                ? title
                                : getNombreFormulario(this)
                        }
                        postHeading={[
                            <ResumenOperarios
                                enableFullScreen={enableFullScreen}
                                mainScreen={mainScreen}
                                operariosTotal={operariosTotal}
                                operariosActuales={operariosActuales}
                                operariosInactivos={operariosInactivos}
                                operariosActivosProduccion={operariosActivosProduccion}
                                kgProduccion={kgProduccion}
                                produccionKgHoraActual={produccionKgHoraActual}
                                hora={hora}
                            />,
                            <div className="d-flex justify-content-between align-items-center">
                                {mainScreen && (show_paginaSecundaria ? "" : this.getbutton_ControlRendimiento())}
                                {!mainScreen &&
                                    (enableFullScreen === true ? (
                                        <div className="header-btn-lg">
                                            <LastUpdateClock fecha={this.state.ultimaFecha_load} />
                                        </div>
                                    ) : (
                                        ""
                                    ))}
                                {!mainScreen && (enableFullScreen === true ? <UserBox /> : "")}
                            </div>,
                        ]}
                    />
                    <div className={"media-body he-100 w-100 "}>
                        <div id="SmartView" className="formContainer scrollbar-container pb-4 pt-0">
                            <MultiView
                                height="100%"
                                width="100%"
                                selectedIndex={show_paginaSecundaria ? 1 : 0}
                                focusStateEnabled={false}
                                loop={false}
                                swipeEnabled={false}
                                deferRendering={false}
                                animationEnabled={true}
                            >
                                <MultiViewItem>
                                    <SmartViewLayout />
                                </MultiViewItem>
                                <MultiViewItem>
                                    <ControlRendimiento
                                        group={idMaquinaSel ? "maquina" : null}
                                        filtro_1={idMaquinaSel ? { idMaquina: idMaquinaSel } : {}}
                                    />
                                </MultiViewItem>
                            </MultiView>
                        </div>
                    </div>
                </SmartViewContext>
            </Fragment>
        );
    }

    setMaquinaSel(idMaquina) {
        this.setState({
            show_paginaSecundaria: true,
            idMaquinaSel: idMaquina,
        });
    }

    get_isMovil() {
        return this.props.resolucion === "xs" || this.props.resolucion === "sm" || this.props.resolucion === "md";
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
    resolucion: state.Global.resolucion,
    enableFullScreen: state.ThemeOptions.enableFullScreen,
});

export default connect(mapStateToProps)(SmartView);
