import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { impresionActions, loadPanelActions } from "../../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import OpcionesTitle from "../../../../layout/AppOpciones/Title";
import OpcionesContainer from "../../../../layout/AppOpciones/Container";

//Layout
import PageTitle from "../../../../layout/AppMain/PageTitle";

import { getNombreFormulario, getTrad } from "../../../../helpers";

import { Tooltip } from "devextreme-react/tooltip";
import $ from "jquery";

//Js
import cargaJs from "./Js.js";

//Selector
import Selector from "../../../../layout/AppSelector";
import * as cardContent from "./cardContent";

class InfReposicionesOffice extends React.Component {
  constructor(props) {
    super(props);
    let { param_paginaSecundaria } = props;
    this.state = {
      showSelector: true,
      fechaDesde_redireccion:
        param_paginaSecundaria != null
          ? param_paginaSecundaria.fechaDesde
          : null,
      fechaHasta_redireccion:
        param_paginaSecundaria != null
          ? param_paginaSecundaria.fechaHasta
          : null,
    };

    this.setParams = this.setParams.bind(this);
    this.setReport = this.setReport.bind(this);
    this.closeReport = this.closeReport.bind(this);
    this.hideSelector = this.hideSelector.bind(this);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  setParams(params) {
    this.props.setParams(params);
  }

  setReport(report) {
    this.props.setReport(report);
  }

  closeReport() {
    this.props.close();
  }

  hideSelector(params) {
    this.loadPanel_show();
    this.setState({ showSelector: false, ...params });
  }

  render() {
    const { showSelector, idEntidad } = this.state;
    let { getBackButton_paginaSecundaria, param_paginaSecundaria } = this.props;
    return (
      <Fragment>
        <PageTitle
          heading={
            param_paginaSecundaria != null
              ? param_paginaSecundaria.nombre_paginaSecundaria
              : getNombreFormulario(this)
          }
          preHeading={
            <Fragment>
              <div>
                {showSelector
                  ? getBackButton_paginaSecundaria
                    ? getBackButton_paginaSecundaria()
                    : ""
                  : ""}
              </div>
              <div>
                {!showSelector ? (
                  <div
                    id="btnAtras_InformeRepOffice"
                    className="backButton dx-icon-chevronleft container_spanCentrado"
                  ></div>
                ) : (
                  ""
                )}
              </div>
            </Fragment>
          }
          postHeading={showSelector ? "" : <OpcionesTitle />}
        />

        {showSelector ? (
          <Selector
            contenido={getNombreFormulario(this)}
            selector="compañia"
            expandir={true}
            rangeSelector={true}
            hideSelector={this.hideSelector}
            location={this.props.location}
            {...cardContent}
            {...this.state}
            {...this.props}
          />
        ) : (
          <Fragment>
            <div className={"media-body"}>
              {/* <ReactCSSTransitionGroup
                        component="div"
                        className={"media-body"}
                        transitionName="PageAnimation"
                        transitionAppear={true}
                        transitionAppearTimeout={1200}
                        transitionEnter={false}
                        transitionLeave={false}> */}
              <OpcionesContainer />
              <div id="InfReposicionesOffice" className={"formContainer"}>
                <div id="dxContainer"></div>
                <div id="dxPopup_repartos"></div>
                <div id="dxPopover_abonoEntrega"></div>
              </div>
              {/* </ReactCSSTransitionGroup> */}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma, param_paginaSecundaria } = this.props;

    if (!this.state.showSelector) {
      if (
        (lavanderia &&
          lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
        this.state.showSelector !== prevState.showSelector ||
        idioma.idIdioma !== prevProps.idioma.idIdioma ||
        (param_paginaSecundaria !== prevProps.param_paginaSecundaria &&
          param_paginaSecundaria != null)
      ) {
        if (
          lavanderia &&
          lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
        ) {
          this.setState({ showSelector: true });
        }
        cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
        this.props.close();
      }
    } else if (
      param_paginaSecundaria !== prevProps.param_paginaSecundaria &&
      param_paginaSecundaria != null
    ) {
      this.loadPanel_show();
      cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
    }
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.closeReport();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  setReport: (report) => dispatch(impresionActions.setReport(report)),
  setParams: (params) => dispatch(impresionActions.setParams(params)),
  close: () => dispatch(impresionActions.close()),
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InfReposicionesOffice);
