import { connect } from "react-redux";
import React, { Component } from "react";

import "./Styles.scss";
import {
    errorHandler,
    authHeader,
    getTrad,
    patchRequestHandler,
    formatDate_noTime_parameter,
    patchRequestHandlerWithNavigations,
} from "helpers";
import { connectionConstants } from "constants";

import query from "devextreme/data/query";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { Card, CardBody, CardHeader } from "reactstrap";
import { Button, Popup, ScrollView } from "devextreme-react";
import Box, { Item as ItemBox } from "devextreme-react/box";
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    FilterRow,
    Lookup,
    Pager,
    Selection,
} from "devextreme-react/data-grid";
import { editCellComponent } from "components/DataGrid/Cells";
import FechaAntiguedadPopup from "../FechaAntiguedadPopup";
import ODataContext from "devextreme/data/odata/context";

const diasSemana = [
    new Date("2024-02-12"),
    new Date("2024-02-13"),
    new Date("2024-02-14"),
    new Date("2024-02-15"),
    new Date("2024-02-16"),
    new Date("2024-02-17"),
    new Date("2024-02-18"),
];

class AsignarLlamamientos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tblLlamamientoByLavanderia: query(props.tblLlamamiento ?? [])
                .groupBy("idLavanderia")
                .sortBy("key")
                .toArray(),
            listaLlamamientos: [],
            tblTurno: [],
            idLavanderiaSel: null,
            llamamientoSel: null,

            dxPopup_visible: false,
            isVisible_FechaAntiguedadPopup: false,
        };

        this.dxDataGrid_Personas_REF = React.createRef();
    }

    get dxDataGrid_Personas() {
        return this.dxDataGrid_Personas_REF.current.instance;
    }

    // #region Traducciones

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    // #endregion

    // #region Componente

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.popupVisible !== this.props.popupVisible) {
            this.setState({
                listaLlamamientos: [],
                idLavanderiaSel: null,
            });
        }
        if (prevState.idLavanderiaSel !== this.state.idLavanderiaSel) {
            this.setState({
                llamamientoSel: null,
            });
        }
        if (prevState.llamamientoSel !== this.state.llamamientoSel) {
            const { isVistaGeneral, AsignarLlamamientos_onSelectionChanged } = this.props;
            const { llamamientoSel } = this.state;
            if (!isVistaGeneral && AsignarLlamamientos_onSelectionChanged && llamamientoSel != null) {
                AsignarLlamamientos_onSelectionChanged({ ...llamamientoSel });
            }
        }
        if (JSON.stringify(prevProps.tblLlamamiento) !== JSON.stringify(this.props.tblLlamamiento)) {
            const { idLavanderiaSel } = this.state;
            let tblLlamamientoByLavanderia = query(this.props.tblLlamamiento ?? [])
                .groupBy("idLavanderia")
                .sortBy("key")
                .toArray();
            let listaLlamamientos = tblLlamamientoByLavanderia.find((x) => x.key === idLavanderiaSel)?.items ?? [];

            this.setState({
                tblLlamamientoByLavanderia,
                listaLlamamientos,
            });
        }
    };

    // #endregion

    datasource_tblPersona = new DataSource({
        // paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblPersona_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblPersona_onLoading(loadOptions),
            version: 4,
        }),
        // select: ["idPersona", "idLavanderia", "nombre", "apellidos", "telefono", "idCategoriaInterna", "idTipoTrabajo"],
        expand: [
            "tblDatosSalariales($select=fechaAntiguedad,isTrienio,numPagas,percSegSocial)",
            "tblPersonaNTipoContrato",
        ],
    });

    datasource_tblPersona_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        if (request.method === "get") {
            request.url =
                connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/Llamamiento/GetPersonasDiscontinuas()";
        }
    };

    datasource_tblPersona_onLoading = (loadOptions) => {
        if (!Array.isArray(loadOptions.filter)) loadOptions.filter = [];
        else {
            if (!Array.isArray(loadOptions.filter[0])) {
                loadOptions.filter = [loadOptions.filter];
            }
        }

        if (!this.dxDataGrid_Personas.columnOption("tblPersonaNTipoContrato.idMotivoBaja", "visible"))
            loadOptions.filter.push(["tblPersonaNTipoContrato.idMotivoBaja", "=", 4]); // Cese actividad

        if (!this.dxDataGrid_Personas.columnOption("tblPersonaNTipoContrato.idTipoContrato", "visible"))
            loadOptions.filter.push(["tblPersonaNTipoContrato.idTipoContrato", "=", 4]); // Fijo discontinuo
    };

    render() {
        const { tblCategoriaInterna, tblTipoTrabajo, tblTipoContrato, isVistaGeneral } = this.props;
        const {
            listaLlamamientos,
            llamamientoSel,
            tblLlamamientoByLavanderia,
            tblTurno,
            isVisible_FechaAntiguedadPopup,
            personaSel,
        } = this.state;

        return (
            <>
                <Box className="he-100">
                    <ItemBox baseSize={350}>
                        <ScrollView height={665}>
                            <div className="llamamientoCardList">
                                {tblLlamamientoByLavanderia?.map((x) => (
                                    <this.LavanderiaCard idLavanderia={x.key} llamamientos={x.items} />
                                ))}
                            </div>
                        </ScrollView>
                    </ItemBox>
                    <ItemBox ratio={1}>
                        <Card className="he-100 position-relative">
                            <CardBody>
                                <DataGrid
                                    className="llamamientoGrid card-content he-100"
                                    width={"100%"}
                                    dataSource={listaLlamamientos}
                                    showRowLines={true}
                                    showColumnLines={false}
                                    repaintChangesOnly={true}
                                    onSelectionChanged={this.dxDataGrid_onSelectionChanged}
                                    selectedRowKeys={isVistaGeneral ? [] : [llamamientoSel]}
                                    hoverStateEnabled
                                    onRowUpdating={this.onRowUpdating}
                                >
                                    <Selection mode={isVistaGeneral ? "none" : "single"} />
                                    <Editing
                                        mode={isVistaGeneral ? "cell" : "batch"}
                                        allowAdding={false}
                                        allowUpdating={isVistaGeneral}
                                        allowDeleting={false}
                                        useIcons={true}
                                    />
                                    <FilterRow
                                        visible={true}
                                        applyFilter="auto"
                                        showAllText={this.getTrad("todos").toUpperCase()}
                                    />
                                    <Column
                                        dataField={"idLlamamiento"}
                                        dataType={"number"}
                                        allowEditing={false}
                                        visible={false}
                                    />
                                    <Column
                                        dataField={"codigoLlamamiento"}
                                        dataType={"number"}
                                        caption={""}
                                        width={40}
                                        alignment={"left"}
                                        allowEditing={false}
                                        visible={true}
                                        allowFiltering={false}
                                        sortOrder={"asc"}
                                        cellRender={this.dxDataGrid_cellComponent_codigoLlamamiento}
                                    />
                                    <Column
                                        dataField={"idCategoriaInterna"}
                                        dataType={"number"}
                                        caption={this.getTrad("categoriaInterna")}
                                        alignment={"center"}
                                        allowEditing={false}
                                        width={180}
                                    >
                                        <Lookup
                                            dataSource={tblCategoriaInterna}
                                            valueExpr={"idCategoriaInterna"}
                                            displayExpr={"denominacion"}
                                        />
                                    </Column>
                                    <Column
                                        dataField={"idTipoTrabajo"}
                                        dataType={"number"}
                                        caption={this.getTrad("tipoTrabajo")}
                                        alignment={"center"}
                                        allowEditing={false}
                                        width={180}
                                    >
                                        <Lookup
                                            dataSource={tblTipoTrabajo}
                                            valueExpr={"idTipoTrabajo"}
                                            displayExpr={"denominacion"}
                                        />
                                    </Column>
                                    <Column
                                        dataField={"fechaIni"}
                                        dataType={"date"}
                                        format={"dd/MM/yyyy"}
                                        caption={this.getTrad("fechaInicio")}
                                        alignment={"center"}
                                        width={110}
                                        cellComponent={this.dxDataGrid_cellComponent_fecha}
                                        allowEditing={true}
                                    />
                                    <Column
                                        dataField={"idTurno"}
                                        dataType={"number"}
                                        caption={this.getTrad("turno")}
                                        alignment={"center"}
                                        width={140}
                                        allowEditing={true}
                                        allowFiltering={true}
                                    >
                                        <Lookup
                                            dataSource={tblTurno}
                                            valueExpr={"idTurno"}
                                            displayExpr={"denominacion"}
                                        />
                                    </Column>
                                    <Column
                                        dataField={"tblDiasLibresPersonal_Llamamiento"}
                                        dataType={"object"}
                                        caption={this.getTrad("diasLibres")}
                                        alignment={"center"}
                                        width={100}
                                        cellComponent={this.dxDataGrid_cellComponent_diasLibres}
                                        allowFiltering={false}
                                        allowEditing={false}
                                    />
                                    <Column
                                        dataField={"idPersona"}
                                        dataType={"number"}
                                        allowFiltering={false}
                                        visible={false}
                                        allowEditing={false}
                                    />
                                    <Column
                                        dataField={"idTipoContrato"}
                                        dataType={"string"}
                                        caption={this.getTrad("tipoContrato")}
                                        width={120}
                                        alignment={"center"}
                                    >
                                        <Lookup
                                            dataSource={tblTipoContrato?.filter((x) => x.idTipoContrato !== 3)}
                                            valueExpr={"idTipoContrato"}
                                            displayExpr={"denominacion"}
                                        />
                                    </Column>
                                    <Column
                                        dataField={"idPersonaNavigation.nombreCompuesto"}
                                        dataType={"string"}
                                        caption={this.getTrad("personaAsociada")}
                                        width={230}
                                        alignment={"center"}
                                        cssClass={"p-0 position-relative"}
                                        allowFiltering={false}
                                        allowEditing={false}
                                        cellComponent={this.dxDataGrid_cellComponent_idPersona}
                                        fixed={true}
                                        fixedPosition={"right"}
                                    />
                                    <Column
                                        dataType={"bool"}
                                        allowEditing={false}
                                        width={30}
                                        visible={isVistaGeneral}
                                        cellComponent={this.dxDataGrid_cellComponent_eliminarPersona}
                                        cssClass={"p-0"}
                                        fixed={true}
                                        fixedPosition={"right"}
                                    />
                                </DataGrid>
                            </CardBody>
                        </Card>
                    </ItemBox>
                </Box>
                <this.DataGridPersonas />
                <FechaAntiguedadPopup
                    visible={isVisible_FechaAntiguedadPopup}
                    idPersona={personaSel?.idPersona}
                    nombreCompleto={
                        personaSel?.idPersonaNavigation != null
                            ? personaSel?.idPersonaNavigation.nombreCompuesto
                            : personaSel?.nombre + " " + personaSel?.apellidos
                    }
                    onHiding={this.FechaAntiguedaPopup_onHiding}
                    onGuardar={this.FechaAntiguedaPopup_onGuardar}
                />
            </>
        );
    }

    // #region DataGrid seleccion persona

    DataGridPersonas = () => {
        const { tblCategoriaInterna, tblTipoTrabajo, tblLavanderia, tblTipoContrato, tblMotivoBaja } = this.props;
        const { dxPopup_visible, llamamientoSel } = this.state;
        return (
            <Popup
                visible={dxPopup_visible}
                onHiding={this.dxPopup_onHiding}
                dragEnabled={false}
                closeOnOutsideClick={false}
                width={"90%"}
            >
                <div className="position-relative" style={{ height: 665 }}>
                    <div className="popup-content he-100">
                        <DataGrid
                            ref={this.dxDataGrid_Personas_REF}
                            className="llamamientoGrid he-100"
                            dataSource={this.datasource_tblPersona}
                            selectedRowKeys={[]}
                            onSelectionChanged={this.dxDataGrid_Personas_onSelectionChanged}
                            showColumnLines={false}
                            showRowLines={true}
                            hoverStateEnabled
                        >
                            <Selection mode={"single"} />
                            <FilterRow
                                visible={true}
                                showAllText={this.getTrad("todos").toUpperCase()}
                                applyFilter="auto"
                            />
                            <ColumnChooser enabled={true} />

                            <Column
                                dataField={"tblDatosSalariales.fechaAntiguedad"}
                                alignment={"center"}
                                caption={this.getTrad("fechaAntiguedad")}
                                sortOrder={"asc"}
                                width={"auto"}
                                dataType={"date"}
                                format={"dd/MM/yyyy"}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField={"nombre"}
                                alignment={"left"}
                                caption={this.getTrad("nombre")}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField={"apellidos"}
                                alignment={"left"}
                                caption={this.getTrad("apellidos")}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField={"telefono"}
                                alignment={"center"}
                                width={"auto"}
                                caption={this.getTrad("telefono")}
                                allowFiltering={false}
                            />
                            <Column
                                dataField={"idCategoriaInterna"}
                                alignment={"center"}
                                caption={this.getTrad("categoriaInterna")}
                                filterValue={llamamientoSel?.idCategoriaInterna}
                            >
                                <Lookup
                                    dataSource={tblCategoriaInterna}
                                    valueExpr={"idCategoriaInterna"}
                                    displayExpr={"denominacion"}
                                />
                            </Column>
                            <Column
                                dataField={"idTipoTrabajo"}
                                alignment={"center"}
                                caption={this.getTrad("tipoTrabajo")}
                                filterValue={llamamientoSel?.idTipoTrabajo}
                            >
                                <Lookup
                                    dataSource={tblTipoTrabajo}
                                    valueExpr={"idTipoTrabajo"}
                                    displayExpr={"denominacion"}
                                />
                            </Column>
                            <Column
                                dataField={"idLavanderia"}
                                alignment={"center"}
                                caption={this.getTrad("lavanderia")}
                                filterValue={llamamientoSel?.idLavanderia}
                            >
                                <Lookup
                                    dataSource={tblLavanderia}
                                    valueExpr={"idLavanderia"}
                                    displayExpr={"denominacion"}
                                />
                            </Column>
                            <Column
                                dataField={"tblPersonaNTipoContrato.idMotivoBaja"}
                                alignment={"center"}
                                caption={this.getTrad("motivoBaja")}
                                defaultFilterValue={4} // Cese actividad
                                visible={false}
                            >
                                <Lookup
                                    dataSource={tblMotivoBaja}
                                    valueExpr={"idMotivoBaja"}
                                    displayExpr={"denominacion"}
                                />
                            </Column>
                            <Column
                                dataField={"tblPersonaNTipoContrato.idTipoContrato"}
                                alignment={"center"}
                                caption={this.getTrad("tipoContrato")}
                                defaultFilterValue={4} // Fijo discontinuo
                                visible={false}
                            >
                                <Lookup
                                    dataSource={tblTipoContrato}
                                    valueExpr={"idTipoContrato"}
                                    displayExpr={"denominacion"}
                                />
                            </Column>
                        </DataGrid>
                    </div>
                </div>
            </Popup>
        );
    };

    dxPopup_onHiding = () => {
        this.setState({ dxPopup_visible: false });
    };

    dxDataGrid_Personas_onSelectionChanged = ({ selectedRowsData }) => {
        const { dataSource_tblLlamamiento } = this.props;
        const { llamamientoSel } = this.state;

        if (selectedRowsData.length > 0) {
            let personaSel = selectedRowsData?.at(0);

            dataSource_tblLlamamiento
                .store()
                .update(llamamientoSel.idLlamamiento, patchRequestHandler({ idPersona: personaSel?.idPersona }))
                .then(() => {
                    this.setState({
                        dxPopup_visible: false,
                        isVisible_FechaAntiguedadPopup: true,
                        FechaAntiguedadPopup_saveMode: "update",
                        personaSel,
                    });
                });
        }
    };

    // #endregion

    // #region Card lavanderia

    LavanderiaCard = ({ idLavanderia, llamamientos }) => {
        const { idLavanderiaSel } = this.state;
        const { tblLavanderia, tblCategoriaInterna } = this.props;

        const lavanderia = tblLavanderia?.find((x) => x.idLavanderia === idLavanderia);

        const categoriaCount = llamamientos.filter((x) => x.idPersona == null).length;

        let llamamientosByCI = query(llamamientos).groupBy("idCategoriaInterna").toArray();

        return (
            <Card
                className={`lavanderiaCard ${idLavanderia === idLavanderiaSel ? "cardSelected" : ""}`}
                onClick={() => this.LlamamientoCard_onClick(idLavanderia, llamamientos)}
            >
                <CardHeader className={"justify-content-between"}>
                    <span>{lavanderia?.denominacion}</span>
                    <div className="p-0">
                        {/* {categoriaCountAviso > 0 && <Chip color={'danger'} className={'mx-1'} text={categoriaCountAviso} />} */}
                        {categoriaCount > 0 && <Chip color={"warning"} className={"mx-1"} text={categoriaCount} />}
                    </div>
                </CardHeader>
                <CardBody className="categoriaInternaList">
                    {llamamientosByCI.map((x) => {
                        const categoriaInterna = tblCategoriaInterna?.find((ci) => ci.idCategoriaInterna === x.key);
                        const categoriaCount = x.items.filter((x) => x.idPersona == null).length;

                        return (
                            <div key={`ci-${x.key}`} className="d-flex flex-row justify-content-between">
                                <span>{categoriaInterna?.denominacion}</span>
                                <div className="p-0">
                                    {/* {categoriaCountAviso > 0 && <Chip color={'danger'} className={'mx-1'} text={categoriaCountAviso} />} */}
                                    {categoriaCount > 0 && (
                                        <Chip color={"warning"} className={"mx-1"} text={categoriaCount} />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </CardBody>
            </Card>
        );
    };

    LlamamientoCard_onClick = (idLavanderia, llamamientos) => {
        let tblTurno = this.props.tblTurno.filter((x) => x.idLavanderia === idLavanderia);

        this.setState({
            idLavanderiaSel: idLavanderia,
            listaLlamamientos: llamamientos,
            tblTurno,
        });
    };

    // #endregion

    // #region dxDataGrid

    dxDataGrid_cellComponent_codigoLlamamiento = ({ displayValue, key }) => {
        // return <code>#{displayValue}</code>
        return <code>#{key.codigoLlamamiento}</code>;
    };

    dxDataGrid_onSelectionChanged = (e) => {
        this.setState({ llamamientoSel: e.selectedRowsData.at(0) });
    };

    onRowUpdating = (e) => {
        const { newData, key } = e;
        this.props.dataSource_tblLlamamiento
            .store()
            .update(key.idLlamamiento, patchRequestHandler(newData))
            .then(() => {
                if (newData.fechaIni != null || newData.idTipoContrato) {
                    this.setState({
                        personaSel: key,
                        isVisible_FechaAntiguedadPopup: true,
                        FechaAntiguedadPopup_saveMode: "updateFechaAntiguedad",
                    });
                }
            })
            .catch(() => {
                this.props.refresh();
            });
    };

    dxDataGrid_cellComponent_fecha = ({ data }) => {
        const { text, value } = data;
        const { idPersona } = data.data;

        return <span className={value < Date.now() && idPersona == null ? "color-red" : ""}>{text}</span>;
    };

    dxDataGrid_cellComponent_diasLibres = ({ data }) => {
        const { value } = data;
        if (value[0]?.idDiaSemana != null) {
            return (
                <span className="text-capitalize">
                    {value
                        .map((x) =>
                            diasSemana[x.idDiaSemana - 1].toLocaleDateString(undefined, {
                                weekday: "short",
                            })
                        )
                        .join(", ")}
                </span>
            );
        } else {
            return <span>{value.map((x) => x.idDiaMes).join(", ")}</span>;
        }
    };

    dxDataGrid_cellComponent_idPersona = ({ data, ...e }) => {
        const { isVistaGeneral } = this.props;
        const { value, key, row } = data;
        return (
            <div className={"botonAsociarPersonaGeneral d-flex align-items-center justify-content-center"}>
                {value ? (
                    <span className="px-2">{value}</span>
                ) : (
                    <Button
                        className="he-100 w-100"
                        disabled={!isVistaGeneral}
                        stylingMode={"text"}
                        type={"default"}
                        text={this.getTrad("asociarPersona")}
                        onClick={() => this.dxButton_onClick(key)}
                    />
                )}
            </div>
        );
    };

    dxDataGrid_cellComponent_eliminarPersona = ({ data }) => {
        const { key } = data;
        return key.idPersona ? (
            <i
                className="dx-icon dx-icon-close he-100 w-100 text-center"
                onClick={() => this.dxDataGrid_cellComponent_eliminarPersona_onClick(key)}
            />
        ) : null;
    };

    dxDataGrid_cellComponent_eliminarPersona_onClick = (llamamiento) => {
        const { dataSource_tblLlamamiento } = this.props;
        dataSource_tblLlamamiento
            .store()
            .update(llamamiento.idLlamamiento, patchRequestHandler({ idPersona: null }))
            .then(() => {
                this.setState({
                    personaSel: { ...llamamiento },
                    isVisible_FechaAntiguedadPopup: true,
                    FechaAntiguedadPopup_saveMode: "updateFechaAntiguedad",
                });
            });
    };

    // #endregion

    // #region Asociar persona

    dxButton_onClick = (value) => {
        this.setState({ dxPopup_visible: true, llamamientoSel: value });
    };

    // #endregion

    // #region Fecha antiguedad

    FechaAntiguedaPopup_onHiding = (e) => {
        this.setState({ isVisible_FechaAntiguedadPopup: false });
    };

    FechaAntiguedaPopup_onGuardar = ({ component, value: fechaAntiguedad }) => {
        // component.option("disabled", true);
        const { tblLavanderia, tblCategoriaInterna } = this.props;
        const { llamamientoSel, personaSel, FechaAntiguedadPopup_saveMode } = this.state;

        const fecha = fechaAntiguedad instanceof Date ? fechaAntiguedad : new Date(fechaAntiguedad);

        let prom;
        if (FechaAntiguedadPopup_saveMode === "updateFechaAntiguedad") {
            const objPersona = {
                tblDatosSalariales: { fechaAntiguedad: formatDate_noTime_parameter(fecha) },
            };

            prom = this.datasource_tblPersona
                .store()
                .update(personaSel.idPersona, patchRequestHandlerWithNavigations(objPersona));
        } else if (FechaAntiguedadPopup_saveMode === "update") {
            const lavanderia = tblLavanderia?.find((x) => x.idLavanderia === llamamientoSel.idLavanderia);

            const categoriaInterna = tblCategoriaInterna.find(
                (x) => x.idCategoriaInterna === llamamientoSel.idCategoriaInterna
            );

            let objPersona = {
                idLlamamiento: llamamientoSel.idLlamamiento,
                idLavanderia: llamamientoSel.idLavanderia,
                idTurno: llamamientoSel.idTurno,
                idTipoTrabajo: llamamientoSel.idTipoTrabajo,
                idCategoriaInterna: llamamientoSel.idCategoriaInterna,
                idFormatoDiasLibres: llamamientoSel.idFormatoDiasLibres,
                tblDiasLibresPersonal: llamamientoSel.tblDiasLibresPersonal_Llamamiento,
                idCentroTrabajo: null,
                idEmpresaPolarier: lavanderia.idEmpresaPolarier,
                idLocalizacion: lavanderia.idLocalizacion,
                tblDatosSalariales: {
                    numPagas: 12,
                    isTrienio: false,
                    ...personaSel.tblDatosSalariales,
                    fechaAntiguedad: fecha,
                    salarioBase: categoriaInterna.salarioBase,
                    impHoraExtra: categoriaInterna.impHoraExtra,
                    incentivo: categoriaInterna.incentivo,
                    plusAsistencia: categoriaInterna.plusAsistencia,
                    plusPeligrosidad: categoriaInterna.plusPeligrosidad,
                    plusProductividad: categoriaInterna.plusProductividad,
                    plusResponsabilidad: categoriaInterna.plusResponsabilidad,
                },
            };

            prom = this.datasource_tblPersona
                .store()
                .update(personaSel.idPersona, patchRequestHandlerWithNavigations(objPersona));
        }

        prom.always(() => {
            component.option("disabled", false);
            this.setState({
                isVisible_FechaAntiguedadPopup: false,
                FechaAntiguedadPopup_saveMode: null,
                personaSel: null,
            });
            this.datasource_tblPersona.reload();
        });
    };

    // #endregion
}

function Chip({ color, text, className }) {
    return <span className={`chip text-white font-weight-bold bg-${color} ${className}`}>{text}</span>;
}

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(AsignarLlamamientos);
