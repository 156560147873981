import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  dxMensajePregunta,
} from "../../../helpers";
import {
  svg_flecha,
  svg_remove,
  svg_edit,
  svg_warning,
} from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var auditoriaSel;
  var infoPuntoDeRevision;
  var imagenSeleccionada; //Almacena el id de la imagen que se quiere visualizar en grande
  var noConfSel;

  //FILTROS
  var idArea,
    idAmbito,
    idSubAmbito,
    idTipoMaquina,
    idCategoriaMaquina,
    idMaquina;

  var array_fotos;

  //#region DATASOURCES

  var dataSource_Auditorias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAuditoria",
      key: "idAuditoria",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: [
      "idAuditoria",
      "denoLavanderia",
      "fecha",
      "idTipoAuditoria",
      "denoTipoAuditoria",
    ],
  });
  dataSource_Auditorias.load();

  var dataSource_PuntoRevision = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPuntoRevisionNAuditoria",
      key: "idPuntoRevisionNAuditoria",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idAuditoria =
          typeof auditoriaSel.idAuditoria !== "undefined"
            ? auditoriaSel.idAuditoria
            : 0;
        request.params.idArea = typeof idArea !== "undefined" ? idArea : null;
        request.params.idAmbito =
          typeof idAmbito !== "undefined" ? idAmbito : null;
        request.params.idSubAmbito =
          typeof idSubAmbito !== "undefined" ? idSubAmbito : null;
        request.params.idTipoMaquina =
          typeof idTipoMaquina !== "undefined" ? idTipoMaquina : null;
        request.params.idCategoriaMaquina =
          typeof idCategoriaMaquina !== "undefined" ? idCategoriaMaquina : null;
        request.params.idMaquina =
          typeof idMaquina !== "undefined" ? idMaquina : null;
      },
      version: 4,
    }),
    select: [
      "idPuntoRevisionNAuditoria",
      "idPuntuacion",
      "descripcion",
      "noConformidades",
    ],
  });

  var dataSource_Fotos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFoto",
      key: "idFoto",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;

        request.params.idNoConformidad = noConfSel.idNoConformidad;
      },
      version: 4,
    }),
    select: ["idNoConformidad", "img"],
  });

  var dataSource_Areas = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblArea",
      key: "idArea",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idArea", "denominacion"],
  });
  dataSource_Areas.load();

  var dataSource_Ambito = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAmbito",
      key: "idAmbito",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idAuditoria = auditoriaSel?.idAuditoria ?? null;

        request.params.idArea = typeof idArea !== "undefined" ? idArea : null;
      },
      version: 4,
    }),
  });
  dataSource_Ambito.load();

  var dataSource_SubAmbito = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblSubAmbito",
      key: "idSubAmbito",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idAuditoria = auditoriaSel?.idAuditoria ?? null;

        request.params.idAmbito =
          typeof idAmbito !== "undefined" ? idAmbito : null;
      },
      version: 4,
    }),
  });
  dataSource_SubAmbito.load();

  var dataSource_Puntuacion = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPuntuacion",
      key: "idPuntuacion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idPuntuacion", "denominacion"],
  });
  dataSource_Puntuacion.load();

  var dataSource_CategoriaMaquina = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCategoriaMaquina",
      key: "idCategoriaMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idCategoriaMaquina", "denominacion"],
  });
  dataSource_CategoriaMaquina.load();

  var dataSource_TipoMaquina = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoMaquina",
      key: "idTipoMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idTipoMaquina", "denominacion"],
  });
  dataSource_TipoMaquina.load();

  var dataSource_Maquina = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMaquina",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;

        request.params.idTipoMaquina =
          typeof idTipoMaquina !== "undefined" ? idTipoMaquina : null;
        request.params.idCategoriaMaquina =
          typeof idCategoriaMaquina !== "undefined" ? idCategoriaMaquina : null;
      },
      version: 4,
    }),
    select: ["idMaquina", "denominacion"],
  });
  dataSource_Maquina.load();

  var dataSource_NoConformidad = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblNoConformidad",
      key: "idNoConformidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (request.method === "get") {
          request.params.idPuntoRevisionNAuditoria =
            typeof infoPuntoDeRevision !== "undefined"
              ? infoPuntoDeRevision.idPuntoRevisionNAuditoria
              : null;
        }
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      inserted: function (item) {
        notify({
          message: getTrad("aviso_C_RegistroInsertado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    select: [
      "idNoConformidad",
      "idPuntoRevisionNAuditoria",
      "accionCorrectora",
      "idGrado",
      "responsable",
      "responsable2",
      "responsable3",
      "descripcion",
    ],
  });

  var dataSource_Grado = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblNoConformidad_Grado",
      key: "idGrado",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });
  dataSource_Grado.load();

  var dataSource_NecesitaRecambio = [
    {
      text: "SÍ",
      value: 0,
    },
    {
      text: "NO",
      value: 1,
    },
  ];

  //#endregion

  //#region TABPANEL CON LOS GRIDS PRINCIPALES
  $("#Auditorias #dxContainer").dxBox({
    direction: "col",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
    width: "100%",
    items: [
      {
        ratio: 1,
        template: function (e, index, item) {
          item.append(
            $("<div id='tabpanel-container' />")
              .addClass("no-header")
              .dxTabPanel({
                height: "100%",
                width: "100%",
                scrollByContent: true,
                scrollingEnabled: true,
                animationEnabled: true,
                items: [
                  {
                    title: "Auditorias",
                    template: function () {
                      return $("<div />").dxDataGrid({
                        //Datos
                        dataSource: dataSource_Auditorias,
                        remoteOperations: true,
                        //Propiedades
                        columnsAutoWidth: true,
                        height: "100%",
                        headerFilter: {
                          visible: true,
                        },
                        filterRow: {
                          visible: true,
                          applyFilter: "auto",
                        },
                        paging: {
                          pageSize: 100,
                        },
                        pager: {
                          showPageSizeSelector: true,
                          allowedPageSizes: [20, 50, 100],
                          showInfo: true,
                        },
                        columns: [
                          {
                            caption: " ",
                            width: "50px",
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (cellElement, cellInfo) {
                              var contenedor = $("<div/>").addClass(
                                "container_spanCentrado",
                              );
                              svg_edit
                                .clone()
                                .css("height", "25px")
                                .css("width", "25px")
                                .css("cursor", "pointer")
                                .appendTo(contenedor);

                              cellElement.on("click", function () {
                                auditoriaSel = cellInfo.data;
                                // hfAuditorias.Set('idAuditoria', auditoriaSel.idAuditoria);
                                // hfAuditorias.Set('tipoAuditoria', auditoriaSel.idTipoAuditoria);

                                dataSource_PuntoRevision.load();
                                controlDeGrids(auditoriaSel.idTipoAuditoria);
                                if (auditoriaSel.idTipoAuditoria !== 3) {
                                  $("#dxDataGrid_puntosDeRevisionMng")
                                    .dxDataGrid("instance")
                                    .refresh();
                                } else {
                                  $("#dgvPuntosDeRevisionTec")
                                    .dxDataGrid("instance")
                                    .refresh();
                                }
                              });

                              contenedor.appendTo(cellElement);
                            },
                          },
                          {
                            dataField: "fecha",
                            caption: getTrad("fecha"),
                            dataType: "date",
                            format: "dd/MM/yyyy",
                            width: "25%",
                            alignment: "center",
                            sortOrder: "desc",
                            allowHeaderFiltering: false,
                          },
                          {
                            dataField: "denoLavanderia",
                            caption: getTrad("denominacion"),
                            width: "50%",
                            minWidth: "150px",
                            alignment: "left",
                          },
                          {
                            dataField: "denoTipoAuditoria",
                            caption: getTrad("tipo"),
                            width: "25%",
                            minWidth: "100px",
                            alignment: "left",
                          },
                        ],
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                        rowAlternationEnabled: true,
                      });
                    },
                  },
                  {
                    title: "Puntos de revisión Mng",
                    template: function () {
                      return $(
                        "<div id='dxDataGrid_puntosDeRevisionMng'>",
                      ).dxDataGrid({
                        //Datos
                        dataSource: dataSource_PuntoRevision,
                        remoteOperations: true,
                        //Propiedades
                        editing: {
                          allowUpdating: true,
                          allowAdding: false,
                          mode: "cell",
                        },
                        columnsAutoWidth: true,
                        height: "100%",
                        headerFilter: {
                          visible: true,
                        },
                        filterRow: {
                          visible: true,
                          applyFilter: "auto",
                        },
                        paging: {
                          pageSize: 50,
                        },
                        pager: {
                          showPageSizeSelector: true,
                          allowedPageSizes: [20, 50, 100],
                          showInfo: true,
                        },
                        columns: [
                          { dataField: "noConformidades", visible: false },
                          {
                            dataField: "descripcion",
                            caption: "Puntos de revisión",
                            width: "84%",
                            minWidth: "150px",
                            alignment: "left",
                            allowEditing: false,
                            allowHeaderFiltering: false,
                          },
                          {
                            dataField: "idPuntuacion",
                            caption: getTrad("puntuacion"),
                            width: "11%",
                            minWidth: "100px",
                            alignment: "left",
                            allowEditing: true,
                            lookup: {
                              placeholder: "Todas",
                              dataSource: dataSource_Puntuacion.store(),
                              valueExpr: "idPuntuacion",
                              displayExpr: "denominacion",
                            },
                          },
                          {
                            caption: "No conformidad",
                            width: "120",
                            allowEditing: false,
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (cellElement, cellInfo) {
                              var contenedor = $("<div/>").addClass(
                                "container_spanCentrado",
                              );
                              svg_edit
                                .clone()
                                .css("height", "25px")
                                .css("width", "25px")
                                .css("cursor", "pointer")
                                .appendTo(contenedor);

                              if (cellInfo.data.noConformidades !== 0) {
                                svg_warning
                                  .clone()
                                  .css("height", "25px")
                                  .css("width", "25px")
                                  .css("cursor", "pointer")
                                  .appendTo(contenedor);
                              }

                              cellElement.on("click", function () {
                                infoPuntoDeRevision = cellInfo.data;
                                dataSource_NoConformidad
                                  .load()
                                  .done(function () {
                                    var dgvNoConformidad =
                                      $("#dgvNoConformidad").dxDataGrid(
                                        "instance",
                                      );
                                    $("#popup-noconformidad")
                                      .dxPopup("instance")
                                      .show();
                                    dgvNoConformidad.refresh();
                                    dgvNoConformidad.repaint();
                                  });
                              });

                              contenedor.appendTo(cellElement);
                            },
                          },
                        ],
                        //Eventos
                        onToolbarPreparing: function (e) {
                          e.toolbarOptions.items.unshift(
                            {
                              location: "before",
                              widget: "dxButton",
                              toolbar: "bottom",
                              options: {
                                text: getTrad("atras"),
                                type: "back",
                                onClick: function () {
                                  auditoriaSel = 0;
                                  //#region REINICIAR FILTROS
                                  idArea = null;
                                  idAmbito = null;
                                  idSubAmbito = null;
                                  $("#selectBoxArea")
                                    .dxSelectBox("instance")
                                    .option("value", null);
                                  $("#selectBoxAmbito")
                                    .dxSelectBox("instance")
                                    .option("value", null);
                                  $("#selectBoxSubAmbito")
                                    .dxSelectBox("instance")
                                    .option("value", null);
                                  //#endregion
                                  $("#dxDataGrid_puntosDeRevisionMng")
                                    .dxDataGrid("instance")
                                    .clearFilter();
                                  controlDeGrids(0);
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              showText: "inMenu",
                              locateInMenu: "auto",
                              options: {
                                text: getTrad("limpiarFiltro"),
                                icon: " icon_EliminarFiltros",
                                onClick: function () {
                                  $("#selectBoxArea")
                                    .dxSelectBox("instance")
                                    .option("value", null);
                                  $("#selectBoxAmbito")
                                    .dxSelectBox("instance")
                                    .option("value", null);
                                  $("#selectBoxSubAmbito")
                                    .dxSelectBox("instance")
                                    .option("value", null);
                                  e.component.clearFilter();
                                  notify({
                                    message: getTrad(
                                      "aviso_C_FiltroRestaurado",
                                    ),
                                    type: "success",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                  });
                                },
                              },
                            },
                            {
                              location: "before",
                              widget: "dxButton",
                              showText: "inMenu",
                              locateInMenu: "auto",
                              visible: false,
                              options: {
                                text: getTrad("imprimir"),
                                icon: " icon_Impresora",
                                onClick: function (button) {
                                  // hfAuditorias.Set('idAuditoria', auditoriaSel.idAuditoria);
                                  // hfAuditorias.Set('tipoAuditoria', auditoriaSel.idTipoAuditoria);
                                  // __doPostBack(null, 'impresionWord');
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              showText: "inMenu",
                              locateInMenu: "auto",
                              template: function (item) {
                                var container = $("<div></div>");

                                $("<div id='btnActualizar'>")
                                  .dxButton({
                                    icon: "refresh",
                                    text: getTrad("actualizar"),
                                    onClick: function (options) {
                                      $("#dxDataGrid_puntosDeRevisionMng")
                                        .dxDataGrid("instance")
                                        .refresh();
                                    },
                                  })
                                  .appendTo(container);

                                $("<div>")
                                  .dxTooltip({
                                    target: "#btnActualizar",
                                    height: "42px",
                                    showEvent: "dxhoverstart",
                                    hideEvent: "dxhoverend",
                                    position: "bottom",
                                    contentTemplate: function (contentElement) {
                                      contentElement.html(
                                        getTrad("actualizar"),
                                      );
                                    },
                                    animation: {
                                      show: {
                                        type: "pop",
                                        from: {
                                          scale: 0.1,
                                          opacity: 0,
                                        },
                                        to: {
                                          opacity: 1,
                                          scale: 1,
                                        },
                                      },
                                      hide: {
                                        type: "pop",
                                        from: {
                                          scale: 1,
                                          opacity: 1,
                                        },
                                        to: {
                                          opacity: 0,
                                          scale: 0.1,
                                        },
                                      },
                                    },
                                  })
                                  .appendTo(container);

                                return container;
                              },
                            },
                            {
                              location: "after",
                              widget: "dxSelectBox",
                              toolbar: "bottom",
                              options: {
                                dataSource: dataSource_Areas,
                                elementAttr: {
                                  id: "selectBoxArea",
                                },
                                width: "400px",
                                placeholder: "Todas las áreas", //getTrad('repartir'),
                                displayExpr: "denominacion",
                                valueExpr: "idArea",
                                showClearButton: true,
                                onValueChanged: function (e) {
                                  if (auditoriaSel !== 0) {
                                    var selectBoxAmbito =
                                      $("#selectBoxAmbito").dxSelectBox(
                                        "instance",
                                      );
                                    var selectBoxSubAmbito = $(
                                      "#selectBoxSubAmbito",
                                    ).dxSelectBox("instance");
                                    idArea = e.value;

                                    selectBoxAmbito.getDataSource().load();
                                    selectBoxSubAmbito.getDataSource().load();
                                    selectBoxAmbito.option("value", null);
                                    selectBoxSubAmbito.option("value", null);
                                    $("#dxDataGrid_puntosDeRevisionMng")
                                      .dxDataGrid("instance")
                                      .refresh();
                                  }
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxSelectBox",
                              toolbar: "bottom",
                              options: {
                                dataSource: dataSource_Ambito,
                                elementAttr: {
                                  id: "selectBoxAmbito",
                                },
                                width: "280px",
                                placeholder: "Todos los ámbitos", //getTrad('repartir'),
                                displayExpr: "denominacion",
                                valueExpr: "idAmbito",
                                showClearButton: true,
                                onValueChanged: function (e) {
                                  if (auditoriaSel !== 0) {
                                    var selectBoxSubAmbito = $(
                                      "#selectBoxSubAmbito",
                                    ).dxSelectBox("instance");
                                    idAmbito = e.value;

                                    selectBoxSubAmbito.getDataSource().load();
                                    selectBoxSubAmbito.option("value", null);
                                    $("#dxDataGrid_puntosDeRevisionMng")
                                      .dxDataGrid("instance")
                                      .refresh();
                                  }
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxSelectBox",
                              toolbar: "bottom",
                              options: {
                                dataSource: dataSource_SubAmbito,
                                elementAttr: {
                                  id: "selectBoxSubAmbito",
                                },
                                width: "230px",
                                placeholder: "Todos los subámbitos", //getTrad('repartir'),
                                displayExpr: "denominacion",
                                valueExpr: "idSubAmbito",
                                showClearButton: true,
                                onValueChanged: function (e) {
                                  if (auditoriaSel !== 0) {
                                    idSubAmbito = e.value;
                                    $("#dxDataGrid_puntosDeRevisionMng")
                                      .dxDataGrid("instance")
                                      .refresh();
                                  }
                                },
                              },
                            },
                          );

                          e.toolbarOptions.items.unshift();
                        },
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                        rowAlternationEnabled: true,
                      });
                    },
                  },
                  {
                    title: "Puntos de revisión Tec",
                    template: function () {
                      return $("<div id='dgvPuntosDeRevisionTec'>").dxDataGrid({
                        //Datos
                        dataSource: dataSource_PuntoRevision,
                        remoteOperations: true,
                        //Propiedades
                        editing: {
                          allowUpdating: true,
                          allowAdding: false,
                          mode: "cell",
                        },
                        columnsAutoWidth: true,
                        height: "100%",
                        headerFilter: {
                          visible: true,
                        },
                        filterRow: {
                          visible: true,
                          applyFilter: "auto",
                        },
                        columns: [
                          {
                            dataField: "descripcion",
                            caption: "Puntos de revisión", //getTrad('cliente'),
                            width: "84%",
                            minWidth: "150px",
                            alignment: "left",
                            allowEditing: false,
                            allowHeaderFiltering: false,
                          },
                          {
                            dataField: "idPuntuacion",
                            caption: getTrad("puntuacion"),
                            width: "11%",
                            minWidth: "100px",
                            alignment: "left",
                            allowEditing: true,
                            lookup: {
                              placeholder: getTrad("todas"),
                              dataSource: dataSource_Puntuacion.store(),
                              valueExpr: "idPuntuacion",
                              displayExpr: "denominacion",
                            },
                          },
                          {
                            caption: getTrad("noConf"),
                            allowEditing: false,
                            width: "120px",
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (cellElement, cellInfo) {
                              var contenedor = $("<div/>").addClass(
                                "container_spanCentrado",
                              );
                              svg_edit
                                .clone()
                                .css("height", "25px")
                                .css("width", "25px")
                                .css("cursor", "pointer")
                                .appendTo(contenedor);

                              cellElement.on("click", function () {
                                infoPuntoDeRevision = cellInfo.data;
                                dataSource_NoConformidad
                                  .load()
                                  .done(function () {
                                    $("#popup-noconformidad")
                                      .dxPopup("instance")
                                      .show();
                                    $("#dgvNoConformidad")
                                      .dxDataGrid("instance")
                                      .refresh();
                                    $("#dgvNoConformidad")
                                      .dxDataGrid("instance")
                                      .repaint();
                                  });
                              });

                              contenedor.appendTo(cellElement);
                            },
                          },
                        ],
                        //Eventos
                        onToolbarPreparing: function (e) {
                          e.toolbarOptions.items.unshift(
                            {
                              location: "before",
                              widget: "dxButton",
                              toolbar: "bottom",
                              options: {
                                text: getTrad("atras"),
                                type: "back",
                                onClick: function () {
                                  auditoriaSel = 0;
                                  //#region REINICIAR FILTROS
                                  idTipoMaquina = null;
                                  idCategoriaMaquina = null;
                                  idMaquina = null;
                                  $("#selectBoxCategoriaMaquina")
                                    .dxSelectBox("instance")
                                    .option("value", null);
                                  $("#selectBoxTipoMaquina")
                                    .dxSelectBox("instance")
                                    .option("value", null);
                                  $("#selectBoxMaquina")
                                    .dxSelectBox("instance")
                                    .option("value", null);
                                  //#endregion
                                  $("#dgvPuntosDeRevisionTec")
                                    .dxDataGrid("instance")
                                    .clearFilter();
                                  controlDeGrids(0);
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              locateInMenu: "auto",
                              showText: "inMenu",
                              template: function (item) {
                                var container = $("<div></div>");

                                $("<div id='btnLimpiarFiltro'>")
                                  .dxButton({
                                    icon: " icon_EliminarFiltros",
                                    text: getTrad("limpiarFiltro"),
                                    onClick: function (options) {
                                      $("#dgvPuntosDeRevisionTec")
                                        .dxDataGrid("instance")
                                        .clearFilter();
                                    },
                                  })
                                  .appendTo(container);

                                $("<div>")
                                  .dxTooltip({
                                    target: "#btnLimpiarFiltro",
                                    height: "42px",
                                    showEvent: "dxhoverstart",
                                    hideEvent: "dxhoverend",
                                    position: "bottom",
                                    contentTemplate: function (contentElement) {
                                      contentElement.html(
                                        getTrad("limpiarFiltro"),
                                      );
                                    },
                                    animation: {
                                      show: {
                                        type: "pop",
                                        from: {
                                          scale: 0.1,
                                          opacity: 0,
                                        },
                                        to: {
                                          opacity: 1,
                                          scale: 1,
                                        },
                                      },
                                      hide: {
                                        type: "pop",
                                        from: {
                                          scale: 1,
                                          opacity: 1,
                                        },
                                        to: {
                                          opacity: 0,
                                          scale: 0.1,
                                        },
                                      },
                                    },
                                  })
                                  .appendTo(container);

                                return container;
                              },
                            },
                            {
                              location: "before",
                              widget: "dxButton",
                              showText: "inMenu",
                              locateInMenu: "auto",
                              visible: false,
                              options: {
                                text: getTrad("imprimir"),
                                icon: " icon_Impresora",
                                onClick: function (button) {
                                  // hfAuditorias.Set('idAuditoria', auditoriaSel.idAuditoria);
                                  // hfAuditorias.Set('tipoAuditoria', auditoriaSel.idTipoAuditoria);
                                  // __doPostBack(null, 'impresionWord');
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              showText: "inMenu",
                              locateInMenu: "auto",
                              template: function (item) {
                                var container = $("<div></div>");

                                $("<div id='btnActualizar'>")
                                  .dxButton({
                                    icon: "refresh",
                                    text: getTrad("actualizar"),
                                    onClick: function (options) {
                                      $("#dgvPuntosDeRevisionTec")
                                        .dxDataGrid("instance")
                                        .refresh();
                                    },
                                  })
                                  .appendTo(container);

                                $("<div>")
                                  .dxTooltip({
                                    target: "#btnActualizar",
                                    height: "42px",
                                    showEvent: "dxhoverstart",
                                    hideEvent: "dxhoverend",
                                    position: "bottom",
                                    contentTemplate: function (contentElement) {
                                      contentElement.html(
                                        getTrad("actualizar"),
                                      );
                                    },
                                    animation: {
                                      show: {
                                        type: "pop",
                                        from: {
                                          scale: 0.1,
                                          opacity: 0,
                                        },
                                        to: {
                                          opacity: 1,
                                          scale: 1,
                                        },
                                      },
                                      hide: {
                                        type: "pop",
                                        from: {
                                          scale: 1,
                                          opacity: 1,
                                        },
                                        to: {
                                          opacity: 0,
                                          scale: 0.1,
                                        },
                                      },
                                    },
                                  })
                                  .appendTo(container);

                                return container;
                              },
                            },
                            {
                              location: "after",
                              widget: "dxSelectBox",
                              toolbar: "bottom",
                              options: {
                                dataSource: dataSource_TipoMaquina,
                                elementAttr: {
                                  id: "selectBoxTipoMaquina",
                                },
                                width: "250px",
                                placeholder: "Todos los tipos de máquina", //getTrad('repartir'),
                                displayExpr: "denominacion",
                                valueExpr: "idTipoMaquina",
                                showClearButton: true,
                                onValueChanged: function (e) {
                                  if (auditoriaSel !== 0) {
                                    if (e.value) {
                                      idTipoMaquina = e.value;
                                      $("#selectBoxCategoriaMaquina")
                                        .dxSelectBox("instance")
                                        .option("value", null);
                                    } else {
                                      idTipoMaquina = e.value;
                                    }
                                    $("#selectBoxMaquina")
                                      .dxSelectBox("instance")
                                      .option("value", null);
                                    $("#selectBoxMaquina")
                                      .dxSelectBox("instance")
                                      .getDataSource()
                                      .load();
                                    $("#dgvPuntosDeRevisionTec")
                                      .dxDataGrid("instance")
                                      .refresh();
                                  }
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxSelectBox",
                              toolbar: "bottom",
                              options: {
                                dataSource: dataSource_CategoriaMaquina,
                                elementAttr: {
                                  id: "selectBoxCategoriaMaquina",
                                },
                                width: "310px",
                                placeholder: "Todas las categorías de máquinas",
                                displayExpr: "denominacion",
                                valueExpr: "idCategoriaMaquina",
                                showClearButton: true,
                                onValueChanged: function (e) {
                                  if (auditoriaSel !== 0) {
                                    if (e.value) {
                                      idCategoriaMaquina = e.value;
                                      idTipoMaquina = null;
                                      $("#selectBoxTipoMaquina")
                                        .dxSelectBox("instance")
                                        .option("value", null);
                                    } else {
                                      idCategoriaMaquina = e.value;
                                    }
                                    $("#selectBoxMaquina")
                                      .dxSelectBox("instance")
                                      .option("value", null);
                                    $("#selectBoxMaquina")
                                      .dxSelectBox("instance")
                                      .getDataSource()
                                      .load();
                                    $("#dgvPuntosDeRevisionTec")
                                      .dxDataGrid("instance")
                                      .refresh();
                                  }
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxSelectBox",
                              toolbar: "bottom",
                              options: {
                                dataSource: dataSource_Maquina,
                                elementAttr: {
                                  id: "selectBoxMaquina",
                                },
                                width: "350px",
                                placeholder: "Todas las máquinas", //getTrad('repartir'),
                                displayExpr: "denominacion",
                                valueExpr: "idMaquina",
                                showClearButton: true,
                                onValueChanged: function (e) {
                                  if (auditoriaSel !== 0) {
                                    idMaquina = e.value;
                                    $("#dgvPuntosDeRevisionTec")
                                      .dxDataGrid("instance")
                                      .refresh();
                                  }
                                },
                              },
                            },
                          );

                          e.toolbarOptions.items.unshift();
                        },
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                        rowAlternationEnabled: true,
                      });
                    },
                  },
                ],
              }),
          );
        },
      },
    ],
  });

  //#endregion

  //#region POPUP

  $("#Auditorias #popup-noconformidad").dxPopup({
    showTitle: true,
    title: "No conformidades",
    deferRendering: false,
    showCloseButton: true,
    closeOnOutsideClick: false,
    width: "1000px",
    maxHeight: "665px",
    height: "100%",
    onShowing: function (e) {
      $("#textBoxPuntoDeRevision")
        .dxTextBox("instance")
        .option("value", infoPuntoDeRevision.descripcion);
      $("#tabpanel-noconformidad")
        .dxTabPanel("instance")
        .option("selectedIndex", 0);
    },
    onHidden: function (e) {
      $("#tabpanel-noconformidad")
        .dxTabPanel("instance")
        .option("selectedIndex", 0);
    },

    contentTemplate: function () {
      var container = $("<div id='tabpanel-noconformidad'>")
        .addClass("no-header")
        .dxTabPanel({
          height: "100%",
          width: "100%",
          scrollByContent: true,
          scrollingEnabled: true,
          animationEnabled: true,
          deferRendering: false,
          items: [
            {
              title: "Lista no conformidades",
              template: function () {
                var container = $("<div style='height:100%;'></div>");

                $(
                  "<div id='textBoxPuntoDeRevision' style='margin-bottom:10px;'>",
                )
                  .dxTextBox({
                    value: "",
                    readOnly: true,
                    width: "100%",
                  })
                  .appendTo(container);

                $("<div id='dgvNoConformidad'>")
                  .dxDataGrid({
                    //Datos
                    dataSource: dataSource_NoConformidad,
                    remoteOperations: true,
                    //Propiedades
                    columnsAutoWidth: true,
                    height: "90%",
                    columns: [
                      {
                        caption: " ",
                        width: "40px",
                        alignment: "center",
                        cssClass: "p-0",
                        cellTemplate: function (cellElement, cellInfo) {
                          var contenedor = $("<div/>").addClass(
                            "container_spanCentrado",
                          );
                          svg_edit
                            .clone()
                            .css("height", "25px")
                            .css("width", "25px")
                            .css("cursor", "pointer")
                            .attr("rowIndex", cellInfo.rowIndex)
                            .appendTo(contenedor);

                          cellElement.on("click", function () {
                            noConfSel = {
                              accionCorrectora: cellInfo.data.accionCorrectora,
                              descripcion: cellInfo.data.descripcion,
                              idEstado: cellInfo.data.idEstado,
                              idGrado: cellInfo.data.idGrado,
                              idNoConformidad: cellInfo.data.idNoConformidad,
                              idPuntoRevisionNAuditoria:
                                cellInfo.data.idPuntoRevisionNAuditoria,
                              necesitaRecambio: cellInfo.data.necesitaRecambio,
                              observaciones: cellInfo.data.observaciones,
                              responsable: cellInfo.data.responsable,
                              responsable2: cellInfo.data.responsable2,
                              responsable3: cellInfo.data.responsable3,
                            };
                            //Carga las fotos de la BD
                            dataSource_Fotos.load().done(function (data) {
                              $("#tabpanel-noconformidad")
                                .dxTabPanel("instance")
                                .option("selectedIndex", 1);
                              array_fotos = data;

                              var formNoConformidad =
                                $("#formNoConformidad").dxForm("instance");
                              var dxTileView_documentos = $(
                                "#dxTileView_documentos",
                              ).dxTileView("instance");

                              formNoConformidad.updateData(noConfSel);
                              dxTileView_documentos.option(
                                "dataSource",
                                array_fotos,
                              );
                              dxTileView_documentos.repaint();

                              $("#fuExaminar")
                                .dxFileUploader("instance")
                                .reset();
                              if (auditoriaSel.idTipoAuditoria === 3) {
                                formNoConformidad.itemOption(
                                  "necesitaRecambio",
                                  "visible",
                                  true,
                                );
                                formNoConformidad.itemOption(
                                  "idGrado",
                                  "colSpan",
                                  1,
                                );
                              } else {
                                formNoConformidad.itemOption(
                                  "necesitaRecambio",
                                  "visible",
                                  false,
                                );
                                formNoConformidad.itemOption(
                                  "idGrado",
                                  "colSpan",
                                  2,
                                );
                              }
                            });
                          });

                          contenedor.appendTo(cellElement);
                        },
                      },
                      {
                        dataField: "descripcion",
                        caption: "Descripción", //getTrad('cliente'),
                        width: "45%",
                        minWidth: "150px",
                        alignment: "left",
                      },
                      {
                        dataField: "accionCorrectora",
                        caption: "Accion correctora", //getTrad('cliente'),
                        width: "45%",
                        minWidth: "100px",
                        alignment: "left",
                      },
                      {
                        dataField: "idGrado",
                        caption: "Grado", //getTrad('cliente'),
                        minWidth: 120,
                        width: 240,
                        allowEditing: false,
                        lookup: {
                          dataSource: function (options) {
                            return {
                              store: dataSource_Grado.store(),
                            };
                          },
                          displayExpr: "denominacion",
                          valueExpr: "idGrado",
                        },
                      },
                      {
                        dataField: "responsable",
                        visible: false,
                      },
                      {
                        dataField: "responsable2",
                        visible: false,
                      },
                      {
                        dataField: "responsable3",
                        visible: false,
                      },
                      {
                        dataField: "descripcion",
                        visible: false,
                      },
                      {
                        caption: " ",
                        width: "50px",
                        alignment: "center",
                        cssClass: "p-0",
                        cellTemplate: function (cellElement, cellInfo) {
                          var contenedor = $("<div/>").addClass(
                            "container_spanCentrado",
                          );
                          svg_remove
                            .clone()

                            .css("height", "25px")
                            .css("width", "25px")
                            .css("cursor", "pointer")
                            .appendTo(contenedor);

                          cellElement.on("click", function () {
                            cellInfo.component.deleteRow(cellInfo.rowIndex);
                          });

                          contenedor.appendTo(cellElement);
                        },
                      },
                    ],
                    //Estilos
                    showColumnLines: false,
                    showRowLines: true,
                    rowAlternationEnabled: true,
                    onToolbarPreparing: function (e) {
                      e.toolbarOptions.items.unshift({
                        location: "after",
                        widget: "dxButton",
                        toolbar: "bottom",
                        options: {
                          width: null,
                          icon: "plus",
                          text: "Añadir no conformidad", //getTrad('repartir'),
                          type: "normal",
                          elementAttr: {
                            id: "btnAñadirNoConformidad",
                          },
                          onClick: function (e) {
                            $("#tabpanel-noconformidad")
                              .dxTabPanel("instance")
                              .option("selectedIndex", 1);

                            var formNoConformidad =
                              $("#formNoConformidad").dxForm("instance");
                            var dxTileView_documentos = $(
                              "#dxTileView_documentos",
                            ).dxTileView("instance");

                            formNoConformidad.resetValues();
                            formNoConformidad.option(
                              "formData.idPuntoRevisionNAuditoria",
                              infoPuntoDeRevision.idPuntoRevisionNAuditoria,
                            );
                            delete formNoConformidad.option("formData")
                              .idNoConformidad;
                            noConfSel = {};
                            array_fotos = [];

                            if (auditoriaSel.idTipoAuditoria === 3) {
                              formNoConformidad.itemOption(
                                "necesitaRecambio",
                                "visible",
                                true,
                              );
                              formNoConformidad.itemOption(
                                "idGrado",
                                "colSpan",
                                1,
                              );
                            } else {
                              formNoConformidad.itemOption(
                                "necesitaRecambio",
                                "visible",
                                false,
                              );
                              formNoConformidad.itemOption(
                                "idGrado",
                                "colSpan",
                                2,
                              );
                            }

                            dxTileView_documentos.option("dataSource", []);
                            dxTileView_documentos.repaint();

                            $("#fuExaminar").dxFileUploader("instance").reset();
                          },
                        },
                      });
                    },
                    //Eventos
                    onRowRemoved: function () {
                      $("#dxDataGrid_puntosDeRevisionMng")
                        .dxDataGrid("instance")
                        .refresh();
                      $("#dxDataGrid_puntosDeRevisionMng")
                        .dxDataGrid("instance")
                        .repaint();
                      notify({
                        message: "No conformidad eliminada con éxito", //getTrad('aviso_C_RegistroEliminado'),
                        type: "error",
                        displayTime: "1500",
                        closeOnClick: true,
                      });
                    },
                  })
                  .appendTo(container);

                return container;
              },
            },
            {
              title: "Información de la no conformidad",
              template: function () {
                var container = $("<div style='height:100%;'></div>");

                $("<div id='btnAtras' style='margin-bottom:10px;'>")
                  .dxButton({
                    type: "back",
                    onClick: function (options) {
                      $("#tabpanel-noconformidad")
                        .dxTabPanel("instance")
                        .option("selectedIndex", 0);
                    },
                  })
                  .appendTo(container);

                $("<div id='formNoConformidad' style='margin-bottom:20px;'>")
                  .dxForm({
                    formData: noConfSel,
                    labelLocation: "top",
                    colCount: 4,
                    items: [
                      {
                        dataField: "responsable",
                        colSpan: 2,
                        label: {
                          text: "Responsable",
                        },
                      },
                      {
                        colSpan: 2,
                        dataField: "responsable2",
                        label: {
                          text: "Responsable 2",
                        },
                      },
                      {
                        colSpan: 2,
                        dataField: "responsable3",
                        label: {
                          text: "Responsable 3",
                        },
                      },
                      {
                        colSpan: 1,
                        dataField: "idGrado",
                        editorType: "dxSelectBox",
                        editorOptions: {
                          dataSource: dataSource_Grado,
                          valueExpr: "idGrado",
                          displayExpr: "denominacion",
                        },
                        label: {
                          text: "Grado",
                        },
                        validationRules: [
                          {
                            type: "required",
                            message: getTrad("campoNecesario"),
                          },
                        ],
                      },
                      {
                        colSpan: 1,
                        dataField: "necesitaRecambio",
                        editorType: "dxSelectBox",
                        editorOptions: {
                          dataSource: dataSource_NecesitaRecambio,
                          valueExpr: "value",
                          displayExpr: "text",
                        },
                        label: {
                          text: "Necesita recambio",
                        },
                      },
                      {
                        colSpan: 2,
                        dataField: "descripcion",
                        editorType: "dxTextArea",
                        editorOptions: {
                          height: "90px",
                        },
                        label: {
                          text: "Descripción",
                        },
                        validationRules: [
                          {
                            type: "required",
                            message: getTrad("campoNecesario"),
                          },
                        ],
                      },
                      {
                        colSpan: 2,
                        dataField: "accionCorrectora",
                        editorType: "dxTextArea",
                        editorOptions: {
                          height: "90px",
                        },
                        label: {
                          text: "Acción correctora",
                        },
                      },
                    ],
                  })
                  .appendTo(container);

                $("<div id='dxTileView_documentos'>")
                  .css("max-width", "600px")
                  .dxTileView({
                    dataSource: array_fotos,
                    baseItemHeight: 100,
                    noDataText: getTrad("alert_NoArchivosAdjuntos"),
                    hoverStateEnabled: true,
                    baseItemWidth: 100,
                    direction: "horizontal",
                    height: "130",
                    width: "100%",
                    itemMargin: 10,
                    showScrollbar: true,
                    itemTemplate: function (itemData, itemIndex, itemElement) {
                      itemElement.addClass("contenedorImg");

                      itemElement.append(
                        $("<img />")
                          .attr("class", "image")
                          .attr(
                            "style",
                            "background-image: url('data:image/png;base64," +
                              itemData.img,
                          )
                          .on("click", function () {
                            imagenSeleccionada = itemIndex;
                            $("#dxPopup_galeria_documento")
                              .dxPopup("instance")
                              .show();
                          }),
                      );

                      itemElement.append(
                        $("<div class='dxToolbar_opciones'/>")
                          .css("position", "absolute")
                          .css("bottom", "-5px")
                          .css("right", "0px")
                          .dxToolbar({
                            items: [
                              {
                                location: "after",
                                template: function (
                                  dxToolbar_itemData,
                                  dxToolbar_itemIndex,
                                  dxToolbar_itemElement,
                                ) {
                                  dxToolbar_itemElement.append(
                                    $("<div/>")
                                      .addClass("container_spanCentrado")
                                      .addClass("dxToolbar_opcion")

                                      .css("height", "25px")
                                      .css("width", "25px")
                                      .append(
                                        svg_flecha
                                          .clone()
                                          .css("height", "15px")
                                          .css("width", "15px")
                                          .on("click", function () {
                                            var downloadLink =
                                              document.createElement("a");
                                            downloadLink.href =
                                              "data:image/png;base64," +
                                              itemData.img;
                                            downloadLink.download =
                                              "ImgAuditoria";
                                            downloadLink.click();
                                          }),
                                      ),
                                  );
                                },
                              },
                              {
                                location: "after",
                                template: function (
                                  dxToolbar_itemData,
                                  dxToolbar_itemIndex,
                                  dxToolbar_itemElement,
                                ) {
                                  dxToolbar_itemElement.append(
                                    $("<div/>")
                                      .addClass("container_spanCentrado")
                                      .addClass("dxToolbar_opcion")
                                      .append(
                                        svg_remove
                                          .clone()
                                          .css("height", "25px")
                                          .css("width", "25px")
                                          .on("click", function () {
                                            dxMensajePregunta(
                                              getTrad(
                                                "preg_RegistroEliminarCorto",
                                              ),
                                              [
                                                [
                                                  getTrad("aceptar"),
                                                  function () {
                                                    eliminarImagen(itemIndex);
                                                  },
                                                  "danger",
                                                ],
                                                [
                                                  getTrad("cancelar"),
                                                  function () {},
                                                ],
                                              ],
                                            );
                                          }),
                                      ),
                                  );
                                },
                              },
                            ],
                          }),
                      );
                    },
                  })
                  .appendTo(container);

                $("<div id='fuExaminar'>")
                  .dxFileUploader({
                    accept: "image/*", //Solo acepta imagenes
                    uploadMode: "instantly", //Subir fotos al instante
                    multiple: true, //Subida multiple
                    uploadMethod: "POST",
                    labelText: null,
                    showFileList: false,
                    uploadFailedMessage: getTrad("subidaFallida"),
                    selectButtonText: getTrad("examinar"),
                    onUploadStarted: function (e) {
                      var reader = new FileReader();

                      reader.onloadend = function () {
                        añadirImagen(reader.result.split(",")[1]); //Quita al String el tipo FileReader base64
                      };
                      reader.readAsDataURL(e.file);
                    },
                  })
                  .appendTo(container);

                $("<div id='btnGuardarPopUp' style='float:right;' >")
                  .dxButton({
                    type: "success",
                    text: "Guardar",
                    visible: true,
                    onClick: function (options) {
                      var formNoConformidad =
                        $("#formNoConformidad").dxForm("instance");
                      var result = formNoConformidad.validate();
                      if (result && result.isValid) {
                        var data = formNoConformidad.option("formData");
                        data.tblFoto = array_fotos;
                        if (typeof data.idNoConformidad !== "undefined") {
                          // UPDATE
                          dataSource_NoConformidad
                            .store()
                            .update(data.idNoConformidad, data)
                            .done(function (data) {
                              notify({
                                message: getTrad("aviso_C_RegistroInsertado"),
                                type: "success",
                                displayTime: "1500",
                                closeOnClick: true,
                              });
                              $("#dgvNoConformidad")
                                .dxDataGrid("instance")
                                .refresh();
                              $("#tabpanel-noconformidad")
                                .dxTabPanel("instance")
                                .option("selectedIndex", 0);
                            });
                        } else {
                          $.each(data.tblFoto, function (index, item) {
                            item.idNoConformidad = -1;
                          });
                          // INSERT
                          dataSource_NoConformidad
                            .store()
                            .insert(data)
                            .done(function (data, idNoConformidad) {
                              notify({
                                message: getTrad("aviso_C_RegistroInsertado"),
                                type: "success",
                                displayTime: "1500",
                                closeOnClick: true,
                              });
                              $("#dgvNoConformidad")
                                .dxDataGrid("instance")
                                .refresh();
                              $("#tabpanel-noconformidad")
                                .dxTabPanel("instance")
                                .option("selectedIndex", 0);
                              $("#dxTileView_documentos")
                                .dxTileView("instance")
                                .repaint();
                              //$("#dxDataGrid_puntosDeRevisionMng").dxDataGrid("instance").refresh();
                            });
                        }
                      }
                    },
                  })
                  .appendTo(container);

                return container;
              },
            },
          ],
        });

      return container;
    },
  });

  function añadirImagen(imgBase64) {
    $("#fuExaminar").dxFileUploader("instance").reset();
    array_fotos.push({
      idNoConformidad:
        typeof noConfSel.idNoConformidad !== "undefined"
          ? noConfSel.idNoConformidad
          : null,
      img: imgBase64,
    });
    $("#dxTileView_documentos")
      .dxTileView("instance")
      .option("dataSource", array_fotos);
  }

  function eliminarImagen(itemIndex) {
    array_fotos.splice(itemIndex, 1);
    $("#dxTileView_documentos")
      .dxTileView("instance")
      .option("dataSource", array_fotos);
    $("#dxTileView_documentos").dxTileView("instance").repaint();
  }

  //#endregion

  //#region POPUP GALERIA

  $("#Auditorias #dxPopup_galeria_documento").dxPopup({
    showTitle: true,
    title: "Galería de imagenes",
    showCloseButton: true,
    closeOnOutsideClick: true,
    width: "700px",
    height: "500px",
    onShowing: function (e) {
      $("#dxGallery_documento")
        .dxGallery("instance")
        .option("dataSource", array_fotos);
      $("#dxGallery_documento")
        .dxGallery("instance")
        .option("selectedIndex", imagenSeleccionada);
    },
    contentTemplate: function () {
      var container = $("<div></div>");

      $("<div id='dxGallery_documento'>")
        .dxGallery({
          dataSource: array_fotos,
          showIndicator: true,
          showNavButtons: true,
          loop: true,
          height: "400",
          width: "100%",
          itemTemplate: function (itemData, itemIndex, itemElement) {
            itemElement.css("cursor", "pointer");
            itemElement.append(
              $("<img />")
                .addClass("image")
                .attr(
                  "style",
                  "background-image: url(data:image/png;base64," + itemData.img,
                ),
            );
          },
        })
        .appendTo(container);

      return container;
    },
  });

  //#endregion

  //#region CONTROL DE LA VISIBILIDAD DE LOS GRIDS

  function controlDeGrids(idTipoAuditoria) {
    if (idTipoAuditoria === 1) {
      $("#tit_Auditoria").text("AUDITORÍAS - Puntos de revisión management");
      $("#tabpanel-container")
        .dxTabPanel("instance")
        .option("selectedIndex", 1);
    } else if (idTipoAuditoria === 3) {
      $("#tit_Auditoria").text("AUDITORÍAS - Puntos de revisión técnica");
      $("#tabpanel-container")
        .dxTabPanel("instance")
        .option("selectedIndex", 2);
    } else {
      $("#tit_Auditoria").text("AUDITORÍAS");
      $("#tabpanel-container")
        .dxTabPanel("instance")
        .option("selectedIndex", 0);
    }
  }

  //#endregion

  deferred.resolve();
  return deferred.promise();
}
