import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { loadPanelActions } from "actions";
import $ from "jquery";

import "./DataGrid_Nominas.scss";
import { connectionConstants } from "constants";
import { formatNumber, getTrad, authHeader, formatDate_noTime_parameter } from "helpers";

import ODataContext from "devextreme/data/odata/context";

import DataGrid, { Column, Export, GroupItem, Paging, Sorting, Summary, TotalItem } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { Button, DateBox, Popup, Tooltip } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/popup";
import query from "devextreme/data/query";

const columnNames_historicoAsientoNomina = [
    "sueldo",
    "diasPropina",
    "diasFeriados",
    "horasNocturnas",
    "horasExtras35",
    "primaVacacional",
    "gratificacion",
    "otrosIngresos",
    "salarioRetroactivo",
    "incentivos",
    "subsidPorEnferm",
    "subsidioPorMaternidad",
    "ayudaPorNacimiento",
    "ayudaPorMuerte",
    "reembolsoOtrosDescuentos",
    "saldoAFavorISR",
    "reembolsoISR",
    "totalIngresos",
    "impSobreLaRenta",
    "seguroMedicoPrivado",
    "descuentoDeLicenciaMedica",
    "sindicato",
    "anticipoNomina",
    "descAFP",
    "descSFS",
    "dependAdicionalesSFS",
    "otrosDescuentos",
    "ahorroCoop",
    "prestamoCoop",
    "ordenDeCompra",
    "infotep",
    "descuentosOtros",
    "totalDescuentos",
    "neto",
];

class DataGrid_ContabilidadNominas_RD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            centro: null,
            GenerarAsientosNominasSAP_RD_then: null,

            tooltipData: {
                target: null,
                content: <Fragment />,
            },
        };

        this.dxDataGrid_REF = React.createRef();
    }

    get dxDataGrid() {
        return this.dxDataGrid_REF.current.instance;
    }

    // #region Properties

    summaryItem = {
        summaryType: "sum",
        displayFormat: "{0}",
        valueFormat: this.props.currency_format,
        alignByColumn: true,
    };
    dateInSummary = {
        summaryType: "custom",
        alignByColumn: true,
    };
    columnItem = {
        format: this.props.currency_format,
        dataType: "number",
        alignment: "center",
    };

    jButtonContabilizar = $(`<div class="d-flex flex-row botonGenerarSAP position-absolute-0 text-secondary">
        <i class="dx-icon dx-icon-export"></i>
        ${getTrad("contabilizar")}
    </div>`);
    jButtonContabilizado =
        $(`<div class="d-flex flex-row botonGenerarSAP dx-state-disabled position-absolute-0 text-success">
        <i class="dx-icon dx-icon-exportselected"></i>
        ${getTrad("contabilizado")}
    </div>`);

    // #endregion

    //#region Lifecycle

    shouldComponentUpdate(nextProps, nextState) {
        const { fechaSel, fortnight } = this.props;
        let willUpdate = false;
        if (fechaSel !== nextProps.fechaSel || fortnight !== nextProps.fortnight) {
            if (nextProps.fortnight !== null) {
                if (nextProps.fechaSel !== null) {
                    willUpdate = true;
                }
            }
        } else if (this.state.popupVisible !== nextState.popupVisible) {
            willUpdate = true;
        } else if (nextState.fechaAsiento !== this.state.fechaAsiento) {
            willUpdate = true;
        }
        
        return willUpdate;
        // return ((fechaSel !== nextProps.fechaSel || fortnight !== nextProps.fortnight) && nextProps.fortnight !== null && nextProps.fechaSel !== null) || this.state.popupVisible !== nextState.popupVisible;
    }

    //#endregion

    // #region Context

    context_asinetosNominas = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/Contabilidad/AsientosNominas",
        entities: {
            GenerarAsientosNominasSAP_RD: {},
            GuardarHistoricoAsientoNomina_RD: {}
        },
        beforeSend: (request) => this.context_asinetosNominas_beforeSend(request),
    });

    context_asinetosNominas_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { centro, fechaAsiento } = this.state;
        const { fechaSel } = this.props;

        request.params.fechaDesde = formatDate_noTime_parameter(fechaSel.startDate);
        request.params.fechaHasta = formatDate_noTime_parameter(fechaSel.endDate);
        request.params.fortnight = this.props.fortnight === "1Q" ? 1 : 2;
        
        if (request.url.includes("GenerarAsientosNominasSAP_RD")){
            request.params.fechaAsiento = formatDate_noTime_parameter(fechaAsiento);
        }

        if (centro.tipoCentro === "CentroCoste") {
            request.params.idCentroCoste = centro.idCentro;
        } else if (centro.tipoCentro === "ElementoPEP") {
            request.params.idElementoPEP = centro.idCentro;
        }
    };

    // #endregion

    render() {
        const { dataSource, fechaSel } = this.props;
        const { tooltipData, popupVisible, fechaAsiento } = this.state;

        return (
            <>
                <DataGrid
                    ref={this.dxDataGrid_REF}
                    className="DataGrid_Nominas_RD position-absolute-0"
                    dataSource={dataSource}
                    remoteOperations={false}
                    showColumnLines={false}
                    showRowLines={true}
                    repaintChangesOnly={true}
                    onCellPrepared={this.dxDataGrid_onCellPrepared}
                    onCellClick={this.dxDataGrid_onCellClick}
                >
                    <Sorting mode={"multiple"} />
                    <Paging enabled={false} />
                    <Export enabled={false} fileName={"AsientoNominas"} />

                    <Column dataField="centro" groupIndex={0} autoExpandGroup={false} width={300} />
                    <Column
                        dataField="nombreCompleto"
                        caption={getTrad("persona")}
                        dataType={"string"}
                        sortOrder={"asc"}
                        width={350}
                        fixed={true}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("inicioPeriodo")}
                        dataField={"inicioPeriodo"}
                        dataType={"datetime"}
                        format={"dd/MM/yyyy"}
                        sortOrder={"desc"}
                        alignment={"center"}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("finPeriodo")}
                        dataField={"finPeriodo"}
                        dataType={"datetime"}
                        format={"dd/MM/yyyy"}
                        sortOrder={"desc"}
                        alignment={"center"}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("sueldo")}
                        dataField={"sueldo"}
                        alignment={"center"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        caption={getTrad("diasPropina")}
                        dataField={"diasPropina"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        caption={getTrad("diasFeriados")}
                        dataField={"diasFeriados"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        caption={getTrad("horasNocturnas")}
                        dataField={"horasNocturnas"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("horasExtras35")}
                        dataField={"horasExtras35"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("primaVacacional")}
                        dataField={"primaVacacional"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("gratificacion")}
                        dataField={"gratificacion"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        caption={getTrad("otrosIngresos")}
                        dataField={"otrosIngresos"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        ion={getTrad("salarioRetroactivo")}
                        dataField={"salarioRetroactivo"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        caption={getTrad("incentivos")}
                        dataField={"incentivos"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("subsidPorEnferm")}
                        dataField={"subsidPorEnferm"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={150}
                        caption={getTrad("subsidioPorMaternidad")}
                        dataField={"subsidioPorMaternidad"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        caption={getTrad("ayudaPorNacimiento")}
                        dataField={"ayudaPorNacimiento"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        caption={getTrad("ayudaPorMuerte")}
                        dataField={"ayudaPorMuerte"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        caption={getTrad("reembolsoOtrosDescuentos")}
                        dataField={"reembolsoOtrosDescuentos"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={100}
                        ion={getTrad("saldoAFavorISR")}
                        dataField={"saldoAFavorISR"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("reembolsoISR")}
                        dataField={"reembolsoISR"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("totalIngresos")}
                        dataField={"totalIngresos"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("impSobreLaRenta")}
                        dataField={"impSobreLaRenta"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("seguroMedicoPrivado")}
                        dataField={"seguroMedicoPrivado"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("descuentoDeLicenciaMedica")}
                        dataField={"descuentoDeLicenciaMedica"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("sindicato")}
                        dataField={"sindicato"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("anticipoNomina")}
                        dataField={"anticipoNomina"}
                        {...this.columnItem}
                    />
                    <Column minWidth={125} caption={getTrad("descAFP")} dataField={"descAFP"} {...this.columnItem} />
                    <Column minWidth={125} caption={getTrad("descSFS")} dataField={"descSFS"} {...this.columnItem} />
                    <Column
                        minWidth={125}
                        caption={getTrad("dependAdicionalesSFS")}
                        dataField={"dependAdicionalesSFS"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("otrosDescuentos")}
                        dataField={"otrosDescuentos"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("ahorroCoop")}
                        dataField={"ahorroCoop"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("prestamoCoop")}
                        dataField={"prestamoCoop"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("ordenDeCompra")}
                        dataField={"ordenDeCompra"}
                        {...this.columnItem}
                    />
                    <Column minWidth={125} caption={getTrad("infotep")} dataField={"infotep"} {...this.columnItem} />
                    <Column
                        minWidth={125}
                        caption={getTrad("descuentosOtros")}
                        dataField={"descuentosOtros"}
                        {...this.columnItem}
                    />
                    <Column
                        minWidth={125}
                        caption={getTrad("totalDescuentos")}
                        dataField={"totalDescuentos"}
                        {...this.columnItem}
                    />
                    <Column minWidth={125} caption={getTrad("neto")} dataField={"neto"} {...this.columnItem} />
                    <Column
                        minWidth={125}
                        caption={"contabilizado" /* getTrad("contabilizado") */}
                        dataField={"contabilizado"}
                        visible={false}
                        showInColumnChooser={false}
                    />

                    {/* <Column
                        dataField="descuentos"
                        caption={getTrad("descuentosSalariales")}
                        cellComponent={this.dxDataGrid_cellComponent_descuentos}
                        calculateCellValue={this.dxDataGrid_calculateCellValue_descuentos}
                        {...this.columnItem}
                        visible={vista === false}
                    /> */}

                    <Column
                        name="guardar"
                        caption={""}
                        width={90}
                        allowSorting={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowHeaderFiltering={false}
                        fixedPosition={"right"}
                        fixed
                    />

                    <Column
                        dataField="contabilizar"
                        caption={""}
                        width={120}
                        allowSorting={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowHeaderFiltering={false}
                        fixedPosition={"right"}
                        fixed={true}
                    />

                    <Summary
                        calculateCustomSummary={() => {
                            return null;
                        }}
                    >
                        <GroupItem {...this.summaryItem} column="fechaCobro" displayFormat=" " />

                        <GroupItem {...this.summaryItem} column="sueldo" />
                        <TotalItem {...this.summaryItem} column="sueldo" />
                        <GroupItem {...this.summaryItem} column="diasPropina" />
                        <TotalItem {...this.summaryItem} column="diasPropina" />
                        <GroupItem {...this.dateInSummary} column="inicioPeriodo" />
                        <TotalItem {...this.dateInSummary} column="inicioPeriodo" />
                        <GroupItem {...this.dateInSummary} column="finPeriodo" />
                        <TotalItem {...this.dateInSummary} column="finPeriodo" />
                        <GroupItem {...this.summaryItem} column="diasFeriados" />
                        <TotalItem {...this.summaryItem} column="diasFeriados" />
                        <GroupItem {...this.summaryItem} column="horasNocturnas" />
                        <TotalItem {...this.summaryItem} column="horasNocturnas" />
                        <GroupItem {...this.summaryItem} column="horasExtras35" />
                        <TotalItem {...this.summaryItem} column="horasExtras35" />
                        <GroupItem {...this.summaryItem} column="primaVacacional" />
                        <TotalItem {...this.summaryItem} column="primaVacacional" />
                        <GroupItem {...this.summaryItem} column="gratificacion" />
                        <TotalItem {...this.summaryItem} column="gratificacion" />
                        <GroupItem {...this.summaryItem} column="otrosIngresos" />
                        <TotalItem {...this.summaryItem} column="otrosIngresos" />
                        <GroupItem {...this.summaryItem} column="salarioRetroactivo" />
                        <TotalItem {...this.summaryItem} column="salarioRetroactivo" />
                        <GroupItem {...this.summaryItem} column="incentivos" />
                        <TotalItem {...this.summaryItem} column="incentivos" />
                        <GroupItem {...this.summaryItem} column="subsidPorEnferm" />
                        <TotalItem {...this.summaryItem} column="subsidPorEnferm" />
                        <GroupItem {...this.summaryItem} column="subsidioPorMaternidad" />
                        <TotalItem {...this.summaryItem} column="subsidioPorMaternidad" />
                        <GroupItem {...this.summaryItem} column="ayudaPorNacimiento" />
                        <TotalItem {...this.summaryItem} column="ayudaPorNacimiento" />
                        <GroupItem {...this.summaryItem} column="ayudaPorMuerte" />
                        <TotalItem {...this.summaryItem} column="ayudaPorMuerte" />
                        <GroupItem {...this.summaryItem} column="reembolsoOtrosDescuentos" />
                        <TotalItem {...this.summaryItem} column="reembolsoOtrosDescuentos" />
                        <GroupItem {...this.summaryItem} column="saldoAFavorISR" />
                        <TotalItem {...this.summaryItem} column="saldoAFavorISR" />
                        <GroupItem {...this.summaryItem} column="reembolsoISR" />
                        <TotalItem {...this.summaryItem} column="reembolsoISR" />
                        <GroupItem {...this.summaryItem} column="totalIngresos" />
                        <TotalItem {...this.summaryItem} column="totalIngresos" />
                        <GroupItem {...this.summaryItem} column="impSobreLaRenta" />
                        <TotalItem {...this.summaryItem} column="impSobreLaRenta" />
                        <GroupItem {...this.summaryItem} column="seguroMedicoPrivado" />
                        <TotalItem {...this.summaryItem} column="seguroMedicoPrivado" />
                        <GroupItem {...this.summaryItem} column="descuentoDeLicenciaMedica" />
                        <TotalItem {...this.summaryItem} column="descuentoDeLicenciaMedica" />
                        <GroupItem {...this.summaryItem} column="sindicato" />
                        <TotalItem {...this.summaryItem} column="sindicato" />
                        <GroupItem {...this.summaryItem} column="anticipoNomina" />
                        <TotalItem {...this.summaryItem} column="anticipoNomina" />
                        <GroupItem {...this.summaryItem} column="descAFP" />
                        <TotalItem {...this.summaryItem} column="descAFP" />
                        <GroupItem {...this.summaryItem} column="descSFS" />
                        <TotalItem {...this.summaryItem} column="descSFS" />
                        <GroupItem {...this.summaryItem} column="dependAdicionalesSFS" />
                        <TotalItem {...this.summaryItem} column="dependAdicionalesSFS" />
                        <GroupItem {...this.summaryItem} column="otrosDescuentos" />
                        <TotalItem {...this.summaryItem} column="otrosDescuentos" />
                        <GroupItem {...this.summaryItem} column="ahorroCoop" />
                        <TotalItem {...this.summaryItem} column="ahorroCoop" />
                        <GroupItem {...this.summaryItem} column="prestamoCoop" />
                        <TotalItem {...this.summaryItem} column="prestamoCoop" />
                        <GroupItem {...this.summaryItem} column="ordenDeCompra" />
                        <TotalItem {...this.summaryItem} column="ordenDeCompra" />
                        <GroupItem {...this.summaryItem} column="infotep" />
                        <TotalItem {...this.summaryItem} column="infotep" />
                        <GroupItem {...this.summaryItem} column="descuentosOtros" />
                        <TotalItem {...this.summaryItem} column="descuentosOtros" />
                        <GroupItem {...this.summaryItem} column="totalDescuentos" />
                        <TotalItem {...this.summaryItem} column="totalDescuentos" />
                        <GroupItem {...this.summaryItem} column="neto" />
                        <TotalItem {...this.summaryItem} column="neto" />
                    </Summary>
                </DataGrid>
                <Tooltip animation={null} target={tooltipData.target} visible={tooltipData.target != null}>
                    <div className="font-size-xxs">{tooltipData.content}</div>
                </Tooltip>
                <Popup
                    visible={popupVisible}
                    title={getTrad("contabilizar")}
                    onHiding={this.dxPopup_onHiding}
                    onHidden={this.dxPopup_onHidden}
                    dragEnabled={false}
                    width={300}
                    height={225}
                >
                    <div className="dx-form">
                        <span className="dx-field-item-label-text">{getTrad("establecerFechaContable")}:</span>
                        <DateBox
                            displayFormat={"dd/MM/yyyy"}
                            stylingMode="underlined"
                            openOnFieldClick
                            acceptCustomValue={false}
                            value={fechaAsiento}
                            onValueChanged={this.dxDateBox_onValueChanged}
                            min={fechaSel.startDate}
                            max={fechaSel.endDate}
                        />
                    </div>
                    <ToolbarItem location={"after"} toolbar={"bottom"}>
                        <Button
                            disabled={fechaAsiento == null}
                            text={getTrad("crear")}
                            onClick={this.dxToolbarItem_onClick}
                            type="success"
                        />
                    </ToolbarItem>
                    <ToolbarItem location={"after"} toolbar={"bottom"}>
                        <Button text={getTrad("cancelar")} onClick={this.dxPopup_onHiding} />
                    </ToolbarItem>
                </Popup>
            </>
        );
    }

    // #region dxDataGrid

    dxDataGrid_onCellPrepared = (e) => {
        const { column, rowType, value, cellElement, data } = e;

        if (column?.name === "guardar" && rowType === "group") {
            const contabilizado = (data.items ?? data.collapsedItems).every((item) => item.contabilizado === true);

            if (contabilizado) return;

            cellElement.first().addClass("sticky-grid-row-header-left p-0");

            let element = $(cellElement[0]);
            element.css("padding", "0");
            element.addClass("position-relative");
            element.empty().append(
                $(`<div class="d-flex flex-row botonGenerarSAP position-absolute-0 text-secondary">
                    <i class="dx-icon dx-icon-save"></i>
                    ${getTrad("guardar")}
                </div>`)
            );
        }

        if (column.dataField === "contabilizar" && rowType === "group" && e.data.key !== "Sin centro") {
            const items = e.data.items ?? e.data.collapsedItems;
            const contabilizado = items.every((item) => item.contabilizado === true);

            let element = $(cellElement[0]);
            element.css("padding", "0");
            element.addClass("position-relative");
            if (contabilizado) {
                element.append(this.jButtonContabilizado.clone(false));
            } else {
                element.append(this.jButtonContabilizar.clone(false));
            }
        }
        if (column.dataField === "centro" && rowType === "group" && typeof value === "string") {
            cellElement.first().addClass("sticky-grid-row-header-right p-0");
            let div = $("<div>");
            div.addClass("p-2 position-absolute-0");
            div.append(/* column.dataField.charAt(0).toUpperCase() + column.dataField.slice(1) + ": " + */ e.text);
            cellElement.empty().append(div);
        }

        if (
            ["group", "data"].includes(rowType) &&
            columnNames_historicoAsientoNomina.includes(column?.dataField ?? column?.name)
        ) {
            this.toolTip(e);
        }
    };

    toolTip = ({ column, summaryItems, rowType, value, cellElement, data }) => {
        const { tblHistoricoAsientoNomina } = this.props;

        const isGroup = rowType === "group";
        const columnName = column.dataField ?? column.name;
        const idCentro = (data?.collapsedItems ?? data?.items)?.[0]?.idCentro;
        const tipoCentro = (data?.collapsedItems ?? data?.items)?.[0]?.tipoCentro;
        const valueActual =
            Math.round((isGroup ? summaryItems[0].value ?? 0 : data[columnName] ?? value ?? 0) * 100) / 100;

        let dataSource = query(tblHistoricoAsientoNomina)
            .filter((han) =>
                isGroup
                    ? idCentro == (tipoCentro === "CentroCoste" ? han.idAdmCentroCoste : han.idAdmElementoPEP)
                    : han.idNomina_RD === data.idNomina_RD
            )
            .sortBy((han) => han.fecha, true)
            .groupBy((han) =>
                isGroup
                    ? [han.fecha, han.fechaContabilizado, han.idAdmElementoPEP, han.idAdmCentroCoste]
                    : [han.fecha, han.fechaContabilizado, han.idNomina_RD]
            )
            .select((han) => ({
                actual: Math.round(han.items.reduce((acc, han) => acc + (han[columnName] ?? 0), 0) * 100) / 100,
                fecha: han.key[0],
                fechaContabilizado: han.key[1] ?? getTrad("guardado"),
            }))
            .toArray();

        if (dataSource.length === 0) {
            let fechaHistorico = query(tblHistoricoAsientoNomina)
            .filter(han => han.idAdmElementoPEP === data.idElementoPEP && han.idAdmCentroCoste === data.idCentroCoste)
            .groupBy(han => [han.fecha, han.fechaContabilizado, han.idAdmElementoPEP, han.idAdmCentroCoste])
            .select(han => ({
                anterior: "-",
                actual: 0,
                diferencia: "-",
                fecha: han.key[0],
                fechaContabilizado: han.key[1],
            }))
            .toArray();

            if (fechaHistorico.length > 0) {
                dataSource = fechaHistorico
            } else {
                let contabilizado = (isGroup ? data.items?.[0]?.contabilizado : data?.contabilizado) ?? false;
        
                dataSource = contabilizado
                    ? [{
                        anterior: "-",
                        actual: 0,
                        diferencia: "-",
                        fecha: getTrad("sinEspecificar"),
                        fechaContabilizado: getTrad("sinEspecificar")
                    }]
                    : [];
            }
        }

        dataSource = dataSource.map((han, index) => {
            const anterior = index === dataSource.length - 1 ? "-" : dataSource[index + 1].actual;

            return {
                ...han,
                anterior,
                diferencia: typeof anterior === "number" ? han.actual - anterior : "-",
            };
        });

        if (dataSource.length > 0) {
            const lastValueSended = Math.round(dataSource[0].actual * 100) / 100;

            if (lastValueSended < valueActual) {
                cellElement.addClass("text-success font-weight-bold");
                cellElement.children().addClass("text-success font-weight-bold");
            } else if (lastValueSended > valueActual) {
                cellElement.addClass("text-danger font-weight-bold");
                cellElement.children().addClass("text-danger font-weight-bold");
            }

            cellElement.append(
                $("<div />").dxTooltip({
                    target: cellElement,
                    position: "top",
                    showEvent: {
                        name: "dxhoverstart",
                        delay: 200,
                    },
                    hideEvent: "dxhoverend",
                    contentTemplate: (contentElement) => {
                        const tooltipContainer = $('<div style="max-width: 600px;"></div>');
                        contentElement.append(tooltipContainer);
                        if (dataSource.length > 0) {
                            const ultimoAsiento = dataSource[0];

                            const dataGridProps = (title) => ({
                                showRowLines: true,
                                columnAutoWidth: true,
                                hoverStateEnabled: true,
                                rowAlternationEnabled: true,
                                showColumnLines: false,
                                sorting: { mode: "none" },
                                onToolbarPreparing: (e) => {
                                    e.toolbarOptions.items.unshift({
                                        location: "before",
                                        text: getTrad(title),
                                    });
                                },
                                onCellPrepared: (e) => {
                                    if (e.rowType === "data" && e.column.dataField === "diferencia") {
                                        if (e.value > 0) e.cellElement.addClass("text-success");
                                        else if (e.value < 0) e.cellElement.addClass("text-danger");
                                    }
                                },
                            });

                            const numberColumn = (field) => ({
                                dataField: field,
                                caption: getTrad(field),
                                dataType: "number",
                                alignment: "center",
                                format: this.currency_format,
                            });

                            const columns = {
                                fecha: {
                                    dataField: "fecha",
                                    caption: getTrad("fecha"),
                                    dataType: "date",
                                    format: "dd/MM/yyyy - HH:mm",
                                    sortOrder: "desc",
                                },
                                fechaContabilizado: {
                                    dataField: "fechaContabilizado",
                                    caption: getTrad("fechaContabilizado"),
                                    dataType: "date",
                                    format: "dd/MM/yyyy",
                                },
                            };

                            const dataSource_ultimoAsiento = [
                                {
                                    fecha: ultimoAsiento.fecha,
                                    fechaContabilizado: ultimoAsiento.fechaContabilizado,
                                    ultimoAsiento: ultimoAsiento.actual,
                                    actual: valueActual,
                                    diferencia: valueActual - ultimoAsiento.actual,
                                },
                            ];

                            tooltipContainer.append(
                                $("<div />").dxDataGrid({
                                    dataSource: dataSource_ultimoAsiento,
                                    ...dataGridProps("ultimoAsiento"),
                                    columns: [
                                        columns.fecha,
                                        columns.fechaContabilizado,
                                        { ...numberColumn("ultimoAsiento"), width: 120 },
                                        { ...numberColumn("actual"), width: 90 },
                                        { ...numberColumn("diferencia"), width: 90 },
                                    ],
                                })
                            );

                            tooltipContainer.append(
                                $("<div />").dxDataGrid({
                                    dataSource,
                                    ...dataGridProps("historicoAsientos"),
                                    columns: [
                                        columns.fecha,
                                        columns.fechaContabilizado,
                                        { ...numberColumn("anterior"), width: 120 },
                                        { ...numberColumn("actual"), width: 90 },
                                        { ...numberColumn("diferencia"), width: 90 },
                                    ],
                                })
                            );
                        }
                    },
                })
            );
        }
    };

    
    dxDataGrid_onCellClick = (e) => {
        const { column, event, data, component, columnIndex, text, rowType, row, key } = e;

        if (column && column.dataField === "contabilizar" && rowType === "group") {
            let element = $(event.target);
            const { load_tblHistoricoAsientoNomina } = this.props;

            const contabilizado = (data?.collapsedItems ?? data?.items).every((item) => item.contabilizado === true);
            
            if (contabilizado) return;

            this.setState({
                popupVisible: true,
                centro: {
                    idCentro: (data.items ?? data.collapsedItems).at(0).idCentro,
                    denominacion: (data.items ?? data.collapsedItems).at(0).centro,
                    tipoCentro: (data.items ?? data.collapsedItems).at(0).tipoCentro,
                },
                GenerarAsientosNominasSAP_RD_then: (response) => {
                    element.addClass("dx-state-disabled");
                    this.props.loadPanel_hide();
                    if (response) {
                        load_tblHistoricoAsientoNomina().then(() => this.dxDataGrid.refresh(false));
                    }
                },
            });
        } else if (column && column.name === "guardar" && rowType === "group") {
            const { load_tblHistoricoAsientoNomina } = this.props;

            const contabilizado = (data?.collapsedItems ?? data?.items).every((item) => item.contabilizado === true);
            
            if (contabilizado) return;

            this.setState({
                centro: {
                    idCentro: (data.items ?? data.collapsedItems).at(0).idCentro,
                    denominacion: (data.items ?? data.collapsedItems).at(0).centro,
                    tipoCentro: (data.items ?? data.collapsedItems).at(0).tipoCentro,
                },
            }, () => {
                this.dxDataGrid.beginCustomLoading();
                this.context_asinetosNominas
                    .invoke("GuardarHistoricoAsientoNomina_RD", {}, "POST")
                    .then(() => {
                        load_tblHistoricoAsientoNomina().then(() => this.dxDataGrid.refresh(false).then(() => {
                            notify({
                                message: getTrad('aviso_C_RegistroInsertado'),
                                type: 'success',
                                displayTime: 1500,
                                closeOnClick: true
                            });
                            this.setState({ centro: null })
                            this.dxDataGrid.endCustomLoading()
                        }));
                    })
                    .catch(() => {
                        notify({
                            message: getTrad('aviso_RegistroNoInsertado'),
                            type: 'error',
                            displayTime: 1500,
                            closeOnClick: true
                        });
                        this.setState({ centro: null })
                        this.dxDataGrid.endCustomLoading()
                    });
            });
        } else if (rowType === "group" && (columnIndex !== 0 || text !== "")) {
            if (row.isExpanded) {
                component.collapseRow(key);
            } else {
                component.expandRow(key);
            }
        }
    };

    dxDataGrid_calculateCellValue_descuentos = (data) => {
        return (data.absentismo ?? 0) + (data.anticipo ?? 0);
    };

    // #endregion

    // #region Tooltip

    dxTooltip_hide = () => this.setState({ tooltipData: {} });

    // #endregion

    // #region Popup

    dxPopup_onHiding = () => this.setState({ popupVisible: false });

    dxPopup_onHidden = () =>
        this.setState({ centro: null, fechaAsiento: null, GenerarAsientosNominasSAP_RD_then: null });

    dxDateBox_onValueChanged = (e) => this.setState({ fechaAsiento: e.value });

    dxToolbarItem_onClick = (e) => {
        this.setState({ popupVisible: false });
        this.props.loadPanel_show();
        this.context_asinetosNominas
            .invoke("GenerarAsientosNominasSAP_RD", {}, "POST")
            .then(this.state.GenerarAsientosNominasSAP_RD_then);        
    };

    // #endregion

    componentDidUpdate(prevProps) {
        const { fechaSel, load_tblHistoricoAsientoNomina } = this.props;

        if (fechaSel !== prevProps.fechaSel) {
            load_tblHistoricoAsientoNomina().then(() => this.dxDataGrid.refresh(false));
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(DataGrid_ContabilidadNominas_RD);
