import React from "react";

import { connectionConstants } from "constants";
import { authHeader, errorHandler, getTrad } from "helpers";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import DataGrid, { Column, FilterRow, Lookup, Paging, Summary, TotalItem } from "devextreme-react/data-grid";

import { balanceHorasRender } from "../shared/functions";

class GridHoras extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tblLavanderia: [],
        };

        this.dxDataGrid_REF = React.createRef();
    }

    componentDidMount() {
        this.datasource_tblLavanderia.load().then((data) => {
            this.setState({ tblLavanderia: data });
        });
    }

    datasource_tblLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
            key: "idLavanderia",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => (request.headers = { ...authHeader() }),
            version: 4,
        }),
    });

    displayFormat = (value) => {
        return `${value < 0 ? "-" : ""}${Math.floor(Math.abs(value / 60))}:${
            Math.abs(value % 60) < 10 ? "0" : ""
        }${Math.abs(value % 60)}`;
    };

    render() {
        const { tblLavanderia } = this.state;
        const { dataSource, vista } = this.props;
        return (
            <DataGrid
                ref={this.dxDataGrid_REF}
                dataSource={dataSource}
                remoteOperations={false}
                hoverStateEnabled={true}
                width={"100%"}
                height={"100%"}
                showColumnLines={false}
                showRowLines={true}
            >
                <FilterRow visible={true} />
                <Paging enabled={false} />
                <Column
                    dataField={"fecha"}
                    format={"dd/MM/yyyy"}
                    caption={getTrad("fecha")}
                    dataType={"date"}
                    alignment={"center"}
                    width={150}
                />
                <Column
                    dataField={"registros.idLavanderia"}
                    caption={getTrad("lavanderia")}
                    cssClass={"border-left"}
                    dataType={"number"}
                    alignment={"center"}
                    width={275}
                >
                    <Lookup dataSource={tblLavanderia} valueExpr={"idLavanderia"} displayExpr={"denominacion"} />
                </Column>
                <Column
                    caption={getTrad("entrada")}
                    alignment={"center"}
                    cssClass={"border-left border-right border-bottom-0"}
                >
                    <Column
                        dataField={"registros.jornadaIni"}
                        cssClass={"border-left position-relative"}
                        caption={getTrad("jornada")}
                        alignment={"center"}
                        dataType={"datetime"}
                        format={"HH:mm"}
                        cellComponent={this.jornadaCellComponent}
                        allowFiltering={false}
                    />
                    <Column
                        dataField={"registros.cuadranteIni"}
                        cssClass={"border-right"}
                        caption={getTrad("cuadrante")}
                        alignment={"center"}
                        dataType={"datetime"}
                        format={"HH:mm"}
                        allowFiltering={false}
                    />
                </Column>
                <Column
                    caption={getTrad("salida")}
                    alignment={"center"}
                    cssClass={"border-left border-right border-bottom-0"}
                >
                    <Column
                        dataField={"registros.jornadaFin"}
                        cssClass={"border-left position-relative"}
                        caption={getTrad("jornada")}
                        alignment={"center"}
                        dataType={"datetime"}
                        format={"HH:mm"}
                        cellComponent={this.jornadaCellComponent}
                        allowFiltering={false}
                    />
                    <Column
                        dataField={"registros.cuadranteFin"}
                        cssClass={"border-right"}
                        caption={getTrad("cuadrante")}
                        alignment={"center"}
                        dataType={"datetime"}
                        format={"HH:mm"}
                        allowFiltering={false}
                    />
                </Column>
                {vista.id !== 4 && vista.id !== 5 && (
                    <Column
                        dataField={"minutos"}
                        caption={getTrad("horas")}
                        alignment={"center"}
                        cellRender={balanceHorasRender}
                        allowFiltering={false}
                        width={150}
                    />
                )}
                <Column dataField={"tipo"} caption={getTrad("tipo")} alignment={"center"} visible={false} />
                <Summary>
                    <TotalItem
                        column={"minutos"}
                        summaryType={"sum"}
                        valueFormat={"decimal"}
                        displayFormat={this.displayFormat}
                    />
                </Summary>
            </DataGrid>
        );
    }

    jornadaCellComponent = (props) => {
        const { data } = props;

        let className = "";
        if (data.column.dataField === "registros.jornadaIni") {
            if (data.data.registros.jornadaIni == null) {
                return (
                    <i className="icon_Warning font-size-xl position-absolute-0 d-flex align-items-center justify-content-center" />
                );
            }
            let jornadaIni = new Date("1970-01-01 " + data.data.registros.jornadaIni);
            let cuadranteIni = new Date("1970-01-01 " + data.data.registros.cuadranteIni);

            if (jornadaIni.getTime() > cuadranteIni.getTime()) {
                className = "text-danger";
            } else if (jornadaIni.getTime() < cuadranteIni.getTime()) {
                className = "text-info";
            }
        }

        if (data.column.dataField === "registros.jornadaFin") {
            if (data.data.registros.jornadaFin == null) {
                return (
                    <i className="icon_Warning font-size-xl position-absolute-0 d-flex align-items-center justify-content-center" />
                );
            }
            let jornadaFin = new Date("1970-01-01 " + data.data.registros.jornadaFin);
            let cuadranteFin = new Date("1970-01-01 " + data.data.registros.cuadranteFin);

            if (jornadaFin.getTime() < cuadranteFin.getTime()) {
                className = "text-danger";
            } else if (jornadaFin.getTime() > cuadranteFin.getTime()) {
                className = "text-info";
            }
        }

        return <span className={className}>{data.text}</span>;
    };
}

export default GridHoras;
