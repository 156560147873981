export const loading_circular = {
  v: "4.7.1",
  fr: 60,
  ip: 0,
  op: 180,
  w: 200,
  h: 200,
  nm: "Comp 1",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "4",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              n: ["0p25_1_0p75_0"],
              t: -60,
              s: [0],
              e: [360],
            },
            { t: 30 },
          ],
          x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
        },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.2, 0.7098039215686275, 0.8980392156862745, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, -70], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [1, 0.967] },
                    o: { x: [0.75, 0.75], y: [0, 0] },
                    n: ["0p833_1_0p75_0", "0p833_0p967_0p75_0"],
                    t: -60,
                    s: [100, 100],
                    e: [100, 67],
                  },
                  {
                    i: { x: [0.25, 0.25], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0, 0.033] },
                    n: ["0p25_1_0p167_0", "0p25_1_0p167_0p033"],
                    t: -15,
                    s: [100, 67],
                    e: [100, 100],
                  },
                  { t: 30 },
                ],
                x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: -60,
      op: 180,
      st: -60,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "3",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              n: ["0p25_1_0p75_0"],
              t: -40,
              s: [0],
              e: [360],
            },
            { t: 50 },
          ],
          x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
        },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.7843137254901961, 0.3176470588235294, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, -70], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [1, 0.967] },
                    o: { x: [0.75, 0.75], y: [0, 0] },
                    n: ["0p833_1_0p75_0", "0p833_0p967_0p75_0"],
                    t: -40,
                    s: [100, 100],
                    e: [100, 67],
                  },
                  {
                    i: { x: [0.25, 0.25], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0, 0.033] },
                    n: ["0p25_1_0p167_0", "0p25_1_0p167_0p033"],
                    t: 5,
                    s: [100, 67],
                    e: [100, 100],
                  },
                  { t: 50 },
                ],
                x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: -40,
      op: 180,
      st: -40,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "2",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              n: ["0p25_1_0p75_0"],
              t: -20,
              s: [0],
              e: [360],
            },
            { t: 70 },
          ],
          x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
        },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 0.7333333333333333, 0.2, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, -70], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [1, 0.967] },
                    o: { x: [0.75, 0.75], y: [0, 0] },
                    n: ["0p833_1_0p75_0", "0p833_0p967_0p75_0"],
                    t: -20,
                    s: [100, 100],
                    e: [100, 67],
                  },
                  {
                    i: { x: [0.25, 0.25], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0, 0.033] },
                    n: ["0p25_1_0p167_0", "0p25_1_0p167_0p033"],
                    t: 25,
                    s: [100, 67],
                    e: [100, 100],
                  },
                  { t: 70 },
                ],
                x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: -20,
      op: 180,
      st: -20,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "1",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              n: ["0p25_1_0p75_0"],
              t: 0,
              s: [0],
              e: [360],
            },
            { t: 90 },
          ],
          x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
        },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 0.26666666666666666, 0.26666666666666666, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, -70], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [1, 0.967] },
                    o: { x: [0.75, 0.75], y: [0, 0] },
                    n: ["0p833_1_0p75_0", "0p833_0p967_0p75_0"],
                    t: 0,
                    s: [100, 100],
                    e: [100, 67],
                  },
                  {
                    i: { x: [0.25, 0.25], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0, 0.033] },
                    n: ["0p25_1_0p167_0", "0p25_1_0p167_0p033"],
                    t: 45,
                    s: [100, 67],
                    e: [100, 100],
                  },
                  { t: 90 },
                ],
                x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "8",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              n: ["0p25_1_0p75_0"],
              t: -61,
              s: [0],
              e: [360],
            },
            { t: 29 },
          ],
          x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
        },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.2, 0.7098039215686275, 0.8980392156862745, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, -70], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [1, 0.967] },
                    o: { x: [0.75, 0.75], y: [0, 0] },
                    n: ["0p833_1_0p75_0", "0p833_0p967_0p75_0"],
                    t: -61,
                    s: [100, 100],
                    e: [100, 67],
                  },
                  {
                    i: { x: [0.25, 0.25], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0, 0.033] },
                    n: ["0p25_1_0p167_0", "0p25_1_0p167_0p033"],
                    t: -16,
                    s: [100, 67],
                    e: [100, 100],
                  },
                  { t: 29 },
                ],
                x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: -61,
      op: 179,
      st: -61,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "7",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              n: ["0p25_1_0p75_0"],
              t: -41,
              s: [0],
              e: [360],
            },
            { t: 49 },
          ],
          x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
        },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.7843137254901961, 0.3176470588235294, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, -70], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [1, 0.967] },
                    o: { x: [0.75, 0.75], y: [0, 0] },
                    n: ["0p833_1_0p75_0", "0p833_0p967_0p75_0"],
                    t: -41,
                    s: [100, 100],
                    e: [100, 67],
                  },
                  {
                    i: { x: [0.25, 0.25], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0, 0.033] },
                    n: ["0p25_1_0p167_0", "0p25_1_0p167_0p033"],
                    t: 4,
                    s: [100, 67],
                    e: [100, 100],
                  },
                  { t: 49 },
                ],
                x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: -41,
      op: 179,
      st: -41,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "6",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              n: ["0p25_1_0p75_0"],
              t: -21,
              s: [0],
              e: [360],
            },
            { t: 69 },
          ],
          x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
        },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 0.7333333333333333, 0.2, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, -70], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [1, 0.967] },
                    o: { x: [0.75, 0.75], y: [0, 0] },
                    n: ["0p833_1_0p75_0", "0p833_0p967_0p75_0"],
                    t: -21,
                    s: [100, 100],
                    e: [100, 67],
                  },
                  {
                    i: { x: [0.25, 0.25], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0, 0.033] },
                    n: ["0p25_1_0p167_0", "0p25_1_0p167_0p033"],
                    t: 24,
                    s: [100, 67],
                    e: [100, 100],
                  },
                  { t: 69 },
                ],
                x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: -21,
      op: 179,
      st: -21,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "5",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              n: ["0p25_1_0p75_0"],
              t: -1,
              s: [0],
              e: [360],
            },
            { t: 89 },
          ],
          x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
        },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 0.26666666666666666, 0.26666666666666666, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, -70], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [1, 0.967] },
                    o: { x: [0.75, 0.75], y: [0, 0] },
                    n: ["0p833_1_0p75_0", "0p833_0p967_0p75_0"],
                    t: -1,
                    s: [100, 100],
                    e: [100, 67],
                  },
                  {
                    i: { x: [0.25, 0.25], y: [1, 1] },
                    o: { x: [0.167, 0.167], y: [0, 0.033] },
                    n: ["0p25_1_0p167_0", "0p25_1_0p167_0p033"],
                    t: 44,
                    s: [100, 67],
                    e: [100, 100],
                  },
                  { t: 89 },
                ],
                x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
                ix: 3,
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: -1,
      op: 179,
      st: -1,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: "Shape Layer 2",
      ks: {
        o: { a: 0, k: 20 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.5, 0.5, 0.5], y: [1, 1, 1] },
              o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
              n: ["0p5_1_0p5_0", "0p5_1_0p5_0", "0p5_1_0p5_0"],
              t: -60,
              s: [100, 100, 100],
              e: [125, 125, 100],
            },
            {
              i: { x: [0.5, 0.5, 0.5], y: [1, 1, 1] },
              o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
              n: ["0p5_1_0p5_0", "0p5_1_0p5_0", "0p5_1_0p5_0"],
              t: 29.5,
              s: [125, 125, 100],
              e: [100, 100, 100],
            },
            { t: 119 },
          ],
          x: "var $bm_rt;\n$bm_rt = loopOut('Cycle');",
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "Shape Layer 1",
      ks: {
        o: { a: 0, k: 20 },
        r: { a: 0, k: 0 },
        p: { a: 0, k: [100, 100, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.5, 0.5, 0.5], y: [1, 1, 1] },
              o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
              n: ["0p5_1_0p5_0", "0p5_1_0p5_0", "0p5_1_0p5_0"],
              t: 0,
              s: [100, 100, 100],
              e: [125, 125, 100],
            },
            {
              i: { x: [0.5, 0.5, 0.5], y: [1, 1, 1] },
              o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
              n: ["0p5_1_0p5_0", "0p5_1_0p5_0", "0p5_1_0p5_0"],
              t: 89.5,
              s: [125, 125, 100],
              e: [100, 100, 100],
            },
            { t: 179 },
          ],
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [40, 40] },
              p: { a: 0, k: [0, 0] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1] },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
      sr: 1,
    },
  ],
};
