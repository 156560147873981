import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  errorHandler,
  authHeader,
  formatNumber,
  getTrad,
  getNombreFormulario,
  formatDate,
  convertClienteUtcToLavanderiaUtc,
  capitalize,
} from "../../../helpers";

//Actions
import {
  loadPanelActions,
  globalActions,
  impresionActions,
} from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import CardSelector from "components/CardSelector";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { Col, Row } from "reactstrap";

import $ from "jquery";
import query from "devextreme/data/query";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import { Tooltip } from "devextreme-react/tooltip";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";

import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import LastUpdateClock from "../../../components/LastUpdateClock";
import { SelectBox } from "devextreme-react/select-box";

import Chart, {
  CommonSeriesSettings,
  SeriesTemplate,
  ArgumentAxis,
  Series,
  Legend,
  Tooltip as TooltipChart,
  ValueAxis,
  Animation,
} from "devextreme-react/chart";

//Css
import "./Css.scss";

//SignalR
import { signalRService, Group } from "../../../services";

const tiempoRefresco_Actual = 1000;

class Home extends React.Component {
  constructor(props) {
    super(props);

    let { lavanderia } = this.props;
    this.state = {
      fechaSel: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        0,
        0,
        0,
      ),
      datosSelector_Consumos: [],
      datosSelector_Consumos_fecha: [],
      datosSelector_Informes: [],
      dxMultiView_index: 0,
      kgEstancia_compañiaSel: null,
      logistica_compañiaSel: null,
      tiempoActual: convertClienteUtcToLavanderiaUtc(
        lavanderia.GMT,
        new Date(),
      ),
      ultimaFecha_load: convertClienteUtcToLavanderiaUtc(
        lavanderia.GMT,
        new Date(),
      ),
      idCategoriaRecursoSel: null,
    };

    //DATASOURCE
    this.dataSource_spSelectEnergyHub_Consumos_beforeSend =
      this.dataSource_spSelectEnergyHub_Consumos_beforeSend.bind(this);
    this.dataSource_spSelectEnergyHub_Consumos_postProcess =
      this.dataSource_spSelectEnergyHub_Consumos_postProcess.bind(this);

    //SELECTOR LAVANDERIA
    this.onCardClick_Contadores = this.onCardClick_Contadores.bind(this);

    //Filtro Categoria Recurso
    this.selectorCategoriaRecurso_onSelectionChanged =
      this.selectorCategoriaRecurso_onSelectionChanged.bind(this);

    //DATE PICKER
    this.prevDate_Click = this.prevDate_Click.bind(this);
    this.nextDate_Click = this.nextDate_Click.bind(this);
    this.datePicker_onValueChanged = this.datePicker_onValueChanged.bind(this);
    this.datePicker_displayFormat = this.datePicker_displayFormat.bind(this);

    //BACK BUTTON
    this.backButton_onClick = this.backButton_onClick.bind(this);

    //SELECTOR INFORMES

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  //#region DATASOURCE
  dataSource_spSelectEnergyHub_Consumos = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectEnergyHub_Consumos",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectEnergyHub_Consumos_beforeSend(request);
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.dataSource_spSelectEnergyHub_Consumos_postProcess(data);
    },
    onChanged: () => {
      this.dataSource_spSelectEnergyHub_Consumos_onChanged();
    },
  });
  dataSource_spSelectEnergyHub_Consumos_beforeSend(request) {
    let idLavanderia = this.props.lavanderia.idLavanderia;

    request.headers = { ...authHeader() };
    request.params.idLavanderia = idLavanderia;
  }
  dataSource_spSelectEnergyHub_Consumos_postProcess(data) {
    let _this = this;

    let dataSource_agrupado_contador = query(data)
      .groupBy(function (dataItem) {
        return [
          dataItem.idRecursoContador,
          dataItem.denominacion,
          dataItem.idGrupoEnergetico,
          dataItem.unidadMedida,
          dataItem.idCategoriaRecurso,
        ];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("consumos")
          .done(function (result) {
            resultItem = {
              idRecursoContador: dataItem.key[0],
              denominacion: dataItem.key[1],
              idGrupoEnergetico: dataItem.key[2],
              unidadMedida: dataItem.key[3],
              idCategoriaRecurso: dataItem.key[4],
              consumos: result,
            };
          });
        query(dataItem.items)
          .sum("coste")
          .done(function (result) {
            resultItem = {
              idRecursoContador: dataItem.key[0],
              denominacion: dataItem.key[1],
              idGrupoEnergetico: dataItem.key[2],
              unidadMedida: dataItem.key[3],
              idCategoriaRecurso: dataItem.key[4],
              consumos: resultItem.consumos,
              coste: result,
            };
          });
        return resultItem;
      })
      .toArray();

    let dataSource_chart_ultimosDias = query(data)
      .groupBy(function (dataItem) {
        return [dataItem.idRecursoContador, dataItem.denominacion];
      })
      .select(function (dataItem) {
        var resultItem = null;
        resultItem = query(dataItem.items).sortBy("fecha").toArray().slice(-5);
        return resultItem;
      })
      .toArray();

    $.each(dataSource_agrupado_contador, function (index, item) {
      let mensualFiltrado = $.grep(data, function (fecha) {
        return fecha.idRecursoContador == item.idRecursoContador;
      });

      //#region INTEGRAR DATOS TOTALES DEL MES EN EL CONTADOR
      let datosGrafica = $.map(mensualFiltrado, function (item) {
        return {
          arg: item.fecha,
          val: item.consumos,
          coste: item.coste,
        };
      });

      let datosSelector_Consumos_mensual = [
        {
          denominacion: item.denominacion,
          datosGrafica: datosGrafica,
          id: item.idRecursoContador,
          cardClass: "heightFixed",
          udMedida: item.unidadMedida,
          consumoMes: item.consumos,
          costeMes: item.coste,
          textoTotal:
            item.idGrupoEnergetico == 1
              ? getTrad("totalLAgua")
              : item.idGrupoEnergetico == 2
                ? getTrad("totalKwhElectricidad")
                : item.idGrupoEnergetico == 3 && item.unidadMedida === "KWH"
                  ? getTrad("totalKwhCalefaccion")
                  : item.idGrupoEnergetico == 3 && item.unidadMedida === "M³"
                    ? getTrad("totalM3")
                    : getTrad("total"),
          headerContent: function (item) {
            return _this.get_HeaderContent_Contador_Diario(item);
          },
          bodyContent: function (item) {
            return _this.get_BodyContent_Contador_Diario(item);
          },
        },
      ];
      item.datosSelector_Consumos_fecha = datosSelector_Consumos_mensual;

      if (
        _this.state.datosSelector_Consumos_fecha.length > 0 &&
        _this.state.datosSelector_Consumos_fecha[0].id ===
          item.datosSelector_Consumos_fecha[0].id
      ) {
        _this.setState({
          datosSelector_Consumos_fecha: item.datosSelector_Consumos_fecha,
        });
      }
      //#endregion

      item.id = item.idRecursoContador;
      item.cardClass = "row-2";

      let idRecursoContador = item.idRecursoContador;

      let ultimosDatos = [];
      $.each(dataSource_chart_ultimosDias, function (index, item) {
        $.each(item, function (subIndex, subItem) {
          if (subItem.idRecursoContador === idRecursoContador) {
            ultimosDatos.push(subItem);
          }
        });
      });

      let ultimoDia = $(ultimosDatos).get(-1); //todo que coja el día actual
      item.icono =
        item.idGrupoEnergetico == 1
          ? "icon_Agua"
          : item.idGrupoEnergetico == 2
            ? "icon_Electricidad"
            : "icon_Calefaccion";
      item.consumoMes = item.consumos;
      item.idCategoriaRecurso = item.idCategoriaRecurso;
      item.consumoHoy = ultimoDia.consumos;
      item.costeHoy = ultimoDia.coste;
      item.ultimosDatos = $.map(ultimosDatos, function (item) {
        return {
          arg: item.fecha,
          val: item.consumos,
        };
      });

      item.headerContent = function (item) {
        return _this.get_HeaderContent_Contador_General(item);
      };
      item.bodyContent = function (item) {
        return _this.get_BodyContent_Contador_General(item);
      };
    });

    return dataSource_agrupado_contador;
  }

  dataSource_spSelectEnergyHub_Consumos_onChanged() {
    let { lavanderia } = this.props;
    let items = this.dataSource_spSelectEnergyHub_Consumos.items();
    this.setState({
      datosSelector_Consumos: items,
      ultimaFecha_load: convertClienteUtcToLavanderiaUtc(
        lavanderia.GMT,
        new Date(),
      ),
    });
  }

  datasource_tblCategoriaRecurso = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCategoriaRecurso",
      key: "idCategoriaRecurso",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.datasource_tblCategoriaRecurso_postProcess(data);
    },
  });
  datasource_tblCategoriaRecurso_postProcess(data) {
    if (data.length > 1) {
      data.splice(0, 0, { idCategoriaRecurso: null, denominacion: null });
    }
    return data;
  }

  //#endregion

  render() {
    const {
      dxMultiView_index,
      datosSelector_Consumos,
      datosSelector_Consumos_fecha,
    } = this.state;
    let { idCategoriaRecursoSel } = this.state;

    let datosSelector_Filtrado = datosSelector_Consumos.filter(
      (x) =>
        (idCategoriaRecursoSel != null &&
          idCategoriaRecursoSel == x.idCategoriaRecurso) ||
        idCategoriaRecursoSel === null,
    );

    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          preHeading={this.getBackButton()}
          postHeading={[
            this.getSelectorCategoriaRecurso(),
            <LastUpdateClock fecha={this.state.ultimaFecha_load} />,
          ]}
        />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <MultiView
            elementAttr={this.elementAttr_multiView}
            height="100%"
            width="100%"
            selectedIndex={dxMultiView_index}
            loop={true}
            swipeEnabled={false}
            deferRendering={true}
            animationEnabled={true}
          >
            <MultiViewItem>
              <CardSelector
                data={datosSelector_Filtrado}
                onClick={this.onCardClick_Contadores}
                bodyClick={true}
                zoom={false}
              ></CardSelector>
            </MultiViewItem>
            <MultiViewItem>
              <CardSelector
                data={datosSelector_Consumos_fecha}
                onClick={this.onCardClick_fechas}
                bodyClick={false}
                readOnly={true}
                zoom={false}
                {...this.state}
              ></CardSelector>
            </MultiViewItem>
          </MultiView>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  getSelectorCategoriaRecurso() {
    let { idCategoriaRecursoSel, dxMultiView_index } = this.state;
    return (
      <SelectBox
        deferRendering={false}
        className="centerText font-size-sm mr-5"
        inputAttr={{ class: "font-size-sm" }}
        dataSource={this.datasource_tblCategoriaRecurso}
        valueExpr="idCategoriaRecurso"
        displayExpr={function (item) {
          if (item && item.denominacion) {
            return item.denominacion.toUpperCase();
          } else if (item) {
            return getTrad("todos").toUpperCase();
          } else {
            return;
          }
        }}
        value={idCategoriaRecursoSel}
        defaultValue={idCategoriaRecursoSel}
        width={215}
        stylingMode="underlined"
        visible={dxMultiView_index === 0}
        onSelectionChanged={this.selectorCategoriaRecurso_onSelectionChanged}
      />
    );
  }
  selectorCategoriaRecurso_onSelectionChanged(e) {
    let idCategoriaRecursoSel = e.selectedItem.idCategoriaRecurso;

    this.setState({ idCategoriaRecursoSel: idCategoriaRecursoSel });
  }

  //#region MÉTODOS
  //SELECTOR LAVANDERIA
  onCardClick_Contadores(contador) {
    this.setState({
      dxMultiView_index: 1,
      datosSelector_Consumos_fecha: contador.datosSelector_Consumos_fecha,
    });
  }
  get_HeaderContent_Contador_General(item) {
    return (
      <Fragment>
        <div className="ml-auto">
          <div className={"pr-2 font-size-xxl " + item.icono} />
        </div>
      </Fragment>
    );
  }
  get_BodyContent_Contador_General(recurso) {
    let { lavanderia, idioma } = this.props;

    return (
      <Box direction="col" width="100%" height={"100%"}>
        <ItemBox ratio={1}>
          <Row className="card-row-header pr-2 pl-2 pt-1">
            <Col className="flex-column">
              <div
                className="widget-title opacity-5 text-uppercase text-left"
                style={{ height: "15px", lineHeight: "10px" }}
              >
                HOY
              </div>
              <div
                className="font-size-xxl font-weight-bold text-left"
                style={{ lineHeight: "35px" }}
              >
                {formatNumber(recurso.consumoHoy, 2) +
                  " " +
                  recurso.unidadMedida}
              </div>
            </Col>
            <Col className="flex-column">
              <div
                className="widget-title opacity-5 text-uppercase text-right"
                style={{ height: "15px", lineHeight: "10px" }}
              >
                MES
              </div>
              <div
                className="font-size-xxl opacity-5 font-weight-bold text-right"
                style={{ lineHeight: "35px" }}
              >
                {formatNumber(recurso.consumoMes, 2) +
                  " " +
                  recurso.unidadMedida}
              </div>
            </Col>
          </Row>
        </ItemBox>
        <ItemBox ratio={3}>
          <Row className="dashboardEnergyHub_chart_container flex-fill ml-1 mr-1 pb-4">
            <div className="widget-content-wrapper w-100">
              <Chart
                id="Chart"
                dataSource={recurso.ultimosDatos}
                redrawOnResize={true}
                legend={{ visible: false }}
                palette={this.customPalette}
                rotated={true}
                animation={{ enabled: false }}
                customizePoint={(e) => this.customizePoint(e, recurso)}
              >
                <CommonSeriesSettings
                  argumentField="arg"
                  valueField="val"
                  type="bar"
                  ignoreEmptyPoints={true}
                  barPadding={0.15}
                  hoverMode="allArgumentPoints"
                />

                <ArgumentAxis
                  argumentType={"datetime"}
                  tickInterval={"day"}
                  aggregationInterval={"day"}
                  label={{
                    overlappingBehavior: "none",
                    displayMode: "rotate",
                    format: "monthAndDay",
                    customizeText: function (e) {
                      let value = new Date(e.value);
                      return (
                        value.getDate() +
                        " " +
                        capitalize(
                          new Intl.DateTimeFormat(idioma.codigo, {
                            month: "long",
                          }).format(value),
                        )
                      );
                    },
                  }}
                />
                <SeriesTemplate nameField="val" />
                <TooltipChart
                  enabled={true}
                  location="edge"
                  contentRender={(e) =>
                    this.dxChart_tooltip_contentRender(e, recurso.unidadMedida)
                  }
                  shared={true}
                />
              </Chart>
            </div>
          </Row>
        </ItemBox>
        <ItemBox ratio={1}>
          <div className="p-2  card-row-footer he-100 divider-top container_spanCentrado flex-column">
            <div
              className="widget-title opacity-5 text-uppercase"
              style={{ height: "10px" }}
            >
              {"HOY"}
            </div>
            <div style={{ height: "5px" }} />
            <div
              className={
                "font-size-max text-center text-cut pl-3 pr-3 font-weight-bold"
              }
            >
              {formatNumber(recurso.costeHoy, 2, "currency", lavanderia.moneda)}
            </div>
          </div>
        </ItemBox>
      </Box>
    );
  }

  onCardClick_fechas() {}

  get_HeaderContent_Contador_Diario(item) {
    return <div></div>;
  }
  get_BodyContent_Contador_Diario(fechas) {
    let { lavanderia } = this.props;
    return (
      <Box direction="col" width="100%" height={"100%"}>
        <ItemBox baseSize={60}>
          <Toolbar>
            <ToolbarItem location="before">
              <Box direction="row" width={410} height={"100%"}>
                <ItemBox ratio={1}>
                  <div className="dxChart_contenedorIndividual text-left">
                    {fechas.textoTotal.toUpperCase()}
                    <div className="lblInfoGeneral_value">
                      {formatNumber(fechas.consumoMes, 2) +
                        " " +
                        fechas.udMedida}
                    </div>
                  </div>
                </ItemBox>
                <ItemBox baseSize={25} />
                <ItemBox ratio={1}>
                  <div className="dxChart_contenedorIndividual text-left">
                    {getTrad("coste").toUpperCase()}
                    <div className="lblInfoGeneral_value">
                      {formatNumber(
                        fechas.costeMes,
                        2,
                        "currency",
                        lavanderia.moneda,
                      )}
                    </div>
                  </div>
                </ItemBox>
              </Box>
            </ToolbarItem>
          </Toolbar>
        </ItemBox>
        <ItemBox ratio={1}>
          <Chart
            className="he-100 w-100"
            id="ChartDiario"
            dataSource={fechas.datosGrafica}
            redrawOnResize={true}
          >
            <CommonSeriesSettings
              argumentField="arg"
              ignoreEmptyPoints={true}
              hoverMode="allArgumentPoints"
            ></CommonSeriesSettings>
            <Series valueField="val" color="var(--primary)" type="bar" />
            <ValueAxis position="left" maxValueMargin={0} minValueMargin={0} />
            <ArgumentAxis argumentType="datetime" tickInterval="day" />
            <TooltipChart
              enabled={true}
              location="edge"
              contentRender={(e) =>
                this.dxChart_tooltip_contentRender_Diario(e, fechas)
              }
              shared={true}
            />
            <Animation enabled={false} />
            <Legend visible={false} />
          </Chart>
        </ItemBox>
      </Box>
    );
  }

  //DATE PICKER
  getDatePicker() {
    return (
      <DateBox
        className="centerText font-size-sm"
        inputAttr={{ class: "font-size-sm" }}
        value={this.state.fechaSel}
        width="100%"
        stylingMode="underlined"
        displayFormat={this.datePicker_displayFormat}
        openOnFieldClick={true}
        calendarOptions={{
          maxZoomLevel: "year",
          minZoomLevel: "year",
        }}
        onValueChanged={this.datePicker_onValueChanged}
      >
        <DateBoxButton
          name="prevDate"
          location="before"
          options={{
            icon: "spinprev",
            stylingMode: "text",
            onClick: this.prevDate_Click,
          }}
        />
        <DateBoxButton
          name="nextDate"
          location="after"
          options={{
            icon: "spinnext",
            stylingMode: "text",
            onClick: this.nextDate_Click,
          }}
        />
      </DateBox>
    );
  }
  prevDate_Click(e) {
    const { fechaSel } = this.state;
    const _fechaSel = new Date(fechaSel.getTime());
    this.setState({
      fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() - 1)),
    });
  }
  nextDate_Click(e) {
    let { fechaSel } = this.state;
    let _fechaSel = new Date(fechaSel.getTime());
    this.setState({
      fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() + 1)),
    });
  }
  datePicker_onValueChanged(e) {
    this.setState({ fechaSel: e.value });
  }
  datePicker_displayFormat(fecha) {
    let { idioma } = this.props;
    if (new Date().getFullYear() === fecha.getFullYear()) {
      return fecha
        .toLocaleString(idioma.codigo, { month: "long" })
        .toUpperCase();
    } else {
      return fecha
        .toLocaleString(idioma.codigo, { month: "long", year: "numeric" })
        .toUpperCase();
    }
  }

  //BACK BUTTON
  getBackButton() {
    let { dxMultiView_index } = this.state;
    return (
      <Fragment>
        <div
          id="backButton"
          className="backButton dx-icon-chevronleft container_spanCentrado"
          onClick={this.backButton_onClick}
          hidden={dxMultiView_index === 0}
        />
        <Tooltip
          target="#backButton"
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{getTrad("volverLavanderia")}</div>
        </Tooltip>
      </Fragment>
    );
  }
  backButton_onClick() {
    this.setState({
      dxMultiView_index: 0,
      kgEstancia_compañiaSel: null,
      logistica_compañiaSel: null,
    });
  }

  customizeTooltip_Charts(info, udMedida) {
    let idioma = this.props.idioma;
    let argument = new Date(info.argument);
    let argumentText =
      argument.getDate() +
      " " +
      capitalize(
        new Intl.DateTimeFormat(idioma.codigo, { month: "long" }).format(
          argument,
        ),
      );

    let html = $("<div />");
    html.append(
      $("<div />")
        .addClass("d-flex justify-content-between")
        .css("width", "250px")
        .append(
          $("<div />")
            .addClass("tooltip-title font-size-md")
            .css("color", "var(--secondary)")
            .text(argumentText),
        )
        .append(
          $("<div />")
            .addClass("tooltip_value-text font-size-md")
            .css("color", "var(--gray)")
            .text(formatNumber(info.value, 2, "decimal") + udMedida),
        ),
    );
    return {
      html: html.html(),
    };
  }

  dxChart_tooltip_contentRender(arg, udMedida) {
    return (
      <div>
        <div className="tooltip_header">
          <div className="tooltip_title"> {formatDate(arg.argument)}</div>
        </div>
        <div className="tooltip_body">
          {arg.points.map((point, i) => (
            <div key={point.seriesName} className="tooltip_row">
              <div className="tooltip_series-name">
                {"Consumo".toUpperCase()}
              </div>
              <div className="tooltip_value-text">
                {formatNumber(arg.value, 2, "decimal") + " " + udMedida}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  dxChart_tooltip_contentRender_Diario(arg, fechas) {
    let argument = new Date(arg.argument);

    let fechaSel = $.grep(fechas.datosGrafica, function (item) {
      if (item.arg != null)
        return formatDate(item.arg) === formatDate(argument);
    });

    return (
      <div>
        <div className="tooltip_header">
          <div className="tooltip_title"> {formatDate(arg.argument)}</div>
        </div>
        <div className="tooltip_body">
          {arg.points.map((point, i) => (
            <Fragment key={point.seriesName}>
              <div className="tooltip_row">
                <div className="tooltip_series-name">
                  {"Consumo".toUpperCase()}
                </div>
                <div className="tooltip_value-text">
                  {formatNumber(arg.value, 2, "decimal") +
                    " " +
                    fechas.udMedida}
                </div>
              </div>
              <div className="tooltip_row">
                <div className="tooltip_series-name">
                  {getTrad("coste").toUpperCase()}
                </div>
                <div className="tooltip_value-text">
                  {formatNumber(
                    fechaSel[0].coste,
                    2,
                    "currency",
                    this.props.lavanderia.moneda,
                  )}
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    );
  }

  customizeTooltip_Charts_Diario(info, fechas) {
    let udMedida = fechas.idGrupoEnergetico === 1 ? " L" : " KWH";
    let idioma = this.props.idioma;
    let argument = new Date(info.argument);
    let argumentText =
      argument.getDate() +
      " " +
      capitalize(
        new Intl.DateTimeFormat(idioma.codigo, { month: "long" }).format(
          argument,
        ),
      );

    let fechaSel = $.grep(fechas.datosGrafica, function (item) {
      if (item.arg != null)
        return formatDate(item.arg) === formatDate(argument);
    });

    let html = $("<div />");
    html.append(
      $("<div />")
        .addClass("d-flex justify-content-between")
        .css("width", "325px")
        .append(
          $("<div />")
            .addClass("tooltip-title font-size-md")
            .css("color", "var(--secondary)")
            .text(argumentText),
        )
        .append(
          $("<div />")
            .addClass("tooltip_value-text font-size-md")
            .css("color", "var(--gray)")
            .text(
              formatNumber(
                fechaSel[0].coste,
                2,
                "currency",
                this.props.lavanderia.moneda,
              ),
            ),
        )
        .append(
          $("<div />")
            .addClass("tooltip_value-text font-size-md")
            .css("color", "var(--gray)")
            .text(formatNumber(info.value, 2, "decimal") + fechas.unidadMedida),
        ),
    );
    return {
      html: html.html(),
    };
  }

  chart_fondoManiobra_customizePoint(pointInfo) {
    return {
      color:
        pointInfo.value === 0
          ? "var(--primary)"
          : pointInfo.value > 0
            ? "var(--success)"
            : "var(--danger)",
    };
  }

  //UTILS
  setLavanderia(idLavanderia) {
    let lavanderias = JSON.parse(localStorage.getItem("lavanderias"));

    var lav = lavanderias.find((lav) => {
      return lav.idLavanderia === idLavanderia;
    });
    this.props.setLavanderia(lav);
  }
  elementAttr_multiView = { id: "DashboardEnergyHub" };
  customPalette = ["#A9A9A9"];

  customizePoint(_this, recurso) {
    let ultimosDatos_grafica = $(recurso.ultimosDatos).get(-1);
    if (formatDate(_this.argument) === formatDate(ultimosDatos_grafica.arg)) {
      return { color: "#F1BE48" };
    }
  }

  get_responsiveFont_gauge() {
    let width = $(window).width();
    // 3 Columnas
    if (width > 2150) return "font-size-xxl";
    if (width >= 1630 && width < 2150) return "font-size-xl";
    else if (width >= 1260 && width < 1630) return "font-size-lg";
    else if (width >= 1200 && width < 1260) return "font-size-md";
    // 2 Columnas
    else if (width >= 800 && width < 1200) return "font-size-xl";
    else if (width >= 650 && width < 800) return "font-size-lg";
    else if (width >= 576 && width < 650) return "font-size-md";
    // 1 Column
    else if (width >= 400 && width < 576) return "font-size-xl";
    else if (width < 400) return "font-size-lg";
    else return "font-size-xl";
  }
  //#endregion

  //CONFIGURACIÓN
  //CHART
  chart_breakStyle = { color: "lightgray" };

  //GRID
  grid_Singleselection = { mode: "single" };
  grid_allowedPageSizes = [20, 50, 100];

  //#region INTERNOS
  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }
  signalR_onRefresh() {
    this.dataSource_spSelectEnergyHub_Consumos.load();
  }
  //#endregion

  componentDidMount() {
    let _this = this;
    let { lavanderia } = this.props;

    this.interval_tiempoRefresco_Actual = setInterval(() => {
      _this.setState({
        tiempoActual: convertClienteUtcToLavanderiaUtc(
          lavanderia.GMT,
          new Date(),
        ),
      });
    }, tiempoRefresco_Actual);

    _this.dataSource_spSelectEnergyHub_Consumos.load().done(function () {
      _this.loadPanel_hide();
    });

    //#region SignalR
    signalRService.openConnection(
      "EnergyHub",
      $.hubConnection(connectionConstants.WEB_API_URL),
    );
    let grupos = [new Group("idLavanderia", null, lavanderia.idLavanderia)];
    signalRService.set_grupos(grupos);
    signalRService.signalR_refresh(this);
    //#endregion
  }
  componentDidUpdate(prevProps, prevState) {
    let _this = this;
    let { lavanderia } = this.props;
    let { fechaSel } = this.state;

    let hayCambiosLavanderia =
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia;
    let hayCambiosFecha =
      fechaSel.getFullYear() !== prevState.fechaSel.getFullYear() ||
      fechaSel.getMonth() !== prevState.fechaSel.getMonth();

    if (hayCambiosLavanderia || hayCambiosFecha) {
      let grupos = [
        new Group(
          "idLavanderia",
          prevProps.lavanderia.idLavanderia,
          lavanderia.idLavanderia,
        ),
      ];
      signalRService.set_grupos(grupos);

      this.loadPanel_show();
      this.setState({ dxMultiView_index: 0 });
      this.dataSource_spSelectEnergyHub_Consumos.load().done(function () {
        _this.loadPanel_hide();
      });
    }
  }
  componentWillUnmount() {
    //#region SignalR
    clearInterval(this.interval_tiempoRefresco_Actual);
    clearInterval(this.interval_CambioPagina);

    signalRService.closeAllConnections();
    //#endregion
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  setLavanderia: (lavanderia) =>
    dispatch(globalActions.setLavanderia(lavanderia)),
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
  close: () => dispatch(impresionActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
