import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAplicaciones, getUrlFormularioInicio, getTrad } from "../helpers";

class NotFound extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { lavanderia, user } = this.props;

    let aplicaciones = getAplicaciones(null);
    let appInicio;
    if (aplicaciones && aplicaciones.length > 0) {
      if (user.tblFormularioInicio)
        appInicio = user.tblFormularioInicio.tblAplicacion;
      if (user.idAplicacionInicial)
        appInicio = aplicaciones.find(
          (x) => x.idAplicacion === user.idAplicacionInicial,
        );
      else appInicio = aplicaciones[0];
    }

    let redirection = getUrlFormularioInicio(
      lavanderia,
      appInicio,
      user.tblFormularioInicio,
    );
    return (
      <div className="he-100 d-flex flex-column align-items-center justify-content-center">
        <div className="flex-container">
          <div className="flex-item text-center">
            <div className="d-flex flex-row">
              <div className="flex-item">
                <div
                  style={{
                    height: "85%",
                    width: "60px",
                    backgroundPosition: "left",
                  }}
                  className="logo-Estrella mx-auto"
                ></div>
              </div>
              <div className="flex-item">
                <p className="font-size-xxxl">
                  {getTrad("paginaNoEncontrada")}
                </p>
              </div>
            </div>
            <p>
              {getTrad("irPaginaInicio") + " "}{" "}
              <Link to={redirection}>{getTrad("aqui").toLowerCase()}</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(NotFound);
