import React, { Component } from "react";
import { getTrad } from "helpers";

// Componente de clase refactorizado
class DatosSalariales extends Component {
  get muestraRender() {
    const { datosPersonaSel } = this.props;
    return (
      !!datosPersonaSel?.tblDatosSalariales?.salarioBase &&
      !!datosPersonaSel?.tblDatosSalariales?.plusAsistencia &&
      !!datosPersonaSel?.tblDatosSalariales?.numPagas &&
      !!datosPersonaSel?.idTurnoNavigation
    );
  }

  render() {
    const { datosPersonaSel } = this.props;
    const { idTurnoNavigation, tblDatosSalariales } = datosPersonaSel ?? {};

    if (!this.muestraRender) {
      return null;
    }

    return (
      <div className="d-flex w-100 bg-white px-4 py-2 mt-4 shadow border-radius justify-content-between ">
        {!!tblDatosSalariales?.salarioBase && (
          <div className="d-flex flex-column justify-content-center  align-items-center">
            <div>{getTrad("salarioBase")}</div>
            <div className="d-flex w-100 m-2 justify-content-center">{tblDatosSalariales?.salarioBase} €</div>
          </div>
        )}

        {!!tblDatosSalariales?.plusAsistencia && (
          <div className="d-flex flex-column justify-content-center  align-items-center">
            <div>{getTrad("plusAsistencia")}</div>
            <div className="d-flex w-100 m-2 justify-content-center">{tblDatosSalariales?.plusAsistencia} €</div>
          </div>
        )}

        {!!tblDatosSalariales?.numPagas && (
          <div className="d-flex flex-column justify-content-center  align-items-center">
            <div>{getTrad("numPagas")}</div>
            <div className="d-flex w-100 m-2 justify-content-center">{tblDatosSalariales?.numPagas}</div>
          </div>
        )}
        {!!idTurnoNavigation && (
          <div className="d-flex flex-column justify-content-center  align-items-center">
            <div>{getTrad("turno")}</div>
            <div className="d-flex w-100 m-2 justify-content-center">{idTurnoNavigation?.denominacion}</div>
          </div>
        )}
      </div>
    );
  }
}

export default DatosSalariales;
