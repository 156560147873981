import React from "react";

import DataGrid, { Column, Sorting } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { connectionConstants } from "constants";
import { authHeader, errorHandler, getTrad } from "helpers";

class TablaHistorialNominas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
  }

  formats = {
    currency: {
      style: "currency",
      maximumFractionDigits: 2,
      currency: "EUR",
    },
  };

  datasource_nominas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/GestionNominas/GetHistorialNominas",
      key: "idNomina",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => this.datasource_nominas_beforeSend(request),
      version: 4,
    }),
  });

  datasource_nominas_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.params.idPersona = this.props.idPersona;
  };

  datasource_nominas_load = () => {
    this.datasource_nominas.load().done(data => this.setState({ dataSource: data }));
  };

  render() {
    const { dataSource } = this.state;

    return (
      <DataGrid
        dataSource={dataSource}
        height={"100%"}
        width={"100%"}
        allowColumnReordering
        columnsAutoWidth
        hoverStateEnabled
        repaintChangesOnly
        rowAlternationEnabled
        showColumnLines={false}
        showRowLines
      >
        <Sorting mode={"multiple"} />
        <Column
          width={125}
          caption={getTrad("fechaCobro")}
          dataField={"fechaCobro"}
          dataType={"datetime"}
          format={"dd/MM/yyyy"}
          sortOrder={"desc"}
        />
        <Column
          width={125}
          caption={getTrad("tipoPaga")}
          dataField={"tipoPaga"}
        />
        <Column
          width={100}
          caption={getTrad("salarioBruto")}
          dataField={"salarioBruto"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={100}
          caption={getTrad("pagaExtra1")}
          dataField={"pagaExtra1"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={100}
          caption={getTrad("pagaExtra2")}
          dataField={"pagaExtra2"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={125}
          caption={getTrad("tributacionIRPF")}
          dataField={"tributacionIRPF"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={125}
          caption={getTrad("segSocialTrabajador")}
          dataField={"segSocialTrabajador"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={125}
          caption={getTrad("liquidoPercibir")}
          dataField={"liquidoPercibir"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={100}
          caption={getTrad("anticipo")}
          dataField={"anticipo"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={100}
          caption={getTrad("embargo")}
          dataField={"embargo"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={100}
          caption={getTrad("absentismo")}
          dataField={"absentismo"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={125}
          caption={getTrad("indemnizacion")}
          dataField={"indemnizacion"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={150}
          caption={getTrad("conceptoNEspecie")}
          dataField={"conceptoNEspecie"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={100}
          caption={getTrad("segSocialEmpresa")}
          dataField={"segSocialEmpresa"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={100}
          caption={getTrad("totalTC1")}
          dataField={"totalTC1"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={100}
          caption={getTrad("baseCC")}
          dataField={"baseCC"}
          dataType={"number"}
          format={this.formats.currency}
        />
        <Column
          width={125}
          caption={getTrad("costeEmpresa")}
          dataField={"costeEmpresa"}
          dataType={"number"}
          format={this.formats.currency}
        />
      </DataGrid>
    );
  };

  componentDidMount = () => {
    if (this.props.idPersona) {
      this.datasource_nominas_load();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.idPersona && this.props.idPersona !== prevProps.idPersona) {
      this.datasource_nominas_load();
    }
  };
}

export default TablaHistorialNominas;
