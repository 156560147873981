import React, { useRef, useState } from "react";

import { Col, Row } from "reactstrap";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import SelectBox from "devextreme-react/select-box";

import BusquedaRecambioProvider from "./components/BusquedaRecambioProvider";
import idsTipoMovimientoRecambio from "constants/enums/Assistant/tblTipoMovimientoRecambio";
import { authHeader, errorHandler, formatNumber, getTrad } from "helpers";
import { connectionConstants } from "constants";

const regex = /'([^']+)'/;

const displayExpr = (e) => {
  const strings = [
    e?.referenciaInterna,
    e?.referencia,
    e?.denominacion,
    e?.denoProveedor,
  ];

  return e && strings.filter((s) => s?.length > 0).join(" | ");
};

const BuscadorRecambio = ({
  formData,
  readOnly,
  setIsLoading,
  onAddRecambio,
}) => {
  const selectBoxRef = useRef(null);

  const [idRecambioSel, setIdRecambioSel] = useState(null);
  const [idRecambioNProveedorSel, setIdRecambioNProveedorSel] = useState(null);

  const {
    idAlmacenDestino,
    idAlmacenOrigen,
    idProveedor,
    idTipoMovimientoRecambio,
    tblRecambioNMovimientoRecambio,
  } = formData;

  const datasource_buscarRecambios_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    const match = request.params["$filter"]?.match(regex);
    const campoBusqueda = match?.[1];

    request.params["$filter"] = undefined;

    request.params.idAlmacenOrigen = idAlmacenOrigen;
    request.params.idAlmacenDestino = idAlmacenDestino;
    request.params.idProveedor = idProveedor;
    request.params.idTipoMovimientoRecambio = idTipoMovimientoRecambio;
    request.params.campoBusqueda = `'${campoBusqueda}'`;

    request.method = "POST";

    request.payload = tblRecambioNMovimientoRecambio.map((r) => r.idRecambio);
  };

  const datasource_buscarRecambios = new DataSource({
    paginate: true,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Assistant/MovimientoRecambio/GetRecambios_MovimientoRecambio",
      errorHandler,
      beforeSend: datasource_buscarRecambios_beforeSend,
      version: 4,
    }),
    sort: ["orden"],
  });

  const itemRender = (data) => {
    let fields = [
      {
        colSpan: 1,
        value: data.referenciaInterna,
      },
      {
        colSpan: 2,
        value: data.referencia,
      },
      {
        colSpan: 5,
        value: data.denominacion,
      },
    ];

    if (idTipoMovimientoRecambio === idsTipoMovimientoRecambio.entrada) {
      fields = [
        ...fields,
        {
          colSpan: 2,
          value: data.denoProveedor,
        },
        {
          colSpan: 2,
          value: data.precio
            ? formatNumber(
                data.precio,
                2,
                "currency",
                data.codigoMoneda ?? "EUR"
              )
            : "",
        },
      ];
    }

    return (
      <div>
        <Row className={"w-100"}>
          {fields.map((field, index) => {
            if (field.isVisible === false) return null;
            return (
              <Col
                key={index}
                xs={field.colSpan}
                className={
                  "p-2 text-truncate " + (fields[index + 1] && "border-right")
                }
              >
                {field.value}
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  const onGetRecambio = (recambio) => {
    setIsLoading(false);
    setIdRecambioSel(null);
    setIdRecambioNProveedorSel(null);
    onAddRecambio(recambio);
  };

  const onValueChanged = ({ value }) => {
    if (value?.idRecambioNProveedor) {
      setIdRecambioNProveedorSel(value?.idRecambioNProveedor);
      setIdRecambioSel(null);
    } else if (value?.idRecambio) {
      setIdRecambioSel(value?.idRecambio);
      setIdRecambioNProveedorSel(null);
    } else {
      return;
    }

    setIsLoading(true);

    selectBoxRef?.current?.instance?.reset();
  };

  return (
    <BusquedaRecambioProvider
      formData={formData}
      idRecambioSel={idRecambioSel}
      idRecambioNProveedorSel={idRecambioNProveedorSel}
      onGetRecambio={onGetRecambio}
    >
      <SelectBox
        ref={selectBoxRef}
        dataSource={datasource_buscarRecambios}
        searchExpr={["searchExpr"]}
        displayExpr={displayExpr}
        placeholder={`${getTrad("buscar")}...`}
        openOnFieldClick={false}
        searchEnabled={true}
        showClearButton={true}
        showDropDownButton={false}
        readOnly={readOnly}
        minSearchLength={1}
        itemRender={itemRender}
        onValueChanged={onValueChanged}
      />
    </BusquedaRecambioProvider>
  );
};

export default BuscadorRecambio;
