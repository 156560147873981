const idsEstadoNomina = {
  enProceso: 1,
  solicitudCambioRRHH: 2,
  validadoRRHH: 3,
  solicitudCambioGestoria: 4,
  importadoConConflictos: 5,
  validadoGestoria: 6,
  validadoEncargado: 7,
};

export default idsEstadoNomina;
