import React from "react";

// Constantes y helpers
import { connectionConstants } from "../../../../../constants";
import { errorHandler, authHeader, getTrad } from "../../../../../helpers";

// jQuery
import $ from "jquery";

// DevExtreme
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import { Popup, ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";
import Form, { Item } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import Box, { Item as ItemBox } from "devextreme-react/box";
import List from "devextreme-react/list";
import DataGrid, { Column, SearchPanel, Pager, Scrolling, Paging, Editing } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { Tooltip } from "devextreme-react/tooltip";

const formData_default = {
  idCategoriaInterna: null,
  idCategoriaConvenio: null,
  denominacion: "",
  salarioBase: null,
  plusAsistencia: null,
  plusResponsabilidad: null,
  plusPeligrosidad: null,
  incentivo: null,
  impHoraExtra: null,
  plusProductividad: null,
};

export default class PopupEdicionCategoriaInterna extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      categoriaInternaMod: { ...formData_default },
      personasExcluidas: [],
      idPersonaHover: null,
      mensajeHover: "_",
      keyHover: null,
      saveAvailable: true,
    };

    this.dxDataGrid_personas_Ref = React.createRef();
    this.dxList_personasExcluidas_Ref = React.createRef();
  }

  get dxDataGrid_personas() {
    return this.dxDataGrid_personas_Ref.current.instance;
  }

  get dxList_personasExcluidas() {
    return this.dxList_personasExcluidas_Ref.current.instance;
  }

  //#region DATASOURCES
  datasource_tblCategoriaInterna = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaInterna_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblCategoriaInterna_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblPersona = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPersona_beforeSend(request);
      },
      onLoading: (request) => {
        this.datasource_tblPersona_onLoading(request);
      },
      version: 4,
    }),
    select: ["idPersona", "nombre", "apellidos", "activo"],
    expand: [
      "tblDatosSalariales($select=salarioBase,plusAsistencia,plusResponsabilidad,plusPeligrosidad,incentivo,percSegSocial,impHoraExtra, plusProductividad)",
    ],
  });

  datasource_tblPersona_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.filtroTipoTrabajoRRHH = false;
  }

  datasource_tblPersona_onLoading = (loadOptions) => {
    const { categoriaInternaSel } = this.props;

    const idCategoriaInterna = categoriaInternaSel?.idCategoriaInterna ?? -1;
    let filter = [["idCategoriaInterna", "=", idCategoriaInterna], "and", ["eliminado eq false"]];

    if (loadOptions.filter) loadOptions.filter = [loadOptions.filter, "and", filter];
    else loadOptions.filter = filter;
  };

  context_categoriaInterna = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "CategoriasLaborales/",
    entities: {
      PatchMasivo: {},
    },
    beforeSend: (request) => {
      this.context_categoriaInterna_beforeSend(request);
    },
  });

  context_categoriaInterna_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.payload = this.context_categoriaInterna_getPayLoad();
  };

  //#endregion

  render() {
    let { categoriaInternaMod, personasExcluidas, saveAvailable, idPersonaHover, mensajeHover, keyHover } = this.state;
    let { isVisible_popup_showCategoria, categoriaInternaSel } = this.props;

    return (
      <Popup
        title={categoriaInternaSel?.denominacion + " - " + getTrad("modificacionDSalariales") ?? ""}
        width="95%"
        height="95%"
        deferRendering={false}
        visible={isVisible_popup_showCategoria}
        closeOnOutsideClick={false}
        onHiding={this.onHiding_popup_categoria}
      >
        <Box direction="row" width="100%" height="100%">
          <ItemBox ratio={4}>
            {/* FORM CATEGORIAINTERNA */}
            <Box direction="col" width="100%" height="100%">
              <ItemBox ratio={1}>
                <Form
                  formData={categoriaInternaMod}
                  deferRendering={false}
                  labelLocation="left"
                  colCount={3}
                  height="100%"
                  width="100%"
                >
                  <Item
                    dataField="salarioBase"
                    editorType={"dxNumberBox"}
                    editorOptions={this.currency_editorOptions}
                    label={this.formLabel_salarioBase}
                  />
                  <Item
                    dataField="percSegSocial"
                    editorType={"dxNumberBox"}
                    editorOptions={this.percSegSocial_editorOptions}
                    label={this.formLabel_percSegSocial}
                  />
                  <Item
                    dataField="plusAsistencia"
                    editorType={"dxNumberBox"}
                    editorOptions={this.currency_editorOptions}
                    label={this.formLabel_plusAsistencia}
                  />
                  <Item
                    dataField="plusResponsabilidad"
                    editorType={"dxNumberBox"}
                    editorOptions={this.currency_editorOptions}
                    label={this.formLabel_plusResponsabilidad}
                  />
                  <Item
                    dataField="plusPeligrosidad"
                    editorType={"dxNumberBox"}
                    editorOptions={this.currency_editorOptions}
                    label={this.formLabel_plusPeligrosidad}
                  />
                  <Item
                    dataField="incentivo"
                    editorType={"dxNumberBox"}
                    editorOptions={this.currency_editorOptions}
                    label={this.formLabel_incentivo}
                  />
                  <Item
                    dataField="impHoraExtra"
                    editorType={"dxNumberBox"}
                    editorOptions={this.currency_editorOptions}
                    label={this.formLabel_impHoraExtra}
                  />
                  <Item
                    dataField="plusProductividad"
                    editorType={"dxNumberBox"}
                    editorOptions={this.currency_editorOptions}
                    label={this.formLabel_plusProductividad}
                  />
                </Form>
              </ItemBox>
              {/* DATAGRID PERSONAS EN CATEGORIA */}
              <ItemBox ratio={5}>
                <DataGrid
                  //Datos
                  ref={this.dxDataGrid_personas_Ref}
                  dataSource={this.datasource_tblPersona}
                  //Props
                  repaintChangesOnly={false}
                  hoverStateEnabled={true}
                  //styles
                  height="100%"
                  width="100%"
                  columnsAutoWidth={true}
                  showColumnLines={false}
                  showRowLines={true}
                  confirmDeleteMessage=""
                  onCellHoverChanged={this.dxDataGrid_personas_onCellHoverChanged}
                  onCellClick={this.dxDataGrid_personas_onCellClick}
                  onToolbarPreparing={this.dxDataGrid_tblPersona_onToolbarPreparing}
                  remoteOperations={false}
                >
                  {/* <Editing confirmDelete={false} allowAdding={false} allowUpdating={false}></Editing> */}
                  <SearchPanel
                    visible={true}
                    width={240}
                    placeholder={getTrad("busqueda")}
                    searchVisibleColumnsOnly={true}
                  />
                  <Pager visible={false} />
                  <Scrolling mode="virtual" />
                  <Paging enabled={true} defaultPageSize={100} />
                  <Column
                    caption={""}
                    name="datosCambian"
                    dataType="boolean"
                    width={50}
                    cssClass="p-0"
                    allowEditing={false}
                    cellRender={this.cellRender_cambiaDatos}
                    calculateCellValue={this.check_datosSalarialesCambian_persona}
                    calculateSortValue={this.check_datosSalarialesCambian_persona}
                    sortIndex={3}
                    allowSorting={true}
                  />
                  <Column
                    name={"nombre"}
                    dataField="nombre"
                    caption={getTrad("nombre")}
                    minWidth={80}
                    sortOrder="asc"
                    sortIndex={2}
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="apellidos"
                    caption={getTrad("apellidos")}
                    minWidth={150}
                    sortOrder="asc"
                    sortIndex={3}
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="tblDatosSalariales.salarioBase"
                    caption={getTrad("salarioBase")}
                    width={100}
                    dataType="number"
                    alignment="center"
                    allowSorting={true}
                    calculateDisplayValue={this.calculateDisplayValue_salarioBase}
                    format={this.currency_format}
                  />
                  <Column
                    dataField="tblDatosSalariales.percSegSocial"
                    caption={getTrad("percSegSocial")}
                    width={120}
                    dataType="number"
                    alignment="center"
                    allowSorting={true}
                    calculateDisplayValue={this.calculateDisplayValue_percSegSocial}
                    format={this.percSegSocial_format}
                  />
                  <Column
                    dataField="tblDatosSalariales.plusAsistencia"
                    caption={getTrad("plusAsistencia")}
                    width={100}
                    dataType="number"
                    alignment="center"
                    allowSorting={true}
                    format={this.currency_format}
                    calculateDisplayValue={this.calculateDisplayValue_plusAsistencia}
                  />
                  <Column
                    dataField="tblDatosSalariales.plusResponsabilidad"
                    caption={getTrad("plusResponsabilidad")}
                    width={120}
                    dataType="number"
                    alignment="center"
                    allowSorting={true}
                    format={this.currency_format}
                    calculateDisplayValue={this.calculateDisplayValue_plusResponsabilidad}
                  />
                  <Column
                    dataField="tblDatosSalariales.plusPeligrosidad"
                    caption={getTrad("plusPeligrosidad")}
                    width={120}
                    dataType="number"
                    alignment="center"
                    allowSorting={true}
                    calculateDisplayValue={this.calculateDisplayValue_plusPeligrosidad}
                    format={this.currency_format}
                  />
                  <Column
                    dataField="tblDatosSalariales.incentivo"
                    caption={getTrad("incentivo")}
                    width={80}
                    dataType="number"
                    alignment="center"
                    allowSorting={true}
                    calculateDisplayValue={this.calculateDisplayValue_incentivo}
                    format={this.currency_format}
                  />
                  <Column
                    dataField="tblDatosSalariales.impHoraExtra"
                    caption={getTrad("impHoraExtra")}
                    width={110}
                    dataType="number"
                    alignment="center"
                    allowSorting={true}
                    calculateDisplayValue={this.calculateDisplayValue_impHoraExtra}
                    format={this.currency_format}
                  />
                  <Column
                    dataField="tblDatosSalariales.plusProductividad"
                    caption={getTrad("plusProductividad")}
                    width={120}
                    dataType="number"
                    alignment="center"
                    allowSorting={true}
                    calculateDisplayValue={this.calculateDisplayValue_plusProductividad}
                    format={this.currency_format}
                  />
                  <Column
                    dataField="activo"
                    caption={""}
                    width={90}
                    sortOrder="desc"
                    sortIndex={1}
                    alignment={"center"}
                    cellRender={this.renderActivo}
                  />
                  <Column
                    name="excluir"
                    caption={getTrad("excluir")}
                    width={60}
                    alignment="center"
                    cssClass="p-0"
                    cellRender={this.cellRender_excluir}
                  />
                  <Column dataField="idPersona" allowSearch={false} visible={false} />
                </DataGrid>
              </ItemBox>
            </Box>
          </ItemBox>
          <ItemBox baseSize={30} />
          <ItemBox ratio={1}>
            <div className="he-100 personasExcluidas_container">
              <div className="dx-list-group-header personasExcluidas_header">
                <div>{getTrad("personasExcluidas").toUpperCase()}</div>
              </div>
              <List
                ref={this.dxList_personasExcluidas_Ref}
                className="container_list_personasExcluidas  pb-5 "
                itemDeleteMode="static"
                selectionMode="none"
                noDataText=""
                pageLoadMode="scrollBottom"
                dataSource={personasExcluidas}
                itemRender={this.itemRender}
                searchExpr={["nombre", "apellidos"]}
                keyExpr="idPersona"
                showSelectionControls={true}
                allowItemDeleting={true}
                searchEnabled={true}
                onItemDeleted={this.onDelete_dxList_tblPersona_ExcluirPersona}
              />
            </div>
          </ItemBox>
        </Box>
        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
          <Button
            text={getTrad("guardar")}
            type="success"
            disabled={!saveAvailable}
            onClick={this.dxButton_popupCategoria_guardar_onClick}
          />
        </ToolbarItem_popup>
        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
          <Button text={getTrad("cancelar")} type="normal" onClick={this.dxButton_popupCategoria_cancelar_onClick} />
        </ToolbarItem_popup>
        <Tooltip
          target={"#" + keyHover + idPersonaHover}
          width={"auto"}
          height={"auto"}
          showEvent="mouseenter"
          hideEvent="mouseleave"
        >
          {mensajeHover}
        </Tooltip>
      </Popup>
    );
  }

  dxDataGrid_tblPersona_onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "before",
      template: function () {
        return $("<div>").addClass("font-size").addClass("pl-2 pb-2").text("Personas afectadas por los cambios");
      },
    });
  };

  calculateDisplayValue_salarioBase = (rowData) => {
    return rowData?.tblDatosSalariales?.salarioBase ?? 0;
  };
  calculateDisplayValue_percSegSocial = (rowData) => {
    return rowData?.tblDatosSalariales?.percSegSocial ?? 0;
  };
  calculateDisplayValue_plusAsistencia = (rowData) => {
    return rowData?.tblDatosSalariales?.plusAsistencia ?? 0;
  };
  calculateDisplayValue_plusResponsabilidad = (rowData) => {
    return rowData?.tblDatosSalariales?.plusResponsabilidad ?? 0;
  };
  calculateDisplayValue_plusPeligrosidad = (rowData) => {
    return rowData?.tblDatosSalariales?.plusPeligrosidad ?? 0;
  };
  calculateDisplayValue_incentivo = (rowData) => {
    return rowData?.tblDatosSalariales?.incentivo ?? 0;
  };
  calculateDisplayValue_impHoraExtra = (rowData) => {
    return rowData?.tblDatosSalariales?.impHoraExtra ?? 0;
  };
  calculateDisplayValue_plusProductividad = (rowData) => {
    return rowData?.tblDatosSalariales?.plusProductividad ?? 0;
  };

  itemRender = (item) => {
    return item.nombre.toUpperCase() + " " + item.apellidos.toUpperCase();
  };

  cellRender_excluir = () => {
    return (
      <div className="container_spanCentrado pointer">
        <div className="icon_Cerrar font-size-lg" />
      </div>
    );
  };

  cellRender_cambiaDatos = (e) => {
    if (e.rowType == "data") {
      let datosCambian = e.value;
      if (datosCambian) {
        return (
          <div id={"cambiaDatos_" + e.data.idPersona} className="container_spanCentrado">
            {<div className="icon_account_details_outline danger font-size-xl" />}
          </div>
        );
      }
    }
  };

  renderActivo = (cellData) => {
    const isActivo = cellData.data.activo;

    const estado = isActivo ? getTrad("activo") : getTrad("noActivo");
    const colorEstado = isActivo ? "bg-success" : "bg-danger";

    return <div className={"border-radius text-white " + colorEstado + ""}>{estado}</div>;
  };

  dxDataGrid_personas_onCellHoverChanged = (e) => {
    const { column, key } = e;
    let mensajeHover, keyHover;

    if (e.rowType == "data" && e.eventType === "mouseover") {
      if (column.name === "datosCambian" && e.value == true) {
        mensajeHover = getTrad("datosSalarialesPersonalizados");
        keyHover = "cambiaDatos_";
        this.setState({ idPersonaHover: key, mensajeHover, keyHover });
      }
    }
  };

  dxDataGrid_personas_onCellClick = (e) => {
    const { data } = e;
    const { personasExcluidas } = this.state;

    e.cancel = true;

    var personaYaExcluida = personasExcluidas.filter((x) => x.idPersona === data.idPersona)[0];
    if (personaYaExcluida == null && e.column.name == "excluir") {
      let persExcluida = { ...data };
      delete persExcluida.tblDatosSalariales;

      let personasExcluidas_ = [...personasExcluidas];
      personasExcluidas_.push(persExcluida);

      let filter = [];
      personasExcluidas_
        .map((x) => x.idPersona)
        .forEach((x) => {
          filter.push(["idPersona", "<>", x]);
        });

      this.datasource_tblPersona.filter(filter);
      this.datasource_tblPersona.reload();
      personasExcluidas_ = this.ordenarPersonas(personasExcluidas_);
      this.setState({ personasExcluidas: personasExcluidas_ });
    }
  };

  onDelete_dxList_tblPersona_ExcluirPersona = (e) => {
    const { personasExcluidas } = this.state;

    let filter = [];
    personasExcluidas
      .map((x) => x.idPersona)
      .forEach((x) => {
        filter.push(["idPersona", "<>", x]);
      });

    filter = filter.length > 0 ? filter : null;

    this.datasource_tblPersona.filter(filter);
    this.datasource_tblPersona.reload();

    personasExcluidas = this.ordenarPersonas(personasExcluidas);
    this.setState({ personasExcluidas: personasExcluidas });
  };

  ordenarPersonas = (personas) => {
    personas = personas.sort((a, b) => a.nombre.localeCompare(b.nombre));
    return personas;
  };

  dxFormEditor_onContentReady(e) {
    $(e.element).find("input").css({
      "text-align": "end",
      "font-weight": 500,
    });

    $(e.element).find(".dx-placeholder").css({
      "text-align": "right",
      width: "100%",
    });

    $(e.element).find(".dx-placeholder:before").css({
      padding: 0,
    });

    setTimeout(() => {
      $(e.element).find(".dx-selectbox-container .dx-texteditor-input").css({
        "font-weight": 500,
        "text-align": "start",
      });
    }, 0);
  }

  dxFormEditor_onValueChanged = (e) => {
    let categoriaInternaMod = { ...this.state.categoriaInternaMod };
    let dataField = e.component.option("name");
    categoriaInternaMod[dataField] = e.value;
    this.setState({ categoriaInternaMod: categoriaInternaMod });
  };

  dxButton_popupCategoria_guardar_onClick = (e) => {
    const { reloadGrid } = this.props;
    this.setState({ saveAvailable: false });

    this.context_categoriaInterna
      .invoke("PatchMasivo", {}, "POST")
      .done(() => {
        this.onHiding_popup_categoria();
        reloadGrid();

        notify({
          message: getTrad("aviso_C_RegistroActualizado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      })
      .always(() => {
        this.setState({ saveAvailable: true });
      });
  };

  dxButton_popupCategoria_cancelar_onClick = (e) => {
    this.onHiding_popup_categoria();
  };

  onHiding_popup_categoria = (e) => {
    this.props.onHiding();
    this.setState({
      categoriaInternaMod: { ...formData_default },
      personasExcluidas: [],
    });
    this.datasource_tblPersona.filter(null);
    this.dxDataGrid_personas.searchByText("");
    this.dxDataGrid_personas.clearSorting();
    this.dxDataGrid_personas.columnOption("nombre", "sortOrder", "asc");
    this.dxList_personasExcluidas.option("searchValue", "");
  };

  context_categoriaInterna_getPayLoad = () => {
    const { categoriaInternaMod, personasExcluidas } = this.state;
    const idsPersonasExcluidas = [];
    personasExcluidas.map((pers) => idsPersonasExcluidas.push(pers.idPersona));

    return { categoriaInterna: categoriaInternaMod, idsPersonasExcluidas };
  };

  check_datosSalarialesCambian_persona = ({ tblDatosSalariales }) => {
    const { categoriaInternaSel } = this.props;

    if (!categoriaInternaSel || !tblDatosSalariales) {
      return false;
    }

    const keys = [
      "salarioBase",
      "plusAsistencia",
      "plusResponsabilidad",
      "plusPeligrosidad",
      "incentivo",
      "impHoraExtra",
      "plusProductividad",
    ];
    const cambios = keys.some((key) => {
      const valorActual = categoriaInternaSel[key];
      const valorNuevo = tblDatosSalariales[key];

      return (
        valorActual !== valorNuevo &&
        !(valorActual === null && valorNuevo === 0) &&
        !(valorActual === 0 && valorNuevo === null)
      );
    });
    return cambios;
  };

  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: "EUR",
  };
  currency_editorOptions = {
    placeholder: "0.00 €",
    stylingMode: "underlined",
    step: 0,
    min: 0,
    max: 99999.99,
    width: "100%",
    format: this.currency_format,
    onContentReady: (e) => {
      this.dxFormEditor_onContentReady(e);
    },
  };

  percSegSocial_format = { style: "percent", maximumFractionDigits: 1 };

  percSegSocial_editorOptions = {
    placeholder: "0%",
    stylingMode: "underlined",
    step: 0,
    min: 0,
    max: 100,
    width: "100%",
    format: this.percSegSocial_format,
    onContentReady: (e) => {
      this.dxFormEditor_onContentReady(e);
    },
  };

  formLabel_salarioBase = { text: getTrad("salarioBase") };
  formLabel_percSegSocial = { text: getTrad("percSegSocial") };
  formLabel_plusAsistencia = { text: getTrad("plusAsistencia") };
  formLabel_plusResponsabilidad = { text: getTrad("plusResponsabilidad") };
  formLabel_plusPeligrosidad = { text: getTrad("plusPeligrosidad") };
  formLabel_incentivo = { text: getTrad("incentivo") };
  formLabel_impHoraExtra = { text: getTrad("impHoraExtra") };
  formLabel_plusProductividad = { text: getTrad("plusProductividad") };

  componentDidUpdate(prevProps) {
    const { categoriaInternaSel } = this.props;
    const _this = this;

    if (prevProps.categoriaInternaSel !== categoriaInternaSel) {
      this.datasource_tblPersona.reload().done(() => {
        _this.setState({ categoriaInternaMod: { ...categoriaInternaSel } });
      });
    }
  }
}
