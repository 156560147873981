import React, { Component, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import DefaultLink from "./DefaultLink";

import { history, getEstructura } from "../../helpers";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";

import Loader from "react-loaders";

class Nav extends Component {
  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    let { lavanderia, aplicacionActiva } = this.props;
    let estructuraMenu = getEstructura(lavanderia);
    let estructura = [];

    if (estructuraMenu && Object.keys(estructuraMenu).length > 0) {
      let estr1 = [];
      let estr2 = [];
      let estr3 = [];
      let estr4 = [];

      //PARTE 1
      if (estructuraMenu.Informes) {
        estr1.push({
          key: "form_0",
          icon: "menu_icons_Informes",
          label: "Informes",
          to: "/Informes",
        });
      }
      if (estructuraMenu.Dashboard) {
        estr1.push(estructuraMenu.Dashboard);
      }
      if (estructuraMenu.Compras) {
        estr1.push(estructuraMenu.Compras);
      }
      if (estructuraMenu.Ventas) {
        estr1.push(estructuraMenu.Ventas);
      }

      if (estructuraMenu.Administracion) {
        estr1.push(estructuraMenu.Administracion);
      }

      if (estructuraMenu.Gestoria) {
        estr1.push(estructuraMenu.Gestoria);
      }

      if (estructuraMenu.Contabilidad) {
        estr1.push(estructuraMenu.Contabilidad);
      }

      if (estructuraMenu.AssistantNew) {
        estr1.push(estructuraMenu.AssistantNew);
      }

      //PARTE 2
      if (estructuraMenu.Produccion) {
        estr2.push(estructuraMenu.Produccion);
      }
      if (estructuraMenu.Logistica) {
        estr2.push(estructuraMenu.Logistica);
      }
      if (estructuraMenu.ControlCalidad) {
        estr2.push(estructuraMenu.ControlCalidad);
      }

      if (estructuraMenu.Assistant) {
        estr2.push(estructuraMenu.Assistant);
      }

      if (estructuraMenu.Inventarios) {
        estr2.push(estructuraMenu.Inventarios);
      }
      if (estructuraMenu.Valet) {
        estr2.push(estructuraMenu.Valet);
      }
      if (estructuraMenu.ParametrosGenerales) {
        estr2.push(estructuraMenu.ParametrosGenerales);
      }

      //PARTE 3
      if (estructuraMenu.GestionInterna) {
        estr3.push(estructuraMenu.GestionInterna);
      }

      //PARTE 4
      if (estructuraMenu.RRHH) {
        estr4.push(estructuraMenu.RRHH);
      }

      //FINANZAS PARTE 5
      let estr_Finanzas = [];
      if (estructuraMenu.Finanzas) {
        estr_Finanzas.push(estructuraMenu.Finanzas);
      }
      if (estr_Finanzas.length > 0) {
        estructura.push(estr_Finanzas);
      }

      if (estr1.length > 0) {
        estructura.push(estr1);
      }
      if (estr2.length > 0) {
        estructura.push(estr2);
      }
      if (estr3.length > 0) {
        estructura.push(estr3);
      }
      if (estr4.length > 0) {
        estructura.push(estr4);
      }
    }

    return (
      <Fragment>
        {estructura.length > 0 ? (
          <Fragment>
            <div className="mt-3" />
            {estructura.map((estr, i) => (
              <Fragment key={i}>
                <MetisMenu
                  ref={"menu" + i}
                  content={estr}
                  activeLinkTo={history.location.pathname}
                  LinkComponent={DefaultLink}
                  onSelected={this.toggleMobileSidebar}
                  className={"vertical-nav-menu"}
                  classNameIcon={estr[0].menuItemColor && "color-white"} // Icono izquierda
                  classNameLink={estr[0].menuItemColor} // Nombre formulario
                  iconNameStateHidden={estr[0].menuItemColor && "color-white"} // Icono derecha normal
                  iconNameStateVisible={estr[0].menuItemColor && "color-white rotate-minus-90"} // Icono derecha seleccionado
                  iconNamePrefix=""
                  classNameStateIcon="menu_icons_ArrowDown"
                />
                {i < estructura.length - 1 ? <div className="divider"></div> : ""}
              </Fragment>
            ))}
          </Fragment>
        ) : (
          <div className="loader-container" style={{ position: "absolute", left: "0" }}>
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale" />
              </div>
              <h6 className="mt-3" style={{ color: "var(--secondary)" }}>
                Cargando
              </h6>
            </div>
          </div>
        )}
      </Fragment>
    );
  }

  componentDidUpdate() {
    let to = history.location.pathname.includes("Informes") ? "/Informes" : history.location.pathname;

    for (var propertyName in this.refs) {
      this.refs[propertyName].changeActiveLinkTo(to);
    }
  }
}

const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  formularioActivo: state.Global.formularioActivo,
  aplicacionActiva: state.Global.aplicacionActiva,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Nav);
