import React from "react";
import { connect } from "react-redux";
import JornadaPersonaReact from "../../RRHH/JornadaPersona/indexReact.js";
import JornadaPersonaJs from "./indexJs.js";

class FormSelector_JornadaPersona extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    let { lavanderia } = this.props;
    if (lavanderia == null) return null;
    return lavanderia.enableControlHorario ? (
      <JornadaPersonaReact />
    ) : (
      <JornadaPersonaJs />
    );
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(FormSelector_JornadaPersona);
