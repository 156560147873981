import React, { Fragment } from "react";
import { connect } from "react-redux";

//Layout
import { authHeader, errorHandler, getTrad } from "../../../../helpers";

//Componentes
import Box, { Item as ItemBox } from "devextreme-react/box";

import { DataGrid, Popup } from "devextreme-react";
import { connectionConstants } from "constants";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";

import EncuestaRealizada from "./EncuestaRealizada";
import StarRatings from "react-star-ratings";
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  KeyboardNavigation,
  Paging,
  Selection,
} from "devextreme-react/data-grid";

//Js

class PopUpDatosGenerales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource_listaUsuarios: [],
      idUsuarioSel: null,
    };

    this.dx_Grid_encuestaNUsuario_REF = React.createRef();
  }

  render() {
    const { visible, idEncuestaSel } = this.props;
    const { idUsuarioSel, dataSource_listaUsuarios } = this.state;

    return (
      <Popup
        visible={visible}
        onHiding={this.onHiding}
        title={getTrad("encuestas")}
        width={"65%"}
        height={"80%"}
      >
        <Box direction="row" width="100%" height="100%">
          <ItemBox ratio={1}>
            <div className="he-100 pb-5 pt-1">
              <div className="h4 text-center">{getTrad("usuarios")}</div>
              <DataGrid
                ref={this.dx_Grid_encuestaNUsuario_REF}
                dataSource={visible ? dataSource_listaUsuarios : []}
                className="he-100"
                remoteOperations={false}
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={true}
                hoverStateEnabled={true}
                onRowClick={this.seleccionaUsuario}
                noDataText={getTrad("sinDatos")}
                keyExpr="idUsuario"
                onRowPrepared={this.onRowPrepared}
              >
                <Editing
                  mode="cell"
                  allowUpdating={true}
                  selectTextOnEditStart={true}
                />
                <Paging enabled={false} />
                <HeaderFilter visible={true} />
                <FilterRow visible={true} />
                <Selection mode={"single"} />
                <KeyboardNavigation
                  enterKeyAction="moveFocus"
                  enterKeyDirection="row"
                  editOnKeyPress={true}
                />
                <Column
                  dataField="nombre"
                  caption={getTrad("nombre")}
                  dataType="string"
                  allowHeaderFiltering={true}
                  allowSearch={true}
                  allowEditing={false}
                  sortIndex={1}
                  minWidth={225}
                />
                <Column
                  dataField="valorMedio"
                  caption={getTrad("valorMedio")}
                  alignment="center"
                  dataType="number"
                  allowHeaderFiltering={false}
                  allowSearch={false}
                  allowEditing={false}
                  sortIndex={1}
                  calculateCellValue={this.dx_column_redondeaValorMedio}
                  width={90}
                />
                <Column
                  dataField="valorMedio"
                  caption={getTrad("rating")}
                  defaultSelectedFilterOperation=">="
                  alignment="center"
                  allowHeaderFiltering={false}
                  allowSearch={false}
                  width={120}
                  allowEditing={false}
                  cellRender={this.render_starRating}
                  minWidth={170}
                />
                /
              </DataGrid>
            </div>
          </ItemBox>
          <ItemBox baseSize={30} />
          <ItemBox ratio={1}>
            <EncuestaRealizada
              visible={visible}
              idEncuestaSel={idEncuestaSel}
              idUsuarioSel={idUsuarioSel}
            />
          </ItemBox>
        </Box>
      </Popup>
    );
  }

  dx_column_redondeaValorMedio = (e) => {
    if (e.valorMedio) return e.valorMedio.toFixed(2);
  };

  render_starRating = (e) => {
    const maxRango = e?.row?.data?.maxRango ?? null;

    if (maxRango == null) {
      return <></>;
    }

    return (
      <div className="container_starRating">
        <StarRatings
          rating={e.value}
          starRatedColor={"var(--primary)"}
          starEmptyColor={"#F3EFEF"}
          numberOfStars={maxRango}
          name={getTrad("rating")}
          starDimension="22px"
          starSpacing="1px"
        />
      </div>
    );
  };

  onRowPrepared = (e) => {
    if (e.rowType === "data") {
      e.rowElement.addClass("styleRowGrid");
    }
  };

  get dx_Grid_encuestaNUsuario() {
    return this.dx_Grid_encuestaNUsuario_REF.current.instance;
  }

  dx_List_itemRendered = (e) => {
    const nombre = e?.nombre;
    const contestado = e?.valorMedio !== null;

    return (
      <div
        style={{ minHeight: 35 }}
        className="he-100 w-100 d-flex align-items-center"
      >
        <div className="he-100 w-100 ">{nombre}</div>
        {contestado && (
          <div className="d-flex justify-content-between align-items-center">
            <div
              id={"idUsuario_" + e.idUsuario + "_contestado"}
              className="container_starRating"
            >
              <StarRatings
                rating={e.valorMedio}
                starRatedColor={"var(--primary)"}
                starEmptyColor={"#F3EFEF"}
                numberOfStars={e.maxRango}
                name={getTrad("rating")}
                starDimension="25px"
                starSpacing="1px"
              />
            </div>
            <div className="px-2">{e.valorMedio.toFixed(2)}</div>
          </div>
        )}
      </div>
    );
  };

  seleccionaUsuario = (e) => {
    const idUsuarioSel = e.data.idUsuario;
    this.setState({ idUsuarioSel });
  };

  onHiding = () => {
    const { onValueChange } = this.props;
    this.setState({ idUsuarioSel: null, dataSource_listaUsuarios: [] });
    onValueChange({ showPopUpDatosGenerales: false });
  };

  dataSource_listaUsuarios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Encuestas/getDatosEncuestas_listaUsuarios",
      key: "idPregunta",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_datosEncuestaNUsuario_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_datosEncuestaNUsuario_beforeSend = (request) => {
    const { idLavanderiaSel, idEncuestaSel, idCompañiaSel } = this.props;

    request.headers = { ...authHeader() };
    request.params.idLavanderia = idLavanderiaSel;
    request.params.idEncuesta = idEncuestaSel;
    request.params.idCompañia = idCompañiaSel;
  };

  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    if (visible && !prevProps.visible) {
      this.dataSource_listaUsuarios.reload().done((data) => {
        this.setState({ dataSource_listaUsuarios: data }, () => {
          this.dx_Grid_encuestaNUsuario.repaint();
        });
      });
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(PopUpDatosGenerales);
