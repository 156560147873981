import React, { Fragment } from "react";

import { connectionConstants } from "../../../../../constants";
import { errorHandler, authHeader, getTrad, formatNumber, formatDate_parameter } from "../../../../../helpers";

import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import DataGrid, {
    Column,
    Paging,
    HeaderFilter,
    FilterRow,
    Selection,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";
import { Tooltip } from "devextreme-react/tooltip";

import { progressBarCellComponent } from "../../../../../components/DataGrid/Cells";

export default class GridControlRendimiento extends React.PureComponent {
    constructor(props) {
        super(props);

        this.grid_REF = React.createRef();
    }

    get grid() {
        return this.grid_REF.current.instance;
    }

    datasource_spSelectReportSmartHub = new DataSource({
        paginate: false,
        store: new ODataStore({
            key: "id",
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "Dashboard/SmartView/spSelectReportSmartHub",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_spSelectReportSmartHub_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_spSelectReportSmartHub_beforeSend(request) {
        let { group, filtros } = this.props;
        let { idLavanderia, fechaIni, fechaFin, idFamilia, idTipoPrenda, idCompañia, idEntidad, idMaquina } = filtros;

        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
        request.params.group = group;
        request.params.idFamilia = idFamilia;
        request.params.idTipoPrenda = idTipoPrenda;
        request.params.idCompañia = idCompañia;
        request.params.idEntidad = idEntidad;
        request.params.idMaquina = idMaquina;
        request.params.fechaIni = formatDate_parameter(fechaIni);
        request.params.fechaFin = formatDate_parameter(fechaFin);
    }

    render() {
        let { selectedRowKey, group } = this.props;

        return (
            <DataGrid
                ref={this.grid_REF}
                dataSource={process.env.NODE_ENV === "development" ? null : this.datasource_spSelectReportSmartHub}
                remoteOperations={false}
                height="100%"
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={true}
                hoverStateEnabled={true}
                selectedRowKeys={selectedRowKey != null ? [selectedRowKey] : undefined}
                onToolbarPreparing={this.props.title ? this.onToolbarPreparing : undefined}
                onEditorPreparing={this.onEditorPreparing}
                onSelectionChanged={this.props.onSelectionChanged ? this.onSelectionChanged : undefined}
            >
                <Paging enabled={false} />
                <HeaderFilter visible={true} />
                <FilterRow visible={true} />
                <Selection mode={this.props.onSelectionChanged ? "single" : undefined} />
                <Column
                    dataField="denominacion"
                    dataType="string"
                    caption={group.length === 0 ? getTrad("fecha") : getTrad("denominacion")}
                    minWidth={200}
                />
                <Column
                    dataField="denoMaquina"
                    caption={getTrad("maquina")}
                    dataType="string"
                    visible={group.length === 0}
                    minWidth={200}
                />
                <Column
                    dataField="numPrendas"
                    caption={getTrad("prendas")}
                    dataType="number"
                    alignment={"center"}
                    defaultSelectedFilterOperation=">="
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    width={100}
                />
                <Column
                    dataField="horas"
                    caption={getTrad("horas")}
                    dataType="number"
                    alignment={"center"}
                    defaultSelectedFilterOperation=">="
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    width={100}
                    customizeText={this.customizeText_horas}
                />
                <Column
                    dataField="piezasHoraReal"
                    caption={getTrad("real")}
                    dataType="number"
                    alignment={"center"}
                    defaultSelectedFilterOperation=">="
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    width={100}
                    customizeText={this.customizeText_decimal}
                />
                <Column
                    dataField="prendasHora"
                    caption={getTrad("teorico")}
                    dataType="number"
                    alignment={"center"}
                    defaultSelectedFilterOperation=">="
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    width={100}
                    customizeText={this.customizeText_decimal}
                />
                <Column
                    dataField="cumplimiento"
                    caption={getTrad("cumplimiento")}
                    cellComponent={progressBarCellComponent}
                    dataType="number"
                    alignment="center"
                    filterOperations={["=", "<>", "<", ">", "<=", ">="]}
                    defaultSelectedFilterOperation=">="
                    defaultFilterValue={0}
                    cssClass="p-0"
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    sortOrder="desc"
                    width={150}
                    calculateCellValue={this.calculateCellValue_cumplimiento}
                />
                <Column
                    dataField="personas"
                    caption={getTrad("personas")}
                    width={60}
                    alignment="center"
                    cssClass="p-0"
                    allowFiltering={false}
                    allowSorting={false}
                    allowEditing={false}
                    visible={group.length === 0}
                    cellRender={this.cellRender_personas}
                />
                <Summary calculateCustomSummary={this.calculateCustomSummary}>
                    <TotalItem column="denominacion" displayFormat={getTrad("total").toUpperCase()} />
                    <TotalItem column="numPrendas" name="numPrendas" summaryType="sum" displayFormat="{0}" />
                    <TotalItem
                        column="horas"
                        name="horas"
                        summaryType="sum"
                        displayFormat="{0}"
                        customizeText={this.customizeText_sum_horas}
                    />
                    <TotalItem
                        name="piezasHoraReal"
                        showInColumn={"piezasHoraReal"}
                        summaryType="custom"
                        displayFormat="{0}"
                        valueFormat={this.decimal_format}
                    />
                    <TotalItem
                        name="prendasHora"
                        showInColumn={"prendasHora"}
                        summaryType="custom"
                        displayFormat="{0}"
                        valueFormat={this.decimal_format}
                    />
                    <TotalItem
                        name="cumplimiento"
                        showInColumn={"cumplimiento"}
                        summaryType="custom"
                        displayFormat="{0}"
                        customizeText={this.customizeText_sum_cumplimiento}
                    />
                </Summary>
            </DataGrid>
        );
    }

    customizeText_decimal = (e) => {
        return Math.round(e.value) + "";
    };

    calculateCellValue_cumplimiento = (e) => {
        return Math.round(e.piezasHoraReal) / Math.round(e.prendasHora);
    };

    decimal_format = { style: "decimal", maximumFractionDigits: 2 };

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: "before",
            text: this.props.title,
        });
    };

    sliderText = null;
    onEditorPreparing = (e) => {
        let _this = this;
        if (e.parentType === "filterRow") {
            if (e.dataField === "cumplimiento") {
                e.editorName = "dxSlider";
                e.editorOptions = {
                    min: 0,
                    max: 1,
                    value: e.value,
                    step: 0.05,
                    width: "100%",
                    onValueChanged: function (data) {
                        e.setValue(data.value);
                        let textValue = formatNumber(data.value, 0, "percent");
                        _this.sliderText.text(textValue);
                    },
                    onInitialized: function (e) {
                        let value = e.component.option("value");
                        let textValue = formatNumber(value, 0, "percent");
                        _this.sliderText = $("<div />").addClass("slider-textValue").text(textValue);
                        e.element.append(_this.sliderText);
                    },
                };
            }
        }
    };

    cellRender_personas = (itemData) => {
        let { data, value } = itemData;

        return (
            <Fragment>
                <div
                    id={"tooltip_personas_" + data.id}
                    className={"container_spanCentrado center-svg iconos_svg icon_Personas"}
                    style={{ fontSize: "23px" }}
                />
                <Tooltip
                    target={"#tooltip_personas_" + data.id}
                    position="bottom"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                >
                    <div>
                        <div className="tooltip-header">PERSONAS</div>
                        <div className="tooltip-body">
                            {value.split(", ").map((pers, i) => {
                                return (
                                    <div key={i} className="tooltip-row">
                                        <div className="series-name">{pers}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Tooltip>
            </Fragment>
        );
    };

    customizeText_horas = (e) => {
        return this.format_hours_toHHMM(e.value);
    };

    calculateCustomSummary = (options) => {
        if (options.name == "piezasHoraReal") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    options.horasTotal = 0;
                    options.producto_horas_piezasHoraReal = 0;
                    break;
                case "calculate":
                    options.horasTotal += options.value.horas;
                    options.producto_horas_piezasHoraReal += options.value.horas * options.value.piezasHoraReal;
                    break;
                case "finalize":
                    options.totalValue = options.producto_horas_piezasHoraReal / options.horasTotal;
                    options.totalValue = options.totalValue ? Math.round(options.totalValue) : 0;
                    break;
            }
        } else if (options.name == "prendasHora") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    options.horasTotal = 0;
                    options.producto_horas_prendasHora = 0;
                    break;
                case "calculate":
                    options.horasTotal += options.value.horas;
                    options.producto_horas_prendasHora += options.value.horas * options.value.prendasHora;
                    break;
                case "finalize":
                    options.totalValue = options.producto_horas_prendasHora / options.horasTotal;
                    options.totalValue = options.totalValue ? Math.round(options.totalValue) : 0;
                    break;
            }
        } else if (options.name == "cumplimiento") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    options.horasTotal = 0;
                    options.producto_horas_prendasHora = 0;
                    options.producto_horas_piezasHoraReal = 0;
                    break;
                case "calculate":
                    options.horasTotal += options.value.horas;
                    options.producto_horas_piezasHoraReal += options.value.horas * options.value.piezasHoraReal;
                    options.producto_horas_prendasHora += options.value.horas * options.value.prendasHora;
                    break;
                case "finalize":
                    let piezasHoraReal_total = options.producto_horas_piezasHoraReal / options.horasTotal;
                    let prendasHora_total = options.producto_horas_prendasHora / options.horasTotal;
                    options.totalValue = Math.round(piezasHoraReal_total) / Math.round(prendasHora_total);
                    options.totalValue = options.totalValue ? options.totalValue : 0;
                    break;
            }
        }
    };

    customizeText_sum_cumplimiento = (itemInfo) => {
        return formatNumber(itemInfo.value, 2, "percent");
    };

    customizeText_sum_horas = (itemInfo) => {
        return this.format_hours_toHHMM(itemInfo.value);
    };

    format_hours_toHHMM = (totalHours) => {
        var signo = totalHours < 0 ? "-" : "";
        var absTotalHours = Math.abs(totalHours);

        var horas = Math.floor(absTotalHours);
        var minutos = Math.round((absTotalHours - horas) * 60);

        var horas_formatted = String(horas).padStart(2, "0");
        var minutos_formatted = String(minutos).padStart(2, "0");

        return signo + horas_formatted + ":" + minutos_formatted;
    };

    onSelectionChanged = (e) => {
        if (e.selectedRowKeys.length > 0) {
            let key = e.selectedRowKeys[0].split("_");
            this.props.onSelectionChanged({ [key[0]]: parseInt(key[1]) });
        } else {
            this.props.onSelectionChanged({});
        }
    };

    componentDidUpdate(prevProps) {
        let { title, group, filtros } = this.props;
        if (JSON.stringify(prevProps.filtros) !== JSON.stringify(filtros) || prevProps.group !== group) {
            this.datasource_spSelectReportSmartHub.reload();
            this.grid.clearFilter();
        }

        if (prevProps.title !== title) {
            this.grid.repaint();
        }
    }
}
